import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import classes from './NftMessages.module.css';
import 'emoji-mart/css/emoji-mart.css';
import {
  createNftMessageRequest,
  createNotificationToMultipleUsersRequest,
  getNftMessagesRequest,
  searchUsersByUsernameRequest,
} from '../../../../httpRequests/httpRequests';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { AuthContext } from '../../../../context/auth-context';
import { SocketContext } from '../../../../context/socket-context';
import { Link, useHistory } from 'react-router-dom';
import notify from '../../../../utils/notify';
import moment from 'moment';
import { isToday } from '../../../../helpers/helpers';
import {
  NFT_MESSAGE_CREATED,
  NFT_MESSAGE_MENTION,
} from '../../../../constants/SOCKET_EVENTS';
import Button from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import VerifiedIcon from '../../../../assets/verified.png';
import NoMessagesIcon from '../../../../assets/no-messages.png';

const RESULTS_PER_PAGE = 10;
// let fetchedPages = {};
// let page = 2;

function NftMessages(props) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { nft } = props;
  const nftId = nft._id;
  const { profile } = useContext(AuthContext);
  const { socket } = useContext(SocketContext);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['nft-messages', nftId],
    ({ pageParam = 1 }) =>
      getNftMessagesRequest(nftId, pageParam, RESULTS_PER_PAGE),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  const messageInputRef = useRef(null);
  const messagesRef = useRef(null);
  const [message, setMessage] = useState('');
  const [splittedMessage, setSplittedMessage] = useState([]);
  const [mentionedUsernames, setMentionedUsernames] = useState({});
  const [, setShowEmojiEditor] = useState(false);

  const getMentionValue = () => {
    if (message === '') return '';

    if (splittedMessage[splittedMessage.length - 1]?.includes('\n@')) {
      let mentionValue = '';
      let i = splittedMessage[splittedMessage.length - 1].length - 1;
      while (splittedMessage[splittedMessage.length - 1][i] !== '@') {
        mentionValue += splittedMessage[splittedMessage.length - 1][i];
        i--;
      }
      return mentionValue.split('').reverse().join('');
    }

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (msg[0] === '@' && mentionedUsernames[msg]) continue;

      if (msg[0] === '@' && !mentionedUsernames[msg]) {
        const mentionValue = msg.replace('@', '');
        return mentionValue;
      }
    }
    return '';
  };

  const mentionValue = getMentionValue();

  const { data: mentions } = useQuery(['mentions', mentionValue], () =>
    searchUsersByUsernameRequest(mentionValue)
  );

  useEffect(() => {
    const checkForClosingEmojiEditor = (e) => {
      if (
        e.target.className === 'open-editor-emoji' ||
        e.target.className === 'input-message'
      )
        return;

      const clickedInsideEmojiEditor = e.target.closest('.emoji-mart') !== null;
      if (!clickedInsideEmojiEditor) {
        setShowEmojiEditor(false);
      } else {
        setShowEmojiEditor(true);
      }
    };

    window.addEventListener('click', checkForClosingEmojiEditor);

    return () =>
      window.removeEventListener('click', checkForClosingEmojiEditor);
  }, []);

  const scrollToTop = useCallback(() => {
    const scrollingElement = messagesRef.current;
    if (scrollingElement) scrollingElement.scrollTop = 0;
  }, [messagesRef]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  // useEffect(() => {
  //   if (!messagesRef.current) return;

  //   const messagesRefElement = messagesRef.current;
  //   let fetching = false;
  //   const scrollHandler = async (e) => {
  //     const { scrollHeight, scrollTop, clientHeight } = e.target;

  //     if (
  //       !fetching &&
  //       !fetchedPages[page] &&
  //       scrollHeight - scrollTop >= clientHeight * 1.25
  //     ) {
  //       fetching = true;

  //       if (hasNextPage) {
  //         const { data: newMessages } = await getNftMessagesRequest(
  //           nftId,
  //           page,
  //           RESULTS_PER_PAGE
  //         );
  //         fetchedPages[page] = true;
  //         queryClient.setQueryData(['nft-messages', nftId], (data) => {
  //           return {
  //             ...data,
  //             pages: data.pages.map((page) => {
  //               const updatedData = [...newMessages, ...page.data];

  //               return {
  //                 ...page,
  //                 results: page.results + 1,
  //                 data: updatedData,
  //               };
  //             }),
  //           };
  //         });
  //         page++;
  //         fetching = false;
  //       }
  //     }
  //   };

  // messagesRefElement.addEventListener('scroll', scrollHandler);
  // return () => {
  //   messagesRefElement.removeEventListener('scroll', scrollHandler);
  // };
  // }, [hasNextPage, fetchNextPage, queryClient, nftId, messagesRef]);

  // see messagesRefElement error

  useEffect(() => {
    let fetching = false;
    const messagesRefElement = messagesRef.current;

    if (!messagesRefElement) return;

    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.4) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    messagesRefElement.addEventListener('scroll', scrollHandler);
    return () => {
      messagesRefElement.removeEventListener('scroll', scrollHandler);
    };
  }, [hasNextPage, fetchNextPage, messagesRef]);

  const renderMessage = ({ _id, message, sender, mentions }) => {
    return (
      <div key={_id} className={classes['message-container']}>
        <Link
          to={
            sender._id === profile?._id
              ? '/my-space'
              : `/user-feed/${sender._id}`
          }
        >
          <img
            src={sender.photo}
            alt="Sender"
            className={classes['sender-photo']}
          />
        </Link>

        <div className={classes['fullName-message']}>
          <Link
            to={
              sender._id === profile?._id
                ? '/my-space'
                : `/user-feed/${sender._id}`
            }
          >
            {' '}
            <p className={classes['sender-fullName']}>
              {sender.fullName}
              {sender.verified && (
                <img
                  alt="Verified"
                  src={VerifiedIcon}
                  style={{ height: 14, marginLeft: 4, marginBottom: 2 }}
                />
              )}
            </p>
          </Link>
          {message.split('\n').map((newLineMessage, i) => {
            const splittedNewLineMessage = newLineMessage.split(' ');
            return (
              <p key={newLineMessage + i + Math.random().toString()}>
                {splittedNewLineMessage.map((msg) => {
                  if (msg === '' || msg === ' ')
                    return (
                      <span key={msg + i + Math.random().toString()}> </span>
                    );
                  const mentionIndex = mentions.findIndex(
                    ({ mentionUsername }) => mentionUsername === msg
                  );

                  const isMention = msg[0] === '@' && mentionIndex !== -1;

                  if (isMention) {
                    return (
                      <span key={msg + i + Math.random().toString()}>
                        <span> </span>
                        <span
                          className={classes['mention-text']}
                          onClick={() =>
                            history.push(
                              mentions[mentionIndex]?.data.id === profile?._id
                                ? '/my-space'
                                : `/user-feed/${mentions[mentionIndex]?.data.id}`
                            )
                          }
                        >
                          {msg}
                        </span>
                      </span>
                    );
                  }
                  return (
                    <span
                      className={classes['message']}
                      key={msg + i + Math.random().toString()}
                    >
                      <span> </span>
                      <span>{msg}</span>
                    </span>
                  );
                })}
              </p>
            );
          })}
        </div>
      </div>
    );
  };

  const getMessagesGroupedByDate = useCallback((messages) => {
    const messagesGroupedByDate = {};
    messages.forEach((message) => {
      const { createdAt } = message;

      const groupedByDateKey = isToday(createdAt)
        ? 'Today'
        : `${moment(createdAt).format('MMMM Do, YYYY')}`;

      if (messagesGroupedByDate[groupedByDateKey]) {
        messagesGroupedByDate[groupedByDateKey].push(message);
      } else {
        messagesGroupedByDate[groupedByDateKey] = [message];
      }
    });

    const groupedMessages = [];
    for (const key in messagesGroupedByDate) {
      groupedMessages.push({
        date: key,
        messages: messagesGroupedByDate[key],
      });
    }

    return groupedMessages;
  }, []);

  const renderMessages = () =>
    data.pages.map((page) => {
      const messagesGroupedByDate = getMessagesGroupedByDate(page.data);
      return messagesGroupedByDate.map(({ date, messages }, i) => {
        return (
          <div key={date + i}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p className={classes['date']}>{date}</p>
            </div>

            {messages.map(({ _id, message, sender, createdAt, mentions }) =>
              renderMessage({ _id, message, sender, createdAt, mentions })
            )}

            {(isLoading || isFetching) && <LoadingSpinner />}
          </div>
        );
      });
    });

  const messageChangeHandler = (e) => {
    const splittedMessage = e.target.value.split(' ');

    setMessage(e.target.value);
    setSplittedMessage(splittedMessage);

    const updatedMentionedUsernames = {};
    for (const usernameKey in mentionedUsernames) {
      if (splittedMessage.includes(usernameKey)) {
        updatedMentionedUsernames[usernameKey] =
          mentionedUsernames[usernameKey];
      }
    }

    setMentionedUsernames(updatedMentionedUsernames);
  };

  const shouldNotShowMentionsTable = () => {
    return splittedMessage.every((msg) => {
      return (
        msg === '' ||
        msg === ' ' ||
        msg[0] !== '@' ||
        (msg[0] === '@' && typeof mentionedUsernames[msg] === 'object')
      );
    });
  };

  const invalidateNftMessages = useCallback(
    (newNftMessage) => {
      queryClient.setQueryData(['nft-messages', nftId], (data) => {
        return {
          ...data,
          pages: data.pages.map((page, i) => {
            const updatedData = [];
            if (i === 0) {
              updatedData.push(newNftMessage);
            }
            updatedData.push(...page.data);

            return { ...page, results: page.results + 1, data: updatedData };
          }),
        };
      });
    },
    [nftId, queryClient]
  );

  useEffect(() => {
    if (!socket) return;
    socket.on(NFT_MESSAGE_CREATED, (data) => {
      if (nftId === data.nftId && profile?._id !== data.senderId) {
        invalidateNftMessages(data.nftMessage);
        setTimeout(() => {
          scrollToTop();
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  const sendNotificationsToMentionedUsers = useCallback(async () => {
    if (!profile)
      return notify(
        'error',
        'You are not logged in. Please log in to perform this action!',
        2000
      );

    try {
      const mentionedUserIds = [];
      for (const key in mentionedUsernames) {
        const userId = mentionedUsernames[key]._id;
        const isMe = profile?._id === userId;
        if (!isMe) {
          mentionedUserIds.push(userId);
        }
      }
      const requestBody = {
        userIds: mentionedUserIds,
        notificationType: 'nft-message-mention',
        entityName: nft.title,
        entityId: nftId,
      };
      await createNotificationToMultipleUsersRequest(requestBody);
      socket.emit(NFT_MESSAGE_MENTION, { mentionedUserIds, nft });
    } catch (error) {
      notify('error', error, 2000);
    }
  }, [socket, mentionedUsernames, nft, profile, nftId]);

  const sendMessageHandler = useCallback(async () => {
    if (!message.trim()) return;
    try {
      const mentions = [];
      Object.entries(mentionedUsernames).forEach(([mentionUsername, data]) => {
        mentions.push({ mentionUsername, data });
      });
      const requestBody = {
        message: message,
        mentions,
      };
      const { data } = await createNftMessageRequest(nftId, requestBody);
      setMessage('');
      setSplittedMessage([]);
      invalidateNftMessages(data);
      setTimeout(() => {
        scrollToTop();
      });
      sendNotificationsToMentionedUsers();
      setMentionedUsernames({});
    } catch (error) {
      notify('error', error, 2000);
    }
  }, [
    message,
    nftId,
    invalidateNftMessages,
    scrollToTop,
    sendNotificationsToMentionedUsers,
    mentionedUsernames,
  ]);

  useEffect(() => {
    const keyPressHandler = (e) => {
      if (e.key === 'Enter' && e.shiftKey) {
        return;
      }

      if (e.key === 'Enter') {
        sendMessageHandler();
      }
    };

    const registerEnterPressEvent = () => {
      document.addEventListener('keypress', keyPressHandler);
    };

    registerEnterPressEvent();

    return () => document.removeEventListener('keypress', keyPressHandler);
  }, [sendMessageHandler]);

  const selectUserToMentionHandler = (user) => {
    const updatedMentionedUsernames = { ...mentionedUsernames };

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (
        msg[0] === '@' &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[i] = `@${username}`;
      } else if (
        splittedMessage[splittedMessage.length - 1]?.includes('\n@') &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[splittedMessage.length - 1] = `\n@${username}`;
      }
    }

    splittedMessage.push('');
    setMentionedUsernames(updatedMentionedUsernames);
    setMessage(splittedMessage.join(' '));
    setSplittedMessage(splittedMessage);
    messageInputRef.current.focus();
  };

  const shouldShowMentionsTableInNewLine = () => {
    return splittedMessage[splittedMessage.length - 1]?.includes('\n@');
  };

  return (
    <div className={classes['nft-messages-container']}>
      {data && data.pages[0].results === 0 ? (
        <div className={classes['no-messages-container']}>
          <img width={100} src={NoMessagesIcon} alt="No messages yet!" />
          <p className={classes['no-messages']}>No Messages</p>
        </div>
      ) : (
        <div ref={messagesRef} className={classes['messages-container']}>
          {data?.pages && renderMessages()}
        </div>
      )}

      <div className={classes['mention-input-container']}>
        <div className={classes['input-container']}>
          <textarea
            value={message}
            onChange={messageChangeHandler}
            ref={messageInputRef}
            placeholder="Add a message…"
            className={classes['textarea']}
          />
          <Button
            disabled={!message}
            onClick={sendMessageHandler}
            darkpink="true"
          >
            Post
          </Button>
        </div>

        {(!shouldNotShowMentionsTable() ||
          shouldShowMentionsTableInNewLine()) && (
          <div className={classes['mention-container']}>
            {mentions && mentions.data.length === 0 && (
              <p className={classes['no-users-found']}>No Users found</p>
            )}

            {mentions &&
              mentions.data.map((user) => {
                const fullName = `${user.firstName} ${user.lastName}`;
                return (
                  <div
                    onClick={() => selectUserToMentionHandler(user)}
                    className={classes['user-container']}
                    key={user._id}
                  >
                    <div>
                      <img
                        style={{ objectFit: 'cover' }}
                        alt="userPhoto"
                        className={classes['user-image']}
                        src={user.photo}
                      />
                    </div>
                    <p className={classes['user-text']}>
                      {user.username} ({fullName})
                      {user._id === profile?._id ? ' (You)' : ''}
                    </p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}

export default NftMessages;
