import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import { MetaMaskContext } from '../../context/metamask-context';

import notify from '../../utils/notify';
import { useHistory } from 'react-router-dom';
import {
  isInputValidationCompleted,
  isOverallFormValid,
} from '../../helpers/helpers';
import EmailForgotPasswordModal from './components/EmailForgotPasswordModal';
import ForgotPasswordModal from './components/ForgotPasswordModal';
import { GoogleLogin } from 'react-google-login';
import classes from './Login.module.css';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import FacebookLogin from 'react-facebook-login';
import FacebookIcon from '../../assets/facebook-icon.png';
import HiddenIcon from '../../assets/hidden.png';
import NotHiddenIcon from '../../assets/not-hidden.png';
import MetaMaskIcon from '../../assets/metamask-icon.png';
import LoginWithUnstoppable from './components/LoginWithUnstoppable/LoginWithUnstoppable';
import GlimpseLogo from '../../assets/glimpse-logo.png';
import AppStoreIcon from '../../assets/app-store-icon.png';
import PlayStoreIcon from '../../assets/play-store-icon.png';
import { decrypt } from '../../helpers/encrypt-decrypt';
import { NewsFeedContext } from '../../context/news-feed-context';

const Login = () => {
  const history = useHistory();

  const { setShowChangePasswordModal } = useContext(NewsFeedContext);

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const resetParam = queryParams.get('reset');
  const emailParam = queryParams.get('e');
  const passwordParam = queryParams.get('p');

  const emailParamExists = resetParam === '1' && emailParam;
  const passwordParamExists = resetParam === '1' && passwordParam;

  const {
    login,
    loginViaGoogle,
    loginViaFacebook,
    loginWithMetaMask,
  } = useContext(AuthContext);
  const {
    connectedWalletAddress,
    setConnectedWalletAddress,
    isMetaMaskInstalled,
  } = useContext(MetaMaskContext);
  const [formState, setFormState] = useState({
    email: {
      value: emailParamExists ? decrypt('salt', emailParam) : '',
      touched: emailParamExists ? true : false,
      valid: emailParamExists ? true : false,
      validationRequirements: { isEmail: true },
    },
    password: {
      value: passwordParamExists ? decrypt('salt', passwordParam) : '',
      touched: passwordParamExists ? true : false,
      valid: passwordParamExists ? true : false,
      validationRequirements: { minLength: 6 },
    },
  });
  const [isFormValid, setIsFormValid] = useState(
    emailParamExists && passwordParamExists ? true : false
  );
  const [error, setError] = useState();
  const [
    showEmailForgotPasswordModal,
    setShowEmailForgotPasswordModal,
  ] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showPasswordAsText, setShowPasswordAsText] = useState(false);

  useEffect(() => {
    error && notify('error', error, 2000, () => setError());
  }, [error]);

  const inputChangeHandler = (formStateKey, value) => {
    const updatedFormState = { ...formState };
    updatedFormState[formStateKey].value = value;
    updatedFormState[formStateKey].touched = true;
    updatedFormState[formStateKey].valid = isInputValidationCompleted(
      value,
      updatedFormState[formStateKey].validationRequirements
    );

    setIsFormValid(isOverallFormValid(updatedFormState));

    setFormState(updatedFormState);
  };

  const emailStyle = {};
  const passwordStyle = {};

  if (formState.email.valid === false && formState.email.touched) {
    emailStyle.border = '1px solid red';
  }

  if (formState.password.valid === false && formState.password.touched) {
    passwordStyle.border = '1px solid red';
  }

  const submitFormHandler = async (e) => {
    e.preventDefault();

    try {
      await login(
        formState.email.value.toLowerCase(),
        formState.password.value
      );

      if (resetParam === '1' && emailParamExists && passwordParamExists) {
        setShowChangePasswordModal(true);
      }

      history.push('/news-feed');
    } catch (error) {
      setError(error);
    }
  };

  const loginWithGoogleFailureHandler = (err) => {
    const errorMessage = err.details
      ? `Login with Google Error: ${err.details}`
      : 'Something went wrong via Login with Google';
    notify('error', errorMessage, 2000);
  };

  const loginWithGoogleSuccessHandler = async (res) => {
    try {
      const accessToken = res.tokenId;
      await loginViaGoogle(accessToken);
    } catch (error) {
      notify(
        'error',
        error || 'Something went wrong via Login with Google!',
        2000
      );
    }
  };

  const loginWithFacebookHandler = async (res) => {
    try {
      const { accessToken } = res;
      await loginViaFacebook(accessToken);
    } catch (err) {
      notify('error', 'Something went wrong via Login with Facebook!', 2000);
    }
  };

  const loginWithMetaMaskHandler = async () => {
    const { ethereum } = window;

    try {
      let metaMaskWalletAddress;
      if (connectedWalletAddress) {
        metaMaskWalletAddress = connectedWalletAddress;
      } else {
        await ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await ethereum.request({ method: 'eth_accounts' });

        metaMaskWalletAddress = accounts[0];
      }

      const msg = `0x${Buffer.from(
        'Use MetaMask to Connect to Glimpse',
        'utf8'
      ).toString('hex')}`;
      const metaMaskPersonalSignature = await ethereum.request({
        method: 'personal_sign',
        params: [msg, metaMaskWalletAddress, 'Example password'],
      });

      await loginWithMetaMask(metaMaskPersonalSignature, metaMaskWalletAddress);

      setConnectedWalletAddress(metaMaskWalletAddress);
    } catch (err) {
      if (typeof err === 'string') {
        notify('error', err, 2000);
      } else notify('error', err.message, 2000);
    }
  };

  return (
    <>
      {showEmailForgotPasswordModal && (
        <EmailForgotPasswordModal
          setShowEmailForgotPasswordModal={setShowEmailForgotPasswordModal}
          setShowForgotPasswordModal={setShowForgotPasswordModal}
        />
      )}
      {showForgotPasswordModal && (
        <ForgotPasswordModal
          setShowForgotPasswordModal={setShowForgotPasswordModal}
        />
      )}

      <div className={classes['login-container']}>
        <div className={classes['info']}>
          <header className={classes['info-header']}>
            <img
              src={GlimpseLogo}
              alt="Glimpse"
              className={classes['glimpse-logo']}
            />

            <Link to="/apply-as-creator">
              <div className={classes['apply-as-creator-container']}>
                <p className={classes['apply-as-creator-text']}>
                  Apply As Creator
                </p>
              </div>
            </Link>
          </header>
          <div className={classes['info-description']}>
            <h3>Get Inside Access to Your Favorite Creator</h3>
            <p>
              Get the inside scoop, the latest updates, and the behind the scene
              shots by joining your favorite creators private community - It’s
              like being a part of their private story!
            </p>
          </div>

          <div className={classes['app-store-play-store-container']}>
            <a
              href={'https://apps.apple.com/us/app/glimpse-nft/id1630409614'}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={AppStoreIcon}
                alt="App Store"
                className={classes['app-store-icon']}
              />
            </a>

            <a
              href={
                'https://play.google.com/store/apps/details?id=com.glimpse.glimpsenft&hl=en_US&gl=US'
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={PlayStoreIcon}
                alt="Play Store"
                className={classes['play-store-icon']}
              />
            </a>
          </div>
        </div>

        <div className={classes['login-form']}>
          <div className={classes['form-container']}>
            <div className={classes['create-account-container']}>
              <h2>Sign In</h2>
            </div>

            <div className={classes['form']}>
              <div className={classes['email']}>
                <Input
                  value={formState.email.value}
                  style={{
                    width: '100%',
                    borderRadius: 50,
                    padding: '11px 10px',
                    background: '#F4F5F5',
                    ...emailStyle,
                  }}
                  placeholder="E-mail"
                  onChange={(e) => inputChangeHandler('email', e.target.value)}
                />
              </div>

              <div className={classes['password']}>
                <Input
                  value={formState.password.value}
                  type={showPasswordAsText ? 'text' : 'password'}
                  onChange={(e) =>
                    inputChangeHandler('password', e.target.value)
                  }
                  style={{
                    width: '100%',
                    background: '#F4F5F5',
                    padding: '11px 10px',
                    borderRadius: 50,
                    ...passwordStyle,
                  }}
                  placeholder="Enter Password"
                />

                <img
                  onClick={() =>
                    setShowPasswordAsText((prevState) => !prevState)
                  }
                  src={!showPasswordAsText ? HiddenIcon : NotHiddenIcon}
                  alt="Hide"
                  className={classes['hidden-icon']}
                />
              </div>

              <div className={classes['empty-space']}></div>

              <Button
                onClick={submitFormHandler}
                disabled={!isFormValid}
                darkpink={'true'}
                style={{
                  width: '100%',
                }}
              >
                Sign In
              </Button>

              <div className={classes['forgot-password-signup-container']}>
                <p
                  onClick={() => setShowEmailForgotPasswordModal(true)}
                  className={classes['forgot-password']}
                >
                  Forgot Password ?
                </p>

                <Link to="/register">
                  <p className={classes['signup-text']}>Sign up for Glimpse</p>
                </Link>
              </div>

              <div style={{ marginTop: 20 }}></div>
              <div className={classes['facebook-google-container']}>
                <GoogleLogin
                  clientId={
                    '937758613434-b2fbdud15euo7ugs79inld6bbflmcr73.apps.googleusercontent.com'
                  }
                  buttonText={
                    <p className={classes['login-google']}>Login With Google</p>
                  }
                  className="login-with-google-btn"
                  onSuccess={loginWithGoogleSuccessHandler}
                  onFailure={loginWithGoogleFailureHandler}
                  cookiePolicy={'single_host_origin'}
                />

                <div className="facebook-login-container">
                  <FacebookLogin
                    appId="303589791961151"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={loginWithFacebookHandler}
                    scope="public_profile,email,user_photos,user_videos,user_friends"
                    cssClass="my-facebook-button-class"
                    icon={
                      <img
                        alt="Facebook"
                        src={FacebookIcon}
                        className={classes['facebook-icon']}
                      />
                    }
                    textButton="Login With Facebook"
                  />
                </div>
              </div>

              {isMetaMaskInstalled ? (
                <div
                  onClick={loginWithMetaMaskHandler}
                  className={classes['signup-metamask-button']}
                >
                  <img
                    src={MetaMaskIcon}
                    alt="MetaMask"
                    className={classes['metamask-icon']}
                  />
                  <p className={classes['signup-metamask-text']}>
                    Login With MetaMask
                  </p>
                </div>
              ) : (
                <a
                  rel="noreferrer"
                  href={`https://metamask.io/download/`}
                  target="_blank"
                >
                  <div className={classes['signup-metamask-button']}>
                    <img
                      src={MetaMaskIcon}
                      alt="MetaMask"
                      className={classes['metamask-icon']}
                    />
                    <p className={classes['signup-metamask-text']}>
                      Install & Login With MetaMask
                    </p>
                  </div>
                </a>
              )}

              <LoginWithUnstoppable />
            </div>
          </div>
        </div>

        {/* <div className={classes['info']}>
          <img
            src={LoginImagePreview}
            className={classes['login-image-preview']}
            alt="Login Preview"
          />
          <div className={classes['info-center']}>
            <img
              alt="Glimpse"
              className={classes['glimpse-vector']}
              src={GlimpseVector}
            />

            <h3>
              Social media built to reward ALL users. Whether you're a content
              creator,
              <span style={{ display: 'block' }}>
                supporter, or simply use social media to stay connected - we
                designed Glimpse with you in mind.
              </span>
            </h3>
            <p className={classes['through']}>
              With Glimpse, users can easily create NFT's (seriously, it's
              grandma friendly), connect with others who have verified shared
              interests, and receive rewards simply for being social!
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Login;
