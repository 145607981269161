import React, { useEffect, useState } from 'react';
import classes from './AcceptCollaboratorNftUploadRequestModal.module.css';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import TextArea from '../TextArea/TextArea';
import { limitString } from '../../helpers/helpers';
import PinkRemoveIcon from '../../assets/pink-remove.png';
import { acceptRequestedNftUploadFromCollaboratorRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import { useQueryClient } from 'react-query';

function AcceptCollaboratorNftUploadRequestModal(props) {
  const queryClient = useQueryClient();

  const {
    setAcceptCollaboratorNftUploadRequestModalState,
    collaboratorNftUploadRequestId,
  } = props;

  const [buyNowPrice, setBuyNowPrice] = useState('');
  const [buyNowPriceTouched, setBuyNowPriceTouched] = useState(false);
  const [title, setTitle] = useState('');
  const [titleTouched, setTitleTouched] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionTouched, setDescriptionTouched] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const [categoryInputValueTouched, setCategoryInputValueTouched] = useState(
    ''
  );
  const [royalteFee, setRoyalteFee] = useState('');
  const [royalteFeeTouched, setRoyalteFeeTouched] = useState(false);

  const [
    acceptingCollaboratorNftUploadRequest,
    setAcceptingCollaboratorNftUploadRequest,
  ] = useState(false);

  const closeModalHandler = () => {
    setAcceptCollaboratorNftUploadRequestModalState({
      opened: false,
      previewUrl: undefined,
      ipfs: undefined,
      nftCollection: undefined,
      type: undefined,
    });
  };

  useEffect(() => {
    const addCategoryInputValueToCategoriesOnEnterClick = (e) => {
      if (
        e.key === 'Enter' &&
        categoryInputValue.trim().length > 0 &&
        categories.length < 3
      ) {
        setCategories((prevState) => [...prevState, categoryInputValue]);
        setCategoryInputValue('');
      }
    };

    document.addEventListener(
      'keyup',
      addCategoryInputValueToCategoriesOnEnterClick
    );

    return () =>
      document.removeEventListener(
        'keyup',
        addCategoryInputValueToCategoriesOnEnterClick
      );
  }, [categoryInputValue, categories]);

  const trendingCategoryClickHandler = (trendingCategory) => {
    if (categories.includes(trendingCategory)) {
      setCategories((prevState) =>
        prevState.filter((el) => el !== trendingCategory)
      );
    } else if (categories.length < 3) {
      setCategories((prevState) => [...prevState, trendingCategory]);
    }
  };

  const categoryChangeHandler = (e) => {
    setCategoryInputValue(e.target.value.trim());
    setCategoryInputValueTouched(true);
    if (
      e.target.value.indexOf(' ') !== -1 &&
      e.target.value.trim().length >= 1
    ) {
      setCategories((prevState) => [...prevState, categoryInputValue]);
      setCategoryInputValue('');
    }
  };

  const buyNowPriceChangeHandler = (e) => {
    setBuyNowPrice(e.target.value);
    setBuyNowPriceTouched(true);
  };

  const titleChangeHandler = (e) => {
    setTitle(e.target.value);
    setTitleTouched(true);
  };

  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value);
    setDescriptionTouched(true);
  };

  const royalteFeeChangeHandler = (e) => {
    setRoyalteFee(e.target.value);
    setRoyalteFeeTouched(true);
  };

  const removeCategoryHandler = (index) => {
    setCategories((prevState) => prevState.filter((_, i) => i !== index));
  };

  const trendingCategories = [
    'Art',
    'Collectibles',
    'Photography',
    'Sports',
    'Trading Cards',
    'Music',
    'Profile Picture',
    'Animals',
    'Nature',
    'Painting',
    '3D Abstract',
    'AI',
    'Meme',
    'Video',
  ];

  const invalidateMyRequestedNftUploadsFromCollaborators = () => {
    return Promise.all([
      queryClient.invalidateQueries(
        'my-requested-nft-uploads-from-collaborators'
      ),
      queryClient.refetchQueries('my-requested-nft-uploads-from-collaborators'),
    ]);
  };

  const acceptRequestedNftUploadFromCollaboratorHandler = async () => {
    setAcceptingCollaboratorNftUploadRequest(true);

    try {
      const requestBody = {
        title,
        buyNowPrice: parseFloat(buyNowPrice),
        description,
        category: categories[0],
        additionalCategories: categories.slice(1),
        royalteFee,
      };

      await acceptRequestedNftUploadFromCollaboratorRequest(
        collaboratorNftUploadRequestId,
        requestBody
      );

      await invalidateMyRequestedNftUploadsFromCollaborators();

      setAcceptingCollaboratorNftUploadRequest(false);

      closeModalHandler();
    } catch (err) {
      setAcceptingCollaboratorNftUploadRequest(false);
      notify('error', err, 2000);
    }
  };

  const titleInputStyle = { width: '100%' };
  if (!title && titleTouched) {
    titleInputStyle['border'] = '1px solid red';
  }

  const buyNowPriceInputStyle = { width: '100%' };
  if (
    buyNowPriceTouched &&
    (!buyNowPrice || isNaN(buyNowPrice) || parseFloat(buyNowPrice) < 500)
  ) {
    buyNowPriceInputStyle['border'] = '1px solid red';
  }

  const descriptionInputStyle = { width: '100%', minHeight: 100 };
  if (descriptionTouched && !description) {
    descriptionInputStyle['border'] = '1px solid red';
  }

  const categoryInputStyle = {};
  if (
    categoryInputValue.length === 0 &&
    categories.length === 0 &&
    categoryInputValueTouched
  ) {
    categoryInputStyle['border'] = '1px solid red';
  }

  const royalteFeeInputStyle = { width: '100%' };
  if (
    royalteFeeTouched &&
    (!royalteFee ||
      isNaN(royalteFee) ||
      parseFloat(royalteFee) < 0 ||
      parseFloat(royalteFee) > 5)
  ) {
    royalteFeeInputStyle['border'] = '1px solid red';
  }

  const isCreateButtonDisabled =
    !title ||
    !description ||
    !buyNowPrice ||
    isNaN(buyNowPrice) ||
    parseFloat(buyNowPrice) < 500 ||
    !royalteFee ||
    isNaN(royalteFee) ||
    parseFloat(royalteFee) < 0 ||
    parseFloat(royalteFee) > 5 ||
    categories.length === 0;

  return (
    <Modal
      style={{ minHeight: 670 }}
      closeModal={closeModalHandler}
      title="Accept & Create NFT"
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={acceptingCollaboratorNftUploadRequest}
            style={{ width: 150 }}
            modernpinkcolor="true"
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <Button
            disabled={
              isCreateButtonDisabled || acceptingCollaboratorNftUploadRequest
            }
            style={{ width: 150 }}
            modernpink="true"
            onClick={acceptRequestedNftUploadFromCollaboratorHandler}
          >
            Create
          </Button>
        </div>
      }
    >
      <div>
        <p className={classes['label']}>Title</p>
        <Input
          value={title}
          onChange={titleChangeHandler}
          style={titleInputStyle}
          placeholder="Enter here"
        />

        <p className={classes['label']}>Price (Min: 500 GLMS)</p>
        <Input
          value={buyNowPrice}
          onChange={buyNowPriceChangeHandler}
          style={buyNowPriceInputStyle}
          placeholder="Enter here"
          type="number"
        />

        <p className={classes['label']}>Royalte Fee (0% - 5%)</p>
        <Input
          value={royalteFee}
          onChange={royalteFeeChangeHandler}
          style={royalteFeeInputStyle}
          placeholder="Enter here"
          type="number"
        />

        <p className={classes['label']}>Description</p>
        <TextArea
          value={description}
          onChange={descriptionChangeHandler}
          style={descriptionInputStyle}
          placeholder="Enter here"
        />

        <div className={classes['categories-container']}>
          <p className={classes['label']}>
            Categories (Maximum 3 - Separate via Space/Enter)
          </p>
          <div className={classes['categories-input']}>
            {categories.map((category, i) => (
              <div
                title={`#${category}`}
                key={category + i}
                className={classes['category']}
              >
                <p
                  style={{
                    whiteSpace: 'nowrap',
                    margin: 0,
                  }}
                >
                  #{limitString(category, 16)}
                </p>
                <img
                  alt="Remove"
                  className={classes['remove-icon']}
                  onClick={() => removeCategoryHandler(i)}
                  src={PinkRemoveIcon}
                  width={8}
                  height={9}
                />
              </div>
            ))}
            <Input
              style={{ width: '100%', ...categoryInputStyle }}
              placeholder={categories.length === 3 ? '' : '#Enter here'}
              value={categoryInputValue}
              disabled={categories.length === 3}
              onChange={categoryChangeHandler}
            />
          </div>
          <div className={classes['trending-categories']}>
            {trendingCategories.map((trendingCategory, i) => {
              const containerStyle = {};
              if (categories.includes(trendingCategory)) {
                containerStyle['border'] = '1px solid #F900B7';
              }
              return (
                <div
                  style={containerStyle}
                  onClick={() => trendingCategoryClickHandler(trendingCategory)}
                  className={classes['trending-category-container']}
                  key={trendingCategory + i}
                >
                  <p
                    style={{
                      color: categories.includes(trendingCategory) && '#F900B7',
                    }}
                    className={classes['trending-category-text']}
                  >
                    {trendingCategory}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AcceptCollaboratorNftUploadRequestModal;
