import React, { useEffect, useMemo, useState } from 'react';
import classes from './Explore.module.css';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import GoBackIcon from '../../assets/go-back-icon-2.png';
import MarketPlace from '../MarketPlace/MarketPlace';
import SuggestedGroups from '../SuggestedGroups/SuggestedGroups';
import DiscoverFriends from '../DiscoverFriends/DiscoverFriends';
import { useHistory, useLocation } from 'react-router-dom';
import NftCollections from '../NftCollections/NftCollections';
import BrandItems from '../BrandItems/BrandItems';

const EXPLORE_OPTIONS = {
  Marketplace: 'Marketplace',
  Collections: 'Collections',
  Users: 'Users',
  Communities: 'Communities',
  // Brands: 'Brands',
};

function Explore() {
  const history = useHistory();
  const [selectedExploreOption, setSelectedExploreOption] = useState(
    EXPLORE_OPTIONS.Marketplace
  );

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const tabParam = queryParams.get('tab');

  useEffect(() => {
    if (tabParam) {
      if (EXPLORE_OPTIONS[tabParam]) {
        setSelectedExploreOption(EXPLORE_OPTIONS[tabParam]);
      }
    }
  }, [tabParam]);

  return (
    <div className={classes['explore-page-container']}>
      <div className={classes['user-sidebar-container']}>
        <UserSidebar />
      </div>

      <div className={classes['explore-container']}>
        <header className={classes['explore-header']}>
          <div className={classes['go-back-container']}>
            <div onClick={() => history.push('/news-feed')}>
              <img
                src={GoBackIcon}
                alt="Go Back"
                className={classes['go-back-icon']}
              />
            </div>
            <h3>Explore</h3>
          </div>

          <div className={classes['explore-options-container']}>
            {Object.keys(EXPLORE_OPTIONS).map((option) => (
              <div
                key={option}
                onClick={() => {
                  setSelectedExploreOption(option);
                  history.push(`/explore?tab=${option}`);
                }}
                className={classes['explore-option-container']}
              >
                <p
                  className={`${classes['explore-option-text']} ${
                    option === selectedExploreOption &&
                    classes['explore-option-text-selected']
                  }`}
                >
                  {option}
                </p>
              </div>
            ))}
          </div>
        </header>

        {selectedExploreOption === EXPLORE_OPTIONS.Marketplace && (
          <MarketPlace />
        )}

        {selectedExploreOption === EXPLORE_OPTIONS.Communities && (
          <SuggestedGroups />
        )}

        {selectedExploreOption === EXPLORE_OPTIONS.Users && <DiscoverFriends />}

        {selectedExploreOption === EXPLORE_OPTIONS.Collections && (
          <NftCollections />
        )}

        {selectedExploreOption === EXPLORE_OPTIONS.Brands && <BrandItems />}
      </div>
    </div>
  );
}

export default Explore;
