import React, { useContext, useEffect } from 'react';
import classes from './NftCollectionActivity.module.css';
import { useInfiniteQuery } from 'react-query';
import { getNftCollectionActivityRequest } from '../../../../httpRequests/httpRequests';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { IPFS_URL } from '../../../../constants/IPFS';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { getFormattedNumber, limitString } from '../../../../helpers/helpers';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import { AuthContext } from '../../../../context/auth-context';
import moment from 'moment';
import notify from '../../../../utils/notify';

const RESULTS_PER_PAGE = 10;
const GLMS_TO_USD_PRICE = 0.01;

function NftCollectionActivity(props) {
  const { collectionId } = props;
  const { profile } = useContext(AuthContext);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['nft-collection-activity', collectionId],
    ({ pageParam = 1 }) =>
      getNftCollectionActivityRequest(
        collectionId,
        pageParam,
        RESULTS_PER_PAGE
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const renderTransactionAction = (transaction) => {
    if (transaction.from === profile?._id) {
      return <p className={classes['transaction-action']}>Sold</p>;
    } else if (transaction.to === profile?._id) {
      return <p className={classes['transaction-action']}>Purchased</p>;
    }

    return null;
  };

  const renderTransactionPrice = (transaction) => {
    if (transaction.currency === 'GLMS') {
      const usdPrice = parseFloat(
        transaction.price * GLMS_TO_USD_PRICE
      ).toFixed(2);

      return (
        <div>
          <p className={classes['green-price']}>
            {getFormattedNumber(usdPrice)} USD
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p className={classes['green-price']}>
            {getFormattedNumber(transaction.price)} {transaction.currency}
          </p>
        </div>
      );
    }
  };

  const renderTransactionDate = (transaction) => {
    const { date } = transaction;

    return (
      <p className={classes['transaction-date']}>
        {moment(date).format('MMMM Do, YYYY')}
      </p>
    );
  };

  return (
    <div className={classes['transactions-container']}>
      <div className={classes['transactions-table']}>
        <p>NFT</p>
        <p>TITLE</p>
        <p>PRICE</p>
        <p>DATE</p>

        <div className={classes['table-horizontal-line']}></div>

        {!isLoading && data && data.pages && data.pages[0].results === 0 && (
          <p style={{ gridColumn: '1/-1' }}>No Transactions Found!</p>
        )}

        {!isLoading &&
          data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map((transaction) => {
              return (
                <Link
                  to={`/nfts/${transaction.nft._id}`}
                  key={transaction._id}
                  className={classes['transaction']}
                >
                  {transaction.nft.type === 'video' ? (
                    <ReactPlayer
                      width={44}
                      height={44}
                      style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                      url={
                        transaction.nft.previewUrl
                          ? transaction.nft.previewUrl
                          : `${IPFS_URL}/${transaction.nft.ipfs}`
                      }
                    />
                  ) : (
                    <img
                      width={44}
                      height={44}
                      className={classes['nft-image']}
                      src={
                        transaction.nft.previewUrl
                          ? transaction.nft.previewUrl
                          : `${IPFS_URL}/${transaction.nft.ipfs}`
                      }
                      alt="IPFS"
                    />
                  )}
                  <p
                    title={transaction.nft.title}
                    className={classes['nft-title']}
                  >
                    {limitString(transaction.nft.title, 19)}
                  </p>
                  {renderTransactionAction(transaction)}
                  {renderTransactionPrice(transaction)}
                  {renderTransactionDate(transaction)}
                </Link>
              );
            });
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default NftCollectionActivity;
