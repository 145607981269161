import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { createGroupPollAnswerRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import classes from './AddGroupPollAnswerOptionModal.module.css';

function AddGroupPollAnswerOptionModal(props) {
  const queryClient = useQueryClient();
  const { setShowAddGroupPollAnswerOptionModal, groupPollId, groupId } = props;
  const [option, setOption] = useState('');
  const [optionTouched, setOptionTouched] = useState(false);
  const [addingOption, setAddingOption] = useState(false);

  const closeModalHandler = () => {
    setShowAddGroupPollAnswerOptionModal(false);
  };

  const optionChangeHandler = (e) => {
    setOptionTouched(true);
    setOption(e.target.value);
  };

  const invalidateGroupPolls = () =>
    Promise.all([
      queryClient.invalidateQueries(['group-polls', groupId]),
      queryClient.refetchQueries(['group-polls', groupId]),
    ]);

  const invalidateVoteResults = () =>
    Promise.all([
      queryClient.invalidateQueries(['votes-results', groupPollId]),
      queryClient.refetchQueries(['votes-results', groupPollId]),
    ]);

  const invalidateUserAccessToCreateGroupPollAnswer = () =>
    Promise.all([
      queryClient.invalidateQueries(['access-to-create-answer', groupPollId]),
      queryClient.refetchQueries(['access-to-create-answer', groupPollId]),
    ]);

  const addGroupPollAnswerOptionHandler = async () => {
    setAddingOption(true);

    try {
      const requestBody = {
        answer: option,
      };
      await createGroupPollAnswerRequest(groupPollId, requestBody);

      await Promise.all([
        invalidateGroupPolls(),
        invalidateVoteResults(),
        invalidateUserAccessToCreateGroupPollAnswer(),
      ]);

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setAddingOption(false);
  };

  const optionInputStyle = { width: '100%' };
  if (!option && optionTouched) {
    optionInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            disabled={addingOption}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={!option || addingOption}
            onClick={addGroupPollAnswerOptionHandler}
            darkpink="true"
          >
            Add
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Add Another Option"
    >
      <p className={classes['label']}>Option</p>
      <Input
        style={optionInputStyle}
        placeholder="Enter here"
        value={option}
        onChange={optionChangeHandler}
      />
    </Modal>
  );
}

export default AddGroupPollAnswerOptionModal;
