import React, { useContext, useEffect, useState, useCallback } from 'react';
import { LEAVE_ROOM, OFFLINE } from '../constants/SOCKET_EVENTS';
import {
  signMetaMaskPersonalSignatureRequest,
  updateMyMetaMaskWalletAddressesRequest,
} from '../httpRequests/httpRequests';
import notify from '../utils/notify';
import { AuthContext } from './auth-context';
import { SocketContext } from './socket-context';

const { ethereum } = window;

const metaMaskContext = {
  isMetaMaskInstalled: false,
  connectMetaMaskHandler: () => {},
  connectedWalletAddress: undefined,
  setConnectedWalletAddress: (_connectedWalletAddress) => {},
};

export const MetaMaskContext = React.createContext(metaMaskContext);

const isMetaMaskInstalledHandler = () => {
  return Boolean(ethereum && ethereum.isMetaMask);
};

const MetaMaskProvider = (props) => {
  const { profile, updateProfileInformation, logout } = useContext(AuthContext);
  const { socket, setJoinedUserId } = useContext(SocketContext);
  const isMetaMaskInstalled = isMetaMaskInstalledHandler();

  const [connectedWalletAddress, setConnectedWalletAddress] = useState(
    undefined
  );

  useEffect(() => {
    if (!ethereum || !isMetaMaskInstalled || !profile) return;

    getAccountsHandler();
    // eslint-disable-next-line
  }, [profile, isMetaMaskInstalled]);

  const logOutHandler = () => {
    socket?.emit(LEAVE_ROOM, profile?._id);
    socket?.emit(OFFLINE, profile?._id);
    setJoinedUserId(false);
    logout();
    window.location.reload();
  };

  useEffect(() => {
    if (!ethereum || !isMetaMaskInstalled) return;

    ['chainChanged', 'accountsChanged'].forEach((event) => {
      ethereum.on(event, (accounts) => {
        if (event === 'accountsChanged') {
          if (!accounts[0]) {
            setConnectedWalletAddress(undefined);
            if (profile?.loggedInViaMetaMask) {
              logOutHandler();
            }
          }
          return;
        }
        if (profile) {
          window.location.reload();
        }
      });
    });
    // eslint-disable-next-line
  }, [profile]);

  const signMetaMaskPersonalSignatureHandler = useCallback(
    async (connectedWalletAddress) => {
      try {
        const msg = `0x${Buffer.from(
          'Use MetaMask to Connect to Glimpse',
          'utf8'
        ).toString('hex')}`;

        if (!profile?.metaMaskPersonalSignatureSigned) {
          const metaMaskPersonalSignature = await ethereum.request({
            method: 'personal_sign',
            params: [msg, connectedWalletAddress, 'Example password'],
          });

          const requestBody = {
            metaMaskWalletAddress: connectedWalletAddress,
          };
          await signMetaMaskPersonalSignatureRequest(
            metaMaskPersonalSignature,
            requestBody
          );

          updateProfileInformation({
            ...profile,
            metaMaskPersonalSignatureSigned: true,
          });
          return metaMaskPersonalSignature;
        }
      } catch (err) {
        if (typeof err === 'string') {
          notify('error', err, 2000);
        } else notify('error', err.message, 2000);
      }
    },
    // eslint-disable-next-line
    [profile]
  );

  const signMyMetaMaskWalletAddress = async (connectedWalletAddress) => {
    try {
      const msg = `0x${Buffer.from(
        'Include your MetaMask Wallet Address to Glimpse',
        'utf8'
      ).toString('hex')}`;

      const metaMaskPersonalSignature = await ethereum.request({
        method: 'personal_sign',
        params: [msg, connectedWalletAddress, 'Example password'],
      });

      const requestBody = {
        metaMaskPersonalSignature,
      };
      await updateMyMetaMaskWalletAddressesRequest(
        connectedWalletAddress,
        requestBody
      );
    } catch (err) {
      throw err;
    }
  };

  const getAccountsHandler = useCallback(async () => {
    if (!profile) return;

    try {
      const accounts = await ethereum.request({ method: 'eth_accounts' });

      if (!accounts[0]) return;

      if (profile.metaMaskPersonalSignatureSigned) {
        setConnectedWalletAddress(accounts[0]);

        // if (profile.lastConnectedMetaMaskWalletAddress !== accounts[0]) {
        if (!profile.metaMaskWalletAddresses?.includes(accounts[0])) {
          await signMyMetaMaskWalletAddress(accounts[0]);
          setConnectedWalletAddress(accounts[0]);
        }
      } else {
        if (await signMetaMaskPersonalSignatureHandler(accounts[0])) {
          setConnectedWalletAddress(accounts[0]);

          // if (profile.lastConnectedMetaMaskWalletAddress !== accounts[0])
          if (!profile.metaMaskWalletAddresses?.includes(accounts[0]))
            signMyMetaMaskWalletAddress(accounts[0]);
        }
      }
    } catch (err) {
      if (typeof err === 'string') {
        notify('error', err, 2000);
      } else notify('error', err.message, 2000);
    }
    // eslint-disable-next-line
  }, [profile]);

  const connectMetaMaskHandler = async () => {
    try {
      if (!ethereum || !isMetaMaskInstalled) return;

      await ethereum.request({ method: 'eth_requestAccounts' });

      await getAccountsHandler();
    } catch (err) {
      throw err;
    }
  };

  return (
    <MetaMaskContext.Provider
      value={{
        isMetaMaskInstalled,
        connectMetaMaskHandler,
        connectedWalletAddress,
        setConnectedWalletAddress,
      }}
    >
      {props.children}
    </MetaMaskContext.Provider>
  );
};

export default MetaMaskProvider;
