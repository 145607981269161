import React, { useEffect, useState } from 'react';
import classes from './UserSidebar.module.css';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth-context';
import NotificationsSelectedIcon from '../../assets/notifications-selected-icon.png';
import { getUsersNumberOfFollowersAndFollowingsRequest } from '../../httpRequests/httpRequests';
import { Link, useHistory, useLocation } from 'react-router-dom';
import NotificationIcon from '../../assets/notifications-icon.png';
import MessagesIcon from '../../assets/messages-icon.png';
import MessagesSelectedIcon from '../../assets/messages-selected-icon.png';
import WhiteWalletIcon from '../../assets/balance-icon.png';
import DiamondIcon from '../../assets/diamond.png';
import ReferralSelectedIcon from '../../assets/megaphone-selected-icon.png';
import ReferralIcon from '../../assets/megaphone-icon.png';
import PinkVerifiedIcon from '../../assets/verified.png';
import GlimpseLogo from '../../assets/glimpse-logo.png';
import HomeIcon from '../../assets/home-icon.png';
import HomeSelectedIcon from '../../assets/home-selected-icon.png';
import DiscoverIcon from '../../assets/discover-icon.png';
import DiscoverSelectedIcon from '../../assets/discover-selected-icon.png';
import SearchIcon from '../../assets/search-icon2.png';
import SearchSelectedIcon from '../../assets/search-selected-icon.png';
import BagIcon from '../../assets/watchlist-gray-icon.png';
import BagSelectedIcon from '../../assets/watchlist-selected-icon.png';
import PlusIcon from '../../assets/create-plus-icon.png';
import UpIcon from '../../assets/up-icon.png';
import DownIcon from '../../assets/down-icon.png';
import RequestNftIcon from '../../assets/request-nft-icon.png';
import RequestNftSelectedIcon from '../../assets/request-nft-selected-icon.png';
import SettingsIcon from '../../assets/settings-icon.png';
import SettingsSelectedIcon from '../../assets/settings-selected-icon.png';
import CollaboratorRequestsIcon from '../../assets/collaborator-requests-icon.png';
import CollaboratorRequestsSelectedIcon from '../../assets/collaborator-requests-selected-icon.png';
import LogoutIcon from '../../assets/logout-icon.png';
import OffersIcon from '../../assets/offers-icon.png';
import OffersSelectedIcon from '../../assets/offers-selected-icon.png';
import BecomeCreatorIcon from '../../assets/become-creator-icon.png';
import BecomeCreatorSelectedIcon from '../../assets/become-creator-selected-icon.png';
import FeedbackIcon from '../../assets/feedback-icon.png';
import FeedbackSelectedIcon from '../../assets/feedback-selected-icon.png';
import GlimpseCoinIcon from '../../assets/g-coin.png';
import GoBackIcon from '../../assets/go-back-icon.png';
import CommunitiesIcon from '../../assets/communities-sidebar.png';
import CommunitiesSelectedIcon from '../../assets/communities-selected-icon.png';
import { limitString } from '../../helpers/helpers';
import { SocketContext } from '../../context/socket-context';
import { LEAVE_ROOM, OFFLINE } from '../../constants/SOCKET_EVENTS';
import SettingsModal from '../SettingsModal/SettingsModal';
import { useQuery } from 'react-query';
import CreateNftsOptionsModal from '../CreateNftsOptionsModal/CreateNftsOptionsModal';
import SearchSidebar from '../SearchSidebar/SearchSidebar';
import GroupsAsAdmin from '../../pages/Groups/components/GroupsSidebar/components/GroupsAsAdmin/GroupsAsAdmin';
import GroupsIHaveJoined from '../../pages/Groups/components/GroupsSidebar/components/GroupsIHaveJoined/GroupsIHaveJoined';
import SearchInput from '../SearchInput/SearchInput';
import Button from '../Button/Button';
import StripeAccount from '../StripeAccount/StripeAccount';
import BuyPointsModal from '../BuyPointsModal/BuyPointsModal';
import ShareLinkIcon from '../../assets/share-link-icon.png';
import notify from '../../utils/notify';
import GetStartedIcon from '../../assets/get-started-icon.png';
import { CoinMarketCapContext } from '../../context/coinmarketcap-context';
import RequestedServicesIcon from '../../assets/bag-icon.png';
import RequestedServicesSelectedIcon from '../../assets/bag-selected-icon.png';
import BuyGlmsModal from '../../pages/Wallet/components/BuyGlmsModal/BuyGlmsModal';

function UserSidebar(props) {
  const location = useLocation();
  const history = useHistory();
  const { socket, setJoinedUserId } = useContext(SocketContext);
  const { glmsToUsd } = useContext(CoinMarketCapContext);
  const [selectedLink, setSelectedLink] = useState(props.selectedLink);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showCreateNftsOptionsModal, setShowCreateNftsOptionsModal] = useState(
    false
  );
  const [isUpIconSelected, setIsUpIconSelected] = useState(true);
  const [closedMode, setClosedMode] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [recentGroupActivityMode, setRecentGroupActivityMode] = useState(false);
  const [groupSearchValue, setGroupSearchValue] = useState('');
  const [showBuyPointsModal, setShowBuyPointsModal] = useState(false);
  const [showBuyGlmsModal, setShowBuyGlmsModal] = useState(false);

  const {
    profile,
    myWalletInformation,
    numberOfUnreadNotifications,
    numberOfUnreadMessagesForMyChats,
    numberOfUnreadGroupMessagesForMyGroups,
    logout,
  } = useContext(AuthContext);

  const myNumberOfFollowersAndFollowingsResponse = useQuery(
    ['my-number-of-followers-and-followings'],
    () => getUsersNumberOfFollowersAndFollowingsRequest(profile?._id)
  );

  const myNumberOfFollowers = myNumberOfFollowersAndFollowingsResponse.data
    ? myNumberOfFollowersAndFollowingsResponse.data.numberOfFollowers
    : 0;
  const myNumberOfFollowings = myNumberOfFollowersAndFollowingsResponse.data
    ? myNumberOfFollowersAndFollowingsResponse.data.numberOfFollowings
    : 0;

  useEffect(() => {
    const setSelectedLinkHandler = () => {
      const pathname = location.pathname.substring(1);

      if (
        pathname === 'my-space' ||
        pathname === 'explore' ||
        pathname === 'watchlist' ||
        pathname === 'home' ||
        pathname === 'collections' ||
        pathname === 'referrals' ||
        pathname === 'discover-friends' ||
        pathname === 'notifications' ||
        pathname === 'chats' ||
        pathname === 'get-started'
      ) {
        setSelectedLink(pathname);
      }

      if (
        pathname === 'groups' ||
        pathname.startsWith('groups') ||
        pathname.startsWith('my-groups')
      ) {
        setClosedMode(true);
        setRecentGroupActivityMode(true);
      }
    };

    setSelectedLinkHandler();
  }, [location]);

  let glimpseBalance = '--';
  let usdBalance = '--';
  if (myWalletInformation?.glimpseBalance > 1_000_000) {
    glimpseBalance = (
      parseFloat(myWalletInformation?.glimpseBalance) / 1000000
    ).toFixed(2);
    glimpseBalance = glimpseBalance + 'M';
  } else if (myWalletInformation?.glimpseBalance < 1000) {
    glimpseBalance = parseFloat(myWalletInformation?.glimpseBalance).toFixed(2);
  } else if (myWalletInformation?.glimpseBalance > 1000) {
    glimpseBalance = (
      parseFloat(myWalletInformation?.glimpseBalance) / 1000
    ).toFixed(2);
    glimpseBalance = glimpseBalance + 'K';
  }

  if (myWalletInformation?.glimpseBalance) {
    usdBalance = myWalletInformation.glimpseBalance * glmsToUsd.price;

    if (usdBalance > 1_000_000) {
      usdBalance = (parseFloat(usdBalance) / 1000000).toFixed(2);
      usdBalance += 'M';
    } else if (usdBalance > 1000) {
      usdBalance = (parseFloat(usdBalance) / 1000).toFixed(2);
      usdBalance += 'K';
    } else if (usdBalance < 1000) {
      usdBalance = parseFloat(usdBalance).toFixed(2);
    }
  }

  const logOutHandler = () => {
    socket.emit(LEAVE_ROOM, profile?._id);
    socket.emit(OFFLINE, profile?._id);
    setJoinedUserId(false);
    logout();
    history.push('/');
  };

  const openSettingsModalHandler = () => {
    setShowSettingsModal(true);
  };

  const openCreateNftsOptionsModalHandler = () => {
    setShowCreateNftsOptionsModal(true);
  };

  const openBuyPointsModalHandler = () => {
    setShowBuyPointsModal(true);
  };

  const closeSearchHandler = () => {
    setSearchMode(false);
    setClosedMode(false);
  };

  const closeCommunitiesTabHandler = () => {
    setRecentGroupActivityMode(false);
    setClosedMode(false);
  };

  const copyReferralLinkHandler = async () => {
    const referralLink = `${window.location.origin}/register?ref=${profile.username}`;
    await navigator.clipboard.writeText(referralLink);

    notify('success', 'Referral Link copied successfully', 2000);
  };

  const copyProfileLinkHandler = async () => {
    const profileLink = `${window.location.origin}/user-feed/${profile._id}`;
    await navigator.clipboard.writeText(profileLink);

    notify('success', 'Profile Link copied successfully', 2000);
  };

  const renderSecondPartOfNavigation = () => {
    return (
      <nav className={classes['nav']}>
        <li
          className={
            selectedLink === 'request-nft'
              ? classes['selected-link']
              : classes['request-nft-link']
          }
        >
          <div className={classes['home-icon-container']}>
            <img
              className={classes['home-icon']}
              src={
                selectedLink === 'request-nft'
                  ? RequestNftSelectedIcon
                  : RequestNftIcon
              }
              width={18}
              alt="Requested Collectibles"
            />
          </div>

          <Link to={'/requested-nfts'}>
            <p
              className={`${classes['home']} ${
                selectedLink === 'request-nft' && classes['selected-text']
              }`}
            >
              Requested Collectibles
            </p>
          </Link>
        </li>

        <li
          className={
            selectedLink === 'requested-services'
              ? classes['selected-link']
              : classes['request-nft-link']
          }
        >
          <div className={classes['home-icon-container']}>
            <img
              className={classes['home-icon']}
              src={
                selectedLink === 'requested-services'
                  ? RequestedServicesSelectedIcon
                  : RequestedServicesIcon
              }
              width={18}
              alt="Requested Services"
            />
          </div>

          <Link to={'/requested-services'}>
            <p
              className={`${classes['home']} ${
                selectedLink === 'requested-services' &&
                classes['selected-text']
              }`}
            >
              Requested Services
            </p>
          </Link>
        </li>

        <li
          className={
            selectedLink === 'collaborator-requests'
              ? classes['selected-link']
              : classes['collaborator-requests-link']
          }
        >
          <div className={classes['home-icon-container']}>
            <img
              className={classes['home-icon']}
              src={
                selectedLink === 'collaborator-requests'
                  ? CollaboratorRequestsSelectedIcon
                  : CollaboratorRequestsIcon
              }
              width={18}
              alt="Collaborator Requests"
            />
          </div>

          <Link to={'/collaborator-requests'}>
            <p
              className={`${classes['home']} ${
                selectedLink === 'collaborator-requests' &&
                classes['selected-text']
              }`}
            >
              Collaborator Requests
            </p>
          </Link>
        </li>

        <li
          className={
            selectedLink === 'offers'
              ? classes['selected-link']
              : classes['offers-link']
          }
        >
          <div className={classes['home-icon-container']}>
            <img
              className={classes['home-icon']}
              src={selectedLink === 'offers' ? OffersSelectedIcon : OffersIcon}
              width={18}
              alt="Offers"
            />
          </div>

          <Link to={'/nft-offers'}>
            <p
              className={`${classes['home']} ${
                selectedLink === 'offers' && classes['selected-text']
              }`}
            >
              Offers
            </p>
          </Link>
        </li>

        <div className={classes['black-horizontal-line']}></div>

        <li
          className={
            selectedLink === 'become-creator'
              ? classes['selected-link']
              : classes['become-creator-link']
          }
          onClick={() => history.push('/apply-as-creator')}
        >
          <div className={classes['home-icon-container']}>
            <img
              className={classes['home-icon']}
              src={
                selectedLink === 'become-creator'
                  ? BecomeCreatorSelectedIcon
                  : BecomeCreatorIcon
              }
              width={18}
              alt="Become Creator"
            />
          </div>

          <p
            className={`${classes['home']} ${
              selectedLink === 'become-creator' && classes['selected-text']
            }`}
          >
            Become Creator
          </p>
        </li>

        {profile.accessToCreateStripeAccount && (
          <div className={classes['stripe-account-container']}>
            <StripeAccount />
          </div>
        )}

        <div className={classes['black-horizontal-line']}></div>

        <li
          className={
            selectedLink === 'feedback'
              ? classes['selected-link']
              : classes['feedback-link']
          }
          onClick={() =>
            window.open(
              'https://docs.google.com/forms/d/e/1FAIpQLSdipS3hQxGV4hzfYRQ8aIEpqX2_gYz1dOkYvN4dm5i6ubpTbA/viewform',
              '_blank'
            )
          }
        >
          <div className={classes['home-icon-container']}>
            <img
              className={classes['home-icon']}
              src={
                selectedLink === 'feedback'
                  ? FeedbackSelectedIcon
                  : FeedbackIcon
              }
              width={18}
              alt="Feedback"
            />
          </div>

          <p
            className={`${classes['home']} ${
              selectedLink === 'feedback' && classes['selected-text']
            }`}
          >
            Feedback
          </p>
        </li>

        <li
          className={
            selectedLink === 'settings'
              ? classes['selected-link']
              : classes['settings-link']
          }
          onClick={openSettingsModalHandler}
        >
          <div className={classes['home-icon-container']}>
            <img
              className={classes['home-icon']}
              src={
                selectedLink === 'settings'
                  ? SettingsSelectedIcon
                  : SettingsIcon
              }
              width={18}
              alt="Settings"
            />
          </div>

          <p
            className={`${classes['home']} ${
              selectedLink === 'settings' && classes['selected-text']
            }`}
          >
            Settings
          </p>
        </li>
        {/* 
        <li
          className={
            selectedLink === 'help'
              ? classes['selected-link']
              : classes['help-link']
          }
        >
          <div className={classes['home-icon-container']}>
            <img
              className={classes['home-icon']}
              src={selectedLink === 'help' ? HelpSelectedIcon : HelpIcon}
              width={18}
              alt="Help"
            />
          </div>

          <p
            className={`${classes['home']} ${
              selectedLink === 'help' && classes['selected-text']
            }`}
          >
            Help
          </p>
        </li> */}

        <li
          className={
            selectedLink === 'logout'
              ? classes['selected-link']
              : classes['logout-link']
          }
          onClick={logOutHandler}
        >
          <div className={classes['home-icon-container']}>
            <img
              className={classes['home-icon']}
              src={LogoutIcon}
              width={18}
              alt="Logout"
            />
          </div>

          <p
            className={`${classes['home']} ${
              selectedLink === 'logout' && classes['selected-text']
            }`}
          >
            Logout
          </p>
        </li>
        <div className={classes['black-horizontal-line']}></div>
      </nav>
    );
  };

  const renderProfileSection = () => {
    if (isUpIconSelected) {
      return (
        <div className={classes['my-profile-section']}>
          <div className={classes['user-image-container']}>
            <Link to="/my-space">
              <img
                alt={profile?.fullName}
                className={classes['user-image']}
                src={profile?.photo}
              />
            </Link>
          </div>
          <div className={classes['firstname-verified-container']}>
            <p
              title={`${profile.firstName} ${profile.lastName}`}
              onClick={() => {
                setSelectedLink('my-space');
                history.push('/my-space');
              }}
              className={classes['hello-text']}
            >
              {limitString(`${profile.firstName} ${profile.lastName}`, 15)}
            </p>

            {profile?.verified && (
              <img
                src={PinkVerifiedIcon}
                alt="Verified"
                className={classes['verified-icon']}
              />
            )}
          </div>{' '}
          <img
            src={isUpIconSelected ? UpIcon : DownIcon}
            alt="Show"
            className={classes['up-down-icon']}
            onClick={() => setIsUpIconSelected((prevState) => !prevState)}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className={classes['my-profile-section']}>
            <div className={classes['user-image-container']}>
              <Link to="/my-space">
                <img
                  alt={profile?.fullName}
                  className={classes['user-image']}
                  src={profile?.photo}
                />
              </Link>
            </div>
            <div
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              className={classes['firstname-verified-container']}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <p
                  onClick={() => {
                    setSelectedLink('my-space');
                    history.push('/my-space');
                  }}
                  className={classes['hello-text']}
                >
                  {limitString(`${profile.firstName} ${profile.lastName}`, 25)}
                </p>

                {profile?.verified && (
                  <img
                    src={PinkVerifiedIcon}
                    alt="Verified"
                    className={classes['verified-icon']}
                  />
                )}
              </div>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  color: '#A8ADAF',
                  fontSize: '12px',
                }}
              >
                @{profile.username}
              </p>
            </div>
            <img
              src={isUpIconSelected ? UpIcon : DownIcon}
              alt="Show"
              className={classes['up-down-icon']}
              onClick={() => setIsUpIconSelected((prevState) => !prevState)}
            />
          </div>

          <div className={classes['number-of-followers-followings']}>
            <p className={classes['number-of-followers']}>
              <span className={classes['number-of-followers-value']}>
                {myNumberOfFollowers}
              </span>
              <span className={classes['followers-text']}>Followers</span>
            </p>
            <p className={classes['number-of-followings']}>
              <span className={classes['number-of-followings-value']}>
                {myNumberOfFollowings}
              </span>
              <span className={classes['following-text']}>Following</span>
            </p>
          </div>
        </div>
      );
    }
  };

  if (!profile) return null;

  return (
    <>
      {showSettingsModal && (
        <SettingsModal setShowSettingsModal={setShowSettingsModal} />
      )}

      {showCreateNftsOptionsModal && (
        <CreateNftsOptionsModal
          setShowCreateNftsOptionsModal={setShowCreateNftsOptionsModal}
        />
      )}

      {showBuyPointsModal && (
        <BuyPointsModal setShowBuyPointsModal={setShowBuyPointsModal} />
      )}

      {showBuyGlmsModal && (
        <BuyGlmsModal setShowBuyGlmsModal={setShowBuyGlmsModal} />
      )}

      {!closedMode ? (
        <div className={classes['sidebar-container']}>
          <div className={classes['sidebar']}>
            <div className={classes['glimpse-logo-container']}>
              <img
                src={GlimpseLogo}
                alt="Glimpse"
                className={classes['glimpse-logo']}
              />
            </div>

            <div className={classes['black-horizontal-line']}></div>

            {profile && renderProfileSection()}

            <div className={classes['black-horizontal-line']}></div>

            {isUpIconSelected && (
              <nav className={classes['nav']}>
                <li
                  onClick={() => {
                    setSelectedLink('home');
                    history.push('/news-feed');
                  }}
                  className={
                    selectedLink === 'home'
                      ? classes['selected-link']
                      : classes['home-link']
                  }
                >
                  <div className={classes['home-icon-container']}>
                    <img
                      className={classes['home-icon']}
                      src={
                        selectedLink === 'home' ? HomeSelectedIcon : HomeIcon
                      }
                      width={18}
                      alt="Home"
                    />
                  </div>

                  <p
                    className={`${classes['home']} ${
                      selectedLink === 'home' && classes['selected-text']
                    }`}
                  >
                    Home
                  </p>
                </li>

                <li
                  onClick={() => {
                    setSelectedLink('explore');
                    history.push('/explore');
                  }}
                  className={
                    selectedLink === 'explore'
                      ? classes['selected-link']
                      : classes['marketplace-link']
                  }
                >
                  <div className={classes['marketplace-icon-container']}>
                    <img
                      className={classes['marketplace-icon']}
                      src={
                        selectedLink === 'explore'
                          ? DiscoverSelectedIcon
                          : DiscoverIcon
                      }
                      width={18}
                      alt="Explore"
                    />
                  </div>

                  <p
                    className={`${classes['marketplace']} ${
                      selectedLink === 'explore' && classes['selected-text']
                    }`}
                  >
                    Explore
                  </p>
                </li>

                <li
                  onClick={() => {
                    setSelectedLink('groups');
                    history.push('/groups');
                  }}
                  className={
                    selectedLink === 'groups'
                      ? classes['selected-link']
                      : classes['marketplace-link']
                  }
                >
                  <div className={classes['marketplace-icon-container']}>
                    <img
                      className={classes['marketplace-icon']}
                      src={
                        recentGroupActivityMode
                          ? CommunitiesSelectedIcon
                          : CommunitiesIcon
                      }
                      width={18}
                      alt="Marketplace"
                    />
                  </div>

                  <p
                    className={`${classes['marketplace']} ${
                      selectedLink === 'groups' && classes['selected-text']
                    }`}
                  >
                    Communities
                  </p>
                </li>

                <li
                  onClick={() => {
                    setSelectedLink('collections');
                    setClosedMode(true);
                    setSearchMode(true);
                  }}
                  className={
                    selectedLink === 'collections'
                      ? classes['selected-link']
                      : classes['marketplace-link']
                  }
                >
                  <div className={classes['marketplace-icon-container']}>
                    <img
                      className={classes['marketplace-icon']}
                      src={
                        selectedLink === 'collections'
                          ? SearchSelectedIcon
                          : SearchIcon
                      }
                      width={18}
                      alt="Marketplace"
                    />
                  </div>

                  <p
                    className={`${classes['marketplace']} ${
                      selectedLink === 'collections' && classes['selected-text']
                    }`}
                  >
                    Search
                  </p>
                </li>

                <li
                  onClick={() => {
                    setSelectedLink('chats');
                    history.push('/chats');
                  }}
                  className={
                    selectedLink === 'chats'
                      ? classes['selected-link']
                      : classes['marketplace-link']
                  }
                >
                  <div className={classes['marketplace-icon-container']}>
                    <img
                      className={classes['marketplace-icon']}
                      src={
                        selectedLink === 'chats'
                          ? MessagesSelectedIcon
                          : MessagesIcon
                      }
                      width={18}
                      alt="Marketplace"
                    />
                  </div>
                  <div className={classes['messages-container']}>
                    <p
                      className={`${classes['marketplace']} ${
                        selectedLink === 'chats' && classes['selected-text']
                      }`}
                    >
                      Messages
                    </p>

                    {numberOfUnreadMessagesForMyChats +
                      numberOfUnreadGroupMessagesForMyGroups >
                      0 && (
                      <div
                        className={
                          classes['number-of-unread-messages-container']
                        }
                      >
                        <p className={classes['number-of-messages-text']}>
                          {numberOfUnreadMessagesForMyChats +
                            numberOfUnreadGroupMessagesForMyGroups}
                        </p>
                      </div>
                    )}
                  </div>{' '}
                </li>

                <li
                  onClick={() => {
                    setSelectedLink('notifications');
                    history.push('/notifications');
                  }}
                  className={
                    selectedLink === 'notifications'
                      ? classes['selected-link']
                      : classes['marketplace-link']
                  }
                >
                  <div className={classes['marketplace-icon-container']}>
                    <img
                      className={classes['marketplace-icon']}
                      src={
                        selectedLink === 'notifications'
                          ? NotificationsSelectedIcon
                          : NotificationIcon
                      }
                      width={18}
                      alt="Marketplace"
                    />
                  </div>

                  <div className={classes['notifications-container']}>
                    <p
                      className={`${classes['marketplace']} ${
                        selectedLink === 'notifications' &&
                        classes['selected-text']
                      }`}
                    >
                      Notifications
                    </p>

                    {numberOfUnreadNotifications > 0 && (
                      <div
                        className={
                          classes['number-of-unread-notifications-container']
                        }
                      >
                        <p
                          className={
                            classes['number-of-unread-notifications-text']
                          }
                        >
                          {numberOfUnreadNotifications}
                        </p>
                      </div>
                    )}
                  </div>
                </li>

                {profile && (
                  <li
                    onClick={() => {
                      setSelectedLink('watchlist');
                      history.push('/watchlist');
                    }}
                    className={
                      selectedLink === 'watchlist'
                        ? classes['selected-link']
                        : classes['cart-link']
                    }
                  >
                    <div className={classes['cart-icon-container']}>
                      <img
                        className={classes['cart-icon']}
                        src={
                          selectedLink === 'watchlist'
                            ? BagSelectedIcon
                            : BagIcon
                        }
                        height={18}
                        alt="Watchlist"
                      />
                    </div>

                    <p
                      className={`${classes['cart']} ${
                        selectedLink === 'watchlist' && classes['selected-text']
                      }`}
                    >
                      Watchlist
                    </p>
                  </li>
                )}

                {profile && (
                  <li
                    onClick={() => {
                      setSelectedLink('referrals');
                      history.push('/referrals');
                    }}
                    className={
                      selectedLink === 'referrals'
                        ? classes['selected-link']
                        : classes['network-link']
                    }
                  >
                    <div className={classes['network-icon-container']}>
                      <img
                        className={classes['network-icon']}
                        src={
                          selectedLink === 'referrals'
                            ? ReferralSelectedIcon
                            : ReferralIcon
                        }
                        height={20}
                        alt="Referrals Network"
                      />
                    </div>

                    <p
                      className={`${classes['network']}  ${
                        selectedLink === 'referrals' && classes['selected-text']
                      }`}
                    >
                      Referrals
                    </p>
                  </li>
                )}

                {profile && (
                  <li
                    onClick={() => {
                      setSelectedLink('get-started');
                      history.push('/get-started');
                    }}
                    className={
                      selectedLink === 'get-started'
                        ? classes['selected-link']
                        : classes['get-started-link']
                    }
                  >
                    <div className={classes['get-started-icon-container']}>
                      <img
                        className={classes['get-started-icon']}
                        src={GetStartedIcon}
                        height={20}
                        alt="Get Started"
                      />
                    </div>

                    <p
                      className={`${classes['get-started']}  ${
                        selectedLink === 'get-started' &&
                        classes['selected-text']
                      }`}
                    >
                      Get Started
                    </p>
                  </li>
                )}
              </nav>
            )}

            {!isUpIconSelected && renderSecondPartOfNavigation()}
          </div>
          {isUpIconSelected && (
            <div
              onClick={openCreateNftsOptionsModalHandler}
              className={classes['create-collectible-container']}
            >
              <img src={PlusIcon} alt="Plus" className={classes['plus-icon']} />
              <p className={classes['create-collectible-text']}>Create</p>
            </div>
          )}

          <div className={classes['referral-profile-link-container']}>
            <p
              onClick={copyReferralLinkHandler}
              className={classes['referral-link-text']}
            >
              Referral Link
              <img
                className={classes['share-link-icon']}
                src={ShareLinkIcon}
                alt="Share"
              />
            </p>
            <p
              onClick={copyProfileLinkHandler}
              className={classes['profile-link-text']}
            >
              Profile Link
              <img
                className={classes['share-link-icon']}
                src={ShareLinkIcon}
                alt="Share"
              />
            </p>
          </div>

          <div className={classes['balance-points-container']}>
            {/* <Link to={'/wallet'}> */}
            <div
              onClick={() => setShowBuyGlmsModal(true)}
              className={classes['balance-container']}
            >
              <div className={classes['vertical-line-container']}></div>
              <div className={classes['wallet-balance']}>
                <img height={14} src={WhiteWalletIcon} alt="Wallet" />
                <p className={classes['balance-text']}>BALANCE</p>
              </div>
              {/* 
                <h3 className={classes['glimpse-balance']}>
                  {glimpseBalance} GLMS
                </h3> */}
              <h3 className={classes['glimpse-balance']}>{usdBalance} USD</h3>
            </div>
            {/* </Link> */}

            <div
              onClick={openBuyPointsModalHandler}
              className={classes['points-container']}
            >
              <div className={classes['points']}>
                <img height={14} src={DiamondIcon} alt="Points" />

                <p className={classes['points-text']}>Diamonds</p>
              </div>
              <h3 className={classes['total-points']}>
                {parseFloat(profile?.winningPoints).toFixed(2)}
              </h3>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes['closed-sidebar-search-container']}>
          <div className={classes['closed-sidebar-container']}>
            <img
              src={GlimpseCoinIcon}
              alt="Glimpse"
              className={classes['glimpse-coin-icon']}
            />
            <div className={classes['black-horizontal-line']}></div>

            <Link to="/my-space">
              <img
                src={profile?.photo}
                alt={profile?.fullName}
                className={classes['user-image']}
              />
            </Link>

            <div className={classes['black-horizontal-line']}></div>

            <nav className={classes['closed-nav']}>
              <li
                onClick={() => {
                  setSelectedLink('home');
                  history.push('/news-feed');
                }}
                className={
                  selectedLink === 'home'
                    ? classes['selected-link']
                    : classes['home-link']
                }
              >
                <div className={classes['home-icon-container']}>
                  <img
                    className={classes['home-icon']}
                    src={selectedLink === 'home' ? HomeSelectedIcon : HomeIcon}
                    width={18}
                    alt="Home"
                  />
                </div>
              </li>

              <li
                onClick={() => {
                  setSelectedLink('explore');
                  history.push('/explore');
                }}
                className={
                  selectedLink === 'explore'
                    ? classes['selected-link']
                    : classes['marketplace-link']
                }
              >
                <div className={classes['marketplace-icon-container']}>
                  <img
                    className={classes['marketplace-icon']}
                    src={
                      selectedLink === 'explore'
                        ? DiscoverSelectedIcon
                        : DiscoverIcon
                    }
                    width={18}
                    alt="Explore"
                  />
                </div>
              </li>

              <li
                onClick={() => {
                  setSelectedLink('groups');
                  history.push('/groups');
                }}
                className={
                  selectedLink === 'groups'
                    ? classes['selected-link']
                    : classes['marketplace-link']
                }
              >
                <div className={classes['marketplace-icon-container']}>
                  <img
                    className={classes['marketplace-icon']}
                    src={
                      recentGroupActivityMode
                        ? CommunitiesSelectedIcon
                        : CommunitiesIcon
                    }
                    width={18}
                    alt="Marketplace"
                  />
                </div>
              </li>

              <li
                className={
                  selectedLink === 'collections'
                    ? classes['selected-link']
                    : classes['marketplace-link']
                }
              >
                <div className={classes['marketplace-icon-container']}>
                  <img
                    className={classes['marketplace-icon']}
                    src={
                      selectedLink === 'collections'
                        ? SearchSelectedIcon
                        : SearchIcon
                    }
                    width={18}
                    alt="Marketplace"
                  />
                </div>
              </li>

              <li
                onClick={() => {
                  setSelectedLink('chats');
                  history.push('/chats');
                }}
                className={
                  selectedLink === 'chats'
                    ? classes['selected-link']
                    : classes['marketplace-link']
                }
              >
                <div className={classes['marketplace-icon-container']}>
                  <img
                    className={classes['marketplace-icon']}
                    src={
                      selectedLink === 'chats'
                        ? MessagesSelectedIcon
                        : MessagesIcon
                    }
                    width={18}
                    alt="Marketplace"
                  />
                </div>
                {/* <div className={classes['messages-container']}>
                <p className={classes['marketplace']}>Messages</p>

                {numberOfUnreadMessagesForMyChats +
                  numberOfUnreadGroupMessagesForMyGroups >
                  0 && (
                  <div
                    className={classes['number-of-unread-messages-container']}
                  >
                    <p className={classes['number-of-messages-text']}>
                      {numberOfUnreadMessagesForMyChats +
                        numberOfUnreadGroupMessagesForMyGroups}
                    </p>
                  </div>
                )}
              </div>{' '} */}
              </li>

              <li
                onClick={() => {
                  setSelectedLink('notifications');
                  history.push('/notifications');
                }}
                className={
                  selectedLink === 'notifications'
                    ? classes['selected-link']
                    : classes['marketplace-link']
                }
              >
                <div className={classes['marketplace-icon-container']}>
                  <img
                    className={classes['marketplace-icon']}
                    src={
                      selectedLink === 'notifications'
                        ? NotificationsSelectedIcon
                        : NotificationIcon
                    }
                    width={18}
                    alt="Marketplace"
                  />
                </div>

                {/* <div className={classes['notifications-container']}>
                <p
                  className={`${classes['marketplace']} ${
                    selectedLink === 'notifications' && classes['selected-text']
                  }`}
                >
                  Notifications
                </p>

                {numberOfUnreadNotifications > 0 && (
                  <div
                    className={
                      classes['number-of-unread-notifications-container']
                    }
                  >
                    <p
                      className={classes['number-of-unread-notifications-text']}
                    >
                      {numberOfUnreadNotifications}
                    </p>
                  </div>
                )}
              </div> */}
              </li>

              {profile && (
                <li
                  onClick={() => {
                    setSelectedLink('watchlist');
                    history.push('/watchlist');
                  }}
                  className={
                    selectedLink === 'watchlist'
                      ? classes['selected-link']
                      : classes['cart-link']
                  }
                >
                  <div className={classes['cart-icon-container']}>
                    <img
                      className={classes['cart-icon']}
                      src={
                        selectedLink === 'watchlist' ? BagSelectedIcon : BagIcon
                      }
                      height={20}
                      alt="Watchlist"
                    />
                  </div>
                </li>
              )}

              {profile && (
                <li
                  onClick={() => {
                    setSelectedLink('referrals');
                    history.push('/referrals');
                  }}
                  className={
                    selectedLink === 'referrals'
                      ? classes['selected-link']
                      : classes['network-link']
                  }
                >
                  <div className={classes['network-icon-container']}>
                    <img
                      className={classes['network-icon']}
                      src={
                        selectedLink === 'referrals'
                          ? ReferralSelectedIcon
                          : ReferralIcon
                      }
                      height={20}
                      alt="Referrals Network"
                    />
                  </div>
                </li>
              )}

              <div
                onClick={openCreateNftsOptionsModalHandler}
                className={classes['closed-create-collectible-container']}
              >
                <img
                  className={classes['closed-plus-icon']}
                  src={PlusIcon}
                  alt="Plus"
                />
              </div>

              <div className={classes['closed-balance-points-container']}>
                <Link to={'/wallet'}>
                  <div className={classes['balance-container']}>
                    <div className={classes['vertical-line-container']}></div>
                    <div className={classes['wallet-balance']}>
                      <img height={18} src={WhiteWalletIcon} alt="Wallet" />
                    </div>
                  </div>
                </Link>

                <div className={classes['points-container']}>
                  <div className={classes['points']}>
                    <img height={18} src={DiamondIcon} alt="Points" />
                  </div>
                </div>
              </div>
            </nav>
          </div>

          {searchMode && (
            <div className={classes['search-container']}>
              <div
                onClick={closeSearchHandler}
                className={classes['search-header']}
              >
                <img
                  src={GoBackIcon}
                  alt="Back"
                  className={classes['go-back-icon']}
                />
                <h3 className={classes['search-text']}>Search</h3>
              </div>

              <div className={classes['black-horizontal-line']}></div>

              <div>
                <SearchSidebar />
              </div>
            </div>
          )}

          {recentGroupActivityMode && (
            <div className={classes['group-activity-container']}>
              <div className={classes['groups-sidebar']}>
                <div
                  onClick={closeCommunitiesTabHandler}
                  className={classes['groups-text-header']}
                >
                  <img
                    src={GoBackIcon}
                    alt="Back"
                    className={classes['go-back-icon']}
                  />
                  <h3 className={classes['groups-text']}>Communities</h3>
                </div>
                <div className={classes['black-horizontal-line']}></div>
                <div className={classes['groups-header']}>
                  <div className={classes['input-container']}>
                    <SearchInput
                      style={{ width: 190 }}
                      placeholder="Search Group"
                      value={groupSearchValue}
                      onChange={(e) => setGroupSearchValue(e.target.value)}
                    />
                  </div>

                  <Link to="/create-group">
                    <Button
                      style={{ width: 190, margin: '20px 0 10px 0' }}
                      darkpink="true"
                    >
                      Create Group
                    </Button>
                  </Link>
                </div>
                <div className={classes['black-horizontal-line']}></div>

                <div className={classes['groups-content']}>
                  <GroupsAsAdmin
                    style={{ width: 195 }}
                    searchValue={groupSearchValue}
                  />
                  <p style={{ marginTop: 0 }}>&nbsp;</p>

                  <div className={classes['black-horizontal-line']}></div>

                  <GroupsIHaveJoined
                    style={{ width: 195 }}
                    searchValue={groupSearchValue}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default UserSidebar;
