import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getUserRecentlyAddedNftsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './RecentlyAddedNfts.module.css';
import { IPFS_URL } from '../../../../constants/IPFS';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { getFormattedNumber } from '../../../../helpers/helpers';
import { CoinMarketCapContext } from '../../../../context/coinmarketcap-context';

function RecentlyAddedNfts(props) {
  const { userProfile, setSelectedSection } = props;
  const { glmsToUsd } = useContext(CoinMarketCapContext);
  const { data, error } = useQuery(
    ['recently-added-nfts', userProfile._id],
    () => getUserRecentlyAddedNftsRequest(userProfile._id)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const goToListingsSectionHandler = () => {
    setSelectedSection('Listings');
  };

  return (
    <div className={classes['user-recent-nfts-container']}>
      <div className={classes['recent-nfts-view-all']}>
        <h3 className={classes['user-recent-nfts-text']}>
          Recent Collectibles
        </h3>

        <div
          onClick={goToListingsSectionHandler}
          className={classes['see-all-container']}
        >
          <p>See All</p>
        </div>
      </div>

      <div className={classes['user-recent-nfts']}>
        {data && data.data.length === 0 && (
          <p className={classes['no-recent-nfts-text']}>
            No Recent Collectibles
          </p>
        )}

        {data &&
          data.data.map((nft, i) => {
            let style = {};
            if (i === 2) {
              style = {
                borderBottomLeftRadius: 10,
              };
            } else if (i === 3) {
              style = {
                borderBottomRightRadius: 10,
              };
            }

            return (
              <div key={nft._id} className={classes['recent-sale']}>
                <Link to={`/nfts/${nft._id}`}>
                  {nft.type === 'image' ? (
                    <img
                      style={style}
                      alt="IPFS Hash"
                      className={classes['nft-image']}
                      src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
                      width={150}
                      height={150}
                    />
                  ) : (
                    <ReactPlayer
                      style={{
                        objectFit: 'cover',
                        maxWidth: 183,
                        maxHeight: 183,
                        ...style,
                      }}
                      width={150}
                      height={150}
                      url={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
                    />
                  )}
                </Link>

                {nft.listedForBuyNow && (
                  <div className={classes['buy-now-price-container']}>
                    <p className={classes['buy-now-price']}>
                      $
                      {getFormattedNumber(
                        (nft.buyNowPrice * glmsToUsd.price).toFixed(2)
                      )}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default RecentlyAddedNfts;
