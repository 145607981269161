import React, { useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getAllGroupChannelsForSpecificGroupRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import classes from './Channels.module.css';
import CreateGroupChannelModal from '../CreateGroupChannelModal/CreateGroupChannelModal';
import PrivateIcon from '../../../../../../assets/private-1.png';
import { limitString } from '../../../../../../helpers/helpers';
import PlusIcon from '../../../../../../assets/blue-plus.png';

function Channels(props) {
  const {
    groupId,
    selectedChannelName,
    setSelectedChannelId,
    setSelectedChannelName,
    setIsSelectedChannelPrivate,
    isMyRoleAdmin,
  } = props;

  const [
    showCreateGroupChannelModal,
    setShowCreateGroupChannelModal,
  ] = useState(false);

  const { data, error } = useQuery(['group-channels', groupId], () =>
    getAllGroupChannelsForSpecificGroupRequest(groupId)
  );

  const selectChannelHandler = (channel) => {
    setSelectedChannelName(channel.name);
    setSelectedChannelId(channel._id);
    setIsSelectedChannelPrivate(channel.status === 'Private');
  };

  useEffect(() => {
    if (!data?.data) return;

    const generalChannel = data.data.find(({ name }) => name === 'General');

    if (generalChannel) {
      setSelectedChannelName(generalChannel.name);
      setSelectedChannelId(generalChannel._id);
      setIsSelectedChannelPrivate(generalChannel.status === 'Private');
    }
  }, [
    setSelectedChannelId,
    setIsSelectedChannelPrivate,
    setSelectedChannelName,
    data,
  ]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const openCreateGroupChannelModalHandler = () => {
    setShowCreateGroupChannelModal(true);
  };

  const channels = useMemo(() => {
    if (!data?.data) return [];

    return data.data;
  }, [data]);

  return (
    <>
      {showCreateGroupChannelModal && (
        <CreateGroupChannelModal
          setShowCreateGroupChannelModal={setShowCreateGroupChannelModal}
          groupId={groupId}
        />
      )}

      <div className={classes['left-sidebar-container']}>
        <div className={classes['group-channels-button-container']}>
          <h3 className={classes['group-channels-text']}>Channel</h3>
          {isMyRoleAdmin && (
            <div
              onClick={openCreateGroupChannelModalHandler}
              className={classes['plus-container']}
            >
              <img
                src={PlusIcon}
                alt="Plus"
                width={14}
                className={classes['plus-icon']}
              />
            </div>
          )}
        </div>

        <div className={classes['horizontal-line']}></div>

        <div className={classes['channels']}>
          {channels.map((channel) => {
            const { name } = channel;

            return (
              <p
                title={`#${name}`}
                onClick={() => selectChannelHandler(channel)}
                key={name}
                className={`${classes['channel-name']} ${
                  selectedChannelName === name && classes['selected-channel']
                } `}
              >
                #{limitString(name, 10)}
              </p>
            );
          })}

          {data?.myPrivateGroupChannels &&
            data.myPrivateGroupChannels.map(({ groupChannel }) => {
              if (!groupChannel) return null;
              const { name } = groupChannel;
              return (
                <p
                  key={groupChannel._id}
                  title={`#${name}`}
                  onClick={() => selectChannelHandler(groupChannel)}
                  className={`${classes['channel-name']} ${
                    selectedChannelName === name && classes['selected-channel']
                  } `}
                >
                  #{limitString(name, 10)}
                  <img
                    alt="Private"
                    src={PrivateIcon}
                    className={classes['private-icon']}
                  />
                </p>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default Channels;
