import React from 'react';
import classes from './BuyingMethodModal.module.css';
import Modal from '../../../../components/Modal/Modal';
// import CreditCardIcon from '../../../../assets/credit-card.png';
import notify from '../../../../utils/notify';
import {
  createStripePaymentCheckoutSessionRequest,
  getStripeAccountStatusForSpecificUserRequest,
} from '../../../../httpRequests/httpRequests';
import USDIcon from '../../../../assets/pink-usd-icon.png';
// import { AuthContext } from '../../../../context/auth-context';
import { useQuery } from 'react-query';
import DebitCreditCardIcon from '../../../../assets/debit-credit-card-icon.png';

function BuyingMethodModal(props) {
  // const { profile } = useContext(AuthContext);

  const {
    createCheckoutSessionHandler,
    setShowBuyingMethodModal,
    openBuyNowModalHandler,
    // buyWithWyreHandler,
    nft,
  } = props;

  const stripeOwnerAccountStatus = useQuery(
    ['stripe-account-status', nft.owner._id],
    () => getStripeAccountStatusForSpecificUserRequest(nft.owner._id)
  );

  const closeModalHandler = () => {
    setShowBuyingMethodModal(false);
  };

  const buyWithGLMSHandler = () => {
    openBuyNowModalHandler();
    closeModalHandler();
  };

  const buyWithCreditCardHandler = () => {
    createCheckoutSessionHandler();
    closeModalHandler();
  };

  // what if the author / owner has not completed KYC and they do not have a wyre wallet ???
  // const buyWithWyreWalletHandler = () => {
  //   buyWithWyreHandler();
  //   closeModalHandler();
  // };

  const buyWithStripeConnectHandler = async () => {
    try {
      const requestBody = {
        nftId: nft._id,
      };

      const { sessionUrl } = await createStripePaymentCheckoutSessionRequest(
        requestBody
      );

      window.open(sessionUrl, '_blank');
      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderBuyWithStripeConnect = () => {
    if (
      nft.tokenId ||
      nft.claimable ||
      nft.claimedBy ||
      nft.author._id !== nft.owner._id ||
      stripeOwnerAccountStatus.data.data === false
    )
      return (
        <div
          onClick={buyWithCreditCardHandler}
          className={classes['credit-card-icon-container']}
        >
          <img
            className={classes['credit-card-icon']}
            src={DebitCreditCardIcon}
            alt="Debit/Credit Card"
          />
          <p className={classes['credit-card-text']}>Debit / Credit Card</p>
        </div>
      );

    return (
      <div
        onClick={buyWithStripeConnectHandler}
        className={classes['usd-icon-container']}
      >
        <img
          className={classes['credit-card-icon']}
          src={DebitCreditCardIcon}
          alt="Debit/Credit Card"
        />
        <p className={classes['credit-card-text']}>Debit / Credit Card</p>
      </div>
    );
  };

  return (
    <Modal
      style={{ minWidth: 450, height: 260, minHeight: 260 }}
      closeModal={closeModalHandler}
      title="Buying Method"
      closeIcon="true"
    >
      {stripeOwnerAccountStatus.isLoading ? null : (
        <div className={classes['buying-method-container']}>
          <div
            onClick={buyWithGLMSHandler}
            className={classes['glimpse-icon-container']}
          >
            <img className={classes['glimpse-icon']} src={USDIcon} alt="USD" />
            <p className={classes['glms-text']}>USD</p>
          </div>

          {/* {profile?.role === 'admin' && (
          <div
            onClick={buyWithWyreWalletHandler}
            className={classes['usd-icon-container']}
          >
            <img
              className={classes['credit-card-icon']}
              src={USDIcon}
              alt="USD"
            />
            <p className={classes['credit-card-text']}>USD</p>
          </div>
        )} */}

          {!stripeOwnerAccountStatus.isLoading &&
            stripeOwnerAccountStatus.data !== undefined &&
            renderBuyWithStripeConnect()}

          {/* {profile?.role === 'admin' && (
          <div
            onClick={buyWithWyreWalletHandler}
            className={classes['usd-icon-container']}
          >
            <img
              className={classes['credit-card-icon']}
              src={USDIcon}
              alt="USD"
            />
            <p className={classes['credit-card-text']}>USD</p>
          </div>
        )} */}
        </div>
      )}
    </Modal>
  );
}

export default BuyingMethodModal;
