import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../../../context/auth-context';
import classes from './WhatAreYouThinking.module.css';
import CreatePostModal from './components/CreatePostModal/CreatePostModal';
import PhotoOrVideoIcon from '../../../../../../assets/photo-or-video.png';
import FeelingIcon from '../../../../../../assets/feeling.png';

function WhatAreYouThinking() {
  const { profile } = useContext(AuthContext);
  const [showCreatePostModal, setShowCreatePostModal] = useState(false);

  const openCreatePostModalHandler = () => setShowCreatePostModal(true);

  return (
    <>
      {showCreatePostModal && (
        <CreatePostModal setShowCreatePostModal={setShowCreatePostModal} />
      )}

      <div className={classes['what-are-you-thinking-container']}>
        <div className={classes['user-image-what-are-you-thinking']}>
          <img
            className={classes['user-image']}
            src={profile?.photo}
            alt={`${profile?.fullName}`}
          />
          <p
            onClick={openCreatePostModalHandler}
            className={classes['what-are-you-thinking']}
          >
            What are you thinking, {profile?.firstName} ?
          </p>
        </div>

        <div className={classes['horizontal-line']}>&nbsp;</div>

        <div className={classes['photo-video-feeling-container']}>
          <div
            onClick={openCreatePostModalHandler}
            className={classes['photo-or-video-container']}
          >
            <img
              className={classes['photo-or-video-image']}
              src={PhotoOrVideoIcon}
              alt="PhotoOrVideo"
            />
            <p className={classes['photo-or-video-text']}>Photo / Video</p>
          </div>

          <div
            onClick={openCreatePostModalHandler}
            className={classes['feeling-container']}
          >
            <img
              className={classes['feeling-image']}
              src={FeelingIcon}
              alt="Feeling / Activity"
            />
            <p className={classes['feeling-text']}>Feeling / Activity</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatAreYouThinking;
