import React, { useContext } from 'react';
import classes from './Notification.module.css';
import { useHistory } from 'react-router-dom';
import {
  markNotificationAsReadRequest,
  reactToGroupChannelInvitationRequest,
  reactToGroupInvitationRequest,
  reactToNftCollectionCollaborationInvitationRequest,
} from '../../../../httpRequests/httpRequests';
import { AuthContext } from '../../../../context/auth-context';
import moment from 'moment';
import FailedIcon from '../../../../assets/failed.png';
import ConffetiIcon from '../../../../assets/confetti.png';
import CommentIcon from '../../../../assets/commentIcon.png';
import { limitString } from '../../../../helpers/helpers';
import notify from '../../../../utils/notify';

const GROUP_INVITATION_STATUSES = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
};

function Notification(props) {
  const { notification } = props;
  const { getNumberOfUnreadNotifications } = useContext(AuthContext);
  const history = useHistory();
  const {
    type: notificationType,
    read,
    from,
    entity,
    createdAt,
    extraInfo,
  } = notification;

  const markNotificationAsRead = (goTo) => {
    markNotificationAsReadRequest(notification._id).then(() => {
      getNumberOfUnreadNotifications();
    });

    history.push(goTo);
  };

  const acceptGroupChannelInvitation = async (groupChannelInvitationId) => {
    try {
      const requestBody = {
        status: GROUP_INVITATION_STATUSES.ACCEPTED,
      };
      await reactToGroupChannelInvitationRequest(
        groupChannelInvitationId,
        requestBody
      );
      markNotificationAsRead('/groups');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const rejectGroupChannelInvitation = async (groupChannelInvitationId) => {
    try {
      const requestBody = {
        status: GROUP_INVITATION_STATUSES.REJECTED,
      };
      await reactToGroupChannelInvitationRequest(
        groupChannelInvitationId,
        requestBody
      );
      markNotificationAsRead('/groups');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const getGroupChannelInvitationMarkup = (
    notificationMessage,
    groupChannelInvitationId
  ) => {
    return !read ? (
      <div style={{ cursor: 'auto' }} className={classes['notification']}>
        <div className={classes['pink-icon']}></div>
        {from && (
          <img
            alt="user-profile"
            width={35}
            height={35}
            src={from.photo}
            className={classes['user-photo']}
          />
        )}
        <div className={classes['notification-information']}>
          <p className={classes['notification-message']}>
            {' '}
            {notificationMessage}
          </p>
          <div className={classes['date']}>
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
            <div className={classes['accept-reject-container']}>
              <p
                onClick={() =>
                  acceptGroupChannelInvitation(groupChannelInvitationId)
                }
                className={classes['accept']}
              >
                Accept
              </p>
              <p
                onClick={() =>
                  rejectGroupChannelInvitation(groupChannelInvitationId)
                }
                className={classes['reject']}
              >
                Reject
              </p>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={classes['notification']}>
        {from && (
          <img
            alt="user-profile"
            width={35}
            height={35}
            src={from.photo}
            className={classes['user-photo']}
          />
        )}

        <div className={classes['notification-information']}>
          <p className={classes['notification-message-read']}>
            {' '}
            {notificationMessage}
          </p>
          <p className={classes['date']}>
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>
    );
  };

  const getNftOfferMarkup = (notificationMessage, goTo) => {
    return !read ? (
      <div
        onClick={() => markNotificationAsRead(goTo)}
        className={classes['notification']}
      >
        <div className={classes['pink-icon']}></div>
        {from && (
          <img
            alt="user-profile"
            width={35}
            height={35}
            src={from.photo}
            className={classes['user-photo']}
          />
        )}

        <div className={classes['notification-information']}>
          <p className={classes['notification-message']}>
            {' '}
            {notificationMessage}
          </p>
          <p className={classes['date']}>
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>
    ) : (
      <div
        onClick={() => markNotificationAsRead(goTo)}
        className={classes['notification']}
      >
        {from && (
          <img
            alt="user-profile"
            width={35}
            height={35}
            src={from.photo}
            className={classes['user-photo']}
          />
        )}
        <div className={classes['notification-information']}>
          <p className={classes['notification-message-read']}>
            {' '}
            {notificationMessage}
          </p>
          <p className={classes['date']}>
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>
    );
  };

  const acceptCollaboratorInvitation = async (
    collaboratorInvitationId,
    nftCollectionId
  ) => {
    try {
      const requestBody = {
        status: 'Accepted',
      };

      await reactToNftCollectionCollaborationInvitationRequest(
        collaboratorInvitationId,
        requestBody
      );

      markNotificationAsRead(`/collections/${nftCollectionId}`);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const rejectCollaboratorInvitation = async (
    collaboratorInvitationId,
    nftCollectionId
  ) => {
    try {
      const requestBody = {
        status: 'Rejected',
      };
      await reactToNftCollectionCollaborationInvitationRequest(
        collaboratorInvitationId,
        requestBody
      );

      markNotificationAsRead(`/collections/${nftCollectionId}`);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const getNftCollectionCollaborationInvitationMarkup = (
    notificationMessage,
    nftCollectionId,
    collaboratorInvitationId
  ) => {
    return !read ? (
      <div style={{ cursor: 'auto' }} className={classes['notification']}>
        <div className={classes['pink-icon']}></div>
        {from && (
          <img
            alt="user-profile"
            width={35}
            height={35}
            src={from.photo}
            className={classes['user-photo']}
          />
        )}
        <div className={classes['notification-information']}>
          <p className={classes['notification-message']}>
            {' '}
            {notificationMessage}
          </p>
          <div className={classes['date']}>
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
            <div className={classes['accept-reject-container']}>
              <p
                onClick={() =>
                  acceptCollaboratorInvitation(
                    collaboratorInvitationId,
                    nftCollectionId
                  )
                }
                className={classes['accept']}
              >
                Accept
              </p>
              <p
                onClick={() =>
                  rejectCollaboratorInvitation(
                    collaboratorInvitationId,
                    nftCollectionId
                  )
                }
                className={classes['reject']}
              >
                Reject
              </p>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={classes['notification']}>
        {from && (
          <img
            alt="user-profile"
            width={35}
            height={35}
            src={from.photo}
            className={classes['user-photo']}
          />
        )}

        <div className={classes['notification-information']}>
          <p className={classes['notification-message-read']}>
            {' '}
            {notificationMessage}
          </p>
          <p className={classes['date']}>
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>
    );
  };

  const acceptGroupInvitation = async (groupInvitationId) => {
    try {
      const requestBody = {
        status: GROUP_INVITATION_STATUSES.ACCEPTED,
      };
      await reactToGroupInvitationRequest(groupInvitationId, requestBody);
      markNotificationAsRead('/groups');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const rejectGroupInvitation = async (groupInvitationId) => {
    try {
      const requestBody = {
        status: GROUP_INVITATION_STATUSES.REJECTED,
      };
      await reactToGroupInvitationRequest(groupInvitationId, requestBody);
      markNotificationAsRead('/groups');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const getGroupInvitationMarkup = (notificationMessage, groupInvitationId) => {
    return !read ? (
      <div style={{ cursor: 'auto' }} className={classes['notification']}>
        <div className={classes['pink-icon']}></div>
        {from && (
          <img
            alt="user-profile"
            width={35}
            height={35}
            src={from.photo}
            className={classes['user-photo']}
          />
        )}
        <div className={classes['notification-information']}>
          <p className={classes['notification-message']}>
            {' '}
            {notificationMessage}
          </p>
          <p className={classes['date']}>
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
            <div className={classes['accept-reject-container']}>
              <p
                onClick={() => acceptGroupInvitation(groupInvitationId)}
                className={classes['accept']}
              >
                Accept
              </p>
              <p
                onClick={() => rejectGroupInvitation(groupInvitationId)}
                className={classes['reject']}
              >
                Reject
              </p>
            </div>
          </p>
        </div>
      </div>
    ) : (
      <div className={classes['notification']}>
        {from && (
          <img
            alt="user-profile"
            width={35}
            height={35}
            src={from.photo}
            className={classes['user-photo']}
          />
        )}

        <div className={classes['notification-information']}>
          <p className={classes['notification-message-read']}>
            {' '}
            {notificationMessage}
          </p>
          <p className={classes['date']}>
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>
    );
  };

  const getNotificationMarkup = (notificationMessage, goTo, extraIcon) => {
    return !read ? (
      <div
        onClick={() => markNotificationAsRead(goTo)}
        className={classes['notification']}
      >
        <div className={classes['pink-icon']}></div>
        {from && (
          <img
            alt="user-profile"
            width={35}
            height={35}
            src={from.photo}
            className={classes['user-photo']}
          />
        )}
        {extraIcon && extraIcon}
        <div className={classes['notification-information']}>
          <p className={classes['notification-message']}>
            {' '}
            {notificationMessage}
          </p>
          <p className={classes['date']}>
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>
    ) : (
      <div
        onClick={() => markNotificationAsRead(goTo)}
        className={classes['notification']}
      >
        {from && (
          <img
            alt="user-profile"
            width={35}
            height={35}
            src={from.photo}
            className={classes['user-photo']}
          />
        )}
        {extraIcon && extraIcon}
        <div className={classes['notification-information']}>
          <p className={classes['notification-message-read']}>
            {' '}
            {notificationMessage}
          </p>
          <p className={classes['date']}>
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>
    );
  };

  const getPostTipLink = (extraInfo, entity) => {
    let link;
    if (extraInfo.isGroupPost) {
      link = `/user-posts/${entity?.id}?isGroupPost=1`;
    } else if (extraInfo.isDedicatedPost) {
      link = `/user-posts/${entity?.id}?isDedicatedPost=1`;
    } else {
      link = `/user-posts/${entity?.id}`;
    }

    return link;
  };

  const renderNotification = () => {
    if (notificationType === 'like-nft')
      return getNotificationMarkup(
        `${from.firstName} ${from.lastName} liked your collectible (${entity.name})`,
        `/nfts/${entity.id}`
      );
    else if (notificationType === 'remove-like-nft') {
    } else if (notificationType === 'follow')
      return getNotificationMarkup(
        `${from.firstName} ${from.lastName} followed you.`,
        `/user-feed/${entity.id}`
      );
    else if (notificationType === 'unfollow')
      return getNotificationMarkup(
        `${from.firstName} ${from.lastName} unfollowed you.`,
        `/user-feed/${entity.id}`
      );
    else if (notificationType === 'remove-follower')
      return getNotificationMarkup(
        `${from.firstName} ${from.lastName} removed you as follower.`,
        `/user-feed/${entity.id}`
      );
    else if (notificationType === 'watchlist-nft')
      return getNotificationMarkup(
        `${from.firstName} ${from.lastName} added your collectible (${entity.name}) to their watchlist`,
        `/nfts/${entity.id}`
      );
    else if (notificationType === 'unlock-nft-for-sale')
      return getNotificationMarkup(
        `${from.firstName} ${from.lastName} unlocked for sale collectible (${entity.name})`,
        `/nfts/${entity.id}`
      );
    else if (notificationType === 'remove-watchlist-nft')
      return getNotificationMarkup(
        `${from.firstName} ${from.lastName} removed your collectible (${entity.name}) from watchlist`,
        `/nfts/${entity.id}`
      );
    else if (notificationType === 'close-auction-seller')
      return getNotificationMarkup(
        `Your collectible (${entity.name}) was being sold. Congratulations!`,
        `/nfts/${entity.id}`
      );
    else if (notificationType === 'close-auction-buyer')
      return getNotificationMarkup(
        `Your were the winner of collectible (${entity.name}). Congratulations!`,
        `/nfts/${entity.id}`
      );

    if (notificationType === 'close-auction-seller-no-offers')
      return getNotificationMarkup(
        `No offers for your collectible (${entity.name}). You can unlock for sale again.`,
        `/nfts/${entity.id}`
      );
    if (notificationType === 'offer-outbidded')
      return getNotificationMarkup(
        `Your offer for collectible (${entity.name}) got outbidded!`,
        `/nfts/${entity.id}`
      );
    else if (notificationType === 'nft-message-mention')
      return getNotificationMarkup(
        `You have been mentioned in (${entity.name}) collectible chat!`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Comment"
            className={classes['extra-icon']}
            src={CommentIcon}
            style={{ width: 14 }}
            width={15}
            height={13}
          />
        </div>
      );
    else if (notificationType === 'post-mention') {
      return getNotificationMarkup(
        `${entity.name} mentioned you in a post!`,
        `/user-posts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Comment"
            className={classes['extra-icon']}
            src={CommentIcon}
            style={{ width: 14 }}
            width={15}
            height={13}
          />
        </div>
      );
    } else if (notificationType === 'chat-message-mention')
      return getNotificationMarkup(
        `You've been mentioned in (${entity.name}) chat!`,
        `/chats`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Comment"
            className={classes['extra-icon']}
            src={CommentIcon}
            style={{ width: 14 }}
            width={15}
            height={13}
          />
        </div>
      );
    else if (notificationType === 'nft-created-on-blockchain')
      return getNotificationMarkup(
        `Your collectible (${entity.name}) is created on the Blockchain!`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            width={20}
            height={17}
          />
        </div>
      );
    else if (notificationType === 'nft-failed-creation-on-blockchain')
      return getNotificationMarkup(
        `Something went wrong during the Buy Process of collectible (${entity.name}) on the Blockchain!`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    else if (
      notificationType === 'nft-failed-to-unlock-for-sale-on-blockchain'
    ) {
      return getNotificationMarkup(
        `Your Collectible (${entity.name}) was unable to be listed for sale.
        `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    } else if (notificationType === 'nft-unlocked-for-sale-on-blockchain')
      return getNotificationMarkup(
        `Your Collectible (${entity.name}) is listed for sale! 
        `,
        `/nfts/${entity.id}`
      );
    else if (notificationType === 'nft-failed-to-lock-for-sale-on-blockchain')
      return getNotificationMarkup(
        `
        Your Collectible (${entity.name}) was unable to be de-listed!!
    `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    else if (notificationType === 'nft-locked-for-sale-on-blockchain')
      return getNotificationMarkup(
        `
        Your collectible (${entity.name}) locked for sale on the Blockchain!
    `,
        `/nfts/${entity.id}`
      );
    else if (notificationType === 'failed-to-create-offer-on-blockchain') {
      return getNotificationMarkup(
        `Failed to place bid for collectible (${entity.name}) on the Blockchain! Please try again!
      `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'created-offer-on-blockchain') {
      return getNotificationMarkup(
        `
      Your bid for collectible (${entity.name}) was successfully placed on the Blockchain!
      `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            width={20}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'transfered-glms-on-blockchain') {
      return getNotificationMarkup(
        `You have successfully transferred ${entity.name}!`,
        '/wallet',
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            style={{ width: 19 }}
            src={ConffetiIcon}
            width={20}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'user-post-tag')
      return getNotificationMarkup(
        `You have been tagged by ${entity.name} in one of his posts!`,
        `/user-posts/${entity.id}?postId=${entity.id}`
      );
    else if (notificationType === 'listed-nft-for-buy-now-on-blockchain')
      return getNotificationMarkup(
        `
          (${entity.name}) has been listed for sale!
        `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            width={20}
            height={17}
          />
        </div>
      );
    else if (notificationType === 'updated-nft-buy-now-price-on-blockchain')
      return getNotificationMarkup(
        `The sale price of (${entity.name}) has been updated!
        `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    else if (notificationType === 'bought-nft-with-buy-now')
      return getNotificationMarkup(
        `
        You have just bought (${entity.name})! Congratulations!
    `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    else if (notificationType === 'bought-nft-with-offer')
      return getNotificationMarkup(
        `
        You have just bought (${entity.name})! Congratulations!
    `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    else if (notificationType === 'sold-nft-with-buy-now')
      return getNotificationMarkup(
        `
        Your Collectible (${entity.name}) has sold! Congratulations!
        `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    else if (notificationType === 'sold-nft-with-offer')
      return getNotificationMarkup(
        `
        Your offer for (${entity.name}) has been accepted! Congratulations!
        `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    else if (notificationType === 'failed-to-sell-nft-with-buy-now')
      return getNotificationMarkup(
        ` Something went wrong selling (${entity.name}). Contact support.`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    else if (notificationType === 'failed-to-buy-nft-with-buy-now')
      return getNotificationMarkup(
        `Something went wrong buying (${entity.name}). Try again!`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    else if (
      notificationType === 'failed-to-update-nft-buy-now-price-on-blockchain'
    )
      return getNotificationMarkup(
        `Failed to update the price of (${entity.name}).`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    else if (
      notificationType === 'failed-to-list-nft-for-buy-now-on-blockchain'
    )
      return getNotificationMarkup(
        `Failed to list (${entity.name}) for Buy Now.`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    else if (notificationType === 'user-life-event-tag')
      return getNotificationMarkup(
        `You have been tagged by ${entity.name} in one of his Life Events. Congratulations!`,
        `/user-feed/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Comment"
            className={classes['extra-icon']}
            src={CommentIcon}
            style={{ width: 14 }}
            height={13}
          />
        </div>
      );
    else if (notificationType === 'created-user-dedicated-post')
      return getNotificationMarkup(
        `${entity.name} created a post on your timeline!`,
        `/user-posts/${entity.id}?isDedicatedPost=1`
      );
    else if (notificationType === 'liked-user-post') {
      return getNotificationMarkup(
        `${entity.name} liked one of your posts!`,
        extraInfo?.isDedicatedPost
          ? `/user-posts/${entity.id}?isDedicatedPost=1`
          : extraInfo?.isGroupPost
          ? `/user-posts/${entity.id}?isGroupPost=1`
          : `/user-posts/${entity.id}`
      );
    } else if (notificationType === 'commented-user-post') {
      return getNotificationMarkup(
        `${entity.name} commented on one of your posts`,
        extraInfo?.isDedicatedPost
          ? `/user-posts/${entity.id}?isDedicatedPost=1`
          : `/user-posts/${entity.id}`
      );
    } else if (notificationType === 'commented-for-dedicated-user-post')
      return getNotificationMarkup(
        `${entity.name} commented on a post on your timeline`,
        `/user-posts/${entity.id}?isDedicatedPost=1`
      );
    else if (notificationType === 'replied-user-post-comment') {
      return getNotificationMarkup(
        `${entity.name} replied to one of your comments!`,
        extraInfo?.isDedicatedPost
          ? `/user-posts/${extraInfo?.postId}?isDedicatedPost=${1}`
          : `/user-posts/${extraInfo?.postId}`
      );
    } else if (notificationType === 'failed-transfer-glms-on-blockchain') {
      return getNotificationMarkup(
        `Failed to transfer ${entity.name}`,
        `/wallet`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    } else if (notificationType === 'unlisted-nft-for-buy-now-on-blockchain') {
      return getNotificationMarkup(
        `
        Successfully unlisted (${entity.name}).
        `,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (
      notificationType === 'failed-to-unlist-nft-for-buy-now-on-blockchain'
    ) {
      return getNotificationMarkup(
        `Failed to unlist the Collectible.`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    } else if (notificationType === 'successful-transfer-glms-on-blockchain') {
      return getNotificationMarkup(
        `${entity.name}`,
        `/wallet`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'user-post-comment-mention') {
      return getNotificationMarkup(
        `${entity.name} mentioned you in a comment!`,
        `/user-posts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Comment"
            className={classes['extra-icon']}
            src={CommentIcon}
            style={{ width: 14 }}
            height={13}
          />
        </div>
      );
    } else if (notificationType === 'user-post-comment-reply-mention') {
      return getNotificationMarkup(
        `${entity.name} mentioned you in a reply!`,
        `/user-posts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Comment"
            className={classes['extra-icon']}
            src={CommentIcon}
            style={{ width: 14 }}
            height={13}
          />
        </div>
      );
    } else if (notificationType === 'group-invitation') {
      return getGroupInvitationMarkup(
        `has invited you to join the Community (${limitString(
          entity.name,
          30
        )})`,
        entity.id
      );
    } else if (notificationType === 'group-post-mention') {
      return getNotificationMarkup(
        `${entity.name} mentioned you in a Community Post!`,
        `/user-posts/${entity.id}?isGroupPost=1`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Comment"
            className={classes['extra-icon']}
            src={CommentIcon}
            style={{ width: 14 }}
            height={13}
          />
        </div>
      );
    } else if (notificationType === 'user-group-post-comment-mention') {
      return getNotificationMarkup(
        `${entity.name} mentioned you in a Group Post Comment!`,
        `/user-posts/${entity.id}?isGroupPost=1`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Comment"
            className={classes['extra-icon']}
            src={CommentIcon}
            style={{ width: 14 }}
            height={13}
          />
        </div>
      );
    } else if (notificationType === 'user-group-post-comment-reply-mention') {
      return getNotificationMarkup(
        `${entity.name} mentioned you in a Community Comment Reply!`,
        `/user-posts/${entity.id}?isGroupPost=1`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Comment"
            className={classes['extra-icon']}
            src={CommentIcon}
            style={{ width: 14 }}
            height={13}
          />
        </div>
      );
    } else if (notificationType === 'group-channel-invitation') {
      return getGroupChannelInvitationMarkup(
        `has invited you to join (${limitString(
          entity.name,
          30
        )}) Group Channel`,
        entity.id
      );
    } else if (notificationType === 'accepted-group-membership-request') {
      return getNotificationMarkup(
        `Your request to join (${entity.name}) has been accepted!`,
        `/my-groups?id=${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'rejected-group-membership-request') {
      return getNotificationMarkup(
        `Your request to join (${entity.name}) has been rejected!`,
        `/groups`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    } else if (notificationType === 'requested-group-membership') {
      return getNotificationMarkup(
        `${extraInfo?.userWhoRequested} has requested to join (${entity.name})`,
        `/my-groups?id=${entity.id}`
      );
    } else if (notificationType === 'removed-from-group') {
      return getNotificationMarkup(
        `You have been removed from (${entity.name}) Community!`,
        `/groups`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    } else if (notificationType === 'nft-collection-collaborator-invitation') {
      return getNftCollectionCollaborationInvitationMarkup(
        `has invited you to join (${limitString(
          entity.name,
          30
        )}) Collection as a collaborator`,
        entity.id,
        extraInfo.collaboratorInvitationId
      );
    } else if (notificationType === 'nft-offer') {
      return getNftOfferMarkup(
        `You have a new offer for (${entity.name}) of ${extraInfo?.price} GLMS by ${extraInfo?.buyer}`,
        `/nft-offers`
      );
    } else if (notificationType === 'nft-offer-rejected') {
      return getNotificationMarkup(
        `Your offer for (${entity.name}) has been rejected!`,
        `/nfts/${entity.id}`
      );
    } else if (notificationType === 'nft-offer-deleted') {
      return getNotificationMarkup(
        `Your offer for (${entity.name}) has been cancelled!`,
        `/nft-offers`
      );
    } else if (notificationType === 'nft-offer-updated') {
      return getNotificationMarkup(
        `Your offer for (${entity.name}) has been updated!`,
        `/nft-offers`
      );
    } else if (notificationType === 'nft-request-created') {
      return getNotificationMarkup(
        `${extraInfo?.userWhoRequested || ''} has requested a Collectible`,
        `/requested-nfts`
      );
    } else if (notificationType === 'nft-request-from-uploaded') {
      return getNotificationMarkup(
        `Successfully uploaded your requested collectible (${entity.name}) for ${extraInfo.occasion}`,
        `/nfts/${entity.id}`
      );
    } else if (notificationType === 'nft-request-for-uploaded') {
      return getNotificationMarkup(
        `Successfully uploaded a requested collectible (${entity.name}) for ${extraInfo.occasion}`,
        `/nfts/${entity.id}`
      );
    } else if (notificationType === 'rejected-nft-request-for') {
      return getNotificationMarkup(
        `One of your requested collectibles has been rejected`,
        `/requested-nfts`
      );
    } else if (notificationType === 'rejected-nft-request-from') {
      return getNotificationMarkup(
        ` ${entity.name} rejected collectible request for you!`,
        `/requested-nfts`
      );
    } else if (notificationType === 'user-registration-referral') {
      return getNotificationMarkup(
        `${entity.name} used you as a Referral!  Congratulations!`,
        `/user-feed/${entity.id}`
      );
    } else if (notificationType === 'nft-collection-holder-benefits-update') {
      return getNotificationMarkup(
        `${extraInfo?.nftCollectionOwner} has updated Holder Benefits for Collection (${entity.name})!`,
        `/collections/${entity.id}`
      );
    } else if (notificationType === 'my-nft-transferred-successfully') {
      return getNotificationMarkup(
        `Your Collectible (${entity.name}) was transferred successfully!`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'transferred-nft-successfully') {
      return getNotificationMarkup(
        `${extraInfo?.from?.fullName} transferred (${entity.name}) to you. Congratulations!`,
        `/nfts/${entity.id}`
      );
    } else if (notificationType === 'my-nft-transfer-failed') {
      return getNotificationMarkup(
        `Something went wrong transferring (${entity.name})!`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    } else if (notificationType === 'claimed-nft') {
      return getNotificationMarkup(
        `(${entity.name}) was claimed by ${extraInfo?.from}!`,
        `/nfts/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'claim-nft-link') {
      return getNotificationMarkup(
        `Claim your Collectible from the (${entity.name}) Collection!`,
        extraInfo?.claimNftLinkPath
      );
    } else if (notificationType === 'collection-created') {
      return getNotificationMarkup(
        `Checkout my new Collection (${entity.name}) on Glimpse!`,
        extraInfo?.collectionLinkPath
      );
    } else if (notificationType === 'request-nft-as-collaborator') {
      return getNotificationMarkup(
        `${extraInfo?.requestedBy} has requested to add a Collectible to your Collection (${entity.name})`,
        `/collaborator-requests`
      );
    } else if (notificationType === 'request-nft-upload-as-collaborator') {
      return getNotificationMarkup(
        `${extraInfo?.requestedBy} has requested to upload a Collectible to your Collection (${entity.name})`,
        `/collaborator-requests`
      );
    } else if (notificationType === 'collaborator-nft-request-rejected') {
      return getNotificationMarkup(
        `${extraInfo?.rejectedBy} has rejected your request to add (Collectible - ${extraInfo?.nftTitle}) to Collection (${entity?.name})`,
        `/collaborator-requests`
      );
    } else if (notificationType === 'collaborator-nft-request-accepted') {
      return getNotificationMarkup(
        `${extraInfo?.rejectedBy} has accepted your request to add (Collectible - ${extraInfo?.nftTitle}) to Collection (${entity?.name}). Congratulations!`,
        `/collaborator-requests`
      );
    } else if (
      notificationType === 'collaborator-nft-request-upload-rejected'
    ) {
      return getNotificationMarkup(
        `${extraInfo?.rejectedBy} has rejected one of your upload requests to Collection (${entity?.name})`,
        `/collaborator-requests`
      );
    } else if (
      notificationType === 'collaborator-nft-request-upload-accepted'
    ) {
      return getNotificationMarkup(
        `${extraInfo?.rejectedBy} has uploaded one of your requests for Collection (${entity?.name}). Check your Collectible here!`,
        `/nfts/${entity?.id}`
      );
    } else if (notificationType === 'joined-community-buying-nft') {
      return getNotificationMarkup(
        ` You can now access ${entity.name} because you bought a collectible connected with the community`,
        `/my-groups?id=${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'removed-from-community-selling-nft') {
      return getNotificationMarkup(
        `You have been removed from ${entity.name} as you no longer own a collectible connected with the community!`,
        `/groups/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    } else if (notificationType === 'removed-from-community-transfer-nft') {
      return getNotificationMarkup(
        `You have been removed from ${entity.name} by transfering all collectibles connected with the community!`,
        `/groups/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    } else if (notificationType === 'joined-community-nft-transfer') {
      return getNotificationMarkup(
        ` You can now access ${entity.name} because a collectible connected with the community has been transferred to you!`,
        `/my-groups?id=${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'joined-community-claim-nft') {
      return getNotificationMarkup(
        ` You can now access ${entity.name} because you just claimed a collectible connected with the community!`,
        `/my-groups?id=${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'buy-glms-success') {
      return getNotificationMarkup(
        `You successfully bought ${
          extraInfo?.glmsAmount ? extraInfo?.glmsAmount : ''
        } GLMS via Stripe. Congratulations!`,
        `/wallet`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'buy-glms-error') {
      return getNotificationMarkup(
        `${
          typeof extraInfo?.reason === 'string'
            ? extraInfo.reason
            : 'Something went wrong during the process of buying GLMS! Please, contact support@glimpsenft.com'
        } `,
        `/wallet`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    } else if (notificationType === 'buy-diamonds-success') {
      return getNotificationMarkup(
        `You successfully bought ${
          extraInfo?.pointsAmount ? extraInfo?.pointsAmount : ''
        } Diamonds via Stripe. Congratulations!`,
        `/wallet`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'buy-diamonds-error') {
      return getNotificationMarkup(
        `${
          typeof extraInfo?.reason === 'string'
            ? extraInfo.reason
            : 'Something went wrong during the process of buying Diamonds! Please, contact support@glimpsenft.com'
        } `,
        `/wallet`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Failed"
            className={classes['extra-icon']}
            src={FailedIcon}
            width={20}
            height={20}
          />
        </div>
      );
    } else if (notificationType === 'post-tip') {
      return getNotificationMarkup(
        `${entity?.name} tipped one of your posts. They sent ${extraInfo?.tipAmount} Diamonds. Congratulations!`,
        getPostTipLink(extraInfo, entity)
      );
    } else if (notificationType === 'collectible-holders-post-created') {
      return getNotificationMarkup(
        `${entity?.name} created a post for collectible holders.`,
        `/user-posts/${entity?.id}`
      );
    } else if (notificationType === 'unlocked-nft-content') {
      return getNotificationMarkup(
        `You purchased (${entity?.name}) and now have access to unlockable content!`,
        `/nfts/${entity?.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'give-access-to-create-stripe-account') {
      return getNotificationMarkup(
        `You have a new task! You can link your bank account to start receiving direct payouts!`,
        `/get-started`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'nft-post-tip') {
      return getNotificationMarkup(
        `${entity?.name} tipped one of your Collectible posts. They sent ${extraInfo?.tipAmount} Diamonds. Congratulations!`,
        '/wallet'
      );
    } else if (notificationType === 'user-service-request-created') {
      return getNotificationMarkup(
        `${
          entity?.name ? entity.name : ''
        } has requested one of your services!`,
        '/requested-services?tab=Received'
      );
    } else if (notificationType === 'user-service-request-finished') {
      return getNotificationMarkup(
        `${
          entity?.name ? entity.name : ''
        } has completed one of your requested services. Congratulations!`,
        '/requested-services?tab=Sent'
      );
    } else if (notificationType === 'brand-item-bought') {
      return getNotificationMarkup(
        `You have successfully bought Brand Item (${entity.name}). Congratulations!`,
        `/brand-items/${entity.id}`,
        <div className={classes['extra-icon-container']}>
          <img
            alt="Conffeti"
            className={classes['extra-icon']}
            src={ConffetiIcon}
            style={{ width: 19 }}
            height={17}
          />
        </div>
      );
    } else if (notificationType === 'brand-item-sold') {
      return getNotificationMarkup(
        `You have successfully sold one of your brand items to (${entity.name}). Congratulations!`,
        `/brand-items/${entity.id}`
      );
    } else if (notificationType === 'post-payment') {
      return getNotificationMarkup(
        `${
          entity?.name ? entity.name : ''
        } unlocked one of your paid posts for ${
          extraInfo?.amount
        } Diamonds. Congratulations!`,
        `/`
      );
    } else
      return getNotificationMarkup(`${from.firstName} ${from.lastName}`, `/`);
  };

  return renderNotification();
}

export default Notification;
