import React, { useEffect, useState } from 'react';
import {
  getUserNftsAsOwnerAndCreatorRequest,
  updateNftContentRequest,
} from '../../../../httpRequests/httpRequests';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import SliderButton from '../../../../components/SliderButton/SliderButton';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { IPFS_URL } from '../../../../constants/IPFS';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import classes from './UserNftsAsOwnerAndCreator.module.css';
import notify from '../../../../utils/notify';

const RESULTS_PER_PAGE = 10;

function UserNftsAsOwnerAndCreator(props) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { userId } = props;
  const [searchValue, setSearchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['user-nfts-as-owner-and-creator', userId, searchValue],
    ({ pageParam = 1 }) =>
      getUserNftsAsOwnerAndCreatorRequest(
        userId,
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateNft = (nftId, isAdultContent) => {
    queryClient.setQueryData(
      ['user-nfts-as-owner-and-creator', userId],
      (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => {
            const updatedData = page.data.map((nft) =>
              nft._id === nftId ? { ...nft, adultContent: isAdultContent } : nft
            );
            return { ...page, data: updatedData };
          }),
        };
      }
    );
  };

  const updateNftContentHandler = async (nft, isAdultContent) => {
    try {
      const requestBody = {
        adultContent: isAdultContent,
      };
      notify('success', 'Updating content', 1000);
      await updateNftContentRequest(nft._id, requestBody);

      invalidateNft(nft._id, isAdultContent);
    } catch (error) {
      notify('error', error, 2500);
    }
  };
  return (
    <>
      <div
        style={{ minHeight: '85vh' }}
        id="table-nft"
        className={classes['all-nfts-container']}
      >
        <div className={classes['heading-container']}>
          <h2 className={classes['heading']}>NFTs as Owner & Creator</h2>
          <div>
            <input
              placeholder="Search For NFTs"
              style={{ width: 300 }}
              className="searchInput"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>

        <Row justify="space-between">
          <Col md={1} className={classes['pagination-col']}>
            NFT
          </Col>
          <Col md={2} className={classes['pagination-col']}>
            Title
          </Col>

          <Col md={3} className={classes['pagination-col']}>
            Creator
          </Col>
          <Col md={3} className={classes['pagination-col']}>
            Owner
          </Col>

          <Col
            md={5}
            style={{ paddingLeft: '5%' }}
            className={classes['pagination-col']}
          >
            Content
          </Col>
        </Row>

        {data && data.pages[0].results === 0 && (
          <h2 style={{ paddingTop: 10 }} className="bold-text">
            No NFTs found
          </h2>
        )}

        {!isLoading &&
          data &&
          data.pages.map((page) => {
            return page.data.map((nft) => {
              const { author, owner } = nft;

              return (
                <div key={nft._id}>
                  <hr />
                  <Row justify="space-between" style={{ padding: '1em 0' }}>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/nfts/${nft._id}`)}
                      md={1}
                    >
                      {nft.type === 'image' ? (
                        <img
                          width={30}
                          height={30}
                          style={{ borderRadius: 100, objectFit: 'cover' }}
                          alt="User"
                          src={`${IPFS_URL}/${nft.ipfs}`}
                        />
                      ) : (
                        <ReactPlayer
                          width={30}
                          height={30}
                          url={`${IPFS_URL}/${nft.ipfs}`}
                        />
                      )}
                    </Col>
                    <Col md={2}>{nft.title}</Col>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-feed/${author._id}`)}
                      md={3}
                    >
                      {author.firstName} {author.lastName}
                    </Col>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-feed/${owner._id}`)}
                      md={3}
                    >
                      {owner.firstName} {owner.lastName}
                    </Col>

                    <Col md={5}>
                      <SliderButton
                        leftValue={'Safe'}
                        rightValue={'Adult'}
                        isLeftContentSelected={!nft.adultContent}
                        onSliderChangeCb={(isSafeContentSelected) => {
                          updateNftContentHandler(nft, !isSafeContentSelected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              );
            });
          })}

        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </>
  );
}

export default UserNftsAsOwnerAndCreator;
