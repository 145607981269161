import React, { useContext, useEffect, useState } from 'react';
import classes from './FollowPeopleModal.module.css';
import { useQuery, useQueryClient } from 'react-query';
import Modal from '../../../../components/Modal/Modal';
import {
  getGetStartedTaskFollowPeopleSuggestionsRequest,
  getUsersNumberOfFollowersAndFollowingsRequest,
  requestFollowingRequest,
} from '../../../../httpRequests/httpRequests';
import Button from '../../../../components/Button/Button';
import { AuthContext } from '../../../../context/auth-context';
import notify from '../../../../utils/notify';

function FollowPeopleModal(props) {
  const queryClient = useQueryClient();
  const { getMyFollowings, myFollowingsIds, profile } = useContext(AuthContext);
  const { setShowFollowPeopleModal } = props;
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);

  const { isLoading, data, error } = useQuery(
    'get-started-task-follow-people-suggestions',
    getGetStartedTaskFollowPeopleSuggestionsRequest
  );

  const myNumberOfFollowersAndFollowingsResponse = useQuery(
    ['my-number-of-followers-and-followings'],
    () => getUsersNumberOfFollowersAndFollowingsRequest(profile?._id)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    if (
      myNumberOfFollowersAndFollowingsResponse.data &&
      myNumberOfFollowersAndFollowingsResponse.data.numberOfFollowings >= 10
    ) {
      setIsTaskCompleted(true);
    }
  }, [myNumberOfFollowersAndFollowingsResponse]);

  const closeModalHandler = () => {
    setShowFollowPeopleModal(false);
  };

  const followUserHandler = async (userId) => {
    try {
      await requestFollowingRequest(userId);
      await getMyFollowings();

      queryClient.invalidateQueries(['my-number-of-followers-and-followings']);
      queryClient.refetchQueries(['my-number-of-followers-and-followings']);
    } catch (err) {}
  };

  const completeTaskHandler = async () => {
    await Promise.all([
      queryClient.invalidateQueries('get-started-tasks-completion-info'),
      queryClient.refetchQueries('get-started-tasks-completion-info'),
    ]);

    closeModalHandler();
  };

  return (
    <Modal
      closeIcon="true"
      closeModal={closeModalHandler}
      title="Follow People"
    >
      <div>
        <div className={classes['follow-text-container']}>
          <p className={classes['follow-people-text']}>
            Follow 10 people to get 25 Diamonds
          </p>

          {myNumberOfFollowersAndFollowingsResponse.data &&
            myNumberOfFollowersAndFollowingsResponse.data.numberOfFollowings !==
              undefined && (
              <p className={classes['number-of-followings-text']}>
                {
                  myNumberOfFollowersAndFollowingsResponse.data
                    .numberOfFollowings
                }
                / 10
              </p>
            )}
        </div>

        <div className={classes['user-list']}>
          {!isLoading &&
            data?.data &&
            data.data.map((user) => {
              return (
                <div key={user._id} className={classes['user-container']}>
                  <img
                    className={classes['user-photo']}
                    src={user.photo}
                    alt={user.fullName}
                  />
                  <h3 className={classes['user-fullName']}>{user.fullName}</h3>

                  {myFollowingsIds[user._id] ? (
                    <Button
                      style={{ marginLeft: 'auto' }}
                      disabled
                      darkpink="true"
                    >
                      Following
                    </Button>
                  ) : (
                    <Button
                      onClick={() => followUserHandler(user._id)}
                      style={{ marginLeft: 'auto' }}
                      darkpink="true"
                    >
                      Follow
                    </Button>
                  )}
                </div>
              );
            })}
        </div>

        {isTaskCompleted ? (
          <Button
            style={{ width: '100%' }}
            onClick={completeTaskHandler}
            darkpinkcolor="true"
          >
            Complete
          </Button>
        ) : (
          <Button style={{ width: '100%' }} darkpinkcolor="true" disabled>
            Complete
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default FollowPeopleModal;
