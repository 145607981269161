import React, { useState } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import Button from '../../../../components/Button/Button';
import { getNumberOfMutualFollowersRequest } from '../../../../httpRequests/httpRequests';
import { limitString } from '../../../../helpers/helpers';
import { requestFollowingRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './UserSuggestionCard.module.css';
import VerifiedIcon from '../../../../assets/verified.png';
import { useHistory } from 'react-router-dom';
import MutualFollowersModal from '../../../../components/MutualFollowersModal/MutualFollowersModal';

function UserSuggestionCard(props) {
  const { user } = props;
  const history = useHistory();
  const queryClient = useQueryClient();

  const [showMutualFollowersModal, setShowMutualFollowersModal] = useState(
    false
  );

  const { isLoading, data } = useQuery(
    ['number-of-mutual-followers', user._id],
    () => getNumberOfMutualFollowersRequest(user._id)
  );

  const followUserHandler = async () => {
    try {
      await requestFollowingRequest(user?._id);
      queryClient.invalidateQueries(['friend-suggestions']);
      queryClient.refetchQueries(['friend-suggestions']);
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const openMutualFollowersModalHandler = () => {
    setShowMutualFollowersModal(true);
  };

  return (
    <>
      {showMutualFollowersModal && (
        <MutualFollowersModal
          userId={user._id}
          setShowMutualFollowersModal={setShowMutualFollowersModal}
        />
      )}

      <div style={props.style} className={classes['card-container']}>
        <img src={user.photo} alt="User" className={classes['user-image']} />
        <h3 title={user.fullName}>
          {limitString(user.fullName, 15)}
          {user.verified && (
            <img
              alt="Verified"
              className={classes['verified-icon']}
              src={VerifiedIcon}
            />
          )}
        </h3>

        {!isLoading && data?.data !== undefined && (
          <p
            onClick={openMutualFollowersModalHandler}
            className={classes['mutual-followers']}
          >
            {data.data} Mutual Followers
          </p>
        )}

        <Button
          onClick={followUserHandler}
          darkpink="true"
          style={{ padding: '.7rem 3.3rem', width: 160 }}
        >
          Follow
        </Button>

        <Button
          onClick={() => history.push(`/user-feed/${user._id}`)}
          gray="true"
          style={{ padding: '.7rem 3.3rem', width: 160 }}
        >
          Visit
        </Button>
      </div>
    </>
  );
}

export default UserSuggestionCard;
