import React, { useRef, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { getAllGroupMembersForASpecificGroupRequest } from '../../../../httpRequests/httpRequests';
import classes from './MembersModal.module.css';
import User from '../../../../components/User/User';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import notify from '../../../../utils/notify';

const RESULTS_PER_PAGE = 10;

function MembersModal(props) {
  const membersListRef = useRef();
  const { setShowMembersModal, groupId } = props;

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['group-members', groupId],
    ({ pageParam = 1 }) =>
      getAllGroupMembersForASpecificGroupRequest(
        pageParam,
        RESULTS_PER_PAGE,
        groupId,
        ''
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const membersListContainerElement = membersListRef.current;

    if (!membersListContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    membersListContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      membersListContainerElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const closeModalHandler = () => {
    setShowMembersModal(false);
  };

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Members"
      footer={
        <div className={classes['footer']}>
          <Button pinkcolor="true" onClick={closeModalHandler}>
            Close
          </Button>
        </div>
      }
    >
      <div ref={membersListRef} className={classes['members-list']}>
        {data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map(({ user }) => {
              return (
                <User
                  key={user._id}
                  user={user}
                  closeModal={closeModalHandler}
                />
              );
            });
          })}

        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </Modal>
  );
}

export default MembersModal;
