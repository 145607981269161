import React from 'react';
import classes from './SingleOrMultipleUploadModal.module.css';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import SeriesIcon from '../../../../../../assets/series-icon.png';
import SeriesSelectedIcon from '../../../../../../assets/series-selected-icon.png';
import EditionIcon from '../../../../../../assets/edition-icon.png';
import EditionSelectedIcon from '../../../../../../assets/edition-selected-icon.png';

function SingleOrMultipleUploadModal(props) {
  const {
    setScreenNumber,
    closeCreateCollectionModal,
    setIsSingleUploadSeleted,
    isSingleUploadSelected,
  } = props;

  const closeModalHandler = () => {
    closeCreateCollectionModal();
  };

  const goToNextScreenHandler = () => {
    setScreenNumber(1);
  };

  const selectSingleUploadHandler = () => {
    setIsSingleUploadSeleted(true);
  };

  const selectMultipleUpload = () => {
    setIsSingleUploadSeleted(false);
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>
          <Button
            style={{ width: 200 }}
            onClick={goToNextScreenHandler}
            darkpink="true"
          >
            Next{' '}
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Series / Edition"
    >
      <div className={classes['options-container']}>
        <div
          onClick={selectMultipleUpload}
          className={`${classes['multiple-container']} ${
            !isSingleUploadSelected && classes['selected-container']
          }`}
        >
          <img
            width={20}
            height={20}
            src={!isSingleUploadSelected ? SeriesSelectedIcon : SeriesIcon}
            style={{
              marginBottom: 5,
            }}
            alt="Single Upload"
          />
          <p>Series</p>
        </div>

        <div
          onClick={selectSingleUploadHandler}
          className={`${classes['single-container']} ${
            isSingleUploadSelected && classes['selected-container']
          }`}
        >
          <img
            width={20}
            height={20}
            style={{ marginBottom: 5 }}
            src={isSingleUploadSelected ? EditionSelectedIcon : EditionIcon}
            alt="Single Upload"
          />
          <p>Edition</p>
        </div>
      </div>

      <div className={classes['options-info-container']}>
        {!isSingleUploadSelected && (
          <div className={classes['multiple-upload-info-container']}>
            <h3>Create A Series</h3>

            <p>
              Select to create a Collection made up of multiple files. For
              example, you have 500 unique photos and want to make 1 Digital
              Collectible for each photo (so 500 Collectibles would be created).
            </p>
          </div>
        )}
        {isSingleUploadSelected && (
          <div className={classes['single-info-container']}>
            <h3>Create An Edition</h3>

            <p>
              Select to create a 1/x Collection. For example, you have 1
              image/video/file you want to create 1,000 copies of, where each
              individual Digital Collectible represents x/1000. This is a great
              way to control the rarity - the smaller the edition size the more
              rare the collectible!
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default SingleOrMultipleUploadModal;
