import React, { useState, useEffect, useCallback } from 'react';
import classes from './VerifyAndConfirmTransferOfNftModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import ReactPlayer from 'react-player';
import { IPFS_URL } from '../../../../constants/IPFS';
import notify from '../../../../utils/notify';
import { verifyAndConfirmTransferOfNftRequest } from '../../../../httpRequests/httpRequests';

function VerifyAndConfirmTransferOfNftModal(props) {
  const {
    nft,
    setShowVerifyAndConfirmTransferOfNftModal,
    toUsername,
    setShowTransferNftModal,
    invalidateNft,
  } = props;

  const [verificationCodeNumbers, setVerificationCodeNumbers] = useState([]);
  const [checkingVerificationCode, setCheckingVerificationCode] = useState(
    false
  );

  const closeModalHandler = () => {
    setShowVerifyAndConfirmTransferOfNftModal(false);
  };

  const verifyAndConfirmTransferOfNftHandler = useCallback(
    async (verificationCode) => {
      setCheckingVerificationCode(true);

      try {
        const requestBody = {
          verificationCode,
        };
        await verifyAndConfirmTransferOfNftRequest(nft._id, requestBody);
        notify('success', 'Transfer Of NFT is being processed!', 2000);
        setShowTransferNftModal(false);
        invalidateNft?.();
      } catch (err) {
        notify('error', err, 2000);
      }

      setCheckingVerificationCode(false);
    },
    []
  );

  useEffect(() => {
    const pasteVerificationCodeHandler = ({ clipboardData }) => {
      const verificationCode = clipboardData.getData('Text');
      const splittedVerificationCode = verificationCode.split('');

      setVerificationCodeNumbers(splittedVerificationCode);

      verifyAndConfirmTransferOfNftHandler(verificationCode);
    };

    document.addEventListener('paste', pasteVerificationCodeHandler);

    return () =>
      document.removeEventListener('paste', pasteVerificationCodeHandler);
  }, [verifyAndConfirmTransferOfNftHandler]);

  useEffect(() => {
    const verificationCodeHandler = async (e) => {
      const key = e.key;

      if (key === 'Backspace' || key === 'Delete') {
        const updatedVerificationCodeNumbers = [...verificationCodeNumbers];
        if (updatedVerificationCodeNumbers.length === 0) return;
        updatedVerificationCodeNumbers.pop();
        return setVerificationCodeNumbers(updatedVerificationCodeNumbers);
      }

      if (isNaN(key)) return;

      const updatedVerificationCodeNumbers = [...verificationCodeNumbers, key];
      setVerificationCodeNumbers(updatedVerificationCodeNumbers);

      if (updatedVerificationCodeNumbers.length === 6) {
        verifyAndConfirmTransferOfNftHandler(
          updatedVerificationCodeNumbers.join('')
        );
      }
    };

    window.addEventListener('keydown', verificationCodeHandler);

    return () => window.removeEventListener('keydown', verificationCodeHandler);
  }, [verifyAndConfirmTransferOfNftHandler, verificationCodeNumbers]);

  return (
    <Modal
      style={{ height: 550 }}
      footer={
        <div className={classes['footer']}>
          <Button modernpinkcolor="true" onClick={closeModalHandler}>
            Cancel
          </Button>
          <Button
            modernpink="true"
            disabled={
              verificationCodeNumbers.length < 6 || checkingVerificationCode
            }
          >
            {checkingVerificationCode ? 'Verifying...' : 'Verify and confirm'}
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Verify Transfer"
    >
      <div>
        <div className={classes['nft-image-container']}>
          {nft.type === 'image' ? (
            <img
              alt="IPFS Hash"
              className={classes['nft-image']}
              src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
            />
          ) : (
            <ReactPlayer
              style={{
                objectFit: 'cover',
                borderRadius: 16,
              }}
              width={280}
              height={280}
              url={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
            />
          )}

          <p className={classes['to-text']}>To: {toUsername}</p>
        </div>

        <div>
          <div className={classes['verification-code-container']}>
            {new Array(6).fill(0).map((_, i) => {
              if (verificationCodeNumbers[i])
                return (
                  <div key={i} className={classes['box-number']}>
                    {verificationCodeNumbers[i]}
                  </div>
                );
              else
                return (
                  <div
                    style={{ color: 'rgba(0,0,0,.4)' }}
                    key={i}
                    className={classes['box-number']}
                  >
                    -
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default VerifyAndConfirmTransferOfNftModal;
