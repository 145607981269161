import React, { useRef, useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import { getAllMembersForASpecificPrivateGroupChannelRequest } from '../../../../../../httpRequests/httpRequests';
import Input from '../../../../../../components/Input/Input';
import User from '../../../../../../components/User/User';
import classes from './PrivateChannelMembersModal.module.css';
import notify from '../../../../../../utils/notify';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';

const RESULTS_PER_PAGE = 10;

function PrivateChannelMembersModal(props) {
  const { setShowPrivateChannelMembersModal, channelId, groupId } = props;
  const membersListContainerRef = useRef();
  const [searchValue, setSearchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['private-channel-group-members', channelId, groupId, searchValue],
    ({ pageParam = 1 }) =>
      getAllMembersForASpecificPrivateGroupChannelRequest(
        groupId,
        channelId,
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    const membersListContainerElement = membersListContainerRef.current;

    if (!membersListContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    membersListContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      membersListContainerElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  const closeModalHandler = () => {
    setShowPrivateChannelMembersModal(false);
  };

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <Modal
      style={{ height: 550 }}
      closeModal={closeModalHandler}
      title="Members"
      footer={
        <Button pinkcolor="true" onClick={closeModalHandler}>
          Close
        </Button>
      }
    >
      <>
        <Input
          style={{ width: '100%' }}
          placeholder="Search user"
          value={searchValue}
          onChange={searchValueChangeHandler}
        />

        <div ref={membersListContainerRef} className={classes['members-list']}>
          {data && data.pages && data.pages[0].results === 0 && (
            <p>No Results Found</p>
          )}
          {data &&
            data.pages &&
            data.pages.map((page) => {
              return page.data.map(({ user }) => (
                <User key={user._id} user={user} />
              ));
            })}
          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </>
    </Modal>
  );
}

export default PrivateChannelMembersModal;
