import React, { useContext, useState } from 'react';
import classes from './RequestEmailChangeModal.module.css';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import Input from '../../../Input/Input';
import { isValidEmail } from '../../../../helpers/helpers';
import { AuthContext } from '../../../../context/auth-context';
import notify from '../../../../utils/notify';
import {
  changeMyEmailRequest,
  requestEmailChangeRequest,
} from '../../../../httpRequests/httpRequests';

function RequestEmailChangeModal(props) {
  const { setShowRequestEmailChangeModal } = props;
  const { profile, updateProfileInformation } = useContext(AuthContext);
  const [newEmail, setNewEmail] = useState(profile.email);
  const [newEmailValid, setNewEmailValid] = useState(true);
  const [
    showEmailVerificationCodeInput,
    setShowEmailVerificationCodeInput,
  ] = useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [
    gettingEmailVerificationCode,
    setGettingEmailVerificationCode,
  ] = useState(false);

  const closeModalHandler = () => {
    setShowRequestEmailChangeModal(false);
  };

  const newEmailChangeHandler = (e) => {
    setNewEmail(e.target.value);
    setNewEmailValid(isValidEmail(e.target.value));
  };

  const emailVerificationCodeChangeHandler = (e) => {
    setEmailVerificationCode(e.target.value);
  };

  const requestEmailChangeHandler = async () => {
    setGettingEmailVerificationCode(true);

    try {
      const requestBody = {
        newEmail,
      };
      await requestEmailChangeRequest(requestBody);

      setShowEmailVerificationCodeInput(true);
    } catch (err) {
      notify('error', err, 2000);
    }

    setGettingEmailVerificationCode(false);
  };

  const changeMyEmailHandler = async () => {
    try {
      const requestBody = {
        newEmail,
      };

      await changeMyEmailRequest(emailVerificationCode, requestBody);

      updateProfileInformation({ ...profile, email: newEmail });
      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const newEmailInputStyle = { width: '100%' };
  if (!newEmailValid) {
    newEmailInputStyle['border'] = '1px solid red';
  }
  const emailVerificationCodeInputStyle = { width: '100%' };
  if (!emailVerificationCode) {
    emailVerificationCodeInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            disabled={gettingEmailVerificationCode}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Cancel
          </Button>
          <Button
            onClick={
              showEmailVerificationCodeInput
                ? changeMyEmailHandler
                : requestEmailChangeHandler
            }
            style={{ width: '100%' }}
            darkpink="true"
            disabled={!newEmailValid || gettingEmailVerificationCode}
          >
            {showEmailVerificationCodeInput
              ? 'Change Email'
              : 'Get Verification Code'}
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Change Email"
    >
      <p className={classes['label']}>New Email</p>
      <Input
        disabled={showEmailVerificationCodeInput}
        style={newEmailInputStyle}
        value={newEmail}
        onChange={newEmailChangeHandler}
      />

      {showEmailVerificationCodeInput && (
        <>
          <p className={classes['label']}>Verification Code</p>
          <Input
            style={emailVerificationCodeInputStyle}
            onChange={emailVerificationCodeChangeHandler}
            value={emailVerificationCode}
          />
        </>
      )}
    </Modal>
  );
}

export default RequestEmailChangeModal;
