import React, { useEffect } from 'react';
import classes from './GroupPosts.module.css';
import { useInfiniteQuery } from 'react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import UserPost from '../../../../components/UserPost/UserPost';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import { getUserGroupPostsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import NoPostsFoundIcon from '../../../../assets/no-posts-found-icon.png';

const RESULTS_PER_PAGE = 10;

function GroupPosts(props) {
  const { groupId, selectedTopic } = props;

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['group-posts', groupId, selectedTopic],
    ({ pageParam = 1 }) =>
      getUserGroupPostsRequest(
        pageParam,
        RESULTS_PER_PAGE,
        groupId,
        selectedTopic
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const allLikes = [];
  if (data?.pages) {
    data.pages.forEach((page) => {
      allLikes.push(...page.myLikeStatusForEachPost);
    });
  }

  return (
    <div>
      {data && data.pages && data.pages[0].results === 0 && (
        <div className={classes['no-posts-found-container']}>
          <img
            src={NoPostsFoundIcon}
            alt="No Posts Found"
            className={classes['no-posts-found-icon']}
          />
          <p className={classes['no-posts-found-text']}>No Posts Found</p>
        </div>
      )}

      {data &&
        data.pages &&
        data.pages.map((page) => {
          return page.data.map((post) => {
            const isPostLikedByMe =
              allLikes.find(({ postId }) => {
                return postId === post._id;
              })?.likeStatus !== null;

            return (
              <UserPost
                groupId={groupId}
                setQueryDataKey={['group-posts', groupId, selectedTopic]}
                isGroupPost={true}
                key={post._id}
                post={post}
                isPostLikedByMe={isPostLikedByMe}
              />
            );
          });
        })}

      {(isLoading || isFetching) && <LoadingSpinner />}
    </div>
  );
}

export default GroupPosts;
