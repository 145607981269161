import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getMyRecentlyAddedNftsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './MyRecentlyAddedNfts.module.css';
import { IPFS_URL } from '../../../../constants/IPFS';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { getFormattedNumber } from '../../../../helpers/helpers';
import { CoinMarketCapContext } from '../../../../context/coinmarketcap-context';

function MyRecentlyAddedNfts(props) {
  const { data, error } = useQuery(
    'my-recently-added-nfts',
    getMyRecentlyAddedNftsRequest
  );
  const { glmsToUsd } = useContext(CoinMarketCapContext);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['my-recent-nfts-container']}>
      <div className={classes['recent-nfts-see-all-container']}>
        <h3 className={classes['my-recent-nfts-text']}>
          My Recent Collectibles
        </h3>

        <div
          onClick={() => props.seeAllHandler?.()}
          className={classes['see-all-container']}
        >
          <p>See All</p>
        </div>
      </div>
      <div className={classes['my-recent-nfts']}>
        {data && data.data.length === 0 && (
          <p
            style={{ marginTop: 0 }}
            className={classes['no-recent-sale-text']}
          >
            No Recent Collectibles
          </p>
        )}

        {data &&
          data.data.map((nft, i) => {
            let style = {};
            if (i === 2) {
              style = {
                borderBottomLeftRadius: 10,
              };
            } else if (i === 3) {
              style = {
                borderBottomRightRadius: 10,
              };
            }

            return (
              <div key={nft._id} className={classes['recent-sale']}>
                <Link to={`/nfts/${nft._id}`}>
                  {nft.type === 'image' ? (
                    <img
                      style={style}
                      alt="IPFS Hash"
                      className={classes['nft-image']}
                      src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
                      width={150}
                      height={150}
                    />
                  ) : (
                    <ReactPlayer
                      style={{
                        objectFit: 'cover',
                        maxWidth: 183,
                        maxHeight: 183,
                        ...style,
                      }}
                      width={150}
                      height={150}
                      url={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
                    />
                  )}
                </Link>

                {nft.listedForBuyNow && (
                  <div className={classes['buy-now-price-container']}>
                    <p className={classes['buy-now-price']}>
                      $
                      {getFormattedNumber(
                        (nft.buyNowPrice * glmsToUsd.price).toFixed(2)
                      )}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      {/* <Link style={{ marginTop: 'auto' }} to="/create">
        <div className={classes['add-nfts-button']}>
          <img src={PlusIcon} alt="Add" />
          <p className={classes['add-nfts-text']}>Add NFT’s</p>
        </div>
      </Link> */}
    </div>
  );
}

export default MyRecentlyAddedNfts;
