import React, { useState, useRef, useEffect, useMemo } from 'react';
import classes from './InviteFriendsModal.module.css';
import Modal from '../Modal/Modal';
import { useInfiniteQuery } from 'react-query';
import {
  getMemberStatusInASpecificGroupRequest,
  getPaginatedUnionOfMyFollowersAndFollowingsRequest,
  getUnionOfMyFollowersAndFollowingsRequest,
} from '../../httpRequests/httpRequests';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Input from '../Input/Input';
import Button from '../Button/Button';
import notify from '../../utils/notify';
import RemoveIcon from '../../assets/gray-remove.png';

const RESULTS_PER_PAGE = 10;

function InviteFriendsModal(props) {
  const friendsContainerRef = useRef();
  const { groupId, setShowInviteFriendsModal } = props;
  const [searchValue, setSearchValue] = useState('');
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [selectedFriendsIds, setSelectedFriendsIds] = useState({});
  const [usersToInvite, setUsersToInvite] = useState([]);
  const [loadingUsersToInvite, setLoadingUsersToInvite] = useState(true);
  const [
    unionOfMyFollowersAndFollowings,
    setUnionOfMyFollowersAndFollowings,
  ] = useState();

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['union-of-my-followers-and-followings', searchValue],
    ({ pageParam = 1 }) =>
      getPaginatedUnionOfMyFollowersAndFollowingsRequest(
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const friendsContainerElement = friendsContainerRef.current;

    if (!friendsContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    friendsContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      friendsContainerElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const closeModalHandler = () => {
    setShowInviteFriendsModal(false);
  };

  const selectAllFriendsHandler = async () => {
    let unionOfMyFollowersAndFollowingsData;

    if (unionOfMyFollowersAndFollowings) {
      unionOfMyFollowersAndFollowingsData = unionOfMyFollowersAndFollowings;
    } else {
      const { data } = await getUnionOfMyFollowersAndFollowingsRequest();
      unionOfMyFollowersAndFollowingsData = data;
      setUnionOfMyFollowersAndFollowings(data);
    }

    const updatedSelectedFriendsIds = {};

    unionOfMyFollowersAndFollowingsData.forEach(({ user }) => {
      updatedSelectedFriendsIds[user._id] = user;
    });

    setSelectedFriendsIds(updatedSelectedFriendsIds);
    setSelectedFriends(Object.values(updatedSelectedFriendsIds));
  };

  const removeAllSelectedFriendsHandler = () => {
    setSelectedFriendsIds({});
    setSelectedFriends([]);
  };

  const selectFriendHandler = (friend) => {
    const isFriendSelected = !!selectedFriendsIds[friend._id];

    const updatedSelectedFriendsIds = { ...selectedFriendsIds };

    if (isFriendSelected) {
      delete updatedSelectedFriendsIds[friend._id];
    } else {
      updatedSelectedFriendsIds[friend._id] = friend;
    }

    setSelectedFriendsIds(updatedSelectedFriendsIds);
    setSelectedFriends(Object.values(updatedSelectedFriendsIds));
  };

  const removeFriendHandler = (friendId) => {
    const updatedSelectedFriendsIds = { ...selectedFriendsIds };
    delete updatedSelectedFriendsIds[friendId];
    setSelectedFriendsIds(updatedSelectedFriendsIds);
    setSelectedFriends(Object.values(updatedSelectedFriendsIds));
  };

  const inviteHandler = async () => {
    try {
      if (props.inviteHandler) await props.inviteHandler(selectedFriendsIds);
      notify('success', 'Invitation sent successfully!', 2000);
    } catch (err) {
      notify('error', err, 2000);
    }
    closeModalHandler();
  };

  useMemo(async () => {
    if (!data || !data.pages) return [];

    setLoadingUsersToInvite(true);

    const allUsers = [];
    data.pages.forEach((page) => {
      page.data.forEach(({ user }) => {
        allUsers.push(user);
      });
    });

    const allUsersStatus = await Promise.all(
      allUsers.map(async (user) => {
        const statusData = await getMemberStatusInASpecificGroupRequest(
          groupId,
          user._id
        );
        return {
          user,
          isPartOfTheGroup: statusData.data?.status === 'ACCEPTED',
        };
      })
    );

    setUsersToInvite(
      allUsersStatus.filter(({ isPartOfTheGroup }) => !isPartOfTheGroup)
    );
    setLoadingUsersToInvite(false);
  }, [data, groupId]);

  return (
    <Modal
      closeIcon="true"
      footer={<div className={classes['footer']}></div>}
      closeModal={closeModalHandler}
      title="Invite Friends"
      style={{ width: 550, maxHeight: 550 }}
    >
      <>
        <div className={classes['invite-friends-container']}>
          <div className={classes['friends-to-invite-container']}>
            <Input
              onChange={searchValueChangeHandler}
              style={{ width: '100%' }}
              placeholder={'Search user'}
              value={searchValue}
            />

            <div className={classes['suggested-select-all']}>
              <h3 className={classes['suggested-text']}>Suggested</h3>
              <p
                onClick={selectAllFriendsHandler}
                className={classes['select-text']}
              >
                Select All
              </p>
            </div>

            <div ref={friendsContainerRef} className={classes['friends-list']}>
              {usersToInvite.map(({ user, isPartOfTheGroup }) => {
                return (
                  <div className={classes['user-container']} key={user._id}>
                    <div className={classes['user-image-name']}>
                      <img
                        alt="Friend"
                        src={user.photo}
                        className={classes['user-image']}
                      />
                      <p className={classes['user-name']}>{user.fullName}</p>
                    </div>

                    <input
                      onChange={() => selectFriendHandler(user)}
                      className={classes['checkbox']}
                      type={'checkbox'}
                      disabled={isPartOfTheGroup}
                      checked={!!selectedFriendsIds[user._id]}
                    />
                  </div>
                );
              })}

              {data &&
                data.pages &&
                usersToInvite.length === 0 &&
                !loadingUsersToInvite &&
                searchValue === '' && <p>No Friends to Invite</p>}

              {data &&
                data.pages &&
                usersToInvite.length === 0 &&
                !loadingUsersToInvite &&
                searchValue !== '' && <p>No Results Found</p>}
              {(isLoading || isFetching || loadingUsersToInvite) && (
                <LoadingSpinner />
              )}
            </div>
          </div>

          <div className={classes['invited-friends-container']}>
            <div>
              <div className={classes['friends-selected-remove-all']}>
                <p className={classes['number-selected-friends']}>
                  {selectedFriends.length} Friends Selected
                </p>
                <p
                  onClick={removeAllSelectedFriendsHandler}
                  className={classes['remove-all-text']}
                >
                  Remove All
                </p>
              </div>
              <div className={classes['selected-friends-list']}>
                {selectedFriends.map((friend) => {
                  return (
                    <div className={classes['user-container']} key={friend._id}>
                      <div className={classes['user-image-name']}>
                        <img
                          alt="Friend"
                          src={friend.photo}
                          className={classes['user-image']}
                        />
                        <p className={classes['user-name']}>
                          {friend.fullName}
                        </p>
                      </div>

                      <img
                        alt="Remove"
                        src={RemoveIcon}
                        onClick={() => removeFriendHandler(friend._id)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <Button
              onClick={inviteHandler}
              style={{ alignSelf: 'flex-end' }}
              pink="true"
              disabled={selectedFriends.length === 0}
            >
              Invite
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
}

export default InviteFriendsModal;
