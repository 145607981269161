import React from 'react';
import { mapUsersWithWyreUsersRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';

function WyreUsersMappings() {
  const mapUsersWithWyreUsersHandler = async () => {
    try {
      await mapUsersWithWyreUsersRequest();

      notify('success', 'All Users mapped with Wyre Users!', 2000);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div style={{ width: 150, height: 150 }}>
      <button
        className="btn-white-glimpse"
        onClick={mapUsersWithWyreUsersHandler}
      >
        MAP USERS WITH WYRE USERS
      </button>
    </div>
  );
}

export default WyreUsersMappings;
