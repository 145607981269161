import React, { useState, useEffect } from 'react';
import classes from './Photos.module.css';
import Albums from './components/Albums/Albums';
import AlbumPhotos from './components/AlbumPhotos/AlbumPhotos';
import { getUserAlbumsForSpecificUserRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

function Photos() {
  const { userId } = useParams();

  const [, setPhotosOptions] = useState(['Albums']);
  const [selectedPhotoOption, setSelectedPhotoOption] = useState('Albums');

  const [, setShowAlbumPhotos] = useState(false);
  const [selectedAlbumId, setSelectedAlbumId] = useState(undefined);

  const { data, error } = useQuery(['user-image-albums', userId], () =>
    getUserAlbumsForSpecificUserRequest(userId)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <>
      <div className={classes['photos-container']}>
        {selectedPhotoOption === 'Albums' && (
          <Albums
            data={data}
            setPhotosOptions={setPhotosOptions}
            setSelectedPhotoOption={setSelectedPhotoOption}
            setSelectedAlbumId={setSelectedAlbumId}
            setShowAlbumPhotos={setShowAlbumPhotos}
          />
        )}
        {selectedPhotoOption === 'Album Photos' && (
          <AlbumPhotos
            setSelectedPhotoOption={setSelectedPhotoOption}
            selectedAlbumId={selectedAlbumId}
          />
        )}
      </div>
    </>
  );
}

export default Photos;
