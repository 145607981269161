import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import { AuthContext } from '../../context/auth-context';
import { CoinMarketCapContext } from '../../context/coinmarketcap-context';
import { getMyTransactionsRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import classes from './Wallet.module.css';
import { Link, useHistory } from 'react-router-dom';
import { IPFS_URL } from '../../constants/IPFS';
import ReactPlayer from 'react-player';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import TransferModal from './components/TransferModal/TransferModal';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import PlusIcon from '../../assets/deposit-icon.png';
import SendIcon from '../../assets/send-icon-2.png';
import { getFormattedNumber, limitString } from '../../helpers/helpers';
import DepositModal from './components/DepositModal/DepositModal';
import BuyIcon from '../../assets/buy-icon.png';
import MetaTags from 'react-meta-tags';
import GoBackIcon from '../../assets/go-back-icon-2.png';
import BuyGlmsModal from './components/BuyGlmsModal/BuyGlmsModal';
import PointsTransactions from './components/PointsTransactions/PointsTransactions';
import GlmsTransactions from './components/GlmsTransactions/GlmsTransactions';
// import WyreWalletInformation from './components/WyreWalletInformation/WyreWalletInformation';
// import PreKycModal from './components/PreKycModal/PreKycModal';
// import Button from '../../components/Button/Button';

const RESULTS_PER_PAGE = 10;

function Wallet() {
  const history = useHistory();
  const {
    profile,
    myWalletInformation,
    myLockedGLMSBalance,
    // myWyreWalletInformation,
  } = useContext(AuthContext);
  const { glmsToUsd } = useContext(CoinMarketCapContext);

  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showBuyGlmsModal, setShowBuyGlmsModal] = useState(false);
  // const [walletAddressCopied, setWalletAddressCopied] = useState(false);

  // const [showPreKycModal, setShowPreKycModal] = useState(false);

  const [
    selectedTransactionsSection,
    setSelectedTransactionsSection,
  ] = useState('Collectibles');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    'my-transactions',
    ({ pageParam = 1 }) =>
      getMyTransactionsRequest(pageParam, RESULTS_PER_PAGE),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 3000);
  }, [error]);

  // const copyWalletAddressHandler = async () => {
  //   await navigator.clipboard.writeText(myWalletInformation.walletAddress);

  //   setWalletAddressCopied(true);

  //   setTimeout(() => {
  //     setWalletAddressCopied(false);
  //   }, 2000);
  // };

  const renderTransactionAction = (transaction) => {
    if (transaction.from === profile?._id) {
      return <p className={classes['transaction-action']}>Sold</p>;
    } else if (transaction.to === profile?._id) {
      return <p className={classes['transaction-action']}>Purchased</p>;
    }

    return null;
  };

  const openTransferModalHandler = () => setShowTransferModal(true);

  const openDepositModalHandler = () => setShowDepositModal(true);

  const openBuyGlmsModalHandler = () => {
    setShowBuyGlmsModal(true);
  };

  const renderTransactionPrice = (transaction) => {
    if (transaction.from === profile?._id) {
      return (
        <div>
          <p className={classes['green-price']}>
            + {getFormattedNumber(transaction.price)} {transaction.currency}
          </p>

          <span className={classes['fee-price']}>
            -{' '}
            {getFormattedNumber(parseFloat(transaction.price / 10).toFixed(2))}{' '}
            {transaction.currency} (Fee)
          </span>
        </div>
      );
    } else if (transaction.to === profile?._id) {
      return (
        <p className={classes['red-price']}>
          - {getFormattedNumber(transaction.price)} {transaction.currency}
        </p>
      );
    }

    return null;
  };

  const renderTransactionDate = (transaction) => {
    const { date } = transaction;

    return (
      <p className={classes['transaction-date']}>
        {moment(date).format('MMMM Do, YYYY')}
      </p>
    );
  };

  let glimpseBalance = 0;
  if (myWalletInformation?.glimpseBalance) {
    glimpseBalance = parseFloat(myWalletInformation?.glimpseBalance).toFixed(4);
  }

  return (
    <>
      <MetaTags>
        <title>Wallet | Glimpse</title>
        <meta property="og:title" content={`Wallet | Glimpse`} />
      </MetaTags>

      {showTransferModal && (
        <TransferModal
          glimpseBalanceInUsd={glmsToUsd.price * parseFloat(glimpseBalance)}
          formattedGlimpseBalanceUsd={getFormattedNumber(
            glmsToUsd.price * parseFloat(glimpseBalance)
          )}
          formattedGlimpseBalance={getFormattedNumber(glimpseBalance)}
          glimpseBalance={glimpseBalance}
          setShowTransferModal={setShowTransferModal}
        />
      )}

      {showDepositModal && (
        <DepositModal setShowDepositModal={setShowDepositModal} />
      )}

      {showBuyGlmsModal && (
        <BuyGlmsModal setShowBuyGlmsModal={setShowBuyGlmsModal} />
      )}

      <div className={classes['wallet-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['wallet-container']}>
          <div className={classes['wallet-transactions-container']}>
            {/* <div className={classes['wallet-deposit-send-container']}>
              <div className={classes['wallet-content-container']}>
                {myWalletInformation ? (
                  <>
                    <div className={classes['balances-container']}>
                      <div>
                        <h3 className={classes['balance-text']}>Balance</h3>
                        <h2 className={classes['glimpse-balance-text']}>
                          {getFormattedNumber(
                            parseFloat(glimpseBalance).toFixed(2)
                          )}{' '}
                          GLMS
                        </h2>
                        <p className={classes['balance-usd']}>
                          ≈{' '}
                          {getFormattedNumber(
                            parseFloat(
                              glmsToUsd.price * parseFloat(glimpseBalance)
                            ).toFixed(2)
                          )}{' '}
                          USD
                        </p>
                      </div>

                      <div className={classes['vertical-line']}></div>

                      {myLockedGLMSBalance && (
                        <div className={classes['locked-balance-container']}>
                          <div>
                            <h3 className={classes['balance-text']}>
                              Locked Balance
                            </h3>
                            <h2 className={classes['glimpse-balance-text']}>
                              {getFormattedNumber(
                                parseFloat(myLockedGLMSBalance.total).toFixed(2)
                              )}{' '}
                              GLMS
                            </h2>
                            <p className={classes['balance-usd']}>
                              ≈{' '}
                              {getFormattedNumber(
                                parseFloat(
                                  glmsToUsd.price *
                                    parseFloat(myLockedGLMSBalance.total)
                                ).toFixed(2)
                              )}{' '}
                              USD
                            </p>
                          </div>
                          <div>
                            <div
                              className={classes['pending-offers-container']}
                            >
                              <p className={classes['pending-offers-text']}>
                                Pending Offers:
                              </p>
                              <p className={classes['pending-offers-value']}>
                                {parseFloat(
                                  myLockedGLMSBalance.myPendingOffersSum
                                ).toFixed(2)}{' '}
                                GLMS
                              </p>
                            </div>

                            <div className={classes['small-horizontal-line']}>
                              &nbsp;
                            </div>

                            <div
                              className={classes['requested-nfts-container']}
                            >
                              <p className={classes['requested-nfts-text']}>
                                Requested NFTs:{' '}
                              </p>

                              <p className={classes['requested-nfts-value']}>
                                {parseFloat(
                                  myLockedGLMSBalance.myPendingNftRequestsFeesSum
                                ).toFixed(2)}{' '}
                                GLMS
                              </p>
                            </div>
                          </div>
                     
                        </div>
                      )}
                    </div>

                    <div className={classes['address-text']}>
                      Address:{' '}
                      <span className={classes['address']}>
                        {myWalletInformation.walletAddress}
                      </span>
                      <div onClick={copyWalletAddressHandler}>
                        <img
                          alt="Copy"
                          className={classes['copy-icon']}
                          src={CopyIcon}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <p className={classes['getting-wallet-information-text']}>
                    Getting wallet information from Blockchain...
                  </p>
                )}

                {walletAddressCopied && (
                  <div className={classes['copied-container']}>
                    <img
                      alt="Copied"
                      src={PinkOkIcon}
                      className={classes['pinkOk-icon']}
                    />
                    <p className={classes['copied-text']}>
                      Wallet Address Copied!
                    </p>
                  </div>
                )}
              </div>

              {myWalletInformation && myLockedGLMSBalance && (
                <div className={classes['deposit-send-container']}>
                  <div className={classes['deposit-container']}>
                    <div
                      onClick={openDepositModalHandler}
                      className={classes['deposit-circle']}
                    >
                      <img
                        alt="Plus"
                        className={classes['plus-icon']}
                        src={PlusIcon}
                        width={20}
                        height={20}
                      />
                    </div>
                    <h3 className={classes['deposit-text']}>Deposit</h3>
                  </div>
                  <div className={classes['send-container']}>
                    <div
                      onClick={openTransferModalHandler}
                      className={classes['send-circle']}
                    >
                      <img
                        alt="Send"
                        className={classes['plus-icon']}
                        src={SendIcon}
                        width={30}
                        height={30}
                      />
                    </div>
                    <h3 className={classes['send-text']}>Send</h3>
                  </div>
                </div>
              )}
            </div> */}

            <div className={classes['wallet-deposit-buy-send-container']}>
              <header className={classes['wallet-header']}>
                <div className={classes['go-back-container']}>
                  <div onClick={() => history.push('/news-feed')}>
                    <img
                      src={GoBackIcon}
                      alt="Go Back"
                      className={classes['go-back-icon']}
                    />
                  </div>
                  <h3>Wallet</h3>
                </div>
              </header>

              <div className={classes['balances-container']}>
                <div className={classes['available-balance-container']}>
                  <h3 className={classes['available-balance-text']}>
                    Available Balance
                  </h3>

                  <div>
                    <h2 className={classes['glimpse-balance-text']}>
                      {getFormattedNumber(
                        parseFloat(glimpseBalance).toFixed(2)
                      )}{' '}
                      GLMS
                    </h2>
                    <p className={classes['balance-usd']}>
                      ≈{' '}
                      {getFormattedNumber(
                        parseFloat(
                          glmsToUsd.price * parseFloat(glimpseBalance)
                        ).toFixed(2)
                      )}{' '}
                      USD
                    </p>
                  </div>
                </div>

                <div className={classes['locked-balance-container']}>
                  <h3 className={classes['locked-balance-text']}>
                    Locked Balance
                  </h3>

                  <div>
                    <h2 className={classes['glimpse-balance-text']}>
                      {getFormattedNumber(
                        parseFloat(myLockedGLMSBalance.total).toFixed(2)
                      )}{' '}
                      GLMS
                    </h2>
                    <p className={classes['balance-usd']}>
                      ≈{' '}
                      {getFormattedNumber(
                        parseFloat(
                          glmsToUsd.price *
                            parseFloat(myLockedGLMSBalance.total)
                        ).toFixed(2)
                      )}{' '}
                      USD
                    </p>
                  </div>
                </div>

                <div className={classes['pending-balance-container']}>
                  <h3 className={classes['pending-balance-text']}>
                    Pending Balance
                  </h3>

                  <div className={classes['pending-options-container']}>
                    <div className={classes['pending-offers-container']}>
                      <p className={classes['pending-offers-text']}>Offers:</p>
                      <p className={classes['pending-offers-value']}>
                        {parseFloat(
                          myLockedGLMSBalance.myPendingOffersSum
                        ).toFixed(2)}{' '}
                        GLMS
                      </p>
                    </div>

                    <div className={classes['requested-nfts-container']}>
                      <p className={classes['requested-nfts-text']}>
                        Requested NFTs:{' '}
                      </p>

                      <p className={classes['requested-nfts-value']}>
                        {parseFloat(
                          myLockedGLMSBalance.myPendingNftRequestsFeesSum
                        ).toFixed(2)}{' '}
                        GLMS
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  onClick={openDepositModalHandler}
                  className={classes['deposit-container']}
                >
                  <img
                    src={PlusIcon}
                    alt="Deposit"
                    className={classes['deposit-icon']}
                  />

                  <h3 className={classes['deposit-text']}>Deposit</h3>
                </div>
                <div
                  onClick={openTransferModalHandler}
                  className={classes['send-container']}
                >
                  <img
                    src={SendIcon}
                    alt="Deposit"
                    className={classes['send-icon']}
                  />

                  <h3 className={classes['send-text']}>Send</h3>
                </div>
                <div
                  onClick={openBuyGlmsModalHandler}
                  className={classes['buy-container']}
                >
                  <img
                    src={BuyIcon}
                    alt="Deposit"
                    className={classes['buy-icon']}
                  />

                  <h3 className={classes['buy-text']}>Buy</h3>
                </div>
              </div>
            </div>

            {/* {showPreKycModal && (
              <PreKycModal setShowPreKycModal={setShowPreKycModal} />
            )}

            {profile?.role === 'admin' &&
              !profile.completedPreWyreKycInformation && (
                <Button
                  onClick={() => setShowPreKycModal(true)}
                  modernpinkcolor="true"
                >
                  Complete PRE KYC
                </Button>
              )} */}

            {/* {myWyreWalletInformation && profile?.role === 'admin' && (
              <WyreWalletInformation />
            )} */}

            <div className={classes['transactions-sections']}>
              <p
                className={
                  selectedTransactionsSection === 'Collectibles'
                    ? classes['selected-section']
                    : undefined
                }
                onClick={() => setSelectedTransactionsSection('Collectibles')}
              >
                Collectibles
              </p>

              <p
                className={
                  selectedTransactionsSection === 'Glms'
                    ? classes['selected-section']
                    : undefined
                }
                onClick={() => setSelectedTransactionsSection('Glms')}
              >
                GLMS
              </p>

              <p
                className={
                  selectedTransactionsSection === 'Points'
                    ? classes['selected-section']
                    : undefined
                }
                onClick={() => setSelectedTransactionsSection('Points')}
              >
                Diamonds
              </p>
            </div>

            {selectedTransactionsSection === 'Collectibles' && (
              <div className={classes['transactions-container']}>
                <h3 className={classes['transactions-text']}>Transactions</h3>

                <div className={classes['table-horizontal-line']}></div>

                <div className={classes['transactions-table']}>
                  <p>NFT</p>
                  <p>TITLE</p>
                  <p>ACTION</p>
                  <p>PRICE</p>
                  <p>DATE</p>

                  <div className={classes['table-horizontal-line']}></div>

                  {!isLoading &&
                    data &&
                    data.pages &&
                    data.pages[0].results === 0 && (
                      <p style={{ opacity: '.4', gridColumn: '1/-1' }}>
                        No Transactions Found!
                      </p>
                    )}

                  {!isLoading &&
                    data &&
                    data.pages &&
                    data.pages.map((page) => {
                      return page.data.map((transaction) => {
                        return (
                          <Link
                            to={`/nfts/${transaction.nft._id}`}
                            key={transaction._id}
                            className={classes['transaction']}
                          >
                            {transaction.nft.type === 'video' ? (
                              <ReactPlayer
                                width={44}
                                height={44}
                                style={{
                                  borderRadius: '50%',
                                  objectFit: 'cover',
                                }}
                                url={`${IPFS_URL}/${transaction.nft.ipfs}`}
                              />
                            ) : (
                              <img
                                width={44}
                                height={44}
                                className={classes['nft-image']}
                                src={
                                  transaction.nft.previewUrl ||
                                  `${IPFS_URL}/${transaction.nft.ipfs}`
                                }
                                alt="IPFS"
                              />
                            )}
                            <p
                              title={transaction.nft.title}
                              className={classes['nft-title']}
                            >
                              {limitString(transaction.nft.title, 9)}
                            </p>
                            {renderTransactionAction(transaction)}
                            {renderTransactionPrice(transaction)}
                            {renderTransactionDate(transaction)}
                          </Link>
                        );
                      });
                    })}
                  {(isLoading || isFetching) && <LoadingSpinner />}
                </div>
              </div>
            )}
            {selectedTransactionsSection === 'Points' && <PointsTransactions />}
            {selectedTransactionsSection === 'Glms' && <GlmsTransactions />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Wallet;
