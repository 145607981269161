import React from 'react';
import classes from './Support.module.css';

function Support() {
  return (
    <div className={classes['support-container']}>
      <p className={classes['support-text']}>
        Contact us at support@glimpsenft.com for questions or concerns.
      </p>
    </div>
  );
}

export default Support;
