import React, { useContext, useEffect } from 'react';
import classes from './WyreTransfers.module.css';
import { useQuery } from 'react-query';
import { getAllWyreTransfersRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { Col, Row } from 'antd';
import { AuthContext } from '../../../../context/auth-context';
import { Link } from 'react-router-dom';
import moment from 'moment';

function WyreTransfers() {
  const { profile } = useContext(AuthContext);
  const { isLoading, data, error } = useQuery(
    'wyre-transfers',
    getAllWyreTransfersRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['all-transfers-container']}>
      <div className={classes['heading-container']}>
        <h2 className={classes['all-users-heading']}>
          WYRE TRANSFERS = {data?.data.length}
        </h2>
      </div>

      <div
        style={{ minHeight: '85vh' }}
        id="table-nft"
        className={classes['likes-container']}
      >
        <Row
          style={{ margin: 10, borderBottom: '1px solid white' }}
          justify="space-between"
        >
          <Col md={4} className={classes['pagination-col']}>
            Id
          </Col>
          <Col md={3} className={classes['pagination-col']}>
            From
          </Col>
          <Col md={3} className={classes['pagination-col']}>
            To
          </Col>

          <Col md={2} className={classes['pagination-col']}>
            Amount
          </Col>

          <Col md={2} className={classes['pagination-col']}>
            Currency
          </Col>

          <Col md={3} className={classes['pagination-col']}>
            Status
          </Col>

          <Col md={2} className={classes['pagination-col']}>
            Payment For
          </Col>

          <Col md={4} className={classes['pagination-col']}>
            Updated At
          </Col>
        </Row>

        {data?.data &&
          data.data.map(
            ({
              _id,
              from,
              to,
              amount,
              currency,
              status,
              paymentFor,
              updatedAt,
            }) => {
              const isGlimpseFee = !to;

              return (
                <Row
                  justify="space-between"
                  style={{ margin: 10, borderBottom: '1px solid white' }}
                >
                  <Col md={4} className={classes['pagination-col']}>
                    {_id}
                  </Col>

                  <Col md={3} className={classes['pagination-col']}>
                    <Link
                      to={
                        from._id === profile?._id
                          ? '/my-space'
                          : `/user-feed/${from._id}`
                      }
                    >
                      <img
                        width={25}
                        height={25}
                        style={{ borderRadius: 100, objectFit: 'cover' }}
                        src={from.photo}
                        alt={from.fullName}
                      />
                      <p className={classes['user-fullName']}>
                        {from.fullName}
                      </p>
                    </Link>
                  </Col>
                  {!isGlimpseFee ? (
                    <Col md={3} className={classes['pagination-col']}>
                      <Link
                        to={
                          to._id === profile?._id
                            ? '/my-space'
                            : `/user-feed/${to._id}`
                        }
                      >
                        <img
                          width={25}
                          height={25}
                          style={{ borderRadius: 100, objectFit: 'cover' }}
                          src={to.photo}
                          alt={to.fullName}
                        />
                        <p className={classes['user-fullName']}>
                          {to.fullName}
                        </p>
                      </Link>
                    </Col>
                  ) : (
                    <Col md={3}>Glimpse Fee (10%)</Col>
                  )}
                  <Col md={2} className={classes['pagination-col']}>
                    {parseFloat(amount).toFixed(2)}
                  </Col>
                  <Col md={2} className={classes['pagination-col']}>
                    {currency}
                  </Col>
                  <Col md={3} className={classes['pagination-col']}>
                    {status}
                  </Col>
                  <Col md={2} className={classes['pagination-col']}>
                    {paymentFor}
                  </Col>
                  <Col md={4} className={classes['pagination-col']}>
                    {moment(updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
                  </Col>
                </Row>
              );
            }
          )}

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default WyreTransfers;
