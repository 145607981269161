import React, { useEffect } from 'react';
import classes from './MyNftCollections.module.css';
import { useQuery } from 'react-query';
import { getMyNftCollectionsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import NftCollection from '../../../../components/NftCollection/NftCollection';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

function MyNftCollections() {
  const { isLoading, data, error } = useQuery(
    'my-nft-collections',
    getMyNftCollectionsRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['my-nft-collections-page']}>
      {data?.data && data.data.length === 0 && (
        <div className={classes['no-collections-found-container']}>
          <p className={classes['no-collections-text']}>
            No Collections Found!
          </p>
        </div>
      )}
      <div className={classes['nft-collections-list']}>
        {data?.data &&
          data.data.map((nftCollection) => (
            <NftCollection
              key={nftCollection._id}
              nftCollection={nftCollection}
            />
          ))}
      </div>
      {isLoading && <LoadingSpinner />}
    </div>
  );
}

export default MyNftCollections;
