import React, { useState } from 'react';
import classes from './BenefitsForHoldersModal.module.css';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Modal from '../../../../components/Modal/Modal';
import { updateNftCollectionHolderBenefitsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { useQueryClient } from 'react-query';

function BenefitsForHoldersModal(props) {
  const queryClient = useQueryClient();
  const { setShowBenefitsForHoldersModal, nftCollection } = props;
  const initialHolderBenefits =
    nftCollection.holderBenefits?.length > 0
      ? nftCollection.holderBenefits
      : [''];
  const [benefits, setBenefits] = useState(
    initialHolderBenefits.map((benefit) => ({
      value: benefit,
      isValid: true,
      touched: true,
    }))
  );

  const closeModalHandler = () => {
    setShowBenefitsForHoldersModal(false);
  };

  const benefitChangeHandler = (index, value) => {
    const updatedBenefits = [...benefits];

    updatedBenefits[index] = {
      value,
      isValid: value.length > 0,
      touched: true,
    };

    setBenefits(updatedBenefits);
  };

  const addAnotherBenefitHandler = () => {
    setBenefits((prevState) => [
      ...prevState,
      {
        value: '',
        isValid: false,
        touched: false,
      },
    ]);
  };

  const updateNftCollectionHolderBenefitsHandler = async () => {
    try {
      const requestBody = {
        holderBenefits: benefits.map(({ value }) => value),
      };
      await updateNftCollectionHolderBenefitsRequest(
        nftCollection._id,
        requestBody
      );

      queryClient.invalidateQueries([
        'nft-collection-information',
        nftCollection._id,
      ]);
      queryClient.refetchQueries([
        'nft-collection-information',
        nftCollection._id,
      ]);

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const isAddAnotherOptionDisabled = () =>
    benefits.some((benefit) => !benefit.isValid || !benefit.touched);

  return (
    <Modal
      style={{ height: 500 }}
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={isAddAnotherOptionDisabled()}
            onClick={updateNftCollectionHolderBenefitsHandler}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Benefits For Holders"
    >
      <div className={classes['benefits-container']}>
        {benefits.map((benefit, i) => {
          const inputStyle = { width: '100%' };
          if (benefit.isValid === false && benefit.touched) {
            inputStyle['border'] = '1px solid red';
          }

          return (
            <div key={i}>
              <p className={classes['label']}>Benefit {i + 1}</p>
              <Input
                style={inputStyle}
                placeholder="Enter here"
                value={benefit.value}
                onChange={(e) => benefitChangeHandler(i, e.target.value)}
              />
            </div>
          );
        })}
      </div>
      <Button
        onClick={addAnotherBenefitHandler}
        disabled={isAddAnotherOptionDisabled()}
        style={{ margin: '10px 0', width: '100%' }}
        darkpink="true"
      >
        + Add Another Benefit
      </Button>
    </Modal>
  );
}

export default BenefitsForHoldersModal;
