import React, { useContext, useState } from 'react';
import classes from './EditSocialLinksModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import TelegramIcon from '../../../../assets/telegram.png';
import TwitterIcon from '../../../../assets/twitter.png';
import LinkedinIcon from '../../../../assets/linkedin-1.png';
import YoutubeIcon from '../../../../assets/youtube.png';
import InstagramIcon from '../../../../assets/instagram.png';
import LinktreeIcon from '../../../../assets/linktree.png';
import ImdbIcon from '../../../../assets/imdb.png';
import SliderButton from '../../../../components/SliderButton/SliderButton';
import Input from '../../../../components/Input/Input';
import notify from '../../../../utils/notify';
import { updateMySocialLinksRequest } from '../../../../httpRequests/httpRequests';
import { AuthContext } from '../../../../context/auth-context';

function EditSocialLinksModal(props) {
  const { setShowEditSocialLinksModal } = props;
  const { profile, getMyProfile } = useContext(AuthContext);

  const [telegramUrl, setTelegramUrl] = useState(
    profile.socialLinks?.telegram || ''
  );
  const [isTelegramUrlValid, setIsTelegramUrlValid] = useState(
    !!profile.socialLinks?.telegram || false
  );
  const [includeTelegramUrl, setIncudeTelegramUrl] = useState(
    !!profile.socialLinks?.telegram || false
  );

  const [twitterUrl, setTwitterUrl] = useState(
    profile.socialLinks?.twitter || ''
  );
  const [isTwitterUrlValid, setIsTwitterUrlValid] = useState(
    !!profile.socialLinks?.twitter || false
  );
  const [includeTwitterUrl, setIncudeTwitterUrl] = useState(
    !!profile.socialLinks?.twitter || false
  );

  const [linkedinUrl, setLinkedinUrl] = useState(
    profile.socialLinks?.linkedin || ''
  );
  const [isLinkedinUrlValid, setIsLinkedinUrlValid] = useState(
    !!profile.socialLinks?.linkedin || false
  );
  const [includeLinkedinUrl, setIncudeLinkedinUrl] = useState(
    !!profile.socialLinks?.linkedin || false
  );

  const [youtubeUrl, setYoutubeUrl] = useState(
    profile.socialLinks?.youtube || ''
  );
  const [isYoutubeUrlValid, setIsYoutubeUrlValid] = useState(
    !!profile.socialLinks?.youtube || false
  );
  const [includeYoutubeUrl, setIncludeYoutubeUrl] = useState(
    !!profile.socialLinks?.youtube || false
  );

  const [instagramUrl, setInstagramUrl] = useState(
    profile.socialLinks?.instagram || ''
  );
  const [isInstagramUrlValid, setIsInstagramUrlValid] = useState(
    !!profile.socialLinks?.instagram || false
  );
  const [includeInstagramUrl, setIncludeInstagramUrl] = useState(
    !!profile.socialLinks?.instagram || false
  );

  const [linktreeUrl, setLinktreeUrl] = useState(
    profile.socialLinks?.linktree || ''
  );
  const [isLinktreeUrlValid, setIsLinktreeUrlValid] = useState(
    !!profile.socialLinks?.linktree || false
  );
  const [includeLinktreeUrl, setIncludeLinktreeUrl] = useState(
    !!profile.socialLinks?.linktree || false
  );

  const [imdbUrl, setImdbUrl] = useState(profile.socialLinks?.imdb || '');
  const [isImdbUrlValid, setIsImdbUrlValid] = useState(
    !!profile.socialLinks?.imdb || false
  );
  const [includeImdbUrl, setIncludeImdbUrl] = useState(
    !!profile.socialLinks?.imdb || false
  );

  const telegramUrlChangeHandler = (e) => {
    setTelegramUrl(e.target.value);
    const telegramRegex = /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/;
    setIsTelegramUrlValid(telegramRegex.test(e.target.value));
  };

  const twitterUrlChangeHandler = (e) => {
    setTwitterUrl(e.target.value);
    // eslint-disable-next-line
    const twitterRegex = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/;
    setIsTwitterUrlValid(twitterRegex.test(e.target.value));
  };

  const linkedinUrlChangeHandler = (e) => {
    setLinkedinUrl(e.target.value);
    const linkedinRegex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;
    setIsLinkedinUrlValid(linkedinRegex.test(e.target.value));
  };

  const youtubeUrlChangeHandler = (e) => {
    setYoutubeUrl(e.target.value);
    // eslint-disable-next-line
    const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/;
    setIsYoutubeUrlValid(youtubeRegex.test(e.target.value));
  };

  const instagramUrlChangeHandler = (e) => {
    setInstagramUrl(e.target.value);
    // eslint-disable-next-line
    const instagramRegex = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;
    setIsInstagramUrlValid(instagramRegex.test(e.target.value));
  };

  const linktreeUrlChangeHandler = (e) => {
    setLinktreeUrl(e.target.value);
    setIsLinktreeUrlValid(e.target.value.includes('linktr.ee/'));
  };

  const imdbUrlChangeHandler = (e) => {
    setImdbUrl(e.target.value);
    setIsImdbUrlValid(e.target.value.includes('imdb.com/'));
  };

  const isSaveButtonDisabled = () => {
    if (includeTelegramUrl && !isTelegramUrlValid) return true;
    if (includeTwitterUrl && !isTwitterUrlValid) return true;
    if (includeLinkedinUrl && !isLinkedinUrlValid) return true;
    if (includeYoutubeUrl && !isYoutubeUrlValid) return true;
    if (includeInstagramUrl && !isInstagramUrlValid) return true;
    if (includeLinktreeUrl && !isLinktreeUrlValid) return true;
    if (includeImdbUrl && !isImdbUrlValid) return true;

    return false;
  };

  const closeModalHandler = () => {
    setShowEditSocialLinksModal(false);
  };

  const updateSocialLinksHandler = async () => {
    try {
      const requestBody = {};
      if (includeTelegramUrl) requestBody['telegramUrl'] = telegramUrl;
      if (includeTwitterUrl) requestBody['twitterUrl'] = twitterUrl;
      if (includeLinkedinUrl) requestBody['linkedinUrl'] = linkedinUrl;
      if (includeYoutubeUrl) requestBody['youtubeUrl'] = youtubeUrl;
      if (includeInstagramUrl) requestBody['instagramUrl'] = instagramUrl;
      if (includeLinktreeUrl) requestBody['linktreeUrl'] = linktreeUrl;
      if (includeImdbUrl) requestBody['imdbUrl'] = imdbUrl;

      await updateMySocialLinksRequest(requestBody);

      getMyProfile();

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const telgramUrlInputStyle = { width: '100%' };
  if (!isTelegramUrlValid && includeTelegramUrl) {
    telgramUrlInputStyle['border'] = '1px solid red';
  }

  const twitterUrlInputStyle = { width: '100%' };
  if (!isTwitterUrlValid && includeTwitterUrl) {
    twitterUrlInputStyle['border'] = '1px solid red';
  }

  const linkedinUrlInputStyle = { width: '100%' };
  if (!isLinkedinUrlValid && includeLinkedinUrl) {
    linkedinUrlInputStyle['border'] = '1px solid red';
  }

  const youtubeUrlInputStyle = { width: '100%' };
  if (!isYoutubeUrlValid && includeYoutubeUrl) {
    youtubeUrlInputStyle['border'] = '1px solid red';
  }

  const instagramUrlInputStyle = { width: '100%' };
  if (!isInstagramUrlValid && includeInstagramUrl) {
    instagramUrlInputStyle['border'] = '1px solid red';
  }

  const linktreeInputStyle = { width: '100%' };
  if (!isLinktreeUrlValid && includeLinktreeUrl) {
    linktreeInputStyle['border'] = '1px solid red';
  }

  const imdbInputStyle = { width: '100%' };
  if (!isImdbUrlValid && includeImdbUrl) {
    imdbInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      style={{ minHeight: 500 }}
      closeModal={closeModalHandler}
      title="Info"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            onClick={updateSocialLinksHandler}
            disabled={isSaveButtonDisabled()}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
    >
      <div className={classes['socials-container']}>
        <p className={classes['social-details-text']}>Social Details</p>
        <div className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Telegram"
              src={TelegramIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Telegram</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeTelegramUrl}
            setIsLeftContentSelected={setIncudeTelegramUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={telgramUrlInputStyle}
          value={telegramUrl}
          onChange={telegramUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Twitter"
              src={TwitterIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Twitter</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeTwitterUrl}
            setIsLeftContentSelected={setIncudeTwitterUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={twitterUrlInputStyle}
          value={twitterUrl}
          onChange={twitterUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Linkedin"
              src={LinkedinIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Linkedin</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeLinkedinUrl}
            setIsLeftContentSelected={setIncudeLinkedinUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={linkedinUrlInputStyle}
          value={linkedinUrl}
          onChange={linkedinUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Youtube"
              src={YoutubeIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Youtube</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeYoutubeUrl}
            setIsLeftContentSelected={setIncludeYoutubeUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={youtubeUrlInputStyle}
          value={youtubeUrl}
          onChange={youtubeUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Instagram"
              src={InstagramIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Instagram</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeInstagramUrl}
            setIsLeftContentSelected={setIncludeInstagramUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={instagramUrlInputStyle}
          value={instagramUrl}
          onChange={instagramUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Linktree"
              src={LinktreeIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Linktree</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeLinktreeUrl}
            setIsLeftContentSelected={setIncludeLinktreeUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={linktreeInputStyle}
          value={linktreeUrl}
          onChange={linktreeUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img alt="IMDB" src={ImdbIcon} className={classes['social-icon']} />
            <p className={classes['social-text']}>IMDB</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeImdbUrl}
            setIsLeftContentSelected={setIncludeImdbUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={imdbInputStyle}
          value={imdbUrl}
          onChange={imdbUrlChangeHandler}
        />
      </div>
    </Modal>
  );
}

export default EditSocialLinksModal;
