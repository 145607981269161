import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getSpecificGroupRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './GroupAbout.module.css';
import PrivateIcon from '../../../../assets/private-1.png';
import GeneralIcon from '../../../../assets/general.png';
import PublicIcon from '../../../../assets/public.png';
import { getDateFrom, limitString } from '../../../../helpers/helpers';

function GroupAbout(props) {
  const { selectedGroup } = props;

  const { data, error } = useQuery(['groups', selectedGroup._id], () =>
    getSpecificGroupRequest(selectedGroup._id)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['group-about-container']}>
      <div>
        <h3 className={classes['introduction-text']}>About</h3>
      </div>

      <div className={classes['about-container']}>
        <p className={classes['introduction-content']}>
          {data?.data && limitString(data?.data.about, 80)}
        </p>
        {data?.data && (
          <div className={classes['status-container']}>
            <img
              src={data.data.status === 'private' ? PrivateIcon : PublicIcon}
              alt="Private"
              className={classes['private-icon']}
            />

            <div>
              <p className={classes['status-text']}>
                {data.data.status.substring(0, 1).toUpperCase() +
                  data.data.status.substring(1)}{' '}
              </p>
              <p className={classes['only-members']}>
                {data.data.status === 'public'
                  ? ` Anyone can see who's in the group and what they post.`
                  : `Only members can see who’s in the group and what they post`}
              </p>
            </div>
          </div>
        )}

        <div>
          <div className={classes['general-createdAt']}>
            <img
              alt="General"
              src={GeneralIcon}
              className={classes['general-icon']}
            />
            <p className={classes['createdAt-text']}>
              Created {data?.data && `${getDateFrom(data.data.createdAt)} ago`}
            </p>
          </div>

          {data?.data?.lastActivity && (
            <div className={classes['general-createdAt']}>
              <p className={classes['createdAt-text']}>
                Last Activity {`${getDateFrom(data.data.lastActivity)} ago`}
              </p>
            </div>
          )}
        </div>
      </div>

      {/* <div style={{ marginTop: 20 }}>
        <div className={classes['members-container']}>
          <h3 className={classes['members-text']}>Members</h3>
          <p className={classes['circle']}></p>
          <p className={classes['members-count-text']}>
            {numberOfMembersResponse.data?.data}
          </p>
        </div>

        <div className={classes['admins-list']}>
          {groupAdmins.slice(0, 5).map((admin, i) => (
            <img
              key={admin._id}
              style={{ zIndex: 11 - i }}
              src={admin.photo}
              alt="Admin"
              className={classes['user-image']}
            />
          ))}
        </div>
        <p className={classes['admin-numbers']}>
          {groupAdmins.length} admins in this group
        </p>
      </div> */}
    </div>
  );
}

export default GroupAbout;
