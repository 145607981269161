import React, { useContext } from 'react';
import classes from './RequestedNftFromMe.module.css';
import Button from '../../../../components/Button/Button';
import moment from 'moment';
import FinishedIcon from '../../../../assets/finished.png';
import PendingIcon from '../../../../assets/wall-clock.png';
import RejectedIcon from '../../../../assets/white-remove-icon.png';
import { rejectNftRequest } from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import notify from '../../../../utils/notify';
import { AuthContext } from '../../../../context/auth-context';

function RequestedNftFromMe(props) {
  const { getMyLockedGLMSBalance } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { requestFor, _id, status, createdAt, setSelectedRequestedNft } = props;

  const cancelNftRequestHandler = async () => {
    try {
      await rejectNftRequest(_id);
      queryClient.invalidateQueries(['my-requested-nfts']);
      queryClient.refetchQueries(['my-requested-nfts']);
      getMyLockedGLMSBalance();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const viewDetailsHandler = () => {
    setSelectedRequestedNft();
  };

  const renderStatusIcon = () => {
    if (status === 'Pending') {
      return (
        <div className={classes['pending-status-container']}>
          <img
            className={classes['pending-icon']}
            src={PendingIcon}
            alt="Pending"
          />
        </div>
      );
    } else if (status === 'Finished') {
      return (
        <div className={classes['finished-status-container']}>
          <img
            className={classes['finished-icon']}
            src={FinishedIcon}
            alt="Finished"
          />
        </div>
      );
    } else if (status === 'Rejected') {
      return (
        <div className={classes['rejected-status-container']}>
          <p
            className={classes['rejected-icon']}
            src={RejectedIcon}
            alt="Rejected"
          >
            X
          </p>
        </div>
      );
    }
  };

  return (
    <div className={classes['requested-nft-container']}>
      <div className={classes['from-info-container']}>
        <img
          src={requestFor.photo}
          alt={requestFor.fullName}
          className={classes['user-image']}
        />
        <div>
          <p className={classes['from-fullName-text']}>{requestFor.fullName}</p>
          <p className={classes['received-on']}>
            Received: {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>

      {renderStatusIcon()}

      <div className={classes['view-details-container']}>
        {status === 'Pending' ? (
          <div className={classes['actions-container']}>
            <Button
              style={{ flex: 1 }}
              onClick={viewDetailsHandler}
              darkpink="true"
            >
              View Details
            </Button>
            <Button darkpinkcolor="true" onClick={cancelNftRequestHandler}>
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            onClick={viewDetailsHandler}
            style={{ width: '100%' }}
            darkpinkcolor="true"
          >
            View Details
          </Button>
        )}
      </div>
    </div>
  );
}

export default RequestedNftFromMe;
