import React, { useContext, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { CoinMarketCapContext } from '../../../../context/coinmarketcap-context';
import TransactionVerificationModal from './components/TransactionVerificationModal/TransactionVerificationModal';
import {
  createTransferRequest,
  searchUsersRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import Input from '../../../../components/Input/Input';
import classes from './TransferModal.module.css';
import Button from '../../../../components/Button/Button';
import { AuthContext } from '../../../../context/auth-context';
import { getFormattedNumber, limitString } from '../../../../helpers/helpers';
import VerifiedIcon from '../../../../assets/verified.png';

function TransferModal(props) {
  const { setShowTransferModal, glimpseBalance } = props;

  const { glmsToUsd } = useContext(CoinMarketCapContext);
  const { myWalletInformation, myLockedGLMSBalance, profile } = useContext(
    AuthContext
  );

  const [walletAddress, setWalletAddress] = useState('');
  const [walletAddressValid, setWalletAddressValid] = useState(false);
  const [walletAddressTouched, setWalletAddressTouched] = useState(false);
  const [glmsToTransferValue, setGlmsToTransferValue] = useState('');
  const [glmsToTransferValueTouched, setGlmsToTransferValueTouched] = useState(
    false
  );
  const [
    showTransactionVerificationModal,
    setShowTransactionVerificationModal,
  ] = useState(false);
  const [
    sendingVerificationCodeToEmail,
    setSendingVerificationCodeToEmail,
  ] = useState(false);

  const [toFullName, setToFullName] = useState('');
  const [showUsers, setShowUsers] = useState(false);
  const [users, setUsers] = useState([]);

  const closeModalHandler = () => {
    setShowTransferModal(false);
  };

  const openTransactionVerificationModalHandler = async () => {
    setSendingVerificationCodeToEmail(true);

    try {
      const requestBody = {
        glmsAmount: parseFloat(glmsToTransferValue),
      };

      await createTransferRequest(walletAddress, requestBody);

      setShowTransactionVerificationModal(true);
    } catch (err) {
      notify('error', err, 2000);
    }

    setSendingVerificationCodeToEmail(false);
  };

  const glmsToTransferChangeHandler = (e) => {
    const value = parseFloat(e.target.value);
    setGlmsToTransferValue(value);
    setGlmsToTransferValueTouched(true);
  };

  const walletAddressChangeHandler = (e) => {
    const walletAddressValue = e.target.value;

    setWalletAddress(walletAddressValue);
    setWalletAddressTouched(true);
    if (
      walletAddressValue.length === 42 &&
      walletAddressValue.startsWith('0x')
    ) {
      setWalletAddressValid(true);
    } else {
      setWalletAddressValid(false);
    }
  };

  const toFullNameChangeHandler = async (e) => {
    try {
      e.persist();
      setToFullName(e.target.value);
      setShowUsers(e.target.value ? true : false);
      const { data } = await searchUsersRequest(e.target.value);
      setUsers(data);
    } catch (err) {
      notify('error', err, 1000);
    }
  };

  const selectUserHandler = (user) => {
    if (user.walletAddress) {
      setWalletAddressValid(true);
      setWalletAddress(user.walletAddress);
      setUsers([]);
      setShowUsers(false);
      setToFullName(`${user.firstName} ${user.lastName}`);
    }
  };

  const glmsToTransferInUsd = glmsToTransferValue
    ? (glmsToUsd.price * glmsToTransferValue).toFixed(2)
    : 0;

  let isGlmsToTransferValueValid =
    !!glmsToTransferValue &&
    !isNaN(glmsToTransferValue) &&
    parseFloat(glmsToTransferValue) > 0;

  const glmsToTransferValueStyle = {
    width: '100%',
  };
  if (!isGlmsToTransferValueValid && glmsToTransferValueTouched) {
    glmsToTransferValueStyle['border'] = '1px solid red';
  }

  const walletAddressInputStyle = {
    width: '100%',
  };
  if (
    (!walletAddressValid && walletAddressTouched) ||
    myWalletInformation?.walletAddress === walletAddress
  ) {
    walletAddressInputStyle['border'] = '1px solid red';
  }

  const toFullNameStyle = { width: '100%' };

  const isFormValid =
    walletAddressValid &&
    !!isGlmsToTransferValueValid &&
    myWalletInformation?.walletAddress !== walletAddress;

  return (
    <>
      {showTransactionVerificationModal && (
        <TransactionVerificationModal
          setShowTransferModal={setShowTransferModal}
          walletAddress={walletAddress}
          glmsToTransferValue={glmsToTransferValue}
          glmsToTransferInUsd={glmsToTransferInUsd}
          setShowTransactionVerificationModal={
            setShowTransactionVerificationModal
          }
        />
      )}

      {!showTransactionVerificationModal && (
        <Modal
          style={{ height: '90%' }}
          footer={
            <div className={classes['footer']}>
              <Button
                style={{ width: 200 }}
                darkpinkcolor="true"
                onClick={closeModalHandler}
              >
                Cancel
              </Button>
              <Button
                style={{ width: '100%' }}
                darkpink="true"
                disabled={!isFormValid || sendingVerificationCodeToEmail}
                onClick={openTransactionVerificationModalHandler}
              >
                Get Verification Code
              </Button>
            </div>
          }
          closeModal={closeModalHandler}
          title="Send"
        >
          <div>
            <div>
              <p className={classes['address-text']}>To: (Optional)</p>
              <Input
                placeholder="Enter here"
                type="text"
                value={toFullName}
                style={toFullNameStyle}
                onChange={toFullNameChangeHandler}
              />

              {showUsers && users.length > 0 && (
                <div className={classes['users-container']}>
                  {users.map((user) => {
                    const fullName = `${user.firstName} ${user.lastName}`;
                    const { username } = user;

                    if (user._id === profile?._id) return null;

                    return (
                      <div
                        onClick={() => selectUserHandler(user)}
                        className={classes['user-container']}
                        key={user._id}
                      >
                        <img
                          alt="userPhoto"
                          className={classes['user-image']}
                          src={user.photo}
                        />
                        <p
                          title={`${fullName} @${username}`}
                          className={classes['user-text']}
                        >
                          {fullName} @{limitString(username, 14)}
                          {user.verified && (
                            <img
                              alt="Verified"
                              src={VerifiedIcon}
                              style={{
                                height: 16,
                                marginLeft: 6,
                                marginBottom: 2,
                              }}
                            />
                          )}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <p className={classes['address-text']}>Address</p>
            <Input
              placeholder="Enter here"
              type="text"
              value={walletAddress}
              style={walletAddressInputStyle}
              onChange={walletAddressChangeHandler}
            />

            <p className={classes['amount-text']}>Amount</p>
            <Input
              placeholder="0.00"
              type={'number'}
              value={glmsToTransferValue}
              style={glmsToTransferValueStyle}
              onChange={glmsToTransferChangeHandler}
            />

            <div className={classes['available-transfer-amount-container']}>
              <div className={classes['available-balance-container']}>
                <p className={classes['available-balance']}>
                  Available Balance
                </p>
                <p className={classes['glimpse-balance']}>
                  {getFormattedNumber(
                    parseFloat(
                      parseFloat(glimpseBalance) - myLockedGLMSBalance.total
                    ).toFixed(2)
                  )}{' '}
                  GLMS
                </p>
                <p className={classes['glimpse-balance-usd']}>
                  $
                  {getFormattedNumber(
                    (
                      parseFloat(
                        parseFloat(glimpseBalance) - myLockedGLMSBalance.total
                      ) * glmsToUsd.price
                    ).toFixed(2)
                  )}
                </p>
              </div>

              <div className={classes['amount-container']}>
                <p className={classes['transfer-amount-text']}>Amount</p>
                <p className={classes['glimpse-balance']}>
                  {getFormattedNumber(glmsToTransferValue || 0)} GLMS
                </p>

                <p className={classes['glimpse-balance-usd']}>
                  $
                  {isNaN(parseFloat(glmsToTransferValue) * glmsToUsd.price)
                    ? 0.0
                    : getFormattedNumber(
                        (
                          parseFloat(glmsToTransferValue) *
                          (glmsToUsd.price || 0)
                        ).toFixed(2)
                      )}
                </p>
              </div>
            </div>

            <div className={classes['remaining-container']}>
              <p className={classes['remaining-balance-text']}>
                Remaining Balance
              </p>
              <div>
                <p className={classes['glimpse-balance']}>
                  {getFormattedNumber(
                    (
                      parseFloat(glimpseBalance) -
                      parseFloat(glmsToTransferValue || 0) -
                      myLockedGLMSBalance.total
                    ).toFixed(2)
                  )}{' '}
                  GLMS
                </p>
                <p className={classes['glimpse-balance-usd']}>
                  $
                  {getFormattedNumber(
                    (
                      (parseFloat(glimpseBalance) -
                        parseFloat(glmsToTransferValue || 0) -
                        myLockedGLMSBalance.total) *
                      glmsToUsd.price
                    ).toFixed(2)
                  )}
                </p>

                <div
                  style={{ margin: '10px 0' }}
                  className={classes['horizontal-line']}
                ></div>

                <p className={classes['glimpse-balance-fee']}>
                  - 250 GLMS ( ${parseFloat(250 * glmsToUsd.price).toFixed(2)})
                  Fee{' '}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default TransferModal;
