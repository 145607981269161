import React, { useRef, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getDateFrom, limitString } from '../../../../../../helpers/helpers';
import { getMyGroupsIHaveJoinedRequest } from '../../../../../../httpRequests/httpRequests';
import GroupPresentationImage from '../../../../../../assets/group-presentation-image.png';
import classes from './GroupsIHaveJoined.module.css';
import notify from '../../../../../../utils/notify';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { Link } from 'react-router-dom';

const RESULTS_PER_PAGE = 5;

function GroupsIHaveJoined(props) {
  const { searchValue } = props;
  const groupsListRef = useRef();

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-groups-i-have-joined', searchValue],
    ({ pageParam = 1 }) =>
      getMyGroupsIHaveJoinedRequest(pageParam, RESULTS_PER_PAGE, searchValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    let fetching = false;
    const groupListContainerElement = groupsListRef.current;

    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.4) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    groupListContainerElement.addEventListener('scroll', scrollHandler);
    return () => {
      groupListContainerElement.removeEventListener('scroll', scrollHandler);
    };
  }, [hasNextPage, fetchNextPage]);

  return (
    <div
      style={props.style ? props.style : {}}
      className={classes['groups-as-admin-container']}
    >
      <h4 className={classes['groups-you-manage-text']}>
        Groups you have joined
      </h4>

      <div ref={groupsListRef} className={classes['groups-list']}>
        {!isLoading && data?.pages && data.pages[0].results === 0 && (
          <p className={classes['no-groups-found']}>No Groups Found</p>
        )}
        {!isLoading &&
          data?.pages &&
          data.pages.map((page) => {
            return page.data.map(({ group }) => {
              return (
                <Link
                  to={`/my-groups?id=${group._id}`}
                  key={group._id}
                  className={`${classes['group']}`}
                >
                  {group.coverPhoto ? (
                    <img
                      className={classes['cover-photo']}
                      src={group.coverPhoto}
                      alt="Group Cover"
                    />
                  ) : (
                    <img
                      className={classes['cover-photo']}
                      src={GroupPresentationImage}
                      alt={'Group Cover'}
                    />
                  )}
                  <div>
                    <p title={group.name} className={classes['group-name']}>
                      {limitString(group.name, 12)}
                    </p>
                    <p className={classes['last-active']}>
                      {group.lastActive
                        ? `Last active ${
                            getDateFrom(group.lastActive) === 'now'
                              ? 'some seconds ago'
                              : `${getDateFrom(group.lastActive)} ago`
                          }`
                        : `Created ${
                            getDateFrom(group.createdAt) === 'now'
                              ? 'some seconds ago'
                              : `${getDateFrom(group.createdAt)} ago`
                          }`}
                    </p>
                  </div>
                </Link>
              );
            });
          })}

        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default GroupsIHaveJoined;
