import React, { useEffect, useState, useRef } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import {
  activateUserRequest,
  deactivateUserRequest,
  getAllUsersRequest,
  getAllNonPaginatedUsersRequest,
  unVerifyUserRequest,
  verifyUserRequest,
  verifyUserEmailRequest,
  verifyUserAsInstitutionRequest,
  unVerifyUserAsInstitutionRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { Col, Row } from 'antd';
import classes from './AllUsers.module.css';
import { useHistory } from 'react-router-dom';
import ResetPasswordModal from './components/ResetPasswordModal';
import WarnUserModal from './components/WarnUserModal';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import Button from '../../../../components/Button/Button';
import { CSVLink } from 'react-csv';
import { limitString } from '../../../../helpers/helpers';

const RESULTS_PER_PAGE = 10;

function AllUsers() {
  const csvLinkEl = useRef();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [searchUserInputValue, setSearchUserInputValue] = useState('');
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [usersCSV, setUsersCsv] = useState([]);
  const [
    selectedUserForResetPassword,
    setSelectedUserForResetPassword,
  ] = useState(undefined);
  const [showWarnUserModal, setShowWarnUserModal] = useState(false);
  const [selectedUserForWarn, setSelectedUserForWarn] = useState(undefined);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['all-users', searchUserInputValue],
    ({ pageParam = 1 }) =>
      getAllUsersRequest(pageParam, RESULTS_PER_PAGE, searchUserInputValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateUser = (userId, banned) => {
    queryClient.setQueryData(['all-users', searchUserInputValue], (data) => {
      return {
        ...data,
        pages: data.pages.map((page) => {
          const updatedData = page.data.map((user) =>
            user._id === userId ? { ...user, banned: banned } : user
          );
          return { ...page, data: updatedData };
        }),
      };
    });
  };

  const deactivateUserHandler = async (userId) => {
    await deactivateUserRequest(userId);
    invalidateUser(userId, true);
  };

  const activateUserHandler = async (userId) => {
    await activateUserRequest(userId);
    invalidateUser(userId, false);
  };

  const openResetPasswordModal = (user) => {
    setShowResetPasswordModal(true);
    setSelectedUserForResetPassword(user);
  };

  const openWarnUserModal = (user) => {
    setShowWarnUserModal(true);
    setSelectedUserForWarn(user);
  };

  const renderActivateOrDeactivateButtonAccordingly = (user) => {
    if (!user.banned) {
      return (
        <button
          onClick={() => deactivateUserHandler(user._id)}
          className="cancel-button"
        >
          Deactivate
        </button>
      );
    } else {
      return (
        <button
          onClick={() => activateUserHandler(user._id)}
          className="success-button"
        >
          Activate
        </button>
      );
    }
  };

  const invalidateUserVerification = (userId, verified) => {
    queryClient.setQueryData(['all-users', searchUserInputValue], (data) => {
      return {
        ...data,
        pages: data.pages.map((page) => {
          const updatedData = page.data.map((user) =>
            user._id === userId ? { ...user, verified } : user
          );
          return { ...page, data: updatedData };
        }),
      };
    });
  };

  const invalidateUserEmailVerification = (userId, emailVerified) => {
    queryClient.setQueryData(['all-users', searchUserInputValue], (data) => {
      return {
        ...data,
        pages: data.pages.map((page) => {
          const updatedData = page.data.map((user) =>
            user._id === userId ? { ...user, emailVerified } : user
          );
          return { ...page, data: updatedData };
        }),
      };
    });
  };

  const verifyUserEmailHandler = async (user) => {
    try {
      await verifyUserEmailRequest(user._id);
      invalidateUserEmailVerification(user._id, true);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const verifyUserHandler = async (user) => {
    try {
      await verifyUserRequest(user._id);
      invalidateUserVerification(user._id, true);
    } catch (err) {
      notify('error', err, 2000);
    }
  };
  const unVerifyUserHandler = async (user) => {
    try {
      await unVerifyUserRequest(user._id);
      invalidateUserVerification(user._id, false);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderVerifyButton = (user) => {
    if (user.verified) {
      return (
        <Button
          className="cancel-button"
          onClick={() => unVerifyUserHandler(user)}
        >
          UnVerify
        </Button>
      );
    } else {
      return (
        <Button
          className="success-button"
          onClick={() => verifyUserHandler(user)}
        >
          Verify
        </Button>
      );
    }
  };

  const invalidateUsersHandler = () => {
    queryClient.invalidateQueries(['all-users', searchUserInputValue]);
    queryClient.refetchQueries(['all-users', searchUserInputValue]);
  };

  const unVerifyUserAsInstitutionHandler = async (userId) => {
    try {
      await unVerifyUserAsInstitutionRequest(userId);

      invalidateUsersHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const verifyUserAsInstitutionHandler = async (userId) => {
    try {
      await verifyUserAsInstitutionRequest(userId);

      invalidateUsersHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderVerifyAsInstitutionButton = (user) => {
    if (user.verifiedAsInstitution) {
      return (
        <Button
          className="cancel-button"
          onClick={() => unVerifyUserAsInstitutionHandler(user._id)}
        >
          UnVerify Institution
        </Button>
      );
    } else {
      return (
        <Button
          className="success-button"
          onClick={() => verifyUserAsInstitutionHandler(user._id)}
        >
          Verify Institution
        </Button>
      );
    }
  };

  const csvHandler = async () => {
    const { data } = await getAllNonPaginatedUsersRequest();
    setUsersCsv([
      [
        'Full Name',
        'Email',
        'Email Verified',
        'Role',
        'Suspended',
        'Winning Points',
        'Wallet Address',
        'Id',
      ],
      ...data.map((user) => [
        user.fullName,
        user.email,
        user.emailVerified,
        user.role,
        user.banned,
        user.winningPoints,
        user.walletAddress,
        user._id,
      ]),
    ]);
    setTimeout(() => {
      csvLinkEl.current.link.click();
    });
  };

  return (
    <div style={{ flex: 1 }}>
      {showResetPasswordModal && (
        <ResetPasswordModal
          setShowResetPasswordModal={setShowResetPasswordModal}
          selectedUser={selectedUserForResetPassword}
          setSelectedUserForResetPassword={setSelectedUserForResetPassword}
        />
      )}
      {showWarnUserModal && (
        <WarnUserModal
          setShowWarnUserModal={setShowWarnUserModal}
          selectedUser={selectedUserForWarn}
          setSelectedUserForWarn={setSelectedUserForWarn}
          searchUserInputValue={searchUserInputValue}
        />
      )}
      <div className={classes['all-users-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-users-heading']}>
            ALL USERS = {data?.pages[0].results}
          </h2>
          <Button onClick={csvHandler} pinkcolor="true">
            Export CSV
          </Button>
          <CSVLink filename="Users.csv" data={usersCSV} ref={csvLinkEl} />
          <div>
            <input
              placeholder="Search For Users (Full Name / Email)"
              style={{ width: 300 }}
              className="searchInput"
              value={searchUserInputValue}
              onChange={(e) => {
                setSearchUserInputValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={1} className={classes['pagination-col']}>
              Photo
            </Col>
            <Col md={2} className={classes['pagination-col']}>
              Full Name
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Email
            </Col>
            <Col md={1} className={classes['pagination-col']}>
              Role
            </Col>

            <Col md={1} className={classes['pagination-col']}>
              Email Verified
            </Col>

            <Col md={1} className={classes['pagination-col']}>
              Active
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Deactivate User
            </Col>
            <Col md={4} className={classes['pagination-col']}>
              Reset Password
            </Col>
            <Col md={2} className={classes['pagination-col']}>
              Reports
            </Col>
            <Col md={2} className={classes['pagination-col']}>
              Warnings
            </Col>
            <Col md={2} className={classes['pagination-col']}>
              Warn User
            </Col>
            <Col md={2} className={classes['pagination-col']}>
              Verified
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Verify Email
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Verify Institution
            </Col>
          </Row>

          {!isLoading &&
            data &&
            data.pages.map((page) => {
              return page.data.map((user) => {
                return (
                  <div key={user._id}>
                    <hr />
                    <Row justify="space-between" style={{ padding: '1em 0' }}>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/user-stats/${user._id}`)}
                        md={1}
                      >
                        <img
                          width={25}
                          height={25}
                          style={{ borderRadius: 100, objectFit: 'cover' }}
                          alt="User"
                          src={user.photo}
                        />
                      </Col>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/user-stats/${user._id}`)}
                        md={2}
                      >
                        {user.firstName} {user.lastName}
                      </Col>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/user-stats/${user._id}`)}
                        md={3}
                      >
                        {limitString(user.email, 30)}
                      </Col>
                      <Col md={1}>{user.role}</Col>
                      <Col md={1}>{user.emailVerified ? 'YES' : 'NO'}</Col>

                      <Col md={1}>{!user.banned ? 'YES' : 'NO'}</Col>
                      <Col md={3}>
                        {renderActivateOrDeactivateButtonAccordingly(user)}
                      </Col>
                      <Col md={4}>
                        <button
                          className="btn-white-glimpse"
                          onClick={() => openResetPasswordModal(user)}
                        >
                          Reset Password
                        </button>
                      </Col>
                      <Col md={2} className={classes['pagination-col']}>
                        {user.reports ? user.reports.length : 0}
                      </Col>
                      <Col md={2} className={classes['pagination-col']}>
                        {user.warnings ? user.warnings.length : 0}
                      </Col>
                      <Col md={2} className={classes['pagination-col']}>
                        <button
                          onClick={() => openWarnUserModal(user)}
                          className="warning-button"
                        >
                          WARN
                        </button>
                      </Col>

                      <Col md={2} className={classes['pagination-col']}>
                        {renderVerifyButton(user)}
                      </Col>

                      {!user.emailVerified && (
                        <Col md={3} className={classes['pagination-col']}>
                          <button
                            className="btn-white-glimpse"
                            onClick={() => verifyUserEmailHandler(user)}
                          >
                            Verify Email
                          </button>
                        </Col>
                      )}

                      <Col md={3} className={classes['pagination-col']}>
                        {renderVerifyAsInstitutionButton(user)}
                      </Col>
                    </Row>
                  </div>
                );
              });
            })}

          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default AllUsers;
