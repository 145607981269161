import React, { useState, useMemo, useEffect } from 'react';
import classes from './MyGroups.module.css';
import SelectedGroup from './components/SelectedGroup/SelectedGroup';
import { useLocation } from 'react-router-dom';
import { getSpecificGroupRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import MetaTags from 'react-meta-tags';
import UserSidebar from '../../components/UserSidebar/UserSidebar';

function MyGroups() {
  const [selectedGroup, setSelectedGroup] = useState();

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const groupId = queryParams.get('id');

  useEffect(() => {
    const checkForGettingGroupHandler = async () => {
      if (groupId) {
        try {
          const { data } = await getSpecificGroupRequest(groupId);
          if (data?.name) {
            setSelectedGroup(data);
          }
        } catch (error) {
          notify('error', error, 2000);
        }
      }
    };

    checkForGettingGroupHandler();
  }, [groupId]);

  return (
    <>
      <MetaTags>
        <title>Groups | Glimpse</title>
        <meta property="og:title" content={`Groups | Glimpse`} />
      </MetaTags>

      <div className={classes['my-groups-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>
        {/* <div>
          <MyGroupsSidebar
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          />
        </div> */}

        {selectedGroup && <SelectedGroup selectedGroup={selectedGroup} />}
      </div>
    </>
  );
}

export default MyGroups;
