import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import { getDateFrom } from '../../helpers/helpers';
import classes from './NftPost.module.css';
import VerifiedIcon from '../../assets/verified.png';
import { IPFS_URL } from '../../constants/IPFS';
import ReactPlayer from 'react-player';
import {
  addNftToWatchlistRequest,
  createNftPostTipRequest,
  createStripeCheckoutRequest,
  getNumberOfLikesForSpecificNftRequest,
  getTipStatusForSpecificNftPostRequest,
  isNftAccessibleToBuyByMeRequest,
  likeNftRequest,
  removeNftFromWatchlistRequest,
  unlikeNftRequest,
} from '../../httpRequests/httpRequests';
import { useQuery, useQueryClient } from 'react-query';
import WatchlistIcon from '../../assets/nft-post-unfilled-watchlist-icon.png';
import UnfilledHeart from '../../assets/nft-post-like-icon.png';
import ShareIcon from '../../assets/nft-post-share-icon.png';
import heartIcon from '../../assets/nft-post-liked-icon.png';
import WatchlistedIcon from '../../assets/nft-post-filled-watchlist-icon.png';
import DiamondIcon from '../../assets/diamond.png';
import PinkDiamondIcon from '../../assets/pink-diamond-icon.png';

import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import moment from 'moment';
import notify from '../../utils/notify';
import BuyingMethodModal from '../../pages/NftViewContent/components/BuyingMethodModal/BuyingMethodModal';
import BuyNowModal from '../../pages/NftViewContent/components/BuyNowModal/BuyNowModal';
import BuyPointsModal from '../BuyPointsModal/BuyPointsModal';

const TIP_OPTIONS = [1, 5, 10, 20, 50, 100];

function NftPost(props) {
  const queryClient = useQueryClient();
  const postContainerRef = useRef();
  const {
    profile,
    myLikes,
    myWatchlist,
    getMyWatchlist,
    getMyLikes,
    myWalletInformation,
    getMyProfile,
  } = useContext(AuthContext);

  const { nftPost } = props;
  const { nft, user, postContent, createdAt } = nftPost;
  const nftId = nft._id;

  const [showShareIcons, setShowShareIcons] = useState(false);
  const [isPostInHoverState, setIsPostInHoverState] = useState(false);
  const [showBuyingMethodModal, setShowBuyingMethodModal] = useState(false);
  const [showBuyNowModal, setShowBuyNowModal] = useState(false);
  const [showTipOptions, setShowTipOptions] = useState(false);
  const [showBuyPointsModal, setShowBuyPointsModal] = useState(false);

  const numberOfLikesResponse = useQuery(
    ['nft-number-likes', nft._id],
    () => getNumberOfLikesForSpecificNftRequest(nft._id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const tipStatusResponse = useQuery(['nft-post-tip-status', nftPost._id], () =>
    getTipStatusForSpecificNftPostRequest(nftPost._id)
  );

  useEffect(() => {
    const postContainerRefElement = postContainerRef.current;
    if (!postContainerRefElement) return;

    const mouseOverHandler = () => {
      setIsPostInHoverState(true);
    };

    const mouseOutHandler = () => {
      setIsPostInHoverState(false);
    };

    postContainerRefElement.addEventListener('mouseover', mouseOverHandler);
    postContainerRefElement.addEventListener('mouseleave', mouseOutHandler);

    return () => {
      postContainerRefElement.removeEventListener(
        'mouseover',
        mouseOverHandler
      );
      postContainerRefElement.removeEventListener('mouseout', mouseOutHandler);
    };
  }, [postContainerRef]);

  const likeNftHandler = async () => {
    try {
      await likeNftRequest(nft._id);
      await Promise.all([
        getMyLikes(),
        queryClient.invalidateQueries(['nft-number-likes', nft._id]),
        queryClient.refetchQueries(['nft-number-likes', nft._id]),
      ]);
    } catch (error) {}
  };

  const unlikeNftHandler = async () => {
    try {
      await unlikeNftRequest(nft._id);

      await Promise.all([
        getMyLikes(),
        queryClient.invalidateQueries(['nft-number-likes', nft._id]),
        queryClient.refetchQueries(['nft-number-likes', nft._id]),
      ]);
    } catch (error) {}
  };

  const addNftToWatchlistHandler = async () => {
    await addNftToWatchlistRequest(nft._id);
    getMyWatchlist();
  };

  const removeNftFromWatchlistHandler = async () => {
    await removeNftFromWatchlistRequest(nft._id);
    getMyWatchlist();
  };

  const toggleShareIconsHandler = () => {
    setShowShareIcons((prevState) => !prevState);
  };

  const toggleTipOptionsHandler = () => {
    if (tipStatusResponse.isLoading || tipStatusResponse?.data?.data) return;

    if (user._id === profile?._id) return;

    setShowTipOptions((prevState) => !prevState);
  };

  const isBuyNowDisabled = () => {
    if (nft.owner === profile?._id) return true;

    if (
      !nft.listedForBuyNow ||
      nft.processingBuyNowTransactionOnTheBlockchain ||
      nft.updatingBuyNowPriceOnBlockchain ||
      nft.listingForBuyNowOnBlockchain ||
      nft.unlistingBuyNowOnBlockchain ||
      nft.processingNftOfferOnTheBlockchain ||
      nft.transferringNftOnTheBlockchain
    )
      return true;

    if (
      nft.nftCollection?.liveDate &&
      moment().diff(nft.nftCollection.liveDate) < 0
    )
      return true;

    return false;
  };

  const openBuyingMethodModalHandler = async () => {
    try {
      const { data, reason } = await isNftAccessibleToBuyByMeRequest(nft._id);
      if (data === false) {
        return notify('error', reason, 2000);
      }

      if (
        nft.nftCollection?.liveDate &&
        moment().diff(nft.nftCollection.liveDate) < 0
      ) {
        return notify('error', 'NFT Collection is not live yet!', 2000);
      }
      setShowBuyingMethodModal(true);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const openBuyNowModalHandler = async () => {
    try {
      const { data, reason } = await isNftAccessibleToBuyByMeRequest(nftId);
      if (data === false) {
        return notify('error', reason, 2000);
      }

      if (
        nft.nftCollection?.liveDate &&
        moment().diff(nft.nftCollection.liveDate) < 0
      ) {
        return notify('error', 'NFT Collection is not live yet!', 2000);
      }

      if (
        myWalletInformation &&
        myWalletInformation.glimpseBalance &&
        parseFloat(myWalletInformation.glimpseBalance) <
          parseFloat(nft.buyNowPrice)
      ) {
        notify(
          'error',
          'You do not have enough balance to buy this NFT!',
          2000
        );
      } else {
        setShowBuyNowModal(true);
      }
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const createCheckoutSessionHandler = async () => {
    try {
      const { data, reason } = await isNftAccessibleToBuyByMeRequest(nft._id);
      if (data === false) {
        return notify('error', reason, 2000);
      }

      if (
        nft.nftCollection?.liveDate &&
        moment().diff(nft.nftCollection.liveDate) < 0
      ) {
        return notify('error', 'NFT Collection is not live yet!', 2000);
      }

      const requestBody = {
        nftId: nft._id,
      };
      const { sessionUrl } = await createStripeCheckoutRequest(requestBody);
      window.open(sessionUrl, '_blank');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const invalidateTipStatusHandler = () =>
    Promise.all([
      queryClient.invalidateQueries(['nft-post-tip-status', nftPost._id]),
      queryClient.refetchQueries(['nft-post-tip-status', nftPost._id]),
    ]);

  const createNftPostTipHandler = async (tipAmount) => {
    if (profile?.winningPoints < parseInt(tipAmount)) {
      setShowBuyPointsModal(true);
      return;
    }

    try {
      const requestBody = {
        tipAmount: parseInt(tipAmount),
      };
      await createNftPostTipRequest(nftPost._id, requestBody);
      await invalidateTipStatusHandler();
      notify('success', 'You have successfully tipped the post!', 2000);
      setShowTipOptions(false);
      getMyProfile();
    } catch (err) {
      if (!err.startsWith('E11000')) {
        notify('error', err, 2000);
      }
    }
  };

  const renderNft = () => {
    const nftId = nft._id;
    const isCurrentNftPartOfMyLikes = myLikes.some(
      ({ nft }) => nft?._id === nftId
    );
    const isNftInMyWatchlist = myWatchlist.some(
      (el) => el.nft?._id === nft._id
    );

    return (
      <div className={classes['nft-image-container']}>
        <Link to={`/nfts/${nft._id}`}>
          {nft.type === 'image' ? (
            <img
              alt="IPFS"
              className={classes['nft-image']}
              src={nft.previewUrl ? nft.previewUrl : `${IPFS_URL}/${nft.ipfs}`}
            />
          ) : (
            <ReactPlayer
              controls
              width={'100%'}
              height={360}
              url={`${IPFS_URL}/${nft.ipfs}`}
            />
          )}
        </Link>

        <div className={classes['watchlist-likes-share-container']}>
          <div
            onClick={
              isCurrentNftPartOfMyLikes ? unlikeNftHandler : likeNftHandler
            }
            className={classes['likes-container']}
          >
            <img
              src={isCurrentNftPartOfMyLikes ? heartIcon : UnfilledHeart}
              alt="Likes"
              className={classes['likes-icon']}
            />
            <p className={classes['likes-number']}>
              {numberOfLikesResponse.data?.data}
            </p>
          </div>

          <div className={classes['vertical-line']}>&nbsp;</div>

          <div
            onClick={
              isNftInMyWatchlist
                ? removeNftFromWatchlistHandler
                : addNftToWatchlistHandler
            }
            className={classes['watchlist-container']}
          >
            <img
              src={isNftInMyWatchlist ? WatchlistedIcon : WatchlistIcon}
              alt="Watchlist"
              className={classes['watchlist-icon']}
            />
          </div>

          <div className={classes['vertical-line']}>&nbsp;</div>

          <div
            onClick={toggleShareIconsHandler}
            className={classes['share-container']}
          >
            <img
              src={ShareIcon}
              alt="Share"
              className={classes['share-icon']}
            />

            {showShareIcons && (
              <div className={classes['share-icons-container']}>
                <FacebookShareButton
                  url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                  hashtag={`#NFT`}
                  description={nft.description}
                  title={nft.title}
                >
                  <FacebookIcon size={28} round />
                </FacebookShareButton>

                <TwitterShareButton
                  url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                  hashtag={`#NFT`}
                  description={nft.description}
                  title={nft.title}
                >
                  <TwitterIcon size={28} round />
                </TwitterShareButton>

                <WhatsappShareButton
                  url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                  hashtag={`#NFT`}
                  description={nft.description}
                  title={nft.title}
                >
                  <WhatsappIcon size={28} round />
                </WhatsappShareButton>

                <TelegramShareButton
                  url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                  hashtag={`#NFT`}
                  description={nft.description}
                  title={nft.title}
                >
                  <TelegramIcon size={28} round />
                </TelegramShareButton>

                <RedditShareButton
                  url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                  hashtag={`#NFT`}
                  description={nft.description}
                  title={nft.title}
                >
                  <RedditIcon size={28} round />
                </RedditShareButton>
              </div>
            )}
          </div>
        </div>

        <div className={classes['tip-buy-container']}>
          <div
            onClick={toggleTipOptionsHandler}
            className={classes['tip-container']}
          >
            <img
              src={
                !tipStatusResponse.isLoading &&
                tipStatusResponse.data &&
                tipStatusResponse.data.data !== null
                  ? PinkDiamondIcon
                  : DiamondIcon
              }
              alt="Diamond"
              className={classes['diamond-icon']}
            />

            {showTipOptions && (
              <div className={classes['tip-options-container']}>
                {TIP_OPTIONS.map((tipOption) => (
                  <div
                    onClick={() => createNftPostTipHandler(tipOption)}
                    key={tipOption}
                    className={`${classes['box-option']}`}
                  >
                    <p className={classes['points-value']}>
                      <img
                        src={PinkDiamondIcon}
                        alt="Diamond"
                        className={classes['diamond-icon']}
                      />
                      {parseFloat(tipOption)}{' '}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={classes['vertical-line']}>&nbsp;</div>
          <div
            onClick={
              isBuyNowDisabled() === false
                ? openBuyingMethodModalHandler
                : undefined
            }
            className={classes['buy-container']}
          >
            {isPostInHoverState && isBuyNowDisabled() === false ? (
              <p className={classes['buy-text']}>
                ${parseFloat(nft.buyNowPrice / 100).toFixed(2)}
              </p>
            ) : (
              <p
                className={
                  isBuyNowDisabled() === false
                    ? classes['buy-text']
                    : classes['buy-text-disabled']
                }
              >
                BUY
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {showBuyingMethodModal && (
        <BuyingMethodModal
          openBuyNowModalHandler={openBuyNowModalHandler}
          createCheckoutSessionHandler={createCheckoutSessionHandler}
          nftId={nftId}
          nft={{
            ...nft,
            owner: { _id: nft.owner },
            author: { _id: nft.author },
          }}
          setShowBuyingMethodModal={setShowBuyingMethodModal}
        />
      )}

      {showBuyNowModal && (
        <BuyNowModal setShowBuyNowModal={setShowBuyNowModal} nft={nft} />
      )}

      {showBuyPointsModal && (
        <BuyPointsModal setShowBuyPointsModal={setShowBuyPointsModal} />
      )}

      <div ref={postContainerRef} className={classes['post-container']}>
        <div className={classes['header']}>
          <div className={classes['user']}>
            <Link
              to={
                user._id === profile?._id
                  ? '/my-space'
                  : `/user-feed/${user._id}`
              }
            >
              <img
                alt={user.fullName}
                src={user.photo}
                className={classes['user-photo']}
              />
            </Link>
            <div className={classes['user-info']}>
              <div className={classes['post-author-tags']}>
                <Link
                  to={
                    user._id === profile?._id
                      ? '/my-space'
                      : `/user-feed/${user._id}`
                  }
                >
                  <div className={classes['fullName']}>
                    {user.fullName}
                    {user.verified && (
                      <img
                        alt="Verified"
                        src={VerifiedIcon}
                        style={{ height: 16, marginLeft: 6, marginBottom: 2 }}
                      />
                    )}
                  </div>
                </Link>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <p className={classes['created-at']}>
                  {getDateFrom(createdAt)}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes['post-content-container']}>
          <p className={classes['post-content-text']}>{postContent}</p>
        </div>

        <div className={classes['nft-container']}>{renderNft()}</div>
      </div>
    </>
  );
}

export default NftPost;
