import React, { useEffect, useState } from 'react';
import classes from './ManageAdmins.module.css';
import { Col, Row } from 'antd';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import {
  getAllUsersRequest,
  markAdminAsUserRequest,
  markUserAsAdminRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { limitString } from '../../../../helpers/helpers';

const RESULTS_PER_PAGE = 10;

function ManageAdmins() {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [searchUserInputValue, setSearchUserInputValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['all-users', searchUserInputValue],
    ({ pageParam = 1 }) =>
      getAllUsersRequest(pageParam, RESULTS_PER_PAGE, searchUserInputValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateUsersHandler = () =>
    Promise.all([
      queryClient.invalidateQueries(['all-users', searchUserInputValue]),
      queryClient.refetchQueries(['all-users', searchUserInputValue]),
    ]);

  const markAdminAsUserHandler = async (adminId) => {
    try {
      await markAdminAsUserRequest(adminId);

      invalidateUsersHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const markUserAsAdminHandler = async (userId) => {
    try {
      await markUserAsAdminRequest(userId);

      invalidateUsersHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-users-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-users-heading']}>
            ALL USERS = {data?.pages[0].results}
          </h2>

          <div>
            <input
              placeholder="Search For Users (Full Name / Email)"
              style={{ width: 300 }}
              className="searchInput"
              value={searchUserInputValue}
              onChange={(e) => {
                setSearchUserInputValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={3} className={classes['pagination-col']}>
              Photo
            </Col>
            <Col md={3} className={classes['pagination-col']}>
              Full Name
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Email
            </Col>

            <Col md={5} className={classes['pagination-col']}>
              Mark / Remove Admin
            </Col>
          </Row>

          {!isLoading &&
            data &&
            data.pages.map((page) => {
              return page.data.map((user) => {
                return (
                  <div key={user._id}>
                    <hr />
                    <Row justify="space-between" style={{ padding: '1em 0' }}>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/user-stats/${user._id}`)}
                        md={3}
                      >
                        <img
                          width={25}
                          height={25}
                          style={{ borderRadius: 100, objectFit: 'cover' }}
                          alt="User"
                          src={user.photo}
                        />
                      </Col>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/user-stats/${user._id}`)}
                        md={3}
                      >
                        {user.firstName} {user.lastName}
                      </Col>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/user-stats/${user._id}`)}
                        md={3}
                      >
                        {limitString(user.email, 30)}
                      </Col>

                      <Col md={5}>
                        {user.role === 'admin' ? (
                          <button
                            onClick={() => markAdminAsUserHandler(user._id)}
                            className="cancel-button"
                          >
                            Remove Admin
                          </button>
                        ) : (
                          <button
                            onClick={() => markUserAsAdminHandler(user._id)}
                            className="btn-white-glimpse"
                          >
                            Mark As Admin
                          </button>
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              });
            })}

          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default ManageAdmins;
