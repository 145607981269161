import React, { useEffect } from 'react';
import classes from './SentCollaboratorNftRequests.module.css';
import { useInfiniteQuery } from 'react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import SentCollaboratorNftRequest from '../../../../components/SentCollaboratorNftRequest/SentCollaboratorNftRequest';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import { getMyRequestedNftsAsCollaboratorRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';

const RESULTS_PER_PAGE = 10;

function SentCollaboratorNftRequests() {
  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    'my-requested-nfts-as-collaborator',
    ({ pageParam = 1 }) =>
      getMyRequestedNftsAsCollaboratorRequest(pageParam, RESULTS_PER_PAGE),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <div style={{ marginBottom: 10 }}>
      <div className={classes['sent-collaborator-nft-requests-list']}>
        {!isLoading && data && data?.pages[0].results === 0 && (
          <div
            style={{
              background: 'white',
              padding: '.8rem',
              borderRadius: 10,
              marginTop: 10,
              width: '95%',
              gridColumn: '1/-1',
            }}
          >
            <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
              No Results Found!
            </p>
          </div>
        )}

        {data?.pages &&
          data.pages.map((page) => {
            return page.data.map(
              ({ nft, nftCollection, to, updatedAt, _id, status }) => (
                <SentCollaboratorNftRequest
                  key={_id}
                  nft={nft}
                  nftCollection={nftCollection}
                  to={to}
                  updatedAt={updatedAt}
                  collaboratorNftRequestId={_id}
                  status={status}
                />
              )
            );
          })}
      </div>

      {(isLoading || isFetching) && <LoadingSpinner />}
    </div>
  );
}

export default SentCollaboratorNftRequests;
