import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import classes from './EditPostModal.module.css';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import { AuthContext } from '../../../../context/auth-context';
import ReactPlayer from 'react-player';
import RemoveIcon from '../../../../assets/white-remove-icon.png';
import {
  createNotificationToMultipleUsersRequest,
  getAllPaginatedUserActivityTopicsRequest,
  searchUsersByUsernameRequest,
  updateMyGroupPostRequest,
} from '../../../../httpRequests/httpRequests';
import { useQuery, useQueryClient } from 'react-query';
import { SocketContext } from '../../../../context/socket-context';
import { POST_MENTION } from '../../../../constants/SOCKET_EVENTS';
import notify from '../../../../utils/notify';
import EmojiIcon from '../../../../assets/emoji.png';
import { Picker } from 'emoji-mart';
import ImageVideoUpload from '../../../ImageVideoUpload/ImageVideoUpload';
import ImagePostIcon from '../../../../assets/image-post-1.png';

const getMentionedUsernames = (mentions) => {
  const mentionedUsernames = {};
  mentions.forEach((mention) => {
    mentionedUsernames[mention.mentionUsername] = mention.data;
  });

  return mentionedUsernames;
};

function EditPostModal(props) {
  const queryClient = useQueryClient();
  const { profile } = useContext(AuthContext);
  const { socket } = useContext(SocketContext);
  const { setShowEditPostModal, post } = props;
  const postContentInputRef = useRef();

  const [postContent, setPostContent] = useState(post.postContent);
  const [selectedFeeling, setSelectedFeeling] = useState(post.feeling);
  const [postImages, setPostImages] = useState(post.postImages);
  const [postVideos, setPostVideos] = useState(post.postVideos);
  const [showTopicsTable, setShowTopicsTable] = useState(false);
  const [previousSplittedMessage, setPreviousSplittedMessage] = useState([]);
  const [fetchedTopics, setFetchedTopics] = useState([]);
  const [fetchingTopics, setFetchingTopics] = useState(false);
  const [splittedMessage, setSplittedMessage] = useState([]);
  const [mentionedUsernames, setMentionedUsernames] = useState(
    getMentionedUsernames(post.mentions)
  );
  const [showEmojiEditor, setShowEmojiEditor] = useState(false);
  const [newUploadedVideosUrl, setNewUploadedVideosUrl] = useState([]);
  const [newUploadedImagesUrl, setNewUploadedImagesUrl] = useState([]);
  const [
    newUploadedVideosPreviewUrl,
    setNewUploadedVideosPreviewUrl,
  ] = useState([]);
  const [
    newUploadedImagesPreviewUrl,
    setNewUploadedImagesPreviewUrl,
  ] = useState([]);
  const [editingPost, setEditingPost] = useState(false);

  const getLastTypedMessage = useCallback(() => {
    for (let i = 0; i < splittedMessage.length; i++) {
      if (previousSplittedMessage[i] !== splittedMessage[i]) {
        return { index: i, msg: splittedMessage[i] };
      }
    }
  }, [splittedMessage, previousSplittedMessage]);

  const getMentionValue = () => {
    if (postContent === '') return '';

    if (splittedMessage[splittedMessage.length - 1]?.includes('\n@')) {
      let mentionValue = '';
      let i = splittedMessage[splittedMessage.length - 1].length - 1;
      while (splittedMessage[splittedMessage.length - 1][i] !== '@') {
        mentionValue += splittedMessage[splittedMessage.length - 1][i];
        i--;
      }
      return mentionValue.split('').reverse().join('');
    }

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (msg[0] === '@' && mentionedUsernames[msg]) continue;

      if (msg[0] === '@' && !mentionedUsernames[msg]) {
        const mentionValue = msg.replace('@', '');
        return mentionValue;
      }
    }
    return '';
  };
  const mentionValue = getMentionValue();

  const { data: mentions } = useQuery(['mentions', mentionValue], () =>
    searchUsersByUsernameRequest(mentionValue)
  );

  useEffect(() => {
    const checkForClosingEmojiPicker = (e) => {
      if (e.target.id.startsWith('emoji')) {
        setShowEmojiEditor(true);
      } else if (e.target.closest('#emoji-container') === null) {
        setShowEmojiEditor(false);
      }
    };

    window.addEventListener('click', checkForClosingEmojiPicker);
  }, []);

  useEffect(() => {
    const checkForShowingTopicsTable = async () => {
      const lastTypedMessage = getLastTypedMessage();
      if (lastTypedMessage?.msg?.startsWith('#')) {
        setShowTopicsTable(true);
        setFetchingTopics(true);
        const { data } = await getAllPaginatedUserActivityTopicsRequest(
          1,
          50,
          lastTypedMessage.msg
        );
        setFetchingTopics(false);
        setFetchedTopics(Array.from(new Set(data.map(({ name }) => name))));
      } else setShowTopicsTable(false);
    };

    checkForShowingTopicsTable();
  }, [getLastTypedMessage]);

  const selectUserToMentionHandler = (user) => {
    const updatedMentionedUsernames = { ...mentionedUsernames };

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (
        msg[0] === '@' &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[i] = `@${username}`;
      } else if (
        splittedMessage[splittedMessage.length - 1]?.includes('\n@') &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[splittedMessage.length - 1] = `\n@${username}`;
      }
    }

    splittedMessage.push('');
    setMentionedUsernames(updatedMentionedUsernames);
    setPostContent(splittedMessage.join(' '));
    setSplittedMessage(splittedMessage);
    postContentInputRef.current.focus();
  };

  const selectTopicHandler = (topic) => {
    const lastTypedMessage = getLastTypedMessage();
    if (lastTypedMessage?.msg) {
      const updatedSplittedMessage = [...splittedMessage];
      updatedSplittedMessage[lastTypedMessage.index] = topic;
      setSplittedMessage(updatedSplittedMessage);
      setPostContent(updatedSplittedMessage.join(' '));
    }
  };

  const closeModalHandler = () => {
    setShowEditPostModal(false);
  };

  const sendNotificationsToMentionedUsers = async (post) => {
    if (!profile)
      return notify(
        'error',
        'You are not logged in. Please log in to perform this action!',
        2000
      );

    try {
      const mentionedUserIds = [];
      for (const key in mentionedUsernames) {
        const userId = mentionedUsernames[key]._id;
        const isMe = profile?._id === userId;
        if (!isMe) {
          mentionedUserIds.push(userId);
        }
      }
      const requestBody = {
        userIds: mentionedUserIds,
        notificationType: 'post-mention',
        entityName: profile.fullName,
        entityId: post._id,
      };

      await createNotificationToMultipleUsersRequest(requestBody);

      socket.emit(POST_MENTION, {
        mentionedUserIds,
        postAuthor: profile.fullName,
      });
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const inputFileHandler = (value) => {
    if (!value) return;

    const fileReader = new FileReader();

    if (value.type === 'video/mp4') {
      setNewUploadedVideosUrl((prevState) => [...prevState, value]);
    } else {
      setNewUploadedImagesUrl((prevState) => [...prevState, value]);
    }

    try {
      fileReader.onload = () => {
        if (value.type === 'video/mp4') {
          setNewUploadedVideosPreviewUrl((prevState) => [
            ...prevState,
            fileReader.result,
          ]);
        } else {
          setNewUploadedImagesPreviewUrl((prevState) => [
            ...prevState,
            fileReader.result,
          ]);
        }
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const editPostHandler = async () => {
    setEditingPost(true);

    const formData = new FormData();
    if (postContent) formData.append('postContent', postContent);
    if (selectedFeeling) formData.append('feeling', selectedFeeling);
    formData.append('postImages', JSON.stringify(postImages));
    formData.append('postVideos', JSON.stringify(postVideos));

    if (newUploadedImagesUrl.length > 0) {
      for (let i = 0; i < newUploadedImagesUrl.length; i++) {
        formData.append('newImages', newUploadedImagesUrl[i]);
      }
    }
    if (newUploadedVideosUrl.length > 0) {
      for (let i = 0; i < newUploadedVideosUrl.length; i++) {
        formData.append('newVideos', newUploadedVideosUrl[i]);
      }
    }

    const mentions = [];
    Object.entries(mentionedUsernames).forEach(([mentionUsername, data]) => {
      mentions.push({ mentionUsername, data });
    });
    formData.append('mentions', JSON.stringify(mentions));

    const topics = [];
    postContent.split(' ').forEach((el) => {
      if (el.startsWith('#')) {
        topics.push(el);
      }
    });
    formData.append('postTopics', JSON.stringify(topics));

    try {
      const { data: updatedPost } = await updateMyGroupPostRequest(
        post._id,
        formData
      );

      await Promise.all([
        queryClient.invalidateQueries(['group-posts', updatedPost.group]),
        queryClient.refetchQueries(['group-posts', updatedPost.group]),
      ]);

      sendNotificationsToMentionedUsers(updatedPost);
      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setEditingPost(false);
  };

  const postContentChangeHandler = (e) => {
    setPostContent(e.target.value);
    const splittedMessage = e.target.value.split(' ');
    setSplittedMessage((prevState) => {
      setPreviousSplittedMessage(prevState);
      return splittedMessage;
    });
    const updatedMentionedUsernames = {};
    for (const usernameKey in mentionedUsernames) {
      if (splittedMessage.includes(usernameKey)) {
        updatedMentionedUsernames[usernameKey] =
          mentionedUsernames[usernameKey];
      }
    }

    setMentionedUsernames(updatedMentionedUsernames);
  };

  const removeVideoHandler = (index) => {
    setPostVideos((prevState) => prevState.filter((_, i) => index !== i));
  };

  const removeImageHandler = (index) => {
    setPostImages((prevState) => prevState.filter((_, i) => index !== i));
  };

  const removeNewImageUrl = (index) => {
    setNewUploadedImagesPreviewUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
    setNewUploadedImagesUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  const removeNewVideoUrl = (index) => {
    setNewUploadedVideosPreviewUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
    setNewUploadedVideosUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  const shouldNotShowMentionsTable = () => {
    return splittedMessage.every((msg) => {
      return (
        msg === '' ||
        msg === ' ' ||
        msg[0] !== '@' ||
        (msg[0] === '@' && typeof mentionedUsernames[msg] === 'object')
      );
    });
  };

  const shouldShowMentionsTableInNewLine = () => {
    return splittedMessage[splittedMessage.length - 1]?.includes('\n@');
  };

  return (
    <Modal
      style={{ height: 600 }}
      closeModal={closeModalHandler}
      title="Edit Post"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            disabled={editingPost}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={editingPost}
            darkpink="true"
            onClick={editPostHandler}
          >
            Save
          </Button>
        </div>
      }
    >
      <div>
        <div className={classes['user-post-status']}>
          <img
            alt={profile?.fullName}
            src={profile?.photo}
            width={45}
            height={45}
            className={classes['user-image']}
          />

          <div className={classes['fullName-post-status-container']}>
            <div className={classes['fullName-feeling-container']}>
              <h4 className={classes['user-fullName']}>{profile?.fullName}</h4>
              {selectedFeeling && (
                <div className={classes['feeling-container']}>
                  {' '}
                  <span className={classes['circle']}>&nbsp;</span>{' '}
                  <p className={classes['feeling-text']}>
                    Feeling {selectedFeeling}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={classes['input-container']}>
          <textarea
            ref={postContentInputRef}
            className={classes['textarea']}
            style={{ minHeight: 100, width: '100%' }}
            value={postContent}
            onChange={postContentChangeHandler}
            placeholder="Post Content"
          />

          {showTopicsTable && (
            <div className={classes['topics-container']}>
              {fetchedTopics.length === 0 && fetchingTopics === false && (
                <p className={classes['no-topics-found']}>No Topics Found</p>
              )}
              {fetchedTopics.map((topic) => {
                return (
                  <div
                    onClick={() => selectTopicHandler(topic)}
                    className={classes['topic-container']}
                    key={topic}
                  >
                    <p className={classes['topic-text']}>{topic}</p>
                  </div>
                );
              })}
            </div>
          )}

          {(!shouldNotShowMentionsTable() ||
            shouldShowMentionsTableInNewLine()) && (
            <div className={classes['mention-container']}>
              {mentions && mentions.data.length === 0 && (
                <p className={classes['no-users-found']}>No Users Found</p>
              )}

              {mentions &&
                mentions.data.map((user) => {
                  const fullName = `${user.firstName} ${user.lastName}`;
                  return (
                    <div
                      onClick={() => selectUserToMentionHandler(user)}
                      className={classes['user-container']}
                      key={user._id}
                    >
                      <img
                        alt="userPhoto"
                        className={classes['user-image']}
                        src={user.photo}
                      />
                      <p className={classes['user-text']}>
                        {user.username} ({fullName})
                        {user._id === profile?._id ? ' (You)' : ''}
                      </p>
                    </div>
                  );
                })}
            </div>
          )}

          <img
            alt="Emoji"
            src={EmojiIcon}
            className={classes['emoji-icon']}
            id="emoji-icon"
          />

          <ImageVideoUpload onInput={inputFileHandler}>
            <img
              alt="Post"
              src={ImagePostIcon}
              className={classes['image-post-icon']}
            />
          </ImageVideoUpload>

          {showEmojiEditor && (
            <div id="emoji-container" className={classes['emoji-container']}>
              <Picker
                emojiSize={20}
                title="Pick your emoji"
                emoji="point_up"
                onSelect={({ native }) => {
                  setShowEmojiEditor(false);
                  setSelectedFeeling(native);
                }}
              />
            </div>
          )}
        </div>

        <div className={classes['upload-image-container']}>
          {postVideos.map((videoUrl, i) => {
            return (
              <div key={videoUrl + i} className={classes['video-container']}>
                <ReactPlayer
                  width={300}
                  height={155}
                  url={videoUrl}
                  controls
                  style={{
                    borderRadius: '13px',
                    objectFit: 'cover',
                    marginTop: 27,
                  }}
                />

                <div
                  onClick={() => removeVideoHandler(i)}
                  className={classes['remove-video-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            );
          })}

          {newUploadedVideosPreviewUrl.map((previewUrl, i) => (
            <div key={previewUrl + i} className={classes['video-container']}>
              <ReactPlayer
                width={300}
                height={155}
                url={previewUrl}
                controls
                style={{
                  borderRadius: '13px',
                  objectFit: 'cover',
                  marginTop: 27,
                }}
              />

              <div
                onClick={() => removeNewVideoUrl(i)}
                className={classes['remove-video-icon-container']}
              >
                <img
                  alt="Remove"
                  src={RemoveIcon}
                  className={classes['remove-icon']}
                />
              </div>
            </div>
          ))}

          {postImages.map((imageUrl, i) => {
            return (
              <div key={imageUrl + i} className={classes['image-container']}>
                <img
                  alt="Preview"
                  src={imageUrl}
                  className={classes['uploaded-image']}
                />
                <div
                  onClick={() => removeImageHandler(i)}
                  className={classes['remove-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            );
          })}

          {newUploadedImagesPreviewUrl.map((previewUrl, i) => (
            <div key={previewUrl + i} className={classes['image-container']}>
              <img
                alt="Preview"
                src={previewUrl}
                className={classes['uploaded-image']}
              />
              <div
                onClick={() => removeNewImageUrl(i)}
                className={classes['remove-icon-container']}
              >
                <img
                  alt="Remove"
                  src={RemoveIcon}
                  className={classes['remove-icon']}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default EditPostModal;
