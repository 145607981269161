import React, { useContext, useEffect, useMemo, useState } from 'react';
import classes from './MessagesSidebar.module.css';
import MessagesIcon from '../../assets/messages-icon.png';
import UpIcon from '../../assets/up-icon.png';
import DownIcon from '../../assets/down-icon.png';
import Input from '../Input/Input';
import Chat from '../../pages/Chats/components/Chat';
import { useQuery } from 'react-query';
import {
  getMyChatsRequest,
  getNumberOfMyRequestedChatsRequest,
} from '../../httpRequests/httpRequests';
import { AuthContext } from '../../context/auth-context';
import SearchIcon from '../../assets/search-icon.png';
import RequestedChatList from '../../pages/Chats/components/RequestedChatList/RequestedChatList';
import CommunitiesChatList from '../../pages/Chats/components/CommunitiesChatList/CommunitiesChatList';
import { useHistory } from 'react-router-dom';

const CHAT_SECTIONS = ['Primary', 'Communities', 'Requests'];

function MessagesSidebar() {
  const history = useHistory();
  const {
    profile,
    numberOfUnreadGroupMessagesForMyGroups,
    numberOfUnreadMessagesForMyChats,
  } = useContext(AuthContext);

  const [isUpIconSelected, setIsUpIconSelected] = useState(false);
  const [selectedChatSection, setSelectedChatSection] = useState('Primary');
  const [searchChatValue, setSearchChatValue] = useState('');
  const [selectedChatGroupId, setSelectedChatGroupId] = useState(undefined);
  const [, setSelectedChatGroup] = useState(undefined);
  const [selectedCommunity, setSelectedCommunity] = useState();
  const [selectedCommunityChannel, setSelectedCommunityChannel] = useState();

  const { isLoading, data, error } = useQuery('my-chats', getMyChatsRequest);

  const numberOfMyRequestedChatsResponse = useQuery(
    'number-of-my-requested-chats',
    getNumberOfMyRequestedChatsRequest
  );

  const myChats = useMemo(() => {
    if (!data) return [];

    const chats = data.data;

    return chats;
  }, [data]);

  const filteredChatsBySearchValue = useMemo(() => {
    const filteredChats = myChats.filter((chat) => {
      if (chat.isGroupChat) {
        return chat.name.toLowerCase().includes(searchChatValue.toLowerCase());
      } else {
        const otherUserIfSingleChat = chat.users.find(
          (user) => user._id !== profile?._id
        );
        if (
          `${otherUserIfSingleChat.firstName} ${otherUserIfSingleChat.lastName}`
            .toLowerCase()
            .includes(searchChatValue.toLowerCase())
        )
          return true;
      }

      return false;
    });

    return filteredChats;
  }, [myChats, searchChatValue, profile?._id]);

  useEffect(() => {
    if (!selectedChatGroupId) return;
    let tab = 'primary';
    if (selectedChatSection === 'Requests') {
      tab = 'requests';
    }
    history.push(`/chats?tab=${tab}&id=${selectedChatGroupId}`);
    // eslint-disable-next-line
  }, [selectedChatGroupId]);

  useEffect(() => {
    if (!selectedCommunity || !selectedCommunityChannel) return;

    history.push(
      `/chats?tab=communities&id=${selectedCommunity}&channel=${
        selectedCommunityChannel.channelName
      }&private=${selectedCommunityChannel.privateChannel ? 1 : 0}&channelId=${
        selectedCommunityChannel.channelId
      }`
    );
  }, [history, selectedCommunity, selectedCommunityChannel]);

  return (
    <div
      className={
        isUpIconSelected
          ? classes['bottom-messages-sidebar-container']
          : classes['top-messages-sidebar-container']
      }
    >
      <div className={classes['messages-text-icon-container']}>
        <img
          src={MessagesIcon}
          alt="Messages"
          className={classes['messages-icon']}
        />
        <h3 className={classes['messages-text']}> Messages</h3>

        <div className={classes['unread-messages-up-icon-container']}>
          {isUpIconSelected &&
            numberOfUnreadGroupMessagesForMyGroups +
              numberOfUnreadMessagesForMyChats >
              0 && (
              <div className={classes['total-unread-messages-container']}>
                <p>
                  {numberOfUnreadGroupMessagesForMyGroups +
                    numberOfUnreadMessagesForMyChats}
                </p>
              </div>
            )}
          <img
            onClick={() => setIsUpIconSelected((prevState) => !prevState)}
            src={!isUpIconSelected ? DownIcon : UpIcon}
            className={classes['up-down-icon']}
            alt="Up/Down"
          />
        </div>
      </div>

      <div className={classes['horizontal-line']}></div>

      {!isUpIconSelected && (
        <div className={classes['chats']}>
          <div className={classes['chat-sections']}>
            {CHAT_SECTIONS.map((section) => {
              return (
                <div
                  onClick={() => setSelectedChatSection(section)}
                  key={section}
                  className={classes['section-container']}
                >
                  <div
                    className={`${classes['section-text']} ${
                      section === selectedChatSection
                        ? classes['selected-section']
                        : ''
                    }`}
                  >
                    <span>{section}</span>
                    {section === 'Primary' && (
                      <span className={classes['number-of-unread-messages']}>
                        <span
                          className={classes['number-of-unread-messages-text']}
                        >
                          {numberOfUnreadMessagesForMyChats}
                        </span>
                      </span>
                    )}
                    {section === 'Communities' && (
                      <span className={classes['number-of-unread-messages']}>
                        <span
                          className={classes['number-of-unread-messages-text']}
                        >
                          {numberOfUnreadGroupMessagesForMyGroups}
                        </span>
                      </span>
                    )}
                    {section === 'Requests' &&
                      numberOfMyRequestedChatsResponse.data?.data !==
                        undefined && (
                        <span className={classes['number-of-unread-messages']}>
                          <span
                            className={
                              classes['number-of-unread-messages-text']
                            }
                          >
                            {numberOfMyRequestedChatsResponse.data?.data}
                          </span>
                        </span>
                      )}
                    {section === selectedChatSection && (
                      <div className={classes['selected-border']}>&nbsp;</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={classes['horizontal-line']}></div>

          <div className={classes['input-container']}>
            <Input
              value={searchChatValue}
              onChange={(e) => setSearchChatValue(e.target.value)}
              style={{ width: '98%', marginTop: 12 }}
              className={classes['search-input']}
              placeholder={'Search here'}
            />
            <img
              alt="Search"
              className={classes['search-icon']}
              src={SearchIcon}
              width={18}
            />
          </div>

          {selectedChatSection === 'Primary' && (
            <div className={classes['chat-list']}>
              {!isLoading &&
                !error &&
                data &&
                filteredChatsBySearchValue.length === 0 && (
                  <p style={{ opacity: '.7', marginTop: 20 }}>
                    No Chats Found!
                  </p>
                )}

              {!isLoading &&
                !error &&
                data &&
                filteredChatsBySearchValue.map((chat) => {
                  return (
                    <Chat
                      isFavorite={chat.isFavorite}
                      setSelectedChatGroup={setSelectedChatGroup}
                      selectedChatGroupId={selectedChatGroupId}
                      setSelectedChatGroupId={setSelectedChatGroupId}
                      key={chat._id}
                      chat={chat}
                    />
                  );
                })}
            </div>
          )}

          {selectedChatSection === 'Requests' && (
            <RequestedChatList
              searchChatValue={searchChatValue}
              setSelectedChatGroup={setSelectedChatGroup}
              selectedChatGroupId={selectedChatGroupId}
              setSelectedChatGroupId={setSelectedChatGroupId}
            />
          )}

          {selectedChatSection === 'Communities' && (
            <CommunitiesChatList
              messagesSidebar={true}
              searchChatValue={searchChatValue}
              selectedCommunity={selectedCommunity}
              setSelectedCommunity={setSelectedCommunity}
              selectedCommunityChannel={selectedCommunityChannel}
              setSelectedCommunityChannel={setSelectedCommunityChannel}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default MessagesSidebar;
