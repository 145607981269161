import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  acceptChatRequest,
  createMessageRequest,
  createNotificationToMultipleUsersRequest,
  getChatMessagesRequest,
  markChatMessageAsReadRequest,
  rejectChatRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { useQuery, useQueryClient } from 'react-query';
import classes from './Messages.module.css';
import { AuthContext } from '../../../../context/auth-context';
import moment from 'moment';
import { SocketContext } from '../../../../context/socket-context';
import {
  EMIT_LEFT_TYPING_MESSAGE,
  EMIT_TYPING_MESSAGE,
  LEFT_TYPING_MESSAGE,
  MESSAGE_CREATED,
  TYPING_MESSAGE,
} from '../../../../constants/SOCKET_EVENTS';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import {
  convertToMB,
  downloadFile,
  isToday,
  isValidUrl,
} from '../../../../helpers/helpers';
import { Link, useHistory } from 'react-router-dom';
import DeliveredMessageIcon from '../../../../assets/delivered-icon-pink.png';
import Button from '../../../../components/Button/Button';
import ReadMessageIcon from '../../../../assets/read-icon-pink.png';
import EmojiIcon from '../../../../assets/emoji.png';
import ImagePostIcon from '../../../../assets/image-post-1.png';
import MultipleFileUpload from '../../../../components/MultipleFileUpload/MultipleFileUpload';
import { Document, Page, pdfjs } from 'react-pdf';
import FileModal from '../../../../components/ImageModal/ImageModal';
import ReactPlayer from 'react-player';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let CHAT_GROUP_ID = undefined;
let usersWhoAreTyping = [];

const MESSAGE_STATUSES = {
  READ: 'READ',
  RECEIVED: 'RECEIVED',
  DELIVERED: 'DELIVERED',
};

function Messages(props) {
  const emojiIconRef = useRef();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { socket } = useContext(SocketContext);
  const { profile } = useContext(AuthContext);
  const messageInputRef = useRef();
  const messagesRef = useRef();
  const {
    selectedChatGroupId,
    selectedChatGroup,
    setSelectedChat,
    setSelectedChatGroupId,
  } = props;
  const [requestedChat, setRequestedChat] = useState(
    selectedChatGroup.requested && selectedChatGroup.organizer !== profile?._id
  );

  useEffect(() => {
    setRequestedChat(
      !selectedChatGroup.requested
        ? false
        : selectedChatGroup.requested &&
            selectedChatGroup.organizer !== profile?._id
    );
  }, [selectedChatGroup, profile?._id]);

  const { data, error } = useQuery(['chat-messages', selectedChatGroupId], () =>
    getChatMessagesRequest(selectedChatGroupId)
  );
  const [message, setMessage] = useState('');
  const [splittedMessage, setSplittedMessage] = useState([]);
  const [mentionedUsernames, setMentionedUsernames] = useState({});
  const [, setTriggerRerender] = useState(1);
  const [showEmojiEditor, setShowEmojiEditor] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [fileModalState, setFileModalState] = useState({
    show: false,
    fileUrl: undefined,
    fileType: undefined,
    fileName: undefined,
  });

  useEffect(() => {
    const checkForClosingEmojiEditor = (e) => {
      if (e.target !== emojiIconRef.current) setShowEmojiEditor(false);
    };

    window.addEventListener('click', checkForClosingEmojiEditor);

    return () =>
      window.removeEventListener('click', checkForClosingEmojiEditor);
  }, []);

  const messages = useMemo(() => (data ? data.data : []), [data]);

  const getMessagesGroupedByDate = useCallback(() => {
    const messagesGroupedByDate = {};
    messages.forEach((message) => {
      const { createdAt } = message;

      const groupedByDateKey = isToday(createdAt)
        ? 'Today'
        : `${moment(createdAt).format('MMMM Do, YYYY')}`;

      if (messagesGroupedByDate[groupedByDateKey]) {
        messagesGroupedByDate[groupedByDateKey].push(message);
      } else {
        messagesGroupedByDate[groupedByDateKey] = [message];
      }
    });

    const groupedMessages = [];
    for (const key in messagesGroupedByDate) {
      groupedMessages.push({
        date: key,
        messages: messagesGroupedByDate[key],
      });
    }

    return groupedMessages;
  }, [messages]);

  const messagesGroupedByDate = useMemo(() => getMessagesGroupedByDate(), [
    getMessagesGroupedByDate,
  ]);

  const usersPartOfChatExceptMe = useMemo(
    () =>
      selectedChatGroup.users
        .filter((user) => user._id.toString() !== profile?._id.toString())
        .map((user) => user._id),
    [selectedChatGroup, profile?._id]
  );

  const getMentionValue = () => {
    if (message === '' || !message.includes('@')) return '';

    if (splittedMessage[splittedMessage.length - 1]?.includes('\n@')) {
      let mentionValue = '';
      let i = splittedMessage[splittedMessage.length - 1].length - 1;
      while (splittedMessage[splittedMessage.length - 1][i] !== '@') {
        mentionValue += splittedMessage[splittedMessage.length - 1][i];
        i--;
      }
      return mentionValue.split('').reverse().join('');
    }

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (msg[0] === '@' && mentionedUsernames[msg]) continue;

      if (msg[0] === '@' && !mentionedUsernames[msg]) {
        const mentionValue = msg.replace('@', '');
        return mentionValue;
      }
    }
    return '';
  };

  const mentionValue = getMentionValue();

  const getMentionsForSelectedChat = useCallback(
    (mentionValue) => {
      const { users } = selectedChatGroup;

      return users.filter(
        (user) =>
          user.username.toLowerCase().includes(mentionValue.toLowerCase()) &&
          user._id !== profile?._id
      );
    },
    [selectedChatGroup, profile]
  );

  const mentions = useMemo(() => getMentionsForSelectedChat(mentionValue), [
    mentionValue,
    getMentionsForSelectedChat,
  ]);

  useEffect(() => {
    const scrollingElement = messagesRef.current;
    if (scrollingElement)
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
  }, [messages]);

  useEffect(() => {
    CHAT_GROUP_ID = selectedChatGroupId;
    usersWhoAreTyping = [];
    setMessage('');
  }, [selectedChatGroupId]);

  useEffect(() => {
    error && notify('error', error, 3000);
  }, [error]);

  useEffect(() => {
    socket.on(MESSAGE_CREATED, async ({ chatMessage }) => {
      if (chatMessage.chat === CHAT_GROUP_ID) {
        try {
          queryClient.invalidateQueries(['chat-messages', chatMessage.chat]);
          queryClient.refetchQueries(['chat-messages', chatMessage.chat]);
          await markChatMessageAsReadRequest(chatMessage.chat, chatMessage._id);
          queryClient.invalidateQueries(['chat-messages', chatMessage.chat]);
          queryClient.refetchQueries(['chat-messages', chatMessage.chat]);
        } catch (err) {
          notify('error', err, 3000);
        }
      }
    });

    socket.on(EMIT_TYPING_MESSAGE, ({ sender, chatGroupId }) => {
      if (chatGroupId === CHAT_GROUP_ID) {
        usersWhoAreTyping.push(sender);

        const uniqueUsersWhoAreTyping = [];
        usersWhoAreTyping.forEach((user) => {
          if (
            !uniqueUsersWhoAreTyping.some(
              (uniqueUser) => uniqueUser._id === user._id
            )
          ) {
            uniqueUsersWhoAreTyping.push(user);
          }
        });

        usersWhoAreTyping = uniqueUsersWhoAreTyping;
        setTriggerRerender((prevState) => prevState + 1);
      }
    });

    socket.on(EMIT_LEFT_TYPING_MESSAGE, ({ sender }) => {
      const updatedUsersWhoAreTyping = [...usersWhoAreTyping].filter(
        ({ _id }) => {
          return _id !== sender._id;
        }
      );

      usersWhoAreTyping = updatedUsersWhoAreTyping;
      setTriggerRerender((prevState) => prevState + 1);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkIfUserLeftTyping = () => {
      const timer = setTimeout(() => {
        if (message === messageInputRef.current?.value) {
          socket.emit(LEFT_TYPING_MESSAGE, {
            sender: {
              _id: profile?._id,
              fullName: `${profile?.firstName} ${profile?.lastName}`,
            },
            usersPartOfChat: usersPartOfChatExceptMe,
            chatGroupId: selectedChatGroupId,
          });
        }
      }, 2750);

      return timer;
    };

    const timer = checkIfUserLeftTyping();

    return () => clearTimeout(timer);
  }, [message, profile, selectedChatGroupId, usersPartOfChatExceptMe, socket]);

  const sendNotificationsToMentionedUsers = useCallback(async () => {
    try {
      const mentionedUserIds = [];
      for (const key in mentionedUsernames) {
        const userId = mentionedUsernames[key]._id;
        const isMe = profile?._id === userId;
        if (!isMe) {
          mentionedUserIds.push(userId);
        }
      }
      const requestBody = {
        userIds: mentionedUserIds,
        notificationType: 'chat-message-mention',
        entityName: selectedChatGroup.name,
        entityId: selectedChatGroupId,
      };
      await createNotificationToMultipleUsersRequest(requestBody);
    } catch (error) {
      notify('error', error, 2000);
    }
  }, [
    mentionedUsernames,
    selectedChatGroup.name,
    selectedChatGroupId,
    profile,
  ]);

  const sendMessageHandler = useCallback(async () => {
    setSendingMessage(true);

    try {
      const mentions = [];
      if (selectedChatGroup.isGroupChat) {
        Object.entries(mentionedUsernames).forEach(
          ([mentionUsername, data]) => {
            mentions.push({ mentionUsername, data: { ...data, id: data._id } });
          }
        );
      }
      const formData = new FormData();
      formData.append('message', message);
      formData.append('mentions', JSON.stringify(mentions));

      if (uploadedFiles.length > 0) {
        for (let i = 0; i < uploadedFiles.length; i++) {
          formData.append('uploadedFiles', uploadedFiles[i].url);
        }
      }

      await createMessageRequest(selectedChatGroupId, formData);

      if (selectedChatGroup.isGroupChat) sendNotificationsToMentionedUsers();

      setSplittedMessage([]);
      setMentionedUsernames({});
      socket.emit(LEFT_TYPING_MESSAGE, {
        sender: {
          _id: profile?._id,
          fullName: `${profile?.firstName} ${profile?.lastName}`,
        },
        usersPartOfChat: usersPartOfChatExceptMe,
        chatGroupId: selectedChatGroupId,
      });
      setMessage('');
      setUploadedFiles([]);
      queryClient.invalidateQueries(['chat-messages', selectedChatGroupId]);
      queryClient.refetchQueries(['chat-messages', selectedChatGroupId]);
    } catch (error) {
      notify('error', error, 3000);
    }

    setSendingMessage(false);
  }, [
    message,
    selectedChatGroup,
    selectedChatGroupId,
    profile,
    usersPartOfChatExceptMe,
    queryClient,
    socket,
    mentionedUsernames,
    sendNotificationsToMentionedUsers,
    uploadedFiles,
  ]);

  useEffect(() => {
    const keyPressHandler = (e) => {
      if (e.key === 'Enter' && e.shiftKey) {
        return;
      }

      if (e.key === 'Enter') {
        sendMessageHandler();
      }
    };

    const registerEnterPressEvent = () => {
      document.addEventListener('keypress', keyPressHandler);
    };

    registerEnterPressEvent();

    return () => document.removeEventListener('keypress', keyPressHandler);
  }, [sendMessageHandler]);

  const messageChangeHandler = (e) => {
    const message = e.target.value;
    const splittedMessage = message.split(' ');

    setMessage(message);

    setSplittedMessage(splittedMessage);

    socket.emit(TYPING_MESSAGE, {
      sender: {
        _id: profile?._id,
        fullName: `${profile?.firstName} ${profile?.lastName}`,
      },
      usersPartOfChat: usersPartOfChatExceptMe,
      chatGroupId: selectedChatGroupId,
    });

    const updatedMentionedUsernames = {};
    for (const usernameKey in mentionedUsernames) {
      if (splittedMessage.includes(usernameKey)) {
        updatedMentionedUsernames[usernameKey] =
          mentionedUsernames[usernameKey];
      }
    }

    setMentionedUsernames(updatedMentionedUsernames);
  };

  const shouldNotShowMentionsTable = () => {
    return splittedMessage.every((msg) => {
      return (
        msg === '' ||
        msg === ' ' ||
        msg[0] !== '@' ||
        (msg[0] === '@' && typeof mentionedUsernames[msg] === 'object')
      );
    });
  };

  const toggleEmojiEditorHandler = () => {
    setShowEmojiEditor((prevState) => !prevState);
  };

  const selectUserToMentionHandler = (user) => {
    const updatedMentionedUsernames = { ...mentionedUsernames };

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (
        msg[0] === '@' &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[i] = `@${username}`;
      } else if (
        splittedMessage[splittedMessage.length - 1]?.includes('\n@') &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[splittedMessage.length - 1] = `\n@${username}`;
      }
    }

    splittedMessage.push('');
    setMentionedUsernames(updatedMentionedUsernames);
    setMessage(splittedMessage.join(' '));
    setSplittedMessage(splittedMessage);
    messageInputRef.current.focus();
  };

  const getMessageStatus = useCallback((receivedBy, readBy) => {
    const isMessageRead = readBy && readBy.length >= 2;

    if (isMessageRead) {
      return MESSAGE_STATUSES.READ;
    }

    const isMessageReceived = receivedBy && receivedBy.length >= 2;

    if (isMessageReceived) {
      return MESSAGE_STATUSES.RECEIVED;
    }

    return MESSAGE_STATUSES.DELIVERED;
  }, []);

  const isSendButtonDisabled = () => {
    if (sendingMessage || (!message && uploadedFiles.length === 0)) return true;

    return false;
  };

  const renderIconAccordingToMessageStatus = (messageStatus) => {
    if (messageStatus === MESSAGE_STATUSES.DELIVERED)
      return (
        <span className={classes['deliver-icon-container']}>
          <img src={DeliveredMessageIcon} alt="Delivered" />
        </span>
      );
    if (messageStatus === MESSAGE_STATUSES.RECEIVED)
      return (
        <span className={classes['deliver-icon-container']}>
          <img src={DeliveredMessageIcon} alt="Delivered" />
        </span>
      );
    if (messageStatus === MESSAGE_STATUSES.READ)
      return (
        <span className={classes['deliver-icon-container']}>
          <img
            alt="Read"
            src={ReadMessageIcon}
            style={{ padding: 0, margin: 0 }}
          />
        </span>
      );
  };

  const renderMessages = useCallback(() => {
    if (messagesGroupedByDate.length === 0) {
      const { isGroupChat } = selectedChatGroup;

      if (!isGroupChat) {
        const chatUsers = selectedChatGroup.users;
        const otherUserIfSingleChat = chatUsers.find(
          (user) => user._id !== profile?._id
        );

        return (
          <div className={classes['selected-user-information']}>
            <img
              alt={otherUserIfSingleChat.fullName}
              src={otherUserIfSingleChat.photo}
              className={classes['selected-user-photo']}
            />

            <h3 className={classes['selected-user-fullName']}>
              {otherUserIfSingleChat.fullName}
            </h3>

            <p className={classes['start-message']}>Start your first message</p>
          </div>
        );
      } else {
        return <p className={classes['no-messages']}>No messages yet</p>;
      }
    }

    return messagesGroupedByDate.map(({ date, messages }) => {
      return (
        <div key={date}>
          <p className={classes['date-text']}>{date}</p>
          {messages.map(
            ({
              message,
              _id,
              user: sender,
              createdAt,
              receivedBy,
              readBy,
              mentions,
              uploadedFiles,
            }) => {
              const isMyMessage = profile?._id === sender._id;

              if (isMyMessage) {
                const messageStatus = getMessageStatus(receivedBy, readBy);
                return (
                  <>
                    {message && (
                      <div
                        key={_id}
                        className={classes['my-message-container']}
                      >
                        <div className={classes['my-message-content']}>
                          {message &&
                            message.split('\n').map((newLineMessage, i) => {
                              const splittedNewLineMessage = newLineMessage.split(
                                ' '
                              );
                              return (
                                <p
                                  className={classes['my-message-text']}
                                  key={
                                    newLineMessage +
                                    i +
                                    Math.random().toString()
                                  }
                                >
                                  {splittedNewLineMessage.map((msg) => {
                                    if (msg === '\r')
                                      return (
                                        <p
                                          key={
                                            msg + i + Math.random().toString()
                                          }
                                          className={classes['empty-space']}
                                        >
                                          &nbsp;
                                        </p>
                                      );

                                    if (msg === '' || msg === ' ')
                                      return (
                                        <span
                                          key={
                                            msg + i + Math.random().toString()
                                          }
                                        >
                                          {' '}
                                        </span>
                                      );
                                    const mentionIndex = mentions.findIndex(
                                      ({ mentionUsername }) =>
                                        mentionUsername === msg
                                    );

                                    const isMention =
                                      msg[0] === '@' && mentionIndex !== -1;

                                    if (isMention) {
                                      return (
                                        <span
                                          key={
                                            msg + i + Math.random().toString()
                                          }
                                        >
                                          <span> </span>
                                          <span
                                            className={classes['my-mention']}
                                            onClick={() =>
                                              history.push(
                                                mentions[mentionIndex]?.data
                                                  .id === profile?._id
                                                  ? '/my-space'
                                                  : `/user-feed/${mentions[mentionIndex]?.data.id}`
                                              )
                                            }
                                          >
                                            {msg}
                                          </span>
                                        </span>
                                      );
                                    }

                                    if (isValidUrl(msg)) {
                                      return (
                                        <span
                                          key={
                                            msg + i + Math.random().toString()
                                          }
                                        >
                                          <span> </span>
                                          <a
                                            rel="noreferrer"
                                            className={
                                              classes['my-message-link']
                                            }
                                            target={'_blank'}
                                            href={
                                              msg.startsWith('https://')
                                                ? msg
                                                : `https://${msg}`
                                            }
                                          >
                                            {msg}
                                          </a>
                                        </span>
                                      );
                                    }

                                    return (
                                      <span
                                        key={msg + i + Math.random().toString()}
                                      >
                                        <span> </span>
                                        <span>{msg}</span>
                                      </span>
                                    );
                                  })}
                                </p>
                              );
                            })}

                          <div
                            className={classes['date-delivered-icon-container']}
                          >
                            <p className={classes['my-created-at']}>
                              {moment(createdAt).format('HH:mm A')}
                            </p>
                            {renderIconAccordingToMessageStatus(messageStatus)}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={classes['my-uploaded-files']}>
                      {uploadedFiles.map(
                        ({ fileType, fileUrl, fileName, fileSize }, i) => {
                          if (fileType === 'application/pdf') {
                            return (
                              <div
                                onClick={() =>
                                  downloadFile({
                                    fileUrl,
                                    fileName,
                                    contentType: fileType,
                                  })
                                }
                                key={fileUrl + i}
                                className={classes['my-pdf-message-content']}
                              >
                                <div
                                  className={classes['file-fileName-container']}
                                >
                                  <Document file={fileUrl}>
                                    <Page height={25} pageNumber={1} />
                                  </Document>

                                  <div
                                    className={
                                      classes['fileName-fileSize-container']
                                    }
                                  >
                                    <p className={classes['my-fileName']}>
                                      {fileName}
                                    </p>
                                    <p className={classes['my-file-size']}>
                                      {convertToMB(fileSize)} MB
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={
                                    classes['date-delivered-icon-container']
                                  }
                                >
                                  <p className={classes['my-created-at']}>
                                    {moment(createdAt).format('HH:mm A')}
                                  </p>
                                  {renderIconAccordingToMessageStatus(
                                    messageStatus
                                  )}
                                </div>
                              </div>
                            );
                          } else if (fileType === 'video/mp4') {
                            return (
                              <div
                                onClick={() =>
                                  setFileModalState({
                                    show: true,
                                    fileUrl,
                                    fileType,
                                    fileName,
                                  })
                                }
                                key={fileUrl + i}
                                className={classes['my-pdf-message-content']}
                              >
                                <div
                                  className={classes['file-fileName-container']}
                                >
                                  <video
                                    height={30}
                                    src={fileUrl}
                                    alt="FileImage"
                                  />
                                  <div
                                    className={
                                      classes['fileName-fileSize-container']
                                    }
                                  >
                                    <p className={classes['my-fileName']}>
                                      {fileName}
                                    </p>
                                    <p className={classes['my-file-size']}>
                                      {convertToMB(fileSize)} MB
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={
                                    classes['date-delivered-icon-container']
                                  }
                                >
                                  <p className={classes['my-created-at']}>
                                    {moment(createdAt).format('HH:mm A')}
                                  </p>
                                  {renderIconAccordingToMessageStatus(
                                    messageStatus
                                  )}
                                </div>
                              </div>
                            );
                          } else if (fileType.startsWith('image')) {
                            return (
                              <div
                                onClick={() =>
                                  setFileModalState({
                                    show: true,
                                    fileUrl,
                                    fileType,
                                    fileName,
                                  })
                                }
                                key={fileUrl + i}
                                className={classes['my-pdf-message-content']}
                              >
                                <div
                                  className={classes['file-fileName-container']}
                                >
                                  <img
                                    height={30}
                                    src={fileUrl}
                                    alt="FileImage"
                                  />
                                  <div
                                    className={
                                      classes['fileName-fileSize-container']
                                    }
                                  >
                                    <p className={classes['my-fileName']}>
                                      {fileName}
                                    </p>
                                    <p className={classes['my-file-size']}>
                                      {convertToMB(fileSize)} MB
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={
                                    classes['date-delivered-icon-container']
                                  }
                                >
                                  <p className={classes['my-created-at']}>
                                    {moment(createdAt).format('HH:mm A')}
                                  </p>
                                  {renderIconAccordingToMessageStatus(
                                    messageStatus
                                  )}
                                </div>
                              </div>
                            );
                          }

                          return null;
                        }
                      )}
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    {message && (
                      <div
                        key={_id}
                        className={classes['other-user-message-container']}
                      >
                        <Link to={`/user-feed/${sender._id}`}>
                          <img
                            alt={sender.fullName}
                            width={40}
                            height={40}
                            className={classes['sender-photo']}
                            src={sender.photo}
                          />
                        </Link>
                        <div className={classes['message-content']}>
                          {message.split('\n').map((newLineMessage, i) => {
                            const splittedNewLineMessage = newLineMessage.split(
                              ' '
                            );
                            return (
                              <p
                                className={classes['message-text']}
                                key={
                                  newLineMessage + i + Math.random().toString()
                                }
                              >
                                {splittedNewLineMessage.map((msg) => {
                                  if (msg === '\r')
                                    return (
                                      <p
                                        key={msg + i + Math.random().toString()}
                                        className={classes['empty-space']}
                                      >
                                        &nbsp;
                                      </p>
                                    );

                                  if (msg === '' || msg === ' ')
                                    return (
                                      <span
                                        key={msg + i + Math.random().toString()}
                                      >
                                        {' '}
                                      </span>
                                    );
                                  const mentionIndex = mentions.findIndex(
                                    ({ mentionUsername }) =>
                                      mentionUsername === msg
                                  );

                                  const isMention =
                                    msg[0] === '@' && mentionIndex !== -1;

                                  if (isMention) {
                                    return (
                                      <span
                                        className={classes['mention']}
                                        key={msg + i + Math.random().toString()}
                                      >
                                        <span> </span>
                                        <span
                                          onClick={() =>
                                            history.push(
                                              mentions[mentionIndex]?.data
                                                .id === profile?._id
                                                ? '/my-space'
                                                : `/user-feed/${mentions[mentionIndex]?.data.id}`
                                            )
                                          }
                                        >
                                          {msg}
                                        </span>
                                      </span>
                                    );
                                  }

                                  if (isValidUrl(msg)) {
                                    return (
                                      <span
                                        key={msg + i + Math.random().toString()}
                                      >
                                        <span> </span>
                                        <a
                                          rel="noreferrer"
                                          className={classes['other-user-link']}
                                          target={'_blank'}
                                          href={
                                            msg.startsWith('https://')
                                              ? msg
                                              : `https://${msg}`
                                          }
                                        >
                                          {msg}
                                        </a>
                                      </span>
                                    );
                                  }

                                  return (
                                    <span
                                      key={msg + i + Math.random().toString()}
                                    >
                                      <span> </span>
                                      <span>{msg}</span>
                                    </span>
                                  );
                                })}
                              </p>
                            );
                          })}

                          <p className={classes['created-at']}>
                            {moment(createdAt).format('HH:mm A')}
                          </p>
                        </div>
                      </div>
                    )}
                    {uploadedFiles?.length > 0 && (
                      <div className={classes['other-user-message-container']}>
                        <Link to={`/user-feed/${sender._id}`}>
                          <img
                            alt={sender.fullName}
                            width={40}
                            height={40}
                            className={classes['sender-photo']}
                            src={sender.photo}
                          />
                        </Link>

                        <div className={classes['uploaded-files-container']}>
                          {uploadedFiles.map(
                            ({ fileType, fileUrl, fileName, fileSize }, i) => {
                              if (fileType === 'application/pdf') {
                                return (
                                  <div
                                    onClick={() =>
                                      downloadFile({
                                        fileUrl,
                                        fileName,
                                        contentType: fileType,
                                      })
                                    }
                                    key={fileUrl + i}
                                  >
                                    <div
                                      className={classes['pdf-message-content']}
                                    >
                                      <div
                                        className={
                                          classes['file-fileName-container']
                                        }
                                      >
                                        <Document file={fileUrl}>
                                          <Page height={25} pageNumber={1} />
                                        </Document>

                                        <div
                                          className={
                                            classes[
                                              'fileName-fileSize-container'
                                            ]
                                          }
                                        >
                                          <p className={classes['fileName']}>
                                            {fileName}
                                          </p>
                                          <p className={classes['file-size']}>
                                            {convertToMB(fileSize)} MB
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          classes[
                                            'date-delivered-icon-container'
                                          ]
                                        }
                                      >
                                        <p className={classes['created-at']}>
                                          {moment(createdAt).format('HH:mm A')}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else if (fileType === 'video/mp4') {
                                return (
                                  <div
                                    onClick={() =>
                                      setFileModalState({
                                        show: true,
                                        fileUrl,
                                        fileType,
                                        fileName,
                                      })
                                    }
                                    key={fileUrl + i}
                                    className={classes['pdf-message-content']}
                                  >
                                    <div
                                      className={
                                        classes['file-fileName-container']
                                      }
                                    >
                                      <video
                                        height={30}
                                        src={fileUrl}
                                        alt="FileVideo"
                                      />
                                      <div
                                        className={
                                          classes['fileName-fileSize-container']
                                        }
                                      >
                                        <p className={classes['fileName']}>
                                          {fileName}
                                        </p>
                                        <p className={classes['file-size']}>
                                          {convertToMB(fileSize)} MB
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        classes['date-delivered-icon-container']
                                      }
                                    >
                                      <p className={classes['created-at']}>
                                        {moment(createdAt).format('HH:mm A')}
                                      </p>
                                    </div>
                                  </div>
                                );
                              } else if (fileType.startsWith('image')) {
                                return (
                                  <div
                                    onClick={() =>
                                      setFileModalState({
                                        show: true,
                                        fileUrl,
                                        fileType,
                                        fileName,
                                      })
                                    }
                                    key={fileUrl + i}
                                    className={classes['pdf-message-content']}
                                  >
                                    <div
                                      className={
                                        classes['file-fileName-container']
                                      }
                                    >
                                      <img
                                        height={30}
                                        src={fileUrl}
                                        alt="FileImage"
                                      />
                                      <div
                                        className={
                                          classes['fileName-fileSize-container']
                                        }
                                      >
                                        <p className={classes['fileName']}>
                                          {fileName}
                                        </p>
                                        <p className={classes['file-size']}>
                                          {convertToMB(fileSize)} MB
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        classes['date-delivered-icon-container']
                                      }
                                    >
                                      <p className={classes['created-at']}>
                                        {moment(createdAt).format('HH:mm A')}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }

                              return null;
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </>
                );
              }
            }
          )}
        </div>
      );
    });
  }, [
    messagesGroupedByDate,
    profile?._id,
    selectedChatGroup,
    getMessageStatus,
    history,
  ]);

  const shouldShowMentionsTableInNewLine = () => {
    return splittedMessage[splittedMessage.length - 1]?.includes('\n@');
  };

  const renderUsersWhoAreTyping = () => {
    if (usersWhoAreTyping.length === 0)
      return <p className={classes['typing']}></p>;
    if (usersWhoAreTyping.length > 3) {
      return (
        <p className={classes['typing']}>
          {usersWhoAreTyping[0].fullName}, ${usersWhoAreTyping[1].fullName}
          {usersWhoAreTyping[2].fullName} and others are typing...
        </p>
      );
    } else {
      const users = usersWhoAreTyping.map((user) => user.fullName);

      return (
        <p className={classes['typing']}>
          {users.join(' and ')} {users.length === 1 ? 'is' : 'are'} typing...
        </p>
      );
    }
  };

  const inputFileHandler = (value) => {
    if (!value) return;

    let files = [...value];

    let totalFilesSize = 0;
    files.forEach((el) => {
      totalFilesSize += el.size;
    });
    if (totalFilesSize > 10000000)
      return notify(
        'error',
        'You can not upload more than 10MB Content!',
        2000
      );

    files.forEach((value) => {
      const fileReader = new FileReader();

      try {
        fileReader.onload = () => {
          setUploadedFiles((prevState) => [
            ...prevState,
            { type: value.type, url: value, previewUrl: fileReader.result },
          ]);
        };

        fileReader.readAsDataURL(value);
      } catch (err) {
        notify('error', err, 2000);
      }
    });
  };

  const removeUploadedFileHandler = (index) => {
    setUploadedFiles((prevState) => prevState.filter((_, i) => i !== index));
  };

  const invalidateNumberOfMyRequestedChats = async () => {
    await Promise.all([
      queryClient.invalidateQueries('number-of-my-requested-chats'),
      queryClient.refetchQueries('number-of-my-requested-chats'),
    ]);
  };

  const acceptChatRequestHandler = async () => {
    try {
      await acceptChatRequest(selectedChatGroupId);
      await Promise.all([
        queryClient.invalidateQueries('my-requested-chats'),
        queryClient.refetchQueries('my-requested-chats'),
        queryClient.invalidateQueries('my-chats'),
        queryClient.refetchQueries('my-chats'),
        invalidateNumberOfMyRequestedChats(),
      ]);
      setRequestedChat(false);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const rejectChatRequestHandler = async () => {
    try {
      await rejectChatRequest(selectedChatGroupId);
      await Promise.all([
        queryClient.invalidateQueries('my-requested-chats'),
        queryClient.refetchQueries('my-requested-chats'),
        invalidateNumberOfMyRequestedChats(),
      ]);

      setSelectedChat(undefined);
      setSelectedChatGroupId(undefined);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  // const resizeTextAreaHeightOnKeyupHandler = (e) => {
  //   const element = e.target;

  //   if (
  //     element.style?.height?.replace('px', '') &&
  //     parseInt(element.style.height.replace('px', '')) > 80
  //   )
  //     return;

  //   element.style.height = '1px';
  //   element.style.height = 5 + element.scrollHeight + 'px';
  // };

  // useEffect(() => {
  //   if (message.length === 0 && messageInputRef.current) {
  //     messageInputRef.current.style.height = '1px';
  //     messageInputRef.current.style.height =
  //       5 + messageInputRef.current.scrollHeight + 'px';
  //   }
  // }, [message, messageInputRef]);

  return (
    <>
      {fileModalState.show && (
        <FileModal
          title={fileModalState.fileName}
          setShowImageModal={() =>
            setFileModalState({
              show: false,
              fileUrl: undefined,
              fileType: undefined,
              fileName: undefined,
            })
          }
        >
          {fileModalState.fileType.startsWith('image') && (
            <img
              style={{ maxHeight: 600 }}
              src={fileModalState.fileUrl}
              alt="File"
            />
          )}
          {fileModalState.fileType.startsWith('video') && (
            <ReactPlayer
              style={{
                objectFit: 'cover',
                borderRadius: 10,
              }}
              url={fileModalState.fileUrl}
              alt="File"
              controls
            />
          )}
        </FileModal>
      )}

      <div className={classes['messages-container']}>
        <div ref={messagesRef} className={classes['messages']}>
          {renderMessages()}
        </div>

        <div className={classes['input-message-container']}>
          <div className={classes['typing-message']}>
            {renderUsersWhoAreTyping()}
          </div>
          <div className={classes['input-send-container']}>
            <div className={classes['mentions-send-message-container']}>
              {!requestedChat ? (
                <>
                  <div className={classes['send-message-container']}>
                    <textarea
                      // onKeyUp={resizeTextAreaHeightOnKeyupHandler}
                      className={classes['textarea']}
                      ref={messageInputRef}
                      value={message}
                      onChange={messageChangeHandler}
                      placeholder="Type message"
                    />

                    {showEmojiEditor && (
                      <div className={classes['emoji-container']}>
                        <Picker
                          title="Pick your emoji"
                          emoji="point_up"
                          onSelect={({ native }) =>
                            setMessage((prevMessage) => prevMessage + native)
                          }
                        />
                      </div>
                    )}
                    <img
                      alt="Emoji"
                      ref={emojiIconRef}
                      onClick={toggleEmojiEditorHandler}
                      src={EmojiIcon}
                      className={classes['emoji-icon']}
                    />

                    <MultipleFileUpload
                      accept=".mp4,.jpg,.png,.jpeg,.pdf"
                      onInput={inputFileHandler}
                    >
                      <img
                        src={ImagePostIcon}
                        alt="File"
                        className={classes['file-icon']}
                      />
                    </MultipleFileUpload>

                    <Button
                      style={{
                        borderTopRightRadius: 16,
                        borderBottomRightRadius: 16,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        padding: '.75rem 1.5rem',
                      }}
                      disabled={isSendButtonDisabled()}
                      onClick={sendMessageHandler}
                      darkpink="true"
                    >
                      Send
                    </Button>
                  </div>
                  {uploadedFiles.length > 0 && (
                    <div className={classes['uploaded-files-preview']}>
                      {uploadedFiles.map(({ type, previewUrl }, i) => {
                        if (type.startsWith('image')) {
                          return (
                            <div
                              key={previewUrl + i}
                              className={classes['file-remove-container']}
                            >
                              <img
                                className={classes['file-preview']}
                                src={previewUrl}
                                alt="Preview"
                              />
                              <Button
                                onClick={() => removeUploadedFileHandler(i)}
                                pinkcolor="true"
                                style={{ minWidth: 110 }}
                              >
                                Remove
                              </Button>
                            </div>
                          );
                        } else if (type.startsWith('video')) {
                          return (
                            <div
                              key={previewUrl + i}
                              className={classes['file-remove-container']}
                            >
                              <ReactPlayer
                                controls
                                url={previewUrl}
                                className={classes['file-preview']}
                                alt="Preview"
                              />
                              <Button
                                onClick={() => removeUploadedFileHandler(i)}
                                pinkcolor="true"
                                style={{ minWidth: 110 }}
                              >
                                Remove
                              </Button>
                            </div>
                          );
                        } else if (type === 'application/pdf') {
                          return (
                            <div
                              key={previewUrl + i}
                              className={classes['file-remove-container']}
                            >
                              <div
                                className={classes['file-fileName-container']}
                              >
                                <Document file={previewUrl}>
                                  <Page height={150} pageNumber={1} />
                                </Document>
                              </div>
                              <Button
                                onClick={() => removeUploadedFileHandler(i)}
                                pinkcolor="true"
                                style={{ minWidth: 110, marginTop: 125 }}
                              >
                                Remove
                              </Button>
                            </div>
                          );
                        }

                        return null;
                      })}
                    </div>
                  )}
                </>
              ) : (
                <div
                  className={classes['accept-reject-requested-chat-container']}
                >
                  <Button
                    onClick={acceptChatRequestHandler}
                    style={{ width: 200 }}
                    pinkcolor="true"
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={rejectChatRequestHandler}
                    style={{ width: 200 }}
                    reject="true"
                  >
                    Reject
                  </Button>
                </div>
              )}
              {selectedChatGroup.isGroupChat &&
                (!shouldNotShowMentionsTable() ||
                  shouldShowMentionsTableInNewLine()) && (
                  <div className={classes['mention-container']}>
                    {mentions.length === 0 && (
                      <p className={classes['no-users-found']}>
                        No Users Found
                      </p>
                    )}

                    {mentions.map((user) => {
                      const fullName = `${user.firstName} ${user.lastName}`;
                      return (
                        <div
                          onClick={() => selectUserToMentionHandler(user)}
                          className={classes['user-container']}
                          key={user._id}
                        >
                          <img
                            alt="userPhoto"
                            className={classes['user-image']}
                            src={user.photo}
                          />
                          <p className={classes['user-text']}>
                            {user.username} ({fullName})
                            {user._id === profile?._id ? ' (You)' : ''}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(Messages);
