import React, { useContext, useState, useRef, useEffect } from 'react';
import classes from './EditDedicatedPostModal.module.css';
import Button from '../../../../../../../../components/Button/Button';
import Modal from '../../../../../../../../components/Modal/Modal';
import { AuthContext } from '../../../../../../../../context/auth-context';
import notify from '../../../../../../../../utils/notify';
import RemoveIcon from '../../../../../../../../assets/white-remove-icon.png';
import ImagePostIcon from '../../../../../../../../assets/image-post-1.png';
import ReactPlayer from 'react-player';
import ImageVideoUpload from '../../../../../../../../components/ImageVideoUpload/ImageVideoUpload';
import EmojiIcon from '../../../../../../../../assets/emoji.png';
import { Picker } from 'emoji-mart';
import { updateDedicatedPostRequest } from '../../../../../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';

function EditDedicatedPostModal(props) {
  const queryClient = useQueryClient();
  const { post, setShowEditDedicatedPostModal } = props;
  const { profile } = useContext(AuthContext);

  const postContentInputRef = useRef();

  const [postContent, setPostContent] = useState(post.postContent);
  const [selectedFeeling, setSelectedFeeling] = useState(post.feeling);
  const [postImagePreviewUrl, setPostImagePreviewUrl] = useState(
    post.postImage
  );
  const [additionalImagesPreviewUrl, setAdditionalImagesPreviewUrl] = useState(
    post.additionalImages || []
  );
  const [postVideoPreviewUrl, setPostVideoPreviewUrl] = useState(
    post.postVideo
  );
  const [additionalVideosPreviewUrl, setAdditionalVideosPreviewUrl] = useState(
    post.additionalVideos || []
  );

  const [showEmojiEditor, setShowEmojiEditor] = useState(false);
  const [newUploadedVideosUrl, setNewUploadedVideosUrl] = useState([]);
  const [newUploadedImagesUrl, setNewUploadedImagesUrl] = useState([]);
  const [
    newUploadedVideosPreviewUrl,
    setNewUploadedVideosPreviewUrl,
  ] = useState([]);
  const [
    newUploadedImagesPreviewUrl,
    setNewUploadedImagesPreviewUrl,
  ] = useState([]);
  const [editingPost, setEditingPost] = useState(false);

  useEffect(() => {
    const checkForClosingEmojiPicker = (e) => {
      if (e.target.id.startsWith('emoji')) {
        setShowEmojiEditor(true);
      } else if (e.target.closest('#emoji-container') === null) {
        setShowEmojiEditor(false);
      }
    };

    window.addEventListener('click', checkForClosingEmojiPicker);
  }, []);

  const closeModalHandler = () => {
    setShowEditDedicatedPostModal(false);
  };

  const inputFileHandler = (value) => {
    if (!value) return;

    const fileReader = new FileReader();

    if (value.type === 'video/mp4') {
      setNewUploadedVideosUrl((prevState) => [...prevState, value]);
    } else {
      setNewUploadedImagesUrl((prevState) => [...prevState, value]);
    }

    try {
      fileReader.onload = () => {
        if (value.type === 'video/mp4') {
          setNewUploadedVideosPreviewUrl((prevState) => [
            ...prevState,
            fileReader.result,
          ]);
        } else {
          setNewUploadedImagesPreviewUrl((prevState) => [
            ...prevState,
            fileReader.result,
          ]);
        }
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const postContentChangeHandler = (e) => {
    setPostContent(e.target.value);
  };

  const editPostHandler = async () => {
    setEditingPost(true);

    const formData = new FormData();
    if (postContent) formData.append('postContent', postContent);
    if (selectedFeeling) formData.append('feeling', selectedFeeling);
    if (postImagePreviewUrl) formData.append('postImage', postImagePreviewUrl);
    formData.append(
      'additionalImages',
      JSON.stringify(additionalImagesPreviewUrl)
    );
    if (postVideoPreviewUrl) formData.append('postVideo', postVideoPreviewUrl);
    formData.append(
      'additionalVideos',
      JSON.stringify(additionalVideosPreviewUrl)
    );

    if (newUploadedImagesUrl.length > 0) {
      for (let i = 0; i < newUploadedImagesUrl.length; i++) {
        formData.append('newImages', newUploadedImagesUrl[i]);
      }
    }
    if (newUploadedVideosUrl.length > 0) {
      for (let i = 0; i < newUploadedVideosUrl.length; i++) {
        formData.append('newVideos', newUploadedVideosUrl[i]);
      }
    }

    try {
      await updateDedicatedPostRequest(post._id, formData);

      if (post.to?._id) {
        await Promise.all([
          queryClient.invalidateQueries(['posts', post.to._id]),

          queryClient.refetchQueries(['posts', post.to._id]),
        ]);
      }

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setEditingPost(false);
  };

  const removeVideoHandler = () => {
    setPostVideoPreviewUrl(undefined);
  };

  const removeImageHandler = () => {
    setPostImagePreviewUrl(undefined);
  };

  const removeAdditionalImageUrl = (index) => {
    setAdditionalImagesPreviewUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  const removeAdditionalVideoUrl = (index) => {
    setAdditionalVideosPreviewUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  const removeNewImageUrl = (index) => {
    setNewUploadedImagesPreviewUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
    setNewUploadedImagesUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  const removeNewVideoUrl = (index) => {
    setNewUploadedVideosPreviewUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
    setNewUploadedVideosUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };
  return (
    <Modal
      style={{ height: 600 }}
      closeModal={closeModalHandler}
      title="Edit Post"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            disabled={editingPost}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={editingPost}
            darkpink="true"
            onClick={editPostHandler}
          >
            Save
          </Button>
        </div>
      }
    >
      <div>
        <div className={classes['user-post-status']}>
          <img
            alt={profile?.fullName}
            src={profile?.photo}
            width={45}
            height={45}
            className={classes['user-image']}
          />

          <div className={classes['fullName-post-status-container']}>
            <div className={classes['fullName-feeling-container']}>
              <h4 className={classes['user-fullName']}>{profile?.fullName}</h4>
              {selectedFeeling && (
                <div className={classes['feeling-container']}>
                  {' '}
                  <span className={classes['circle']}>&nbsp;</span>{' '}
                  <p className={classes['feeling-text']}>
                    Feeling {selectedFeeling}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={classes['input-container']}>
          <textarea
            ref={postContentInputRef}
            className={classes['textarea']}
            style={{ minHeight: 100, width: '100%' }}
            value={postContent}
            onChange={postContentChangeHandler}
            placeholder="Post Content"
          />

          <img
            alt="Emoji"
            src={EmojiIcon}
            className={classes['emoji-icon']}
            id="emoji-icon"
          />

          <ImageVideoUpload onInput={inputFileHandler}>
            <img
              alt="Post"
              src={ImagePostIcon}
              className={classes['image-post-icon']}
            />
          </ImageVideoUpload>

          {showEmojiEditor && (
            <div id="emoji-container" className={classes['emoji-container']}>
              <Picker
                emojiSize={20}
                title="Pick your emoji"
                emoji="point_up"
                onSelect={({ native }) => {
                  setShowEmojiEditor(false);
                  setSelectedFeeling(native);
                }}
              />
            </div>
          )}
        </div>

        {(postImagePreviewUrl ||
          postVideoPreviewUrl ||
          additionalImagesPreviewUrl.length > 0 ||
          additionalVideosPreviewUrl.length > 0 ||
          newUploadedImagesPreviewUrl.length > 0 ||
          newUploadedVideosPreviewUrl.length > 0) && (
          <div className={classes['upload-image-container']}>
            {postVideoPreviewUrl && (
              <div className={classes['video-container']}>
                <ReactPlayer
                  width={300}
                  height={155}
                  url={postVideoPreviewUrl}
                  controls
                  style={{
                    borderRadius: '13px',
                    objectFit: 'cover',
                    marginTop: 27,
                  }}
                />

                <div
                  onClick={removeVideoHandler}
                  className={classes['remove-video-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            )}
            {postImagePreviewUrl && (
              <div className={classes['image-container']}>
                <img
                  alt="Preview"
                  src={postImagePreviewUrl}
                  className={classes['uploaded-image']}
                />
                <div
                  onClick={removeImageHandler}
                  className={classes['remove-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            )}

            {additionalImagesPreviewUrl.map((previewUrl, i) => (
              <div key={previewUrl + i} className={classes['image-container']}>
                <img
                  alt="Preview"
                  src={previewUrl}
                  className={classes['uploaded-image']}
                />
                <div
                  onClick={() => removeAdditionalImageUrl(i)}
                  className={classes['remove-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            ))}

            {newUploadedImagesPreviewUrl.map((previewUrl, i) => (
              <div key={previewUrl + i} className={classes['image-container']}>
                <img
                  alt="Preview"
                  src={previewUrl}
                  className={classes['uploaded-image']}
                />
                <div
                  onClick={() => removeNewImageUrl(i)}
                  className={classes['remove-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            ))}

            {additionalVideosPreviewUrl.map((previewUrl, i) => (
              <div key={previewUrl + i} className={classes['video-container']}>
                <ReactPlayer
                  width={300}
                  height={155}
                  url={previewUrl}
                  controls
                  style={{
                    borderRadius: '13px',
                    objectFit: 'cover',
                    marginTop: 27,
                  }}
                />

                <div
                  onClick={() => removeAdditionalVideoUrl(i)}
                  className={classes['remove-video-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            ))}

            {newUploadedVideosPreviewUrl.map((previewUrl, i) => (
              <div key={previewUrl + i} className={classes['video-container']}>
                <ReactPlayer
                  width={300}
                  height={155}
                  url={previewUrl}
                  controls
                  style={{
                    borderRadius: '13px',
                    objectFit: 'cover',
                    marginTop: 27,
                  }}
                />

                <div
                  onClick={() => removeNewVideoUrl(i)}
                  className={classes['remove-video-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default EditDedicatedPostModal;
