import React, { useContext, useState } from 'react';
import classes from './CreateGroupPollModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import TextArea from '../../../../components/TextArea/TextArea';
import { AuthContext } from '../../../../context/auth-context';
import Input from '../../../../components/Input/Input';
import notify from '../../../../utils/notify';
import { createGroupPollRequest } from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';

const POLL_DURATIONS = [
  {
    value: '30 Mins',
    durationInHours: 0.5,
  },
  {
    value: '1 Hour',
    durationInHours: 1,
  },
  {
    value: '12 Hours',
    durationInHours: 12,
  },
];

const ANSWERS_SECTIONS = {
  SPECIFIC_ANSWERS: 'Specific Answers',
  COMMUNITY_ANSWERS: 'Community Answers',
};

function CreateGroupPollModal(props) {
  const queryClient = useQueryClient();
  const { profile } = useContext(AuthContext);
  const { setShowCreateGroupPollModal, groupId } = props;

  const [pollQuestion, setPollQuestion] = useState('');
  const [pollAnswers, setPollAnswers] = useState([
    { value: '', touched: false, isValid: false },
    { value: '', touched: false, isValid: false },
  ]);
  const [pollDurationInHoursValid, setPollDurationInHoursValid] = useState(
    false
  );
  const [pollDurationInHoursTouched, setPollDurationInHoursTouched] = useState(
    false
  );
  const [pollDurationInHours, setPollDurationInHours] = useState();
  const [selectedAnswersSection, setSelectedAnswersSection] = useState(
    'Specific Answers'
  );

  const closeModalHandler = () => {
    setShowCreateGroupPollModal(false);
  };

  const updatePollAnswersHandler = (index, value) => {
    setPollAnswers((prevState) =>
      prevState.map((answer, i) =>
        i === index
          ? { value, isValid: value.length > 0, touched: true }
          : answer
      )
    );
  };

  const addAnotherOptionHandler = () => {
    setPollAnswers((prevState) => [
      ...prevState,
      { value: '', touched: false, isValid: false },
    ]);
  };

  const durationInHoursChangeHandler = (e) => {
    const { value } = e.target;
    setPollDurationInHoursTouched(true);

    if (!value || isNaN(value) || value < 0) {
      setPollDurationInHoursValid(false);
    } else {
      setPollDurationInHoursValid(true);
    }

    setPollDurationInHours(e.target.value);
  };

  const selectDurationInHoursHandler = (durationInHours) => {
    setPollDurationInHoursTouched(true);
    setPollDurationInHoursValid(true);
    setPollDurationInHours(durationInHours);
  };

  const invalidateGroupPolls = () => {
    return Promise.all([
      queryClient.invalidateQueries(['group-polls', groupId]),
      queryClient.refetchQueries(['group-polls', groupId]),
    ]);
  };

  const createPollHandler = async () => {
    try {
      const requestBody = {
        question: pollQuestion,
        answers:
          selectedAnswersSection === ANSWERS_SECTIONS.COMMUNITY_ANSWERS
            ? []
            : pollAnswers.map(({ value }) => value),
        durationInHours: parseFloat(pollDurationInHours),
        answersByCommunity:
          selectedAnswersSection === ANSWERS_SECTIONS.COMMUNITY_ANSWERS,
      };
      await createGroupPollRequest(groupId, requestBody);

      await invalidateGroupPolls();

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const pollQuestionChangeHandler = (e) => {
    setPollQuestion(e.target.value);
  };

  const isAddAnotherOptionDisabled = pollAnswers.some(
    (answer) => answer.isValid === false
  );

  const isCreatePollButtonDisabled = () => {
    if (selectedAnswersSection === ANSWERS_SECTIONS.COMMUNITY_ANSWERS) {
      return !pollQuestion || !pollDurationInHoursValid;
    }

    let completedPollAnswers = 0;
    pollAnswers.forEach(({ isValid }) => isValid && completedPollAnswers++);

    if (!pollQuestion || !pollDurationInHoursValid || completedPollAnswers <= 1)
      return true;

    if (pollAnswers.some(({ isValid }) => !isValid)) return true;

    const pollAnswersValues = {};
    let sameAnswerValues = false;
    pollAnswers.forEach(({ value }) => {
      if (pollAnswersValues[value]) {
        sameAnswerValues = true;
      }
      pollAnswersValues[value] = true;
    });

    if (sameAnswerValues) return true;

    return false;
  };

  const pollDurationInHoursInputStyle = {};
  if (pollDurationInHoursTouched && !pollDurationInHoursValid) {
    pollDurationInHoursInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      style={{ minHeight: 590 }}
      closeModal={closeModalHandler}
      title="Create Poll"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={isCreatePollButtonDisabled()}
            onClick={createPollHandler}
            darkpink="true"
          >
            Create Poll
          </Button>
        </div>
      }
    >
      <div className={classes['answers-sections']}>
        {Object.values(ANSWERS_SECTIONS).map((section) => (
          <div
            onClick={() => setSelectedAnswersSection(section)}
            key={section}
            className={classes['section-container']}
          >
            <div
              className={`${classes['section-text']} ${
                section === selectedAnswersSection
                  ? classes['selected-section']
                  : ''
              }`}
            >
              {section}{' '}
              {section === selectedAnswersSection && (
                <div className={classes['selected-border']}>&nbsp;</div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={classes['horizontal-line']}></div>

      <div>
        <TextArea
          value={pollQuestion}
          onChange={pollQuestionChangeHandler}
          style={{ minHeight: 100, width: '100%' }}
          placeholder={`What are you thinking ${profile.firstName} ?`}
        />
      </div>

      {selectedAnswersSection === ANSWERS_SECTIONS.SPECIFIC_ANSWERS && (
        <div className={classes['poll-answers-container']}>
          {pollAnswers.map((answer, i) => {
            const inputStyle = { width: '100%' };

            return (
              <div key={i}>
                <p className={classes['label']}>Option {i + 1}</p>
                <Input
                  style={inputStyle}
                  value={answer.value}
                  placeholder="Enter here"
                  onChange={(e) => updatePollAnswersHandler(i, e.target.value)}
                />
              </div>
            );
          })}

          <Button
            style={{ marginTop: 10, width: '100%' }}
            onClick={addAnotherOptionHandler}
            darkpink="true"
            disabled={isAddAnotherOptionDisabled}
          >
            Add Another Option
          </Button>
        </div>
      )}

      <p className={classes['label']}>Set Poll Duration (In Hours)</p>
      <div className={classes['poll-duration-container']}>
        <div>
          <Input
            value={pollDurationInHours}
            style={pollDurationInHoursInputStyle}
            type="number"
            placeholder="00"
            onChange={durationInHoursChangeHandler}
          />
        </div>
        {POLL_DURATIONS.map((duration) => (
          <div
            key={duration.durationInHours}
            style={{
              background:
                duration.durationInHours === parseFloat(pollDurationInHours) &&
                'rgba(249, 0, 183, .4)',
            }}
            onClick={() =>
              selectDurationInHoursHandler(duration.durationInHours)
            }
            className={classes['duration-container']}
          >
            <p
              style={{
                color:
                  duration.durationInHours ===
                    parseFloat(pollDurationInHours) && '#F900B7',
              }}
              className={classes['duration-value']}
            >
              {duration.value}
            </p>
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default CreateGroupPollModal;
