import React from 'react';
import { SIDEBAR_OPTIONS } from '../../AdminPanel';
import classes from './SidebarAdminPanel.module.css';

function SidebarAdminPanel(props) {
  const { selectedOption, setSelectedOption } = props;

  return (
    <div className={classes['sidebar-container']}>
      <ul>
        {Object.values(SIDEBAR_OPTIONS).map((option) => (
          <div key={option}>
            <li
              onClick={() => setSelectedOption(option)}
              style={{ color: selectedOption === option && '#f92baf' }}
              className={classes['sidebar-item']}
              key={option}
            >
              {option}
            </li>
            <div className="horizontal-line" />
          </div>
        ))}
      </ul>
    </div>
  );
}

export default SidebarAdminPanel;
