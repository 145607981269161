import React, { useState } from 'react';
import classes from './ConfirmDeleteChannelModal.module.css';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import notify from '../../../../../../utils/notify';
import Input from '../../../../../../components/Input/Input';

function ConfirmDeleteChannelModal(props) {
  const { setShowConfirmDeleteChannelModal, deleteChannelHandler } = props;

  const [deletingChannel, setDeletingChannel] = useState(false);
  const [securityText, setSecurityText] = useState('');

  const closeModalHandler = () => {
    setShowConfirmDeleteChannelModal(false);
  };

  const securityTextChangeHandler = (e) => {
    setSecurityText(e.target.value);
  };

  const deleteChannel = async () => {
    setDeletingChannel(true);
    try {
      await deleteChannelHandler();

      setDeletingChannel(false);
      closeModalHandler();
    } catch (err) {
      setDeletingChannel(false);
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Delete Channel"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            disabled={deletingChannel}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={deletingChannel || securityText !== 'Delete'}
            onClick={deleteChannel}
            darkpink="true"
          >
            Delete
          </Button>
        </div>
      }
    >
      <div>
        <p className={classes['text']}>
          Deleting this channel can not be undone.
        </p>
        <p className={classes['text']}>
          If you'd like to continue, please type "Delete" below and click
          Delete.
        </p>

        <Input
          style={{ width: '100%', marginTop: 10 }}
          placeholder="Delete"
          value={securityText}
          onChange={securityTextChangeHandler}
        />
      </div>
    </Modal>
  );
}

export default ConfirmDeleteChannelModal;
