import React from 'react';
import classes from './StripeAccountModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import StripeAccount from '../../../../components/StripeAccount/StripeAccount';

function StripeAccountModal(props) {
  const { setShowStripeAccountModal } = props;

  const closeModalHandler = () => {
    setShowStripeAccountModal(false);
  };

  return (
    <Modal
      style={{ width: 300, height: 400 }}
      closeIcon="true"
      closeModal={closeModalHandler}
      title="Link your Bank Account"
    >
      <div className={classes['stripe-account-modal-container']}>
        <p className={classes['link-your-bank-text']}>
          Glimpse partners with Stripe to allow users to recieve direct payouts
          to their linked bank account! Linking your bank account can be
          completed in under 60 seconds!
        </p>
        <StripeAccount />
      </div>
    </Modal>
  );
}

export default StripeAccountModal;
