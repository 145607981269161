import { useEffect } from 'react';

export const useIntersectionObserver = ({
  hasNextPage,
  fetchNextPage,
  scrollCoefficient = 1.5,
}) => {
  useEffect(() => {
    let fetching = false;
    const scrollHandler = async (event) => {
      const {
        scrollHeight,
        scrollTop,
        clientHeight,
      } = event.target.scrollingElement;

      if (
        !fetching &&
        scrollHeight - scrollTop <= clientHeight * scrollCoefficient
      ) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [hasNextPage, fetchNextPage, scrollCoefficient]);
};
