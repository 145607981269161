import React, { useState, useEffect, useRef, useContext } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { IPFS_URL } from '../../../../constants/IPFS';
import { AuthContext } from '../../../../context/auth-context';
import classes from './NftImage.module.css';
import WhitePlusIcon from '../../../../assets/white-plus.png';
import {
  addNftToWatchlistRequest,
  removeNftFromWatchlistRequest,
} from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import notify from '../../../../utils/notify';
import AddedIcon from '../../../../assets/white-ok.png';

function NftImage(props) {
  const { profile, myWatchlist, getMyWatchlist } = useContext(AuthContext);
  const nftImageContainerRef = useRef();
  const queryClient = useQueryClient();

  const { nft } = props;

  const [isNftImageInHoverState, setIsNftImageInHoverState] = useState(false);

  useEffect(() => {
    const nftImageContainerElement = nftImageContainerRef.current;
    if (!nftImageContainerElement) return;

    const mouseOverHandler = () => {
      setIsNftImageInHoverState(true);
    };

    const mouseOutHandler = () => {
      setIsNftImageInHoverState(false);
    };

    nftImageContainerElement.addEventListener('mouseover', mouseOverHandler);
    nftImageContainerElement.addEventListener('mouseleave', mouseOutHandler);

    return () => {
      nftImageContainerElement.removeEventListener(
        'mouseover',
        mouseOverHandler
      );
      nftImageContainerElement.removeEventListener(
        'mouseleave',
        mouseOutHandler
      );
    };
  }, [nftImageContainerRef]);

  const addNftToWatchlistHandler = async () => {
    try {
      if (!profile?._id)
        return notify(
          'error',
          'You are not logged in. Please log in to perform this action!',
          1500
        );

      await addNftToWatchlistRequest(nft._id);
      await Promise.all([
        queryClient.invalidateQueries(['nft-number-watchlists', nft._id]),
        queryClient.refetchQueries(['nft-number-watchlists', nft._id]),
        getMyWatchlist(),
      ]);
    } catch (error) {
      //   notify('error', error, 2000);
    }
  };

  const removeNftFromWatchlistHandler = async () => {
    try {
      if (!profile?._id)
        return notify(
          'error',
          'You are not logged in. Please log in to perform this action!',
          1500
        );

      await removeNftFromWatchlistRequest(nft._id);
      await Promise.all([
        queryClient.invalidateQueries(['nft-number-watchlists', nft._id]),
        queryClient.refetchQueries(['nft-number-watchlists', nft._id]),
        getMyWatchlist(),
      ]);
    } catch (error) {
      //   notify('error', error, 2000);
    }
  };

  const renderWatchlistIcon = (nftId) => {
    if (!profile) return null;

    const isNftInMyWatchlist = myWatchlist.some((el) => el.nft?._id === nftId);

    if (!isNftInMyWatchlist) {
      return (
        <div
          onClick={() => addNftToWatchlistHandler(nftId)}
          className={classes['add-to-watchlist-container']}
        >
          <img src={WhitePlusIcon} alt="Add" className={classes['plus-icon']} />
        </div>
      );
    } else {
      return (
        <div
          onClick={() => removeNftFromWatchlistHandler(nftId)}
          className={classes['remove-watchlist-container']}
        >
          <img src={AddedIcon} alt="Added" className={classes['plus-icon']} />
        </div>
      );
    }
  };

  const renderNftImage = () => {
    if (nft.type === 'image') {
      return (
        <div
          ref={nftImageContainerRef}
          className={classes['nft-image-container']}
        >
          {isNftImageInHoverState && renderWatchlistIcon(nft._id)}
          <Link to={`/nfts/${nft._id}`}>
            <img
              className={classes['nft-image']}
              src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
              alt="IPFS Hash"
            />
          </Link>
        </div>
      );
    } else {
      return (
        <div
          ref={nftImageContainerRef}
          className={classes['nft-image-container']}
        >
          {isNftImageInHoverState && renderWatchlistIcon(nft._id)}
          <Link to={`/nfts/${nft._id}`}>
            <ReactPlayer
              style={{
                objectFit: 'cover',
                borderRadius: 8,
              }}
              width={60}
              height={60}
              url={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
            />
          </Link>
        </div>
      );
    }
  };

  return renderNftImage();
}

export default NftImage;
