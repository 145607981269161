import React, { useMemo } from 'react';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import classes from './NftCreatedSuccessfully.module.css';
import NftCreatedSuccessfullyImage from '../../assets/nft-created.png';
import Button from '../../components/Button/Button';
import { useHistory, useLocation } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import CopyIcon from '../../assets/copy-1.png';
import notify from '../../utils/notify';

function NftCreatedSuccessfully() {
  const history = useHistory();

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const idParam = queryParams.get('id');

  const copyLinkHandler = async () => {
    await navigator.clipboard.writeText(
      `${window.location.origin}/nfts/${idParam}`
    );

    notify('success', 'Copied!', 1000);
  };

  return (
    <>
      <MetaTags>
        <title>Success | Glimpse</title>
        <meta property="og:title" content={`Success | Glimpse`} />
      </MetaTags>

      <div className={classes['nft-created-successfully-page']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>
        <div className={classes['nft-created-successfully-container']}>
          <div className={classes['success-container']}>
            <img
              alt="Success"
              className={classes['success-image']}
              src={NftCreatedSuccessfullyImage}
            />

            <h3 className={classes['done-text']}>That Was Easy!</h3>
            <p className={classes['success-upload-text']}>
              Congrats, your collectible is listed in the marketplace!
            </p>

            {idParam && `${idParam}`?.length === 24 && (
              <div
                onClick={copyLinkHandler}
                className={classes['copy-link-container']}
              >
                <p className={classes['copy-text']}>Copy Link</p>
                <img
                  src={CopyIcon}
                  alt="Copy"
                  className={classes['copy-icon']}
                />
              </div>
            )}

            <Button
              onClick={() => history.push('/explore')}
              style={{ marginTop: 30, width: 240 }}
              darkpink="true"
            >
              Great
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NftCreatedSuccessfully;
