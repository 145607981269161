import React, { useState, useEffect } from 'react';
import classes from './Photos.module.css';
import PlusIcon from '../../../../assets/pink-plus.png';
import ImageUpload from '../../../../components/ImageUpload/ImageUpload';
import notify from '../../../../utils/notify';
import UploadedPhotoModal from './components/UploadedPhotoModal/UploadedPhotoModal';
import { useQuery, useQueryClient } from 'react-query';
import {
  deletePhotoRequest,
  getMyUploadedPhotosRequest,
  uploadPhotoRequest,
} from '../../../../httpRequests/httpRequests';
import MoreIcon from '../../../../assets/gray-menu-options.png';
import ImageModal from '../../../../components/ImageModal/ImageModal';
import MultipleImageUpload from '../../../../components/MultipleImageUpload/MultipleImageUpload';

function Photos() {
  const queryClient = useQueryClient();
  const { data, error } = useQuery('my-photos', getMyUploadedPhotosRequest);
  const [previewUrl, setPreviewUrl] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [showUploadedPhotoModal, setShowUploadedPhotoModal] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showMoreOptionsPhotoIndex, setShowMoreOptionsPhotoId] = useState();
  const [photoImageModalState, setPhotoImageModalState] = useState({
    opened: false,
    photo: null,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const inputImageHandler = (value) => {
    if (!value) return;
    if (value.size > 10000000)
      return notify(
        'error',
        'You can not upload more than 10MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setImageUrl(value);
    try {
      fileReader.onload = () => {
        setShowUploadedPhotoModal(true);
        setPreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const inputMultipleImagesHandler = async (files) => {
    await Promise.all(
      Array.from(files).map((file) => {
        const formData = new FormData();
        formData.append('photo', file);
        return uploadPhotoRequest(formData);
      })
    );

    queryClient.invalidateQueries('my-photos');
    queryClient.refetchQueries('my-photos');
  };

  const toggleMoreOptions = (id) => {
    setShowMoreOptions((prevState) => !prevState);
    setShowMoreOptionsPhotoId(id);
  };

  const deletePhotoHandler = async (photoId) => {
    setShowMoreOptions(false);
    setShowMoreOptionsPhotoId(undefined);
    await deletePhotoRequest(photoId);
    queryClient.invalidateQueries('my-photos');
    queryClient.refetchQueries('my-photos');
  };

  return (
    <>
      {showUploadedPhotoModal && (
        <UploadedPhotoModal
          setShowUploadedPhotoModal={setShowUploadedPhotoModal}
          setPreviewUrl={setPreviewUrl}
          setImageUrl={setImageUrl}
          imageUrl={imageUrl}
          previewUrl={previewUrl}
        />
      )}

      {photoImageModalState.opened && (
        <ImageModal
          setShowImageModal={() =>
            setPhotoImageModalState({
              opened: false,
              photo: null,
            })
          }
          title="User Photo"
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={photoImageModalState.photo}
            alt="UserPhoto"
          />
        </ImageModal>
      )}
      <div className={classes['photos-page-container']}>
        <div className={classes['single-multiple-photos-container']}>
          <ImageUpload onInput={inputImageHandler}>
            <div className={classes['upload-photo-btn']}>
              <img
                alt="Plus"
                src={PlusIcon}
                width={12}
                className={classes['plus-icon']}
              />
              <p className={classes['upload-photo-text']}>Upload Photo</p>
            </div>
          </ImageUpload>

          <MultipleImageUpload onInput={inputMultipleImagesHandler}>
            <div className={classes['upload-photo-btn']}>
              <img
                alt="Plus"
                src={PlusIcon}
                width={12}
                className={classes['plus-icon']}
              />
              <p className={classes['upload-photo-text']}>
                Upload Multiple Photos
              </p>
            </div>
          </MultipleImageUpload>
        </div>

        <div className={classes['photos-container']}>
          {data && data.results === 0 && (
            <p className={classes['no-photos-found']}>No Photos Found!</p>
          )}

          {data &&
            data.data.map(({ _id, photo }, i) => (
              <div
                style={{ cursor: 'pointer' }}
                key={i}
                className={classes['photo-container']}
              >
                <div
                  onClick={() => toggleMoreOptions(_id)}
                  className={classes['more-icon-container']}
                >
                  <img
                    alt="More"
                    src={MoreIcon}
                    className={classes['more-icon']}
                  />
                </div>
                <div
                  style={{
                    visibility:
                      showMoreOptions && showMoreOptionsPhotoIndex === _id
                        ? 'visible'
                        : 'hidden',
                  }}
                  className={classes['options']}
                >
                  <p
                    onClick={() => deletePhotoHandler(_id)}
                    className={classes['delete-text']}
                  >
                    Delete
                  </p>
                </div>
                <img
                  alt="MyPhoto"
                  onClick={() =>
                    setPhotoImageModalState({ opened: true, photo })
                  }
                  className={classes['photo']}
                  src={photo}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Photos;
