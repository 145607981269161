import React, { useState } from 'react';
import classes from './UpdateBrandItemPriceModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import notify from '../../../../utils/notify';
import { updateBrandItemPriceRequest } from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';

function UpdateBrandItemPriceModal(props) {
  const queryClient = useQueryClient();
  const { setShowUpdateBrandItemPriceModal, brandItemId } = props;

  const [price, setPrice] = useState('');
  const [priceTouched, setPriceTouched] = useState(false);

  const [updatingBrandItemPrice, setUpdatingBrandItemPrice] = useState(false);

  const closeModalHandler = () => {
    setShowUpdateBrandItemPriceModal(false);
  };

  const priceChangeHandler = (e) => {
    setPrice(e.target.value);
    setPriceTouched(true);
  };

  const invalidateBrandItemHandler = () =>
    Promise.all([
      queryClient.invalidateQueries(['brand-item', brandItemId]),
      queryClient.refetchQueries(['brand-item', brandItemId]),
    ]);

  const updateBrandItemPriceHandler = async () => {
    setUpdatingBrandItemPrice(true);

    try {
      const requestBody = {
        price: parseFloat(price),
      };
      await updateBrandItemPriceRequest(brandItemId, requestBody);
      await invalidateBrandItemHandler();
      setUpdatingBrandItemPrice(false);

      closeModalHandler();
    } catch (err) {
      setUpdatingBrandItemPrice(false);
      notify('error', err, 2000);
    }
  };

  const priceInputStyle = {};
  const isPriceInvalid = !price || isNaN(price) || parseFloat(price) < 5;
  if (priceTouched && isPriceInvalid) {
    priceInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Update Price"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>
          <Button
            onClick={updateBrandItemPriceHandler}
            disabled={isPriceInvalid || updatingBrandItemPrice}
            style={{ width: 200 }}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
    >
      <Input
        style={{ width: '100%', ...priceInputStyle }}
        value={price}
        onChange={priceChangeHandler}
        placeHolder="Enter USD amount"
        type="number"
      />
    </Modal>
  );
}

export default UpdateBrandItemPriceModal;
