import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './SearchSidebar.module.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import {
  searchCollectionsNftsGroupsRequest,
  searchUsersRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import SearchInput from '../SearchInput/SearchInput';
import { limitString } from '../../helpers/helpers';
import VerifiedIcon from '../../assets/verified.png';
import GroupPresentationImage from '../../assets/group-presentation-image.png';
import { IPFS_URL } from '../../constants/IPFS';
import ReactPlayer from 'react-player';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

function SearchSidebar() {
  const { profile } = useContext(AuthContext);
  const inputRef = useRef(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [nftCollections, setNftCollections] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const updateFilteredUsersOnInputChange = async () => {
      setTimeout(async () => {
        if (!dataLoaded || inputRef?.current?.value === searchValue) {
          try {
            const [
              { data },
              { nftCollections, groups, nfts },
            ] = await Promise.all([
              searchUsersRequest(searchValue),
              searchCollectionsNftsGroupsRequest(searchValue),
            ]);
            setDataLoaded(true);
            setNftCollections(nftCollections);
            setGroups(groups);
            setNfts(nfts);
            setFilteredUsers(data);
          } catch (error) {
            notify('error', error, 2000);
          }
        }
      }, 300);
    };

    updateFilteredUsersOnInputChange();
  }, [searchValue, dataLoaded]);

  if (!dataLoaded) return <LoadingSpinner />;

  return (
    <div className={classes['search-sidebar-container']}>
      <SearchInput
        ref={inputRef}
        style={{ width: '100%' }}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search here"
      />

      <div className={classes['results-container']}>
        <div className={classes['users-container']}>
          {filteredUsers.length > 0 && (
            <p className={classes['horizontal-text']}>USERS</p>
          )}

          {filteredUsers.map((user, key) => {
            const fullName = `${user.firstName} ${user.lastName}`;
            const { username } = user;
            return (
              <Link
                key={key}
                to={
                  profile?._id === user._id
                    ? '/my-space'
                    : `/user-feed/${user._id}`
                }
              >
                <div className={classes['user-container']}>
                  <img
                    alt={user.firstName}
                    className={classes['user-image']}
                    src={user.photo}
                  />

                  <div>
                    <p className={classes['fullName']}>
                      {limitString(fullName, 14)}
                      {user.verified && (
                        <img
                          src={VerifiedIcon}
                          alt="Verified"
                          className={classes['verified-icon']}
                        />
                      )}
                    </p>
                    <p className={classes['username']}>
                      @{limitString(username, 14)}
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>

        <div className={classes['groups-container']}>
          {groups.length > 0 && (
            <p className={classes['horizontal-text']}>GROUPS</p>
          )}

          {groups.map((group) => {
            return (
              <Link key={group._id} to={`/groups/${group._id}`}>
                <div className={classes['group-container']}>
                  <img
                    alt={group.name}
                    className={classes['group-image']}
                    src={group.coverPhoto || GroupPresentationImage}
                  />

                  <div>
                    <p title={group.name} className={classes['group-name']}>
                      {limitString(group.name, 12)}
                    </p>
                    {group.organizer?.fullName && (
                      <p className={classes['group-organizer']}>
                        {group.organizer.fullName}
                      </p>
                    )}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>

        <div className={classes['nfts-container']}>
          {nfts.length > 0 && (
            <p className={classes['horizontal-text']}>COLLECTIBLES</p>
          )}

          {nfts.map((nft) => {
            return (
              <Link key={nft._id} to={`/nfts/${nft._id}`}>
                <div className={classes['nft-container']}>
                  {nft.type === 'image' ? (
                    <img
                      alt="NFT"
                      className={classes['nft-image']}
                      src={
                        nft.previewUrl
                          ? nft.previewUrl
                          : `${IPFS_URL}/${nft.ipfs}`
                      }
                    />
                  ) : (
                    <ReactPlayer
                      style={{
                        objectFit: 'cover',
                        borderRadius: 10,
                      }}
                      width={40}
                      height={40}
                      url={`${IPFS_URL}/${nft.ipfs}`}
                    />
                  )}
                  <p title={nft.title} className={classes['nft-name']}>
                    {limitString(nft.title, 14)}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>

        <div className={classes['nft-collections-container']}>
          {nftCollections.length > 0 && (
            <p className={classes['horizontal-text']}>COLLECTIONS</p>
          )}
          {nftCollections.map((nftCollection) => {
            return (
              <Link
                key={nftCollection._id}
                to={`/collections/${nftCollection._id}`}
              >
                <div className={classes['nft-collection-container']}>
                  <img
                    alt="NFT Collection"
                    className={classes['nft-collection-image']}
                    src={nftCollection.coverPhoto}
                  />
                  <p
                    title={nftCollection.name}
                    className={classes['nft-collection-name']}
                  >
                    {limitString(nftCollection.name, 14)}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SearchSidebar;
