import React, { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import {
  getAllNftPostsRequest,
  getPayablePostsOfOtherPeopleRequest,
  getPostRequest,
  getPostsOfMyNftCollectorHoldersRequest,
  getPostsOfOtherPeopleRequest,
} from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import classes from './Posts.module.css';
import Post from './components/Post/Post';
import { useIntersectionObserver } from '../../../../../../hooks/useIntersectionObserver';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import NoPostsFoundIcon from '../../../../../../assets/no-activity-found-icon.png';
import NftPost from '../../../../../../components/NftPost/NftPost';
import PayablePost from '../../../../../../components/PayablePost/PayablePost';

const RESULTS_PER_PAGE = 15;
function Posts(props) {
  const {
    selectedTopic,
    setSelectedTopic,
    isAllChecked,
    isCollectorsChecked,
  } = props;
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const postId = queryParams.get('postId');

  const [addedSpecificPostToTheList, setAddedSpecificPostToTheList] = useState(
    false
  );

  const nftPostsResponse = useQuery('nft-posts', getAllNftPostsRequest);

  const payablePostsResponse = useQuery(['payable-posts', isAllChecked], () =>
    getPayablePostsOfOtherPeopleRequest(!isAllChecked)
  );

  const nftPosts = useMemo(() => {
    if (nftPostsResponse.isLoading || !nftPostsResponse.data?.data) return [];

    return nftPostsResponse.data.data;
  }, [nftPostsResponse]);

  const payablePosts = useMemo(() => {
    if (payablePostsResponse.isLoading || !payablePostsResponse.data?.data)
      return [];

    return payablePostsResponse.data.data;
  }, [payablePostsResponse]);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['posts', selectedTopic, isAllChecked, isCollectorsChecked],
    ({ pageParam = 1 }) =>
      isCollectorsChecked
        ? getPostsOfMyNftCollectorHoldersRequest(
            pageParam,
            RESULTS_PER_PAGE,
            selectedTopic
          )
        : getPostsOfOtherPeopleRequest(
            pageParam,
            RESULTS_PER_PAGE,
            selectedTopic,
            !isAllChecked
          ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    const addSpecificPostToTheList = async () => {
      try {
        if (postId && data && !addedSpecificPostToTheList) {
          const { data: newPost } = await getPostRequest(postId);
          queryClient.setQueryData('posts', (data) => {
            return {
              ...data,
              pages: data.pages.map((page) => {
                const updatedData = [];
                page.data.forEach((post) => {
                  if (post._id !== newPost._id) {
                    updatedData.push(post);
                  }
                });

                return {
                  ...page,
                  results: page.results + 1,
                  data: [newPost, ...updatedData],
                };
              }),
            };
          });

          setAddedSpecificPostToTheList(true);
        }
      } catch (error) {
        notify('error', error, 2000);
      }
    };

    addSpecificPostToTheList();
  }, [postId, data, addedSpecificPostToTheList, queryClient]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const allLikes = [];
  if (data?.pages) {
    data.pages.forEach((page) => {
      allLikes.push(...page.myLikeStatusForEachPost);
    });
  }

  return (
    <div className={classes['posts-container']}>
      {data && data.pages && data.pages[0]?.results === 0 && (
        <div className={classes['no-posts-found-container']}>
          <img src={NoPostsFoundIcon} alt="No Posts" />
          <p>No Posts Found</p>
        </div>
      )}

      {data &&
        data.pages.map((page, pageIndex) => {
          return page.data.map((post, i) => {
            const isPostLikedByMe =
              allLikes.find(({ postId }) => {
                return postId === post._id;
              })?.likeStatus !== null;

            const nftPostIndex = pageIndex * RESULTS_PER_PAGE + i;

            if (nftPosts.length > nftPostIndex && !isCollectorsChecked) {
              return (
                <div key={post._id}>
                  <Post
                    selectedTopic={selectedTopic}
                    setSelectedTopic={setSelectedTopic}
                    queryKey={[
                      'posts',
                      selectedTopic,
                      isAllChecked,
                      isCollectorsChecked,
                    ]}
                    isPostLikedByMe={isPostLikedByMe}
                    key={post._id}
                    post={post}
                  />
                  <NftPost nftPost={nftPosts[nftPostIndex]} />

                  {payablePosts.length > nftPostIndex && (
                    <PayablePost payablePost={payablePosts[nftPostIndex]} />
                  )}
                </div>
              );
            }

            return (
              <>
                <Post
                  selectedTopic={selectedTopic}
                  setSelectedTopic={setSelectedTopic}
                  queryKey={[
                    'posts',
                    selectedTopic,
                    isAllChecked,
                    isCollectorsChecked,
                  ]}
                  isPostLikedByMe={isPostLikedByMe}
                  key={post._id}
                  post={post}
                />
                {payablePosts.length > nftPostIndex && !isCollectorsChecked && (
                  <PayablePost payablePost={payablePosts[nftPostIndex]} />
                )}
              </>
            );
          });
        })}
      {(isLoading || isFetching) && <LoadingSpinner />}
    </div>
  );
}

export default Posts;
