import React, { useContext, useState } from 'react';
import classes from './NotListedNftsAccessStatusModal.module.css';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import { AuthContext } from '../../../../context/auth-context';
import notify from '../../../../utils/notify';
import { updateMyNotListedNftsAccessStatusRequest } from '../../../../httpRequests/httpRequests';

const NOT_LISTED_NFTS_ACCESS_STATUS_OPTIONS = [
  'Private',
  'Public',
  'My Followers',
];

function NotListedNftsAccessStatusModal(props) {
  const { profile, getMyProfile } = useContext(AuthContext);
  const { setShowNotListedNFTsAccessStatusModal } = props;

  const [notListedNftsAccessStatus, setNotListedNftsAccessStatus] = useState(
    profile?.notListedNftsAccessStatus || 'Private'
  );

  const closeModalHandler = () => {
    setShowNotListedNFTsAccessStatusModal(false);
  };

  const notListedNftsAccessStatusChangeHandler = (status) => {
    setNotListedNftsAccessStatus(status);
  };

  const updateMyNotListedNftsAccessStatusHandler = async () => {
    try {
      const requestBody = {
        notListedNftsAccessStatus: notListedNftsAccessStatus,
      };
      await updateMyNotListedNftsAccessStatusRequest(requestBody);

      getMyProfile();
      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Cancel
          </Button>
          <Button
            onClick={updateMyNotListedNftsAccessStatusHandler}
            style={{ width: 200 }}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Not Listed NFTs Access Status"
    >
      <div>
        {NOT_LISTED_NFTS_ACCESS_STATUS_OPTIONS.map((status) => (
          <div
            key={status}
            onClick={() => notListedNftsAccessStatusChangeHandler(status)}
            className={classes['status-container']}
          >
            <input
              checked={status === notListedNftsAccessStatus}
              onChange={() => notListedNftsAccessStatusChangeHandler(status)}
              type={'checkbox'}
              className={classes['checkbox']}
            />

            <p className={`${classes['paragraph']}`}>{status}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default NotListedNftsAccessStatusModal;
