import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import {
  getAllCategoriesAndCorrespondingSubCategoriesRequest,
  updateSubCategoryRequest,
} from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import classes from './CategoriesAndSubCategoriesTable.module.css';
import { Row, Col } from 'antd';
import EditCategoryAndSubCategoryModal from '../EditCategoryAndSubCategoryModal/EditCategoryAndSubCategoryModal';

function CategoriesAndSubCategoriesTable() {
  const queryClient = useQueryClient();
  const { isLoading, data, error } = useQuery(
    'categories-and-corresponding-subCategories',
    getAllCategoriesAndCorrespondingSubCategoriesRequest
  );
  const [selectedCategoryToEdit, setSelectedCategoryToEdit] = useState();
  const [selectedSubCategoryToEdit, setSelectedSubCategoryToEdit] = useState();
  const [
    showEditCategoryAndSubCategoryModal,
    setShowEditCategoryAndSubCategoryModal,
  ] = useState(false);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateCategoriesAndSubCategoriesQuery = () => {
    queryClient.invalidateQueries('categories-and-corresponding-subCategories');
    queryClient.refetchQueries('categories-and-corresponding-subCategories');
  };

  const deActivateSubCategoryHandler = async (category, subCategory) => {
    try {
      const requestBody = {
        active: false,
      };

      await updateSubCategoryRequest(
        category._id,
        subCategory._id,
        requestBody
      );
      notify('error', `Deactivating Sub Category ${subCategory.name}`, 2000);
      invalidateCategoriesAndSubCategoriesQuery(subCategory);
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const activateSubCategoryHandler = async (category, subCategory) => {
    try {
      const requestBody = {
        active: true,
      };
      await updateSubCategoryRequest(
        category._id,
        subCategory._id,
        requestBody
      );
      notify('success', `Activating Sub Category ${subCategory.name}`, 1500);
      invalidateCategoriesAndSubCategoriesQuery(subCategory);
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const renderActivateOrDeactivateButton = (category, subCategory) => {
    if (subCategory.active)
      return (
        <button
          onClick={() => deActivateSubCategoryHandler(category, subCategory)}
          className="cancel-button"
        >
          Deactivate
        </button>
      );
    else
      return (
        <button
          onClick={() => activateSubCategoryHandler(category, subCategory)}
          className="success-button"
        >
          Activate
        </button>
      );
  };

  const openEditCategoryAndSubCategoryModalHandler = (
    category,
    subCategory
  ) => {
    setSelectedCategoryToEdit(category);
    setSelectedSubCategoryToEdit(subCategory);
    setShowEditCategoryAndSubCategoryModal(true);
  };

  const renderCategoriesAndCorrespondingSubCategories = (
    categoriesAndCorrespondingSubCategories
  ) => {
    const allSubCategoriesForEachCategory = [];
    for (const category of categoriesAndCorrespondingSubCategories) {
      const subCategories = category.subCategories;

      allSubCategoriesForEachCategory.push({ subCategories, category });
    }

    return allSubCategoriesForEachCategory.map(
      ({ category, subCategories }) => {
        return subCategories.map((subCategory) => (
          <div key={subCategory.name}>
            <hr />
            <Row justify="space-between" style={{ padding: '1em 0' }}>
              <Col md={3} className={classes['pagination-col']}>
                {category.name}
              </Col>
              <Col md={3} className={classes['pagination-col']}>
                {subCategory.name}
              </Col>
              <Col md={3} className={classes['pagination-col']}>
                <button
                  onClick={() =>
                    openEditCategoryAndSubCategoryModalHandler(
                      category,
                      subCategory
                    )
                  }
                  className="btn-white-glimpse"
                >
                  Edit
                </button>
              </Col>
              <Col md={3} className={classes['pagination-col']}>
                {renderActivateOrDeactivateButton(category, subCategory)}
              </Col>
            </Row>
          </div>
        ));
      }
    );
  };

  return (
    <>
      {showEditCategoryAndSubCategoryModal && (
        <EditCategoryAndSubCategoryModal
          invalidateCategoriesAndSubCategoriesQuery={
            invalidateCategoriesAndSubCategoriesQuery
          }
          selectedCategoryToEdit={selectedCategoryToEdit}
          selectedSubCategoryToEdit={selectedSubCategoryToEdit}
          setShowEditCategoryAndSubCategoryModal={
            setShowEditCategoryAndSubCategoryModal
          }
        />
      )}
      <div style={{ flex: 1 }}>
        <div className={classes['all-events-container']}>
          <div className={classes['heading-container']}>
            <h2 className={classes['all-events-heading']}>
              ALL Categories & SubCategories{' '}
            </h2>
          </div>
          <div
            style={{ minHeight: '85vh', width: '100%' }}
            id="table-nft"
            className={classes['likes-container']}
          >
            <Row justify="space-between">
              <Col md={3} className={classes['pagination-col']}>
                Category
              </Col>
              <Col md={3} className={classes['pagination-col']}>
                SubCategory
              </Col>
              <Col md={3} className={classes['pagination-col']}>
                Edit
              </Col>
              <Col md={3} className={classes['pagination-col']}>
                Activate / Deactivate
              </Col>
            </Row>
            {!isLoading &&
              data &&
              renderCategoriesAndCorrespondingSubCategories(data.data)}
            {isLoading && <LoadingSpinner />}
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoriesAndSubCategoriesTable;
