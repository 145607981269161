import React, { useEffect, useState } from 'react';
import classes from './UserGroups.module.css';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { limitString } from '../../../../helpers/helpers';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import {
  getGroupsForASpecificUserRequest,
  requestGroupMembershipRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import CommunitiesIcon from '../../../../assets/white-communities-icon.png';

const RESULTS_PER_PAGE = 12;

function UserGroups() {
  const queryClient = useQueryClient();
  const { userId } = useParams();
  const [searchValue] = useState('');
  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['user-groups', userId, searchValue],
    ({ pageParam = 1 }) =>
      getGroupsForASpecificUserRequest(
        userId,
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const requestGroupMembershipHandler = async (groupId) => {
    try {
      await requestGroupMembershipRequest(groupId);

      queryClient.invalidateQueries(['user-groups', userId, searchValue]);
      queryClient.refetchQueries(['user-groups', userId, searchValue]);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderButtonAccordingToMyStatus = (group, myGroupMembershipStatus) => {
    const groupStatus = group.status;

    if (groupStatus === 'public') {
      return (
        <Link
          style={{ width: '100%', textAlign: 'center' }}
          to={`/groups/${group._id}`}
        >
          <Button
            style={{
              background: '#E9EBEB',
              color: '#EF00D7',
            }}
            modernpinkcolor="true"
          >
            Visit
          </Button>
        </Link>
      );
    } else {
      if (!myGroupMembershipStatus) {
        return (
          <Button
            style={{
              background: '#E9EBEB',
              color: '#EF00D7',
            }}
            onClick={() => requestGroupMembershipHandler(group._id)}
            modernpink="true"
          >
            Request
          </Button>
        );
      }
      if (myGroupMembershipStatus === 'REQUESTED') {
        return (
          <Button style={{}} modernpinkcolor="true" disabled>
            Requested
          </Button>
        );
      }
      if (myGroupMembershipStatus === 'ACCEPTED') {
        return (
          <Link
            to={`/groups/${group._id}`}
            style={{ width: '100%', textAlign: 'center' }}
          >
            <Button
              style={{
                background: '#E9EBEB',
                color: '#EF00D7',
              }}
              modernpinkcolor="true"
            >
              Visit
            </Button>
          </Link>
        );
      }

      if (myGroupMembershipStatus === 'REJECTED') {
        return (
          <Button style={{}} modernpinkcolor="true" disabled>
            Rejected
          </Button>
        );
      }
    }
  };

  return (
    <div className={classes['my-groups-page']}>
      <div className={classes['my-groups-list']}>
        {data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map(
              ({ numberOfMembers, group, myGroupMembershipStatus }) => {
                return (
                  <div
                    key={group._id}
                    className={`${
                      group.status === 'private'
                        ? classes['private-suggested-group']
                        : classes['suggested-group']
                    }`}
                    style={{ background: group.coverPhoto && 'transparent' }}
                  >
                    {group.coverPhoto && (
                      <img
                        src={group.coverPhoto}
                        alt="Cover"
                        className={classes['cover-photo']}
                      />
                    )}
                    {/* <div className={classes['name-members-status-container']}>
                      <h3 className={classes['group-name']}>
                        {limitString(group.name, 20)}
                      </h3>
                      <div
                        className={classes['members-group-status-container']}
                      >
                        <p className={classes['number-of-members']}>
                          {numberOfMembers}{' '}
                          {numberOfMembers === 1 ? 'Member' : 'Members'}
                        </p>

                        <div className={classes['circle']}>&nbsp;</div>

                        {group.status === 'private' ? (
                          <p className={classes['status-text']}>Private</p>
                        ) : (
                          <p className={classes['status-text']}>Public </p>
                        )}
                      </div>
                    </div> */}
                    <div className={classes['group-info-container']}>
                      <div className={classes['communities-icon-container']}>
                        <img
                          className={classes['communities-icon']}
                          src={CommunitiesIcon}
                          alt="Communities"
                        />
                      </div>

                      <div className={classes['group-name-status-container']}>
                        <h3 className={classes['group-name']}>
                          {limitString(group.name, 14)}
                        </h3>

                        {group.status === 'private' ? (
                          <p className={classes['status-text']}>PRIVATE</p>
                        ) : (
                          <p className={classes['status-text']}>PUBLIC </p>
                        )}
                      </div>
                    </div>

                    <div className={classes['members-container']}>
                      <h3 className={classes['members-text']}>MEMBERS</h3>
                      <p className={classes['members-value']}>
                        {numberOfMembers}
                      </p>
                    </div>

                    <div className={classes['join-request-container']}>
                      {renderButtonAccordingToMyStatus(
                        group,
                        myGroupMembershipStatus
                      )}
                    </div>
                  </div>
                );
              }
            );
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default UserGroups;
