import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import classes from './UserFeed.module.css';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import {
  getUserFollowingsAndFollowersRequest,
  getUserProfileRequest,
  getUsersNumberOfFollowersAndFollowingsRequest,
  requestFollowingRequest,
  unfollowUserRequest,
  blockUserRequest,
  getUserBlockStatusWithASpecificUserRequest,
  unBlockUserRequest,
} from '../../httpRequests/httpRequests';
import Friends from './components/Friends/Friends';
import Posts from './components/Posts/Posts';
import Purchases from './components/Purchases/Purchases';
import Listings from './components/Listings/Listings';
import { AuthContext } from '../../context/auth-context';
import notify from '../../utils/notify';
import FollowingsModal from '../../components/FollowingsModal/FollowingsModal';
import FollowersModal from '../../components/FollowersModal/FollowersModal';
import FollowIcon from '../../assets/follow-icon-2.png';
import FollowingIcon from '../../assets/following-icon-2.png';
import Photos from './components/Photos/Photos';
import VerifiedIcon from '../../assets/verified.png';
import AlbumPhotos from './components/AlbumPhotos/Photos';
import ReportUserModal from '../../components/ReportUserModal/ReportUserModal';
import ImageModal from '../../components/ImageModal/ImageModal';
import MetaTags from 'react-meta-tags';
import MetaMaskNfts from './components/MetaMaskNfts/MetaMaskNfts';
import UserNftCollections from './components/UserNftCollections/UserNftCollections';
import UserGroups from './components/UserGroups/UserGroups';
import PrivatePurchases from './components/PrivatePurchases/PrivatePurchases';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import MessagesSidebar from '../../components/MessagesSidebar/MessagesSidebar';
import SendMessageIcon from '../../assets/send-message-icon.png';
import PinkMenuOptionsIcon from '../../assets/pink-menu-options.png';
import BrightDownIcon from '../../assets/bright-down-icon.png';
import DarkDownIcon from '../../assets/dark-down-icon.png';

const FOLLOWER_STATUSES = {
  REQUESTED: 'REQUESTED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

function UserFeed() {
  const moreIconRef = useRef();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { userId } = useParams();
  const { profile, getMyFollowings } = useContext(AuthContext);

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showReportUserModal, setShowReportUserModal] = useState(false);
  const [showCoverPhotoImageModal, setShowCoverPhotoImageModal] = useState(
    false
  );
  const [showUserProfilePhoto, setShowUserProfilePhoto] = useState(false);

  const userFollowingsAndFollowersResponse = useQuery(
    ['followers-followings', userId],
    () => getUserFollowingsAndFollowersRequest(userId)
  );

  const userBlockStatusResponse = useQuery(['user-block-status', userId], () =>
    getUserBlockStatusWithASpecificUserRequest(profile?._id, userId)
  );

  const userProfileResponse = useQuery(['users', userId], () =>
    getUserProfileRequest(userId)
  );
  const numberOfFollowersAndFollowingsRespone = useQuery(
    ['number-of-followers-followings', userId],
    () => getUsersNumberOfFollowersAndFollowingsRequest(userId)
  );

  useEffect(() => {
    const checkForClosingMoreOptions = (e) => {
      if (e.target !== moreIconRef.current) {
        setShowMoreOptions(false);
      }
    };

    window.addEventListener('click', checkForClosingMoreOptions);

    return () =>
      window.removeEventListener('click', checkForClosingMoreOptions);
  }, [moreIconRef]);

  const [selectedSection, setSelectedSection] = useState('Posts');
  const [showFollowingsModal, setShowFollowingsModal] = useState(false);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [
    showListingsPurchasesAndCollectionsOptions,
    setShowListingsPurchasesAndCollectionsOptions,
  ] = useState(false);

  const getFollowingsAndFollowersWithStatusAccepted = useCallback(() => {
    if (!userFollowingsAndFollowersResponse.data)
      return {
        followingsWithStatusAccepted: [],
        followersWithStatusAccepted: [],
        myFollowerStatusWithCurrentUser: [],
      };

    const userFollowingsAndFollowers = userFollowingsAndFollowersResponse.data;

    const followersWithStatusAccepted = [];
    let myFollowerStatusWithCurrentUser = undefined;

    for (let i = 0; i < userFollowingsAndFollowers.followers.length; i++) {
      const follower = userFollowingsAndFollowers.followers[i].follower;
      const status = userFollowingsAndFollowers.followers[i].status;

      if (status === FOLLOWER_STATUSES.ACCEPTED) {
        followersWithStatusAccepted.push(follower);
      }

      if (follower._id === profile?._id) {
        myFollowerStatusWithCurrentUser = status;
      }
    }

    return {
      followingsWithStatusAccepted: userFollowingsAndFollowers.followings.filter(
        (el) => el.status === FOLLOWER_STATUSES.ACCEPTED
      ),
      followersWithStatusAccepted,
      myFollowerStatusWithCurrentUser,
    };
  }, [userFollowingsAndFollowersResponse, profile]);

  const {
    followingsWithStatusAccepted,
    followersWithStatusAccepted,
    myFollowerStatusWithCurrentUser,
  } = useMemo(() => getFollowingsAndFollowersWithStatusAccepted(), [
    getFollowingsAndFollowersWithStatusAccepted,
  ]);

  const requestFollowHandler = useCallback(async () => {
    try {
      await requestFollowingRequest(userId);
      await getMyFollowings();
      queryClient.invalidateQueries(['followers-followings', userId]);
      queryClient.refetchQueries(['followers-followings', userId]);
      queryClient.invalidateQueries(['number-of-followers-followings', userId]);
      queryClient.refetchQueries(['number-of-followers-followings', userId]);
    } catch (error) {
      notify('error', error, 2000);
    }
  }, [userId, queryClient, getMyFollowings]);

  const unfollowUserHandler = useCallback(async () => {
    try {
      await unfollowUserRequest(userId);
      await getMyFollowings();
      queryClient.invalidateQueries(['followers-followings', userId]);
      queryClient.refetchQueries(['followers-followings', userId]);
      queryClient.invalidateQueries(['number-of-followers-followings', userId]);
      queryClient.refetchQueries(['number-of-followers-followings', userId]);
    } catch (error) {
      notify('error', error, 2000);
    }
  }, [userId, queryClient, getMyFollowings]);

  const renderFollowState = () => {
    if (userBlockStatusResponse.data?.data) {
      return (
        <div
          onClick={unBlockUserHandler}
          className={classes['unblock-user-container']}
        >
          Unblock User
        </div>
      );
    }

    let followState = {
      btnText: 'Follow',
      actionHandler: requestFollowHandler,
    };
    if (myFollowerStatusWithCurrentUser === undefined) {
      followState = {
        btnText: 'Follow',
        actionHandler: requestFollowHandler,
      };
    } else if (myFollowerStatusWithCurrentUser === FOLLOWER_STATUSES.ACCEPTED) {
      followState = {
        btnText: 'Following',
        actionHandler: unfollowUserHandler,
      };
    } else if (myFollowerStatusWithCurrentUser === FOLLOWER_STATUSES.REJECTED) {
      followState = {
        btnText: 'Follow',
        actionHandler: requestFollowHandler,
      };
    } else if (
      myFollowerStatusWithCurrentUser === FOLLOWER_STATUSES.REQUESTED
    ) {
      followState = {
        btnText: 'Pending',
        actionHandler: () => {},
      };
    }

    return (
      <div
        onClick={followState.actionHandler}
        className={
          followState.btnText === 'Following'
            ? classes['following-btn']
            : classes['follow-btn']
        }
      >
        <img
          alt="Follow"
          className={classes['follow-icon']}
          src={followState.btnText === 'Following' ? FollowIcon : FollowingIcon}
        />
      </div>
    );
  };

  const doIFollowCurrentUser = useMemo(
    () =>
      userFollowingsAndFollowersResponse.data?.followers.some(
        ({ follower }) => follower?._id === profile?._id
      ),
    [userFollowingsAndFollowersResponse, profile]
  );

  const doCurrentUserFollowMe = useMemo(
    () =>
      userFollowingsAndFollowersResponse.data?.followings.some(
        ({ following }) => following?._id === profile?._id
      ),
    [userFollowingsAndFollowersResponse, profile]
  );

  const directMessageHandler = () => {
    if (doIFollowCurrentUser && doCurrentUserFollowMe) {
      history.push(`/chats?user=${userId}`);
    } else {
      history.push(`/chats?user=${userId}&request=1`);
    }
  };

  const invalidateUserBlockStatus = () => {
    queryClient.invalidateQueries(['user-block-status', userId]);
    queryClient.refetchQueries(['user-block-status', userId]);
  };

  const blockUserHandler = async () => {
    try {
      await blockUserRequest(userId);
      [
        ['followers-followings', userId],
        ['number-of-followers-followings', userId],
      ].forEach((queryKey) => {
        queryClient.invalidateQueries(queryKey);
        queryClient.refetchQueries(queryKey);
      });
      invalidateUserBlockStatus();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const unBlockUserHandler = async () => {
    try {
      await unBlockUserRequest(userId);
      invalidateUserBlockStatus();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const openFollowersModalHandler = () => {
    setShowFollowersModal(true);
  };

  const openFollowingsModalHandler = () => {
    setShowFollowingsModal(true);
  };

  const toggleMoreOptionsHandler = () => {
    setShowMoreOptions((prevState) => !prevState);
  };

  const openReportUserModalHandler = () => {
    setShowReportUserModal(true);
  };

  const doIHaveAccessToPurchasesSection = () => {
    const userNotListedNftsAccessStatus =
      userProfileResponse.data.data.notListedNftsAccessStatus;

    if (userNotListedNftsAccessStatus === 'Private') {
      return false;
    } else if (userNotListedNftsAccessStatus === 'Public') {
      return true;
    } else if (userNotListedNftsAccessStatus === 'My Followers') {
      return doIFollowCurrentUser;
    } else {
      return true;
    }
  };

  const renderListingsPurchasesAndCollectionsHandler = () => {
    const isContainerSelected = [
      'Listings',
      'Not Listed',
      'Collections',
    ].includes(selectedSection);

    return (
      <div className={classes['listings-purchases-collections-container']}>
        <div
          onClick={() =>
            setShowListingsPurchasesAndCollectionsOptions(
              (prevState) => !prevState
            )
          }
          className={classes['selected-section-down-icon-container']}
        >
          <p
            className={
              isContainerSelected
                ? classes['selected-container-text']
                : undefined
            }
          >
            {isContainerSelected ? selectedSection : 'Collectibles'}
          </p>
          <img
            alt="See"
            src={isContainerSelected ? DarkDownIcon : BrightDownIcon}
          />
        </div>

        {showListingsPurchasesAndCollectionsOptions && (
          <div
            className={
              classes['listings-purchases-collections-options-container']
            }
          >
            {['Listings', 'Not Listed', 'Collections']
              .filter((el) => el !== selectedSection)
              .map((el) => (
                <p
                  style={{ margin: 0, padding: '5px' }}
                  onClick={() => {
                    setSelectedSection(el);
                    setShowListingsPurchasesAndCollectionsOptions(
                      (prevState) => !prevState
                    );
                  }}
                >
                  {el}
                </p>
              ))}
          </div>
        )}
      </div>
    );
  };

  let USER_SECTIONS = [
    'Posts',
    'Photos',
    // 'Albums',
    // 'Collections',
    'Communities',
    // 'Not Listed',
    // 'Listings',
    'Friends',
    // 'MetaMask NFTs',
  ];

  const isUserBlockedByMe =
    !userBlockStatusResponse.isLoading &&
    userBlockStatusResponse.data?.data !== null;

  if (userId === profile?._id) {
    return <Redirect to="/my-space" />;
  }

  return (
    <>
      {userProfileResponse.data?.data && (
        <MetaTags>
          <title>{userProfileResponse.data?.data?.fullName} | Glimpse</title>
          <meta
            property="og:title"
            content={`${userProfileResponse.data?.data?.fullName} | Glimpse`}
          />
          <meta
            property="og:image"
            content={userProfileResponse.data?.data?.photo}
          />
        </MetaTags>
      )}

      {showFollowingsModal && (
        <FollowingsModal
          setShowFollowingsModal={setShowFollowingsModal}
          followings={followingsWithStatusAccepted}
        />
      )}

      {showFollowersModal && (
        <FollowersModal
          setShowFollowersModal={setShowFollowersModal}
          followers={followersWithStatusAccepted}
        />
      )}

      {showReportUserModal && userProfileResponse.data?.data && (
        <ReportUserModal
          user={userProfileResponse.data.data}
          userId={userId}
          setShowReportUserModal={setShowReportUserModal}
        />
      )}

      {showCoverPhotoImageModal && (
        <ImageModal
          setShowImageModal={setShowCoverPhotoImageModal}
          title="Cover Photo"
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={userProfileResponse.data?.data.coverPhoto}
            alt="Cover"
          />
        </ImageModal>
      )}

      {showUserProfilePhoto && (
        <ImageModal setShowImageModal={setShowUserProfilePhoto}>
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={userProfileResponse.data.data.photo}
            alt="Profile"
          />
        </ImageModal>
      )}

      <div className={classes['user-feed-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        {userProfileResponse.data?.data && (
          <div className={classes['user-feed-content-container']}>
            {userProfileResponse.data?.data.coverPhoto ? (
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setShowCoverPhotoImageModal(true)}
                alt="CoverPhoto"
                className={classes['cover-photo']}
                src={userProfileResponse.data.data.coverPhoto}
              />
            ) : (
              <div className={classes['no-cover-photo-container']}></div>
            )}

            <div className={classes['user-information']}>
              <div className={classes['user-image-fullName-container']}>
                <img
                  onClick={() => setShowUserProfilePhoto(true)}
                  style={{ cursor: 'pointer' }}
                  alt="UserPhoto"
                  src={userProfileResponse.data.data.photo}
                  className={classes['user-image']}
                />

                <div>
                  <h3 className={classes['user-fullName']}>
                    {userProfileResponse.data.data.fullName}
                    {userProfileResponse.data.data.verified && (
                      <img
                        alt="Verified"
                        src={VerifiedIcon}
                        style={{ marginLeft: 8, height: 16 }}
                      />
                    )}
                  </h3>
                  {
                    <p className={classes['username']}>
                      @{userProfileResponse.data.data.username}
                    </p>
                  }
                </div>
              </div>

              <div className={classes['follow-message-container']}>
                {userBlockStatusResponse.data?.data !== undefined &&
                  renderFollowState()}

                {userBlockStatusResponse.isLoading === false &&
                  !isUserBlockedByMe && (
                    <>
                      <div
                        onClick={directMessageHandler}
                        className={classes['message-container']}
                      >
                        <div className={classes['message-icon-container']}>
                          <img
                            alt="Message"
                            className={classes['message-icon']}
                            src={SendMessageIcon}
                            width={15}
                            height={15}
                          />
                        </div>
                      </div>
                      <Link to={`/request-nft/${userId}`}>
                        <div className={classes['request-nft-container']}>
                          <p>Request</p>
                        </div>
                      </Link>
                    </>
                  )}

                <div style={{ position: 'relative' }}>
                  {showMoreOptions && (
                    <div className={classes['more-options']}>
                      <p
                        onClick={openReportUserModalHandler}
                        className={classes['option']}
                      >
                        Report User
                      </p>

                      {userBlockStatusResponse.data?.data !== undefined &&
                      userBlockStatusResponse.data?.data === null ? (
                        <p
                          onClick={blockUserHandler}
                          className={classes['option']}
                        >
                          Block User
                        </p>
                      ) : (
                        <p
                          onClick={unBlockUserHandler}
                          className={classes['option']}
                        >
                          Unblock User
                        </p>
                      )}
                    </div>
                  )}
                  <div className={classes['menu-options-container']}>
                    <img
                      ref={moreIconRef}
                      alt="More"
                      onClick={toggleMoreOptionsHandler}
                      className={classes['more-icon']}
                      src={PinkMenuOptionsIcon}
                    />
                  </div>
                </div>
              </div>
            </div>

            {userBlockStatusResponse.isLoading === false && !isUserBlockedByMe && (
              <>
                <div className={classes['user-sections-container']}>
                  {USER_SECTIONS.map((section) => {
                    return (
                      <div
                        onClick={() => setSelectedSection(section)}
                        key={section}
                        className={classes['section-container']}
                      >
                        <div
                          className={`${classes['section-text']} ${
                            section === selectedSection
                              ? classes['selected-section']
                              : ''
                          }`}
                        >
                          {section}

                          {section === selectedSection && (
                            <div className={classes['selected-border']}>
                              &nbsp;
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  {renderListingsPurchasesAndCollectionsHandler()}
                </div>

                <div className={classes['horizontal-line']}></div>

                {selectedSection === 'Posts' && (
                  <Posts
                    isMyFriend={doIFollowCurrentUser && doCurrentUserFollowMe}
                    userProfile={userProfileResponse.data.data}
                    openFollowersModalHandler={openFollowersModalHandler}
                    numberOfFollowers={
                      numberOfFollowersAndFollowingsRespone?.data
                        ?.numberOfFollowers
                    }
                    openFollowingsModalHandler={openFollowingsModalHandler}
                    numberOfFollowings={
                      numberOfFollowersAndFollowingsRespone?.data
                        ?.numberOfFollowings
                    }
                    setSelectedSection={setSelectedSection}
                  />
                )}

                {selectedSection === 'Photos' && (
                  <Photos userId={userProfileResponse.data.data._id} />
                )}
                {selectedSection === 'Albums' && <AlbumPhotos />}
                {selectedSection === 'Collections' && <UserNftCollections />}
                {selectedSection === 'Communities' && <UserGroups />}
                {selectedSection === 'Not Listed' &&
                  doIHaveAccessToPurchasesSection() && <Purchases />}
                {selectedSection === 'Not Listed' &&
                  !doIHaveAccessToPurchasesSection() && <PrivatePurchases />}
                {selectedSection === 'Listings' && <Listings />}
                {selectedSection === 'Friends' && <Friends />}
                {selectedSection === 'MetaMask NFTs' && (
                  <MetaMaskNfts userProfile={userProfileResponse.data.data} />
                )}
              </>
            )}
          </div>
        )}

        <div className={classes['messages-sidebar-container']}>
          <MessagesSidebar />
        </div>
      </div>
    </>
  );
}

export default UserFeed;
