import React, { useEffect, useMemo, useState } from 'react';
import classes from './RequestedServices.module.css';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import GoBackIcon from '../../assets/go-back-icon-2.png';
import { useHistory, useLocation } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import RequestedServicesForMe from './components/RequestedServicesForMe/RequestedServicesForMe';
import RequestedServicesFromMe from './components/RequestedServicesFromMe/RequestedServicesFromMe';

const REQUESTED_SERVICES_SECTIONS = ['Received', 'Sent'];

function RequestedServices() {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const tabParam = queryParams.get('tab');

  const [selectedSection, setSelectedSection] = useState('Received');

  useEffect(() => {
    if (REQUESTED_SERVICES_SECTIONS.includes(tabParam)) {
      setSelectedSection(tabParam);
    }
  }, [tabParam]);

  return (
    <>
      <MetaTags>
        <title>Requested Services | Glimpse</title>
        <meta property="og:title" content={`Requested Services | Glimpse`} />
      </MetaTags>

      <div className={classes['requested-services-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['requested-services-container']}>
          <header className={classes['requested-services-header']}>
            <div className={classes['go-back-container']}>
              <div onClick={() => history.goBack()}>
                <img
                  src={GoBackIcon}
                  alt="Go Back"
                  className={classes['go-back-icon']}
                />
              </div>
              <h3>Requested Services</h3>
            </div>

            <div className={classes['requested-services-options-container']}>
              {REQUESTED_SERVICES_SECTIONS.map((option) => (
                <div
                  key={option}
                  onClick={() => setSelectedSection(option)}
                  className={classes['requested-services-option-container']}
                >
                  <p
                    className={`${classes['requested-services-option-text']} ${
                      option === selectedSection &&
                      classes['requested-services-option-text-selected']
                    }`}
                  >
                    {option}
                  </p>
                </div>
              ))}
            </div>
          </header>

          {selectedSection === 'Received' && <RequestedServicesForMe />}
          {selectedSection === 'Sent' && <RequestedServicesFromMe />}
        </div>
      </div>
    </>
  );
}

export default RequestedServices;
