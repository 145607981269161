import React, { useEffect, useState } from 'react';
import classes from './BuyPointsModal.module.css';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import notify from '../../utils/notify';
import { createBuyPointsStripeCheckoutSessionRequest } from '../../httpRequests/httpRequests';
import { getFormattedNumber } from '../../helpers/helpers';
import DiamondIcon from '../../assets/pink-diamond-icon.png';

const POINTS_OPTIONS = {
  1000: 10,
  5000: 50,
  10000: 100,
  25000: 250,
};

function BuyPointsModal(props) {
  const { setShowBuyPointsModal } = props;

  const [selectedPointsValue, setSelectedPointsValue] = useState('');
  const [creatingSession, setCreatingSession] = useState(false);

  useEffect(() => {
    const keyDownEventHandler = (e) => {
      const key = e.key;

      if (key === 'Backspace') {
        setSelectedPointsValue((prevState) => {
          return prevState.substring(0, prevState.length - 1);
        });
      } else if (!isNaN(key)) {
        setSelectedPointsValue((prevState) => {
          const updatedState = prevState + key;
          if (parseFloat(updatedState) > 100000) return '100000';
          return updatedState;
        });
      }
    };

    window.addEventListener('keydown', keyDownEventHandler);

    return () => window.removeEventListener('keydown', keyDownEventHandler);
  }, []);

  const closeModalHandler = () => {
    setShowBuyPointsModal(false);
  };

  const selectPointsOptionHandler = (pointOption) => {
    setSelectedPointsValue(pointOption);
  };

  const buyPointsHandler = async () => {
    setCreatingSession(true);
    try {
      const requestBody = {
        pointsAmount: parseFloat(selectedPointsValue),
      };
      const { sessionUrl } = await createBuyPointsStripeCheckoutSessionRequest(
        requestBody
      );

      setCreatingSession(false);
      closeModalHandler();
      window.open(sessionUrl, '_blank');
    } catch (err) {
      setCreatingSession(false);
      notify('error', err, 2000);
    }
  };

  const renderPointsValue = () => {
    if (
      !selectedPointsValue ||
      isNaN(selectedPointsValue) ||
      isNaN(parseFloat(selectedPointsValue))
    )
      return '0.00';

    return getFormattedNumber(parseFloat(selectedPointsValue));
  };

  const renderUsdValue = () => {
    if (
      !selectedPointsValue ||
      isNaN(selectedPointsValue) ||
      isNaN(parseFloat(selectedPointsValue))
    )
      return '0.00';

    return (parseFloat(selectedPointsValue) * 0.01).toFixed(2);
  };

  const isBuyPointsButtonDisabled = () => {
    if (creatingSession) return true;

    if (isNaN(parseFloat(selectedPointsValue))) return true;

    if (!selectedPointsValue || isNaN(selectedPointsValue)) return true;

    const parsedAmount = parseFloat(selectedPointsValue);
    if (parsedAmount < 1000) return true;

    return false;
  };

  return (
    <Modal closeIcon="true" closeModal={closeModalHandler} title="Buy Diamonds">
      <div className={classes['buy-points-container']}>
        <div className={classes['usd-points-info-container']}>
          <h2 className={classes['usd-value']}>$ {renderUsdValue()}</h2>
          <p className={classes['you-will-get']}>You will get</p>
          <h2 className={classes['points-text']}>
            <img
              src={DiamondIcon}
              alt="Diamond"
              className={classes['big-diamond-icon']}
            />
            {renderPointsValue()}
          </h2>
        </div>

        <div className={classes['points-options']}>
          {Object.keys(POINTS_OPTIONS).map((pointOption, i) => {
            return (
              <div
                onClick={() => selectPointsOptionHandler(pointOption)}
                key={pointOption}
                className={`${classes['box-option']} ${
                  pointOption === selectedPointsValue &&
                  classes['selected-box-option']
                } `}
              >
                {i === 1 && (
                  <div className={classes['most-popular-container']}>
                    <p>Most Popular</p>
                  </div>
                )}
                <p className={classes['points-value']}>
                  <img
                    src={DiamondIcon}
                    alt="Diamond"
                    className={classes['diamond-icon']}
                  />
                  {parseFloat(pointOption)}{' '}
                </p>
                <p className={classes['usd-option-value']}>
                  ${POINTS_OPTIONS[pointOption]}
                </p>
              </div>
            );
          })}
        </div>

        <Button
          onClick={buyPointsHandler}
          style={{ width: '100%' }}
          darkpink="true"
          disabled={isBuyPointsButtonDisabled()}
        >
          Buy DIAMONDS
        </Button>
      </div>
    </Modal>
  );
}

export default BuyPointsModal;
