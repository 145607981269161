import React, { useContext, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { AuthContext } from '../../context/auth-context';
import { getUsersWhoHaveLikedASpecificUserPostRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import Button from '../Button/Button';
import Input from '../Input/Input';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Modal from '../Modal/Modal';
import User from '../User/User';
import classes from './UsersWhoHaveLikedUserPostModal.module.css';

const RESULTS_PER_PAGE = 10;

function UsersWhoHaveLikedUserPostModal(props) {
  const { profile } = useContext(AuthContext);
  const likedByContainerRef = useRef(null);
  const { userPostId, setShowUsersWhoHaveLikedUserPostModal } = props;

  const [searchUserInputValue, setSearchUserInputValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['users-who-have-liked-user-post', userPostId, searchUserInputValue],
    ({ pageParam = 1 }) =>
      getUsersWhoHaveLikedASpecificUserPostRequest(
        userPostId,
        pageParam,
        RESULTS_PER_PAGE,
        searchUserInputValue,
        profile?._id
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    let fetching = false;
    const likedByContainerRefElement = likedByContainerRef.current;

    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.4) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    likedByContainerRefElement.addEventListener('scroll', scrollHandler);
    return () => {
      likedByContainerRefElement.removeEventListener('scroll', scrollHandler);
    };
  }, [hasNextPage, fetchNextPage]);

  const closeModalHandler = () => {
    setShowUsersWhoHaveLikedUserPostModal(false);
  };

  return (
    <Modal
      style={{ height: 650 }}
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: '100%' }}
            onClick={closeModalHandler}
            pinkcolor="true"
          >
            Close
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Liked By"
    >
      <>
        <Input
          value={searchUserInputValue}
          onChange={(e) => setSearchUserInputValue(e.target.value)}
          style={{ width: '100%', marginBottom: '1rem' }}
          placeholder="Search user"
        />
        <div
          className={classes['liked-by-container']}
          ref={likedByContainerRef}
        >
          {data &&
            data.pages &&
            data.pages[0].results === 0 &&
            searchUserInputValue === '' && (
              <p className={classes['no-results-found']}>
                No one has liked this post!
              </p>
            )}
          {data &&
            data.pages &&
            data.pages[0].results === 0 &&
            searchUserInputValue !== '' && (
              <p className={classes['no-results-found']}>No results found!</p>
            )}

          {data &&
            data.pages &&
            data.pages.map((page) => {
              return page.data.map(({ user }) => {
                return (
                  <User
                    closeModal={closeModalHandler}
                    key={user._id}
                    user={user}
                  />
                );
              });
            })}

          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </>
    </Modal>
  );
}

export default UsersWhoHaveLikedUserPostModal;
