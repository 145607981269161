import React, { useContext, useMemo } from 'react';
import classes from './RequestedChatList.module.css';
import { useQuery } from 'react-query';
import Chat from '../Chat';
import { getMyRequestedChatsRequest } from '../../../../httpRequests/httpRequests';
import { AuthContext } from '../../../../context/auth-context';

function RequestedChatList(props) {
  const { profile } = useContext(AuthContext);
  const {
    setSelectedChatGroup,
    selectedChatGroupId,
    setSelectedChatGroupId,
    searchChatValue,
  } = props;

  const { isLoading, data, error } = useQuery(
    'my-requested-chats',
    getMyRequestedChatsRequest
  );

  const filteredRequestedChatListBySearchValue = useMemo(() => {
    if (!data?.data) return [];

    return data.data.filter((chat) => {
      const otherUserIfSingleChat = chat.users.find(
        (user) => user._id !== profile?._id
      );
      if (
        `${otherUserIfSingleChat.firstName} ${otherUserIfSingleChat.lastName}`
          .toLowerCase()
          .includes(searchChatValue.toLowerCase())
      )
        return true;

      return false;
    });
  }, [searchChatValue, profile, data]);

  return (
    <div className={classes['chat-list']}>
      {!isLoading &&
        !error &&
        data &&
        filteredRequestedChatListBySearchValue.length === 0 && (
          <p className={classes['no-chats']}>No Chats Found!</p>
        )}

      {!isLoading &&
        !error &&
        data &&
        data.data &&
        filteredRequestedChatListBySearchValue.map((chat) => (
          <Chat
            setSelectedChatGroup={setSelectedChatGroup}
            selectedChatGroupId={selectedChatGroupId}
            setSelectedChatGroupId={setSelectedChatGroupId}
            key={chat._id}
            chat={chat}
          />
        ))}
    </div>
  );
}

export default RequestedChatList;
