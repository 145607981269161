class Node {
  constructor(value) {
    this.value = value;
    this.next = null;
  }
}

export class LinkedList {
  constructor() {
    this.head = null;
  }

  insert(value) {
    const node = new Node(value);
    if (this.head === null) {
      this.head = node;
    } else {
      node.next = this.head;
      this.head = node;
    }
  }

  toArray() {
    if (this.head === null) return [];

    const result = [];
    let tempHead = { ...this.head };
    while (tempHead !== null) {
      result.push(tempHead.value);
      tempHead = tempHead.next;
    }

    return result;
  }
}
