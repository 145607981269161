import React, { useEffect, useRef, useState } from 'react';
import classes from './NftList.module.css';
import { useInfiniteQuery } from 'react-query';
import { getAllNftsForSpecificCollectionRequest } from '../../../../httpRequests/httpRequests';
import Input from '../../../../components/Input/Input';
import SearchIcon from '../../../../assets/search-icon.png';
import Nft from '../../../../components/Nft/Nft';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import notify from '../../../../utils/notify';
import ArrowDownIcon from '../../../../assets/arrowDown.png';

const RESULTS_PER_PAGE = 12;

const sortByValues = [
  {
    value: 1,
    sortByValue: 'Price low to high',
    showValue: '📈 Price L-H',
  },
  {
    value: 2,
    sortByValue: 'Price high to low',
    showValue: '📈 Price H-L',
  },
];

function NftList(props) {
  const { collectionId, selectedSection } = props;
  const sortContainerRef = useRef();

  const [showSortOptions, setShowSortOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sortByValue, setSortByValue] = useState(1);

  let filterListedItems = true;
  if (selectedSection === 'Not Listed Items') {
    filterListedItems = false;
  }

  const sortBy = sortByValues.find((el) => el.value === sortByValue);
  let sortByFilter = 'low-high';
  if (sortBy?.sortByValue === 'Price low to high') {
    sortByFilter = 'low-high';
  } else if (sortBy?.sortByValue === 'Price high to low') {
    sortByFilter = 'high-low';
  }

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [
      'nfts-for-specific-collection',
      collectionId,
      searchValue,
      filterListedItems,
      sortByFilter,
    ],
    ({ pageParam = 1 }) =>
      getAllNftsForSpecificCollectionRequest(
        collectionId,
        pageParam,
        RESULTS_PER_PAGE,
        searchValue,
        filterListedItems,
        sortByFilter
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const checkForClosingSortOptions = (e) => {
      if (e.target.closest('#sorting-container') !== sortContainerRef.current) {
        setShowSortOptions(false);
      }
    };

    window.addEventListener('click', checkForClosingSortOptions);

    return () =>
      window.removeEventListener('click', checkForClosingSortOptions);
  }, [sortContainerRef]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <div>
      <div className={classes['filter-container']}>
        <div className={classes['input-container']}>
          <Input
            style={{ width: '100%' }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search Item"
          />
          <img
            alt="Search"
            src={SearchIcon}
            width={18}
            className={classes['search-icon']}
          />
        </div>

        {selectedSection === 'Listed Items' && (
          <div
            ref={sortContainerRef}
            id="sorting-container"
            onClick={() => setShowSortOptions((prevState) => !prevState)}
            className={classes['sorting-container']}
          >
            <p className={classes['sorting-text']}>{sortBy['showValue']} </p>
            <img alt="Arrow" src={ArrowDownIcon} width={14} height={8} />

            {showSortOptions && (
              <div className={classes['sort-options']}>
                {sortByValues
                  .filter(({ showValue }) => showValue !== sortBy['showValue'])
                  .map(({ value, showValue }) => {
                    return (
                      <p
                        key={showValue}
                        onClick={() => setSortByValue(value)}
                        className={classes['sort-option']}
                      >
                        {showValue}
                      </p>
                    );
                  })}
              </div>
            )}
          </div>
        )}
      </div>

      {!isLoading && data?.pages && data.pages[0].results === 0 && (
        <p style={{ fontWeight: 500, padding: '.75rem 1rem' }}>
          No NFTs Found!
        </p>
      )}
      <div className={classes['nft-list']}>
        {!isLoading &&
          data?.pages &&
          data.pages.map((page) => {
            return page.data.map(({ nft }) => <Nft key={nft._id} nft={nft} />);
          })}
      </div>
      {(isLoading || isFetching) && <LoadingSpinner />}
    </div>
  );
}

export default NftList;
