import React, { useEffect, useState } from 'react';
import classes from './ThirdScreen.module.css';
import { useQuery } from 'react-query';
import Button from '../../../../../../components/Button/Button';
import { getDateFrom, limitString } from '../../../../../../helpers/helpers';
import { getMyGroupsAsAdminRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import GroupPresentationImage from '../../../../../../assets/group-presentation-image.png';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
// import SliderButton from '../../../../../../components/SliderButton/SliderButton';

function ThirdScreen(props) {
  const {
    setScreenNumber,
    associatedGroups,
    setAssociatedGroups,
    // isNftGatedCommunity,
    // setIsNftGatedCommunity,
  } = props;

  const [searchValue] = useState('');

  const { isLoading, data, error } = useQuery(
    ['my-groups-as-admin', searchValue],
    () => getMyGroupsAsAdminRequest(searchValue)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const goBackHandler = () => {
    setScreenNumber((prevState) => prevState - 1);
  };

  const goToNextStepHandler = () => {
    setScreenNumber((prevState) => prevState + 1);
  };

  const includeGroupInAssociatedGroupsHandler = (groupId) => {
    setAssociatedGroups((prevState) => [...prevState, groupId]);
  };

  const removeGroupFromAssociatedGroupsHandler = (groupId) => {
    setAssociatedGroups((prevState) =>
      prevState.filter((el) => el !== groupId)
    );
  };

  const renderAddOrRemoveButton = (groupId) => {
    if (associatedGroups.includes(groupId)) {
      return (
        <div style={{ marginLeft: 'auto' }}>
          <Button
            style={{ width: 200 }}
            onClick={() => removeGroupFromAssociatedGroupsHandler(groupId)}
            darkpink="true"
          >
            Remove
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ marginLeft: 'auto' }}>
          <Button
            style={{
              width: 200,
            }}
            onClick={() => includeGroupInAssociatedGroupsHandler(groupId)}
            darkpinkcolor="true"
            disabled={associatedGroups.length > 0}
          >
            Add
          </Button>
        </div>
      );
    }
  };

  const isNextButtonDisabled = () => {
    return false;
  };

  return (
    <div
      style={{
        minHeight: 360,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: 520,
      }}
    >
      <div>
        <p className={classes['nft-gated-community-text']}>
          Collections can provide access to a community you own, kind of like a
          pass. If you own a community and would like this collection to provide
          access to it - select it below.
        </p>
        {/* <SliderButton
          leftValue={<p className={classes['slider-text']}>NO</p>}
          rightValue={<p className={classes['slider-text']}>YES</p>}
          isLeftContentSelected={!isNftGatedCommunity}
          setIsLeftContentSelected={setIsNftGatedCommunity}
        /> */}

        <h3 className={classes['associated-groups-text']}>Which Community?</h3>

        <div className={classes['groups-list']}>
          {!isLoading && data?.data && data.data.length === 0 && (
            <p className={classes['no-groups-found']}>No Community Found</p>
          )}
          {!isLoading &&
            data?.data &&
            data.data.map(({ group }) => {
              return (
                <div key={group._id} className={`${classes['group']}`}>
                  {group.coverPhoto ? (
                    <img
                      className={classes['cover-photo']}
                      src={group.coverPhoto}
                      alt="Group Cover"
                    />
                  ) : (
                    <img
                      className={classes['cover-photo']}
                      src={GroupPresentationImage}
                      alt={'Group Cover'}
                    />
                  )}
                  <div>
                    <p title={group.name} className={classes['group-name']}>
                      {limitString(group.name, 20)}
                    </p>
                    <p className={classes['createdAt']}>
                      {getDateFrom(group.createdAt)} ago
                    </p>
                  </div>
                  {renderAddOrRemoveButton(group._id)}
                </div>
              );
            })}

          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      <div style={{ justifySelf: 'flex-end' }}>
        {props.children}
        <div className={classes['footer']}>
          <Button
            style={{ flex: 1 }}
            darkpinkcolor="true"
            onClick={goBackHandler}
          >
            Go Back
          </Button>
          <Button
            disabled={isNextButtonDisabled()}
            onClick={goToNextStepHandler}
            style={{ flex: 1 }}
            darkpink="true"
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ThirdScreen;
