import React, { useState, useContext } from 'react';
import ReactPlayer from 'react-player';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { IPFS_URL } from '../../../../constants/IPFS';
import { CoinMarketCapContext } from '../../../../context/coinmarketcap-context';
import { getFormattedNumber } from '../../../../helpers/helpers';
import { buyNftWithWyreWalletRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './BuyWithWyreWalletModal.module.css';

function BuyWithWyreWalletModal(props) {
  const { nft, setShowBuyWithWyreWalletModal } = props;
  const { glmsToUsd } = useContext(CoinMarketCapContext);

  const [startedBuyNow, setStartedBuyNow] = useState(false);

  const closeModalHandler = () => {
    setShowBuyWithWyreWalletModal(false);
  };

  const buyNftWithWyreWalletHandler = async () => {
    setStartedBuyNow(true);

    try {
      await buyNftWithWyreWalletRequest(nft._id);
      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setStartedBuyNow(false);
  };

  return (
    <Modal
      style={{ height: 500 }}
      title={'Buy With USD'}
      closeModal={closeModalHandler}
      footer={
        <div className={classes['footer']}>
          <Button
            modernpinkcolor="true"
            onClick={closeModalHandler}
            disabled={startedBuyNow}
          >
            Cancel
          </Button>
          <Button
            modernpink="true"
            onClick={buyNftWithWyreWalletHandler}
            disabled={startedBuyNow}
          >
            Buy With USD
          </Button>
        </div>
      }
    >
      <div className={classes['buy-now-modal-container']}>
        <div className={classes['info-container']}>
          {nft.type === 'video' ? (
            <ReactPlayer
              width={300}
              height={300}
              url={`${IPFS_URL}/${nft.ipfs}`}
            />
          ) : (
            <img
              alt="IPFS"
              className={classes['nft-image']}
              src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
            />
          )}
          <div className={classes['price-container']}>
            <p className={classes['price-text']}>Price:</p>
            <div className={classes['price-content']}>
              <h3 className={classes['usd-price']}>
                {getFormattedNumber(
                  (glmsToUsd.price * nft.buyNowPrice).toFixed(2)
                )}{' '}
                USD
              </h3>
              <p className={classes['price']}>
                {getFormattedNumber(nft.buyNowPrice)} GLMS
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default BuyWithWyreWalletModal;
