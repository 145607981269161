import React, { useEffect, useState } from 'react';
import classes from './UpdateWhoCanWriteModal.module.css';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import notify from '../../../../../../utils/notify';
import { useQuery } from 'react-query';
import {
  getSpecificGroupChannelRequest,
  updateWhoCanWriteInASpecificGroupChannelRequest,
} from '../../../../../../httpRequests/httpRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';

function UpdateWhoCanWriteModal(props) {
  const { groupId, channelId, setShowUpdateWhoCanWriteModal } = props;
  const [whoCanWrite, setWhoCanWrite] = useState('Anyone');
  const [updatingWhoCanWrite, setUpdatingWhoCanWrite] = useState(false);

  const { isLoading, data, error } = useQuery(
    ['group-channel', channelId, groupId],
    () => getSpecificGroupChannelRequest(groupId, channelId)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    if (!data?.data) return;
    setWhoCanWrite(data.data.whoCanWrite);
  }, [data]);

  const closeModalHandler = () => {
    setShowUpdateWhoCanWriteModal(false);
  };

  const updateWhoCanWriteInASpecificChannelHandler = async () => {
    setUpdatingWhoCanWrite(true);

    try {
      const requestBody = {
        whoCanWrite,
      };
      await updateWhoCanWriteInASpecificGroupChannelRequest(
        groupId,
        channelId,
        requestBody
      );

      setUpdatingWhoCanWrite(false);
      closeModalHandler();
    } catch (err) {
      setUpdatingWhoCanWrite(false);
      notify('error', err, 2000);
    }
  };

  const updateWhoCanWriteHandler = (who) => {
    setWhoCanWrite(who);
  };

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Update Who Can Write"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            disabled={updatingWhoCanWrite}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Close
          </Button>

          <Button
            style={{ width: 200 }}
            disabled={updatingWhoCanWrite}
            onClick={updateWhoCanWriteInASpecificChannelHandler}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && data?.data && (
        <div>
          <div className={classes['anyone-container']}>
            <input
              onChange={() => updateWhoCanWriteHandler('anyone')}
              checked={whoCanWrite === 'anyone'}
              type={'radio'}
              className={classes['checkbox']}
            />
            <p>Anyone</p>
          </div>

          <div className={classes['only-admins-container']}>
            <input
              onChange={() => updateWhoCanWriteHandler('admins')}
              checked={whoCanWrite === 'admins'}
              type={'radio'}
              className={classes['checkbox']}
            />
            <p>Only Admins</p>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default UpdateWhoCanWriteModal;
