import React from 'react';
import classes from './CreateFirstNftModal.module.css';
import CreateClaimableIcon from '../../../../assets/create-claimable-collection-icon.png';
import CreateCollectionIcon from '../../../../assets/create-collection-icon-2.png';
import SingleNftIcon from '../../../../assets/single-nft-icon.png';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { Link } from 'react-router-dom';

function CreateFirstNftModal(props) {
  const { setShowCreateFirstNftModal } = props;

  const closeModalHandler = () => {
    setShowCreateFirstNftModal(false);
  };

  return (
    <Modal
      style={{ height: 500, width: 400 }}
      closeIcon="true"
      closeModal={closeModalHandler}
      title="Create first collectible"
    >
      <div className={classes['create-first-collectible-modal-container']}>
        <p className={classes['different-type-text']}>
          There are 3 ways to create collectibles on Glimpse
        </p>

        <div className={classes['single-nfts']}>
          <div className={classes['single-nft-icon-container']}>
            <img
              alt="1/1s"
              src={SingleNftIcon}
              className={classes['single-nft-icon']}
            />
          </div>

          <div className={classes['single-nft-description-container']}>
            <h3 className={classes['create-collectible-type']}>1/1’s</h3>
            <p className={classes['create-collectible-description']}>
              1 of 1 Collectibles are the rarest type of collectible, because
              only 1 of them exists. When you create a 1/1, you are creating a
              single collectible that can be purchased by 1 other user. The
              collectible can be resold, but there will only ever be 1.
            </p>
          </div>
        </div>

        <div className={classes['collections']}>
          <div className={classes['collection-icon-container']}>
            <img
              alt="Collections"
              src={CreateCollectionIcon}
              className={classes['collection-icon']}
            />
          </div>

          <div className={classes['collection-description-container']}>
            <h3 className={classes['create-collectible-type']}>Collections</h3>
            <p className={classes['create-collectible-description']}>
              Collections are designed to give users greater control over their
              content. For example, you may want to create multiple collectibles
              from the same image/video rather than just 1 - this is known as an
              EDITION. Or you may want each collectible to be different, but
              also want your own space on Glimpse to make it easier for your
              followers to find you - this is known as a SERIES.
            </p>
          </div>
        </div>

        <div className={classes['airdrops']}>
          <div className={classes['airdrop-icon-container']}>
            <img
              alt="Airdrops"
              src={CreateClaimableIcon}
              className={classes['airdrop-icon']}
            />
          </div>

          <div className={classes['airdrop-description-container']}>
            <h3 className={classes['create-airdrop-type']}>Airdrops</h3>
            <p className={classes['create-airdrop-description']}>
              Airdrops are essentially free collections. Like a collection, you
              can create an edition or a series, but the difference is that
              users claim the collectibles for free rather than pay for them.
              This can be a great way to grow your audience before you offer a
              paid collection, can be used to offer special content to your
              current followers, and can even be used by events/brands to offer
              a proof of attendance badge or other digital memorabilia.
            </p>
          </div>
        </div>

        <Link to="/create">
          <Button style={{ width: '100%', marginTop: 10 }} darkpink="true">
            Create your first 1/1
          </Button>
        </Link>
      </div>
    </Modal>
  );
}

export default CreateFirstNftModal;
