import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import {
  getAllEventsRequest,
  updateEventRequest,
} from '../../../../../httpRequests/httpRequests';
import { useIntersectionObserver } from '../../../../../hooks/useIntersectionObserver';
import { Row, Col } from 'antd';
import classes from './AllEventsTable.module.css';
import LoadingSpinner from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import notify from '../../../../../utils/notify';
import moment from 'moment';

const RESULTS_PER_PAGE = 10;

function AllEventsTable() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['all-events', searchValue],
    ({ pageParam = 1 }) =>
      getAllEventsRequest(pageParam, RESULTS_PER_PAGE, searchValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateEvent = (eventId, isActive) => {
    queryClient.setQueryData(['all-events', searchValue], (data) => {
      return {
        ...data,
        pages: data.pages.map((page) => {
          const updatedData = page.data.map((event) =>
            event._id === eventId ? { ...event, active: isActive } : event
          );
          return { ...page, data: updatedData };
        }),
      };
    });
  };

  const deactivateEventHandler = async (eventId) => {
    try {
      const requestBody = {
        active: false,
      };
      await updateEventRequest(eventId, requestBody);
      invalidateEvent(eventId, false);
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const activateEventHandler = async (eventId) => {
    try {
      const requestBody = {
        active: true,
      };
      await updateEventRequest(eventId, requestBody);
      invalidateEvent(eventId, true);
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-events-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-events-heading']}>
            ALL EVENTS = {data?.pages[0].results}
          </h2>
          <div>
            <input
              placeholder="Search For Events"
              style={{ width: 300 }}
              className="searchInput"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={3} className={classes['pagination-col']}>
              Photo
            </Col>
            <Col md={3} className={classes['pagination-col']}>
              Event Name
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Date
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Location
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Active
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Deactivate
            </Col>
          </Row>
          {data && data.pages[0].results === 0 && (
            <h2 className="bold-text">No results found!</h2>
          )}
          {data &&
            data.pages.map((page) => {
              return page.data.map(
                ({ _id, name, date, location, photo, active }) => {
                  return (
                    <div key={_id}>
                      <hr />
                      <Row justify="space-between" style={{ padding: '1em 0' }}>
                        <Col
                          md={3}
                          style={{ cursor: 'pointer' }}
                          onClick={() => history.push(`/events/${_id}`)}
                        >
                          <img
                            width={25}
                            height={25}
                            style={{ borderRadius: 100, objectFit: 'cover' }}
                            alt="User"
                            src={photo}
                          />
                        </Col>
                        <Col
                          style={{ cursor: 'pointer' }}
                          onClick={() => history.push(`/events/${_id}`)}
                          md={3}
                        >
                          {name}
                        </Col>
                        <Col md={3}>{moment(date).format('MMMM Do, YYYY')}</Col>
                        <Col md={3}>{location}</Col>
                        <Col md={3}>{active ? 'YES' : 'NO'}</Col>
                        <Col md={3}>
                          {active ? (
                            <button
                              onClick={() => deactivateEventHandler(_id)}
                              className="cancel-button"
                            >
                              Deactivate
                            </button>
                          ) : (
                            <button
                              onClick={() => activateEventHandler(_id)}
                              className="success-button"
                            >
                              Activate
                            </button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  );
                }
              );
            })}

          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default AllEventsTable;
