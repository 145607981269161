import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './SelectedGroup.module.css';
import Button from '../../../../components/Button/Button';
import { limitString } from '../../../../helpers/helpers';
import { useQuery } from 'react-query';
import {
  getAllGroupMembersForASpecificGroupRequest,
  getMemberRoleInASpecificGroupRequest,
  getNumberOfMembersForASpecificGroupRequest,
  getSpecificGroupRequest,
  inviteUsersToGroupRequest,
  leaveGroupRequest,
} from '../../../../httpRequests/httpRequests';
import MembersModal from '../MembersModal/MembersModal';
import ImageUpload from '../../../../components/ImageUpload/ImageUpload';
import CameraIcon from '../../../../assets/camera-1.png';
import notify from '../../../../utils/notify';
import { AuthContext } from '../../../../context/auth-context';
import UploadCoverPhotoModal from '../UploadCoverPhotoModal/UploadCoverPhotoModal';
import InviteUsersModal from '../../../../components/InviteFriendsModal/InviteFriendsModal';
import Discussion from '../Discussion/Discussion';
import Topics from '../Topics/Topics';
import Members from '../Members/Members';
import Events from '../Events/Events';
import Chat from '../Chat/Chat';
import InvitationRequestsModal from '../InvitationRequestsModal/InvitationRequestsModal';
import { useHistory } from 'react-router-dom';
import EditGroupInfoModal from '../EditGroupInfoModal/EditGroupInfoModal';
import NftCollections from '../NftCollections/NftCollections';
import ImageModal from '../../../../components/ImageModal/ImageModal';
import MetaTags from 'react-meta-tags';
import Polling from '../Polling/Polling';
import UpdateWhoCanPostModal from '../UpdateWhoCanPostModal/UpdateWhoCanPostModal';
import PinkMenuOptionsIcon from '../../../../assets/pink-menu-options.png';
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

const GROUP_SECTIONS = [
  'Discussion',
  'Topics',
  'Members',
  'Chat',
  'NFT Collections',
  'Polling',
];

function SelectedGroup(props) {
  const { selectedGroup } = props;
  const shareIconsContainerRef = useRef();
  const moreIconRef = useRef();
  const history = useHistory();
  const { profile } = useContext(AuthContext);
  const [showInviteUsersModal, setShowInviteUsersModal] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [coverPhotoPreviewUrl, setCoverPhotoPreviewUrl] = useState();
  const [coverPhotoUrl, setCoverPhotoUrl] = useState();
  const [showUploadCoverPhotoModal, setShowUploadCoverPhotoModal] = useState(
    false
  );
  const [selectedGroupSection, setSelectedGroupSection] = useState(
    GROUP_SECTIONS[0]
  );
  const [selectedTopic, setSelectedTopic] = useState(undefined);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [
    showInvitationRequestsModal,
    setShowInvitationRequestsModal,
  ] = useState(false);
  const [showEditGroupInfoModal, setShowEditGroupInfoModal] = useState(false);
  const [showCoverPhotoImageModal, setShowCoverPhotoImageModal] = useState(
    false
  );

  const [showUpdateWhoCanPostModal, setShowUpdateWhoCanPostModal] = useState(
    false
  );

  const [showShareGroupOptions, setShowShareGroupOptions] = useState(false);

  const groupInformationResponse = useQuery(['groups', selectedGroup._id], () =>
    getSpecificGroupRequest(selectedGroup._id)
  );

  const first10GroupMembersResponse = useQuery(
    ['first-10-group-members', selectedGroup._id],
    () =>
      getAllGroupMembersForASpecificGroupRequest(1, 10, selectedGroup._id, '')
  );

  const numberOfMembersResponse = useQuery(
    ['number-of-members', selectedGroup._id],
    () => getNumberOfMembersForASpecificGroupRequest(selectedGroup._id)
  );

  const myMemberRoleResponse = useQuery(
    ['my-member-role', selectedGroup._id],
    () => getMemberRoleInASpecificGroupRequest(selectedGroup._id, profile?._id)
  );

  useEffect(() => {
    const checkForClosingShareGroupOptions = (e) => {
      if (e.target.closest('#share-btn')) return;

      if (e.target.closest('#share-icons-container') === null) {
        setShowShareGroupOptions(false);
      }
    };

    window.addEventListener('click', checkForClosingShareGroupOptions);

    return () =>
      window.removeEventListener('click', checkForClosingShareGroupOptions);
  }, []);

  const openMembersModalHandler = () => setShowMembersModal(true);

  const openInviteUsersModalHandler = () => setShowInviteUsersModal(true);

  const coverPhotoInputHandler = (value) => {
    if (!value) return;
    if (value.size > 40000000)
      return notify(
        'error',
        'You can not upload more than 40MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setCoverPhotoUrl(value);
    try {
      fileReader.onload = () => {
        setCoverPhotoPreviewUrl(fileReader.result);
        setShowUploadCoverPhotoModal(true);
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const inviteHandler = async (selectedFriendsIds) => {
    try {
      const requestBody = {
        userIds: Object.keys(selectedFriendsIds),
      };
      await inviteUsersToGroupRequest(selectedGroup._id, requestBody);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const toggleMoreOptions = () => {
    setShowMoreOptions((prevState) => !prevState);
  };

  const openInvitationRequestsModalHandler = () => {
    setShowInvitationRequestsModal(true);
    setShowMoreOptions(false);
  };

  const leaveGroupHandler = async () => {
    try {
      await leaveGroupRequest(selectedGroup._id);
      history.push('/groups');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const openEditGroupInfoModalHandler = async () => {
    setShowEditGroupInfoModal(true);
  };

  const openUpdateWhoCanPostModalHandler = () => {
    setShowUpdateWhoCanPostModal(true);
  };

  const renderShareGroupOptionsHandler = () => {
    const url =
      selectedGroup.status === 'public'
        ? `${window.location.origin}/groups/${selectedGroup._id}`
        : `${window.location.origin}`;

    return (
      <div
        id="share-icons-container"
        ref={shareIconsContainerRef}
        className={classes['share-icons-container']}
      >
        <FacebookShareButton
          url={url}
          hashtag={'#glimpsecommunities'}
          title={`I got in to ${selectedGroup.name} exclusive community on Glimpse!`}
        >
          <FacebookIcon size={28} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={url}
          hashtag={'#glimpsecommunities'}
          title={`I got in to ${selectedGroup.name} exclusive community on Glimpse!`}
        >
          <TwitterIcon size={28} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={url}
          hashtag={'#glimpsecommunities'}
          title={`I got in to ${selectedGroup.name} exclusive community on Glimpse!`}
        >
          <WhatsappIcon size={28} round />
        </WhatsappShareButton>

        <TelegramShareButton
          url={url}
          hashtag={'#glimpsecommunities'}
          title={`I got in to ${selectedGroup.name} exclusive community on Glimpse!`}
        >
          <TelegramIcon size={28} round />
        </TelegramShareButton>

        <RedditShareButton
          url={url}
          hashtag={'#glimpsecommunities'}
          title={`I got in to ${selectedGroup.name} exclusive community on Glimpse!`}
        >
          <RedditIcon size={28} round />
        </RedditShareButton>
      </div>
    );
  };

  const isMyRoleAdmin = myMemberRoleResponse.data?.data?.role === 'admin';

  return (
    <>
      <MetaTags>
        <title>{selectedGroup.name} | Group</title>
        <meta property="og:title" content={`${selectedGroup.name} | Group`} />
        <meta property="og:description" content={`${selectedGroup.about}`} />

        {groupInformationResponse.data?.data?.coverPhoto && (
          <meta
            property="og:image"
            content={groupInformationResponse.data?.data?.coverPhoto}
          />
        )}
      </MetaTags>

      {showInviteUsersModal && (
        <InviteUsersModal
          groupId={selectedGroup._id}
          inviteHandler={inviteHandler}
          setShowInviteFriendsModal={setShowInviteUsersModal}
        />
      )}

      {showInvitationRequestsModal && (
        <InvitationRequestsModal
          groupId={selectedGroup._id}
          setShowInvitationRequestsModal={setShowInvitationRequestsModal}
        />
      )}

      {showUploadCoverPhotoModal && (
        <UploadCoverPhotoModal
          setShowUploadCoverPhotoModal={setShowUploadCoverPhotoModal}
          coverPhotoPreviewUrl={coverPhotoPreviewUrl}
          setCoverPhotoPreviewUrl={setCoverPhotoPreviewUrl}
          coverPhotoImageUrl={coverPhotoUrl}
          setCoverPhotoImageUrl={setCoverPhotoUrl}
          groupId={selectedGroup._id}
        />
      )}

      {showEditGroupInfoModal && (
        <EditGroupInfoModal
          groupId={selectedGroup._id}
          groupName={selectedGroup.name}
          about={selectedGroup.about}
          setShowEditGroupInfoModal={setShowEditGroupInfoModal}
        />
      )}

      {showMembersModal && (
        <MembersModal
          groupId={selectedGroup._id}
          setShowMembersModal={setShowMembersModal}
        />
      )}

      {showUpdateWhoCanPostModal && groupInformationResponse.data?.data && (
        <UpdateWhoCanPostModal
          group={groupInformationResponse.data.data}
          setShowUpdateWhoCanPostModal={setShowUpdateWhoCanPostModal}
        />
      )}

      {showCoverPhotoImageModal && (
        <ImageModal
          title="Cover Photo"
          setShowImageModal={setShowCoverPhotoImageModal}
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={groupInformationResponse.data?.data.coverPhoto}
            alt="CoverPhoto"
          />
        </ImageModal>
      )}

      <div className={classes['selected-group-container']}>
        {groupInformationResponse.data?.data.coverPhoto ? (
          <div className={classes['cover-photo-container']}>
            <img
              onClick={() => setShowCoverPhotoImageModal(true)}
              alt={'CoverPhoto'}
              className={classes['cover-photo']}
              src={groupInformationResponse.data.data.coverPhoto}
            />
            {isMyRoleAdmin && (
              <div className={classes['cover-photo-camera-icon-container']}>
                <ImageUpload onInput={coverPhotoInputHandler}>
                  <img
                    alt="Camera"
                    src={CameraIcon}
                    className={classes['cover-photo-camera-icon']}
                  />
                </ImageUpload>
              </div>
            )}

            <div className={classes['group-information']}>
              <div style={{ width: '100%' }}>
                <div className={classes['members-privacy-container']}>
                  {groupInformationResponse.data?.data && (
                    <h3
                      title={groupInformationResponse.data.data.name}
                      className={classes['group-name']}
                    >
                      {limitString(groupInformationResponse.data.data.name, 25)}
                    </h3>
                  )}
                  <p className={classes['circle']}></p>
                  <p className={classes['group-privacy']}>
                    {selectedGroup.status.substring(0, 1).toUpperCase() +
                      selectedGroup.status.substring(1)}{' '}
                    Group
                  </p>
                </div>

                {first10GroupMembersResponse &&
                  first10GroupMembersResponse.data?.data && (
                    <div className={classes['profile-images-container']}>
                      <p
                        onClick={openMembersModalHandler}
                        className={classes['members-text']}
                      >
                        {numberOfMembersResponse.data &&
                        numberOfMembersResponse.data?.data
                          ? `${numberOfMembersResponse.data.data} Members`
                          : '-- Members'}
                      </p>
                      {first10GroupMembersResponse.data.data.map(
                        ({ user }, i) => {
                          return (
                            <div key={user._id}>
                              <img
                                style={{ zIndex: 11 - i }}
                                className={classes['user-image']}
                                src={user.photo}
                                alt="Member"
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
              </div>

              <div className={classes['button-options']}>
                <Button
                  disabled={
                    !isMyRoleAdmin &&
                    groupInformationResponse.data?.data.status === 'private'
                  }
                  onClick={openInviteUsersModalHandler}
                  style={{
                    border: '1px solid white',
                  }}
                  darkpink="true"
                >
                  Invite
                </Button>

                <Button
                  id="share-btn"
                  onClick={() =>
                    setShowShareGroupOptions((prevState) => !prevState)
                  }
                  style={{ marginLeft: 10 }}
                  darkpinkcolor="true"
                >
                  Share
                </Button>

                {showShareGroupOptions && renderShareGroupOptionsHandler()}

                <div style={{ position: 'relative' }}>
                  {showMoreOptions && (
                    <div className={classes['more-options']}>
                      {isMyRoleAdmin &&
                        groupInformationResponse.data?.data.status ===
                          'private' && (
                          <p
                            onClick={openInvitationRequestsModalHandler}
                            className={classes['option']}
                          >
                            Join Requests
                          </p>
                        )}

                      {isMyRoleAdmin && (
                        <p
                          onClick={openEditGroupInfoModalHandler}
                          className={classes['option']}
                        >
                          Edit Group Info
                        </p>
                      )}

                      {isMyRoleAdmin && (
                        <p
                          onClick={openUpdateWhoCanPostModalHandler}
                          className={classes['option']}
                        >
                          Update Who Can Post
                        </p>
                      )}

                      <p
                        onClick={leaveGroupHandler}
                        className={classes['leave']}
                      >
                        Leave Group
                      </p>
                    </div>
                  )}

                  <div
                    onClick={toggleMoreOptions}
                    ref={moreIconRef}
                    className={classes['menu-options-container']}
                  >
                    <img
                      alt="More"
                      className={classes['more-icon']}
                      src={PinkMenuOptionsIcon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes['cover-photo-container']}>
            <div className={classes['no-cover-photo']}></div>

            {selectedGroup.organizer === profile?._id && (
              <div className={classes['cover-photo-camera-icon-container']}>
                <ImageUpload onInput={coverPhotoInputHandler}>
                  <img
                    alt="Camera"
                    src={CameraIcon}
                    className={classes['cover-photo-camera-icon']}
                  />
                </ImageUpload>
              </div>
            )}

            <div className={classes['group-information']}>
              <div style={{ width: '100%' }}>
                <div className={classes['members-privacy-container']}>
                  {groupInformationResponse.data?.data && (
                    <h3
                      title={groupInformationResponse.data.data.name}
                      className={classes['group-name']}
                    >
                      {limitString(groupInformationResponse.data.data.name, 25)}
                    </h3>
                  )}
                  <p className={classes['circle']}></p>
                  <p className={classes['group-privacy']}>
                    {selectedGroup.status.substring(0, 1).toUpperCase() +
                      selectedGroup.status.substring(1)}{' '}
                    Group
                  </p>
                </div>

                {first10GroupMembersResponse &&
                  first10GroupMembersResponse.data?.data && (
                    <div className={classes['profile-images-container']}>
                      <p
                        onClick={openMembersModalHandler}
                        className={classes['members-text']}
                      >
                        {numberOfMembersResponse.data &&
                        numberOfMembersResponse.data?.data
                          ? `${numberOfMembersResponse.data.data} Members`
                          : '-- Members'}
                      </p>
                      {first10GroupMembersResponse.data.data.map(
                        ({ user }, i) => {
                          return (
                            <div key={user._id}>
                              <img
                                style={{ zIndex: 11 - i }}
                                className={classes['user-image']}
                                src={user.photo}
                                alt="Member"
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
              </div>

              <div className={classes['button-options']}>
                <Button
                  disabled={
                    !isMyRoleAdmin &&
                    groupInformationResponse.data?.data.status === 'private'
                  }
                  onClick={openInviteUsersModalHandler}
                  style={{
                    border: '1px solid white',
                  }}
                  darkpink="true"
                >
                  Invite
                </Button>

                <Button
                  id="share-btn"
                  onClick={() =>
                    setShowShareGroupOptions((prevState) => !prevState)
                  }
                  darkpinkcolor="true"
                  style={{ marginLeft: 10 }}
                >
                  Share
                </Button>

                {showShareGroupOptions && renderShareGroupOptionsHandler()}

                <div style={{ position: 'relative' }}>
                  {showMoreOptions && (
                    <div className={classes['more-options']}>
                      {isMyRoleAdmin &&
                        groupInformationResponse.data?.data.status ===
                          'private' && (
                          <p
                            onClick={openInvitationRequestsModalHandler}
                            className={classes['option']}
                          >
                            Join Requests
                          </p>
                        )}

                      {isMyRoleAdmin && (
                        <p
                          onClick={openEditGroupInfoModalHandler}
                          className={classes['option']}
                        >
                          Edit Group Info
                        </p>
                      )}

                      {isMyRoleAdmin && (
                        <p
                          onClick={openUpdateWhoCanPostModalHandler}
                          className={classes['option']}
                        >
                          Update Who Can Post
                        </p>
                      )}

                      <p
                        onClick={leaveGroupHandler}
                        className={classes['leave']}
                      >
                        Leave Group
                      </p>
                    </div>
                  )}

                  <div
                    onClick={toggleMoreOptions}
                    ref={moreIconRef}
                    className={classes['menu-options-container']}
                  >
                    <img
                      alt="More"
                      className={classes['more-icon']}
                      src={PinkMenuOptionsIcon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={classes['group-sections']}>
          {GROUP_SECTIONS.map((section) => {
            return (
              <div
                onClick={() => setSelectedGroupSection(section)}
                key={section}
                className={classes['section-container']}
              >
                <div
                  className={`${classes['section-text']} ${
                    section === selectedGroupSection
                      ? classes['selected-section']
                      : ''
                  }`}
                >
                  {section}

                  {section === selectedGroupSection && (
                    <div className={classes['selected-border']}>&nbsp;</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes['horizontal-line']}></div>

        {selectedGroupSection === 'Discussion' && myMemberRoleResponse?.data && (
          <div className={classes['single-section-container']}>
            <Discussion
              setSelectedTopic={setSelectedTopic}
              setSelectedGroupSection={setSelectedGroupSection}
              selectedTopic={selectedTopic}
              groupId={selectedGroup._id}
              selectedGroup={selectedGroup}
              isMyRoleAdmin={isMyRoleAdmin}
            />
          </div>
        )}
        {selectedGroupSection === 'Topics' && (
          <div className={classes['single-section-container']}>
            <Topics
              setSelectedGroupSection={setSelectedGroupSection}
              selectedTopic={selectedTopic}
              setSelectedTopic={setSelectedTopic}
              groupId={selectedGroup._id}
            />
          </div>
        )}

        {selectedGroupSection === 'Members' &&
          numberOfMembersResponse.data &&
          numberOfMembersResponse.data?.data && (
            <div className={classes['single-section-container']}>
              <Members
                isMyRoleAdmin={isMyRoleAdmin}
                groupId={selectedGroup._id}
                numberOfMembers={numberOfMembersResponse.data.data}
              />
            </div>
          )}

        {selectedGroupSection === 'Events' && (
          <Events groupId={selectedGroup._id} />
        )}

        {selectedGroupSection === 'Chat' && (
          <div className={classes['single-section-container']}>
            <Chat isMyRoleAdmin={isMyRoleAdmin} groupId={selectedGroup._id} />
          </div>
        )}

        {selectedGroupSection === 'NFT Collections' && (
          <div className={classes['single-section-container']}>
            <NftCollections groupId={selectedGroup._id} />
          </div>
        )}

        {selectedGroupSection === 'Polling' && (
          <div className={classes['single-section-container']}>
            <Polling
              isMyRoleAdmin={isMyRoleAdmin}
              groupId={selectedGroup._id}
              setSelectedTopic={setSelectedTopic}
              setSelectedGroupSection={setSelectedGroupSection}
              selectedTopic={selectedTopic}
              selectedGroup={selectedGroup}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default SelectedGroup;
