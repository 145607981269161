import React, { useContext } from 'react';
import './landing.scss';
import classes from './Landing.module.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import MetaTags from 'react-meta-tags';
import GlimpseLogo from '../../assets/Glimpse-Vector.png';
import Button from '../../components/Button/Button';
import MoneyCreditCardImage from '../../assets/money-credit-card.png';
import ContentCreatorImage from '../../assets/content-creator.png';
import RightPinkIcon from '../../assets/right-pink.png';
import Telegram from '../../assets/telegram.png';
import Twitter from '../../assets/twitter.png';
import Linkedin from '../../assets/linkedin-1.png';
import { useState } from 'react';
import { isValidEmail } from '../../helpers/helpers';
import { requestBetaAccessRequest } from '../../httpRequests/httpRequests';

const Landing = () => {
  const { profile } = useContext(AuthContext);

  const [requestBetaAccessEmail, setRequestBetaAccessEmail] = useState('');
  const [
    requestBetaAccessEmailTouched,
    setRequestBetaAccessEmailTouched,
  ] = useState(false);
  const [
    requestBetaAccessEmailValid,
    setRequestBetaAccessEmailValid,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  // eslint-disable-next-line
  const requestBetaAccessEmailChangeHandler = (e) => {
    setRequestBetaAccessEmail(e.target.value);
    setRequestBetaAccessEmailTouched(true);
    setRequestBetaAccessEmailValid(isValidEmail(e.target.value));
  };

  // eslint-disable-next-line
  const requestBetaAccessHandler = async () => {
    try {
      const requestBody = {
        email: requestBetaAccessEmail,
        accessFor: 'MOBILE_APP',
      };
      await requestBetaAccessRequest(requestBody);

      setSuccessMessage(
        `Congrats! You’ll be the first to know when our Beta is live!`
      );
      setRequestBetaAccessEmail('');
      setTimeout(() => {
        setSuccessMessage(undefined);
      }, 6000);
    } catch (err) {
      setErrorMessage(err);
      setTimeout(() => {
        setErrorMessage(undefined);
      }, 6000);
    }
  };

  const requestBetaAccessEmailInputStyle = {};
  if (requestBetaAccessEmailTouched && !requestBetaAccessEmailValid) {
    requestBetaAccessEmailInputStyle['border'] = '1px solid red';
  }

  return (
    <div style={{ background: 'white' }}>
      <MetaTags>
        <title>Glimpse | NFT</title>
        <meta property="og:title" content={`Glimpse | NFT`} />
        <meta property="og:image" content={GlimpseLogo} />
      </MetaTags>

      <div className={classes['landing-container']}>
        <img
          alt="Landing"
          className={classes['landing-first-section-image']}
          src={
            'https://bucket-locally.s3.us-east-2.amazonaws.com/landing-1.png'
          }
        />
        <header className={classes['header']}>
          <img
            className={classes['glimpse-logo-icon']}
            src={GlimpseLogo}
            alt="Glimpse"
          />
        </header>

        <img
          src={'https://bucket-locally.s3.us-east-2.amazonaws.com/Phone.png'}
          alt="Mobile"
          className={classes['mobile-app-image']}
        />

        <div className={classes['actions-container']}>
          <Link to={profile?._id ? '/news-feed' : '/login'}>
            <Button pinkcolorwhitebackground="true">
              {profile?._id ? 'Launch App' : 'Login'}
            </Button>
          </Link>
          <Link to={'/apply-as-creator'}>
            <Button style={{ marginLeft: 8 }} pinkcolorwhitebackground="true">
              Apply As A Creator
            </Button>
          </Link>
        </div>

        <div className={classes['create-share-sell-container']}>
          <h1 className={classes['create-share-sell-text']}>
            Create. Monetize. Reward.
          </h1>
          <p className={classes['create-nfts-text']}>
            Monetize your top social content and reward your biggest fans in
            your exclusive NFT based community.
          </p>

          <h3 className={classes['request-beta-access-text']}>
            Mobile App is coming soon!
          </h3>

          {/* <input
            style={requestBetaAccessEmailInputStyle}
            value={requestBetaAccessEmail}
            onChange={requestBetaAccessEmailChangeHandler}
            className={classes['email-container']}
            contentEditable="true"
            placeholder="Email"
          />
          <Button
            onClick={requestBetaAccessHandler}
            style={{ marginLeft: '-95px', padding: '.5rem 1.5rem' }}
            pinkcolorwhitebackground="true"
          >
            Send
          </Button> */}

          {successMessage && (
            <p className={classes['success-request']}>{successMessage}</p>
          )}

          {errorMessage && (
            <p className={classes['error-request']}>{errorMessage}</p>
          )}
        </div>
      </div>

      <div className={classes['empty-section']}></div>

      {/* <div className={classes['second-section']}>
        // <img
        //   className={classes['social-media-image']}
        //   src={
        //     'https://bucket-locally.s3.us-east-2.amazonaws.com/social-media-meets-nfts.png'
        //   }
        // />

        // <h2 className={classes['social-media-text']}>
        //   Social Media Meets NFT’s
        // </h2>

        // <p className={classes['social-media-description']}>
        //   Glimpse leverges the digital scarcity of NFT’s to allow both creators
        //   and fans to make money through digital connections
        // </p>

        // <div className={classes['features-grid']}>
        //   <div className={classes['feature-container']}>
        //     <div className={classes['question-container']}>
        //       <h2 className={classes['feature-point']}>1</h2>
        //     </div>

        //     <p>No Crypto wallet needed</p>
        //   </div>

        //   <div className={classes['feature-container']}>
        //     <div className={classes['question-container']}>
        //       <h2 className={classes['feature-point']}>2</h2>
        //     </div>

        //     <p>Earn points and receive additonal rewards</p>
        //   </div>

        //   <div className={classes['feature-container']}>
        //     <div className={classes['question-container']}>
        //       <h2 className={classes['feature-point']}>3</h2>
        //     </div>

        //     <p>Send and Receive GLMS at any time</p>
        //   </div>

        //   <div className={classes['feature-container']}>
        //     <div className={classes['question-container']}>
        //       <h2 className={classes['feature-point']}>4</h2>
        //     </div>

        //     <p>No NFT Experience necessary</p>
        //   </div>
        // </div>
      </div> */}

      <div className={classes['third-section']}>
        <img
          className={classes['third-section-image']}
          src={
            'https://bucket-locally.s3.us-east-2.amazonaws.com/landing-2.png'
          }
          alt="Platform"
        />

        <h2 className={classes['make-money-text']}>
          Break free of creator funds and one off ad deals, take control of your
          brand and build a recurring revenue stream.{' '}
        </h2>
        <p className={classes['apps-description']}>
          You're the star! So why make money for platforms like Insta & TikTok?
          Glimpse allows you to directly monetize your content and create a
          recurring revenue stream that grows with your fanbase!
        </p>

        <img
          alt="Money"
          src={MoneyCreditCardImage}
          className={classes['money-credit-card-image']}
        />
      </div>

      <div className={classes['apply-as-creator-container']}>
        <img
          className={classes['creator-landing']}
          src={
            'https://bucket-locally.s3.us-east-2.amazonaws.com/landing-3.png'
          }
          alt="Apply As Creator"
        />
        <img
          alt="Content Creator"
          className={classes['content-creator-image']}
          src={ContentCreatorImage}
        />

        <div className={classes['apply-text-container']}>
          <h1 className={classes['apply-as-creator-text']}>
            Apply As A Creator
          </h1>
          <p className={classes['join-text']}>
            Join our growing community of creators from all walks of life and
            all forms of creative expression - from video creation sports,
            cooking, activisim, music, and more.
          </p>

          <Link to="apply-as-creator">
            <div className={classes['creator-application-container']}>
              <p className={classes['creator-application-text']}>
                Creator Application
              </p>
              <img alt="Right" src={RightPinkIcon} />
            </div>
          </Link>
        </div>
      </div>

      <footer className={classes['footer']}>
        <img
          alt="Footer"
          className={classes['footer-image']}
          src={
            'https://bucket-locally.s3.us-east-2.amazonaws.com/footer-landing.png'
          }
        />

        <img
          alt="Glimpse"
          src={GlimpseLogo}
          className={classes['glimpse-logo-footer']}
        />

        <div className={classes['glimpse-description-container']}>
          <p className={classes['glimpse-description']}>
            Glimpse is a decentralized social media platform that enables
            content creators and influencers to connect and engage with their
            fan base using NFT's.
          </p>
        </div>

        <div className={classes['join-the-community-container']}>
          <h2 className={classes['join-the-community-text']}>
            Join the Community
          </h2>
          <div className={classes['socials-container']}>
            <a
              rel="noreferrer"
              href={`https://t.me/glimpsetoken`}
              target={'_blank'}
            >
              <img alt="Telegram" src={Telegram} height={35} />
            </a>

            <a
              rel="noreferrer"
              href={`https://twitter.com/glimpse_nft`}
              target={'_blank'}
            >
              <img alt="Twitter" src={Twitter} height={35} />
            </a>

            <a
              rel="noreferrer"
              href={`https://www.linkedin.com/company/glimpsenft`}
              target={'_blank'}
            >
              <img alt="Linkedin" src={Linkedin} height={35} />
            </a>
          </div>
          <p className={classes['all-rights-reserved']}>
            Glimpse | NFT {new Date().getFullYear()} All Rights Reserved
          </p>

          <div className={classes['terms-of-use-privacy-policy-container']}>
            <Link to="/terms" target={'_blank'}>
              <p className={classes['terms-of-use-text']}>Terms Of Use</p>
            </Link>
            <Link to="/privacy-policy" target={'_blank'}>
              <p className={classes['privacy-policy-text']}>Privacy Policy</p>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
