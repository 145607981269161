import React, { useEffect, useMemo, useState } from 'react';
import classes from './RequestedNfts.module.css';
import { useInfiniteQuery } from 'react-query';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import {
  getMyRequestedNfts,
  getRequestedNftsFromMeRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import GoBack from '../../components/GoBack/GoBack';
import Input from '../../components/Input/Input';
import SearchIcon from '../../assets/search-icon.png';
import RequestedNftForMe from './components/RequestedNftForMe/RequestedNftForMe';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import UploadRequestedNft from './components/UploadRequestedNft/UploadRequestedNft';
import RequestedNftFromMe from './components/RequestedNftFromMe/RequestedNftFromMe';
import MetaTags from 'react-meta-tags';
import GoBackIcon from '../../assets/go-back-icon-2.png';
import { useHistory, useLocation } from 'react-router-dom';

const RESULTS_PER_PAGE = 12;

const REQUESTED_NFTS_SECTIONS = ['Received', 'Sent'];

function RequestedNfts() {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [selectedRequestedNft, setSelectedRequestedNft] = useState();
  const [selectedSection, setSelectedSection] = useState('Received');

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const tabParam = queryParams.get('tab');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-requested-nfts', searchValue, selectedSection],
    ({ pageParam = 1 }) =>
      selectedSection === 'Received'
        ? getMyRequestedNfts(pageParam, RESULTS_PER_PAGE, searchValue)
        : getRequestedNftsFromMeRequest(
            pageParam,
            RESULTS_PER_PAGE,
            searchValue
          ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    if (REQUESTED_NFTS_SECTIONS.includes(tabParam)) {
      setSelectedSection(tabParam);
    }
  }, [tabParam]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <MetaTags>
        <title>Requested Collectibles | Glimpse</title>
        <meta
          property="og:title"
          content={`Requested Collectibles | Glimpse`}
        />
      </MetaTags>

      <div className={classes['nft-requests-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        {!selectedRequestedNft && (
          <div className={classes['requested-nfts-container']}>
            <header className={classes['requested-nfts-header']}>
              <div className={classes['go-back-container']}>
                <div onClick={() => history.goBack()}>
                  <img
                    src={GoBackIcon}
                    alt="Go Back"
                    className={classes['go-back-icon']}
                  />
                </div>
                <h3>Requested Collectibles</h3>
              </div>

              <div className={classes['requested-nfts-options-container']}>
                {REQUESTED_NFTS_SECTIONS.map((option) => (
                  <div
                    key={option}
                    onClick={() => setSelectedSection(option)}
                    className={classes['requested-nfts-option-container']}
                  >
                    <p
                      className={`${classes['requested-nfts-option-text']} ${
                        option === selectedSection &&
                        classes['requested-nfts-option-text-selected']
                      }`}
                    >
                      {option}
                    </p>
                  </div>
                ))}
              </div>
            </header>

            <div className={classes['input-container']}>
              <Input
                style={{
                  width: '100%',
                  marginTop: '1rem',
                  background: 'white',
                }}
                value={searchValue}
                onChange={searchValueChangeHandler}
                placeholder={'Search here'}
              />
              <img
                alt="Search"
                className={classes['search-icon']}
                src={SearchIcon}
                width={18}
              />
            </div>

            {!isLoading && data?.pages && data.pages[0].results === 0 && (
              <p className={classes['no-results']}>No Results Found!</p>
            )}

            {selectedSection === 'Received' && (
              <div className={classes['requested-nfts-list']}>
                {!isLoading &&
                  data?.pages &&
                  data.pages.map((page) => {
                    return page.data.map((requestedNft) => {
                      const { _id, from, status, createdAt } = requestedNft;

                      return (
                        <RequestedNftForMe
                          setSelectedRequestedNft={() =>
                            setSelectedRequestedNft(requestedNft)
                          }
                          createdAt={createdAt}
                          key={_id}
                          from={from}
                          status={status}
                          _id={_id}
                        />
                      );
                    });
                  })}
              </div>
            )}

            {selectedSection === 'Sent' && (
              <div className={classes['requested-nfts-list']}>
                {!isLoading &&
                  data?.pages &&
                  data.pages.map((page) => {
                    return page.data.map((requestedNft) => {
                      const {
                        _id,
                        requestFor,
                        status,
                        createdAt,
                      } = requestedNft;
                      return (
                        <RequestedNftFromMe
                          setSelectedRequestedNft={() =>
                            setSelectedRequestedNft(requestedNft)
                          }
                          createdAt={createdAt}
                          key={_id}
                          requestFor={requestFor}
                          status={status}
                          _id={_id}
                        />
                      );
                    });
                  })}
              </div>
            )}

            {(isLoading || isFetching) && <LoadingSpinner />}
          </div>
        )}

        {selectedRequestedNft && (
          <div className={classes['upload-requested-nft-container']}>
            <div className={classes['go-back-container']}>
              <GoBack
                goBackHandler={() => setSelectedRequestedNft(undefined)}
              />
              <h3 className={classes['requested-nfts-text']}>
                Requested Collectibles /{' '}
                <span className={classes['from-text']}>
                  {selectedSection === 'Sent'
                    ? selectedRequestedNft.requestFor.fullName
                    : selectedRequestedNft.from.fullName}
                </span>
              </h3>
            </div>

            <UploadRequestedNft
              selectedSection={selectedSection}
              fromMe={selectedSection === 'Sent'}
              setSelectedRequestedNft={setSelectedRequestedNft}
              selectedRequestedNft={selectedRequestedNft}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default RequestedNfts;
