import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getCryptoCurrencyPriceConversionRequest } from '../httpRequests/httpRequests';
import notify from '../utils/notify';

const coinMarketCapContext = {
  glmsToUsd: 0,
  refetchGLMSPrice: () => {},
};

export const CoinMarketCapContext = React.createContext(coinMarketCapContext);

const CoinMarketCapProvider = (props) => {
  const queryClient = useQueryClient();
  const { data, error } = useQuery(
    'glms-to-usd',
    getCryptoCurrencyPriceConversionRequest,
    {
      refetchInterval: 300000,
    }
  );
  const glmsToUsd = data ? data.data : 0;

  const refetchGLMSPrice = () => {
    queryClient.invalidateQueries('glms-to-usd');
    queryClient.refetchQueries('glms-to-usd');
  };

  error && notify('error', error, 3000);

  return (
    <CoinMarketCapContext.Provider
      value={{
        glmsToUsd,
        refetchGLMSPrice,
      }}
    >
      {props.children}
    </CoinMarketCapContext.Provider>
  );
};

export default CoinMarketCapProvider;
