import React, { useState } from 'react';

const newsFeedContext = {
  isFeaturedChecked: true,
  isAllChecked: false,
  isCollectorsChecked: false,
  showChangePasswordModal: false,
  featuredClickHandler: () => {},
  allClickHandler: () => {},
  collectorsClickHandler: () => {},
  setShowChangePasswordModal: (_show) => {},
};

export const NewsFeedContext = React.createContext(newsFeedContext);

const NewsFeedProvider = (props) => {
  const [isFeaturedChecked, setIsFeaturedChecked] = useState(true);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isCollectorsChecked, setIsCollectorsChecked] = useState(false);

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const featuredClickHandler = () => {
    setIsFeaturedChecked(true);
    setIsAllChecked(false);
    setIsCollectorsChecked(false);
  };

  const allClickHandler = () => {
    setIsFeaturedChecked(false);
    setIsCollectorsChecked(false);
    setIsAllChecked(true);
  };

  const collectorsClickHandler = () => {
    setIsCollectorsChecked(true);
    setIsFeaturedChecked(false);
    setIsAllChecked(false);
  };

  return (
    <NewsFeedContext.Provider
      value={{
        isFeaturedChecked,
        isAllChecked,
        isCollectorsChecked,
        featuredClickHandler,
        allClickHandler,
        collectorsClickHandler,

        showChangePasswordModal,
        setShowChangePasswordModal,
      }}
    >
      {props.children}
    </NewsFeedContext.Provider>
  );
};

export default NewsFeedProvider;
