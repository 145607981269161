import React, { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getUserNftsStatisticsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import NftsStatisticsTable from './components/NftsStatisticsTable.js/NftsStatisticsTable';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';

const RESULTS_PER_PAGE = 10;

function UserNftsStatistics() {
  const [searchNftInputValue, setSearchNftInputValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['user-nfts-statistics', searchNftInputValue],
    ({ pageParam = 1 }) =>
      getUserNftsStatisticsRequest(
        pageParam,
        searchNftInputValue,
        RESULTS_PER_PAGE
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const userNftsStatistics = useMemo(() => {
    if (!data) return undefined;

    const nftsStatistics = data.pages.map((page) => {
      return page.data.map((el) => {
        console.log({ el });
        return {
          owner: el.owner,
          listedForBuyNow: el.listedForBuyNow,
          notListedForBuyNow: el.notListedForBuyNow,
          totalNfts: el.totalNfts,
        };
      });
    });

    return nftsStatistics;
  }, [data]);

  useEffect(() => {
    error && notify('error', error, 3000);
  });

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <NftsStatisticsTable
        results={data?.pages[0].results}
        isLoading={isLoading}
        isFetching={isFetching}
        RESULTS_PER_PAGE={RESULTS_PER_PAGE}
        userNftsStatistics={userNftsStatistics}
        searchNftInputValue={searchNftInputValue}
        setSearchNftInputValue={setSearchNftInputValue}
      />
    </div>
  );
}

export default UserNftsStatistics;
