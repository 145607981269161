import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../../../context/auth-context';
import { SocketContext } from '../../../../context/socket-context';
import classes from './SelectedChat.module.css';
import OnlineIcon from '../../../../assets/online.png';
import OfflineIcon from '../../../../assets/dot.png';
import ChatInfoIcon from '../../../../assets/chat-info.png';
import MoreIcon from '../../../../assets/more.png';
import Messages from '../Messages/Messages';
import { Link, useHistory } from 'react-router-dom';
import { limitString } from '../../../../helpers/helpers';
import ChatInfoModal from '../ChatInfoModal/ChatInfoModal';
import {
  deleteChatRequest,
  leaveChatRequest,
  markChatAsFavoriteRequest,
  removeChatAsFavoriteRequest,
} from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import notify from '../../../../utils/notify';

function SelectedChat(props) {
  const queryClient = useQueryClient();
  const moreIconRef = useRef(null);
  const history = useHistory();
  const { profile } = useContext(AuthContext);
  const { onlineUsers } = useContext(SocketContext);
  const {
    selectedChat,
    selectedChatGroupId,
    setSelectedChatGroup,
    setSelectedChatGroupId,
    requestedChat,
    isFavorite,
  } = props;

  const [showChatOptions, setShowChatOptions] = useState(false);
  const [showChatInfoModal, setShowChatInfoModal] = useState(false);

  const otherUserIfSingleChat = selectedChat.users.find(
    (user) => user._id !== profile?._id
  );

  useEffect(() => {
    const checkForClosingChatOptions = (e) => {
      if (e.target !== moreIconRef.current) {
        setShowChatOptions(false);
      }
    };

    window.addEventListener('click', checkForClosingChatOptions);

    return () =>
      window.removeEventListener('click', checkForClosingChatOptions);
  }, []);

  const viewProfileHandler = () => {
    history.push(`/user-feed/${otherUserIfSingleChat._id}`);
  };

  const leaveChatHandler = async () => {
    try {
      setSelectedChatGroup(undefined);
      setSelectedChatGroupId(undefined);
      await leaveChatRequest(selectedChat._id);
      queryClient.invalidateQueries('my-chats');
      queryClient.refetchQueries('my-chats');
    } catch (error) {
      notify('error', error, 3000);
    }
  };

  const deleteChatHandler = async () => {
    setSelectedChatGroup(undefined);
    setSelectedChatGroupId(undefined);
    await deleteChatRequest(selectedChat._id);
    queryClient.invalidateQueries('my-chats');
    queryClient.refetchQueries('my-chats');
  };

  const openChatInfoModalHandler = () => {
    setShowChatInfoModal(true);
  };

  const markChatAsFavoriteHandler = async () => {
    try {
      await markChatAsFavoriteRequest(selectedChat._id);
      queryClient.invalidateQueries('my-chats');
      queryClient.refetchQueries('my-chats');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const removeChatAsFavoriteHandler = async () => {
    try {
      await removeChatAsFavoriteRequest(selectedChat._id);
      queryClient.invalidateQueries('my-chats');
      queryClient.refetchQueries('my-chats');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderGroupChatHeader = () => {
    const profileImages = selectedChat.users.map((user) => user.photo);

    return (
      <div className={classes['header']}>
        <div className={classes['user-information']}>
          <div className={classes['profile-images-container']}>
            {profileImages.slice(0, 4).map((image, i) => {
              return (
                <img
                  key={i}
                  alt="ProfileImage"
                  className={classes['profile-image']}
                  src={image}
                  width={30}
                  height={30}
                />
              );
            })}
          </div>

          <div>
            <h3 className={classes['other-user-fullName']}>
              {limitString(selectedChat.name, 30)}
            </h3>
          </div>
        </div>

        <div className={classes['options']}>
          <img
            alt="Info"
            onClick={openChatInfoModalHandler}
            src={ChatInfoIcon}
            width={18}
            height={18}
          />

          <img
            alt="More"
            height={20}
            ref={moreIconRef}
            onClick={() => setShowChatOptions((prevState) => !prevState)}
            src={MoreIcon}
            className={classes['more-icon']}
          />

          {showChatOptions && (
            <div className={classes['chat-options']}>
              {isFavorite ? (
                <p
                  className={classes['delete']}
                  onClick={removeChatAsFavoriteHandler}
                >
                  Remove as Favorite
                </p>
              ) : (
                <p
                  className={classes['option']}
                  onClick={markChatAsFavoriteHandler}
                >
                  Mark as Favorite
                </p>
              )}

              <p onClick={leaveChatHandler} className={classes['delete']}>
                Leave Chat
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderSingleChatHeader = () => {
    return (
      <div className={classes['header']}>
        <div className={classes['user-information']}>
          <Link to={`/user-feed/${otherUserIfSingleChat._id}`}>
            <img
              alt={otherUserIfSingleChat.fullName}
              src={otherUserIfSingleChat.photo}
              width={40}
              height={40}
              className={classes['user-image']}
            />
          </Link>

          <div>
            <h3 className={classes['other-user-fullName']}>
              {otherUserIfSingleChat.fullName}
            </h3>
            {onlineUsers[otherUserIfSingleChat._id] ? (
              <p className={classes['online']}>
                <img
                  alt="Online"
                  className={classes['online-icon']}
                  src={OnlineIcon}
                  width={16}
                  height={16}
                />
                Active
              </p>
            ) : (
              <p className={classes['offline']}>
                <img
                  alt="Offline"
                  className={classes['offline-icon']}
                  src={OfflineIcon}
                  width={16}
                  height={16}
                />
                Offline
              </p>
            )}
          </div>
        </div>

        <div className={classes['options']}>
          <img
            alt="More"
            ref={moreIconRef}
            onClick={() => setShowChatOptions((prevState) => !prevState)}
            src={MoreIcon}
            height={20}
            className={classes['more-icon']}
          />

          {showChatOptions && (
            <div className={classes['chat-options']}>
              {isFavorite ? (
                <p
                  className={classes['delete']}
                  onClick={removeChatAsFavoriteHandler}
                >
                  Remove as Favorite
                </p>
              ) : (
                <p
                  className={classes['option']}
                  onClick={markChatAsFavoriteHandler}
                >
                  Mark as Favorite
                </p>
              )}

              <p
                onClick={viewProfileHandler}
                className={classes['view-profile']}
              >
                View Profile
              </p>
              <p onClick={deleteChatHandler} className={classes['delete']}>
                Delete
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {showChatInfoModal && (
        <ChatInfoModal
          setSelectedChat={setSelectedChatGroup}
          selectedChat={selectedChat}
          setShowChatInfoModal={setShowChatInfoModal}
        />
      )}

      <div className={classes['selected-chat-container']}>
        {selectedChat.isGroupChat
          ? renderGroupChatHeader()
          : renderSingleChatHeader()}

        <div className={classes['horizontal-line']}></div>

        {selectedChatGroupId && (
          <Messages
            setSelectedChat={setSelectedChatGroup}
            setSelectedChatGroupId={setSelectedChatGroupId}
            requestedChat={requestedChat}
            selectedChatGroupId={selectedChatGroupId}
            selectedChatGroup={selectedChat}
          />
        )}
      </div>
    </>
  );
}

export default SelectedChat;
