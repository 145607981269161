import React, { useContext, useEffect, useState } from 'react';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ArrowRightIcon from '../../assets/black-arrow-right.png';
import classes from './SettingsModal.module.css';
import ChangePasswordModal from './components/ChangePasswordModal/ChangePasswordModal';
import PersonalDetailsModal from './components/PersonalDetailsModal/PersonalDetailsModal';
import SetRequestNftPriceModal from './components/SetRequestNftPriceModal/SetRequestNftPriceModal';
import VerifyPhoneNumberModal from './components/VerifyPhoneNumberModal/VerifyPhoneNumberModal';
import GreenOkIcon from '../../assets/green-ok.png';
import { AuthContext } from '../../context/auth-context';
import SliderButton from '../SliderButton/SliderButton';
import { updateMyMetaMaskNftsViewStatusRequest } from '../../httpRequests/httpRequests';
import NotListedNftsAccessStatusModal from './components/NotListedNftsAccessStatusModal/NotListedNftsAccessStatusModal';

function SettingsModal(props) {
  const { profile, updateProfileInformation } = useContext(AuthContext);
  const { setShowSettingsModal } = props;
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showPersonalDetailsModal, setShowPersonalDetailsModal] = useState(
    false
  );
  const [
    showSetRequestNftPriceModal,
    setShowSetRequestNftPriceModal,
  ] = useState(false);
  const [showVerifyPhoneNumberModal, setShowVerifyPhoneNumberModal] = useState(
    false
  );
  const [showMetaMaskNftsToOthers, setShowMetaMaskNftsToOthers] = useState(
    profile?.showMetaMaskNftsToOthers
  );

  const [
    showNotListedNFTsAccessStatusModal,
    setShowNotListedNFTsAccessStatusModal,
  ] = useState(false);

  useEffect(() => {
    const requestBody = {
      enabled: showMetaMaskNftsToOthers,
    };

    updateMyMetaMaskNftsViewStatusRequest(requestBody).then(() => {
      updateProfileInformation({ ...profile, showMetaMaskNftsToOthers });
    });
    // eslint-disable-next-line
  }, [showMetaMaskNftsToOthers]);

  const closeModalHandler = () => {
    setShowSettingsModal(false);
  };

  const openChangePasswordModalHandler = () => {
    setShowChangePasswordModal(true);
  };

  const openPersonalDetailsModalHandler = () => {
    setShowPersonalDetailsModal(true);
  };

  const openSetRequestNftPriceModalHandler = () => {
    setShowSetRequestNftPriceModal(true);
  };

  const openVerifyPhoneNumberModalHandler = () => {
    setShowVerifyPhoneNumberModal(true);
  };

  const openNotListedNFTsAccessStatusModalHandler = () => {
    setShowNotListedNFTsAccessStatusModal(true);
  };

  return (
    <>
      {showChangePasswordModal && (
        <ChangePasswordModal
          setShowSettingsModal={setShowSettingsModal}
          setShowChangePasswordModal={setShowChangePasswordModal}
        />
      )}

      {showPersonalDetailsModal && (
        <PersonalDetailsModal
          setShowPersonalDetailsModal={setShowPersonalDetailsModal}
          setShowSettingsModal={setShowSettingsModal}
        />
      )}

      {showSetRequestNftPriceModal && (
        <SetRequestNftPriceModal
          setShowSetRequestNftPriceModal={setShowSetRequestNftPriceModal}
        />
      )}

      {showVerifyPhoneNumberModal && (
        <VerifyPhoneNumberModal
          setShowVerifyPhoneNumberModal={setShowVerifyPhoneNumberModal}
        />
      )}

      {showNotListedNFTsAccessStatusModal && (
        <NotListedNftsAccessStatusModal
          setShowNotListedNFTsAccessStatusModal={
            setShowNotListedNFTsAccessStatusModal
          }
        />
      )}

      {!showChangePasswordModal &&
        !showPersonalDetailsModal &&
        !showSetRequestNftPriceModal &&
        !showVerifyPhoneNumberModal &&
        !showNotListedNFTsAccessStatusModal && (
          <Modal
            footer={
              <>
                <Button
                  style={{ width: '100%' }}
                  onClick={closeModalHandler}
                  darkpinkcolor="true"
                >
                  Close
                </Button>
              </>
            }
            closeModal={closeModalHandler}
            title="Settings"
          >
            <div
              onClick={openPersonalDetailsModalHandler}
              className={classes['personal-details-container']}
            >
              <p className={classes['text']}>Personal Details</p>
              <img
                alt="Arrow Right"
                style={{ cursor: 'pointer' }}
                src={ArrowRightIcon}
              />
            </div>

            <div className={classes['horizontal-line']}></div>

            <div
              onClick={openChangePasswordModalHandler}
              className={classes['change-password-container']}
            >
              <p className={classes['text']}>Change Password</p>
              <img
                alt="Arrow Right"
                style={{ cursor: 'pointer' }}
                src={ArrowRightIcon}
              />
            </div>

            <div className={classes['horizontal-line']}></div>

            <div
              onClick={openSetRequestNftPriceModalHandler}
              className={classes['request-nft-container']}
            >
              <p className={classes['text']}>Request Collectible Price</p>
              <img
                alt="Arrow Right"
                style={{ cursor: 'pointer' }}
                src={ArrowRightIcon}
              />
            </div>

            <div className={classes['horizontal-line']}></div>

            <div
              onClick={
                !profile.phoneNumberVerified
                  ? openVerifyPhoneNumberModalHandler
                  : undefined
              }
              className={classes['request-nft-container']}
            >
              <p className={classes['text']}>Verify Phone Number</p>
              <img
                alt="Arrow Right"
                style={{ cursor: 'pointer' }}
                src={profile.phoneNumberVerified ? GreenOkIcon : ArrowRightIcon}
              />
            </div>

            <div className={classes['horizontal-line']}></div>

            <div
              onClick={openNotListedNFTsAccessStatusModalHandler}
              className={classes['request-nft-container']}
            >
              <p className={classes['text']}>Not Listed NFTs Access</p>
              <img
                alt="Arrow Right"
                style={{ cursor: 'pointer' }}
                src={ArrowRightIcon}
              />
            </div>

            <div className={classes['horizontal-line']}></div>

            <div className={classes['metamask-nfts-container']}>
              <p className={classes['text']}>MetaMask NFTs</p>
              <SliderButton
                leftValue={<p className={classes['slider-text']}>Private</p>}
                rightValue={<p className={classes['slider-text']}>Public</p>}
                isLeftContentSelected={!showMetaMaskNftsToOthers}
                setIsLeftContentSelected={setShowMetaMaskNftsToOthers}
              />
            </div>
          </Modal>
        )}
    </>
  );
}

export default SettingsModal;
