import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getMyGroupsAsAdminRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import GroupPresentationImage from '../../../../../../assets/group-presentation-image.png';
import { getDateFrom, limitString } from '../../../../../../helpers/helpers';
import classes from './GroupsAsAdmin.module.css';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';

function GroupsAsAdmin(props) {
  const { searchValue } = props;

  const { isLoading, data, error } = useQuery(
    ['my-groups-as-admin', searchValue],
    () => getMyGroupsAsAdminRequest(searchValue)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div
      style={props.style ? props.style : {}}
      className={classes['groups-as-admin-container']}
    >
      <h4 className={classes['groups-you-manage-text']}>Groups you manage</h4>

      <div className={classes['groups-list']}>
        {!isLoading && data?.data && data.data.length === 0 && (
          <p className={classes['no-groups-found']}>No Groups Found</p>
        )}
        {!isLoading &&
          data?.data &&
          data.data.map(({ group }) => {
            return (
              <Link
                to={`/my-groups?id=${group._id}`}
                key={group._id}
                className={`${classes['group']}`}
              >
                {group.coverPhoto ? (
                  <img
                    className={classes['cover-photo']}
                    src={group.coverPhoto}
                    alt="Group Cover"
                  />
                ) : (
                  <img
                    className={classes['cover-photo']}
                    src={GroupPresentationImage}
                    alt={'Group Cover'}
                  />
                )}
                <div>
                  <p title={group.name} className={classes['group-name']}>
                    {limitString(group.name, 12)}
                  </p>
                  <p className={classes['last-active']}>
                    {group.lastActive
                      ? `Last active ${
                          getDateFrom(group.lastActive) === 'now'
                            ? 'some seconds ago'
                            : `${getDateFrom(group.lastActive)} ago`
                        }`
                      : `Created ${
                          getDateFrom(group.createdAt) === 'now'
                            ? 'some seconds ago'
                            : `${getDateFrom(group.createdAt)} ago`
                        }`}
                  </p>
                </div>
              </Link>
            );
          })}

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default GroupsAsAdmin;
