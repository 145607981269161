import React, { useEffect, useState } from 'react';
import classes from './Photos.module.css';
import notify from '../../../../utils/notify';
import { useQuery } from 'react-query';
import { getUserUploadedPhotosRequest } from '../../../../httpRequests/httpRequests';
import ImageModal from '../../../../components/ImageModal/ImageModal';

function Photos(props) {
  const { userId } = props;
  const { data, error } = useQuery(['user-photos', userId], () =>
    getUserUploadedPhotosRequest(userId)
  );
  const [photoModalState, setPhotoModalState] = useState({
    opened: false,
    photo: null,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <>
      {photoModalState.opened && (
        <ImageModal
          title="User Photo"
          setShowImageModal={(opened) =>
            setPhotoModalState({
              opened,
              photo: null,
            })
          }
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={photoModalState.photo}
            alt="UserPhoto"
          />
        </ImageModal>
      )}

      <div className={classes['photos-page-container']}>
        <div className={classes['photos-container']}>
          {data && data.results === 0 && (
            <div
              style={{
                background: 'white',
                padding: '.8rem',
                borderRadius: 10,
                gridColumn: '1/-1',
              }}
            >
              <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
                No Photos Found!
              </p>
            </div>
          )}
          {data &&
            data.data.map(({ photo }, i) => (
              <div key={i} className={classes['photo-container']}>
                <img
                  onClick={() =>
                    setPhotoModalState({
                      opened: true,
                      photo,
                    })
                  }
                  style={{ cursor: 'pointer' }}
                  alt="MyPhoto"
                  className={classes['photo']}
                  src={photo}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Photos;
