import React, { useContext, useState, useEffect } from 'react';
import classes from './FirstScreen.module.css';
import { AuthContext } from '../../../../../../context/auth-context';
import Input from '../../../../../../components/Input/Input';
import TextArea from '../../../../../../components/TextArea/TextArea';
import { limitString } from '../../../../../../helpers/helpers';
import PinkRemoveIcon from '../../../../../../assets/pink-remove.png';
import Button from '../../../../../../components/Button/Button';

const trendingCategories = [
  'Art',
  'Collectibles',
  'Photography',
  'Sports',
  'Trading Cards',
  'Music',
  'Profile Picture',
  'Animals',
  'Nature',
  'Painting',
  '3D Abstract',
  'AI',
  'Meme',
  'Video',
];

function FirstScreen(props) {
  const { profile } = useContext(AuthContext);
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const [categoryInputValueTouched, setCategoryInputValueTouched] = useState(
    ''
  );
  const {
    collectionName,
    setCollectionName,
    categories,
    setCategories,
    description,
    setDescription,
    collectionNameTouched,
    setCollectionNameTouched,
    descriptionTouched,
    setDescriptionTouched,
    setScreenNumber,
  } = props;

  useEffect(() => {
    const addCategoryInputValueToCategoriesOnEnterClick = (e) => {
      if (e.key === 'Enter' && categoryInputValue.trim().length > 0) {
        setCategories((prevState) => [...prevState, categoryInputValue]);
        setCategoryInputValue('');
      }
    };

    document.addEventListener(
      'keyup',
      addCategoryInputValueToCategoriesOnEnterClick
    );

    return () =>
      document.removeEventListener(
        'keyup',
        addCategoryInputValueToCategoriesOnEnterClick
      );
  }, [categoryInputValue, setCategories]);

  const collectionNameChangeHandler = (e) => {
    setCollectionName(e.target.value);
    setCollectionNameTouched(true);
  };

  const descriptionChangeHandler = (e) => {
    if (e.target.value.length > 300) return;

    setDescription(e.target.value);
    setDescriptionTouched(true);
  };

  const categoryChangeHandler = (e) => {
    setCategoryInputValue(e.target.value.trim());
    setCategoryInputValueTouched(true);
    if (
      e.target.value.indexOf(' ') !== -1 &&
      e.target.value.trim().length >= 1
    ) {
      setCategories((prevState) => [...prevState, categoryInputValue]);
      setCategoryInputValue('');
    }
  };

  const removeCategoryHandler = (index) => {
    setCategories((prevState) => prevState.filter((_, i) => i !== index));
  };

  const goToNextScreenHandler = () => {
    setScreenNumber((prevState) => prevState + 1);
  };

  const trendingCategoryClickHandler = (trendingCategory) => {
    if (categories.includes(trendingCategory)) {
      setCategories((prevState) =>
        prevState.filter((el) => el !== trendingCategory)
      );
    } else if (categories.length < 3) {
      setCategories((prevState) => [...prevState, trendingCategory]);
    }
  };

  const collectionNameInputStyle = { width: '100%' };
  if (collectionNameTouched && collectionName.length === 0) {
    collectionNameInputStyle['border'] = '1px solid red';
  }
  const categoriesInputStyle = { width: '100%' };
  if (
    categoryInputValue.length === 0 &&
    categories.length === 0 &&
    categoryInputValueTouched
  ) {
    categoriesInputStyle['border'] = '1px solid red';
  }

  const descriptionInputStyle = { minHeight: 90, width: '100%' };
  if (descriptionTouched && description.length < 10) {
    descriptionInputStyle['border'] = '1px solid red';
  }

  return (
    <div style={{ maxWidth: 500 }}>
      <p className={classes['collection-details-text']}>Collection Details</p>

      <div className={classes['user-profile-container']}>
        <img
          className={classes['user-image']}
          src={profile?.photo}
          alt="Creator"
        />
        <div>
          <p className={classes['fullName']}>{profile?.fullName}</p>
          <p className={classes['creator-text']}>Creator - Your Profile</p>
        </div>
      </div>

      <div className={classes['collection-info']}>
        <p className={classes['label']}>Collection Name</p>
        <Input
          value={collectionName}
          onChange={collectionNameChangeHandler}
          style={collectionNameInputStyle}
          placeholder="Enter here"
        />

        <p className={classes['label']}>
          Categories (Maximum 3 - Separate via Space/Enter)
        </p>

        <div className={classes['categories-input']}>
          {categories.map((category, i) => (
            <div
              title={`#${category}`}
              key={category + i}
              className={classes['category']}
            >
              <p
                style={{
                  whiteSpace: 'nowrap',
                  margin: 0,
                }}
              >
                #{limitString(category, 16)}
              </p>

              <img
                alt="Remove"
                className={classes['remove-icon']}
                onClick={() => removeCategoryHandler(i)}
                src={PinkRemoveIcon}
                width={8}
                height={9}
              />
            </div>
          ))}
          <Input
            style={{ width: '100%', ...categoriesInputStyle }}
            placeholder={categories.length === 3 ? '' : '#Enter here'}
            value={categoryInputValue}
            disabled={categories.length === 3}
            onChange={categoryChangeHandler}
          />
        </div>

        <div className={classes['trending-categories']}>
          {trendingCategories.map((trendingCategory, i) => {
            const containerStyle = {};
            if (categories.includes(trendingCategory)) {
              containerStyle['border'] = '1px solid #F900B7';
            }
            return (
              <div
                style={containerStyle}
                onClick={() => trendingCategoryClickHandler(trendingCategory)}
                className={classes['trending-category-container']}
                key={trendingCategory + i}
              >
                <p
                  style={{
                    color: categories.includes(trendingCategory) && '#F900B7',
                  }}
                  className={classes['trending-category-text']}
                >
                  {trendingCategory}
                </p>
              </div>
            );
          })}
        </div>

        <p className={classes['label']}>
          Description {description.length}/300 (Minimum: 10 characters)
        </p>
        <TextArea
          value={description}
          onChange={descriptionChangeHandler}
          style={descriptionInputStyle}
          placeholder="Enter here"
        />
      </div>

      {props.children}

      <Button
        darkpink="true"
        disabled={
          !collectionName || categories.length === 0 || description.length < 10
        }
        style={{ width: '100%' }}
        onClick={goToNextScreenHandler}
      >
        Next
      </Button>
    </div>
  );
}

export default FirstScreen;
