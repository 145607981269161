import React, { useState } from 'react';
import Button from '../../../../../../components/Button/Button';
import classes from './Albums.module.css';
import CreateAlbumModal from './components/CreateAlbumModal/CreateAlbumModal';
import UserImageAlbum from './components/UserImageAlbum/UserImageAlbum';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';

function Albums(props) {
  const {
    isLoading,
    setSelectedAlbumId,
    setShowAlbumPhotos,
    setPhotosOptions,
    setSelectedPhotoOption,
    data,
  } = props;

  const [showCreateAlbumModal, setShowCreateAlbumModal] = useState(false);

  const openCreateAlbumModalHandler = () => {
    setShowCreateAlbumModal(true);
  };

  return (
    <div style={{ padding: '1rem 0' }}>
      {showCreateAlbumModal && (
        <CreateAlbumModal setShowCreateAlbumModal={setShowCreateAlbumModal} />
      )}

      {data && data.data.length === 0 && (
        <div className={classes['no-albums-container']}>
          {' '}
          <p className={classes['no-albums-text']}>
            No Albums found. Start by creating a new one!
          </p>
        </div>
      )}

      {isLoading && <LoadingSpinner />}

      <div className={classes['albums-container']}>
        {data &&
          data.data.map((album) => {
            return (
              <UserImageAlbum
                setPhotosOptions={setPhotosOptions}
                setSelectedPhotoOption={setSelectedPhotoOption}
                setSelectedAlbumId={setSelectedAlbumId}
                setShowAlbumPhotos={setShowAlbumPhotos}
                key={album._id}
                album={album}
              />
            );
          })}
      </div>

      <div style={{ textAlign: 'right', marginTop: 10 }}>
        <Button
          style={{ width: 200 }}
          onClick={openCreateAlbumModalHandler}
          darkpink="true"
        >
          Create Album
        </Button>
      </div>
    </div>
  );
}

export default Albums;
