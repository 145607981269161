import React, { useState } from 'react';
import { Col, Row } from 'antd';
import SidebarMarketPlace from './SidebarMarketPlace';
import ViewNFTS from './ViewNFTS';

const ViewNFTsForCategories = () => {
  const [edit, setEdit] = useState(false);

  return (
    <div className="site-layout-content">
      <Row className="profile">
        <Col md={6}>
          <SidebarMarketPlace edit={edit} setEdit={setEdit} />
        </Col>
        <Col md={18}>
          <ViewNFTS />
        </Col>
      </Row>
    </div>
  );
};

export default ViewNFTsForCategories;
