import React, { useState } from 'react';
import NewsFeedContent from './components/NewsFeedContent/NewsFeedContent';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import classes from './NewsFeed.module.css';
import MetaTags from 'react-meta-tags';
// import MessagesSidebar from '../../components/MessagesSidebar/MessagesSidebar';
import { Helmet } from 'react-helmet';

function NewsFeed() {
  const [selectedTopic, setSelectedTopic] = useState();

  return (
    <>
      <MetaTags>
        <title>News Feed | Glimpse</title>
        <meta property="og:title" content={'News Feed | Glimpse'} />
      </MetaTags>

      <div className={classes['news-feed-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar selectedLink="home" />
        </div>
        <div className={classes['news-feed-content-container']}>
          <NewsFeedContent
            setSelectedTopic={setSelectedTopic}
            selectedTopic={selectedTopic}
          />
        </div>

        {/* <div className={classes['messages-sidebar-container']}>
          <MessagesSidebar />
        </div> */}
      </div>

      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-na1.hs-scripts.com/24222719.js"
        ></script>
      </Helmet>
    </>
  );
}

export default NewsFeed;
