import React from 'react';
import classes from './AlbumPhotos.module.css';
import { useQueryClient } from 'react-query';
import AlbumPhoto from './components/AlbumPhoto/AlbumPhoto';
import { useParams } from 'react-router-dom';
import GoBack from '../../../../../../components/GoBack/GoBack';

function AlbumPhotos(props) {
  const { userId } = useParams();
  const queryClient = useQueryClient();

  const { selectedAlbumId, setSelectedPhotoOption } = props;

  const { data } = queryClient.getQueryData(['user-image-albums', userId]);
  const selectedAlbum = data.find((el) => el._id === selectedAlbumId);

  if (!selectedAlbum)
    return <p>You have not selected an Album. Start by selecting one!</p>;

  return (
    <>
      <div className={classes['album-photos-container']}>
        <div className={classes['go-back-container']}>
          <GoBack goBackHandler={() => setSelectedPhotoOption('Albums')} />
          <h4 className={classes['albums-go-back-text']}>Albums</h4>
        </div>
        {selectedAlbum.images.length === 0 && (
          <div className={classes['no-images-container']}>
            {' '}
            <p className={classes['no-images-text']}>
              No Images on this Album. Try uploading the first one.
            </p>
          </div>
        )}

        <div className={classes['album-photos-grid']}>
          {selectedAlbum.images.map((image) => {
            return (
              <AlbumPhoto
                image={image}
                selectedAlbum={selectedAlbum}
                key={image + Math.random()}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default AlbumPhotos;
