import React, { useContext, useState } from 'react';
import classes from './BrandItemContent.module.css';
import MetaTags from 'react-meta-tags';
import ReactPlayer from 'react-player';
import { limitString } from '../../../../helpers/helpers';
import { AuthContext } from '../../../../context/auth-context';
import { Link } from 'react-router-dom';
import VerifiedIcon from '../../../../assets/verified.png';
import Button from '../../../../components/Button/Button';
import notify from '../../../../utils/notify';
import {
  createBrandItemCheckoutSessionRequest,
  unlistBrandItemForSaleRequest,
} from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import UpdateBrandItemPriceModal from '../UpdateBrandItemPriceModal/UpdateBrandItemPriceModal';
import ListBrandItemForSaleModal from '../ListBrandItemForSaleModal/ListBrandItemForSaleModal';

function BrandItemContent(props) {
  const queryClient = useQueryClient();
  const { brandItem } = props;
  const {
    title,
    description,
    previewUrl,
    type,
    categories,
    tags,
    owner,
    author,
    price,
    listedForSale,
    _id,
  } = brandItem;

  const { profile } = useContext(AuthContext);

  const [unlistingBrandItem, setUnlistingBrandItem] = useState(false);
  const [
    showUpdateBrandItemPriceModal,
    setShowUpdateBrandItemPriceModal,
  ] = useState(false);
  const [
    showListBrandItemForSaleModal,
    setShowListBrandItemForSaleModal,
  ] = useState(false);

  const isMyBrandItem = owner._id === profile?._id;

  const invalidateBrandItemHandler = () =>
    Promise.all([
      queryClient.invalidateQueries(['brand-item', _id]),
      queryClient.refetchQueries(['brand-item', _id]),
    ]);

  const unlistBrandItemForSaleHandler = async () => {
    setUnlistingBrandItem(true);

    try {
      await unlistBrandItemForSaleRequest(_id);
      await invalidateBrandItemHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setUnlistingBrandItem(false);
  };

  const openUpdateBrandItemPriceModalHandler = () => {
    setShowUpdateBrandItemPriceModal(true);
  };

  const createBrandItemCheckoutSessionHandler = async () => {
    try {
      const { sessionUrl } = await createBrandItemCheckoutSessionRequest(_id);

      window.open(sessionUrl, '_blank');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const openListBrandItemForSaleModalHandler = () => {
    setShowListBrandItemForSaleModal(true);
  };

  const renderBuyNowButtonAccordingly = () => {
    if (owner._id !== author?._id) return null;

    if (listedForSale && owner._id !== profile?._id) {
      return (
        <div className={classes['buttons-container']}>
          <Button
            onClick={createBrandItemCheckoutSessionHandler}
            darkpink="true"
            style={{ flex: 1 }}
          >
            Buy Now
          </Button>
        </div>
      );
    }
    if (listedForSale && isMyBrandItem) {
      return (
        <div className={classes['buttons-container']}>
          <Button
            onClick={openUpdateBrandItemPriceModalHandler}
            darkpink="true"
            style={{ flex: 1 }}
          >
            Update Price
          </Button>
          <Button
            disabled={unlistingBrandItem}
            onClick={unlistBrandItemForSaleHandler}
            darkpinkcolor="true"
            style={{ flex: 1 }}
          >
            Unlist
          </Button>
        </div>
      );
    }

    if (!listedForSale && isMyBrandItem) {
      return (
        <div className={classes['buttons-container']}>
          <Button
            onClick={openListBrandItemForSaleModalHandler}
            darkpink="true"
            style={{ flex: 1 }}
          >
            List
          </Button>
        </div>
      );
    }
    if (!listedForSale && owner._id !== profile?._id) {
      return (
        <div className={classes['buttons-container']}>
          <Button disabled darkpink="true" style={{ flex: 1 }}>
            Not Listed
          </Button>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {showUpdateBrandItemPriceModal && (
        <UpdateBrandItemPriceModal
          brandItemId={_id}
          setShowUpdateBrandItemPriceModal={setShowUpdateBrandItemPriceModal}
        />
      )}

      {showListBrandItemForSaleModal && (
        <ListBrandItemForSaleModal
          brandItemId={_id}
          setShowListBrandItemForSaleModal={setShowListBrandItemForSaleModal}
        />
      )}

      <MetaTags>
        <title>{title} - Brand | Glimpse</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={previewUrl} />
      </MetaTags>

      <div className={classes['brand-item-content-page-container']}>
        <div className={classes['brand-item-image-details-container']}>
          <div className={classes['brand-item-content-container']}>
            <div className={classes['brand-item-image-container']}>
              {type === 'video' && (
                <ReactPlayer
                  width={500}
                  height={500}
                  url={previewUrl}
                  controls
                  playing={true}
                />
              )}
              {type === 'image' && (
                <img
                  className={classes['brand-item-image']}
                  alt="Brand"
                  src={previewUrl}
                />
              )}
            </div>
          </div>
        </div>

        <div className={classes['brand-item-information-container']}>
          <div className={classes['brand-item-info-container']}>
            <div className={classes['title-tags-collection-container']}>
              <div className={classes['title-tags-container']}>
                <div className={classes['title-container']}>
                  <h3 title={title} className={classes['title-text']}>
                    {limitString(title, 40)}
                  </h3>
                </div>
                <div className={classes['description-container']}>
                  <p className={classes['description']}>{description}</p>
                </div>

                <p className={classes['categories-text']}>Categories</p>
                <div className={classes['tags']}>
                  {categories.map((category, i) => (
                    <p
                      title={category}
                      className={classes['tag']}
                      key={category + i}
                    >
                      #{limitString(category, 18)}
                    </p>
                  ))}
                </div>

                <p className={classes['tags-text']}>Tags</p>
                <div className={classes['tags']}>
                  {tags.map((tag, i) => (
                    <p title={tag} className={classes['tag']} key={tag + i}>
                      #{limitString(tag, 18)}
                    </p>
                  ))}
                </div>

                <div className={classes['author-owner-container']}>
                  <Link
                    to={
                      author._id === profile?._id
                        ? '/my-space'
                        : `/user-feed/${author._id}`
                    }
                    className={classes['creator-container']}
                  >
                    <img
                      alt={author.fullName}
                      className={classes['author-owner-image']}
                      src={author.photo}
                    />
                    <div>
                      <p className={classes['creator-text']}>Creator</p>
                      <p className={classes['author-firstName']}>
                        {author.firstName}
                        {author.verified && (
                          <img
                            alt="Verified"
                            src={VerifiedIcon}
                            style={{
                              height: 14,
                              marginLeft: 2,
                              marginBottom: 2,
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </Link>
                  <Link
                    to={
                      owner._id === profile?._id
                        ? '/my-space'
                        : `/user-feed/${owner._id}`
                    }
                    className={classes['owner-container']}
                  >
                    <img
                      alt={owner.fullName}
                      className={classes['author-owner-image']}
                      src={owner.photo}
                    />
                    <div>
                      <p className={classes['owner-text']}>Owner</p>

                      <p className={classes['owner-firstName']}>
                        {owner.firstName}
                        {owner.verified && (
                          <img
                            alt="Verified"
                            src={VerifiedIcon}
                            style={{
                              height: 14,
                              marginLeft: 2,
                              marginBottom: 2,
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </Link>
                </div>

                <div className={classes['brand-item-actions-container']}>
                  <div className={classes['price-information-container']}>
                    {owner._id !== author._id ? (
                      <p className={classes['price-text']}>Last Price</p>
                    ) : (
                      <p className={classes['price-text']}>Price</p>
                    )}
                    <p className={classes['brand-item-price']}>
                      {price ? parseFloat(price).toFixed(2) : 0.0} USD
                    </p>
                  </div>

                  {owner._id === author._id && (
                    <div className={classes['horizontal-line']}></div>
                  )}

                  {renderBuyNowButtonAccordingly()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BrandItemContent;
