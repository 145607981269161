import React, { useState, useEffect, useRef, useContext } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import {
  createGroupPostTopicRequest,
  createNotificationToMultipleUsersRequest,
  createUserPostCommentReplyRequest,
  createUserPostCommentRequest,
  getAllGroupMembersForASpecificGroupRequest,
  getCommentsForSpecificUserPostRequest,
  getGroupPostTopicsRequest,
  searchUsersByUsernameRequest,
  updateMyPostCommentRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './PostComments.module.css';
import PostComment from './components/PostComment/PostComment';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useCallback } from 'react';
import { AuthContext } from '../../../../context/auth-context';
// import Button from '../../../../components/Button/Button';
import EmojiIcon from '../../../../assets/emoji.png';
import { Picker } from 'emoji-mart';
import { SocketContext } from '../../../../context/socket-context';
import {
  USER_POST_COMMENT_MENTION,
  USER_POST_COMMENT_REPLY_MENTION,
} from '../../../../constants/SOCKET_EVENTS';

const RESULTS_PER_PAGE = 5;

function PostComments(props) {
  const { profile } = useContext(AuthContext);
  const { socket } = useContext(SocketContext);
  const queryClient = useQueryClient();
  const { postId, isGroupPost } = props;

  const commentInputRef = useRef();
  const postCommentsContainerRef = useRef(null);

  const [comment, setComment] = useState('');
  const [splittedMessage, setSplittedMessage] = useState([]);
  const [mentionedUsernames, setMentionedUsernames] = useState({});
  const [replyCommentState, setReplyCommentState] = useState({
    isReplying: false,
    selectedCommentIdToReply: undefined,
    authorReplyingTo: undefined,
    reply: '',
  });
  const [updateCommentState, setUpdateCommentState] = useState({
    isUpdating: false,
    selectedCommentIdToUpdate: undefined,
    updatedComment: '',
  });
  const [showEmojiEditor, setShowEmojiEditor] = useState(false);
  const [showTopicsTable, setShowTopicsTable] = useState(false);
  const [previousSplittedMessage, setPreviousSplittedMessage] = useState([]);
  const [fetchedTopics, setFetchedTopics] = useState([]);
  const [fetchingTopics, setFetchingTopics] = useState(false);

  const getLastTypedMessage = useCallback(() => {
    for (let i = 0; i < splittedMessage.length; i++) {
      if (previousSplittedMessage[i] !== splittedMessage[i]) {
        return { index: i, msg: splittedMessage[i] };
      }
    }
  }, [splittedMessage, previousSplittedMessage]);

  const getMentionValue = () => {
    let message;
    if (replyCommentState.isReplying) message = replyCommentState.reply;
    else message = comment;

    if (message === '') return '';

    if (splittedMessage[splittedMessage.length - 1]?.includes('\n@')) {
      let mentionValue = '';
      let i = splittedMessage[splittedMessage.length - 1].length - 1;
      while (splittedMessage[splittedMessage.length - 1][i] !== '@') {
        mentionValue += splittedMessage[splittedMessage.length - 1][i];
        i--;
      }
      return mentionValue.split('').reverse().join('');
    }

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (msg[0] === '@' && mentionedUsernames[msg]) continue;

      if (msg[0] === '@' && !mentionedUsernames[msg]) {
        const mentionValue = msg.replace('@', '');
        return mentionValue;
      }
    }
    return '';
  };

  const mentionValue = getMentionValue();

  const mentionKey = isGroupPost
    ? ['group-mentions', props.groupId, mentionValue]
    : ['mentions', mentionValue];
  const mentionFn = isGroupPost
    ? () =>
        getAllGroupMembersForASpecificGroupRequest(
          1,
          50,
          props.groupId,
          mentionValue
        )
    : () => searchUsersByUsernameRequest(mentionValue);
  const { data: mentions } = useQuery(mentionKey, mentionFn);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['user-post-comments', postId],
    ({ pageParam = 1 }) =>
      getCommentsForSpecificUserPostRequest(
        postId,
        pageParam,
        RESULTS_PER_PAGE
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    let fetching = false;
    const postCommentsRefElement = postCommentsContainerRef.current;

    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.4) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    postCommentsRefElement.addEventListener('scroll', scrollHandler);
    return () => {
      postCommentsRefElement.removeEventListener('scroll', scrollHandler);
    };
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    const checkForShowingTopicsTable = async () => {
      const lastTypedMessage = getLastTypedMessage();
      if (lastTypedMessage?.msg?.startsWith('#')) {
        setShowTopicsTable(true);
        setFetchingTopics(true);
        const { data } = await getGroupPostTopicsRequest(
          props.groupId,
          1,
          50,
          lastTypedMessage.msg
        );
        setFetchingTopics(false);
        setFetchedTopics(Array.from(new Set(data.map(({ name }) => name))));
      } else setShowTopicsTable(false);
    };

    checkForShowingTopicsTable();
  }, [getLastTypedMessage, props.groupId]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const commentChangeHandler = (e) => {
    const { value } = e.target;
    setComment(value);
    setSplittedMessage((prevState) => {
      setPreviousSplittedMessage(prevState);
      return value.split(' ');
    });

    const updatedMentionedUsernames = {};
    for (const usernameKey in mentionedUsernames) {
      if (splittedMessage.includes(usernameKey)) {
        updatedMentionedUsernames[usernameKey] =
          mentionedUsernames[usernameKey];
      }
    }

    setMentionedUsernames(updatedMentionedUsernames);
  };

  const replyChangeHandler = (e) => {
    const { value } = e.target;
    const reply = value;
    const splittedMessage = reply.split(' ');
    setSplittedMessage((prevState) => {
      setPreviousSplittedMessage(prevState);
      return splittedMessage;
    });

    const updatedMentionedUsernames = {};
    for (const usernameKey in mentionedUsernames) {
      if (splittedMessage.includes(usernameKey)) {
        updatedMentionedUsernames[usernameKey] =
          mentionedUsernames[usernameKey];
      }
    }

    setMentionedUsernames(updatedMentionedUsernames);

    setReplyCommentState((prevState) => ({
      ...prevState,
      reply,
    }));
  };

  const renderPostComments = () => {
    let mentionsData = mentions ? mentions.data : [];

    return data.pages.map((page) =>
      page.data.map(
        ({ _id: commentId, comment, user, createdAt, mentions }) => (
          <PostComment
            mentions={mentions || []}
            postId={postId}
            key={commentId}
            commentId={commentId}
            setComment={setComment}
            replyCommentState={replyCommentState}
            setReplyCommentState={setReplyCommentState}
            updateCommentState={updateCommentState}
            setUpdateCommentState={setUpdateCommentState}
            comment={comment}
            user={user}
            createdAt={createdAt}
            cancelReplyingHandler={cancelReplyingHandler}
            setMentionedUsernames={setMentionedUsernames}
            replyChangeHandler={replyChangeHandler}
            sendReplyHandler={sendReplyHandler}
            shouldNotShowMentionsTable={shouldNotShowMentionsTable}
            shouldShowMentionsTableInNewLine={shouldShowMentionsTableInNewLine}
            selectUserToMentionHandler={selectUserToMentionHandler}
            mentionsData={mentionsData}
            showTopicsTable={showTopicsTable}
            fetchedTopics={fetchedTopics}
            fetchingTopics={fetchingTopics}
            selectFetchedTopicHandler={selectTopicHandler}
            isGroupPost={isGroupPost}
          />
        )
      )
    );
  };

  const scrollToTop = useCallback(() => {
    const scrollingElement = postCommentsContainerRef.current;
    if (scrollingElement) scrollingElement.scrollTop = 0;
  }, [postCommentsContainerRef]);

  const sendNotificationsToMentionedUsers = async (
    notificationType,
    eventName
  ) => {
    if (!profile)
      return notify(
        'error',
        'You are not logged in. Please log in to perform this action!',
        2000
      );

    try {
      const mentionedUserIds = [];
      for (const key in mentionedUsernames) {
        const userId = mentionedUsernames[key]._id;
        const isMe = profile?._id === userId;
        if (!isMe) {
          mentionedUserIds.push(userId);
        }
      }
      const requestBody = {
        userIds: mentionedUserIds,
        notificationType,
        entityName: profile.fullName,
        entityId: postId,
      };
      await createNotificationToMultipleUsersRequest(requestBody);
      socket.emit(eventName, {
        mentionedUserIds,
        author: { fullName: profile.fullName, photo: profile.photo },
      });
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const sendCommentHandler = async () => {
    if (!comment) return;

    const mentions = [];
    Object.entries(mentionedUsernames).forEach(([mentionUsername, data]) => {
      mentions.push({ mentionUsername, data: { ...data, id: data._id } });
    });

    try {
      const topics = [];
      comment.split(' ').forEach((el) => {
        if (el.startsWith('#')) {
          const requestBody = {
            topicName: el,
          };
          createGroupPostTopicRequest(props.groupId, postId, requestBody);
        }
      });
      const requestBody = {
        comment,
        isGroupPost,
        mentions,
        commentTopics: JSON.stringify(topics),
      };

      setComment('');

      const { data: newComment } = await createUserPostCommentRequest(
        postId,
        requestBody
      );

      const notificationType = isGroupPost
        ? 'user-group-post-comment-mention'
        : 'user-post-comment-mention';
      sendNotificationsToMentionedUsers(
        notificationType,
        USER_POST_COMMENT_MENTION
      );
      setMentionedUsernames({});
      setSplittedMessage([]);

      queryClient.invalidateQueries([
        'number-of-comments-for-specific-post',
        postId,
      ]);
      queryClient.setQueryData(['user-post-comments', postId], (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => {
            const updatedData = [newComment, ...page.data];
            return { ...page, results: page.results + 1, data: updatedData };
          }),
        };
      });
      scrollToTop();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const sendReplyHandler = async () => {
    const { reply } = replyCommentState;
    if (!reply) return;

    const mentions = [];
    Object.entries(mentionedUsernames).forEach(([mentionUsername, data]) => {
      mentions.push({ mentionUsername, data: { ...data, id: data._id } });
    });

    reply.split(' ').forEach((el) => {
      if (el.startsWith('#')) {
        const requestBody = {
          topicName: el,
        };
        createGroupPostTopicRequest(props.groupId, postId, requestBody);
      }
    });

    const requestBody = {
      reply,
      mentions,
    };

    try {
      const { data: newReply } = await createUserPostCommentReplyRequest(
        replyCommentState.selectedCommentIdToReply,
        requestBody
      );

      const notificationType = isGroupPost
        ? 'user-group-post-comment-reply-mention'
        : 'user-post-comment-reply-mention';

      sendNotificationsToMentionedUsers(
        notificationType,
        USER_POST_COMMENT_REPLY_MENTION
      );
      cancelReplyingHandler();

      queryClient.setQueryData(
        [
          'user-post-comment-replies',
          replyCommentState.selectedCommentIdToReply,
        ],
        (data) => {
          return {
            ...data,
            results: data.results + 1,
            data: [newReply, ...data.data],
          };
        }
      );
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const cancelReplyingHandler = () => {
    setReplyCommentState({
      isReplying: false,
      selectedCommentIdToReply: undefined,
      authorReplyingTo: undefined,
      reply: '',
    });
    setMentionedUsernames({});
    setSplittedMessage([]);
  };

  const updateCommentHandler = async () => {
    try {
      const requestBody = { comment: updateCommentState.updatedComment };
      await updateMyPostCommentRequest(
        updateCommentState.selectedCommentIdToUpdate,
        requestBody
      );

      queryClient.setQueryData(['user-post-comments', postId], (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => {
            const updatedData = page.data.map((el) => {
              if (el._id === updateCommentState.selectedCommentIdToUpdate) {
                return { ...el, comment: updateCommentState.updatedComment };
              } else {
                return { ...el };
              }
            });
            return {
              ...page,
              data: updatedData,
            };
          }),
        };
      });

      setUpdateCommentState({
        isUpdating: false,
        selectedCommentIdToUpdate: undefined,
        updatedComment: '',
      });
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const selectTopicHandler = (topic) => {
    const lastTypedMessage = getLastTypedMessage();
    if (lastTypedMessage?.msg) {
      const updatedSplittedMessage = [...splittedMessage];
      updatedSplittedMessage[lastTypedMessage.index] = topic;
      setSplittedMessage(updatedSplittedMessage);

      if (replyCommentState.isReplying) {
        setReplyCommentState((prevState) => ({
          ...prevState,
          reply: updatedSplittedMessage.join(' '),
        }));
      } else {
        setComment(updatedSplittedMessage.join(' '));
      }
    }
  };

  const getTextAreaValue = () => {
    // if (replyCommentState.isReplying) return replyCommentState.reply;

    if (updateCommentState.isUpdating) return updateCommentState.updatedComment;

    return comment;
  };

  const updateCommentTextHandler = (e) => {
    const { value } = e.target;
    setUpdateCommentState((prevState) => ({
      ...prevState,
      updatedComment: value,
    }));
  };

  const textAreaChangeHandler = (e) => {
    // if (replyCommentState.isReplying) return replyChangeHandler(e);

    if (updateCommentState.isUpdating) return updateCommentTextHandler(e);

    commentChangeHandler(e);
  };
  const sendClickHandler = () => {
    // if (replyCommentState.isReplying) return sendReplyHandler();

    if (updateCommentState.isUpdating) return updateCommentHandler();

    sendCommentHandler();
  };

  const keyDownHandler = (e) => {
    if (!comment.trim()) return;

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendClickHandler();
    }
  };

  const selectEmojiHandler = (icon) => {
    if (replyCommentState.isReplying) {
      setReplyCommentState((prevState) => ({
        ...prevState,
        reply: prevState.reply + icon,
      }));
    } else if (updateCommentState.isUpdating) {
      setUpdateCommentState((prevState) => ({
        ...prevState,
        updatedComment: prevState.updatedComment + icon,
      }));
    } else {
      setComment((prevState) => prevState + icon);
    }
  };

  const toggleEmojiContainer = () => {
    setShowEmojiEditor((prevState) => !prevState);
  };

  const shouldNotShowMentionsTable = () => {
    return splittedMessage.every((msg) => {
      return (
        msg === '' ||
        msg === ' ' ||
        msg[0] !== '@' ||
        (msg[0] === '@' && typeof mentionedUsernames[msg] === 'object')
      );
    });
  };

  const selectUserToMentionHandler = (user) => {
    const updatedMentionedUsernames = { ...mentionedUsernames };

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (
        msg[0] === '@' &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[i] = `@${username}`;
      } else if (
        splittedMessage[splittedMessage.length - 1]?.includes('\n@') &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[splittedMessage.length - 1] = `\n@${username}`;
      }
    }

    splittedMessage.push('');
    setMentionedUsernames(updatedMentionedUsernames);
    if (replyCommentState.isReplying) {
      setReplyCommentState((prevState) => ({
        ...prevState,
        reply: splittedMessage.join(' '),
      }));
    } else {
      setComment(splittedMessage.join(' '));
    }

    setSplittedMessage(splittedMessage);

    if (!replyCommentState.isReplying) commentInputRef.current.focus();
  };

  const shouldShowMentionsTableInNewLine = () => {
    return splittedMessage[splittedMessage.length - 1]?.includes('\n@');
  };

  const textAreaValue = getTextAreaValue();

  // const resizeTextAreaHeightOnKeyupHandler = (e) => {
  //   const element = e.target;

  //   if (
  //     element.style?.height?.replace('px', '') &&
  //     parseInt(element.style.height.replace('px', '')) > 80
  //   )
  //     return;

  //   element.style.height = '1px';
  //   element.style.height = 5 + element.scrollHeight + 'px';
  // };

  // useEffect(() => {
  //   if (textAreaValue.length === 0 && commentInputRef.current) {
  //     commentInputRef.current.style.height = '1px';
  //     commentInputRef.current.style.height =
  //       5 + commentInputRef.current.scrollHeight + 'px';
  //   }
  // }, [textAreaValue, commentInputRef]);

  return (
    <div>
      <div style={{ position: 'relative', width: '100%' }}>
        <div className={classes['send-comment-container']}>
          <div className={classes['textarea-emoji-container']}>
            <textarea
              // onKeyUp={resizeTextAreaHeightOnKeyupHandler}
              className={classes['textarea']}
              ref={commentInputRef}
              value={textAreaValue}
              onChange={textAreaChangeHandler}
              placeholder={
                updateCommentState.isUpdating
                  ? 'Start updating your comment'
                  : 'Start by typing a comment...'
              }
              style={{ width: '100%' }}
              onKeyDown={keyDownHandler}
            />

            <img
              alt="Emoji"
              onClick={toggleEmojiContainer}
              className={classes['emoji-icon']}
              src={EmojiIcon}
            />
            {showEmojiEditor && (
              <div className={classes['emoji-container']}>
                <Picker
                  title="Pick your emoji"
                  emoji="point_up"
                  onSelect={({ native }) => selectEmojiHandler(native)}
                />
              </div>
            )}
          </div>

          {/* <Button
            style={{ padding: '.7rem 1.5rem', borderRadius: 30 }}
            onClick={sendClickHandler}
            darkpink="true"
          >
            Comment
          </Button> */}
        </div>

        {!isLoading && data && data.pages[0].results !== 0 && (
          <div className={classes['horizontal-line']}></div>
        )}

        {showTopicsTable && !replyCommentState.isReplying && (
          <div className={classes['topics-container']}>
            {fetchedTopics.length === 0 && fetchingTopics === false && (
              <p className={classes['no-topics-found']}>No Topics Found</p>
            )}
            {fetchedTopics.map((topic) => {
              return (
                <div
                  onClick={() => selectTopicHandler(topic)}
                  className={classes['topic-container']}
                  key={topic}
                >
                  <p className={classes['topic-text']}>{topic}</p>
                </div>
              );
            })}
          </div>
        )}

        {(!shouldNotShowMentionsTable() ||
          shouldShowMentionsTableInNewLine()) &&
          !replyCommentState.isReplying && (
            <div className={classes['mention-container']}>
              {mentions && mentions.data.length === 0 && (
                <p className={classes['no-users-found']}>No Users found</p>
              )}

              {mentions &&
                mentions.data.map((el) => {
                  let user = isGroupPost ? el.user : el;
                  const fullName = `${user.firstName} ${user.lastName}`;
                  return (
                    <div
                      onClick={() => selectUserToMentionHandler(user)}
                      className={classes['user-container']}
                      key={user._id}
                    >
                      <div>
                        <img
                          style={{ objectFit: 'cover' }}
                          alt="userPhoto"
                          className={classes['user-image']}
                          src={user.photo}
                        />
                      </div>
                      <p className={classes['user-text']}>
                        {user.username} ({fullName})
                        {user._id === profile?._id ? ' (You)' : ''}
                      </p>
                    </div>
                  );
                })}
            </div>
          )}
      </div>

      <div
        className={classes['post-comments-container']}
        ref={postCommentsContainerRef}
      >
        {data && renderPostComments()}
      </div>

      {(isLoading || isFetching) && <LoadingSpinner />}
    </div>
  );
}

export default PostComments;
