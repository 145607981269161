import React from 'react';
import classes from './MetaMaskNft.module.css';
import EthereumIcon from '../../assets/ethereum.png';
import { limitString } from '../../helpers/helpers';

function MetaMaskNft(props) {
  const { imageUrl, chain, collectionName, name } = props;

  const renderChain = (chain) => {
    if (chain === 'ETH') {
      return (
        <div className={classes['chain-container']}>
          <img
            src={EthereumIcon}
            alt="Ethereum"
            className={classes['chain-symbol']}
          />
          <p className={classes['chain-text']}>Ethereum</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={classes['metamask-nft-container']}>
      <img src={imageUrl} alt="NFT URL" className={classes['nft-image']} />

      <div className={classes['nft-info-container']}>
        <h3 title={name} className={classes['nft-name']}>
          {limitString(name, 25)}
        </h3>

        <div className={classes['collection-chain-container']}>
          <p style={{ margin: 0 }}>
            <span className={classes['collection-text']}>Collection: </span>
            <span className={classes['collection-value']}>
              {' '}
              {limitString(collectionName, 16)}
            </span>
          </p>

          {renderChain(chain)}
        </div>
      </div>
    </div>
  );
}

export default MetaMaskNft;
