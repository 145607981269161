import React, { useRef, useEffect } from 'react';
import classes from './InvitationRequestsModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import {
  acceptRequestedGroupMembershipRequest,
  getAllRequestedGroupMembershipsRequest,
  rejectRequestedGroupMembershipRequest,
} from '../../../../httpRequests/httpRequests';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { limitString } from '../../../../helpers/helpers';
import notify from '../../../../utils/notify';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

const RESULTS_PER_PAGE = 10;

function InvitationRequestsModal(props) {
  const queryClient = useQueryClient();
  const requestsListRef = useRef();
  const { groupId, setShowInvitationRequestsModal } = props;

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['requested-group-memberships', groupId],
    ({ pageParam = 1 }) =>
      getAllRequestedGroupMembershipsRequest(
        pageParam,
        RESULTS_PER_PAGE,
        groupId
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    let fetching = false;
    const requestsListContainerElement = requestsListRef.current;

    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.4) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    requestsListContainerElement.addEventListener('scroll', scrollHandler);
    return () => {
      requestsListContainerElement.removeEventListener('scroll', scrollHandler);
    };
  }, [hasNextPage, fetchNextPage]);

  const closeModalHandler = () => {
    setShowInvitationRequestsModal(false);
  };

  const invalidateGroupMembers = () => {
    [
      ['first-10-group-members', groupId],
      ['number-of-members', groupId],
    ].forEach((queryKey) => {
      queryClient.invalidateQueries(queryKey);
      queryClient.refetchQueries(queryKey);
    });
  };

  const acceptInvitationRequestHandler = async (userId) => {
    try {
      await acceptRequestedGroupMembershipRequest(groupId, userId);

      queryClient.setQueryData(
        ['requested-group-memberships', groupId],
        (data) => {
          return {
            ...data,
            pages: data.pages.map((page) => {
              const updatedData = page.data.filter(
                ({ user }) => user._id !== userId
              );
              return { ...page, results: page.results - 1, data: updatedData };
            }),
          };
        }
      );

      invalidateGroupMembers();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const rejectInvitationRequestHandler = async (userId) => {
    try {
      await rejectRequestedGroupMembershipRequest(groupId, userId);

      queryClient.setQueryData(
        ['requested-group-memberships', groupId],
        (data) => {
          return {
            ...data,
            pages: data.pages.map((page) => {
              const updatedData = page.data.filter(
                ({ user }) => user._id !== userId
              );
              return { ...page, results: page.results - 1, data: updatedData };
            }),
          };
        }
      );
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  return (
    <Modal
      style={{ height: 550 }}
      closeModal={closeModalHandler}
      title="Join Requests"
      footer={
        <Button pinkcolor="true" onClick={closeModalHandler}>
          Close
        </Button>
      }
    >
      <div ref={requestsListRef} className={classes['requests-list']}>
        {!isLoading && data?.pages && data.pages[0].results === 0 && (
          <p>No Results Found</p>
        )}
        {!isLoading &&
          data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map(({ user }) => {
              return (
                <div className={classes['user-container']}>
                  <Link
                    className={classes['user-info']}
                    to={`/user-feed/${user._id}`}
                  >
                    <img
                      alt="User"
                      src={user.photo}
                      className={classes['user-image']}
                    />
                    <h3
                      className={classes['user-fullName']}
                      title={user.fullName}
                    >
                      {limitString(user.fullName, 25)}
                    </h3>
                  </Link>
                  <div className={classes['buttons-container']}>
                    <Button
                      onClick={() => acceptInvitationRequestHandler(user._id)}
                      style={{
                        padding: '0.5rem 1rem',
                        width: 'auto',
                      }}
                      pink="true"
                    >
                      Accept
                    </Button>
                    <Button
                      onClick={() => rejectInvitationRequestHandler(user._id)}
                      pinkcolor="true"
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              );
            });
          })}

        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </Modal>
  );
}

export default InvitationRequestsModal;
