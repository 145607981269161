import React, { useContext, useState } from 'react';
import classes from './ReceivedNftOffer.module.css';
import { IPFS_URL } from '../../constants/IPFS';
import VerifiedIcon from '../../assets/verified.png';
import moment from 'moment';
import { CoinMarketCapContext } from '../../context/coinmarketcap-context';
import { getFormattedNumber } from '../../helpers/helpers';
import Button from '../Button/Button';
import {
  acceptNftOfferRequest,
  rejectNftOfferRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

function ReceivedNftOffer(props) {
  const queryClient = useQueryClient();
  const { nftOffer } = props;
  const { nft, buyer, price, comment } = nftOffer;
  const { glmsToUsd } = useContext(CoinMarketCapContext);
  const [processingOffer, setProcessingOffer] = useState(false);

  const invalidateReceivedNftOffers = async () => {
    await Promise.all([
      queryClient.invalidateQueries(['my-received-nft-offers']),
      queryClient.refetchQueries(['my-received-nft-offers']),
    ]);
  };

  const acceptNftOfferHandler = async () => {
    setProcessingOffer(true);

    try {
      await acceptNftOfferRequest(nftOffer._id);
      await invalidateReceivedNftOffers();
    } catch (err) {
      notify('error', err, 2000);
    }

    setProcessingOffer(false);
  };

  const rejectNftOfferHandler = async () => {
    setProcessingOffer(true);

    try {
      await rejectNftOfferRequest(nftOffer._id);
      await invalidateReceivedNftOffers();
    } catch (err) {
      notify('error', err, 2000);
    }

    setProcessingOffer(false);
  };

  return (
    <div className={classes['nft-offer-container']}>
      {nft.type === 'image' ? (
        <Link to={`/nfts/${nft._id}`}>
          <img
            className={classes['nft-image']}
            src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
            alt="IPFS Hash"
          />
        </Link>
      ) : (
        <Link to={`/nfts/${nft._id}`}>
          <ReactPlayer
            style={{
              objectFit: 'cover',
              borderRadius: 10,
            }}
            width={330}
            height={300}
            url={`${IPFS_URL}/${nft.ipfs}`}
          />
        </Link>
      )}

      <div className={classes['offer-info-container']}>
        <div className={classes['buyer-info-container']}>
          <img
            className={classes['buyer-photo']}
            src={buyer.photo}
            alt="Buyer"
          />
          <p className={classes['buyer-fullName']}>{buyer.fullName}</p>
          {buyer.verified && (
            <img width={14} src={VerifiedIcon} alt="Verified" />
          )}
        </div>

        <div className={classes['title-price-container']}>
          <p className={classes['nft-title']}>{nft.title}</p>
          {/* <p className={classes['price']}>{getFormattedNumber(price)} GLMS</p> */}
          <p className={classes['price']}>
            {getFormattedNumber((price * glmsToUsd.price).toFixed(2))} USD
          </p>
        </div>
        <div className={classes['received-usd-price-container']}>
          <p className={classes['received-on']}>
            Received on:{' '}
            {moment(nftOffer.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
          {/* <p className={classes['usd-price']}>
            ${getFormattedNumber((price * glmsToUsd.price).toFixed(2))}
          </p> */}
        </div>

        <p className={classes['comment-container']}>
          <span className={classes['sender-comment-text']}>
            Sender Comment :{' '}
          </span>
          <span className={classes['comment-text']}>
            {comment ? comment : 'No Comment Found!'}
          </span>
        </p>

        <div className={classes['actions-container']}>
          <Button
            disabled={processingOffer || nft.processingNftOfferOnTheBlockchain}
            onClick={acceptNftOfferHandler}
            style={{ padding: '.5rem 2rem', width: '100%' }}
            darkpink="true"
          >
            {nft.processingNftOfferOnTheBlockchain ? 'Processing...' : 'Accept'}
          </Button>
          <Button
            style={{ width: '100%' }}
            disabled={processingOffer || nft.processingNftOfferOnTheBlockchain}
            onClick={rejectNftOfferHandler}
            darkpinkcolor="true"
          >
            Reject
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReceivedNftOffer;
