import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import Modal from '../../../../components/Modal/Modal';
import { CoinMarketCapContext } from '../../../../context/coinmarketcap-context';
import { buyNftNowRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './BuyNowModal.module.css';
import Button from '../../../../components/Button/Button';
import { getFormattedNumber, limitString } from '../../../../helpers/helpers';
import ReactPlayer from 'react-player';
import { IPFS_URL } from '../../../../constants/IPFS';

function BuyNowModal(props) {
  const queryClient = useQueryClient();
  const { nft, setShowBuyNowModal } = props;
  const { glmsToUsd } = useContext(CoinMarketCapContext);

  const [startedBuyNow, setStartedBuyNow] = useState(false);

  const closeModalHandler = () => setShowBuyNowModal(false);

  const buyNowHandler = async () => {
    setStartedBuyNow(true);

    try {
      await buyNftNowRequest(nft._id);

      setStartedBuyNow(false);

      queryClient.invalidateQueries(['nfts', nft._id]);
      queryClient.refetchQueries(['nfts', nft._id]);

      notify(
        'success',
        'A notification will be sent after the transaction finishes successfully!',
        2000
      );
      closeModalHandler();
    } catch (error) {
      setStartedBuyNow(false);
      notify('error', error, 2000);
    }
  };

  return (
    <>
      <Modal
        style={{ height: 500 }}
        title={`Buy Now  ${limitString(nft.title, 25)}`}
        closeModal={closeModalHandler}
        footer={
          <>
            <Button
              style={{ width: 200 }}
              darkpinkcolor="true"
              onClick={closeModalHandler}
              disabled={startedBuyNow}
            >
              Cancel
            </Button>
            <span style={{ marginRight: 10 }}></span>
            <Button
              style={{ width: 200 }}
              darkpink="true"
              onClick={buyNowHandler}
              disabled={startedBuyNow}
            >
              Buy Now
            </Button>
          </>
        }
      >
        <div className={classes['buy-now-modal-container']}>
          <div className={classes['info-container']}>
            {nft.type === 'video' ? (
              <ReactPlayer
                width={300}
                height={300}
                url={`${IPFS_URL}/${nft.ipfs}`}
              />
            ) : (
              <img
                alt="IPFS"
                className={classes['nft-image']}
                src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
              />
            )}
            <div className={classes['price-container']}>
              <p className={classes['price-text']}>Price:</p>
              <div className={classes['price-content']}>
                {/* <h3 className={classes['price']}>
                  {getFormattedNumber(nft.buyNowPrice)} GLMS
                </h3> */}
                <h3 className={classes['price']}>
                  {getFormattedNumber(
                    (glmsToUsd.price * nft.buyNowPrice).toFixed(2)
                  )}{' '}
                  USD
                </h3>

                {/* <p className={classes['usd-price']}>
                  $
                  {getFormattedNumber(
                    (glmsToUsd.price * nft.buyNowPrice).toFixed(2)
                  )}
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default BuyNowModal;
