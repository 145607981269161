import React from 'react';
import { useHistory } from 'react-router-dom';
import classes from './GoBack.module.css';
import GoBackIcon from '../../assets/goBack.png';

function GoBack(props) {
  const history = useHistory();
  return (
    <div style={props.style ? props.style : {}} className={classes['goBack']}>
      <img
        width={22}
        height={14}
        alt="Go Back"
        src={GoBackIcon}
        onClick={() =>
          props.goBackHandler ? props.goBackHandler() : history.goBack()
        }
      />
    </div>
  );
}

export default GoBack;
