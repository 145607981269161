import React, { useContext, useState } from 'react';
import classes from './EditCreatorServicesPricesModal.module.css';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import { limitString } from '../../helpers/helpers';
import {
  createUserServicePriceRequest,
  updateMyCreatorServicesRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import { useQueryClient } from 'react-query';
import { AuthContext } from '../../context/auth-context';
import EditGroupBenefitsModal from '../EditGroupBenefitsModal/EditGroupBenefitsModal';

function EditCreatorServicesPricesModal(props) {
  const queryClient = useQueryClient();
  const { getMyProfile } = useContext(AuthContext);

  const {
    customServices,
    nonCustomServices,
    setShowEditCreatorServicesPricesModal,
    currentServicesPrices,
    closeEditCreatorServicesModal,
  } = props;

  const [savingPrices, setSavingPrices] = useState(false);
  const [showEditGroupBenefitsModal, setShowEditGroupBenefitsModal] = useState(
    false
  );

  const buildServicesPrices = () => {
    const servicesPrices = {};

    [...customServices, ...nonCustomServices].forEach((service) => {
      servicesPrices[service] = {
        regularPrice: currentServicesPrices[service]
          ? currentServicesPrices[service].regularPrice
          : '',
        regularPriceValid: currentServicesPrices[service]?.regularPrice
          ? true
          : false,
        regularPriceTouched: currentServicesPrices[service]?.regularPrice
          ? true
          : false,
        communityPrice: currentServicesPrices[service]
          ? currentServicesPrices[service].communityPrice
          : '',
        communityPriceValid: currentServicesPrices[service]?.communityPrice
          ? true
          : false,
        communityPriceTouched: currentServicesPrices[service]?.communityPrice
          ? true
          : false,
      };
    });

    return servicesPrices;
  };

  const [servicesPrices, setServicesPrices] = useState(() =>
    buildServicesPrices()
  );

  const closeModalHandler = () => {
    setShowEditCreatorServicesPricesModal(false);
  };

  const regularPriceChangeHandler = (e, service) => {
    const { value } = e.target;

    const updatedServicesPrices = { ...servicesPrices };
    updatedServicesPrices[service] = {
      ...updatedServicesPrices[service],
      regularPrice: value,
      regularPriceValid:
        value && parseFloat(value) > 0 && parseFloat(value) < 10001,
      regularPriceTouched: true,
    };

    setServicesPrices(updatedServicesPrices);
  };

  const communityPriceChangeHandler = (e, service) => {
    const { value } = e.target;

    const updatedServicesPrices = { ...servicesPrices };
    updatedServicesPrices[service] = {
      ...updatedServicesPrices[service],
      communityPrice: value,
      communityPriceValid:
        value && parseFloat(value) > 0 && parseFloat(value) < 10001,
      communityPriceTouched: true,
    };

    setServicesPrices(updatedServicesPrices);
  };

  const renderServices = () => {
    return [...customServices, ...nonCustomServices].map((service, i) => {
      const regularPriceValid = servicesPrices[service].regularPriceValid;
      const regularPriceTouched = servicesPrices[service].regularPriceTouched;
      const regularPriceStyle = {};
      if (regularPriceTouched && !regularPriceValid) {
        regularPriceStyle['border'] = '1px solid red';
      }

      const communityPriceValid = servicesPrices[service].communityPriceValid;
      const communityPriceTouched =
        servicesPrices[service].communityPriceTouched;
      const communityPriceStyle = {};
      if (!communityPriceValid && communityPriceTouched) {
        communityPriceStyle['border'] = '1px solid red';
      }

      return (
        <div key={service + i} className={classes['service']}>
          <p
            style={{
              overflowWrap: 'anywhere',
              whiteSpace: 'pre-wrap',
            }}
            title={service}
            className={classes['service-text']}
          >
            {limitString(service, 18)}
          </p>
          <Input
            placeholder="$12.99"
            style={{ width: 130, textAlign: 'center', ...regularPriceStyle }}
            type="number"
            value={servicesPrices[service].regularPrice}
            onChange={(e) => regularPriceChangeHandler(e, service)}
          />
          <Input
            placeholder="$9.99"
            type="number"
            style={{ width: 130, textAlign: 'center', ...communityPriceStyle }}
            value={servicesPrices[service].communityPrice}
            onChange={(e) => communityPriceChangeHandler(e, service)}
          />
        </div>
      );
    });
  };

  const saveServicesPricesHandler = async () => {
    setSavingPrices(true);

    try {
      await Promise.all(
        Object.keys(servicesPrices).map((service) => {
          const requestBody = {
            service,
            regularPrice: parseFloat(servicesPrices[service].regularPrice),
            communityPrice: parseFloat(servicesPrices[service].communityPrice),
          };
          return createUserServicePriceRequest(requestBody);
        })
      );

      const requestBody = {
        creatorServices: Object.keys(servicesPrices),
      };

      await updateMyCreatorServicesRequest(requestBody);

      getMyProfile();

      await Promise.all([
        queryClient.invalidateQueries('my-services-prices'),
        queryClient.refetchQueries('my-services-prices'),
      ]);

      setSavingPrices(false);

      setShowEditGroupBenefitsModal(true);

      // closeEditCreatorServicesModal();
    } catch (err) {
      setSavingPrices(false);
      notify('error', err, 2000);
    }
  };

  const isSaveButtonDisabled = () => {
    let isSaveButtonDisabled = false;

    for (const key in servicesPrices) {
      if (
        !servicesPrices[key].regularPriceValid ||
        !servicesPrices[key].communityPriceValid
      ) {
        isSaveButtonDisabled = true;
      }
    }

    return isSaveButtonDisabled;
  };

  return showEditGroupBenefitsModal ? (
    <EditGroupBenefitsModal
      closeEditCreatorServicesModal={closeEditCreatorServicesModal}
      customServices={customServices}
      selectedServices={nonCustomServices}
    />
  ) : (
    <Modal
      style={{ width: 450, height: 550 }}
      closeModal={closeModalHandler}
      title="Creator Services"
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={savingPrices}
            onClick={closeModalHandler}
            darkpinkcolor="true"
            style={{ width: 200 }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSaveButtonDisabled() || savingPrices}
            darkpink="true"
            style={{ width: 200 }}
            onClick={saveServicesPricesHandler}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className={classes['prices-table']}>
        <p>Service</p>
        <p>Regular Price</p>
        <p>Community Price</p>
        <div className={classes['table-horizontal-line']}></div>
      </div>
      <div className={classes['services-container']}>{renderServices()}</div>
    </Modal>
  );
}

export default EditCreatorServicesPricesModal;
