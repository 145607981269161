import React, { useState, useMemo } from 'react';
import MarketPlaceContent from './MarketPlaceContent';
import { useQuery } from 'react-query';
import { getNftsGroupedByCategoriesForSaleRequest } from '../../httpRequests/httpRequests';
import { useEffect } from 'react';
import classes from './MarketPlaceContent.module.css';
import MetaTags from 'react-meta-tags';

const sortByValues = [
  {
    value: 1,
    sortByValue: 'Most recent',
    showValue: '🔥 Most recent',
  },
  {
    value: 2,
    sortByValue: 'Price high to low',
    showValue: '📈 Price H-L',
  },
  {
    value: 3,
    sortByValue: 'Price low to high',
    showValue: '📈 Price L-H',
  },
];

export const EVENT_OPTIONS = {
  NEW_EVENTS: 'New Events',
  TRENDING_EVENTS: 'Trending',
  LOCATION: 'Location',
  ALL_EVENTS: 'All Events',
};

export const BUTTON_TYPES = {
  BUY_NOW: 'Buy now',
  NEW: 'New',
};

const MarketPlace = () => {
  const [selectedButtonType, setSelectedButtonType] = useState(
    BUTTON_TYPES.BUY_NOW
  );
  const [page, setPage] = useState(1);
  const { data } = useQuery(
    'groupedNftsByCategoriesForSale',
    getNftsGroupedByCategoriesForSaleRequest,
    {
      refetchOnWindowFocus: false,
    }
  );
  const [selectedCategoryName, setSelectedCategoryName] = useState(undefined);
  const [sortByValue, setSortByValue] = useState(1);
  const [
    groupedNftsByCategoriesForSale,
    setGroupedNftsByCategoriesForSale,
  ] = useState([]);
  const [
    groupedNftsByCategoriesForSaleLoading,
    setGroupedNftsByCategoriesForSaleLoading,
  ] = useState(true);
  const [nftsInAuctionPage, setNftsInAuctionPage] = useState(1);
  const [isSafeContentSelected] = useState(false);

  const [categoriesStart, setCategoriesStart] = useState(1);
  const [categoriesEnd, setCategoriesEnd] = useState(6);

  useEffect(() => {
    const populateAndMapState = () => {
      if (!data) return;
      setGroupedNftsByCategoriesForSale(data.data);
      setGroupedNftsByCategoriesForSaleLoading(false);
      setSelectedCategoryName('All');

      if (data.data.length === 0) return;
    };

    populateAndMapState();
  }, [data]);

  const categories = useMemo(() => {
    const nftCategories = ['All'];
    groupedNftsByCategoriesForSale.forEach(({ _id }) => {
      nftCategories.push(_id);
    });
    return nftCategories;
  }, [groupedNftsByCategoriesForSale]);

  return (
    <>
      <MetaTags>
        <title>Marketplace | Glimpse</title>
        <meta property="og:title" content={`Marketplace | Glimpse`} />
      </MetaTags>

      <div className={classes['marketplace-container']}>
        <MarketPlaceContent
          sortByValues={sortByValues}
          sortByValue={sortByValue}
          selectedButtonType={selectedButtonType}
          setSelectedButtonType={setSelectedButtonType}
          isSafeContentSelected={isSafeContentSelected}
          nftsInAuctionPage={nftsInAuctionPage}
          setNftsInAuctionPage={setNftsInAuctionPage}
          page={page}
          setPage={setPage}
          setSelectedCategoryName={setSelectedCategoryName}
          selectedCategoryName={selectedCategoryName}
          categories={categories}
          groupedNftsByCategoriesForSale={groupedNftsByCategoriesForSale}
          categoriesStart={categoriesStart}
          categoriesEnd={categoriesEnd}
          setCategoriesStart={setCategoriesStart}
          setSortByValue={setSortByValue}
          groupedNftsByCategoriesForSaleLoading={
            groupedNftsByCategoriesForSaleLoading
          }
          setCategoriesEnd={setCategoriesEnd}
        />
      </div>
    </>
  );
};

export default MarketPlace;
