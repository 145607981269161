import React, { useEffect, useState } from 'react';
import classes from './Create.module.css';
import ipfs from '../../ipfs';
import { useDropzone } from 'react-dropzone';
import {
  createNFTRequest,
  // getTrendingCategoriesRequest,
  updateNftPreviewUrlRequest,
  uploadAudioForSpecificNftRequest,
} from '../../httpRequests/httpRequests';
import {
  isInputValidationCompleted,
  isOverallFormValid,
  limitString,
} from '../../helpers/helpers';
import notify from '../../utils/notify';
import { useHistory } from 'react-router';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth-context';
import MetaTags from 'react-meta-tags';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Input from '../../components/Input/Input';
import TextArea from '../../components/TextArea/TextArea';
import Button from '../../components/Button/Button';
import PinkRemoveIcon from '../../assets/pink-remove.png';
import UploadNftImage from '../../assets/upload-nft.png';
// import { useQuery } from 'react-query';
import BenefitsModal from './components/BenefitsModal/BenefitsModal';
import AudioUpload from '../../components/AudioUpload/AudioUpload';

const POPULAR_BENEFITS = [
  '1/1 Chat (15mins)',
  '1/1 Video Call (15mins)',
  'Personalized Image',
  'Personalized Video',
];

const GLMS_TO_USD_PRICE = 0.01;

const Create = () => {
  const { getMyNfts } = useContext(AuthContext);
  const history = useHistory();

  // const trendingCategoriesResponse = useQuery(
  //   'trending-categories',
  //   getTrendingCategoriesRequest
  // );

  const [nftInformation, setNftInformation] = useState({
    ipfsHash: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
    title: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
    description: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
    buyNowPrice: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
    royalteFee: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
  });
  const [isSafeContentSelected] = useState(true);
  const [isVideo, setIsVideo] = useState('');
  const [loadingIPFS, setLoadingIPFS] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [creatingNft, setCreatingNft] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const [categoryInputValueTouched, setCategoryInputValueTouched] = useState(
    ''
  );
  const [previewUrl, setPreviewUrl] = useState();
  const [previewUrlSize, setPreviewUrlSize] = useState();
  const [nftUrl, setNftUrl] = useState();

  const [unlockContentValue, setUnlockContentValue] = useState('');
  const [benefits, setBenefits] = useState([]);
  const [, setIncludeBenefits] = useState(false);
  const [showBenefitsModal, setShowBenefitsModal] = useState(false);

  const [includeAudio, setIncludeAudio] = useState(false);
  const [audioUrl, setAudioUrl] = useState();
  const [audioPreviewUrl, setAudioPreviewUrl] = useState();

  const inputChangeHandler = (formKey, inputValue) => {
    const updatedNftInformation = { ...nftInformation };
    updatedNftInformation[formKey].value = inputValue;

    updatedNftInformation[formKey].touched = true;
    updatedNftInformation[formKey].valid = isInputValidationCompleted(
      inputValue,
      updatedNftInformation[formKey].validationRequirements
    );

    if (formKey === 'royalteFee') {
      if (inputValue.length >= 1) {
        updatedNftInformation[formKey].value = inputValue[0];
      }

      updatedNftInformation[formKey].valid =
        !isNaN(updatedNftInformation[formKey].value) &&
        parseInt(updatedNftInformation[formKey].value) >= 0 &&
        parseInt(updatedNftInformation[formKey].value) <= 5;
    }

    if (formKey === 'buyNowPrice') {
      updatedNftInformation[formKey].valid =
        !isNaN(updatedNftInformation[formKey].value) &&
        parseFloat(updatedNftInformation[formKey].value) >= 5;
    }

    setIsFormValid(isOverallFormValid(updatedNftInformation));
    setNftInformation(updatedNftInformation);
  };

  useEffect(() => {
    const addCategoryInputValueToCategoriesOnEnterClick = (e) => {
      if (
        e.key === 'Enter' &&
        categoryInputValue.trim().length > 0 &&
        categories.length < 3
      ) {
        setCategories((prevState) => [...prevState, categoryInputValue]);
        setCategoryInputValue('');
      }
    };

    document.addEventListener(
      'keyup',
      addCategoryInputValueToCategoriesOnEnterClick
    );

    return () =>
      document.removeEventListener(
        'keyup',
        addCategoryInputValueToCategoriesOnEnterClick
      );
  }, [categoryInputValue, categories]);

  const trendingCategoryClickHandler = (trendingCategory) => {
    if (categories.includes(trendingCategory)) {
      setCategories((prevState) =>
        prevState.filter((el) => el !== trendingCategory)
      );
    } else if (categories.length < 3) {
      setCategories((prevState) => [...prevState, trendingCategory]);
    }
  };

  const categoryChangeHandler = (e) => {
    setCategoryInputValue(e.target.value.trim());
    setCategoryInputValueTouched(true);
    if (
      e.target.value.indexOf(' ') !== -1 &&
      e.target.value.trim().length >= 1
    ) {
      setCategories((prevState) => [...prevState, categoryInputValue]);
      setCategoryInputValue('');
    }
  };

  const removeCategoryHandler = (index) => {
    setCategories((prevState) => prevState.filter((_, i) => i !== index));
  };

  const addBenefitHandler = (benefit) => {
    setBenefits((prevState) => [
      ...prevState,
      {
        value: benefit,
        isValid: true,
        touched: true,
      },
    ]);
  };

  const removeBenefitHandler = (benefit) => {
    setBenefits((prevState) => prevState.filter((el) => el.value !== benefit));
  };

  const uploadNftHandler = async (e) => {
    e.preventDefault();

    setCreatingNft(true);

    const requestBody = {
      ipfs: nftInformation.ipfsHash.value,
      title: nftInformation.title.value,
      category: categories[0],
      additionalCategories: categories.slice(1),
      description: nftInformation.description.value,
      adultContent: !isSafeContentSelected,
      royalteFee: nftInformation.royalteFee.value,
      buyNowPrice: parseFloat(
        nftInformation.buyNowPrice.value / GLMS_TO_USD_PRICE
      ),
      type: isVideo ? 'video' : 'image',
      isPartOfCollection: false,
    };

    if (unlockContentValue) {
      requestBody['unlockableContent'] = unlockContentValue;
    }

    if (benefits.length > 0) {
      requestBody['benefits'] = benefits.map((benefit) => benefit.value);
    }

    try {
      const { data } = await createNFTRequest(requestBody);
      getMyNfts();

      if (previewUrlSize < 20000000) {
        const formData = new FormData();
        formData.append('previewUrl', nftUrl);
        updateNftPreviewUrlRequest(data._id, formData);
      }

      if (includeAudio && audioUrl) {
        const audioFormData = new FormData();
        audioFormData.append('audioUrl', audioUrl);
        try {
          await uploadAudioForSpecificNftRequest(data._id, audioFormData);
        } catch (err) {}
      }

      setCreatingNft(false);
      history.push(`/nft-created-successfully?id=${data._id}`);
    } catch (error) {
      setCreatingNft(false);
      notify('error', error, 2000);
    }
  };

  const addToIpfs = async (content) => {
    try {
      const added = await ipfs.add(content);
      const hash = added.cid.toString();

      inputChangeHandler('ipfsHash', hash);
      setLoadingIPFS(false);

      return hash;
    } catch (err) {
      notify('error', err, 2000);
      console.log(err);
    }
  };

  const audioUploadHandler = (value) => {
    if (!value) return;
    const fileReader = new FileReader();

    if (value.size > 10000000) {
      notify('error', 'File size must be less than 10MB!', 3000);
      return;
    }

    setAudioUrl(value);
    try {
      fileReader.onload = () => {
        setAudioPreviewUrl(fileReader.result);
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const onDrop = (files) => {
    setLoadingIPFS(true);
    if (files.length === 1) {
      if (files[0].size > 40000000) {
        notify('error', 'File size must be less than 40MB!', 3000);
        setLoadingIPFS(false);
        return;
      }
      let ext = files[0].name.split('.');
      ext = ext[ext.length - 1];
      ext = ext.toLowerCase();

      if (
        ext !== 'jpg' &&
        ext !== 'JPG' &&
        ext !== 'jpeg' &&
        ext !== 'JPEG' &&
        ext !== 'png' &&
        ext !== 'PNG' &&
        ext !== 'mp4' &&
        ext !== 'MP4'
      ) {
        notify('error', 'Invalid file type', '', 2000);
        return;
      }
      if (ext === 'mp4' || ext === 'MP4') setIsVideo(true);
      else setIsVideo(false);

      setPreviewUrlSize(files[0].size);
      setNftUrl(files[0]);

      const fileReader = new FileReader();
      fileReader.onload = () => setPreviewUrl(fileReader.result);
      fileReader.readAsDataURL(files[0]);

      var reader = new FileReader();
      reader.readAsArrayBuffer(files[0]);
      reader.onloadend = () => {
        addToIpfs(Buffer(reader.result));
      };
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const titleStyle = {};
  const descriptionStyle = {};
  const categoryStyle = {};
  const royalteFeeStyle = { width: '100%' };
  const buyNowPriceStyle = { width: '100%' };

  if (nftInformation.title.valid === false && nftInformation.title.touched) {
    titleStyle.border = '1px solid red';
  }

  if (
    nftInformation.description.valid === false &&
    nftInformation.description.touched
  ) {
    descriptionStyle.border = '1px solid red';
  }

  if (
    categoryInputValue.length === 0 &&
    categories.length === 0 &&
    categoryInputValueTouched
  ) {
    categoryStyle['border'] = '1px solid red';
  }

  if (
    nftInformation.royalteFee.valid === false &&
    nftInformation.royalteFee.touched
  ) {
    royalteFeeStyle['border'] = '1px solid red';
  }

  if (
    nftInformation.buyNowPrice.valid === false &&
    nftInformation.buyNowPrice.touched
  ) {
    buyNowPriceStyle['border'] = '1px solid red';
  }

  const trendingCategories = [
    'Art',
    'Collectibles',
    'Photography',
    'Sports',
    'Trading Cards',
    'Music',
    'Profile Picture',
    'Animals',
    'Nature',
    'Painting',
    '3D Abstract',
    'AI',
    'Meme',
    'Video',
  ];
  // const trendingCategories = useMemo(() => {
  //   if (!trendingCategoriesResponse.data) return [];

  //   const popularCategories = [
  //     'Art',
  //     'Collectibles',
  //     'Photography',
  //     'Sports',
  //     'Trading Cards',
  //     'Music',
  //     'Profile Picture',
  //     'Animals',
  //     'Nature',
  //     'Painting',
  //     '3D Abstract',
  //     'AI',
  //     'Meme',
  //     'Video',
  //   ];

  //   return Array.from(
  //     new Set([
  //       ...popularCategories,
  //       ...trendingCategoriesResponse.data.data.map(({ _id }) => _id),
  //     ])
  //   );
  // }, [trendingCategoriesResponse]);

  return (
    <>
      <MetaTags>
        <title>Create NFT | Glimpse</title>
        <meta property="og:title" content={'Create NFT | Glimpse'} />
      </MetaTags>

      {showBenefitsModal && (
        <BenefitsModal
          setShowBenefitsModal={setShowBenefitsModal}
          setIncludeBenefits={setIncludeBenefits}
          benefits={benefits}
          setBenefits={setBenefits}
          POPULAR_BENEFITS={POPULAR_BENEFITS}
        />
      )}

      <div className={classes['create-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['nft-form-container']}>
          <div className={classes['details-container']}>
            <div className={classes['enter-details-type-container']}>
              <h3>Enter Collectible Details</h3>
              <div className={classes['image-video-audio-sections-container']}>
                <p
                  onClick={() => setIncludeAudio(false)}
                  className={
                    includeAudio
                      ? classes['section-text']
                      : classes['section-text-selected']
                  }
                >
                  Image/Video
                </p>
                <p
                  onClick={() => setIncludeAudio(true)}
                  className={
                    !includeAudio
                      ? classes['section-text']
                      : classes['section-text-selected']
                  }
                >
                  Audio
                </p>
              </div>
            </div>

            <p className={classes['title-text']}>Title</p>
            <Input
              style={{ width: '100%', ...titleStyle }}
              placeholder="Enter here"
              value={nftInformation.title.value}
              onChange={(e) => inputChangeHandler('title', e.target.value)}
            />

            <div className={classes['categories-container']}>
              <p className={classes['category-text']}>
                Categories (Maximum 3 - Separate via Space/Enter)
              </p>
              <div className={classes['categories-input']}>
                {categories.map((category, i) => (
                  <div
                    title={`#${category}`}
                    key={category + i}
                    className={classes['category']}
                  >
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                        margin: 0,
                      }}
                    >
                      #{limitString(category, 16)}
                    </p>
                    <img
                      alt="Remove"
                      className={classes['remove-icon']}
                      onClick={() => removeCategoryHandler(i)}
                      src={PinkRemoveIcon}
                      width={8}
                      height={9}
                    />
                  </div>
                ))}
                <Input
                  style={{ width: '100%', ...categoryStyle }}
                  placeholder={categories.length === 3 ? '' : '#Enter here'}
                  value={categoryInputValue}
                  disabled={categories.length === 3}
                  onChange={categoryChangeHandler}
                />
              </div>

              <div className={classes['trending-categories']}>
                {trendingCategories.map((trendingCategory, i) => {
                  const containerStyle = {};
                  if (categories.includes(trendingCategory)) {
                    containerStyle['border'] = '1px solid #F900B7';
                  }
                  return (
                    <div
                      style={containerStyle}
                      onClick={() =>
                        trendingCategoryClickHandler(trendingCategory)
                      }
                      className={classes['trending-category-container']}
                      key={trendingCategory + i}
                    >
                      <p
                        style={{
                          color:
                            categories.includes(trendingCategory) && '#F900B7',
                        }}
                        className={classes['trending-category-text']}
                      >
                        {trendingCategory}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classes['description-container']}>
              <p className={classes['description-text']}>Description</p>
              <TextArea
                placeholder="Enter here"
                style={{
                  ...descriptionStyle,
                  width: '100%',
                  minHeight: 80,
                }}
                value={nftInformation.description.value}
                onChange={(e) =>
                  inputChangeHandler('description', e.target.value)
                }
              />
            </div>

            <div className={classes['price-royalty-container']}>
              <div style={{ width: '100%' }}>
                <p style={{ margin: 0 }}>
                  {/* <span className={classes['royalte-fee-text']}>
                    Price (Min 500 GLMS){' '}
                  </span> */}
                  <span className={classes['royalte-fee-text']}>
                    Price (Min 5 USD){' '}
                  </span>
                  {/* {nftInformation.buyNowPrice.value ? (
                    <span className={classes['dollar-equivalent-text']}>
                      = $
                      {parseFloat(
                        nftInformation.buyNowPrice.value * glmsToUsd.price
                      ).toFixed(2)}
                    </span>
                  ) : (
                    <span className={classes['dollar-equivalent-text']}>
                      1 GLMS = .01 USD
                    </span>
                  )} */}
                </p>
                <Input
                  type="number"
                  style={buyNowPriceStyle}
                  placeholder="Enter USD amount"
                  value={nftInformation.buyNowPrice.value}
                  onChange={(e) =>
                    inputChangeHandler('buyNowPrice', e.target.value)
                  }
                />
              </div>
              <div style={{ width: '100%' }}>
                <p className={classes['royalte-fee-text']}>
                  Royalte fee (0% - 5%)
                </p>
                <Input
                  type="number"
                  style={{ ...royalteFeeStyle }}
                  placeholder="0%"
                  value={nftInformation.royalteFee.value}
                  onChange={(e) =>
                    inputChangeHandler('royalteFee', e.target.value)
                  }
                />
              </div>
            </div>

            <div>
              <div className={classes['unlock-content-container']}>
                <div className={classes['unlock-slider-container']}>
                  <h3 className={classes['unlock-content-text']}>
                    Unlock Once Purchased (Optional)
                  </h3>
                  {/* <SliderButton
                    leftValue={''}
                    rightValue={''}
                    isLeftContentSelected={!unlockContentSelected}
                    setIsLeftContentSelected={setUnlockContentSelected}
                  /> */}
                </div>
                <TextArea
                  placeholder="Example: Special access code, link to file"
                  value={unlockContentValue}
                  onChange={(e) => setUnlockContentValue(e.target.value)}
                  style={{ width: '100%', margin: '10px 0' }}
                  // disabled={!unlockContentSelected}
                />
              </div>

              <div className={classes['benefits-container']}>
                <div className={classes['benefits-slider-container']}>
                  <h3 className={classes['benefits-content-text']}>Benefits</h3>
                  <h3
                    style={{ cursor: 'pointer' }}
                    className={classes['benefits-content-text']}
                    onClick={() => setShowBenefitsModal(true)}
                  >
                    Add Custom
                  </h3>
                </div>

                <div className={classes['popular-benefits-container']}>
                  {POPULAR_BENEFITS.map((benefit, i) => {
                    const isBenefitIncluded = benefits.some(
                      (el) => el.value === benefit
                    );

                    return (
                      <div
                        key={benefit + i}
                        onClick={
                          isBenefitIncluded
                            ? () => removeBenefitHandler(benefit)
                            : () => addBenefitHandler(benefit)
                        }
                        className={
                          isBenefitIncluded
                            ? classes['selected-benefit-container']
                            : classes['benefit-container']
                        }
                      >
                        <p>{benefit}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={classes['audio-file-container']}>
              <div className={classes['upload-container']}>
                <div className={classes['upload-img-container']}>
                  <input {...getInputProps()} multiple={false} />
                  <div>
                    {!previewUrl && !loadingIPFS ? (
                      <div
                        {...getRootProps()}
                        className={classes['upload-container']}
                      >
                        <img
                          className={classes['upload-img']}
                          src={UploadNftImage}
                          alt="Upload"
                          width={60}
                        />
                        <p className={classes['click-to-upload-text']}>
                          Click To Upload Image/Video
                        </p>
                      </div>
                    ) : loadingIPFS ? (
                      <LoadingSpinner />
                    ) : previewUrl && !loadingIPFS ? (
                      <div>
                        {isVideo ? (
                          <video className={classes['nft-video']} controls>
                            <source src={previewUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            className={classes['nft-image']}
                            alt="IPFS-hash"
                            src={previewUrl}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {includeAudio && (
                <AudioUpload onInput={audioUploadHandler}>
                  {audioPreviewUrl ? (
                    <audio src={audioPreviewUrl} controls>
                      {/* <source src={audioPreviewUrl} type="audio/ogg" /> */}
                    </audio>
                  ) : (
                    <div className={classes['upload-img-container']}>
                      <div className={classes['upload-container']}>
                        <img
                          className={classes['upload-img']}
                          src={UploadNftImage}
                          alt="Upload"
                          width={60}
                        />
                        <p className={classes['click-to-upload-text']}>
                          Click To Upload Audio
                        </p>
                      </div>
                    </div>
                  )}
                </AudioUpload>
              )}
            </div>

            <Button
              style={{ width: '100%', marginTop: 20 }}
              darkpink="true"
              disabled={
                isFormValid === false ||
                categories.length === 0 ||
                creatingNft ||
                (includeAudio && !audioUrl)
              }
              onClick={uploadNftHandler}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Create;
