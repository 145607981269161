import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../context/auth-context';
import { updateProfileRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import Button from '../../../Button/Button';
import Input from '../../../Input/Input';
import Modal from '../../../Modal/Modal';
import ChangeUsernameModal from '../ChangeUsernameModal/ChangeUsernameModal';
import classes from './PersonalDetailsModal.module.css';
import RequestEmailChangeModal from '../RequestEmailChangeModal/RequestEmailChangeModal';

function PersonalDetailsModal(props) {
  const { setShowPersonalDetailsModal, setShowSettingsModal } = props;
  const { profile, updateProfileInformation } = useContext(AuthContext);

  const [firstName, setFirstName] = useState(profile?.firstName);
  const [lastName, setLastName] = useState(profile?.lastName);
  const [changingProfileInformation, setChangingProfileInformation] = useState(
    false
  );
  const [showChangeUsernameModal, setShowChangeUsernameModal] = useState(false);
  const [
    showRequestEmailChangeModal,
    setShowRequestEmailChangeModal,
  ] = useState(false);

  const closeModalHandler = () => {
    setShowPersonalDetailsModal(false);
  };

  const firstNameChangeHandler = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameChangeHandler = (e) => {
    setLastName(e.target.value);
  };

  const openChangeUsernameModalHandler = () => {
    setShowChangeUsernameModal(true);
  };

  const openChangeEmailModalHandler = () => {
    setShowRequestEmailChangeModal(true);
  };

  const changeProfileInformationHandler = async () => {
    const requestBody = {
      firstName,
      lastName,
      fullName: `${firstName} ${lastName}`,
    };

    setChangingProfileInformation(true);
    try {
      await updateProfileRequest(requestBody);
      updateProfileInformation({ ...profile, ...requestBody });
    } catch (error) {
      notify('error', error, 2000);
    }

    setChangingProfileInformation(false);
    setShowSettingsModal(false);
  };

  const firstNameInputStyle = { width: '100%' };
  const lastNameInputStyle = { width: '100%' };
  if (firstName.length === 0) {
    firstNameInputStyle['border'] = '1px solid red';
  }
  if (lastName.length === 0) {
    lastNameInputStyle['border'] = '1px solid red';
  }

  const isSaveButtonDisabled =
    firstName.length === 0 ||
    lastName.length === 0 ||
    changingProfileInformation;

  return (
    <>
      {showChangeUsernameModal && (
        <ChangeUsernameModal
          setShowSettingsModal={setShowSettingsModal}
          setShowChangeUsernameModal={setShowChangeUsernameModal}
        />
      )}

      {showRequestEmailChangeModal && (
        <RequestEmailChangeModal
          setShowRequestEmailChangeModal={setShowRequestEmailChangeModal}
        />
      )}

      {!showChangeUsernameModal && !showRequestEmailChangeModal && (
        <Modal
          footer={
            <div className={classes['footer']}>
              <Button
                style={{ width: 200 }}
                onClick={closeModalHandler}
                darkpinkcolor="true"
              >
                Cancel
              </Button>
              <Button
                disabled={isSaveButtonDisabled}
                style={{ width: 200 }}
                onClick={changeProfileInformationHandler}
                darkpink="true"
              >
                Save
              </Button>
            </div>
          }
          closeModal={closeModalHandler}
          title="Personal Details"
        >
          <p className={classes['label']}>First Name</p>
          <Input
            style={firstNameInputStyle}
            value={firstName}
            onChange={firstNameChangeHandler}
          />

          <p className={classes['label']}>Last Name</p>
          <Input
            style={lastNameInputStyle}
            value={lastName}
            onChange={lastNameChangeHandler}
          />

          <div className={classes['username-change-container']}>
            <p className={classes['label']}>Username</p>
            <p
              onClick={openChangeUsernameModalHandler}
              className={classes['change-text']}
            >
              Change
            </p>
          </div>
          <Input style={{ width: '100%' }} value={profile?.username} disabled />

          <div className={classes['email-change-container']}>
            <p className={classes['label']}>Email</p>
            <p
              onClick={openChangeEmailModalHandler}
              className={classes['change-text']}
            >
              Change
            </p>
          </div>
          <Input style={{ width: '100%' }} disabled value={profile?.email} />
        </Modal>
      )}
    </>
  );
}

export default PersonalDetailsModal;
