import React, { useContext, useState } from 'react';
import classes from './SentNftOffer.module.css';
import { IPFS_URL } from '../../constants/IPFS';
import VerifiedIcon from '../../assets/verified.png';
import moment from 'moment';
import { CoinMarketCapContext } from '../../context/coinmarketcap-context';
import { getFormattedNumber } from '../../helpers/helpers';
import Button from '../Button/Button';
import { deleteMyNftOfferRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import { useQueryClient } from 'react-query';
import UpdateNftOfferPriceModal from '../UpdateNftOfferPriceModal/UpdateNftOfferPriceModal';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { AuthContext } from '../../context/auth-context';

function SentNftOffer(props) {
  const { getMyLockedGLMSBalance } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { nftOffer } = props;
  const { nft, seller, price, comment } = nftOffer;
  const { glmsToUsd } = useContext(CoinMarketCapContext);
  const [cancellingNftOffer, setCancellingNftOffer] = useState(false);
  const [
    showUpdateNftOfferPriceModal,
    setShowUpdateNftOfferPriceModal,
  ] = useState(false);

  const invalidateSentNftOffers = async () => {
    await Promise.all([
      queryClient.invalidateQueries(['my-sent-nft-offers']),
      queryClient.refetchQueries(['my-sent-nft-offers']),
    ]);
  };

  const cancelNftOfferHandler = async () => {
    setCancellingNftOffer(true);

    try {
      await deleteMyNftOfferRequest(nftOffer._id);
      await invalidateSentNftOffers();

      getMyLockedGLMSBalance();
    } catch (err) {
      notify('error', err, 2000);
    }

    setCancellingNftOffer(false);
  };

  const openUpdateNftOfferPriceModalHandler = () => {
    setShowUpdateNftOfferPriceModal(true);
  };

  return (
    <>
      {showUpdateNftOfferPriceModal && (
        <UpdateNftOfferPriceModal
          nftOffer={nftOffer}
          setShowUpdateNftOfferPriceModal={setShowUpdateNftOfferPriceModal}
        />
      )}

      <div className={classes['nft-offer-container']}>
        {nft.type === 'image' ? (
          <Link to={`/nfts/${nft._id}`}>
            <img
              className={classes['nft-image']}
              src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
              alt="IPFS Hash"
            />
          </Link>
        ) : (
          <Link to={`/nfts/${nft._id}`}>
            <ReactPlayer
              style={{
                objectFit: 'cover',
                borderRadius: 10,
              }}
              width={330}
              height={300}
              url={`${IPFS_URL}/${nft.ipfs}`}
            />
          </Link>
        )}

        <div className={classes['offer-info-container']}>
          <div className={classes['buyer-info-container']}>
            <img
              className={classes['buyer-photo']}
              src={seller.photo}
              alt="Buyer"
            />
            <p className={classes['buyer-fullName']}>{seller.fullName}</p>
            {seller.verified && (
              <img width={14} src={VerifiedIcon} alt="Verified" />
            )}
          </div>

          <div className={classes['title-price-container']}>
            <p className={classes['nft-title']}>{nft.title}</p>
            {/* <p className={classes['price']}>{getFormattedNumber(price)} GLMS</p> */}
            <p className={classes['price']}>
              {getFormattedNumber((price * glmsToUsd.price).toFixed(2))} USD
            </p>
          </div>
          <div className={classes['received-usd-price-container']}>
            <p className={classes['received-on']}>
              Received on:{' '}
              {moment(nftOffer.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
            </p>
            {/* <p className={classes['usd-price']}>
              ${getFormattedNumber((price * glmsToUsd.price).toFixed(2))}
            </p> */}
          </div>

          <p className={classes['comment-text']}>
            {comment ? comment : 'No Comment Found!'}
          </p>

          <div className={classes['actions-container']}>
            <Button
              onClick={openUpdateNftOfferPriceModalHandler}
              disabled={cancellingNftOffer}
              style={{ padding: '.5rem 2rem', width: '100%' }}
              darkpink="true"
            >
              Update
            </Button>
            <Button
              style={{ width: '100%' }}
              disabled={cancellingNftOffer}
              onClick={cancelNftOfferHandler}
              darkpinkcolor="true"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SentNftOffer;
