import React, { useContext } from 'react';
import classes from './Routes.module.css';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import './pages/Landing/landing.scss';
import Landing from './pages/Landing/Landing';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import Create from './pages/Create/Create';
import ViewNFTsForCategories from './pages/MarketPlace/ViewNFtsForCategories';
import Nft from './pages/NftViewContent/Nft';
import { AuthContext } from './context/auth-context';
import { Redirect } from 'react-router-dom';
import Chats from './pages/Chats/Chats';
import Wallet from './pages/Wallet/Wallet';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import UserStatistics from './pages/UserStatistics/UserStatistics';
import NewsFeed from './pages/NewsFeed/NewsFeed';
import MySpace from './pages/MySpace/MySpace';
import UserFeed from './pages/UserFeed/UserFeed';
import UserPost from './pages/UserPost/UserPost';
import Watchlist from './pages/Watchlist/Watchlist';
import NftCreatedSuccessfully from './pages/NftCreatedSuccessfully/NftCreatedSuccessfully';
import Notifications from './pages/Notifications/Notifications';
import CreateGroup from './pages/CreateGroup/CreateGroup';
import MyGroups from './pages/MyGroups/MyGroups';
import Groups from './pages/Groups/Groups';
import Group from './pages/Group/Group';
import NftCollections from './pages/NftCollections/NftCollections';
import NftCollectionViewContent from './pages/NftCollectionViewContent/NftCollectionViewContent';
import NftOffers from './pages/NftOffers/NftOffers';
import RequestNft from './pages/RequestNft/RequestNft';
import RequestedNfts from './pages/RequestedNfts/RequestedNfts';
import Referrals from './pages/Referrals/Referrals';
import ApplyAsCreator from './pages/ApplyAsCreator/ApplyAsCreator';
import SuggestedGroups from './pages/SuggestedGroups/SuggestedGroups';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ClaimRandomNftFromClaimableCollection from './pages/ClaimRandomNftFromClaimableCollection/ClaimRandomNftFromClaimableCollection';
import Support from './pages/Support/Support';
import Events from './pages/Events/Events';
import EventViewContent from './pages/EventViewContent/EventViewContent';
import CollaboratorRequests from './pages/CollaboratorRequests/CollaboratorRequests';
import Explore from './pages/Explore/Explore';
import GetStarted from './pages/GetStarted/GetStarted';
import RequestedServices from './pages/RequestedServices/RequestedServices';
import CompleteRequestedService from './pages/CompleteRequestedService/CompleteRequestedService';
import CreateBrandItem from './pages/CreateBrandItem/CreateBrandItem';
import BrandItemViewContent from './pages/BrandItemViewContent/BrandItemViewContent';

const { Content } = Layout;

export const MAIN_PART_OPTIONS = {
  CONTENT_PROFILE: 'CONTENT_PROFILE',
  OFFERS: 'OFFERS',
  WATCHLIST: 'WATCHLIST',
  LIKES: 'LIKES',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SETTINGS: 'SETTINGS',
};

const Routes = React.memo(() => {
  const location = useLocation();
  const { profile, autoLoginLoading } = useContext(AuthContext);

  if (autoLoginLoading) return null;

  return (
    <Layout>
      {/* {location.pathname !== '/' &&
        location.pathname !== '/apply-as-creator' &&
        location.pathname !== '/nftnyc' && <Header />} */}

      <div
        style={{
          marginTop:
            (location.pathname === '/' ||
              location.pathname === '/apply-as-creator' ||
              location.pathname === '/nftnyc') &&
            0,
        }}
        className={classes['content-container']}
      >
        <Content>
          {profile ? (
            <Switch>
              <Route exact path="/" component={NewsFeed} />

              <Route exact path="/create" component={Create} />
              {/* <Route exact path="/marketplace" component={MarketPlace} /> */}
              <Route exact path="/nfts/:nftId" component={Nft} />
              <Route
                exact
                path="/marketplace/:id"
                component={ViewNFTsForCategories}
              />

              <Route exact path="/user-feed/:userId" component={UserFeed} />
              <Route exact path="/user-posts/:postId" component={UserPost} />

              <Route exact path="/news-feed" component={NewsFeed} />
              <Route
                exact
                path="/nft-created-successfully"
                component={NftCreatedSuccessfully}
              />
              <Route exact path="/chats" component={Chats} />
              <Route exact path="/notifications" component={Notifications} />
              <Route exact path="/wallet" component={Wallet} />
              <Route exact path="/watchlist" component={Watchlist} />
              <Route exact path={'/my-space'} component={MySpace} />
              <Route exact path={'/create-group'} component={CreateGroup} />
              <Route exact path={'/groups'} component={Groups} />
              <Route exact path={'/my-groups'} component={MyGroups} />
              <Route exact path={`/groups/:id`} component={Group} />
              <Route exact path={`/collections`} component={NftCollections} />
              <Route exact path={`/nft-offers`} component={NftOffers} />
              <Route
                exact
                path={`/request-nft/:userId`}
                component={RequestNft}
              />
              <Route exact path={`/requested-nfts`} component={RequestedNfts} />
              <Route exact path={`/referrals`} component={Referrals} />
              {/* <Route exact path={`/mux`} component={Mux} /> */}
              {/* <Route exact path={`/simplex`} component={Simplex} /> */}

              <Route exact path={'/get-started'} component={GetStarted} />

              <Route
                exact
                path={`/suggested-groups`}
                component={SuggestedGroups}
              />

              <Route
                exact
                path="/apply-as-creator"
                component={ApplyAsCreator}
              />
              <Route
                exact
                path={`/collections/:id`}
                component={NftCollectionViewContent}
              />

              <Route
                exact
                path={`/claim-nft/:collectionId`}
                component={ClaimRandomNftFromClaimableCollection}
              />

              <Route exact path="/events" component={Events} />
              <Route exact path="/playlist" component={Events} />
              <Route exact path="/explore" component={Explore} />

              <Route exact path="/terms" component={TermsOfUse} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/support" component={Support} />

              <Route
                exact
                path="/events/:eventId"
                component={EventViewContent}
              />

              <Route
                exact
                path="/collaborator-requests"
                component={CollaboratorRequests}
              />

              <Route
                exact
                path="/requested-services"
                component={RequestedServices}
              />

              <Route
                exact
                path="/requested-services/:requestedServiceId"
                component={CompleteRequestedService}
              />

              <Route
                exact
                path="/create-brand-item"
                component={CreateBrandItem}
              />

              <Route
                exact
                path="/brand-items/:brandItemId"
                component={BrandItemViewContent}
              />

              <ProtectedRoute
                exact
                path="/admin-panel"
                Component={AdminPanel}
                accessCondition={'is-admin'}
              />
              <ProtectedRoute
                exact
                path="/user-stats/:userId"
                Component={UserStatistics}
                accessCondition={'is-admin'}
              />

              <Redirect to="/news-feed" />
            </Switch>
          ) : (
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/nftnyc" component={Landing} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/create" component={Login} />
              {/* <Route exact path="/marketplace" component={MarketPlace} /> */}

              {/* <Route exact path="/user-feed/:userId" component={UserFeed} /> */}
              <Route exact path="/user-posts/:postId" component={UserPost} />
              <Route exact path="/nfts/:nftId" component={Nft} />

              <Route
                exact
                path={`/collections/:id`}
                component={NftCollectionViewContent}
              />

              {/* <Route exact path="/rankings" component={Rankings} /> */}
              <Route
                exact
                path="/apply-as-creator"
                component={ApplyAsCreator}
              />

              <Route exact path="/explore" component={Explore} />
              <Route exact path={`/collections`} component={NftCollections} />

              {/* <Route exact path="/github-success" component={GithubSuccess} /> */}
              <Route exact path="/terms" component={TermsOfUse} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/support" component={Support} />

              <Route
                exact
                path={`/claim-nft/:collectionId`}
                component={ClaimRandomNftFromClaimableCollection}
              />

              <Redirect to="/" />
            </Switch>
          )}
        </Content>
      </div>
    </Layout>
  );
});

export default Routes;
