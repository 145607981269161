import React, { useEffect, useState } from 'react';
import classes from './AlbumPhotos.module.css';
import { useQueryClient } from 'react-query';
import AlbumPhoto from './components/AlbumPhoto/AlbumPhoto';
import Button from '../../../../../../components/Button/Button';
import ImageUpload from '../../../../../../components/ImageUpload/ImageUpload';
import { uploadAlbumPhotoRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';

function AlbumPhotos(props) {
  const queryClient = useQueryClient();
  const { selectedAlbumId, setSelectedPhotoOption } = props;

  const [previewUrl, setPreviewUrl] = useState();
  const [imageUrl, setImageUrl] = useState();

  const { data } = queryClient.getQueryData('my-user-image-albums');
  const selectedAlbum = data.find((el) => el._id === selectedAlbumId);

  const inputImageHandler = (value) => {
    if (!value) return;

    if (value.size > 10000000)
      return notify(
        'error',
        'You can not upload more than 10MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setImageUrl(value);
    try {
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  useEffect(() => {
    const uploadAlbumPhotoOnPreviewUrlChange = async () => {
      if (previewUrl) {
        await uploadAlbumPhotoHandler();
        setPreviewUrl(undefined);
      }
    };

    uploadAlbumPhotoOnPreviewUrlChange();
    // eslint-disable-next-line
  }, [previewUrl]);

  const uploadAlbumPhotoHandler = async () => {
    try {
      const formData = new FormData();

      formData.append('image', imageUrl);

      const { data: updatedAlbum } = await uploadAlbumPhotoRequest(
        selectedAlbum._id,
        formData
      );

      queryClient.setQueryData('my-user-image-albums', (data) => {
        const updatedData = data.data.map((el) => {
          if (el._id === updatedAlbum._id) return updatedAlbum;

          return el;
        });
        return {
          ...data,
          data: updatedData,
        };
      });
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  if (!selectedAlbum)
    return <p>You have not selected an Album. Start by selecting one!</p>;

  return (
    <>
      <div className={classes['album-photos-container']}>
        {/* <div className={classes['go-back-container']}>
          <GoBack goBackHandler={() => setSelectedPhotoOption('Albums')} />
          <h4 className={classes['albums-go-back-text']}>Albums</h4>
        </div> */}
        {selectedAlbum.images.length === 0 && (
          <div className={classes['no-images-container']}>
            {' '}
            <p className={classes['no-images-text']}>
              No Images on this Album. Try uploading the first one.
            </p>
          </div>
        )}

        <div className={classes['album-photos-grid']}>
          {selectedAlbum.images.map((image) => {
            return (
              <AlbumPhoto
                image={image}
                selectedAlbum={selectedAlbum}
                key={image + Math.random()}
              />
            );
          })}
        </div>

        <div className={classes['actions-container']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={() => setSelectedPhotoOption('Albums')}
          >
            Go Back
          </Button>

          <ImageUpload onInput={inputImageHandler}>
            <Button style={{ width: 200 }} darkpink="true">
              Upload New Photo
            </Button>
          </ImageUpload>
        </div>
      </div>
    </>
  );
}

export default AlbumPhotos;
