import React, { useContext, useState } from 'react';
import classes from './CompleteYourProfileModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import { AuthContext } from '../../../../context/auth-context';
import GrayCameraIcon from '../../../../assets/gray-camera-icon.png';
import TextArea from '../../../../components/TextArea/TextArea';
import Button from '../../../../components/Button/Button';
import notify from '../../../../utils/notify';
import ImageUpload from '../../../../components/ImageUpload/ImageUpload';
import {
  editIntroductionRequest,
  updateProfilePictureRequest,
  uploadCoverPhotoRequest,
} from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';

function CompleteYourProfileModal(props) {
  const queryClient = useQueryClient();
  const { getMyProfile } = useContext(AuthContext);
  const { setShowCompleteYourProfileModal, completeProfileTaskInfo } = props;

  const [introduction, setIntroduction] = useState(
    completeProfileTaskInfo.introduction
      ? completeProfileTaskInfo.introduction
      : ''
  );

  const [coverPhotoUrl, setCoverPhotoUrl] = useState();
  const [coverPhotoPreviewUrl, setCoverPhotoPreviewUrl] = useState(
    completeProfileTaskInfo.coverPhoto
  );
  const [uploadImageUrl, setUploadImageUrl] = useState();
  const [uploadImagePreviewUrl, setUploadImagePreviewUrl] = useState(
    completeProfileTaskInfo.photo
  );
  const [completingTask, setCompletingTask] = useState(false);

  const closeModalHandler = () => {
    setShowCompleteYourProfileModal(false);
  };

  const coverPhotoInputHandler = (value) => {
    if (!value) return;

    const fileReader = new FileReader();

    setCoverPhotoUrl(value);
    try {
      fileReader.onload = () => {
        setCoverPhotoPreviewUrl(fileReader.result);
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const inputImageHandler = (value) => {
    if (!value) return;

    const fileReader = new FileReader();

    setUploadImageUrl(value);
    try {
      fileReader.onload = () => {
        setUploadImagePreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const completeProfileTaskHandler = async () => {
    setCompletingTask(true);

    try {
      if (!completeProfileTaskInfo.coverPhoto && coverPhotoUrl) {
        const formData = new FormData();
        formData.append('coverPhoto', coverPhotoUrl);
        await uploadCoverPhotoRequest(formData);
      }

      if (!completeProfileTaskInfo.photo && uploadImageUrl) {
        const formData = new FormData();
        formData.append('photo', uploadImageUrl);
        await updateProfilePictureRequest(formData);
      }

      await editIntroductionRequest({
        introduction,
      });

      await getMyProfile();

      await Promise.all([
        queryClient.invalidateQueries('get-started-tasks-completion-info'),
        queryClient.refetchQueries('get-started-tasks-completion-info'),
      ]);

      setCompletingTask(false);

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      closeIcon="true"
      closeModal={closeModalHandler}
      title="Complete Your Profile"
    >
      <div>
        <div className={classes['cover-photo-container']}>
          {coverPhotoPreviewUrl ? (
            <img
              alt="Cover"
              src={coverPhotoPreviewUrl}
              className={classes['cover-photo']}
            />
          ) : (
            <ImageUpload onInput={coverPhotoInputHandler}>
              <img
                src={GrayCameraIcon}
                alt="CoverPhoto"
                className={classes['cover-camera-icon']}
              />
            </ImageUpload>
          )}
          <div className={classes['photo-container']}>
            {uploadImagePreviewUrl ? (
              <img
                alt="Camera"
                className={classes['photo']}
                src={uploadImagePreviewUrl}
              />
            ) : (
              <ImageUpload onInput={inputImageHandler}>
                <img
                  alt="CameraPhoto"
                  src={GrayCameraIcon}
                  className={classes['photo-camera-icon']}
                />
              </ImageUpload>
            )}
          </div>
        </div>

        <div className={classes['intro-container']}>
          <p className={classes['intro-text']}>Intro</p>
          <TextArea
            placeholder="Enter here"
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
            style={{ minHeight: 80, width: '100%' }}
          />
        </div>

        <Button
          onClick={completeProfileTaskHandler}
          disabled={
            !uploadImagePreviewUrl ||
            !coverPhotoPreviewUrl ||
            !introduction ||
            completingTask
          }
          darkpink="true"
          style={{ width: '100%', margin: '10px 0' }}
        >
          Complete
        </Button>
      </div>
    </Modal>
  );
}

export default CompleteYourProfileModal;
