import React, { useState } from 'react';
import Modal from '../../../../../../../../components/Modal/Modal';
import SelectSpecificFriendsModal from './components/SelectSpecificFriendsModal/SelectSpecificFriendsModal';
import Button from '../../../../../../../../components/Button/Button';
import classes from './SelectPostStatusModal.module.css';
import WorldIcon from '../../../../../../../../assets/public-icon.png';
import FriendsIcon from '../../../../../../../../assets/my-followers-icon.png';
// import SpecificFriendIcon from '../../../../../../../../assets/specific-friend.png';
import SpecificFriendIcon from '../../../../../../../../assets/specific-followers-icon.png';
// import OnlyMeIcon from '../../../../../../../../assets/only-me.png';
import OnlyMeIcon from '../../../../../../../../assets/only-me-icon.png';

import GrayArrowRightIcon from '../../../../../../../../assets/gray-arrow-right.png';
// import GrayCommunitiesIcon from '../../../../../../../../assets/gray-communities.png';
import GrayCommunitiesIcon from '../../../../../../../../assets/my-communities-icon.png';
import SelectMyCommunitiesModal from './components/SelectMyCommunitiesModal/SelectMyCommunitiesModal';
import CollectibleHoldersIcon from '../../../../../../../../assets/collectible-holders-icon.png';

function SelectPostStatusModal(props) {
  const {
    selectedPostStatus,
    setSelectedPostStatus,
    setShowSelectPostStatusModal,
    setSelectedFollowerIds,
    selectedFollowerIds,

    selectedCommunityIds,
    setSelectedCommunityIds,
  } = props;

  const [
    showSelectSpecificFriendsModal,
    setShowSelectSpecificFriendsModal,
  ] = useState(false);

  const [
    showSelectMyCommunitiesModal,
    setShowSelectMyCommunitiesModal,
  ] = useState(false);

  const postStatusChangeHandler = (postStatus) => {
    if (postStatus === 'Specific Followers') {
      setShowSelectSpecificFriendsModal(true);
    } else if (postStatus === 'My Communities') {
      setShowSelectMyCommunitiesModal(true);
    } else setSelectedPostStatus(postStatus);
  };

  const closeModalHandler = () => {
    setShowSelectPostStatusModal(false);
  };

  return (
    <>
      {showSelectSpecificFriendsModal && (
        <SelectSpecificFriendsModal
          setSelectedPostStatus={setSelectedPostStatus}
          setShowSelectPostStatusModal={setShowSelectPostStatusModal}
          selectedFollowerIds={selectedFollowerIds}
          setSelectedFollowerIds={setSelectedFollowerIds}
          setShowSelectSpecificFriendsModal={setShowSelectSpecificFriendsModal}
        />
      )}

      {showSelectMyCommunitiesModal && (
        <SelectMyCommunitiesModal
          setShowSelectMyCommunitiesModal={setShowSelectMyCommunitiesModal}
          selectedCommunityIds={selectedCommunityIds}
          setSelectedCommunityIds={setSelectedCommunityIds}
          setSelectedPostStatus={setSelectedPostStatus}
          setShowSelectPostStatusModal={setShowSelectPostStatusModal}
        />
      )}

      {!showSelectSpecificFriendsModal && !showSelectMyCommunitiesModal && (
        <Modal
          style={{ height: 600 }}
          footer={
            <div className={classes['footer']}>
              <Button
                style={{ width: 200 }}
                darkpinkcolor="true"
                onClick={closeModalHandler}
              >
                Cancel
              </Button>
              <Button
                darkpink="true"
                style={{ width: 200 }}
                onClick={closeModalHandler}
              >
                Save
              </Button>
            </div>
          }
          closeModal={closeModalHandler}
          title="Select Audience"
        >
          <div>
            <p className={classes['choose-who-can-see-text']}>
              {' '}
              Choose who can see your post on newsfeed
            </p>

            <div className={classes['select-post-status-modal-container']}>
              <div className={classes['post-status-option-container']}>
                <div className={classes['post-status-option']}>
                  <div className={classes['world-container']}>
                    <img
                      alt="World"
                      className={classes['world-icon']}
                      src={WorldIcon}
                      width={20}
                    />
                  </div>
                  <div>
                    <h3 className={classes['status-text']}>Public</h3>
                    <p className={classes['status-description']}>
                      Anyone on or off Glimpse
                    </p>
                  </div>
                </div>

                <div
                  onClick={() => postStatusChangeHandler('Public')}
                  className={
                    classes[
                      selectedPostStatus === 'Public'
                        ? 'selected'
                        : 'not-selected'
                    ]
                  }
                ></div>
              </div>

              <div className={classes['post-status-option-container']}>
                <div className={classes['post-status-option']}>
                  <div className={classes['world-container']}>
                    <img
                      alt="Friends"
                      className={classes['world-icon']}
                      src={FriendsIcon}
                      width={20}
                    />
                  </div>
                  <div>
                    <h3 className={classes['status-text']}>My Followers</h3>
                    <p className={classes['status-description']}>
                      Your followers on Glimpse
                    </p>
                  </div>
                </div>

                <div
                  onClick={() => postStatusChangeHandler('My Followers')}
                  className={
                    classes[
                      selectedPostStatus === 'My Followers'
                        ? 'selected'
                        : 'not-selected'
                    ]
                  }
                ></div>
              </div>
              <div className={classes['post-status-option-container']}>
                <div className={classes['post-status-option']}>
                  <div className={classes['world-container']}>
                    <img
                      alt="Specific Followers"
                      className={classes['world-icon']}
                      src={SpecificFriendIcon}
                      width={20}
                    />
                  </div>
                  <div>
                    <h3 className={classes['status-text']}>
                      Specific Followers
                    </h3>
                    <p className={classes['status-description']}>
                      Show to selected followers
                    </p>
                  </div>
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => postStatusChangeHandler('Specific Followers')}
                >
                  <img
                    alt="Arrow Right"
                    src={GrayArrowRightIcon}
                    width={9}
                    style={{ marginRight: 4.5 }}
                  />
                </div>
              </div>

              <div className={classes['post-status-option-container']}>
                <div className={classes['post-status-option']}>
                  <div className={classes['world-container']}>
                    <img
                      alt="My Communities"
                      className={classes['world-icon']}
                      src={GrayCommunitiesIcon}
                      width={20}
                    />
                  </div>

                  <div>
                    <h3 className={classes['status-text']}>My Communities</h3>
                    <p className={classes['status-description']}>
                      Visible to your selected Communities
                    </p>
                  </div>
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => postStatusChangeHandler('My Communities')}
                >
                  <img
                    alt="Arrow Right"
                    src={GrayArrowRightIcon}
                    width={9}
                    style={{ marginRight: 4.5 }}
                  />
                </div>
              </div>

              <div className={classes['post-status-option-container']}>
                <div className={classes['post-status-option']}>
                  <div className={classes['world-container']}>
                    <img
                      alt="Collectible Holders"
                      className={classes['world-icon']}
                      src={CollectibleHoldersIcon}
                      width={20}
                    />
                  </div>

                  <div>
                    <h3 className={classes['status-text']}>
                      Collectible Holders
                    </h3>
                    <p className={classes['status-description']}>
                      Visible to anyone who owns one of your NFTs
                    </p>
                  </div>
                </div>

                <div
                  onClick={() => postStatusChangeHandler('Collectible Holders')}
                  className={
                    classes[
                      selectedPostStatus === 'Collectible Holders'
                        ? 'selected'
                        : 'not-selected'
                    ]
                  }
                ></div>
              </div>

              <div className={classes['post-status-option-container']}>
                <div className={classes['post-status-option']}>
                  <div className={classes['world-container']}>
                    <img
                      alt="Only Me"
                      className={classes['world-icon']}
                      src={OnlyMeIcon}
                      width={20}
                    />
                  </div>

                  <div>
                    <h3 className={classes['status-text']}>Only Me</h3>
                    <p className={classes['status-description']}>
                      Only you will be able to see post
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => postStatusChangeHandler('Only Me')}
                  className={
                    classes[
                      selectedPostStatus === 'Only Me'
                        ? 'selected'
                        : 'not-selected'
                    ]
                  }
                ></div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default SelectPostStatusModal;
