import React, { useEffect } from 'react';
import classes from './AboutUser.module.css';
import TelegramIcon from '../../../../assets/telegram.png';
import TwitterIcon from '../../../../assets/twitter.png';
import LinkedinIcon from '../../../../assets/linkedin-1.png';
import YoutubeIcon from '../../../../assets/youtube.png';
import InstagramIcon from '../../../../assets/instagram.png';
import LinktreeIcon from '../../../../assets/linktree.png';
import ImdbIcon from '../../../../assets/imdb.png';
import Introduction from '../Introduction/Introduction';
// import About from '../About/About';
// import Education from '../Education/Education';
import RecentlyAddedNfts from '../RecentlyAddedNfts/RecentlyAddedNfts';
import { useQuery } from 'react-query';
import { getUserUploadedPhotosRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import CreatorServices from '../CreatorServices/CreatorServices';

function AboutUser(props) {
  const {
    openFollowersModalHandler,
    openFollowingsModalHandler,
    numberOfFollowers,
    numberOfFollowings,
    userProfile,
    setSelectedSection,
  } = props;

  const { data, error } = useQuery(['user-photos', userProfile._id], () =>
    getUserUploadedPhotosRequest(userProfile._id)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const renderPhotos = () => {
    return (
      <div className={classes['photos-container']}>
        <div className={classes['photos-see-all']}>
          <h3 className={classes['photos-text']}>Media</h3>
          <div
            onClick={() => setSelectedSection('Photos')}
            className={classes['see-all-container']}
          >
            <p className={classes['see-all-text']}>See All</p>
          </div>
        </div>

        {data.data.length === 0 && (
          <p className={classes['no-photos-found']}>No Photos Found</p>
        )}

        <div className={classes['photos-grid-container']}>
          {data.data.slice(0, 6).map(({ photo }, i) => {
            let style = {};
            if (i === 4) {
              style = {
                borderBottomLeftRadius: 10,
              };
            } else if (i === 5) {
              style = {
                borderBottomRightRadius: 10,
              };
            }

            return (
              <img
                key={i}
                style={style}
                className={classes['photo']}
                src={photo}
                alt="SpecificPhoto"
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderSocialLinks = () => {
    if (!userProfile.socialLinks)
      return (
        <div className={classes['no-socials-container']}>
          <p className={classes['no-socials-found']}>No Socials Found!</p>
        </div>
      );
    return (
      <div className={classes['social-links-container']}>
        {userProfile.socialLinks?.telegram && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={userProfile.socialLinks.telegram}
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={TelegramIcon}
                alt="Telegram"
              />
            </a>
          </div>
        )}

        {userProfile.socialLinks?.twitter && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                userProfile.socialLinks.twitter.startsWith('https://')
                  ? userProfile.socialLinks.twitter
                  : `https://${userProfile.socialLinks.twitter}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={TwitterIcon}
                alt="Twitter"
              />
            </a>
          </div>
        )}

        {userProfile.socialLinks?.linkedin && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                userProfile.socialLinks.linkedin.startsWith('https://')
                  ? userProfile.socialLinks.linkedin
                  : `https://${userProfile.socialLinks.linkedin}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={LinkedinIcon}
                alt="Linkedin"
              />
            </a>
          </div>
        )}

        {userProfile.socialLinks?.youtube && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                userProfile.socialLinks.youtube.startsWith('https://')
                  ? userProfile.socialLinks.youtube
                  : `https://${userProfile.socialLinks.youtube}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={YoutubeIcon}
                alt="Youtube"
              />
            </a>
          </div>
        )}

        {userProfile.socialLinks?.instagram && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                userProfile.socialLinks.instagram.startsWith('https://')
                  ? userProfile.socialLinks.instagram
                  : `https://${userProfile.socialLinks.instagram}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={InstagramIcon}
                alt="Instagram"
              />
            </a>
          </div>
        )}

        {userProfile.socialLinks?.linktree && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                userProfile.socialLinks.linktree.startsWith('https://')
                  ? userProfile.socialLinks.linktree
                  : `https://${userProfile.socialLinks.linktree}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={LinktreeIcon}
                alt="Linktree"
              />
            </a>
          </div>
        )}

        {userProfile.socialLinks?.imdb && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                userProfile.socialLinks.imdb.startsWith('https://')
                  ? userProfile.socialLinks.imdb
                  : `https://${userProfile.socialLinks.imdb}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={ImdbIcon}
                alt="IMDB"
                style={{ width: 25, height: 20 }}
              />
            </a>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes['about-user-container']}>
      <div
        style={{
          // top: userProfile?.creatorServices?.length > 0 ? '-158%' : '-125%',
          top: userProfile?.creatorServices?.length > 0 ? '-145%' : '-118%',
        }}
        className={classes['user-information-container']}
      >
        <div className={classes['followers-socials-about-container']}>
          <div className={classes['info-container']}>
            <h3>Info</h3>
          </div>
          <div
            style={{ margin: '0px 0px 10px 0' }}
            className={classes['horizontal-line']}
          ></div>
          <div className={classes['number-followers-followings-container']}>
            <div
              onClick={openFollowersModalHandler}
              className={classes['followers-container']}
            >
              <p className={classes['number-followers-followings-text']}>
                {numberOfFollowers}
              </p>
              <p className={classes['followers-text']}>Followers</p>
            </div>
            <div className={classes['vertical-line']}></div>
            <div
              onClick={openFollowingsModalHandler}
              className={classes['followings-container']}
            >
              <p className={classes['number-followers-followings-text']}>
                {numberOfFollowings}
              </p>
              <p className={classes['followings-text']}>Following</p>
            </div>
          </div>
          <div style={{ margin: '20px 0' }}></div>

          {/* <div className={classes['horizontal-line']}></div> */}

          {renderSocialLinks()}

          <div className={classes['horizontal-line']}></div>

          <div>
            <Introduction userProfile={userProfile} />

            {/* {!userProfile.verifiedAsInstitution ? (
              <>
                <About userProfile={userProfile} />
                <Education userProfile={userProfile} />
              </>
            ) : (
              <div style={{ height: 370 }}>
                <Introduction userProfile={userProfile} />
              </div>
            )} */}
          </div>
        </div>

        {userProfile.creatorServices &&
          userProfile.creatorServices.length > 0 && (
            <CreatorServices userProfile={userProfile} />
          )}

        <RecentlyAddedNfts
          userProfile={userProfile}
          setSelectedSection={setSelectedSection}
        />

        {data?.data && renderPhotos()}
      </div>

      {/* <div className={classes['user-information-container']}>
        <div className={classes['followers-socials-about-container']}>
          <div className={classes['number-followers-followings-container']}>
            <div style={pointerStyle} onClick={openMyFollowersModalHander}>
              <p className={classes['number-followers-followings-text']}>
                {myNumberOfFollowers}
              </p>
              <p className={classes['followers-text']}>Followers</p>
            </div>
            <div className={classes['vertical-line']}></div>
            <div
              style={pointerStyle}
              onClick={openMyFollowingsModalHander}
              className={classes['number-followings-container']}
            >
              <p className={classes['number-followers-followings-text']}>
                {myNumberOfFollowings}
              </p>
              <p className={classes['followings-text']}>Following</p>
            </div>
          </div>

          <div className={classes['horizontal-line']}></div>

          {renderSocialLinks()}

          <div className={classes['horizontal-line']}></div>

          <div>
            <Introduction />
            <About />
            <Education />
          </div>
        </div>
        <div className={classes['my-recently-added-nfts-container']}>
          <MyRecentlyAddedNfts seeAllHandler={showListingsSectionsHandler} />
        </div>
        {data?.data && renderPhotos()}
      </div> */}
    </div>
  );
}

export default AboutUser;
