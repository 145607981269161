import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { AuthContext } from '../../../../context/auth-context';
import classes from './DepositModal.module.css';
import CopyIcon from '../../../../assets/copy-1.png';
import PinkOkIcon from '../../../../assets/pink-ok.svg';
import notify from '../../../../utils/notify';
import QRCode from 'qrcode';

let timer;

function DepositModal(props) {
  const canvasRef = useRef();
  const { myWalletInformation } = useContext(AuthContext);
  const { setShowDepositModal } = props;
  const [walletAddressCopied, setWalletAddressCopied] = useState(false);

  useEffect(() => {
    const generateWalletAddressQRCode = () => {
      if (!myWalletInformation?.walletAddress) return;

      QRCode.toCanvas(canvasRef.current, myWalletInformation.walletAddress);
    };

    generateWalletAddressQRCode();
  }, [myWalletInformation]);

  const closeModalHandler = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setShowDepositModal(false);
  };

  const copyWalletAddressHandler = async () => {
    try {
      await navigator.clipboard.writeText(myWalletInformation.walletAddress);
      setWalletAddressCopied(true);

      timer = setTimeout(() => {
        setWalletAddressCopied(false);
      }, 2000);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Close
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Deposit"
    >
      <div>
        <p className={classes['you-can-copy']}>
          You can copy this wallet address to send GLMS from an external wallet.
        </p>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <canvas ref={canvasRef}></canvas>

          <p className={classes['address']}>Address:</p>
          <p>
            <span className={classes['wallet-address']}>
              {myWalletInformation?.walletAddress}
            </span>
            <img
              alt="Copy"
              onClick={copyWalletAddressHandler}
              className={classes['copy-icon']}
              src={CopyIcon}
            />
          </p>

          {walletAddressCopied && (
            <div className={classes['copied-container']}>
              <img
                alt="Copied"
                src={PinkOkIcon}
                className={classes['pinkOk-icon']}
              />
              <p className={classes['copied-text']}>Wallet Address Copied!</p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default DepositModal;
