import React, { useState } from 'react';
import classes from './Chat.module.css';
import Channels from './components/Channels/Channels';
import Messages from './components/Messages/Messages';

function Chat(props) {
  const { groupId, isMyRoleAdmin } = props;

  const [selectedChannelName, setSelectedChannelName] = useState('General');
  const [selectedChannelId, setSelectedChannelId] = useState();
  const [isSelectedChannelPrivate, setIsSelectedChannelPrivate] = useState(
    false
  );

  return (
    <div className={classes['chat-container']}>
      <Channels
        isMyRoleAdmin={isMyRoleAdmin}
        setIsSelectedChannelPrivate={setIsSelectedChannelPrivate}
        setSelectedChannelName={setSelectedChannelName}
        setSelectedChannelId={setSelectedChannelId}
        selectedChannelName={selectedChannelName}
        groupId={groupId}
      />

      {selectedChannelId && (
        <Messages
          setSelectedChannelId={setSelectedChannelId}
          setIsSelectedChannelPrivate={setIsSelectedChannelPrivate}
          isMyRoleAdmin={isMyRoleAdmin}
          setSelectedChannelName={setSelectedChannelName}
          selectedChannelName={selectedChannelName}
          isSelectedChannelPrivate={isSelectedChannelPrivate}
          channelId={selectedChannelId}
          groupId={groupId}
        />
      )}
    </div>
  );
}

export default Chat;
