import React, { useEffect } from 'react';
import classes from './Level2VerifiedUsers.module.css';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  getAllLevel2VerifiedUsersRequest,
  updateLevel2VerificationForASpecificUserRequest,
} from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import { Col, Row } from 'antd';
import { limitString } from '../../../../../../helpers/helpers';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';

function Level2VerifiedUsers() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { isLoading, data, error } = useQuery(
    'level-2-verified-users',
    getAllLevel2VerifiedUsersRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateLevel2VerifiedUsersHandler = () =>
    Promise.all([
      queryClient.invalidateQueries('level-2-verified-users'),
      queryClient.refetchQueries('level-2-verified-users'),
    ]);

  const unVerifyLevel2ForASpecificUserHandler = async (userId) => {
    try {
      const requestBody = {
        level2Verified: false,
      };
      await updateLevel2VerificationForASpecificUserRequest(
        userId,
        requestBody
      );
      invalidateLevel2VerifiedUsersHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-users-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-users-heading']}>
            LEVEL 2 VERIFIED USERS = {data?.data.length}
          </h2>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={3} className={classes['pagination-col']}>
              Photo
            </Col>
            <Col md={3} className={classes['pagination-col']}>
              Full Name
            </Col>

            <Col md={5} className={classes['pagination-col']}>
              Email
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Unverify
            </Col>
          </Row>

          {data?.data &&
            data.data.map((user) => {
              return (
                <div key={user._id}>
                  <hr />
                  <Row justify="space-between" style={{ padding: '1em 0' }}>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={3}
                    >
                      <img
                        width={25}
                        height={25}
                        style={{ borderRadius: 100, objectFit: 'cover' }}
                        alt="User"
                        src={user.photo}
                      />
                    </Col>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={3}
                    >
                      {user.firstName} {user.lastName}
                    </Col>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={5}
                    >
                      {limitString(user.email, 30)}
                    </Col>

                    <Col md={3}>
                      <button
                        onClick={() =>
                          unVerifyLevel2ForASpecificUserHandler(user._id)
                        }
                        className="cancel-button"
                      >
                        Unverify
                      </button>
                    </Col>
                  </Row>
                </div>
              );
            })}

          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default Level2VerifiedUsers;
