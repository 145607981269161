export const JOIN_USER_ID = 'JOIN_USER_ID';
export const JOIN_ROOM = 'JOIN_ROOM';
export const NOTIFICATION = 'NOTIFICATION';
export const LEAVE_ROOM = 'LEAVE_ROOM';
export const OFFER_CREATED = 'OFFER_CREATED';
export const MESSAGE_CREATED = 'MESSAGE_CREATED';
export const TYPING_MESSAGE = 'TYPING_MESSAGE';
export const EMIT_TYPING_MESSAGE = 'EMIT_TYPING_MESSAGE';
export const LEFT_TYPING_MESSAGE = 'LEFT_TYPING_MESSAGE';
export const EMIT_LEFT_TYPING_MESSAGE = 'EMIT_LEFT_TYPING_MESSAGE';
export const OFFLINE = 'OFFLINE';
export const ONLINE_USERS = 'ONLINE_USERS';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const MESSAGE_READ = 'MESSAGE_READ';
export const NFT_MESSAGE_CREATED = 'NFT_MESSAGE_CREATED';
export const NFT_MESSAGE_MENTION = 'NFT_MESSAGE_MENTION';
export const EMIT_NFT_MESSAGE_MENTION = 'EMIT_NFT_MESSAGE_MENTION';
export const CHAT_MESSAGE_MENTION = 'CHAT_MESSAGE_MENTION';
export const TAG_NOTIFICATION = 'TAG_NOTIFICATION';
export const POST_MENTION = 'POST_MENTION';
export const EMIT_POST_MENTION = 'EMIT_POST_MENTION';
export const PROCESSING_BUY_NOW = 'PROCESSING_BUY_NOW';
export const UPDATING_NFT_PRICE = 'UPDATING_NFT_PRICE';
export const UNLISTING_NFT = 'UNLISTING_NFT';
export const LISTING_NFT_FOR_BUY_NOW = 'LISTING_NFT_FOR_BUY_NOW';
export const WINNING_POINTS = 'WINNING_POINTS';
export const NFT_UPDATE = 'NFT_UPDATE';
export const USER_POST_COMMENT_MENTION = 'USER_POST_COMMENT_MENTION';
export const EMIT_USER_POST_COMMENT_MENTION = 'EMIT_USER_POST_COMMENT_MENTION';
export const USER_POST_COMMENT_REPLY_MENTION =
  'USER_POST_COMMENT_REPLY_MENTION';
export const EMIT_USER_POST_COMMENT_REPLY_MENTION =
  'EMIT_USER_POST_COMMENT_REPLY_MENTION';
export const GROUP_POST_MENTION = 'GROUP_POST_MENTION';
export const EMIT_GROUP_POST_MENTION = 'EMIT_GROUP_POST_MENTION';
export const PROCESSING_NFT_OFFER = 'PROCESSING_NFT_OFFER';
export const GROUP_MESSAGE_CREATED = 'GROUP_MESSAGE_CREATED';
export const BRAND_ITEM_UPDATE = 'BRAND_ITEM_UPDATE';
