import React from 'react';
import GroupPosts from '../GroupPosts/GroupPosts';

function Discussion(props) {
  const { groupId } = props;

  return <GroupPosts selectedTopic={undefined} groupId={groupId} />;
}

export default Discussion;
