import Swal from 'sweetalert2';

export default function notify(
  type,
  menssage,
  timer = 3000,
  onCloseCb = () => {}
) {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
    didClose: onCloseCb,
  });

  return Toast.fire({
    icon: type,
    title: menssage,
  });
}
