import React from 'react';
import classes from './Albums.module.css';
import UserImageAlbum from './components/UserImageAlbum/UserImageAlbum';

function Albums(props) {
  const {
    setSelectedAlbumId,
    setShowAlbumPhotos,
    setPhotosOptions,
    setSelectedPhotoOption,
    data,
  } = props;

  return (
    <div style={{ padding: '1rem 0' }}>
      {data && data.data.length === 0 && (
        <div className={classes['no-albums-container']}>
          {' '}
          <p className={classes['no-albums-text']}>No Albums found.</p>
        </div>
      )}

      <div className={classes['albums-container']}>
        {data &&
          data.data.map((album) => {
            return (
              <UserImageAlbum
                setPhotosOptions={setPhotosOptions}
                setSelectedPhotoOption={setSelectedPhotoOption}
                setSelectedAlbumId={setSelectedAlbumId}
                setShowAlbumPhotos={setShowAlbumPhotos}
                key={album._id}
                album={album}
              />
            );
          })}
      </div>
    </div>
  );
}

export default Albums;
