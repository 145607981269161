import React, { useState } from 'react';
import classes from './EditNftModal.module.css';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Modal from '../../../../components/Modal/Modal';
import notify from '../../../../utils/notify';
import TextArea from '../../../../components/TextArea/TextArea';
import { updateNftRequest } from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';

function EditNftModal(props) {
  const queryClient = useQueryClient();
  const { setShowEditNftModal, nft } = props;
  const [title, setTitle] = useState(nft.title);
  const [description, setDescription] = useState(nft.description);
  const [royalteFee, setRoyalteFee] = useState(nft.royalteFee);

  const closeModalHandler = () => {
    setShowEditNftModal(false);
  };

  const editNftHandler = async () => {
    try {
      const requestBody = {
        title,
        description,
      };
      if (!nft.tokenId) {
        requestBody['royalteFee'] = royalteFee;
      }

      await updateNftRequest(nft._id, requestBody);
      await Promise.all([
        queryClient.invalidateQueries(['nfts', nft._id]),
        queryClient.refetchQueries(['nfts', nft._id]),
      ]);

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const titleChangeHandler = (e) => {
    setTitle(e.target.value);
  };

  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value);
  };

  const royalteFeeChangeHandler = (e) => {
    const { value } = e.target;

    if (value.length >= 1) {
      setRoyalteFee(e.target.value[0]);
    } else {
      setRoyalteFee('');
    }
  };

  const titleInputStyle = {};
  if (!title) {
    titleInputStyle['border'] = '1px solid red';
  }
  const descriptionInputStyle = {};
  if (!description) {
    descriptionInputStyle['border'] = '1px solid red';
  }
  const royalteFeeInputStyle = {};
  if (
    !royalteFee ||
    isNaN(royalteFee) ||
    parseInt(royalteFee) < 0 ||
    parseInt(royalteFee) > 5
  ) {
    royalteFeeInputStyle['border'] = '1px solid red';
  }

  const isSaveButtonDisabled = () => {
    if (!title) return true;
    if (!description) return true;
    if (
      !royalteFee ||
      isNaN(royalteFee) ||
      parseInt(royalteFee) < 0 ||
      parseInt(royalteFee) > 5
    )
      return true;

    return false;
  };

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Edit NFT"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={isSaveButtonDisabled()}
            onClick={editNftHandler}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
    >
      <p className={classes['label']}>Name</p>
      <Input
        placeholder="Enter here"
        style={{ width: '100%', ...titleInputStyle }}
        value={title}
        onChange={titleChangeHandler}
      />

      <p className={classes['label']}>Description</p>
      <TextArea
        placeholder="Enter here"
        style={{ minHeight: 100, width: '100%', ...descriptionInputStyle }}
        value={description}
        onChange={descriptionChangeHandler}
      />

      {!nft.tokenId && (
        <>
          <p className={classes['label']}>Royalte fee (0% - 5%)</p>
          <Input
            type="number"
            style={{ width: '100%', ...royalteFeeInputStyle }}
            placeholder="0%"
            value={royalteFee}
            onChange={royalteFeeChangeHandler}
          />
        </>
      )}
    </Modal>
  );
}

export default EditNftModal;
