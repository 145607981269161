import React, { useRef, useState } from 'react';
import classes from './NftOffers.module.css';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import MetaTags from 'react-meta-tags';
import Input from '../../components/Input/Input';
import ArrowDownIcon from '../../assets/arrowDown.png';
import SearchIcon from '../../assets/search-icon.png';
import SentOffers from './components/SentOffers/SentOffers';
import ReceivedOffers from './components/ReceivedOffers/ReceivedOffers';
import GoBackIcon from '../../assets/go-back-icon-2.png';
import { useHistory } from 'react-router-dom';

const NFT_OFFERS_SECTIONS = ['Received Offers', 'Sent Offers'];

const sortByValues = [
  {
    value: 0,
    sortByValue: 'Most Recent',
    showValue: 'Most Recent',
  },
  {
    value: 1,
    sortByValue: 'Price high to low',
    showValue: 'High to Low',
  },
  {
    value: 2,
    sortByValue: 'Price low to high',
    showValue: 'Low to High',
  },
];

function NftOffers() {
  const history = useHistory();
  const sortContainerRef = useRef();
  const [selectedOfferSection, setSelectedOfferSection] = useState(
    'Received Offers'
  );
  const [searchValue, setSearchValue] = useState('');
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [sortByValue, setSortByValue] = useState(0);

  const sortBy = sortByValues.find((el) => el.value === sortByValue);

  let sortByFilter = 'most-recent';
  if (sortBy?.sortByValue === 'Most Recent') {
    sortByFilter = 'most-recent';
  }
  if (sortBy?.sortByValue === 'Price low to high') {
    sortByFilter = 'low-high';
  } else if (sortBy?.sortByValue === 'Price high to low') {
    sortByFilter = 'high-low';
  }

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <MetaTags>
        <title>Offers | Glimpse</title>
        <meta property="og:title" content={'Offers | Glimpse'} />
      </MetaTags>

      <div className={classes['nft-offers-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>
        <div className={classes['offers-container']}>
          <header className={classes['offers-requests-header']}>
            <div className={classes['go-back-container']}>
              <div onClick={() => history.goBack()}>
                <img
                  src={GoBackIcon}
                  alt="Go Back"
                  className={classes['go-back-icon']}
                />
              </div>
              <h3>Offers</h3>
            </div>

            <div className={classes['offers-requests-options-container']}>
              {NFT_OFFERS_SECTIONS.map((option) => (
                <div
                  key={option}
                  onClick={() => setSelectedOfferSection(option)}
                  className={classes['offers-requests-option-container']}
                >
                  <p
                    className={`${classes['offers-requests-option-text']} ${
                      option === selectedOfferSection &&
                      classes['offers-requests-option-text-selected']
                    }`}
                  >
                    {option}
                  </p>
                </div>
              ))}
            </div>
          </header>

          <div className={classes['filters-container']}>
            <div className={classes['input-search-container']}>
              <Input
                placeholder="Search here"
                value={searchValue}
                onChange={searchValueChangeHandler}
                style={{ width: '100%', background: 'white' }}
              />

              <img
                alt="Search"
                className={classes['search-icon']}
                src={SearchIcon}
              />
            </div>

            <div
              ref={sortContainerRef}
              id="sorting-container"
              onClick={() => setShowSortOptions((prevState) => !prevState)}
              className={classes['sorting-container']}
            >
              <p style={{ margin: 0 }}>
                {sortBy['showValue'] !== 'Most Recent' && (
                  <span className={classes['price-text']}>Price: </span>
                )}{' '}
                <span className={classes['sorting-text']}>
                  {sortBy['showValue']}
                </span>
              </p>
              <img alt="Arrow" src={ArrowDownIcon} width={14} height={8} />

              {showSortOptions && (
                <div className={classes['sort-options']}>
                  {sortByValues
                    .filter(
                      ({ showValue }) => showValue !== sortBy['showValue']
                    )
                    .map(({ value, showValue }) => {
                      return (
                        <p
                          key={showValue}
                          onClick={() => setSortByValue(value)}
                          className={classes['sort-option']}
                        >
                          {showValue}
                        </p>
                      );
                    })}
                </div>
              )}
            </div>
          </div>

          <div className={classes['sent-received-offers-container']}>
            {selectedOfferSection === 'Received Offers' && (
              <ReceivedOffers searchValue={searchValue} sortBy={sortByFilter} />
            )}
            {selectedOfferSection === 'Sent Offers' && (
              <SentOffers searchValue={searchValue} sortBy={sortByFilter} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NftOffers;
