import React from 'react';
import classes from './AssociatedGroups.module.css';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { limitString } from '../../../../helpers/helpers';

function AssociatedGroups(props) {
  const { collectionId } = props;
  const queryClient = useQueryClient();

  const nftCollectionInformation = queryClient.getQueryData([
    'nft-collection-information',
    collectionId,
  ]);

  const associatedGroups = nftCollectionInformation.data?.associatedGroups;

  return (
    <div>
      {associatedGroups && associatedGroups.length === 0 && (
        <div
          style={{
            background: 'white',
            padding: '.8rem',
            borderRadius: 10,
            marginTop: 10,
            width: '95%',
            gridColumn: '1/-1',
          }}
        >
          <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
            No Associated Groups Found!
          </p>
        </div>
      )}

      {associatedGroups?.length > 0 && (
        <div className={classes['transactions-container']}>
          <div className={classes['transactions-table']}>
            <p>COVER PHOTO</p>
            <p>NAME</p>
            <p>USERNAME</p>

            <div className={classes['table-horizontal-line']}></div>

            {associatedGroups.map((group) => {
              return (
                <Link
                  to={`/groups/${group._id}`}
                  key={group._id}
                  className={classes['row']}
                >
                  <div>
                    <img
                      alt="Cover"
                      src={group.coverPhoto}
                      className={classes['group-cover-photo']}
                    />
                  </div>{' '}
                  <p title={group.name} className={classes['row-text']}>
                    {limitString(group.name, 19)}
                  </p>
                  <p className={classes['row-text']}>{group.username}</p>
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default AssociatedGroups;
