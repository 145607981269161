import React, { useState, useEffect } from 'react';
import Nft from '../../../../components/Nft/Nft';
import { useInfiniteQuery } from 'react-query';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import notify from '../../../../utils/notify';
import { getAllMyNftsNotForSaleRequest } from '../../../../httpRequests/httpRequests';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import classes from './MyPurchases.module.css';
import Input from '../../../../components/Input/Input';

const LIMIT_PER_PAGE = 6;

function MyPurchases() {
  const [searchNftNotForSaleValue, setSearchNftNotForSaleValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['all-my-nfts-not-for-sale', searchNftNotForSaleValue],
    ({ pageParam = 1 }) =>
      getAllMyNftsNotForSaleRequest(pageParam, searchNftNotForSaleValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / LIMIT_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 3000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
    scrollCoefficient: 2.1,
  });

  return (
    <div className={classes['my-purchases-page-container']}>
      <div className={classes['input-container']}>
        <Input
          placeholder="Search for NFTs"
          style={{ width: '100%', background: 'white' }}
          value={searchNftNotForSaleValue}
          onChange={(e) => setSearchNftNotForSaleValue(e.target.value)}
        />
      </div>
      <div className={classes['collection-container']}>
        {!isLoading &&
          data &&
          data.pages.map((page) => {
            return page.data.map((nft) => {
              return <Nft key={nft._id} nft={nft} owner={nft.owner} />;
            });
          })}
      </div>
      {(isLoading || isFetching) && <LoadingSpinner />}
      {!isLoading && data && data.pages[0].results === 0 && (
        <div
          style={{
            background: 'white',
            padding: '.8rem',
            borderRadius: 10,
            marginBottom: 10,
          }}
        >
          <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
            No NFT’s Found!
          </p>
        </div>
      )}
    </div>
  );
}

export default MyPurchases;
