import React, { useContext, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getPostsForSpecificUserRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './Posts.module.css';
import Post from '../../../NewsFeed/components/NewsFeedContent/components/Posts/components/Post/Post';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { AuthContext } from '../../../../context/auth-context';
import { useParams } from 'react-router-dom';
import CreateDedicatedPost from './components/CreateDedicatedPost/CreateDedicatedPost';
import AboutUser from '../AboutUser/AboutUser';
import NoPostsFoundIcon from '../../../../assets/no-activity-found-icon.png';

const RESULTS_PER_PAGE = 10;

function Posts({
  userProfile,
  isMyFriend,
  openFollowersModalHandler,
  openFollowingsModalHandler,
  numberOfFollowers,
  numberOfFollowings,
  setSelectedSection,
}) {
  const { userId } = useParams();
  const { profile } = useContext(AuthContext);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['posts', userId],
    ({ pageParam = 1 }) =>
      getPostsForSpecificUserRequest(
        userId,
        pageParam,
        RESULTS_PER_PAGE,
        profile?._id
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const allLikes = [];
  if (data?.pages) {
    data.pages.forEach((page) => {
      allLikes.push(...page.myLikeStatusForEachPost);
    });
  }

  const isUserLoggedIn = !!profile?._id;

  return (
    <div className={classes['posts-page-container']}>
      {!isLoading && (
        <AboutUser
          openFollowersModalHandler={openFollowersModalHandler}
          openFollowingsModalHandler={openFollowingsModalHandler}
          numberOfFollowers={numberOfFollowers}
          numberOfFollowings={numberOfFollowings}
          userProfile={userProfile}
          setSelectedSection={setSelectedSection}
        />
      )}
      <div
        style={{
          width: data && data.pages[0].results === 0 && '100%',
          minWidth: '60%',
        }}
        className={classes['posts-section-container']}
      >
        {isUserLoggedIn && isMyFriend && data?.pages ? (
          <CreateDedicatedPost userProfile={userProfile} />
        ) : (
          <div style={{ marginBottom: 20 }}></div>
        )}

        <div className={classes['posts-container']}>
          {data && data.pages[0].results === 0 && (
            <div className={classes['no-posts-found-container']}>
              <img
                src={NoPostsFoundIcon}
                alt="No Posts Found"
                className={classes['no-posts-found-icon']}
              />
              <p className={classes['no-posts-found-text']}>No Posts Found</p>
            </div>
          )}

          {data &&
            data.pages.map((page) => {
              return page.data.map((post) => {
                const isPostLikedByMe =
                  allLikes.find(({ postId }) => {
                    return postId === post._id;
                  })?.likeStatus !== null;

                return (
                  <Post
                    isPostLikedByMe={isPostLikedByMe}
                    queryKey={['posts', userId]}
                    key={post._id}
                    post={post}
                  />
                );
              });
            })}
          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default Posts;
