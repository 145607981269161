import React from 'react';
import classes from './CustomServicesModal.module.css';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';

function CustomServicesModal(props) {
  const { setShowCustomServicesModal, customServices } = props;

  const closeModalHandler = () => {
    setShowCustomServicesModal(false);
  };

  return (
    <Modal
      style={{ height: 300, width: 300 }}
      title={`Custom Services`}
      closeModal={closeModalHandler}
      footer={
        <Button
          style={{ width: 200 }}
          darkpinkcolor="true"
          onClick={closeModalHandler}
        >
          Close
        </Button>
      }
    >
      <div className={classes['services-container']}>
        {customServices.map((service, i) => (
          <p className={classes['service-text']} key={service + i}>
            {i + 1} - {service}
          </p>
        ))}
      </div>
    </Modal>
  );
}

export default CustomServicesModal;
