import React, { useState } from 'react';
import { createUserByAdminRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './CreateUser.module.css';

function CreateUser() {
  const [formState, setFormState] = useState({
    firstName: {
      value: '',
      placeholder: 'First Name',
    },
    lastName: {
      value: '',
      placeholder: 'Last Name',
    },
    username: {
      value: '',
      placeholder: 'Username',
    },
    email: {
      value: '',
      placeholder: 'Email',
    },
    password: {
      value: '',
      placeholder: 'Password',
    },
  });

  const [creatingUser, setCreatingUser] = useState(false);

  const inputChangeHandler = (key, value) => {
    const updatedFormState = { ...formState };
    updatedFormState[key].value = value;

    setFormState(updatedFormState);
  };

  const createUserHandler = async () => {
    setCreatingUser(true);
    try {
      const requestBody = {
        firstName: formState.firstName.value,
        lastName: formState.lastName.value,
        username: formState.username.value,
        email: formState.email.value,
        password: formState.password.value,
      };

      await createUserByAdminRequest(requestBody);

      notify('success', 'User created successfully!', 2000);
    } catch (err) {
      notify('error', err, 2000);
    }

    setCreatingUser(false);
  };

  return (
    <div className={classes['all-events-container']}>
      <div className={classes['heading-container']}>
        <h2 className={classes['all-events-heading']}>CREATE USER</h2>
      </div>

      <div className={classes['inputs-container']}>
        {Object.keys(formState).map((key) => {
          const { value, placeholder } = formState[key];
          return (
            <div className={classes['input-container']}>
              <p className={classes['label']}>{placeholder}</p>
              <input
                type={key === 'password' ? 'password' : 'text'}
                className="searchInput"
                value={value}
                placeholder={placeholder}
                onChange={(e) => inputChangeHandler(key, e.target.value)}
              />
            </div>
          );
        })}
      </div>

      <button
        disabled={creatingUser}
        className={creatingUser ? 'btn-disabled' : 'btn-pink-glimpse'}
        onClick={createUserHandler}
      >
        {creatingUser ? 'Creating User...' : 'Create User'}
      </button>
    </div>
  );
}

export default CreateUser;
