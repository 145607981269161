import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  getMostRecentNftsFromMyFollowersRequest,
  // getMostRecentNftsFromMyUniqueFollowingsRequest,
} from '../../../../../../httpRequests/httpRequests';
import classes from './MostRecentNfts.module.css';
import MostRecentNftCard from './components/MostRecentNftCard/MostRecentNftCard';
import RightIcon from '../../../../../../assets/right-icon.png';

function MostRecentNfts() {
  const { data } = useQuery(
    ['most-recent-nfts-from-my-followings'],
    // getMostRecentNftsFromMyUniqueFollowingsRequest
    getMostRecentNftsFromMyFollowersRequest
  );
  const [sliceStart, setSliceStart] = useState(0);
  const [sliceEnd, setSliceEnd] = useState(5);

  const goNextHandler = () => {
    if (!data?.data) return;

    const totalResults = data.data.length;
    if (sliceEnd <= totalResults - 5) {
      setSliceStart((prevState) => prevState + 5);
      setSliceEnd((prevState) => prevState + 5);
    } else {
      setSliceStart(0);
      setSliceEnd(5);
    }
  };

  return (
    <div className={classes['most-recent-nfts-container']}>
      <div className={classes['most-recent-nfts']}>
        {/* <Link to={'/create'} className={classes['create']}>
          <img alt="Create" className={classes['rectangle']} src={CreateIcon} />
          <div className={classes['add-container']}>
            <img
              alt="Add"
              className={classes['add-icon']}
              width={15}
              src={AddIcon}
            />
            <p className={classes['add-text']}>Add</p>
          </div>
        </Link> */}

        {data &&
          data.data
            .slice(sliceStart, sliceEnd)
            .map((nft) => <MostRecentNftCard nft={nft} key={nft._id} />)}
        {data?.data && data.data.length > 12 && (
          <div onClick={goNextHandler} className={classes['next-icon']}>
            <img src={RightIcon} alt="Next" className={classes['right-icon']} />
          </div>
        )}
      </div>
    </div>
  );
}

export default MostRecentNfts;
