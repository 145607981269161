import React, { useState } from 'react';
import classes from './RequestNft.module.css';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import Request from './components/Request/Request';
import PaymentInfo from './components/PaymentInfo/PaymentInfo';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getUserProfileRequest } from '../../httpRequests/httpRequests';
import GoBackIcon from '../../assets/go-back-icon-2.png';

function RequestNft() {
  const history = useHistory();
  const params = useParams();
  const { userId } = params;

  const userProfileResponse = useQuery(['users', userId], () =>
    getUserProfileRequest(userId)
  );

  const [step, setStep] = useState('Request Collectible');
  const [nftFor, setNftFor] = useState('');
  const [isNftForMyself, setIsNftForMyself] = useState(false);
  const [showNftForOptions, setShowNftForOptions] = useState(false);
  const [selectedOccasion, setSelectedOccasion] = useState();
  const [about, setAbout] = useState('');
  const [selectedNftForId, setSelectedNftForId] = useState();
  const [selectedDeliverySpeed, setSelectedDeliverySpeed] = useState(
    'Standard'
  );
  const [nftTitle, setNftTitle] = useState('');
  const [nftTitleTouched, setNftTitleTouched] = useState(false);
  const [isNftTypeVideo, setIsNftTypeVideo] = useState(false);

  return (
    <div className={classes['request-nft-page-container']}>
      <div className={classes['user-sidebar-container']}>
        <UserSidebar />
      </div>

      <div className={classes['request-container']}>
        <header className={classes['request-nft-header']}>
          <div className={classes['go-back-container']}>
            <div onClick={() => history.push('/news-feed')}>
              <img
                src={GoBackIcon}
                alt="Go Back"
                className={classes['go-back-icon']}
              />
            </div>
            <h3>{step}</h3>
          </div>
        </header>

        {step === 'Request Collectible' && userProfileResponse.data?.data && (
          <Request
            selectedOccasion={selectedOccasion}
            setSelectedOccasion={setSelectedOccasion}
            nftFor={nftFor}
            setNftFor={setNftFor}
            about={about}
            setAbout={setAbout}
            selectedNftForId={selectedNftForId}
            setSelectedNftForId={setSelectedNftForId}
            showNftForOptions={showNftForOptions}
            setShowNftForOptions={setShowNftForOptions}
            isNftForMyself={isNftForMyself}
            setIsNftForMyself={setIsNftForMyself}
            setStep={setStep}
            userProfile={userProfileResponse.data?.data}
            nftTitle={nftTitle}
            setNftTitle={setNftTitle}
            nftTitleTouched={nftTitleTouched}
            setNftTitleTouched={setNftTitleTouched}
            isNftTypeVideo={isNftTypeVideo}
            setIsNftTypeVideo={setIsNftTypeVideo}
          />
        )}
        {step === 'Payment Info' && userProfileResponse.data?.data && (
          <PaymentInfo
            selectedDeliverySpeed={selectedDeliverySpeed}
            setSelectedDeliverySpeed={setSelectedDeliverySpeed}
            setStep={setStep}
            requestFor={userId}
            nftFor={selectedNftForId}
            occasion={selectedOccasion}
            description={about}
            nftTitle={nftTitle}
            userProfile={userProfileResponse.data.data}
            isNftTypeVideo={isNftTypeVideo}
          />
        )}
      </div>
    </div>
  );
}

export default RequestNft;
