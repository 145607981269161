import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import classes from './InviteGroupMembersModal.module.css';
import Modal from '../Modal/Modal';
import { useInfiniteQuery } from 'react-query';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Input from '../Input/Input';
import Button from '../Button/Button';
import notify from '../../utils/notify';
import RemoveIcon from '../../assets/gray-remove.png';
import {
  getAllGroupMembersForASpecificGroupRequest,
  getAllNonPaginatedGroupMembersForASpecificGroupRequest,
} from '../../httpRequests/httpRequests';
import { AuthContext } from '../../context/auth-context';

const RESULTS_PER_PAGE = 10;

function InviteGroupMembers(props) {
  const groupMembersContainerRef = useRef();
  const { profile } = useContext(AuthContext);
  const { groupId, setShowInviteGroupMembersModal } = props;
  const [searchValue, setSearchValue] = useState('');
  const [selectedGroupMembers, setSelectedGroupMembers] = useState([]);
  const [selectedGroupMembersIds, setSelectedGroupMembersIds] = useState({});
  const [usersToInvite, setUsersToInvite] = useState([]);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['group-members', groupId, searchValue],
    ({ pageParam = 1 }) =>
      getAllGroupMembersForASpecificGroupRequest(
        pageParam,
        RESULTS_PER_PAGE,
        groupId,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const groupMembersContainerElement = groupMembersContainerRef.current;

    if (!groupMembersContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    groupMembersContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      groupMembersContainerElement?.removeEventListener(
        'scroll',
        scrollHandler
      );
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const closeModalHandler = () => {
    setShowInviteGroupMembersModal(false);
  };

  const selectAllFriendsHandler = async () => {
    const {
      data,
    } = await getAllNonPaginatedGroupMembersForASpecificGroupRequest(groupId);

    const updatedSelectedGroupMembersIds = {};

    data.forEach(({ user }) => {
      if (user._id === profile?._id) return;
      updatedSelectedGroupMembersIds[user._id] = user;
    });

    setSelectedGroupMembersIds(updatedSelectedGroupMembersIds);
    setSelectedGroupMembers(Object.values(updatedSelectedGroupMembersIds));
  };

  const removeAllSelectedGroupMembersHandler = () => {
    setSelectedGroupMembersIds({});
    setSelectedGroupMembers([]);
  };

  const selectGroupMemberHandler = (friend) => {
    const isFriendSelected = !!selectedGroupMembersIds[friend._id];

    const updatedSelectedGroupMembersIds = { ...selectedGroupMembersIds };

    if (isFriendSelected) {
      delete updatedSelectedGroupMembersIds[friend._id];
    } else {
      updatedSelectedGroupMembersIds[friend._id] = friend;
    }

    setSelectedGroupMembersIds(updatedSelectedGroupMembersIds);
    setSelectedGroupMembers(Object.values(updatedSelectedGroupMembersIds));
  };

  const removeFriendHandler = (friendId) => {
    const updatedSelectedGroupMembersIds = { ...selectedGroupMembersIds };
    delete updatedSelectedGroupMembersIds[friendId];
    setSelectedGroupMembersIds(updatedSelectedGroupMembersIds);
    setSelectedGroupMembers(Object.values(updatedSelectedGroupMembersIds));
  };

  const inviteHandler = () => {
    if (props.inviteHandler) {
      props.inviteHandler(selectedGroupMembersIds);
    }
  };

  useMemo(async () => {
    if (!data || !data.pages) return [];

    const allUsers = [];
    data.pages.forEach((page) => {
      page.data.forEach(({ user }) => {
        if (user._id !== profile?._id) allUsers.push(user);
      });
    });

    setUsersToInvite(allUsers);
  }, [data, profile?._id]);

  return (
    <Modal
      closeIcon="true"
      footer={<div className={classes['footer']}></div>}
      closeModal={closeModalHandler}
      title="Invite Group Members"
      style={{ maxWidth: 700, height: 550 }}
    >
      <>
        <div className={classes['invite-friends-container']}>
          <div className={classes['friends-to-invite-container']}>
            <Input
              onChange={searchValueChangeHandler}
              style={{ width: '100%' }}
              placeholder={'Search user'}
              value={searchValue}
            />

            <div className={classes['suggested-select-all']}>
              <h3 className={classes['suggested-text']}>Suggested</h3>
              <p
                onClick={selectAllFriendsHandler}
                className={classes['select-text']}
              >
                Select All
              </p>
            </div>

            <div
              ref={groupMembersContainerRef}
              className={classes['friends-list']}
            >
              {usersToInvite.map((user) => {
                return (
                  <div className={classes['user-container']} key={user._id}>
                    <div className={classes['user-image-name']}>
                      <img
                        alt="Friend"
                        src={user.photo}
                        className={classes['user-image']}
                      />
                      <p className={classes['user-name']}>{user.fullName}</p>
                    </div>

                    <input
                      onChange={() => selectGroupMemberHandler(user)}
                      className={classes['checkbox']}
                      type={'checkbox'}
                      checked={!!selectedGroupMembersIds[user._id]}
                    />
                  </div>
                );
              })}

              {data &&
                data.pages &&
                usersToInvite.length === 0 &&
                searchValue === '' && <p>No Friends to Invite</p>}

              {data &&
                data.pages &&
                usersToInvite.length === 0 &&
                searchValue !== '' && <p>No Results Found</p>}
              {(isLoading || isFetching) && <LoadingSpinner />}
            </div>
          </div>

          <div className={classes['invited-friends-container']}>
            <div>
              <div className={classes['friends-selected-remove-all']}>
                <p className={classes['number-selected-friends']}>
                  {selectedGroupMembers.length}{' '}
                  {selectedGroupMembers.length === 1 ? 'Member' : 'Members'}{' '}
                  Selected
                </p>
                <p
                  onClick={removeAllSelectedGroupMembersHandler}
                  className={classes['remove-all-text']}
                >
                  Remove All
                </p>
              </div>
              <div className={classes['selected-friends-list']}>
                {selectedGroupMembers.map((friend) => {
                  return (
                    <div className={classes['user-container']} key={friend._id}>
                      <div className={classes['user-image-name']}>
                        <img
                          alt="Friend"
                          src={friend.photo}
                          className={classes['user-image']}
                        />
                        <p className={classes['user-name']}>
                          {friend.fullName}
                        </p>
                      </div>

                      <img
                        alt="Remove"
                        src={RemoveIcon}
                        onClick={() => removeFriendHandler(friend._id)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <Button
              onClick={inviteHandler}
              style={{ alignSelf: 'flex-end', width: '100%' }}
              darkpink="true"
              disabled={selectedGroupMembers.length === 0}
            >
              Invite
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
}

export default InviteGroupMembers;
