import React, { useContext, useState } from 'react';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { AuthContext } from '../../../../context/auth-context';
import { uploadCoverPhotoRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './UploadCoverPhotoModal.module.css';

function UploadCoverPhotoModal(props) {
  const { getMyProfile } = useContext(AuthContext);
  const {
    setShowUploadCoverPhotoModal,
    coverPhotoPreviewUrl,
    setCoverPhotoPreviewUrl,
    coverPhotoImageUrl,
    setCoverPhotoImageUrl,
  } = props;

  const [uploadingCoverPhoto, setUploadingCoverPhoto] = useState(false);

  const closeModalHandler = () => {
    setCoverPhotoPreviewUrl(undefined);
    setCoverPhotoImageUrl(undefined);
    setShowUploadCoverPhotoModal(false);
  };

  const uploadCoverPhotoHandler = async () => {
    setUploadingCoverPhoto(true);

    const formData = new FormData();
    formData.append('coverPhoto', coverPhotoImageUrl);
    try {
      await uploadCoverPhotoRequest(formData);
      await getMyProfile();
      setUploadingCoverPhoto(false);
      closeModalHandler();
    } catch (err) {
      setUploadingCoverPhoto(false);
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      title={`Upload Cover Photo`}
      closeModal={closeModalHandler}
      footer={
        <>
          <Button
            disabled={uploadingCoverPhoto}
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <span style={{ marginRight: 10 }}></span>
          <Button
            disabled={uploadingCoverPhoto}
            onClick={uploadCoverPhotoHandler}
            style={{ width: 200 }}
            darkpink="true"
          >
            Save
          </Button>
        </>
      }
    >
      <div className={classes['upload-image-container']}>
        <img
          alt="MyCover"
          src={coverPhotoPreviewUrl}
          className={classes['image']}
        />
      </div>
    </Modal>
  );
}

export default UploadCoverPhotoModal;
