import React, { useContext, useState } from 'react';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { AuthContext } from '../../../../context/auth-context';
import { updateProfilePictureRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './UploadImageModal.module.css';

function UploadImageModal(props) {
  const { getMyProfile } = useContext(AuthContext);
  const {
    setShowUploadImageModal,
    uploadImagePreviewUrl,
    setUploadImagePreviewUrl,
    setUploadImageUrl,
    uploadImageUrl,
  } = props;
  const [
    uploadingProfilePhotoLoading,
    setUploadingProfilePhotoLoading,
  ] = useState(false);

  const closeModalHandler = () => {
    setUploadImagePreviewUrl(undefined);
    setUploadImageUrl(undefined);
    setShowUploadImageModal(false);
  };

  const uploadImageHandler = async () => {
    setUploadingProfilePhotoLoading(true);

    const formData = new FormData();
    formData.append('photo', uploadImageUrl);
    try {
      await updateProfilePictureRequest(formData);
      await getMyProfile();

      setUploadingProfilePhotoLoading(false);
      closeModalHandler();
    } catch (err) {
      setUploadingProfilePhotoLoading(false);
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      title={`Upload Profile Picture`}
      closeModal={closeModalHandler}
      footer={
        <>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
            disabled={uploadingProfilePhotoLoading}
          >
            Cancel
          </Button>
          <span style={{ marginRight: 10 }}></span>
          <Button
            disabled={uploadingProfilePhotoLoading}
            onClick={uploadImageHandler}
            style={{ width: 200 }}
            darkpink="true"
          >
            Save
          </Button>
        </>
      }
    >
      <div className={classes['upload-image-container']}>
        <img
          alt="UploadedImg"
          src={uploadImagePreviewUrl}
          className={classes['image']}
        />
      </div>
    </Modal>
  );
}

export default UploadImageModal;
