// api/users
export const SIGNUP_ENDPOINT = `/api/users/signup`;
export const LOGIN_ENDPOINT = `/api/users/login`;
export const GET_LOGGED_IN_USER_ENDPOINT = `/api/users/current-user`;
export const GET_MY_PROFILE_ENDPOINT = `/api/users/profile`;
export const UPDATE_PROFILE_ENDPOINT = `/api/users/profile`;
export const UPDATE_PROFILE_PICTURE_ENDPOINT = `/api/users/upload-photo`;
export const FORGOT_PASSWORD_ENDPOINT = `/api/users/forgot-password`;
export const RESET_PASSWORD_ENDPOINT = `/api/users/reset-password`;
export const SEARCH_USERS_ENDPOINT = `/api/users`;
export const GET_USER_PROFILE_ENDPOINT = `/api/users`;
export const GET_USERS_WITH_MOST_FOLLOWERS = '/api/users/most-followers';
export const GET_ALL_USERS_ENDPOINT = `/api/users`;
export const DEACTIVATE_USER_ENDPOINT = `/api/users`;
export const ACTIVATE_USER_ENDPOINT = `/api/users`;
export const CHANGE_USER_PASSWORD_ENDPOINT = `/api/users`;
export const CHANGE_MY_PASSWORD_ENDPOINT = `/api/users/change-password`;
export const GET_USER_STATISTICS_ENDPOINT = `/api/users`;
export const GET_USER_REPORTS_ENDPOINT = `/api/users`;
export const SEARCH_USERS_BY_USERNAME_ENDPOINT = `/api/users/search-username`;
export const UPDATE_USERNAME_ENDPOINT = `/api/users/username`;
export const SORT_USERS_BY_NUMBER_OF_NFTS_SOLD_ENDPOINT = `/api/users/rank-by-number-of-nfts-sold`;
export const SORT_USERS_BY_NUMBER_OF_NFTS_BOUGHT_ENDPOINT = `/api/users/rank-by-number-of-nfts-bought`;
export const SORT_USERS_BY_NUMBER_OF_FOLLOWERS = `/api/users/rank-by-number-of-followers`;
export const SORT_USERS_BY_NUMBER_OF_NFT_UPVOTES = `/api/users/rank-by-nft-upvotes`;
export const GET_WALLET_INFORMATION_ENDPOINT = `/api/users/wallet-info`;
export const GET_USERS_NUMBER_OF_FOLLOWERS_AND_FOLLOWINGS_ENDPOINT = `/api/users`;
export const UPLOAD_COVER_PHOTO_ENDPOINT = `/api/users/upload-cover-photo`;
export const EDIT_ABOUT_INFO_ENDPOINT = `/api/users/edit-about-info`;
export const EDIT_INTRODUCTION_ENDPOINT = `/api/users/edit-introduction`;
export const EDIT_EDUCATION_ENDPOINT = `/api/users/edit-education`;
export const VERIFY_USER_ENDPOINT = `/api/users`;
export const UNVERIFY_USER_ENDPOINT = `/api/users`;
export const SEARCH_COLLECTIONS_GROUPS_NFTS_ENDPOINT = `/api/users/search-collection-groups-nfts`;
export const GET_ALL_NON_PAGINATED_USERS_ENDPOINT = `/api/users/non-paginated`;
export const UPDATE_MY_REQUEST_NFT_PRICE = `/api/users/request-nft-price`;
export const GET_MY_LOCKED_GLMS_BALANCE_ENDPOINT = `/api/users/locked-glms-balance`;
export const UPDATE_MY_SOCIAL_LINKS_ENDPOINT = `/api/users/social-links`;
export const REQUEST_EMAIL_CHANGE_ENDPOINT = `/api/users/request-email-change`;
export const CHANGE_MY_EMAIL_ENDPOINT = `/api/users/change-email`;
export const REQUEST_PHONE_NUMBER_VERIFICATION_CODE_ENDPOINT = `/api/users/phone-number`;
export const VERIFY_PHONE_NUMBER_ENDPOINT = `/api/users/verify-phone-number`;
export const VERIFY_USER_EMAIL_ENDPOINT = `/api/users`;
export const UPDATE_MY_METAMASK_WALLET_ADDRESSES_ENDPOINT = `/api/users/metamask-addresses`;
export const UPDATE_MY_METAMASK_NFTS_VIEW_STATUS_ENDPOINT = `/api/users/metamask-nfts/view-status`;
export const GET_METAMASK_NFTS_FOR_A_SPECIFIC_USER_ENDPOINT = `/api/users`;
export const SIGN_METAMASK_PERSONAL_SIGNATURE_ENDPOINT = `/api/users/sign-metamask-personal-signature`;
export const SIGNUP_WITH_METAMASK_ENDPOINT = `/api/users/signup-metamask`;
export const LOGIN_WITH_METAMASK_ENDPOINT = `/api/users/login-metamask`;
export const VERIFY_USER_AS_INSTITUTION_ENDPOINT = `/api/users`;
export const UNVERIFY_USER_AS_INSTITUTION_ENDPOINT = `/api/users`;
export const SIGNUP_WITH_UNSTOPPABLE_DOMAINS_ENDPOINT = `/api/users/signup-unstoppable`;
export const LOGIN_WITH_UNSTOPPABLE_ENDPOINT = `/api/users/login-unstoppable`;
export const GET_USER_ADMINS_ENDPOINT = `/api/users/admins`;
export const CHECK_FOR_WINNING_POINTS_AFTER_AUTO_LOGIN_ENDPOINT = `/api/users/check-winning-points-after-auto-login`;
export const MARK_ADMIN_AS_USER_ENDPOINT = `/api/users/admins`;
export const GET_VERIFIED_USERS_ENDPOINT = `/api/users/verified`;
export const GET_VERIFIED_INSTITUTIONS_ENDPOINT = `/api/users/verified-institutions`;
export const MARK_USER_AS_ADMIN_ENDPOINT = `/api/users`;
export const PUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_A_SPECIFIC_USER_ENDPOINT = `/api/users`;
export const UNPUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_A_SPECIFIC_USER_ENDPOINT = `/api/users`;
export const PUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_EVERY_USER_ENDPOINT = `/api/users/publish-nfts-or-collection-content`;
export const UNPUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_EVERY_USER_ENDPOINT = `/api/users/unpublish-nfts-or-collection-content`;
export const GET_ALL_USERS_ALLOWED_TO_PUBLISH_NFTS_OR_COLLECTION_CONTENT_ENDPOINT = `/api/users/allowed-to-publish-nfts-or-collection-content`;
export const GIVE_USER_ACCESS_TO_CREATE_STRIPE_ACCOUNT_ENDPOINT = `/api/users`;
export const REMOVE_USER_ACCESS_TO_CREATE_STRIPE_ACCOUNT_ENDPOINT = `/api/users`;
export const GET_ALL_USERS_WHO_HAVE_ACCESS_TO_CREATE_STRIPE_ACCOUNT_ENDPOINT = `/api/users/access-to-create-stripe-account`;
export const UPDATE_MY_NOT_LISTED_NFTS_ACCESS_STATUS_ENDPOINT = `/api/users/not-listed-nfts-access-status`;
export const DO_I_HAVE_ACCESS_TO_CREATE_NFT_COLLECTION_OR_COMMUNITY_ENDPOINT = `/api/users/access-to-create-collection-or-community`;
export const GET_ALL_USERS_GROUPED_BY_UNVERIFIED_EMAILS_ENDPOINT = `/api/users/unverified-emails`;
export const UPDATE_LEVEL_2_VERIFICATION_FOR_A_SPECIFIC_USER_ENDPOINT = `/api/users`;
export const GET_ALL_LEVEL_2_VERIFIED_USERS_ENDPOINT = `/api/users/level-2-verified`;
export const GET_ALL_USERS_UNDER_SUSPICION_ENDPOINT = `/api/users/under-suspicion`;
export const GET_ALL_USERS_REGISTERED_VIA_SPECIFIC_SIGNUP_TYPE_ENDPOINT = `/api/users/registered-via-specific-signup-type`;
export const DELETE_USER_ACCOUNT_ENDPOINT = `/api/users`;
export const GET_TOP_CREATORS_ENDPOINT = `/api/users/top-creators`;
export const UPDATE_MY_CREATOR_SERVICES_ENDPOINT = `/api/users/creator-services`;
export const CREATE_USER_BY_ADMIN_ENDPOINT = `/api/users/create-user-by-admin`;
export const CHANGE_PASSWORD_FOR_USER_CREATED_BY_ADMIN_ENDPOINT = `/api/users/change-password-created-by-admin`;
export const GET_MY_ACCESSIBILITY_INFORMATION_ENDPOINT = `/api/users/my-accessibility/information`;

// api/followers
export const GET_MY_FOLLOWINGS_ENDPOINT = `/api/followers/my-followings`;
export const GET_MY_PAGINATED_FOLLOWINGS_ENDPOINT = `/api/followers/my-paginated-followings`;
export const GET_MY_FOLLOWERS_ENDPOINT = `/api/followers/my-followers`;
export const GET_MY_PAGINATED_FOLLOWERS_ENDPOINT = `/api/followers/my-paginated-followers`;
export const REMOVE_FOLLOWER_ENDPOINT = `/api/followers/remove-followers`;
export const REMOVE_FOLLOWING_ENDPOINT = `/api/followers/remove-following`;
export const GET_USER_FOLLOWINGS_AND_FOLLOWERS_ENDPOINT = `/api/followers/followings-followers`;
export const REQUEST_FOLLOWING_ENDPOINT = `/api/followers/request`;
export const UNFOLLOW_USER_ENDPOINT = `/api/followers/reject`;
export const GET_MY_PAGINATED_FRIENDS_ENDPOINT = `/api/followers/my-paginated-friends`;
export const GET_MY_FRIENDS_ENDPOINT = `/api/followers/my-friends`;
export const GET_USER_PAGINATED_FOLLOWERS = `/api/followers/paginated-followers`;
export const GET_USER_PAGINATED_FOLLOWINGS = `/api/followers/paginated-followings`;
export const GET_USER_PAGINATED_FRIENDS = `/api/followers/paginated-friends`;
export const GET_PAGINATED_UNION_OF_MY_FOLLOWERS_AND_FOLLOWINGS_ENDPOINT = `/api/followers/my-paginated-union-followers-followings`;
export const GET_UNION_OF_MY_FOLLOWERS_AND_FOLLOWINGS_ENDPOINT = `/api/followers/my-union-followers-followings`;
export const GET_FRIEND_SUGGESTIONS_ENDPOINT = `/api/followers/suggestions`;
export const GET_PAGINATED_MUTUAL_FOLLOWERS_ENDPOINT = `/api/followers/paginated-mutual-followers`;
export const GET_NUMBER_OF_MUTUAL_FOLLOWERS_ENDPOINT = `/api/followers/number-of-mutual-followers`;

// api/nfts
export const CREATE_NFT_ENDPOINT = `/api/nfts`;
export const GET_MY_NFTS_ENDPOINT = `/api/nfts`;
export const GET_NFT_BY_ID_ENDPOINT = `/api/nfts`;
export const LOCK_NFT_FOR_SALE_ENDPOINT = `/api/nfts`;
export const UNLOCK_NFT_FOR_SALE_ENDPOINT = `/api/nfts`;
export const GET_NFTS_GROUPED_BY_CATEGORIES_FOR_SALE = `/api/nfts/grouped-by-categories-for-sale`;
export const GET_NFTS_FOR_SALE_PER_USER = `/api/nfts/for-sale`;
export const GET_NFTS_NOT_FOR_SALE_PER_USER_ENDPOINT = `/api/nfts/not-for-sale`;
export const GET_RECENT_SALES_ENDPOINT = `/api/nfts`;
export const GET_SIMILAR_NFTS_BY_CATEGORY_AND_SUBCATEGORY_ENDPOINT = `/api/nfts/similar-nfts`;
export const GET_NEW_NFTS_ENDPOINT = `/api/nfts/new`;
export const GET_NFTS_WITH_OFFERS_ENDPOINT = `/api/nfts/with-offers`;
export const GET_NFTS_IN_AUCTION_ENDPOINT = `/api/nfts/in-auction`;
export const GET_ALL_MY_NFTS_FOR_SALE_ENDPOINT = `/api/nfts/for-sale`;
export const GET_ALL_MY_NFTS_NOT_FOR_SALE_ENDPOINT = `/api/nfts/not-for-sale`;
export const GET_NFTS_FOR_SALE_FOR_SPECIFIC_CATEGORY_AND_SUBCATEGORY_ENDPOINT = `/api/nfts/grouped-by-categories-and-subcategories-for-sale`;
export const GET_USER_NFTS_STATISTICS = `/api/nfts/user-statistics`;
export const GET_ALL_NFTS_ENDPOINT = `/api/nfts/all`;
export const UPDATE_NFT_CONTENT_ENDPOINT = `/api/nfts`;
export const GET_USER_NFTS_AS_OWNER_AND_CREATOR_ENDPOINT = `/api/nfts/owner-and-creator`;
export const DELETE_NFT_ENDPOINT = `/api/nfts`;
export const GET_MOST_RECENTS_NFTS_FROM_MY_FOLLOWINGS_ENDPOINT = `/api/nfts/most-recent-from-followings`;
export const LIST_NFT_FOR_BUY_NOW_ENDPOINT = `/api/nfts`;
export const UNLIST_BUY_NOW_ENDPOINT = `/api/nfts`;
export const GET_NFTS_LISTED_FOR_BUY_NOW_ENDPOINT = `/api/nfts/listed-for-buy-now`;
export const UPDATE_NFT_BUY_NOW_PRICE_ENDPOINT = `/api/nfts`;
export const BUY_NOW_NFT_ENDPOINT = `/api/nfts`;
export const GET_RECENTLY_ADDED_NFTS_ENDPOINT = `/api/nfts/recently-added`;
export const GET_MY_RECENTLY_ADDED_NFTS_ENDPOINT = `/api/nfts/my-recently-added`;
export const GET_USER_RECENTLY_ADDED_NFTS_ENDPOINT = `/api/nfts/user-recently-added`;
export const UPDATE_NFT_PREVIEW_URL_ENDPOINT = `/api/nfts`;
export const GET_MY_NFTS_NOT_PART_OF_A_COLLECTION_ENDPOINT = `/api/nfts/not-part-of-collection`;
export const UPDATE_NFT_ENDPOINT = `/api/nfts`;
export const CREATE_NFT_PART_OF_FUNGIBLE_AGNOSTIC_COLLECTION_ENDPOINT = `/api/nfts/fungible-agnostic`;
export const CLAIM_NFT_ENDPOINT = `/api/nfts`;
export const GET_MY_CLAIMABLE_ACCESS_FOR_A_SPECIFIC_NFT_ENDPOINT = `/api/nfts`;
export const GET_MOST_RECENT_NFTS_FROM_MY_UNIQUE_FOLLOWINGS_ENDPOINT = `/api/nfts/most-recent-from-unique-followings`;
export const GET_UNLOCKABLE_CONTENT_FOR_SPECIFIC_NFT_ENDPOINT = `/api/nfts`;
export const GET_UNLOCKABLE_CONTENT_STATUS_FOR_SPECIFIC_NFT_ENDPOINT = `/api/nfts`;
export const UPLOAD_AUDIO_FOR_SPECIFIC_NFT_ENDPOINT = `/api/nfts`;

// api/categoryNfts
export const GET_ALL_CATEGORIES_OF_NFTS_ENDPOINT = `/api/categoryNfts`;
export const GET_ALL_SUB_CATEGORIES_OF_NFTS_ENDPOINT = `/api/categoryNfts`;
export const GET_ALL_CATEGORIES_AND_CORRESPONDING_SUBCATEGORIES_ENDPOINT = `/api/categoryNfts/subCategories`;
export const UPDATE_SUBCATEGORY_ENDPOINT = `/api/categoryNfts`;
export const UPDATE_CATEGORY_ENDPOINT = `/api/categoryNfts`;

// api/watchlist
export const ADD_NFT_TO_WATCHLIST = `/api/watchlist`;
export const REMOVE_NFT_FROM_WATCHLIST = `/api/watchlist`;
export const GET_MY_WATCHLIST = `/api/watchlist`;
export const GET_NUMBER_OF_WATCHLISTS_FOR_SPECIFIC_NFT_ENDPOINT = `/api/watchlist`;

// api/offers
export const GET_MY_OFFERS_ENDPOINT = `/api/offers`;
export const GET_MY_RECEIVED_OFFERS_ENDPOINT = `/api/offers/received`;
export const MAKE_OFFER_ENDPOINT = `/api/offers`;
export const GET_NFT_OFFERS_ENDPOINT = `/api/offers`;

// api/likes
export const GET_MY_LIKES_ENDPOINT = `/api/likes`;
export const LIKE_NFT_ENDPOINT = `/api/likes`;
export const UNLIKE_NFT_ENDPOINT = `/api/likes`;
export const GET_NUMBER_OF_LIKES_FOR_SPECIFIC_NFT_ENDPOINT = `/api/likes`;

// api/tools/price-conversion
export const GET_CRYPTOCURRENCY_PRICE_CONVERSION = `/api/tools/price-conversion`;

// api/notifications
export const GET_MY_NOTIFICATIONS = `/api/notifications`;
export const MARK_NOTIFICATION_AS_READ_ENDPOINT = `/api/notifications`;
export const MARK_ALL_NOTIFICATIONS_AS_READ_ENDPOINT = `/api/notifications/mark-all-as-read`;
export const GET_NUMBER_OF_UNREAD_NOTIFICATIONS_ENDPOINT = `/api/notifications/unread-notifications-count`;
export const CREATE_NOTIFICATIONS_TO_MULTIPLE_USERS_ENDPOINT = `/api/notifications/multiple`;
export const NOTIFY_CLAIM_NFT_LINK_TO_MY_FOLLOWERS_ENDPOINT = `/api/notifications/claim-nft-link`;
export const NOTIFY_COLLECTION_CREATED_TO_MY_FOLLOWERS_ENDPOINT = `/api/notifications/collection-created`;

// api/events
export const GET_FUTURE_EVENTS_ENDPOINT = `/api/events/future`;
export const GET_NEW_EVENTS_ENDPOINT = `/api/events/new`;
export const GET_EVENT_ENDPOINT = `/api/events`;
export const CREATE_EVENT_ENDPOINT = `/api/events`;
export const GET_GROUPED_EVENTS_BY_LOCATION_ENDPOINT = `/api/events/grouped-by-location`;
export const GET_GROUPED_EVENTS_BY_CATEGORY_ENDPOINT = `/api/events/grouped-by-category`;
export const GET_ALL_EVENTS_ENDPOINT = `/api/events`;
export const UPDATE_EVENT_ENDPOINT = `/api/events`;
export const UPLOAD_EVENT_PHOTOS_ENDPOINT = `/api/events`;
export const DELETE_EVENT_ENDPOINT = `/api/events`;

// api/nft-event
export const GET_NFTS_FOR_SPECIFIC_EVENT_ENDPOINT = `/api/nft-event`;
export const GET_NEW_NFTS_ASSOCIATED_WITH_EVENT_ENDPOINT = `/api/nft-event/new`;
export const GET_ALL_NFTS_ASSOCIATED_WITH_EVENT_ENDPOINT = `/api/nft-event`;
export const GET_EVENT_STATISTICS_ENDPOINT = `/api/nft-event/statistics`;
export const GET_TRENDING_EVENTS_ENDPOINT = `/api/nft-event/trending`;
export const GET_EVENT_FOR_SPECIFIC_NFT_ENDPOINT = `/api/nft-event/nfts`;

// api/chats
export const GET_MY_CHATS_ENDPOINT = `/api/chats`;
export const CREATE_GROUP_CHAT_ENDPOINT = `/api/chats/group`;
export const GET_CHAT_MESSAGES_ENDPOINT = `/api/chats`;
export const CREATE_MESSAGE_ENDPOINT = `/api/chats`;
export const MARK_CHAT_MESSAGES_AS_READ = `/api/chats`;
export const MARK_CHAT_MESSAGE_AS_READ = `/api/chats`;
export const LEAVE_CHAT_ENDPOINT = `/api/chats`;
export const EDIT_CHAT_NAME_ENDPOINT = `/api/chats`;
export const ADD_NEW_CHAT_MEMBERS_ENDPOINT = `/api/chats`;
export const GET_NUMBER_OF_UNREAD_MESSAGES_FOR_MY_CHATS_ENDPOINTS = `/api/chats/number-of-unread-messages`;
export const MARK_CHAT_MESSAGE_AS_RECEIVED = `/api/chats`;
export const MARK_ALL_CHAT_MESSAGES_AS_RECEIVED_ENDPOINT = `/api/chats`;
export const CREATE_SINGLE_CHAT_ENDPOINT = `/api/chats`;
export const DELETE_CHAT_ENDPOINT = `/api/chats`;
export const UPDATE_CHAT_MEMBERS_ENDPOINT = `/api/chats`;
export const REMOVE_MEMBER_FROM_CHAT_ENDPOINT = `/api/chats`;
export const GET_MY_REQUESTED_CHATS_ENDPOINT = `/api/chats/requested`;
export const ACCEPT_CHAT_REQUEST_ENDPOINT = `/api/chats`;
export const REJECT_CHAT_REQUEST_ENDPOINT = `/api/chats`;
export const GET_NUMBER_OF_MY_REQUESTED_CHATS_ENDPOINT = `/api/chats/requested-count`;

// api/wallets
export const CREATE_WALLET_ENDPOINT = `/api/wallets`;
export const GET_MY_WALLET_ENDPOINT = `/api/wallets`;

// api/owner-history
export const GET_OWNER_HISTORY_ENDPOINT = `/api/nfts`;
export const GET_MY_TRANSACTIONS_ENDPOINT = `/api/owner-history/transactions`;
export const GET_USERS_TRANSACTIONS_FOR_SOLD_NFTS_STATISTICS = `/api/owner-history/sold-nfts-statistics`;
export const GET_USERS_TRANSACTIONS_FOR_BOUGHT_NFTS_STATISTICS = `/api/owner-history/bought-nfts-statistics`;
export const GET_MOST_RECENT_SALES_ENDPOINT = `/api/owner-history/most-recent-sales`;
export const GET_USERS_WITH_MOST_RECENT_ACTIVITY_ENDPOINT = `/api/owner-history/users-with-most-recent-activity`;

// api/categoryNfts
export const CREATE_CATEGORY_NFT_ENDPOINT = `/api/categoryNfts`;
export const CREATE_SUB_CATEGORY_NFT_ENDPOINT = `/api/categoryNfts`;
export const GET_TRENDING_CATEGORIES_ENDPOINT = `/api/categoryNfts/trending`;
export const GET_RECENTLY_ADDED_CATEGORIES_ENDPOINT = `/api/categoryNfts/recently-added`;

// api/user-warning
export const CREATE_USER_WARNING_ENDPOINT = `/api/user-warnings`;

// api/auth
export const GET_USER_TOKEN_VIA_GITHUB_ENDPOINT = `/api/auth/github`;
export const LOGIN_VIA_GOOGLE_ENDPOINT = `/api/auth/google-login`;
export const LOGIN_VIA_FACEBOOK_ENDPOINT = `/api/auth/facebook`;

// api/reports
export const CREATE_REPORT_ENDPOINT = `/api/reports`;
export const GET_ALL_REPORTS_ENDPOINT = `/api/reports`;
export const APPROVE_REPORT_ENDPOINT = `/api/reports`;
export const UPDATE_REPORT_STATUS_ENDPOINT = `/api/reports`;
export const UPDATE_REPORT_ACTION_TAKEN_DESCRIPTION = `/api/reports`;

// api/nft-messages
export const CREATE_NFT_MESSAGE_ENDPOINT = `/api/nft-messages`;
export const GET_NFT_MESSAGES_ENDPOINT = `/api/nft-messages`;

// api/nft-votes
export const UP_VOTE_NFT_ENDPOINT = `/api/nft-votes`;
export const GET_NUMBER_OF_NFT_UPVOTES_ENDPOINT = `/api/nft-votes`;
export const DELETE_NFT_UP_VOTE_ENDPOINT = `/api/nft-votes`;
export const GET_MY_NFT_VOTE_FOR_SPECIFIC_NFT_ENDPOINT = `/api/nft-votes`;

// api/nft-comments
export const GET_NFT_COMMENTS_ENDPOINT = `/api/nft-comments`;
export const CREATE_NFT_COMMENT_ENDPOINT = `/api/nft-comments`;
export const UPDATE_NFT_COMMENT_ENDPOINT = `/api/nft-comments`;
export const DELETE_NFT_COMMENT_ENDPOINT = `/api/nft-comments`;

// api/nft-comment-replies
export const GET_ALL_NFT_COMMENT_REPLIES_ENDPOINT = `/api/nft-comment-replies`;
export const CREATE_NFT_COMMENT_REPLY_ENDPOINT = `/api/nft-comment-replies`;

// api/nft-comment-likes
export const LIKE_NFT_COMMENT_ENDPOINT = `/api/nft-comment-likes`;
export const REMOVE_NFT_COMMENT_LIKE_ENDPOINT = `/api/nft-comment-likes`;
export const GET_NUMBER_OF_LIKES_FOR_SPECIFIC_NFT_COMMENT_ENDPOINT = `/api/nft-comment-likes`;
export const GET_MY_NFT_COMMENT_LIKE_STATUS_ENDPOINT = `/api/nft-comment-likes`;

// api/nft-comment-reply-likes
export const LIKE_NFT_COMMENT_REPLY_ENDPOINT = `/api/nft-comment-reply-likes`;
export const REMOVE_NFT_COMMENT_REPLY_LIKE_ENDPOINT = `/api/nft-comment-reply-likes`;
export const GET_NUMBER_OF_LIKES_FOR_SPECIFIC_NFT_COMMENT_REPLY_ENDPOINT = `/api/nft-comment-reply-likes`;
export const GET_MY_NFT_COMMENT_REPLY_LIKE_STATUS_ENDPOINT = `/api/nft-comment-reply-likes`;

// api/user-posts
export const CREATE_USER_POST_ENDPOINT = `/api/user-posts`;
export const GET_POSTS_OF_OTHER_PEOPLE_ENDPOINT = `/api/user-posts/others`;
export const GET_POST_ENDPOINT = `/api/user-posts`;
export const GET_MY_POSTS_ENDPOINT = `/api/user-posts`;
export const GET_POSTS_FOR_SPECIFIC_USER_ENDPOINT = `/api/user-posts/users`;
export const UPLOAD_USER_POST_VIDEO_ENDPOINT = `/api/user-posts`;
export const DELETE_POST_ENDPOINT = `/api/user-posts`;
export const ADD_POST_TO_RECYCLE_BIN_ENDPOINT = `/api/user-posts`;
export const GET_USER_POSTS_FOR_SPECIFIC_TOPIC_ENDPOINT = `/api/user-posts`;
export const UPDATE_MY_POST_ENDPOINT = `/api/user-posts`;
export const GET_POSTS_OF_MY_NFT_COLLECTOR_HOLDERS_ENDPOINT = `/api/user-posts/my-nft-collector-holders`;

// api/user-post-likes
export const LIKE_USER_POST_ENDPOINT = `/api/user-post-likes`;
export const GET_NUMBER_OF_LIKES_FOR_SPECIFIC_USER_POST_ENDPOINT = `/api/user-post-likes`;
export const REMOVE_USER_POST_LIKE_ENDPOINT = `/api/user-post-likes`;
export const GET_USERS_WHO_HAVE_LIKED_A_SPECIFIC_USER_POST_ENDPOINT = `/api/user-post-likes`;
export const GET_SOME_FOLLOWINGS_WHO_HAVE_LIKED_SPECIFIC_USER_POST_ENDPOINT = `/api/user-post-likes`;

// api/user-post-comments
export const CREATE_USER_POST_COMMENT_ENDPOINT = `/api/user-post-comments`;
export const GET_ALL_COMMENTS_FOR_SPECIFIC_USER_POST_ENDPOINT = `/api/user-post-comments`;
export const GET_NUMBER_OF_COMMENTS_FOR_SPECIFIC_POST_ENDPOINT = `/api/user-post-comments`;
export const DELETE_MY_POST_COMMENT_ENDPOINT = `/api/user-post-comments`;
export const UPDATE_MY_POST_COMMENT_ENDPOINT = `/api/user-post-comments`;

// api/user-post-comment-likes
export const LIKE_USER_POST_COMMENT_ENDPOINT = `/api/user-post-comment-likes`;
export const REMOVE_USER_POST_COMMENT_LIKE_ENDPOINT = `/api/user-post-comment-likes`;
export const GET_NUMBER_OF_LIKES_FOR_SPECIFIC_USER_POST_COMMENT = `/api/user-post-comment-likes`;
export const GET_MY_USER_POST_COMMENT_LIKE_STATUS_ENDPOINT = `/api/user-post-comment-likes`;

// api/user-post-comment-replies
export const CREATE_USER_POST_COMMENT_REPLY_ENDPOINT = `/api/user-post-comment-replies`;
export const GET_ALL_USER_POST_COMMENT_REPLIES_ENDPOINT = `/api/user-post-comment-replies`;
export const DELETE_USER_POST_COMMENT_REPLY_ENDPOINT = `/api/user-post-comment-replies`;

// api/user-post-comment-reply-likes
export const GET_NUMBER_OF_LIKES_FOR_SPECIFIC_USER_POST_COMMENT_REPLY = `/api/user-post-comment-reply-likes`;
export const GET_MY_POST_COMMENT_REPLY_LIKE_STATUS_ENDPOINT = `/api/user-post-comment-reply-likes`;
export const LIKE_USER_POST_COMMENT_REPLY_ENDPOINT = `/api/user-post-comment-reply-likes`;
export const REMOVE_USER_POST_COMMENT_REPLY_LIKE_ENDPOINT = `/api/user-post-comment-reply-likes`;

// api/transfers
export const CREATE_TRANSFER_GLMS_ENDPOINT = `/api/transfers`;
export const VERIFY_AND_CONFIRM_TRANSFER_GLMS_ENDPOINT = `/api/transfers`;
export const CREATE_TRANSFER_GLMS_TO_A_SPECIFIC_USER_ENDPOINT = `/api/transfers`;

// api/favorite-friends
export const MARK_FRIEND_AS_FAVORITE_ENDPOINT = `/api/favorite-friends`;
export const REMOVE_FRIEND_FROM_FAVORITES_ENDPOINT = `/api/favorite-friends`;
export const GET_FAVORITE_FRIEND_STATUS_FOR_SPECIFIC_FRIEND_ENDPOINT = `/api/favorite-friends`;
export const GET_MY_PAGINATED_FAVORITE_FRIENDS_ENDPOINT = `/api/favorite-friends/paginated`;

// api/user-albums
export const CREATE_USER_ALBUM_ENDPOINT = `/api/user-albums`;
export const GET_MY_USER_ALBUMS_ENDPOINT = `/api/user-albums`;
export const UPLOAD_ALBUM_PHOTO_ENDPOINT = `/api/user-albums`;
export const DELETE_SPECIFIC_IMAGE_FROM_ALBUM_ENDPOINT = `/api/user-albums`;
export const GET_USER_ALBUMS_FOR_SPECIFIC_USER_ENDPOINT = `/api/user-albums/users`;
export const DELETE_ALBUM_ENDPOINT = `/api/user-albums`;

// api/user-life-events
export const CREATE_USER_LIFE_EVENT_ENDPOINT = `/api/user-life-events`;
export const UPLOAD_USER_LIFE_EVENT_VIDEO_ENDPOINT = `/api/user-life-events`;
export const GET_MY_LIFE_EVENTS_ENDPOINT = `/api/user-life-events`;
export const GET_USER_LIFE_USER_EVENTS_FOR_SPECIFIC_USER_ENDPOINT = `/api/user-life-events/users`;

// api/user-dedicated-posts
export const CREATE_USER_DEDICATED_POST_ENDPOINT = `/api/user-dedicated-posts`;
export const DELETE_USER_DEDICATED_POST_ENDPOINT = `/api/user-dedicated-posts`;
export const GET_USER_DEDICATED_POST_ENDPOINT = `/api/user-dedicated-posts`;
export const UPLOAD_USER_DEDICATED_POST_VIDEO_ENDPOINT = `/api/user-dedicated-posts`;
export const UPDATE_DEDICATED_POST_ENDPOINT = `/api/user-dedicated-posts`;

// api/user-upload-photos
export const UPLOAD_PHOTO_ENDPOINT = `/api/user-upload-photos`;
export const DELETE_PHOTO_ENDPOINT = `api/user-upload-photos`;
export const GET_MY_UPLOADED_PHOTOS_ENDPOINT = `api/user-upload-photos`;
export const GET_USER_UPLOADED_PHOTOS_ENDPOINT = `/api/user-upload-photos`;

// api/groups
export const CREATE_GROUP_ENDPOINT = `/api/groups`;
export const GET_MY_GROUPS_ENDPOINT = `/api/groups`;
export const UPLOAD_GROUP_COVER_PHOTO_ENDPOINT = `/api/groups`;
export const GET_SPECIFIC_GROUP_ENDPOINT = `/api/groups`;
export const GET_MY_GROUPS_AS_ADMIN_ENDPOINT = `/api/groups/as-admin`;
export const GET_MY_GROUPS_I_HAVE_JOINED_ENDPOINT = `/api/groups/joined`;
export const GET_GROUP_SUGGESTIONS_ENDPOINT = `/api/groups/suggestions`;
export const GET_MY_GROUPS_RECENT_ACTIVITY_ENDPOINT = `/api/groups/recent-activity`;
export const LEAVE_GROUP_ENDPOINT = `/api/groups`;
export const UPDATE_MY_GROUP_ENDPOINT = `/api/groups`;
export const GET_NUMBER_OF_UNREAD_MESSAGES_FOR_MY_GROUPS_ENDPOINT = `/api/groups/number-of-unread-messages`;
export const GET_NUMBER_OF_UNREAD_MESSAGES_FOR_SPECIFIC_GROUP_ENDPOINT = `/api/groups`;
export const GET_GROUPS_FOR_A_SPECIFIC_USER_ENDPOINT = `/api/groups/users`;
export const GET_ALL_GROUPS_ENDPOINT = `/api/groups/all`;
export const HIDE_GROUP_FROM_SUGGESTIONS_ENDPOINT = `/api/groups`;
export const SHOW_GROUP_IN_SUGGESTIONS_ENDPOINT = `/api/groups`;
export const GET_ALL_GROUPS_NOT_HIDDEN_FROM_SUGGESTIONS_ENDPOINT = `/api/groups/not-hidden-from-suggestions`;
export const HIDE_ALL_GROUPS_FROM_SUGGESTIONS_ENDPOINT = `/api/groups/hide-all-from-suggestions`;
export const SHOW_ALL_GROUPS_IN_SUGGESTIONS_ENDPOINT = `/api/groups/show-all-in-suggestions`;
export const UPDATE_WHO_CAN_POST_IN_A_SPECIFIC_GROUP_ENDPOINT = `/api/groups`;
export const UPDATE_GROUP_BENEFITS_ENDPOINT = `/api/groups`;
export const GET_MY_GROUPS_AS_ORGANIZER_ENDPOINT = `/api/groups/as-organizer`;

// api/group-members
export const GET_ALL_GROUP_MEMBERS_FOR_A_SPECIFIC_GROUP = `/api/group-members`;
export const GET_ALL_NON_PAGINATED_GROUP_MEMBERS_FOR_A_SPECIFIC_GROUP_ENDPOINT = `/api/group-members`;
export const GET_NUMBER_OF_MEMBERS_FOR_A_SPECIFIC_GROUP_ENDPOINT = `/api/group-members`;
export const GET_GROUP_ADMINS_ENDPOINT = `/api/group-members`;
export const GET_MEMBER_STATUS_IN_A_SPECIFIC_GROUP_ENDPOINT = `/api/group-members`;
export const GET_MEMBER_ROLE_IN_A_SPECIFIC_GROUP_ENDPOINT = `/api/group-members`;
export const GET_GROUP_MEMBERS_EXCEPT_ADMINS_ENDPOINT = `/api/group-members`;
export const JOIN_PUBLIC_GROUP_ENDPOINT = `/api/group-members`;
export const REQUEST_GROUP_MEMBERSHIP_ENDPOINT = `/api/group-members`;
export const GET_ALL_REQUESTED_GROUP_MEMBERSHIPS_ENDPOINT = `/api/group-members`;
export const ACCEPT_REQUESTED_GROUP_MEMBERSHIPS_ENDPOINT = `/api/group-members`;
export const REJECT_REQUESTED_GROUP_MEMBERSHIP_ENDPOINT = `/api/group-members`;
export const MARK_USER_AS_GROUP_ADMIN_ENDPOINT = `/api/group-members`;
export const REMOVE_GROUP_ADMIN_ENDPOINT = `/api/group-members`;
export const REMOVE_GROUP_MEMBER_ENDPOINT = `/api/group-members`;

// api/group-invitations
export const INVITE_USERS_TO_GROUP_ENDPOINT = `/api/group-invitations`;
export const REACT_TO_GROUP_INVITATION_ENDPOINT = `/api/group-invitations`;

// api/user-group-posts
export const CREATE_USER_GROUP_POST_ENDPOINT = `/api/user-group-posts`;
export const GET_USER_GROUP_POSTS_ENDPOINT = `/api/user-group-posts`;
export const GET_USER_GROUP_POST_ENDPOINT = `/api/user-group-posts`;
export const UPDATE_MY_GROUP_POST_ENDPOINT = `/api/user-group-posts`;

// api/group-post-topics
export const GET_GROUP_POST_TOPICS_ENDPOINT = `/api/group-post-topics`;
export const GET_GROUP_POST_TOPICS_SORTED_BY_NUMBER_OF_POSTS_ENDPOINT = `/api/group-post-topics`;
export const CREATE_GROUP_POST_TOPIC_ENDPOINT = `/api/group-post-topics`;

// api/group-channels
export const GET_ALL_GROUP_CHANNELS_FOR_SPECIFIC_GROUP_ENDPOINT = `/api/group-channels`;
export const CREATE_GROUP_CHANNEL_ENDPOINT = `/api/group-channels`;
export const CREATE_PRIVATE_GROUP_CHANNEL_ENDPOINT = `/api/group-channels`;
export const UPDATE_GROUP_CHANNEL_NAME_ENDPOINT = `/api/group-channels`;
export const DELETE_GROUP_CHANNEL_ENDPOINT = `/api/group-channels`;
export const MARK_ALL_GROUP_CHANNEL_MESSAGES_AS_READ_ENDPOINT = `/api/group-channels`;
export const GET_NUMBER_OF_UNREAD_MESSAGES_FOR_SPECIFIC_GROUP_CHANNEL_ENDPOINT = `/api/group-channels`;
export const UPDATE_WHO_CAN_WRITE_IN_A_SPECIFIC_GROUP_CHANNEL_ENDPOINT = `/api/group-channels`;
export const GET_SPECIFIC_GROUP_CHANNEL_ENDPOINT = `/api/group-channels`;

// api/group-messages
export const GET_ALL_GROUP_MESSAGES_FOR_SPECIFIC_CHANNEL_ENDPOINT = `/api/group-messages`;
export const CREATE_GROUP_MESSAGE_ENDPOINT = `/api/group-messages`;
export const MARK_GROUP_MESSAGE_AS_READ_ENDPOINT = `/api/group-messages`;

// api/group-channel-invitations
export const INVITE_USERS_TO_GROUP_CHANNEL_ENDPOINT = `/api/group-channel-invitations`;
export const REACT_TO_GROUP_CHANNEL_INVITATION_ENDPOINT = `/api/group-channel-invitations`;
export const GET_AVAILABLE_USERS_TO_INVITE_PRIVATE_GROUP_CHANNEL_ENDPOINT = `/api/group-channel-invitations`;
export const GET_ALL_NON_PAGINATED_AVAILABLE_USERS_TO_INVITE_PRIVATE_GROUP_CHANNEL_ENDPOINT = `/api/group-channel-invitations`;

// api/private-group-channel-members
export const GET_ALL_MEMBERS_FOR_A_SPECIFIC_PRIVATE_GROUP_CHANNEL_ENDPOINT = `/api/private-group-channel-members`;
export const REMOVE_PRIVATE_GROUP_CHANNEL_MEMBER_ENDPOINT = `/api/private-group-channel-members`;

// api/nft-collections
export const GET_ALL_NFT_COLLECTIONS_ENDPOINT = `/api/nft-collections`;
export const CREATE_NFT_COLLECTION_ENDPOINT = `/api/nft-collections`;
export const GET_SPECIFIC_NFT_COLLECTION_ENDPOINT = `/api/nft-collections`;
export const GET_NFT_COLLECTION_STATISTICS_ENDPOINT = `/api/nft-collections`;
export const UPDATE_NFT_COLLECTION_ENDPOINT = `/api/nft-collections`;
export const GET_NFT_COLLECTION_ACTIVITY_ENDPOINT = `/api/nft-collections`;
export const REMOVE_NFT_COLLECTION_COLLABORATOR_ENDPOINT = `/api/nft-collections`;
export const ADD_NFT_COLLECTION_TO_A_SPECIFIC_GROUP_ENDPOINT = `/api/nft-collections`;
export const REMOVE_NFT_COLLECTION_FROM_A_SPECIFIC_GROUP_ENDPOINT = `/api/nft-collections`;
export const GET_ALL_NFT_COLLECTIONS_ASSOCIATED_WITH_A_SPECIFIC_GROUP_ENDPOINT = `/api/nft-collections`;
export const UPDATE_NFT_COLLECTION_STATUS_ENDPOINT = `/api/nft-collections`;
export const GET_UNIQUE_OWNERS_FOR_A_SPECIFIC_NFT_COLLECTION_ENDPOINT = `/api/nft-collections`;
export const UPDATE_NFT_COLLECTION_HOLDER_BENEFITS_ENDPOINT = `/api/nft-collections`;
export const DELETE_MY_NFT_COLLECTION_ENDPOINT = `/api/nft-collections`;
export const GET_MY_NFT_COLLECTIONS_ENDPOINT = `/api/nft-collections/mine`;
export const GET_NFT_COLLECTIONS_FOR_A_SPECIFIC_USER_ENDPOINT = `/api/nft-collections/users`;
export const GET_MY_NFTS_NOT_PART_OF_A_COLLECTION_AND_COLLABORATOR_REQUEST_STATUS_ENDPOINT = `/api/nft-collections/nfts-not-part-of-collection-and-collaborator-request-status`;

// api/nft-collection-mappings
export const CREATE_NFT_COLLECTION_MAPPING_ENDPOINT = `/api/nft-collection-mappings`;
export const GET_ALL_NFTS_FOR_SPECIFIC_COLLECTION_ENDPOINT = `/api/nft-collection-mappings`;
export const ADD_NFT_TO_COLLECTION_ENDPOINT = `/api/nft-collection-mappings`;
export const IS_NFT_ACCESSIBLE_TO_BUY_BY_ME_ENDPOINT = `/api/nft-collection-mappings`;
export const GET_CLAIMABLE_NFTS_FOR_A_SPECIFIC_COLLECTION_ENDPOINT = `/api/nft-collection-mappings`;
export const GET_NFT_METADATA_FOR_A_SPECIFIC_FUNGIBILITY_AGNOSTIC_NFT_COLLECTION_ENDPOINT = `/api/nft-collection-mappings`;
export const CLAIM_RANDOM_NFT_FROM_A_CLAIMABLE_COLLECTION_ENDPOINT = `/api/nft-collection-mappings`;
export const GET_TOP_NFTS_FOR_SPECIFIC_COLLECTION_ENDPOINT = `/api/nft-collection-mappings/top-nfts`;

// api/watchlist-nft-collections
export const ADD_NFT_COLLECTION_TO_WATCHLIST_ENDPOINT = `/api/watchlist-nft-collections`;
export const REMOVE_NFT_COLLECTION_FROM_WATCHLIST_ENDPOINT = `/api/watchlist-nft-collections`;
export const GET_MY_WATCHLIST_STATUS_FOR_SPECIFIC_NFT_COLLECTION_ENDPOINT = `/api/watchlist-nft-collections`;
export const GET_MY_NFT_COLLECTION_WATCHLIST_ENDPOINT = `/api/watchlist-nft-collections`;

// api/nft-collection-collaborator-invitations
export const INVITE_NFT_COLLECTION_COLLABORATOR_ENDPOINT = `/api/nft-collection-collaborator-invitations`;
export const GET_AVAILABLE_USERS_TO_INVITE_AS_NFT_COLLECTION_COLLABORATOR_ENDPOINT = `/api/nft-collection-collaborator-invitations`;
export const REACT_TO_NFT_COLLECTION_COLLABORATION_INVITATION_ENDPOINT = `/api/nft-collection-collaborator-invitations`;

// api/user-activity-topics
export const GET_ALL_PAGINATED_USER_ACTIVITY_TOPICS_ENDPOINT = `/api/user-activity-topics`;
export const GET_USER_ACTIVITY_TRENDING_TOPICS_ENDPOINT = `/api/user-activity-topics/trending-topics`;
export const GET_USER_ACTIVITY_TRENDING_POST_TOPICS_ENDPOINT = `/api/user-activity-topics/trending-post-topics`;

// api/user-blocks
export const BLOCK_USER_ENDPOINT = `/api/user-blocks`;
export const GET_USER_BLOCK_STATUS_WITH_A_SPECIFIC_USER_ENDPOINT = `/api/user-blocks`;
export const UNBLOCK_USER_ENDPOINT = `/api/user-blocks`;

// api/link-preview-urls
export const GET_LINK_PREVIEW_URL_ENDPOINT = `/api/link-preview-urls`;

// api/nft-offers
export const MAKE_NFT_OFFER_ENDPOINT = `/api/nft-offers`;
export const ACCEPT_NFT_OFFER_ENDPOINT = `/api/nft-offers`;
export const GET_NFT_OFFERS_FOR_SPECIFIC_NFT_ENDPOINT = `/api/nft-offers`;
export const REJECT_NFT_OFFER_ENDPOINT = `/api/nft-offers`;
export const GET_MY_RECEIVED_NFT_OFFERS_ENDPOINT = `/api/nft-offers/received`;
export const GET_MY_SENT_NFT_OFFERS_ENDPOINT = `/api/nft-offers/sent`;
export const DELETE_MY_NFT_OFFER_ENDPOINT = `/api/nft-offers`;
export const UPDATE_MY_NFT_OFFER_ENDPOINT = `/api/nft-offers`;

// api/nft-requests
export const CREATE_NFT_REQUEST_ENDPOINT = `/api/nft-requests`;
export const GET_MY_PENDING_NFT_REQUESTS = `/api/nft-requests/pending`;
export const REJECT_NFT_REQUEST_ENDPOINT = `/api/nft-requests`;
export const UPLOAD_REQUESTED_NFT_ENDPOINT = `/api/nft-requests`;
export const GET_MY_REQUESTED_NFTS = `/api/nft-requests`;
export const GET_REQUESTED_NFTS_FROM_ME = `/api/nft-requests/from-me`;

// api/user-referrals
export const GET_MY_REFERRALS_NETWORK_ENDPOINT = `/api/user-referrals/network`;
export const GET_MY_DIRECT_REFERRALS_ENDPOINT = `/api/user-referrals`;

// api/crypto-news
export const GET_LATEST_CRYPTO_NEWS_ENDPOINT = `/api/crypto-news`;

// api/favorite-chats
export const GET_MY_FAVORITE_CHATS_ENDPOINT = `/api/favorite-chats`;
export const MARK_CHAT_AS_FAVORITE_ENDPOINT = `/api/favorite-chats`;
export const REMOVE_CHAT_AS_FAVORITE_ENDPOINT = `/api/favorite-chats`;
export const GET_FAVORITE_STATUS_FOR_SPECIFIC_CHAT_ENDPOINT = `/api/favorite-chats`;

// api/favorite-groups
export const MARK_GROUP_AS_FAVORITE_ENDPOINT = `/api/favorite-groups`;
export const REMOVE_GROUP_AS_FAVORITE_ENDPOINT = `/api/favorite-groups`;
export const GET_GROUP_FAVORITE_STATUS_ENDPOINT = `/api/favorite-groups`;

// api/request-beta-accesses
export const REQUEST_BETA_ACCESS_ENDPOINT = `/api/request-beta-accesses`;
export const GET_ALL_BETA_ACCESS_REQUESTS_ENDPOINT = `/api/request-beta-accesses`;

// api/creator-applies
export const APPLY_AS_CREATOR_ENDPOINT = `/api/creator-applies`;
export const GET_ALL_CREATOR_APPLIES_ENDPOINT = `api/creator-applies`;

// api/group-polls
export const CREATE_GROUP_POLL_ENDPOINT = `/api/group-polls`;
export const GET_ALL_POLLS_FOR_A_SPECIFIC_GROUP_ENDPOINT = `/api/group-polls`;

// api/group-poll-votes
export const CREATE_GROUP_POLL_VOTE_ENDPOINT = `/api/group-poll-votes`;
export const GET_VOTES_FOR_A_SPECIFIC_POLL_ENDPOINT = `/api/group-poll-votes`;
export const GET_VOTES_INFORMATION_FOR_A_SPECIFIC_GROUP_POLL_ENDPOINT = `/api/group-poll-votes`;
export const GET_USERS_WHO_VOTED_FOR_A_SPECIFIC_GROUP_POLL_ANSWER_ENDPOINT = `/api/group-poll-votes`;

// api/group-poll-answers
export const CREATE_GROUP_POLL_ANSWER_ENDPOINT = `/api/group-poll-answers`;
export const HAS_USER_ACCESS_TO_CREATE_A_GROUP_POLL_ANSWER_ENDPOINT = `/api/group-poll-answers`;

// api/mux
export const GET_MUX_SIGNED_JWT_ENDPOINT = `/api/mux/signed-jwt`;
export const MAP_FULLNAME_TO_PARTICIPANT_ID_ENDPOINT = `/api/mux`;
export const REMOVE_PARTICIPANT_ID_FROM_MAPPING_ENDPOINT = `/api/mux`;
export const GET_ALL_PARTICIPANTS_ENDPOINT = `/api/mux/participants`;

// api/stripe
export const CREATE_STRIPE_CHECKOUT_ENDPOINT = `/api/stripe/create-checkout-session`;

// api/quicknode
export const GET_ALL_NFTS_FOR_A_SPECIFIC_METAMASK_WALLET_ADDRESS_ENDPOINT = `/api/quicknode`;

// api/transfer-nfts
export const CREATE_TRANSFER_OF_NFT_ENDPOINT = `/api/transfer-nfts`;
export const VERIFY_AND_CONFIRM_TRANSFER_OF_NFT_ENDPOINT = `/api/transfer-nfts`;

// api/plaid
export const CREATE_PLAID_LINK_TOKEN_ENDPOINT = `/api/plaid/create_link_token`;
export const EXCHANGE_PLAID_PUBLIC_TOKEN_ENDPOINT = `/api/plaid/exchange_public_token`;
export const DISCONNECT_BANK_ACCOUNT_ENDPOINT = `/api/plaid/disconnect-bank`;
export const DEPOSIT_TO_MY_CONNECTED_BANK_ACCOUNT_VIA_PLAID_ENDPOINT = `/api/plaid/deposit-to-my-connected-bank-account`;

// api/wyre
export const MAP_USERS_WITH_WYRE_USERS_ENDPOINT = `/api/wyre/map-users`;
export const GET_WYRE_WALLET_INFORMATION_ENDPOINT = `/api/wyre/wallet-info`;
export const CREATE_WYRE_TRANSFER_ENDPOINT = `/api/wyre/transfer`;
export const BUY_NFT_WITH_WYRE_WALLET_ENDPOINT = `/api/wyre/buy-nft`;
export const GET_ALL_WYRE_PAYMENTS_ENDPOINT = `/api/wyre/payments`;
export const GET_ALL_WYRE_TRANSFERS_ENDPOINT = `/api/wyre/transfers`;
export const WITHDRAW_TO_BANK_ACCOUNT_ENDPOINT = `/api/wyre/withdraw-to-bank`;
export const GET_WYRE_KYC_ONBOARDING_URL_ENDPOINT = `/api/wyre/kyc-onboarding-url`;
export const GET_WYRE_KYC_STATUS_ENDPOINT = `/api/wyre/kyc-status`;
export const DEPOSIT_TO_MY_CONNECTED_BANK_ACCOUNT_ENDPOINT = `/api/wyre/deposit-to-my-connected-bank-account`;
export const MAP_WYRE_USER_ID_AND_WALLET_ENDPOINT = `/api/wyre/map-id-and-wallet`;
export const UPDATE_MY_WYRE_ACCOUNT_FIELDS_ENDPOINT = `/api/wyre/account-fields`;

// api/collaborator-nft-requests
export const REQUEST_CREATOR_TO_ADD_MY_NFT_AS_COLLABORATOR_ENDPOINT = `/api/collaborator-nft-requests`;
export const GET_MY_REQUESTED_NFTS_FROM_COLLABORATORS_ENDPOINT = `/api/collaborator-nft-requests/from-collaborators`;
export const REJECT_REQUESTED_NFT_FROM_COLLABORATOR_ENDPOINT = `/api/collaborator-nft-requests`;
export const ACCEPT_REQUESTED_NFT_FROM_COLLABORATOR_ENDPOINT = `/api/collaborator-nft-requests`;
export const GET_MY_REQUESTED_NFTS_AS_COLLABORATOR_ENDPOINT = `/api/collaborator-nft-requests/as-collaborator`;
export const DELETE_MY_NFT_REQUEST_AS_COLLABORATOR_ENDPOINT = `/api/collaborator-nft-requests`;

// api/collaborator-nft-upload-requests
export const REQUEST_CREATOR_TO_UPLOAD_MY_NFTS_AS_COLLABORATOR_ENDPOINT = `/api/collaborator-nft-upload-requests`;
export const GET_MY_REQUESTED_NFT_UPLOADS_FROM_COLLABORATORS_ENDPOINT = `/api/collaborator-nft-upload-requests/from-collaborators`;
export const REJECT_REQUESTED_NFT_UPLOAD_FROM_COLLABORATOR_ENDPOINT = `/api/collaborator-nft-upload-requests`;
export const ACCEPT_REQUESTED_NFT_UPLOAD_FROM_COLLABORATOR_ENDPOINT = `/api/collaborator-nft-upload-requests`;
export const GET_MY_REQUESTED_NFT_UPLOADS_AS_COLLABORATOR_ENDPOINT = `/api/collaborator-nft-upload-requests/as-collaborator`;
export const DELETE_MY_NFT_REQUEST_UPLOAD_AS_COLLABORATOR_ENDPOINT = `/api/collaborator-nft-upload-requests`;

// api/stripe-payments
export const CREATE_STRIPE_PAYMENT_ACCOUNT_LINK_ENDPOINT = `/api/stripe-payments/account-link`;
export const CREATE_STRIPE_PAYMENT_CHECKOUT_SESSION_ENDPOINT = `/api/stripe-payments/create-checkout-session`;
export const GET_MY_STRIPE_ACCOUNT_DETAILS_ENDPOINT = `/api/stripe-payments/account`;
export const GET_ALL_STRIPE_PAYMENTS_ENDPOINT = `/api/stripe-payments`;
export const GET_STRIPE_ACCOUNT_STATUS_FOR_SPECIFIC_USER_ENDPOINT = `/api/stripe-payments`;

// api/glms-stripe-buy
export const CREATE_BUY_GLMS_STRIPE_CHECKOUT_SESSION_ENDPOINT = `/api/glms-stripe-buy/create-checkout-session`;
export const GET_ALL_GLMS_STRIPE_BUY_TRANSACTIONS_ENDPOINT = `/api/glms-stripe-buy`;

// api/points-stripe-buy
export const CREATE_BUY_POINTS_STRIPE_CHECKOUT_SESSION_ENDPOINT = `/api/points-stripe-buy/create-checkout-session`;
export const GET_ALL_POINTS_STRIPE_BUY_TRANSACTIONS_ENDPOINT = `/api/points-stripe-buy`;

// api/user-post-tips
export const CREATE_POST_TIP_ENDPOINT = `/api/user-post-tips`;
export const GET_TIP_STATUS_FOR_SPECIFIC_POST_ENDPOINT = `/api/user-post-tips`;

// api/points-transactions
export const GET_MY_POINTS_TRANSACTIONS_ENDPOINT = `/api/points-transactions`;

// api/glms-transactions
export const GET_MY_GLMS_TRANSACTIONS_ENDPOINT = `/api/glms-transactions`;

// api/get-started-tasks
export const GET_GET_STARTED_TASKS_COMPLETION_INFO_ENDPOINT = `/api/get-started-tasks/completion-info`;
export const GET_GET_STARTED_TASK_FOLLOW_PEOPLE_SUGGESTIONS_ENDPOINT = `/api/get-started-tasks/follow-people-suggestions`;
export const CLAIM_GET_STARTED_TASK_ENDPOINT = `/api/get-started-tasks/claim`;
export const GET_MY_CLAIMED_GET_STARTED_TASKS_ENDPOINT = `/api/get-started-tasks/claimed`;

// api/nft-posts
export const CREATE_NFT_POST_ENDPOINT = `/api/nft-posts`;
export const GET_ALL_NFT_POSTS_ENDPOINT = `/api/nft-posts`;

// api/nft-post-tips
export const CREATE_NFT_POST_TIP_ENDPOINT = `/api/nft-post-tips`;
export const GET_TIP_STATUS_FOR_SPECIFIC_NFT_POST_ENDPOINT = `/api/nft-post-tips`;

// api/user-service-prices
export const CREATE_USER_SERVICE_PRICE_ENDPOINT = `/api/user-service-prices`;
export const GET_MY_SERVICES_PRICES_ENDPOINT = `/api/user-service-prices`;

// api/user-service-requests
export const CREATE_USER_SERVICE_REQUEST_STRIPE_CHECKOUT_SESSION_ENDPOINT = `/api/user-service-requests/create-checkout-session`;
export const GET_REQUESTED_SERVICES_FOR_ME_ENDPOINT = `/api/user-service-requests/for-me`;
export const GET_REQUESTED_SERVICES_FROM_ME_ENDPOINT = `/api/user-service-requests/from-me`;
export const GET_SPECIFIC_REQUESTED_SERVICE_FOR_ME_ENDPOINT = `/api/user-service-requests/for-me`;
export const UPLOAD_REQUESTED_SERVICE_ENDPOINT = `/api/user-service-requests`;
export const GET_SPECIFIC_USER_SERVICE_PRICE_ENDPOINT = `/api/user-service-requests/price`;

// api/brand-items
export const CREATE_BRAND_ITEM_ENDPOINT = `/api/brand-items`;
export const GET_ALL_BRAND_ITEMS_LISTED_FOR_SALE_ENDPOINT = `/api/brand-items`;
export const GET_BRAND_ITEM_ENDPOINT = `/api/brand-items`;
export const UNLIST_BRAND_ITEM_FOR_SALE_ENDPOINT = `/api/brand-items`;
export const LIST_BRAND_ITEM_FOR_SALE_ENDPOINT = `/api/brand-items`;
export const UPDATE_BRAND_ITEM_PRICE_ENDPOINT = `/api/brand-items`;
export const CREATE_BRAND_ITEM_CHECKOUT_SESSION_ENDPOINT = `/api/brand-items/create-checkout-session`;

// api/payable-posts
export const CREATE_PAYABLE_POST_ENDPOINT = `/api/payable-posts`;
export const GET_PAYABLE_POSTS_OF_OTHERS_PEOPLE_ENDPOINT = `/api/payable-posts/others`;

// api/post-payments
export const CREATE_POST_PAYMENT_ENDPOINT = `/api/post-payments`;
