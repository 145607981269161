import React, { useEffect, useRef } from 'react';
import classes from './TextArea.module.css';

function TextArea(props) {
  const textareaRef = useRef();

  const resizeTextAreaHeightOnKeyupHandler = (e) => {
    const element = e.target;

    if (
      element.style?.height?.replace('px', '') &&
      parseInt(element.style.height.replace('px', '')) > 80
    )
      return;

    element.style.height = '1px';
    element.style.height = 5 + element.scrollHeight + 'px';
  };

  useEffect(() => {
    if (textareaRef.current?.value?.length === 0) {
      textareaRef.current.style.height = '1px';
      textareaRef.current.style.height =
        5 + textareaRef.current.scrollHeight + 'px';
    }
  }, [textareaRef]);

  return (
    <textarea
      ref={textareaRef}
      onKeyUp={props.shouldExpand && resizeTextAreaHeightOnKeyupHandler}
      {...props}
      className={classes['textarea']}
    />
  );
}

export default TextArea;
