import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  getPostRequest,
  getUserDedicatedPostRequest,
  getUserGroupPostRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import Post from '../../pages/NewsFeed/components/NewsFeedContent/components/Posts/components/Post/Post';
import classes from './UserPost.module.css';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import MessagesSidebar from '../../components/MessagesSidebar/MessagesSidebar';

function UserPost() {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const isDedicatedPost = queryParams.get('isDedicatedPost');
  const isGroupPost = queryParams.get('isGroupPost');

  const { postId } = useParams();

  const [userPost, setUserPost] = useState(null);
  const [error, setError] = useState();
  const [isPostLikedByMe, setIsPostLikedByMe] = useState(false);

  const getUserPost = useCallback(async () => {
    try {
      if (isDedicatedPost) {
        const userPostResponse = await getUserDedicatedPostRequest(postId);

        setUserPost(userPostResponse.data);
        setIsPostLikedByMe(userPostResponse.isPostLikedByMe);
      } else if (isGroupPost) {
        const userPostResponse = await getUserGroupPostRequest(postId);

        setUserPost(userPostResponse.data);
        setIsPostLikedByMe(userPostResponse.isPostLikedByMe);
      } else {
        const userPostResponse = await getPostRequest(postId);

        setUserPost(userPostResponse.data);
        setIsPostLikedByMe(userPostResponse.isPostLikedByMe);
      }
    } catch (err) {
      setError(err);
      notify('error', err, 2000);
    }
  }, [isDedicatedPost, postId, isGroupPost]);

  useEffect(() => {
    getUserPost();
  }, [getUserPost]);

  return (
    <div className={classes['user-post-container']}>
      <div className={classes['user-sidebar-container']}>
        <UserSidebar />
      </div>

      <div>
        <div className={classes['post-container']}>
          {!error && userPost && userPost._id && (
            <Post
              refetchPost={() => getUserPost(postId)}
              isPostLikedByMe={isPostLikedByMe}
              post={userPost}
            />
          )}
        </div>
      </div>
      <div className={classes['messages-sidebar']}>
        <MessagesSidebar />
      </div>
    </div>
  );
}

export default UserPost;
