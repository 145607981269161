import Modal from '../../components/Modal/Modal';
import React from 'react';
import User from '../User/User';
import classes from './FollowingsModal.module.css';

function MyFollowingsModal(props) {
  const { followings, setShowFollowingsModal } = props;

  // const searchHandler = useCallback(
  //   (value) => {
  //     const updatedFilteredFollowings = followings.filter(({ following }) => {
  //       return (
  //         following.firstName.toLowerCase().includes(value) ||
  //         following.lastName.toLowerCase().includes(value) ||
  //         `${following.lastName.toLowerCase()} ${following.firstName.toLowerCase()}`.includes(
  //           value
  //         )
  //       );
  //     });
  //     setFilteredFollowings(updatedFilteredFollowings);
  //   },
  //   [followings]
  // );

  return (
    <Modal
      closeModal={() => setShowFollowingsModal(false)}
      title="Followings"
      closeIcon="true"
      style={{ maxWidth: 600, height: 600 }}
    >
      <div className={classes['followings-container']}>
        {followings.length === 0 ? (
          <p>This user has no followings!</p>
        ) : (
          followings.map(({ following }) => (
            <User
              key={following._id}
              user={following}
              closeModal={() => setShowFollowingsModal(false)}
            />
          ))
        )}
      </div>
    </Modal>
  );
}

export default MyFollowingsModal;
