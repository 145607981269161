import React, { useEffect, useMemo, useState } from 'react';
import classes from './RequestUserServiceModal.module.css';
import {
  createUserServiceRequestSessionRequest,
  getSpecificUserServicePriceRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

import StarIcon from '../../assets/custom-benefits-star-icon.png';
import PersonalizedVideoIcon from '../../assets/personalized-video-icon.png';
import PersonalizedImageIcon from '../../assets/personalized-image-icon.png';
import ChatBenefitIcon from '../../assets/chat-benefit-icon.png';
import VideoCallBenefitIcon from '../../assets/video-call-benefit-icon.png';
import { useQuery } from 'react-query';

function RequestUserServiceModal(props) {
  const { closeModal, service, userProfile } = props;
  const [loadingStripeSession, setLoadingStripeSession] = useState(false);
  const [description, setDescription] = useState('');

  const { isLoading, data, error } = useQuery(
    ['user-service-price', service, userProfile._id],
    () => getSpecificUserServicePriceRequest(userProfile._id, service)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const priceInfo = useMemo(() => {
    if (isLoading || !data?.data)
      return {
        price: 'Fetching best price...',
        isCommunityPrice: false,
      };

    return {
      price: data.data,
      isCommunityPrice: data.isCommunityPrice,
    };
  }, [isLoading, data]);

  const requestUserServiceCheckoutSessionHandler = async () => {
    setLoadingStripeSession(true);

    try {
      const requestBody = {
        service,
        description,
      };
      const { sessionUrl } = await createUserServiceRequestSessionRequest(
        userProfile._id,
        requestBody
      );

      window.open(sessionUrl, '_blank');

      setLoadingStripeSession(false);
      closeModal();
    } catch (err) {
      setLoadingStripeSession(false);

      notify('error', err, 2000);
    }
  };

  const renderServiceIcon = (service) => {
    if (service === 'Personalized Video')
      return (
        <img
          className={classes['service-icon']}
          src={PersonalizedVideoIcon}
          alt="Personalized Video"
        />
      );

    if (service === 'Personalized Image')
      return (
        <img
          className={classes['service-icon']}
          src={PersonalizedImageIcon}
          alt="Personalized Benefit"
        />
      );

    if (service === '1/1 Video Call (15mins)')
      return (
        <img
          className={classes['service-icon']}
          src={VideoCallBenefitIcon}
          alt="Video Call Benefit"
        />
      );

    if (service === '1/1 Chat (15mins)')
      return (
        <img
          className={classes['service-icon']}
          src={ChatBenefitIcon}
          alt="Chat Benefit"
        />
      );

    return (
      <img
        className={classes['service-icon']}
        src={StarIcon}
        alt="Custom Services"
      />
    );
  };

  return (
    <Modal
      closeModal={closeModal}
      title="Request Service"
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={loadingStripeSession}
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModal}
          >
            Close
          </Button>
          <Button
            disabled={loadingStripeSession || isLoading}
            style={{ width: 200 }}
            darkpink="true"
            onClick={requestUserServiceCheckoutSessionHandler}
          >
            Continue Payment
          </Button>
        </div>
      }
    >
      <div className={classes['request-user-service-container']}>
        <div className={classes['service-container']}>
          <div className={classes['service-icon-container']}>
            {renderServiceIcon(service)}
            <p className={classes['service-text']}>{service}</p>
          </div>
        </div>

        <div className={classes['price-container']}>
          {isLoading ? (
            <p className={classes['fetching-best-price-text']}>
              Getting best price...
            </p>
          ) : (
            <>
              <p className={classes['price']}>${priceInfo.price}</p>
              <p className={classes['price-text']}>
                {priceInfo.isCommunityPrice
                  ? 'Community Price'
                  : 'Member Price'}
              </p>
            </>
          )}
        </div>

        <div className={classes['note-container']}>
          <p className={classes['send-note-text']}>
            Send a note with your request
          </p>

          <textarea
            onChange={(e) => setDescription(e.target.value)}
            className={classes['textarea']}
            placeholder="Enter here"
            style={{ width: 400, height: 90 }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default RequestUserServiceModal;
