import React, { useState, useEffect, useContext } from 'react';
import classes from './UploadRequestedNft.module.css';
import moment from 'moment';
import GraduationIcon from '../../../../assets/graduation-1.png';
import PepTalkIcon from '../../../../assets/pep-talk-1.png';
import AdviceIcon from '../../../../assets/advice-1.png';
import BirthdayIcon from '../../../../assets/birthday.png';
import OtherIcon from '../../../../assets/others.png';
import Input from '../../../../components/Input/Input';
import { limitString } from '../../../../helpers/helpers';
import TextArea from '../../../../components/TextArea/TextArea';
import PinkRemoveIcon from '../../../../assets/pink-remove.png';
import { useDropzone } from 'react-dropzone';
import { uploadRequestedNft } from '../../../../httpRequests/httpRequests';
import ipfs from '../../../../ipfs';
import notify from '../../../../utils/notify';
import UploadImageIcon from '../../../../assets/upload-img-icon.png';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Button from '../../../../components/Button/Button';
import { useQueryClient } from 'react-query';
import { IPFS_URL } from '../../../../constants/IPFS';
import FinishedIcon from '../../../../assets/finished.png';
import PendingIcon from '../../../../assets/wall-clock.png';
import RejectedIcon from '../../../../assets/white-remove-icon.png';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { CoinMarketCapContext } from '../../../../context/coinmarketcap-context';

function UploadRequestedNft(props) {
  const queryClient = useQueryClient();
  const { glmsToUsd } = useContext(CoinMarketCapContext);
  const { selectedRequestedNft, setSelectedRequestedNft, fromMe } = props;
  const {
    _id,
    createdAt,
    occasion,
    description: fromDescription,
    nftTitle,
    status,
    uploadedNft,
    nftFee,
    nftType,
  } = selectedRequestedNft;

  const isNftTypeVideo = nftType === 'video';

  const from = fromMe
    ? selectedRequestedNft.requestFor
    : selectedRequestedNft.from;

  const [categories, setCategories] = useState([]);
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const [categoryInputValueTouched, setCategoryInputValueTouched] = useState(
    ''
  );
  const [description, setDescription] = useState('');
  const [descriptionTouched, setDescriptionTouched] = useState(false);
  const [uploadingRequestedNft, setUploadingRequestedNft] = useState(false);

  const [ipfsHash, setIpfsHash] = useState('');
  const [loadingIPFS, setLoadingIPFS] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  useEffect(() => {
    const addCategoryInputValueToCategoriesOnEnterClick = (e) => {
      if (e.key === 'Enter' && categoryInputValue.trim().length > 0) {
        setCategories((prevState) => [...prevState, categoryInputValue]);
        setCategoryInputValue('');
      }
    };

    document.addEventListener(
      'keyup',
      addCategoryInputValueToCategoriesOnEnterClick
    );

    return () =>
      document.removeEventListener(
        'keyup',
        addCategoryInputValueToCategoriesOnEnterClick
      );
  }, [categoryInputValue]);

  const categoryChangeHandler = (e) => {
    setCategoryInputValue(e.target.value.trim());
    setCategoryInputValueTouched(true);
    if (
      e.target.value.indexOf(' ') !== -1 &&
      e.target.value.trim().length >= 1
    ) {
      setCategories((prevState) => [...prevState, categoryInputValue]);
      setCategoryInputValue('');
    }
  };

  const removeCategoryHandler = (index) => {
    setCategories((prevState) => prevState.filter((_, i) => i !== index));
  };

  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value);
    setDescriptionTouched(true);
  };

  const renderOccasionImage = (occasion) => {
    if (occasion === 'Birthday')
      return (
        <img
          className={classes['occasion-image']}
          src={BirthdayIcon}
          alt="Birthday"
        />
      );

    if (occasion === 'Graduation')
      return (
        <img
          className={classes['occasion-image']}
          src={GraduationIcon}
          alt="Graduation"
        />
      );

    if (occasion === 'Pep Talk')
      return (
        <img
          className={classes['occasion-image']}
          src={PepTalkIcon}
          alt="Pep Talk"
        />
      );

    if (occasion === 'Advice')
      return (
        <img
          className={classes['occasion-image']}
          src={AdviceIcon}
          alt="Advice"
        />
      );

    if (occasion === 'Other')
      return (
        <img
          className={classes['occasion-image']}
          src={OtherIcon}
          alt="Other"
        />
      );
  };

  const uploadRequestedNftHandler = async () => {
    setUploadingRequestedNft(true);

    try {
      const requestBody = {
        title: nftTitle,
        ipfs: ipfsHash,
        category: categories[0],
        additionalCategories: categories.slice(1),
        description,
        type: isVideo ? 'video' : 'image',
      };

      await uploadRequestedNft(_id, requestBody);

      queryClient.invalidateQueries(['my-requested-nfts']);
      queryClient.refetchQueries(['my-requested-nfts']);
      setSelectedRequestedNft(undefined);
    } catch (err) {
      notify('error', err, 2000);
    }

    setUploadingRequestedNft(false);
  };

  const addToIpfs = async (content) => {
    const added = await ipfs.add(content);
    const hash = added.cid.toString();

    setIpfsHash(hash);
    setLoadingIPFS(false);
    return hash;
  };

  const onDrop = (files) => {
    setLoadingIPFS(true);

    if (files.length === 1) {
      if (files[0].size > 100000000) {
        notify('error', 'File size is bigger than 100MB', '', 2000);
        return;
      }
      let ext = files[0].name.split('.');
      ext = ext[ext.length - 1];
      ext = ext.toLowerCase();

      if (
        ext !== 'jpg' &&
        ext !== 'JPG' &&
        ext !== 'jpeg' &&
        ext !== 'JPEG' &&
        ext !== 'png' &&
        ext !== 'PNG' &&
        ext !== 'mp4' &&
        ext !== 'MP4'
      ) {
        notify('error', 'Invalid file type', '', 2000);
        return;
      }
      if (ext === 'mp4' || ext === 'MP4') setIsVideo(true);
      else setIsVideo(false);

      const fileReader = new FileReader();
      fileReader.onload = () => setPreviewUrl(fileReader.result);
      fileReader.readAsDataURL(files[0]);

      var reader = new FileReader();
      reader.readAsArrayBuffer(files[0]);
      reader.onloadend = () => {
        addToIpfs(Buffer(reader.result));
      };
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const renderStatusIcon = () => {
    if (status === 'Pending') {
      return (
        <div className={classes['pending-status-container']}>
          <img
            className={classes['pending-icon']}
            src={PendingIcon}
            alt="Pending"
          />
        </div>
      );
    } else if (status === 'Finished') {
      return (
        <div className={classes['finished-status-container']}>
          <img
            className={classes['finished-icon']}
            src={FinishedIcon}
            alt="Finished"
          />
        </div>
      );
    } else if (status === 'Rejected') {
      return (
        <div className={classes['rejected-status-container']}>
          <p
            className={classes['rejected-icon']}
            src={RejectedIcon}
            alt="Rejected"
          >
            X
          </p>
        </div>
      );
    }
  };

  const categoryStyle = {};
  const descriptionStyle = { width: '100%', minHeight: 100 };

  if (
    categoryInputValue.length === 0 &&
    categories.length === 0 &&
    categoryInputValueTouched
  ) {
    categoryStyle['border'] = '1px solid red';
  }

  if (descriptionTouched && !description) {
    descriptionStyle['border'] = '1px solid red';
  }

  const isDeliverButtonDisabled =
    !description ||
    categories.length === 0 ||
    !ipfsHash ||
    uploadingRequestedNft;

  return (
    <div className={classes['upload-requested-nft-container']}>
      {renderStatusIcon()}

      <div className={classes['from-info-container']}>
        <img
          className={classes['user-image']}
          src={from.photo}
          alt={from.fullName}
        />
        <div className={classes['from-fullName-received-on-container']}>
          <h3 className={classes['from-fullName-text']}>
            {fromMe ? 'Request For:' : 'Request From:'} {from.fullName}
          </h3>
          <p className={classes['received-on-text']}>
            Received on: {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>

      <div className={classes['nft-fee-container']}>
        <p className={classes['nft-fee-text']}>Collectible Fee:</p>
        <p className={classes['nft-fee']}>
          {parseFloat(nftFee * glmsToUsd.price).toFixed(2)} USD
        </p>
        {/* <p className={classes['nft-fee']}>{nftFee} GLMS</p> */}
      </div>

      <div className={classes['nft-fee-container']}>
        <p className={classes['nft-fee-text']}>Collectible Type: </p>
        <p className={classes['nft-fee']}>
          {isNftTypeVideo ? 'VIDEO' : 'IMAGE'}
        </p>
      </div>

      <div className={classes['occasion-container']}>
        {renderOccasionImage(occasion)}
        <p className={classes['occasion-text']}>{occasion}</p>
      </div>

      <div className={classes['description-container']}>
        <p className={classes['description-text']}>Message from Requester: </p>
        <p className={classes['description']}>{fromDescription}</p>
      </div>

      {status === 'Pending' && !fromMe && (
        <>
          <div className={classes['deliver-order-container']}>
            <h3>Deliver Order</h3>

            <p className={classes['label']}>Title</p>
            <Input
              disabled
              value={nftTitle}
              placeholder="Enter here"
              style={{ width: '100%' }}
            />

            <p className={classes['label']}>
              Categories (Maximum 3 - Separate via Space/Enter)
            </p>
            <div className={classes['categories-input']}>
              {categories.map((category, i) => (
                <div
                  title={`#${category}`}
                  key={category + i}
                  className={classes['category']}
                >
                  #{limitString(category, 6)}
                  <img
                    alt="Remove"
                    className={classes['remove-icon']}
                    onClick={() => removeCategoryHandler(i)}
                    src={PinkRemoveIcon}
                    width={8}
                    height={9}
                  />
                </div>
              ))}
              <Input
                style={{ width: '100%', ...categoryStyle }}
                placeholder={categories.length === 3 ? '' : '#Enter here'}
                value={categoryInputValue}
                disabled={categories.length === 3}
                onChange={categoryChangeHandler}
              />
            </div>

            <div className={classes['description-container']}>
              <p className={classes['description-text']}>Description</p>
              <TextArea
                placeholder="Enter here"
                style={{
                  ...descriptionStyle,
                  width: '100%',
                  minHeight: 150,
                }}
                value={description}
                onChange={descriptionChangeHandler}
              />
            </div>

            <div className={classes['upload-img-container']}>
              <input
                {...getInputProps()}
                accept={isNftTypeVideo ? '.mp4' : '.jpg,.png,.jpeg'}
              />
              <div>
                {!previewUrl && !loadingIPFS ? (
                  <div
                    {...getRootProps()}
                    className={classes['upload-container']}
                  >
                    <img
                      className={classes['upload-img']}
                      src={UploadImageIcon}
                      alt="Upload"
                      width={35}
                      height={40}
                    />
                    <p className={classes['drag-drop-text']}>
                      Drag and drop <span style={{ marginLeft: 17 }}>or</span>{' '}
                      <span className={classes['select-text']}>select</span>
                    </p>
                  </div>
                ) : loadingIPFS ? (
                  <LoadingSpinner />
                ) : previewUrl && !loadingIPFS ? (
                  <div>
                    {isVideo ? (
                      <video className={classes['nft-video']} controls>
                        <source src={previewUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        className={classes['nft-image']}
                        alt="IPFS-hash"
                        src={previewUrl}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className={classes['deliver-button-container']}>
            <Button
              disabled={isDeliverButtonDisabled}
              style={{ width: 200 }}
              onClick={uploadRequestedNftHandler}
              darkpink="true"
            >
              Deliver
            </Button>
          </div>
        </>
      )}

      {status === 'Finished' && uploadedNft[0] && (
        <div className={classes['content']}>
          <div className={classes['nft-image-container']}>
            {uploadedNft[0].type === 'video' && (
              <ReactPlayer
                width={300}
                height={300}
                url={`${IPFS_URL}/${uploadedNft[0].ipfs}`}
                controls
              />
            )}
            {uploadedNft[0].type === 'image' && (
              <img
                className={classes['nft-image']}
                alt="IPFS Hash"
                src={`${IPFS_URL}/${uploadedNft[0].ipfs}`}
              />
            )}
          </div>

          <div className={classes['uploaded-nft-information-container']}>
            <div className={classes['title-container']}>
              <h3
                title={uploadedNft[0].title}
                className={classes['title-text']}
              >
                {limitString(uploadedNft[0].title, 20)}
              </h3>
            </div>

            <div className={classes['tags']}>
              {uploadedNft[0]?.category?.name && (
                <p className={classes['tag']}>
                  #{limitString(uploadedNft[0].category.name, 9)}
                </p>
              )}

              {uploadedNft[0].additionalCategories &&
                Array.isArray(uploadedNft[0].additionalCategories) &&
                uploadedNft[0].additionalCategories.map((category, i) => (
                  <p className={classes['tag']} key={category + i}>
                    #{limitString(category, 9)}
                  </p>
                ))}
            </div>

            <div className={classes['nft-description-container']}>
              <p className={classes['nft-description']}>
                {uploadedNft[0].description}
              </p>
            </div>

            <div className={classes['price-information-container']}>
              <div className={classes['royalte-fee-container']}>
                <p className={classes['royalte-fee-text']}>Royalte Fee</p>
                <p className={classes['royalte-fee-value']}>
                  {uploadedNft[0].royalteFee} %
                </p>
              </div>
            </div>

            <Link to={`/nfts/${uploadedNft[0]._id}`}>
              <Button style={{ width: 250, margin: 10 }} darkpink="true">
                Visit Collectible
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadRequestedNft;
