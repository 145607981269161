import React from 'react';
import classes from './Modal.module.css';
import GoBack from '../../components/GoBack/GoBack';
import CloseIcon from '../../assets/close-modal-icon.png';

function Modal(props) {
  const overlayClickHandler = (e) => {
    // const element = e.target.getAttribute('id');
    // if (element === 'overlay') props.closeModal();
  };

  if (props.closeIcon) {
    return (
      <div
        id="overlay"
        onClick={overlayClickHandler}
        className={classes['overlay']}
      >
        <>
          <div
            style={props.style ? props.style : {}}
            id="modal"
            className={classes['modal']}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <h3 className={classes['title']}>{props.title}</h3>
              <div
                onClick={props.closeModal}
                className={classes['close-icon-container']}
              >
                <img src={CloseIcon} width={12} alt="Close" />
              </div>
            </div>
            <div className={classes['horizontal-line']}></div>

            <div className={classes['modal-content']}>{props.children}</div>
          </div>
        </>
      </div>
    );
  } else if (props.goBackTitle) {
    const TitleCmp = props.title;
    return (
      <div
        id="overlay"
        onClick={overlayClickHandler}
        className={classes['overlay']}
      >
        <div style={props.style || {}} id="modal" className={classes['modal']}>
          <div className={classes['go-back-title']}>
            <GoBack
              goBackHandler={props.goBackHandler}
              style={{
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
              }}
            />
            <TitleCmp />
          </div>
          <div className={classes['horizontal-line']}></div>

          <div className={classes['modal-content']}>{props.children}</div>
          <div className={classes['modal-footer']}>{props.footer}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        id="overlay"
        onClick={overlayClickHandler}
        className={classes['overlay']}
      >
        <div style={props.style || {}} id="modal" className={classes['modal']}>
          <h3 className={classes['title']}>{props.title}</h3>
          <div className={classes['horizontal-line']}></div>

          <div className={classes['modal-content']}>{props.children}</div>
          <div className={classes['modal-footer']}>{props.footer}</div>
        </div>
      </div>
    );
  }
}

export default Modal;
