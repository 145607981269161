import React from 'react';
import { useQuery } from 'react-query';
import { getGroupAdminsRequest } from '../../../../../../httpRequests/httpRequests';
import classes from './Admins.module.css';
import Member from '../Member/Member';

function Admins(props) {
  const { groupId, isMyRoleAdmin } = props;

  const { data } = useQuery(['group-admins', groupId], () =>
    getGroupAdminsRequest(groupId)
  );

  return (
    <div className={classes['admins-container']}>
      <div className={classes['admins-header']}>
        <h3 className={classes['admins-moderators']}>Admins & Moderators</h3>
        <p className={classes['circle']}></p>
        {data && data.data ? (
          <p className={classes['number-of-admins']}>{data.data.length}</p>
        ) : (
          <p className={classes['number-of-admins']}>--</p>
        )}
      </div>

      <div className={classes['members-list']}>
        {data &&
          data.data &&
          data.data.map(({ role, user }) => (
            <Member
              groupId={groupId}
              isMyRoleAdmin={isMyRoleAdmin}
              key={user._id}
              user={user}
              isGroupAdmin={role === 'admin'}
            />
          ))}
      </div>
    </div>
  );
}

export default Admins;
