import React, { useEffect, useState } from 'react';
import classes from './StripeAccountAccess.module.css';
import { Col, Row } from 'antd';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { limitString } from '../../../../helpers/helpers';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import {
  getAllUsersRequest,
  giveUserAccessToCreateStripeAccountRequest,
  removeUserAccessToCreateStripeAccountRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import UsersWhoHaveAccessToCreateStripeAccount from './components/UsersWhoHaveAccessToCreateStripeAccount/UsersWhoHaveAccessToCreateStripeAccount';

const STRIPE_ACCOUNT_ACCESS_SECTIONS = {
  ALL: 'ALL',
  ACCESS_ACCOUNTS: 'ACCESS_ACCOUNTS',
};

const RESULTS_PER_PAGE = 20;

function StripeAccountAccess() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [selectedSection, setSelectedSection] = useState(
    STRIPE_ACCOUNT_ACCESS_SECTIONS['ALL']
  );

  const [searchUserInputValue, setSearchUserInputValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['all-users', searchUserInputValue],
    ({ pageParam = 1 }) =>
      getAllUsersRequest(pageParam, RESULTS_PER_PAGE, searchUserInputValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const invalidateUsersHandler = () =>
    Promise.all([
      queryClient.invalidateQueries(['all-users', searchUserInputValue]),
      queryClient.refetchQueries(['all-users', searchUserInputValue]),
    ]);

  const giveUserAccessToCreateStripeAccountHandler = async (userId) => {
    try {
      await giveUserAccessToCreateStripeAccountRequest(userId);

      invalidateUsersHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const removeUserAccessToCreateStripeAccountHandler = async (userId) => {
    try {
      await removeUserAccessToCreateStripeAccountRequest(userId);

      invalidateUsersHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['sections-container']}>
        {Object.values(STRIPE_ACCOUNT_ACCESS_SECTIONS).map((section) => {
          return (
            <p
              key={section}
              onClick={() => setSelectedSection(section)}
              className={`${classes['section']} ${
                selectedSection === section && classes['selected-section']
              }`}
            >
              {section}
            </p>
          );
        })}
      </div>

      {selectedSection === STRIPE_ACCOUNT_ACCESS_SECTIONS.ACCESS_ACCOUNTS && (
        <UsersWhoHaveAccessToCreateStripeAccount />
      )}

      {selectedSection === STRIPE_ACCOUNT_ACCESS_SECTIONS.ALL && (
        <div className={classes['all-users-container']}>
          <div className={classes['heading-container']}>
            <h2 className={classes['all-users-heading']}>
              ALL USERS = {data?.pages[0].results}
            </h2>

            <div>
              <input
                placeholder="Search For Users (Full Name / Email)"
                style={{ width: 300 }}
                className="searchInput"
                value={searchUserInputValue}
                onChange={(e) => {
                  setSearchUserInputValue(e.target.value);
                }}
              />
            </div>
          </div>

          {selectedSection === STRIPE_ACCOUNT_ACCESS_SECTIONS.ALL && (
            <div
              style={{ minHeight: '85vh' }}
              id="table-nft"
              className={classes['likes-container']}
            >
              <Row justify="space-between">
                <Col md={3} className={classes['pagination-col']}>
                  Photo
                </Col>
                <Col md={3} className={classes['pagination-col']}>
                  Full Name
                </Col>

                <Col md={3} className={classes['pagination-col']}>
                  Email
                </Col>

                <Col md={5} className={classes['pagination-col']}>
                  Publish NFT / Collection Content
                </Col>
              </Row>

              {!isLoading &&
                data &&
                data.pages.map((page) => {
                  return page.data.map((user) => {
                    return (
                      <div key={user._id}>
                        <hr />
                        <Row
                          justify="space-between"
                          style={{ padding: '1em 0' }}
                        >
                          <Col
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              history.push(`/user-stats/${user._id}`)
                            }
                            md={3}
                          >
                            <img
                              width={25}
                              height={25}
                              style={{ borderRadius: 100, objectFit: 'cover' }}
                              alt="User"
                              src={user.photo}
                            />
                          </Col>
                          <Col
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              history.push(`/user-stats/${user._id}`)
                            }
                            md={3}
                          >
                            {user.firstName} {user.lastName}
                          </Col>
                          <Col
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              history.push(`/user-stats/${user._id}`)
                            }
                            md={3}
                          >
                            {limitString(user.email, 30)}
                          </Col>

                          <Col md={5}>
                            {user.accessToCreateStripeAccount ? (
                              <button
                                onClick={() =>
                                  removeUserAccessToCreateStripeAccountHandler(
                                    user._id
                                  )
                                }
                                className="cancel-button"
                              >
                                Remove Access
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  giveUserAccessToCreateStripeAccountHandler(
                                    user._id
                                  )
                                }
                                className="btn-white-glimpse"
                              >
                                Give Access
                              </button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    );
                  });
                })}

              {(isLoading || isFetching) && <LoadingSpinner />}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default StripeAccountAccess;
