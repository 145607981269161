import React from 'react';
import classes from './BenefitsModal.module.css';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Modal from '../../../../components/Modal/Modal';

function BenefitsModal(props) {
  const {
    setShowBenefitsModal,
    setIncludeBenefits,
    benefits,
    setBenefits,
    POPULAR_BENEFITS,
  } = props;

  const closeModalHandler = () => {
    setShowBenefitsModal(false);
  };

  const benefitChangeHandler = (index, value) => {
    const updatedBenefits = [...benefits];

    updatedBenefits[index] = {
      value,
      isValid: value.length > 0,
      touched: true,
    };

    setBenefits(updatedBenefits);
  };

  const addAnotherBenefitHandler = () => {
    setBenefits((prevState) => [
      ...prevState,
      {
        value: '',
        isValid: false,
        touched: false,
      },
    ]);
  };

  const saveBenefitsHandler = () => {
    setIncludeBenefits(true);
    closeModalHandler();
  };

  const isAddAnotherOptionDisabled = () =>
    benefits.some((benefit) => !benefit.isValid || !benefit.touched);

  return (
    <Modal
      style={{ height: 500 }}
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={isAddAnotherOptionDisabled()}
            darkpink="true"
            onClick={saveBenefitsHandler}
          >
            Save
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Benefits For Holders"
    >
      <div className={classes['benefits-container']}>
        {benefits.map((benefit, i) => {
          if (POPULAR_BENEFITS.includes(benefit.value)) return null;

          const inputStyle = { width: '100%' };
          if (benefit.isValid === false && benefit.touched) {
            inputStyle['border'] = '1px solid red';
          }

          return (
            <div key={i}>
              <p className={classes['label']}>Custom Benefit</p>
              <Input
                style={inputStyle}
                placeholder="Enter here"
                value={benefit.value}
                onChange={(e) => benefitChangeHandler(i, e.target.value)}
              />
            </div>
          );
        })}
      </div>
      <Button
        onClick={addAnotherBenefitHandler}
        disabled={isAddAnotherOptionDisabled()}
        style={{ margin: '10px 0', width: '100%' }}
        darkpink="true"
      >
        + Add Another Benefit
      </Button>
    </Modal>
  );
}

export default BenefitsModal;
