import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getFriendSuggestionsRequest } from '../../httpRequests/httpRequests';
import classes from './DiscoverFriends.module.css';
import ArrowRight from '../../assets/white-arrow-right.png';
import ArrowLeft from '../../assets/white-arrow-left.png';
import UserSuggestionCard from './components/UserSuggestionCard/UserSuggestionCard';
import notify from '../../utils/notify';
import NoSuggestionsIcon from '../../assets/no-suggestions.png';
import MetaTags from 'react-meta-tags';

const zIndexes = [1, 2, 5, 2, 1];
const heights = [350, 370, 400, 370, 350];
const margins = [-100, -100, 0, -100, -100];

function DiscoverUsers() {
  const { isLoading, data, error } = useQuery(
    ['friend-suggestions'],
    getFriendSuggestionsRequest,
    { refetchOnWindowFocus: false }
  );

  const [userSuggestions, setUserSuggestions] = useState([]);
  // const [recentlyRegistered, setRecentlyRegistered] = useState([]);
  // const [userSuggestionsLoading, setUserSuggestionsLoading] = useState(true);

  useEffect(() => {
    if (!data?.data) return;
    // setUserSuggestionsLoading(true);
    // setRecentlyRegistered(data.recentlyRegisteredOrVerifiedUsers);
    setUserSuggestions(data.data.slice(0, 5));
    // setUserSuggestionsLoading(false);
  }, [data]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const goLeftHandler = () => {
    const updatedUserSuggestions = [];
    for (let i = 0; i < userSuggestions.length; i++) {
      if (i === userSuggestions.length - 1) {
        updatedUserSuggestions[userSuggestions.length - 1] = userSuggestions[0];
      } else updatedUserSuggestions[i] = userSuggestions[i + 1];
    }

    setUserSuggestions(updatedUserSuggestions);
  };

  const goRightHandler = () => {
    const updatedUserSuggestions = [];
    for (let i = userSuggestions.length - 1; i >= 0; i--) {
      if (i === 0) {
        updatedUserSuggestions[0] = userSuggestions[userSuggestions.length - 1];
      } else updatedUserSuggestions[i] = userSuggestions[i - 1];
    }
    setUserSuggestions(updatedUserSuggestions);
  };

  if (isLoading) return null;

  return (
    <>
      <MetaTags>
        <title>Discover Friends | Glimpse</title>
        <meta property="og:title" content={'Discover Friends | Glimpse'} />
      </MetaTags>

      <div className={classes['discover-friends-container']}>
        {!isLoading && data?.data && data.data.length === 0 && (
          <div className={classes['no-suggestions-page']}>
            <img
              alt="No Suggested Friends"
              className={classes['no-suggestions-icon']}
              src={NoSuggestionsIcon}
            />
            <p className={classes['no-suggested-text']}>No Suggested Friends</p>
          </div>
        )}

        {userSuggestions.length >= 5 && (
          <div className={classes['discover-friends']}>
            <div className={classes['people-you-might-know']}>
              <h2 className={classes['people-text']}>People you might know</h2>

              <div className={classes['suggestions-arrows']}>
                <div
                  onClick={goLeftHandler}
                  className={classes['go-left-container']}
                >
                  <img
                    alt="Left"
                    className={classes['go-left-icon']}
                    src={ArrowLeft}
                  />
                </div>
                <div className={classes['user-suggestions-container']}>
                  {userSuggestions.map((user, i) => {
                    return (
                      <UserSuggestionCard
                        style={{
                          zIndex: zIndexes[i],
                          height: heights[i],
                          marginLeft: i < 2 ? 0 : margins[i],
                          marginRight: i < 2 ? margins[i] : 0,
                        }}
                        key={user._id}
                        user={user}
                      />
                    );
                  })}
                </div>
                <div
                  onClick={goRightHandler}
                  className={classes['go-right-container']}
                >
                  <img
                    className={classes['go-right-icon']}
                    src={ArrowRight}
                    alt="Right"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {data?.data && data.data.length > 0 && (
          <div className={classes['recently-registered']}>
            <h2 className={classes['recently-registered-text']}>
              Suggested for you
            </h2>
            <div className={classes['recently-registered-container']}>
              {data?.data &&
                data.data.map((user) => (
                  <UserSuggestionCard key={user._id} user={user} />
                ))}
              {data?.data && data.data.length === 0 && (
                <p>No Suggestions found</p>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DiscoverUsers;
