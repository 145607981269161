import React from 'react';
import classes from './RequestedServiceForMe.module.css';
import moment from 'moment';
import Button from '../../../../components/Button/Button';

import StarIcon from '../../../../assets/custom-benefits-star-icon.png';
import PersonalizedVideoIcon from '../../../../assets/personalized-video-icon.png';
import PersonalizedImageIcon from '../../../../assets/personalized-image-icon.png';
import ChatBenefitIcon from '../../../../assets/chat-benefit-icon.png';
import VideoCallBenefitIcon from '../../../../assets/video-call-benefit-icon.png';

import FinishedIcon from '../../../../assets/finished.png';
import PendingIcon from '../../../../assets/wall-clock.png';
import RejectedIcon from '../../../../assets/white-remove-icon.png';
import { Link } from 'react-router-dom';

function RequestedServiceForMe(props) {
  const { requestedService } = props;
  const { from, service, status, createdAt, price } = requestedService;

  const renderServiceIcon = (service) => {
    if (service === 'Personalized Video')
      return (
        <img
          className={classes['service-icon']}
          src={PersonalizedVideoIcon}
          alt="Personalized Video"
        />
      );

    if (service === 'Personalized Image')
      return (
        <img
          className={classes['service-icon']}
          src={PersonalizedImageIcon}
          alt="Personalized Benefit"
        />
      );

    if (service === '1/1 Video Call (15mins)')
      return (
        <img
          className={classes['service-icon']}
          src={VideoCallBenefitIcon}
          alt="Video Call Benefit"
        />
      );

    if (service === '1/1 Chat (15mins)')
      return (
        <img
          className={classes['service-icon']}
          src={ChatBenefitIcon}
          alt="Chat Benefit"
        />
      );

    return (
      <img
        className={classes['service-icon']}
        src={StarIcon}
        alt="Custom Services"
        style={{ width: 16, height: 16, marginBottom: 1.5 }}
      />
    );
  };

  const renderStatusIcon = () => {
    if (status === 'Pending') {
      return (
        <div className={classes['pending-status-container']}>
          <img
            className={classes['pending-icon']}
            src={PendingIcon}
            alt="Pending"
          />
        </div>
      );
    } else if (status === 'Finished') {
      return (
        <div className={classes['finished-status-container']}>
          <img
            className={classes['finished-icon']}
            src={FinishedIcon}
            alt="Finished"
          />
        </div>
      );
    } else if (status === 'Rejected') {
      return (
        <div className={classes['rejected-status-container']}>
          <p
            className={classes['rejected-icon']}
            src={RejectedIcon}
            alt="Rejected"
          >
            X
          </p>
        </div>
      );
    }
  };

  return (
    <div className={classes['requested-service-container']}>
      <div className={classes['photo-fullName-container']}>
        <Link to={`/user-feed/${from._id}`}>
          <img
            src={from.photo}
            alt={from.fullName}
            className={classes['from-photo']}
          />
        </Link>
        <div>
          <p className={classes['from-fullName']}>{from.fullName}</p>
          <p className={classes['created-at']}>
            Received: {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>

      {renderStatusIcon()}

      <div className={classes['service-container']}>
        <div className={classes['service-icon-container']}>
          {renderServiceIcon(service)}
          <p className={classes['service-text']}>{service}</p>
        </div>
        <p className={classes['price']}>${price}</p>
      </div>

      <div className={classes['horizontal-line']}></div>

      <div className={classes['actions-container']}>
        <Button style={{ width: 170 }} disabled darkpink="true">
          Reject
        </Button>
        <Link to={`/requested-services/${requestedService._id}`}>
          <Button
            disabled={status === 'Finished'}
            style={{ width: 170 }}
            darkpink="true"
          >
            {status === 'Finished' ? 'Completed' : 'Complete'}
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default RequestedServiceForMe;
