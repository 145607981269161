import React, { useContext, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { AuthContext } from '../../../../../../context/auth-context';
import {
  markSpecificUserAsGroupAdminRequest,
  removeGroupAdminRequest,
  removeGroupMemberRequest,
  requestFollowingRequest,
  unfollowUserRequest,
} from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import classes from './Member.module.css';
import Button from '../../../../../../components/Button/Button';
import VerifiedIcon from '../../../../../../assets/verified.png';

function Member(props) {
  const history = useHistory();
  const { user, isGroupAdmin, isMyRoleAdmin, groupId } = props;
  const userId = user._id;
  const queryClient = useQueryClient();
  const { profile: myProfile, getMyFollowings, myFollowingsIds } = useContext(
    AuthContext
  );

  const invalidateNumberOfMyFollowersAndFollowings = useCallback(() => {
    queryClient.invalidateQueries(['my-number-of-followers-and-followings']);
    queryClient.refetchQueries(['my-number-of-followers-and-followings']);
  }, [queryClient]);

  const unfollowUserHandler = useCallback(async () => {
    try {
      await unfollowUserRequest(userId);
      await getMyFollowings();
      queryClient.invalidateQueries(['followers-followings', userId]);
      queryClient.refetchQueries(['followers-followings', userId]);
      invalidateNumberOfMyFollowersAndFollowings();
    } catch (error) {
      notify('error', error, 2000);
    }
  }, [
    userId,
    queryClient,
    getMyFollowings,
    invalidateNumberOfMyFollowersAndFollowings,
  ]);

  const requestFollowHandler = useCallback(async () => {
    try {
      await requestFollowingRequest(userId);
      await getMyFollowings();
      queryClient.invalidateQueries(['followers-followings', userId]);
      queryClient.refetchQueries(['followers-followings', userId]);
      invalidateNumberOfMyFollowersAndFollowings();
    } catch (error) {
      notify('error', error, 2000);
    }
  }, [
    userId,
    queryClient,
    getMyFollowings,
    invalidateNumberOfMyFollowersAndFollowings,
  ]);

  const renderButtonAccordingToFollowRelation = () => {
    let followState = {
      btnText: 'Follow',
      actionHandler: requestFollowHandler,
    };

    const isPartOfMyFollowings = !!myFollowingsIds[userId];

    if (isPartOfMyFollowings) {
      followState = {
        btnText: 'Following',
        actionHandler: unfollowUserHandler,
      };
    } else if (!isPartOfMyFollowings) {
      followState = {
        btnText: 'Follow',
        actionHandler: requestFollowHandler,
      };
    }

    if (myProfile?._id === userId) {
      followState = {
        btnText: 'You',
        actionHandler() {},
        disabled: true,
      };
    }

    if (followState.btnText === 'You') {
      return (
        <Button
          style={{
            width: 120,
            padding: '.5rem 1rem',
            borderRadius: 13,
            background: '#F8F8F8',
            color: '#999999',
            fontWeight: 500,
          }}
          pinkcolor="true"
          disabled={true}
        >
          {followState.btnText}
        </Button>
      );
    }

    if (followState.btnText === 'Follow') {
      return (
        <Button
          style={{
            width: 120,
            padding: '.5rem 1rem',
            background: '#F900B7',
            borderRadius: 13,
            fontWeight: 500,
          }}
          pink="true"
          disabled={followState.disabled}
          onClick={followState.actionHandler}
        >
          {followState.btnText}
        </Button>
      );
    } else {
      return (
        <Button
          opacitypink="true"
          style={{ width: 120, padding: '.5rem 1rem', borderRadius: 13 }}
          disabled={followState.disabled}
          onClick={followState.actionHandler}
        >
          {followState.btnText}
        </Button>
      );
    }
  };

  const navigateToCurrentUser = () => {
    const navigateTo =
      myProfile?._id === userId ? '/my-space' : `/user-feed/${userId}`;
    history.push(navigateTo);
  };

  const markUserAsGroupAdminHandler = async () => {
    try {
      await markSpecificUserAsGroupAdminRequest(groupId, userId);
      await Promise.all([
        queryClient.invalidateQueries(['group-admins', groupId]),
        queryClient.refetchQueries(['group-admins', groupId]),
      ]);

      queryClient.setQueryData(
        ['group-members-except-admins', groupId, ''],
        (data) => {
          return {
            ...data,
            pages: data.pages.map((page) => {
              const updatedData = page.data.filter(
                ({ user }) => user._id !== userId
              );
              return { ...page, data: updatedData };
            }),
          };
        }
      );
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const removeGroupAdminHandler = async () => {
    try {
      const { data: updatedGroupMember } = await removeGroupAdminRequest(
        groupId,
        userId
      );
      await Promise.all([
        queryClient.invalidateQueries(['group-admins', groupId]),
        queryClient.refetchQueries(['group-admins', groupId]),
      ]);

      queryClient.setQueryData(
        ['group-members-except-admins', groupId, ''],
        (data) => {
          return {
            ...data,
            pages: data.pages.map((page, i) => {
              let updatedData = page.data;
              if (i === 0) {
                updatedData = [
                  {
                    user: updatedGroupMember.user,
                    role: updatedGroupMember.role,
                  },
                  ...page.data,
                ];
              }

              return { ...page, data: updatedData };
            }),
          };
        }
      );
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const removeGroupMemberHandler = async () => {
    try {
      await removeGroupMemberRequest(groupId, userId);
      queryClient.setQueryData(
        ['group-members-except-admins', groupId, ''],
        (data) => {
          return {
            ...data,
            pages: data.pages.map((page) => {
              const updatedData = page.data.filter(
                ({ user }) => user._id !== userId
              );
              return { ...page, data: updatedData };
            }),
          };
        }
      );
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div className={classes['user-container']}>
      <img
        onClick={navigateToCurrentUser}
        src={user.photo}
        className={classes['user-image']}
        alt="UserPhoto"
      />

      <p onClick={navigateToCurrentUser} className={classes['user-text']}>
        {user.fullName ? user.fullName : `${user.firstName} ${user.lastName}`}
        {user.verified && (
          <img
            alt="Verified"
            src={VerifiedIcon}
            style={{ height: 17, marginLeft: 6, marginBottom: 2 }}
          />
        )}
      </p>

      {isGroupAdmin && (
        <div className={classes['admin-container']}>
          <p className={classes['admin-text']}>Admin</p>
        </div>
      )}

      <div className={classes['follow-state-container']}>
        {isMyRoleAdmin && !isGroupAdmin && (
          <Button
            onClick={markUserAsGroupAdminHandler}
            style={{ width: '150px', padding: '0.5rem 1rem' }}
            pinkcolor="true"
          >
            Add Admin
          </Button>
        )}

        {isMyRoleAdmin && isGroupAdmin && (
          <Button
            onClick={removeGroupAdminHandler}
            style={{ width: '150px', padding: '0.5rem 1rem' }}
            pinkcolor="true"
          >
            Remove Admin
          </Button>
        )}

        {isMyRoleAdmin && !isGroupAdmin && (
          <Button
            onClick={removeGroupMemberHandler}
            style={{ width: '150px', padding: '0.5rem 1rem' }}
            pinkcolor="true"
          >
            Remove
          </Button>
        )}
        {renderButtonAccordingToFollowRelation()}
      </div>
    </div>
  );
}

export default Member;
