import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context/auth-context';
import classes from './AboutMe.module.css';
import TelegramIcon from '../../../../assets/telegram.png';
import TwitterIcon from '../../../../assets/twitter.png';
import LinkedinIcon from '../../../../assets/linkedin-1.png';
import YoutubeIcon from '../../../../assets/youtube.png';
import InstagramIcon from '../../../../assets/instagram.png';
import LinktreeIcon from '../../../../assets/linktree.png';
import ImdbIcon from '../../../../assets/imdb.png';
import LinkIcon from '../../../../assets/link-pink.png';
import EditSocialLinksModal from '../EditSocialLinksModal/EditSocialLinksModal';
// import Education from '../Education/Education';
// import About from '../About/About';
import MyRecentlyAddedNfts from '../MyRecentlyAddedNfts/MyRecentlyAddedNfts';
import { getMyUploadedPhotosRequest } from '../../../../httpRequests/httpRequests';
import { useQuery } from 'react-query';
import notify from '../../../../utils/notify';
import CreatorServices from '../CreatorServices/CreatorServices';
import Introduction from '../Introduction/Introduction';

function AboutMe(props) {
  const { profile } = useContext(AuthContext);
  const [showEditSocialLinksModal, setShowEditSocialLinksModal] = useState(
    false
  );
  const { data, error } = useQuery('my-photos', getMyUploadedPhotosRequest);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const {
    openMyFollowersModalHander,
    openMyFollowingsModalHander,
    myNumberOfFollowers,
    myNumberOfFollowings,
    showListingsSectionsHandler,
    seeAllPhotosHandler,
  } = props;

  const openEditSocialLinksModalHandler = () => {
    setShowEditSocialLinksModal(true);
  };

  const renderSocialLinks = () => {
    if (!profile.socialLinks)
      return (
        <div className={classes['no-socials-container']}>
          <p className={classes['no-socials-found']}>No Socials Found!</p>
          <div
            onClick={openEditSocialLinksModalHandler}
            style={{ cursor: 'pointer' }}
            className={classes['social-container']}
          >
            <img
              src={LinkIcon}
              alt="Edit"
              width={20}
              className={classes['social-link']}
            />
          </div>
        </div>
      );
    return (
      <div className={classes['social-links-container']}>
        {profile.socialLinks?.telegram && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                profile.socialLinks.telegram.startsWith('https://')
                  ? profile.socialLinks.telegram
                  : `https://${profile.socialLinks.telegram}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={TelegramIcon}
                alt="Telegram"
              />
            </a>
          </div>
        )}
        {profile.socialLinks?.twitter && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                profile.socialLinks.twitter.startsWith('https://')
                  ? profile.socialLinks.twitter
                  : `https://${profile.socialLinks.twitter}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={TwitterIcon}
                alt="Twitter"
              />
            </a>
          </div>
        )}

        {profile.socialLinks?.linkedin && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                profile.socialLinks.linkedin.startsWith('https://')
                  ? profile.socialLinks.linkedin
                  : `https://${profile.socialLinks.linkedin}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={LinkedinIcon}
                alt="Linkedin"
              />
            </a>
          </div>
        )}

        {profile.socialLinks?.youtube && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                profile.socialLinks.youtube.startsWith('https://')
                  ? profile.socialLinks.youtube
                  : `https://${profile.socialLinks.youtube}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={YoutubeIcon}
                alt="Youtube"
              />
            </a>
          </div>
        )}

        {profile.socialLinks?.instagram && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                profile.socialLinks.instagram.startsWith('https://')
                  ? profile.socialLinks.instagram
                  : `https://${profile.socialLinks.instagram}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={InstagramIcon}
                alt="Instagram"
              />
            </a>
          </div>
        )}

        {profile.socialLinks?.linktree && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                profile.socialLinks.linktree.startsWith('https://')
                  ? profile.socialLinks.linktree
                  : `https://${profile.socialLinks.linktree}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={LinktreeIcon}
                alt="Linktree"
              />
            </a>
          </div>
        )}

        {profile.socialLinks?.imdb && (
          <div className={classes['social-container']}>
            <a
              rel="noreferrer"
              href={
                profile.socialLinks.imdb.startsWith('https://')
                  ? profile.socialLinks.imdb
                  : `https://${profile.socialLinks.imdb}`
              }
              target="_blank"
            >
              <img
                className={classes['social-link']}
                src={ImdbIcon}
                alt="IMDB"
                style={{ width: 25, height: 20 }}
              />
            </a>
          </div>
        )}
      </div>
    );
  };

  const renderPhotos = () => {
    return (
      <div className={classes['photos-container']}>
        <div className={classes['photos-see-all']}>
          <h3 className={classes['photos-text']}>Media</h3>
          <div
            onClick={seeAllPhotosHandler}
            className={classes['see-all-container']}
          >
            <p className={classes['see-all-text']}>See All</p>
          </div>
        </div>

        {data.data.length === 0 && (
          <p className={classes['no-photos-found']}>No Photos Found</p>
        )}
        <div className={classes['photos-grid-container']}>
          {data.data.slice(0, 6).map(({ photo }, i) => {
            let style = {};
            if (i === 4) {
              style = {
                borderBottomLeftRadius: 10,
              };
            } else if (i === 5) {
              style = {
                borderBottomRightRadius: 10,
              };
            }

            return (
              <img
                key={i}
                style={style}
                className={classes['photo']}
                src={photo}
                alt="SpecificPhoto"
              />
            );
          })}
        </div>
      </div>
    );
  };

  const pointerStyle = { cursor: 'pointer' };

  return (
    <div className={classes['about-me-container']}>
      {showEditSocialLinksModal && (
        <EditSocialLinksModal
          setShowEditSocialLinksModal={setShowEditSocialLinksModal}
        />
      )}
      <div
        style={{
          // top: profile?.publishNftsOrCollectionContent ? '-158%' : '-125%',
          top: profile?.publishNftsOrCollectionContent ? '-145%' : '-118%',
        }}
        className={classes['user-information-container']}
      >
        <div className={classes['followers-socials-about-container']}>
          <div className={classes['info-container']}>
            <h3>Info</h3>

            <p
              onClick={openEditSocialLinksModalHandler}
              className={classes['edit-text']}
            >
              Edit
            </p>
          </div>
          <div
            style={{ margin: '0px 0px 10px 0' }}
            className={classes['horizontal-line']}
          ></div>

          <div className={classes['number-followers-followings-container']}>
            <div style={pointerStyle} onClick={openMyFollowersModalHander}>
              <p className={classes['number-followers-followings-text']}>
                {myNumberOfFollowers}
              </p>
              <p className={classes['followers-text']}>Followers</p>
            </div>
            <div className={classes['vertical-line']}></div>
            <div
              style={pointerStyle}
              onClick={openMyFollowingsModalHander}
              className={classes['number-followings-container']}
            >
              <p className={classes['number-followers-followings-text']}>
                {myNumberOfFollowings}
              </p>
              <p className={classes['followings-text']}>Following</p>
            </div>
          </div>

          <div style={{ margin: '20px 0' }}></div>
          {renderSocialLinks()}

          <div className={classes['horizontal-line']}></div>

          <div>
            <Introduction />
            {/* {!profile.verifiedAsInstitution ? (
              <>
                <About />
                <Education />
              </>
            ) : (
              <div style={{ height: 370 }}></div>
            )} */}
          </div>
        </div>

        {profile?.publishNftsOrCollectionContent &&
          profile?.accessToCreateStripeAccount && <CreatorServices />}

        <MyRecentlyAddedNfts seeAllHandler={showListingsSectionsHandler} />
        {data?.data && renderPhotos()}
      </div>
    </div>
  );
}

export default AboutMe;
