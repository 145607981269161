import React, { useState } from 'react';
import classes from './ApplyAsCreator.module.css';
import GlimpseLogo from '../../assets/glimpse-logo.png';
import ContentCreatorImage from '../../assets/apply-as-creator-icon.png';
import Input from '../../components/Input/Input';
import TextArea from '../../components/TextArea/TextArea';
import Button from '../../components/Button/Button';
import { Link } from 'react-router-dom';
import { isInputValidationCompleted, isValidUrl } from '../../helpers/helpers';
import notify from '../../utils/notify';
import { applyAsCreatorRequest } from '../../httpRequests/httpRequests';

const getApplyFormInitialState = () => ({
  email: {
    value: '',
    valid: false,
    touched: false,
    validationRequirements: {
      isEmail: true,
    },
  },
  firstName: {
    value: '',
    valid: false,
    touched: false,
    validationRequirements: {
      required: true,
    },
  },
  lastName: {
    value: '',
    valid: false,
    touched: false,
    validationRequirements: {
      required: true,
    },
  },
  mainSocialChannelUrl: {
    value: '',
    valid: false,
    touched: false,
    validationRequirements: {
      required: true,
    },
  },
  numberOfFollowers: {
    value: '',
    valid: false,
    touched: false,
    validationRequirements: {
      required: true,
    },
  },
  description: {
    value: '',
    valid: false,
    touched: false,
    validationRequirements: {
      minLength: 20,
    },
  },
});

function ApplyAsCreator() {
  const [applyFormState, setApplyFormState] = useState(() =>
    getApplyFormInitialState()
  );
  const [sendingApply, setSendingApply] = useState(false);

  const inputChangeHandler = (key, value) => {
    const updatedApplyFormState = { ...applyFormState };
    updatedApplyFormState[key].value = value;
    updatedApplyFormState[key].touched = true;

    updatedApplyFormState[key].valid = isInputValidationCompleted(
      value,
      updatedApplyFormState[key].validationRequirements
    );

    if (key === 'mainSocialChannelUrl') {
      updatedApplyFormState[key].valid =
        updatedApplyFormState[key].valid && isValidUrl(value);
    }

    if (key === 'numberOfFollowers') {
      updatedApplyFormState[key].valid =
        updatedApplyFormState[key].valid && !isNaN(value) && value >= 0;
    }

    setApplyFormState(updatedApplyFormState);
  };

  const applyAsCreatorHandler = async () => {
    setSendingApply(true);

    try {
      const requestBody = {
        email: applyFormState.email.value,
        firstName: applyFormState.firstName.value,
        lastName: applyFormState.lastName.value,
        mainSocialChannelUrl: applyFormState.mainSocialChannelUrl.value,
        numberOfFollowersInMainSocialChannel:
          applyFormState.numberOfFollowers.value,
        description: applyFormState.description.value,
      };
      await applyAsCreatorRequest(requestBody);

      notify(
        'success',
        `Thanks for submitting your application to launch your Glimpse community! A member of our team will be in touch with next steps!`,
        3000
      );

      setApplyFormState(getApplyFormInitialState());
    } catch (err) {
      notify('error', err, 2000);
    }

    setSendingApply(false);
  };

  const emailInputStyle = { width: '100%' };
  if (applyFormState.email.touched && !applyFormState.email.valid) {
    emailInputStyle['border'] = '1px solid red';
  }
  const firstNameInputStyle = { width: '100%' };
  if (applyFormState.firstName.touched && !applyFormState.firstName.valid) {
    firstNameInputStyle['border'] = '1px solid red';
  }
  const lastNameInputStyle = { width: '100%' };
  if (applyFormState.lastName.touched && !applyFormState.lastName.valid) {
    lastNameInputStyle['border'] = '1px solid red';
  }
  const mainSocialChannelUrlInputStyle = { width: '100%' };
  if (
    applyFormState.mainSocialChannelUrl.touched &&
    !applyFormState.mainSocialChannelUrl.valid
  ) {
    mainSocialChannelUrlInputStyle['border'] = '1px solid red';
  }
  const numberOfFollowersInputStyle = { width: '100%' };
  if (
    applyFormState.numberOfFollowers.touched &&
    !applyFormState.numberOfFollowers.valid
  ) {
    numberOfFollowersInputStyle['border'] = '1px solid red';
  }
  const descriptionInputStyle = { width: '100%', minHeight: 150 };
  if (applyFormState.description.touched && !applyFormState.description.valid) {
    descriptionInputStyle['border'] = '1px solid red';
  }

  const isApplyButtonDisabled = !(
    applyFormState.email.valid &&
    applyFormState.firstName.valid &&
    applyFormState.lastName.valid &&
    applyFormState.mainSocialChannelUrl.valid &&
    applyFormState.numberOfFollowers.valid &&
    applyFormState.description.valid
  );

  return (
    <div className={classes['apply-as-creator-container']}>
      <Link to={'/'}>
        <img
          src={GlimpseLogo}
          alt="Glimpse"
          className={classes['glimpse-logo']}
        />
      </Link>

      <div className={classes['apply-container']}>
        <div className={classes['apply-as-a-creator-image-container']}>
          <img
            alt="Content Creator"
            src={ContentCreatorImage}
            className={classes['content-creator-image']}
          />
          <h2 className={classes['apply-as-a-creator-text']}>
            Apply As A Creator
          </h2>
          <p className={classes['join-community-text']}>
            Join our growing community of creators from all walks of life and
            all forms of creative expression - from video creation sports,
            cooking, activisim, music, and more.
          </p>
        </div>

        <div className={classes['apply-form-container']}>
          <p className={classes['label']}>Email</p>
          <Input
            onChange={(e) => inputChangeHandler('email', e.target.value)}
            style={emailInputStyle}
            placeholder="Enter here"
            value={applyFormState.email.value}
          />

          <div className={classes['first-last-name-container']}>
            <div className={classes['first-name-container']}>
              <p className={classes['label']}>First Name</p>
              <Input
                onChange={(e) =>
                  inputChangeHandler('firstName', e.target.value)
                }
                style={firstNameInputStyle}
                placeholder="Enter here"
                value={applyFormState.firstName.value}
              />
            </div>
            <div className={classes['last-name-container']}>
              <p className={classes['label']}>Last Name</p>
              <Input
                onChange={(e) => inputChangeHandler('lastName', e.target.value)}
                style={lastNameInputStyle}
                placeholder="Enter here"
                value={applyFormState.lastName.value}
              />
            </div>
          </div>

          <p className={classes['label']}>Main Social Channel URL</p>
          <Input
            onChange={(e) =>
              inputChangeHandler('mainSocialChannelUrl', e.target.value)
            }
            placeholder={'Enter here'}
            style={mainSocialChannelUrlInputStyle}
            value={applyFormState.mainSocialChannelUrl.value}
          />

          <p className={classes['label']}>
            How many followers do you have on this channel?
          </p>
          <Input
            onChange={(e) =>
              inputChangeHandler('numberOfFollowers', e.target.value)
            }
            type="number"
            style={numberOfFollowersInputStyle}
            placeholder="Enter here"
            value={applyFormState.numberOfFollowers.value}
          />

          <p className={classes['label']}>
            Why are you excited to join Glimpse as a creator?
          </p>
          <TextArea
            onChange={(e) => inputChangeHandler('description', e.target.value)}
            style={descriptionInputStyle}
            placeholder="Tell us a bit more about yourself and your interest in Glimpse (Min: 20 characters)"
            value={applyFormState.description.value}
          />
        </div>

        <div className={classes['apply-button-container']}>
          <Button
            onClick={applyAsCreatorHandler}
            disabled={isApplyButtonDisabled || sendingApply}
            style={{
              width: 300,
            }}
            darkpink="true"
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ApplyAsCreator;
