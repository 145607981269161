import React from 'react';
import classes from './RequestedServiceFromMeDetailsModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import ReactPlayer from 'react-player';

function RequestedServiceFromMeDetailsModal(props) {
  const { setShowRequestedServiceFromMeDetailsModal, requestedService } = props;

  const closeModalHandler = () => {
    setShowRequestedServiceFromMeDetailsModal(false);
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Requested Service Details"
    >
      <div>
        {requestedService.uploadedText && (
          <div>
            <p className={classes['content-text']}>
              Uploaded Text:{' '}
              <span className={classes['uploaded-text']}>
                {requestedService.uploadedText}
              </span>
            </p>
          </div>
        )}

        {requestedService.uploadedFile && (
          <div className={classes['file-container']}>
            <p className={classes['uploaded-file-text']}>Uploaded File:</p>
            {requestedService.uploadedFileType === 'image' && (
              <img
                width={200}
                height={200}
                src={requestedService.uploadedFile}
                alt="Uploaded File"
                className={classes['uploaded-image']}
              />
            )}

            {requestedService.uploadedFileType === 'video' && (
              <ReactPlayer
                url={requestedService.uploadedFile}
                controls
                width={200}
                height={200}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default RequestedServiceFromMeDetailsModal;
