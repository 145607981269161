import React from 'react';
import classes from './TermsOfUse.module.css';
import { termsOfUse } from './data';

function TermsOfUse() {
  return (
    <div className={classes['terms-of-use-container']}>
      <h3 className={classes['terms-of-use-title']}>TERMS OF USE</h3>
      {termsOfUse.map((el, i) => (
        <p key={i} className={classes['terms-of-use-text']}>
          {el}
        </p>
      ))}
    </div>
  );
}

export default TermsOfUse;
