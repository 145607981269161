import React, { useState } from 'react';
import classes from './UploadNewNftsModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import Input from '../../../../components/Input/Input';
import { useDropzone } from 'react-dropzone';
import UploadImageIcon from '../../../../assets/upload-img-icon.png';
import RemoveIcon from '../../../../assets/gray-remove.png';
import ipfs from '../../../../ipfs';
import notify from '../../../../utils/notify';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import {
  addNftToCollectionRequest,
  createNFTRequest,
  updateNftPreviewUrlRequest,
} from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';

const GLMS_TO_USD_PRICE = 0.01;

function UploadNewNftsModal(props) {
  const { setShowUploadNewNftsModal, nftCollection } = props;

  const queryClient = useQueryClient();

  const [startingPrice, setStartingPrice] = useState('');
  const [startingPriceTouched, setStartingPriceTouched] = useState(false);
  const [royalteFee, setRoyalteFee] = useState('');
  const [royalteFeeTouched, setRoyalteFeeTouched] = useState(false);
  const [uploadedNfts, setUploadedNfts] = useState([]);
  const [loadingIPFS, setLoadingIPFS] = useState(false);
  const [uploadingNfts, setUploadingNfts] = useState(false);

  const closeModalHandler = () => {
    setShowUploadNewNftsModal(false);
  };

  const startingPriceChangeHandler = (e) => {
    setStartingPrice(e.target.value);
    setStartingPriceTouched(true);
  };

  const royalteFeeChangeHandler = (e) => {
    let royalte = e.target.value;
    if (royalte.length >= 1) {
      royalte = royalte[0];
    }
    setRoyalteFee(royalte);
    setRoyalteFeeTouched(true);
  };

  const onDrop = (files) => {
    setLoadingIPFS(true);
    if (files.length === 1) {
      if (files[0].size > 100000000) {
        notify('error', 'File size is bigger than 100MB', '', 2000);
        setLoadingIPFS(false);
        return;
      }
      let ext = files[0].name.split('.');
      ext = ext[ext.length - 1];
      ext = ext.toLowerCase();

      if (
        ext !== 'jpg' &&
        ext !== 'JPG' &&
        ext !== 'jpeg' &&
        ext !== 'JPEG' &&
        ext !== 'png' &&
        ext !== 'PNG' &&
        ext !== 'mp4' &&
        ext !== 'MP4'
      ) {
        notify('error', 'Invalid file type', '', 2000);
        setLoadingIPFS(false);
        return;
      }

      const previewUrlSize = files[0].size;
      const nftUrl = files[0];
      let previewUrl;

      let isVideo = false;
      if (ext === 'mp4' || ext === 'MP4') {
        isVideo = true;
      } else {
        isVideo = false;
      }

      const fileReader = new FileReader();
      fileReader.onload = () => {
        previewUrl = fileReader.result;
      };
      fileReader.readAsDataURL(files[0]);

      let reader = new FileReader();
      reader.readAsArrayBuffer(files[0]);

      reader.onloadend = async () => {
        const content = Buffer(reader.result);
        const added = await ipfs.add(content);
        const hash = added.cid.toString();

        setLoadingIPFS(false);
        const nftData = {
          isVideo,
          previewUrl,
          url: nftUrl,
          previewUrlSize,
          ipfs: hash,
        };
        setUploadedNfts((prevState) => [nftData, ...prevState]);
      };
    } else {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 100000000) {
          notify('error', 'File size is bigger than 100MB', '', 2000);
          setLoadingIPFS(false);
          continue;
        }

        let ext = files[i].name.split('.');
        ext = ext[ext.length - 1];
        ext = ext.toLowerCase();

        if (
          ext !== 'jpg' &&
          ext !== 'JPG' &&
          ext !== 'jpeg' &&
          ext !== 'JPEG' &&
          ext !== 'png' &&
          ext !== 'PNG' &&
          ext !== 'mp4' &&
          ext !== 'MP4'
        ) {
          notify('error', 'Invalid file type', '', 2000);
          setLoadingIPFS(false);
          continue;
        }

        const previewUrlSize = files[i].size;
        const nftUrl = files[i];
        let previewUrl;

        let isVideo = false;
        if (ext === 'mp4' || ext === 'MP4') {
          isVideo = true;
        } else {
          isVideo = false;
        }

        const fileReader = new FileReader();
        fileReader.onload = () => {
          previewUrl = fileReader.result;
        };
        fileReader.readAsDataURL(files[i]);

        let reader = new FileReader();
        reader.readAsArrayBuffer(files[i]);

        reader.onloadend = async () => {
          const content = Buffer(reader.result);
          const added = await ipfs.add(content);
          const hash = added.cid.toString();

          setLoadingIPFS(false);
          const nftData = {
            isVideo,
            previewUrl,
            url: nftUrl,
            previewUrlSize,
            ipfs: hash,
          };
          setUploadedNfts((prevState) => [nftData, ...prevState]);
        };
      }
    }
  };

  const removeUploadedNft = (index) => {
    setUploadedNfts((prevState) => prevState.filter((_, i) => i !== index));
  };

  const uploadNewNftsHandler = async () => {
    const nftCollectionStats = queryClient.getQueryData([
      'nft-collection-stats',
      nftCollection._id,
    ]);

    let startingIndex = 1;
    if (!isNaN(nftCollectionStats?.numberOfUniqueNfts)) {
      startingIndex = parseInt(nftCollectionStats.numberOfUniqueNfts) + 1;
    }

    setUploadingNfts(true);
    try {
      await Promise.all(
        uploadedNfts.map(async (nft, i) => {
          const { ipfs, isVideo, url, previewUrlSize } = nft;
          const requestBody = {
            ipfs,
            title: `${nftCollection.name} #${i + startingIndex}`,
            category: nftCollection.categories[0],
            additionalCategories: nftCollection.categories.slice(1),
            description: nftCollection.description,
            adultContent: false,
            royalteFee: parseInt(royalteFee),
            buyNowPrice: parseFloat(startingPrice / GLMS_TO_USD_PRICE),
            type: isVideo ? 'video' : 'image',
            isPartOfCollection: true,
            nftCollection: nftCollection._id,
            claimable: nftCollection.claimable,
          };

          if (
            nftCollection.holderBenefits &&
            nftCollection.holderBenefits.length > 0
          ) {
            requestBody['benefits'] = nftCollection.holderBenefits;
          }

          const { data: createdNft } = await createNFTRequest(requestBody);

          if (previewUrlSize < 10000000) {
            const formData = new FormData();
            formData.append('previewUrl', url);
            updateNftPreviewUrlRequest(createdNft._id, formData);
          }

          return await addNftToCollectionRequest(
            nftCollection._id,
            createdNft._id
          );
        })
      );
      await Promise.all(
        [
          ['nft-collection-stats', nftCollection._id],
          [
            'nfts-for-specific-collection',
            nftCollection._id,
            '',
            true,
            'low-high',
          ],
        ].map(async (queryKey) =>
          Promise.all([
            queryClient.invalidateQueries(queryKey),
            queryClient.refetchQueries(queryKey),
          ])
        )
      );

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setUploadingNfts(false);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const startingPriceInputStyle = { width: '100%' };
  if (
    startingPriceTouched &&
    (!startingPrice || isNaN(startingPrice) || parseFloat(startingPrice) < 5)
  ) {
    startingPriceInputStyle['border'] = '1px solid red';
  }

  const royalteFeeInputStyle = { width: '100%' };
  if (
    royalteFeeTouched &&
    (isNaN(royalteFee) || parseInt(royalteFee) < 0 || parseInt(royalteFee) > 5)
  ) {
    royalteFeeInputStyle['border'] = '1px solid red';
  }

  const isUploadButtonDisabled = () => {
    if (
      !royalteFee ||
      isNaN(royalteFee) ||
      parseInt(royalteFee) < 0 ||
      parseInt(royalteFee) > 5
    )
      return true;

    if (
      !nftCollection.claimable &&
      (!startingPrice || isNaN(startingPrice) || parseFloat(startingPrice) < 5)
    )
      return true;

    if (uploadedNfts.length === 0) return true;

    if (uploadingNfts) return true;

    return false;
  };

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Upload New NFTs"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={isUploadButtonDisabled()}
            onClick={uploadNewNftsHandler}
            darkpink="true"
          >
            Upload
          </Button>
        </div>
      }
    >
      {!nftCollection.claimable && (
        <>
          {/* <p className={classes['label']}>Starting Price (Min 500 GLMS)</p> */}
          <p className={classes['label']}>Starting Price (Min 5 USD)</p>

          <Input
            placeholder="Enter USD amount"
            style={startingPriceInputStyle}
            type="number"
            value={startingPrice}
            onChange={startingPriceChangeHandler}
          />
        </>
      )}

      <p className={classes['label']}>Royalte Fee (0-5%)</p>
      <Input
        placeholder="Enter here"
        style={royalteFeeInputStyle}
        type="number"
        value={royalteFee}
        onChange={royalteFeeChangeHandler}
      />

      <div className={classes['uploaded-nfts-container']}>
        <p style={{ margin: '10px 0 3px 0', color: '#677074' }}>Upload NFT’s</p>
        <div className={classes['upload-file-and-nfts-container']}>
          <div className={classes['upload-img-container']}>
            <input {...getInputProps()} />
            <div>
              {loadingIPFS && <LoadingSpinner />}
              {!loadingIPFS && (
                <div
                  {...getRootProps()}
                  className={classes['upload-container']}
                >
                  <img
                    className={classes['upload-img']}
                    src={UploadImageIcon}
                    alt="Upload"
                    width={35}
                    height={40}
                  />
                  <p className={classes['drag-drop-text']}>Upload Files</p>
                </div>
              )}
            </div>
          </div>

          {uploadedNfts.slice(0, 3).map((nft, i) => {
            if (nft.isVideo) {
              return (
                <div
                  key={i}
                  style={{ marginTop: 5 }}
                  className={classes['nft-container']}
                >
                  <video className={classes['nft-video']} controls>
                    <source src={nft.previewUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div
                    onClick={() => removeUploadedNft(i)}
                    className={classes['remove-icon-container']}
                  >
                    {' '}
                    <img
                      alt="Remove"
                      src={RemoveIcon}
                      className={classes['remove-icon']}
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div key={i} className={classes['nft-container']}>
                  <img
                    className={classes['nft-image']}
                    alt="IPFS-hash"
                    src={nft.previewUrl}
                  />
                  <div
                    onClick={() => removeUploadedNft(i)}
                    className={classes['remove-icon-container']}
                  >
                    {' '}
                    <img
                      alt="Remove"
                      src={RemoveIcon}
                      className={classes['remove-icon']}
                    />
                  </div>
                </div>
              );
            }
          })}

          {uploadedNfts.length > 3 && (
            <div className={classes['more-nfts-container']}>
              <div className={classes['more-text-container']}>
                <h3 className={classes['more-text']}>
                  +{uploadedNfts.length - 3}
                </h3>{' '}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default UploadNewNftsModal;
