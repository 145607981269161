import React, { useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getSpecificGroupRequest,
  getMemberStatusInASpecificGroupRequest,
} from '../../httpRequests/httpRequests';
import classes from './Group.module.css';
import GroupInformation from './components/GroupInformation/GroupInformation';
import { AuthContext } from '../../context/auth-context';
import { useHistory } from 'react-router-dom';
import notify from '../../utils/notify';
import UserSidebar from '../../components/UserSidebar/UserSidebar';

function Group() {
  const history = useHistory();
  const { profile } = useContext(AuthContext);
  const { id: groupId } = useParams();

  const { data, error } = useQuery(['groups', groupId], () =>
    getSpecificGroupRequest(groupId)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    const checkMyStatusInTheCurrentGroup = async () => {
      const { data } = await getMemberStatusInASpecificGroupRequest(
        groupId,
        profile?._id
      );

      if (data?.status) {
        history.push(`/my-groups?id=${groupId}`);
      }
    };

    checkMyStatusInTheCurrentGroup();
  });

  return (
    <div className={classes['group-container']}>
      <div className={classes['user-sidebar-container']}>
        <UserSidebar />
      </div>

      <div className={classes['group-information-container']}>
        {data?.data && <GroupInformation group={data.data} />}
      </div>
    </div>
  );
}

export default Group;
