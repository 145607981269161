import React, { useContext, useMemo, useState } from 'react';
import classes from './CreatorServices.module.css';
import EditCreatorServicesModal from '../../../../components/EditCreatorServicesModal/EditCreatorServicesModal';
import { AuthContext } from '../../../../context/auth-context';

import StarIcon from '../../../../assets/custom-benefits-star-icon.png';
import PersonalizedVideoIcon from '../../../../assets/personalized-video-icon.png';
import PersonalizedImageIcon from '../../../../assets/personalized-image-icon.png';
import ChatBenefitIcon from '../../../../assets/chat-benefit-icon.png';
import VideoCallBenefitIcon from '../../../../assets/video-call-benefit-icon.png';

import NoSuggestionsIcon from '../../../../assets/no-suggestions.png';
import CustomServicesModal from './components/CustomServicesModal/CustomServicesModal';
import { getMyStripeAccountDetailsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { useHistory } from 'react-router-dom';
import { limitString } from '../../../../helpers/helpers';

const POPULAR_SERVICES = [
  '1/1 Chat (15mins)',
  '1/1 Video Call (15mins)',
  'Personalized Image',
  'Personalized Video',
];

function CreatorServices() {
  const { profile } = useContext(AuthContext);
  const history = useHistory();

  const myExistingServices = useMemo(() => {
    if (!profile?.creatorServices || profile?.creatorServices?.length === 0)
      return {
        custom: [],
        nonCustom: [],
      };

    const existingCustomServices = [];
    const existingNonCustomServices = [];
    profile.creatorServices.forEach((el) => {
      if (POPULAR_SERVICES.includes(el)) {
        existingNonCustomServices.push(el);
      } else {
        existingCustomServices.push(el);
      }
    });

    return {
      custom: existingCustomServices,
      nonCustom: existingNonCustomServices,
    };
  }, [profile]);

  const customServices = myExistingServices.custom;
  const nonCustomServices = myExistingServices.nonCustom;

  const [
    showEditCreatorServicesModal,
    setShowEditCreatorServicesModal,
  ] = useState(false);
  const [showCustomServicesModal, setShowCustomServicesModal] = useState(false);
  const [loadingStripeData, setLoadingStripeData] = useState(false);

  const openEditCreatorServicesModalHandler = async () => {
    setLoadingStripeData(true);

    try {
      const {
        data: myStripeAccountDetails,
      } = await getMyStripeAccountDetailsRequest();

      setLoadingStripeData(false);

      if (!myStripeAccountDetails) {
        notify(
          'error',
          'You need to link your bank account to perform this action!',
          4000
        );

        setTimeout(() => {
          history.push('/get-started');
        }, 4000);

        return;
      }

      if (
        myStripeAccountDetails.capabilities &&
        myStripeAccountDetails.capabilities.card_payments === 'active' &&
        myStripeAccountDetails.capabilities.transfers === 'active'
      ) {
        setShowEditCreatorServicesModal(true);
      } else {
        notify(
          'error',
          'You need to link your bank account to perform this action!',
          4000
        );

        setTimeout(() => {
          history.push('/get-started');
        }, 4000);

        return;
      }
    } catch (err) {
      setLoadingStripeData(false);
      notify('error', err, 2000);
    }
  };

  const renderServices = () => {
    const isChatIncluded = nonCustomServices.includes('1/1 Chat (15mins)');
    const isVideoCallIncluded = nonCustomServices.includes(
      '1/1 Video Call (15mins)'
    );
    const isPersonalizedImageIncluded = nonCustomServices.includes(
      'Personalized Image'
    );
    const isPersonalizedVideoIncluded = nonCustomServices.includes(
      'Personalized Video'
    );

    if (nonCustomServices.length + customServices.length === 0) {
      return (
        <div>
          <img
            src={NoSuggestionsIcon}
            alt="No Services"
            className={classes['no-services-icon']}
          />
          <p className={classes['no-services-text']}>No Services Found!</p>
        </div>
      );
    }

    return (
      <div className={classes['custom-non-custom-benefits-container']}>
        {isChatIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={ChatBenefitIcon} alt="Chat Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                1/1 Chat (15mins)
              </p>
            </div>
          </div>
        )}

        {isVideoCallIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={VideoCallBenefitIcon} alt="Video Call Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                1/1 Video Call (15mins)
              </p>
            </div>
          </div>
        )}

        {isPersonalizedImageIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={PersonalizedImageIcon} alt="Personalized Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                Personalized Image
              </p>
            </div>
          </div>
        )}

        {isPersonalizedVideoIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={PersonalizedVideoIcon} alt="Personalized Video" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                Personalized Video
              </p>
            </div>
          </div>
        )}

        {customServices.map((customService) => {
          return (
            <div
              key={customService}
              className={classes['custom-benefits-container']}
            >
              <img
                src={StarIcon}
                alt="Custom Services"
                className={classes['star-icon']}
              />
              <div className={classes['custom-text-container']}>
                <p
                  style={{
                    overflowWrap: 'anywhere',
                    whiteSpace: 'pre-wrap',
                  }}
                  title={customService}
                  className={classes['custom-benefits-text-bold']}
                >
                  {limitString(customService, 15)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {showEditCreatorServicesModal && (
        <EditCreatorServicesModal
          setShowEditCreatorServicesModal={setShowEditCreatorServicesModal}
        />
      )}

      {showCustomServicesModal && (
        <CustomServicesModal
          setShowCustomServicesModal={setShowCustomServicesModal}
          customServices={customServices}
        />
      )}

      <div className={classes['creator-services-container']}>
        <div className={classes['creator-services-edit-container']}>
          <p className={classes['creator-services-text']}>Creator Services</p>

          <p
            style={{ cursor: loadingStripeData ? 'not-allowed' : 'pointer' }}
            className={classes['edit-text']}
            onClick={openEditCreatorServicesModalHandler}
          >
            Edit
          </p>
        </div>

        <div className={classes['services-container']}>{renderServices()}</div>
      </div>
    </>
  );
}

export default CreatorServices;
