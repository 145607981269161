import React from 'react';
import Modal from '../../../../../../../../components/Modal/Modal';

function ReportDescriptionModal(props) {
  const { reportDescription, setShowReportDescriptionModal } = props;

  const closeModalHandler = () => {
    setShowReportDescriptionModal(false);
  };

  return (
    <Modal
      footer={
        <>
          <button onClick={closeModalHandler} className={'cancel-button'}>
            Cancel
          </button>
        </>
      }
      closeModal={closeModalHandler}
      title="Report Description"
    >
      <h2 className="bold-text">{reportDescription}</h2>
    </Modal>
  );
}

export default ReportDescriptionModal;
