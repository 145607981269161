import React, { useContext, useRef, useState, useEffect } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { AuthContext } from '../../../../context/auth-context';
import classes from './ReferFriendsModal.module.css';
import QRCode from 'qrcode';
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import Button from '../../../../components/Button/Button';
import CopyIcon from '../../../../assets/copy-1.png';
import notify from '../../../../utils/notify';

function ReferFriendsModal(props) {
  const canvasRef = useRef();
  const { profile } = useContext(AuthContext);
  const { setShowReferFriendsModal } = props;

  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    const referralLink = `${window.location.origin}/register?ref=${profile.username}`;

    QRCode.toCanvas(canvasRef.current, referralLink);
  }, [profile]);

  const closeModalHandler = () => {
    setShowReferFriendsModal(false);
  };

  const copyReferralLinkHandler = async () => {
    try {
      const referralLink = `${window.location.origin}/register?ref=${profile.username}`;

      await navigator.clipboard.writeText(referralLink);

      setLinkCopied(true);

      setTimeout(() => {
        setLinkCopied(false);
      }, 3000);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderShareReferralLinkHandler = () => {
    const url = `${window.location.origin}/register?ref=${profile.username}`;

    return (
      <div
        id="share-icons-container"
        className={classes['share-socials-container']}
      >
        <div
          onClick={copyReferralLinkHandler}
          className={classes['copy-icon-container']}
        >
          <img src={CopyIcon} alt="Copy" className={classes['copy-icon']} />
        </div>

        <FacebookShareButton
          url={url}
          hashtag={'#glimpsecommunities'}
          title={`Use my invite link to join me on Glimpse!`}
        >
          <FacebookIcon size={28} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={url}
          hashtag={'#glimpsecommunities'}
          title={`Use my invite link to join me on Glimpse!`}
        >
          <TwitterIcon size={28} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={url}
          hashtag={'#glimpsecommunities'}
          title={`Use my invite link to join me on Glimpse!`}
        >
          <WhatsappIcon size={28} round />
        </WhatsappShareButton>

        <TelegramShareButton
          url={url}
          hashtag={'#glimpsecommunities'}
          title={`Use my invite link to join me on Glimpse!`}
        >
          <TelegramIcon size={28} round />
        </TelegramShareButton>

        <RedditShareButton
          url={url}
          hashtag={'#glimpsecommunities'}
          title={`Use my invite link to join me on Glimpse!`}
        >
          <RedditIcon size={28} round />
        </RedditShareButton>
      </div>
    );
  };

  return (
    <Modal
      style={{ width: 300 }}
      closeIcon="true"
      closeModal={closeModalHandler}
      title="Refer Friends"
    >
      <div>
        <div className={classes['refer-friends-text-container']}>
          <p className={classes['refer-friends-text']}>
            Referring friends is the fasted way to grow your audience on
            Glimpse. Refer{' '}
            <span className={classes['five-friends-text']}>5 Friends </span>
            to complete the module.
          </p>
        </div>

        <div className={classes['qr-code-container']}>
          <canvas className={classes['canvas']} ref={canvasRef}></canvas>
        </div>

        {renderShareReferralLinkHandler()}

        {linkCopied && (
          <div style={{ textAlign: 'center' }}>
            <p className={classes['success-copy']}>
              Referral Link copied successfully!
            </p>
          </div>
        )}

        <Button
          darkpink="true"
          onClick={closeModalHandler}
          style={{ width: '100%', marginTop: 20 }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default ReferFriendsModal;
