import React from 'react';
import { IPFS_URL } from '../../../../constants/IPFS';
import classes from './NftDetails.module.css';

function NftDetails(props) {
  const { nft } = props;

  return (
    <div className={classes['details-container']}>
      <div className={classes['ipfs-hash-container']}>
        <p className={classes['bold']}>IPFS HASH</p>
        <a
          target={'_blank'}
          rel="noreferrer"
          href={`${IPFS_URL}/${nft.ipfs}`}
          className={`${classes['thin']} ${classes['ipfs-link']}`}
        >
          {nft.ipfs}
        </a>
      </div>

      <div className={classes['owner-address-container']}>
        <p className={classes['bold']}>Owner Address</p>
        <p className={`${classes['thin']} ${classes['owner-text']}`}>
          {nft.owner.walletAddress}
        </p>
      </div>
      <div className={classes['author-address-container']}>
        <p className={classes['bold']}>Author Address</p>
        <p className={`${classes['thin']} ${classes['author-text']}`}>
          {nft.author.walletAddress}
        </p>
      </div>

      <div className={classes['token-address-container']}>
        <p className={classes['bold']}>Network</p>
        <p className={classes['thin']}>Binance Smart Chain</p>
      </div>
    </div>
  );
}

export default NftDetails;
