import React, { useState, useEffect } from 'react';
import classes from './ReferralsProgress.module.css';
import { useQuery } from 'react-query';
import { getMyDirectReferralsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import NoReferralsIcon from '../../../../assets/no-suggestions.png';
import VerifiedIcon from '../../../../assets/verified.png';
import { RadialChart, Hint } from 'react-vis';
import { Link } from 'react-router-dom';

function ReferralsProgress() {
  const { isLoading, data, error } = useQuery(
    'my-direct-referrals',
    getMyDirectReferralsRequest
  );
  const [hoveredChartValue, setHoveredChartValue] = useState();

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const generateRadialChartData = () => {
    return [
      {
        angle: data.data.length,
        color: '#F900B7',
        label: 'Completed',
      },
      {
        angle: 100 - data.data.length,
        color: '#F8F8F8',
        label: 'Remaining',
      },
    ];
  };

  return (
    <div className={classes['referrals-progress-container']}>
      <div className={classes['direct-referrals-list']}>
        {!isLoading && data?.data && data.data.length === 0 && (
          <div className={classes['no-referrals-container']}>
            <img
              src={NoReferralsIcon}
              alt="No Referrals"
              className={classes['no-referrals-icon']}
            />
            <p className={classes['no-referrals-found-text']}>
              No Referrals Found!
            </p>
          </div>
        )}

        {!isLoading &&
          data?.data &&
          data?.data.map(({ referral }) => {
            return (
              <Link to={`/user-feed/${referral._id}`} key={referral._id}>
                <div
                  style={{
                    marginBottom: 15,
                  }}
                  className={classes['user-information-container']}
                >
                  <img
                    src={referral.photo}
                    alt="Referral"
                    className={classes['user-image']}
                  />
                  <p className={classes['referral-fullName']}>
                    {referral.fullName}
                  </p>

                  {referral.verified && (
                    <img src={VerifiedIcon} alt="Verified" />
                  )}
                </div>
              </Link>
            );
          })}
      </div>

      {!isLoading && data?.data && (
        <div className={classes['progress-container']}>
          <RadialChart
            colorType="literal"
            animation={true}
            width={300}
            height={300}
            data={generateRadialChartData()}
            onValueMouseOver={(v) => setHoveredChartValue(v)}
            onSeriesMouseOut={() => setHoveredChartValue(undefined)}
          >
            {hoveredChartValue && (
              <Hint value={hoveredChartValue}>
                <div className={classes['hint-container']}>
                  <p className={classes['hint-label']}>
                    {hoveredChartValue.label}
                  </p>
                </div>
              </Hint>
            )}
          </RadialChart>

          <div className={classes['legend-box-container']}>
            <div className={classes['legend-container']}>
              <div className={classes['pink-legend-color']}>&nbsp;</div>
              <div className={classes['legend-text-percentage-container']}>
                <p className={classes['legend-text']}>Completed</p>
                <p className={classes['percentage-text']}>
                  {data.data.length > 100 ? '100%' : `${data.data.length}%`}
                </p>
              </div>
            </div>

            <div className={classes['legend-container']}>
              <div className={classes['white-legend-color']}>&nbsp;</div>
              <div className={classes['legend-text-percentage-container']}>
                <p className={classes['legend-text']}>Remaining</p>
                <p className={classes['percentage-text']}>
                  {data.data.length >= 100
                    ? '0%'
                    : `${100 - data.data.length}%`}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReferralsProgress;
