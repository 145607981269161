import React, { useEffect, useState } from 'react';
import classes from './NftCollectionViewContent.module.css';
import { useParams } from 'react-router-dom';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import NftCollectionInformation from './components/NftCollectionInformation/NftCollectionInformation';
import NftList from './components/NftList/NftList';
import NftSections from './components/NftSections/NftSections';
import NftCollectionActivity from './components/NftCollectionActivity/NftCollectionActivity';
import AssociatedGroups from './components/AssociatedGroups/AssociatedGroups';
import ClaimableNfts from './components/ClaimableNfts/ClaimableNfts';

export const NFT_COLLECTION_SECTIONS = [
  'Listed Items',
  'Not Listed Items',
  'Activity',
];

function NftCollectionViewContent() {
  const { id: collectionId } = useParams();
  const [selectedSection, setSelectedSection] = useState(
    NFT_COLLECTION_SECTIONS[0]
  );
  const [isCollectionClaimable, setIsCollectionClaimable] = useState(false);

  useEffect(() => {
    if (isCollectionClaimable) {
      setSelectedSection('Claimable Items');
    }
  }, [isCollectionClaimable]);

  return (
    <div className={classes['nft-collection-container']}>
      <div className={classes['user-sidebar-container']}>
        <UserSidebar selectedLink="collections" />
      </div>

      <div className={classes['nft-collection-information-container']}>
        <NftCollectionInformation
          collectionId={collectionId}
          setIsCollectionClaimable={setIsCollectionClaimable}
        />

        <NftSections
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          isCollectionClaimable={isCollectionClaimable}
        />

        {(selectedSection === 'Listed Items' ||
          selectedSection === 'Not Listed Items') && (
          <NftList
            selectedSection={selectedSection}
            collectionId={collectionId}
          />
        )}

        {selectedSection === 'Activity' && (
          <NftCollectionActivity collectionId={collectionId} />
        )}
        {selectedSection === 'Associated Groups' && (
          <AssociatedGroups collectionId={collectionId} />
        )}

        {selectedSection === 'Claimable Items' && (
          <ClaimableNfts collectionId={collectionId} />
        )}
      </div>
    </div>
  );
}

export default NftCollectionViewContent;
