import React, { useState, useContext } from 'react';
import classes from './MakeOfferModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import Input from '../../../../components/Input/Input';
import TextArea from '../../../../components/TextArea/TextArea';
import notify from '../../../../utils/notify';
import { makeNftOfferRequest } from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import { AuthContext } from '../../../../context/auth-context';

const GLMS_TO_USD_PRICE = 0.01;

function MakeOfferModal(props) {
  const { getMyLockedGLMSBalance } = useContext(AuthContext);
  const { setShowMakeOfferModal, nftId } = props;
  const queryClient = useQueryClient();
  const [price, setPrice] = useState('');
  const [priceTouched, setPriceTouched] = useState(false);
  const [comment, setComment] = useState('');
  const [makingOffer, setMakingOffer] = useState(false);

  const closeModalHandler = () => {
    setShowMakeOfferModal(false);
  };

  const priceChangeHandler = (e) => {
    setPrice(e.target.value);
    setPriceTouched(true);
  };

  const commentChangeHandler = (e) => {
    setComment(e.target.value);
  };

  const makeOfferHandler = async () => {
    setMakingOffer(true);

    try {
      const requestBody = {
        price: parseFloat(price) / GLMS_TO_USD_PRICE,
        comment,
      };
      await makeNftOfferRequest(nftId, requestBody);

      queryClient.invalidateQueries(['pending-nft-offers', nftId]);
      queryClient.refetchQueries(['pending-nft-offers', nftId]);

      getMyLockedGLMSBalance();

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setMakingOffer(false);
  };

  const priceInputStyle = { width: '100%' };
  if (priceTouched && (!price || isNaN(price) || parseFloat(price) < 5)) {
    priceInputStyle['border'] = '1px solid red';
  }

  const isMakeOfferButtonDisabled =
    !price || isNaN(price) || parseFloat(price) < 5 || makingOffer;

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Make Offer"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={isMakeOfferButtonDisabled}
            onClick={makeOfferHandler}
            darkpink="true"
          >
            Make Offer
          </Button>
        </div>
      }
    >
      <p className={classes['label']}>
        {/* Price (Min 500 GLMS) */}
        Price (Min 5 USD)
        {/* <span className={classes['glms-usd-conversion-text']}>
          {price ? ` = ${(price * 0.01).toFixed(2)} USD` : `1 GLMS = .01 USD`}
        </span> */}
      </p>
      <Input
        placeholder="Enter USD amount"
        type="number"
        style={priceInputStyle}
        onChange={priceChangeHandler}
        value={price}
      />

      <p style={{ marginTop: 10 }} className={classes['label']}>
        Comment
      </p>
      <TextArea
        style={{ width: '100%', minHeight: 100 }}
        value={comment}
        placeholder="Enter here"
        onChange={commentChangeHandler}
      />
    </Modal>
  );
}

export default MakeOfferModal;
