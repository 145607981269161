import React, { useContext, useEffect, useState } from 'react';
import classes from './TopCreators.module.css';
import { useQuery } from 'react-query';
import { getTopCreatorsRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import VerifiedIcon from '../../assets/verified.png';
import RightIcon from '../../assets/right-icon.png';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import { limitString } from '../../helpers/helpers';

function TopCreators() {
  const { profile } = useContext(AuthContext);
  const { data, error } = useQuery('top-creators', getTopCreatorsRequest);
  const [sliceStart, setSliceStart] = useState(0);
  const [sliceEnd, setSliceEnd] = useState(6);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const goNextHandler = () => {
    if (!data?.data) return;

    const totalResults = data.data.length;
    if (sliceEnd <= totalResults - 6) {
      setSliceStart((prevState) => prevState + 6);
      setSliceEnd((prevState) => prevState + 6);
    } else {
      setSliceStart(0);
      setSliceEnd(6);
    }
  };

  const renderTopCreator = (user) => {
    return (
      <Link
        key={user._id}
        to={user._id === profile?._id ? '/my-space' : `/user-feed/${user._id}`}
      >
        <div className={classes['top-creator']}>
          {user.coverPhoto && (
            <img
              src={user.coverPhoto}
              alt={user.fullName}
              className={classes['user-cover-photo']}
            />
          )}

          <div className={classes['user-info-container']}>
            <img
              src={user.photo}
              alt={user.fullName}
              className={classes['user-photo']}
            />
            <div className={classes['user-name-container']}>
              <div className={classes['fullName-verified-container']}>
                <p title={user.fullName} className={classes['user-fullName']}>
                  {limitString(user.fullName, 20)}
                </p>
                {user.verified && (
                  <img
                    alt="Verified"
                    src={VerifiedIcon}
                    style={{ marginLeft: 6, height: 14 }}
                  />
                )}
              </div>
              <p title={`@${user.username}`} className={classes['username']}>
                @{limitString(user.username, 20)}
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  if (!data?.data) return null;

  return (
    <div className={classes['top-creators-container']}>
      <div className={classes['top-creators-text-container']}>
        <h3 className={classes['top-creators-text']}>
          Top Creators
          {data?.data && data.data.length > 12 && (
            <img
              alt="Next"
              src={RightIcon}
              onClick={goNextHandler}
              className={classes['right-icon']}
            />
          )}
        </h3>
        <div className={classes['horizontal-line']}></div>
      </div>

      <div className={classes['top-creators']}>
        {data?.data &&
          data.data
            .slice(sliceStart, sliceEnd)
            .map((user) => renderTopCreator(user))}
      </div>
    </div>
  );
}

export default TopCreators;
