import React from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';

function ImageModal(props) {
  const { setShowImageModal, title } = props;

  const closeModalHandler = () => {
    setShowImageModal(false);
  };

  return (
    <Modal
      closeIcon="true"
      style={{ maxHeight: 650, maxWidth: '90vw', overflow: 'auto' }}
      closeModal={closeModalHandler}
      title={title}
      footer={
        <Button pinkcolor="true" onClick={closeModalHandler}>
          Close
        </Button>
      }
    >
      {props.children}
    </Modal>
  );
}

export default ImageModal;
