import React, { useContext, useState } from 'react';
import classes from './CreateNftsOptionsModal.module.css';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import notify from '../../utils/notify';
import SingleNftIcon from '../../assets/single-nft-icon.png';
import SingleNftSelectedIcon from '../../assets/single-nft-selected-icon.png';
import CreateCollectionIcon from '../../assets/create-collection-icon-2.png';
import CreateCollectionSelectedIcon from '../../assets/create-collection-selected-icon.png';
import CreateClaimableIcon from '../../assets/create-claimable-collection-icon.png';
import CreateClaimableSelectedIcon from '../../assets/create-claimable-collection-selected-icon.png';

const OPTIONS = {
  SINGLE: 'SINGLE',
  COLLECTION: 'COLLECTION',
  CLAIMABLE: 'CLAIMABLE',
};

function CreateNftsOptionsModal(props) {
  const { setShowCreateNftsOptionsModal } = props;
  const history = useHistory();
  const { myNftCollectionAccessToCreate } = useContext(AuthContext);

  const [selectedOption, setSelectedOption] = useState(OPTIONS.SINGLE);

  const closeModalHandler = () => {
    setShowCreateNftsOptionsModal(false);
  };

  const goToSelectedOptionPageHandler = () => {
    if (selectedOption === OPTIONS.SINGLE) {
      history.push('/create');
    } else if (selectedOption === OPTIONS.COLLECTION) {
      if (!myNftCollectionAccessToCreate)
        return notify(
          'error',
          'You do not have access to create a collection!',
          3000
        );
      history.push('/explore?tab=Collections&claimable=0');
    } else if (selectedOption === OPTIONS.CLAIMABLE) {
      if (!myNftCollectionAccessToCreate)
        return notify(
          'error',
          'You do not have access to create a collection!',
          3000
        );

      history.push('/explore?tab=Collections&claimable=1');
    }

    closeModalHandler();
  };

  return (
    <Modal
      style={{ height: 475 }}
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>

          <Button
            style={{ width: 200 }}
            onClick={goToSelectedOptionPageHandler}
            darkpink="true"
          >
            Next
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Create Collectibles"
    >
      <div className={classes['options-container']}>
        <div
          onClick={() => setSelectedOption(OPTIONS.SINGLE)}
          className={`${
            selectedOption === OPTIONS.SINGLE
              ? classes['selected-container']
              : classes['option-container']
          }`}
        >
          <img
            src={
              selectedOption === OPTIONS.SINGLE
                ? SingleNftSelectedIcon
                : SingleNftIcon
            }
            alt="Single Collectibles"
            className={classes['icon']}
            width={30}
            height={30}
          />
          <p className={classes['option-text']}>Single</p>
          <p className={classes['option-text']}>1/1</p>
        </div>
        <div
          onClick={() => setSelectedOption(OPTIONS.COLLECTION)}
          className={`${
            selectedOption === OPTIONS.COLLECTION
              ? classes['selected-container']
              : classes['option-container']
          }`}
        >
          <img
            src={
              selectedOption === OPTIONS.COLLECTION
                ? CreateCollectionSelectedIcon
                : CreateCollectionIcon
            }
            alt="Create Collection"
            className={classes['icon']}
            width={30}
            height={30}
          />
          <p className={`${classes['option-text']}`}>Create</p>
          <p className={classes['option-text']}>Collection</p>
        </div>
        <div
          onClick={() => setSelectedOption(OPTIONS.CLAIMABLE)}
          className={
            selectedOption === OPTIONS.CLAIMABLE
              ? classes['selected-container']
              : classes['claimable-container']
          }
        >
          <img
            src={
              selectedOption === OPTIONS.CLAIMABLE
                ? CreateClaimableSelectedIcon
                : CreateClaimableIcon
            }
            alt="Claimable Collectibles"
            className={classes['icon']}
          />
          <p className={classes['option-text']}>Claimable</p>
          <p className={classes['option-text']}>Collectibles</p>
        </div>
      </div>

      <div className={classes['options-info-container']}>
        {selectedOption === OPTIONS.SINGLE && (
          <div className={classes['single-info-container']}>
            <h3>Single Collectibles</h3>
            <p>
              Select this option if you want to create a single 1/1 collectible.
              1/1 means that there will only be 1 collectible created and
              available for purchase.{' '}
            </p>
          </div>
        )}
        {selectedOption === OPTIONS.COLLECTION && (
          <div className={classes['collection-info-container']}>
            <h3>Collection</h3>
            <p>
              Best for creating multiple collectibles that are related in some
              way. There are many use cases for a collection: A collection can
              be used to showcase your photography, digital creatives, top
              content, or for special collectible drops.
            </p>
          </div>
        )}
        {selectedOption === OPTIONS.CLAIMABLE && (
          <div className={classes['claimable-info-container']}>
            <h3>Claimable</h3>
            <p>
              Best for creating multiple collectibles that are related in some
              way AND letting people claim them for free. While you can treat
              this like a Free Collection - Claimable collectibles are best for
              events, brands, and creators looking to give participants or top
              supporters a special digital collectible.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default CreateNftsOptionsModal;
