import React, { useContext } from 'react';
import Nft from '../../../../components/Nft/Nft';
import { AuthContext } from '../../../../context/auth-context';
import classes from './NftWatchlist.module.css';

function NftWatchlist() {
  const { myWatchlist } = useContext(AuthContext);

  return (
    <div className={classes['nft-list']}>
      {myWatchlist?.length === 0 && (
        <div
          style={{
            background: 'white',
            padding: '.8rem',
            borderRadius: 10,
            marginTop: 10,
            width: '95%',
            gridColumn: '1/-1',
          }}
        >
          <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
            No NFT’s on your Watchlist!
          </p>
        </div>
      )}
      {myWatchlist.map(({ nft }) => {
        if (!nft?._id) return null;

        return <Nft key={nft._id} nft={nft} />;
      })}
    </div>
  );
}

export default NftWatchlist;
