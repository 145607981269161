import React from 'react';
import classes from './PrivacyPolicy.module.css';
import { privacyPolicy } from './data';

function PrivacyPolicy() {
  return (
    <div className={classes['privacy-policy-container']}>
      <h3 className={classes['privacy-policy-title']}>PRIVACY POLICY</h3>
      {privacyPolicy.map((el, i) => (
        <p key={i} className={classes['privacy-policy-text']}>
          {el}
        </p>
      ))}
    </div>
  );
}

export default PrivacyPolicy;
