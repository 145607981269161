import React, { useContext } from 'react';
import WhatAreYouThinking from './components/WhatAreYouThinking/WhatAreYouThinking';
import MostRecentNfts from './components/MostRecentNfts/MostRecentNfts';
import Posts from './components/Posts/Posts';
import classes from './NewsFeedContent.module.css';
import TopCreators from '../../../../components/TopCreators/TopCreators';
import { NewsFeedContext } from '../../../../context/news-feed-context';
import { AuthContext } from '../../../../context/auth-context';
import ChangePasswordNewsFeedModal from './components/ChangePasswordNewsFeedModal/ChangePasswordNewsFeedModal';

function NewsFeedContent(props) {
  const { selectedTopic, setSelectedTopic } = props;

  const { profile, myAccessibilityInformation } = useContext(AuthContext);

  const {
    isFeaturedChecked,
    isAllChecked,
    isCollectorsChecked,
    featuredClickHandler,
    allClickHandler,
    collectorsClickHandler,

    showChangePasswordModal,
    setShowChangePasswordModal,
  } = useContext(NewsFeedContext);

  return (
    <>
      {showChangePasswordModal && (
        <ChangePasswordNewsFeedModal
          setShowChangePasswordModal={setShowChangePasswordModal}
          setShowSettingsModal={() => setShowChangePasswordModal(false)}
        />
      )}

      <div className={classes['feed-content-container']}>
        <MostRecentNfts />

        <div style={{ display: 'flex', width: '100%', gap: 10 }}>
          <div className={classes['posts-container']}>
            <WhatAreYouThinking />

            <div>
              <div className={classes['featured-all-filter-container']}>
                <div
                  onClick={featuredClickHandler}
                  className={`${classes['featured-filter-container']} ${
                    isFeaturedChecked && classes['featured-checked']
                  }`}
                >
                  <p>Featured</p>
                </div>

                {(profile?.publishNftsOrCollectionContent ||
                  myAccessibilityInformation?.showCollectorsFeed === true) && (
                  <div
                    onClick={collectorsClickHandler}
                    className={`${classes['collectors-filter-container']} ${
                      isCollectorsChecked && classes['collectors-checked']
                    }`}
                  >
                    <p>Collectors</p>
                  </div>
                )}
                <div
                  onClick={allClickHandler}
                  className={`${classes['all-filter-container']} ${
                    isAllChecked && classes['all-checked']
                  }`}
                >
                  <p>All</p>
                </div>
              </div>
              <Posts
                setSelectedTopic={setSelectedTopic}
                selectedTopic={selectedTopic}
                isAllChecked={isAllChecked}
                isCollectorsChecked={isCollectorsChecked}
              />
            </div>
          </div>

          <div className={classes['top-creators-container']}>
            <TopCreators />
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsFeedContent;
