import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import LoadingSpinner from '../../../../../../../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import {
  getDateFrom,
  isValidUrl,
} from '../../../../../../../../../../../../../../helpers/helpers';
import {
  getAllRepliesForSpecificUserPostCommentRequest,
  deleteUserPostCommentReplyRequest,
} from '../../../../../../../../../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../../../../../../../../../utils/notify';
import classes from './PostCommentReplies.module.css';
import PostCommentReplyLikeText from '../PostCommentReplyLikeText/PostCommentReplyLikeText';
import { AuthContext } from '../../../../../../../../../../../../../../context/auth-context';
import { Link } from 'react-router-dom';
import VerifiedIcon from '../../../../../../../../../../../../../../assets/verified.png';
import MoreIcon from '../../../../../../../../../../../../../../assets/more.png';

const RESULTS_PER_PAGE = 3;

function PostCommentReplies(props) {
  const queryClient = useQueryClient();
  const { profile } = useContext(AuthContext);
  const { commentId } = props;
  const { isLoading, data, error } = useQuery(
    ['user-post-comment-replies', commentId],
    () => getAllRepliesForSpecificUserPostCommentRequest(commentId)
  );
  const [selectedReplyToDelete, setSelectedReplyToDelete] = useState();

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [sliceEnd, setSliceEnd] = useState(RESULTS_PER_PAGE);

  const viewMoreRepliesHandler = () => {
    setSliceEnd((prevSliceEnd) => prevSliceEnd + RESULTS_PER_PAGE);
  };

  useEffect(() => {
    error && notify('error', error, 1000);
  }, [error]);

  const toggleMoreOptionsHandler = (replyId) => {
    setShowMoreOptions((prevState) => !prevState);
    setSelectedReplyToDelete(replyId);
  };

  const deletePostCommentReplyHandler = async (replyId) => {
    await deleteUserPostCommentReplyRequest(commentId, replyId);
    queryClient.invalidateQueries(['user-post-comment-replies', commentId]);
    queryClient.refetchQueries(['user-post-comment-replies', commentId]);
  };

  const selectTopicHandler = (topic) => {
    if (!topic.startsWith('#')) return;

    if (props.selectedTopic === topic) {
      props.setSelectedTopic?.(undefined);
    } else {
      props.setSelectedTopic?.(topic);
    }
  };

  const renderReply = (reply, mentions) => {
    return reply.split('\n').map((sentence, index) => {
      if (sentence === '')
        return (
          <p key={sentence + index} className={classes['empty-space']}>
            &nbsp;
          </p>
        );

      return (
        <div
          style={{ overflowWrap: 'break-word', fontSize: 12, color: '#677074' }}
          key={sentence + index}
        >
          {sentence.split(' ').map((word, i) => {
            if (isValidUrl(word)) {
              return (
                <span key={word + i}>
                  <span> </span>
                  <a
                    href={
                      word.startsWith('https://') ? word : `https://${word}`
                    }
                    target="_blank"
                    style={{
                      cursor: 'pointer',
                      color: '#0094ff',
                      overflowWrap: 'anywhere',
                    }}
                    key={word + i}
                    rel="noreferrer"
                  >
                    {word}
                  </a>
                </span>
              );
            }

            const mentionIndex = mentions.findIndex(
              ({ mentionUsername }) => mentionUsername === word
            );

            if (mentionIndex !== -1) {
              const mentionId = mentions[mentionIndex]?.data?.id;
              return (
                <span key={word + i}>
                  <span> </span>{' '}
                  <Link
                    to={
                      mentionId === profile?._id
                        ? '/my-space'
                        : `/user-feed/${mentions[mentionIndex]?.data?.id}`
                    }
                    className={classes['mention']}
                    key={word + i}
                  >
                    {word}
                  </Link>
                </span>
              );
            } else {
              return (
                <span key={word + i}>
                  <span> </span>
                  <span
                    onClick={() => selectTopicHandler(word)}
                    style={{
                      cursor: word.startsWith('#') && 'pointer',
                      color: word.startsWith('#') && '#F900B7',
                      overflowWrap: 'anywhere',
                    }}
                    key={word + i}
                  >
                    {word}
                  </span>
                </span>
              );
            }
          })}
        </div>
      );
    });
  };

  return (
    <div className={classes['replies-container']}>
      {data &&
        data.data
          .slice(0, sliceEnd)
          .map(({ _id: replyId, reply, user: author, createdAt, mentions }) => {
            const isMyReply = author._id === profile?._id;
            return (
              <div key={replyId} className={classes['post-comment-container']}>
                <Link
                  to={
                    author._id === profile?._id
                      ? '/my-space'
                      : `/user-feed/${author._id}`
                  }
                >
                  <img
                    alt={author.fullName}
                    src={author.photo}
                    className={classes['user-image']}
                  />
                </Link>

                <div className={classes['post-comment-like-reply']}>
                  <div className={classes['comment-container']}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 10,
                      }}
                    >
                      <Link
                        to={
                          author._id === profile?._id
                            ? '/my-space'
                            : `/user-feed/${author._id}`
                        }
                        className={classes['user-fullName']}
                      >
                        {author.fullName}
                        {author.verified && (
                          <img
                            alt="Verified"
                            src={VerifiedIcon}
                            style={{ marginLeft: 6, height: 17 }}
                          />
                        )}
                      </Link>
                      {isMyReply && (
                        <img
                          alt="More"
                          style={{ cursor: 'pointer' }}
                          className={classes['more-icon']}
                          src={MoreIcon}
                          height={18}
                          onClick={() => toggleMoreOptionsHandler(replyId)}
                        />
                      )}
                    </div>{' '}
                    {renderReply(reply, mentions)}
                    <div className={classes['more']}>
                      {showMoreOptions && replyId === selectedReplyToDelete && (
                        <div className={classes['more-options']}>
                          <p
                            onClick={() =>
                              deletePostCommentReplyHandler(replyId)
                            }
                            className={classes['recycle-bin']}
                          >
                            Delete Reply
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 5,
                      margin: 0,
                    }}
                  >
                    <PostCommentReplyLikeText replyId={replyId} />
                    <p className={classes['createdAt']}>
                      {getDateFrom(createdAt)}
                    </p>
                  </div>{' '}
                </div>
              </div>
            );
          })}

      {isLoading && <LoadingSpinner />}

      {data && data.results > sliceEnd && (
        <p
          className={classes['view-more-replies']}
          onClick={viewMoreRepliesHandler}
        >
          View more replies
        </p>
      )}
    </div>
  );
}

export default PostCommentReplies;
