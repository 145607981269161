import React, { useContext, useState } from 'react';
import {
  getDateFrom,
  isValidUrl,
} from '../../../../../../../../../../../../helpers/helpers';
import classes from './PostComment.module.css';
import { useQuery, useQueryClient } from 'react-query';
import {
  getMyUserPostCommentLikeStatusRequest,
  getNumberOfLikesForSpecificUserPostCommentRequest,
  likeUserPostCommentRequest,
  removeUserPostCommentLikeRequest,
  deleteMyPostCommentRequest,
} from '../../../../../../../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../../../../../../../utils/notify';
import PostCommentReplies from './components/PostCommentReplies/PostCommentReplies';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../../../../../../../../../context/auth-context';
import VerifiedIcon from '../../../../../../../../../../../../assets/verified.png';
import MoreIcon from '../../../../../../../../../../../../assets/more.png';
import TextArea from '../../../../../../../../../../../../components/TextArea/TextArea';
// import Button from '../../../../../../../../../../../../components/Button/Button';

function PostComment(props) {
  const { profile } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const {
    postId,
    comment,
    user,
    createdAt,
    commentId,
    setReplyCommentState,
    replyCommentState,
    setComment,
    setUpdateCommentState,
    cancelReplyingHandler,
    setMentionedUsernames,
    replyChangeHandler,
    sendReplyHandler,
    mentions,
    shouldNotShowMentionsTable,
    shouldShowMentionsTableInNewLine,
    selectUserToMentionHandler,
    mentionsData,
    showTopicsTable,
    fetchedTopics,
    fetchingTopics,
    selectFetchedTopicHandler,
  } = props;

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const myLikeStatusForPostComment = useQuery(
    ['my-like-status-for-post-comment', commentId],
    () => getMyUserPostCommentLikeStatusRequest(commentId, profile?._id)
  );
  const { data: numberOfLikesData } = useQuery(
    ['number-of-likes-for-specific-user-post-comment', commentId],
    () => getNumberOfLikesForSpecificUserPostCommentRequest(commentId)
  );

  const invalidateMyLikeStatus = () => {
    queryClient.invalidateQueries([
      'my-like-status-for-post-comment',
      commentId,
    ]);
    queryClient.refetchQueries(['my-like-status-for-post-comment', commentId]);
  };

  const invalidateNumberOfLikes = () => {
    queryClient.invalidateQueries([
      'number-of-likes-for-specific-user-post-comment',
      commentId,
    ]);
    queryClient.refetchQueries([
      'number-of-likes-for-specific-user-post-comment',
      commentId,
    ]);
  };

  const likeCommentHandler = async () => {
    try {
      await likeUserPostCommentRequest(commentId);
      invalidateNumberOfLikes();
      invalidateMyLikeStatus();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const removeLikeCommentHandler = async () => {
    try {
      await removeUserPostCommentLikeRequest(commentId);
      invalidateNumberOfLikes();
      invalidateMyLikeStatus();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const renderLikeTextAccordingly = () => {
    if (myLikeStatusForPostComment.data?.data === null) {
      return (
        <span onClick={likeCommentHandler} className={classes['like']}>
          Like ({numberOfLikesData.data})
        </span>
      );
    } else {
      return (
        <span
          onClick={removeLikeCommentHandler}
          className={classes['liked-text']}
        >
          Liked ({numberOfLikesData.data})
        </span>
      );
    }
  };

  const replyCommentHandler = () => {
    if (
      replyCommentState.isReplying &&
      replyCommentState.selectedCommentIdToReply === commentId
    ) {
      cancelReplyingHandler();
    } else {
      setReplyCommentState({
        isReplying: true,
        selectedCommentIdToReply: commentId,
        authorReplyingTo: user.fullName,
        reply: '',
      });
    }
    setComment('');
    setUpdateCommentState({
      isUpdating: false,
      selectedCommentIdToUpdate: undefined,
      updatedComment: '',
    });

    setMentionedUsernames?.({});
  };

  const toggleMoreOptionsHandler = () => {
    setShowMoreOptions((prevState) => !prevState);
  };

  const selectTopicHandler = (topic) => {
    if (!topic.startsWith('#')) return;

    if (props.selectedTopic === topic) {
      props.setSelectedTopic?.(undefined);
    } else {
      props.setSelectedTopic?.(topic);
    }
  };

  const keyDownHandler = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendReplyHandler();
    }
  };

  const renderPostComment = (comment) => {
    return (
      <div className={classes['comment-text']}>
        {comment.split('\n').map((sentence, index) => {
          if (sentence === '')
            return (
              <p key={sentence + index} className={classes['empty-space']}>
                &nbsp;
              </p>
            );

          return (
            <div key={sentence + index}>
              {sentence.split(' ').map((word, i) => {
                if (isValidUrl(word)) {
                  return (
                    <span key={word + i}>
                      <span> </span>
                      <a
                        href={
                          word.startsWith('https://') ? word : `https://${word}`
                        }
                        target="_blank"
                        style={{
                          cursor: 'pointer',
                          color: '#0094ff',
                          overflowWrap: 'anywhere',
                        }}
                        key={word + i}
                        rel="noreferrer"
                      >
                        {word}
                      </a>
                    </span>
                  );
                }

                const mentionIndex = mentions.findIndex(
                  ({ mentionUsername }) => mentionUsername === word
                );

                if (mentionIndex !== -1) {
                  const mentionId = mentions[mentionIndex]?.data?.id;
                  return (
                    <span key={word + i}>
                      <span> </span>
                      <Link
                        style={{ overflowWrap: 'break-word' }}
                        to={
                          mentionId === profile?._id
                            ? '/my-space'
                            : `/user-feed/${mentions[mentionIndex]?.data?.id}`
                        }
                        className={classes['mention']}
                        key={word + i}
                      >
                        {word}
                      </Link>
                    </span>
                  );
                } else {
                  return (
                    <span key={word + i}>
                      <span> </span>
                      <span
                        onClick={() => selectTopicHandler(word)}
                        style={{
                          cursor: word.startsWith('#') && 'pointer',
                          color: word.startsWith('#') && '#F900B7',
                          overflowWrap: 'anywhere',
                        }}
                        key={word + i}
                      >
                        {word}
                      </span>
                    </span>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const renderReplyInput = () => {
    return (
      <div className={classes['reply-input-container']}>
        {/* <Link to={'/my-space'}>
          <img
            className={classes['user-image']}
            src={profile.photo}
            alt="ProfilePhoto"
          />
        </Link> */}

        <TextArea
          // shouldExpand
          value={replyCommentState.reply}
          onChange={replyChangeHandler}
          style={{ width: '100%' }}
          placeholder="Reply here"
          onKeyDown={keyDownHandler}
        />

        {/* <Button
          disabled={!replyCommentState.reply}
          onClick={sendReplyHandler}
          darkpink="true"
        >
          Reply
        </Button> */}

        {(!shouldNotShowMentionsTable() ||
          shouldShowMentionsTableInNewLine()) && (
          <div className={classes['mention-container']}>
            {mentionsData.length === 0 && (
              <p className={classes['no-users-found']}>No Users found</p>
            )}

            {mentionsData.map((user) => {
              const fullName = `${user.firstName} ${user.lastName}`;
              return (
                <div
                  onClick={() => selectUserToMentionHandler(user)}
                  className={classes['user-container']}
                  key={user._id}
                >
                  <div>
                    <img
                      style={{ objectFit: 'cover' }}
                      alt="userPhoto"
                      className={classes['user-image']}
                      src={user.photo}
                    />
                  </div>
                  <p className={classes['user-text']}>
                    {user.username} ({fullName})
                    {user._id === profile?._id ? ' (You)' : ''}
                  </p>
                </div>
              );
            })}
          </div>
        )}

        {showTopicsTable && (
          <div className={classes['topics-container']}>
            {fetchedTopics.length === 0 && fetchingTopics === false && (
              <p className={classes['no-topics-found']}>No Topics Found</p>
            )}
            {fetchedTopics.map((topic) => {
              return (
                <div
                  onClick={() => selectFetchedTopicHandler(topic)}
                  className={classes['topic-container']}
                  key={topic}
                >
                  <p className={classes['topic-text']}>{topic}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const deletePostCommentHandler = async () => {
    try {
      await deleteMyPostCommentRequest(commentId);
      queryClient.invalidateQueries(['user-post-comments', postId]);
      queryClient.refetchQueries(['user-post-comments', postId]);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const isMyComment = user._id === profile?._id;

  return (
    <>
      <div className={classes['post-comment-container']}>
        <Link
          to={
            user._id === profile?._id ? '/my-space' : `/user-feed/${user._id}`
          }
        >
          <img
            alt={user.fullName}
            src={user.photo}
            className={classes['user-image']}
          />
        </Link>

        <div className={classes['post-comment-like-reply']}>
          <div
            style={{ position: 'relative' }}
            className={classes['comment-container']}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link
                to={
                  user._id === profile?._id
                    ? '/my-space'
                    : `/user-feed/${user._id}`
                }
                className={classes['user-fullName']}
              >
                {user.fullName}
                {user.verified && (
                  <img
                    alt="Verified"
                    src={VerifiedIcon}
                    style={{ marginLeft: 6, height: 17 }}
                  />
                )}
              </Link>
              {isMyComment && (
                <img
                  alt="More"
                  style={{ cursor: 'pointer' }}
                  className={classes['more-icon']}
                  src={MoreIcon}
                  height={18}
                  onClick={toggleMoreOptionsHandler}
                />
              )}
            </div>
            {renderPostComment(comment)}

            {isMyComment && (
              <div className={classes['more']}>
                {showMoreOptions && (
                  <div className={classes['more-options']}>
                    <p
                      onClick={deletePostCommentHandler}
                      className={classes['recycle-bin']}
                    >
                      Delete Comment
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          {myLikeStatusForPostComment &&
            numberOfLikesData &&
            renderLikeTextAccordingly()}
          <span
            onClick={replyCommentHandler}
            className={
              replyCommentState.selectedCommentIdToReply === commentId
                ? classes['replying']
                : classes['reply']
            }
          >
            Reply
          </span>
          <span className={classes['createdAt']}>{getDateFrom(createdAt)}</span>
        </div>
      </div>

      {replyCommentState.isReplying &&
        replyCommentState.selectedCommentIdToReply === commentId &&
        renderReplyInput()}

      <PostCommentReplies
        selectedTopic={props.selectedTopic}
        setSelectedTopic={props.setSelectedTopic}
        commentId={commentId}
      />
    </>
  );
}

export default PostComment;
