import React, { forwardRef } from 'react';
import classes from './SearchInput.module.css';
import SearchInputIcon from '../../assets/search-input-icon.png';

const SearchInput = forwardRef((props, ref) => {
  return (
    <div className={classes['search-input-container']}>
      <input ref={ref} {...props} className={classes['input']} />
      <img
        alt="Search"
        className={classes['search-icon']}
        src={SearchInputIcon}
      />
    </div>
  );
});

export default SearchInput;
