import React, { useEffect, useState } from 'react';
import classes from './BuyGlmsModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import notify from '../../../../utils/notify';
import { createBuyGlmsStripeCheckoutSessionRequest } from '../../../../httpRequests/httpRequests';
import { getFormattedNumber } from '../../../../helpers/helpers';

const GLMS_OPTIONS = {
  1000: 10,
  5000: 50,
  10000: 100,
  25000: 250,
};

function BuyGlmsModal(props) {
  const { setShowBuyGlmsModal } = props;

  const [selectedGlmsValue, setSelectedGlmsValue] = useState('');
  const [creatingSession, setCreatingSession] = useState(false);

  useEffect(() => {
    const keyDownEventHandler = (e) => {
      const key = e.key;

      if (key === 'Backspace') {
        setSelectedGlmsValue((prevState) => {
          return prevState.substring(0, prevState.length - 1);
        });
      } else if (!isNaN(key)) {
        setSelectedGlmsValue((prevState) => {
          const updatedState = prevState + key;
          if (parseFloat(updatedState) > 100000) return '100000';
          return updatedState;
        });
      }
    };

    window.addEventListener('keydown', keyDownEventHandler);

    return () => window.removeEventListener('keydown', keyDownEventHandler);
  }, []);

  const closeModalHandler = () => {
    setShowBuyGlmsModal(false);
  };

  const selectGlmsOptionHandler = (glmsOption) => {
    setSelectedGlmsValue(glmsOption);
  };

  const buyGlmsHandler = async () => {
    setCreatingSession(true);
    try {
      const requestBody = {
        glmsAmount: parseFloat(selectedGlmsValue),
      };
      const { sessionUrl } = await createBuyGlmsStripeCheckoutSessionRequest(
        requestBody
      );

      setCreatingSession(false);
      closeModalHandler();
      window.open(sessionUrl, '_blank');
    } catch (err) {
      setCreatingSession(false);
      notify('error', err, 2000);
    }
  };

  const renderGlmsValue = () => {
    if (
      !selectedGlmsValue ||
      isNaN(selectedGlmsValue) ||
      isNaN(parseFloat(selectedGlmsValue))
    )
      return '0.00';

    return getFormattedNumber(parseFloat(selectedGlmsValue));
  };

  const renderUsdValue = () => {
    if (
      !selectedGlmsValue ||
      isNaN(selectedGlmsValue) ||
      isNaN(parseFloat(selectedGlmsValue))
    )
      return '0.00';

    return (parseFloat(selectedGlmsValue) * 0.01).toFixed(2);
  };

  const isBuyGlmsButtonDisabled = () => {
    if (creatingSession) return true;

    if (isNaN(parseFloat(selectedGlmsValue))) return true;

    if (!selectedGlmsValue || isNaN(selectedGlmsValue)) return true;

    const parsedAmount = parseFloat(selectedGlmsValue);
    if (parsedAmount < 1000) return true;

    return false;
  };

  return (
    <Modal closeIcon="true" closeModal={closeModalHandler} title="Add USD">
      <div className={classes['buy-glms-container']}>
        <div className={classes['usd-glms-info-container']}>
          <h2 className={classes['usd-value']}>$ {renderUsdValue()}</h2>
          {/* <p className={classes['you-will-get']}>You will get</p>
          <h2 className={classes['glms-text']}>{renderGlmsValue()} GLMS</h2> */}
        </div>

        <div className={classes['glms-options']}>
          {Object.keys(GLMS_OPTIONS).map((glmsOption, i) => {
            return (
              <div
                onClick={() => selectGlmsOptionHandler(glmsOption)}
                key={glmsOption}
                className={`${classes['box-option']} ${
                  glmsOption === selectedGlmsValue &&
                  classes['selected-box-option']
                } `}
              >
                {i === 1 && (
                  <div className={classes['most-popular-container']}>
                    <p>Most Popular</p>
                  </div>
                )}
                {/* <p className={classes['glms-value']}>
                  {parseFloat(glmsOption)}{' '}
                  <span className={classes['glms-option-text']}>GLMS</span>
                </p> */}
                <p className={classes['usd-option-value']}>
                  {GLMS_OPTIONS[glmsOption]} USD
                </p>
              </div>
            );
          })}
        </div>

        <Button
          onClick={buyGlmsHandler}
          style={{ width: '100%', marginTop: 40 }}
          darkpink="true"
          disabled={isBuyGlmsButtonDisabled()}
        >
          Add USD
        </Button>
      </div>
    </Modal>
  );
}

export default BuyGlmsModal;
