import React, { useMemo, useContext, useState } from 'react';
import classes from './EditCreatorServicesModal.module.css';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { getMyServicesPricesRequest } from '../../httpRequests/httpRequests';
import { AuthContext } from '../../context/auth-context';
import notify from '../../utils/notify';
import { useQuery, useQueryClient } from 'react-query';
import EditCreatorServicesPricesModal from '../EditCreatorServicesPricesModal/EditCreatorServicesPricesModal';

const POPULAR_SERVICES = [
  '1/1 Chat (15mins)',
  '1/1 Video Call (15mins)',
  'Personalized Image',
  'Personalized Video',
];

function EditCreatorServicesModal(props) {
  const queryClient = useQueryClient();
  const { profile } = useContext(AuthContext);

  const myServicesPricesResponse = useQuery(
    'my-services-prices',
    getMyServicesPricesRequest
  );

  const myServicesPrices = useMemo(() => {
    if (
      myServicesPricesResponse.isLoading ||
      !myServicesPricesResponse.data?.data
    )
      return {};

    const servicesPrices = {};
    myServicesPricesResponse.data.data.forEach(
      ({ service, regularPrice, communityPrice }) => {
        servicesPrices[service] = {
          regularPrice,
          communityPrice,
        };
      }
    );
    return servicesPrices;
  }, [myServicesPricesResponse]);

  const myExistingServices = useMemo(() => {
    if (!profile?.creatorServices || profile?.creatorServices?.length === 0)
      return {
        custom: [],
        nonCustom: [],
      };

    const existingCustomServices = [];
    const existingNonCustomServices = [];
    profile.creatorServices.forEach((el) => {
      if (POPULAR_SERVICES.includes(el)) {
        existingNonCustomServices.push(el);
      } else {
        existingCustomServices.push(el);
      }
    });

    return {
      custom: existingCustomServices,
      nonCustom: existingNonCustomServices,
    };
  }, [profile]);

  const { setShowEditCreatorServicesModal } = props;
  const [customServiceValue, setCustomServiceValue] = useState('');
  const [customServices, setCustomServices] = useState(
    () => myExistingServices.custom
  );
  const [selectedServices, setSelectedServices] = useState(
    () => myExistingServices.nonCustom
  );
  const [savingServices, setSavingServices] = useState(false);
  const [
    showEditCreatorServicesPricesModal,
    setShowEditCreatorServicesPricesModal,
  ] = useState(false);

  const closeModalHandler = () => {
    setShowEditCreatorServicesModal(false);
  };

  const customServiceChangeValueHandler = (e) => {
    setCustomServiceValue(e.target.value);
  };

  const addServiceHandler = (service) => {
    setSelectedServices((prevState) => [...prevState, service]);
  };

  const removeServiceHandler = (service) => {
    setSelectedServices((prevState) =>
      prevState.filter((el) => el !== service)
    );
  };

  const addCustomServiceHandler = () => {
    if (!customServiceValue) return;

    const isCustomServiceIncluded = customServices.includes(customServiceValue);

    if (isCustomServiceIncluded) return;

    setCustomServices((prevState) => [...prevState, customServiceValue]);
    setCustomServiceValue('');
  };

  const removeCustomServiceHandler = (customService) => {
    setCustomServices((prevState) =>
      prevState.filter((el) => el !== customService)
    );
  };

  const updateCreatorServicesHandler = async () => {
    setSavingServices(true);

    try {
      // const requestBody = {
      //   creatorServices: [...selectedServices, ...customServices],
      // };

      // await updateMyCreatorServicesRequest(requestBody);
      await Promise.all([
        queryClient.invalidateQueries('my-services-prices'),
        queryClient.refetchQueries('my-services-prices'),
      ]);
      // getMyProfile();

      setSavingServices(false);

      setShowEditCreatorServicesPricesModal(true);
    } catch (err) {
      setSavingServices(false);

      notify('error', err, 2000);
    }
  };

  return showEditCreatorServicesPricesModal &&
    !myServicesPricesResponse.isLoading ? (
    <EditCreatorServicesPricesModal
      setShowEditCreatorServicesPricesModal={
        setShowEditCreatorServicesPricesModal
      }
      customServices={customServices}
      nonCustomServices={selectedServices}
      currentServicesPrices={myServicesPrices}
      closeEditCreatorServicesModal={closeModalHandler}
    />
  ) : (
    <Modal
      style={{ width: 450, height: 550 }}
      closeModal={closeModalHandler}
      title="Creator Services"
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={savingServices}
            onClick={closeModalHandler}
            darkpinkcolor="true"
            style={{ flex: 1 }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              savingServices ||
              [...selectedServices, ...customServices].length === 0
            }
            onClick={updateCreatorServicesHandler}
            darkpink="true"
            style={{ width: 300 }}
          >
            Add Pricing Information
          </Button>
        </div>
      }
    >
      <div className={classes['creator-services-container']}>
        <div className={classes['add-custom-new-container']}>
          <p className={classes['add-custom-service-text']}>
            Add Custom Service
          </p>
          <p
            onClick={addCustomServiceHandler}
            className={classes['add-new-text']}
          >
            Add New
          </p>
        </div>

        <Input
          placeholder={'Create a custom service or select one below'}
          style={{ width: '100%', margin: '10px 0' }}
          value={customServiceValue}
          onChange={customServiceChangeValueHandler}
        />

        <div className={classes['popular-services-container']}>
          {POPULAR_SERVICES.map((service, i) => {
            const isServiceIncluded = selectedServices.includes(service);

            return (
              <div
                key={service + i}
                onClick={
                  isServiceIncluded
                    ? () => removeServiceHandler(service)
                    : () => addServiceHandler(service)
                }
                className={
                  isServiceIncluded
                    ? classes['selected-service-container']
                    : classes['service-container']
                }
              >
                <p>{service}</p>
              </div>
            );
          })}

          {customServices.map((customService, i) => {
            return (
              <div
                key={customService + i}
                onClick={() => removeCustomServiceHandler(customService)}
                className={classes['selected-service-container']}
              >
                <p
                  style={{
                    overflowWrap: 'anywhere',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {customService}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

export default EditCreatorServicesModal;
