import axios from 'axios';
import {
  GET_LOGGED_IN_USER_ENDPOINT,
  GET_USER_PROFILE_ENDPOINT,
  LOGIN_ENDPOINT,
  SIGNUP_ENDPOINT,
  UPDATE_PROFILE_ENDPOINT,
  UPDATE_PROFILE_PICTURE_ENDPOINT,
  CREATE_NFT_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  GET_MY_FOLLOWINGS_ENDPOINT,
  GET_MY_FOLLOWERS_ENDPOINT,
  REMOVE_FOLLOWER_ENDPOINT,
  REMOVE_FOLLOWING_ENDPOINT,
  SEARCH_USERS_ENDPOINT,
  GET_MY_PROFILE_ENDPOINT,
  GET_USER_FOLLOWINGS_AND_FOLLOWERS_ENDPOINT,
  REQUEST_FOLLOWING_ENDPOINT,
  GET_NFT_BY_ID_ENDPOINT,
  GET_MY_NFTS_ENDPOINT,
  GET_ALL_CATEGORIES_OF_NFTS_ENDPOINT,
  GET_ALL_SUB_CATEGORIES_OF_NFTS_ENDPOINT,
  ADD_NFT_TO_WATCHLIST,
  GET_MY_WATCHLIST,
  LOCK_NFT_FOR_SALE_ENDPOINT,
  UNLOCK_NFT_FOR_SALE_ENDPOINT,
  GET_NFTS_GROUPED_BY_CATEGORIES_FOR_SALE,
  GET_NFTS_FOR_SALE_FOR_SPECIFIC_CATEGORY_AND_SUBCATEGORY_ENDPOINT,
  GET_NFTS_FOR_SALE_PER_USER,
  GET_NFTS_NOT_FOR_SALE_PER_USER_ENDPOINT,
  MAKE_OFFER_ENDPOINT,
  GET_NFT_OFFERS_ENDPOINT,
  GET_MY_RECEIVED_OFFERS_ENDPOINT,
  GET_MY_LIKES_ENDPOINT,
  LIKE_NFT_ENDPOINT,
  UNLIKE_NFT_ENDPOINT,
  GET_CRYPTOCURRENCY_PRICE_CONVERSION,
  GET_MY_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ_ENDPOINT,
  MARK_ALL_NOTIFICATIONS_AS_READ_ENDPOINT,
  GET_RECENT_SALES_ENDPOINT,
  GET_SIMILAR_NFTS_BY_CATEGORY_AND_SUBCATEGORY_ENDPOINT,
  GET_NEW_NFTS_ENDPOINT,
  GET_NFTS_WITH_OFFERS_ENDPOINT,
  GET_FUTURE_EVENTS_ENDPOINT,
  GET_NFTS_IN_AUCTION_ENDPOINT,
  GET_NEW_EVENTS_ENDPOINT,
  GET_NFTS_FOR_SPECIFIC_EVENT_ENDPOINT,
  GET_EVENT_ENDPOINT,
  GET_GROUPED_EVENTS_BY_LOCATION_ENDPOINT,
  GET_GROUPED_EVENTS_BY_CATEGORY_ENDPOINT,
  GET_MY_CHATS_ENDPOINT,
  GET_NEW_NFTS_ASSOCIATED_WITH_EVENT_ENDPOINT,
  GET_ALL_NFTS_ASSOCIATED_WITH_EVENT_ENDPOINT,
  GET_EVENT_STATISTICS_ENDPOINT,
  GET_TRENDING_EVENTS_ENDPOINT,
  CREATE_GROUP_CHAT_ENDPOINT,
  GET_CHAT_MESSAGES_ENDPOINT,
  CREATE_MESSAGE_ENDPOINT,
  MARK_CHAT_MESSAGES_AS_READ,
  MARK_CHAT_MESSAGE_AS_READ,
  GET_MY_WALLET_ENDPOINT,
  GET_USERS_WITH_MOST_FOLLOWERS,
  GET_OWNER_HISTORY_ENDPOINT,
  LEAVE_CHAT_ENDPOINT,
  EDIT_CHAT_NAME_ENDPOINT,
  GET_EVENT_FOR_SPECIFIC_NFT_ENDPOINT,
  ADD_NEW_CHAT_MEMBERS_ENDPOINT,
  GET_NUMBER_OF_UNREAD_MESSAGES_FOR_MY_CHATS_ENDPOINTS,
  MARK_CHAT_MESSAGE_AS_RECEIVED,
  MARK_ALL_CHAT_MESSAGES_AS_RECEIVED_ENDPOINT,
  GET_ALL_MY_NFTS_FOR_SALE_ENDPOINT,
  GET_MY_TRANSACTIONS_ENDPOINT,
  GET_NUMBER_OF_LIKES_FOR_SPECIFIC_NFT_ENDPOINT,
  GET_NUMBER_OF_WATCHLISTS_FOR_SPECIFIC_NFT_ENDPOINT,
  GET_ALL_MY_NFTS_NOT_FOR_SALE_ENDPOINT,
  CREATE_SINGLE_CHAT_ENDPOINT,
  GET_ALL_USERS_ENDPOINT,
  CREATE_CATEGORY_NFT_ENDPOINT,
  CREATE_SUB_CATEGORY_NFT_ENDPOINT,
  CREATE_EVENT_ENDPOINT,
  GET_USER_NFTS_STATISTICS,
  DEACTIVATE_USER_ENDPOINT,
  ACTIVATE_USER_ENDPOINT,
  DELETE_CHAT_ENDPOINT,
  CHANGE_USER_PASSWORD_ENDPOINT,
  CHANGE_MY_PASSWORD_ENDPOINT,
  GET_USERS_TRANSACTIONS_FOR_SOLD_NFTS_STATISTICS,
  GET_USERS_TRANSACTIONS_FOR_BOUGHT_NFTS_STATISTICS,
  CREATE_USER_WARNING_ENDPOINT,
  GET_ALL_NFTS_ENDPOINT,
  UPDATE_NFT_CONTENT_ENDPOINT,
  GET_USER_TOKEN_VIA_GITHUB_ENDPOINT,
  LOGIN_VIA_GOOGLE_ENDPOINT,
  GET_ALL_EVENTS_ENDPOINT,
  UPDATE_EVENT_ENDPOINT,
  CREATE_REPORT_ENDPOINT,
  GET_ALL_REPORTS_ENDPOINT,
  GET_NUMBER_OF_UNREAD_NOTIFICATIONS_ENDPOINT,
  GET_ALL_CATEGORIES_AND_CORRESPONDING_SUBCATEGORIES_ENDPOINT,
  UPDATE_SUBCATEGORY_ENDPOINT,
  UPDATE_CATEGORY_ENDPOINT,
  UPDATE_REPORT_STATUS_ENDPOINT,
  UPDATE_REPORT_ACTION_TAKEN_DESCRIPTION,
  GET_USER_STATISTICS_ENDPOINT,
  CREATE_NFT_MESSAGE_ENDPOINT,
  GET_NFT_MESSAGES_ENDPOINT,
  GET_USER_REPORTS_ENDPOINT,
  GET_USER_NFTS_AS_OWNER_AND_CREATOR_ENDPOINT,
  SEARCH_USERS_BY_USERNAME_ENDPOINT,
  CREATE_NOTIFICATIONS_TO_MULTIPLE_USERS_ENDPOINT,
  UPDATE_USERNAME_ENDPOINT,
  DELETE_NFT_ENDPOINT,
  UP_VOTE_NFT_ENDPOINT,
  GET_NUMBER_OF_NFT_UPVOTES_ENDPOINT,
  DELETE_NFT_UP_VOTE_ENDPOINT,
  GET_MY_NFT_VOTE_FOR_SPECIFIC_NFT_ENDPOINT,
  SORT_USERS_BY_NUMBER_OF_NFT_UPVOTES,
  SORT_USERS_BY_NUMBER_OF_FOLLOWERS,
  SORT_USERS_BY_NUMBER_OF_NFTS_SOLD_ENDPOINT,
  SORT_USERS_BY_NUMBER_OF_NFTS_BOUGHT_ENDPOINT,
  CREATE_NFT_COMMENT_ENDPOINT,
  GET_NFT_COMMENTS_ENDPOINT,
  GET_WALLET_INFORMATION_ENDPOINT,
  GET_USERS_NUMBER_OF_FOLLOWERS_AND_FOLLOWINGS_ENDPOINT,
  CREATE_NFT_COMMENT_REPLY_ENDPOINT,
  GET_ALL_NFT_COMMENT_REPLIES_ENDPOINT,
  LIKE_NFT_COMMENT_ENDPOINT,
  REMOVE_NFT_COMMENT_LIKE_ENDPOINT,
  GET_NUMBER_OF_LIKES_FOR_SPECIFIC_NFT_COMMENT_ENDPOINT,
  GET_MY_NFT_COMMENT_LIKE_STATUS_ENDPOINT,
  LIKE_NFT_COMMENT_REPLY_ENDPOINT,
  REMOVE_NFT_COMMENT_REPLY_LIKE_ENDPOINT,
  GET_NUMBER_OF_LIKES_FOR_SPECIFIC_NFT_COMMENT_REPLY_ENDPOINT,
  GET_MY_NFT_COMMENT_REPLY_LIKE_STATUS_ENDPOINT,
  GET_MOST_RECENTS_NFTS_FROM_MY_FOLLOWINGS_ENDPOINT,
  CREATE_USER_POST_ENDPOINT,
  GET_POSTS_OF_OTHER_PEOPLE_ENDPOINT,
  LIKE_USER_POST_ENDPOINT,
  REMOVE_USER_POST_LIKE_ENDPOINT,
  GET_NUMBER_OF_LIKES_FOR_SPECIFIC_USER_POST_ENDPOINT,
  CREATE_USER_POST_COMMENT_ENDPOINT,
  GET_ALL_COMMENTS_FOR_SPECIFIC_USER_POST_ENDPOINT,
  GET_NUMBER_OF_COMMENTS_FOR_SPECIFIC_POST_ENDPOINT,
  LIKE_USER_POST_COMMENT_ENDPOINT,
  GET_NUMBER_OF_LIKES_FOR_SPECIFIC_USER_POST_COMMENT,
  REMOVE_USER_POST_COMMENT_LIKE_ENDPOINT,
  GET_MY_USER_POST_COMMENT_LIKE_STATUS_ENDPOINT,
  CREATE_USER_POST_COMMENT_REPLY_ENDPOINT,
  GET_ALL_USER_POST_COMMENT_REPLIES_ENDPOINT,
  GET_NUMBER_OF_LIKES_FOR_SPECIFIC_USER_POST_COMMENT_REPLY,
  GET_MY_POST_COMMENT_REPLY_LIKE_STATUS_ENDPOINT,
  LIKE_USER_POST_COMMENT_REPLY_ENDPOINT,
  REMOVE_USER_POST_COMMENT_REPLY_LIKE_ENDPOINT,
  GET_POST_ENDPOINT,
  VERIFY_AND_CONFIRM_TRANSFER_GLMS_ENDPOINT,
  CREATE_TRANSFER_GLMS_ENDPOINT,
  LIST_NFT_FOR_BUY_NOW_ENDPOINT,
  GET_NFTS_LISTED_FOR_BUY_NOW_ENDPOINT,
  UPDATE_NFT_BUY_NOW_PRICE_ENDPOINT,
  GET_MY_POSTS_ENDPOINT,
  BUY_NOW_NFT_ENDPOINT,
  UPLOAD_COVER_PHOTO_ENDPOINT,
  GET_MY_PAGINATED_FOLLOWINGS_ENDPOINT,
  GET_MY_PAGINATED_FOLLOWERS_ENDPOINT,
  GET_MY_PAGINATED_FRIENDS_ENDPOINT,
  MARK_FRIEND_AS_FAVORITE_ENDPOINT,
  REMOVE_FRIEND_FROM_FAVORITES_ENDPOINT,
  GET_FAVORITE_FRIEND_STATUS_FOR_SPECIFIC_FRIEND_ENDPOINT,
  EDIT_ABOUT_INFO_ENDPOINT,
  GET_MY_PAGINATED_FAVORITE_FRIENDS_ENDPOINT,
  CREATE_USER_ALBUM_ENDPOINT,
  GET_MY_USER_ALBUMS_ENDPOINT,
  UPLOAD_ALBUM_PHOTO_ENDPOINT,
  DELETE_SPECIFIC_IMAGE_FROM_ALBUM_ENDPOINT,
  GET_POSTS_FOR_SPECIFIC_USER_ENDPOINT,
  GET_USER_PAGINATED_FOLLOWERS,
  GET_USER_PAGINATED_FOLLOWINGS,
  GET_USER_PAGINATED_FRIENDS,
  GET_USER_ALBUMS_FOR_SPECIFIC_USER_ENDPOINT,
  UPLOAD_USER_POST_VIDEO_ENDPOINT,
  CREATE_USER_LIFE_EVENT_ENDPOINT,
  UPLOAD_USER_LIFE_EVENT_VIDEO_ENDPOINT,
  GET_MY_LIFE_EVENTS_ENDPOINT,
  GET_USER_LIFE_USER_EVENTS_FOR_SPECIFIC_USER_ENDPOINT,
  DELETE_MY_POST_COMMENT_ENDPOINT,
  UPDATE_MY_POST_COMMENT_ENDPOINT,
  UPDATE_NFT_COMMENT_ENDPOINT,
  DELETE_NFT_COMMENT_ENDPOINT,
  CREATE_USER_DEDICATED_POST_ENDPOINT,
  DELETE_POST_ENDPOINT,
  ADD_POST_TO_RECYCLE_BIN_ENDPOINT,
  DELETE_USER_DEDICATED_POST_ENDPOINT,
  GET_USER_DEDICATED_POST_ENDPOINT,
  GET_MOST_RECENT_SALES_ENDPOINT,
  GET_USERS_WITH_MOST_RECENT_ACTIVITY_ENDPOINT,
  GET_TRENDING_CATEGORIES_ENDPOINT,
  GET_RECENTLY_ADDED_CATEGORIES_ENDPOINT,
  GET_RECENTLY_ADDED_NFTS_ENDPOINT,
  EDIT_INTRODUCTION_ENDPOINT,
  EDIT_EDUCATION_ENDPOINT,
  GET_MY_RECENTLY_ADDED_NFTS_ENDPOINT,
  GET_USER_RECENTLY_ADDED_NFTS_ENDPOINT,
  UPLOAD_USER_DEDICATED_POST_VIDEO_ENDPOINT,
  UPDATE_CHAT_MEMBERS_ENDPOINT,
  REMOVE_MEMBER_FROM_CHAT_ENDPOINT,
  UPLOAD_PHOTO_ENDPOINT,
  GET_MY_UPLOADED_PHOTOS_ENDPOINT,
  DELETE_PHOTO_ENDPOINT,
  GET_USER_UPLOADED_PHOTOS_ENDPOINT,
  VERIFY_USER_ENDPOINT,
  UNVERIFY_USER_ENDPOINT,
  UPDATE_NFT_PREVIEW_URL_ENDPOINT,
  GET_USERS_WHO_HAVE_LIKED_A_SPECIFIC_USER_POST_ENDPOINT,
  GET_MY_FRIENDS_ENDPOINT,
  CREATE_GROUP_ENDPOINT,
  GET_MY_GROUPS_ENDPOINT,
  DELETE_ALBUM_ENDPOINT,
  GET_ALL_GROUP_MEMBERS_FOR_A_SPECIFIC_GROUP,
  UPLOAD_GROUP_COVER_PHOTO_ENDPOINT,
  GET_SPECIFIC_GROUP_ENDPOINT,
  GET_NUMBER_OF_MEMBERS_FOR_A_SPECIFIC_GROUP_ENDPOINT,
  GET_GROUP_ADMINS_ENDPOINT,
  INVITE_USERS_TO_GROUP_ENDPOINT,
  REACT_TO_GROUP_INVITATION_ENDPOINT,
  GET_PAGINATED_UNION_OF_MY_FOLLOWERS_AND_FOLLOWINGS_ENDPOINT,
  GET_MEMBER_STATUS_IN_A_SPECIFIC_GROUP_ENDPOINT,
  CREATE_USER_GROUP_POST_ENDPOINT,
  GET_USER_GROUP_POSTS_ENDPOINT,
  GET_FRIEND_SUGGESTIONS_ENDPOINT,
  GET_PAGINATED_MUTUAL_FOLLOWERS_ENDPOINT,
  GET_NUMBER_OF_MUTUAL_FOLLOWERS_ENDPOINT,
  GET_GROUP_POST_TOPICS_ENDPOINT,
  GET_GROUP_POST_TOPICS_SORTED_BY_NUMBER_OF_POSTS_ENDPOINT,
  GET_GROUP_MEMBERS_EXCEPT_ADMINS_ENDPOINT,
  GET_USER_GROUP_POST_ENDPOINT,
  GET_ALL_GROUP_CHANNELS_FOR_SPECIFIC_GROUP_ENDPOINT,
  GET_ALL_GROUP_MESSAGES_FOR_SPECIFIC_CHANNEL_ENDPOINT,
  CREATE_GROUP_MESSAGE_ENDPOINT,
  CREATE_GROUP_CHANNEL_ENDPOINT,
  INVITE_USERS_TO_GROUP_CHANNEL_ENDPOINT,
  REACT_TO_GROUP_CHANNEL_INVITATION_ENDPOINT,
  CREATE_PRIVATE_GROUP_CHANNEL_ENDPOINT,
  GET_ALL_MEMBERS_FOR_A_SPECIFIC_PRIVATE_GROUP_CHANNEL_ENDPOINT,
  GET_MY_GROUPS_AS_ADMIN_ENDPOINT,
  GET_MY_GROUPS_I_HAVE_JOINED_ENDPOINT,
  GET_GROUP_SUGGESTIONS_ENDPOINT,
  JOIN_PUBLIC_GROUP_ENDPOINT,
  REQUEST_GROUP_MEMBERSHIP_ENDPOINT,
  GET_MY_GROUPS_RECENT_ACTIVITY_ENDPOINT,
  GET_MEMBER_ROLE_IN_A_SPECIFIC_GROUP_ENDPOINT,
  GET_ALL_REQUESTED_GROUP_MEMBERSHIPS_ENDPOINT,
  ACCEPT_REQUESTED_GROUP_MEMBERSHIPS_ENDPOINT,
  REJECT_REQUESTED_GROUP_MEMBERSHIP_ENDPOINT,
  LEAVE_GROUP_ENDPOINT,
  GET_UNION_OF_MY_FOLLOWERS_AND_FOLLOWINGS_ENDPOINT,
  GET_ALL_NON_PAGINATED_GROUP_MEMBERS_FOR_A_SPECIFIC_GROUP_ENDPOINT,
  UPDATE_GROUP_CHANNEL_NAME_ENDPOINT,
  DELETE_GROUP_CHANNEL_ENDPOINT,
  MARK_USER_AS_GROUP_ADMIN_ENDPOINT,
  REMOVE_GROUP_ADMIN_ENDPOINT,
  REMOVE_GROUP_MEMBER_ENDPOINT,
  DELETE_USER_POST_COMMENT_REPLY_ENDPOINT,
  UPDATE_MY_GROUP_ENDPOINT,
  CREATE_NFT_COLLECTION_ENDPOINT,
  CREATE_NFT_COLLECTION_MAPPING_ENDPOINT,
  GET_ALL_NFT_COLLECTIONS_ENDPOINT,
  GET_ALL_NFTS_FOR_SPECIFIC_COLLECTION_ENDPOINT,
  GET_SPECIFIC_NFT_COLLECTION_ENDPOINT,
  GET_NFT_COLLECTION_STATISTICS_ENDPOINT,
  GET_MY_NFTS_NOT_PART_OF_A_COLLECTION_ENDPOINT,
  ADD_NFT_TO_COLLECTION_ENDPOINT,
  UPDATE_NFT_COLLECTION_ENDPOINT,
  GET_NFT_COLLECTION_ACTIVITY_ENDPOINT,
  GET_MY_WATCHLIST_STATUS_FOR_SPECIFIC_NFT_COLLECTION_ENDPOINT,
  ADD_NFT_COLLECTION_TO_WATCHLIST_ENDPOINT,
  REMOVE_NFT_COLLECTION_FROM_WATCHLIST_ENDPOINT,
  INVITE_NFT_COLLECTION_COLLABORATOR_ENDPOINT,
  GET_AVAILABLE_USERS_TO_INVITE_AS_NFT_COLLECTION_COLLABORATOR_ENDPOINT,
  REACT_TO_NFT_COLLECTION_COLLABORATION_INVITATION_ENDPOINT,
  REMOVE_NFT_COLLECTION_COLLABORATOR_ENDPOINT,
  ADD_NFT_COLLECTION_TO_A_SPECIFIC_GROUP_ENDPOINT,
  REMOVE_NFT_COLLECTION_FROM_A_SPECIFIC_GROUP_ENDPOINT,
  GET_ALL_NFT_COLLECTIONS_ASSOCIATED_WITH_A_SPECIFIC_GROUP_ENDPOINT,
  UPDATE_NFT_ENDPOINT,
  GET_MY_NFT_COLLECTION_WATCHLIST_ENDPOINT,
  GET_AVAILABLE_USERS_TO_INVITE_PRIVATE_GROUP_CHANNEL_ENDPOINT,
  GET_ALL_NON_PAGINATED_AVAILABLE_USERS_TO_INVITE_PRIVATE_GROUP_CHANNEL_ENDPOINT,
  REMOVE_PRIVATE_GROUP_CHANNEL_MEMBER_ENDPOINT,
  IS_NFT_ACCESSIBLE_TO_BUY_BY_ME_ENDPOINT,
  SEARCH_COLLECTIONS_GROUPS_NFTS_ENDPOINT,
  UPDATE_NFT_COLLECTION_STATUS_ENDPOINT,
  GET_ALL_PAGINATED_USER_ACTIVITY_TOPICS_ENDPOINT,
  GET_USER_ACTIVITY_TRENDING_TOPICS_ENDPOINT,
  CREATE_GROUP_POST_TOPIC_ENDPOINT,
  GET_USER_POSTS_FOR_SPECIFIC_TOPIC_ENDPOINT,
  GET_USER_ACTIVITY_TRENDING_POST_TOPICS_ENDPOINT,
  BLOCK_USER_ENDPOINT,
  GET_USER_BLOCK_STATUS_WITH_A_SPECIFIC_USER_ENDPOINT,
  UNBLOCK_USER_ENDPOINT,
  LOGIN_VIA_FACEBOOK_ENDPOINT,
  GET_LINK_PREVIEW_URL_ENDPOINT,
  MAKE_NFT_OFFER_ENDPOINT,
  ACCEPT_NFT_OFFER_ENDPOINT,
  GET_NFT_OFFERS_FOR_SPECIFIC_NFT_ENDPOINT,
  REJECT_NFT_OFFER_ENDPOINT,
  GET_ALL_NON_PAGINATED_USERS_ENDPOINT,
  GET_MY_REQUESTED_CHATS_ENDPOINT,
  ACCEPT_CHAT_REQUEST_ENDPOINT,
  REJECT_CHAT_REQUEST_ENDPOINT,
  GET_NUMBER_OF_MY_REQUESTED_CHATS_ENDPOINT,
  UPDATE_MY_POST_ENDPOINT,
  UPDATE_MY_GROUP_POST_ENDPOINT,
  GET_MY_RECEIVED_NFT_OFFERS_ENDPOINT,
  GET_MY_SENT_NFT_OFFERS_ENDPOINT,
  DELETE_MY_NFT_OFFER_ENDPOINT,
  UPDATE_MY_NFT_OFFER_ENDPOINT,
  CREATE_NFT_REQUEST_ENDPOINT,
  GET_MY_PENDING_NFT_REQUESTS,
  REJECT_NFT_REQUEST_ENDPOINT,
  UPLOAD_REQUESTED_NFT_ENDPOINT,
  GET_MY_REQUESTED_NFTS,
  GET_REQUESTED_NFTS_FROM_ME,
  UPDATE_MY_REQUEST_NFT_PRICE,
  GET_MY_LOCKED_GLMS_BALANCE_ENDPOINT,
  UPDATE_MY_SOCIAL_LINKS_ENDPOINT,
  MARK_ALL_GROUP_CHANNEL_MESSAGES_AS_READ_ENDPOINT,
  MARK_GROUP_MESSAGE_AS_READ_ENDPOINT,
  GET_NUMBER_OF_UNREAD_MESSAGES_FOR_MY_GROUPS_ENDPOINT,
  GET_NUMBER_OF_UNREAD_MESSAGES_FOR_SPECIFIC_GROUP_CHANNEL_ENDPOINT,
  GET_MY_REFERRALS_NETWORK_ENDPOINT,
  GET_MY_DIRECT_REFERRALS_ENDPOINT,
  REQUEST_EMAIL_CHANGE_ENDPOINT,
  CHANGE_MY_EMAIL_ENDPOINT,
  GET_LATEST_CRYPTO_NEWS_ENDPOINT,
  GET_NUMBER_OF_UNREAD_MESSAGES_FOR_SPECIFIC_GROUP_ENDPOINT,
  GET_MY_FAVORITE_CHATS_ENDPOINT,
  MARK_CHAT_AS_FAVORITE_ENDPOINT,
  REMOVE_CHAT_AS_FAVORITE_ENDPOINT,
  GET_FAVORITE_STATUS_FOR_SPECIFIC_CHAT_ENDPOINT,
  MARK_GROUP_AS_FAVORITE_ENDPOINT,
  REMOVE_GROUP_AS_FAVORITE_ENDPOINT,
  GET_UNIQUE_OWNERS_FOR_A_SPECIFIC_NFT_COLLECTION_ENDPOINT,
  REQUEST_BETA_ACCESS_ENDPOINT,
  APPLY_AS_CREATOR_ENDPOINT,
  GET_ALL_BETA_ACCESS_REQUESTS_ENDPOINT,
  GET_ALL_CREATOR_APPLIES_ENDPOINT,
  CREATE_GROUP_POLL_ENDPOINT,
  GET_ALL_POLLS_FOR_A_SPECIFIC_GROUP_ENDPOINT,
  CREATE_GROUP_POLL_VOTE_ENDPOINT,
  GET_VOTES_FOR_A_SPECIFIC_POLL_ENDPOINT,
  GET_VOTES_INFORMATION_FOR_A_SPECIFIC_GROUP_POLL_ENDPOINT,
  GET_USERS_WHO_VOTED_FOR_A_SPECIFIC_GROUP_POLL_ANSWER_ENDPOINT,
  CREATE_GROUP_POLL_ANSWER_ENDPOINT,
  HAS_USER_ACCESS_TO_CREATE_A_GROUP_POLL_ANSWER_ENDPOINT,
  UPDATE_DEDICATED_POST_ENDPOINT,
  REQUEST_PHONE_NUMBER_VERIFICATION_CODE_ENDPOINT,
  VERIFY_PHONE_NUMBER_ENDPOINT,
  UPDATE_NFT_COLLECTION_HOLDER_BENEFITS_ENDPOINT,
  GET_MUX_SIGNED_JWT_ENDPOINT,
  VERIFY_USER_EMAIL_ENDPOINT,
  MAP_FULLNAME_TO_PARTICIPANT_ID_ENDPOINT,
  REMOVE_PARTICIPANT_ID_FROM_MAPPING_ENDPOINT,
  GET_ALL_PARTICIPANTS_ENDPOINT,
  CREATE_STRIPE_CHECKOUT_ENDPOINT,
  GET_ALL_NFTS_FOR_A_SPECIFIC_METAMASK_WALLET_ADDRESS_ENDPOINT,
  UPDATE_MY_METAMASK_WALLET_ADDRESSES_ENDPOINT,
  UPDATE_MY_METAMASK_NFTS_VIEW_STATUS_ENDPOINT,
  GET_METAMASK_NFTS_FOR_A_SPECIFIC_USER_ENDPOINT,
  SIGN_METAMASK_PERSONAL_SIGNATURE_ENDPOINT,
  SIGNUP_WITH_METAMASK_ENDPOINT,
  LOGIN_WITH_METAMASK_ENDPOINT,
  CREATE_TRANSFER_OF_NFT_ENDPOINT,
  VERIFY_AND_CONFIRM_TRANSFER_OF_NFT_ENDPOINT,
  CREATE_NFT_PART_OF_FUNGIBLE_AGNOSTIC_COLLECTION_ENDPOINT,
  CLAIM_NFT_ENDPOINT,
  GET_MY_CLAIMABLE_ACCESS_FOR_A_SPECIFIC_NFT_ENDPOINT,
  DELETE_MY_NFT_COLLECTION_ENDPOINT,
  GET_CLAIMABLE_NFTS_FOR_A_SPECIFIC_COLLECTION_ENDPOINT,
  GET_NFT_METADATA_FOR_A_SPECIFIC_FUNGIBILITY_AGNOSTIC_NFT_COLLECTION_ENDPOINT,
  CLAIM_RANDOM_NFT_FROM_A_CLAIMABLE_COLLECTION_ENDPOINT,
  UPLOAD_EVENT_PHOTOS_ENDPOINT,
  NOTIFY_CLAIM_NFT_LINK_TO_MY_FOLLOWERS_ENDPOINT,
  NOTIFY_COLLECTION_CREATED_TO_MY_FOLLOWERS_ENDPOINT,
  DELETE_EVENT_ENDPOINT,
  CREATE_PLAID_LINK_TOKEN_ENDPOINT,
  EXCHANGE_PLAID_PUBLIC_TOKEN_ENDPOINT,
  MAP_USERS_WITH_WYRE_USERS_ENDPOINT,
  GET_WYRE_WALLET_INFORMATION_ENDPOINT,
  CREATE_WYRE_TRANSFER_ENDPOINT,
  BUY_NFT_WITH_WYRE_WALLET_ENDPOINT,
  GET_ALL_WYRE_PAYMENTS_ENDPOINT,
  GET_ALL_WYRE_TRANSFERS_ENDPOINT,
  DISCONNECT_BANK_ACCOUNT_ENDPOINT,
  WITHDRAW_TO_BANK_ACCOUNT_ENDPOINT,
  GET_WYRE_KYC_ONBOARDING_URL_ENDPOINT,
  VERIFY_USER_AS_INSTITUTION_ENDPOINT,
  UNVERIFY_USER_AS_INSTITUTION_ENDPOINT,
  GET_WYRE_KYC_STATUS_ENDPOINT,
  GET_MY_NFT_COLLECTIONS_ENDPOINT,
  GET_NFT_COLLECTIONS_FOR_A_SPECIFIC_USER_ENDPOINT,
  GET_GROUPS_FOR_A_SPECIFIC_USER_ENDPOINT,
  GET_MY_NFTS_NOT_PART_OF_A_COLLECTION_AND_COLLABORATOR_REQUEST_STATUS_ENDPOINT,
  REQUEST_CREATOR_TO_ADD_MY_NFT_AS_COLLABORATOR_ENDPOINT,
  GET_MY_REQUESTED_NFTS_FROM_COLLABORATORS_ENDPOINT,
  REJECT_REQUESTED_NFT_FROM_COLLABORATOR_ENDPOINT,
  ACCEPT_REQUESTED_NFT_FROM_COLLABORATOR_ENDPOINT,
  GET_MY_REQUESTED_NFTS_AS_COLLABORATOR_ENDPOINT,
  DELETE_MY_NFT_REQUEST_AS_COLLABORATOR_ENDPOINT,
  REQUEST_CREATOR_TO_UPLOAD_MY_NFTS_AS_COLLABORATOR_ENDPOINT,
  GET_MY_REQUESTED_NFT_UPLOADS_FROM_COLLABORATORS_ENDPOINT,
  REJECT_REQUESTED_NFT_UPLOAD_FROM_COLLABORATOR_ENDPOINT,
  ACCEPT_REQUESTED_NFT_UPLOAD_FROM_COLLABORATOR_ENDPOINT,
  GET_MY_REQUESTED_NFT_UPLOADS_AS_COLLABORATOR_ENDPOINT,
  DELETE_MY_NFT_REQUEST_UPLOAD_AS_COLLABORATOR_ENDPOINT,
  SIGNUP_WITH_UNSTOPPABLE_DOMAINS_ENDPOINT,
  LOGIN_WITH_UNSTOPPABLE_ENDPOINT,
  DEPOSIT_TO_MY_CONNECTED_BANK_ACCOUNT_ENDPOINT,
  GET_USER_ADMINS_ENDPOINT,
  DEPOSIT_TO_MY_CONNECTED_BANK_ACCOUNT_VIA_PLAID_ENDPOINT,
  CHECK_FOR_WINNING_POINTS_AFTER_AUTO_LOGIN_ENDPOINT,
  MARK_ADMIN_AS_USER_ENDPOINT,
  GET_MOST_RECENT_NFTS_FROM_MY_UNIQUE_FOLLOWINGS_ENDPOINT,
  GET_VERIFIED_USERS_ENDPOINT,
  GET_VERIFIED_INSTITUTIONS_ENDPOINT,
  MARK_USER_AS_ADMIN_ENDPOINT,
  PUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_A_SPECIFIC_USER_ENDPOINT,
  UNPUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_A_SPECIFIC_USER_ENDPOINT,
  CREATE_TRANSFER_GLMS_TO_A_SPECIFIC_USER_ENDPOINT,
  PUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_EVERY_USER_ENDPOINT,
  UNPUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_EVERY_USER_ENDPOINT,
  GET_ALL_USERS_ALLOWED_TO_PUBLISH_NFTS_OR_COLLECTION_CONTENT_ENDPOINT,
  GET_ALL_GROUPS_ENDPOINT,
  MAP_WYRE_USER_ID_AND_WALLET_ENDPOINT,
  HIDE_GROUP_FROM_SUGGESTIONS_ENDPOINT,
  SHOW_GROUP_IN_SUGGESTIONS_ENDPOINT,
  GET_ALL_GROUPS_NOT_HIDDEN_FROM_SUGGESTIONS_ENDPOINT,
  HIDE_ALL_GROUPS_FROM_SUGGESTIONS_ENDPOINT,
  SHOW_ALL_GROUPS_IN_SUGGESTIONS_ENDPOINT,
  UPDATE_MY_WYRE_ACCOUNT_FIELDS_ENDPOINT,
  CREATE_STRIPE_PAYMENT_ACCOUNT_LINK_ENDPOINT,
  CREATE_STRIPE_PAYMENT_CHECKOUT_SESSION_ENDPOINT,
  GIVE_USER_ACCESS_TO_CREATE_STRIPE_ACCOUNT_ENDPOINT,
  REMOVE_USER_ACCESS_TO_CREATE_STRIPE_ACCOUNT_ENDPOINT,
  GET_MY_STRIPE_ACCOUNT_DETAILS_ENDPOINT,
  GET_ALL_USERS_WHO_HAVE_ACCESS_TO_CREATE_STRIPE_ACCOUNT_ENDPOINT,
  GET_ALL_STRIPE_PAYMENTS_ENDPOINT,
  UPDATE_MY_NOT_LISTED_NFTS_ACCESS_STATUS_ENDPOINT,
  UPDATE_WHO_CAN_POST_IN_A_SPECIFIC_GROUP_ENDPOINT,
  UPDATE_WHO_CAN_WRITE_IN_A_SPECIFIC_GROUP_CHANNEL_ENDPOINT,
  GET_SPECIFIC_GROUP_CHANNEL_ENDPOINT,
  DO_I_HAVE_ACCESS_TO_CREATE_NFT_COLLECTION_OR_COMMUNITY_ENDPOINT,
  GET_ALL_USERS_GROUPED_BY_UNVERIFIED_EMAILS_ENDPOINT,
  UPDATE_LEVEL_2_VERIFICATION_FOR_A_SPECIFIC_USER_ENDPOINT,
  GET_ALL_LEVEL_2_VERIFIED_USERS_ENDPOINT,
  GET_ALL_USERS_UNDER_SUSPICION_ENDPOINT,
  GET_ALL_USERS_REGISTERED_VIA_SPECIFIC_SIGNUP_TYPE_ENDPOINT,
  DELETE_USER_ACCOUNT_ENDPOINT,
  GET_TOP_CREATORS_ENDPOINT,
  GET_TOP_NFTS_FOR_SPECIFIC_COLLECTION_ENDPOINT,
  GET_GROUP_FAVORITE_STATUS_ENDPOINT,
  CREATE_BUY_GLMS_STRIPE_CHECKOUT_SESSION_ENDPOINT,
  GET_ALL_GLMS_STRIPE_BUY_TRANSACTIONS_ENDPOINT,
  CREATE_BUY_POINTS_STRIPE_CHECKOUT_SESSION_ENDPOINT,
  GET_ALL_POINTS_STRIPE_BUY_TRANSACTIONS_ENDPOINT,
  CREATE_POST_TIP_ENDPOINT,
  GET_TIP_STATUS_FOR_SPECIFIC_POST_ENDPOINT,
  GET_POSTS_OF_MY_NFT_COLLECTOR_HOLDERS_ENDPOINT,
  GET_MY_POINTS_TRANSACTIONS_ENDPOINT,
  GET_MY_GLMS_TRANSACTIONS_ENDPOINT,
  GET_GET_STARTED_TASKS_COMPLETION_INFO_ENDPOINT,
  GET_GET_STARTED_TASK_FOLLOW_PEOPLE_SUGGESTIONS_ENDPOINT,
  CLAIM_GET_STARTED_TASK_ENDPOINT,
  GET_MY_CLAIMED_GET_STARTED_TASKS_ENDPOINT,
  GET_UNLOCKABLE_CONTENT_FOR_SPECIFIC_NFT_ENDPOINT,
  GET_UNLOCKABLE_CONTENT_STATUS_FOR_SPECIFIC_NFT_ENDPOINT,
  GET_STRIPE_ACCOUNT_STATUS_FOR_SPECIFIC_USER_ENDPOINT,
  UPLOAD_AUDIO_FOR_SPECIFIC_NFT_ENDPOINT,
  CREATE_NFT_POST_ENDPOINT,
  GET_ALL_NFT_POSTS_ENDPOINT,
  CREATE_NFT_POST_TIP_ENDPOINT,
  GET_TIP_STATUS_FOR_SPECIFIC_NFT_POST_ENDPOINT,
  UPDATE_MY_CREATOR_SERVICES_ENDPOINT,
  UPDATE_GROUP_BENEFITS_ENDPOINT,
  CREATE_USER_SERVICE_PRICE_ENDPOINT,
  GET_MY_SERVICES_PRICES_ENDPOINT,
  GET_MY_GROUPS_AS_ORGANIZER_ENDPOINT,
  CREATE_USER_SERVICE_REQUEST_STRIPE_CHECKOUT_SESSION_ENDPOINT,
  GET_REQUESTED_SERVICES_FOR_ME_ENDPOINT,
  GET_REQUESTED_SERVICES_FROM_ME_ENDPOINT,
  GET_SPECIFIC_REQUESTED_SERVICE_FOR_ME_ENDPOINT,
  UPLOAD_REQUESTED_SERVICE_ENDPOINT,
  GET_SPECIFIC_USER_SERVICE_PRICE_ENDPOINT,
  CREATE_USER_BY_ADMIN_ENDPOINT,
  CHANGE_PASSWORD_FOR_USER_CREATED_BY_ADMIN_ENDPOINT,
  CREATE_BRAND_ITEM_ENDPOINT,
  GET_ALL_BRAND_ITEMS_LISTED_FOR_SALE_ENDPOINT,
  GET_BRAND_ITEM_ENDPOINT,
  UNLIST_BRAND_ITEM_FOR_SALE_ENDPOINT,
  LIST_BRAND_ITEM_FOR_SALE_ENDPOINT,
  UPDATE_BRAND_ITEM_PRICE_ENDPOINT,
  CREATE_BRAND_ITEM_CHECKOUT_SESSION_ENDPOINT,
  GET_MY_ACCESSIBILITY_INFORMATION_ENDPOINT,
  CREATE_PAYABLE_POST_ENDPOINT,
  GET_PAYABLE_POSTS_OF_OTHERS_PEOPLE_ENDPOINT,
  CREATE_POST_PAYMENT_ENDPOINT,
} from '../constants/API_ENDPOINTS';

const handleError = (error) => {
  if (error.response?.status === 401) {
    setTimeout(() => {
      window.location.href = '/login';
    }, 2000);
    return 'You are not logged in. Please log in to perform this action!';
  }

  return error.response.data.message;
};

export const signupRequest = async (requestBody, referredBy) => {
  try {
    const signUpEndpoint = referredBy
      ? `${SIGNUP_ENDPOINT}?referredBy=${referredBy}`
      : SIGNUP_ENDPOINT;

    const { data } = await axios.post(signUpEndpoint, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const loginRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(LOGIN_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getLoggedInUserRequest = async () => {
  try {
    const { data } = await axios.get(GET_LOGGED_IN_USER_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyProfileRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_PROFILE_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserProfileRequest = async (userId) => {
  try {
    const { data } = await axios.get(`${GET_USER_PROFILE_ENDPOINT}/${userId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const searchUsersRequest = async (searchQuery) => {
  try {
    const { data } = await axios.get(
      `${SEARCH_USERS_ENDPOINT}/search?searchQuery=${searchQuery}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const searchUsersByUsernameRequest = async (searchQuery) => {
  try {
    const { data } = await axios.get(
      `${SEARCH_USERS_BY_USERNAME_ENDPOINT}?searchQuery=${searchQuery}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyFollowingsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_FOLLOWINGS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyPaginatedFollowingsRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_PAGINATED_FOLLOWINGS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyFollowersRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_FOLLOWERS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};
export const getMyPaginatedFollowersRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_PAGINATED_FOLLOWERS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeFollowerRequest = async (followerId) => {
  try {
    const { data } = await axios.patch(
      `${REMOVE_FOLLOWER_ENDPOINT}/${followerId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const unfollowUserRequest = async (followingId) => {
  try {
    const { data } = await axios.patch(
      `${REMOVE_FOLLOWING_ENDPOINT}/${followingId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserFollowingsAndFollowersRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_FOLLOWINGS_AND_FOLLOWERS_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestFollowingRequest = async (userId) => {
  try {
    const { data } = await axios.post(
      `${REQUEST_FOLLOWING_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyPaginatedFriendsRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_PAGINATED_FRIENDS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyNftsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_NFTS_ENDPOINT);
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getAllMyNftsForSaleRequest = async (page, searchValue = '') => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_MY_NFTS_FOR_SALE_ENDPOINT}?page=${page}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getAllMyNftsNotForSaleRequest = async (page, searchValue = '') => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_MY_NFTS_NOT_FOR_SALE_ENDPOINT}?page=${page}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getNftByIdRequest = async (id) => {
  try {
    const { data } = await axios.get(GET_NFT_BY_ID_ENDPOINT + `/${id}`);
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const updateProfileRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(UPDATE_PROFILE_ENDPOINT, requestBody);

    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateProfilePictureRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      UPDATE_PROFILE_PICTURE_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const forgotPasswordRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(FORGOT_PASSWORD_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const resetPasswordRequest = async (requestBody, resetToken) => {
  try {
    const { data } = await axios.patch(
      `${RESET_PASSWORD_ENDPOINT}/${resetToken}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNFTRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(CREATE_NFT_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllCategoriesOfNftsRequest = async () => {
  try {
    const { data } = await axios.get(GET_ALL_CATEGORIES_OF_NFTS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllSubCategoriesOfNftsRequest = async (categoryId) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_SUB_CATEGORIES_OF_NFTS_ENDPOINT}/${categoryId}/subCategory`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyWatchlistRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_WATCHLIST);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const addNftToWatchlistRequest = async (nftId) => {
  try {
    const { data } = await axios.post(`${ADD_NFT_TO_WATCHLIST}/${nftId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeNftFromWatchlistRequest = async (nftId) => {
  try {
    const { data } = await axios.delete(`${ADD_NFT_TO_WATCHLIST}/${nftId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const lockNftForSaleRequest = async (nftId) => {
  try {
    const { data } = await axios.patch(
      `${LOCK_NFT_FOR_SALE_ENDPOINT}/${nftId}/lockForSale`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const unlockNftForSaleRequest = async (nftId, requestBody = {}) => {
  try {
    const { data } = await axios.patch(
      `${UNLOCK_NFT_FOR_SALE_ENDPOINT}/${nftId}/unlockForSale`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftsGroupedByCategoriesForSaleRequest = async () => {
  try {
    const { data } = await axios.get(GET_NFTS_GROUPED_BY_CATEGORIES_FOR_SALE);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftsOnSalePerUserRequest = async (
  userId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_NFTS_FOR_SALE_PER_USER}/${userId}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftsNotForSaleRequest = async (
  userId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_NFTS_NOT_FOR_SALE_PER_USER_ENDPOINT}/${userId}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const makeOfferRequest = async (requestBody, nftId) => {
  try {
    const { data } = await axios.post(
      `${MAKE_OFFER_ENDPOINT}/${nftId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftOffersRequest = async (nftId) => {
  try {
    const { data } = await axios.get(`${GET_NFT_OFFERS_ENDPOINT}/${nftId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyOffersRequest = async () => {
  try {
    const { data } = await axios.get(`${GET_NFT_OFFERS_ENDPOINT}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyReceivedOffersRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_RECEIVED_OFFERS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyLikesRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_LIKES_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const likeNftRequest = async (nftId) => {
  try {
    const { data } = await axios.post(`${LIKE_NFT_ENDPOINT}/${nftId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const unlikeNftRequest = async (nftId) => {
  try {
    const { data } = await axios.delete(`${UNLIKE_NFT_ENDPOINT}/${nftId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getCryptoCurrencyPriceConversionRequest = async (
  requestBody = {}
) => {
  try {
    const { data } = await axios.get(
      GET_CRYPTOCURRENCY_PRICE_CONVERSION,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyNotificationsRequest = async (page, limit) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_NOTIFICATIONS}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markNotificationAsReadRequest = async (notificationId) => {
  try {
    const { data } = await axios.patch(
      `${MARK_NOTIFICATION_AS_READ_ENDPOINT}/${notificationId}/mark-as-read`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markAllNotificationsAsReadRequest = async () => {
  try {
    const { data } = await axios.patch(MARK_ALL_NOTIFICATIONS_AS_READ_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRecentSalesRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_RECENT_SALES_ENDPOINT}/${userId}/recent-sales`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSimilarNftsByCategoryAndSubCategoryRequest = async (
  category,
  subCategory
) => {
  try {
    const { data } = await axios.get(
      `${GET_SIMILAR_NFTS_BY_CATEGORY_AND_SUBCATEGORY_ENDPOINT}?category=${category}&subCategory=${subCategory}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNewNftsRequest = async (
  page,
  sortByFilter,
  searchValue,
  isSafeContentSelected
) => {
  try {
    const { data } = await axios.get(
      `${GET_NEW_NFTS_ENDPOINT}?page=${page}&sortBy=${sortByFilter}&searchValue=${searchValue}&safeContent=${
        isSafeContentSelected ? 1 : 0
      }`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftsWithOffersRequest = async (
  page,
  sortByFilter,
  searchValue,
  isSafeContentSelected
) => {
  try {
    const { data } = await axios.get(
      `${GET_NFTS_WITH_OFFERS_ENDPOINT}?page=${page}&sortBy=${sortByFilter}&searchValue=${searchValue}&safeContent=${
        isSafeContentSelected ? 1 : 0
      }`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getFutureEventsRequest = async (category) => {
  try {
    const endpoint = category
      ? `${GET_FUTURE_EVENTS_ENDPOINT}?category=${category}`
      : GET_FUTURE_EVENTS_ENDPOINT;
    const { data } = await axios.get(endpoint);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftsInAuctionRequest = async (
  pageParam,
  sortByFilter,
  searchValue,
  isSafeContentSelected
) => {
  try {
    const { data } = await axios.get(
      `${GET_NFTS_IN_AUCTION_ENDPOINT}?page=${pageParam}&sortBy=${sortByFilter}&searchValue=${searchValue}&safeContent=${
        isSafeContentSelected ? 1 : 0
      }`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftsForSpecificCategoryRequest = async ({
  pageParam,
  sortByFilter,
  searchValue,
  category,
  typeFilter,
  isSafeContentSelected,
  loggedInUserId,
  isFeaturedChecked,
  isAllChecked,
}) => {
  try {
    const { data } = await axios.get(
      `${GET_NFTS_FOR_SALE_FOR_SPECIFIC_CATEGORY_AND_SUBCATEGORY_ENDPOINT}?page=${pageParam}&sortBy=${sortByFilter}&searchValue=${searchValue}&category=${category.replace(
        '#',
        ''
      )}&typeFilter=${typeFilter}&safeContent=${
        isSafeContentSelected ? 1 : 0
      }&loggedInUserId=${loggedInUserId}&featured=${
        isFeaturedChecked ? 1 : 0
      }&all=${isAllChecked ? 1 : 0}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getFilteredEventsRequest = async (filterQuery) => {
  try {
    const { data } = await axios.get(
      `${GET_FUTURE_EVENTS_ENDPOINT}?${filterQuery}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNewEventsRequest = async () => {
  try {
    const { data } = await axios.get(GET_NEW_EVENTS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftsForSpecificEventRequest = async (eventId) => {
  try {
    const { data } = await axios.get(
      `${GET_NFTS_FOR_SPECIFIC_EVENT_ENDPOINT}/${eventId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getEventRequest = async (eventId) => {
  try {
    const { data } = await axios.get(`${GET_EVENT_ENDPOINT}/${eventId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGroupedEventsByLocationRequest = async () => {
  try {
    const { data } = await axios.get(GET_GROUPED_EVENTS_BY_LOCATION_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGroupedEventsByCategoryRequest = async () => {
  try {
    const { data } = await axios.get(GET_GROUPED_EVENTS_BY_CATEGORY_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllFutureEventsRequest = async () => {
  try {
    const { data } = await axios.get(GET_FUTURE_EVENTS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyChatsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_CHATS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyRequestedChatsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_REQUESTED_CHATS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNewNftsAssociatedWithEventRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_NEW_NFTS_ASSOCIATED_WITH_EVENT_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllNftsAssociatedWithEventRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_ALL_NFTS_ASSOCIATED_WITH_EVENT_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getEventStatisticsRequest = async () => {
  try {
    const { data } = await axios.get(GET_EVENT_STATISTICS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getTrendingEventsRequest = async () => {
  try {
    const { data } = await axios.get(GET_TRENDING_EVENTS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createGroupChatRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(CREATE_GROUP_CHAT_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getChatMessagesRequest = async (chatId) => {
  try {
    const { data } = await axios.get(
      `${GET_CHAT_MESSAGES_ENDPOINT}/${chatId}/messages`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createMessageRequest = async (chatId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_MESSAGE_ENDPOINT}/${chatId}/messages`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markChatMessagesAsReadRequest = async (chatId) => {
  try {
    const { data } = await axios.patch(
      `${MARK_CHAT_MESSAGES_AS_READ}/${chatId}/messages/mark-as-read`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markChatMessageAsReadRequest = async (chatId, chatMessageId) => {
  try {
    const { data } = await axios.patch(
      `${MARK_CHAT_MESSAGE_AS_READ}/${chatId}/messages/${chatMessageId}/mark-as-read`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyWalletRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_WALLET_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUsersWithMostFollowersRequest = async () => {
  try {
    const { data } = await axios.get(GET_USERS_WITH_MOST_FOLLOWERS);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getOwnerHistoryRequest = async (nftId) => {
  try {
    const { data } = await axios.get(
      `${GET_OWNER_HISTORY_ENDPOINT}/${nftId}/owner-history`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const leaveChatRequest = async (chatId) => {
  try {
    const { data } = await axios.patch(
      `${LEAVE_CHAT_ENDPOINT}/${chatId}/leave`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const editChatNameRequest = async (chatId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${EDIT_CHAT_NAME_ENDPOINT}/${chatId}/edit-name`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getEventForSpecificNftRequest = async (nftId) => {
  try {
    const { data } = await axios.get(
      `${GET_EVENT_FOR_SPECIFIC_NFT_ENDPOINT}/${nftId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const addNewChatMembersRequest = async (chatId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${ADD_NEW_CHAT_MEMBERS_ENDPOINT}/${chatId}/add-new-members`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfUnreadMessagesForMyChatsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_NUMBER_OF_UNREAD_MESSAGES_FOR_MY_CHATS_ENDPOINTS
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markChatMessageAsReceivedRequest = async (
  chatId,
  chatMessageId
) => {
  try {
    const { data } = await axios.patch(
      `${MARK_CHAT_MESSAGE_AS_RECEIVED}/${chatId}/messages/${chatMessageId}/mark-as-received`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markAllDeliveredChatMessagesAsReceivedRequest = async () => {
  try {
    const { data } = await axios.patch(
      `${MARK_ALL_CHAT_MESSAGES_AS_RECEIVED_ENDPOINT}/messages/mark-as-received`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyTransactionsRequest = async (page, limit) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_TRANSACTIONS_ENDPOINT}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfLikesForSpecificNftRequest = async (nftId) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_LIKES_FOR_SPECIFIC_NFT_ENDPOINT}/${nftId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfWatchlistsForSpecificNftRequest = async (nftId) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_WATCHLISTS_FOR_SPECIFIC_NFT_ENDPOINT}/${nftId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createSingleChatRequest = async (userId) => {
  try {
    const { data } = await axios.post(
      `${CREATE_SINGLE_CHAT_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllUsersRequest = async (page, limit, searchQuery) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_USERS_ENDPOINT}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createCategoryNftRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      CREATE_CATEGORY_NFT_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createSubCategoryNftRequest = async (categoryId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_SUB_CATEGORY_NFT_ENDPOINT}/${categoryId}/subCategory`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createEventRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(CREATE_EVENT_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserNftsStatisticsRequest = async (
  page,
  searchValue,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_NFTS_STATISTICS}?page=${page}&searchValue=${searchValue}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deactivateUserRequest = async (userId) => {
  try {
    const { data } = await axios.patch(
      `${DEACTIVATE_USER_ENDPOINT}/${userId}/deactivate`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const activateUserRequest = async (userId) => {
  try {
    const { data } = await axios.patch(
      `${ACTIVATE_USER_ENDPOINT}/${userId}/activate`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteChatRequest = async (chatId) => {
  try {
    const { data } = await axios.delete(`${DELETE_CHAT_ENDPOINT}/${chatId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const changeUserPasswordRequest = async (userId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${CHANGE_USER_PASSWORD_ENDPOINT}/${userId}/reset-password`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const changeMyPasswordRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      CHANGE_MY_PASSWORD_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUsersTransactionsForSoldNftsStatisticsRequest = async (
  page = 1,
  limit = 10,
  searchValue = ''
) => {
  try {
    const { data } = await axios.get(
      `${GET_USERS_TRANSACTIONS_FOR_SOLD_NFTS_STATISTICS}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUsersTransactionsForBoughtNftsStatisticsRequest = async (
  page = 1,
  limit = 10,
  searchValue = ''
) => {
  try {
    const { data } = await axios.get(
      `${GET_USERS_TRANSACTIONS_FOR_BOUGHT_NFTS_STATISTICS}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserWarningRequest = async (userId) => {
  try {
    const { data } = await axios.post(
      `${CREATE_USER_WARNING_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllNftsRequest = async (page, limit, searchValue) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_NFTS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateNftContentRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_NFT_CONTENT_ENDPOINT}/${nftId}/content`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserTokenViaGithubRequest = async (githubId) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_TOKEN_VIA_GITHUB_ENDPOINT}/${githubId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const loginViaGoogleRequest = async (accessToken, requestBody) => {
  try {
    const { data } = await axios.post(
      `${LOGIN_VIA_GOOGLE_ENDPOINT}/${accessToken}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const loginViaFacebookRequest = async (accessToken, requestBody) => {
  try {
    const { data } = await axios.post(
      `${LOGIN_VIA_FACEBOOK_ENDPOINT}?access_token=${accessToken}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllEventsRequest = async (page, limit, searchValue) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_EVENTS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateEventRequest = async (eventId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_EVENT_ENDPOINT}/${eventId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createReportRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(CREATE_REPORT_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllReportsRequest = async (page, limit, searchValue) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_REPORTS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfUnreadNotificationsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_NUMBER_OF_UNREAD_NOTIFICATIONS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllCategoriesAndCorrespondingSubCategoriesRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_ALL_CATEGORIES_AND_CORRESPONDING_SUBCATEGORIES_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateSubCategoryRequest = async (
  categoryId,
  subCategoryId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_SUBCATEGORY_ENDPOINT}/${categoryId}/subCategory/${subCategoryId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateCategoryRequest = async (categoryId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_CATEGORY_ENDPOINT}/${categoryId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateReportStatusRequest = async (reportId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_REPORT_STATUS_ENDPOINT}/${reportId}/status`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateReportActionTakenDescriptionRequest = async (
  reportId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_REPORT_ACTION_TAKEN_DESCRIPTION}/${reportId}/actionTakenDescription`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserStatisticsRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_STATISTICS_ENDPOINT}/${userId}/user-stats`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNftMessageRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_NFT_MESSAGE_ENDPOINT}/${nftId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftMessagesRequest = async (nftId, page, limit = 10) => {
  try {
    const { data } = await axios.get(
      `${GET_NFT_MESSAGES_ENDPOINT}/${nftId}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserReportsRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_REPORTS_ENDPOINT}/${userId}/reports`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserNftsAsOwnerAndCreatorRequest = async (
  userId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_NFTS_AS_OWNER_AND_CREATOR_ENDPOINT}/${userId}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNotificationToMultipleUsersRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      CREATE_NOTIFICATIONS_TO_MULTIPLE_USERS_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateUsernameRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(UPDATE_USERNAME_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteNftRequest = async (nftId) => {
  try {
    const { data } = await axios.delete(`${DELETE_NFT_ENDPOINT}/${nftId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const upVoteNftRequest = async (nftId) => {
  try {
    const { data } = await axios.post(`${UP_VOTE_NFT_ENDPOINT}/${nftId}/up`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfNftUpVotesRequest = async (nftId) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_NFT_UPVOTES_ENDPOINT}/${nftId}/up`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteNftUpVoteRequest = async (nftId) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_NFT_UP_VOTE_ENDPOINT}/${nftId}/up`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyNftVoteForSpecificNftRequest = async (nftId) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_NFT_VOTE_FOR_SPECIFIC_NFT_ENDPOINT}/${nftId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSortedUsersByNumberOfNftUpVotesRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${SORT_USERS_BY_NUMBER_OF_NFT_UPVOTES}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSortedUsersByNumberOfFollowersRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${SORT_USERS_BY_NUMBER_OF_FOLLOWERS}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSortedUsersByNumberOfNftsSoldRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${SORT_USERS_BY_NUMBER_OF_NFTS_SOLD_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSortedUsersByNumberOfNftsBoughtRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${SORT_USERS_BY_NUMBER_OF_NFTS_BOUGHT_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNftCommentRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_NFT_COMMENT_ENDPOINT}/${nftId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftCommentsRequest = async (page, limit, nftId) => {
  try {
    const { data } = await axios.get(
      `${GET_NFT_COMMENTS_ENDPOINT}/${nftId}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getWalletInformationRequest = async () => {
  try {
    const { data } = await axios.get(GET_WALLET_INFORMATION_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUsersNumberOfFollowersAndFollowingsRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_USERS_NUMBER_OF_FOLLOWERS_AND_FOLLOWINGS_ENDPOINT}/${userId}/nr-followers-followings`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNftCommentReplyRequest = async (commentId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_NFT_COMMENT_REPLY_ENDPOINT}/${commentId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllRepliesForSpecificNftCommentRequest = async (commentId) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_NFT_COMMENT_REPLIES_ENDPOINT}/${commentId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const likeNftCommentRequest = async (commentId) => {
  try {
    const { data } = await axios.post(
      `${LIKE_NFT_COMMENT_ENDPOINT}/${commentId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeNftCommentLikeRequest = async (commentId) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_NFT_COMMENT_LIKE_ENDPOINT}/${commentId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfLikesForSpecificNftCommentRequest = async (
  commentId
) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_LIKES_FOR_SPECIFIC_NFT_COMMENT_ENDPOINT}/${commentId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyNftCommentLikeStatusRequest = async (commentId) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_NFT_COMMENT_LIKE_STATUS_ENDPOINT}/${commentId}/status`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const likeNftCommentReplyRequest = async (replyId) => {
  try {
    const { data } = await axios.post(
      `${LIKE_NFT_COMMENT_REPLY_ENDPOINT}/${replyId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeNftCommentReplyLikeRequest = async (replyId) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_NFT_COMMENT_REPLY_LIKE_ENDPOINT}/${replyId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfLikesForSpecificNftCommentReplyRequest = async (
  replyId
) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_LIKES_FOR_SPECIFIC_NFT_COMMENT_REPLY_ENDPOINT}/${replyId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyNftCommentReplyLikeStatusRequest = async (replyId) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_NFT_COMMENT_REPLY_LIKE_STATUS_ENDPOINT}/${replyId}/status`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMostRecentNftsFromMyFollowersRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_MOST_RECENTS_NFTS_FROM_MY_FOLLOWINGS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserPostRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(CREATE_USER_POST_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPostsOfOtherPeopleRequest = async (
  page,
  limit,
  topicName,
  featuredOnly
) => {
  try {
    const { data } = await axios.get(
      `${GET_POSTS_OF_OTHER_PEOPLE_ENDPOINT}?page=${page}&limit=${limit}&topicName=${encodeURIComponent(
        topicName
      )}&featuredOnly=${featuredOnly ? 1 : 0}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const likeUserPostRequest = async (userPostId, requestBody = {}) => {
  try {
    const { data } = await axios.post(
      `${LIKE_USER_POST_ENDPOINT}/${userPostId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeUserPostLikeRequest = async (userPostId) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_USER_POST_LIKE_ENDPOINT}/${userPostId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfLikesForSpecificUserPostRequest = async (
  userPostId
) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_LIKES_FOR_SPECIFIC_USER_POST_ENDPOINT}/${userPostId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserPostCommentRequest = async (userPostId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_USER_POST_COMMENT_ENDPOINT}/${userPostId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getCommentsForSpecificUserPostRequest = async (
  userPostId,
  page,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_COMMENTS_FOR_SPECIFIC_USER_POST_ENDPOINT}/${userPostId}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfCommentsForSpecificPostRequest = async (userPostId) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_COMMENTS_FOR_SPECIFIC_POST_ENDPOINT}/${userPostId}/nr-comments`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const likeUserPostCommentRequest = async (userPostCommentId) => {
  try {
    const { data } = await axios.post(
      `${LIKE_USER_POST_COMMENT_ENDPOINT}/${userPostCommentId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeUserPostCommentLikeRequest = async (userPostCommentId) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_USER_POST_COMMENT_LIKE_ENDPOINT}/${userPostCommentId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfLikesForSpecificUserPostCommentRequest = async (
  userPostCommentId
) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_LIKES_FOR_SPECIFIC_USER_POST_COMMENT}/${userPostCommentId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyUserPostCommentLikeStatusRequest = async (
  userPostCommentId,
  loggedInUserId
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_USER_POST_COMMENT_LIKE_STATUS_ENDPOINT}/${userPostCommentId}/my-status?loggedInUserId=${loggedInUserId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserPostCommentReplyRequest = async (
  userPostCommentId,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${CREATE_USER_POST_COMMENT_REPLY_ENDPOINT}/${userPostCommentId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllRepliesForSpecificUserPostCommentRequest = async (
  userPostCommentId
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_USER_POST_COMMENT_REPLIES_ENDPOINT}/${userPostCommentId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfLikesForSpecificUserPostCommentReplyRequest = async (
  userPostCommentReplyId
) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_LIKES_FOR_SPECIFIC_USER_POST_COMMENT_REPLY}/${userPostCommentReplyId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyPostCommentReplyLikeStatusRequest = async (
  userPostCommentReplyId,
  loggedInUserId
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_POST_COMMENT_REPLY_LIKE_STATUS_ENDPOINT}/${userPostCommentReplyId}/status?loggedInUserId=${loggedInUserId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const likeUserPostCommentReplyRequest = async (
  userPostCommentReplyId
) => {
  try {
    const { data } = await axios.post(
      `${LIKE_USER_POST_COMMENT_REPLY_ENDPOINT}/${userPostCommentReplyId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeUserPostCommentReplyLikeRequest = async (
  userPostCommentReplyId
) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_USER_POST_COMMENT_REPLY_LIKE_ENDPOINT}/${userPostCommentReplyId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPostRequest = async (postId) => {
  try {
    const { data } = await axios.get(`${GET_POST_ENDPOINT}/${postId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createTransferRequest = async (walletAddress, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_TRANSFER_GLMS_ENDPOINT}/${walletAddress}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const verifyAndConfirmTransferGlmsRequest = async (
  walletAddress,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${VERIFY_AND_CONFIRM_TRANSFER_GLMS_ENDPOINT}/${walletAddress}/verify-confirm`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const listNftForBuyNowRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${LIST_NFT_FOR_BUY_NOW_ENDPOINT}/${nftId}/list-for-buy-now`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const unlistBuyNowRequest = async (nftId) => {
  try {
    const { data } = await axios.patch(
      `${LIST_NFT_FOR_BUY_NOW_ENDPOINT}/${nftId}/unlist-buy-now`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftsListedForBuyNowRequest = async (
  pageParam,
  sortByFilter,
  searchValue,
  isSafeContentSelected,
  loggedInUserId,
  isFeaturedChecked,
  isAllChecked
) => {
  try {
    const { data } = await axios.get(
      `${GET_NFTS_LISTED_FOR_BUY_NOW_ENDPOINT}?page=${pageParam}&sortBy=${sortByFilter}&searchValue=${searchValue}&safeContent=${
        isSafeContentSelected ? 1 : 0
      }&loggedInUserId=${loggedInUserId}&featured=${
        isFeaturedChecked ? 1 : 0
      }&all=${isAllChecked ? 1 : 0}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateNftBuyNowPriceRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_NFT_BUY_NOW_PRICE_ENDPOINT}/${nftId}/buy-now-price`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyPostsRequest = async (page, limit) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_POSTS_ENDPOINT}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const buyNftNowRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${BUY_NOW_NFT_ENDPOINT}/${nftId}/buy-now`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadCoverPhotoRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      UPLOAD_COVER_PHOTO_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markFriendAsFavoriteRequest = async (friendId) => {
  try {
    const { data } = await axios.post(
      `${MARK_FRIEND_AS_FAVORITE_ENDPOINT}/${friendId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeFavoriteFriendRequest = async (friendId) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_FRIEND_FROM_FAVORITES_ENDPOINT}/${friendId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getFavoriteFriendStatusForSpecificFriendRequest = async (
  friendId
) => {
  try {
    const { data } = await axios.get(
      `${GET_FAVORITE_FRIEND_STATUS_FOR_SPECIFIC_FRIEND_ENDPOINT}/${friendId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const editAboutInfoRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(EDIT_ABOUT_INFO_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyPaginatedFavoriteFriendsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_MY_PAGINATED_FAVORITE_FRIENDS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserImageAlbumRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(CREATE_USER_ALBUM_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyUserImageAlbumsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_USER_ALBUMS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadAlbumPhotoRequest = async (userAlbumId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPLOAD_ALBUM_PHOTO_ENDPOINT}/${userAlbumId}/upload-image`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteSpecificImageInAlbumRequest = async (
  userAlbumId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${DELETE_SPECIFIC_IMAGE_FROM_ALBUM_ENDPOINT}/${userAlbumId}/delete-image`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPostsForSpecificUserRequest = async (
  userId,
  page,
  limit,
  loggedInUserId
) => {
  try {
    const { data } = await axios.get(
      `${GET_POSTS_FOR_SPECIFIC_USER_ENDPOINT}/${userId}?page=${page}&limit=${limit}&loggedInUserId=${loggedInUserId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserPaginatedFollowersRequest = async (
  userId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_PAGINATED_FOLLOWERS}/${userId}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserPaginatedFollowingsRequest = async (
  userId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_PAGINATED_FOLLOWINGS}/${userId}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserPaginatedFriendsRequest = async (
  userId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_PAGINATED_FRIENDS}/${userId}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserAlbumsForSpecificUserRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_ALBUMS_FOR_SPECIFIC_USER_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadUserPostVideoRequest = async (userPostId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPLOAD_USER_POST_VIDEO_ENDPOINT}/${userPostId}/video`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserLifeEventRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_USER_LIFE_EVENT_ENDPOINT}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadUserLifeEventVideoRequest = async (
  userLifeEventId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPLOAD_USER_LIFE_EVENT_VIDEO_ENDPOINT}/${userLifeEventId}/video`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyLifeEventsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_LIFE_EVENTS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserLifeEventsForSpecificUserRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_LIFE_USER_EVENTS_FOR_SPECIFIC_USER_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteMyPostCommentRequest = async (postCommentId) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_MY_POST_COMMENT_ENDPOINT}/${postCommentId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyPostCommentRequest = async (
  postCommentId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_MY_POST_COMMENT_ENDPOINT}/${postCommentId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateNftCommentRequest = async (nftCommentId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_NFT_COMMENT_ENDPOINT}/${nftCommentId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteNftCommentRequest = async (nftCommentId) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_NFT_COMMENT_ENDPOINT}/${nftCommentId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserDedicatedPostRequest = async (toId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_USER_DEDICATED_POST_ENDPOINT}/${toId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const addMyPostToRecycleBinRequest = async (postId) => {
  try {
    const { data } = await axios.patch(
      `${ADD_POST_TO_RECYCLE_BIN_ENDPOINT}/${postId}/add-post-to-recycleBin`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deletePostRequest = async (postId) => {
  try {
    const { data } = await axios.delete(`${DELETE_POST_ENDPOINT}/${postId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteUserDedicatedPostRequest = async (postId) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_USER_DEDICATED_POST_ENDPOINT}/${postId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateDedicatedPostRequest = async (postId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_DEDICATED_POST_ENDPOINT}/${postId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserDedicatedPostRequest = async (postId) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_DEDICATED_POST_ENDPOINT}/${postId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMostRecentSalesRequest = async () => {
  try {
    const { data } = await axios.get(GET_MOST_RECENT_SALES_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUsersWithMostRecentActivityRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_USERS_WITH_MOST_RECENT_ACTIVITY_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getTrendingCategoriesRequest = async () => {
  try {
    const { data } = await axios.get(GET_TRENDING_CATEGORIES_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRecentlyAddedCategoriesRequest = async () => {
  try {
    const { data } = await axios.get(GET_RECENTLY_ADDED_CATEGORIES_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRecentlyAddedNftsRequest = async (loggedInUserId) => {
  try {
    const { data } = await axios.get(
      `${GET_RECENTLY_ADDED_NFTS_ENDPOINT}?loggedInUserId=${loggedInUserId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const editIntroductionRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(EDIT_INTRODUCTION_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const editEducationRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(EDIT_EDUCATION_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyRecentlyAddedNftsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_RECENTLY_ADDED_NFTS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserRecentlyAddedNftsRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_RECENTLY_ADDED_NFTS_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadDedicatedPostVideoRequest = async (
  userPostId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPLOAD_USER_DEDICATED_POST_VIDEO_ENDPOINT}/${userPostId}/video`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateChatMembersRequest = async (chatId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_CHAT_MEMBERS_ENDPOINT}/${chatId}/update-members`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeMemberFromChatRequest = async (chatId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${REMOVE_MEMBER_FROM_CHAT_ENDPOINT}/${chatId}/remove-member`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadPhotoRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(`${UPLOAD_PHOTO_ENDPOINT}`, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyUploadedPhotosRequest = async () => {
  try {
    const { data } = await axios.get(`${GET_MY_UPLOADED_PHOTOS_ENDPOINT}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deletePhotoRequest = async (photoId) => {
  try {
    const { data } = await axios.delete(`${DELETE_PHOTO_ENDPOINT}/${photoId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserUploadedPhotosRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_UPLOADED_PHOTOS_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const verifyUserRequest = async (userId) => {
  try {
    const { data } = await axios.patch(
      `${VERIFY_USER_ENDPOINT}/${userId}/verify`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const unVerifyUserRequest = async (userId) => {
  try {
    const { data } = await axios.patch(
      `${UNVERIFY_USER_ENDPOINT}/${userId}/unverify`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateNftPreviewUrlRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_NFT_PREVIEW_URL_ENDPOINT}/${nftId}/previewUrl`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUsersWhoHaveLikedASpecificUserPostRequest = async (
  userPostId,
  page,
  limit,
  searchValue,
  loggedInUserId
) => {
  try {
    const { data } = await axios.get(
      `${GET_USERS_WHO_HAVE_LIKED_A_SPECIFIC_USER_POST_ENDPOINT}/${userPostId}/users?page=${page}&limit=${limit}&searchValue=${searchValue}&loggedInUserId=${loggedInUserId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSomeFollowingsWhoHaveLikedSpecificUserPostRequest = async (
  userPostId,
  loggedInUserId
) => {
  try {
    const { data } = await axios.get(
      `${GET_USERS_WHO_HAVE_LIKED_A_SPECIFIC_USER_POST_ENDPOINT}/${userPostId}/followings?loggedInUserId=${loggedInUserId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyFriendsRequest = async (searchValue) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_FRIENDS_ENDPOINT}?searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createGroupRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(CREATE_GROUP_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyGroupsRequest = async (page, limit, searchValue) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_GROUPS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteAlbumRequest = async (albumId) => {
  try {
    const { data } = await axios.delete(`${DELETE_ALBUM_ENDPOINT}/${albumId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllGroupMembersForASpecificGroupRequest = async (
  page,
  limit,
  groupId,
  searchValue = ''
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_GROUP_MEMBERS_FOR_A_SPECIFIC_GROUP}/${groupId}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadGroupCoverPhotoRequest = async (groupId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPLOAD_GROUP_COVER_PHOTO_ENDPOINT}/${groupId}/upload-cover-photo`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSpecificGroupRequest = async (groupId) => {
  try {
    const { data } = await axios.get(
      `${GET_SPECIFIC_GROUP_ENDPOINT}/${groupId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getFriendSuggestionsRequest = async () => {
  try {
    const { data } = await axios.get(GET_FRIEND_SUGGESTIONS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfMembersForASpecificGroupRequest = async (groupId) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_MEMBERS_FOR_A_SPECIFIC_GROUP_ENDPOINT}/${groupId}/members-count`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGroupAdminsRequest = async (groupId) => {
  try {
    const { data } = await axios.get(
      `${GET_GROUP_ADMINS_ENDPOINT}/${groupId}/admins`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const inviteUsersToGroupRequest = async (groupId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${INVITE_USERS_TO_GROUP_ENDPOINT}/${groupId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const reactToGroupInvitationRequest = async (
  groupInvitationId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${REACT_TO_GROUP_INVITATION_ENDPOINT}/${groupInvitationId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPaginatedUnionOfMyFollowersAndFollowingsRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_PAGINATED_UNION_OF_MY_FOLLOWERS_AND_FOLLOWINGS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};
export const getPaginatedMutualFollowersRequest = async (
  userId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_PAGINATED_MUTUAL_FOLLOWERS_ENDPOINT}/${userId}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMemberStatusInASpecificGroupRequest = async (
  groupId,
  userId
) => {
  try {
    const { data } = await axios.get(
      `${GET_MEMBER_STATUS_IN_A_SPECIFIC_GROUP_ENDPOINT}/${groupId}/users/${userId}/member-status`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMemberRoleInASpecificGroupRequest = async (groupId, userId) => {
  try {
    const { data } = await axios.get(
      `${GET_MEMBER_ROLE_IN_A_SPECIFIC_GROUP_ENDPOINT}/${groupId}/users/${userId}/member-role`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserGroupPostsRequest = async (
  page,
  limit,
  groupId,
  selectedTopic
) => {
  try {
    let endpoint = `${GET_USER_GROUP_POSTS_ENDPOINT}/${groupId}?page=${page}&limit=${limit}`;

    if (selectedTopic) {
      endpoint = `${GET_USER_GROUP_POSTS_ENDPOINT}/${groupId}?page=${page}&limit=${limit}&groupTopic=${selectedTopic.replaceAll(
        '#',
        ''
      )}`;
    }

    const { data } = await axios.get(endpoint);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserGroupPostRequest = async (groupId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_USER_GROUP_POST_ENDPOINT}/${groupId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfMutualFollowersRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_MUTUAL_FOLLOWERS_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGroupPostTopicsRequest = async (
  groupId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_GROUP_POST_TOPICS_ENDPOINT}/${groupId}?page=${page}&limit=${limit}&searchValue=${searchValue.replaceAll(
        '#',
        ''
      )}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGroupPostTopicsSortedByNumberOfPostsRequest = async (
  groupId,
  page,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_GROUP_POST_TOPICS_SORTED_BY_NUMBER_OF_POSTS_ENDPOINT}/${groupId}/sorted-by-posts?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGroupMembersExceptAdminsRequest = async (
  groupId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_GROUP_MEMBERS_EXCEPT_ADMINS_ENDPOINT}/${groupId}/except-admins?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserGroupPostRequest = async (userGroupPostId) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_GROUP_POST_ENDPOINT}/${userGroupPostId}/post`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllGroupChannelsForSpecificGroupRequest = async (groupId) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_GROUP_CHANNELS_FOR_SPECIFIC_GROUP_ENDPOINT}/${groupId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGroupMessagesForSpecificChannelRequest = async (
  groupId,
  channelId,
  page,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_GROUP_MESSAGES_FOR_SPECIFIC_CHANNEL_ENDPOINT}/${groupId}/channels/${channelId}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createGroupMessageRequest = async (
  groupId,
  channelId,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${CREATE_GROUP_MESSAGE_ENDPOINT}/${groupId}/channels/${channelId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createGroupChannelRequest = async (groupId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_GROUP_CHANNEL_ENDPOINT}/${groupId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createPrivateGroupChannelRequest = async (
  groupId,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${CREATE_PRIVATE_GROUP_CHANNEL_ENDPOINT}/${groupId}/private`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const inviteUsersToGroupChannelRequest = async (
  groupId,
  channelId,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${INVITE_USERS_TO_GROUP_CHANNEL_ENDPOINT}/${groupId}/channels/${channelId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const reactToGroupChannelInvitationRequest = async (
  groupChannelInvitationId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${REACT_TO_GROUP_CHANNEL_INVITATION_ENDPOINT}/${groupChannelInvitationId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllMembersForASpecificPrivateGroupChannelRequest = async (
  groupId,
  channelId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_MEMBERS_FOR_A_SPECIFIC_PRIVATE_GROUP_CHANNEL_ENDPOINT}/${groupId}/channels/${channelId}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyGroupsIHaveJoinedRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_GROUPS_I_HAVE_JOINED_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyGroupsAsAdminRequest = async (searchValue) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_GROUPS_AS_ADMIN_ENDPOINT}?searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGroupSuggestionsRequest = async (page, limit) => {
  try {
    const { data } = await axios.get(
      `${GET_GROUP_SUGGESTIONS_ENDPOINT}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const joinPublicGroupRequest = async (groupId) => {
  try {
    const { data } = await axios.post(
      `${JOIN_PUBLIC_GROUP_ENDPOINT}/${groupId}/join`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestGroupMembershipRequest = async (groupId) => {
  try {
    const { data } = await axios.post(
      `${REQUEST_GROUP_MEMBERSHIP_ENDPOINT}/${groupId}/request`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyGroupsRecentActivityRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_GROUPS_RECENT_ACTIVITY_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllRequestedGroupMembershipsRequest = async (
  page,
  limit,
  groupId
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_REQUESTED_GROUP_MEMBERSHIPS_ENDPOINT}/${groupId}/requested?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const acceptRequestedGroupMembershipRequest = async (
  groupId,
  userId
) => {
  try {
    const { data } = await axios.patch(
      `${ACCEPT_REQUESTED_GROUP_MEMBERSHIPS_ENDPOINT}/${groupId}/users/${userId}/accept-request`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const rejectRequestedGroupMembershipRequest = async (
  groupId,
  userId
) => {
  try {
    const { data } = await axios.patch(
      `${REJECT_REQUESTED_GROUP_MEMBERSHIP_ENDPOINT}/${groupId}/users/${userId}/reject-request`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const leaveGroupRequest = async (groupId) => {
  try {
    const { data } = await axios.patch(
      `${LEAVE_GROUP_ENDPOINT}/${groupId}/leave`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUnionOfMyFollowersAndFollowingsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_UNION_OF_MY_FOLLOWERS_AND_FOLLOWINGS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllNonPaginatedGroupMembersForASpecificGroupRequest = async (
  groupId
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_NON_PAGINATED_GROUP_MEMBERS_FOR_A_SPECIFIC_GROUP_ENDPOINT}/${groupId}/non-paginated`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateGroupChannelNameRequest = async (
  groupId,
  channelId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_GROUP_CHANNEL_NAME_ENDPOINT}/${groupId}/channels/${channelId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteGroupChannelRequest = async (groupId, channelId) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_GROUP_CHANNEL_ENDPOINT}/${groupId}/channels/${channelId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markSpecificUserAsGroupAdminRequest = async (groupId, userId) => {
  try {
    const { data } = await axios.patch(
      `${MARK_USER_AS_GROUP_ADMIN_ENDPOINT}/${groupId}/users/${userId}/mark-as-admin`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeGroupAdminRequest = async (groupId, userId) => {
  try {
    const { data } = await axios.patch(
      `${REMOVE_GROUP_ADMIN_ENDPOINT}/${groupId}/users/${userId}/remove-admin`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeGroupMemberRequest = async (groupId, userId) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_GROUP_MEMBER_ENDPOINT}/${groupId}/users/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteUserPostCommentReplyRequest = async (
  userPostCommentId,
  userPostCommentReplyId
) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_USER_POST_COMMENT_REPLY_ENDPOINT}/${userPostCommentId}/replies/${userPostCommentReplyId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyGroupRequest = async (groupId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_MY_GROUP_ENDPOINT}/${groupId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNftCollectionRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      CREATE_NFT_COLLECTION_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNftCollectionMappingRequest = async (
  nftId,
  collectionId
) => {
  try {
    const { data } = await axios.post(
      `${CREATE_NFT_COLLECTION_MAPPING_ENDPOINT}/${nftId}/collections/${collectionId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllNftCollectionsRequest = async (
  page,
  limit,
  searchValue,
  loggedInUserId,
  isCollectionFilterChecked,
  isAirdropFilterChecked,
  isFeaturedChecked,
  isAllChecked
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_NFT_COLLECTIONS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}&loggedInUserId=${loggedInUserId}&collection=${
        isCollectionFilterChecked ? 1 : 0
      }&airdrop=${isAirdropFilterChecked ? 1 : 0}&featured=${
        isFeaturedChecked ? 1 : 0
      }&all=${isAllChecked ? 1 : 0}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllNftsForSpecificCollectionRequest = async (
  collectionId,
  page,
  limit,
  searchValue = '',
  filterListedItems = true,
  sortByFilter
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_NFTS_FOR_SPECIFIC_COLLECTION_ENDPOINT}/${collectionId}?page=${page}&limit=${limit}&searchValue=${encodeURIComponent(
        searchValue
      )}&filterListedItems=${
        filterListedItems === true ? 1 : 0
      }&sortBy=${sortByFilter}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSpecificNftCollectionRequest = async (collectionId) => {
  try {
    const { data } = await axios.get(
      `${GET_SPECIFIC_NFT_COLLECTION_ENDPOINT}/${collectionId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftCollectionStatisticsRequest = async (collectionId) => {
  try {
    const { data } = await axios.get(
      `${GET_NFT_COLLECTION_STATISTICS_ENDPOINT}/${collectionId}/stats`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyNftsNotPartOfACollectionRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_NFTS_NOT_PART_OF_A_COLLECTION_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const addNftToCollectionRequest = async (collectionId, nftId) => {
  try {
    const { data } = await axios.post(
      `${ADD_NFT_TO_COLLECTION_ENDPOINT}/${nftId}/collections/${collectionId}/add`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateNftCollectionRequest = async (
  nftCollectionId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_NFT_COLLECTION_ENDPOINT}/${nftCollectionId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftCollectionActivityRequest = async (
  nftCollectionId,
  page,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_NFT_COLLECTION_ACTIVITY_ENDPOINT}/${nftCollectionId}/activity?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const addNftCollectionToWatchlistRequest = async (nftCollectionId) => {
  try {
    const { data } = await axios.post(
      `${ADD_NFT_COLLECTION_TO_WATCHLIST_ENDPOINT}/${nftCollectionId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeNftCollectionFromWatchlistRequest = async (
  nftCollectionId
) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_NFT_COLLECTION_FROM_WATCHLIST_ENDPOINT}/${nftCollectionId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyWatchlistStatusForSpecificNftCollectionRequest = async (
  nftCollectionId,
  loggedInUserId
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_WATCHLIST_STATUS_FOR_SPECIFIC_NFT_COLLECTION_ENDPOINT}/${nftCollectionId}/status?loggedInUserId=${loggedInUserId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const inviteNftCollectionCollaboratorRequest = async (
  nftCollectionId,
  userId
) => {
  try {
    const { data } = await axios.post(
      `${INVITE_NFT_COLLECTION_COLLABORATOR_ENDPOINT}/${nftCollectionId}/users/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAvailableUsersToInviteAsNftCollectionCollaboratorRequest = async (
  nftCollectionId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_AVAILABLE_USERS_TO_INVITE_AS_NFT_COLLECTION_COLLABORATOR_ENDPOINT}/${nftCollectionId}/available-users-to-invite?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const reactToNftCollectionCollaborationInvitationRequest = async (
  collaboratorInvitationId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${REACT_TO_NFT_COLLECTION_COLLABORATION_INVITATION_ENDPOINT}/${collaboratorInvitationId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeNftCollectionCollaboratorRequest = async (
  nftCollectionId,
  userId
) => {
  try {
    const { data } = await axios.patch(
      `${REMOVE_NFT_COLLECTION_COLLABORATOR_ENDPOINT}/${nftCollectionId}/users/${userId}/remove-collaborator`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const addNftCollectionToASpecificGroupRequest = async (
  nftCollectionId,
  groupId
) => {
  try {
    const { data } = await axios.patch(
      `${ADD_NFT_COLLECTION_TO_A_SPECIFIC_GROUP_ENDPOINT}/${nftCollectionId}/groups/${groupId}/add`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeNftCollectionFromASpecificGroupRequest = async (
  nftCollectionId,
  groupId
) => {
  try {
    const { data } = await axios.patch(
      `${REMOVE_NFT_COLLECTION_FROM_A_SPECIFIC_GROUP_ENDPOINT}/${nftCollectionId}/groups/${groupId}/remove`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllNftCollectionsAssociatedWithASpecificGroupRequest = async (
  groupId
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_NFT_COLLECTIONS_ASSOCIATED_WITH_A_SPECIFIC_GROUP_ENDPOINT}/groups/${groupId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateNftRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_NFT_ENDPOINT}/${nftId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyNftCollectionWatchlistRequest = async (page, limit) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_NFT_COLLECTION_WATCHLIST_ENDPOINT}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAvailableGroupMembersToInvitePrivateChannelRequest = async (
  page,
  limit,
  searchValue,
  groupId,
  privateChannelId
) => {
  try {
    const { data } = await axios.get(
      `${GET_AVAILABLE_USERS_TO_INVITE_PRIVATE_GROUP_CHANNEL_ENDPOINT}/${groupId}/channels/${privateChannelId}/available-users-to-invite?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllAvailableNonPaginatedGroupMembersToInvitePrivateChannelRequest = async (
  groupId,
  privateChannelId
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_NON_PAGINATED_AVAILABLE_USERS_TO_INVITE_PRIVATE_GROUP_CHANNEL_ENDPOINT}/${groupId}/channels/${privateChannelId}/all-non-paginated-available-users-to-invite`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removePrivateGroupChannelMemberRequest = async (
  groupId,
  privateChannelId,
  userId
) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_PRIVATE_GROUP_CHANNEL_MEMBER_ENDPOINT}/${groupId}/channels/${privateChannelId}/users/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const isNftAccessibleToBuyByMeRequest = async (nftId) => {
  try {
    const { data } = await axios.get(
      `${IS_NFT_ACCESSIBLE_TO_BUY_BY_ME_ENDPOINT}/${nftId}/accessible-to-buy`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const searchCollectionsNftsGroupsRequest = async (searchValue) => {
  try {
    const { data } = await axios.get(
      `${SEARCH_COLLECTIONS_GROUPS_NFTS_ENDPOINT}?searchQuery=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateNftCollectionStatusRequest = async (
  nftCollectionId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_NFT_COLLECTION_STATUS_ENDPOINT}/${nftCollectionId}/status`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllPaginatedUserActivityTopicsRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_PAGINATED_USER_ACTIVITY_TOPICS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${encodeURIComponent(
        searchValue
      )}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserActivityTrendingTopicsRequest = async (page, limit) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_ACTIVITY_TRENDING_TOPICS_ENDPOINT}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserActivityTrendingPostTopicsRequest = async (page, limit) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_ACTIVITY_TRENDING_POST_TOPICS_ENDPOINT}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createGroupPostTopicRequest = async (
  groupId,
  postId,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${CREATE_GROUP_POST_TOPIC_ENDPOINT}/${groupId}/posts/${postId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserPostsForSpecificTopicRequest = async (topicName) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_POSTS_FOR_SPECIFIC_TOPIC_ENDPOINT}/topics/${encodeURIComponent(
        topicName
      )}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const blockUserRequest = async (userId) => {
  try {
    const { data } = await axios.post(`${BLOCK_USER_ENDPOINT}/${userId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserBlockStatusWithASpecificUserRequest = async (
  loggedInUserId,
  userId
) => {
  try {
    const { data } = await axios.get(
      `${GET_USER_BLOCK_STATUS_WITH_A_SPECIFIC_USER_ENDPOINT}/${userId}/status?loggedInUserId=${loggedInUserId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const unBlockUserRequest = async (userId) => {
  try {
    const { data } = await axios.delete(`${UNBLOCK_USER_ENDPOINT}/${userId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getLinkPreviewUrlRequest = async (link) => {
  try {
    const { data } = await axios.get(
      `${GET_LINK_PREVIEW_URL_ENDPOINT}?link=${encodeURIComponent(link)}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const makeNftOfferRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${MAKE_NFT_OFFER_ENDPOINT}/${nftId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const acceptNftOfferRequest = async (nftOfferId) => {
  try {
    const { data } = await axios.patch(
      `${ACCEPT_NFT_OFFER_ENDPOINT}/${nftOfferId}/accept`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftOffersForSpecificNftRequest = async (nftId) => {
  try {
    const { data } = await axios.get(
      `${GET_NFT_OFFERS_FOR_SPECIFIC_NFT_ENDPOINT}/${nftId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const rejectNftOfferRequest = async (nftOfferId) => {
  try {
    const { data } = await axios.patch(
      `${REJECT_NFT_OFFER_ENDPOINT}/${nftOfferId}/reject`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllNonPaginatedUsersRequest = async () => {
  try {
    const { data } = await axios.get(GET_ALL_NON_PAGINATED_USERS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const acceptChatRequest = async (chatId) => {
  try {
    const { data } = await axios.patch(
      `${ACCEPT_CHAT_REQUEST_ENDPOINT}/${chatId}/accept`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const rejectChatRequest = async (chatId) => {
  try {
    const { data } = await axios.delete(
      `${REJECT_CHAT_REQUEST_ENDPOINT}/${chatId}/reject`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfMyRequestedChatsRequest = async () => {
  try {
    const { data } = await axios.get(GET_NUMBER_OF_MY_REQUESTED_CHATS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyPostRequest = async (userPostId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_MY_POST_ENDPOINT}/${userPostId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyGroupPostRequest = async (
  userGroupPostId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_MY_GROUP_POST_ENDPOINT}/${userGroupPostId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyReceivedNftOffersRequest = async (
  page,
  limit,
  searchValue,
  sortBy
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_RECEIVED_NFT_OFFERS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}&sortBy=${sortBy}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMySentNftOffersRequest = async (
  page,
  limit,
  searchValue,
  sortBy
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_SENT_NFT_OFFERS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}&sortBy=${sortBy}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteMyNftOfferRequest = async (nftOfferId) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_MY_NFT_OFFER_ENDPOINT}/${nftOfferId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyNftOfferRequest = async (nftOfferId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_MY_NFT_OFFER_ENDPOINT}/${nftOfferId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNftRequest = async (requestFor, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_NFT_REQUEST_ENDPOINT}/${requestFor}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyPendingNftRequests = async () => {
  try {
    const { data } = await axios.get(GET_MY_PENDING_NFT_REQUESTS);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const rejectNftRequest = async (nftRequestId) => {
  try {
    const { data } = await axios.patch(
      `${REJECT_NFT_REQUEST_ENDPOINT}/${nftRequestId}/reject`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadRequestedNft = async (nftRequestId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPLOAD_REQUESTED_NFT_ENDPOINT}/${nftRequestId}/process`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyRequestedNfts = async (page, limit, searchValue) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_REQUESTED_NFTS}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRequestedNftsFromMeRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_REQUESTED_NFTS_FROM_ME}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyRequestNftPriceRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      UPDATE_MY_REQUEST_NFT_PRICE,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyLockedGLMSBalanceRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_LOCKED_GLMS_BALANCE_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMySocialLinksRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      UPDATE_MY_SOCIAL_LINKS_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markAllGroupChannelMessagesAsReadRequest = async (
  groupId,
  channelId
) => {
  try {
    const { data } = await axios.patch(
      `${MARK_ALL_GROUP_CHANNEL_MESSAGES_AS_READ_ENDPOINT}/${groupId}/channels/${channelId}/mark-as-read`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markGroupMessageAsReadRequest = async (groupMessageId) => {
  try {
    const { data } = await axios.patch(
      `${MARK_GROUP_MESSAGE_AS_READ_ENDPOINT}/${groupMessageId}/mark-as-read`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfUnreadGroupMessagesForMyGroupsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_NUMBER_OF_UNREAD_MESSAGES_FOR_MY_GROUPS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfUnreadMessagesForSpecificGroupChannelRequest = async (
  groupId,
  groupChannelId
) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_UNREAD_MESSAGES_FOR_SPECIFIC_GROUP_CHANNEL_ENDPOINT}/${groupId}/channels/${groupChannelId}/number-of-unread-messages`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyReferralsNetworkRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_REFERRALS_NETWORK_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyDirectReferralsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_DIRECT_REFERRALS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestEmailChangeRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      REQUEST_EMAIL_CHANGE_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const changeMyEmailRequest = async (
  emailVerificationCode,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${CHANGE_MY_EMAIL_ENDPOINT}/${emailVerificationCode}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getLatestCryptoNewsRequest = async () => {
  try {
    const { data } = await axios.get(GET_LATEST_CRYPTO_NEWS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNumberOfUnreadGroupMessagesForSpecificGroupRequest = async (
  groupId
) => {
  try {
    const { data } = await axios.get(
      `${GET_NUMBER_OF_UNREAD_MESSAGES_FOR_SPECIFIC_GROUP_ENDPOINT}/${groupId}/number-of-unread-messages`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyFavoriteChatsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_FAVORITE_CHATS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markChatAsFavoriteRequest = async (chatId) => {
  try {
    const { data } = await axios.post(
      `${MARK_CHAT_AS_FAVORITE_ENDPOINT}/${chatId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeChatAsFavoriteRequest = async (chatId) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_CHAT_AS_FAVORITE_ENDPOINT}/${chatId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getFavoriteStatusForSpecificChatRequest = async (chatId) => {
  try {
    const { data } = await axios.get(
      `${GET_FAVORITE_STATUS_FOR_SPECIFIC_CHAT_ENDPOINT}/${chatId}/status`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markGroupAsFavoriteRequest = async (groupId) => {
  try {
    const { data } = await axios.post(
      `${MARK_GROUP_AS_FAVORITE_ENDPOINT}/${groupId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeGroupAsFavoriteRequest = async (groupId) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_GROUP_AS_FAVORITE_ENDPOINT}/${groupId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUniqueOwnersForASpecificNftCollectionRequest = async (
  nftCollectionId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_UNIQUE_OWNERS_FOR_A_SPECIFIC_NFT_COLLECTION_ENDPOINT}/${nftCollectionId}/unique-owners?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestBetaAccessRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      REQUEST_BETA_ACCESS_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const applyAsCreatorRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(APPLY_AS_CREATOR_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllBetaAccessRequests = async () => {
  try {
    const { data } = await axios.get(GET_ALL_BETA_ACCESS_REQUESTS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllCreatorAppliesRequest = async () => {
  try {
    const { data } = await axios.get(GET_ALL_CREATOR_APPLIES_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createGroupPollRequest = async (groupId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_GROUP_POLL_ENDPOINT}/${groupId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllPollsForASpecificGroupRequest = async (
  groupId,
  page,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_POLLS_FOR_A_SPECIFIC_GROUP_ENDPOINT}/${groupId}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createGroupPollVoteRequest = async (
  groupPollAnswerId,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${CREATE_GROUP_POLL_VOTE_ENDPOINT}/${groupPollAnswerId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getVotesForASpecificPollRequest = async (groupPollId) => {
  try {
    const { data } = await axios.get(
      `${GET_VOTES_FOR_A_SPECIFIC_POLL_ENDPOINT}/groupPolls/${groupPollId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getVotesResultsForASpecificPollRequest = async (groupPollId) => {
  try {
    const { data } = await axios.get(
      `${GET_VOTES_INFORMATION_FOR_A_SPECIFIC_GROUP_POLL_ENDPOINT}/${groupPollId}/results`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUsersWhoVotedForASpecificGroupPollAnswerRequest = async (
  groupPollAnswerId,
  groupPollId,
  page,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_USERS_WHO_VOTED_FOR_A_SPECIFIC_GROUP_POLL_ANSWER_ENDPOINT}/${groupPollAnswerId}/users?groupPollId=${groupPollId}&page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createGroupPollAnswerRequest = async (
  groupPollId,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${CREATE_GROUP_POLL_ANSWER_ENDPOINT}/${groupPollId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const hasUserAccessToCreateAnAnswerForASpecificGroupPollRequest = async (
  groupPollId
) => {
  try {
    const { data } = await axios.get(
      `${HAS_USER_ACCESS_TO_CREATE_A_GROUP_POLL_ANSWER_ENDPOINT}/${groupPollId}/access-to-create-answer`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestPhoneNumberVerificationCodeRequest = async (
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      REQUEST_PHONE_NUMBER_VERIFICATION_CODE_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const verifyPhoneNumberRequest = async (phoneNumberVerificationCode) => {
  try {
    const { data } = await axios.patch(
      `${VERIFY_PHONE_NUMBER_ENDPOINT}/${phoneNumberVerificationCode}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateNftCollectionHolderBenefitsRequest = async (
  nftCollectionId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_NFT_COLLECTION_HOLDER_BENEFITS_ENDPOINT}/${nftCollectionId}/holder-benefits`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMuxSignedJWTRequest = async (spaceId) => {
  try {
    const { data } = await axios.get(
      `${GET_MUX_SIGNED_JWT_ENDPOINT}/${encodeURIComponent(spaceId)}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const verifyUserEmailRequest = async (userId) => {
  try {
    const { data } = await axios.patch(
      `${VERIFY_USER_EMAIL_ENDPOINT}/${userId}/verify-email`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const mapFullNameToParticipantIdRequest = async (
  participantId,
  spaceId
) => {
  try {
    const { data } = await axios.post(
      `${MAP_FULLNAME_TO_PARTICIPANT_ID_ENDPOINT}/${participantId}/spaces/${spaceId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllParticipantsRequest = async (spaceId) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_PARTICIPANTS_ENDPOINT}/spaces/${spaceId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeParticipantIdFromMappingRequest = async (
  participantId,
  spaceId
) => {
  try {
    const { data } = await axios.delete(
      `${REMOVE_PARTICIPANT_ID_FROM_MAPPING_ENDPOINT}/${participantId}/spaces/${spaceId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createStripeCheckoutRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      CREATE_STRIPE_CHECKOUT_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllNftsForASpecificMetaMaskWalletAddressRequest = async (
  metaMaskWalletAddress,
  page,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_NFTS_FOR_A_SPECIFIC_METAMASK_WALLET_ADDRESS_ENDPOINT}/nfts/${metaMaskWalletAddress}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyMetaMaskWalletAddressesRequest = async (
  metaMaskWalletAddress,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_MY_METAMASK_WALLET_ADDRESSES_ENDPOINT}/${metaMaskWalletAddress}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyMetaMaskNftsViewStatusRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      UPDATE_MY_METAMASK_NFTS_VIEW_STATUS_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMetaMaskNftsForASpecificUserRequest = async (
  userId,
  page,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_METAMASK_NFTS_FOR_A_SPECIFIC_USER_ENDPOINT}/${userId}/metamask-nfts?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const signMetaMaskPersonalSignatureRequest = async (
  metaMaskPersonalSignature,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${SIGN_METAMASK_PERSONAL_SIGNATURE_ENDPOINT}/${metaMaskPersonalSignature}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const signupWithMetaMaskRequest = async (requestBody, referredBy) => {
  try {
    const signUpWithMetaMaskEndpoint = referredBy
      ? `${SIGNUP_WITH_METAMASK_ENDPOINT}?referredBy=${referredBy}`
      : SIGNUP_WITH_METAMASK_ENDPOINT;

    const { data } = await axios.post(signUpWithMetaMaskEndpoint, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const loginWithMetaMaskRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      LOGIN_WITH_METAMASK_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createTransferOfNftRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_TRANSFER_OF_NFT_ENDPOINT}/${nftId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const verifyAndConfirmTransferOfNftRequest = async (
  nftId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${VERIFY_AND_CONFIRM_TRANSFER_OF_NFT_ENDPOINT}/${nftId}/verify-confirm`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNftPartOfFungibleAgnosticCollectionRequest = async (
  requestBody
) => {
  try {
    const { data } = await axios.post(
      CREATE_NFT_PART_OF_FUNGIBLE_AGNOSTIC_COLLECTION_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const claimNftRequest = async (nftId) => {
  try {
    const { data } = await axios.patch(`${CLAIM_NFT_ENDPOINT}/${nftId}/claim`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyClaimableAccessForASpecificNftRequest = async (nftId) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_CLAIMABLE_ACCESS_FOR_A_SPECIFIC_NFT_ENDPOINT}/${nftId}/claimable-access`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteMyNftCollectionRequest = async (nftCollectionId) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_MY_NFT_COLLECTION_ENDPOINT}/${nftCollectionId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getClaimableNftsForASpecificCollectionRequest = async (
  nftCollectionId,
  page,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_CLAIMABLE_NFTS_FOR_A_SPECIFIC_COLLECTION_ENDPOINT}/${nftCollectionId}/claimable-nfts?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftMetaDataForASpecificFungibilityAgnosticCollectionRequest = async (
  nftCollectionId
) => {
  try {
    const { data } = await axios.get(
      `${GET_NFT_METADATA_FOR_A_SPECIFIC_FUNGIBILITY_AGNOSTIC_NFT_COLLECTION_ENDPOINT}/${nftCollectionId}/nft-metadata`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const claimRandomNftFromASpecificClaimableCollectionRequest = async (
  nftCollectionId
) => {
  try {
    const { data } = await axios.post(
      `${CLAIM_RANDOM_NFT_FROM_A_CLAIMABLE_COLLECTION_ENDPOINT}/${nftCollectionId}/claim-random`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadEventPhotosRequest = async (eventId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPLOAD_EVENT_PHOTOS_ENDPOINT}/${eventId}/upload-photos`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const notifyClaimNftLinkToMyFollowersRequest = async (collectionId) => {
  try {
    const { data } = await axios.post(
      `${NOTIFY_CLAIM_NFT_LINK_TO_MY_FOLLOWERS_ENDPOINT}/${collectionId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const notifyCollectionCreatedToMyFollowersRequest = async (
  collectionId
) => {
  try {
    const { data } = await axios.post(
      `${NOTIFY_COLLECTION_CREATED_TO_MY_FOLLOWERS_ENDPOINT}/${collectionId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteEventRequest = async (eventId) => {
  try {
    const { data } = await axios.delete(`${DELETE_EVENT_ENDPOINT}/${eventId}`);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createPlaidLinkTokenRequest = async () => {
  try {
    const { data } = await axios.post(CREATE_PLAID_LINK_TOKEN_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const exchangePlaidPublicTokenRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      EXCHANGE_PLAID_PUBLIC_TOKEN_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const mapUsersWithWyreUsersRequest = async () => {
  try {
    const { data } = await axios.patch(MAP_USERS_WITH_WYRE_USERS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getWyreWalletInformationRequest = async () => {
  try {
    const { data } = await axios.get(GET_WYRE_WALLET_INFORMATION_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createWyreTransferRequest = async (
  paymentMethodId,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${CREATE_WYRE_TRANSFER_ENDPOINT}/${paymentMethodId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const buyNftWithWyreWalletRequest = async (nftId) => {
  try {
    const { data } = await axios.post(
      `${BUY_NFT_WITH_WYRE_WALLET_ENDPOINT}/${nftId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllWyrePaymentsRequest = async () => {
  try {
    const { data } = await axios.get(GET_ALL_WYRE_PAYMENTS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllWyreTransfersRequest = async () => {
  try {
    const { data } = await axios.get(GET_ALL_WYRE_TRANSFERS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const disconnectBankAccountRequest = async () => {
  try {
    const { data } = await axios.patch(DISCONNECT_BANK_ACCOUNT_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const withdrawToBankAccountRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      WITHDRAW_TO_BANK_ACCOUNT_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getWyreKycOnboardingUrlRequest = async () => {
  try {
    const { data } = await axios.get(GET_WYRE_KYC_ONBOARDING_URL_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const verifyUserAsInstitutionRequest = async (userId) => {
  try {
    const { data } = await axios.patch(
      `${VERIFY_USER_AS_INSTITUTION_ENDPOINT}/${userId}/verify-as-institution`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const unVerifyUserAsInstitutionRequest = async (userId) => {
  try {
    const { data } = await axios.patch(
      `${UNVERIFY_USER_AS_INSTITUTION_ENDPOINT}/${userId}/unverify-as-institution`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getWyreKycStatusRequest = async () => {
  try {
    const { data } = await axios.get(GET_WYRE_KYC_STATUS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyNftCollectionsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_NFT_COLLECTIONS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getNftCollectionsForASpecificUserRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_NFT_COLLECTIONS_FOR_A_SPECIFIC_USER_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGroupsForASpecificUserRequest = async (
  userId,
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_GROUPS_FOR_A_SPECIFIC_USER_ENDPOINT}/${userId}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyNftsNotPartOfACollectionAndCollaboratorRequestStatusRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_NFTS_NOT_PART_OF_A_COLLECTION_AND_COLLABORATOR_REQUEST_STATUS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestCreatorToAddMyNftAsCollaboratorRequest = async (
  nftCollectionId,
  nftId
) => {
  try {
    const { data } = await axios.post(
      `${REQUEST_CREATOR_TO_ADD_MY_NFT_AS_COLLABORATOR_ENDPOINT}/${nftCollectionId}/nfts/${nftId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyRequestedNftsFromCollaboratorsRequest = async (
  page,
  limit
) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_REQUESTED_NFTS_FROM_COLLABORATORS_ENDPOINT}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const rejectRequestedNftFromCollaboratorRequest = async (
  collaboratorNftRequestId
) => {
  try {
    const { data } = await axios.patch(
      `${REJECT_REQUESTED_NFT_FROM_COLLABORATOR_ENDPOINT}/${collaboratorNftRequestId}/reject`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const acceptRequestedNftFromCollaboratorRequest = async (
  collaboratorNftRequestId
) => {
  try {
    const { data } = await axios.patch(
      `${ACCEPT_REQUESTED_NFT_FROM_COLLABORATOR_ENDPOINT}/${collaboratorNftRequestId}/accept`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyRequestedNftsAsCollaboratorRequest = async (page, limit) => {
  try {
    const { data } = await axios.get(
      `${GET_MY_REQUESTED_NFTS_AS_COLLABORATOR_ENDPOINT}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteMyNftRequestAsCollaboratorRequest = async (
  collaboratorNftRequestId
) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_MY_NFT_REQUEST_AS_COLLABORATOR_ENDPOINT}/${collaboratorNftRequestId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestCreatorToUploadMyNftsAsCollaboratorRequest = async (
  nftCollectionId,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${REQUEST_CREATOR_TO_UPLOAD_MY_NFTS_AS_COLLABORATOR_ENDPOINT}/${nftCollectionId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyRequestedNftUploadsFromCollaboratorsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_MY_REQUESTED_NFT_UPLOADS_FROM_COLLABORATORS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const rejectRequestedNftUploadFromCollaboratorRequest = async (
  collaboratorNftUploadRequestId
) => {
  try {
    const { data } = await axios.patch(
      `${REJECT_REQUESTED_NFT_UPLOAD_FROM_COLLABORATOR_ENDPOINT}/${collaboratorNftUploadRequestId}/reject`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const acceptRequestedNftUploadFromCollaboratorRequest = async (
  collaboratorNftUploadRequestId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${ACCEPT_REQUESTED_NFT_UPLOAD_FROM_COLLABORATOR_ENDPOINT}/${collaboratorNftUploadRequestId}/accept`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyRequestedNftUploadsAsCollaboratorRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_MY_REQUESTED_NFT_UPLOADS_AS_COLLABORATOR_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteMyNftRequestUploadAsCollaboratorRequest = async (
  collaboratorNftRequestUploadId
) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_MY_NFT_REQUEST_UPLOAD_AS_COLLABORATOR_ENDPOINT}/${collaboratorNftRequestUploadId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const signUpWithUnstoppableDomainsRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      SIGNUP_WITH_UNSTOPPABLE_DOMAINS_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const loginWithUnstoppableRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      LOGIN_WITH_UNSTOPPABLE_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const depositToMyConnectedBankAccountRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      DEPOSIT_TO_MY_CONNECTED_BANK_ACCOUNT_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserAdminsRequest = async () => {
  try {
    const { data } = await axios.get(GET_USER_ADMINS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const depositToMyConnectedBankAccountViaPlaidRequest = async (
  requestBody
) => {
  try {
    const { data } = await axios.post(
      DEPOSIT_TO_MY_CONNECTED_BANK_ACCOUNT_VIA_PLAID_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const checkForWinningPointsAfterAutoLoginRequest = async () => {
  try {
    const { data } = await axios.patch(
      CHECK_FOR_WINNING_POINTS_AFTER_AUTO_LOGIN_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markAdminAsUserRequest = async (adminId) => {
  try {
    const { data } = await axios.patch(
      `${MARK_ADMIN_AS_USER_ENDPOINT}/${adminId}/mark-as-user`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMostRecentNftsFromMyUniqueFollowingsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_MOST_RECENT_NFTS_FROM_MY_UNIQUE_FOLLOWINGS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getVerifiedUsersRequest = async () => {
  try {
    const { data } = await axios.get(GET_VERIFIED_USERS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getVerifiedInstitutionsRequest = async () => {
  try {
    const { data } = await axios.get(GET_VERIFIED_INSTITUTIONS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const markUserAsAdminRequest = async (userId) => {
  try {
    const { data } = await axios.patch(
      `${MARK_USER_AS_ADMIN_ENDPOINT}/${userId}/mark-as-admin`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const publishNftsOrCollectionContentForASpecificUserRequest = async (
  userId
) => {
  try {
    const { data } = await axios.patch(
      `${PUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_A_SPECIFIC_USER_ENDPOINT}/${userId}/publish-nfts-or-collection-content`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const unPublishNftsOrCollectionContentForASpecificUserRequest = async (
  userId
) => {
  try {
    const { data } = await axios.patch(
      `${UNPUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_A_SPECIFIC_USER_ENDPOINT}/${userId}/unpublish-nfts-or-collection-content`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createTransferToASpecificUserRequest = async (
  userId,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${CREATE_TRANSFER_GLMS_TO_A_SPECIFIC_USER_ENDPOINT}/users/${userId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const publishNftsOrCollectionContentForEveryUserRequest = async () => {
  try {
    const { data } = await axios.patch(
      PUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_EVERY_USER_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const unPublishNftsOrCollectionContentForEveryUserRequest = async () => {
  try {
    const { data } = await axios.patch(
      UNPUBLISH_NFTS_OR_COLLECTION_CONTENT_FOR_EVERY_USER_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllUsersAllowedToPublishNftsOrCollectionContentRequest = async (
  page,
  limit,
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_USERS_ALLOWED_TO_PUBLISH_NFTS_OR_COLLECTION_CONTENT_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllGroupsRequest = async (searchValue) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_GROUPS_ENDPOINT}?searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const mapMeWithWyreUserIdAndWalletRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      MAP_WYRE_USER_ID_AND_WALLET_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const hideGroupFromSuggestionsRequest = async (groupId) => {
  try {
    const { data } = await axios.patch(
      `${HIDE_GROUP_FROM_SUGGESTIONS_ENDPOINT}/${groupId}/hide-from-suggestions`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const showGroupInSuggestionsRequest = async (groupId) => {
  try {
    const { data } = await axios.patch(
      `${SHOW_GROUP_IN_SUGGESTIONS_ENDPOINT}/${groupId}/show-in-suggestions`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllGroupsNotHiddenFromSuggestionsRequest = async (
  searchValue
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_GROUPS_NOT_HIDDEN_FROM_SUGGESTIONS_ENDPOINT}?searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const hideAllGroupsFromSuggestionsRequest = async () => {
  try {
    const { data } = await axios.patch(
      HIDE_ALL_GROUPS_FROM_SUGGESTIONS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const showAllGroupsInSuggestionsRequest = async () => {
  try {
    const { data } = await axios.patch(SHOW_ALL_GROUPS_IN_SUGGESTIONS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyWyreAccountFieldsRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      UPDATE_MY_WYRE_ACCOUNT_FIELDS_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createStripePaymentAccountLinkRequest = async () => {
  try {
    const { data } = await axios.post(
      CREATE_STRIPE_PAYMENT_ACCOUNT_LINK_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createStripePaymentCheckoutSessionRequest = async (
  requestBody
) => {
  try {
    const { data } = await axios.post(
      CREATE_STRIPE_PAYMENT_CHECKOUT_SESSION_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const giveUserAccessToCreateStripeAccountRequest = async (userId) => {
  try {
    const { data } = await axios.patch(
      `${GIVE_USER_ACCESS_TO_CREATE_STRIPE_ACCOUNT_ENDPOINT}/${userId}/give-access-to-create-stripe-account`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const removeUserAccessToCreateStripeAccountRequest = async (userId) => {
  try {
    const { data } = await axios.patch(
      `${REMOVE_USER_ACCESS_TO_CREATE_STRIPE_ACCOUNT_ENDPOINT}/${userId}/remove-access-to-create-stripe-account`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyStripeAccountDetailsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_STRIPE_ACCOUNT_DETAILS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllUsersWhoHaveAccessToCreateStripeAccountRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_ALL_USERS_WHO_HAVE_ACCESS_TO_CREATE_STRIPE_ACCOUNT_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllStripePaymentsRequest = async () => {
  try {
    const { data } = await axios.get(GET_ALL_STRIPE_PAYMENTS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyNotListedNftsAccessStatusRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      UPDATE_MY_NOT_LISTED_NFTS_ACCESS_STATUS_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateWhoCanPostInASpecificGroupRequest = async (
  groupId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_WHO_CAN_POST_IN_A_SPECIFIC_GROUP_ENDPOINT}/${groupId}/who-can-post`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateWhoCanWriteInASpecificGroupChannelRequest = async (
  groupId,
  channelId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_WHO_CAN_WRITE_IN_A_SPECIFIC_GROUP_CHANNEL_ENDPOINT}/${groupId}/channels/${channelId}/who-can-write`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSpecificGroupChannelRequest = async (groupId, channelId) => {
  try {
    const { data } = await axios.get(
      `${GET_SPECIFIC_GROUP_CHANNEL_ENDPOINT}/${groupId}/channels/${channelId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const doIHaveAccessToCreateNftCollectionOrCommunityRequest = async () => {
  try {
    const { data } = await axios.get(
      DO_I_HAVE_ACCESS_TO_CREATE_NFT_COLLECTION_OR_COMMUNITY_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllUsersGroupedByUnverifiedEmailsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_ALL_USERS_GROUPED_BY_UNVERIFIED_EMAILS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateLevel2VerificationForASpecificUserRequest = async (
  userId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_LEVEL_2_VERIFICATION_FOR_A_SPECIFIC_USER_ENDPOINT}/${userId}/level-2-verified`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllLevel2VerifiedUsersRequest = async () => {
  try {
    const { data } = await axios.get(GET_ALL_LEVEL_2_VERIFIED_USERS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllUsersUnderSuspicionRequest = async () => {
  try {
    const { data } = await axios.get(GET_ALL_USERS_UNDER_SUSPICION_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllUsersRegisteredViaSpecificSignupTypeRequest = async (
  page,
  limit,
  searchQuery,
  signupType
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_USERS_REGISTERED_VIA_SPECIFIC_SIGNUP_TYPE_ENDPOINT}?page=${page}&limit=${limit}&searchQuery=${searchQuery}&signupType=${signupType}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteUserAccountRequest = async (userId) => {
  try {
    const { data } = await axios.delete(
      `${DELETE_USER_ACCOUNT_ENDPOINT}/${userId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getTopCreatorsRequest = async () => {
  try {
    const { data } = await axios.get(GET_TOP_CREATORS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getTopNftsForSpecificCollectionRequest = async (
  collectionId,
  page,
  limit,
  searchValue = '',
  filterListedItems = true,
  sortByFilter
) => {
  try {
    const { data } = await axios.get(
      `${GET_TOP_NFTS_FOR_SPECIFIC_COLLECTION_ENDPOINT}/${collectionId}?page=${page}&limit=${limit}&searchValue=${encodeURIComponent(
        searchValue
      )}&filterListedItems=${
        filterListedItems === true ? 1 : 0
      }&sortBy=${sortByFilter}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGroupFavoriteStatusRequest = async (groupId) => {
  try {
    const { data } = await axios.get(
      `${GET_GROUP_FAVORITE_STATUS_ENDPOINT}/${groupId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createBuyGlmsStripeCheckoutSessionRequest = async (
  requestBody
) => {
  try {
    const { data } = await axios.post(
      CREATE_BUY_GLMS_STRIPE_CHECKOUT_SESSION_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllGlmsStripeBuyTransactionsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_ALL_GLMS_STRIPE_BUY_TRANSACTIONS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createBuyPointsStripeCheckoutSessionRequest = async (
  requestBody
) => {
  try {
    const { data } = await axios.post(
      CREATE_BUY_POINTS_STRIPE_CHECKOUT_SESSION_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllPointsStripeBuyTransactionsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_ALL_POINTS_STRIPE_BUY_TRANSACTIONS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createPostTipRequest = async (postId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_POST_TIP_ENDPOINT}/${postId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getTipStatusForSpecificPostRequest = async (postId) => {
  try {
    const { data } = await axios.get(
      `${GET_TIP_STATUS_FOR_SPECIFIC_POST_ENDPOINT}/${postId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPostsOfMyNftCollectorHoldersRequest = async (
  page,
  limit,
  topicName
) => {
  try {
    const { data } = await axios.get(
      `${GET_POSTS_OF_MY_NFT_COLLECTOR_HOLDERS_ENDPOINT}?page=${page}&limit=${limit}&topicName=${encodeURIComponent(
        topicName
      )}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyPointsTransactionsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_POINTS_TRANSACTIONS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyGlmsTransactionsRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_GLMS_TRANSACTIONS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGetStartedTasksCompletionInfoRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_GET_STARTED_TASKS_COMPLETION_INFO_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGetStartedTaskFollowPeopleSuggestionsRequest = async () => {
  try {
    const { data } = await axios.get(
      GET_GET_STARTED_TASK_FOLLOW_PEOPLE_SUGGESTIONS_ENDPOINT
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const claimGetStartedTaskRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      CLAIM_GET_STARTED_TASK_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyClaimedGetStartedTasksRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_CLAIMED_GET_STARTED_TASKS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUnlockableContentForSpecificNftRequest = async (nftId) => {
  try {
    const { data } = await axios.get(
      `${GET_UNLOCKABLE_CONTENT_FOR_SPECIFIC_NFT_ENDPOINT}/${nftId}/unlockableContent`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUnlockableContentStatusForSpecificNftRequest = async (
  nftId
) => {
  try {
    const { data } = await axios.get(
      `${GET_UNLOCKABLE_CONTENT_STATUS_FOR_SPECIFIC_NFT_ENDPOINT}/${nftId}/unlockableContent-status`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getStripeAccountStatusForSpecificUserRequest = async (userId) => {
  try {
    const { data } = await axios.get(
      `${GET_STRIPE_ACCOUNT_STATUS_FOR_SPECIFIC_USER_ENDPOINT}/${userId}/account-status`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadAudioForSpecificNftRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPLOAD_AUDIO_FOR_SPECIFIC_NFT_ENDPOINT}/${nftId}/audioUrl`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNftPostRequest = async (nftId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_NFT_POST_ENDPOINT}/${nftId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllNftPostsRequest = async () => {
  try {
    const { data } = await axios.get(GET_ALL_NFT_POSTS_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createNftPostTipRequest = async (nftPostId, requestBody) => {
  try {
    const { data } = await axios.post(
      `${CREATE_NFT_POST_TIP_ENDPOINT}/${nftPostId}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getTipStatusForSpecificNftPostRequest = async (nftPostId) => {
  try {
    const { data } = await axios.get(
      `${GET_TIP_STATUS_FOR_SPECIFIC_NFT_POST_ENDPOINT}/${nftPostId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateMyCreatorServicesRequest = async (requestBody) => {
  try {
    const { data } = await axios.patch(
      UPDATE_MY_CREATOR_SERVICES_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateGroupBenefitsRequest = async (groupId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_GROUP_BENEFITS_ENDPOINT}/${groupId}/benefits`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserServicePriceRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      CREATE_USER_SERVICE_PRICE_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyServicesPricesRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_SERVICES_PRICES_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyGroupsAsOrganizerRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_GROUPS_AS_ORGANIZER_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserServiceRequestSessionRequest = async (
  to,
  requestBody
) => {
  try {
    const { data } = await axios.post(
      `${CREATE_USER_SERVICE_REQUEST_STRIPE_CHECKOUT_SESSION_ENDPOINT}/${to}`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRequestedServicesForMeRequest = async () => {
  try {
    const { data } = await axios.get(GET_REQUESTED_SERVICES_FOR_ME_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};
export const getRequestedServicesFromMeRequest = async () => {
  try {
    const { data } = await axios.get(GET_REQUESTED_SERVICES_FROM_ME_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSpecificRequestedServiceForMeRequest = async (
  requestedServiceId
) => {
  try {
    const { data } = await axios.get(
      `${GET_SPECIFIC_REQUESTED_SERVICE_FOR_ME_ENDPOINT}/${requestedServiceId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadRequestedServiceFileRequest = async (
  requestedServiceId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPLOAD_REQUESTED_SERVICE_ENDPOINT}/${requestedServiceId}/file`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const uploadRequestedServiceTextRequest = async (
  requestedServiceId,
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      `${UPLOAD_REQUESTED_SERVICE_ENDPOINT}/${requestedServiceId}/text`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSpecificUserServicePriceRequest = async (to, service) => {
  try {
    const { data } = await axios.get(
      `${GET_SPECIFIC_USER_SERVICE_PRICE_ENDPOINT}/${to}?service=${service}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createUserByAdminRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      CREATE_USER_BY_ADMIN_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const changePasswordForUserCreatedByAdminRequest = async (
  requestBody
) => {
  try {
    const { data } = await axios.patch(
      CHANGE_PASSWORD_FOR_USER_CREATED_BY_ADMIN_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createBrandItemRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(CREATE_BRAND_ITEM_ENDPOINT, requestBody);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAllBrandItemsListedForSaleRequest = async (
  page,
  limit,
  searchValue,
  tag
) => {
  try {
    const { data } = await axios.get(
      `${GET_ALL_BRAND_ITEMS_LISTED_FOR_SALE_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}&tag=${tag}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getBrandItemRequest = async (brandItemId) => {
  try {
    const { data } = await axios.get(
      `${GET_BRAND_ITEM_ENDPOINT}/${brandItemId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const unlistBrandItemForSaleRequest = async (brandItemId) => {
  try {
    const { data } = await axios.patch(
      `${UNLIST_BRAND_ITEM_FOR_SALE_ENDPOINT}/${brandItemId}/unlist-for-sale`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const listBrandItemForSaleRequest = async (brandItemId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${LIST_BRAND_ITEM_FOR_SALE_ENDPOINT}/${brandItemId}/list-for-sale`,

      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateBrandItemPriceRequest = async (brandItemId, requestBody) => {
  try {
    const { data } = await axios.patch(
      `${UPDATE_BRAND_ITEM_PRICE_ENDPOINT}/${brandItemId}/price`,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createBrandItemCheckoutSessionRequest = async (brandItemId) => {
  try {
    const { data } = await axios.post(
      `${CREATE_BRAND_ITEM_CHECKOUT_SESSION_ENDPOINT}/${brandItemId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyAccessibilityInformationRequest = async () => {
  try {
    const { data } = await axios.get(GET_MY_ACCESSIBILITY_INFORMATION_ENDPOINT);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createPayablePostRequest = async (requestBody) => {
  try {
    const { data } = await axios.post(
      CREATE_PAYABLE_POST_ENDPOINT,
      requestBody
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPayablePostsOfOtherPeopleRequest = async (featuredOnly) => {
  try {
    const { data } = await axios.get(
      `${GET_PAYABLE_POSTS_OF_OTHERS_PEOPLE_ENDPOINT}?featuredOnly=${
        featuredOnly ? 1 : 0
      }`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createPostPaymentRequest = async (payablePostId) => {
  try {
    const { data } = await axios.post(
      `${CREATE_POST_PAYMENT_ENDPOINT}/${payablePostId}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};
