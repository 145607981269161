import React, { useState } from 'react';
import Modal from '../../../../../../components/Modal/Modal';
import { isInputValidationCompleted } from '../../../../../../helpers/helpers';
import {
  updateCategoryRequest,
  updateSubCategoryRequest,
} from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import { useQueryClient } from 'react-query';

function EditCategoryAndSubCategoryModal(props) {
  const queryClient = useQueryClient();
  const {
    selectedCategoryToEdit,
    selectedSubCategoryToEdit,
    setShowEditCategoryAndSubCategoryModal,
    invalidateCategoriesAndSubCategoriesQuery,
  } = props;

  const [formState, setFormState] = useState({
    category: {
      value: selectedCategoryToEdit.name,
      valid: true,
      validationRequirements: { required: true },
      placeholder: 'Category',
    },
    subCategory: {
      value: selectedSubCategoryToEdit.name,
      valid: true,
      validationRequirements: { required: true },
      placeholder: 'Sub Category',
    },
  });
  const [updatingRecords, setUpdatingRecords] = useState(false);

  const closeModalHandler = () => {
    setShowEditCategoryAndSubCategoryModal(false);
  };

  const inputChangeHandler = (key, value) => {
    const updatedFormState = { ...formState };
    updatedFormState[key].value = value;
    updatedFormState[key].valid = isInputValidationCompleted(
      value,
      updatedFormState[key].validationRequirements
    );

    setFormState(updatedFormState);
  };

  const editCategoryAndSubCategoryHandler = async () => {
    setUpdatingRecords(true);

    try {
      await Promise.all([
        updateCategoryRequest(selectedCategoryToEdit._id, {
          name: formState.category.value,
        }),
        updateSubCategoryRequest(
          selectedCategoryToEdit._id,
          selectedSubCategoryToEdit._id,
          { name: formState.subCategory.value }
        ),
      ]);
      invalidateCategoriesAndSubCategoriesQuery();
      queryClient.invalidateQueries('categories');
      queryClient.refetchQueries('categories');
      closeModalHandler();
    } catch (error) {
      notify('error', error, 2000);
    }

    setUpdatingRecords(false);
  };

  const isFormValid = Object.values(formState).every(({ valid }) => valid);

  return (
    <>
      <Modal
        footer={
          <>
            <button onClick={closeModalHandler} className={'cancel-button'}>
              Close
            </button>
            <span style={{ marginLeft: 10 }}></span>
            <button
              disabled={!isFormValid || updatingRecords}
              onClick={editCategoryAndSubCategoryHandler}
              className={
                !isFormValid || updatingRecords
                  ? 'btn-disabled'
                  : 'btn-white-glimpse'
              }
            >
              {updatingRecords === true ? 'Updating...' : 'Edit'}
            </button>
          </>
        }
        closeModal={closeModalHandler}
        title="Edit Category & Sub Category"
      >
        {Object.entries(formState).map(([formKey, formState]) => {
          const inputStyle = {};
          if (formState.valid === false) {
            inputStyle['border'] = '1px solid red';
          }
          return (
            <div key={formKey}>
              <p style={{ padding: 5, margin: 0 }} className="bold-text">
                {formState.placeholder}
              </p>
              <input
                placeholder={formState.placeholder}
                onChange={(e) => inputChangeHandler(formKey, e.target.value)}
                className="searchInput"
                style={inputStyle}
                value={formState.value}
              />
            </div>
          );
        })}
      </Modal>
    </>
  );
}

export default EditCategoryAndSubCategoryModal;
