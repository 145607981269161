import React, { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getMyPostsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './MyPosts.module.css';
import Post from '../../../NewsFeed/components/NewsFeedContent/components/Posts/components/Post/Post';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import WhatAreYouThinking from '../../../NewsFeed/components/NewsFeedContent/components/WhatAreYouThinking/WhatAreYouThinking';
import AboutMe from '../AboutMe/AboutMe';
import NoPostsFoundIcon from '../../../../assets/no-activity-found-icon.png';

const RESULTS_PER_PAGE = 10;
function MyPosts(props) {
  const {
    openMyFollowersModalHander,
    openMyFollowingsModalHander,
    myNumberOfFollowers,
    myNumberOfFollowings,
    showListingsSectionsHandler,
    seeAllPhotosHandler,
  } = props;

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    'my-posts',
    ({ pageParam = 1 }) => getMyPostsRequest(pageParam, RESULTS_PER_PAGE),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const allLikes = [];
  if (data?.pages) {
    data.pages.forEach((page) => {
      allLikes.push(...page.myLikeStatusForEachPost);
    });
  }

  return (
    <div className={classes['posts-page-container']}>
      {!isLoading && (
        <AboutMe
          openMyFollowersModalHander={openMyFollowersModalHander}
          openMyFollowingsModalHander={openMyFollowingsModalHander}
          myNumberOfFollowers={myNumberOfFollowers}
          myNumberOfFollowings={myNumberOfFollowings}
          showListingsSectionsHandler={showListingsSectionsHandler}
          seeAllPhotosHandler={seeAllPhotosHandler}
        />
      )}

      <div
        style={{
          width: data && data.pages && data.pages[0].results === 0 && '100%',
          minWidth: '60%',
        }}
        className={classes['posts-section-container']}
      >
        {data?.pages && <WhatAreYouThinking />}

        <div className={classes['posts-container']}>
          {data && data.pages && data.pages[0].results === 0 && (
            <div className={classes['no-posts-found-container']}>
              <img
                src={NoPostsFoundIcon}
                alt="No Posts Found"
                className={classes['no-posts-found-icon']}
              />
              <p className={classes['no-posts-found-text']}>No Posts Found</p>
            </div>
          )}
          {data &&
            data.pages &&
            data.pages.map((page) => {
              return page.data.map((post, i) => {
                const isPostLikedByMe =
                  allLikes.find(({ postId }) => {
                    return postId === post._id;
                  })?.likeStatus !== null;

                return (
                  <Post
                    isPostLikedByMe={isPostLikedByMe}
                    queryKey={'my-posts'}
                    key={post._id}
                    post={post}
                  />
                );
              });
            })}
          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default MyPosts;
