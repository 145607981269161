import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import Input from '../../../../../../../../../../components/Input/Input';
import Modal from '../../../../../../../../../../components/Modal/Modal';
import { getMyPaginatedFollowersRequest } from '../../../../../../../../../../httpRequests/httpRequests';
import classes from './SelectSpecificFriendsModal.module.css';
import SearchIcon from '../../../../../../../../../../assets/search-icon.png';
import LoadingSpinner from '../../../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import GreenPlusIcon from '../../../../../../../../../../assets/green-plus.png';
import GrayPlusIcon from '../../../../../../../../../../assets/gray-plus.png';
import notify from '../../../../../../../../../../utils/notify';
import Button from '../../../../../../../../../../components/Button/Button';

const RESULTS_PER_PAGE = 10;

function SelectSpecificFriendsModal(props) {
  const followersContainerRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-paginated-followers', searchValue],
    ({ pageParam = 1 }) =>
      getMyPaginatedFollowersRequest(pageParam, RESULTS_PER_PAGE, searchValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const followersContainerElement = followersContainerRef.current;

    if (!followersContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    followersContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      followersContainerElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const {
    setShowSelectSpecificFriendsModal,
    setSelectedFollowerIds,
    selectedFollowerIds,
    setShowSelectPostStatusModal,
    setSelectedPostStatus,
  } = props;

  const closeModalHandler = () => {
    setShowSelectSpecificFriendsModal(false);
  };

  const addUserToSelectedFollowersHandler = (user) => {
    if (selectedFollowerIds[user._id]) {
      setSelectedFollowerIds((prevState) => ({
        ...prevState,
        [user._id]: false,
      }));
    } else {
      setSelectedFollowerIds((prevState) => ({
        ...prevState,
        [user._id]: true,
      }));
    }
  };

  const saveSelectedFollowersHandler = () => {
    setSelectedPostStatus('Specific Followers');
    closeModalHandler();
    setShowSelectPostStatusModal(false);
  };

  return (
    <>
      <Modal
        style={{ height: 600 }}
        // closeIcon
        title="Select Followers"
        // goBackHandler={closeModalHandler}
        // goBackTitle="true"
        // title={() => (
        //   <div className={classes['title-container']}>
        //     <h3 className={classes['select-followers-text']}>
        //       Select Followers
        //     </h3>
        //     <p className={classes['show-to-selected-followers-text']}>
        //       Show to selected followers
        //     </p>
        //   </div>
        // )}
        footer={
          <div className={classes['footer']}>
            <Button
              style={{ width: 200 }}
              darkpinkcolor="true"
              onClick={closeModalHandler}
            >
              Cancel
            </Button>
            <Button
              darkpink="true"
              onClick={saveSelectedFollowersHandler}
              style={{ width: 200 }}
            >
              Save
            </Button>
          </div>
        }
        closeModal={closeModalHandler}
      >
        <div>
          <div className={classes['input-search-container']}>
            <Input
              style={{ width: '100%' }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search user"
            />
            <img
              alt="Search"
              className={classes['search-icon']}
              src={SearchIcon}
            />
          </div>
        </div>

        <div
          ref={followersContainerRef}
          className={classes['followers-container']}
        >
          {data && data.pages && data.pages[0].results === 0 && (
            <p className={classes['no-followers-found']}>No Followers Found</p>
          )}
          {data &&
            data.pages &&
            data.pages.map((page) => {
              return page.data.map(({ follower }) => {
                return (
                  <>
                    <div className={classes['follower-container']}>
                      <img
                        alt="Follower"
                        src={follower.photo}
                        className={classes['follower-photo']}
                        width={45}
                        height={45}
                      />
                      <div>
                        <h3 className={classes['follower-fullName']}>
                          {follower.fullName}
                        </h3>
                        <p className={classes['follower-username']}>
                          {follower.username}
                        </p>
                      </div>
                      <div
                        onClick={() =>
                          addUserToSelectedFollowersHandler(follower)
                        }
                        style={{
                          border: !selectedFollowerIds[follower._id]
                            ? '1px solid #999999'
                            : '1px solid #00B432',
                        }}
                        className={classes['add-container']}
                      >
                        <img
                          alt="Plus"
                          src={
                            selectedFollowerIds[follower._id]
                              ? GreenPlusIcon
                              : GrayPlusIcon
                          }
                          height={12}
                          className={classes['plus-icon']}
                        />
                      </div>
                    </div>
                    <div className={classes['horizontal-line']}></div>
                  </>
                );
              });
            })}
          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </Modal>
    </>
  );
}

export default SelectSpecificFriendsModal;
