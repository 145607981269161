import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { uploadGroupCoverPhotoRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './UploadCoverPhotoModal.module.css';

function UploadCoverPhotoModal(props) {
  const queryClient = useQueryClient();
  const {
    setShowUploadCoverPhotoModal,
    coverPhotoPreviewUrl,
    setCoverPhotoPreviewUrl,
    coverPhotoImageUrl,
    setCoverPhotoImageUrl,
    groupId,
  } = props;
  const [uploadingCoverPhoto, setUploadingCoverPhoto] = useState(false);

  const closeModalHandler = () => {
    setCoverPhotoPreviewUrl(undefined);
    setCoverPhotoImageUrl(undefined);
    setShowUploadCoverPhotoModal(false);
  };

  const invalidateGroupInformation = () => {
    queryClient.invalidateQueries(['groups', groupId]);
    queryClient.refetchQueries(['groups', groupId]);
  };

  const uploadCoverPhotoHandler = async () => {
    setUploadingCoverPhoto(true);
    const formData = new FormData();
    formData.append('coverPhoto', coverPhotoImageUrl);

    try {
      await uploadGroupCoverPhotoRequest(groupId, formData);

      invalidateGroupInformation();

      queryClient.invalidateQueries(['my-groups', '']);
      queryClient.refetchQueries(['my-groups', '']);

      setUploadingCoverPhoto(false);
      closeModalHandler();
    } catch (err) {
      setUploadingCoverPhoto(false);
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      title={`Upload Cover Photo`}
      closeModal={closeModalHandler}
      footer={
        <>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <span style={{ marginRight: 10 }}></span>
          <Button
            onClick={uploadCoverPhotoHandler}
            disabled={uploadingCoverPhoto}
            style={{ width: 200 }}
            darkpink="true"
          >
            Save
          </Button>
        </>
      }
    >
      <div className={classes['upload-image-container']}>
        <img
          alt="MyCover"
          src={coverPhotoPreviewUrl}
          className={classes['image']}
        />
      </div>
    </Modal>
  );
}

export default UploadCoverPhotoModal;
