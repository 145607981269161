import React, { useState } from 'react';
import classes from './CreateBrandItem.module.css';
import MetaTags from 'react-meta-tags';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import Input from '../../components/Input/Input';
import { isInputValidationCompleted, limitString } from '../../helpers/helpers';
import PinkRemoveIcon from '../../assets/pink-remove.png';
import TextArea from '../../components/TextArea/TextArea';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useDropzone } from 'react-dropzone';
import notify from '../../utils/notify';
import UploadImage from '../../assets/upload-nft.png';
import Button from '../../components/Button/Button';
import { createBrandItemRequest } from '../../httpRequests/httpRequests';

const trendingCategories = [
  'Art',
  'Collectibles',
  'Photography',
  'Sports',
  'Trading Cards',
  'Music',
  'Profile Picture',
  'Animals',
  'Nature',
  'Painting',
  '3D Abstract',
  'AI',
  'Meme',
  'Video',
];

const trendingTags = [
  'Nike',
  'Under',
  'Armour',
  'Lululemon',
  'Chick-fil-A',
  'Starbucks',
  'Apple',
  'Coca Cola',
  'McDonalds',
  'Disney',
  'Tiffany & Co.',
  'Gucci',
  'Rolex',
  'Adidas',
  'H&M',
  'RedBull',
  'Puma',
  'Jordan',
];

function CreateBrandItem() {
  const [formState, setFormState] = useState({
    title: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
    description: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
    price: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
  });

  const [categories, setCategories] = useState([]);
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const [categoryInputValueTouched, setCategoryInputValueTouched] = useState(
    ''
  );
  const [tags, setTags] = useState([]);
  const [tagInputValue, setTagInputValue] = useState('');
  const [tagInputValueTouched, setTagInputValueTouched] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  // const [previewUrlSize, setPreviewUrlSize] = useState();
  const [url, setUrl] = useState();
  const [isVideo, setIsVideo] = useState('');
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [creatingBrandItem, setCreatingBrandItem] = useState(false);

  const inputChangeHandler = (formKey, value) => {
    const updatedFormState = { ...formState };
    updatedFormState[formKey].value = value;
    updatedFormState[formKey].touched = true;
    updatedFormState[formKey].valid = isInputValidationCompleted(
      value,
      updatedFormState[formKey].validationRequirements
    );

    setFormState(updatedFormState);
  };

  const categoryChangeHandler = (e) => {
    setCategoryInputValue(e.target.value.trim());
    setCategoryInputValueTouched(true);
    if (
      e.target.value.indexOf(' ') !== -1 &&
      e.target.value.trim().length >= 1
    ) {
      setCategories((prevState) => [...prevState, categoryInputValue]);
      setCategoryInputValue('');
    }
  };

  const tagChangeHandler = (e) => {
    setTagInputValue(e.target.value.trim());
    setTagInputValueTouched(true);
    if (
      e.target.value.indexOf(' ') !== -1 &&
      e.target.value.trim().length >= 1
    ) {
      setTags((prevState) => [...prevState, tagInputValue]);
      setTagInputValue('');
    }
  };

  const removeCategoryHandler = (index) => {
    setCategories((prevState) => prevState.filter((_, i) => i !== index));
  };

  const removeTagHandler = (index) => {
    setTags((prevState) => prevState.filter((_, i) => i !== index));
  };

  const trendingCategoryClickHandler = (trendingCategory) => {
    if (categories.includes(trendingCategory)) {
      setCategories((prevState) =>
        prevState.filter((el) => el !== trendingCategory)
      );
    } else if (categories.length < 3) {
      setCategories((prevState) => [...prevState, trendingCategory]);
    }
  };

  const trendingTagClickHandler = (trendingTag) => {
    if (tags.includes(trendingTag)) {
      setTags((prevState) => prevState.filter((el) => el !== trendingTag));
    } else if (tags.length < 3) {
      setTags((prevState) => [...prevState, trendingTag]);
    }
  };

  const onDrop = (files) => {
    setLoadingUpload(true);
    if (files.length === 1) {
      if (files[0].size > 10000000) {
        notify('error', 'File size must be less than 10MB!', 3000);
        setLoadingUpload(false);
        return;
      }
      let ext = files[0].name.split('.');
      ext = ext[ext.length - 1];
      ext = ext.toLowerCase();

      if (
        ext !== 'jpg' &&
        ext !== 'JPG' &&
        ext !== 'jpeg' &&
        ext !== 'JPEG' &&
        ext !== 'png' &&
        ext !== 'PNG' &&
        ext !== 'mp4' &&
        ext !== 'MP4'
      ) {
        notify('error', 'Invalid file type', '', 2000);
        return;
      }
      if (ext === 'mp4' || ext === 'MP4') setIsVideo(true);
      else setIsVideo(false);

      // setPreviewUrlSize(files[0].size);
      setUrl(files[0]);

      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result);
        setLoadingUpload(false);
      };
      fileReader.readAsDataURL(files[0]);
    }
  };

  const createBrandItemHandler = async () => {
    setCreatingBrandItem(true);

    try {
      const formData = new FormData();
      formData.append('title', formState.title.value);
      formData.append('description', formState.description.value);
      formData.append('price', parseFloat(formState.price.value));
      formData.append('categories', JSON.stringify(categories));
      formData.append('tags', JSON.stringify(tags));
      formData.append('type', isVideo ? 'video' : 'image');
      formData.append('previewUrl', url);

      await createBrandItemRequest(formData);
    } catch (err) {
      notify('error', err, 2000);
    }

    setCreatingBrandItem(false);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const titleStyle = {};
  const categoryStyle = {};
  const descriptionStyle = {};
  const priceStyle = {};
  const tagStyle = {};

  if (formState.title.valid === false && formState.title.touched) {
    titleStyle['border'] = '1px solid red';
  }
  if (
    categoryInputValue.length === 0 &&
    categories.length === 0 &&
    categoryInputValueTouched
  ) {
    categoryStyle['border'] = '1px solid red';
  }
  if (formState.description.valid === false && formState.description.touched) {
    descriptionStyle.border = '1px solid red';
  }
  if (tagInputValue.length === 0 && tags.length === 0 && tagInputValueTouched) {
    tagStyle['border'] = '1px solid red';
  }
  if (
    formState.price.touched &&
    (!formState.price.value ||
      isNaN(formState.price.value) ||
      parseFloat(formState.price.value) < 5)
  ) {
    priceStyle['border'] = '1px solid red';
  }

  const isCreateButtonDisabled = () => {
    if (
      !formState.title.valid ||
      categories.length === 0 ||
      tags.length === 0 ||
      !formState.description.valid
    )
      return true;

    if (
      !formState.price.value ||
      isNaN(formState.price.value) ||
      parseFloat(formState.price.value) < 5
    )
      return true;

    if (!previewUrl) return true;

    if (creatingBrandItem) return true;

    return false;
  };

  return (
    <>
      <MetaTags>
        <title>Create Brand Item | Glimpse</title>
        <meta property="og:title" content={'Create Brand Item | Glimpse'} />
      </MetaTags>

      <div className={classes['create-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['form-container']}>
          <div className={classes['details-container']}>
            <h3>Enter Brand Item Details</h3>
            <p className={classes['title-text']}>Title</p>
            <Input
              style={{ width: '100%', ...titleStyle }}
              placeholder="Enter here"
              value={formState.title.value}
              onChange={(e) => inputChangeHandler('title', e.target.value)}
            />

            <div className={classes['categories-container']}>
              <p className={classes['category-text']}>
                Categories (Maximum 3 - Separate via Space)
              </p>
              <div className={classes['categories-input']}>
                {categories.map((category, i) => (
                  <div
                    title={`#${category}`}
                    key={category + i}
                    className={classes['category']}
                  >
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                        margin: 0,
                      }}
                    >
                      #{limitString(category, 16)}
                    </p>
                    <img
                      alt="Remove"
                      className={classes['remove-icon']}
                      onClick={() => removeCategoryHandler(i)}
                      src={PinkRemoveIcon}
                      width={8}
                      height={9}
                    />
                  </div>
                ))}
                <Input
                  style={{ width: '100%', ...categoryStyle }}
                  placeholder={categories.length === 3 ? '' : '#Enter here'}
                  value={categoryInputValue}
                  disabled={categories.length === 3}
                  onChange={categoryChangeHandler}
                />
              </div>

              <div className={classes['trending-categories']}>
                {trendingCategories.map((trendingCategory, i) => {
                  const containerStyle = {};
                  if (categories.includes(trendingCategory)) {
                    containerStyle['border'] = '1px solid #F900B7';
                  }
                  return (
                    <div
                      style={containerStyle}
                      onClick={() =>
                        trendingCategoryClickHandler(trendingCategory)
                      }
                      className={classes['trending-category-container']}
                      key={trendingCategory + i}
                    >
                      <p
                        style={{
                          color:
                            categories.includes(trendingCategory) && '#F900B7',
                        }}
                        className={classes['trending-category-text']}
                      >
                        {trendingCategory}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classes['categories-container']}>
              <p className={classes['category-text']}>
                Tags (Maximum 3 - Separate via Space)
              </p>
              <div className={classes['categories-input']}>
                {tags.map((tag, i) => (
                  <div
                    title={`#${tag}`}
                    key={tag + i}
                    className={classes['tag']}
                  >
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                        margin: 0,
                      }}
                    >
                      #{limitString(tag, 16)}
                    </p>
                    <img
                      alt="Remove"
                      className={classes['remove-icon']}
                      onClick={() => removeTagHandler(i)}
                      src={PinkRemoveIcon}
                      width={8}
                      height={9}
                    />
                  </div>
                ))}
                <Input
                  style={{ width: '100%', ...tagStyle }}
                  placeholder={tags.length === 3 ? '' : '#Enter here'}
                  value={tagInputValue}
                  disabled={tags.length === 3}
                  onChange={tagChangeHandler}
                />
              </div>

              <div className={classes['trending-tags']}>
                {trendingTags.map((trendingTag, i) => {
                  const containerStyle = {};
                  if (tags.includes(trendingTag)) {
                    containerStyle['border'] = '1px solid #F900B7';
                  }
                  return (
                    <div
                      style={containerStyle}
                      onClick={() => trendingTagClickHandler(trendingTag)}
                      className={classes['trending-tag-container']}
                      key={trendingTag + i}
                    >
                      <p
                        style={{
                          color: tags.includes(trendingTag) && '#F900B7',
                        }}
                        className={classes['trending-tag-text']}
                      >
                        {trendingTag}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classes['description-container']}>
              <p className={classes['description-text']}>Description</p>
              <TextArea
                placeholder="Enter here"
                style={{
                  ...descriptionStyle,
                  width: '100%',
                  minHeight: 80,
                }}
                value={formState.description.value}
                onChange={(e) =>
                  inputChangeHandler('description', e.target.value)
                }
              />
            </div>

            <p className={classes['description-text']}>Price</p>
            <Input
              type="number"
              style={{ width: '100%', ...priceStyle }}
              placeholder="Enter USD amount"
              value={formState.price.value}
              onChange={(e) => inputChangeHandler('price', e.target.value)}
            />

            <div className={classes['upload-container']}>
              <div className={classes['upload-img-container']}>
                <input {...getInputProps()} multiple={false} />
                <div>
                  {!previewUrl && !loadingUpload ? (
                    <div
                      {...getRootProps()}
                      className={classes['upload-container']}
                    >
                      <img
                        className={classes['upload-img']}
                        src={UploadImage}
                        alt="Upload"
                        width={60}
                      />
                      <p className={classes['click-to-upload-text']}>
                        Click To Upload Image/Video
                      </p>
                    </div>
                  ) : loadingUpload ? (
                    <LoadingSpinner />
                  ) : previewUrl && !loadingUpload ? (
                    <div>
                      {isVideo ? (
                        <video className={classes['nft-video']} controls>
                          <source src={previewUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          className={classes['nft-image']}
                          alt="Preview"
                          src={previewUrl}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <Button
              style={{ width: '100%', marginTop: 20 }}
              darkpink="true"
              disabled={isCreateButtonDisabled()}
              onClick={createBrandItemHandler}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateBrandItem;
