import React, { useEffect } from 'react';
import classes from './BrandItemViewContent.module.css';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getBrandItemRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import BrandItemContent from './components/BrandItemContent/BrandItemContent';

function BrandItemViewContent() {
  const { brandItemId } = useParams();
  const { isLoading, data, error } = useQuery(['brand-item', brandItemId], () =>
    getBrandItemRequest(brandItemId)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <>
      <div className={classes['brand-item-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        {!isLoading && data && data.data && (
          <BrandItemContent brandItem={data.data} />
        )}
      </div>
    </>
  );
}

export default BrandItemViewContent;
