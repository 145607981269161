import React from 'react';
import Button from '../../../../../../components/Button/Button';
import Input from '../../../../../../components/Input/Input';
import classes from './HolderBenefitScreen.module.css';

const POPULAR_BENEFITS = [
  '1/1 Chat (15mins)',
  '1/1 Video Call (15mins)',
  'Personalized Image',
  'Personalized Video',
];

function HolderBenefitScreen(props) {
  const { benefits, setBenefits, setScreenNumber } = props;

  const benefitChangeHandler = (index, value) => {
    const updatedBenefits = [...benefits];

    updatedBenefits[index] = {
      value,
      isValid: value.length > 0,
      touched: true,
    };

    setBenefits(updatedBenefits);
  };

  const addAnotherBenefitHandler = () => {
    setBenefits((prevState) => [
      ...prevState,
      {
        value: '',
        isValid: false,
        touched: false,
      },
    ]);
  };

  const addBenefitHandler = (benefit) => {
    setBenefits((prevState) => [
      ...prevState,
      {
        value: benefit,
        isValid: true,
        touched: true,
      },
    ]);
  };

  const removeBenefitHandler = (benefit) => {
    setBenefits((prevState) => prevState.filter((el) => el.value !== benefit));
  };

  const goBackHandler = () => {
    setScreenNumber((prevState) => prevState - 1);
  };

  const goToNextStepHandler = () => {
    setScreenNumber((prevState) => prevState + 1);
  };

  const isAddAnotherOptionDisabled = () =>
    benefits.some((benefit) => !benefit.isValid || !benefit.touched);

  const isNextButtonDisabled = () => {
    // if (benefits.length === 1) return false;

    return isAddAnotherOptionDisabled();
  };

  return (
    <>
      <div className={classes['popular-benefits-container']}>
        {POPULAR_BENEFITS.map((benefit, i) => {
          const isBenefitIncluded = benefits.some((el) => el.value === benefit);

          return (
            <div
              key={benefit + i}
              onClick={
                isBenefitIncluded
                  ? () => removeBenefitHandler(benefit)
                  : () => addBenefitHandler(benefit)
              }
              className={
                isBenefitIncluded
                  ? classes['selected-benefit-container']
                  : classes['benefit-container']
              }
            >
              <p>{benefit}</p>
            </div>
          );
        })}
      </div>
      <div className={classes['benefits-container']}>
        {benefits.map((benefit, i) => {
          if (POPULAR_BENEFITS.includes(benefit.value)) return null;

          const inputStyle = { width: '100%' };
          if (benefit.isValid === false && benefit.touched) {
            inputStyle['border'] = '1px solid red';
          }

          return (
            <div key={i}>
              <p className={classes['label']}>Custom Benefit</p>
              <Input
                style={inputStyle}
                placeholder="Enter here"
                value={benefit.value}
                onChange={(e) => benefitChangeHandler(i, e.target.value)}
              />
            </div>
          );
        })}
      </div>
      <Button
        onClick={addAnotherBenefitHandler}
        disabled={isAddAnotherOptionDisabled()}
        style={{ margin: '10px 0', width: '100%' }}
        darkpink="true"
      >
        + Add Another Benefit
      </Button>

      <div style={{ marginTop: 20 }}>
        {props.children}

        <div className={classes['footer']}>
          <Button
            style={{ flex: 1 }}
            darkpinkcolor="true"
            onClick={goBackHandler}
          >
            Go Back
          </Button>
          <Button
            disabled={isNextButtonDisabled()}
            onClick={goToNextStepHandler}
            style={{ flex: 1 }}
            darkpink="true"
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export default HolderBenefitScreen;
