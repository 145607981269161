import React from 'react';
import Button from '../../../../../../components/Button/Button';
import classes from './FourthScreen.module.css';
import ImageUpload from '../../../../../../components/ImageUpload/ImageUpload';
import notify from '../../../../../../utils/notify';
import SliderButton from '../../../../../../components/SliderButton/SliderButton';
import Input from '../../../../../../components/Input/Input';
import { useDropzone } from 'react-dropzone';
import ipfs from '../../../../../../ipfs';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import UploadImageIcon from '../../../../../../assets/upload-img-icon.png';
import RemoveIcon from '../../../../../../assets/gray-remove.png';
import UploadIcon from '../../../../../../assets/upload-icon.png';

function FourthScreen(props) {
  const {
    setScreenNumber,
    coverPhotoUrl,
    setCoverPhotoUrl,
    setCoverPhotoPreviewUrl,
    coverPhotoPreviewUrl,
    // isCollectionStatusPublic,
    // setIsCollectionStatusPublic,
    createCollectionHandler,
    creatingCollection,
    startingPrice,
    setStartingPrice,
    startingPriceTouched,
    setStartingPriceTouched,
    royalteFee,
    setRoyalteFee,
    royalteFeeTouched,
    setRoyalteFeeTouched,
    profilePhotoPreviewUrl,
    profilePhotoUrl,
    setProfilePhotoPreviewUrl,
    setProfilePhotoUrl,
    loadingIPFS,
    setLoadingIPFS,
    uploadedNfts,
    setUploadedNfts,
    editionSize,
    setEditionSize,
    editionSizeTouched,
    setEditionSizeTouched,
    claimable,
    isSingleUploadSelected,
    capped,
    setCapped,
  } = props;

  const goBackHandler = () => {
    setScreenNumber((prevState) => prevState - 1);
  };

  const coverPhotoInputHandler = (value) => {
    if (!value) return;
    if (value.size > 10000000)
      return notify(
        'error',
        'You can not upload more than 10MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setCoverPhotoUrl(value);

    try {
      fileReader.onload = () => {
        setCoverPhotoPreviewUrl(fileReader.result);
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const profilePhotoInputHandler = (value) => {
    if (!value) return;
    if (value.size > 10000000)
      return notify(
        'error',
        'You can not upload more than 10MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setProfilePhotoUrl(value);

    try {
      fileReader.onload = () => {
        setProfilePhotoPreviewUrl(fileReader.result);
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const onDrop = (files) => {
    if (isSingleUploadSelected && uploadedNfts.length > 0) return;

    setLoadingIPFS(true);
    if (files.length === 1) {
      if (files[0].size > 10000000) {
        notify('error', 'File size must be less than 10MB!', 3000);
        setLoadingIPFS(false);
        return;
      }
      let ext = files[0].name.split('.');
      ext = ext[ext.length - 1];
      ext = ext.toLowerCase();

      if (
        ext !== 'jpg' &&
        ext !== 'JPG' &&
        ext !== 'jpeg' &&
        ext !== 'JPEG' &&
        ext !== 'png' &&
        ext !== 'PNG' &&
        ext !== 'mp4' &&
        ext !== 'MP4'
      ) {
        notify('error', 'Invalid file type', 3000);
        setLoadingIPFS(false);
        return;
      }

      const previewUrlSize = files[0].size;
      const nftUrl = files[0];
      let previewUrl;

      let isVideo = false;
      if (ext === 'mp4' || ext === 'MP4') {
        isVideo = true;
      } else {
        isVideo = false;
      }

      const fileReader = new FileReader();
      fileReader.onload = () => {
        previewUrl = fileReader.result;
      };
      fileReader.readAsDataURL(files[0]);

      let reader = new FileReader();
      reader.readAsArrayBuffer(files[0]);

      reader.onloadend = async () => {
        try {
          const content = Buffer(reader.result);
          const added = await ipfs.add(content);
          const hash = added.cid.toString();

          if (
            !isSingleUploadSelected &&
            uploadedNfts.some((nft) => nft.ipfs === hash)
          ) {
            setLoadingIPFS(false);
            notify(
              'error',
              'You have already uploaded this file. Please, try a new one!',
              2000
            );
          } else {
            setLoadingIPFS(false);
            const nftData = {
              isVideo,
              previewUrl,
              url: nftUrl,
              previewUrlSize,
              ipfs: hash,
            };
            setUploadedNfts((prevState) => [nftData, ...prevState]);
          }
        } catch (err) {
          notify('error', err, 2000);
          setLoadingIPFS(false);
        }
      };
    } else {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 100000000) {
          notify('error', 'File size is bigger than 100MB', '', 2000);
          setLoadingIPFS(false);
          continue;
        }

        let ext = files[i].name.split('.');
        ext = ext[ext.length - 1];
        ext = ext.toLowerCase();

        if (
          ext !== 'jpg' &&
          ext !== 'JPG' &&
          ext !== 'jpeg' &&
          ext !== 'JPEG' &&
          ext !== 'png' &&
          ext !== 'PNG' &&
          ext !== 'mp4' &&
          ext !== 'MP4'
        ) {
          notify('error', 'Invalid file type', '', 2000);
          setLoadingIPFS(false);
          continue;
        }

        const previewUrlSize = files[i].size;
        const nftUrl = files[i];
        let previewUrl;

        let isVideo = false;
        if (ext === 'mp4' || ext === 'MP4') {
          isVideo = true;
        } else {
          isVideo = false;
        }

        const fileReader = new FileReader();
        fileReader.onload = () => {
          previewUrl = fileReader.result;
        };
        fileReader.readAsDataURL(files[i]);

        let reader = new FileReader();
        reader.readAsArrayBuffer(files[i]);

        reader.onloadend = async () => {
          try {
            const content = Buffer(reader.result);
            const added = await ipfs.add(content);
            const hash = added.cid.toString();

            setLoadingIPFS(false);
            const nftData = {
              isVideo,
              previewUrl,
              url: nftUrl,
              previewUrlSize,
              ipfs: hash,
            };
            setUploadedNfts((prevState) => [nftData, ...prevState]);
          } catch (err) {
            notify('error', err, 2000);
          }
        };
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const removeUploadedNft = (index) => {
    setUploadedNfts((prevState) => prevState.filter((_, i) => i !== index));
  };

  const startingPriceChangeHandler = (e) => {
    setStartingPrice(e.target.value);
    setStartingPriceTouched(true);
  };

  const royalteFeeChangeHandler = (e) => {
    let royalte = e.target.value;
    if (royalte.length >= 1) {
      royalte = royalte[0];
    }
    setRoyalteFee(royalte);
    setRoyalteFeeTouched(true);
  };

  const editionSizeChangeHandler = (e) => {
    setEditionSizeTouched(true);

    const editionSizeValue = isNaN(e.target.value)
      ? 1
      : parseInt(e.target.value);

    if (editionSizeValue > 10000) return;

    setEditionSize(e.target.value);
  };

  const isCreateCollectionButtonDisabled = () => {
    if (loadingIPFS) return true;

    if (creatingCollection) return true;

    if (
      isSingleUploadSelected &&
      (isNaN(editionSize) ||
        !editionSize ||
        parseInt(editionSize) < 1 ||
        parseInt(editionSize) > 10000)
    )
      return true;

    if (
      !claimable &&
      (!startingPrice || isNaN(startingPrice) || parseFloat(startingPrice) < 5)
    )
      return true;

    if (
      isNaN(royalteFee) ||
      parseInt(royalteFee) < 0 ||
      parseInt(royalteFee) > 5
    )
      return true;

    if (!profilePhotoUrl || !coverPhotoUrl) return true;
  };

  const startingPriceInputStyle = { width: '100%' };
  if (
    startingPriceTouched &&
    (!startingPrice || isNaN(startingPrice) || parseFloat(startingPrice) < 5)
  ) {
    startingPriceInputStyle['border'] = '1px solid red';
  }

  const royalteFeeInputStyle = { width: '100%' };
  if (
    royalteFeeTouched &&
    (isNaN(royalteFee) || parseInt(royalteFee) < 0 || parseInt(royalteFee) > 5)
  ) {
    royalteFeeInputStyle['border'] = '1px solid red';
  }

  const isEditionSizeInputDisabled = uploadedNfts.length !== 1;

  const editionSizeInputStyle = { width: '100%' };
  if (
    (isNaN(editionSize) || editionSize < 1 || editionSize > 10000) &&
    editionSizeTouched &&
    !isEditionSizeInputDisabled
  ) {
    editionSizeInputStyle['border'] = '1px solid red';
  }

  return (
    <div>
      {!claimable && (
        <>
          {' '}
          <p className={classes['label']}>
            {/* Starting Price (Min 500 GLMS) */}
            Starting Price (Min 5 USD)
            {/* {startingPrice ? (
              <span className={classes['dollar-equivalent-text']}>
                = ${parseFloat(startingPrice * glmsToUsd.price).toFixed(2)}
              </span>
            ) : (
              <span className={classes['dollar-equivalent-text']}>
                1 GLMS = .01 USD
              </span>
            )} */}
          </p>
          <Input
            placeholder="Enter USD amount"
            style={startingPriceInputStyle}
            type="number"
            value={startingPrice}
            onChange={startingPriceChangeHandler}
          />
        </>
      )}

      <p className={classes['label']}>Royalte Fee (0-5%)</p>
      <Input
        placeholder="Enter here"
        style={royalteFeeInputStyle}
        type="number"
        value={royalteFee}
        onChange={royalteFeeChangeHandler}
      />

      <div className={classes['profile-photo-cover-photo-container']}>
        <div className={classes['profile-photo']}>
          <p className={classes['cover-photo-text']}>Profile Photo</p>

          {!profilePhotoPreviewUrl ? (
            <ImageUpload onInput={profilePhotoInputHandler}>
              <div className={classes['no-profile-photo-container']}>
                <img
                  alt="Upload"
                  src={UploadIcon}
                  className={classes['upload-icon']}
                />
              </div>
            </ImageUpload>
          ) : (
            <ImageUpload onInput={profilePhotoInputHandler}>
              <div className={classes['profile-photo-container']}>
                <img
                  alt="Profile"
                  className={classes['profile-photo-image']}
                  src={profilePhotoPreviewUrl}
                />
              </div>
            </ImageUpload>
          )}
        </div>

        <div className={classes['cover-photo-status-container']}>
          <p className={classes['cover-photo-text']}>Cover Photo</p>
          {!coverPhotoUrl ? (
            <ImageUpload onInput={coverPhotoInputHandler}>
              <div className={classes['no-cover-photo-container']}>
                <img
                  alt="Upload"
                  src={UploadIcon}
                  className={classes['upload-icon']}
                />
              </div>
            </ImageUpload>
          ) : (
            <ImageUpload onInput={coverPhotoInputHandler}>
              <div className={classes['cover-photo-container']}>
                <img
                  alt="Cover"
                  className={classes['cover-photo-image']}
                  src={coverPhotoPreviewUrl}
                />
              </div>
            </ImageUpload>
          )}
        </div>
      </div>

      <div className={classes['uploaded-nfts-container']}>
        <p className={classes['upload-nfts-text']}>Upload NFT’s</p>
        <div className={classes['upload-file-and-nfts-container']}>
          <div className={classes['upload-img-container']}>
            <input
              disabled={isSingleUploadSelected && uploadedNfts.length > 0}
              {...getInputProps()}
            />
            <div>
              {loadingIPFS && <LoadingSpinner />}
              {!loadingIPFS && (
                <div
                  {...getRootProps()}
                  className={classes['upload-container']}
                >
                  <img
                    className={classes['upload-img']}
                    src={UploadImageIcon}
                    alt="Upload"
                    width={35}
                    height={40}
                  />
                  <p className={classes['drag-drop-text']}>Upload Files</p>
                </div>
              )}
            </div>
          </div>

          {uploadedNfts.slice(0, 3).map((nft, i) => {
            if (nft.isVideo) {
              return (
                <div
                  key={i}
                  style={{ marginTop: 5 }}
                  className={classes['nft-container']}
                >
                  <video className={classes['nft-video']} controls>
                    <source src={nft.previewUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div
                    onClick={() => removeUploadedNft(i)}
                    className={classes['remove-icon-container']}
                  >
                    {' '}
                    <img
                      alt="Remove"
                      src={RemoveIcon}
                      className={classes['remove-icon']}
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div key={i} className={classes['nft-container']}>
                  <img
                    className={classes['nft-image']}
                    alt="IPFS-hash"
                    src={nft.previewUrl}
                  />
                  <div
                    onClick={() => removeUploadedNft(i)}
                    className={classes['remove-icon-container']}
                  >
                    {' '}
                    <img
                      alt="Remove"
                      src={RemoveIcon}
                      className={classes['remove-icon']}
                    />
                  </div>
                </div>
              );
            }
          })}

          {uploadedNfts.length > 3 && (
            <div className={classes['more-nfts-container']}>
              <div className={classes['more-text-container']}>
                <h3 className={classes['more-text']}>
                  +{uploadedNfts.length - 3}
                </h3>{' '}
              </div>
            </div>
          )}
        </div>
      </div>

      {isSingleUploadSelected ? (
        <>
          <p className={classes['label']}>
            What is the Edition Size? (Max: 10000)
          </p>
          <Input
            style={editionSizeInputStyle}
            placeholder="Enter here"
            type="number"
            value={isEditionSizeInputDisabled ? '' : editionSize}
            onChange={editionSizeChangeHandler}
            disabled={isEditionSizeInputDisabled}
          />
        </>
      ) : (
        <div>
          <SliderButton
            leftValue={
              <p style={{ margin: 0, padding: 0, color: '#677074' }}>
                Limited Edition
              </p>
            }
            rightValue={
              <p style={{ margin: 0, padding: 0, color: '#677074' }}>
                Unlimited Edition
              </p>
            }
            isLeftContentSelected={capped}
            setIsLeftContentSelected={setCapped}
          />
          <div style={{ marginTop: 10 }}>
            <p className={classes['capped-description']}>
              Limited Edition means that No new NFT’s can be added to the
              collection after creation.
            </p>
            <p className={classes['capped-description']}>
              Unlimited Edition means more NFT’s can be added later.
            </p>
          </div>
        </div>
      )}

      <div>
        {props.children}
        <div className={classes['footer']}>
          <Button
            style={{ flex: 1 }}
            darkpinkcolor="true"
            onClick={goBackHandler}
          >
            Go Back
          </Button>
          <Button
            disabled={isCreateCollectionButtonDisabled()}
            onClick={createCollectionHandler}
            style={{ flex: 1 }}
            darkpink="true"
          >
            Create
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FourthScreen;
