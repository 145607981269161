import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import Input from '../../../../../../components/Input/Input';
import { useIntersectionObserver } from '../../../../../../hooks/useIntersectionObserver';
import { getGroupMembersExceptAdminsRequest } from '../../../../../../httpRequests/httpRequests';
import Member from '../Member/Member';
import classes from './MembersExceptAdmins.module.css';
import SearchIcon from '../../../../../../assets/search-icon.png';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import notify from '../../../../../../utils/notify';

const RESULTS_PER_PAGE = 10;

function MembersExceptAdmins(props) {
  const { groupId, isMyRoleAdmin } = props;
  const [searchValue, setSearchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['group-members-except-admins', groupId, searchValue],
    ({ pageParam = 1 }) =>
      getGroupMembersExceptAdminsRequest(
        groupId,
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className={classes['members-container']}>
      <div className={classes['input-container']}>
        <Input
          style={{ width: '100%', marginBottom: 10 }}
          placeholder="Search user"
          value={searchValue}
          onChange={searchValueChangeHandler}
        />
        <img
          alt="Search"
          src={SearchIcon}
          width={18}
          className={classes['search-icon']}
        />
      </div>

      <div className={classes['members-header']}>
        <h3 className={classes['others-text']}>Others</h3>
        <p className={classes['circle']}></p>
        <p className={classes['number-of-members']}>
          {data && data.pages ? data.pages[0].results : '--'}
        </p>
      </div>

      <div className={classes['members-list']}>
        {data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map(({ user, role }) => {
              return (
                <Member
                  groupId={groupId}
                  isMyRoleAdmin={isMyRoleAdmin}
                  key={user._id}
                  isGroupAdmin={role === 'admin'}
                  user={user}
                />
              );
            });
          })}

        {data && data.pages && data.pages[0].results === 0 && (
          <p>No Results Found</p>
        )}

        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default MembersExceptAdmins;
