import React from 'react';
import { Row, Col } from 'antd';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import classes from '../../UserNftsStatistics.module.css';
import { useHistory } from 'react-router-dom';

function NftsForSaleTable(props) {
  const history = useHistory();
  const {
    userNftsStatistics,
    searchNftInputValue,
    setSearchNftInputValue,
    isFetching,
    isLoading,
  } = props;

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-users-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-users-heading']}>USER NFTs Statistics</h2>
          <div>
            <input
              placeholder="Search For Users"
              style={{ width: 300 }}
              className="searchInput"
              value={searchNftInputValue}
              onChange={(e) => {
                setSearchNftInputValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={3} className={classes['pagination-col']}>
              Photo
            </Col>
            <Col md={3} className={classes['pagination-col']}>
              Full Name
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              NFTs Listed
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              NFTs Not Listed
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Total NFTs
            </Col>
          </Row>

          {userNftsStatistics && userNftsStatistics.length === 0 && (
            <h2 className="bold-text">No results found!</h2>
          )}

          {userNftsStatistics &&
            userNftsStatistics.map((el) => {
              return el.map(
                ({ owner, listedForBuyNow, notListedForBuyNow, totalNfts }) => {
                  return (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${owner._id}`)}
                      key={owner._id}
                    >
                      <hr />
                      <Row justify="space-between" style={{ padding: '1em 0' }}>
                        <Col md={3}>
                          <img
                            width={25}
                            height={25}
                            style={{ borderRadius: 100, objectFit: 'cover' }}
                            alt="User"
                            src={owner.photo}
                          />
                        </Col>
                        <Col md={3}>
                          {owner.firstName} {owner.lastName}
                        </Col>

                        <Col md={3}>{listedForBuyNow}</Col>
                        <Col md={3}>{notListedForBuyNow}</Col>

                        <Col md={3}>{totalNfts}</Col>
                      </Row>
                    </div>
                  );
                }
              );
            })}
          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default NftsForSaleTable;
