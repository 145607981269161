import PointsImage from '../assets/pointsImage.png';
import Button from '../components/Button/Button';

export const getWinningPointsContent = (
  winningPoints,
  message,
  closeNotificationHandler
) => {
  return (
    <div className={'notification-points-container'}>
      <img alt="Points" className="points-image" src={PointsImage} />
      <h3 className="you-won-points">
        You Won {winningPoints} {winningPoints === 1 ? 'Diamond' : 'Diamonds'}
      </h3>
      <p className={'notification-points-message'}>{message}</p>
      <Button
        style={{ width: 150, padding: '.75rem 1.2rem', borderRadius: 10 }}
        onClick={closeNotificationHandler}
        pink="true"
      >
        Congrats
      </Button>
    </div>
  );
};
