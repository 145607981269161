import { Col, Row } from 'antd';
import classes from './CreatorApplies.module.css';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { getAllCreatorAppliesRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { CSVLink } from 'react-csv';
import Button from '../../../../components/Button/Button';

function CreatorApplies() {
  const csvLinkEl = useRef();
  const history = useHistory();
  const { isLoading, data, error } = useQuery(
    'creator-applies',
    getAllCreatorAppliesRequest
  );

  const [creatorsCsv, setCreatorsCsv] = useState([]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const csvHandler = async () => {
    setCreatorsCsv([['Email'], ...data.data.map((user) => [user.email])]);
    setTimeout(() => {
      csvLinkEl.current.link.click();
    });
  };

  return (
    <div className={classes['all-events-container']}>
      <div className={classes['heading-container']}>
        <h2 className={classes['all-events-heading']}>
          CREATOR APPLIES = {data?.results}
        </h2>
        {data?.data && (
          <>
            <Button onClick={csvHandler} pinkcolor="true">
              Export CSV
            </Button>
            <CSVLink
              filename="Creators.csv"
              data={creatorsCsv}
              ref={csvLinkEl}
            />
          </>
        )}
      </div>
      <div
        style={{ minHeight: '85vh' }}
        id="table-nft"
        className={classes['likes-container']}
      >
        <Row justify="space-between">
          <Col
            style={{ paddingLeft: '4%' }}
            md={3}
            className={classes['pagination-col']}
          >
            Email
          </Col>

          <Col md={2} className={classes['pagination-col']}>
            First Name + Last Name
          </Col>

          <Col md={2} className={classes['pagination-col']}>
            User
          </Col>

          <Col md={3} className={classes['pagination-col']}>
            Main Social Channel URL
          </Col>

          <Col md={1} className={classes['pagination-col']}>
            Number Of Followers
          </Col>
          <Col md={4} className={classes['pagination-col']}>
            Description
          </Col>
          <Col md={1} className={classes['pagination-col']}>
            Apply Status
          </Col>
        </Row>

        {data?.data &&
          data.data.map(
            ({
              email,
              user,
              firstName,
              lastName,
              mainSocialChannelUrl,
              numberOfFollowersInMainSocialChannel,
              description,
              applyStatus,
            }) => {
              return (
                <div key={email}>
                  <hr />
                  <Row justify="space-between" style={{ padding: '1em 0' }}>
                    <Col md={3}>{email}</Col>
                    <Col md={2}>{`${firstName} ${lastName}`}</Col>

                    {user ? (
                      <Col
                        md={2}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          cursor: 'pointer',
                        }}
                        onClick={() => history.push(`/user-stats/${user._id}`)}
                      >
                        <img
                          width={25}
                          height={25}
                          style={{ borderRadius: 100, objectFit: 'cover' }}
                          alt="User"
                          src={user.photo}
                        />
                        <p>{user.fullName}</p>
                      </Col>
                    ) : (
                      <Col md={2}>-</Col>
                    )}

                    <Col md={4}>
                      <a
                        href={mainSocialChannelUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {mainSocialChannelUrl}
                      </a>
                    </Col>

                    <Col md={1}>{numberOfFollowersInMainSocialChannel}</Col>

                    <Col md={4}>{description}</Col>
                    <Col md={1}>{applyStatus}</Col>
                  </Row>
                </div>
              );
            }
          )}

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default CreatorApplies;
