import React, { useState } from 'react';
import classes from './GroupInformation.module.css';
import Button from '../../../../components/Button/Button';
import { limitString } from '../../../../helpers/helpers';
import { useQuery } from 'react-query';
import {
  getAllGroupMembersForASpecificGroupRequest,
  getNumberOfMembersForASpecificGroupRequest,
  getSpecificGroupRequest,
  joinPublicGroupRequest,
} from '../../../../httpRequests/httpRequests';
import Discussion from '../Discussion/Discussion';
import notify from '../../../../utils/notify';
import { useHistory } from 'react-router-dom';
import ImageModal from '../../../../components/ImageModal/ImageModal';
import MetaTags from 'react-meta-tags';
import GroupAbout from '../GroupAbout/GroupAbout';

const GROUP_SECTIONS = ['Discussion'];

function GroupInformation(props) {
  const history = useHistory();
  const { group: selectedGroup } = props;
  const [selectedGroupSection, setSelectedGroupSection] = useState(
    GROUP_SECTIONS[0]
  );
  const [showCoverPhotoImageModal, setShowCoverPhotoImageModal] = useState(
    false
  );
  const [joiningGroup, setJoiningGroup] = useState(false);

  const groupInformationResponse = useQuery(['groups', selectedGroup._id], () =>
    getSpecificGroupRequest(selectedGroup._id)
  );

  const first10GroupMembersResponse = useQuery(
    ['first-10-group-members', selectedGroup._id],
    () =>
      getAllGroupMembersForASpecificGroupRequest(1, 10, selectedGroup._id, '')
  );

  const numberOfMembersResponse = useQuery(
    ['number-of-members', selectedGroup._id],
    () => getNumberOfMembersForASpecificGroupRequest(selectedGroup._id)
  );

  const joinPublicGroupHandler = async () => {
    setJoiningGroup(true);

    try {
      await joinPublicGroupRequest(selectedGroup._id);
      setJoiningGroup(false);

      history.push(`/my-groups?id=${selectedGroup._id}`);
    } catch (err) {
      setJoiningGroup(false);

      notify('error', err, 2000);
    }
  };

  return (
    <>
      <MetaTags>
        <title>{selectedGroup.name} Group | Glimpse</title>
        <meta
          property="og:title"
          content={`${selectedGroup.name} Group | Glimpse`}
        />
      </MetaTags>

      {showCoverPhotoImageModal && (
        <ImageModal
          title="Cover Photo"
          setShowImageModal={setShowCoverPhotoImageModal}
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={groupInformationResponse.data?.data.coverPhoto}
            alt="Cover"
          />
        </ImageModal>
      )}

      <div className={classes['selected-group-container']}>
        {groupInformationResponse.data?.data.coverPhoto ? (
          <div className={classes['cover-photo-container']}>
            <img
              onClick={() => setShowCoverPhotoImageModal(true)}
              alt={'CoverPhoto'}
              className={classes['cover-photo']}
              src={groupInformationResponse.data.data.coverPhoto}
            />

            <div className={classes['group-information']}>
              <div>
                <div className={classes['members-privacy-container']}>
                  {groupInformationResponse.data?.data && (
                    <h3
                      title={groupInformationResponse.data.data.name}
                      className={classes['group-name']}
                    >
                      {limitString(groupInformationResponse.data.data.name, 25)}
                    </h3>
                  )}
                  <p className={classes['circle']}></p>
                  <p className={classes['group-privacy']}>
                    {selectedGroup.status.substring(0, 1).toUpperCase() +
                      selectedGroup.status.substring(1)}{' '}
                    Group
                  </p>
                </div>

                {first10GroupMembersResponse &&
                  first10GroupMembersResponse.data?.data && (
                    <div className={classes['profile-images-container']}>
                      <p className={classes['members-text']}>
                        {numberOfMembersResponse.data &&
                        numberOfMembersResponse.data?.data
                          ? `${numberOfMembersResponse.data.data} Members`
                          : '-- Members'}
                      </p>
                      {first10GroupMembersResponse.data.data.map(
                        ({ user }, i) => {
                          return (
                            <div key={user._id}>
                              <img
                                style={{ zIndex: 11 - i }}
                                className={classes['user-image']}
                                src={user.photo}
                                alt="Member"
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
              </div>

              <Button
                style={{
                  width: 150,
                  border: '1px solid white',
                  marginRight: 40,
                  alignSelf: 'flex-end',
                }}
                disabled={joiningGroup}
                onClick={joinPublicGroupHandler}
                darkpink="true"
              >
                Join
              </Button>
            </div>
          </div>
        ) : (
          <div className={classes['no-cover-photo']}>
            <div className={classes['group-information']}>
              <div>
                <div className={classes['members-privacy-container']}>
                  {groupInformationResponse.data?.data && (
                    <h3
                      title={groupInformationResponse.data.data.name}
                      className={classes['group-name']}
                      style={{
                        color: '#263238',
                      }}
                    >
                      {limitString(groupInformationResponse.data.data.name, 25)}
                    </h3>
                  )}
                  <p className={classes['circle']}></p>
                  <p className={classes['group-privacy']}>
                    {selectedGroup.status.substring(0, 1).toUpperCase() +
                      selectedGroup.status.substring(1)}{' '}
                    Group
                  </p>
                </div>

                {first10GroupMembersResponse &&
                  first10GroupMembersResponse.data?.data && (
                    <div className={classes['profile-images-container']}>
                      <p className={classes['members-text']}>
                        {numberOfMembersResponse.data &&
                        numberOfMembersResponse.data?.data
                          ? `${numberOfMembersResponse.data.data} Members`
                          : '-- Members'}
                      </p>
                      {first10GroupMembersResponse.data.data.map(
                        ({ user }, i) => {
                          return (
                            <div key={user._id}>
                              <img
                                style={{ zIndex: 11 - i }}
                                className={classes['user-image']}
                                src={user.photo}
                                alt="Member"
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
              </div>

              <Button
                style={{
                  width: 150,
                  border: '1px solid white',
                  marginRight: 40,
                  alignSelf: 'flex-end',
                }}
                onClick={joinPublicGroupHandler}
                darkpink="true"
              >
                Join
              </Button>
            </div>
          </div>
        )}

        <div className={classes['group-sections']}>
          {GROUP_SECTIONS.map((section) => {
            return (
              <div
                onClick={() => setSelectedGroupSection(section)}
                key={section}
                className={classes['section-container']}
              >
                <div
                  className={`${classes['section-text']} ${
                    section === selectedGroupSection
                      ? classes['selected-section']
                      : ''
                  }`}
                >
                  {section}

                  {section === selectedGroupSection && (
                    <div className={classes['selected-border']}>&nbsp;</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes['horizontal-line']}></div>

        <div className={classes['discussion-about-container']}>
          {selectedGroupSection === 'Discussion' && (
            <Discussion groupId={selectedGroup._id} />
          )}
          <GroupAbout selectedGroup={selectedGroup} />
        </div>
      </div>
    </>
  );
}

export default GroupInformation;
