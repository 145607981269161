import React, { useEffect, useRef, useState } from 'react';
import classes from './NftCollection.module.css';
import VerifiedIcon from '../../assets/verified.png';
import { Link, useHistory } from 'react-router-dom';
import { limitString } from '../../helpers/helpers';
// import moment from 'moment';
import CollectionGroupIcon from '../../assets/collection-group-icon.png';
import { useQuery } from 'react-query';
import { getTopNftsForSpecificCollectionRequest } from '../../httpRequests/httpRequests';
import NftImage from './components/NftImage/NftImage';
import RightIcon from '../../assets/right-icon.png';

function NftCollection(props) {
  const { nftCollection } = props;
  const nftCollectionContainerRef = useRef();
  const history = useHistory();

  const { isLoading, data } = useQuery(
    ['top-12-nfts-for-specific-collection', nftCollection._id],
    () =>
      getTopNftsForSpecificCollectionRequest(
        nftCollection._id,
        1,
        12,
        '',
        true,
        'high-low'
      )
  );
  const [sliceStart, setSliceStart] = useState(0);
  const [sliceEnd, setSliceEnd] = useState(4);

  // const [
  //   remainingDaysUntilLiveDate,
  //   setRemainingDaysUntilLiveDate,
  // ] = useState();
  // const [
  //   remainingHoursUntilLiveDate,
  //   setRemainingHoursUntilLiveDate,
  // ] = useState();
  // const [
  //   remainingMinutesUntilLiveDate,
  //   setRemainingMinutesUntilLiveDate,
  // ] = useState();
  // const [
  //   remainingSecondsUntilLiveDate,
  //   setRemainingSecondsUntilLiveDate,
  // ] = useState();

  // const updateRemainingHoursAndMinutes = useCallback(() => {
  //   const duration = moment.duration(moment(nftCollection.liveDate).diff());
  //   if (duration < 0) return;
  //   const remainingDays = parseInt(duration.asDays());
  //   const remainingHours = (duration.asDays() - remainingDays) * 24;
  //   const remainingMinutes = (remainingHours - parseInt(remainingHours)) * 60;

  //   const realSeconds = duration.asSeconds();
  //   setRemainingDaysUntilLiveDate(remainingDays);

  //   setRemainingHoursUntilLiveDate(parseInt(remainingHours));
  //   setRemainingMinutesUntilLiveDate(parseInt(remainingMinutes));
  //   setRemainingSecondsUntilLiveDate(
  //     parseInt(
  //       (realSeconds - (duration.asMinutes() - remainingMinutes) * 60) % 60
  //     )
  //   );
  // }, [nftCollection]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //    updateRemainingHoursAndMinutes();
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [updateRemainingHoursAndMinutes]);

  // const renderNftCollectionLiveDate = () => {
  //   if (moment(nftCollection.liveDate).diff() > 0) {
  //     return (
  //       <div className={classes['new-collection-container']}>
  //         <p className={classes['new-collection-text']}>
  //           <span className={classes['days']}>
  //             {remainingDaysUntilLiveDate} D
  //           </span>{' '}
  //           :{' '}
  //           <span className={classes['hours']}>
  //             {remainingHoursUntilLiveDate} H
  //           </span>{' '}
  //           :{' '}
  //           <span className={classes['hours']}>
  //             {remainingMinutesUntilLiveDate} M
  //           </span>{' '}
  //           :{' '}
  //           <span className={classes['hours']}>
  //             {remainingSecondsUntilLiveDate} S
  //           </span>{' '}
  //         </p>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className={classes['live-container']}>
  //         <p className={classes['live-text']}>LIVE</p>
  //       </div>
  //     );
  //   }
  // };

  const goNextHandler = () => {
    if (sliceEnd <= data.data.length - 4) {
      setSliceStart((prevState) => prevState + 4);
      setSliceEnd((prevState) => prevState + 4);
    } else {
      setSliceStart(0);
      setSliceEnd(4);
    }
  };

  useEffect(() => {
    const nftCollectionContainerRefElement = nftCollectionContainerRef.current;
    if (!nftCollectionContainerRefElement) return;

    const goToCollectionPage = (e) => {
      if (
        e.target?.className?.startsWith(
          'NftCollection_nft-collection-container'
        )
      ) {
        history.push(`/collections/${nftCollection._id}`);
      }
    };

    nftCollectionContainerRefElement.addEventListener(
      'click',
      goToCollectionPage
    );
  }, [nftCollectionContainerRef, history, nftCollection._id]);

  return (
    <div
      ref={nftCollectionContainerRef}
      className={classes['nft-collection-container']}
    >
      <Link to={`/collections/${nftCollection._id}`}>
        <div className={classes['profile-photo-creator-container']}>
          <img
            className={classes['profile-photo']}
            src={nftCollection.profilePhoto}
            alt="ProfilePhoto"
          />

          <div className={classes['creator-container']}>
            <p
              title={nftCollection.name}
              className={classes['nft-collection-name']}
            >
              {limitString(nftCollection.name, 24)}
            </p>
            <p className={classes['creator-username']}>
              by @{nftCollection.creator.username}
              {nftCollection.creator.verified && (
                <img
                  alt="Verified"
                  src={VerifiedIcon}
                  className={classes['verified-icon']}
                  height={12}
                />
              )}
            </p>
          </div>
        </div>
      </Link>

      <div className={classes['group-type-container']}>
        <div className={classes['group-container']}>
          {nftCollection.associatedGroups?.length > 0 ? (
            <Link
              to={`/groups/${nftCollection.associatedGroups[0]._id}`}
              className={classes['glimpse-collection-link-container']}
            >
              <img
                className={classes['link-image']}
                src={CollectionGroupIcon}
                alt="Link"
                width={14}
              />
              <p
                title={nftCollection.associatedGroups[0].name}
                className={classes['associated-group-text']}
              >
                {limitString(nftCollection.associatedGroups[0].name, 12)}
              </p>
            </Link>
          ) : (
            <div className={classes['glimpse-collection-link-container']}>
              <p className={classes['associated-group-text']}>Public</p>
            </div>
          )}
        </div>

        <div className={classes['type-container']}>
          {nftCollection.capped ? (
            <p className={classes['limited-text']}>Limited</p>
          ) : (
            <p className={classes['unlimited-text']}>Unlimited</p>
          )}
        </div>
        {/* <div className={classes['live-date-container']}>
            {renderNftCollectionLiveDate()}
          </div> */}

        <div className={classes['nft-list']}>
          {data?.data && data.data.length > 8 && (
            <div onClick={goNextHandler} className={classes['next-icon']}>
              <img
                src={RightIcon}
                alt="Next"
                className={classes['right-icon']}
              />
            </div>
          )}
          {!isLoading &&
            data?.data &&
            data.data
              .slice(sliceStart, sliceEnd)
              .map(({ nft }) => <NftImage nft={nft} key={nft._id} />)}

          {data?.data && data.data.length > 8 && (
            <div onClick={goNextHandler} className={classes['next-icon-2']}>
              <img
                src={RightIcon}
                alt="Next"
                className={classes['right-icon']}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NftCollection;
