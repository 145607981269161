import React from 'react';
import classes from './Button.module.css';

function Button(props) {
  let buttonClassName = '';
  if (props.pink) {
    buttonClassName = classes['pink'];
  }

  if (props.pinkcolor) {
    buttonClassName = classes['pink-color'];
  }

  if (props.pinkgraydisabled) {
    buttonClassName = classes['pink-gray-disabled-color'];
  }

  if (props.opacitypink) {
    buttonClassName = classes['opacity-pink'];
  }

  if (props.gray) {
    buttonClassName = classes['gray'];
  }

  if (props.reject) {
    buttonClassName = classes['reject'];
  }

  if (props.pinkcolorwhitebackground) {
    buttonClassName = classes['pink-color-white-background'];
  }

  if (props.modernpink) {
    buttonClassName = classes['modern-pink'];
  }

  if (props.modernpinkcolor) {
    buttonClassName = classes['modern-pink-color'];
  }

  if (props.darkpink) {
    buttonClassName = classes['dark-pink'];
  }

  if (props.darkpinkcolor) {
    buttonClassName = classes['dark-pink-color'];
  }

  return (
    <button className={`${classes['button']} ${buttonClassName}`} {...props}>
      {props.children}
    </button>
  );
}

export default Button;
