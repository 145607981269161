import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Modal from '../../../../components/Modal/Modal';
import { listNftForBuyNowRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './ListBuyNowModal.module.css';

const GLMS_TO_USD_PRICE = 0.01;

function ListBuyNowModal(props) {
  const queryClient = useQueryClient();
  const { nft, setShowListBuyNowModal } = props;
  const [buyNowPrice, setBuyNowPrice] = useState('');
  const [buyNowTouched, setBuyNowTouched] = useState(false);
  const [buyNowValid, setBuyNowValid] = useState(false);
  const [listingNft, setListingNft] = useState(false);

  const closeModalHandler = () => {
    setShowListBuyNowModal(false);
  };

  const buyNowPriceChangeHandler = (e) => {
    const buyNowValue = e.target.value;

    setBuyNowPrice(buyNowValue);
    setBuyNowTouched(true);
    setBuyNowValid(
      !!buyNowValue && !isNaN(buyNowValue) && parseFloat(buyNowValue) >= 5
    );
  };

  const invalidateNft = () => {
    queryClient.invalidateQueries(['nfts', nft._id]);
    queryClient.refetchQueries(['nfts', nft._id]);
  };

  const listBuyNowHandler = async () => {
    setListingNft(true);

    try {
      const requestBody = {
        buyNowPrice: parseFloat(buyNowPrice / GLMS_TO_USD_PRICE),
      };
      await listNftForBuyNowRequest(nft._id, requestBody);
      await invalidateNft();

      setListingNft(false);
      closeModalHandler();
    } catch (error) {
      setListingNft(false);
      notify('error', error, 2000);
    }
  };

  const buyNowInputStyle = { width: '100%' };
  if (!buyNowValid && buyNowTouched) {
    buyNowInputStyle['border'] = '1px solid red';
  }

  return (
    <>
      <Modal
        title="List Buy Now"
        closeModal={closeModalHandler}
        footer={
          <div className={classes['footer']}>
            <Button
              style={{ width: 200 }}
              disabled={listingNft}
              darkpinkcolor="true"
              onClick={closeModalHandler}
            >
              Cancel
            </Button>
            <Button
              style={{ width: 200 }}
              darkpink="true"
              disabled={!buyNowValid || listingNft}
              onClick={listBuyNowHandler}
            >
              List
            </Button>
          </div>
        }
      >
        <div>
          <p className={classes['label']}>Price</p>
          <Input
            style={buyNowInputStyle}
            placeholder="Enter USD amount"
            type={'number'}
            value={buyNowPrice}
            onChange={buyNowPriceChangeHandler}
          />
        </div>
      </Modal>
    </>
  );
}

export default ListBuyNowModal;
