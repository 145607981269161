import React, { useState } from 'react';

const notificationContext = {
  showNotificationHandler: (_notificationContent, _timeout) => {},
  closeNotificationHandler: () => {},
};

export const NotificationContext = React.createContext(notificationContext);

const NotificationProvider = (props) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState(null);

  const showNotificationHandler = (notificationContent, timeout) => {
    setNotificationContent(notificationContent);
    setShowNotification(true);

    setTimeout(() => {
      closeNotificationHandler();
    }, timeout);
  };

  const closeNotificationHandler = () => {
    setShowNotification(false);
    setNotificationContent(null);
  };

  return (
    <NotificationContext.Provider
      value={{
        showNotificationHandler,
        closeNotificationHandler,
      }}
    >
      {props.children}
      {showNotification && notificationContent !== null && notificationContent}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
