import React from 'react';
import classes from './Groups.module.css';
import RecentActivity from './components/RecentActivity/RecentActivity';
import SuggestedGroups from './components/SuggestedGroups/SuggestedGroups';
import MetaTags from 'react-meta-tags';
import UserSidebar from '../../components/UserSidebar/UserSidebar';

function Groups() {
  return (
    <>
      <MetaTags>
        <title>Groups | Glimpse</title>
        <meta property="og:title" content={`Groups | Glimpse`} />
      </MetaTags>

      <div className={classes['groups-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['recent-activity-container']}>
          <RecentActivity />
        </div>

        <div className={classes['suggested-groups-container']}>
          <SuggestedGroups />
        </div>
      </div>
    </>
  );
}

export default Groups;
