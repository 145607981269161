import React, { useContext, useRef } from 'react';
import classes from './MarketPlaceContent.module.css';
import { useEffect, useState } from 'react';
import Nft from '../../components/Nft/Nft';
import { BUTTON_TYPES } from './MarketPlace';
import NftsInAuction from './NftsInAuction';
import NewNftsContent from './NewNftsContent';
import NftsWithOffers from './NftsWithOffers';
import { getNftsForSpecificCategoryRequest } from '../../httpRequests/httpRequests';
import { useInfiniteQuery } from 'react-query';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import notify from '../../utils/notify';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NftsBuyNow from './NftsBuyNow';
import ArrowDownIcon from '../../assets/gray-down-icon.png';
import { limitString } from '../../helpers/helpers';
import { AuthContext } from '../../context/auth-context';
import BrightDownIcon from '../../assets/bright-down-icon.png';

const RESULTS_PER_PAGE = 12;

const MarketPlaceContent = React.memo((props) => {
  const sortContainerRef = useRef();
  const categoryOptionsContainerRef = useRef();
  const [searchValue, setSearchValue] = useState('');

  const [showSortOptions, setShowSortOptions] = useState(false);

  const [isFeaturedChecked] = useState(true);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const [showCategoryOptions, setShowCategoryOptions] = useState(false);

  const { profile } = useContext(AuthContext);

  const {
    categories,
    selectedCategoryName,
    setSelectedCategoryName,
    sortByValue,
    sortByValues,
    isSafeContentSelected,
    page,
    setPage,
    nftsInAuctionPage,
    selectedButtonType,
    setSortByValue,
  } = props;

  const sortBy = sortByValues.find((el) => el.value === sortByValue);
  let sortByFilter = 'ending-soon';

  if (sortBy?.sortByValue === 'Price low to high') {
    sortByFilter = 'low-high';
  } else if (sortBy?.sortByValue === 'Price high to low') {
    sortByFilter = 'high-low';
  } else if (sortBy?.sortByValue === 'Ending Soon') {
    sortByFilter = 'ending-soon';
  } else if (sortBy?.sortByValue === 'Most recent') {
    sortByFilter = 'most-recent';
  }

  let typeFilter = 'auction';
  if (selectedButtonType === BUTTON_TYPES.NEW) {
    typeFilter = 'new';
  } else if (selectedButtonType === BUTTON_TYPES.HAS_OFFERS) {
    typeFilter = 'has-offers';
  } else if (selectedButtonType === BUTTON_TYPES.BUY_NOW) {
    typeFilter = 'buy-now';
  }

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [
      'nfts-for-specific-category',
      page,
      sortByFilter,
      searchValue,
      selectedCategoryName,
      typeFilter,
      isSafeContentSelected,
      isFeaturedChecked,
      isAllChecked,
    ],
    ({ pageParam = 1 }) =>
      getNftsForSpecificCategoryRequest({
        pageParam,
        sortByFilter,
        searchValue,
        category: selectedCategoryName,
        typeFilter,
        isSafeContentSelected,
        loggedInUserId: profile?._id,
        isFeaturedChecked,
        isAllChecked,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
    }
  );

  useEffect(() => {
    const checkForClosingSortOptions = (e) => {
      if (e.target.closest('#sorting-container') !== sortContainerRef.current) {
        setShowSortOptions(false);
      }
    };

    window.addEventListener('click', checkForClosingSortOptions);

    return () =>
      window.removeEventListener('click', checkForClosingSortOptions);
  }, [sortContainerRef]);

  useEffect(() => {
    error && notify('error', error, 3000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const categoryChangeHandler = (category) => {
    setSelectedCategoryName(category);
    setPage(1);
    setShowCategoryOptions(false);
  };

  const renderCategories = () => {
    return (
      <div className={classes['categories-container']}>
        <div
          className={classes['selected-category-option-container']}
          onClick={() => setShowCategoryOptions((prevState) => !prevState)}
        >
          <p className={classes['category-value']}>
            Category:{' '}
            <span
              title={selectedCategoryName}
              className={classes['selected-category-value']}
            >
              {selectedCategoryName && limitString(selectedCategoryName, 10)}
            </span>
          </p>
          <img
            style={{ marginLeft: 'auto' }}
            width={13}
            alt="See"
            src={BrightDownIcon}
          />
        </div>

        {showCategoryOptions && (
          <div
            ref={categoryOptionsContainerRef}
            className={classes['category-options-container']}
          >
            {categories.map((category) => {
              let style = {};
              if (category === selectedCategoryName) {
                style = {
                  color: '#263238 ',
                  fontWeight: 500,
                };
              }

              return (
                <p
                  title={`#${category}`}
                  onClick={() => categoryChangeHandler(category)}
                  style={style}
                  className={classes['category-text']}
                  key={category}
                >
                  {`#${limitString(category, 16)}`}
                </p>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderResultsWithAllCategories = () => {
    if (selectedButtonType === BUTTON_TYPES.NEW) {
      return (
        <NewNftsContent
          isSafeContentSelected={isSafeContentSelected}
          searchValue={searchValue}
          sortByValue={sortByValue}
          sortByValues={sortByValues}
        />
      );
    } else if (selectedButtonType === BUTTON_TYPES.AUCTION) {
      return (
        <NftsInAuction
          isSafeContentSelected={isSafeContentSelected}
          searchValue={searchValue}
          page={nftsInAuctionPage}
          sortByValue={sortByValue}
          sortByValues={sortByValues}
        />
      );
    } else if (selectedButtonType === BUTTON_TYPES.HAS_OFFERS) {
      return (
        <NftsWithOffers
          isSafeContentSelected={isSafeContentSelected}
          searchValue={searchValue}
          sortByValue={sortByValue}
          sortByValues={sortByValues}
        />
      );
    } else if (selectedButtonType === BUTTON_TYPES.BUY_NOW) {
      return (
        <NftsBuyNow
          isSafeContentSelected={isSafeContentSelected}
          searchValue={searchValue}
          page={nftsInAuctionPage}
          sortByValue={sortByValue}
          sortByValues={sortByValues}
          isFeaturedChecked={isFeaturedChecked}
          isAllChecked={isAllChecked}
        />
      );
    }
  };

  return (
    <div className={classes['marketplace-content-container']}>
      <div className={classes['search-featured-container']}>
        <div className={classes['input-container']}>
          <input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search here..."
            className={classes['search-input']}
          />
        </div>

        <div
          ref={sortContainerRef}
          id="sorting-container"
          onClick={() => setShowSortOptions((prevState) => !prevState)}
          className={classes['sorting-container']}
        >
          <p className={classes['sorting-text']}>{sortBy['showValue']} </p>
          <img alt="Arrow" src={ArrowDownIcon} width={14} height={8} />

          {showSortOptions && (
            <div className={classes['sort-options']}>
              {sortByValues
                .filter(({ showValue }) => showValue !== sortBy['showValue'])
                .map(({ value, showValue }) => {
                  return (
                    <p
                      key={showValue}
                      onClick={() => setSortByValue(value)}
                      className={classes['sort-option']}
                    >
                      {showValue}
                    </p>
                  );
                })}
            </div>
          )}
        </div>

        <div className={classes['featured-all-filter-container']}>
          <div className={classes['featured-filter-container']}>
            <input
              disabled
              checked={true}
              className={classes['checkbox']}
              type="checkbox"
            />
            <p>Featured</p>
          </div>

          <div className={classes['all-filter-container']}>
            <input
              checked={isAllChecked}
              onChange={() => setIsAllChecked((prevState) => !prevState)}
              className={classes['checkbox']}
              type="checkbox"
            />
            <p onClick={() => setIsAllChecked((prevState) => !prevState)}>
              All
            </p>
          </div>
        </div>

        {renderCategories()}
      </div>

      <div>
        {selectedCategoryName !== 'All' ? (
          <>
            <div className={classes['marketplace-list']}>
              {!isLoading &&
                data?.pages &&
                data.pages[0].results > 0 &&
                data.pages.map((page) => {
                  return page.data.map((nft) => {
                    return <Nft key={nft._id} nft={nft} owner={nft.owner} />;
                  });
                })}
            </div>

            {(isLoading || isFetching) && <LoadingSpinner />}

            {!isLoading && data && data?.pages[0].results === 0 && (
              <div
                style={{
                  background: 'white',
                  padding: '.8rem',
                  borderRadius: 10,
                  marginTop: 10,
                  width: '95%',
                  gridColumn: '1/-1',
                }}
              >
                <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
                  No NFTs found with the provided filters!
                </p>
              </div>
            )}
          </>
        ) : (
          renderResultsWithAllCategories()
        )}
      </div>
    </div>
  );
});

export default MarketPlaceContent;
