import React, { useContext, useEffect, useState } from 'react';
import UAuth from '@uauth/js';
import classes from './SignupWithUnstoppable.module.css';
import notify from '../../../../utils/notify';
import { AuthContext } from '../../../../context/auth-context';
import UnstoppableIcon from '../../../../assets/unstoppable-icon.png';

function SignupWithUnstoppable() {
  const { signupWithUnstoppable } = useContext(AuthContext);
  const [user, setUser] = useState();
  const [authorization, setAuthorization] = useState();
  const [error, setError] = useState(null);

  const uauth = new UAuth({
    clientID: '408b7e18-f0d9-46b9-afd8-097ffa8770f9',
    redirectUri: `${window.location.origin}/register`,
    scope: `openid wallet email:optional`,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    if (!user || !authorization) return;

    const handleSignupWithUnstoppable = async () => {
      try {
        const user = await uauth.user();

        const nonce = authorization.idToken.nonce;
        const rawToken = authorization.idToken.__raw;
        const domainName = user.sub;
        const unstoppableWalletAddress = authorization.idToken.wallet_address;
        const unstoppableEmail = authorization.idToken.email;

        await signupWithUnstoppable({
          nonce,
          rawToken,
          domainName,
          unstoppableWalletAddress,
          unstoppableEmail,
        });
      } catch (err) {
        notify('error', err, 2000);
      }
    };

    handleSignupWithUnstoppable();
    // eslint-disable-next-line
  }, [user, authorization]);

  const signupWithUnstoppableHandler = async () => {
    uauth
      .loginWithPopup()
      .then(setAuthorization)
      .then(() =>
        uauth.user().then((user) => {
          setUser(user);
        })
      )
      .catch(setError);
  };

  return (
    <div
      onClick={signupWithUnstoppableHandler}
      className={classes['signup-unstoppable-button']}
    >
      <img
        src={UnstoppableIcon}
        className={classes['unstoppable-icon']}
        alt="Unstoppable"
      />
      <p className={classes['signup-unstoppable-text']}>
        Sign Up With Unstoppable
      </p>
    </div>
  );
}

export default SignupWithUnstoppable;
