import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { io } from 'socket.io-client';
import {
  JOIN_ROOM,
  JOIN_USER_ID,
  MESSAGE_CREATED,
  NOTIFICATION,
  OFFER_CREATED,
  OFFLINE,
  ONLINE_USERS,
  MESSAGE_READ,
  MESSAGE_RECEIVED,
  EMIT_NFT_MESSAGE_MENTION,
  CHAT_MESSAGE_MENTION,
  TAG_NOTIFICATION,
  EMIT_POST_MENTION,
  WINNING_POINTS,
  EMIT_USER_POST_COMMENT_MENTION,
  EMIT_USER_POST_COMMENT_REPLY_MENTION,
  EMIT_GROUP_POST_MENTION,
  NFT_UPDATE,
  UPDATING_NFT_PRICE,
  UNLISTING_NFT,
  // LISTING_NFT_FOR_BUY_NOW,
  PROCESSING_BUY_NOW,
  PROCESSING_NFT_OFFER,
  BRAND_ITEM_UPDATE,
} from '../constants/SOCKET_EVENTS';
import {
  // markAllDeliveredChatMessagesAsReceivedRequest,
  markChatMessageAsReceivedRequest,
} from '../httpRequests/httpRequests';
import notify from '../utils/notify';
import { AuthContext } from './auth-context';
import { NotificationContext } from './notification-context';
import { getWinningPointsContent } from '../utils/notifications';

const socketContext = {
  socket: null,
  onlineUsers: {},
  joinRoom: (_roomId) => {},
  setJoinedUserId: () => {},
};

export const SocketContext = React.createContext(socketContext);

const SocketProvider = (props) => {
  const queryClient = useQueryClient();
  const { showNotificationHandler, closeNotificationHandler } = useContext(
    NotificationContext
  );

  const [socket, setSocket] = useState(null);
  const [joinedUserId, setJoinedUserId] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState({});
  const {
    profile,
    getMyFollowers,
    getMyFollowings,
    getMyNfts,
    getNumberOfUnreadMessagesForMyChats,
    getMyWatchlist,
    getNumberOfUnreadNotifications,
    myWatchlist,
    getMyWalletInformation,
    getMyProfile,
    getMyLockedGLMSBalance,
  } = useContext(AuthContext);
  const profileId = profile ? profile?._id : undefined;

  const joinRoom = useCallback(
    (roomId) => {
      socket.emit(JOIN_ROOM, roomId);
    },
    [socket]
  );

  useEffect(() => {
    if (!profileId || joinedUserId) return;

    const SERVER_ENDPOINT =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : '/';
    const socket = io(SERVER_ENDPOINT, {
      transports: ['websocket'],
    });

    socket.emit(JOIN_USER_ID, profileId);

    setSocket(socket);
    setJoinedUserId(true);

    socket.on('connect', () => {
      socket.on(NOTIFICATION, async ({ message, type, entityId }) => {
        if (type === 'nft-failed-creation-on-blockchain') {
          getNumberOfUnreadNotifications();
          return notify('error', message, 2500);
        }

        if (type === 'nft-failed-to-unlock-for-sale-on-blockchain') {
          getNumberOfUnreadNotifications();
          return notify('error', message, 2500);
        }

        if (type === 'failed-to-create-offer-on-blockchain') {
          getNumberOfUnreadNotifications();
          return notify('error', message, 2500);
        }

        if (type === 'successful-transfer-glms-on-blockchain') {
          getMyWalletInformation();
        }

        if (type === 'transfered-glms-on-blockchain') {
          getMyWalletInformation();
        }

        if (type === 'buy-glms-success') {
          getMyWalletInformation();
        }

        if (type === 'buy-diamonds-success') {
          getMyProfile();
        }

        if (type === 'created-offer-on-blockchain') {
          queryClient.invalidateQueries(['nft-offers', entityId]);
          queryClient.refetchQueries(['nft-offers', entityId]);

          const isNftInMyWatchlist = myWatchlist.some(
            ({ nft }) => nft._id === entityId
          );
          if (isNftInMyWatchlist) getMyWatchlist();
        }

        if (
          type === 'nft-unlocked-for-sale-on-blockchain' ||
          type === 'unlisted-nft-for-buy-now-on-blockchain' ||
          type === 'failed-to-unlist-nft-for-buy-now-on-blockchain'
        ) {
          queryClient.invalidateQueries(['nfts', entityId]);
          queryClient.refetchQueries(['nfts', entityId]);
        }

        notify('success', message, 3000);

        getNumberOfUnreadNotifications();
        queryClient.invalidateQueries('my-notifications');
        queryClient.refetchQueries('my-notifications');

        if (type === 'follow' || type === 'unfollow') {
          getMyFollowers();
        }
        if (type === 'remove-follower') {
          getMyFollowings();
        }
        if (type === 'like-nft' || type === 'watchlist-nft') {
          getMyNfts();
        }
        if (type === 'close-auction-seller' || type === 'close-auction-buyer') {
          getMyNfts();
          if (entityId) {
            queryClient.refetchQueries(['nfts', entityId]);
            queryClient.invalidateQueries(['nfts', entityId]);
            queryClient.invalidateQueries(['nft-offers', entityId]);
            queryClient.refetchQueries(['nft-offers', entityId]);
          }

          queryClient.invalidateQueries('groupedNftsByCategoriesForSale');
          queryClient.refetchQueries('groupedNftsByCategoriesForSale');

          queryClient.invalidateQueries(['nfts-with-offers', 1]);
          queryClient.refetchQueries(['nfts-with-offers', 1]);
          queryClient.invalidateQueries(['nfts-in-auction', 1]);
          queryClient.refetchQueries(['nfts-in-auction', 1]);

          queryClient.invalidateQueries('nfts-associated-with-events');
          queryClient.refetchQueries('nfts-associated-with-events');

          queryClient.invalidateQueries('new-nfts-associated-with-events');
          queryClient.refetchQueries('new-nfts-associated-with-events');
        }
        if (type === 'close-auction-seller-no-offers') {
          if (entityId) {
            queryClient.refetchQueries(['nfts', entityId]);
            queryClient.invalidateQueries(['nfts', entityId]);
          }

          queryClient.invalidateQueries('groupedNftsByCategoriesForSale');
          queryClient.refetchQueries('groupedNftsByCategoriesForSale');

          queryClient.invalidateQueries(['nfts-with-offers', 1]);
          queryClient.refetchQueries(['nfts-with-offers', 1]);
          queryClient.invalidateQueries(['nfts-in-auction', 1]);
          queryClient.refetchQueries(['nfts-in-auction', 1]);

          queryClient.invalidateQueries('nfts-associated-with-events');
          queryClient.refetchQueries('nfts-associated-with-events');

          queryClient.invalidateQueries('new-nfts-associated-with-events');
          queryClient.refetchQueries('new-nfts-associated-with-events');
        }

        if (type === 'offer-outbidded') {
          getMyWatchlist();
        }

        if (
          type === 'rejected-nft-request-from' ||
          type === 'rejected-nft-request-for'
        ) {
          queryClient.invalidateQueries(['my-requested-nfts']);
          queryClient.refetchQueries(['my-requested-nfts']);
          getMyLockedGLMSBalance();
        }

        if (type === 'nft-offer-rejected') {
          getMyLockedGLMSBalance();
        }

        if (
          type === 'listed-nft-for-buy-now-on-blockchain' ||
          type === 'updated-nft-buy-now-price-on-blockchain' ||
          type === 'failed-to-update-nft-buy-now-price-on-blockchain' ||
          type === 'sold-nft-with-buy-now' ||
          type === 'bought-nft-with-buy-now' ||
          type === 'failed-to-list-nft-for-buy-now-on-blockchain'
        ) {
          queryClient.invalidateQueries(['nfts', entityId]);
          queryClient.refetchQueries(['nfts', entityId]);
        }
      });

      socket.on(OFFER_CREATED, ({ nftId }) => {
        queryClient.invalidateQueries(['nft-offers', nftId]);
        queryClient.refetchQueries(['nft-offers', nftId]);
      });

      socket.on(
        MESSAGE_CREATED,
        async ({ chatMessage, sender, chatName, isDirectMessage }) => {
          if (window.location.pathname !== '/chats') {
            await markChatMessageAsReceivedRequest(
              chatMessage.chat,
              chatMessage._id
            );

            queryClient.invalidateQueries(['chat-messages', chatMessage.chat]);
            queryClient.refetchQueries(['chat-messages', chatMessage.chat]);
            getNumberOfUnreadMessagesForMyChats();

            let notifyMessage = `${sender} just sent a message (${chatMessage.message}) to one of your chats (${chatName})`;
            if (isDirectMessage) {
              notifyMessage = `${sender} just sent you a direct message (${chatMessage.message})`;
            }
            notify('success', notifyMessage, 2000);
          }
        }
      );

      socket.on(ONLINE_USERS, (onlineUsers) => {
        setOnlineUsers(onlineUsers);
      });

      socket.on(MESSAGE_RECEIVED, (chatMessage) => {
        queryClient.invalidateQueries(['chat-messages', chatMessage.chat]);
        queryClient.refetchQueries(['chat-messages', chatMessage.chat]);
      });

      socket.on(MESSAGE_READ, (chatMessage) => {
        queryClient.invalidateQueries(['chat-messages', chatMessage.chat]);
        queryClient.refetchQueries(['chat-messages', chatMessage.chat]);
      });

      socket.on(EMIT_POST_MENTION, async ({ postAuthor }) => {
        await Promise.all([
          queryClient.invalidateQueries('my-notifications'),
          queryClient.refetchQueries('my-notifications'),
        ]);
        getNumberOfUnreadNotifications();
        notify(
          'success',
          `You have been mentioned in a post by ${postAuthor}!`,
          2000
        );
      });

      socket.on(EMIT_GROUP_POST_MENTION, async ({ postAuthor }) => {
        await Promise.all([
          queryClient.invalidateQueries('my-notifications'),
          queryClient.refetchQueries('my-notifications'),
        ]);
        getNumberOfUnreadNotifications();
        notify(
          'success',
          `You have been mentioned in a Group Post by ${postAuthor}!`,
          2000
        );
      });

      socket.on(EMIT_USER_POST_COMMENT_MENTION, async (author) => {
        await Promise.all([
          queryClient.invalidateQueries('my-notifications'),
          queryClient.refetchQueries('my-notifications'),
        ]);
        getNumberOfUnreadNotifications();
        notify(
          'success',
          `You have been mentioned in a comment by ${author.fullName}!`,
          2000
        );
      });

      socket.on(EMIT_USER_POST_COMMENT_REPLY_MENTION, async (author) => {
        await Promise.all([
          queryClient.invalidateQueries('my-notifications'),
          queryClient.refetchQueries('my-notifications'),
        ]);
        getNumberOfUnreadNotifications();
        notify(
          'success',
          `You have been mentioned in a reply by ${author.fullName}!`,
          2000
        );
      });

      socket.on(EMIT_NFT_MESSAGE_MENTION, async ({ nft }) => {
        notify(
          'success',
          `You have been mentioned in (${nft.title}) NFT chat!`,
          2000
        );

        await Promise.all([
          queryClient.invalidateQueries('my-notifications'),
          queryClient.refetchQueries('my-notifications'),
        ]);
        getNumberOfUnreadNotifications();
      });

      socket.on(CHAT_MESSAGE_MENTION, async ({ chatName }) => {
        notify('success', `You have been mentioned in ${chatName} chat!`, 2000);
        await Promise.all([
          queryClient.invalidateQueries('my-notifications'),
          queryClient.refetchQueries('my-notifications'),
        ]);
        getNumberOfUnreadNotifications();
      });

      socket.on(TAG_NOTIFICATION, ({ tagCreator, entity }) => {
        getNumberOfUnreadNotifications();

        if (entity.name === 'user-post') {
          notify(
            'success',
            `You have been tagged by ${tagCreator.Name} in one of his posts!`,
            2000
          );
        }

        if (entity.name === 'user-life-event') {
          notify(
            'success',
            `You have been tagged by ${tagCreator.Name} in one of his Life Events. Congratulations!`,
            2000
          );
        }
      });

      socket.on(
        WINNING_POINTS,
        ({ winningPoints, message, soldOrBoughtNft }) => {
          if (soldOrBoughtNft === true) {
            getMyWalletInformation();
            getMyLockedGLMSBalance();
            queryClient.invalidateQueries(['my-received-nft-offers']);
            queryClient.refetchQueries(['my-received-nft-offers']);
            // refresh points
            getMyProfile();
          }

          showNotificationHandler(
            getWinningPointsContent(
              winningPoints,
              message,
              closeNotificationHandler
            ),
            5000
          );
        }
      );

      [
        NFT_UPDATE,
        UPDATING_NFT_PRICE,
        UNLISTING_NFT,
        // LISTING_NFT_FOR_BUY_NOW,
        PROCESSING_BUY_NOW,
        PROCESSING_NFT_OFFER,
      ].forEach((event) => {
        socket.on(event, (nftId) => {
          queryClient.invalidateQueries('nft-posts');
          queryClient.refetchQueries('nft-posts');
        });
      });

      socket.on(BRAND_ITEM_UPDATE, (brandItemId) => {
        queryClient.invalidateQueries(['brand-item', brandItemId]);
        queryClient.refetchQueries(['brand-item', brandItemId]);
      });
    });

    const beforeUnLoadHandler = (e) => {
      e.preventDefault();
      socket.emit(OFFLINE, profileId);
    };

    window.onbeforeunload = beforeUnLoadHandler;
    // eslint-disable-next-line
  }, [joinedUserId, profileId]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        onlineUsers,
        joinRoom,
        setJoinedUserId,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
