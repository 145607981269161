import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { CoinMarketCapContext } from '../../context/coinmarketcap-context';
import { getFormattedNumber } from '../../helpers/helpers';
import { updateMyNftOfferRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import classes from './UpdateNftOfferPriceModal.module.css';

function UpdateNftOfferPriceModal(props) {
  const queryClient = useQueryClient();
  const { glmsToUsd } = useContext(CoinMarketCapContext);
  const { setShowUpdateNftOfferPriceModal, nftOffer } = props;

  const [newOfferPrice, setNewOfferPrice] = useState();
  const [newOfferPriceTouched, setNewOfferPriceTouched] = useState();
  const [newOfferPriceValid, setNewOfferPriceValid] = useState(false);
  const [updatingNftOfferPrice, setUpdatingNftOfferPrice] = useState(false);

  const closeModalHandler = () => {
    setShowUpdateNftOfferPriceModal(false);
  };

  const newOfferPriceChangeHandler = (e) => {
    setNewOfferPriceTouched(true);

    if (
      !e.target.value ||
      isNaN(e.target.value) ||
      parseFloat(e.target.value) < 5
    ) {
      setNewOfferPriceValid(false);
    } else {
      setNewOfferPriceValid(true);
    }
    setNewOfferPrice(e.target.value);
  };

  const updateMyNftOfferHandler = async () => {
    setUpdatingNftOfferPrice(true);

    try {
      const requestBody = {
        price: parseFloat(newOfferPrice / glmsToUsd.price),
      };
      await updateMyNftOfferRequest(nftOffer._id, requestBody);

      await Promise.all([
        queryClient.invalidateQueries(['my-sent-nft-offers']),
        queryClient.refetchQueries(['my-sent-nft-offers']),
      ]);

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setUpdatingNftOfferPrice(false);
  };

  const newOfferPriceInputStyle = { width: '100%' };
  if (newOfferPriceTouched && !newOfferPriceValid) {
    newOfferPriceInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Update Offer"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            disabled={updatingNftOfferPrice}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={updatingNftOfferPrice || !newOfferPriceValid}
            onClick={updateMyNftOfferHandler}
            darkpink="true"
          >
            Confirm
          </Button>
        </div>
      }
    >
      <>
        <div className={classes['current-offer-container']}>
          <p>Current Offer</p>
          <p className={classes['offer-price-container']}>
            {/* <span className={classes['offer-price-text']}>
              {getFormattedNumber(nftOffer.price)} GLMS
            </span>
            <span className={classes['usd-price']}>
              $
              {getFormattedNumber(
                (nftOffer.price * glmsToUsd.price).toFixed(2)
              )}
            </span> */}
            <span className={classes['usd-price']}>
              {' '}
              {getFormattedNumber(
                (nftOffer.price * glmsToUsd.price).toFixed(2)
              )}{' '}
              USD
            </span>
          </p>
        </div>

        <p className={classes['label']}>New Offer (Min 5 USD)</p>
        <Input
          style={newOfferPriceInputStyle}
          type="number"
          value={newOfferPrice}
          placeholder="Enter USD amount"
          onChange={newOfferPriceChangeHandler}
        />

        <div className={classes['new-offer-container']}>
          <p>New Offer</p>
          <p className={classes['offer-price-container']}>
            <span className={classes['offer-price-text']}>
              {isNaN(newOfferPrice) ? '--' : getFormattedNumber(newOfferPrice)}{' '}
              USD
            </span>
            {/* <span className={classes['usd-price']}>
              $
              {isNaN(newOfferPrice)
                ? '--'
                : getFormattedNumber(
                    (newOfferPrice * glmsToUsd.price).toFixed(2)
                  )}
            </span> */}
          </p>
        </div>
      </>
    </Modal>
  );
}

export default UpdateNftOfferPriceModal;
