import React, { useState } from 'react';
import classes from './CollaboratorRequests.module.css';
import MetaTags from 'react-meta-tags';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import ReceivedCollaboratorNftRequests from './components/ReceivedCollaboratorNftRequests/ReceivedCollaboratorNftRequests';
import SentCollaboratorNftRequests from './components/SentCollaboratorNftRequests/SentCollaboratorNftRequests';
import ReceivedCollaboratorNftUploadRequests from './components/ReceivedCollaboratorNftUploadRequests/ReceivedCollaboratorNftUploadRequests';
import SentCollaboratorNftUploadRequests from './components/SentCollaboratorNftUploadRequests/SentCollaboratorNftUploadRequests';
import GoBackIcon from '../../assets/go-back-icon-2.png';
import { useHistory } from 'react-router-dom';

const COLLABORATOR_REQUESTS_SECTIONS = [
  'Received - Existing NFTs',
  'Received - New NFTs',
  'Sent - Existing NFTs',
  'Sent - New NFTs',
];

function CollaboratorRequests() {
  const history = useHistory();
  const [
    selectedCollaboratorRequestSection,
    setSelectedCollaboratorRequestSection,
  ] = useState('Received - Existing NFTs');

  return (
    <>
      <MetaTags>
        <title>Collaborator Requests | Glimpse</title>
        <meta property="og:title" content={'Collaborator Requests | Glimpse'} />
      </MetaTags>

      <div className={classes['collaborator-requests-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['collaborator-requests-container']}>
          <header className={classes['collaborator-requests-header']}>
            <div className={classes['go-back-container']}>
              <div onClick={() => history.goBack()}>
                <img
                  src={GoBackIcon}
                  alt="Go Back"
                  className={classes['go-back-icon']}
                />
              </div>
              <h3>Collaborator NFT Requests</h3>
            </div>

            <div className={classes['collaborator-requests-options-container']}>
              {COLLABORATOR_REQUESTS_SECTIONS.map((option) => (
                <div
                  key={option}
                  onClick={() => setSelectedCollaboratorRequestSection(option)}
                  className={classes['collaborator-requests-option-container']}
                >
                  <p
                    className={`${
                      classes['collaborator-requests-option-text']
                    } ${
                      option === selectedCollaboratorRequestSection &&
                      classes['collaborator-requests-option-text-selected']
                    }`}
                  >
                    {option}
                  </p>
                </div>
              ))}
            </div>
          </header>

          {selectedCollaboratorRequestSection ===
            'Received - Existing NFTs' && <ReceivedCollaboratorNftRequests />}

          {selectedCollaboratorRequestSection === 'Sent - Existing NFTs' && (
            <SentCollaboratorNftRequests />
          )}

          {selectedCollaboratorRequestSection === 'Received - New NFTs' && (
            <ReceivedCollaboratorNftUploadRequests />
          )}

          {selectedCollaboratorRequestSection === 'Sent - New NFTs' && (
            <SentCollaboratorNftUploadRequests />
          )}
        </div>
      </div>
    </>
  );
}

export default CollaboratorRequests;
