import React from 'react';
import Button from '../../../../components/Button/Button';
import classes from './Events.module.css';

function Events(props) {
  return (
    <div className={classes['events-page-container']}>
      <div className={classes['events-header']}>
        <h3>Upcoming Events</h3>
        <div>
          <Button pink="true">Create Event</Button>
        </div>
      </div>
    </div>
  );
}

export default Events;
