import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import LoadingSpinner from '../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import {
  getDateFrom,
  isValidUrl,
} from '../../../../../../../../helpers/helpers';
import { getAllRepliesForSpecificUserPostCommentRequest } from '../../../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../../../utils/notify';
import classes from './PostCommentReplies.module.css';
import PostCommentReplyLikeText from '../PostCommentReplyLikeText/PostCommentReplyLikeText';
import { AuthContext } from '../../../../../../../../context/auth-context';
import { Link } from 'react-router-dom';
import VerifiedIcon from '../../../../../../../../assets/verified.png';

const RESULTS_PER_PAGE = 3;

function PostCommentReplies(props) {
  const { profile } = useContext(AuthContext);
  const { commentId } = props;
  const { isLoading, data, error } = useQuery(
    ['user-post-comment-replies', commentId],
    () => getAllRepliesForSpecificUserPostCommentRequest(commentId)
  );

  const [sliceEnd, setSliceEnd] = useState(RESULTS_PER_PAGE);

  const viewMoreRepliesHandler = () => {
    setSliceEnd((prevSliceEnd) => prevSliceEnd + RESULTS_PER_PAGE);
  };

  useEffect(() => {
    error && notify('error', error, 1000);
  }, [error]);

  const renderReply = (reply, mentions) => {
    return reply.split('\n').map((sentence, index) => {
      if (sentence === '')
        return (
          <p key={sentence + index} className={classes['empty-space']}>
            &nbsp;
          </p>
        );

      return (
        <div
          style={{ overflowWrap: 'break-word', fontSize: 12, color: '#677074' }}
          key={sentence + index}
        >
          {sentence.split(' ').map((word, i) => {
            if (isValidUrl(word)) {
              return (
                <span key={word + i}>
                  <span> </span>
                  <a
                    href={
                      word.startsWith('https://') ? word : `https://${word}`
                    }
                    target="_blank"
                    style={{
                      cursor: 'pointer',
                      color: '#0094ff',
                      overflowWrap: 'anywhere',
                    }}
                    key={word + i}
                    rel="noreferrer"
                  >
                    {word}
                  </a>
                </span>
              );
            }

            const mentionIndex = mentions.findIndex(
              ({ mentionUsername }) => mentionUsername === word
            );

            if (mentionIndex !== -1) {
              const mentionId = mentions[mentionIndex]?.data?.id;
              return (
                <span key={word + i}>
                  <span> </span>{' '}
                  <Link
                    to={
                      mentionId === profile?._id
                        ? '/my-space'
                        : `/user-feed/${mentions[mentionIndex]?.data?.id}`
                    }
                    className={classes['mention']}
                    key={word + i}
                  >
                    {word}
                  </Link>
                </span>
              );
            } else {
              return (
                <span key={word + i}>
                  <span> </span>
                  <span
                    style={{
                      color: word.startsWith('#') && '#F900B7',
                      overflowWrap: 'anywhere',
                    }}
                    key={word + i}
                  >
                    {word}
                  </span>
                </span>
              );
            }
          })}
        </div>
      );
    });
  };
  return (
    <div className={classes['replies-container']}>
      {data &&
        data.data
          .slice(0, sliceEnd)
          .map(({ _id: replyId, reply, user: author, createdAt, mentions }) => {
            return (
              <div key={replyId} className={classes['post-comment-container']}>
                <Link
                  to={
                    author._id === profile?._id
                      ? '/my-space'
                      : `/user-feed/${author._id}`
                  }
                >
                  <img
                    alt={author.fullName}
                    src={author.photo}
                    className={classes['user-image']}
                  />
                </Link>

                <div className={classes['post-comment-like-reply']}>
                  <div className={classes['comment-container']}>
                    <Link
                      to={
                        author._id === profile?._id
                          ? '/my-space'
                          : `/user-feed/${author._id}`
                      }
                    >
                      <p className={classes['user-fullName']}>
                        {author.fullName}
                        {author.verified && (
                          <img
                            alt="Verified"
                            src={VerifiedIcon}
                            style={{ marginLeft: 6, height: 17 }}
                          />
                        )}
                      </p>
                    </Link>{' '}
                    {renderReply(reply, mentions)}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 5,
                      margin: 0,
                    }}
                  >
                    <PostCommentReplyLikeText replyId={replyId} />
                    <p className={classes['createdAt']}>
                      {getDateFrom(createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}

      {isLoading && <LoadingSpinner />}

      {data && data.results > sliceEnd && (
        <p
          className={classes['view-more-replies']}
          onClick={viewMoreRepliesHandler}
        >
          View more replies
        </p>
      )}
    </div>
  );
}

export default PostCommentReplies;
