import React from 'react';
import Modal from '../Modal/Modal';
import User from '../User/User';
import classes from './TaggedUsersModal.module.css';

function TaggedUsersModal(props) {
  const { taggedUsers, setShowTaggedUsersModal } = props;

  return (
    <Modal
      closeModal={() => setShowTaggedUsersModal(false)}
      title="Tagged Users"
      closeIcon="true"
      style={{ width: 600, height: 600 }}
    >
      <div className={classes['tagged-users-container']}>
        {taggedUsers.map((user) => (
          <User
            key={user._id}
            user={user}
            closeModal={() => setShowTaggedUsersModal(false)}
          />
        ))}
      </div>
    </Modal>
  );
}

export default TaggedUsersModal;
