import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import Button from '../../../../../../../../components/Button/Button';
import Modal from '../../../../../../../../components/Modal/Modal';
import { createUserImageAlbumRequest } from '../../../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../../../utils/notify';
import classes from './CreateAlbumModal.module.css';
import Input from '../../../../../../../../components/Input/Input';

function CreateAlbum(props) {
  const queryClient = useQueryClient();
  const { setShowCreateAlbumModal } = props;
  const [albumName, setAlbumName] = useState('');
  const [albumNameTouched, setAlbumNameTouched] = useState(false);
  const [albumNameValid, setAlbumNameValid] = useState(false);

  const closeModalHandler = () => {
    setShowCreateAlbumModal(false);
  };

  const albumNameChangeHandler = (e) => {
    setAlbumName(e.target.value);
    setAlbumNameTouched(true);
    setAlbumNameValid(e.target.value.length > 0);
  };

  const createAlbumHandler = async () => {
    try {
      const requestBody = {
        name: albumName,
      };
      await createUserImageAlbumRequest(requestBody);
      queryClient.invalidateQueries('my-user-image-albums');
      queryClient.refetchQueries('my-user-image-albums');
      closeModalHandler();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const albumInputStyle = { width: '100%' };
  if (!albumNameValid && albumNameTouched) {
    albumInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            darkpink="true"
            onClick={createAlbumHandler}
            disabled={!albumNameValid}
          >
            Create
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Create Album"
    >
      <div>
        <p className={classes['label']}>Album Name</p>
        <Input
          style={albumInputStyle}
          onChange={albumNameChangeHandler}
          placeholder="Enter here"
          value={albumName}
        />
      </div>
    </Modal>
  );
}

export default CreateAlbum;
