import React, {
  useContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Modal from '../../../../../../../../components/Modal/Modal';
import { AuthContext } from '../../../../../../../../context/auth-context';
import { SocketContext } from '../../../../../../../../context/socket-context';
import classes from './CreatePostModal.module.css';
import SelectPostStatusModal from '../SelectPostStatusModal/SelectPostStatusModal';
import notify from '../../../../../../../../utils/notify';
import TagUsersModal from '../TagUsersModal/TagUsersModal';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import {
  createNotificationToMultipleUsersRequest,
  createPayablePostRequest,
  createUserGroupPostRequest,
  createUserPostRequest,
  getAllPaginatedUserActivityTopicsRequest,
  searchUsersByUsernameRequest,
  uploadUserPostVideoRequest,
} from '../../../../../../../../httpRequests/httpRequests';
import { useQueryClient, useQuery } from 'react-query';
import ReactPlayer from 'react-player';
import Button from '../../../../../../../../components/Button/Button';
import ArrowDownIcon from '../../../../../../../../assets/down-icon-2.png';
import WorldIcon from '../../../../../../../../assets/public-icon.png';
import FriendsIcon from '../../../../../../../../assets/my-followers-icon.png';
import OnlyMeIcon from '../../../../../../../../assets/only-me-icon.png';
import EmojiIcon from '../../../../../../../../assets/emoji.png';
import ImagePostIcon from '../../../../../../../../assets/image-post-1.png';
import RemoveIcon from '../../../../../../../../assets/white-remove-icon.png';
import HashTagIcon from '../../../../../../../../assets/at.svg';
import SpecificFollowersIcon from '../../../../../../../../assets/specific-followers-icon.png';
import { POST_MENTION } from '../../../../../../../../constants/SOCKET_EVENTS';
import MultipleImageVideoUpload from '../../../../../../../../components/MultipleImageVideoUpload/MultipleImageVideoUpload';
import GrayCommunitiesIcon from '../../../../../../../../assets/my-communities-icon.png';
import CollectibleHoldersIcon from '../../../../../../../../assets/collectible-holders-icon.png';
import Input from '../../../../../../../../components/Input/Input';
import ImageVideoUpload from '../../../../../../../../components/ImageVideoUpload/ImageVideoUpload';

import UploadIcon from '../../../../../../../../assets/upload-icon.png';

function CreatePostModal(props) {
  const postContentInputRef = useRef();
  const queryClient = useQueryClient();
  const { profile } = useContext(AuthContext);
  const { socket } = useContext(SocketContext);
  const { setShowCreatePostModal } = props;
  const [selectedPostStatus, setSelectedPostStatus] = useState('Public');
  const [showSelectPostStatusModal, setShowSelectPostStatusModal] = useState(
    false
  );
  const [splittedMessage, setSplittedMessage] = useState([]);
  const [mentionedUsernames, setMentionedUsernames] = useState({});
  const [selectedFollowerIds, setSelectedFollowerIds] = useState({});
  const [postContent, setPostContent] = useState('');
  const [postImageUrl, setPostImageUrl] = useState();
  const [postImagePreviewUrl, setPostImagePreviewUrl] = useState();
  const [additionalImagesUrl, setAdditionalImagesUrl] = useState([]);
  const [additionalImagesPreviewUrl, setAdditionalImagesPreviewUrl] = useState(
    []
  );
  const [postVideoUrl, setPostVideoUrl] = useState();
  const [postVideoPreviewUrl, setPostVideoPreviewUrl] = useState();
  const [additionalVideosUrl, setAdditionalVideosUrl] = useState([]);
  const [additionalVideosPreviewUrl, setAdditionalVideosPreviewUrl] = useState(
    []
  );
  const [showTagUsersModal, setShowTagUsersModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState({});
  const [showEmojiEditor, setShowEmojiEditor] = useState(false);
  const [selectedFeeling, setSelectedFeeling] = useState();
  const [creatingUserPost, setCreatingUserPost] = useState(false);
  const [showTopicsTable, setShowTopicsTable] = useState(false);
  const [previousSplittedMessage, setPreviousSplittedMessage] = useState([]);
  const [fetchedTopics, setFetchedTopics] = useState([]);
  const [fetchingTopics, setFetchingTopics] = useState(false);

  const [selectedCommunityIds, setSelectedCommunityIds] = useState({});

  const [isPayablePostSelected, setIsPayablePostSelected] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [uploadedFilePreviewUrl, setUploadedFilePreviewUrl] = useState();
  const [uploadedFileType, setUploadedFileType] = useState();
  const [payablePostPriceInUsd, setPayablePostPriceInUsd] = useState('');
  const [payablePostPriceTouched, setPayablePostPriceTouched] = useState(false);
  const [creatingPayablePost, setCreatingPayablePost] = useState(false);

  const getLastTypedMessage = useCallback(() => {
    for (let i = 0; i < splittedMessage.length; i++) {
      if (previousSplittedMessage[i] !== splittedMessage[i]) {
        return { index: i, msg: splittedMessage[i] };
      }
    }
  }, [splittedMessage, previousSplittedMessage]);

  const getMentionValue = () => {
    if (postContent === '') return '';

    if (splittedMessage[splittedMessage.length - 1]?.includes('\n@')) {
      let mentionValue = '';
      let i = splittedMessage[splittedMessage.length - 1].length - 1;
      while (splittedMessage[splittedMessage.length - 1][i] !== '@') {
        mentionValue += splittedMessage[splittedMessage.length - 1][i];
        i--;
      }
      return mentionValue.split('').reverse().join('');
    }

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (msg[0] === '@' && mentionedUsernames[msg]) continue;

      if (msg[0] === '@' && !mentionedUsernames[msg]) {
        const mentionValue = msg.replace('@', '');
        return mentionValue;
      }
    }
    return '';
  };
  const mentionValue = getMentionValue();

  const { data: mentions } = useQuery(['mentions', mentionValue], () =>
    searchUsersByUsernameRequest(mentionValue)
  );

  useEffect(() => {
    const checkForClosingEmojiPicker = (e) => {
      if (e.target.id.startsWith('emoji')) {
        setShowEmojiEditor(true);
      } else if (e.target.closest('#emoji-container') === null) {
        setShowEmojiEditor(false);
      }
    };

    window.addEventListener('click', checkForClosingEmojiPicker);
  }, []);

  const closeModalHandler = () => {
    setShowCreatePostModal(false);
  };

  useEffect(() => {
    const checkForShowingTopicsTable = async () => {
      const lastTypedMessage = getLastTypedMessage();
      if (lastTypedMessage?.msg?.startsWith('#')) {
        setShowTopicsTable(true);
        setFetchingTopics(true);
        const { data } = await getAllPaginatedUserActivityTopicsRequest(
          1,
          50,
          lastTypedMessage.msg
        );
        setFetchingTopics(false);
        setFetchedTopics(Array.from(new Set(data.map(({ name }) => name))));
      } else setShowTopicsTable(false);
    };

    checkForShowingTopicsTable();
  }, [getLastTypedMessage]);

  const openSelectPostStatusModalHandler = () => {
    setShowSelectPostStatusModal(true);
  };

  const sendNotificationsToMentionedUsers = async (post) => {
    if (!profile)
      return notify(
        'error',
        'You are not logged in. Please log in to perform this action!',
        2000
      );

    try {
      const mentionedUserIds = [];
      for (const key in mentionedUsernames) {
        const userId = mentionedUsernames[key]._id;
        const isMe = profile?._id === userId;
        if (!isMe) {
          mentionedUserIds.push(userId);
        }
      }
      const requestBody = {
        userIds: mentionedUserIds,
        notificationType: 'post-mention',
        entityName: profile.fullName,
        entityId: post._id,
      };

      await createNotificationToMultipleUsersRequest(requestBody);

      socket.emit(POST_MENTION, {
        mentionedUserIds,
        postAuthor: profile.fullName,
      });
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const createPayablePostHandler = async () => {
    const formData = new FormData();
    if (postContent) formData.append('postContent', postContent);
    if (selectedFeeling) formData.append('feeling', selectedFeeling);
    const mentions = [];
    Object.entries(mentionedUsernames).forEach(([mentionUsername, data]) => {
      mentions.push({ mentionUsername, data: { ...data, id: data._id } });
    });

    formData.append('mentions', JSON.stringify(mentions));

    if (uploadedFile) formData.append('uploadedFile', uploadedFile);
    formData.append('uploadedFileType', uploadedFileType);
    formData.append('postStatus', selectedPostStatus);
    for (const key in selectedFollowerIds) {
      formData.append('specificFollowers', key);
    }

    const topics = [];
    postContent.split(' ').forEach((el) => {
      if (el.startsWith('#')) {
        topics.push(el);
      }
    });

    formData.append('postTopics', JSON.stringify(topics));
    formData.append('price', parseFloat(payablePostPriceInUsd * 100));

    try {
      setCreatingPayablePost(true);
      await createPayablePostRequest(formData);

      await Promise.all([
        queryClient.invalidateQueries('payable-posts'),
        queryClient.refetchQueries('payable-posts'),
      ]);

      setCreatingPayablePost(false);

      closeModalHandler();
    } catch (err) {
      setCreatingPayablePost(false);

      notify('error', err, 2000);
    }
  };

  const createPostHandler = async () => {
    if (selectedPostStatus === 'My Communities') {
      const formData = new FormData();
      if (postContent) formData.append('postContent', postContent);
      if (selectedFeeling) formData.append('feeling', selectedFeeling);
      const mentions = [];
      Object.entries(mentionedUsernames).forEach(([mentionUsername, data]) => {
        mentions.push({ mentionUsername, data: { ...data, id: data._id } });
      });

      formData.append('mentions', JSON.stringify(mentions));

      const topics = [];
      postContent.split(' ').forEach((el) => {
        if (el.startsWith('#')) {
          topics.push(el);
        }
      });
      formData.append('groupPostTopics', JSON.stringify(topics));

      if (postImageUrl) formData.append('postImages', postImageUrl);
      if (additionalImagesUrl.length > 0) {
        for (let i = 0; i < additionalImagesUrl.length; i++) {
          formData.append('postImages', additionalImagesUrl[i]);
        }
      }

      if (postVideoUrl) formData.append('postVideos', postVideoUrl);
      if (additionalVideosUrl.length > 0) {
        for (let i = 0; i < additionalVideosUrl.length; i++) {
          formData.append('postVideos', additionalVideosUrl[i]);
        }
      }

      try {
        setCreatingUserPost(true);

        await Promise.all(
          Object.keys(selectedCommunityIds).map(async (groupId) =>
            createUserGroupPostRequest(groupId, formData)
          )
        );
      } catch (err) {
        notify('error', err, 2000);
      }
    }

    const formData = new FormData();
    formData.append('postStatus', selectedPostStatus);
    if (postContent) formData.append('postContent', postContent);

    if (selectedFeeling) formData.append('feeling', selectedFeeling);
    if (postImageUrl) formData.append('postImage', postImageUrl);

    if (additionalImagesUrl.length > 0) {
      for (let i = 0; i < additionalImagesUrl.length; i++) {
        formData.append('additionalImages', additionalImagesUrl[i]);
      }
    }

    for (const key in selectedTagIds) {
      formData.append('tags', key);
    }
    for (const key in selectedFollowerIds) {
      formData.append('specificFollowers', key);
    }

    const mentions = [];
    Object.entries(mentionedUsernames).forEach(([mentionUsername, data]) => {
      mentions.push({ mentionUsername, data });
    });

    formData.append('mentions', JSON.stringify(mentions));

    const topics = [];
    postContent.split(' ').forEach((el) => {
      if (el.startsWith('#')) {
        topics.push(el);
      }
    });

    formData.append('postTopics', JSON.stringify(topics));

    setCreatingUserPost(true);
    try {
      const { data: newPost } = await createUserPostRequest(formData);

      if (additionalVideosUrl.length > 0) {
        const videoFormData = new FormData();
        // if (postVideoUrl) {
        //   videoFormData.append('postVideo', postVideoUrl);
        // }

        for (let i = 0; i < additionalVideosUrl.length; i++) {
          videoFormData.append('additionalVideos', additionalVideosUrl[i]);
        }

        await uploadUserPostVideoRequest(newPost._id, videoFormData);
      }

      await Promise.all([
        queryClient.invalidateQueries('my-posts'),
        queryClient.refetchQueries('my-posts'),
        queryClient.invalidateQueries('posts'),
        queryClient.refetchQueries('posts'),
      ]);

      sendNotificationsToMentionedUsers(newPost);

      closeModalHandler();
    } catch (error) {
      notify('error', error, 2000);
    }

    setCreatingUserPost(false);
  };

  const inputFileHandler = (value) => {
    if (!value) return;

    let files = [...value];

    let totalFilesSize = 0;
    files.forEach((el) => {
      totalFilesSize += el.size;
    });
    if (totalFilesSize > 10000000)
      return notify(
        'error',
        'You can not upload more than 10MB Content!',
        2000
      );

    files.forEach((value) => {
      const fileReader = new FileReader();

      if (value.type === 'video/mp4') {
        // if (!postVideoUrl) {
        //   setPostVideoUrl(value);
        // } else {
        setAdditionalVideosUrl((prevState) => [...prevState, value]);
        // }
      } else {
        // if (!postImageUrl) {
        //   setPostImageUrl(value);
        // } else {
        setAdditionalImagesUrl((prevState) => [...prevState, value]);
        // }
      }

      try {
        fileReader.onload = () => {
          if (value.type === 'video/mp4') {
            // if (!postVideoPreviewUrl) {
            //   setPostVideoPreviewUrl(fileReader.result);
            // } else {
            setAdditionalVideosPreviewUrl((prevState) => [
              ...prevState,
              fileReader.result,
            ]);
            // }
          } else {
            // if (!postImagePreviewUrl) {
            //   setPostImagePreviewUrl(fileReader.result);
            // } else {
            setAdditionalImagesPreviewUrl((prevState) => [
              ...prevState,
              fileReader.result,
            ]);
            // }
          }
        };

        fileReader.readAsDataURL(value);
      } catch (err) {
        notify('error', err, 2000);
      }
    });
  };

  const openTagUsersModalHandler = () => {
    setShowTagUsersModal(true);
  };

  const isCreatePostDisabled = (() => {
    if (creatingUserPost) return true;

    if (additionalImagesUrl.length > 0 || additionalVideosUrl.length > 0)
      return false;

    if (!postVideoUrl && postContent.trim().length === 0 && !postImageUrl)
      return true;

    return false;
  })();

  const renderSelectedPostStatus = () => {
    if (selectedPostStatus === 'Public') {
      return (
        <div className={classes['post-status']}>
          <img alt="World" src={WorldIcon} width={15} />
          <p className={classes['post-status-text']}>Public</p>
          <img alt="Arrow Down" src={ArrowDownIcon} />
        </div>
      );
    }

    if (selectedPostStatus === 'My Followers') {
      return (
        <div className={classes['post-status']}>
          <img alt="Friends" src={FriendsIcon} width={15} />
          <p className={classes['post-status-text']}>My Followers</p>
          <img alt="Arrow Down" src={ArrowDownIcon} />
        </div>
      );
    }

    if (selectedPostStatus === 'Only Me') {
      return (
        <div className={classes['post-status']}>
          <img alt="Only Me" src={OnlyMeIcon} width={15} />
          <p className={classes['post-status-text']}>Only Me</p>
          <img alt="Arrow Down" src={ArrowDownIcon} />
        </div>
      );
    }

    if (selectedPostStatus === 'Specific Followers') {
      return (
        <div className={classes['post-status']}>
          <img
            alt="Specific Followers"
            src={SpecificFollowersIcon}
            width={12}
          />
          <p className={classes['post-status-text']}>Specific Followers</p>
          <img alt="Arrow Down" src={ArrowDownIcon} />
        </div>
      );
    }

    if (selectedPostStatus === 'Collectible Holders') {
      return (
        <div className={classes['post-status']}>
          <img
            alt="Collectible Holders"
            src={CollectibleHoldersIcon}
            width={12}
          />
          <p className={classes['post-status-text']}>Collectible Holders</p>
          <img alt="Arrow Down" src={ArrowDownIcon} />
        </div>
      );
    }

    if (selectedPostStatus === 'My Communities') {
      return (
        <div className={classes['post-status']}>
          <img alt="My Communities" src={GrayCommunitiesIcon} width={12} />
          <p className={classes['post-status-text']}>My Communities</p>
          <img alt="Arrow Down" src={ArrowDownIcon} />
        </div>
      );
    }
  };

  const renderTags = () => {
    const numberOfTags = selectedTags.length;

    if (numberOfTags > 0) {
      if (numberOfTags === 1) {
        return (
          <div className={classes['tags-container']}>
            <p className={classes['is-with']}>is with</p>
            <p className={classes['tagged-user']}>{selectedTags[0].fullName}</p>
          </div>
        );
      } else if (numberOfTags === 2) {
        return (
          <div className={classes['tags-container']}>
            <p className={classes['is-with']}>is with</p>
            <p className={classes['tagged-user']}>{selectedTags[0].fullName}</p>
            <p className={classes['tagged-user']}>
              and {selectedTags[1].fullName}
            </p>
          </div>
        );
      } else {
        return (
          <div className={classes['tags-container']}>
            <p className={classes['is-with']}>is with</p>
            <p className={classes['tagged-user']}>
              {selectedTags[0].fullName},
            </p>
            <p className={classes['tagged-user']}>{selectedTags[1].fullName}</p>
            <p className={classes['tagged-user']}>
              and{' '}
              {numberOfTags - 2 === 1
                ? '1 other'
                : `${numberOfTags - 2} others`}
            </p>
          </div>
        );
      }
    }
  };

  const removeImageHandler = () => {
    setPostImagePreviewUrl(undefined);
    setPostImageUrl(undefined);
  };

  const removeVideoHandler = () => {
    setPostVideoPreviewUrl(undefined);
    setPostVideoUrl(undefined);
  };

  const postContentChangeHandler = (e) => {
    setPostContent(e.target.value);
    const splittedMessage = e.target.value.split(' ');
    setSplittedMessage((prevState) => {
      setPreviousSplittedMessage(prevState);
      return splittedMessage;
    });
    const updatedMentionedUsernames = {};
    for (const usernameKey in mentionedUsernames) {
      if (splittedMessage.includes(usernameKey)) {
        updatedMentionedUsernames[usernameKey] =
          mentionedUsernames[usernameKey];
      }
    }

    setMentionedUsernames(updatedMentionedUsernames);
  };

  const selectUserToMentionHandler = (user) => {
    const updatedMentionedUsernames = { ...mentionedUsernames };

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (
        msg[0] === '@' &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[i] = `@${username}`;
      } else if (
        splittedMessage[splittedMessage.length - 1]?.includes('\n@') &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[splittedMessage.length - 1] = `\n@${username}`;
      }
    }

    splittedMessage.push('');
    setMentionedUsernames(updatedMentionedUsernames);
    setPostContent(splittedMessage.join(' '));
    setSplittedMessage(splittedMessage);
    postContentInputRef.current.focus();
  };

  const selectTopicHandler = (topic) => {
    const lastTypedMessage = getLastTypedMessage();
    if (lastTypedMessage?.msg) {
      const updatedSplittedMessage = [...splittedMessage];
      updatedSplittedMessage[lastTypedMessage.index] = topic;
      setSplittedMessage(updatedSplittedMessage);
      setPostContent(updatedSplittedMessage.join(' '));
    }
  };

  const shouldNotShowMentionsTable = () => {
    return splittedMessage.every((msg) => {
      return (
        msg === '' ||
        msg === ' ' ||
        msg[0] !== '@' ||
        (msg[0] === '@' && typeof mentionedUsernames[msg] === 'object')
      );
    });
  };

  const shouldShowMentionsTableInNewLine = () => {
    return splittedMessage[splittedMessage.length - 1]?.includes('\n@');
  };

  const removeAdditionalImageUrl = (index) => {
    setAdditionalImagesUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
    setAdditionalImagesPreviewUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  const removeAdditionalVideoUrl = (index) => {
    setAdditionalVideosPreviewUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
    setAdditionalVideosUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  const inputPayableFileHandler = (value) => {
    if (!value) return;

    if (value.size > 40000000)
      return notify(
        'error',
        'You can not upload more than 40MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setUploadedFile(value);

    if (value.type === 'video/mp4') {
      setUploadedFileType('video');
    } else {
      setUploadedFileType('image');
    }
    try {
      fileReader.onload = () => {
        setUploadedFilePreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const payablePostPriceChangeHandler = (e) => {
    setPayablePostPriceInUsd(e.target.value);
    setPayablePostPriceTouched(true);
  };

  const isPayablePostButtonDisabled = () => {
    if (
      !payablePostPriceInUsd ||
      isNaN(payablePostPriceInUsd) ||
      parseFloat(payablePostPriceInUsd) < 0.5
    )
      return true;

    if (!uploadedFile) return true;
    if (!postContent) return true;

    return false;
  };

  const payablePostInputPriceStyle = { width: '100%' };
  if (
    payablePostPriceTouched &&
    (!payablePostPriceInUsd ||
      isNaN(payablePostPriceInUsd) ||
      parseFloat(payablePostPriceInUsd) < 0.5)
  ) {
    payablePostInputPriceStyle['border'] = '1px solid red';
  }

  return (
    <>
      {showSelectPostStatusModal && (
        <SelectPostStatusModal
          selectedFollowerIds={selectedFollowerIds}
          setSelectedFollowerIds={setSelectedFollowerIds}
          selectedPostStatus={selectedPostStatus}
          setSelectedPostStatus={setSelectedPostStatus}
          setShowSelectPostStatusModal={setShowSelectPostStatusModal}
          selectedCommunityIds={selectedCommunityIds}
          setSelectedCommunityIds={setSelectedCommunityIds}
        />
      )}
      {showTagUsersModal && (
        <TagUsersModal
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          selectedTagIds={selectedTagIds}
          setSelectedTagIds={setSelectedTagIds}
          setShowTagUsersModal={setShowTagUsersModal}
        />
      )}

      {!showSelectPostStatusModal && !showTagUsersModal && (
        <Modal
          // style={{ height: 500 }}
          footer={
            <div className={classes['footer']}>
              <Button
                style={{ width: 200 }}
                darkpinkcolor="true"
                onClick={closeModalHandler}
              >
                Cancel
              </Button>

              {isPayablePostSelected ? (
                <Button
                  style={{ width: '100%' }}
                  disabled={
                    isPayablePostButtonDisabled() || creatingPayablePost
                  }
                  onClick={createPayablePostHandler}
                  darkpink="true"
                >
                  Create Paid Post
                </Button>
              ) : (
                <Button
                  style={{ width: 200 }}
                  disabled={isCreatePostDisabled}
                  onClick={createPostHandler}
                  darkpink="true"
                >
                  Post
                </Button>
              )}
            </div>
          }
          closeModal={closeModalHandler}
          title="Create Post"
        >
          <div className={classes['create-post-modal-container']}>
            <div className={classes['user-post-status']}>
              <img
                alt={profile?.fullName}
                src={profile?.photo}
                width={45}
                height={45}
                className={classes['user-image']}
              />

              <div className={classes['fullName-post-status-container']}>
                <div className={classes['fullName-feeling-container']}>
                  <div>
                    <h4 className={classes['user-fullName']}>
                      {profile?.fullName}
                    </h4>
                    {selectedFeeling && (
                      <div className={classes['feeling-container']}>
                        {' '}
                        <span className={classes['circle']}>&nbsp;</span>{' '}
                        <p className={classes['feeling-text']}>
                          Feeling {selectedFeeling}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {renderTags()}
              </div>

              <div className={classes['payable-post-container']}>
                {isPayablePostSelected ? (
                  <div
                    onClick={() =>
                      setIsPayablePostSelected((prevState) => !prevState)
                    }
                    className={classes['payable-post-selected']}
                  >
                    <p>Payable</p>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      setIsPayablePostSelected((prevState) => !prevState)
                    }
                    className={classes['payable-post-not-selected']}
                  >
                    <p>Payable</p>
                  </div>
                )}
              </div>

              <div
                onClick={openSelectPostStatusModalHandler}
                className={classes['post-status-container']}
              >
                {renderSelectedPostStatus()}
              </div>
            </div>

            <div className={classes['input-container']}>
              <textarea
                ref={postContentInputRef}
                style={{ marginTop: 30, minHeight: 100, width: '100%' }}
                onChange={postContentChangeHandler}
                value={postContent}
                className={classes['textarea']}
                placeholder={`What are you thinking, ${profile?.firstName} ?`}
              />

              {!isPayablePostSelected && showTopicsTable && (
                <div className={classes['topics-container']}>
                  {fetchedTopics.length === 0 && fetchingTopics === false && (
                    <p className={classes['no-topics-found']}>
                      No Topics Found
                    </p>
                  )}
                  {fetchedTopics.map((topic) => {
                    return (
                      <div
                        onClick={() => selectTopicHandler(topic)}
                        className={classes['topic-container']}
                        key={topic}
                      >
                        <p className={classes['topic-text']}>{topic}</p>
                      </div>
                    );
                  })}
                </div>
              )}

              {(!shouldNotShowMentionsTable() ||
                shouldShowMentionsTableInNewLine()) &&
                !isPayablePostSelected && (
                  <div className={classes['mention-container']}>
                    {mentions && mentions.data.length === 0 && (
                      <p className={classes['no-users-found']}>
                        No Users Found
                      </p>
                    )}

                    {mentions &&
                      mentions.data.map((user) => {
                        const fullName = `${user.firstName} ${user.lastName}`;
                        return (
                          <div
                            onClick={() => selectUserToMentionHandler(user)}
                            className={classes['user-container']}
                            key={user._id}
                          >
                            <img
                              alt="userPhoto"
                              className={classes['user-image']}
                              src={user.photo}
                            />
                            <p className={classes['user-text']}>
                              {user.username} ({fullName})
                              {user._id === profile?._id ? ' (You)' : ''}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                )}

              {showEmojiEditor && (
                <div
                  id="emoji-container"
                  className={classes['emoji-container']}
                >
                  <Picker
                    emojiSize={20}
                    title="Pick your emoji"
                    emoji="point_up"
                    onSelect={({ native }) => {
                      setShowEmojiEditor(false);
                      setSelectedFeeling(native);
                    }}
                  />
                </div>
              )}
            </div>

            {isPayablePostSelected ? (
              <div className={classes['payable-upload-container']}>
                <Input
                  placeholder="Enter USD Amount"
                  style={payablePostInputPriceStyle}
                  type="number"
                  onChange={payablePostPriceChangeHandler}
                />
                <ImageVideoUpload onInput={inputPayableFileHandler}>
                  <div className={classes['upload-file-container']}>
                    {uploadedFilePreviewUrl ? (
                      uploadedFileType === 'image' ? (
                        <img
                          className={classes['uploaded-file']}
                          alt="File"
                          src={uploadedFilePreviewUrl}
                        />
                      ) : (
                        <ReactPlayer
                          style={{
                            objectFit: 'cover',
                            borderRadius: 10,
                          }}
                          controls
                          width={400}
                          height={200}
                          url={uploadedFilePreviewUrl}
                        />
                      )
                    ) : (
                      <img
                        alt="File"
                        src={UploadIcon}
                        className={classes['image-post-icon']}
                      />
                    )}
                  </div>
                </ImageVideoUpload>
              </div>
            ) : (
              <div className={classes['add-to-your-post']}>
                <p className={classes['add-to-your-post-text']}>
                  Add to your post
                </p>
                <div className={classes['icons-container']}>
                  <img
                    alt="Tag"
                    onClick={openTagUsersModalHandler}
                    src={HashTagIcon}
                    className={classes['tag-icon']}
                  />
                  <img
                    alt="Emoji"
                    src={EmojiIcon}
                    className={classes['emoji-icon']}
                    id="emoji-icon"
                  />

                  <MultipleImageVideoUpload onInput={inputFileHandler}>
                    <img
                      alt="Post"
                      src={ImagePostIcon}
                      className={classes['image-post-icon']}
                    />
                  </MultipleImageVideoUpload>
                </div>
              </div>
            )}

            {!isPayablePostSelected &&
              (postImagePreviewUrl ||
                postVideoPreviewUrl ||
                additionalImagesPreviewUrl.length > 0 ||
                additionalVideosPreviewUrl.length > 0) && (
                <div className={classes['upload-image-container']}>
                  {postVideoPreviewUrl && (
                    <div className={classes['video-container']}>
                      <ReactPlayer
                        width={300}
                        height={155}
                        url={postVideoPreviewUrl}
                        controls
                        style={{
                          borderRadius: '13px',
                          objectFit: 'cover',
                          marginTop: 27,
                        }}
                      />

                      <div
                        onClick={removeVideoHandler}
                        className={classes['remove-video-icon-container']}
                      >
                        <img
                          alt="Remove"
                          src={RemoveIcon}
                          className={classes['remove-icon']}
                        />
                      </div>
                    </div>
                  )}
                  {postImagePreviewUrl && (
                    <div className={classes['image-container']}>
                      <img
                        alt="Preview"
                        src={postImagePreviewUrl}
                        className={classes['uploaded-image']}
                      />
                      <div
                        onClick={removeImageHandler}
                        className={classes['remove-icon-container']}
                      >
                        <img
                          alt="Remove"
                          src={RemoveIcon}
                          className={classes['remove-icon']}
                        />
                      </div>
                    </div>
                  )}

                  {additionalImagesPreviewUrl.map((previewUrl, i) => (
                    <div className={classes['image-container']}>
                      <img
                        alt="Preview"
                        src={previewUrl}
                        className={classes['uploaded-image']}
                      />
                      <div
                        onClick={() => removeAdditionalImageUrl(i)}
                        className={classes['remove-icon-container']}
                      >
                        <img
                          alt="Remove"
                          src={RemoveIcon}
                          className={classes['remove-icon']}
                        />
                      </div>
                    </div>
                  ))}

                  {additionalVideosPreviewUrl.map((previewUrl, i) => (
                    <div className={classes['video-container']}>
                      <ReactPlayer
                        width={300}
                        height={155}
                        url={previewUrl}
                        controls
                        style={{
                          borderRadius: '13px',
                          objectFit: 'cover',
                          marginTop: 27,
                        }}
                      />

                      <div
                        onClick={() => removeAdditionalVideoUrl(i)}
                        className={classes['remove-video-icon-container']}
                      >
                        <img
                          alt="Remove"
                          src={RemoveIcon}
                          className={classes['remove-icon']}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
          </div>
        </Modal>
      )}
    </>
  );
}

export default CreatePostModal;
