import ipfsClient from 'ipfs-http-client';

const projectId = '2DASot4MFnIVVPdliJQYDHQ4jBU';
const projectSecret = 'e1ef8b4a75bcc42e9d556b027cbd8ccc';
const auth =
  'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const ipfs = ipfsClient({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    Authorization: auth,
  },
});

export default ipfs;
