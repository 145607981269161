import React, { useEffect } from 'react';
import classes from './StripePayments.module.css';
import { Col, Row } from 'antd';
import { useQuery } from 'react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { getAllStripePaymentsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import moment from 'moment';
import { limitString } from '../../../../helpers/helpers';

function StripePayments() {
  const { isLoading, data, error } = useQuery(
    'stripe-payments',
    getAllStripePaymentsRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-users-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-users-heading']}>
            ALL STRIPE PAYMENTS = {data?.data.length}
          </h2>
        </div>

        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={3} className={classes['pagination-col']}>
              Status
            </Col>
            <Col md={3} className={classes['pagination-col']}>
              Updated At
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Payment For
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              USD Amount
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              User FullName
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              User Email
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Entity Name
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Entity ID
            </Col>
          </Row>

          {!isLoading &&
            data?.data &&
            data.data.map((stripePayment) => {
              return (
                <div key={stripePayment._id}>
                  <hr />
                  <Row justify="space-between" style={{ padding: '1em 0' }}>
                    <Col md={3} className={classes['pagination-col']}>
                      {stripePayment.status}
                    </Col>
                    <Col md={3} className={classes['pagination-col']}>
                      {moment(stripePayment.updatedAt).format(
                        'MMMM Do YYYY, h:mm:ss a'
                      )}
                    </Col>
                    <Col md={3} className={classes['pagination-col']}>
                      {stripePayment.paymentFor}
                    </Col>
                    <Col md={3} className={classes['pagination-col']}>
                      ${stripePayment.usdAmount}
                    </Col>

                    <Col md={3} className={classes['pagination-col']}>
                      {stripePayment.user.fullName}
                      <img
                        src={stripePayment.user.photo}
                        alt={stripePayment.user.fullName}
                        width={30}
                        height={30}
                      />
                    </Col>
                    <Col md={3} className={classes['pagination-col']}>
                      {limitString(stripePayment.user.email, 30)}
                    </Col>

                    <Col md={3} className={classes['pagination-col']}>
                      {stripePayment.entityName}
                    </Col>

                    <Col md={3} className={classes['pagination-col']}>
                      {stripePayment.entityId}
                    </Col>
                  </Row>
                </div>
              );
            })}
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default StripePayments;
