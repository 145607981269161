import React, { useEffect, useRef } from 'react';
import classes from './UsersWhoVotedForASpecificGroupPollAnswerModal.module.css';
import { useInfiniteQuery } from 'react-query';
import { getUsersWhoVotedForASpecificGroupPollAnswerRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import User from '../User/User';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const RESULTS_PER_PAGE = 10;

function UsersWhoVotedForASpecificGroupPollAnswerModal(props) {
  const {
    setShowUsersWhoVotedForASpecificGroupPollAnswerModal,
    groupPollAnswerId,
    groupPollId,
  } = props;
  const votedByContainerRef = useRef();

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['users-who-voted-group-poll-answer', groupPollAnswerId],
    ({ pageParam = 1 }) =>
      getUsersWhoVotedForASpecificGroupPollAnswerRequest(
        groupPollAnswerId,
        groupPollId,
        pageParam,
        RESULTS_PER_PAGE
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const votedByContainerElement = votedByContainerRef.current;

    if (!votedByContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    votedByContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      votedByContainerElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const closeModalHandler = () => {
    setShowUsersWhoVotedForASpecificGroupPollAnswerModal(false);
  };

  return (
    <Modal
      style={{ height: 550 }}
      footer={
        <Button
          style={{ width: '100%' }}
          onClick={closeModalHandler}
          pinkcolor="true"
        >
          Close
        </Button>
      }
      closeModal={closeModalHandler}
      title="Voted By"
    >
      <div ref={votedByContainerRef} className={classes['voted-by-list']}>
        {data?.pages && data.pages[0].results === 0 && <p>No Results Found!</p>}

        {data?.pages &&
          data.pages.map((page) => {
            return page.data.map(({ user }) => (
              <User key={user._id} user={user} closeModal={closeModalHandler} />
            ));
          })}

        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </Modal>
  );
}

export default UsersWhoVotedForASpecificGroupPollAnswerModal;
