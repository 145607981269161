import React, { useState, useMemo } from 'react';
import classes from './EditGroupBenefitsModal.module.css';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { useQuery, useQueryClient } from 'react-query';
import {
  getMyGroupsAsOrganizerRequest,
  updateGroupBenefitsRequest,
} from '../../httpRequests/httpRequests';
import { Link } from 'react-router-dom';
import { limitString } from '../../helpers/helpers';
import notify from '../../utils/notify';

function EditGroupBenefitsModal(props) {
  const queryClient = useQueryClient();
  const {
    closeEditCreatorServicesModal,
    customServices,
    selectedServices,
  } = props;

  const [updatingGroupsIds, setUpdatingGroupsIds] = useState({});

  const myGroupsAsOrganizerResponse = useQuery(
    'my-groups-as-organizer',
    getMyGroupsAsOrganizerRequest
  );

  const myGroupsAsOrganizer = useMemo(() => {
    if (
      myGroupsAsOrganizerResponse.isLoading ||
      !myGroupsAsOrganizerResponse.data
    )
      return [];

    return myGroupsAsOrganizerResponse.data.data;
  }, [myGroupsAsOrganizerResponse]);

  const closeModalHandler = () => {
    closeEditCreatorServicesModal();
  };

  const invalidateMyGroupsAsOrganizerHandler = () =>
    Promise.all([
      queryClient.invalidateQueries('my-groups-as-organizer'),
      queryClient.refetchQueries('my-groups-as-organizer'),
    ]);

  const updateGroupBenefitsHandler = async (groupId) => {
    setUpdatingGroupsIds((prevState) => ({ ...prevState, [groupId]: true }));

    try {
      const requestBody = {
        benefits: [...selectedServices, ...customServices],
      };

      await updateGroupBenefitsRequest(groupId, requestBody);

      invalidateMyGroupsAsOrganizerHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setUpdatingGroupsIds((prevState) => ({ ...prevState, [groupId]: false }));
  };

  return (
    <Modal
      style={{ width: 450, height: 550 }}
      closeModal={closeModalHandler}
      title="Update Group Services"
      footer={
        <div className={classes['footer']}>
          <Button
            onClick={closeModalHandler}
            darkpinkcolor="true"
            style={{ flex: 1 }}
          >
            Close
          </Button>
        </div>
      }
    >
      <>
        {!myGroupsAsOrganizerResponse.isLoading &&
          myGroupsAsOrganizer.length === 0 && (
            <div>
              <p>
                You currently have not created a community. However, you can
                create a group in the community section of the app, and come
                back here to link your services to your group.
              </p>
            </div>
          )}
        {myGroupsAsOrganizer.length > 0 && (
          <>
            <p className={classes['community-text']}>
              Do you want to make these services available to your community?
            </p>
            <div className={classes['group-list-container']}>
              {myGroupsAsOrganizer.map((group) => {
                return (
                  <div key={group._id} className={classes['group-container']}>
                    <Link to={`/my-groups?id=${group._id}`}>
                      <div className={classes['photo-name-container']}>
                        <img
                          className={classes['cover-photo']}
                          src={group.coverPhoto}
                          alt={group.name}
                        />
                        <p title={group.name} className={classes['group-name']}>
                          {limitString(group.name, 30)}
                        </p>
                      </div>
                    </Link>

                    {group.benefits?.length > 0 ? (
                      <Button
                        disabled={
                          [...selectedServices, ...customServices].length ===
                            0 || updatingGroupsIds[group._id]
                        }
                        onClick={() => updateGroupBenefitsHandler(group._id)}
                        darkpinkcolor="true"
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        onClick={() => updateGroupBenefitsHandler(group._id)}
                        darkpink="true"
                        disabled={
                          [...selectedServices, ...customServices].length ===
                            0 || updatingGroupsIds[group._id]
                        }
                      >
                        Add
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </>
    </Modal>
  );
}

export default EditGroupBenefitsModal;
