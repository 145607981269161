import React from 'react';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import { uploadPhotoRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import classes from './UploadedPhotoModal.module.css';
import { useQueryClient } from 'react-query';

function UploadedPhotoModal(props) {
  const queryClient = useQueryClient();
  const {
    setPreviewUrl,
    setImageUrl,
    setShowUploadedPhotoModal,
    previewUrl,
    imageUrl,
  } = props;

  const closeModalHandler = () => {
    setPreviewUrl(undefined);
    setImageUrl(undefined);
    setShowUploadedPhotoModal(false);
  };

  const uploadPhotoHandler = async () => {
    try {
      const formData = new FormData();
      formData.append('photo', imageUrl);
      await uploadPhotoRequest(formData);

      await Promise.all([
        queryClient.invalidateQueries('my-photos'),
        queryClient.refetchQueries('my-photos'),
      ]);

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      title="Upload Photo"
      closeModal={closeModalHandler}
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            onClick={uploadPhotoHandler}
            style={{ width: 200 }}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
    >
      <div className={classes['upload-image-container']}>
        <img alt="Preview" src={previewUrl} className={classes['image']} />
      </div>
    </Modal>
  );
}

export default UploadedPhotoModal;
