import React from 'react';
import { Radio, Space } from 'antd';
import { BUTTON_TYPES } from './MarketPlace';
import SliderButton from '../../components/SliderButton/SliderButton';
import classes from './MarketPlaceContent.module.css';

const SidebarMarketPlace = ({
  selectedCategoryName = 'Football',
  sortByValues,
  setSortByValue,
  sortByValue,
  selectedButtonType,
  setSelectedButtonType,
  setPage,
  isSafeContentSelected,
  setIsSafeContentSelected,
}) => {
  const sortByHandler = (e) => {
    setSortByValue(e.target.value);
  };

  const buttonTypesClickHandler = (type) => {
    setSelectedButtonType(type);
    setPage(1);
  };

  return (
    <div className={classes['sidebar-container']}>
      <div style={{ textAlign: 'left' }}>
        <h1 className={classes['sidebar-header']}>
          Own The <br />
          <span className="color-pink">
            {selectedCategoryName.toUpperCase()}
          </span>{' '}
          Moment
        </h1>
        <hr />
        <div className="center">
          <p className={classes['text']}>Type</p>
        </div>

        <div
          style={{
            transition: 'all .2s ease-out',
            marginBottom: '1rem',
          }}
        >
          <div className={classes['type-buttons']}>
            <button
              className={
                selectedButtonType === BUTTON_TYPES.BUY_NOW
                  ? `btn-pink-glimpse`
                  : `btn-white-glimpse`
              }
              onClick={() => buttonTypesClickHandler(BUTTON_TYPES.BUY_NOW)}
            >
              Buy Now
            </button>
            <button
              className={
                selectedButtonType === BUTTON_TYPES.AUCTION
                  ? `btn-pink-glimpse`
                  : `btn-white-glimpse`
              }
              onClick={() => buttonTypesClickHandler(BUTTON_TYPES.AUCTION)}
            >
              Auction
            </button>
            <button
              className={
                selectedButtonType === BUTTON_TYPES.NEW
                  ? `btn-pink-glimpse`
                  : `btn-white-glimpse`
              }
              onClick={() => buttonTypesClickHandler(BUTTON_TYPES.NEW)}
            >
              New
            </button>
            <button
              className={
                selectedButtonType === BUTTON_TYPES.HAS_OFFERS
                  ? `btn-pink-glimpse`
                  : `btn-white-glimpse`
              }
              onClick={() => buttonTypesClickHandler(BUTTON_TYPES.HAS_OFFERS)}
            >
              Has offers
            </button>
          </div>
        </div>

        <hr />
        <div className="center">
          <p className={classes['text']}>Sort By</p>
        </div>

        <div
          style={{
            transition: 'all .2s ease-out',
          }}
        >
          <Radio.Group onChange={sortByHandler} value={sortByValue}>
            <Space direction="vertical">
              {sortByValues.map(({ value, sortByValue }) => (
                <Radio key={value} value={value}>
                  {sortByValue}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
        <div className="horizontal-line"></div>
        <div className="center">
          <p className={classes['text']}>Content</p>
        </div>
        <div style={{ marginLeft: 13 }}>
          <SliderButton
            leftValue={<h4 className="bold-text">Safe</h4>}
            rightValue={<h4 className="bold-text">All</h4>}
            isLeftContentSelected={isSafeContentSelected}
            setIsLeftContentSelected={setIsSafeContentSelected}
          />
        </div>
      </div>
    </div>
  );
};

export default SidebarMarketPlace;
