import React, { useState } from 'react';
import classes from './RequestedServiceFromMe.module.css';
import moment from 'moment';

import StarIcon from '../../../../assets/custom-benefits-star-icon.png';
import PersonalizedVideoIcon from '../../../../assets/personalized-video-icon.png';
import PersonalizedImageIcon from '../../../../assets/personalized-image-icon.png';
import ChatBenefitIcon from '../../../../assets/chat-benefit-icon.png';
import VideoCallBenefitIcon from '../../../../assets/video-call-benefit-icon.png';

import FinishedIcon from '../../../../assets/finished.png';
import PendingIcon from '../../../../assets/wall-clock.png';
import RejectedIcon from '../../../../assets/white-remove-icon.png';
import Button from '../../../../components/Button/Button';
import RequestedServiceFromMeDetailsModal from '../RequestedServiceFromMeDetailsModal/RequestedServiceFromMeDetailsModal';
import { Link } from 'react-router-dom';

function RequestedServiceFromMe(props) {
  const { requestedService } = props;
  const { to, service, status, createdAt, price } = requestedService;

  const [
    showRequestedServiceFromMeDetailsModal,
    setShowRequestedServiceFromMeDetailsModal,
  ] = useState(false);

  const renderServiceIcon = (service) => {
    if (service === 'Personalized Video')
      return (
        <img
          className={classes['service-icon']}
          src={PersonalizedVideoIcon}
          alt="Personalized Video"
        />
      );

    if (service === 'Personalized Image')
      return (
        <img
          className={classes['service-icon']}
          src={PersonalizedImageIcon}
          alt="Personalized Benefit"
        />
      );

    if (service === '1/1 Video Call (15mins)')
      return (
        <img
          className={classes['service-icon']}
          src={VideoCallBenefitIcon}
          alt="Video Call Benefit"
        />
      );

    if (service === '1/1 Chat (15mins)')
      return (
        <img
          className={classes['service-icon']}
          src={ChatBenefitIcon}
          alt="Chat Benefit"
        />
      );

    return (
      <img
        className={classes['service-icon']}
        src={StarIcon}
        alt="Custom Services"
        style={{ width: 16, height: 16, marginBottom: 1.5 }}
      />
    );
  };

  const renderStatusIcon = () => {
    if (status === 'Pending') {
      return (
        <div className={classes['pending-status-container']}>
          <img
            className={classes['pending-icon']}
            src={PendingIcon}
            alt="Pending"
          />
        </div>
      );
    } else if (status === 'Finished') {
      return (
        <div className={classes['finished-status-container']}>
          <img
            className={classes['finished-icon']}
            src={FinishedIcon}
            alt="Finished"
          />
        </div>
      );
    } else if (status === 'Rejected') {
      return (
        <div className={classes['rejected-status-container']}>
          <p
            className={classes['rejected-icon']}
            src={RejectedIcon}
            alt="Rejected"
          >
            X
          </p>
        </div>
      );
    }
  };

  const openRequestedServiceFromMeDetailsHandler = () => {
    setShowRequestedServiceFromMeDetailsModal(true);
  };

  return (
    <>
      {showRequestedServiceFromMeDetailsModal && (
        <RequestedServiceFromMeDetailsModal
          requestedService={requestedService}
          setShowRequestedServiceFromMeDetailsModal={
            setShowRequestedServiceFromMeDetailsModal
          }
        />
      )}

      <div className={classes['requested-service-container']}>
        <div className={classes['photo-fullName-container']}>
          <Link to={`/user-feed/${to._id}`}>
            <img
              src={to.photo}
              alt={to.fullName}
              className={classes['to-photo']}
            />
          </Link>
          <div>
            <p className={classes['to-fullName']}>{to.fullName}</p>
            <p className={classes['created-at']}>
              Received: {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
            </p>
          </div>
        </div>

        {renderStatusIcon()}

        <div className={classes['service-container']}>
          <div className={classes['service-icon-container']}>
            {renderServiceIcon(service)}
            <p className={classes['service-text']}>{service}</p>
          </div>
          <p className={classes['price']}>${price}</p>
        </div>

        <div className={classes['view-details-container']}>
          <Button
            onClick={openRequestedServiceFromMeDetailsHandler}
            disabled={status !== 'Finished'}
            darkpink="true"
          >
            View Details
          </Button>
        </div>
      </div>
    </>
  );
}

export default RequestedServiceFromMe;
