import React, { useEffect } from 'react';
import classes from './NftCollections.module.css';
import { useQuery } from 'react-query';
import { getAllNftCollectionsAssociatedWithASpecificGroupRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import NftCollection from '../../../../components/NftCollection/NftCollection';

function NftCollections(props) {
  const { groupId } = props;

  const { isLoading, data, error } = useQuery(
    ['nft-collections-associated-with-groups', groupId],
    () => getAllNftCollectionsAssociatedWithASpecificGroupRequest(groupId)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['nft-collections-container']}>
      {!isLoading && data?.data && data.data.length === 0 && (
        <div
          style={{
            background: 'white',
            padding: '.8rem',
            borderRadius: 10,
            marginBottom: 10,
          }}
        >
          <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
            No NFT Collections Found!
          </p>
        </div>
      )}

      <div className={classes['collections-list']}>
        {!isLoading &&
          data?.data &&
          data.data.map((nftCollection) => (
            <NftCollection
              key={nftCollection._id}
              nftCollection={nftCollection}
            />
          ))}
      </div>
    </div>
  );
}

export default NftCollections;
