import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../components/Modal/Modal';
import classes from './ReportInformationModal.module.css';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import {
  getUserReportsRequest,
  updateReportStatusRequest,
} from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import ReportDescriptionModal from './components/ReportDescriptionModal/ReportDescriptionModal';
import ActionTakenDescriptionModal from './components/ActionTakenDescriptionModal/ActionTakenDescriptionModal';

const REPORT_STATUSES = ['In Review', 'Approved', 'Denied'];

function ReportInformationModal(props) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { setShowReportInformationModal, userId } = props;
  const { data, error } = useQuery(['user-reports', userId], () =>
    getUserReportsRequest(userId)
  );
  const [showReportDescriptionModal, setShowReportDescriptionModal] = useState(
    false
  );
  const [selectedReportDescription, setSelectedReportDescription] = useState();
  const [
    actionTakenDescriptionModalState,
    setActionTakenDescriptionModalState,
  ] = useState({
    opened: false,
    selectedReportId: undefined,
  });

  const closeModalHandler = () => setShowReportInformationModal(false);

  const invalidateReportStatus = (reportId, reportStatus) => {
    queryClient.setQueryData(['user-reports', userId], (data) => {
      return {
        ...data,
        data: data.data.map((report) => {
          if (report._id !== reportId) return report;
          return { ...report, status: reportStatus };
        }),
      };
    });
  };

  const reportStatusChangeHandler = async (reportId, reportStatus) => {
    try {
      const requestBody = {
        status: reportStatus,
      };
      await updateReportStatusRequest(reportId, requestBody);

      invalidateReportStatus(reportId, reportStatus);

      setActionTakenDescriptionModalState({
        opened: true,
        selectedReportId: reportId,
      });
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const openDescriptionModal = (description) => {
    setShowReportDescriptionModal(true);
    setSelectedReportDescription(description);
  };

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <>
      {showReportDescriptionModal && (
        <ReportDescriptionModal
          setShowReportDescriptionModal={setShowReportDescriptionModal}
          reportDescription={selectedReportDescription}
        />
      )}

      {actionTakenDescriptionModalState.opened && (
        <ActionTakenDescriptionModal
          actionTakenDescriptionModalState={actionTakenDescriptionModalState}
          setActionTakenDescriptionModalState={
            setActionTakenDescriptionModalState
          }
        />
      )}

      {!showReportDescriptionModal && !actionTakenDescriptionModalState.opened && (
        <Modal
          footer={
            <>
              <button onClick={closeModalHandler} className={'cancel-button'}>
                Cancel
              </button>
            </>
          }
          closeModal={closeModalHandler}
          title="Report Information"
        >
          <div id="table-nft" className={classes['reports-container']}>
            <Row justify="space-between">
              <Col
                style={{ paddingLeft: '4%' }}
                md={3}
                className={classes['pagination-col']}
              >
                From
              </Col>

              <Col md={4} className={classes['pagination-col']}>
                Description
              </Col>

              <Col md={4} className={classes['pagination-col']}>
                Reason
              </Col>

              <Col
                style={{ paddingLeft: 20 }}
                md={3}
                className={classes['pagination-col']}
              >
                Status
              </Col>
            </Row>
            {data && data.data.length === 0 && (
              <h2 className="bold-text">No results found!</h2>
            )}
            {data &&
              data.data.map(
                ({
                  _id,
                  from,
                  reason,
                  description,
                  status,
                  actionTakenDescription,
                }) => {
                  return (
                    <div key={_id}>
                      <hr />
                      <Row justify="space-between" style={{ padding: '1em 0' }}>
                        <Col
                          md={3}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            history.push(`/user-stats/${from._id}`)
                          }
                        >
                          <img
                            width={25}
                            height={25}
                            style={{ borderRadius: 100, objectFit: 'cover' }}
                            alt="User"
                            src={from.photo}
                          />
                          <p>{from.fullName}</p>
                        </Col>
                        <Col md={4}>
                          <button
                            className="btn-white-glimpse"
                            onClick={() => openDescriptionModal(description)}
                          >
                            Description
                          </button>
                        </Col>

                        <Col md={4}>{reason}</Col>

                        <Col md={3}>
                          <select
                            className="select-input"
                            onChange={(e) =>
                              reportStatusChangeHandler(
                                _id,
                                e.target.value,
                                actionTakenDescription
                              )
                            }
                            value={status}
                          >
                            {REPORT_STATUSES.map((reportStatus) => {
                              return (
                                <option value={reportStatus}>
                                  {reportStatus}
                                </option>
                              );
                            })}
                          </select>
                        </Col>
                      </Row>
                    </div>
                  );
                }
              )}
          </div>
        </Modal>
      )}
    </>
  );
}

export default ReportInformationModal;
