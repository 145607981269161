import React, { useState } from 'react';
import classes from './ReceivedCollaboratorNftUploadRequest.module.css';
import VerifiedIcon from '../../assets/verified.png';
import { IPFS_URL } from '../../constants/IPFS';
import { limitString } from '../../helpers/helpers';
import { useQueryClient } from 'react-query';
import notify from '../../utils/notify';
import ReactPlayer from 'react-player';
import Button from '../Button/Button';
import moment from 'moment';
import { rejectRequestedNftUploadFromCollaboratorRequest } from '../../httpRequests/httpRequests';
import AcceptCollaboratorNftUploadRequestModal from '../AcceptCollaboratorNftUploadRequestModal/AcceptCollaboratorNftUploadRequestModal';

function ReceivedCollaboratorNftUploadRequest(props) {
  const queryClient = useQueryClient();

  const {
    ipfs,
    type,
    from,
    previewUrl,
    updatedAt,
    nftCollection,
    comment,
    _id,
  } = props;

  const [acceptingOrRejecting, setAcceptingOrRejecting] = useState(false);

  const [
    acceptCollaboratorNftUploadRequestModalState,
    setAcceptCollaboratorNftUploadRequestModalState,
  ] = useState({
    opened: false,
    previewUrl: undefined,
    ipfs: undefined,
    nftCollection: undefined,
    type: undefined,
  });

  const invalidateMyRequestedNftUploadsFromCollaboratorsHandler = () => {
    return Promise.all([
      queryClient.invalidateQueries(
        'my-requested-nft-uploads-from-collaborators'
      ),
      queryClient.refetchQueries('my-requested-nft-uploads-from-collaborators'),
    ]);
  };

  const rejectRequestedNftUploadFromCollaboratorHanlder = async () => {
    setAcceptingOrRejecting(true);

    try {
      await rejectRequestedNftUploadFromCollaboratorRequest(_id);

      await invalidateMyRequestedNftUploadsFromCollaboratorsHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setAcceptingOrRejecting(false);
  };

  const openAcceptCollaboratorNftUploadRequestModalHandler = () => {
    setAcceptCollaboratorNftUploadRequestModalState({
      opened: true,
      previewUrl,
      ipfs,
      nftCollection,
      type,
    });
  };

  return (
    <>
      {acceptCollaboratorNftUploadRequestModalState.opened && (
        <AcceptCollaboratorNftUploadRequestModal
          setAcceptCollaboratorNftUploadRequestModalState={
            setAcceptCollaboratorNftUploadRequestModalState
          }
          collaboratorNftUploadRequestId={_id}
        />
      )}
      <div className={classes['received-collaborator-nft-request-container']}>
        {type === 'image' ? (
          <img
            className={classes['nft-image']}
            src={previewUrl || `${IPFS_URL}/${ipfs}`}
            alt="IPFS Hash"
          />
        ) : (
          <ReactPlayer
            style={{
              objectFit: 'cover',
              borderRadius: 10,
            }}
            width={330}
            height={300}
            url={previewUrl || `${IPFS_URL}/${ipfs}`}
          />
        )}

        <div
          className={
            classes['received-collaborator-nft-request-info-container']
          }
        >
          <div className={classes['from-info-container']}>
            <p className={classes['from-text']}>From: </p>
            <img
              className={classes['from-photo']}
              src={from.photo}
              alt="From"
            />
            <p className={classes['from-fullName']}>{from.fullName}</p>
            {from.verified && (
              <img width={14} src={VerifiedIcon} alt="Verified" />
            )}
          </div>

          <p className={classes['received-on']}>
            Received on: {moment(updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>

          <div className={classes['collection-container']}>
            <p className={classes['collection-text']}>Collection:</p>
            <img
              src={nftCollection.coverPhoto}
              alt="Collection"
              className={classes['collection-cover-photo']}
            />
            <p className={classes['collection-title']}>
              {limitString(nftCollection.name, 16)}
            </p>
          </div>

          <p className={classes['comment']}>Comment: {comment}</p>

          <div className={classes['button-actions-container']}>
            <Button
              onClick={openAcceptCollaboratorNftUploadRequestModalHandler}
              disabled={acceptingOrRejecting}
              darkpink="true"
            >
              Accept
            </Button>
            <Button
              onClick={rejectRequestedNftUploadFromCollaboratorHanlder}
              darkpinkcolor="true"
              disabled={acceptingOrRejecting}
            >
              Reject
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReceivedCollaboratorNftUploadRequest;
