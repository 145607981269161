import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';

function GoToLoginPageModal() {
  const history = useHistory();

  const closeModalHandler = () => {
    history.push('/');
  };

  return (
    <Modal
      style={{ width: 350 }}
      title={`Login to Claim`}
      closeModal={closeModalHandler}
      footer={
        <Button
          style={{ width: 200 }}
          darkpinkcolor="true"
          onClick={closeModalHandler}
        >
          Login
        </Button>
      }
    >
      <div>
        <p>
          Hm, looks like you are not logged in or don't have an account yet.
          Login or Create an account now to claim the collectible and gain
          exclusive access to your favorite creators!
        </p>
      </div>
    </Modal>
  );
}

export default GoToLoginPageModal;
