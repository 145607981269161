import moment from 'moment';

export const isInputValidationCompleted = (
  inputValue,
  validationRequirements
) => {
  let isInputValid = true;

  if (validationRequirements.minLength) {
    isInputValid =
      isInputValid && inputValue.length >= validationRequirements.minLength;
  }

  if (validationRequirements.maxLength) {
    isInputValid =
      isInputValid && inputValue.length <= validationRequirements.maxLength;
  }

  if (validationRequirements.isEmail) {
    isInputValid = isInputValid && /^\S+@\S+\.\S+$/.test(inputValue);
  }

  if (validationRequirements.required) {
    isInputValid = isInputValid && inputValue.length > 0;
  }

  return isInputValid;
};

export const isOverallFormValid = (updatedFormState) => {
  let isFormValid = true;
  for (const key in updatedFormState) {
    if (updatedFormState[key].valid === false) isFormValid = false;
  }

  return isFormValid;
};

export const truncate = (string, limit = 10) => {
  if (string.length < limit) return string;

  const splittedString = string.split(' ');

  let stringToReturn = '';

  for (let i = 0; i < splittedString.length; i++) {
    const str = splittedString[i];

    if (stringToReturn.length + str.length <= limit) {
      stringToReturn += ` ${str}`;
    } else {
      stringToReturn += '...';
      break;
    }
  }

  return stringToReturn;
};

export const limitString = (string = '', limit) => {
  if (string.length <= limit) return string;

  return string.substring(0, limit) + '...';
};

export const getTimeDifferenceInHours = (endTime) => {
  const now = moment(new Date());
  const duration = moment.duration(endTime.diff(now));
  const hours = duration.asHours();

  return hours;
};

export const isToday = (date) => {
  return moment(date).isSame(new Date(), 'day');
};

export const getDateFrom = (givenDate) => {
  return moment(givenDate).fromNow(
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ',
        s: 'now',
        m: '%d m',
        mm: '%d m',
        h: '%d h',
        hh: '%d h',
        d: '%d d',
        dd: '%d d',
        M: 'a mth',
        MM: '%d mths',
        y: 'y',
        yy: '%d y',
      },
    })
  );
};

export const getFormattedNumber = (number) => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const isValidUrl = (string) => {
  // eslint-disable-next-line
  const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const result = string.match(regex);
  return result !== null;
};

export const isValidEmail = (email) => /^\S+@\S+\.\S+$/.test(email);

export const downloadFile = ({ fileUrl, fileName, contentType }) => {
  fetch(fileUrl, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
};

export const convertToMB = (bytes) => (bytes / 1000000).toFixed(2);

export const formatWalletAddress = (walletAddress = '') => {
  if (!walletAddress) return '';

  return (
    walletAddress.split('').slice(0, 6).join('') +
    '...' +
    walletAddress.split('').reverse().slice(0, 4).reverse().join('')
  );
};
