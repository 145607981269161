import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ImageUpload from '../../../../components/ImageUpload/ImageUpload';
import { isInputValidationCompleted } from '../../../../helpers/helpers';
import {
  createEventRequest,
  getAllCategoriesOfNftsRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './Events.module.css';
import moment from 'moment';
import AllEventsTable from './components/AllEventsTable';

const inputInitialState = {
  value: '',
  touched: false,
  valid: false,
  validationRequirements: { required: true },
};
const eventInitialState = {
  name: { ...inputInitialState, placeholder: 'Name' },
  date: { ...inputInitialState, placeholder: 'Date (YYYY-MM-DD)' },
  location: { ...inputInitialState, placeholder: 'Location' },
  description: { ...inputInitialState, placeholder: 'Description' },
};

function Events() {
  const { isLoading, error, data } = useQuery(
    'categories',
    getAllCategoriesOfNftsRequest
  );

  const categoriesOfNfts = data ? data.data : undefined;

  const [selectedCategory, setSelectedCategory] = useState();
  const [eventState, setEventState] = useState({ ...eventInitialState });
  const [eventPhotoUrl, setEventPhotoUrl] = useState(undefined);
  const [tags, setTags] = useState([{ ...inputInitialState }]);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [creatingEventLoading, setCreatingEventLoading] = useState(false);

  useEffect(() => {
    if (categoriesOfNfts) {
      setSelectedCategory(categoriesOfNfts[0]._id);
    }
  }, [categoriesOfNfts]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const inputImageHandler = (value) => {
    if (!value) return;
    
    
    const fileReader = new FileReader();

    setEventPhotoUrl(value);
    try {
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const eventInputsState = [];
  for (const key in eventState) {
    eventInputsState.push({
      key,
      ...eventState[key],
    });
  }

  const inputChangeHandler = (key, value) => {
    const updatedEventState = { ...eventState };
    updatedEventState[key].value = value;
    updatedEventState[key].touched = true;

    updatedEventState[key].valid = isInputValidationCompleted(
      value,
      updatedEventState[key].validationRequirements
    );
    if (key === 'date') {
      const dateFormat = 'YYYY-MM-DD';
      updatedEventState[key].valid = moment(
        moment(eventState.date.value).format(dateFormat),
        dateFormat,
        true
      ).isValid();
    }

    setEventState(updatedEventState);
  };

  const categoryChangeHandler = (e) => {
    setSelectedCategory(e.target.value);
  };

  const renderEventInputs = () => {
    return eventInputsState.map((inputState) => {
      const { key } = inputState;

      const inputStyle = {};
      if (inputState.valid === false && inputState.touched) {
        inputStyle['border'] = '1px solid red';
      }

      if (key === 'description') {
        return (
          <textarea
            placeholder={inputState.placeholder}
            onChange={(e) => inputChangeHandler(key, e.target.value)}
            value={inputState.value}
            key={key}
            className="searchInput"
            style={{ borderRadius: 10, color: 'white', ...inputStyle }}
          />
        );
      }

      return (
        <input
          style={{ color: 'white', ...inputStyle }}
          placeholder={inputState.placeholder}
          onChange={(e) => inputChangeHandler(key, e.target.value)}
          value={inputState.value}
          key={key}
          className="searchInput"
        />
      );
    });
  };

  const tagInputChangeHandler = (index, value) => {
    const updatedTags = [...tags];
    updatedTags[index].value = value;
    updatedTags[index].valid = value.length > 0;
    updatedTags[index].touched = true;
    setTags(updatedTags);
  };

  const removeTagHandler = (index) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  const renderTags = () => {
    return tags.map((tag, i) => {
      return (
        <div key={tag}>
          <input
            className="searchInput"
            style={{ color: 'white' }}
            placeholder="Tag"
            value={tag.value}
            onChange={(e) => tagInputChangeHandler(i, e.target.value)}
          />
          <button
            onClick={() => removeTagHandler(i)}
            style={{ marginLeft: 15 }}
            className="cancel-button"
          >
            Remove Tag
          </button>
        </div>
      );
    });
  };

  const addNewTagHandler = () => {
    setTags((prevTags) => [...prevTags, { ...inputInitialState }]);
  };

  const createEventHandler = async () => {
    try {
      setCreatingEventLoading(true);

      const formData = new FormData();

      for (const key in eventState) {
        formData.append(key, eventState[key].value);
      }
      formData.append('photo', eventPhotoUrl);

      const categoryId = selectedCategory || categoriesOfNfts[0]._id;
      formData.append('category', categoryId);

      tags.forEach((tag) => {
        formData.append('tags', tag.value);
      });

      await createEventRequest(formData);

      setEventState({ ...eventInitialState });
      setTags([{ ...inputInitialState }]);
      setPreviewUrl(undefined);
      setEventPhotoUrl(undefined);
    } catch (error) {
      notify('error', error, 2000);
    }

    setCreatingEventLoading(false);
  };

  const isAddNewTagButtonEnabled = tags.every((tag) => tag.valid);

  const isOverallFormValid = () => {
    let isValid = true;
    for (const key in eventState) {
      isValid = isValid && !!eventState[key].valid;
    }

    isValid =
      isValid && !!previewUrl && selectedCategory && isAddNewTagButtonEnabled;

    return isValid;
  };
  const isFormValid = isOverallFormValid();

  return (
    <div className={classes['events-container']}>
      <h2 style={{ textAlign: 'center' }} className="bold-text">
        New Event
      </h2>

      <div className={classes['event-form']}>
        <div className={classes['event-inputs-container']}>
          {renderEventInputs()}
        </div>

        <div className={classes['categories']}>
          <h4 className="thin-text">Category</h4>
          {!isLoading && categoriesOfNfts && (
            <select
              placeholder="Category"
              className="select-input"
              value={selectedCategory}
              onChange={categoryChangeHandler}
            >
              {categoriesOfNfts.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
          )}
        </div>

        <div className={classes['event-photo-container']}>
          {previewUrl && (
            <img alt="Event" width={100} height={100} src={previewUrl} />
          )}
          <ImageUpload onInput={inputImageHandler}>
            Upload Event Photo
          </ImageUpload>
        </div>

        <div style={{ padding: 10 }}>
          <div>{renderTags()}</div>
          <button
            disabled={!isAddNewTagButtonEnabled}
            onClick={addNewTagHandler}
            style={{ marginTop: 10 }}
            className={
              !isAddNewTagButtonEnabled ? 'btn-disabled' : 'btn-white-glimpse'
            }
          >
            Add new Tag
          </button>
        </div>

        <div style={{ textAlign: 'right', padding: 20 }}>
          <button
            disabled={!isFormValid || creatingEventLoading}
            className={
              !isFormValid || creatingEventLoading
                ? 'btn-disabled'
                : 'btn-pink-glimpse'
            }
            onClick={createEventHandler}
          >
            {creatingEventLoading ? 'Creating Event...' : 'Create Event'}
          </button>
        </div>
      </div>

      <AllEventsTable />
    </div>
  );
}

export default Events;
