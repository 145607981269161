import React, { useState } from 'react';
import classes from './EditCollectionModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import Input from '../../../../components/Input/Input';
import TelegramIcon from '../../../../assets/telegram.png';
import TwitterIcon from '../../../../assets/twitter.png';
import LinkedinIcon from '../../../../assets/linkedin-1.png';
import YoutubeIcon from '../../../../assets/youtube.png';
import InstagramIcon from '../../../../assets/instagram.png';
import LinktreeIcon from '../../../../assets/linktree.png';
import ImdbIcon from '../../../../assets/imdb.png';
import SliderButton from '../../../../components/SliderButton/SliderButton';
import ImageUpload from '../../../../components/ImageUpload/ImageUpload';
import CameraIcon from '../../../../assets/camera-1.png';
import notify from '../../../../utils/notify';
import { updateNftCollectionRequest } from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import TextArea from '../../../../components/TextArea/TextArea';

function EditCollectionModal(props) {
  const { setShowEditCollectionModal, nftCollection } = props;
  const queryClient = useQueryClient();

  const [collectionName, setCollectionName] = useState(nftCollection.name);
  const [description, setDescription] = useState(nftCollection.description);

  const [includeLinkedinUrl, setIncudeLinkedinUrl] = useState(
    !!nftCollection.linkedinUrl
  );
  const [includeTelegramUrl, setIncudeTelegramUrl] = useState(
    !!nftCollection.telegramUrl
  );
  const [includeTwitterUrl, setIncudeTwitterUrl] = useState(
    !!nftCollection.twitterUrl
  );
  const [includeInstagramUrl, setIncludeInstagramUrl] = useState(
    !!nftCollection.instagramUrl
  );
  const [includeLinktreeUrl, setIncludeLinktreeUrl] = useState(
    !!nftCollection.linktreeUrl
  );
  const [includeYoutubeUrl, setIncludeYoutubeUrl] = useState(
    !!nftCollection.youtubeUrl
  );
  const [includeImdbUrl, setIncludeImdbUrl] = useState(!!nftCollection.imdbUrl);
  const [telegramUrl, setTelegramUrl] = useState(
    nftCollection.telegramUrl || ''
  );
  const [twitterUrl, setTwitterUrl] = useState(nftCollection.twitterUrl || '');
  const [linkedinUrl, setLinkedinUrl] = useState(
    nftCollection.linkedinUrl || ''
  );
  const [youtubeUrl, setYoutubeUrl] = useState(nftCollection.youtubeUrl || '');
  const [linktreeUrl, setLinktreeUrl] = useState(
    nftCollection.linktreeUrl || ''
  );
  const [imdbUrl, setImdbUrl] = useState(nftCollection.imdbUrl || '');
  const [instagramUrl, setInstagramUrl] = useState(
    nftCollection.instagramUrl || ''
  );

  const [isTelegramUrlValid, setIsTelegramUrlValid] = useState(
    !!nftCollection.telegramUrl
  );
  const [isLinkedinUrlValid, setIsLinkedinUrlValid] = useState(
    !!nftCollection.linkedinUrl
  );
  const [isTwitterUrlValid, setIsTwitterUrlValid] = useState(
    !!nftCollection.twitterUrl
  );

  const [isInstagramUrlValid, setIsInstagramUrlValid] = useState(
    !!nftCollection.instagramUrl
  );
  const [isLinktreeUrlValid, setIsLinktreeUrlValid] = useState(
    !!nftCollection.linktreeUrl
  );
  const [isImdbUrlValid, setIsImdbUrlValid] = useState(!!nftCollection.imdbUrl);
  const [isYoutubeUrlValid, setIsYoutubeUrlValid] = useState(
    !!nftCollection.youtubeUrl
  );

  const [profilePhotoUrl, setProfilePhotoUrl] = useState(
    nftCollection.profilePhoto
  );
  const [profilePhotoPreviewUrl, setProfilePhotoPreviewUrl] = useState(
    nftCollection.profilePhoto
  );
  const [coverPhotoUrl, setCoverPhotoUrl] = useState(nftCollection.coverPhoto);
  const [coverPhotoPreviewUrl, setCoverPhotoPreviewUrl] = useState(
    nftCollection.coverPhoto
  );

  const [isCollectionStatusPublic, setIsCollectionStatusPublic] = useState(
    nftCollection.status === 'Public'
  );

  const [creatingCollection, setCreatingCollection] = useState(false);

  const closeModalHandler = () => {
    setShowEditCollectionModal(false);
  };

  const collectionNameChangeHandler = (e) => {
    setCollectionName(e.target.value);
  };

  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value);
  };

  const telegramUrlChangeHandler = (e) => {
    setTelegramUrl(e.target.value);
    setIsTelegramUrlValid(e.target.value.includes('t.me/'));
  };

  const twitterUrlChangeHandler = (e) => {
    setTwitterUrl(e.target.value);
    setIsTwitterUrlValid(e.target.value.includes('twitter.com/'));
  };

  const linkedinUrlChangeHandler = (e) => {
    setLinkedinUrl(e.target.value);
    setIsLinkedinUrlValid(e.target.value.includes('linkedin.com/'));
  };

  const youtubeUrlChangeHandler = (e) => {
    setYoutubeUrl(e.target.value);
    // eslint-disable-next-line
    const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/;
    setIsYoutubeUrlValid(youtubeRegex.test(e.target.value));
  };

  const instagramUrlChangeHandler = (e) => {
    setInstagramUrl(e.target.value);
    // eslint-disable-next-line
    const instagramRegex = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;
    setIsInstagramUrlValid(instagramRegex.test(e.target.value));
  };

  const linktreeUrlChangeHandler = (e) => {
    setLinktreeUrl(e.target.value);
    setIsLinktreeUrlValid(e.target.value.includes('linktr.ee/'));
  };

  const imdbUrlChangeHandler = (e) => {
    setImdbUrl(e.target.value);
    setIsImdbUrlValid(e.target.value.includes('imdb.com/'));
  };

  const coverPhotoInputHandler = (value) => {
    if (!value) return;
    if (value.size > 10000000)
      return notify(
        'error',
        'You can not upload more than 10MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setCoverPhotoUrl(value);

    try {
      fileReader.onload = () => {
        setCoverPhotoPreviewUrl(fileReader.result);
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const profilePhotoInputHandler = (value) => {
    if (!value) return;
    if (value.size > 10000000)
      return notify(
        'error',
        'You can not upload more than 10MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setProfilePhotoUrl(value);

    try {
      fileReader.onload = () => {
        setProfilePhotoPreviewUrl(fileReader.result);
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const invalidateNftCollection = () => {
    queryClient.invalidateQueries([
      'nft-collection-information',
      nftCollection._id,
    ]);
    queryClient.refetchQueries([
      'nft-collection-information',
      nftCollection._id,
    ]);
  };

  const editCollectionHandler = async () => {
    setCreatingCollection(true);
    try {
      const formData = new FormData();
      formData.append('name', collectionName);
      formData.append('description', description);

      if (includeTelegramUrl) formData.append('telegramUrl', telegramUrl);
      if (includeTwitterUrl) formData.append('twitterUrl', twitterUrl);
      if (includeLinkedinUrl) formData.append('linkedinUrl', linkedinUrl);
      if (includeImdbUrl) formData.append('imdbUrl', imdbUrl);
      if (includeInstagramUrl) formData.append('instagramUrl', instagramUrl);
      if (includeLinktreeUrl) formData.append('linktreeUrl', linktreeUrl);
      if (includeYoutubeUrl) formData.append('youtubeUrl', youtubeUrl);

      const collectionStatus = isCollectionStatusPublic ? 'Public' : 'Private';
      formData.append('status', collectionStatus);
      if (coverPhotoUrl) formData.append('coverPhoto', coverPhotoUrl);
      if (profilePhotoUrl) formData.append('profilePhoto', profilePhotoUrl);

      await updateNftCollectionRequest(nftCollection._id, formData);
      invalidateNftCollection();

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setCreatingCollection(false);
  };

  const telgramUrlInputStyle = { width: '100%' };
  if (!isTelegramUrlValid && includeTelegramUrl) {
    telgramUrlInputStyle['border'] = '1px solid red';
  }

  const twitterUrlInputStyle = { width: '100%' };
  if (!isTwitterUrlValid && includeTwitterUrl) {
    twitterUrlInputStyle['border'] = '1px solid red';
  }

  const linkedinUrlInputStyle = { width: '100%' };
  if (!isLinkedinUrlValid && includeLinkedinUrl) {
    linkedinUrlInputStyle['border'] = '1px solid red';
  }

  const youtubeUrlInputStyle = { width: '100%' };
  if (!isYoutubeUrlValid && includeYoutubeUrl) {
    youtubeUrlInputStyle['border'] = '1px solid red';
  }

  const instagramUrlInputStyle = { width: '100%' };
  if (!isInstagramUrlValid && includeInstagramUrl) {
    instagramUrlInputStyle['border'] = '1px solid red';
  }

  const linktreeInputStyle = { width: '100%' };
  if (!isLinktreeUrlValid && includeLinktreeUrl) {
    linktreeInputStyle['border'] = '1px solid red';
  }

  const imdbInputStyle = { width: '100%' };
  if (!isImdbUrlValid && includeImdbUrl) {
    imdbInputStyle['border'] = '1px solid red';
  }

  const isCreateCollectionButtonDisabled = () => {
    if (creatingCollection) return true;

    if (!collectionName || !description || !coverPhotoUrl || !profilePhotoUrl)
      return true;

    if (includeLinkedinUrl && !isLinkedinUrlValid) return true;
    if (includeTelegramUrl && !isTelegramUrlValid) return true;
    if (includeTwitterUrl && !isTwitterUrlValid) return true;
    if (includeYoutubeUrl && !isYoutubeUrlValid) return true;
    if (includeInstagramUrl && !isInstagramUrlValid) return true;
    if (includeLinktreeUrl && !isLinktreeUrlValid) return true;
    if (includeImdbUrl && !isImdbUrlValid) return true;
  };

  const collectionNameInputStyle = { width: '100%' };
  if (collectionName.length === 0) {
    collectionNameInputStyle['border'] = '1px solid red';
  }

  const descriptionInputStyle = { minHeight: 120, width: '100%' };
  if (description.length < 10) {
    descriptionInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      style={{ maxHeight: 650, overflowY: 'auto' }}
      closeModal={closeModalHandler}
      title="Edit Collection"
      closeIcon="true"
    >
      <div style={{ height: 550 }}>
        <p className={classes['label']}>Name</p>
        <Input
          style={{
            width: '100%',
            ...collectionNameInputStyle,
          }}
          value={collectionName}
          onChange={collectionNameChangeHandler}
        />

        <p className={classes['label']}>Description</p>
        <TextArea
          style={{
            width: '100%',
            ...descriptionInputStyle,
          }}
          value={description}
          onChange={descriptionChangeHandler}
        />

        <div className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Telegram"
              src={TelegramIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Telegram</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeTelegramUrl}
            setIsLeftContentSelected={setIncudeTelegramUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={telgramUrlInputStyle}
          value={telegramUrl}
          onChange={telegramUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Twitter"
              src={TwitterIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Twitter</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeTwitterUrl}
            setIsLeftContentSelected={setIncudeTwitterUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={twitterUrlInputStyle}
          value={twitterUrl}
          onChange={twitterUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Linkedin"
              src={LinkedinIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Linkedin</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeLinkedinUrl}
            setIsLeftContentSelected={setIncudeLinkedinUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={linkedinUrlInputStyle}
          value={linkedinUrl}
          onChange={linkedinUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Youtube"
              src={YoutubeIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Youtube</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeYoutubeUrl}
            setIsLeftContentSelected={setIncludeYoutubeUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={youtubeUrlInputStyle}
          value={youtubeUrl}
          onChange={youtubeUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Instagram"
              src={InstagramIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Instagram</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeInstagramUrl}
            setIsLeftContentSelected={setIncludeInstagramUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={instagramUrlInputStyle}
          value={instagramUrl}
          onChange={instagramUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img
              alt="Linktree"
              src={LinktreeIcon}
              className={classes['social-icon']}
            />
            <p className={classes['social-text']}>Linktree</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeLinktreeUrl}
            setIsLeftContentSelected={setIncludeLinktreeUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={linktreeInputStyle}
          value={linktreeUrl}
          onChange={linktreeUrlChangeHandler}
        />

        <div style={{ marginTop: 10 }} className={classes['social-container']}>
          <div className={classes['social-icon-name-container']}>
            <img alt="IMDB" src={ImdbIcon} className={classes['social-icon']} />
            <p className={classes['social-text']}>IMDB</p>
          </div>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!includeImdbUrl}
            setIsLeftContentSelected={setIncludeImdbUrl}
          />
        </div>
        <Input
          placeholder="Enter link here"
          style={imdbInputStyle}
          value={imdbUrl}
          onChange={imdbUrlChangeHandler}
        />

        <div className={classes['profile-photo']}>
          <p className={classes['profile-photo-text']}>Profile Photo</p>
          <div className={classes['profile-photo-container']}>
            <ImageUpload onInput={profilePhotoInputHandler}>
              <div className={classes['profile-camera-container']}>
                <img
                  alt="Camera"
                  className={classes['profile-photo-camera-icon']}
                  src={CameraIcon}
                />
              </div>
            </ImageUpload>
            <img
              alt="Profile"
              className={classes['profile-photo-image']}
              src={profilePhotoPreviewUrl}
            />
          </div>
        </div>

        <div className={classes['cover-photo-status-container']}>
          <p className={classes['cover-photo-text']}>Cover Photo</p>
          <div className={classes['cover-photo-container']}>
            <ImageUpload onInput={coverPhotoInputHandler}>
              <div className={classes['camera-container']}>
                <img
                  alt="Camera"
                  className={classes['cover-photo-camera-icon']}
                  src={CameraIcon}
                />
              </div>
            </ImageUpload>
            <img
              alt="Cover"
              className={classes['cover-photo-image']}
              src={coverPhotoPreviewUrl}
            />
          </div>
        </div>

        <SliderButton
          leftValue={<p className={classes['thin-text']}>Private</p>}
          rightValue={<p className={classes['thin-text']}>Public</p>}
          isLeftContentSelected={!isCollectionStatusPublic}
          setIsLeftContentSelected={setIsCollectionStatusPublic}
        />

        <div
          style={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <Button
            style={{ width: 200, margin: '1rem 0' }}
            darkpink="true"
            disabled={isCreateCollectionButtonDisabled()}
            onClick={editCollectionHandler}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EditCollectionModal;
