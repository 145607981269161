import React, { useState } from 'react';
import classes from './SentCollaboratorNftUploadRequest.module.css';
import { useQueryClient } from 'react-query';
import Button from '../Button/Button';
import ReactPlayer from 'react-player';
import { IPFS_URL } from '../../constants/IPFS';
import moment from 'moment';
import { limitString } from '../../helpers/helpers';
import VerifiedIcon from '../../assets/verified.png';
import notify from '../../utils/notify';
import { deleteMyNftRequestUploadAsCollaboratorRequest } from '../../httpRequests/httpRequests';

function SentCollaboratorNftUploadRequest(props) {
  const queryClient = useQueryClient();
  const {
    ipfs,
    type,
    from,
    previewUrl,
    updatedAt,
    nftCollection,
    comment,
    collaboratorNftRequestUploadId,
  } = props;

  const [cancelling, setCancelling] = useState(false);

  const invalidateMyRequestedNftUploadsFromCollaboratorsHandler = () => {
    return Promise.all([
      queryClient.invalidateQueries('my-requested-nft-uploads-as-collaborator'),
      queryClient.refetchQueries('my-requested-nft-uploads-as-collaborator'),
    ]);
  };

  const deleteMyNftRequestUploadAsCollaboratorHandler = async () => {
    setCancelling(true);

    try {
      await deleteMyNftRequestUploadAsCollaboratorRequest(
        collaboratorNftRequestUploadId
      );

      await invalidateMyRequestedNftUploadsFromCollaboratorsHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setCancelling(false);
  };

  return (
    <div className={classes['sent-collaborator-nft-request-container']}>
      {type === 'image' ? (
        <img
          className={classes['nft-image']}
          src={previewUrl || `${IPFS_URL}/${ipfs}`}
          alt="IPFS Hash"
        />
      ) : (
        <ReactPlayer
          style={{
            objectFit: 'cover',
            borderRadius: 10,
          }}
          width={330}
          height={300}
          url={previewUrl || `${IPFS_URL}/${ipfs}`}
        />
      )}

      <div className={classes['sent-collaborator-nft-request-info-container']}>
        <div className={classes['from-info-container']}>
          <p className={classes['from-text']}>To: </p>
          <img className={classes['from-photo']} src={from.photo} alt="From" />
          <p className={classes['from-fullName']}>{from.fullName}</p>
          {from.verified && (
            <img width={14} src={VerifiedIcon} alt="Verified" />
          )}
        </div>

        <p className={classes['sent-on']}>
          Sent on: {moment(updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
        </p>

        <div className={classes['collection-container']}>
          <p className={classes['collection-text']}>Collection:</p>
          <img
            src={nftCollection.coverPhoto}
            alt="Collection"
            className={classes['collection-cover-photo']}
          />
          <p className={classes['collection-title']}>
            {limitString(nftCollection.name, 16)}
          </p>
        </div>

        <p className={classes['comment']}>Comment: {comment}</p>

        <div className={classes['button-actions-container']}>
          <Button
            disabled={cancelling}
            onClick={deleteMyNftRequestUploadAsCollaboratorHandler}
            modernpinkcolor="true"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SentCollaboratorNftUploadRequest;
