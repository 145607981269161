import React, { useContext, useEffect, useMemo, useState } from 'react';
import classes from './NftCollections.module.css';
import CreateCollectionModal from './components/CreateCollectionModal/CreateCollectionModal';
import { getAllNftCollectionsRequest } from '../../httpRequests/httpRequests';
import { useInfiniteQuery } from 'react-query';
import NftCollection from '../../components/NftCollection/NftCollection';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import notify from '../../utils/notify';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import MetaTags from 'react-meta-tags';
import { useLocation } from 'react-router-dom';
import CreatedCollectionLinkQRCodeModal from './components/CreateCollectionModal/components/CreatedCollectionLinkQRCodeModal/CreatedCollectionLinkQRCodeModal';
import { AuthContext } from '../../context/auth-context';

const RESULTS_PER_PAGE = 12;

function NftCollections() {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const claimableParam = queryParams.get('claimable');

  const { profile } = useContext(AuthContext);

  const [showCreateCollectionModal, setShowCreateCollectionModal] = useState(
    false
  );
  const [searchValue, setSearchValue] = useState('');

  const [
    createdCollectionLinkQRCodeModalState,
    setCreatedCollectionLinkQRCodeModalState,
  ] = useState({
    collectionId: undefined,
    opened: false,
    claimable: undefined,
    collectionName: undefined,
  });

  const [isCollectionFilterChecked, setIsCollectionFilterChecked] = useState(
    true
  );
  const [isAirdropFilterChecked, setIsAirdropFilterChecked] = useState(true);
  const [isFeaturedChecked, setIsFeaturedChecked] = useState(true);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [
      'nft-collections',
      searchValue,
      isCollectionFilterChecked,
      isAirdropFilterChecked,
      isFeaturedChecked,
      isAllChecked,
    ],
    ({ pageParam = 1 }) =>
      getAllNftCollectionsRequest(
        pageParam,
        RESULTS_PER_PAGE,
        searchValue,
        profile?._id,
        isCollectionFilterChecked,
        isAirdropFilterChecked,
        isFeaturedChecked,
        isAllChecked
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    if (claimableParam === '1' || claimableParam === '0') {
      setShowCreateCollectionModal(true);
    }
  }, [claimableParam]);

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  // const openCreateCollectionModalHandler = () => {
  //   if (!myNftCollectionAccessToCreate) {
  //     return notify(
  //       'error',
  //       'You do not have access to create a Collection!',
  //       3000
  //     );
  //   }
  //   setShowCreateCollectionModal(true);
  //   // if (
  //   //   myWalletInformation &&
  //   //   (parseFloat(myWalletInformation.glimpseBalance) >= 2000 ||
  //   //     parseInt(myWalletInformation.nftBalance) > 0)
  //   // ) {
  //   //   setShowCreateCollectionModal(true);
  //   // } else {
  //   //   notify(
  //   //     'error',
  //   //     'You should have at least 2000 GLMS in Balance or have bought an NFT to create a Collection!',
  //   //     2000
  //   //   );
  //   // }
  // };

  return (
    <>
      <MetaTags>
        <title>Collections | Glimpse</title>
        <meta property="og:title" content={`Collections | Glimpse`} />
      </MetaTags>

      {showCreateCollectionModal && (
        <CreateCollectionModal
          setShowCreateCollectionModal={setShowCreateCollectionModal}
          setCreatedCollectionLinkQRCodeModalState={
            setCreatedCollectionLinkQRCodeModalState
          }
        />
      )}

      {createdCollectionLinkQRCodeModalState.opened && (
        <CreatedCollectionLinkQRCodeModal
          collectionId={createdCollectionLinkQRCodeModalState.collectionId}
          setCreatedCollectionLinkQRCodeModalState={
            setCreatedCollectionLinkQRCodeModalState
          }
          createdCollectionLinkQRCodeModalState={
            createdCollectionLinkQRCodeModalState
          }
        />
      )}

      <div className={classes['nft-collections-page-container']}>
        <div className={classes['collections-container']}>
          <div className={classes['search-create-collection-container']}>
            <input
              value={searchValue}
              onChange={searchValueChangeHandler}
              placeholder="Search by username or Collection name"
              className={classes['search-input']}
            />

            <div className={classes['featured-all-filter-container']}>
              <div className={classes['featured-filter-container']}>
                <input
                  onChange={() =>
                    setIsFeaturedChecked((prevState) => !prevState)
                  }
                  checked={isFeaturedChecked}
                  className={classes['checkbox']}
                  type="checkbox"
                />
                <p
                  onClick={() =>
                    setIsFeaturedChecked((prevState) => !prevState)
                  }
                >
                  Featured
                </p>
              </div>

              <div className={classes['all-filter-container']}>
                <input
                  checked={isAllChecked}
                  onChange={() => setIsAllChecked((prevState) => !prevState)}
                  className={classes['checkbox']}
                  type="checkbox"
                />
                <p onClick={() => setIsAllChecked((prevState) => !prevState)}>
                  All
                </p>
              </div>
            </div>

            <div className={classes['collection-airdrop-filter-container']}>
              <div className={classes['collection-filter-container']}>
                <input
                  onChange={() =>
                    setIsCollectionFilterChecked((prevState) => !prevState)
                  }
                  checked={isCollectionFilterChecked}
                  className={classes['checkbox']}
                  type="checkbox"
                />
                <p
                  onClick={() =>
                    setIsCollectionFilterChecked((prevState) => !prevState)
                  }
                >
                  Collection
                </p>
              </div>

              <div className={classes['airdrop-filter-container']}>
                <input
                  checked={isAirdropFilterChecked}
                  onChange={() =>
                    setIsAirdropFilterChecked((prevState) => !prevState)
                  }
                  className={classes['checkbox']}
                  type="checkbox"
                />
                <p
                  onClick={() =>
                    setIsAirdropFilterChecked((prevState) => !prevState)
                  }
                >
                  Airdrop
                </p>
              </div>
            </div>

            {/* <div
              onClick={openCreateCollectionModalHandler}
              className={classes['create-collection']}
            >
              <div className={classes['add-container']}>
                <img
                  alt="Add"
                  className={classes['add-icon']}
                  width={12}
                  height={12}
                  src={AddIcon}
                />
              </div>
              <p className={classes['create-collection-text']}>
                Create Collection
              </p>
            </div> */}
          </div>

          {!isLoading && data && data.pages && data.pages[0].results === 0 && (
            <div
              style={{
                background: 'white',
                padding: '.8rem',
                borderRadius: 10,
                marginTop: 10,
                width: '95%',
                gridColumn: '1/-1',
              }}
            >
              <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
                No Collections Found!
              </p>
            </div>
          )}

          <div className={classes['nft-collections-list']}>
            {!isLoading &&
              data &&
              data.pages &&
              data.pages.map((page) => {
                return page.data.map((nftCollection) => (
                  <div
                    key={nftCollection._id}
                    className={classes['nft-collection']}
                  >
                    <NftCollection nftCollection={nftCollection} />
                    <div className={classes['horizontal-line']}></div>
                  </div>
                ));
              })}
          </div>
          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
}

export default NftCollections;
