import React, { useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import { isInputValidationCompleted } from '../../../helpers/helpers';
import { forgotPasswordRequest } from '../../../httpRequests/httpRequests';
import notify from '../../../utils/notify';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import classes from './EmailForgotPasswordModal.module.css';

function EmailForgotPasswordModal({
  setShowEmailForgotPasswordModal,
  setShowForgotPasswordModal,
}) {
  const [email, setEmail] = useState({
    value: '',
    valid: false,
    touched: false,
    validationRequirements: { isEmail: true },
  });

  const inputChangeHandler = (value) => {
    const updatedEmail = { ...email };
    updatedEmail.value = value;
    updatedEmail.touched = true;
    updatedEmail.valid = isInputValidationCompleted(
      value,
      updatedEmail.validationRequirements
    );

    setEmail(updatedEmail);
  };

  let inputStyle = { width: '100%' };
  if (email.touched && email.valid === false) {
    inputStyle = { ...inputStyle, border: '1px solid red' };
  }

  const sendVerificationCodeToEmail = async () => {
    try {
      const requestBody = { email: email.value };
      await forgotPasswordRequest(requestBody);
      notify(
        'success',
        'Verification code sent to your email successfully!',
        2000
      );
      setShowEmailForgotPasswordModal(false);
      setShowForgotPasswordModal(true);
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const closeModalHandler = () => {
    setShowEmailForgotPasswordModal(false);
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '100%' }}
            darkpink="true"
            onClick={sendVerificationCodeToEmail}
            disabled={!email.valid}
          >
            Get Verification Code
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Forgot password?"
    >
      <p className={classes['label']}>Email</p>
      <Input
        style={inputStyle}
        type="text"
        placeholder={'Enter here'}
        value={email.value}
        onChange={(e) => inputChangeHandler(e.target.value)}
      />
    </Modal>
  );
}

export default EmailForgotPasswordModal;
