import React, { useState } from 'react';
import classes from './DeleteNftCollectionModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import notify from '../../../../utils/notify';

function DeleteNftCollectionModal(props) {
  const { setShowDeleteNftCollectionModal, deleteNftCollectionHandler } = props;

  const [deleting, setDeleting] = useState(false);
  const [securityText, setSecurityText] = useState('');

  const closeModalHandler = () => {
    setShowDeleteNftCollectionModal(false);
  };

  const securityTextChangeHandler = (e) => {
    setSecurityText(e.target.value);
  };

  const deleteCollectionHandler = async () => {
    setDeleting(true);
    try {
      await deleteNftCollectionHandler();
      setDeleting(false);

      closeModalHandler();
    } catch (err) {
      setDeleting(false);
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      style={{ height: 500 }}
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            disabled={deleting}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={securityText !== 'Delete' || deleting}
            onClick={deleteCollectionHandler}
            darkpink="true"
          >
            Delete
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Delete Collection"
    >
      <div>
        <p className={classes['text']}>
          Deleting this collection will remove all affiliations with any
          existing NFTs and can not be undone.
        </p>
        <p className={classes['text']}>
          If you'd like to continue, please type "Delete" below and click Delete
          collection.
        </p>

        <Input
          style={{ width: '100%', marginTop: 10 }}
          placeholder="Delete"
          value={securityText}
          onChange={securityTextChangeHandler}
        />
      </div>
    </Modal>
  );
}

export default DeleteNftCollectionModal;
