import React, { useEffect } from 'react';
import classes from './NftCollectionWatchlist.module.css';
import { useInfiniteQuery } from 'react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import { getMyNftCollectionWatchlistRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import NftCollection from '../../../../components/NftCollection/NftCollection';

const RESULTS_PER_PAGE = 12;

function NftCollectionWatchlist() {
  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    'my-nft-collection-watchlist',
    ({ pageParam = 1 }) =>
      getMyNftCollectionWatchlistRequest(pageParam, RESULTS_PER_PAGE),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <>
      {!isLoading && data && data.pages && data.pages[0].results === 0 && (
        <div
          style={{
            background: 'white',
            padding: '.8rem',
            borderRadius: 10,
            marginTop: 10,
            width: '95%',
            gridColumn: '1/-1',
          }}
        >
          <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
            No Collections on your Watchlist!
          </p>
        </div>
      )}

      <div className={classes['nft-collections-list']}>
        {!isLoading &&
          data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map(({ nftCollection }) => (
              <NftCollection
                key={nftCollection._id}
                nftCollection={nftCollection}
              />
            ));
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </>
  );
}

export default NftCollectionWatchlist;
