import React, { useEffect, useRef, useState } from 'react';
import classes from './RequestNftAsCollaboratorModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import {
  getMyNftsNotPartOfACollectionAndCollaboratorRequestStatusRequest,
  requestCreatorToAddMyNftAsCollaboratorRequest,
} from '../../../../httpRequests/httpRequests';
import Input from '../../../../components/Input/Input';
import SearchIcon from '../../../../assets/search-icon.png';
import ReactPlayer from 'react-player';
import { IPFS_URL } from '../../../../constants/IPFS';
import { limitString } from '../../../../helpers/helpers';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import notify from '../../../../utils/notify';

const RESULTS_PER_PAGE = 20;

function RequestNftAsCollaboratorModal(props) {
  const { setShowRequestNftAsCollaboratorModal, nftCollectionId } = props;
  const nftListContainerRef = useRef();

  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [
      'my-nfts-not-part-of-collection-and-collaborator-request-status',
      searchValue,
    ],
    ({ pageParam = 1 }) =>
      getMyNftsNotPartOfACollectionAndCollaboratorRequestStatusRequest(
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  const closeModalHandler = () => {
    setShowRequestNftAsCollaboratorModal(false);
  };

  useEffect(() => {
    const nftListContainerElement = nftListContainerRef.current;

    if (!nftListContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    nftListContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      nftListContainerElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invaliateNftsNotPartOfCollectionListHandler = async () => {
    return Promise.all([
      queryClient.invalidateQueries([
        'my-nfts-not-part-of-collection-and-collaborator-request-status',
        searchValue,
      ]),
      queryClient.refetchQueries([
        'my-nfts-not-part-of-collection-and-collaborator-request-status',
        searchValue,
      ]),
    ]);
  };

  const requestNftAsCollaboratorToCreatorOfCollectionHandler = async (
    nftId
  ) => {
    try {
      await requestCreatorToAddMyNftAsCollaboratorRequest(
        nftCollectionId,
        nftId
      );

      invaliateNftsNotPartOfCollectionListHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderCollaboratorRequestStatusButton = (
    nftId,
    collaboratorRequestStatusExists,
    collaboratorNftRequest
  ) => {
    if (
      collaboratorRequestStatusExists &&
      collaboratorNftRequest?.nftCollection === nftCollectionId
    ) {
      if (collaboratorNftRequest?.status === 'Pending') {
        return (
          <Button modernpinkcolor="true" style={{ padding: '.5rem' }} disabled>
            Pending
          </Button>
        );
      } else if (collaboratorNftRequest?.status === 'Accepted')
        return (
          <Button modernpinkcolor="true" style={{ padding: '.5rem' }} disabled>
            Accepted
          </Button>
        );
      else if (collaboratorNftRequest?.status === 'Rejected')
        return (
          <Button modernpinkcolor="true" style={{ padding: '.5rem' }} disabled>
            Rejected
          </Button>
        );
    } else if (
      collaboratorRequestStatusExists &&
      collaboratorNftRequest?.nftCollection !== nftCollectionId &&
      collaboratorNftRequest?.status === 'Pending'
    ) {
      return (
        <Button modernpinkcolor="true" style={{ padding: '.5rem' }} disabled>
          Pending
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() =>
            requestNftAsCollaboratorToCreatorOfCollectionHandler(nftId)
          }
          modernpinkcolor="true"
          style={{ padding: '.5rem' }}
        >
          Request
        </Button>
      );
    }
  };

  return (
    <Modal
      style={{ minHeight: 650 }}
      closeModal={closeModalHandler}
      title="Request NFTs to Collection"
      footer={
        <div className={classes['footer']}>
          <Button pinkcolor="true" onClick={closeModalHandler}>
            Close
          </Button>
        </div>
      }
    >
      <div className={classes['input-container']}>
        <Input
          placeholder="Search NFT"
          style={{ width: '100%' }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <img
          alt="Search"
          src={SearchIcon}
          width={18}
          className={classes['search-icon']}
        />
      </div>

      <div className={classes['table-header']}>
        <p>NFT</p>
        <p>TITLE</p>
        <p>Request Status</p>
      </div>
      <div className={classes['table-horizontal-line']}></div>
      <div ref={nftListContainerRef} className={classes['nft-list-container']}>
        {!isLoading && data && data.pages && data.pages[0].results === 0 && (
          <p style={{ opacity: '.4', gridColumn: '1/-1' }}>No NFTs Found!</p>
        )}

        {!isLoading &&
          data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map((nft) => {
              return (
                <div key={nft._id} className={classes['nft']}>
                  {nft.type === 'video' ? (
                    <ReactPlayer
                      width={44}
                      height={44}
                      style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                      url={
                        nft.previewUrl
                          ? nft.previewUrl
                          : `${IPFS_URL}/${nft.ipfs}`
                      }
                    />
                  ) : (
                    <img
                      width={44}
                      height={44}
                      className={classes['nft-image']}
                      src={
                        nft.previewUrl
                          ? nft.previewUrl
                          : `${IPFS_URL}/${nft.ipfs}`
                      }
                      alt="IPFS"
                    />
                  )}
                  <p title={nft.title} className={classes['nft-title']}>
                    {limitString(nft.title, 20)}
                  </p>

                  {renderCollaboratorRequestStatusButton(
                    nft._id,
                    nft.collaboratorNftRequestExists,
                    nft.collaboratorNftRequest
                  )}
                </div>
              );
            });
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </Modal>
  );
}

export default RequestNftAsCollaboratorModal;
