import React, { useEffect, useState } from 'react';
import classes from './AssociatedGroupsModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { useQuery, useQueryClient } from 'react-query';
import {
  addNftCollectionToASpecificGroupRequest,
  getMyGroupsAsAdminRequest,
  removeNftCollectionFromASpecificGroupRequest,
  updateNftCollectionStatusRequest,
} from '../../../../httpRequests/httpRequests';
import Input from '../../../../components/Input/Input';
import GroupPresentationImage from '../../../../assets/group-presentation-image.png';
import { limitString } from '../../../../helpers/helpers';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import notify from '../../../../utils/notify';
import SliderButton from '../../../../components/SliderButton/SliderButton';

function AssociatedGroupsModal(props) {
  const { setShowAssociatedGroupsModal, nftCollection } = props;
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState('');
  const [isCollectionStatusPublic, setIsCollectionStatusPublic] = useState(
    nftCollection.status === 'Public'
  );
  const [updatingStatus, setUpdatingStatus] = useState(false);

  const { isLoading, data, error } = useQuery(
    ['my-groups-as-admin', searchValue],
    () => getMyGroupsAsAdminRequest(searchValue)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    if (nftCollection.status === 'Public') {
      setIsCollectionStatusPublic(true);
    } else {
      setIsCollectionStatusPublic(false);
    }
  }, [nftCollection.status]);

  const closeModalHandler = () => {
    setShowAssociatedGroupsModal(false);
  };

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const invalidateNftCollection = async () => {
    await Promise.all([
      queryClient.invalidateQueries([
        'nft-collection-information',
        nftCollection._id,
      ]),

      queryClient.refetchQueries([
        'nft-collection-information',
        nftCollection._id,
      ]),
    ]);
  };

  const addNftCollectionToGroupHandler = async (groupId) => {
    try {
      await addNftCollectionToASpecificGroupRequest(nftCollection._id, groupId);

      invalidateNftCollection();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const removeNftCollectionFromGroupHandler = async (groupId) => {
    try {
      await removeNftCollectionFromASpecificGroupRequest(
        nftCollection._id,
        groupId
      );
      invalidateNftCollection();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderActionButton = (groupId) => {
    if (
      !nftCollection.associatedGroups ||
      nftCollection.associatedGroups?.length === 0
    )
      return (
        <div style={{ marginLeft: 'auto' }}>
          <Button
            style={{
              width: 150,
            }}
            onClick={() => addNftCollectionToGroupHandler(groupId)}
            darkpinkcolor="true"
          >
            Add
          </Button>
        </div>
      );

    if (
      Array.isArray(nftCollection.associatedGroups) &&
      nftCollection.associatedGroups.find(({ _id }) => _id === groupId)
    ) {
      return (
        <div style={{ marginLeft: 'auto' }}>
          <Button
            style={{
              width: 150,
            }}
            onClick={() => removeNftCollectionFromGroupHandler(groupId)}
            darkpink="true"
          >
            Remove
          </Button>
        </div>
      );
    }

    return (
      <div style={{ marginLeft: 'auto' }}>
        <Button
          disabled={nftCollection.associatedGroups?.length === 1}
          style={{
            width: 150,
          }}
          onClick={() => addNftCollectionToGroupHandler(groupId)}
          darkpinkcolor="true"
        >
          Add
        </Button>
      </div>
    );
  };

  const saveNftCollectionStatusHandler = async () => {
    closeModalHandler();
  };

  useEffect(() => {
    const nftCollectionStatusChangeHandler = async () => {
      setUpdatingStatus(true);

      await updateNftCollectionStatusRequest(nftCollection._id, {
        status: isCollectionStatusPublic ? 'Public' : 'Private',
      });
      await invalidateNftCollection();
      setUpdatingStatus(false);
    };

    nftCollectionStatusChangeHandler();
    // eslint-disable-next-line
  }, [isCollectionStatusPublic, nftCollection._id]);

  return (
    <Modal
      style={{ minHeight: 520 }}
      closeModal={closeModalHandler}
      title="Associated Groups"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={updatingStatus}
            darkpink="true"
            onClick={saveNftCollectionStatusHandler}
          >
            Save
          </Button>
        </div>
      }
    >
      <Input
        style={{ width: '100%', margin: '10px 0' }}
        placeholder="Search Group"
        value={searchValue}
        onChange={searchValueChangeHandler}
      />

      <div className={classes['groups-list']}>
        {!isLoading && data?.data && data.data.length === 0 && (
          <p className={classes['no-groups-found']}>No Groups Found</p>
        )}
        {!isLoading &&
          data?.data &&
          data.data.map(({ group }) => {
            return (
              <div key={group._id} className={`${classes['group']}`}>
                {group.coverPhoto ? (
                  <img
                    className={classes['cover-photo']}
                    src={group.coverPhoto}
                    alt="Group Cover"
                  />
                ) : (
                  <img
                    className={classes['cover-photo']}
                    src={GroupPresentationImage}
                    alt={'Group Cover'}
                  />
                )}
                <div>
                  <p title={group.name} className={classes['group-name']}>
                    {limitString(group.name, 20)}
                  </p>
                </div>

                {renderActionButton(group._id)}
              </div>
            );
          })}

        {isLoading && <LoadingSpinner />}
      </div>

      <SliderButton
        disabled={nftCollection.associatedGroups.length === 0 || updatingStatus}
        leftValue={<p className={classes['thin-text']}>Private</p>}
        rightValue={<p className={classes['thin-text']}>Public</p>}
        isLeftContentSelected={!isCollectionStatusPublic}
        setIsLeftContentSelected={setIsCollectionStatusPublic}
      />
    </Modal>
  );
}

export default AssociatedGroupsModal;
