import React, { useContext, useEffect, useState, useMemo } from 'react';
import classes from './ClaimRandomNftFromClaimableCollection.module.css';
import { useQuery } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import {
  claimRandomNftFromASpecificClaimableCollectionRequest,
  getNftMetaDataForASpecificFungibilityAgnosticCollectionRequest,
} from '../../httpRequests/httpRequests';
import { IPFS_URL } from '../../constants/IPFS';
import notify from '../../utils/notify';
import ReactPlayer from 'react-player';
import { AuthContext } from '../../context/auth-context';
import GoToLoginPageModal from './components/GoToLoginPageModal/GoToLoginPageModal';

import StarIcon from '../../assets/white-star-icon.png';
import PersonalizedVideoIcon from '../../assets/personalized-video-icon.png';
import PersonalizedImageIcon from '../../assets/personalized-image-icon.png';
import ChatBenefitIcon from '../../assets/chat-benefit-icon.png';
import VideoCallBenefitIcon from '../../assets/video-call-benefit-icon.png';
import BenefitsModal from '../NftViewContent/components/BenefitsModal/BenefitsModal';

const POPULAR_BENEFITS = [
  '1/1 Chat (15mins)',
  '1/1 Video Call (15mins)',
  'Personalized Image',
  'Personalized Video',
];

function ClaimRandomNftFromClaimableCollection() {
  const { profile } = useContext(AuthContext);
  const history = useHistory();
  const { collectionId } = useParams();
  const [claiming, setClaiming] = useState(false);
  const [showGoToLoginPageModal, setShowGoToLoginPageModal] = useState(false);
  const [showBenefitsModal, setShowBenefitsModal] = useState(false);

  const { data, error } = useQuery(
    ['nft-metadata-for-fungibilty-agnostic-collection', collectionId],
    () =>
      getNftMetaDataForASpecificFungibilityAgnosticCollectionRequest(
        collectionId
      )
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const claimRandomNftFromASpecificClaimableCollectionHandler = async () => {
    if (!profile) {
      return setShowGoToLoginPageModal(true);
    }

    setClaiming(true);

    try {
      const {
        data,
      } = await claimRandomNftFromASpecificClaimableCollectionRequest(
        collectionId
      );

      setClaiming(false);
      notify(
        'success',
        `You have successfully claimed NFT (${data.nft.title}). Congrats!`,
        2000
      );

      setTimeout(() => {
        history.push(`/nfts/${data.nft?._id}`);
      }, 2000);
    } catch (err) {
      setClaiming(false);
      notify('error', err, 2000);
    }
  };

  const nftMetaData = data?.data || undefined;

  const renderNftOrCollectionCoverPhoto = () => {
    if (!nftMetaData.isPartOfFungibilityAgnosticCollection) {
      return (
        <img
          className={classes['nft-image']}
          src={nftMetaData.collectionCoverPhoto}
          alt={nftMetaData.collectionName}
        />
      );
    }

    return nftMetaData.type === 'video' ? (
      <ReactPlayer
        style={{
          objectFit: 'cover',
          borderRadius: 10,
        }}
        width={400}
        height={300}
        url={nftMetaData.previewUrl || `${IPFS_URL}/${nftMetaData.ipfs}`}
      />
    ) : (
      <img
        className={classes['nft-image']}
        src={nftMetaData.previewUrl || `${IPFS_URL}/${nftMetaData.ipfs}`}
        alt={nftMetaData.collectionName}
      />
    );
  };

  const customAndNonCustomBenefits = useMemo(() => {
    if (
      !nftMetaData ||
      !nftMetaData.benefits ||
      nftMetaData.benefits?.length === 0
    )
      return { customBenefits: [], nonCustomBenefits: [] };

    const customBenefits = [];
    const nonCustomBenefits = [];
    nftMetaData.benefits.forEach((benefit) => {
      if (POPULAR_BENEFITS.includes(benefit)) {
        nonCustomBenefits.push(benefit);
      } else {
        customBenefits.push(benefit);
      }
    });

    return {
      customBenefits,
      nonCustomBenefits,
    };
  }, [nftMetaData]);

  const openBenefitsModalHandler = () => {
    if (
      nftMetaData.benefits &&
      Array.isArray(nftMetaData.benefits) &&
      nftMetaData.benefits?.length > 0
    ) {
      setShowBenefitsModal(true);
    } else {
      notify('error', 'No benefits connected with this collectible!', 3000);
    }
  };

  const { customBenefits, nonCustomBenefits } = customAndNonCustomBenefits;

  const renderBenefits = () => {
    const isChatIncluded = nonCustomBenefits.includes('1/1 Chat (15mins)');
    const isVideoCallIncluded = nonCustomBenefits.includes(
      '1/1 Video Call (15mins)'
    );
    const isPersonalizedImageIncluded = nonCustomBenefits.includes(
      'Personalized Image'
    );
    const isPersonalizedVideoIncluded = nonCustomBenefits.includes(
      'Personalized Video'
    );

    return (
      <div className={classes['custom-non-custom-benefits-container']}>
        {isChatIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={ChatBenefitIcon} alt="Chat Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                1/1 Chat (15mins)
              </p>
              <p className={classes['benefits-text']}>Benefits</p>
            </div>
          </div>
        )}

        {isVideoCallIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={VideoCallBenefitIcon} alt="Video Call Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                1/1 Video Call (15mins)
              </p>
              <p className={classes['benefits-text']}>Benefits</p>
            </div>
          </div>
        )}

        {isPersonalizedImageIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={PersonalizedImageIcon} alt="Personalized Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                Personalized Image
              </p>
              <p className={classes['benefits-text']}>Benefits</p>
            </div>
          </div>
        )}

        {isPersonalizedVideoIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={PersonalizedVideoIcon} alt="Personalized Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                Personalized Video
              </p>
              <p className={classes['benefits-text']}>Benefits</p>
            </div>
          </div>
        )}

        {customBenefits.length > 0 && (
          <div
            onClick={openBenefitsModalHandler}
            className={classes['custom-benefits-container']}
          >
            <img
              src={StarIcon}
              alt="Custom Benefits"
              className={classes['star-icon']}
            />
            <div className={classes['custom-text-container']}>
              <p className={classes['custom-benefits-text-bold']}>
                Custom Benefits
              </p>
              <p className={classes['custom-benefits-text']}>Benefits</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes['claim-nft-page']}>
      {showGoToLoginPageModal && <GoToLoginPageModal />}

      {showBenefitsModal && (
        <BenefitsModal
          setShowBenefitsModal={setShowBenefitsModal}
          benefits={customBenefits}
        />
      )}

      <div>
        {nftMetaData && (
          <div>
            <div className={classes['nft-container']}>
              {renderNftOrCollectionCoverPhoto()}

              <div className={classes['name-description-container']}>
                <Link to={`/collections/${nftMetaData.collectionId}`}>
                  <div className={classes['collection-name-header']}>
                    <p className={classes['collection-header-text']}>
                      Collection:{' '}
                    </p>
                    <img
                      className={classes['collection-profile-photo-small']}
                      src={nftMetaData.collectionProfilePhoto}
                      alt={nftMetaData.collectionName}
                    />
                    <h3 className={classes['collection-name']}>
                      {nftMetaData.collectionName}
                    </h3>
                  </div>
                </Link>

                <p className={classes['description']}>
                  {nftMetaData.description}
                </p>

                <div className={classes['creator-collection-container']}>
                  <div className={classes['creator-container']}>
                    <img
                      className={classes['creator-image']}
                      src={nftMetaData.collectionCreator.photo}
                      alt={nftMetaData.collectionCreator.fullName}
                    />
                    <div>
                      <p className={classes['creator-text']}>Creator</p>
                      <p className={classes['creator']}>
                        {nftMetaData.collectionCreator.fullName}
                      </p>
                    </div>
                  </div>
                  {/* <Link to={`/collections/${nftMetaData.collectionId}`}>
                    <div className={classes['collection-info-container']}>
                      <p className={classes['collection-text']}>Collection:</p>
                      <div
                        className={
                          classes['collection-profile-photo-name-container']
                        }
                      >
                        <img
                          className={classes['collection-profile-photo']}
                          src={nftMetaData.collectionProfilePhoto}
                          alt={nftMetaData.collectionName}
                        />
                        <p className={classes['collection-name']}>
                          {nftMetaData.collectionName}
                        </p>
                      </div>
                    </div>
                  </Link> */}
                </div>
              </div>

              {renderBenefits()}

              <div className={classes['horizontal-line']}></div>
              <div className={classes['claim-button-container']}>
                <Button
                  disabled={claiming}
                  onClick={
                    claimRandomNftFromASpecificClaimableCollectionHandler
                  }
                  style={{ width: '100%', marginBottom: 10 }}
                  darkpink="true"
                >
                  Claim
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClaimRandomNftFromClaimableCollection;
