import React, { useState } from 'react';
import ImageModal from '../../../../../../../../components/ImageModal/ImageModal';
import classes from './AlbumPhoto.module.css';

function AlbumPhoto(props) {
  const { image } = props;
  const [showAlbumPhotoModal, setShowAlbumPhotoModal] = useState(false);

  return (
    <>
      {showAlbumPhotoModal && (
        <ImageModal
          setShowImageModal={() => setShowAlbumPhotoModal(false)}
          title="Album Photo"
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={image}
            alt="AlbumPhoto"
          />
        </ImageModal>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '1rem',
        }}
      >
        <div>
          <img
            onClick={() => setShowAlbumPhotoModal(true)}
            alt={'AlbumPhoto'}
            className={classes['image']}
            src={image}
          />
        </div>
      </div>
    </>
  );
}

export default AlbumPhoto;
