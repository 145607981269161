import React, { useEffect, useState } from 'react';
import classes from './MyGroups.module.css';
import { useInfiniteQuery } from 'react-query';
import { getMyGroupsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { limitString } from '../../../../helpers/helpers';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import CommunitiesIcon from '../../../../assets/white-communities-icon.png';

const RESULTS_PER_PAGE = 12;

function MyGroups() {
  const history = useHistory();
  const [searchValue] = useState('');
  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-groups', searchValue],
    ({ pageParam = 1 }) =>
      getMyGroupsRequest(pageParam, RESULTS_PER_PAGE, searchValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <div className={classes['my-groups-page']}>
      <div className={classes['my-groups-list']}>
        {data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map(({ numberOfMembers, group }) => {
              return (
                <div
                  key={group._id}
                  className={`${
                    group.status === 'private'
                      ? classes['private-suggested-group']
                      : classes['suggested-group']
                  }`}
                  style={{ background: group.coverPhoto && 'transparent' }}
                >
                  {group.coverPhoto && (
                    <img
                      src={group.coverPhoto}
                      alt="Cover"
                      className={classes['cover-photo']}
                    />
                  )}
                  <div className={classes['group-info-container']}>
                    <div className={classes['communities-icon-container']}>
                      <img
                        className={classes['communities-icon']}
                        src={CommunitiesIcon}
                        alt="Communities"
                      />
                    </div>

                    <div className={classes['group-name-status-container']}>
                      <h3 className={classes['group-name']}>
                        {limitString(group.name, 14)}
                      </h3>

                      {group.status === 'private' ? (
                        <p className={classes['status-text']}>PRIVATE</p>
                      ) : (
                        <p className={classes['status-text']}>PUBLIC </p>
                      )}
                    </div>
                  </div>

                  <div className={classes['members-container']}>
                    <h3 className={classes['members-text']}>MEMBERS</h3>
                    <p className={classes['members-value']}>
                      {numberOfMembers}
                    </p>
                  </div>

                  <div className={classes['join-request-container']}>
                    <div
                      onClick={() => history.push(`/groups/${group._id}`)}
                      className={classes['join-container']}
                    >
                      <p className={classes['join-text']}>VISIT</p>
                    </div>
                  </div>
                </div>
              );
            });
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default MyGroups;
