import React, { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import CardNft from '../../components/Nft/Nft';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { getNftsInAuctionRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';

const RESULTS_PER_PAGE = 6;

function NftsInAuction(props) {
  const {
    sortByValue,
    sortByValues,
    page,
    searchValue,
    isSafeContentSelected,
  } = props;

  const sortBy = sortByValues.find((el) => el.value === sortByValue);
  let sortByFilter = 'ending-soon';

  if (sortBy?.sortByValue === 'Price low to high') {
    sortByFilter = 'low-high';
  } else if (sortBy?.sortByValue === 'Price high to low') {
    sortByFilter = 'high-low';
  } else if (sortBy?.sortByValue === 'Ending Soon') {
    sortByFilter = 'ending-soon';
  } else if (sortBy?.sortByValue === 'Most recent') {
    sortByFilter = 'most-recent';
  }

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['nfts-in-auction', page, sortByFilter, searchValue, isSafeContentSelected],
    ({ pageParam = 1 }) =>
      getNftsInAuctionRequest(
        pageParam,
        sortByFilter,
        searchValue,
        isSafeContentSelected
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
    }
  );

  useEffect(() => {
    error && notify('error', error, 3000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <>
      <div className="grid-nfts">
        {!isLoading &&
          data.pages.map((page) => {
            return page.data.map((nft) => {
              return <CardNft key={nft._id} nft={nft} owner={nft.owner} />;
            });
          })}
      </div>
      {(isLoading || isFetching) && <LoadingSpinner />}
      {!isLoading && data && data.pages[0].results === 0 && (
        <h2 style={{ color: 'white', fontWeight: 'bold' }}>
          No NFTs found with the provided filters!
        </h2>
      )}
    </>
  );
}

export default NftsInAuction;
