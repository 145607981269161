import React, { useRef, useState, useEffect } from 'react';

const AudioUpload = (props) => {
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    props.onInput(pickedFile, fileIsValid, previewUrl);
  };

  const pickAudioHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <>
      <input
        id={props.id}
        ref={filePickerRef}
        style={{ display: 'none' }}
        type="file"
        accept=".mp3"
        onChange={pickedHandler}
      />

      {!props.children ? (
        <div
          shape="round"
          className={`btn-photo-upload`}
          onClick={pickAudioHandler}
          style={{ cursor: 'pointer' }}
        >
          <span>+</span>
        </div>
      ) : (
        <span style={{ padding: 0, margin: 0 }} onClick={pickAudioHandler}>
          {props.children}
        </span>
      )}

      {!isValid && <p>{props.errorText}</p>}
    </>
  );
};
export default AudioUpload;
