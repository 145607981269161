import React, { useEffect, useState } from 'react';
import classes from './SelectMyCommunitiesModal.module.css';
import Button from '../../../../../../../../../../components/Button/Button';
import Modal from '../../../../../../../../../../components/Modal/Modal';
import { useQuery } from 'react-query';
import { getMyGroupsAsAdminRequest } from '../../../../../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../../../../../utils/notify';
import SearchIcon from '../../../../../../../../../../assets/search-icon.png';
import LoadingSpinner from '../../../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import Input from '../../../../../../../../../../components/Input/Input';
import { limitString } from '../../../../../../../../../../helpers/helpers';
import GroupPresentationImage from '../../../../../../../../../../assets/group-presentation-image.png';

function SelectMyCommunitiesModal(props) {
  const {
    setShowSelectMyCommunitiesModal,
    selectedCommunityIds,
    setSelectedCommunityIds,
    setSelectedPostStatus,
    setShowSelectPostStatusModal,
  } = props;

  const [searchValue, setSearchValue] = useState('');

  const { isLoading, data, error } = useQuery(
    ['my-groups-as-admin', searchValue],
    () => getMyGroupsAsAdminRequest(searchValue)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const closeModalHandler = () => {
    setShowSelectMyCommunitiesModal(false);
  };

  const toggleCommunityIdsHandler = (group) => {
    const groupId = group._id;

    const updatedCommunityIds = { ...selectedCommunityIds };

    if (updatedCommunityIds[groupId]) {
      delete updatedCommunityIds[groupId];
    } else {
      updatedCommunityIds[groupId] = group;
    }

    setSelectedCommunityIds(updatedCommunityIds);
  };

  const saveSelectedCommunitiesHandler = () => {
    setSelectedPostStatus('My Communities');
    closeModalHandler();
    setShowSelectPostStatusModal(false);
  };

  return (
    <Modal
      style={{ height: 550 }}
      title="Select Communities"
      // goBackHandler={closeModalHandler}
      // goBackTitle="true"
      // title={() => (
      //   <div className={classes['title-container']}>
      //     <h3 className={classes['select-followers-text']}>
      //       Select Communities
      //     </h3>
      //     <p className={classes['show-to-selected-followers-text']}>
      //       Show to selected Communities
      //     </p>
      //   </div>
      // )}
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={Object.keys(selectedCommunityIds).length === 0}
            onClick={saveSelectedCommunitiesHandler}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
    >
      <div>
        <div className={classes['input-search-container']}>
          <Input
            style={{ width: '100%' }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search Community"
          />
          <img
            alt="Search"
            className={classes['search-icon']}
            src={SearchIcon}
          />
        </div>

        <div className={classes['group-list']}>
          {!isLoading && data?.data && data.data.length === 0 && (
            <p className={classes['no-groups-found']}>No Groups Found</p>
          )}

          {!isLoading &&
            data?.data &&
            data.data.map(({ group }) => {
              return (
                <>
                  <div key={group._id} className={`${classes['group']}`}>
                    {group.coverPhoto ? (
                      <img
                        className={classes['cover-photo']}
                        src={group.coverPhoto}
                        alt="Group Cover"
                      />
                    ) : (
                      <img
                        className={classes['cover-photo']}
                        src={GroupPresentationImage}
                        alt={'Group Cover'}
                      />
                    )}
                    <p title={group.name} className={classes['group-name']}>
                      {limitString(group.name, 20)}
                    </p>

                    <div className={classes['add-container']}>
                      {selectedCommunityIds[group._id] ? (
                        <Button
                          darkpinkcolor="true"
                          style={{ width: '150px', textAlign: 'center' }}
                          onClick={() => toggleCommunityIdsHandler(group)}
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button
                          style={{ width: '150px', textAlign: 'center' }}
                          onClick={() => toggleCommunityIdsHandler(group)}
                          darkpink="true"
                          disabled={
                            Object.keys(selectedCommunityIds).length > 0
                          }
                        >
                          Add
                        </Button>
                      )}
                    </div>

                    {/* <div
                    onClick={() => toggleCommunityIdsHandler(group)}
                    style={{
                      border: !selectedCommunityIds[group._id]
                        ? '1px solid #999999'
                        : '1px solid #00B432',
                    }}
                    className={classes['add-container']}
                  >
                    <img
                      alt="Plus"
                      src={
                        selectedCommunityIds[group._id]
                          ? GreenPlusIcon
                          : GrayPlusIcon
                      }
                      height={12}
                      className={classes['plus-icon']}
                    />
                  </div> */}
                  </div>
                  <div className={classes['horizontal-line']}></div>
                </>
              );
            })}
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </Modal>
  );
}

export default SelectMyCommunitiesModal;
