import React, { useRef, useEffect, useState } from 'react';
import classes from './AddNftsToCollectionModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import {
  addNftToCollectionRequest,
  getMyNftsNotPartOfACollectionRequest,
} from '../../../../httpRequests/httpRequests';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import notify from '../../../../utils/notify';
import { limitString } from '../../../../helpers/helpers';
import { IPFS_URL } from '../../../../constants/IPFS';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import ReactPlayer from 'react-player';
import Input from '../../../../components/Input/Input';
import SearchIcon from '../../../../assets/search-icon.png';

const RESULTS_PER_PAGE = 10;

function AddNftsToCollectionModal(props) {
  const { setShowAddNftsToCollectionModal, collectionId } = props;
  const nftListContainerRef = useRef();
  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-nfts-not-part-of-collection', searchValue],
    ({ pageParam = 1 }) =>
      getMyNftsNotPartOfACollectionRequest(
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const nftListContainerElement = nftListContainerRef.current;

    if (!nftListContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    nftListContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      nftListContainerElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const closeModalHandler = () => {
    setShowAddNftsToCollectionModal(false);
  };

  const invalidateNftCollectionData = () => {
    [
      ['nft-collection-stats', collectionId],
      ['nfts-for-specific-collection', collectionId, '', true],
    ].forEach((queryKey) => {
      queryClient.invalidateQueries(queryKey);
      queryClient.refetchQueries(queryKey);
    });
  };

  const addNftToCollectionHandler = async (nftId) => {
    try {
      await addNftToCollectionRequest(collectionId, nftId);

      queryClient.setQueryData(
        ['my-nfts-not-part-of-collection', searchValue],
        (data) => {
          return {
            ...data,
            pages: data.pages.map((page) => {
              const updatedData = page.data.filter((nft) => nft._id !== nftId);

              return { ...page, data: updatedData };
            }),
          };
        }
      );

      invalidateNftCollectionData();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      style={{ minHeight: 650 }}
      closeModal={closeModalHandler}
      title="Add NFTs to Collection"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Close
          </Button>
        </div>
      }
    >
      <div className={classes['input-container']}>
        <Input
          placeholder="Search NFT"
          style={{ width: '100%' }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <img
          alt="Search"
          src={SearchIcon}
          width={18}
          className={classes['search-icon']}
        />
      </div>

      <div className={classes['table-header']}>
        <p>NFT</p>
        <p>TITLE</p>
      </div>
      <div className={classes['table-horizontal-line']}></div>
      <div ref={nftListContainerRef} className={classes['nft-list-container']}>
        {!isLoading && data && data.pages && data.pages[0].results === 0 && (
          <p style={{ opacity: '.4', gridColumn: '1/-1' }}>No NFTs Found!</p>
        )}

        {!isLoading &&
          data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map((nft) => {
              return (
                <div key={nft._id} className={classes['nft']}>
                  {nft.type === 'video' ? (
                    <ReactPlayer
                      width={44}
                      height={44}
                      style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                      url={
                        nft.previewUrl
                          ? nft.previewUrl
                          : `${IPFS_URL}/${nft.ipfs}`
                      }
                    />
                  ) : (
                    <img
                      width={44}
                      height={44}
                      className={classes['nft-image']}
                      src={
                        nft.previewUrl
                          ? nft.previewUrl
                          : `${IPFS_URL}/${nft.ipfs}`
                      }
                      alt="IPFS"
                    />
                  )}
                  <p title={nft.title} className={classes['nft-title']}>
                    {limitString(nft.title, 20)}
                  </p>

                  <Button
                    onClick={() => addNftToCollectionHandler(nft._id)}
                    darkpink="true"
                    style={{ alignSelf: 'start', width: 150 }}
                  >
                    Add
                  </Button>
                </div>
              );
            });
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </Modal>
  );
}

export default AddNftsToCollectionModal;
