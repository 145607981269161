import React, { useState, useContext, useEffect, useMemo } from 'react';
import classes from './Chats.module.css';
import { useQuery, useQueryClient } from 'react-query';
import {
  createSingleChatRequest,
  getMemberRoleInASpecificGroupRequest,
  getMyChatsRequest,
  getNumberOfMyRequestedChatsRequest,
  markChatMessageAsReceivedRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import Chat from './components/Chat';
import { AuthContext } from '../../context/auth-context';
import { SocketContext } from '../../context/socket-context';
import { MESSAGE_CREATED } from '../../constants/SOCKET_EVENTS';
import { Link, useLocation } from 'react-router-dom';
import CreateChatIcon from '../../assets/create-chat (2).png';
import Input from '../../components/Input/Input';
import SearchIcon from '../../assets/search-icon.png';
import SelectedChat from './components/SelectedChat/SelectedChat';
import EmptyChat from './components/EmptyChat/EmptyChat';
import CreateGroupChatModal from './components/CreateGroupChatModal/CreateGroupChatModal';
import MetaTags from 'react-meta-tags';
import RequestedChatList from './components/RequestedChatList/RequestedChatList';
import CommunitiesChatList from './components/CommunitiesChatList/CommunitiesChatList';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import GoBackIcon from '../../assets/go-back-icon-2.png';

import Messages from '../MyGroups/components/Chat/components/Messages/Messages';

let CHAT_GROUP_ID = undefined;
const CHAT_SECTIONS = ['Primary', 'Communities', 'Requests'];

function Chats() {
  const queryClient = useQueryClient();

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const userIdQueryParam = queryParams.get('user');
  const tabParam = queryParams.get('tab');
  const idParam = queryParams.get('id');

  const {
    profile,
    getNumberOfUnreadMessagesForMyChats,
    numberOfUnreadGroupMessagesForMyGroups,
    numberOfUnreadMessagesForMyChats,
  } = useContext(AuthContext);
  const { socket } = useContext(SocketContext);

  const { isLoading, data, error } = useQuery('my-chats', getMyChatsRequest);
  const numberOfMyRequestedChatsResponse = useQuery(
    'number-of-my-requested-chats',
    getNumberOfMyRequestedChatsRequest
  );

  const [selectedChatSection, setSelectedChatSection] = useState('Primary');
  const [selectedCommunity, setSelectedCommunity] = useState();
  const [selectedCommunityChannel, setSelectedCommunityChannel] = useState();
  const [showCreateGroupChatModal, setShowCreateGroupChatModal] = useState(
    false
  );
  const [selectedChatGroupId, setSelectedChatGroupId] = useState(undefined);
  const [selectedChatGroup, setSelectedChatGroup] = useState(undefined);
  const [searchChatValue, setSearchChatValue] = useState('');

  const [
    isMyRoleAdminForSelectedCommunity,
    setIsMyRoleAdminForSelectedCommunity,
  ] = useState(false);

  useEffect(() => {
    const getMyRoleForSelectedCommunity = async () => {
      if (!selectedCommunity) return;

      try {
        const { data } = await getMemberRoleInASpecificGroupRequest(
          selectedCommunity,
          profile?._id
        );

        setIsMyRoleAdminForSelectedCommunity(data.role === 'admin');
      } catch (err) {}
    };

    getMyRoleForSelectedCommunity();
  }, [selectedCommunity, profile]);

  useEffect(() => {
    const retriveOrCreateSingleChat = async () => {
      if (userIdQueryParam) {
        try {
          const { existingChat, data: chat } = await createSingleChatRequest(
            userIdQueryParam
          );

          if (existingChat) {
            setSelectedChatGroup(chat);
            setSelectedChatGroupId(chat._id);
          } else {
            await queryClient.invalidateQueries('my-chats');
            await queryClient.refetchQueries('my-chats');

            setSelectedChatGroup(chat);
            setSelectedChatGroupId(chat._id);
          }
        } catch (error) {
          notify('error', error, 2500);
        }
      }
    };

    retriveOrCreateSingleChat();
  }, [userIdQueryParam, queryClient]);

  useEffect(() => {
    CHAT_GROUP_ID = selectedChatGroupId;
  }, [selectedChatGroupId]);

  useEffect(() => {
    socket.on(
      MESSAGE_CREATED,
      async ({ chatMessage, sender, chatName, isDirectMessage }) => {
        if (CHAT_GROUP_ID !== chatMessage.chat) {
          await markChatMessageAsReceivedRequest(
            chatMessage.chat,
            chatMessage._id
          );
          queryClient.invalidateQueries(['chat-messages', chatMessage.chat]);
          queryClient.refetchQueries(['chat-messages', chatMessage.chat]);
          queryClient.invalidateQueries('my-chats');
          queryClient.refetchQueries('my-chats');

          getNumberOfUnreadMessagesForMyChats();

          let notifyMessage = `${sender} just sent a message (${chatMessage.message}) to one of your chats (${chatName})`;
          if (isDirectMessage) {
            notifyMessage = `${sender} just sent you a direct message (${chatMessage.message})`;
          }
          notify('success', notifyMessage, 2000);
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    error && notify('error', error, 3000);
  }, [error]);

  useEffect(() => {
    if (!tabParam || !idParam || !data?.data) return;

    if (tabParam === 'primary') {
      const chat = data.data.find((el) => el._id === idParam);
      if (!chat) return;
      setSelectedChatGroup(chat);
      setSelectedChatGroupId(chat._id);
      CHAT_GROUP_ID = idParam;
    } else if (tabParam === 'communities') {
      const channelNameParam = queryParams.get('channel');
      const privateChannelParam = queryParams.get('private');
      const channelIdParam = queryParams.get('channelId');

      if (!channelNameParam || !privateChannelParam || !channelNameParam)
        return;

      const selectedCommunityChannel = {
        channelName: channelNameParam,
        channelId: channelIdParam,
        privateChannel: privateChannelParam === 1 ? true : false,
      };
      setSelectedCommunityChannel(selectedCommunityChannel);
      setSelectedCommunity(idParam);
      setSelectedChatSection('Communities');
    } else if (tabParam === 'requests') {
      setSelectedChatSection('Requests');
    }
    // eslint-disable-next-line
  }, [tabParam, idParam, data]);

  const myChats = useMemo(() => {
    if (!data) return [];

    const chats = data.data;

    return chats;
  }, [data]);

  const filteredChatsBySearchValue = useMemo(() => {
    const filteredChats = myChats.filter((chat) => {
      if (chat.isGroupChat) {
        return chat.name.toLowerCase().includes(searchChatValue.toLowerCase());
      } else {
        const otherUserIfSingleChat = chat.users.find(
          (user) => user._id !== profile?._id
        );
        if (
          `${otherUserIfSingleChat.firstName} ${otherUserIfSingleChat.lastName}`
            .toLowerCase()
            .includes(searchChatValue.toLowerCase())
        )
          return true;
      }

      return false;
    });

    return filteredChats;
  }, [myChats, searchChatValue, profile?._id]);

  const openCreateChatModalHandler = () => {
    setShowCreateGroupChatModal(true);
  };

  return (
    <>
      <MetaTags>
        <title>Chats | Glimpse</title>
        <meta property="og:title" content={'Chats | Glimpse'} />
      </MetaTags>

      {showCreateGroupChatModal && (
        <CreateGroupChatModal
          setSelectedChatGroup={setSelectedChatGroup}
          setSelectedChatGroupId={setSelectedChatGroupId}
          myChats={data.data}
          setShowCreateGroupChatModal={setShowCreateGroupChatModal}
        />
      )}
      <div className={classes['chats-container']}>
        <div className={classes['sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['messages-box']}>
          <div className={classes['messages-header']}>
            <Link to="/news-feed">
              <img
                src={GoBackIcon}
                alt="Go Back"
                className={classes['go-back-icon']}
              />
            </Link>
            <h3 className={classes['messages-text']}>Messages</h3>
          </div>

          <div className={classes['chats-messages-container']}>
            <div className={classes['chats']}>
              <div className={classes['header']}>
                <h3 className={classes['chat-text']}>Chat</h3>

                <div className={classes['icons-container']}>
                  <img
                    alt="Create Chat Group"
                    onClick={openCreateChatModalHandler}
                    className={classes['create-chat-icon']}
                    src={CreateChatIcon}
                    width={20}
                  />
                </div>
              </div>

              <div className={classes['chat-sections']}>
                {CHAT_SECTIONS.map((section) => {
                  return (
                    <div
                      onClick={() => setSelectedChatSection(section)}
                      key={section}
                      className={classes['section-container']}
                    >
                      <div
                        className={`${classes['section-text']} ${
                          section === selectedChatSection
                            ? classes['selected-section']
                            : ''
                        }`}
                      >
                        <span>{section}</span>
                        {section === 'Primary' && (
                          <span
                            className={classes['number-of-unread-messages']}
                          >
                            <span
                              className={
                                classes['number-of-unread-messages-text']
                              }
                            >
                              {numberOfUnreadMessagesForMyChats}
                            </span>
                          </span>
                        )}
                        {section === 'Communities' && (
                          <span
                            className={classes['number-of-unread-messages']}
                          >
                            <span
                              className={
                                classes['number-of-unread-messages-text']
                              }
                            >
                              {numberOfUnreadGroupMessagesForMyGroups}
                            </span>
                          </span>
                        )}
                        {section === 'Requests' &&
                          numberOfMyRequestedChatsResponse.data?.data !==
                            undefined && (
                            <span
                              className={classes['number-of-unread-messages']}
                            >
                              <span
                                className={
                                  classes['number-of-unread-messages-text']
                                }
                              >
                                {numberOfMyRequestedChatsResponse.data?.data}
                              </span>
                            </span>
                          )}
                        {section === selectedChatSection && (
                          <div className={classes['selected-border']}>
                            &nbsp;
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={classes['horizontal-line']}></div>

              <div className={classes['input-container']}>
                <Input
                  value={searchChatValue}
                  onChange={(e) => setSearchChatValue(e.target.value)}
                  style={{ width: '98%', marginTop: 12 }}
                  className={classes['search-input']}
                  placeholder={'Search here'}
                />
                <img
                  alt="Search"
                  className={classes['search-icon']}
                  src={SearchIcon}
                  width={18}
                />
              </div>

              {selectedChatSection === 'Primary' && (
                <div className={classes['chat-list']}>
                  {!isLoading &&
                    !error &&
                    data &&
                    filteredChatsBySearchValue.length === 0 && (
                      <p style={{ opacity: '.7', marginTop: 20 }}>
                        No Chats Found!
                      </p>
                    )}

                  {!isLoading &&
                    !error &&
                    data &&
                    filteredChatsBySearchValue.map((chat) => {
                      return (
                        <Chat
                          isFavorite={chat.isFavorite}
                          setSelectedChatGroup={setSelectedChatGroup}
                          selectedChatGroupId={selectedChatGroupId}
                          setSelectedChatGroupId={setSelectedChatGroupId}
                          key={chat._id}
                          chat={chat}
                        />
                      );
                    })}
                </div>
              )}

              {selectedChatSection === 'Requests' && (
                <RequestedChatList
                  searchChatValue={searchChatValue}
                  setSelectedChatGroup={setSelectedChatGroup}
                  selectedChatGroupId={selectedChatGroupId}
                  setSelectedChatGroupId={setSelectedChatGroupId}
                />
              )}

              {selectedChatSection === 'Communities' && (
                <CommunitiesChatList
                  searchChatValue={searchChatValue}
                  selectedCommunity={selectedCommunity}
                  setSelectedCommunity={setSelectedCommunity}
                  selectedCommunityChannel={selectedCommunityChannel}
                  setSelectedCommunityChannel={setSelectedCommunityChannel}
                />
              )}
            </div>

            {selectedChatSection !== 'Communities' ? (
              <div className={classes['selected-chat-container']}>
                {selectedChatGroup ? (
                  <SelectedChat
                    isFavorite={
                      myChats.find((chat) => chat._id === selectedChatGroup._id)
                        ?.isFavorite
                    }
                    requestedChat={
                      selectedChatGroup.requested &&
                      selectedChatGroup.organizer !== profile?._id
                    }
                    setSelectedChatGroup={setSelectedChatGroup}
                    setSelectedChatGroupId={setSelectedChatGroupId}
                    selectedChatGroupId={selectedChatGroupId}
                    selectedChat={selectedChatGroup}
                  />
                ) : (
                  data?.data &&
                  !userIdQueryParam && (
                    <EmptyChat
                      setSelectedChatGroup={setSelectedChatGroup}
                      setSelectedChatGroupId={setSelectedChatGroupId}
                      myChats={data.data}
                    />
                  )
                )}
              </div>
            ) : selectedCommunityChannel ? (
              <div className={classes['community-messages-container']}>
                <Messages
                  setSelectedChannelId={() => {}}
                  setIsSelectedChannelPrivate={() => {}}
                  isMyRoleAdmin={isMyRoleAdminForSelectedCommunity}
                  setSelectedChannelName={() => {}}
                  selectedChannelName={selectedCommunityChannel.channelName}
                  isSelectedChannelPrivate={
                    selectedCommunityChannel.privateChannel
                  }
                  channelId={selectedCommunityChannel.channelId}
                  groupId={selectedCommunity}
                />
              </div>
            ) : (
              data?.data && (
                <div className={classes['empty-chat-container']}>
                  <EmptyChat
                    setSelectedChatGroup={setSelectedChatGroup}
                    setSelectedChatGroupId={setSelectedChatGroupId}
                    myChats={data.data}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(Chats);
