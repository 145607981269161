import React, { useRef, useEffect, useState } from 'react';
import classes from './UniqueOwnersModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { useInfiniteQuery } from 'react-query';
import { getUniqueOwnersForASpecificNftCollectionRequest } from '../../../../httpRequests/httpRequests';
import User from '../../../../components/User/User';
import Input from '../../../../components/Input/Input';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import notify from '../../../../utils/notify';

const RESULTS_PER_PAGE = 10;

function UniqueOwnersModal(props) {
  const { setShowUniqueOwnersModal, nftCollectionId } = props;

  const uniqueOwnersListRef = useRef();
  const [searchValue, setSearchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['unique-owners-for-specific-nft-collection', nftCollectionId, searchValue],
    ({ pageParam = 1 }) =>
      getUniqueOwnersForASpecificNftCollectionRequest(
        nftCollectionId,
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const uniqueOwnersListElement = uniqueOwnersListRef.current;

    if (!uniqueOwnersListElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    uniqueOwnersListElement.addEventListener('scroll', scrollHandler);

    return () =>
      uniqueOwnersListElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const closeModalHandler = () => {
    setShowUniqueOwnersModal(false);
  };

  return (
    <Modal
      style={{ height: 550 }}
      closeModal={closeModalHandler}
      title="Unique Owners"
      footer={
        <div className={classes['footer']}>
          <Button pinkcolor="true" onClick={closeModalHandler}>
            Close
          </Button>
        </div>
      }
    >
      <div ref={uniqueOwnersListRef} className={classes['unique-owners-list']}>
        <Input
          style={{ width: '100%', marginBottom: 10 }}
          value={searchValue}
          onChange={searchValueChangeHandler}
          placeholder="Search user"
        />

        {data?.pages && data.pages[0].results === 0 && <p>No Results found!</p>}

        {data?.pages &&
          data.pages.map((page) => {
            return page.data.map(({ owner }) => {
              return (
                <User
                  closeModal={closeModalHandler}
                  key={owner._id}
                  user={owner}
                />
              );
            });
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </Modal>
  );
}

export default UniqueOwnersModal;
