import React, { useContext, useEffect, useMemo, useState } from 'react';
import classes from './GetStarted.module.css';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import GoBackIcon from '../../assets/go-back-icon-2.png';
import { useHistory } from 'react-router-dom';
import PinkPlusIcon from '../../assets/pink-plus-icon.png';
import { useQuery, useQueryClient } from 'react-query';
import {
  claimGetStartedTaskRequest,
  getGetStartedTasksCompletionInfoRequest,
  getMyClaimedGetStartedTasksRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import FollowPeopleModal from './components/FollowPeopleModal/FollowPeopleModal';
import CompleteYourProfileModal from './components/CompleteYourProfileModal/CompleteYourProfileModal';
import JoinCommunityModal from './components/JoinCommunityModal/JoinCommunityModal';
import ReferFriendsModal from './components/ReferFriendsModal/ReferFriendsModal';
import CreateFirstNftModal from './components/CreateFirstNftModal/CreateFirstNftModal';
import { AuthContext } from '../../context/auth-context';
import MetaTags from 'react-meta-tags';
import StripeAccountModal from './components/StripeAccountModal/StripeAccountModal';
import { Helmet } from 'react-helmet';

const TASK_KEY_HOW_TO_TITLE_MAPPING = {
  COMPLETE_YOUR_PROFILE: 'My Profile',
  CREATE_FIRST_COLLECTIBLE: 'Create a Collectible',
  BUYING_SELLING_TRADING: 'Buying, Selling, and Trading',
  GLMS_DIAMONDS: 'GLMS + Diamonds',
  JOIN_COMMUNITY: 'Communities',
  COLLECTIONS: 'Collections',
  AIRDROPS: 'Airdrops',
  FOLLOW_PEOPLE: 'Follow People',
  REFER_FRIENDS: 'Refer Friends',
  LINK_BANK_ACCOUNT_VIA_STRIPE: 'Link Bank Account',
};

const CLAIM_TASK_KEY_MAPPING = {
  FOLLOW_PEOPLE: 'follow-people',
  COMPLETE_YOUR_PROFILE: 'complete-profile',
  CREATE_FIRST_COLLECTIBLE: 'create-first-nft',
  JOIN_COMMUNITY: 'join-community',
  REFER_FRIENDS: 'refer-friends',
  LINK_BANK_ACCOUNT_VIA_STRIPE: 'link-bank-account-via-stripe',
};

function GetStarted() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { profile, getMyProfile } = useContext(AuthContext);

  const { isLoading, data, error } = useQuery(
    'get-started-tasks-completion-info',
    getGetStartedTasksCompletionInfoRequest
  );

  const myClaimedTasksResponse = useQuery(
    'my-claimed-get-started-tasks',
    getMyClaimedGetStartedTasksRequest
  );

  const myClaimedTasks = useMemo(() => {
    if (!myClaimedTasksResponse.data) return [];

    return myClaimedTasksResponse.data.data.map(({ task }) => task);
  }, [myClaimedTasksResponse]);

  const [taskQuestions, setTaskQuestions] = useState({
    FOLLOW_PEOPLE: [
      {
        id: 29,
        question: 'How do I find users?',
        answer: `You can follow and connect with users by browsing your main feed, joining communities, or by going to the explore tab and selecting the ‘Users’ tab.`,
        expanded: false,
      },
      {
        id: 30,
        question: 'How do I follow users?',
        answer: `You can click ‘Follow’ directly on a user post to begin following the author of that post, you can follow people directly from the explore explore tab, or you can go directly to a users profile and select the follow icon.`,
        expanded: false,
      },
      {
        id: 31,
        question: 'How do I unfollow users?',
        answer: `You can go directly to the users profile and select the unfollow icon - which is located iat the bottom right of their cover photo.`,
        expanded: false,
      },
      {
        id: 32,
        question: 'How do I block a user?',
        answer: `You can go directly to the users profile and select the vertical … icon - which is located iat the bottom right of their cover photo. You can then select the block user option on the pop-up submenu that appears. You can also block a user by selecting the vertical … icon directly from their post.`,
        expanded: false,
      },
    ],
    COMPLETE_YOUR_PROFILE: [
      {
        id: 1,
        question: 'How do I change my username?',
        answer: `On the website: Click on the dropdown arrow in the left side panel next to your profile photo (^). The dropdown menu will show a Settings tab, select it. The settings module will appear where you can select personal details. You can then edit your username. 

        On the mobile: Click on the profile tab in the bottom right of your screen. Then, click on the settings tab in the top right corner. You can then select “Personal Details” to change your username.
        `,
        expanded: false,
      },
      {
        id: 2,
        question: `How do I add other social links to my profile?`,
        answer: `Web only feature: Navigate to your profile page by clicking on your profile photo in the left side menu. In the Info section, you will see an edit button. Clicking this will open a module where you can add your other social media link like Instagram, Linktree, TikTok, IMDB, etc. Click save once you’ve added your social links.`,
        expanded: false,
      },
      {
        id: 3,
        question: `How do I change my email?`,
        answer: `On the website: Click on the dropdown arrow in the left side panel next to your profile photo (^). The dropdown menu will show a Settings tab, select it. The settings module will appear where you can select personal details. You will then see the option to change your email address. *NOTE: a code will be sent to your new email from support@glimpsenft.com to confirm this change. Please check your spam/junk folders if you do not see the email in your inbox. 

        On the mobile: Click on the profile tab in the bottom right of your screen. Then, click on the settings tab in the top right corner. You can then select “Personal Details” to change your email. *NOTE: a code will be sent to your new email from support@glimpsenft.com to confirm this change. Please check your spam/junk folders if you do not see the email in your inbox.`,
        expanded: false,
      },
      {
        id: 4,
        question: `How do I change my password?`,
        answer: `On the website: Click on the dropdown arrow in the left side panel next to your profile photo (^). The dropdown menu will show a Settings tab, select it. The settings module will appear where you can select personal details. You will then see the option to change your password.

        On the mobile: Click on the profile tab in the bottom right of your screen. Then, click on the settings tab in the top right corner. You can then select “Personal Details” to change your password.`,
        expanded: false,
      },
      {
        id: 5,
        question: `What is Request Collectible Price?`,
        answer: `Web only feature: You can set a request collectible price in your settings. This allows other users to request a specific image or video from you. This could be a unique piece of art for one of your collectors, a video of you wishing a fan happy birthday etc. The price you set is what the user will need to pay when they submit the request from you, and you can change the price at anytime.`,
        expanded: false,
      },
      {
        id: 6,
        question: ` I own a collectible and don’t want anyone else to be able to view it.`,
        answer: `Web only feature: Click on the dropdown arrow in the left side panel next to your profile photo (^). The dropdown menu will show a Settings tab, select it. The settings module will appear where you can select “Access to My Personal Collection”. Here, you can select whether to make the collectibles you purchased/claimed viewable only to you, viewable to you + your followers, or public where any Glimpse user can view what you own.`,
        expanded: false,
      },
    ],
    CREATE_FIRST_COLLECTIBLE: [
      {
        id: 7,
        question: `How do I create a single collectible on Glimpse?`,
        answer: `On the website: Click on “Create Collectible” on the left side user panel. There are multiple ways to create collectibles on Glimpse, but the easiest way is to create a single collectible, so select create a 1/1 on the module that appears. This will bring you to a page where you can enter the details for your collectible and upload the image/video. Simply enter in the information and click create! 

        On the mobile: Click on the center “Create” tab and select digital collectible. Only 1/1’s can be created on the mobile app. Follow the module steps to create a digital collectible.
        `,
        expanded: false,
      },
      {
        id: 8,
        question: `How do I share a collectible once I created it? `,
        answer: `Web only feature: Once your collectible is created, you’ll be shown a confirmation screen with a link to your collectible and a QR code (should you need it). This link can be shared across your socials and will bring anyone who clicks on it directly to your collectible. `,
        expanded: false,
      },
    ],
    JOIN_COMMUNITY: [
      {
        id: 9,
        question: 'How do I join a community?',
        answer: `The 1st way is to join a public community where any user can join. 
        
        The 2nd way requires that you own a collectible that provides access to that community. You can think of the collectible as a key, and whoever holds the key has access to that community. To determine what collectible you will need to gain access, click on the community and a module displaying the collectibles that provide access will be displayed. You’ll need to either purchase or claim (for free) one of the collectibles to gain access - which is automatically granted once you own the collectible. You can browse all communities on the explore tab.`,
        expanded: false,
      },
      {
        id: 10,
        question: `How do I create a community?`,
        answer: `Go to the communities tab on the left side user panel. You will see a submenu that has a bright pink button “Create a Community”. Clicking on this button will open a page where you can create your community by entering in a few details. The community is public by default, and can only be gated if you have an existing collection that you’d like to link. If you don’t have any collections and you’d like to create a gated community, you will need to create a collection first or link it at a later time.`,
        expanded: false,
      },
      {
        id: 11,
        question: `What is a gated community? `,
        answer: `Think of a gated community as a locked door that only users with the right key can open. These ‘keys’ are digital collectibles. Gated communities require users to own a collectible that is linked with that community in order to gain access. If a user who has access decides to sell or transfer their collectible, they will lose access to the community as access rights will be transferred to the new owner automatically.`,
        expanded: false,
      },
      {
        id: 12,
        question: `Can I create different channels in my community chat? `,
        answer: `Yes - go to the chat page in a community in which you are an admin of. You can select the + icon to add a channel for a specific discussion topic. For example, you may use the general channel for regular conversations but prefer to have a specific channel for a specific topic. Any channel (other than the general channel), can be made private or public. Only community members who are invited to the private channels will be able to view it. `,
        expanded: false,
      },
    ],
    REFER_FRIENDS: [
      {
        id: 33,
        question: 'How do I refer friends?',
        answer: `You can copy your referral link directly from the left side user menu and share it across your socials, via email, or via text. You can also select the refer friends module above and you will be able to copy your referral link, download your referral QR code, and share your link directly across your socials.`,
        expanded: false,
      },
      {
        id: 34,
        question: 'How do I know if someone used me as a referral?',
        answer: `You can click on the referral tab on the left navigation bar to go to your referral page. This page is a great way to see who is in your network!`,
        expanded: false,
      },
    ],
    BUYING_SELLING_TRADING: [
      {
        id: 13,
        question: `How do I buy a collectible?`,
        answer: `Web only feature: You can buy a collectible using your credit card, debit card, or GLMS (our platform token). To buy a collectible using credit/debit card, click “Buy Now” on the specific collectible page and select Credit/Debit Card. You can then enter in your card information and click purchase! The collectible will be immediately transferred to you. 

        To buy using GLMS, you can select “Buy Now” on the specific collectible page and select GLMS. You will need to have enough GLMS available to make the purchase, and you can purchase GLMS in the wallet section if you need to replenish your balance. 
        `,
        expanded: false,
      },
      {
        id: 14,
        question: `How do I sell a collectible?`,
        answer: `Once you create a collectible, it is automatically listed for sale! There are 3 ways to create a collectible, a single 1/1, a collection, or an airdrop (which is free for users to claim). The best way to increase your chances of selling a collectible is to build your following on Glimpse. This can be done by sharing your referral link with friends/fans, connecting with others, and posting your collectibles link on your existing social media accounts.`,
        expanded: false,
      },
      {
        id: 15,
        question: `Can I send someone a collectible I own for free?`,
        answer: `Yes! You can transfer a collectible you own to another user by going to the collectible page on Glimpse. Once you’re on the collectibles page, you will see a small icon of three vertical dots. Click on this will show the option to transfer the collectible. Simply select the user you’d like to transfer it to and click submit!`,
        expanded: false,
      },
      {
        id: 16,
        question: `Can I make an offer on a collectible?`,
        answer: `Yes - go  to the collectibles listing page and click on “Make Offer”. You will need GLMS in your account in order to make an offer. If you do not have enough GLMS, go to the wallet page to purchase GLMS using your debit/credit card. If you have enough GLMS, simply enter in your offer and click submit! The seller will be notified of your offer and you’ll also receive a notification when the seller accepts or declines your offer.`,
        expanded: false,
      },
      {
        id: 17,
        question: `I received an offer on a collectible, how do I accept or decline it?`,
        answer: `You’ll always get a notification when you receive an offer. You can also go to the submenu on the left side panel by clicking on the dropdown icon (^) to access the “Offers” tab. When you click on the “Offers” tab, you’ll be able to accept or decline offers your received. You can also view, cancel, or change offers you have sent to others.`,
        expanded: false,
      },
    ],
    GLMS_DIAMONDS: [
      {
        id: 18,
        question: `What is GLMS?`,
        answer: `GLMS is the currency of the Glimpse platform. It has a fixed value of .01 USD, just like a penny, meaning that $1 USD is 100 GLMS. Using GLMS allows users to pay for collectibles and avoid expensive credit card processing fees. It also allows you to make offers on collectibles and submit specific requests for collectibles. Lastly, GLMS allows everyone to earn from creating and selling collectibles through direct sales and royalties - even if you’re not a featured creator. Just like a gift card is a cash equivalent, meaning if you have a $10 gift card to Starbucks you have the equivalent of $10 cash, GLMS is also a cash equivalent. 

        Users can purchase and sell GLMS directly on the platform. You can convert GLMS into USD and withdraw it from Glimpse at any time using any one of our withdrawal methods. Treat GLMS like cash! 
        `,
        expanded: false,
      },
      {
        id: 19,
        question: `What are Diamonds? `,
        answer: `Diamonds are the rewards system on Glimpse. You may have been on platforms where you earn 1 point for referring a friend, a point for logging in etc., on Glimpse, we use diamonds. Diamonds are earned for things like referring friends, buying, selling, and can also be purchased directly from the platform. 

        Diamonds can be used to tip users on the platform. If you see a post you like, send some diamonds! Diamonds are also equivalent to .01 USD and can be converted into cash as well.          
        `,
        expanded: false,
      },
      {
        id: 20,
        question: `How do I purchase GLMS?`,
        answer: `GLMS can be purchased directly from the wallet page. Click on the wallet section in the bottom left of the left side user panel to get to the wallet page. Then, click on “Buy” to purchase GLMS.`,
        expanded: false,
      },
      {
        id: 21,
        question: `How do I purchase Diamonds?`,
        answer: `Click on the Diamonds section in the bottom left of the left side user panel to purchase diamonds. A module will open where you can complete your purchase.`,
        expanded: false,
      },
    ],
    COLLECTIONS: [
      {
        id: 22,
        question: `What is a collection?`,
        answer: `A collection is a group of collectibles that are related in some way. There are 2 main types of collections, Editions and Series. Both can be linked to a community to provide gated access. 

        An edition is when you have a single image/video or other asset that you would like to make multiple copies of. Unlike a 1/1, where this is only 1 collectible in existence, editions can be 1/10, 1/100, 1/1000 etc. This means that there are multiple copies of the same image/video. It can be extremely useful for creating an asset that may be super popular, so you want multiple people to be able to own it but still have control over how rare it is. The smaller the edition size, the more rare the collectible. 
        
        A series is when you have multiple images/videos where each image/video is its own 1/1 collectible. To illustrate this: imagine a nature photographer who wants to have their own area on Glimpse where users can purchase 1/1’s of their top shots. They want each shot to be a 1/1, and want to upload thousands of distinct photographs. In this way, each collectible is unique, but the photographer's entire collection can be viewed on its own page.
        `,
        expanded: false,
      },
      {
        id: 23,
        question: `How do I create a collection?`,
        answer: `Click “Create a Collectible” in the left side user panel and select collections. You can then decide whether to create an edition or series (see above FAQ for explanation). You can then follow the module steps to create your collection. Once you create your collection, you will be able to retrieve the link to your collection and notify Glimpse users on the confirmation page. `,
        expanded: false,
      },
      {
        id: 24,
        question: `What is the difference between a limited/unlimited collection?`,
        answer: `A limited collection is exactly that, limited. This means that the creator of the collection can not add more collectibles once the collection is created. This is important in cases where rarity needs to be controlled. Imagine you purchase a very rare collectible from your favorite TikTok star, only 10 have been created. You wouldn’t want them to all of a sudden create 10,000 more collectibles as it would devalue yours. With a limited collection, you can be sure that the rarity can never change. 

        An unlimited collection means that the creator can add more collectibles. This can be very useful for memberships, where more membership collectibles may need to be added later on or in the example of our nature photographer - who wants to continue adding more collectibles as they travel the world. 
        
        Both unlimited and limited collections have their benefits. If you’re unsure of what to choose for your specific use case, please contact @support on the app or email support@glimpsenft.com`,
        expanded: false,
      },
      {
        id: 25,
        question: `How do I link my collection to my community? `,
        answer: `There are two ways to link a collection to your community. 

        1st: You can link your collection during the collection creation process. If you’re an admin of a community, you’ll be able to add the community simply by selecting it. 
        
        2nd. If you have at least 1 collection, you can link your collection during the community creation process.
        `,
        expanded: false,
      },
    ],
    AIRDROPS: [
      {
        id: 26,
        question: `What is an airdrop?`,
        answer: `The best way to think about an airdrop is like a free collection. You still have all the options available to you as a traditional collection, but users “claim” collectibles for free instead of purchasing them. As an example, imagine you have an event and want attendees to be able to claim a collectible, this is a great use case! Airdrops are also a great way to grow your following, as you can giveaway a collectible to grow your fanbase as you work on your main collection. 
        `,
        expanded: false,
      },
      {
        id: 27,
        question: `How can people claim a collectible from my airdrop?`,
        answer: `Once you create your airdrop, you’ll be able to copy the link and QR code associated with your airdrop on the confirmation page. The link can be shared or sent to anyone you’d like to let claim the airdrop, and the QR code can be useful for allowing in person attendees to claim the airdrop. You can also invite users on Glimpse to claim your airdrop by sending a notification to them on the confirmation page.
        `,
        expanded: false,
      },
      {
        id: 28,
        question: `Can a user sell a collectible they claimed from an airdrop?`,
        answer: `Yes - collectibles claimed in an airdrop can be re-sold by users. This means that even if you don’t make money from the initial claims, you can receive a portion of every resale automatically! And if you’re a user who claims a free collectible, you’re more than welcome to sell it!`,
        expanded: false,
      },
    ],
    LINK_BANK_ACCOUNT_VIA_STRIPE: [
      {
        id: 35,
        question: 'How do I link my bank account?',
        answer: `Glimpse parterns with Stripe + Plaid to allow users to easily conenct their bank account. Complete the Connect Your Bank Account module above to start receiving direct payouts.`,
        expanded: false,
      },
      {
        id: 36,
        question: 'How long does it take to link my bank account?',
        answer: `Most users can link their bank account in under 60 seconds!`,
        expanded: false,
      },
    ],
  });

  const [tasksCompletionStatuses, setTasksCompletionStatuses] = useState({
    FOLLOW_PEOPLE: undefined,
    COMPLETE_YOUR_PROFILE: undefined,
    CREATE_FIRST_COLLECTIBLE: undefined,
    JOIN_COMMUNITY: undefined,
    REFER_FRIENDS: undefined,
    LINK_BANK_ACCOUNT_VIA_STRIPE: undefined,
  });

  const [selectedTask, setSelectedTask] = useState(undefined);

  const [showFollowPeopleModal, setShowFollowPeopleModal] = useState(false);
  const [
    showCompleteYourProfileModal,
    setShowCompleteYourProfileModal,
  ] = useState(false);
  const [showCreateFirstNftModal, setShowCreateFirstNftModal] = useState(false);
  const [showJoinCommunityModal, setShowJoinCommunityModal] = useState(false);
  const [showReferFriendsModal, setShowReferFriendsModal] = useState(false);
  const [showStripeAccountModal, setShowStripeAccountModal] = useState(false);

  const TO_DO_TASKS = {
    FOLLOW_PEOPLE: {
      title: 'Follow people',
      description: 'Follow 10 people to claim 10 Diamonds!',
      buttonText: 'Follow',
      buttonAction: () => setShowFollowPeopleModal(true),
      borderColor: '#EF00D7',
    },
    COMPLETE_YOUR_PROFILE: {
      title: 'Complete your profile',
      description:
        'Add a cover photo, a profile photo, and an intro to jump start your profile to claim 5 Diamonds!',
      buttonText: 'Complete',
      buttonAction: () => setShowCompleteYourProfileModal(true),
      borderColor: '#2F80ED',
    },
    CREATE_FIRST_COLLECTIBLE: {
      title: 'Create first collectible',
      description:
        'Learn about the 3 ways you can create on Glimpse and launch your first collectible to claim 5 Diamonds!',
      buttonText: 'Create',
      buttonAction: () => setShowCreateFirstNftModal(true),
      borderColor: '#04BC2C',
    },
    JOIN_COMMUNITY: {
      title: 'Join a community',
      description:
        'Learn about the different types of communities on Glimpse and join a community to connect with other like-minded users to claim 2 Diamonds!',
      buttonText: 'Join',
      buttonAction: () => setShowJoinCommunityModal(true),
      borderColor: '#0CB6C1',
    },
    REFER_FRIENDS: {
      title: 'Refer friends',
      description:
        'Referring friends is the fastest way to connect with others. You get diamonds for each referral and a bonus when you refer 5 friends!',
      buttonText: 'Refer',
      buttonAction: () => setShowReferFriendsModal(true),
      borderColor: '#D68E04',
    },
    LINK_BANK_ACCOUNT_VIA_STRIPE: {
      title: 'Link bank account',
      description:
        'As a featured creator on Glimpse, you can easily receive direct payouts to your bank account. Link your bank account now via stripe to start earning!',
      buttonText: 'Connect',
      buttonAction: () => setShowStripeAccountModal(true),
      borderColor: '#EF00D7',
    },
  };

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    if (isLoading || !data?.status) return;

    setTasksCompletionStatuses({
      FOLLOW_PEOPLE: data.myFollowingsCount >= 10,
      COMPLETE_YOUR_PROFILE: data.completeProfileTask.completed,
      CREATE_FIRST_COLLECTIBLE: data.createFirstNft,
      JOIN_COMMUNITY: data.meAsGroupMemberCount > 0,
      REFER_FRIENDS: data.myReferralsCount >= 5,
      LINK_BANK_ACCOUNT_VIA_STRIPE: data.myStripeAccountIsActive,
    });
  }, [isLoading, data]);

  const invalidateMyClaimedTasks = () =>
    Promise.all([
      queryClient.invalidateQueries('my-claimed-get-started-tasks'),
      queryClient.refetchQueries('my-claimed-get-started-tasks'),
    ]);

  const claimTaskHandler = async (taskKey) => {
    try {
      const requestBody = {
        task: CLAIM_TASK_KEY_MAPPING[taskKey],
      };

      await claimGetStartedTaskRequest(requestBody);
      invalidateMyClaimedTasks();
      getMyProfile();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const selectTaskHandler = (taskKey) => {
    if (selectedTask === taskKey) {
      setSelectedTask(undefined);
    } else {
      setSelectedTask(taskKey);
    }
  };

  const renderTasks = () => {
    const tasksKey = Object.keys(TO_DO_TASKS);

    return (
      <div className={classes['tasks-container']}>
        {tasksKey.map((taskKey) => {
          if (
            taskKey === 'LINK_BANK_ACCOUNT_VIA_STRIPE' &&
            !profile.accessToCreateStripeAccount
          )
            return null;

          const task = TO_DO_TASKS[taskKey];

          const claimTaskKey = CLAIM_TASK_KEY_MAPPING[taskKey];

          return (
            <div
              onClick={() => selectTaskHandler(taskKey)}
              key={taskKey}
              style={{
                borderLeft: `4px solid ${task.borderColor}`,
                borderRadius: 8,
              }}
              className={`${classes['task-container']} ${
                selectedTask === taskKey && classes['selected-task-container']
              }`}
            >
              <h3 className={classes['task-title']}>{task.title}</h3>
              <p className={classes['task-description']}>{task.description}</p>

              {tasksCompletionStatuses[taskKey] === true ? (
                <div className={classes['completed-claim-container']}>
                  <p
                    style={{ color: task.borderColor }}
                    className={classes['task-button-text']}
                  >
                    Completed
                  </p>
                  {myClaimedTasks.includes(claimTaskKey) ? (
                    <p className={classes['claim-text']}>Claimed</p>
                  ) : (
                    <p
                      onClick={() => claimTaskHandler(taskKey)}
                      className={classes['claim-text']}
                    >
                      Claim
                    </p>
                  )}
                </div>
              ) : (
                <p
                  onClick={task.buttonAction}
                  style={{ color: task.borderColor }}
                  className={classes['task-button-text']}
                >
                  {task.buttonText}
                </p>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const toggleAnswerExpansionHandler = (id) => {
    const updatedTaskQuestions = { ...taskQuestions };

    for (const key in updatedTaskQuestions) {
      const questions = updatedTaskQuestions[key];
      const questionToUpdate = questions.find((question) => question.id === id);
      if (questionToUpdate) {
        questionToUpdate.expanded = !questionToUpdate.expanded;
        break;
      }
    }

    setTaskQuestions(updatedTaskQuestions);
  };

  const renderQuestion = ({ id, question, answer, expanded }) => {
    return (
      <div key={id} className={classes['question-answer-container']}>
        <div
          onClick={() => toggleAnswerExpansionHandler(id)}
          className={classes['question-container']}
        >
          <img className={classes['plus-icon']} src={PinkPlusIcon} alt="Plus" />
          <p className={classes['question-text']}>{question}</p>
        </div>
        {expanded && (
          <div className={classes['answer-container']}>
            <p className={classes['answer-text']}>{answer}</p>
          </div>
        )}
      </div>
    );
  };

  const renderQuestions = () => {
    if (!selectedTask) {
      return Object.keys(taskQuestions).map((taskKey) => {
        if (
          taskKey === 'LINK_BANK_ACCOUNT_VIA_STRIPE' &&
          !profile.accessToCreateStripeAccount
        )
          return null;

        const allQuestions = [...taskQuestions[taskKey]];

        return (
          <div key={taskKey} className={classes['how-to-container']}>
            <div className={classes['how-to-text-container']}>
              <h3 className={classes['how-to-text']}>
                {TASK_KEY_HOW_TO_TITLE_MAPPING[taskKey]}
              </h3>
            </div>
            <div className={classes['questions-container']}>
              {allQuestions.map(renderQuestion)}
            </div>
          </div>
        );
      });
    } else {
      const allQuestions = [...taskQuestions[selectedTask]];

      return (
        <div className={classes['how-to-container']}>
          <div className={classes['how-to-text-container']}>
            <h3 className={classes['how-to-text']}>
              {TASK_KEY_HOW_TO_TITLE_MAPPING[selectedTask]}
            </h3>
          </div>
          <div className={classes['questions-container']}>
            {allQuestions.map(renderQuestion)}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <MetaTags>
        <title>Get Started | Glimpse</title>
        <meta property="og:title" content={`Get Started | Glimpse`} />
      </MetaTags>

      {showFollowPeopleModal && (
        <FollowPeopleModal
          setShowFollowPeopleModal={setShowFollowPeopleModal}
        />
      )}

      {showCompleteYourProfileModal && (
        <CompleteYourProfileModal
          setShowCompleteYourProfileModal={setShowCompleteYourProfileModal}
          completeProfileTaskInfo={data.completeProfileTask}
        />
      )}

      {showCreateFirstNftModal && (
        <CreateFirstNftModal
          setShowCreateFirstNftModal={setShowCreateFirstNftModal}
        />
      )}

      {showJoinCommunityModal && (
        <JoinCommunityModal
          setShowJoinCommunityModal={setShowJoinCommunityModal}
        />
      )}

      {showReferFriendsModal && (
        <ReferFriendsModal
          setShowReferFriendsModal={setShowReferFriendsModal}
        />
      )}

      {showStripeAccountModal && (
        <StripeAccountModal
          setShowStripeAccountModal={setShowStripeAccountModal}
        />
      )}

      <div className={classes['get-started-page']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        {isLoading ||
        !data?.status ||
        !myClaimedTasksResponse.data?.status ? null : (
          <div className={classes['get-started-container']}>
            <header className={classes['get-started-header']}>
              <div className={classes['go-back-container']}>
                <div onClick={() => history.push('/news-feed')}>
                  <img
                    src={GoBackIcon}
                    alt="Go Back"
                    className={classes['go-back-icon']}
                  />
                </div>
                <h3>Get Started</h3>
              </div>
            </header>

            <div className={classes['to-do-container']}>
              <div className={classes['to-do-text-container']}>
                <h3 className={classes['to-do-text']}>To Do + Announcements</h3>
              </div>
              {renderTasks()}
            </div>

            {renderQuestions()}
          </div>
        )}
      </div>

      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-na1.hs-scripts.com/24222719.js"
        ></script>
      </Helmet>
    </>
  );
}

export default GetStarted;
