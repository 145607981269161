import React from 'react';
import classes from './BenefitsModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';

function BenefitsModal(props) {
  const { benefits, setShowBenefitsModal } = props;

  const closeModalHandler = () => {
    setShowBenefitsModal(false);
  };

  return (
    <Modal
      style={{ height: 300, width: 300 }}
      title={`Holder Benefits`}
      closeModal={closeModalHandler}
      footer={
        <Button
          style={{ width: 200 }}
          darkpinkcolor="true"
          onClick={closeModalHandler}
        >
          Close
        </Button>
      }
    >
      <div className={classes['benefits-container']}>
        {benefits.map((benefit, i) => (
          <p className={classes['benefit-text']} key={benefit + i}>
            {i + 1} - {benefit}
          </p>
        ))}
      </div>
    </Modal>
  );
}

export default BenefitsModal;
