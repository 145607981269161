import React, { useState, useEffect, useRef } from 'react';
import classes from './RemovePrivateChannelMembersModal.module.css';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import { useInfiniteQuery } from 'react-query';
import { getAllMembersForASpecificPrivateGroupChannelRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import PrivateGroupChannelMember from '../../../../../../components/PrivateGroupChannelMember/PrivateGroupChannelMember';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';

const RESULTS_PER_PAGE = 3;

function RemovePrivateChannelMembersModal(props) {
  const usersListRef = useRef();
  const {
    setShowRemovePrivateChannelMembersModal,
    privateChannelId,
    groupId,
  } = props;

  const [searchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['private-channel-group-members', privateChannelId, groupId, searchValue],
    ({ pageParam = 1 }) =>
      getAllMembersForASpecificPrivateGroupChannelRequest(
        groupId,
        privateChannelId,
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const closeModalHandler = () => {
    setShowRemovePrivateChannelMembersModal(false);
  };

  useEffect(() => {
    const usersListRefElement = usersListRef.current;

    if (!usersListRefElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    usersListRefElement.addEventListener('scroll', scrollHandler);

    return () =>
      usersListRefElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  return (
    <Modal
      style={{ minHeight: 600 }}
      closeModal={closeModalHandler}
      title="Remove Members"
      footer={
        <div className={classes['footer']}>
          <Button pinkcolor="true" onClick={closeModalHandler}>
            Close
          </Button>
        </div>
      }
    >
      <div ref={usersListRef} className={classes['users-list']}>
        {!isLoading &&
          data?.pages &&
          data.pages.map((page) => {
            return page.data.map(({ user }) => (
              <PrivateGroupChannelMember
                groupId={groupId}
                privateChannelId={privateChannelId}
                user={user}
                key={user._id}
              />
            ));
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </Modal>
  );
}

export default RemovePrivateChannelMembersModal;
