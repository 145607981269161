import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import UserPost from '../../../../components/UserPost/UserPost';
import { getMyGroupsRecentActivityRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './RecentActivity.module.css';
import { getDateFrom } from '../../../../helpers/helpers';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../context/auth-context';
import NoActivivitiesImage from '../../../../assets/no-activity-found-icon.png';

function RecentActivity() {
  const { profile } = useContext(AuthContext);
  const { isLoading, data, error } = useQuery(
    'my-groups-recent-activity',
    getMyGroupsRecentActivityRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div style={{ height: '100%' }}>
      {!isLoading &&
        data &&
        data.status === 'success' &&
        data.recentGroupPostsActivity.length === 0 &&
        data.recentGroupMemberInvitationsActivity.length === 0 &&
        data.recentGroupPostTopicsActivity.length === 0 &&
        data.recentGroupChannelsActivity.length === 0 && (
          <div className={classes['no-nfts-container']}>
            <img height={200} src={NoActivivitiesImage} alt="No NFTs Found" />
            <h3 style={{ margin: 10 }}>No Activity Found!</h3>
            <p className={classes['get-back-text']}>
              {' '}
              Join a group or check back later!
            </p>
          </div>
        )}

      <div>
        {!isLoading &&
          data &&
          data.recentGroupMemberInvitationsActivity?.length > 0 &&
          data.recentGroupMemberInvitationsActivity.map(
            ({ invitedUser, group, updatedAt }) => (
              <Link
                to={`/my-groups?id=${group._id}`}
                className={classes['recentGroupMemberInvitationsActivity']}
                key={invitedUser._id}
              >
                <Link
                  to={
                    invitedUser._id === profile?._id
                      ? '/my-space'
                      : `/user-feed/${invitedUser._id}`
                  }
                >
                  <img
                    src={invitedUser.photo}
                    alt="Invited User"
                    className={classes['user-image']}
                  />
                </Link>
                <div>
                  <p className={classes['joined-text']}>
                    <Link
                      to={
                        invitedUser._id === profile?._id
                          ? '/my-space'
                          : `/user-feed/${invitedUser._id}`
                      }
                      className={classes['user-fullName']}
                    >
                      {invitedUser.fullName}
                    </Link>{' '}
                    has joined {group.name}
                  </p>
                  <p className={classes['updatedAt-text']}>
                    {getDateFrom(updatedAt)} ago
                  </p>
                </div>
              </Link>
            )
          )}
      </div>

      <div>
        {!isLoading &&
          data &&
          data.recentGroupChannelsActivity?.length > 0 &&
          data.recentGroupChannelsActivity.map(
            ({ group, name: channelName, updatedAt }) => (
              <Link
                to={`/my-groups?id=${group._id}`}
                className={classes['recentGroupChannelsActivity']}
                key={channelName}
              >
                <div>
                  <p className={classes['channel-name']}>
                    #{channelName} Channel has been created ({group.name} Group)
                  </p>
                  <p className={classes['updatedAt-text']}>
                    {getDateFrom(updatedAt)} ago
                  </p>
                </div>
              </Link>
            )
          )}
      </div>

      <div>
        {!isLoading &&
          data &&
          data.recentGroupPostTopicsActivity?.length > 0 &&
          data.recentGroupPostTopicsActivity.map(
            ({ group, name: topicName, updatedAt }) => (
              <Link
                to={`/my-groups?id=${group._id}`}
                className={classes['recentGroupPostTopicsActivity']}
                key={topicName}
              >
                <div>
                  <p className={classes['channel-name']}>
                    {topicName} Topic has been created ({group.name} Group)
                  </p>
                  <p className={classes['updatedAt-text']}>
                    {getDateFrom(updatedAt)} ago
                  </p>
                </div>
              </Link>
            )
          )}
      </div>

      <div>
        {!isLoading &&
          data &&
          data.recentGroupPostsActivity?.length > 0 &&
          data.recentGroupPostsActivity.map((post, i) => {
            const isPostLikedByMe =
              data.myLikeStatusForEachPost[i]?.likeStatus !== null;
            const groupId = post.group._id;

            return (
              <div key={post._id}>
                <UserPost
                  groupId={groupId}
                  setQueryDataKey={'my-groups-recent-activity'}
                  isGroupPost={true}
                  key={post._id}
                  post={post}
                  isPostLikedByMe={isPostLikedByMe}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default RecentActivity;
