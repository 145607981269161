import React, { useContext, useEffect, useState } from 'react';
import { getDateFrom, isValidUrl } from '../../helpers/helpers';
import classes from './UserPost.module.css';
import heart from '../../assets/filled-heart-icon.png';
import unfilledHeart from '../../assets/unfilled-heart-icon.png';
import notify from '../../utils/notify';
import {
  getNumberOfLikesForSpecificUserPostRequest,
  likeUserPostRequest,
  removeUserPostLikeRequest,
  getNumberOfCommentsForSpecificPostRequest,
  getLinkPreviewUrlRequest,
  blockUserRequest,
  getUsersWhoHaveLikedASpecificUserPostRequest,
  requestFollowingRequest,
  getTipStatusForSpecificPostRequest,
  createPostTipRequest,
} from '../../httpRequests/httpRequests';
import { useQuery, useQueryClient } from 'react-query';
import PostComments from './components/PostComments/PostComments';
import ReactPlayer from 'react-player';
import CommentIcon from '../../assets/comment.png';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import VerifiedIcon from '../../assets/verified.png';
import UsersWhoHaveLikedUserPostModal from '../../components/UsersWhoHaveLikedUserPostModal/UsersWhoHaveLikedUserPostModal';
import ImageModal from '../ImageModal/ImageModal';
import MoreIcon from '../../assets/more.png';
import EditPostModal from './components/EditPostModal/EditPostModal';
import LikedIcon from '../../assets/liked-heart-icon.png';

import GoBackIcon from '../../assets/go-back-icon.png';
import BuyPointsModal from '../../components/BuyPointsModal/BuyPointsModal';
import DiamondIcon from '../../assets/diamond.png';
import PinkDiamondIcon from '../../assets/pink-diamond-icon.png';
const TIP_OPTIONS = [1, 5, 10, 20, 50, 100];

function UserPost(props) {
  const { isPostLikedByMe, post, isGroupPost, setQueryDataKey } = props;
  const {
    profile,
    myFollowingsIds,
    getMyFollowings,
    getMyProfile,
  } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const { data, error } = useQuery(
    ['number-of-likes-for-specific-post', post._id],
    () => getNumberOfLikesForSpecificUserPostRequest(post._id)
  );
  const numberOfCommentsResponse = useQuery(
    ['number-of-comments-for-specific-post', post._id],
    () => getNumberOfCommentsForSpecificPostRequest(post._id)
  );
  const usersWhoHaveLikedUserPostResponse = useQuery(
    ['first-users-who-have-liked-user-post', post._id],
    () =>
      getUsersWhoHaveLikedASpecificUserPostRequest(
        post._id,
        1,
        12,
        '',
        profile?._id
      )
  );

  const tipStatusResponse = useQuery(['tip-status', post._id], () =>
    getTipStatusForSpecificPostRequest(post._id)
  );

  const [showPostImageModalState, setShowPostImageModalState] = useState({
    opened: false,
    image: null,
  });

  const [showComments, setShowComments] = useState(false);
  const [
    showUsersWhoHaveLikedUserPostModal,
    setShowUsersWhoHaveLikedUserPostModal,
  ] = useState(false);
  const [linkPreviews, setLinkPreviews] = useState({});
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showMultipleImagesModal, setShowMultipleImagesModal] = useState(false);
  const [multipleImagesToShow, setMultipleImagesToShow] = useState([]);
  const [showEditPostModal, setShowEditPostModal] = useState(false);
  const [followingUser, setFollowingUser] = useState(false);

  const [showTipOptions, setShowTipOptions] = useState(false);
  const [showBuyPointsModal, setShowBuyPointsModal] = useState(false);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const renderLinkPreview = (word, i) => {
    if (!linkPreviews[word])
      getLinkPreviewUrlRequest(word).then(({ data }) =>
        setLinkPreviews((prevState) => ({
          ...prevState,
          [word]: data,
        }))
      );

    return (
      <div key={word + i} style={{ margin: `20px 0` }}>
        <a
          key={word + i}
          style={{ overflowWrap: 'break-word' }}
          rel="noreferrer"
          href={word}
          className={classes['link']}
          target="_blank"
        >
          <span> </span>
          <span>{word}</span>
        </a>

        {linkPreviews[word] && !post?.postVideos?.length && (
          <div className={classes['preview-link-container']}>
            <img
              alt="Preview"
              className={classes['preview-link-image']}
              src={linkPreviews[word].image}
            />
            <div className={classes['preview-info']}>
              <h3>{linkPreviews[word].title}</h3>
              <p>{linkPreviews[word].description}</p>
            </div>
            <div className={classes['visit-link-container']}>
              <a
                className={classes['visit-link']}
                href={word}
                target="_blank"
                rel="noreferrer"
              >
                Visit
              </a>
            </div>
          </div>
        )}
      </div>
    );
  };

  const openMultipleImagesModalHandler = (images) => {
    setShowMultipleImagesModal(true);
    setMultipleImagesToShow(images);
  };

  const renderPostImages = (postImages) => {
    const { length } = postImages;

    if (length === 1) {
      return (
        <div className={classes['post-image-container']}>
          <img
            onClick={() => {
              setShowPostImageModalState({
                opened: true,
                image: postImages[0],
              });
            }}
            src={postImages[0]}
            alt="PostImage"
            className={classes['post-image']}
          />
        </div>
      );
    } else if (length === 2) {
      return (
        <div className={classes['two-images-container']}>
          {postImages.map((image, i) => (
            <img
              key={image + i}
              onClick={() => {
                setShowPostImageModalState({ opened: true, image });
              }}
              src={image}
              alt="PostImage"
              className={classes['post-image']}
            />
          ))}
        </div>
      );
    } else if (length === 3) {
      return (
        <div className={classes['three-images-container']}>
          <img
            onClick={() => {
              setShowPostImageModalState({
                opened: true,
                image: postImages[0],
              });
            }}
            src={postImages[0]}
            alt="PostImage"
            className={`${classes['three-images-0']}`}
          />

          <img
            onClick={() => {
              setShowPostImageModalState({
                opened: true,
                image: postImages[1],
              });
            }}
            src={postImages[1]}
            alt="PostImage"
            className={`${classes['three-images-1']}`}
          />

          <img
            onClick={() => {
              setShowPostImageModalState({
                opened: true,
                image: postImages[2],
              });
            }}
            src={postImages[2]}
            alt="PostImage"
            className={`${classes['three-images-2']}`}
          />
        </div>
      );
    } else if (length === 4) {
      return (
        <div className={classes['four-images-container']}>
          {postImages.map((image, i) => (
            <img
              key={image + i}
              onClick={() => {
                setShowPostImageModalState({ opened: true, image });
              }}
              src={image}
              alt="PostImage"
              className={`${classes[`four-images-${i}`]}`}
            />
          ))}
        </div>
      );
    } else if (length >= 5) {
      return (
        <div className={classes['four-images-container']}>
          {postImages.slice(0, 3).map((image, i) => (
            <img
              key={image + i}
              onClick={() => {
                setShowPostImageModalState({ opened: true, image });
              }}
              src={image}
              alt="PostImage"
              className={`${classes[`four-images-${i}`]}`}
            />
          ))}

          <div
            onClick={() => openMultipleImagesModalHandler(postImages.slice(3))}
            style={{ position: 'relative' }}
          >
            <img
              src={postImages[3]}
              alt="PostImage"
              className={`${classes[`four-images-${3}`]}`}
            />
            <div className={classes['more-images-container']}>
              <p className={classes['more-images-number']}>
                {postImages.length - 4} +
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderPostContent = () => {
    if (
      post.postContent &&
      !post.postImages?.length &&
      (!post.mentions || post.mentions?.length === 0)
    ) {
      let linkPreviewRendered = false;

      return (
        <div className={classes['post-content']}>
          {post.postContent.split('\n').map((sentence, index) => {
            if (sentence === '\r')
              return (
                <p key={sentence + index} className={classes['empty-space']}>
                  &nbsp;
                </p>
              );

            return (
              <div key={sentence + index}>
                <div></div>
                {sentence.split(' ').map((word, i) => {
                  const isUrl = isValidUrl(word);
                  if (isUrl) {
                    if (!linkPreviewRendered) {
                      linkPreviewRendered = true;
                      return renderLinkPreview(word, i);
                    } else {
                      return (
                        <a
                          key={word + i}
                          style={{ overflowWrap: 'break-word' }}
                          rel="noreferrer"
                          href={word}
                          className={classes['link']}
                          target="_blank"
                        >
                          <span> </span>
                          <span>{word}</span>
                        </a>
                      );
                    }
                  } else {
                    return (
                      <span
                        style={{ overflowWrap: 'break-word' }}
                        key={word + i}
                      >
                        <span> </span>
                        <span
                          style={{
                            color: word.startsWith('#') && '#F900B7',
                          }}
                        >
                          {word}
                        </span>
                      </span>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      );
    } else if (
      post.postContent &&
      !post.postImages?.length &&
      post.mentions?.length > 0
    ) {
      let linkPreviewRendered = false;

      return (
        <div className={classes['post-content']}>
          {post.postContent.split('\n').map((sentence, i) => {
            if (sentence === '\r')
              return (
                <p key={sentence + i} className={classes['empty-space']}>
                  &nbsp;
                </p>
              );

            return (
              <div key={sentence + i}>
                <div></div>
                {sentence.split(' ').map((word, i) => {
                  const mentionIndex = post.mentions.findIndex(
                    ({ mentionUsername }) => {
                      return mentionUsername === word;
                    }
                  );
                  if (word.startsWith('@') && mentionIndex !== -1) {
                    return (
                      <Link
                        key={word + i}
                        to={
                          post.mentions[mentionIndex]?.data?.id === profile?._id
                            ? '/my-space'
                            : `/user-feed/${post.mentions[mentionIndex]?.data?.id}`
                        }
                      >
                        <span> </span>
                        <span className={classes['mention-word']}>{word}</span>
                      </Link>
                    );
                  }

                  const isUrl = isValidUrl(word);
                  if (isUrl) {
                    if (!linkPreviewRendered) {
                      linkPreviewRendered = true;
                      return renderLinkPreview(word, i);
                    } else {
                      return (
                        <a
                          key={word + i}
                          style={{ overflowWrap: 'break-word' }}
                          rel="noreferrer"
                          href={word}
                          className={classes['link']}
                          target="_blank"
                        >
                          <span> </span>
                          <span>{word}</span>
                        </a>
                      );
                    }
                  } else {
                    return (
                      <span key={word + i}>
                        <span> </span>
                        <span
                          style={{
                            color: word.startsWith('#') && '#F900B7',
                          }}
                        >
                          {word}
                        </span>
                      </span>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      );
    }

    if (!post.postContent && post.postImages?.length) {
      return renderPostImages(post.postImages);
    }

    if (post.postContent && post.postImages?.length) {
      if (post.mentions?.length > 0) {
        let linkPreviewRendered = false;

        return (
          <div className={classes['post-content']}>
            {post.postContent.split('\n').map((sentence, index) => {
              if (sentence === '\r')
                return (
                  <p key={sentence + index} className={classes['empty-space']}>
                    &nbsp;
                  </p>
                );
              return (
                <div key={sentence + index}>
                  <div></div>
                  {sentence.split(' ').map((word, i) => {
                    const mentionIndex = post.mentions.findIndex(
                      ({ mentionUsername }) => {
                        return mentionUsername === word;
                      }
                    );
                    if (word.startsWith('@') && mentionIndex !== -1) {
                      return (
                        <Link
                          key={word + i}
                          to={
                            post.mentions[mentionIndex]?.data?.id ===
                            profile?._id
                              ? '/my-space'
                              : `/user-feed/${post.mentions[mentionIndex]?.data?.id}`
                          }
                          className={classes['post-content-text']}
                        >
                          <span> </span>
                          <span className={classes['mention-word']}>
                            {word}
                          </span>
                        </Link>
                      );
                    }

                    const isUrl = isValidUrl(word);

                    if (isUrl) {
                      if (!linkPreviewRendered) {
                        linkPreviewRendered = true;
                        return (
                          <a
                            key={word + i}
                            style={{ overflowWrap: 'break-word' }}
                            rel="noreferrer"
                            href={word}
                            className={classes['link']}
                            target="_blank"
                          >
                            <span> </span>
                            <span>{word}</span>
                          </a>
                        );

                        // renderLinkPreview(word, i);
                      } else {
                        return (
                          <a
                            key={word + i}
                            style={{ overflowWrap: 'break-word' }}
                            rel="noreferrer"
                            href={word}
                            className={classes['link']}
                            target="_blank"
                          >
                            <span> </span>
                            <span>{word}</span>
                          </a>
                        );
                      }
                    } else {
                      return (
                        <span
                          className={classes['post-content-text']}
                          key={word + i}
                        >
                          <span> </span>
                          <span
                            style={{
                              color: word.startsWith('#') && '#F900B7',
                            }}
                          >
                            {word}
                          </span>
                        </span>
                      );
                    }
                  })}
                </div>
              );
            })}

            {renderPostImages(post.postImages)}
          </div>
        );
      } else {
        let linkPreviewRendered = false;

        return (
          <div className={classes['post-image-container']}>
            <div className={classes['post-content']}>
              {post.postContent.split('\n').map((sentence, index) => {
                if (sentence === '\r')
                  return (
                    <p
                      key={sentence + index}
                      className={classes['empty-space']}
                    >
                      &nbsp;
                    </p>
                  );

                return (
                  <div key={sentence + index}>
                    <div></div>
                    {sentence.split(' ').map((word, i) => {
                      const isUrl = isValidUrl(word);
                      if (isUrl) {
                        if (!linkPreviewRendered) {
                          linkPreviewRendered = true;
                          return (
                            <a
                              key={word + i}
                              style={{ overflowWrap: 'break-word' }}
                              rel="noreferrer"
                              href={word}
                              className={classes['link']}
                              target="_blank"
                            >
                              <span> </span>
                              <span>{word}</span>
                            </a>
                          );
                          // renderLinkPreview(word, i);
                        } else {
                          return (
                            <a
                              key={word + i}
                              style={{ overflowWrap: 'break-word' }}
                              rel="noreferrer"
                              href={word}
                              className={classes['link']}
                              target="_blank"
                            >
                              <span> </span>
                              <span>{word}</span>
                            </a>
                          );
                        }
                      } else {
                        return (
                          <span
                            style={{ overflowWrap: 'break-word' }}
                            key={word + i}
                          >
                            <span> </span>
                            <span
                              style={{
                                color: word.startsWith('#') && '#F900B7',
                              }}
                            >
                              {word}
                            </span>
                          </span>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>

            {renderPostImages(post.postImages)}
          </div>
        );
      }
    }
  };

  const invalidateNumberOfLikes = () => {
    queryClient.invalidateQueries([
      'number-of-likes-for-specific-post',
      post._id,
    ]);
    queryClient.refetchQueries(['number-of-likes-for-specific-post', post._id]);

    queryClient.invalidateQueries([
      'first-users-who-have-liked-user-post',
      post._id,
    ]);
    queryClient.refetchQueries([
      'first-users-who-have-liked-user-post',
      post._id,
    ]);
  };

  const likeUserPostHandler = async () => {
    try {
      const requestBody = {
        isGroupPost: isGroupPost,
      };
      const { data: likedPost } = await likeUserPostRequest(
        post._id,
        requestBody
      );

      invalidateNumberOfLikes();

      if (setQueryDataKey === 'my-groups-recent-activity') {
        await Promise.all([
          queryClient.invalidateQueries(setQueryDataKey),
          queryClient.refetchQueries(setQueryDataKey),
        ]);
      } else {
        queryClient.setQueryData(setQueryDataKey, (data) => {
          const allLikes = [];
          data.pages.forEach((page) => {
            allLikes.push(...page.myLikeStatusForEachPost);
          });

          const updatedMyLikeStatusForEachPost = [...allLikes];
          let el = updatedMyLikeStatusForEachPost.find(
            ({ postId }) => postId === post._id
          );
          el.likeStatus = likedPost;

          return {
            ...data,
            myLikeStatusForEachPost: updatedMyLikeStatusForEachPost,
          };
        });
      }
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const removeUserPostLikeHandler = async () => {
    try {
      await removeUserPostLikeRequest(post._id);

      invalidateNumberOfLikes();

      if (setQueryDataKey === 'my-groups-recent-activity') {
        await Promise.all([
          queryClient.invalidateQueries(setQueryDataKey),
          queryClient.refetchQueries(setQueryDataKey),
        ]);
      } else {
        queryClient.setQueryData(setQueryDataKey, (data) => {
          const allLikes = [];
          data.pages.forEach((page) => {
            allLikes.push(...page.myLikeStatusForEachPost);
          });

          const updatedMyLikeStatusForEachPost = [...allLikes];
          let el = updatedMyLikeStatusForEachPost.find(
            ({ postId }) => postId === post._id
          );
          el.likeStatus = null;

          return {
            ...data,
            myLikeStatusForEachPost: updatedMyLikeStatusForEachPost,
          };
        });
      }
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const invalidateTipStatusHandler = () =>
    Promise.all([
      queryClient.invalidateQueries(['tip-status', post._id]),
      queryClient.refetchQueries(['tip-status', post._id]),
    ]);

  const createPostTipHandler = async (tipAmount) => {
    if (profile?.winningPoints < parseInt(tipAmount)) {
      setShowBuyPointsModal(true);
      return;
    }
    try {
      const requestBody = {
        isGroupPost: isGroupPost,
        tipAmount: parseInt(tipAmount),
      };
      await createPostTipRequest(post._id, requestBody);
      await invalidateTipStatusHandler();
      notify('success', 'You have successfully tipped the post!', 2000);
      setShowTipOptions(false);
      getMyProfile();
    } catch (err) {
      if (!err.startsWith('E11000')) {
        notify('error', err, 2000);
      }
    }
  };

  const renderCommentTextAccordingly = () => {
    if (showComments) {
      return (
        <div
          className={classes['comment-container']}
          onClick={() => setShowComments(false)}
        >
          <img src={CommentIcon} alt="Likes" width={16} height={16} />
          <p>{numberOfCommentsResponse?.data?.data}</p>
        </div>
      );
    } else {
      return (
        <div
          className={classes['comment-container']}
          onClick={() => setShowComments(true)}
        >
          <img src={CommentIcon} alt="Likes" width={16} height={16} />
          <p>{numberOfCommentsResponse?.data?.data}</p>
        </div>
      );
    }
  };

  const renderLikeTextAccordingly = () => {
    if (isPostLikedByMe) {
      return (
        <div
          className={classes['like-container']}
          onClick={removeUserPostLikeHandler}
        >
          <img
            alt="Liked"
            src={heart}
            width={18}
            height={17}
            className={classes['like-icon']}
          />
          <p>{data?.data}</p>
        </div>
      );
    } else
      return (
        <div
          className={classes['like-container']}
          onClick={likeUserPostHandler}
        >
          <img
            alt="Like"
            src={unfilledHeart}
            width={18}
            height={17}
            className={classes['like-icon']}
          />
          <p>{data?.data}</p>
        </div>
      );
  };

  const showTipOptionsHandler = () => {
    if (tipStatusResponse.isLoading || tipStatusResponse?.data?.data) return;

    setShowTipOptions(true);
  };

  const renderTipAccordingly = () => {
    return (
      <div className={classes['tip-container']} onClick={showTipOptionsHandler}>
        <img
          src={
            !tipStatusResponse.isLoading &&
            tipStatusResponse.data &&
            tipStatusResponse.data.data !== null
              ? PinkDiamondIcon
              : DiamondIcon
          }
          alt="Tip"
          className={classes['tip-icon']}
        />
        <p
          style={{
            color:
              !tipStatusResponse.isLoading &&
              tipStatusResponse?.data?.data &&
              '#EF00D7',
          }}
          className={classes['tip-text']}
        >
          Tip
        </p>
      </div>
    );
  };

  const openUsersWhoHaveLikedModalHandler = () => {
    setShowUsersWhoHaveLikedUserPostModal(true);
  };

  const toggleMoreOptionsHandler = () => {
    setShowMoreOptions((prevState) => !prevState);
  };

  const openEditPostModalHandler = () => {
    setShowEditPostModal(true);
  };

  const blockUserHandler = async () => {
    try {
      await blockUserRequest(post.user._id);
      queryClient.invalidateQueries(['group-posts', props.groupId]);
      queryClient.refetchQueries(['group-posts', props.groupId]);
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const renderLikedByHandler = () => {
    const likedBy = usersWhoHaveLikedUserPostResponse.data.data;

    if (data?.data && data.data > 0) {
      return (
        <>
          <div className={classes['horizontal-line']}></div>
          <div className={classes['liked-by-container']}>
            {likedBy.map(({ user }) => (
              <Link
                key={user._id}
                to={
                  user._id === profile?._id
                    ? '/my-space'
                    : `/user-feed/${user._id}`
                }
              >
                <div className={classes['liked-by-user']} key={user._id}>
                  <img
                    className={classes['liked-by-user-photo']}
                    src={user.photo}
                    alt={user.fullName}
                  />
                  <img
                    className={classes['liked-icon']}
                    src={LikedIcon}
                    alt="Liked"
                  />
                </div>
              </Link>
            ))}

            {data?.data && data.data > likedBy.length && (
              <div
                onClick={openUsersWhoHaveLikedModalHandler}
                className={classes['remaining-number-of-likes-container']}
              >
                <p>+{data.data - likedBy.length}</p>
              </div>
            )}
          </div>
        </>
      );
    } else return null;
  };

  const followUserHandler = async (userId) => {
    if (followingUser) return;

    setFollowingUser(true);
    try {
      await requestFollowingRequest(userId);
      await getMyFollowings();

      queryClient.invalidateQueries(['followers-followings', userId]);
      queryClient.refetchQueries(['followers-followings', userId]);
      queryClient.invalidateQueries(['number-of-followers-followings', userId]);
      queryClient.refetchQueries(['number-of-followers-followings', userId]);
    } catch (error) {
      notify('error', error, 2000);
    }

    setFollowingUser(false);
  };

  const renderFollowButton = () => {
    const postCreatorId = post.user._id;
    if (profile?._id === postCreatorId) return null;

    const doIFollowCurrentUser = !!myFollowingsIds[postCreatorId];

    if (!doIFollowCurrentUser)
      return (
        <p
          onClick={() => followUserHandler(postCreatorId)}
          className={classes['follow-text']}
          style={{
            cursor: followingUser && 'not-allowed',
            color: followingUser && '#A8ADAF',
          }}
        >
          Follow
        </p>
      );
  };

  const copyPostLinkHandler = async () => {
    const linkToCopy = `${window.location.host}/user-posts/${post._id}?isGroupPost=1`;
    await navigator.clipboard.writeText(linkToCopy);
    notify('success', 'Copied', 1000);
  };

  const isMyPost = post.user._id === profile?._id;

  return (
    <>
      {showUsersWhoHaveLikedUserPostModal && (
        <UsersWhoHaveLikedUserPostModal
          userPostId={post._id}
          setShowUsersWhoHaveLikedUserPostModal={
            setShowUsersWhoHaveLikedUserPostModal
          }
        />
      )}

      {showEditPostModal && (
        <EditPostModal
          setShowEditPostModal={setShowEditPostModal}
          post={post}
        />
      )}

      {showPostImageModalState.opened && (
        <ImageModal
          title="Post Image"
          setShowImageModal={(opened) =>
            setShowPostImageModalState({
              opened,
              image: null,
            })
          }
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={showPostImageModalState.image}
            alt="PostImage"
          />
        </ImageModal>
      )}

      {showMultipleImagesModal && (
        <ImageModal
          setShowImageModal={setShowMultipleImagesModal}
          title={'Post Images'}
        >
          {multipleImagesToShow.map((image, i) => (
            <img
              style={{ marginTop: 3, width: '100%', objectFit: 'cover' }}
              key={image + i}
              alt="PostImage"
              src={image}
            />
          ))}
        </ImageModal>
      )}

      {showBuyPointsModal && (
        <BuyPointsModal setShowBuyPointsModal={setShowBuyPointsModal} />
      )}

      <div className={classes['post-container']}>
        <div className={classes['header']}>
          <div className={classes['user']}>
            <Link
              to={
                post.user._id === profile?._id
                  ? '/my-space'
                  : `/user-feed/${post.user._id}`
              }
            >
              <img
                alt={post.user.fullName}
                src={post.user.photo}
                className={classes['user-photo']}
              />
            </Link>
            <div className={classes['user-info']}>
              <div className={classes['post-author-tags']}>
                <Link
                  to={
                    post.user._id === profile?._id
                      ? '/my-space'
                      : `/user-feed/${post.user._id}`
                  }
                >
                  <p className={classes['fullName']}>
                    {post.user.fullName}
                    {post.user.verified && (
                      <img
                        alt="Verified"
                        src={VerifiedIcon}
                        style={{ height: 16, marginLeft: 6, marginBottom: 2 }}
                      />
                    )}
                  </p>
                </Link>
                {post.feeling && (
                  <div className={classes['feeling-container']}>
                    {' '}
                    <span className={classes['circle']}>&nbsp;</span>{' '}
                    <p className={classes['feeling-text']}>
                      Feeling {post.feeling}
                    </p>
                  </div>
                )}
              </div>

              <p className={classes['created-at']}>
                {getDateFrom(post.createdAt)}
              </p>
            </div>
          </div>

          {renderFollowButton()}

          <div onClick={toggleMoreOptionsHandler} className={classes['more']}>
            {showMoreOptions && (
              <div className={classes['more-options']}>
                <p onClick={copyPostLinkHandler} className={classes['option']}>
                  Copy Link
                </p>
                {!isMyPost && (
                  <p
                    onClick={blockUserHandler}
                    className={classes['recycle-bin']}
                  >
                    Block User
                  </p>
                )}
                {isMyPost && (
                  <p
                    onClick={openEditPostModalHandler}
                    className={classes['option']}
                  >
                    Edit Post
                  </p>
                )}
              </div>
            )}
            <img alt="More" src={MoreIcon} height={20} />
          </div>
        </div>

        <div className={classes['post-content-container']}>
          {renderPostContent()}

          <div className={classes['post-videos-container']}>
            {post.postVideos &&
              post.postVideos.map((video, i) => (
                <div key={i} className={classes['post-video']}>
                  <ReactPlayer
                    width={'100%'}
                    height={300}
                    controls
                    url={video}
                  />
                </div>
              ))}
          </div>
        </div>

        {usersWhoHaveLikedUserPostResponse.data?.data && renderLikedByHandler()}

        {!showTipOptions ? (
          <div className={classes['likes-comments-save']}>
            <div className={classes['likes-comments']}>
              {renderLikeTextAccordingly()}
              {renderCommentTextAccordingly()}
              {!isMyPost && renderTipAccordingly()}
            </div>
          </div>
        ) : (
          <div className={classes['tip-options-container']}>
            <div
              onClick={() => setShowTipOptions(false)}
              className={classes['go-back-tip-container']}
            >
              <img
                src={GoBackIcon}
                alt="Go Back"
                className={classes['go-back-icon']}
              />
              <p className={classes['tip-text']}>Tip</p>
            </div>
            {TIP_OPTIONS.map((tipOption) => (
              <div
                onClick={() => createPostTipHandler(tipOption)}
                key={tipOption}
                className={`${classes['box-option']}`}
              >
                <p className={classes['points-value']}>
                  <img
                    src={PinkDiamondIcon}
                    alt="Diamond"
                    className={classes['diamond-icon']}
                  />
                  {parseFloat(tipOption)}{' '}
                </p>
              </div>
            ))}
          </div>
        )}
        <PostComments
          groupId={props.groupId}
          isGroupPost={isGroupPost}
          postId={post._id}
        />
      </div>
    </>
  );
}

export default UserPost;
