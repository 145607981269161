import React, { useState } from 'react';
import classes from './SentCollaboratorNftRequest.module.css';
import ReactPlayer from 'react-player';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { IPFS_URL } from '../../constants/IPFS';
import VerifiedIcon from '../../assets/verified.png';
import moment from 'moment';
import { limitString } from '../../helpers/helpers';
import Button from '../Button/Button';
import { deleteMyNftRequestAsCollaboratorRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';

function SentCollaboratorNftRequest(props) {
  const queryClient = useQueryClient();

  const { nft, nftCollection, to, updatedAt, collaboratorNftRequestId } = props;

  const [
    deletingMyNftRequestAsCollaborator,
    setDeletingMyNftRequestAsCollaborator,
  ] = useState(false);

  const invalidateMyRequestedNftsAsCollaboratorHandler = () => {
    return Promise.all([
      queryClient.invalidateQueries('my-requested-nfts-as-collaborator'),
      queryClient.refetchQueries('my-requested-nfts-as-collaborator'),
    ]);
  };

  const deleteMyNftRequestAsCollaboratorHandler = async () => {
    setDeletingMyNftRequestAsCollaborator(true);

    try {
      await deleteMyNftRequestAsCollaboratorRequest(collaboratorNftRequestId);

      await invalidateMyRequestedNftsAsCollaboratorHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setDeletingMyNftRequestAsCollaborator(false);
  };

  return (
    <div className={classes['sent-collaborator-nft-request-container']}>
      {nft.type === 'image' ? (
        <Link to={`/nfts/${nft._id}`}>
          <img
            className={classes['nft-image']}
            src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
            alt="IPFS Hash"
          />
        </Link>
      ) : (
        <Link to={`/nfts/${nft._id}`}>
          <ReactPlayer
            style={{
              objectFit: 'cover',
              borderRadius: 10,
            }}
            width={330}
            height={300}
            url={`${IPFS_URL}/${nft.ipfs}`}
          />
        </Link>
      )}

      <div className={classes['sent-collaborator-nft-request-info-container']}>
        <div className={classes['to-info-container']}>
          <p className={classes['to-text']}>To: </p>
          <img className={classes['to-photo']} src={to.photo} alt="To" />
          <p className={classes['to-fullName']}>{to.fullName}</p>
          {to.verified && <img width={14} src={VerifiedIcon} alt="Verified" />}
        </div>

        <p className={classes['sent-on']}>
          Sent on: {moment(updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
        </p>
        <div className={classes['collection-container']}>
          <p className={classes['collection-text']}>Collection:</p>
          <img
            src={nftCollection.coverPhoto}
            alt="Collection"
            className={classes['collection-cover-photo']}
          />
          <p className={classes['collection-title']}>
            {limitString(nftCollection.name, 16)}
          </p>
        </div>

        <Button
          onClick={deleteMyNftRequestAsCollaboratorHandler}
          style={{ width: '100%' }}
          modernpinkcolor="true"
          disabled={deletingMyNftRequestAsCollaborator}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default SentCollaboratorNftRequest;
