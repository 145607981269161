import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button/Button';
import {
  createGroupRequest,
  inviteUsersToGroupRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import CreateGroupForm from './components/CreateGroupForm/CreateGroupForm';
import InviteUsersAsGroupMembers from './components/InviteUsersAsGroupMembers/InviteUsersAsGroupMembers';
import classes from './CreateGroup.module.css';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import GoBackIcon from '../../assets/go-back-icon-2.png';

function CreateGroup() {
  const history = useHistory();

  const [coverPhotoPreviewUrl, setCoverPhotoPreviewUrl] = useState();
  const [coverPhotoUrl, setCoverPhotoUrl] = useState();
  const [groupName, setGroupName] = useState('');
  const [groupNameTouched, setGroupNameTouched] = useState(false);
  const [groupUsername, setGroupUsername] = useState('');
  const [groupUsernameTouched, setGroupUsernameTouched] = useState(false);
  const [about, setAbout] = useState('');
  const [aboutTouched, setAboutTouched] = useState(false);
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [isSelectedPrivacyPrivate, setIsSelectedPrivacyPrivate] = useState(
    true
  );
  const [selectedFriendsIds, setSelectedFriendsIds] = useState({});

  const cancelGroupCreationHandler = () => {
    history.goBack();
  };

  const createGroupHandler = async () => {
    setCreatingGroup(true);
    try {
      const formData = new FormData();
      formData.append('name', groupName);
      formData.append('username', groupUsername);
      formData.append('about', about);
      formData.append(
        'status',
        isSelectedPrivacyPrivate ? 'private' : 'public'
      );
      formData.append('visibility', 'visible');
      if (coverPhotoUrl) formData.append('coverPhoto', coverPhotoUrl);

      const { data } = await createGroupRequest(formData);

      if (Object.keys(selectedFriendsIds).length > 0) {
        await inviteUsersToGroupRequest(data._id, {
          userIds: Object.keys(selectedFriendsIds),
        });
      }

      history.goBack();
    } catch (err) {
      notify('error', err, 2000);
    }
    setCreatingGroup(false);
  };

  let isCreateGroupButtonDisabled = false;
  if (
    about.length === 0 ||
    groupName.length === 0 ||
    groupUsername.length === 0 ||
    !coverPhotoUrl ||
    creatingGroup
  ) {
    isCreateGroupButtonDisabled = true;
  }

  return (
    <>
      <MetaTags>
        <title>Create Group | Glimpse</title>
        <meta property="og:title" content={'Create Group | Glimpse'} />
      </MetaTags>

      <div className={classes['create-group-page']}>
        <div>
          <UserSidebar />
        </div>

        <div>
          <div className={classes['create-group-form-invite-users-container']}>
            <div className={classes['create-group-text-container']}>
              <img
                onClick={() => history.goBack()}
                src={GoBackIcon}
                alt="Go Back"
                className={classes['go-back-icon']}
              />
              <h3>Create Group</h3>
            </div>
            <div className={classes['create-group-page-container']}>
              <CreateGroupForm
                coverPhotoUrl={coverPhotoUrl}
                coverPhotoPreviewUrl={coverPhotoPreviewUrl}
                setCoverPhotoUrl={setCoverPhotoUrl}
                setCoverPhotoPreviewUrl={setCoverPhotoPreviewUrl}
                groupName={groupName}
                setGroupName={setGroupName}
                groupNameTouched={groupNameTouched}
                setGroupNameTouched={setGroupNameTouched}
                groupUsername={groupUsername}
                setGroupUsername={setGroupUsername}
                groupUsernameTouched={groupUsernameTouched}
                setGroupUsernameTouched={setGroupUsernameTouched}
                about={about}
                setAbout={setAbout}
                aboutTouched={aboutTouched}
                setAboutTouched={setAboutTouched}
                creatingGroup={creatingGroup}
                setCreatingGroup={setCreatingGroup}
                isSelectedPrivacyPrivate={isSelectedPrivacyPrivate}
                setIsSelectedPrivacyPrivate={setIsSelectedPrivacyPrivate}
              />

              <div className={classes['invite-users-container']}>
                <h3 className={classes['invite-group-members-text']}>
                  Invite Group Members
                </h3>

                <InviteUsersAsGroupMembers
                  selectedFriendsIds={selectedFriendsIds}
                  setSelectedFriendsIds={setSelectedFriendsIds}
                />
              </div>
            </div>
          </div>
          <div className={classes['actions-container']}>
            <Button
              style={{ width: 200, marginRight: 10 }}
              onClick={cancelGroupCreationHandler}
              darkpinkcolor="true"
            >
              Cancel
            </Button>
            <Button
              disabled={isCreateGroupButtonDisabled}
              onClick={createGroupHandler}
              style={{ width: 200 }}
              darkpink="true"
            >
              Create Group
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateGroup;
