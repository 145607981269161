import React from 'react';
import classes from './SliderButton.module.css';

function SliderButton(props) {
  const {
    leftValue,
    rightValue,
    isLeftContentSelected,
    setIsLeftContentSelected,
    disabled,
  } = props;

  return (
    <div className={classes['slider-container']}>
      {leftValue}

      <div>
        <label className={classes['switch']}>
          <input
            disabled={disabled}
            onChange={() => {
              if (props.onSliderChangeCb) {
                props.onSliderChangeCb(!isLeftContentSelected);
              } else {
                setIsLeftContentSelected((prevState) => !prevState);
              }
            }}
            type="checkbox"
            value={isLeftContentSelected}
            checked={!isLeftContentSelected}
          />
          <span className={classes['slider']}></span>
        </label>
      </div>
      {rightValue}
    </div>
  );
}

export default SliderButton;
