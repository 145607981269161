import React, { useState, useEffect, useContext } from 'react';
import Modal from '../../../../../../../../components/Modal/Modal';
import notify from '../../../../../../../../utils/notify';
import classes from './CreateDedicatedPostModal.module.css';
import {
  createUserDedicatedPostRequest,
  uploadDedicatedPostVideoRequest,
} from '../../../../../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import Button from '../../../../../../../../components/Button/Button';
import TextArea from '../../../../../../../../components/TextArea/TextArea';
import ImageVideoUpload from '../../../../../../../../components/ImageVideoUpload/ImageVideoUpload';
import ImagePostIcon from '../../../../../../../../assets/image-post-1.png';
import ReactPlayer from 'react-player';
import RemoveIcon from '../../../../../../../../assets/white-remove-icon.png';
import { Picker } from 'emoji-mart';
import EmojiIcon from '../../../../../../../../assets/emoji.png';
import { AuthContext } from '../../../../../../../../context/auth-context';

function CreateDedicatedPostModal(props) {
  const { profile } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { setShowCreateDedicatedPostModal, userProfile } = props;

  const [postContent, setPostContent] = useState('');
  const [imageUrl, setImageUrl] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [videoPreviewUrl, setVideoPreviewUrl] = useState();
  const [showEmojiEditor, setShowEmojiEditor] = useState(false);
  const [selectedFeeling, setSelectedFeeling] = useState();
  const [creatingDedicatedPost, setCreatingDedicatedPost] = useState(false);
  const [additionalImagesUrl, setAdditionalImagesUrl] = useState([]);
  const [additionalImagesPreviewUrl, setAdditionalImagesPreviewUrl] = useState(
    []
  );
  const [additionalVideosUrl, setAdditionalVideosUrl] = useState([]);
  const [additionalVideosPreviewUrl, setAdditionalVideosPreviewUrl] = useState(
    []
  );

  useEffect(() => {
    const checkForClosingEmojiPicker = (e) => {
      if (e.target.id.startsWith('emoji')) {
        setShowEmojiEditor(true);
      } else if (e.target.closest('#emoji-container') === null) {
        setShowEmojiEditor(false);
      }
    };

    window.addEventListener('click', checkForClosingEmojiPicker);
  }, []);

  const closeModalHandler = () => {
    setShowCreateDedicatedPostModal(false);
  };

  const postContentChangeHandler = (e) => {
    setPostContent(e.target.value);
  };

  const createDedicatedPostHandler = async () => {
    setCreatingDedicatedPost(true);

    try {
      const formData = new FormData();
      formData.append('postContent', postContent);

      if (imageUrl) formData.append('postImage', imageUrl);
      if (selectedFeeling) formData.append('feeling', selectedFeeling);

      if (additionalImagesUrl.length > 0) {
        for (let i = 0; i < additionalImagesUrl.length; i++) {
          formData.append('additionalImages', additionalImagesUrl[i]);
        }
      }

      const { data: newPost } = await createUserDedicatedPostRequest(
        userProfile._id,
        formData
      );

      if (videoUrl) {
        const videoFormData = new FormData();
        videoFormData.append('postVideo', videoUrl);

        if (additionalVideosUrl.length > 0) {
          for (let i = 0; i < additionalVideosUrl.length; i++) {
            videoFormData.append('additionalVideos', additionalVideosUrl[i]);
          }
        }

        await uploadDedicatedPostVideoRequest(newPost._id, videoFormData);
      }

      await Promise.all([
        queryClient.invalidateQueries(['posts', userProfile._id]),
        queryClient.refetchQueries(['posts', userProfile._id]),
      ]);
    } catch (err) {
      notify('error', err, 2000);
    }
    setCreatingDedicatedPost(false);

    closeModalHandler();
  };

  const inputFileHandler = (value) => {
    if (!value) return;

    if (value.size > 10000000)
      return notify(
        'error',
        'You can not upload more than 10MB Content!',
        2000
      );

    const fileReader = new FileReader();

    if (value.type === 'video/mp4') {
      if (!videoUrl) {
        setVideoUrl(value);
      } else {
        setAdditionalVideosUrl((prevState) => [...prevState, value]);
      }
    } else {
      if (!imageUrl) {
        setImageUrl(value);
      } else {
        setAdditionalImagesUrl((prevState) => [...prevState, value]);
      }
    }

    try {
      fileReader.onload = () => {
        if (value.type === 'video/mp4') {
          if (!videoPreviewUrl) {
            setVideoPreviewUrl(fileReader.result);
          } else {
            setAdditionalVideosPreviewUrl((prevState) => [
              ...prevState,
              fileReader.result,
            ]);
          }
        } else {
          if (!previewUrl) {
            setPreviewUrl(fileReader.result);
          } else {
            setAdditionalImagesPreviewUrl((prevState) => [
              ...prevState,
              fileReader.result,
            ]);
          }
        }
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };
  const removeImageHandler = () => {
    setPreviewUrl(undefined);
    setImageUrl(undefined);
  };
  const removeVideoHandler = () => {
    setVideoPreviewUrl(undefined);
    setVideoUrl(undefined);
  };

  const removeAdditionalImageUrl = (index) => {
    setAdditionalImagesUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
    setAdditionalImagesPreviewUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  const removeAdditionalVideoUrl = (index) => {
    setAdditionalVideosPreviewUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
    setAdditionalVideosUrl((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  return (
    <>
      <Modal
        style={{ height: 500 }}
        footer={
          <div className={classes['footer']}>
            <Button
              style={{ width: 200 }}
              darkpinkcolor="true"
              onClick={closeModalHandler}
            >
              Cancel
            </Button>

            <Button
              style={{ width: 200 }}
              disabled={
                creatingDedicatedPost ||
                (!postContent && !imageUrl && !videoUrl)
              }
              onClick={createDedicatedPostHandler}
              darkpink="true"
            >
              Post
            </Button>
          </div>
        }
        closeModal={closeModalHandler}
        title={`Post something to ${userProfile.fullName}`}
      >
        <div className={classes['user-post-status']}>
          <img
            alt={profile?.fullName}
            src={profile?.photo}
            width={45}
            height={45}
            className={classes['user-image']}
          />

          <div className={classes['fullName-post-status-container']}>
            <div className={classes['fullName-feeling-container']}>
              <h4 className={classes['user-fullName']}>{profile?.fullName}</h4>
              {selectedFeeling && (
                <div className={classes['feeling-container']}>
                  {' '}
                  <span className={classes['circle']}>&nbsp;</span>{' '}
                  <p className={classes['feeling-text']}>
                    Feeling {selectedFeeling}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes['input-container']}>
          <TextArea
            onChange={postContentChangeHandler}
            placeholder={`Write something to ${userProfile.firstName}...`}
            style={{ minHeight: 100, width: '100%' }}
          />

          <img
            alt="Emoji"
            src={EmojiIcon}
            className={classes['emoji-icon']}
            id="emoji-icon"
          />

          <ImageVideoUpload onInput={inputFileHandler}>
            <img
              alt="Post"
              src={ImagePostIcon}
              className={classes['image-post-icon']}
            />
          </ImageVideoUpload>

          {showEmojiEditor && (
            <div id="emoji-container" className={classes['emoji-container']}>
              <Picker
                emojiSize={20}
                title="Pick your emoji"
                emoji="point_up"
                onSelect={({ native }) => {
                  setShowEmojiEditor(false);
                  setSelectedFeeling(native);
                }}
              />
            </div>
          )}
        </div>

        {(previewUrl ||
          videoPreviewUrl ||
          additionalImagesPreviewUrl.length > 0 ||
          additionalVideosPreviewUrl.length > 0) && (
          <div className={classes['upload-image-container']}>
            {videoPreviewUrl && (
              <div className={classes['video-container']}>
                <ReactPlayer
                  width={300}
                  height={155}
                  url={videoPreviewUrl}
                  controls
                  style={{
                    borderRadius: '13px',
                    objectFit: 'cover',
                    marginTop: 27,
                  }}
                />

                <div
                  onClick={removeVideoHandler}
                  className={classes['remove-video-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            )}
            {previewUrl && (
              <div className={classes['image-container']}>
                <img
                  alt="Preview"
                  src={previewUrl}
                  className={classes['uploaded-image']}
                />
                <div
                  onClick={removeImageHandler}
                  className={classes['remove-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            )}

            {additionalImagesPreviewUrl.map((previewUrl, i) => (
              <div className={classes['image-container']}>
                <img
                  alt="Preview"
                  src={previewUrl}
                  className={classes['uploaded-image']}
                />
                <div
                  onClick={() => removeAdditionalImageUrl(i)}
                  className={classes['remove-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            ))}

            {additionalVideosPreviewUrl.map((previewUrl, i) => (
              <div className={classes['video-container']}>
                <ReactPlayer
                  width={300}
                  height={155}
                  url={previewUrl}
                  controls
                  style={{
                    borderRadius: '13px',
                    objectFit: 'cover',
                    marginTop: 27,
                  }}
                />

                <div
                  onClick={() => removeAdditionalVideoUrl(i)}
                  className={classes['remove-video-icon-container']}
                >
                  <img
                    alt="Remove"
                    src={RemoveIcon}
                    className={classes['remove-icon']}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
}

export default CreateDedicatedPostModal;
