import React from 'react';
import Modal from '../../../../../../components/Modal/Modal';

function ActionTakenResultModal(props) {
  const { setActionTakenResultState, actionTakenDescription } = props;

  const closeModalHandler = () =>
    setActionTakenResultState({ opened: false, actionTakenDescription: '' });

  return (
    <>
      <Modal
        footer={
          <>
            <button onClick={closeModalHandler} className={'cancel-button'}>
              Close
            </button>
          </>
        }
        closeModal={closeModalHandler}
        title="Action Result"
      >
        <h3 className="bold-text">{actionTakenDescription} </h3>
      </Modal>
    </>
  );
}

export default ActionTakenResultModal;
