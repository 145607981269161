import React from 'react';
import { limitString } from '../../../../../../../../helpers/helpers';
import classes from './UserImageAlbum.module.css';

function UserImageAlbum(props) {
  const {
    album,
    setSelectedAlbumId,
    setShowAlbumPhotos,
    setPhotosOptions,
    setSelectedPhotoOption,
  } = props;

  const selectAlbumHandler = () => {
    setPhotosOptions(['Albums', 'Album Photos']);
    setSelectedPhotoOption('Album Photos');
    setSelectedAlbumId(album._id);
    setShowAlbumPhotos(true);
  };

  const renderAlbumFirstImage = () => {
    if (album.images.length === 0) {
      return (
        <div
          className={`${classes['empty-image']} ${classes['user-image']}`}
        ></div>
      );
    } else {
      return (
        <img
          alt="ImageAlbum"
          src={album.images[album.images.length - 1]}
          className={classes['user-image']}
        />
      );
    }
  };

  return (
    <div onClick={selectAlbumHandler} className={classes['album-container']}>
      {renderAlbumFirstImage()}

      <div className={classes['album-name-container']}>
        <p title={album.name} className={classes['album-name']}>
          {limitString(album.name, 14)} ({album.images.length})
        </p>
      </div>
    </div>
  );
}

export default UserImageAlbum;
