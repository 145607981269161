import { getUsersTransactionsForSoldNftsStatisticsRequest } from '../../../../../../httpRequests/httpRequests';
import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import notify from '../../../../../../utils/notify';
import classes from './SoldNftsStatistics.module.css';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { useIntersectionObserver } from '../../../../../../hooks/useIntersectionObserver';

const RESULTS_PER_PAGE = 10;

function SoldNftsStatistics() {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['sold-nfts-transactions-statistics', searchValue],
    ({ pageParam = 1 }) =>
      getUsersTransactionsForSoldNftsStatisticsRequest(
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 3000);
  });

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['sold-nfts-transactions-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['sold-nfts-transactions-heading']}>
            Transactions for sold NFTs
          </h2>
          <div>
            <input
              placeholder="Search For Users"
              style={{ width: 300 }}
              className="searchInput"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['table-container']}
        >
          <Row justify="space-between">
            <Col md={1} className={classes['pagination-col']}>
              Photo
            </Col>
            <Col md={7} className={classes['pagination-col']}>
              Full Name
            </Col>

            <Col md={8} className={classes['pagination-col']}>
              Total NFTs Sold
            </Col>
          </Row>

          {data && data.pages[0].results === 0 && (
            <h2 className="bold-text">No results found!</h2>
          )}

          {data &&
            data.pages.map((page) => {
              return page.data.map(({ _id: userId, owner, totalNftsSold }) => {
                return (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(`/user-stats/${userId}`)}
                    key={userId}
                  >
                    <hr />
                    <Row justify="space-between" style={{ padding: '1em 0' }}>
                      <Col md={1}>
                        <img
                          width={25}
                          height={25}
                          style={{ borderRadius: 100, objectFit: 'cover' }}
                          alt="User"
                          src={owner.photo}
                        />
                      </Col>
                      <Col md={7}>
                        {owner.firstName} {owner.lastName}
                      </Col>

                      <Col md={8}>{totalNftsSold}</Col>
                    </Row>
                  </div>
                );
              });
            })}

          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default SoldNftsStatistics;
