import React, { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import Button from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Poll from '../../../../components/Poll/Poll';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import { getAllPollsForASpecificGroupRequest } from '../../../../httpRequests/httpRequests';
import CreateGroupPollModal from '../CreateGroupPollModal/CreateGroupPollModal';
import classes from './Polling.module.css';
import notify from '../../../../utils/notify';

const RESULTS_PER_PAGE = 10;

function Polling(props) {
  const { groupId, isMyRoleAdmin } = props;
  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['group-polls', groupId],
    ({ pageParam = 1 }) =>
      getAllPollsForASpecificGroupRequest(groupId, pageParam, RESULTS_PER_PAGE),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const [showCreateGroupPollModal, setShowCreateGroupPollModal] = useState(
    false
  );

  const openCreateGroupPollModalHandler = () => {
    setShowCreateGroupPollModal(true);
  };

  return (
    <>
      {showCreateGroupPollModal && (
        <CreateGroupPollModal
          groupId={groupId}
          setShowCreateGroupPollModal={setShowCreateGroupPollModal}
        />
      )}

      <div className={classes['polls-create-list-container']}>
        {isMyRoleAdmin && (
          <div className={classes['create-poll-container']}>
            <div
              onClick={openCreateGroupPollModalHandler}
              className={classes['qa-container']}
            >
              <p className={classes['qa-text']}>
                Let’s do Q&A with your friends
              </p>
            </div>
            <Button
              onClick={openCreateGroupPollModalHandler}
              style={{
                width: 'auto',
                padding: '0.75rem 1rem',
              }}
              darkpink="true"
            >
              Create Poll
            </Button>
          </div>
        )}

        <div className={classes['polls-container']}>
          {!isLoading && data?.pages && data.pages[0].results === 0 && (
            <div
              style={{
                background: 'white',
                padding: '.8rem',
                borderRadius: 10,
                marginTop: 10,
                width: '95%',
                gridColumn: '1/-1',
              }}
            >
              <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
                No Polls Found!
              </p>
            </div>
          )}
          {data?.pages &&
            data.pages.map((page) => {
              return page.data.map(
                ({
                  _id,
                  createdBy,
                  createdAt,
                  question,
                  answers,
                  endDate,
                  answersByCommunity,
                }) => (
                  <Poll
                    groupId={groupId}
                    answersByCommunity={answersByCommunity}
                    key={_id}
                    pollId={_id}
                    createdBy={createdBy}
                    createdAt={createdAt}
                    question={question}
                    answers={answers}
                    endDate={endDate}
                  />
                )
              );
            })}

          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
}

export default Polling;
