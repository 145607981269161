import React from 'react';
import SliderButton from '../../../../../../components/SliderButton/SliderButton';
import classes from './SecondScreen.module.css';
import TelegramIcon from '../../../../../../assets/telegram.png';
import TwitterIcon from '../../../../../../assets/twitter.png';
import YoutubeIcon from '../../../../../../assets/youtube.png';
import InstagramIcon from '../../../../../../assets/instagram.png';
import LinktreeIcon from '../../../../../../assets/linktree.png';
import ImdbIcon from '../../../../../../assets/imdb.png';
import LinkedinIcon from '../../../../../../assets/linkedin-1.png';
import Input from '../../../../../../components/Input/Input';
import Button from '../../../../../../components/Button/Button';

function SecondScreen(props) {
  const {
    setScreenNumber,
    telegramUrl,
    setTelegramUrl,
    includeTelegramUrl,
    setIncudeTelegramUrl,
    isTelegramUrlValid,
    setIsTelegramUrlValid,

    twitterUrl,
    setTwitterUrl,
    isTwitterUrlValid,
    setIsTwitterUrlValid,
    includeTwitterUrl,
    setIncudeTwitterUrl,

    linkedinUrl,
    setLinkedinUrl,
    isLinkedinUrlValid,
    setIsLinkedinUrlValid,
    includeLinkedinUrl,
    setIncudeLinkedinUrl,

    instagramUrl,
    setInstagramUrl,
    includeInstagramUrl,
    setIncludeInstagramUrl,
    isInstagramUrlValid,
    setIsInstagramUrlValid,

    youtubeUrl,
    setYoutubeUrl,
    includeYoutubeUrl,
    setIncludeYoutubeUrl,
    isYoutubeUrlValid,
    setIsYoutubeUrlValid,

    linktreeUrl,
    setLinktreeUrl,
    includeLinktreeUrl,
    setIncludeLinktreeUrl,
    isLinktreeUrlValid,
    setIsLinktreeUrlValid,

    imdbUrl,
    setImdbUrl,
    includeImdbUrl,
    setIncludeImdbUrl,
    isImdbUrlValid,
    setIsImdbUrlValid,
  } = props;

  const goBackHandler = () => {
    setScreenNumber((prevState) => prevState - 1);
  };

  const goToNextStepHandler = () => {
    setScreenNumber((prevState) => prevState + 1);
  };

  const telegramUrlChangeHandler = (e) => {
    setTelegramUrl(e.target.value);
    setIsTelegramUrlValid(e.target.value.includes('t.me/'));
  };

  const twitterUrlChangeHandler = (e) => {
    setTwitterUrl(e.target.value);
    setIsTwitterUrlValid(e.target.value.includes('twitter.com/'));
  };

  const linkedinUrlChangeHandler = (e) => {
    setLinkedinUrl(e.target.value);
    setIsLinkedinUrlValid(e.target.value.includes('linkedin.com/'));
  };

  const instagramUrlChangeHandler = (e) => {
    setInstagramUrl(e.target.value);
    // eslint-disable-next-line
    const instagramRegex = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;
    setIsInstagramUrlValid(instagramRegex.test(e.target.value));
  };

  const youtubeUrlChangeHandler = (e) => {
    setYoutubeUrl(e.target.value);
    // eslint-disable-next-line
    const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/;
    setIsYoutubeUrlValid(youtubeRegex.test(e.target.value));
  };

  const linktreeUrlChangeHandler = (e) => {
    setLinktreeUrl(e.target.value);
    setIsLinktreeUrlValid(e.target.value.includes('linktr.ee/'));
  };

  const imdbUrlChangeHandler = (e) => {
    setImdbUrl(e.target.value);
    setIsImdbUrlValid(e.target.value.includes('imdb.com/'));
  };

  const isNextButtonDisabled = () => {
    if (includeTelegramUrl && !telegramUrl.includes('t.me/')) return true;
    if (includeTwitterUrl && !twitterUrl.includes('twitter.com/')) return true;
    if (includeLinkedinUrl && !linkedinUrl.includes('linkedin.com/'))
      return true;
    if (includeInstagramUrl && !instagramUrl.includes('instagram.com/'))
      return true;
    if (includeYoutubeUrl && !isYoutubeUrlValid) return true;
    if (includeLinktreeUrl && !isLinktreeUrlValid) return true;
    if (includeImdbUrl && !isImdbUrlValid) return true;

    return false;
  };

  const telgramUrlInputStyle = { width: '100%' };
  if (!isTelegramUrlValid && includeTelegramUrl) {
    telgramUrlInputStyle['border'] = '1px solid red';
  }

  const twitterUrlInputStyle = { width: '100%' };
  if (!isTwitterUrlValid && includeTwitterUrl) {
    twitterUrlInputStyle['border'] = '1px solid red';
  }

  const linkedinUrlInputStyle = { width: '100%' };
  if (!isLinkedinUrlValid && includeLinkedinUrl) {
    linkedinUrlInputStyle['border'] = '1px solid red';
  }

  const instagramUrlInputStyle = { width: '100%' };
  if (!isInstagramUrlValid && includeInstagramUrl) {
    instagramUrlInputStyle['border'] = '1px solid red';
  }

  const youtubeUrlInputStyle = { width: '100%' };
  if (!isYoutubeUrlValid && includeYoutubeUrl) {
    youtubeUrlInputStyle['border'] = '1px solid red';
  }

  const linktreeInputStyle = { width: '100%' };
  if (!isLinktreeUrlValid && includeLinktreeUrl) {
    linktreeInputStyle['border'] = '1px solid red';
  }

  const imdbInputStyle = { width: '100%' };
  if (!isImdbUrlValid && includeImdbUrl) {
    imdbInputStyle['border'] = '1px solid red';
  }

  return (
    <div
      style={{
        minHeight: 360,
      }}
    >
      <p className={classes['social-details-text']}>Social Details</p>
      <div className={classes['social-container']}>
        <div className={classes['social-icon-name-container']}>
          <img
            alt="Telegram"
            src={TelegramIcon}
            className={classes['social-icon']}
          />
          <p className={classes['social-text']}>Telegram</p>
        </div>
        <SliderButton
          leftValue={''}
          rightValue={''}
          isLeftContentSelected={!includeTelegramUrl}
          setIsLeftContentSelected={setIncudeTelegramUrl}
        />
      </div>
      <Input
        placeholder="Enter link here"
        style={telgramUrlInputStyle}
        value={telegramUrl}
        onChange={telegramUrlChangeHandler}
      />

      <div style={{ marginTop: 10 }} className={classes['social-container']}>
        <div className={classes['social-icon-name-container']}>
          <img
            alt="Twitter"
            src={TwitterIcon}
            className={classes['social-icon']}
          />
          <p className={classes['social-text']}>Twitter</p>
        </div>
        <SliderButton
          leftValue={''}
          rightValue={''}
          isLeftContentSelected={!includeTwitterUrl}
          setIsLeftContentSelected={setIncudeTwitterUrl}
        />
      </div>
      <Input
        placeholder="Enter link here"
        style={twitterUrlInputStyle}
        value={twitterUrl}
        onChange={twitterUrlChangeHandler}
      />

      <div style={{ marginTop: 10 }} className={classes['social-container']}>
        <div className={classes['social-icon-name-container']}>
          <img
            alt="Linkedin"
            src={LinkedinIcon}
            className={classes['social-icon']}
          />
          <p className={classes['social-text']}>Linkedin</p>
        </div>
        <SliderButton
          leftValue={''}
          rightValue={''}
          isLeftContentSelected={!includeLinkedinUrl}
          setIsLeftContentSelected={setIncudeLinkedinUrl}
        />
      </div>
      <Input
        placeholder="Enter link here"
        style={linkedinUrlInputStyle}
        value={linkedinUrl}
        onChange={linkedinUrlChangeHandler}
      />

      <div style={{ marginTop: 10 }} className={classes['social-container']}>
        <div className={classes['social-icon-name-container']}>
          <img
            alt="Youtube"
            src={YoutubeIcon}
            className={classes['social-icon']}
          />
          <p className={classes['social-text']}>Youtube</p>
        </div>
        <SliderButton
          leftValue={''}
          rightValue={''}
          isLeftContentSelected={!includeYoutubeUrl}
          setIsLeftContentSelected={setIncludeYoutubeUrl}
        />
      </div>
      <Input
        placeholder="Enter link here"
        style={youtubeUrlInputStyle}
        value={youtubeUrl}
        onChange={youtubeUrlChangeHandler}
      />

      <div style={{ marginTop: 10 }} className={classes['social-container']}>
        <div className={classes['social-icon-name-container']}>
          <img
            alt="Linktree"
            src={LinktreeIcon}
            className={classes['social-icon']}
          />
          <p className={classes['social-text']}>Linktree</p>
        </div>
        <SliderButton
          leftValue={''}
          rightValue={''}
          isLeftContentSelected={!includeLinktreeUrl}
          setIsLeftContentSelected={setIncludeLinktreeUrl}
        />
      </div>
      <Input
        placeholder="Enter link here"
        style={linktreeInputStyle}
        value={linktreeUrl}
        onChange={linktreeUrlChangeHandler}
      />

      <div style={{ marginTop: 10 }} className={classes['social-container']}>
        <div className={classes['social-icon-name-container']}>
          <img alt="IMDB" src={ImdbIcon} className={classes['social-icon']} />
          <p className={classes['social-text']}>IMDB</p>
        </div>
        <SliderButton
          leftValue={''}
          rightValue={''}
          isLeftContentSelected={!includeImdbUrl}
          setIsLeftContentSelected={setIncludeImdbUrl}
        />
      </div>
      <Input
        placeholder="Enter link here"
        style={imdbInputStyle}
        value={imdbUrl}
        onChange={imdbUrlChangeHandler}
      />

      <div style={{ marginTop: 10 }} className={classes['social-container']}>
        <div className={classes['social-icon-name-container']}>
          <img
            alt="Instagram"
            src={InstagramIcon}
            className={classes['social-icon']}
          />
          <p className={classes['social-text']}>Instagram</p>
        </div>
        <SliderButton
          leftValue={''}
          rightValue={''}
          isLeftContentSelected={!includeInstagramUrl}
          setIsLeftContentSelected={setIncludeInstagramUrl}
        />
      </div>
      <Input
        placeholder="Enter link here"
        style={instagramUrlInputStyle}
        value={instagramUrl}
        onChange={instagramUrlChangeHandler}
      />

      <div style={{ marginTop: 20 }}>
        {props.children}

        <div className={classes['footer']}>
          <Button
            style={{ flex: 1 }}
            darkpinkcolor="true"
            onClick={goBackHandler}
          >
            Go Back
          </Button>
          <Button
            disabled={isNextButtonDisabled()}
            onClick={goToNextStepHandler}
            style={{ flex: 1 }}
            darkpink="true"
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SecondScreen;
