import React, { useContext, useEffect, useState } from 'react';
import {
  getDateFrom,
  isValidUrl,
} from '../../../../../../../../helpers/helpers';
import classes from './Post.module.css';
import heart from '../../../../../../../../assets/filled-heart-icon.png';
import unfilledHeart from '../../../../../../../../assets/unfilled-heart-icon.png';
import notify from '../../../../../../../../utils/notify';
import {
  getNumberOfLikesForSpecificUserPostRequest,
  likeUserPostRequest,
  removeUserPostLikeRequest,
  getNumberOfCommentsForSpecificPostRequest,
  // getSomeFollowingsWhoHaveLikedSpecificUserPostRequest,
  addMyPostToRecycleBinRequest,
  blockUserRequest,
  getLinkPreviewUrlRequest,
  deleteUserDedicatedPostRequest,
  getUsersWhoHaveLikedASpecificUserPostRequest,
  requestFollowingRequest,
  createPostTipRequest,
  getTipStatusForSpecificPostRequest,
} from '../../../../../../../../httpRequests/httpRequests';
import { useQuery, useQueryClient } from 'react-query';
import PostComments from './components/PostComments/PostComments';
import ReactPlayer from 'react-player';
import CommentIcon from '../../../../../../../../assets/comment.png';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../../../../../context/auth-context';
import TaggedUsersModal from '../../../../../../../../components/TaggedUsersModal/TaggedUsersModal';
import VerifiedIcon from '../../../../../../../../assets/verified.png';
import UsersWhoHaveLikedUserPostModal from '../../../../../../../../components/UsersWhoHaveLikedUserPostModal/UsersWhoHaveLikedUserPostModal';
import MoreIcon from '../../../../../../../../assets/more.png';
import ImageModal from '../../../../../../../../components/ImageModal/ImageModal';
import rightArrowIcon from '../../../../../../../../assets/arrow-right.png';
import EditPostModal from '../EditPostModal/EditPostModal';
import EditDedicatedPostModal from '../EditDedicatedPostModal/EditDedicatedPostModal';
// import ExclusiveFollowersIcon from '../../../../../../../../assets/exclusive-followers.png';
// import ExclusiveCollectorsIcon from '../../../../../../../../assets/exclusive-collectors.png';
// import ExclusiveCommunityIcon from '../../../../../../../../assets/exclusive-public.png';
// import ExclusiveIcon from '../../../../../../../../assets/exclusive.png';
import LikedIcon from '../../../../../../../../assets/liked-heart-icon.png';
import GoBackIcon from '../../../../../../../../assets/go-back-icon.png';
import BuyPointsModal from '../../../../../../../../components/BuyPointsModal/BuyPointsModal';
import DiamondIcon from '../../../../../../../../assets/diamond.png';
import PinkDiamondIcon from '../../../../../../../../assets/pink-diamond-icon.png';
import CollectorsOnlyIcon from '../../../../../../../../assets/collectors-only-icon.png';
import CommunityOnlyIcon from '../../../../../../../../assets/community-only-icon.png';
import FollowersOnlyIcon from '../../../../../../../../assets/followers-only-icon.png';

const TIP_OPTIONS = [1, 5, 10, 20, 50, 100];

function Post(props) {
  const {
    profile,
    myFollowingsIds,
    getMyFollowings,
    getMyProfile,
  } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { post, isPostLikedByMe } = props;
  const { data, error } = useQuery(
    ['number-of-likes-for-specific-post', post._id],
    () => getNumberOfLikesForSpecificUserPostRequest(post._id)
  );
  const numberOfCommentsResponse = useQuery(
    ['number-of-comments-for-specific-post', post._id],
    () => getNumberOfCommentsForSpecificPostRequest(post._id)
  );
  // const someFollowingsWhoHaveLikedUserPostResponse = useQuery(
  //   ['some-followings-who-have-liked-user-post', post._id],
  //   () =>
  //     getSomeFollowingsWhoHaveLikedSpecificUserPostRequest(
  //       post._id,
  //       profile?._id
  //     )
  // );

  const usersWhoHaveLikedUserPostResponse = useQuery(
    ['first-users-who-have-liked-user-post', post._id],
    () =>
      getUsersWhoHaveLikedASpecificUserPostRequest(
        post._id,
        1,
        12,
        '',
        profile?._id
      )
  );

  const tipStatusResponse = useQuery(['tip-status', post._id], () =>
    getTipStatusForSpecificPostRequest(post._id)
  );

  const [showComments, setShowComments] = useState(false);
  const [showTaggedUsersModal, setShowTaggedUsersModal] = useState(false);
  const [
    showUsersWhoHaveLikedUserPostModal,
    setShowUsersWhoHaveLikedUserPostModal,
  ] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [linkPreviews, setLinkPreviews] = useState({});
  const [selectedImageToShow, setSelectedImageToShow] = useState();
  const [showMultipleImagesModal, setShowMultipleImagesModal] = useState(false);
  const [multipleImagesToShow, setMultipleImagesToShow] = useState([]);
  const [showEditPostModal, setShowEditPostModal] = useState(false);
  const [showEditDedicatedPostModal, setShowEditDedicatedPostModal] = useState(
    false
  );
  const [followingUser, setFollowingUser] = useState(false);

  const [showTipOptions, setShowTipOptions] = useState(false);
  const [showBuyPointsModal, setShowBuyPointsModal] = useState(false);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  // const someFollowingsWhoHaveLikedUserPost = useMemo(() => {
  //   if (!someFollowingsWhoHaveLikedUserPostResponse.data) return undefined;

  //   return someFollowingsWhoHaveLikedUserPostResponse.data.data;
  // }, [someFollowingsWhoHaveLikedUserPostResponse]);

  const openOtherTaggedUsersModalHandler = () => {
    setShowTaggedUsersModal(true);
  };

  const renderTags = () => {
    const numberOfTags = post.tags.length;

    if (numberOfTags > 0) {
      if (numberOfTags === 1) {
        return (
          <div className={classes['tags-container']}>
            <p className={classes['is-with']}>is with</p>
            <Link
              to={
                post.tags[0]?._id === profile?._id
                  ? '/my-space'
                  : `/user-feed/${post.tags[0]?._id}`
              }
              className={classes['tagged-user']}
            >
              {post.tags[0].fullName}
            </Link>
          </div>
        );
      } else if (numberOfTags === 2) {
        return (
          <div className={classes['tags-container']}>
            <p className={classes['is-with']}>is with</p>
            <Link
              to={
                post.tags[0]?._id === profile?._id
                  ? '/my-space'
                  : `/user-feed/${post.tags[0]?._id}`
              }
              className={classes['tagged-user']}
            >
              {post.tags[0].fullName}
            </Link>
            <span className={classes['and']}>and</span>
            <Link
              to={
                post.tags[1]?._id === profile?._id
                  ? '/my-space'
                  : `/user-feed/${post.tags[1]?._id}`
              }
              className={classes['tagged-user']}
            >
              {post.tags[1].fullName}
            </Link>
          </div>
        );
      } else {
        return (
          <div className={classes['tags-container']}>
            <p className={classes['is-with']}>is with</p>
            <Link
              to={
                post.tags[0]?._id === profile?._id
                  ? '/my-space'
                  : `/user-feed/${post.tags[0]?._id}`
              }
              className={classes['tagged-user']}
            >
              {post.tags[0].fullName},
            </Link>
            <Link
              to={
                post.tags[1]?._id === profile?._id
                  ? '/my-space'
                  : `/user-feed/${post.tags[1]?._id}`
              }
              className={classes['tagged-user']}
            >
              {post.tags[1].fullName}
            </Link>
            <p
              onClick={openOtherTaggedUsersModalHandler}
              className={classes['tagged-user']}
              style={{ cursor: 'pointer' }}
            >
              and{' '}
              {numberOfTags - 2 === 1
                ? '1 other'
                : `${numberOfTags - 2} others`}
            </p>
          </div>
        );
      }
    }
  };

  const renderLinkPreview = (word, i) => {
    if (!linkPreviews[word])
      getLinkPreviewUrlRequest(word).then(({ data }) => {
        setLinkPreviews((prevState) => ({
          ...prevState,
          [word]: data,
        }));
      });

    return (
      <div key={word + i} style={{ margin: `20px 0` }}>
        <a
          key={word + i}
          style={{ overflowWrap: 'break-word' }}
          rel="noreferrer"
          href={word}
          className={classes['link']}
          target="_blank"
        >
          <span> </span>
          <span>{word}</span>
        </a>

        {linkPreviews[word] && !post.additionalVideos?.length && (
          <div className={classes['preview-link-container']}>
            {linkPreviews[word].image && (
              <img
                alt="Preview"
                className={classes['preview-link-image']}
                src={linkPreviews[word].image}
              />
            )}
            <div className={classes['preview-info']}>
              <h3>{linkPreviews[word].title}</h3>
              <p>{linkPreviews[word].description}</p>
            </div>
            <div className={classes['visit-link-container']}>
              <a
                className={classes['visit-link']}
                href={linkPreviews[word].site}
                target="_blank"
                rel="noreferrer"
              >
                Visit
              </a>
            </div>
          </div>
        )}
      </div>
    );
  };

  const openMultipleImagesModalHandler = (images) => {
    setShowMultipleImagesModal(true);
    setMultipleImagesToShow(images);
  };

  const renderPostImages = (postImages) => {
    const { length } = postImages;

    if (length === 1) {
      return (
        <div className={classes['post-image-container']}>
          <img
            onClick={() => {
              setSelectedImageToShow(postImages[0]);
              setShowImageModal(true);
            }}
            src={postImages[0]}
            alt="PostImage"
            className={classes['post-image']}
          />
        </div>
      );
    } else if (length === 2) {
      return (
        <div className={classes['two-images-container']}>
          {postImages.map((image, i) => (
            <img
              key={image + i}
              onClick={() => {
                setSelectedImageToShow(image);
                setShowImageModal(true);
              }}
              src={image}
              alt="PostImage"
              className={classes['post-image']}
            />
          ))}
        </div>
      );
    } else if (length === 3) {
      return (
        <div className={classes['three-images-container']}>
          <img
            onClick={() => {
              setSelectedImageToShow(postImages[0]);
              setShowImageModal(true);
            }}
            src={postImages[0]}
            alt="PostImage"
            className={`${classes['three-images-0']}`}
          />

          <img
            onClick={() => {
              setSelectedImageToShow(postImages[1]);
              setShowImageModal(true);
            }}
            src={postImages[1]}
            alt="PostImage"
            className={`${classes['three-images-1']}`}
          />

          <img
            onClick={() => {
              setSelectedImageToShow(postImages[2]);
              setShowImageModal(true);
            }}
            src={postImages[2]}
            alt="PostImage"
            className={`${classes['three-images-2']}`}
          />
        </div>
      );
    } else if (length === 4) {
      return (
        <div className={classes['four-images-container']}>
          {postImages.map((image, i) => (
            <img
              key={image + i}
              onClick={() => {
                setSelectedImageToShow(image);
                setShowImageModal(true);
              }}
              src={image}
              alt="PostImage"
              className={`${classes[`four-images-${i}`]}`}
            />
          ))}
        </div>
      );
    } else if (length >= 5) {
      return (
        <div className={classes['four-images-container']}>
          {postImages.slice(0, 3).map((image, i) => (
            <img
              key={image + i}
              onClick={() => {
                setSelectedImageToShow(image);
                setShowImageModal(true);
              }}
              src={image}
              alt="PostImage"
              className={`${classes[`four-images-${i}`]}`}
            />
          ))}

          <div
            onClick={() => openMultipleImagesModalHandler(postImages.slice(3))}
            style={{ position: 'relative' }}
          >
            <img
              src={postImages[3]}
              alt="PostImage"
              className={`${classes[`four-images-${3}`]}`}
            />
            <div className={classes['more-images-container']}>
              <p className={classes['more-images-number']}>
                {postImages.length - 4} +
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  const selectTopicHandler = (topic) => {
    if (!topic.startsWith('#')) return;

    if (props.selectedTopic === topic) {
      props.setSelectedTopic?.(undefined);
    } else {
      props.setSelectedTopic?.(topic);
    }
  };

  const renderPostContent = () => {
    let postImages = post.postImage ? [post.postImage] : [];
    if (post.additionalImages && Array.isArray(post.additionalImages)) {
      postImages = [...postImages, ...post.additionalImages];
    }

    if (
      post.postContent &&
      !postImages.length &&
      (!post.mentions || post.mentions?.length === 0)
    ) {
      let linkPreviewRendered = false;
      return (
        <div className={classes['post-content']}>
          {post.postContent.split('\n').map((sentence, index) => {
            if (sentence === '\r')
              return (
                <p key={sentence + index} className={classes['empty-space']}>
                  &nbsp;
                </p>
              );

            return (
              <div key={sentence + index}>
                <div></div>
                {sentence.split(' ').map((word, i) => {
                  const isUrl = isValidUrl(word);
                  if (isUrl) {
                    if (!linkPreviewRendered) {
                      linkPreviewRendered = true;
                      return renderLinkPreview(word, i);
                    } else {
                      return (
                        <a
                          key={word + i}
                          style={{ overflowWrap: 'break-word' }}
                          rel="noreferrer"
                          href={word}
                          className={classes['link']}
                          target="_blank"
                        >
                          <span> </span>
                          <span>{word}</span>
                        </a>
                      );
                    }
                  } else {
                    return (
                      <span
                        style={{ overflowWrap: 'break-word' }}
                        key={word + i}
                      >
                        <span> </span>
                        <span
                          className={
                            props.selectedTopic === word
                              ? classes['selected-topic']
                              : undefined
                          }
                          onClick={() => selectTopicHandler(word)}
                          style={{
                            cursor: word.startsWith('#') && 'pointer',
                            color: word.startsWith('#') && '#F900B7',
                          }}
                        >
                          {word}
                        </span>
                      </span>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      );
    } else if (
      post.postContent &&
      !postImages.length &&
      post.mentions?.length > 0
    ) {
      let linkPreviewRendered = false;
      return (
        <div className={classes['post-content']}>
          {post.postContent.split('\n').map((sentence, i) => {
            if (sentence === '\r')
              return (
                <p key={sentence + i} className={classes['empty-space']}>
                  &nbsp;
                </p>
              );

            return (
              <div key={sentence + i}>
                <div></div>
                {sentence.split(' ').map((word, i) => {
                  const mentionIndex = post.mentions.findIndex(
                    ({ mentionUsername }) => {
                      return mentionUsername === word;
                    }
                  );
                  if (word.startsWith('@') && mentionIndex !== -1) {
                    return (
                      <Link
                        key={word + i}
                        to={
                          post.mentions[mentionIndex]?.data?.id === profile?._id
                            ? '/my-space'
                            : `/user-feed/${post.mentions[mentionIndex]?.data?.id}`
                        }
                      >
                        <span> </span>
                        <span className={classes['mention-word']}>{word}</span>
                      </Link>
                    );
                  }

                  const isUrl = isValidUrl(word);
                  if (isUrl) {
                    if (!linkPreviewRendered) {
                      linkPreviewRendered = true;
                      return renderLinkPreview(word, i);
                    } else {
                      return (
                        <a
                          key={word + i}
                          style={{ overflowWrap: 'break-word' }}
                          rel="noreferrer"
                          href={word}
                          className={classes['link']}
                          target="_blank"
                        >
                          <span> </span>
                          <span>{word}</span>
                        </a>
                      );
                    }
                  } else {
                    return (
                      <span key={word + i}>
                        <span> </span>
                        <span
                          onClick={() => selectTopicHandler(word)}
                          className={
                            props.selectedTopic === word
                              ? classes['selected-topic']
                              : undefined
                          }
                          style={{
                            cursor: word.startsWith('#') && 'pointer',
                            color: word.startsWith('#') && '#F900B7',
                          }}
                        >
                          {word}
                        </span>
                      </span>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      );
    }

    if (!post.postContent && postImages.length > 0) {
      return renderPostImages(postImages);
    }

    if (post.postContent && postImages.length > 0) {
      if (post.mentions?.length > 0) {
        let linkPreviewRendered = false;
        return (
          <div className={classes['post-image-container']}>
            {post.postContent.split('\n').map((sentence, i) => {
              if (sentence === '\r')
                return (
                  <p key={sentence + i} className={classes['empty-space']}>
                    &nbsp;
                  </p>
                );

              return (
                <div key={sentence + i}>
                  <div></div>
                  {sentence.split(' ').map((word, i) => {
                    const mentionIndex = post.mentions.findIndex(
                      ({ mentionUsername }) => {
                        return mentionUsername === word;
                      }
                    );
                    if (word.startsWith('@') && mentionIndex !== -1) {
                      return (
                        <Link
                          key={word + i}
                          to={
                            post.mentions[mentionIndex]?.data?.id ===
                            profile?._id
                              ? '/my-space'
                              : `/user-feed/${post.mentions[mentionIndex]?.data?.id}`
                          }
                          className={classes['post-content-text']}
                        >
                          <span> </span>
                          <span className={classes['mention-word']}>
                            {word}
                          </span>
                        </Link>
                      );
                    }

                    const isUrl = isValidUrl(word);

                    if (isUrl) {
                      if (!linkPreviewRendered) {
                        linkPreviewRendered = true;
                        return (
                          <a
                            key={word + i}
                            style={{ overflowWrap: 'break-word' }}
                            rel="noreferrer"
                            href={word}
                            className={classes['link']}
                            target="_blank"
                          >
                            <span> </span>
                            <span>{word}</span>
                          </a>
                        );
                        // renderLinkPreview(word, i);
                      } else {
                        return (
                          <a
                            key={word + i}
                            style={{ overflowWrap: 'break-word' }}
                            rel="noreferrer"
                            href={word}
                            className={classes['link']}
                            target="_blank"
                          >
                            <span> </span>
                            <span>{word}</span>
                          </a>
                        );
                      }
                    } else {
                      return (
                        <span
                          className={classes['post-content-text']}
                          key={word + i}
                        >
                          <span> </span>
                          <span
                            className={
                              props.selectedTopic === word
                                ? classes['selected-topic']
                                : undefined
                            }
                            onClick={() => selectTopicHandler(word)}
                            style={{
                              cursor: word.startsWith('#') && 'pointer',
                              color: word.startsWith('#') && '#F900B7',
                            }}
                          >
                            {word}
                          </span>
                        </span>
                      );
                    }
                  })}
                </div>
              );
            })}

            {renderPostImages(postImages)}
          </div>
        );
      } else {
        let linkPreviewRendered = false;
        return (
          <div className={classes['post-image-container']}>
            <div className={classes['post-content']}>
              {post.postContent.split('\n').map((sentence, index) => {
                if (sentence === '\r')
                  return (
                    <p
                      key={sentence + index}
                      className={classes['empty-space']}
                    >
                      &nbsp;
                    </p>
                  );

                return (
                  <div key={sentence + index}>
                    <div></div>
                    {sentence.split(' ').map((word, i) => {
                      const isUrl = isValidUrl(word);
                      if (isUrl) {
                        if (!linkPreviewRendered) {
                          linkPreviewRendered = true;
                          return (
                            <a
                              key={word + i}
                              style={{ overflowWrap: 'break-word' }}
                              rel="noreferrer"
                              href={word}
                              className={classes['link']}
                              target="_blank"
                            >
                              <span> </span>
                              <span>{word}</span>
                            </a>
                          );
                          // renderLinkPreview(word, i);
                        } else {
                          return (
                            <a
                              key={word + i}
                              style={{ overflowWrap: 'break-word' }}
                              rel="noreferrer"
                              href={word}
                              className={classes['link']}
                              target="_blank"
                            >
                              <span> </span>
                              <span>{word}</span>
                            </a>
                          );
                        }
                      } else {
                        return (
                          <span
                            style={{ overflowWrap: 'break-word' }}
                            key={word + i}
                          >
                            <span> </span>
                            <span
                              className={
                                props.selectedTopic === word
                                  ? classes['selected-topic']
                                  : undefined
                              }
                              onClick={() => selectTopicHandler(word)}
                              style={{
                                cursor: word.startsWith('#') && 'pointer',
                                color: word.startsWith('#') && '#F900B7',
                              }}
                            >
                              {word}
                            </span>
                          </span>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>

            {renderPostImages(postImages)}
          </div>
        );
      }
    }
  };

  const invalidateNumberOfLikes = () => {
    queryClient.invalidateQueries([
      'number-of-likes-for-specific-post',
      post._id,
    ]);
    queryClient.refetchQueries(['number-of-likes-for-specific-post', post._id]);

    queryClient.invalidateQueries([
      'first-users-who-have-liked-user-post',
      post._id,
    ]);
    queryClient.refetchQueries([
      'first-users-who-have-liked-user-post',
      post._id,
    ]);
  };

  const likeUserPostHandler = async () => {
    try {
      const requestBody = {
        isDedicatedPost: !!post.to,
      };
      const { data: likedPost } = await likeUserPostRequest(
        post._id,
        requestBody
      );

      invalidateNumberOfLikes();

      if (props.refetchPost) {
        props.refetchPost();
      } else
        queryClient.setQueryData(
          props.queryKey ? props.queryKey : 'posts',
          (data) => {
            const allLikes = [];
            data.pages.forEach((page) => {
              allLikes.push(...page.myLikeStatusForEachPost);
            });

            const updatedMyLikeStatusForEachPost = [...allLikes];
            let el = updatedMyLikeStatusForEachPost.find(
              ({ postId }) => postId === post._id
            );
            el.likeStatus = likedPost;

            return {
              ...data,
              myLikeStatusForEachPost: updatedMyLikeStatusForEachPost,
            };
          }
        );
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const removeUserPostLikeHandler = async () => {
    try {
      await removeUserPostLikeRequest(post._id);

      invalidateNumberOfLikes();
      if (props.refetchPost) {
        props.refetchPost();
      } else
        queryClient.setQueryData(
          props.queryKey ? props.queryKey : 'posts',
          (data) => {
            const allLikes = [];
            data.pages.forEach((page) => {
              allLikes.push(...page.myLikeStatusForEachPost);
            });

            const updatedMyLikeStatusForEachPost = [...allLikes];
            let el = updatedMyLikeStatusForEachPost.find(
              ({ postId }) => postId === post._id
            );
            el.likeStatus = null;

            return {
              ...data,
              myLikeStatusForEachPost: updatedMyLikeStatusForEachPost,
            };
          }
        );
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const invalidateTipStatusHandler = () =>
    Promise.all([
      queryClient.invalidateQueries(['tip-status', post._id]),
      queryClient.refetchQueries(['tip-status', post._id]),
    ]);

  const createPostTipHandler = async (tipAmount) => {
    if (profile?.winningPoints < parseInt(tipAmount)) {
      setShowBuyPointsModal(true);
      return;
    }
    try {
      const isDedicatedPost = !!post.to;

      const requestBody = {
        isDedicatedPost,
        tipAmount: parseInt(tipAmount),
      };
      await createPostTipRequest(post._id, requestBody);
      await invalidateTipStatusHandler();
      notify('success', 'You have successfully tipped the post!', 2000);
      setShowTipOptions(false);
      getMyProfile();
    } catch (err) {
      if (!err.startsWith('E11000')) {
        notify('error', err, 2000);
      }
    }
  };

  const renderCommentTextAccordingly = () => {
    if (showComments) {
      return (
        <div
          className={classes['comment-container']}
          onClick={() => setShowComments(false)}
        >
          <img src={CommentIcon} alt="Likes" width={16} height={16} />
          <p>{numberOfCommentsResponse?.data?.data}</p>
        </div>
      );
    } else {
      return (
        <div
          className={classes['comment-container']}
          onClick={() => setShowComments(true)}
        >
          <img src={CommentIcon} alt="Likes" width={16} height={16} />
          <p>{numberOfCommentsResponse?.data?.data}</p>
        </div>
      );
    }
  };

  const renderLikeTextAccordingly = () => {
    if (isPostLikedByMe) {
      return (
        <div
          className={classes['like-container']}
          onClick={removeUserPostLikeHandler}
        >
          <img
            alt="Liked"
            src={heart}
            width={18}
            height={17}
            className={classes['like-icon']}
          />
          <p>{data?.data}</p>
        </div>
      );
    } else
      return (
        <div
          className={classes['like-container']}
          onClick={likeUserPostHandler}
        >
          <img
            alt="Like"
            src={unfilledHeart}
            width={18}
            height={17}
            className={classes['like-icon']}
          />
          <p>{data?.data}</p>
        </div>
      );
  };

  const showTipOptionsHandler = () => {
    if (tipStatusResponse.isLoading || tipStatusResponse?.data?.data) return;

    setShowTipOptions(true);
  };

  const renderTipAccordingly = () => {
    return (
      <div className={classes['tip-container']} onClick={showTipOptionsHandler}>
        <img
          src={
            !tipStatusResponse.isLoading &&
            tipStatusResponse.data &&
            tipStatusResponse.data.data !== null
              ? PinkDiamondIcon
              : DiamondIcon
          }
          alt="Tip"
          className={classes['tip-icon']}
        />
        <p
          style={{
            color:
              !tipStatusResponse.isLoading &&
              tipStatusResponse?.data?.data &&
              '#EF00D7',
          }}
          className={classes['tip-text']}
        >
          Tip
        </p>
      </div>
    );
  };

  const openUsersWhoHaveLikedModalHandler = () => {
    setShowUsersWhoHaveLikedUserPostModal(true);
  };

  // const renderLikedByText = () => {
  //   if (
  //     !someFollowingsWhoHaveLikedUserPost ||
  //     someFollowingsWhoHaveLikedUserPost.length === 0
  //   ) {
  //     if (data?.data === 0) return null;

  //     if (data?.data === 1 && isPostLikedByMe) {
  //       return (
  //         <p
  //           style={{ cursor: 'default' }}
  //           className={classes['see-who-have-liked-text']}
  //         >
  //           Liked by me
  //         </p>
  //       );
  //     }

  //     if (data?.data === 1 && !isPostLikedByMe) {
  //       return (
  //         <p
  //           className={classes['see-who-have-liked-text']}
  //           onClick={openUsersWhoHaveLikedModalHandler}
  //         >
  //           Liked by 1 other
  //         </p>
  //       );
  //     } else if (data?.data === 2 && isPostLikedByMe) {
  //       return (
  //         <p
  //           className={classes['see-who-have-liked-text']}
  //           onClick={openUsersWhoHaveLikedModalHandler}
  //         >
  //           Liked by me and{' '}
  //           {data?.data - 1 === 1 ? '1 other' : `${data?.data - 1} others`}
  //         </p>
  //       );
  //     } else {
  //       return (
  //         <p
  //           className={classes['see-who-have-liked-text']}
  //           onClick={openUsersWhoHaveLikedModalHandler}
  //         >
  //           Liked by {data?.data - 1} others
  //         </p>
  //       );
  //     }
  //   }

  //   return (
  //     <p
  //       className={classes['see-who-have-liked-text']}
  //       onClick={openUsersWhoHaveLikedModalHandler}
  //     >
  //       Liked by{' '}
  //       <img
  //         alt="Following"
  //         className={classes['user-image']}
  //         src={someFollowingsWhoHaveLikedUserPost[0].user.photo}
  //       />{' '}
  //       {someFollowingsWhoHaveLikedUserPost[0].user.fullName}
  //       {data?.data - 1 === 0
  //         ? null
  //         : ` and ${
  //             data?.data - 1 === 1 ? '1 other' : `${data?.data - 1} others`
  //           }`}
  //     </p>
  //   );
  // };

  const renderLikedByHandler = () => {
    const likedBy = usersWhoHaveLikedUserPostResponse.data.data;

    if (data?.data && data.data > 0) {
      return (
        <>
          <div className={classes['horizontal-line']}></div>
          <div className={classes['liked-by-container']}>
            {likedBy.map(({ user }) => (
              <Link
                key={user._id}
                to={
                  user._id === profile?._id
                    ? '/my-space'
                    : `/user-feed/${user._id}`
                }
              >
                <div className={classes['liked-by-user']} key={user._id}>
                  <img
                    className={classes['liked-by-user-photo']}
                    src={user.photo}
                    alt={user.fullName}
                  />
                  <img
                    className={classes['liked-icon']}
                    src={LikedIcon}
                    alt="Liked"
                  />
                </div>
              </Link>
            ))}

            {data?.data && data.data > likedBy.length && (
              <div
                onClick={openUsersWhoHaveLikedModalHandler}
                className={classes['remaining-number-of-likes-container']}
              >
                <p>+{data.data - likedBy.length}</p>
              </div>
            )}
          </div>
        </>
      );
    } else return null;
  };

  const renderPostType = () => {
    if (
      post.postStatus === 'My Followers' ||
      post.postStatus === 'Specific Followers'
    )
      return (
        <div className={classes['followers-post-type-container']}>
          <img
            src={FollowersOnlyIcon}
            alt="Exclusive Followers"
            className={classes['exclusive-icon']}
          />
          <p className={classes['exclusive-followers-text']}>
            {post.postStatus} only
          </p>
        </div>
      );

    if (post.postStatus === 'Collectible Holders')
      return (
        <div className={classes['collectors-post-type-container']}>
          <img
            src={CollectorsOnlyIcon}
            alt="Exclusive Collectors"
            className={classes['exclusive-icon']}
          />
          <p className={classes['exclusive-collectors-text']}>
            Collectors only
          </p>
        </div>
      );

    // if (post.postStatus === 'Public')
    //   return (
    //     <div className={classes['public-post-type-container']}>
    //       <img
    //         src={ExclusiveCommunityIcon}
    //         alt="Exclusive Public"
    //         className={classes['exclusive-icon']}
    //       />
    //       <p className={classes['exclusive-public-text']}>Exclusive: Public</p>
    //     </div>
    //   );

    if (post.postStatus === 'My Communities')
      return (
        <div className={classes['public-post-type-container']}>
          <img
            src={CommunityOnlyIcon}
            alt="Exclusive Communities"
            className={classes['exclusive-icon']}
          />
          <p className={classes['exclusive-public-text']}>Community only</p>
        </div>
      );

    // if (post.postStatus === 'Only Me')
    //   return (
    //     <div className={classes['public-post-type-container']}>
    //       <img
    //         src={ExclusiveIcon}
    //         alt="Exclusive Communities"
    //         className={classes['exclusive-icon']}
    //       />
    //       <p className={classes['exclusive-public-text']}>For you only</p>
    //     </div>
    //   );
  };

  const toggleMoreOptionsHandler = () => {
    setShowMoreOptions((prevState) => !prevState);
  };

  const invalidateUserBlockStatus = () => {
    queryClient.invalidateQueries(['user-block-status', post.user._id]);
    queryClient.refetchQueries(['user-block-status', post.user._id]);
  };

  const invalidatePostsFeed = () => {
    queryClient.invalidateQueries(props.queryKey ? props.queryKey : 'posts');
    queryClient.refetchQueries(props.queryKey ? props.queryKey : 'posts');
  };

  const addToRecycleBinHandler = async () => {
    try {
      await addMyPostToRecycleBinRequest(post._id);
      invalidatePostsFeed();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const blockUserHandler = async () => {
    try {
      await blockUserRequest(post.user._id);
      invalidatePostsFeed();
      queryClient.invalidateQueries(['most-recent-nfts-from-my-followers']);
      queryClient.refetchQueries(['most-recent-nfts-from-my-followers']);

      if (queryClient.getQueryData(['user-block-status', post.user._id])) {
        invalidateUserBlockStatus();
      }
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const deleteDedicatedPostHandler = async () => {
    try {
      await deleteUserDedicatedPostRequest(post._id);

      if (post.to?._id === profile?._id) {
        queryClient.invalidateQueries('my-posts');
        queryClient.refetchQueries('my-posts');
      }

      if (post.to?._id) {
        queryClient.invalidateQueries(['posts', post.to._id]);
        queryClient.refetchQueries(['posts', post.to._id]);
      }
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const followUserHandler = async (userId) => {
    if (followingUser) return;

    setFollowingUser(true);
    try {
      await requestFollowingRequest(userId);
      await getMyFollowings();

      queryClient.invalidateQueries(['followers-followings', userId]);
      queryClient.refetchQueries(['followers-followings', userId]);
      queryClient.invalidateQueries(['number-of-followers-followings', userId]);
      queryClient.refetchQueries(['number-of-followers-followings', userId]);
    } catch (error) {
      notify('error', error, 2000);
    }

    setFollowingUser(false);
  };

  const renderFollowButton = () => {
    const postCreatorId = post.user._id;
    if (
      profile?._id === postCreatorId ||
      post?.tags?.length > 0 ||
      post?.postStatus !== 'Public'
    )
      return null;

    const doIFollowCurrentUser = !!myFollowingsIds[postCreatorId];

    if (!doIFollowCurrentUser)
      return (
        <p
          onClick={() => followUserHandler(postCreatorId)}
          className={classes['follow-text']}
          style={{
            cursor: followingUser && 'not-allowed',
            color: followingUser && '#A8ADAF',
          }}
        >
          Follow
        </p>
      );
  };

  const copyPostLinkHandler = async () => {
    const linkToCopy = !!post.to
      ? `${window.location.host}/user-posts/${post._id}?isDedicatedPost=1`
      : `${window.location.host}/user-posts/${post._id}`;

    await navigator.clipboard.writeText(linkToCopy);
    notify('success', 'Copied', 1000);
  };

  const isMyPost = post.user._id === profile?._id;
  const isDedicatedPost = !!post.to;

  const openEditPostModalHandler = () => {
    if (isDedicatedPost) setShowEditDedicatedPostModal(true);
    else setShowEditPostModal(true);
  };

  return (
    <>
      {showTaggedUsersModal && (
        <TaggedUsersModal
          setShowTaggedUsersModal={setShowTaggedUsersModal}
          taggedUsers={post.tags}
        />
      )}

      {showUsersWhoHaveLikedUserPostModal && (
        <UsersWhoHaveLikedUserPostModal
          userPostId={post._id}
          setShowUsersWhoHaveLikedUserPostModal={
            setShowUsersWhoHaveLikedUserPostModal
          }
        />
      )}

      {showImageModal && (
        <ImageModal setShowImageModal={setShowImageModal} title={'Post Image'}>
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={selectedImageToShow}
            alt="PostImg"
          />
        </ImageModal>
      )}

      {showMultipleImagesModal && (
        <ImageModal
          setShowImageModal={setShowMultipleImagesModal}
          title={'Post Images'}
        >
          {multipleImagesToShow.map((image, i) => (
            <img
              style={{ marginTop: 3, width: '100%', objectFit: 'cover' }}
              key={image + i}
              alt="PostImage"
              src={image}
            />
          ))}
        </ImageModal>
      )}

      {showEditPostModal && (
        <EditPostModal
          setShowEditPostModal={setShowEditPostModal}
          post={post}
        />
      )}

      {showEditDedicatedPostModal && (
        <EditDedicatedPostModal
          setShowEditDedicatedPostModal={setShowEditDedicatedPostModal}
          post={post}
        />
      )}

      {showBuyPointsModal && (
        <BuyPointsModal setShowBuyPointsModal={setShowBuyPointsModal} />
      )}

      <div className={classes['post-container']}>
        <div className={classes['header']}>
          <div className={classes['user']}>
            <Link
              to={
                post.user._id === profile?._id
                  ? '/my-space'
                  : `/user-feed/${post.user._id}`
              }
            >
              <img
                alt={post.user.fullName}
                src={post.user.photo}
                className={classes['user-photo']}
              />
            </Link>
            <div className={classes['user-info']}>
              <div className={classes['post-author-tags']}>
                <Link
                  to={
                    post.user._id === profile?._id
                      ? '/my-space'
                      : `/user-feed/${post.user._id}`
                  }
                >
                  <div className={classes['fullName']}>
                    {post.user.fullName}
                    {post.user.verified && (
                      <img
                        alt="Verified"
                        src={VerifiedIcon}
                        style={{ height: 16, marginLeft: 6, marginBottom: 2 }}
                      />
                    )}
                    {renderPostType()}
                  </div>
                </Link>
                {post.tags?.length > 0 && renderTags()}{' '}
                {isDedicatedPost && (
                  <>
                    <img
                      alt="Right Arrow"
                      className={classes['right-arrow']}
                      src={rightArrowIcon}
                      width={18}
                      height={13}
                    />
                    <Link
                      to={
                        post.to._id === profile?._id
                          ? '/my-space'
                          : `/user-feed/${post.user._id}`
                      }
                    >
                      <p className={classes['fullName']}>
                        {post.to.fullName}
                        {post.to.verified && (
                          <img
                            alt="Verified"
                            src={VerifiedIcon}
                            style={{
                              height: 17,
                              marginLeft: 6,
                              marginBottom: 2,
                            }}
                          />
                        )}
                      </p>
                    </Link>
                  </>
                )}
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <p className={classes['created-at']}>
                  {getDateFrom(post.createdAt)}
                </p>
                {post.feeling && (
                  <div className={classes['feeling-container']}>
                    {' '}
                    <span className={classes['circle']}>&nbsp;</span>{' '}
                    <p className={classes['feeling-text']}>
                      Feeling {post.feeling}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {renderFollowButton()}

          <div onClick={toggleMoreOptionsHandler} className={classes['more']}>
            {showMoreOptions && (
              <div className={classes['more-options']}>
                <p className={classes['option']} onClick={copyPostLinkHandler}>
                  Copy Link
                </p>

                {isMyPost && (
                  <p
                    className={classes['option']}
                    onClick={openEditPostModalHandler}
                  >
                    Edit Post
                  </p>
                )}

                {isMyPost && !post.to && (
                  <p
                    onClick={addToRecycleBinHandler}
                    className={classes['recycle-bin']}
                  >
                    Add To Recycle Bin
                  </p>
                )}

                {((isMyPost && post.to) ||
                  (isDedicatedPost && post.to?._id === profile?._id)) && (
                  <p
                    onClick={deleteDedicatedPostHandler}
                    className={classes['recycle-bin']}
                  >
                    Delete Post
                  </p>
                )}

                {!isMyPost && (
                  <p
                    onClick={blockUserHandler}
                    className={classes['recycle-bin']}
                  >
                    Block User
                  </p>
                )}
              </div>
            )}
            <img alt="More" src={MoreIcon} height={20} />
          </div>
        </div>

        <div className={classes['post-content-container']}>
          {renderPostContent()}

          {post.postVideo && (
            <div className={classes['post-video']}>
              <ReactPlayer
                width={'100%'}
                height={300}
                controls
                url={post.postVideo}
              />
            </div>
          )}
          {post.additionalVideos?.length > 0 &&
            post.additionalVideos.map((videoUrl, i) => (
              <div key={videoUrl + i} className={classes['post-video']}>
                <ReactPlayer
                  width={'100%'}
                  height={300}
                  controls
                  url={videoUrl}
                />
              </div>
            ))}
        </div>

        {usersWhoHaveLikedUserPostResponse.data?.data && renderLikedByHandler()}
        {!showTipOptions ? (
          <div className={classes['likes-comments-save']}>
            <div className={classes['likes-comments']}>
              {renderLikeTextAccordingly()}
              {renderCommentTextAccordingly()}
              {!isMyPost && renderTipAccordingly()}
              {/* {renderLikedByText()} */}
            </div>
          </div>
        ) : (
          <div className={classes['tip-options-container']}>
            <div
              onClick={() => setShowTipOptions(false)}
              className={classes['go-back-tip-container']}
            >
              <img
                src={GoBackIcon}
                alt="Go Back"
                className={classes['go-back-icon']}
              />
              <p className={classes['tip-text']}>Tip</p>
            </div>
            {TIP_OPTIONS.map((tipOption) => (
              <div
                onClick={() => createPostTipHandler(tipOption)}
                key={tipOption}
                className={`${classes['box-option']}`}
              >
                <p className={classes['points-value']}>
                  <img
                    src={PinkDiamondIcon}
                    alt="Diamond"
                    className={classes['diamond-icon']}
                  />
                  {parseFloat(tipOption)}{' '}
                </p>
              </div>
            ))}
          </div>
        )}

        <PostComments
          selectedTopic={props.selectedTopic}
          setSelectedTopic={props.setSelectedTopic}
          postId={post._id}
        />
      </div>
    </>
  );
}

export default Post;
