import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import classes from './Nft.module.css';
import heart from '../../assets/liked-nft-icon.png';
import unfilledHeartIcon from '../../assets/like-nft-icon.png';
import { Link } from 'react-router-dom';
import { CoinMarketCapContext } from '../../context/coinmarketcap-context';
import { getFormattedNumber, limitString } from '../../helpers/helpers';
import { AuthContext } from '../../context/auth-context';
import ReactPlayer from 'react-player';
import { IPFS_URL } from '../../constants/IPFS';
import {
  addNftToWatchlistRequest,
  getNumberOfLikesForSpecificNftRequest,
  getNumberOfWatchlistsForSpecificNftRequest,
  // getUsersNumberOfFollowersAndFollowingsRequest,
  likeNftRequest,
  removeNftFromWatchlistRequest,
  unlikeNftRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import BookmarkIcon from '../../assets/white-bookmark-icon.png';
import BookmarkedIcon from '../../assets/pink-bookmark-icon.png';
import ShareIcon from '../../assets/share-icon.png';
import VerifiedIcon from '../../assets/verified.png';
import {
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { FacebookIcon, TwitterIcon } from 'react-share';
import Button from '../Button/Button';

function Nft(props) {
  const { nft } = props;
  const nftContainerRef = useRef();
  const queryClient = useQueryClient();
  const {
    profile,
    myLikes,
    getMyLikes,
    getMyWatchlist,
    myWatchlist,
  } = useContext(AuthContext);
  const { glmsToUsd } = useContext(CoinMarketCapContext);

  const [showShareIcons, setShowShareIcons] = useState(false);

  const [isNftInHoverState, setIsNftInHoverState] = useState(false);

  const numberOfLikesResponse = useQuery(
    ['nft-number-likes', nft._id],
    () => getNumberOfLikesForSpecificNftRequest(nft._id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const numberOfWatchlistsResponse = useQuery(
    ['nft-number-watchlists', nft._id],
    () => getNumberOfWatchlistsForSpecificNftRequest(nft._id),
    {
      refetchOnWindowFocus: false,
    }
  );

  // const ownerNumberOfFollowersAndFollowingsResponse = useQuery(
  //   ['owner-number-of-followers-followings', nft.owner._id],
  //   () => getUsersNumberOfFollowersAndFollowingsRequest(nft.owner._id),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useEffect(() => {
    const nftContainerRefElement = nftContainerRef.current;
    if (!nftContainerRefElement) return;

    const mouseOverHandler = () => {
      setIsNftInHoverState(true);
    };

    const mouseOutHandler = () => {
      setIsNftInHoverState(false);
      setShowShareIcons(false);
    };

    nftContainerRefElement.addEventListener('mouseover', mouseOverHandler);
    nftContainerRefElement.addEventListener('mouseleave', mouseOutHandler);

    return () => {
      nftContainerRefElement.removeEventListener('mouseover', mouseOverHandler);
      nftContainerRefElement.removeEventListener('mouseout', mouseOutHandler);
    };
  }, [nftContainerRef]);

  const unlikeNftHandler = async () => {
    try {
      if (!profile?._id)
        return notify(
          'error',
          'You are not logged in. Please log in to perform this action!',
          1500
        );

      await unlikeNftRequest(nft._id);
      await Promise.all([
        queryClient.invalidateQueries(['nft-number-likes', nft._id]),
        queryClient.refetchQueries(['nft-number-likes', nft._id]),
        getMyLikes(),
      ]);
    } catch (error) {
      // notify('error', error, 2000);
    }
  };

  const likeNftHandler = async () => {
    try {
      if (!profile?._id)
        return notify(
          'error',
          'You are not logged in. Please log in to perform this action!',
          1500
        );

      await likeNftRequest(nft._id);
      await Promise.all([
        queryClient.invalidateQueries(['nft-number-likes', nft._id]),
        queryClient.refetchQueries(['nft-number-likes', nft._id]),
        getMyLikes(),
      ]);
    } catch (error) {
      // notify('error', error, 2000);
    }
  };

  const renderLikesContainer = () => {
    if (!profile?._id)
      return (
        <img
          className={classes['heart-icon']}
          onClick={unlikeNftHandler}
          alt="likes"
          src={unfilledHeartIcon}
          width={18}
          height={18}
        />
      );

    const isNftInMyLikes = myLikes.some((el) => el.nft?._id === nft._id);

    if (isNftInMyLikes) {
      return (
        <div className={classes['likes-container']}>
          <img
            className={classes['heart-icon']}
            onClick={unlikeNftHandler}
            alt="likes"
            src={heart}
            width={18}
            height={18}
          />

          <p className={classes['number-likes-text']}>
            {numberOfLikesResponse.data?.data}
          </p>
        </div>
      );
    } else {
      return (
        <div className={classes['likes-container']}>
          <img
            className={classes['heart-icon']}
            onClick={likeNftHandler}
            alt="likes"
            src={unfilledHeartIcon}
            width={18}
            height={18}
          />
          <p className={classes['number-likes-text']}>
            {numberOfLikesResponse.data?.data}
          </p>
        </div>
      );
    }
  };

  const renderWatchlistContainer = () => {
    if (!profile?._id)
      return (
        <img
          className={classes['heart-icon']}
          onClick={unlikeNftHandler}
          alt="likes"
          src={unfilledHeartIcon}
          width={18}
          height={18}
        />
      );

    const isNftInMyWatchlist = myWatchlist.some(
      (el) => el.nft?._id === nft._id
    );

    if (isNftInMyWatchlist) {
      return (
        <div
          onClick={removeNftFromWatchlistHandler}
          className={classes['likes-container']}
        >
          <img
            className={classes['heart-icon']}
            alt="Watchlisted"
            src={BookmarkedIcon}
            height={18}
          />

          <p className={classes['number-likes-text']}>
            {numberOfWatchlistsResponse.data?.data}
          </p>
        </div>
      );
    } else {
      return (
        <div
          onClick={addNftToWatchlistHandler}
          className={classes['likes-container']}
        >
          <img
            className={classes['heart-icon']}
            alt="Watchlist"
            src={BookmarkIcon}
            height={18}
          />
          <p className={classes['number-likes-text']}>
            {numberOfWatchlistsResponse.data?.data}
          </p>
        </div>
      );
    }
  };

  const addNftToWatchlistHandler = async () => {
    try {
      if (!profile?._id)
        return notify(
          'error',
          'You are not logged in. Please log in to perform this action!',
          1500
        );

      await addNftToWatchlistRequest(nft._id);
      await Promise.all([
        queryClient.invalidateQueries(['nft-number-watchlists', nft._id]),
        queryClient.refetchQueries(['nft-number-watchlists', nft._id]),
        getMyWatchlist(),
      ]);
    } catch (error) {
      // notify('error', error, 2000);
    }
  };

  const removeNftFromWatchlistHandler = async () => {
    try {
      if (!profile?._id)
        return notify(
          'error',
          'You are not logged in. Please log in to perform this action!',
          1500
        );

      await removeNftFromWatchlistRequest(nft._id);
      await Promise.all([
        queryClient.invalidateQueries(['nft-number-watchlists', nft._id]),
        queryClient.refetchQueries(['nft-number-watchlists', nft._id]),
        getMyWatchlist(),
      ]);
    } catch (error) {
      // notify('error', error, 2000);
    }
  };

  const toggleShareIconsHandler = () => {
    setShowShareIcons((prevState) => !prevState);
  };

  return (
    <div ref={nftContainerRef} className={classes['nft-container']}>
      <Link to={`/nfts/${nft._id}`}>
        {nft.type === 'image' ? (
          <img
            alt="IPFS"
            className={classes['nft-image']}
            src={nft.previewUrl ? nft.previewUrl : `${IPFS_URL}/${nft.ipfs}`}
          />
        ) : (
          <ReactPlayer
            width={330}
            height={360}
            url={`${IPFS_URL}/${nft.ipfs}`}
          />
        )}
      </Link>

      <div className={classes['nft-information-container']}>
        {!isNftInHoverState || !profile?._id ? (
          <div className={classes['nft-owner-container']}>
            <Link
              to={
                nft.owner._id === profile?._id
                  ? '/my-space'
                  : `/user-feed/${nft.owner._id}`
              }
            >
              <img
                src={nft.owner.photo}
                width={35}
                height={35}
                className={classes['owner-photo']}
                alt={nft.owner.fullName}
              />
            </Link>

            <div className={classes['nft-owner-info']}>
              <p className={classes['nft-title']}>
                {limitString(nft.title, 16)}
              </p>
              <p
                title={`${nft.owner.firstName} ${nft.owner.lastName}`}
                className={classes['owner-fullName']}
              >
                {limitString(
                  `${nft.owner.firstName} ${nft.owner.lastName}`,
                  15
                )}
                {nft.owner.verified && (
                  <img
                    alt="Verified"
                    src={VerifiedIcon}
                    style={{ marginLeft: 6, height: 17 }}
                  />
                )}
              </p>
            </div>

            {nft.listedForBuyNow && (
              <div className={classes['price-container']}>
                <p className={classes['usd-price']}>
                  $
                  {getFormattedNumber(
                    (nft.buyNowPrice * glmsToUsd.price).toFixed(2)
                  )}
                </p>
              </div>
            )}
          </div>
        ) : profile?._id ? (
          <div className={classes['watchlist-share-visit-container']}>
            <div className={classes['watchlist-share-container']}>
              {renderLikesContainer()}
              {renderWatchlistContainer()}

              <div onClick={toggleShareIconsHandler}>
                <img
                  className={classes['share-icon']}
                  alt="Share"
                  src={ShareIcon}
                  width={18}
                  height={18}
                />

                {showShareIcons && (
                  <div className={classes['share-icons-container']}>
                    <FacebookShareButton
                      url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                      hashtag={`#NFT`}
                      description={nft.description}
                      title={nft.title}
                    >
                      <FacebookIcon size={28} round />
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                      hashtag={`#NFT`}
                      description={nft.description}
                      title={nft.title}
                    >
                      <TwitterIcon size={28} round />
                    </TwitterShareButton>

                    <WhatsappShareButton
                      url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                      hashtag={`#NFT`}
                      description={nft.description}
                      title={nft.title}
                    >
                      <WhatsappIcon size={28} round />
                    </WhatsappShareButton>

                    <TelegramShareButton
                      url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                      hashtag={`#NFT`}
                      description={nft.description}
                      title={nft.title}
                    >
                      <TelegramIcon size={28} round />
                    </TelegramShareButton>

                    <RedditShareButton
                      url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                      hashtag={`#NFT`}
                      description={nft.description}
                      title={nft.title}
                    >
                      <RedditIcon size={28} round />
                    </RedditShareButton>
                  </div>
                )}
              </div>
            </div>
            <Link to={`/nfts/${nft._id}`}>
              <Button darkpink="true">Visit</Button>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
    // <div className={classes['nft-container']}>
    // <Link to={`/nfts/${nft._id}`}>
    //   {nft.type === 'image' ? (
    //     <img
    //       alt="IPFS"
    //       className={classes['nft-image']}
    //       src={nft.previewUrl ? nft.previewUrl : `${IPFS_URL}/${nft.ipfs}`}
    //     />
    //   ) : (
    //     <ReactPlayer
    //       width={330}
    //       height={360}
    //       url={`${IPFS_URL}/${nft.ipfs}`}
    //     />
    //   )}
    // </Link>

    //   <div className={classes['nft-information']}>
    //     <div className={classes['header']}>
    //       <div className={classes['owner-container']}>
    // <Link
    //   to={
    //     nft.owner._id === profile?._id
    //       ? '/my-space'
    //       : `/user-feed/${nft.owner._id}`
    //   }
    // >
    //   <img
    //     src={nft.owner.photo}
    //     width={35}
    //     height={35}
    //     className={classes['owner-photo']}
    //     alt={nft.owner.fullName}
    //   />
    // </Link>

    //         <div>
    //           <Link
    //             to={
    //               nft.owner._id === profile?._id
    //                 ? '/my-space'
    //                 : `/user-feed/${nft.owner._id}`
    //             }
    //           >
    //             <p
    //               title={`${nft.owner.firstName} ${nft.owner.lastName}`}
    //               className={classes['owner-fullName']}
    //             >
    // {limitString(
    //   `${nft.owner.firstName} ${nft.owner.lastName}`,
    //   22
    // )}

    // {nft.owner.verified && (
    //   <img
    //     alt="Verified"
    //     src={VerifiedIcon}
    //     style={{ marginLeft: 6, height: 17 }}
    //   />
    //               )}
    //             </p>
    //           </Link>
    //           <p className={classes['owner-text']}>Owner</p>
    //         </div>
    //       </div>

    //       <div
    //         style={{
    //           display: 'flex',
    //           flexDirection: 'column',
    //           alignItems: 'flex-end',
    //         }}
    //       >
    //         {nft.listedForBuyNow ? (
    //           <p className={classes['price']}>
    //             {getFormattedNumber(nft.buyNowPrice)} GLMS
    //           </p>
    //         ) : (
    //           <p className={classes['not-listed']}>Not Listed</p>
    //         )}
    // {nft.listedForBuyNow && (
    //   <p className={classes['usd-price']}>
    //     $
    //     {getFormattedNumber(
    //       (nft.buyNowPrice * glmsToUsd.price).toFixed(2)
    //     )}
    //   </p>
    // )}
    //       </div>
    //     </div>

    //     <h3 title={nft.title} className={classes['title']}>
    //       {limitString(nft.title, 20)}
    //     </h3>

    //     <Link to={`/nfts/${nft._id}`}>
    //       <div style={{ width: '100%' }}>
    //         <Button
    //           style={{ width: '100%', borderRadius: 12 }}
    //           modernpink="true"
    //         >
    //           Visit
    //         </Button>
    //       </div>
    //     </Link>

    //     {profile?._id && (
    //       <div className={classes['horizontal-line']}>&nbsp;</div>
    //     )}
    //   </div>

    //   {profile?._id && (
    //     <div className={classes['likes-watchlist-container']}>
    //       {renderLikesContainer()}
    //       {renderWatchlistContainer()}
    //       <div
    //         onClick={toggleShareIconsHandler}
    //         className={classes['watchlist-container']}
    //       >
    //         <img alt="Share" src={ShareIcon} width={18} height={18} />
    //         <p className={classes['share-text']}>Share</p>
    //         {showShareIcons && (
    // <div className={classes['share-icons-container']}>
    //   <FacebookShareButton
    //     url={`https://www.glimpsenft.com/nfts/${nft._id}`}
    //     hashtag={`#NFT`}
    //     description={nft.description}
    //     title={nft.title}
    //   >
    //     <FacebookIcon size={28} round />
    //   </FacebookShareButton>

    //   <TwitterShareButton
    //     url={`https://www.glimpsenft.com/nfts/${nft._id}`}
    //     hashtag={`#NFT`}
    //     description={nft.description}
    //     title={nft.title}
    //   >
    //     <TwitterIcon size={28} round />
    //   </TwitterShareButton>

    //   <WhatsappShareButton
    //     url={`https://www.glimpsenft.com/nfts/${nft._id}`}
    //     hashtag={`#NFT`}
    //     description={nft.description}
    //     title={nft.title}
    //   >
    //     <WhatsappIcon size={28} round />
    //   </WhatsappShareButton>

    //   <TelegramShareButton
    //     url={`https://www.glimpsenft.com/nfts/${nft._id}`}
    //     hashtag={`#NFT`}
    //     description={nft.description}
    //     title={nft.title}
    //   >
    //     <TelegramIcon size={28} round />
    //   </TelegramShareButton>

    //   <RedditShareButton
    //     url={`https://www.glimpsenft.com/nfts/${nft._id}`}
    //     hashtag={`#NFT`}
    //     description={nft.description}
    //     title={nft.title}
    //   >
    //     <RedditIcon size={28} round />
    //   </RedditShareButton>
    // </div>
    //         )}
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
}

export default React.memo(Nft);
