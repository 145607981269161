import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AuthContextProvider from './context/auth-context';
import './index.css';
import SocketProvider from './context/socket-context';
import CoinMarketCapProvider from './context/coinmarketcap-context';
import NotificationProvider from './context/notification-context';
import MetaMaskProvider from './context/metamask-context';
import NewsFeedProvider from './context/news-feed-context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <NotificationProvider>
        <SocketProvider>
          <CoinMarketCapProvider>
            <MetaMaskProvider>
              <NewsFeedProvider>
                <App />
              </NewsFeedProvider>
            </MetaMaskProvider>
          </CoinMarketCapProvider>
        </SocketProvider>
      </NotificationProvider>
    </AuthContextProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  document.getElementById('root')
);
