import React from 'react';
import Modal from '../../components/Modal/Modal';
import User from '../User/User';
import classes from './FollowersModal.module.css';

function MyFollowersModal(props) {
  const { followers, setShowFollowersModal } = props;

  return (
    <Modal
      style={{ maxWidth: 600, height: 600 }}
      closeIcon="true"
      closeModal={() => setShowFollowersModal(false)}
      title="Followers"
    >
      <div className={classes['followers-container']}>
        {followers.length === 0 && <p>No Followers Found!</p>}
        {followers.map((follower) => (
          <User
            key={follower._id}
            user={follower}
            closeModal={() => setShowFollowersModal(false)}
          />
        ))}
      </div>
    </Modal>
  );
}

export default MyFollowersModal;
