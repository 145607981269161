import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getAllNftsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { Row, Col } from 'antd';
import classes from './AllNfts.module.css';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { IPFS_URL } from '../../../../constants/IPFS';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import SliderButton from '../../../../components/SliderButton/SliderButton';
import { useQueryClient } from 'react-query';
import { updateNftContentRequest } from '../../../../httpRequests/httpRequests';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';

const RESULTS_PER_PAGE = 10;

function AllNfts() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [searchInputValue, setSearchInputValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['all-nfts', searchInputValue],
    ({ pageParam = 1 }) =>
      getAllNftsRequest(pageParam, RESULTS_PER_PAGE, searchInputValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateNft = (nftId, isAdultContent) => {
    queryClient.setQueryData(['all-nfts', searchInputValue], (data) => {
      return {
        ...data,
        pages: data.pages.map((page) => {
          const updatedData = page.data.map((nft) =>
            nft._id === nftId ? { ...nft, adultContent: isAdultContent } : nft
          );
          return { ...page, data: updatedData };
        }),
      };
    });
  };

  const updateNftContentHandler = async (nft, isAdultContent) => {
    try {
      const requestBody = {
        adultContent: isAdultContent,
      };
      notify('success', 'Updating content', 1000);
      await updateNftContentRequest(nft._id, requestBody);

      invalidateNft(nft._id, isAdultContent);
    } catch (error) {
      notify('error', error, 2500);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-users-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-users-heading']}>
            ALL NFTs = {data?.pages[0].results}
          </h2>
          <div>
            <input
              placeholder="Search For NFTs / Author / Owner"
              style={{ width: 300 }}
              className="searchInput"
              value={searchInputValue}
              onChange={(e) => {
                setSearchInputValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={1} className={classes['pagination-col']}>
              NFT
            </Col>
            <Col md={2} className={classes['pagination-col']}>
              Title
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Owner
            </Col>
            <Col md={3} className={classes['pagination-col']}>
              Author
            </Col>
            <Col md={3} className={classes['pagination-col']}>
              No. Transactions
            </Col>

            <Col
              md={5}
              style={{ paddingLeft: '5%' }}
              className={classes['pagination-col']}
            >
              Content
            </Col>
          </Row>

          {data && data.pages[0].results === 0 && (
            <h2 style={{ paddingTop: 10 }} className="bold-text">
              No NFTs found
            </h2>
          )}

          {!isLoading &&
            data &&
            data.pages.map((page) => {
              return page.data.map((nft) => {
                const { author, owner, totalTransactions } = nft;

                return (
                  <div key={nft._id}>
                    <hr />
                    <Row justify="space-between" style={{ padding: '1em 0' }}>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/nfts/${nft._id}`)}
                        md={1}
                      >
                        {nft.type === 'image' ? (
                          <img
                            width={30}
                            height={30}
                            style={{ borderRadius: 100, objectFit: 'cover' }}
                            alt="User"
                            src={`${IPFS_URL}/${nft.ipfs}`}
                          />
                        ) : (
                          <ReactPlayer
                            width={30}
                            height={30}
                            url={`${IPFS_URL}/${nft.ipfs}`}
                          />
                        )}
                      </Col>
                      <Col
                        md={2}
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/nfts/${nft._id}`)}
                      >
                        {nft.title}
                      </Col>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/user-stats/${owner._id}`)}
                        md={3}
                      >
                        {owner.firstName} {owner.lastName}
                      </Col>

                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          history.push(`/user-stats/${author._id}`)
                        }
                        md={3}
                      >
                        {author.firstName} {author.lastName}
                      </Col>
                      <Col md={3}>{totalTransactions}</Col>

                      <Col md={5}>
                        <SliderButton
                          leftValue={'Safe'}
                          rightValue={'Adult'}
                          isLeftContentSelected={!nft.adultContent}
                          onSliderChangeCb={(isSafeContentSelected) => {
                            updateNftContentHandler(
                              nft,
                              !isSafeContentSelected
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              });
            })}

          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default AllNfts;
