import React, { useRef } from 'react';

const MultipleImageUpload = (props) => {
  const filePickerRef = useRef();

  const pickedHandler = (e) => {
    if (e.target.files?.length >= 1) {
      props.onInput(e.target.files);
    }
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <>
      <input
        id={props.id}
        ref={filePickerRef}
        style={{ display: 'none' }}
        type="file"
        accept=".jpg,.png,.jpeg"
        onChange={pickedHandler}
        name="files[]"
        multiple
      />

      {!props.children ? (
        <div
          shape="round"
          className={`btn-photo-upload`}
          onClick={pickImageHandler}
          style={{ cursor: 'pointer' }}
        >
          <span>+</span>
        </div>
      ) : (
        <span style={{ padding: 0, margin: 0 }} onClick={pickImageHandler}>
          {props.children}
        </span>
      )}
    </>
  );
};
export default MultipleImageUpload;
