import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../context/auth-context';
import { limitString } from '../../../../helpers/helpers';
import EditIntroductionModal from '../EditIntroductionModal/EditIntroductionModal';
import classes from './Introduction.module.css';

function Introduction() {
  const { profile } = useContext(AuthContext);
  const [showEditIntroductionModal, setShowEditIntroductionModal] = useState(
    false
  );

  const openEditIntroductionModalHandler = () => {
    setShowEditIntroductionModal(true);
  };

  return (
    <>
      {showEditIntroductionModal && (
        <EditIntroductionModal
          setShowEditIntroductionModal={setShowEditIntroductionModal}
        />
      )}
      <div className={classes['introduction-page-container']}>
        <div className={classes['introduction-container']}>
          <h3 className={classes['introduction-text']}>Introduction</h3>
          <p
            onClick={openEditIntroductionModalHandler}
            className={classes['edit']}
          >
            Edit
          </p>
        </div>

        {profile?.introduction ? (
          <p title={profile.introduction} className={classes['introduction']}>
            {limitString(profile.introduction, 280)}
          </p>
        ) : (
          <p
            style={{ opacity: '.4', fontWeight: 400 }}
            className={classes['introduction']}
          >
            No Introduction Found
          </p>
        )}
      </div>
    </>
  );
}

export default Introduction;
