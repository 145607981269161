import React, { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import CardNft from '../../components/Nft/Nft';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { getNewNftsRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import classes from './NewNftsContent.module.css';

const RESULTS_PER_PAGE = 6;

function NewNfts(props) {
  const {
    sortByValue,
    sortByValues,
    page,
    searchValue,
    isSafeContentSelected,
  } = props;

  const sortBy = sortByValues.find((el) => el.value === sortByValue);
  let sortByFilter = 'ending-soon';

  if (sortBy?.sortByValue === 'Price low to high') {
    sortByFilter = 'low-high';
  } else if (sortBy?.sortByValue === 'Price high to low') {
    sortByFilter = 'high-low';
  } else if (sortBy?.sortByValue === 'Ending Soon') {
    sortByFilter = 'ending-soon';
  } else if (sortBy?.sortByValue === 'Most recent') {
    sortByFilter = 'most-recent';
  }

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['new-nfts', page, sortByFilter, searchValue, isSafeContentSelected],
    ({ pageParam = 1 }) =>
      getNewNftsRequest(
        pageParam,
        sortByFilter,
        searchValue,
        isSafeContentSelected
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
    }
  );

  useEffect(() => {
    error && notify('error', error, 3000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <>
      <div className={classes['marketplace-list']}>
        {!isLoading &&
          data?.pages &&
          data.pages.map((page) => {
            return page.data.map((nft) => {
              return <CardNft key={nft._id} nft={nft} owner={nft.owner} />;
            });
          })}
      </div>
      {(isLoading || isFetching) && <LoadingSpinner />}

      {!isLoading && data && data?.pages[0].results === 0 && (
        <div
          style={{
            background: 'white',
            padding: '.8rem',
            borderRadius: 10,
            marginTop: 10,
            width: '95%',
            gridColumn: '1/-1',
          }}
        >
          <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
            No NFTs found with the provided filters!
          </p>
        </div>
      )}
    </>
  );
}

export default NewNfts;
