import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  createStripePaymentAccountLinkRequest,
  getMyStripeAccountDetailsRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import Button from '../Button/Button';

function StripeAccount() {
  const { data, error } = useQuery(
    'my-stripe-account',
    getMyStripeAccountDetailsRequest
  );
  const [
    creatingStripePaymentAccountLink,
    setCreatingStripePaymentAccountLink,
  ] = useState(false);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const connectStripePaymentHandler = async () => {
    setCreatingStripePaymentAccountLink(true);

    try {
      const {
        data: accountLink,
      } = await createStripePaymentAccountLinkRequest();
      window.open(accountLink, '_blank');
    } catch (error) {
      notify('error', error, 2000);
    }

    setCreatingStripePaymentAccountLink(false);
  };

  if (data === undefined) return null;

  const myStripeAccountDetails = data?.data;
  if (myStripeAccountDetails === null)
    return (
      <Button
        disabled={creatingStripePaymentAccountLink}
        darkpinkcolor="true"
        onClick={connectStripePaymentHandler}
      >
        Link Bank Account
      </Button>
    );

  if (
    myStripeAccountDetails.capabilities &&
    myStripeAccountDetails.capabilities.card_payments === 'active' &&
    myStripeAccountDetails.capabilities.transfers === 'active'
  )
    return null;

  return (
    <Button
      disabled={creatingStripePaymentAccountLink}
      darkpinkcolor="true"
      onClick={connectStripePaymentHandler}
    >
      Finish Linking Your Bank
    </Button>
  );
}

export default StripeAccount;
