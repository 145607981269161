import React from 'react';
import SliderButton from '../../../../../../components/SliderButton/SliderButton';
import classes from './FifthScreen.module.css';
import Input from '../../../../../../components/Input/Input';
import Button from '../../../../../../components/Button/Button';
import TextArea from '../../../../../../components/TextArea/TextArea';

function FifthScreen(props) {
  const {
    scheduleDay,
    scheduleMonth,
    scheduleYear,
    setScheduleDay,
    setScheduleMonth,
    setScheduleYear,
    isLiveDateSelected,
    setIsLiveDateSelected,
    setScreenNumber,
    scheduleDayTouched,
    setScheduleDayTouched,
    isScheduleDayValid,
    setIsScheduleDayValid,
    scheduleMonthTouched,
    setScheduleMonthTouched,
    isScheduleMonthValid,
    setIsScheduleMonthValid,
    scheduleHour,
    scheduleMinutes,
    scheduleSeconds,
    setScheduleHour,
    setScheduleMinutes,
    setScheduleSeconds,
    setScheduleHourTouched,
    isScheduleHourValid,
    scheduleHourTouched,
    setIsScheduleHourValid,
    scheduleMinutesTouched,
    setScheduleMinutesTouched,
    isScheduleMinutesValid,
    setIsScheduleMinutesValid,
    scheduleSecondsTouched,
    setScheduleSecondsTouched,
    isScheduleSecondsValid,
    setIsScheduleSecondsValid,
    scheduleYearTouched,
    setScheduleYearTouched,
    isScheduleYearValid,
    setIsScheduleYearValid,
    isAMSelected,
    setIsAMSelected,
  } = props;

  const scheduleDayChangeHandler = (e) => {
    setScheduleDayTouched(true);
    if (
      !e.target.value ||
      isNaN(e.target.value) ||
      parseInt(e.target.value) > 31 ||
      parseInt(e.target.value) < 0
    ) {
      setIsScheduleDayValid(false);
    } else {
      setIsScheduleDayValid(true);
    }

    setScheduleDay(e.target.value);
  };

  const scheduleMonthChangeHandler = (e) => {
    setScheduleMonthTouched(true);
    if (
      !e.target.value ||
      isNaN(e.target.value) ||
      parseInt(e.target.value) > 12 ||
      parseInt(e.target.value) < 0
    ) {
      setIsScheduleMonthValid(false);
    } else {
      setIsScheduleMonthValid(true);
    }

    setScheduleMonth(e.target.value);
  };

  const scheduleYearChangeHandler = (e) => {
    setScheduleYearTouched(true);
    if (
      !e.target.value ||
      isNaN(e.target.value) ||
      parseInt(e.target.value) < new Date().getFullYear()
    ) {
      setIsScheduleYearValid(false);
    } else {
      setIsScheduleYearValid(true);
    }

    setScheduleYear(e.target.value);
  };

  const goBackHandler = () => {
    setScreenNumber((prevState) => prevState - 1);
  };

  const goNextHandler = () => {
    setScreenNumber((prevState) => prevState + 1);
  };

  const scheduleHourChangeHandler = (e) => {
    setScheduleHourTouched(true);
    if (
      !e.target.value ||
      isNaN(e.target.value) ||
      parseInt(e.target.value) > 12 ||
      parseInt(e.target.value) < 0
    ) {
      setIsScheduleHourValid(false);
    } else {
      setIsScheduleHourValid(true);
    }

    setScheduleHour(e.target.value);
  };

  const scheduleMinutesChangeHandler = (e) => {
    setScheduleMinutesTouched(true);
    if (
      !e.target.value ||
      isNaN(e.target.value) ||
      parseInt(e.target.value) > 60 ||
      parseInt(e.target.value) < 0
    ) {
      setIsScheduleMinutesValid(false);
    } else {
      setIsScheduleMinutesValid(true);
    }

    setScheduleMinutes(e.target.value);
  };

  const scheduleSecondsChangeHandler = (e) => {
    setScheduleSecondsTouched(true);
    if (
      !e.target.value ||
      isNaN(e.target.value) ||
      parseInt(e.target.value) > 60 ||
      parseInt(e.target.value) < 0
    ) {
      setIsScheduleSecondsValid(false);
    } else {
      setIsScheduleSecondsValid(true);
    }

    setScheduleSeconds(e.target.value);
  };

  const scheduleDayInputStyle = {};
  if (isLiveDateSelected && !isScheduleDayValid && scheduleDayTouched) {
    scheduleDayInputStyle['border'] = '1px solid red';
  }

  const scheduleHourInputStyle = {};
  if (isLiveDateSelected && !isScheduleHourValid && scheduleHourTouched) {
    scheduleHourInputStyle['border'] = '1px solid red';
  }
  const scheduleMinutesInputStyle = {};
  if (isLiveDateSelected && !isScheduleMinutesValid && scheduleMinutesTouched) {
    scheduleMinutesInputStyle['border'] = '1px solid red';
  }
  const scheduleSecondsInputStyle = {};
  if (isLiveDateSelected && !isScheduleSecondsValid && scheduleSecondsTouched) {
    scheduleSecondsInputStyle['border'] = '1px solid red';
  }
  const scheduleMonthInputStyle = {};
  if (isLiveDateSelected && !isScheduleMonthValid && scheduleMonthTouched) {
    scheduleMonthInputStyle['border'] = '1px solid red';
  }
  const scheduleYearInputStyle = {};
  if (isLiveDateSelected && !isScheduleYearValid && scheduleYearTouched) {
    scheduleYearInputStyle['border'] = '1px solid red';
  }

  const isNextButtonDisabled = () => {
    if (!isLiveDateSelected) return false;

    if (
      isScheduleDayValid &&
      isScheduleHourValid &&
      isScheduleMinutesValid &&
      isScheduleMonthValid &&
      isScheduleYearValid &&
      isScheduleSecondsValid
    ) {
      return false;
    }

    return true;
  };

  return (
    <div
      style={{
        minHeight: 360,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div className={classes['upload-later-slider-container']}>
          <p className={classes['upload-later-text']}>
            Launch Collection Later
          </p>
          <SliderButton
            leftValue={''}
            rightValue={''}
            isLeftContentSelected={!isLiveDateSelected}
            setIsLeftContentSelected={setIsLiveDateSelected}
          />
        </div>

        <div className={classes['schedule-container']}>
          <div className={classes['input-label-container']}>
            <p className={classes['label']}>Day</p>
            <Input
              disabled={!isLiveDateSelected}
              type="number"
              placeholder="DD"
              style={{ width: 120, ...scheduleDayInputStyle }}
              value={scheduleDay}
              onChange={scheduleDayChangeHandler}
            />
          </div>
          <div className={classes['input-label-container']}>
            <p className={classes['label']}>Month</p>
            <Input
              disabled={!isLiveDateSelected}
              type="number"
              placeholder="MM"
              style={{ width: 120, ...scheduleMonthInputStyle }}
              value={scheduleMonth}
              onChange={scheduleMonthChangeHandler}
            />
          </div>
          <div className={classes['input-label-container']}>
            <p className={classes['label']}>Year</p>
            <Input
              disabled={!isLiveDateSelected}
              type="number"
              placeholder="YYYY"
              style={{ width: 120, ...scheduleYearInputStyle }}
              value={scheduleYear}
              onChange={scheduleYearChangeHandler}
            />
          </div>
        </div>

        <div className={classes['schedule-container']}>
          <div>
            <p className={classes['label']}>Hours</p>
            <TextArea
              disabled={!isLiveDateSelected}
              style={{
                width: 120,
                borderRadius: 50,
                ...scheduleHourInputStyle,
              }}
              type="number"
              value={scheduleHour}
              placeholder="00"
              onChange={scheduleHourChangeHandler}
            />
          </div>

          <div>
            <p className={classes['label']}>Minutes</p>
            <TextArea
              disabled={!isLiveDateSelected}
              style={{
                width: 120,
                borderRadius: 50,
                ...scheduleMinutesInputStyle,
              }}
              type="number"
              value={scheduleMinutes}
              placeholder="00"
              onChange={scheduleMinutesChangeHandler}
            />
          </div>

          <div>
            <p className={classes['label']}>Seconds</p>
            <TextArea
              disabled={!isLiveDateSelected}
              style={{
                width: 120,
                borderRadius: 50,
                ...scheduleSecondsInputStyle,
              }}
              value={scheduleSeconds}
              placeholder="00"
              type="number"
              onChange={scheduleSecondsChangeHandler}
            />
          </div>

          <div style={{ marginTop: 20 }}>
            <div
              onClick={() => setIsAMSelected(true)}
              className={`${classes['am-container']} ${
                classes[isAMSelected && 'selected']
              }`}
            >
              <p>AM</p>
            </div>
            <div
              onClick={() => setIsAMSelected(false)}
              className={`${classes['pm-container']} ${
                classes[!isAMSelected && 'selected']
              }`}
            >
              <p>PM</p>
            </div>
          </div>
        </div>
      </div>

      <div className={classes['upload-later-slider-container']}>
        <p className={classes['upload-now-text']}>Launch Collection Now</p>
        <SliderButton
          leftValue={''}
          rightValue={''}
          isLeftContentSelected={isLiveDateSelected}
          setIsLeftContentSelected={setIsLiveDateSelected}
        />
      </div>

      <div style={{ justifySelf: 'flex-end' }}>
        {props.children}
        <div className={classes['footer']}>
          <Button
            style={{ flex: 1 }}
            darkpinkcolor="true"
            onClick={goBackHandler}
          >
            Go Back
          </Button>
          <Button
            disabled={isNextButtonDisabled()}
            onClick={goNextHandler}
            style={{ flex: 1 }}
            darkpink="true"
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FifthScreen;
