import React, { useEffect, useState, useCallback } from 'react';
import { getDateFrom, limitString } from '../../helpers/helpers';
import classes from './Poll.module.css';
import moment from 'moment';
import {
  createGroupPollVoteRequest,
  getVotesResultsForASpecificPollRequest,
  hasUserAccessToCreateAnAnswerForASpecificGroupPollRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import { useQuery, useQueryClient } from 'react-query';
import GreenOkIcon from '../../assets/green-ok.png';
import UsersWhoVotedForASpecificGroupPollAnswerModal from '../UsersWhoVotedForASpecificGroupPollAnswerModal/UsersWhoVotedForASpecificGroupPollAnswerModal';
import Button from '../Button/Button';
import AddGroupPollAnswerOptionModal from '../AddGroupPollAnswerOptionModal/AddGroupPollAnswerOptionModal';

function Poll(props) {
  const queryClient = useQueryClient();
  const {
    pollId,
    createdBy,
    createdAt,
    question,
    answers,
    endDate,
    answersByCommunity,
    groupId,
  } = props;

  const [
    usersWhoVotedForASpecificGroupPollAnswerModalState,
    setUsersWhoVotedForASpecificGroupPollAnswerModalState,
  ] = useState({
    opened: false,
    groupPollAnswerId: undefined,
  });
  const [
    showAddGroupPollAnswerOptionModal,
    setShowAddGroupPollAnswerOptionModal,
  ] = useState(false);

  const [remainingDaysUntilEndDate, setRemainingDaysUntilEndDate] = useState(
    '-'
  );
  const [remainingHoursUntilEndDate, setRemainingHoursUntilEndDate] = useState(
    '-'
  );
  const [
    remainingMinutesUntilEndDate,
    setRemainingMinutesUntilEndDate,
  ] = useState('-');
  const [
    remainingSecondsUntilEndDate,
    setRemainingSecondsUntilEndDate,
  ] = useState('-');

  const { data, error } = useQuery(['votes-results', pollId], () =>
    getVotesResultsForASpecificPollRequest(pollId)
  );
  const hasUserAccessToCreateAnAnswerResponse = useQuery(
    ['access-to-create-answer', pollId],
    () => hasUserAccessToCreateAnAnswerForASpecificGroupPollRequest(pollId)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const updateRemainingHoursAndMinutes = useCallback(() => {
    const duration = moment.duration(moment(endDate).diff());
    if (duration < 0) return;

    const remainingDays = parseInt(duration.asDays());
    const remainingHours = (duration.asDays() - remainingDays) * 24;
    const remainingMinutes = (remainingHours - parseInt(remainingHours)) * 60;

    const realSeconds = duration.asSeconds();
    setRemainingDaysUntilEndDate(remainingDays);
    setRemainingHoursUntilEndDate(parseInt(remainingHours));
    setRemainingMinutesUntilEndDate(parseInt(remainingMinutes));
    setRemainingSecondsUntilEndDate(
      parseInt(
        (realSeconds - (duration.asMinutes() - remainingMinutes) * 60) % 60
      )
    );
  }, [endDate]);

  useEffect(() => {
    if (
      remainingDaysUntilEndDate === 0 &&
      remainingHoursUntilEndDate === 0 &&
      remainingMinutesUntilEndDate === 0 &&
      remainingSecondsUntilEndDate === 0
    ) {
      setTimeout(() => {
        queryClient.invalidateQueries(['votes-results', pollId]);
        queryClient.refetchQueries(['votes-results', pollId]);
        queryClient.invalidateQueries(['access-to-create-answer', pollId]);
        queryClient.refetchQueries(['access-to-create-answer', pollId]);
      }, 1000);
    }
  }, [
    remainingSecondsUntilEndDate,
    remainingDaysUntilEndDate,
    remainingHoursUntilEndDate,
    remainingMinutesUntilEndDate,
    pollId,
    queryClient,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateRemainingHoursAndMinutes();
    }, 1000);

    return () => clearInterval(interval);
  }, [updateRemainingHoursAndMinutes]);

  const myVote = data ? data.myVoteForExistingPoll : undefined;

  const voteAnswerHandler = async (groupPollAnswerId) => {
    try {
      const requestBody = {
        groupPollId: pollId,
      };
      await createGroupPollVoteRequest(groupPollAnswerId, requestBody);

      queryClient.invalidateQueries(['votes-results', pollId]);
      queryClient.refetchQueries(['votes-results', pollId]);

      if (answersByCommunity) {
        queryClient.invalidateQueries(['access-to-create-answer', pollId]);
        queryClient.refetchQueries(['access-to-create-answer', pollId]);
      }
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const openAddGroupPollAnswerOptionModalHandler = () => {
    setShowAddGroupPollAnswerOptionModal(true);
  };

  const openUsersWhoVotedForASpecificGroupPollAnswerModalHandler = (
    groupPollAnswerId
  ) => {
    setUsersWhoVotedForASpecificGroupPollAnswerModalState({
      opened: true,
      groupPollAnswerId,
    });
  };

  return (
    <>
      {showAddGroupPollAnswerOptionModal && (
        <AddGroupPollAnswerOptionModal
          groupId={groupId}
          groupPollId={pollId}
          setShowAddGroupPollAnswerOptionModal={
            setShowAddGroupPollAnswerOptionModal
          }
        />
      )}

      {usersWhoVotedForASpecificGroupPollAnswerModalState.opened && (
        <UsersWhoVotedForASpecificGroupPollAnswerModal
          groupPollId={pollId}
          groupPollAnswerId={
            usersWhoVotedForASpecificGroupPollAnswerModalState.groupPollAnswerId
          }
          setShowUsersWhoVotedForASpecificGroupPollAnswerModal={() =>
            setUsersWhoVotedForASpecificGroupPollAnswerModalState({
              opened: false,
              groupPollAnswerId: undefined,
            })
          }
        />
      )}

      <div className={classes['poll-container']}>
        <div className={classes['header']}>
          <img
            className={classes['creator-photo']}
            src={createdBy.photo}
            alt="Creator"
          />
          <div className={classes['creator-information']}>
            <div className={classes['creator-line']}>
              <p className={classes['creator-fullName']}>
                {createdBy.fullName}
              </p>
              <p className={classes['circle']}>&nbsp;</p>
              <span className={classes['created-poll-text']}>
                Created a Poll
              </span>
            </div>
            <p className={classes['created-at']}>{getDateFrom(createdAt)}</p>
          </div>
        </div>

        <div className={classes['question-duration-container']}>
          <p className={classes['question-text']}>{question}</p>

          {data?.hasPollEnded ? (
            <p className={classes['has-ended']}>Poll Has Ended</p>
          ) : (
            <p className={classes['ends-in']}>
              {`Poll Ends In: ${remainingDaysUntilEndDate}D:${remainingHoursUntilEndDate}H:${remainingMinutesUntilEndDate}M:${remainingSecondsUntilEndDate}S`}
            </p>
          )}
        </div>

        <div className={classes['answers-container']}>
          {answers.map(({ _id, answer }) => {
            let style = {};

            const isMyVotedAnswer = myVote && myVote.groupPollAnswer === _id;

            const numberOfVotes = data?.data
              ? data.data.find((el) => el._id === _id)?.numberOfVotes || 0
              : 0;
            const votedBy = data?.data
              ? data?.data.find((el) => el._id === _id)?.votedBy
              : [];
            const totalVotes = data?.results || 0;
            const percentage =
              parseFloat(numberOfVotes / totalVotes).toFixed(2) * 100;

            if (myVote || data?.hasPollEnded) {
              style = {
                background: `linear-gradient(to right,  rgba(255, 82, 82,.2) ${percentage}%,
              rgba(255, 82, 82,.2) ${percentage}%, rgba(0, 0, 0, 0.05) ${percentage}%, rgba(0, 0, 0, 0.05) ${
                  100 - percentage
                }%)`,
              };
            }

            if (isMyVotedAnswer) {
              return (
                <div key={_id} className={classes['answer-votes-container']}>
                  <div
                    style={style}
                    onClick={() => voteAnswerHandler(_id)}
                    className={classes['answer-container']}
                  >
                    <p title={answer} className={classes['answer-text']}>
                      {limitString(answer, 25)}
                    </p>
                    <div className={classes['green-ok-icon']}>
                      <img alt="Voted" src={GreenOkIcon} />
                    </div>
                  </div>

                  <div className={classes['votes-results']}>
                    <p className={classes['number-of-votes-text']}>
                      {`${((numberOfVotes / data?.results || 0) * 100).toFixed(
                        2
                      )}%`}
                    </p>

                    <div
                      onClick={() =>
                        openUsersWhoVotedForASpecificGroupPollAnswerModalHandler(
                          _id
                        )
                      }
                      className={classes['voted-by-container']}
                    >
                      {votedBy?.map(({ photo }, i) => (
                        <img
                          key={photo + i}
                          src={photo}
                          alt="Voted"
                          className={classes['voted-by-photo']}
                        />
                      ))}
                      {numberOfVotes > 3 && (
                        <div className={classes['extra-votes-container']}>
                          <p className={classes['extra-votes-text']}>
                            {numberOfVotes - 3}+
                          </p>{' '}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <div key={_id} className={classes['answer-votes-container']}>
                <div
                  style={{ ...style }}
                  onClick={() => voteAnswerHandler(_id)}
                  className={classes['answer-container']}
                >
                  <p title={answer} className={classes['answer-text']}>
                    {limitString(answer, 25)}
                  </p>
                </div>

                <div className={classes['votes-results']}>
                  {(myVote || data?.hasPollEnded) && (
                    <p className={classes['number-of-votes-text']}>
                      {`${((numberOfVotes / data?.results || 0) * 100).toFixed(
                        2
                      )}%`}
                    </p>
                  )}

                  {(myVote || data?.hasPollEnded) && (
                    <>
                      <div
                        onClick={() =>
                          openUsersWhoVotedForASpecificGroupPollAnswerModalHandler(
                            _id
                          )
                        }
                        className={classes['voted-by-container']}
                      >
                        {votedBy?.map(({ photo }, i) => (
                          <img
                            key={photo + i}
                            src={photo}
                            alt="Voted"
                            className={classes['voted-by-photo']}
                          />
                        ))}
                        {numberOfVotes > 3 && (
                          <div className={classes['extra-votes-container']}>
                            <p className={classes['extra-votes-text']}>
                              {numberOfVotes - 3}+
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {answersByCommunity === true && (
          <Button
            disabled={!hasUserAccessToCreateAnAnswerResponse.data?.data}
            style={{ marginTop: 20, width: '100%' }}
            darkpink="true"
            onClick={openAddGroupPollAnswerOptionModalHandler}
          >
            + Add Another Option
          </Button>
        )}
      </div>
    </>
  );
}

export default Poll;
