import React from 'react';
import HashTag from '../../../../../../assets/gray-hashtag.png';
import { limitString } from '../../../../../../helpers/helpers';
import classes from './Topic.module.css';

function Topic(props) {
  const {
    topic,
    numberOfPosts,
    setSelectedTopic,
    isSelected,
    setSelectedGroupSection,
  } = props;

  const selectTopicHandler = () => {
    if (isSelected) setSelectedTopic(undefined);
    else {
      setSelectedTopic(topic);
      setSelectedGroupSection('Discussion');
    }
  };

  return (
    <div
      onClick={selectTopicHandler}
      className={`${classes['topic-container']} ${
        isSelected && classes['selected-topic']
      }`}
    >
      <div className={classes['hashtag-topic']}>
        <img alt="Topic" src={HashTag} className={classes['hashtag-icon']} />
        <div>
          <h3 title={topic.replace('#', '')} className={classes['topic-text']}>
            {' '}
            {limitString(topic.replace('#', ''), 17)}
          </h3>
          <p className={classes['number-of-posts']}>
            {' '}
            {numberOfPosts} posts in this group
          </p>
        </div>
      </div>
    </div>
  );
}

export default Topic;
