import React, { useContext, useState } from 'react';
import classes from './ShareNftModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import notify from '../../../../utils/notify';
import { createNftPostRequest } from '../../../../httpRequests/httpRequests';
const { AuthContext } = require('../../../../context/auth-context');

function ShareNftModal(props) {
  const { getMyProfile } = useContext(AuthContext);
  const { nft, setShowShareNftModal } = props;
  const [creatingNftPost, setCreatingNftPost] = useState(false);
  const [postContent, setPostContent] = useState('');

  const closeModalHandler = () => {
    setShowShareNftModal(false);
  };

  const shareNftHandler = async () => {
    setCreatingNftPost(true);

    try {
      const requestBody = {
        postContent,
      };
      await createNftPostRequest(nft._id, requestBody);
      setCreatingNftPost(false);
      closeModalHandler();
      getMyProfile();
    } catch (err) {
      setCreatingNftPost(false);
      notify('error', err, 2000);
    }
  };

  const postContentChangeHandler = (e) => {
    setPostContent(e.target.value);
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
            disabled={creatingNftPost}
          >
            Cancel
          </Button>
          <Button
            disabled={creatingNftPost || !postContent}
            style={{ width: 200 }}
            darkpink="true"
            onClick={shareNftHandler}
          >
            Share
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Share Collectible"
    >
      <textarea
        className={classes['textarea']}
        style={{ minHeight: 280, width: '100%' }}
        placeholder="Share Collectible Content"
        value={postContent}
        onChange={postContentChangeHandler}
      />

      <p className={classes['promote-text']}>
        *Promoting your collectible costs 20 Diamonds
      </p>
    </Modal>
  );
}

export default ShareNftModal;
