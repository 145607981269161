import React, { useEffect, useState, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getMyPaginatedFollowingsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import Modal from '../../../../components/Modal/Modal';
import User from '../../../../components/User/User';
import classes from './MyFollowingsModal.module.css';

const RESULTS_PER_PAGE = 10;

function MyFollowingsModal(props) {
  const followingsContainerRef = useRef();
  const { setShowMyFollowingsModal } = props;

  const [searchValue] = useState('');

  const { data, error, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ['my-paginated-followings', searchValue],
    ({ pageParam = 1 }) =>
      getMyPaginatedFollowingsRequest(pageParam, RESULTS_PER_PAGE, searchValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const followingsContainerRefElement = followingsContainerRef.current;

    if (!followingsContainerRefElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    followingsContainerRefElement.addEventListener('scroll', scrollHandler);

    return () =>
      followingsContainerRefElement?.removeEventListener(
        'scroll',
        scrollHandler
      );
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <Modal
      closeModal={() => setShowMyFollowingsModal(false)}
      title="My Followings"
      closeIcon="true"
      style={{ maxWidth: 600, height: 600 }}
    >
      <div
        ref={followingsContainerRef}
        className={classes['followings-container']}
      >
        {data && data.pages && data.pages[0].results === 0 && (
          <p className={classes['no-followings']}>No Followings Found!</p>
        )}
        {data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map(({ following }) => {
              return (
                <User
                  key={following._id}
                  user={following}
                  closeModal={() => setShowMyFollowingsModal(false)}
                />
              );
            });
          })}
      </div>
    </Modal>
  );
}

export default MyFollowingsModal;
