import React, { useContext, useState } from 'react';
import Button from '../../../../../../components/Button/Button';
import Input from '../../../../../../components/Input/Input';
import Modal from '../../../../../../components/Modal/Modal';
import classes from './ConfirmCreateGroupChatModal.module.css';
import ArrowDownIcon from '../../../../../../assets/arrowDown.png';
import ArrowUpIcon from '../../../../../../assets/arrowUp.png';
import { AuthContext } from '../../../../../../context/auth-context';
import { createGroupChatRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import { useQueryClient } from 'react-query';

function ConfirmCreateGroupChatModal(props) {
  const queryClient = useQueryClient();
  const { profile } = useContext(AuthContext);
  const {
    setShowConfirmCreateGroupChatModal,
    selectedUsersToJoinTheGroup,
    setShowCreateGroupChatModal,
  } = props;

  const [chatName, setChatName] = useState('');
  const [chatNameTouched, setChatNameTouched] = useState(false);
  const [showGroupDetails, setShowGroupDetails] = useState(true);
  const [showGroupMembers, setShowGroupMembers] = useState(true);

  const closeModalHandler = () => setShowConfirmCreateGroupChatModal(false);

  const chatNameChangeHandler = (e) => {
    setChatName(e.target.value);
    setChatNameTouched(true);
  };

  const createGroupChatHandler = async () => {
    try {
      const userIds = selectedUsersToJoinTheGroup.map((user) => user._id);
      const requestBody = { name: chatName, userIds };

      await createGroupChatRequest(requestBody);
      queryClient.invalidateQueries('my-chats');
      queryClient.refetchQueries('my-chats');
      setShowCreateGroupChatModal(false);
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const chatNameInputStyle = {};
  if (chatName.length === 0 && chatNameTouched) {
    chatNameInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      style={{ height: 600 }}
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Cancel
          </Button>

          <Button
            style={{ width: 200 }}
            onClick={createGroupChatHandler}
            disabled={chatName.length === 0}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Group Information"
    >
      <>
        <div className={classes['group-details-container']}>
          <div className={classes['group-details-arrow']}>
            <p className={classes['group-details-text']}>Group Details</p>
            <img
              alt="Arrow"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowGroupDetails((prevState) => !prevState)}
              src={showGroupDetails ? ArrowUpIcon : ArrowDownIcon}
              width={10}
            />
          </div>
          {showGroupDetails && (
            <>
              <div className={classes['group-details']}>
                <div className={classes['images']}>
                  {selectedUsersToJoinTheGroup.length > 3 && (
                    <p className={classes['others-text']}>
                      + {selectedUsersToJoinTheGroup.length - 3} others
                    </p>
                  )}

                  {selectedUsersToJoinTheGroup.slice(0, 3).map((user) => (
                    <img
                      key={user._id}
                      style={{ marginRight: -10 }}
                      src={user.photo}
                      width={50}
                      height={50}
                      className={classes['user-image']}
                      alt={user.fullName}
                    />
                  ))}

                  <img
                    style={{ marginRight: -10 }}
                    src={profile?.photo}
                    width={50}
                    height={50}
                    className={classes['user-image']}
                    alt={profile?.fullName}
                  />
                </div>
              </div>
              <p className={classes['group-name-text']}>Group Name</p>
              <Input
                placeholder="Enter here"
                style={{ width: '100%', ...chatNameInputStyle }}
                value={chatName}
                onChange={chatNameChangeHandler}
              />
            </>
          )}
        </div>

        <div className={classes['horizontal-line']}></div>

        <div className={classes['group-members-container']}>
          <div className={classes['group-members-arrow']}>
            <p className={classes['group-members-text']}>Group Members</p>
            <img
              alt="Arrow"
              src={showGroupMembers ? ArrowUpIcon : ArrowDownIcon}
              width={10}
              style={{ cursor: 'pointer' }}
              onClick={() => setShowGroupMembers((prevState) => !prevState)}
            />
          </div>

          {showGroupMembers && (
            <div className={classes['group-members']}>
              <div className={classes['member']}>
                <img
                  alt={profile?.fullName}
                  src={profile?.photo}
                  className={classes['user-image']}
                />
                <div className={classes['fullName-username-container']}>
                  <p className={classes['user-fullName']}>
                    {profile?.fullName}
                  </p>
                  <p className={classes['user-username']}>
                    @{profile?.username}
                  </p>
                </div>
              </div>
              {selectedUsersToJoinTheGroup.map((user) => {
                return (
                  <div key={user._id} className={classes['member']}>
                    <img
                      alt={user.fullName}
                      src={user.photo}
                      className={classes['user-image']}
                    />
                    <div className={classes['fullName-username-container']}>
                      <p className={classes['user-fullName']}>
                        {user.fullName}
                      </p>
                      <p className={classes['user-username']}>
                        @{user.username}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </>
    </Modal>
  );
}

export default ConfirmCreateGroupChatModal;
