import React from 'react';
import Modal from '../../../../../../components/Modal/Modal';

function DescriptionModal(props) {
  const { description, setShowReportDescriptionModal } = props;

  const closeModalHandler = () => {
    setShowReportDescriptionModal(false);
  };

  const renderDescription = () => {
    return description.split('\n').map((newLineDescription, i) => {
      return (
        <h3 key={i} className="thin-text">
          {newLineDescription}
        </h3>
      );
    });
  };

  return (
    <>
      <Modal
        footer={
          <>
            <button onClick={closeModalHandler} className={'btn-white-glimpse'}>
              Close
            </button>
          </>
        }
        closeModal={closeModalHandler}
        title="Report Description"
      >
        {renderDescription()}
      </Modal>
    </>
  );
}

export default DescriptionModal;
