import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import Input from '../../../../components/Input/Input';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import {
  createMessageRequest,
  createSingleChatRequest,
  getMyPaginatedFriendsRequest,
} from '../../../../httpRequests/httpRequests';
import classes from './EmptyChat.module.css';
import PinkRemoveIcon from '../../../../assets/pink-remove.png';
import TextArea from '../../../../components/TextArea/TextArea';
import Button from '../../../../components/Button/Button';
import notify from '../../../../utils/notify';

const RESULTS_PER_PAGE = 10;

function EmptyChat(props) {
  const friendsContainerRef = useRef(null);
  const queryClient = useQueryClient();
  const { myChats, setSelectedChatGroup, setSelectedChatGroupId } = props;

  const [searchInputValue, setSearchInputValue] = useState('');
  const [selectedUserToChat, setSelectedUserToChat] = useState(undefined);
  const [creatingChat, setCreatingChat] = useState(false);
  const [showFriendsContainer, setShowFriendsContainer] = useState(false);

  const [message, setMessage] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-paginated-friends', searchInputValue],
    ({ pageParam = 1 }) =>
      getMyPaginatedFriendsRequest(
        pageParam,
        RESULTS_PER_PAGE,
        searchInputValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 200);
  }, [error]);

  useEffect(() => {
    const friendsContainerElement = friendsContainerRef.current;

    if (!friendsContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    friendsContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      friendsContainerElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  const inputChangeHandler = (e) => {
    setShowFriendsContainer(true);
    setSearchInputValue(e.target.value);
  };

  const selectUserToChatHandler = (selectedUser) => {
    const myDirectChats = myChats.filter((chat) => chat.users.length === 2);

    const existingDirectChatWithSelectedUserIndex = myDirectChats.findIndex(
      (chat) => {
        return chat.users.some((user) => user._id === selectedUser._id);
      }
    );

    if (existingDirectChatWithSelectedUserIndex !== -1) {
      setSelectedChatGroup(
        myDirectChats[existingDirectChatWithSelectedUserIndex]
      );
      setSelectedChatGroupId(
        myDirectChats[existingDirectChatWithSelectedUserIndex]._id
      );
    } else {
      setSelectedUserToChat(selectedUser);
    }
  };

  const removeSelectedUserToChatHandler = () => {
    setSelectedUserToChat(undefined);
  };

  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
  };

  const sendMessageHandler = async () => {
    setCreatingChat(true);
    try {
      const { existingChat, data: chat } = await createSingleChatRequest(
        selectedUserToChat._id
      );

      if (existingChat) {
        setSelectedChatGroup(chat);
        setSelectedChatGroupId(chat._id);
      } else {
        const requestBody = {
          message,
        };
        await createMessageRequest(chat._id, requestBody);
        await Promise.all([
          queryClient.invalidateQueries('my-chats'),
          queryClient.refetchQueries('my-chats'),
        ]);

        setSelectedChatGroup(chat);
        setSelectedChatGroupId(chat._id);
      }
    } catch (error) {
      notify('error', error, 2500);
    }

    setCreatingChat(false);
  };

  return (
    <div className={classes['empty-chat-container']}>
      <div className={classes['input-users-container']}>
        <p className={classes['to-text']}>To:</p>

        {selectedUserToChat && (
          <div className={classes['selected-user-container']}>
            <p className={classes['selected-user-text']}>
              {selectedUserToChat.fullName}
            </p>
            <div className={classes['remove-icon-container']}>
              <img
                alt="Remove"
                className={classes['remove-icon']}
                onClick={removeSelectedUserToChatHandler}
                src={PinkRemoveIcon}
                width={8}
                style={{ cursor: 'pointer', zIndex: 10 }}
              />
            </div>{' '}
          </div>
        )}
        {!selectedUserToChat && (
          <Input
            value={searchInputValue}
            onChange={inputChangeHandler}
            style={{ width: '100%', background: 'transparent' }}
          />
        )}

        {!selectedUserToChat && showFriendsContainer && (
          <div
            ref={friendsContainerRef}
            className={classes['friends-container']}
          >
            {data && data.pages && data.pages[0].results === 0 && (
              <p className={classes['no-users-found']}>No Users Found</p>
            )}
            {data &&
              data.pages &&
              data.pages.map((page) => {
                return page.data.map(({ follower }) => {
                  return (
                    <div
                      key={follower._id}
                      onClick={() => selectUserToChatHandler(follower)}
                      className={classes['friend-container']}
                    >
                      <img
                        alt={follower.fullName}
                        className={classes['user-image']}
                        src={follower.photo}
                        width={40}
                        height={40}
                      />
                      <p className={classes['follower-fullName']}>
                        {follower.fullName}
                      </p>
                    </div>
                  );
                });
              })}
            {(isLoading || isFetching) && <LoadingSpinner />}
          </div>
        )}
      </div>

      <div className={classes['horizontal-line']}></div>

      {selectedUserToChat && (
        <div className={classes['selected-user-information']}>
          <img
            alt={selectedUserToChat.fullName}
            src={selectedUserToChat.photo}
            className={classes['selected-user-photo']}
          />
          <h3 className={classes['selected-user-fullName']}>
            {selectedUserToChat.fullName}
          </h3>

          <p onClick={classes['start-message']}>Start your first message</p>
        </div>
      )}

      {selectedUserToChat && (
        <div className={classes['input-send-container']}>
          <TextArea
            value={message}
            onChange={messageChangeHandler}
            placeholder="Type message"
          />

          <Button
            onClick={sendMessageHandler}
            disabled={!message || creatingChat}
            style={{ padding: '0.7rem 1.5rem' }}
            pinkgraydisabled="true"
          >
            Send
          </Button>
        </div>
      )}
    </div>
  );
}

export default EmptyChat;
