import React, { useContext, useEffect } from 'react';
import classes from './MetaMaskNfts.module.css';
import { MetaMaskContext } from '../../../../context/metamask-context';
import { getAllNftsForASpecificMetaMaskWalletAddressRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { useInfiniteQuery } from 'react-query';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import MetaMaskNft from '../../../../components/MetaMaskNft/MetaMaskNft';
import NoMetaMaskNftsImage from '../../../../assets/no-results.png';

const RESULTS_PER_PAGE = 10;

function MetaMaskNfts() {
  const { connectedWalletAddress } = useContext(MetaMaskContext);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['metamask-nfts', connectedWalletAddress],
    ({ pageParam = 1 }) =>
      getAllNftsForASpecificMetaMaskWalletAddressRequest(
        connectedWalletAddress,
        pageParam,
        RESULTS_PER_PAGE
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <>
      {connectedWalletAddress && (
        <div className={classes['metamask-nfts-container']}>
          {data?.pages && data.pages[0].results === 0 && (
            <div className={classes['no-nfts-container']}>
              <img height={200} src={NoMetaMaskNftsImage} alt="No NFTs Found" />
              <h3 style={{ margin: 10 }}>No Metamask NFTs Found!</h3>
            </div>
          )}

          <div className={classes['nft-list']}>
            {data?.pages &&
              data.pages.map((page) => {
                return page.data.map(
                  (
                    {
                      imageUrl,
                      chain,
                      collectionAddress,
                      collectionName,
                      name,
                    },
                    i
                  ) => {
                    if (imageUrl && imageUrl.startsWith('https://'))
                      return (
                        <MetaMaskNft
                          key={i}
                          imageUrl={imageUrl}
                          chain={chain}
                          collectionAddress={collectionAddress}
                          collectionName={collectionName}
                          name={name}
                        />
                      );

                    return null;
                  }
                );
              })}
          </div>
          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      )}
    </>
  );
}

export default MetaMaskNfts;
