import React, { useState } from 'react';
import {
  createCategoryNftRequest,
  createSubCategoryNftRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './CategoriesAndSubCategories.module.css';
import CategoriesAndSubCategoriesTable from './components/CategoriesAndSubCategoriesTable/CategoriesAndSubCategoriesTable';

const inputInitialState = {
  value: '',
  touched: false,
  valid: false,
};

function CategoriesAndSubCategories() {
  const [category, setCategory] = useState({ ...inputInitialState });
  const [subCategories, setSubCategories] = useState([
    { ...inputInitialState },
  ]);
  const [
    createCategoryAndSubCategoriesLoading,
    setCreateCategoryAndSubCategoriesLoading,
  ] = useState(false);

  const inputCategoryChangeHandler = (e) => {
    const inputValue = e.target.value;

    setCategory({
      value: inputValue,
      touched: true,
      valid: inputValue.length > 0,
    });
  };

  const subCategoryInputChangeHandler = (index, value) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories[index] = {
      value,
      touched: true,
      valid: value.length > 0,
    };

    setSubCategories(updatedSubCategories);
  };

  const removeSubCategoryHandler = (index) => {
    setSubCategories((prevSubCategories) =>
      prevSubCategories.filter((_, i) => i !== index)
    );
  };

  const renderSubCategoriesForm = () => {
    return subCategories.map((subCategory, i) => {
      const subCategoryStyle = {};
      if (subCategory.valid === false && subCategory.touched)
        subCategoryStyle['border'] = '1px solid red';

      return (
        <div key={subCategory}>
          <input
            style={subCategoryStyle}
            className="searchInput"
            placeholder="Sub Category"
            value={subCategory.value}
            onChange={(e) => subCategoryInputChangeHandler(i, e.target.value)}
          />

          {i !== 0 && (
            <button
              onClick={() => removeSubCategoryHandler(i)}
              style={{ marginLeft: 10 }}
              className="cancel-button"
            >
              Remove
            </button>
          )}
        </div>
      );
    });
  };

  const createCategoryAndCorrespondingSubCategoriesHandler = async () => {
    try {
      setCreateCategoryAndSubCategoriesLoading(true);

      const createCategoryRequestBody = {
        name: category.value,
      };
      const { data: createdCategory } = await createCategoryNftRequest(
        createCategoryRequestBody
      );

      const createSubCategoriesPromise = subCategories.map((subCategory) => {
        const requestBody = { name: subCategory.value };
        return createSubCategoryNftRequest(createdCategory._id, requestBody);
      });
      await Promise.all(createSubCategoriesPromise);
      setCategory({ ...inputInitialState });
      setSubCategories([{ ...inputInitialState }]);
    } catch (error) {
      notify('error', error, 2000);
    }

    setCreateCategoryAndSubCategoriesLoading(false);
  };

  const addNewSubCategoryHandler = () => {
    setSubCategories((prevSubCategories) => [
      ...prevSubCategories,
      { ...inputInitialState },
    ]);
  };

  const areAllSubCategoriesValid = subCategories.every(
    (subCategory) => subCategory.valid
  );

  const isCreateButtonValid =
    category.valid &&
    subCategories.every(
      (subCategory) => subCategory.valid && subCategory.value !== ''
    );

  const categoryStyle = {};
  if (category.valid === false && category.touched === true) {
    categoryStyle['border'] = '1px solid red';
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <div className={classes['categories-subCategories-container']}>
        <h2 className={classes['heading']}>
          Create new Category & Sub Categories
        </h2>
        <div>
          <input
            style={categoryStyle}
            className="searchInput"
            placeholder="Category"
            value={category.value}
            onChange={inputCategoryChangeHandler}
          />
        </div>
        <div>{renderSubCategoriesForm()}</div>
        <div>
          <button
            disabled={!areAllSubCategoriesValid}
            className={
              areAllSubCategoriesValid ? 'btn-white-glimpse' : 'btn-disabled'
            }
            onClick={addNewSubCategoryHandler}
          >
            Add New Sub Category
          </button>
        </div>

        <div style={{ textAlign: 'right' }}>
          <button
            disabled={
              !isCreateButtonValid || createCategoryAndSubCategoriesLoading
            }
            className={
              !isCreateButtonValid || createCategoryAndSubCategoriesLoading
                ? 'btn-disabled'
                : 'btn-pink-glimpse'
            }
            onClick={createCategoryAndCorrespondingSubCategoriesHandler}
          >
            {createCategoryAndSubCategoriesLoading ? 'Creating...' : 'Create'}
          </button>
        </div>
      </div>

      <CategoriesAndSubCategoriesTable />
    </div>
  );
}

export default CategoriesAndSubCategories;
