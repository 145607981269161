import axios from 'axios';
import React, { useCallback, useState, useEffect } from 'react';
import {
  getMyProfileRequest,
  loginRequest,
  signupRequest,
  getMyFollowingsRequest,
  getMyFollowersRequest,
  removeFollowerRequest,
  unfollowUserRequest,
  getMyNftsRequest,
  getMyWatchlistRequest,
  getMyLikesRequest,
  getNumberOfUnreadMessagesForMyChatsRequest,
  getUserTokenViaGithubRequest,
  loginViaGoogleRequest,
  getNumberOfUnreadNotificationsRequest,
  getWalletInformationRequest,
  loginViaFacebookRequest,
  getMyLockedGLMSBalanceRequest,
  getNumberOfUnreadGroupMessagesForMyGroupsRequest,
  signupWithMetaMaskRequest,
  loginWithMetaMaskRequest,
  getWyreWalletInformationRequest,
  getWyreKycStatusRequest,
  signUpWithUnstoppableDomainsRequest,
  loginWithUnstoppableRequest,
  checkForWinningPointsAfterAutoLoginRequest,
  doIHaveAccessToCreateNftCollectionOrCommunityRequest,
  getMyAccessibilityInformationRequest,
} from '../httpRequests/httpRequests';

const authContext = {
  profile: null,
  autoLoginLoading: true,
  myFollowings: [],
  myFollowingsIds: {},
  myFollowers: [],
  myNftsAsOwner: [],
  myNftsAsAuthor: [],
  myWatchlist: [],
  myLikes: [],
  numberOfUnreadMessagesForMyChats: 0,
  numberOfUnreadGroupMessagesForMyGroups: 0,
  numberOfUnreadNotifications: 0,
  myWalletInformation: null,
  myLockedGLMSBalance: null,
  myWyreWalletInformation: null,
  myWyreKycStatus: null,
  myNftCollectionAccessToCreate: false,
  myCommunityAccessToCreate: false,
  myAccessibilityInformation: {},
  signup: (
    _email,
    _firstName,
    _lastName,
    _password,
    _username,
    _referredBy
  ) => {},
  signupWithMetaMask: ({
    metaMaskPersonalSignature,
    firstName,
    lastName,
    email,
    username,
    metaMaskWalletAddress,
    referredBy,
  }) => {},
  signupWithUnstoppable: ({
    nonce,
    rawToken,
    domainName,
    unstoppableWalletAddress,
    unstoppableEmail,
  }) => {},
  login: (_email, _password) => {},
  loginWithMetaMask: (_metaMaskPersonalSignature, _metaMaskWalletAddress) => {},
  loginWithUnstoppable: ({ nonce, rawToken, domainName }) => {},
  loginViaGithub: (_githubId) => {},
  loginViaGoogle: (_accessToken, _referredBy) => {},
  loginViaFacebook: (_accessToken, _referredBy) => {},
  logout: () => {},
  getMyProfile: () => {},
  updateProfileInformation: (_updatedProfileInformation) => {},
  loginViaResetPassword: (_token) => {},
  removeFollower: (_followerId) => {},
  unfollowUser: (_followingId) => {},
  getMyFollowings: () => {},
  getMyFollowers: () => {},
  getMyNfts: () => {},
  getMyWatchlist: () => {},
  getMyLikes: () => {},
  getNumberOfUnreadMessagesForMyChats: () => {},
  getNumberOfUnreadGroupMessagesForMyGroups: () => {},
  getNumberOfUnreadNotifications: () => {},
  getMyLockedGLMSBalance: () => {},
  getMyWyreWalletInformation: () => {},
  getMyWyreKycStatus: () => {},
};

export const AuthContext = React.createContext(authContext);

const AuthContextProvider = (props) => {
  const [profile, setProfile] = useState(null);
  const [autoLoginLoading, setAutoLoginLoading] = useState(true);
  const [myFollowings, setMyFollowings] = useState([]);
  const [myFollowingsIds, setMyFollowingsIds] = useState({});
  const [myFollowers, setMyFollowers] = useState([]);
  const [myNftsAsOwner, setMyNftsAsOwner] = useState([]);
  const [myNftsAsAuthor, setMyNftsAsAuthor] = useState([]);
  const [myWatchlist, setMyWatchlist] = useState([]);
  const [myLikes, setMyLikes] = useState([]);
  const [
    numberOfUnreadMessagesForMyChats,
    setNumberOfUnreadMessagesForMyChats,
  ] = useState(0);
  const [
    numberOfUnreadGroupMessagesForMyGroups,
    setNumberOfUnreadGroupMessagesForMyGroups,
  ] = useState(0);
  const [
    numberOfUnreadNotifications,
    setNumberOfUnreadNotifications,
  ] = useState(0);
  const [myWalletInformation, setMyWalletInformation] = useState(null);
  const [myLockedGLMSBalance, setMyLockedGLMSBalance] = useState(null);
  const [myWyreWalletInformation, setMyWyreWalletInformation] = useState(null);
  const [myWyreKycStatus, setMyWyreKycStatus] = useState(null);
  const [
    myNftCollectionAccessToCreate,
    setMyNftCollectionAccessToCreate,
  ] = useState(false);
  const [myCommunityAccessToCreate, setMyCommunityAccessToCreate] = useState(
    false
  );
  const [myAccessibilityInformation, setMyAccessibilityInformation] = useState(
    {}
  );

  const setAuthorizationHeadersOnAxios = (token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  const saveTokenOnLocalStorage = (token) => {
    localStorage.setItem('jwt', token);
  };

  const removeTokenFromAxiosHeaders = () => {
    delete axios.defaults.headers.common['Authorization'];
  };

  const removeTokenFromLocalStorage = () => {
    localStorage.removeItem('jwt');
  };

  const getMyData = async () => {
    getNumberOfUnreadMessagesForMyChats();
    getNumberOfUnreadGroupMessagesForMyGroups();
    getMyWalletInformation();
    getMyWyreWalletInformation();
    getMyWyreKycStatus();
    doIHaveAccessToCreateNftCollectionOrCommunityHandler();

    await Promise.all([
      getMyFollowings(),
      getMyFollowers(),
      getMyProfile(),
      getMyNfts(),
      getMyWatchlist(),
      getMyLikes(),
      getNumberOfUnreadNotifications(),
      getMyLockedGLMSBalance(),
      getMyAccessibilityInformation(),
    ]);
  };

  const loginViaGoogle = async (accessToken, referredBy) => {
    try {
      const requestBody = {
        referredBy,
      };
      const { token } = await loginViaGoogleRequest(accessToken, requestBody);
      saveTokenOnLocalStorage(token);
      setAuthorizationHeadersOnAxios(token);

      await getMyData();
    } catch (error) {
      throw error;
    }
  };

  const loginViaFacebook = async (accessToken, referredBy) => {
    try {
      const requestBody = {
        referredBy,
      };
      const { token } = await loginViaFacebookRequest(accessToken, requestBody);
      saveTokenOnLocalStorage(token);
      setAuthorizationHeadersOnAxios(token);

      await getMyData();
    } catch (error) {
      throw error;
    }
  };

  const loginViaGithub = async (githubId) => {
    try {
      setAutoLoginLoading(true);
      const { token } = await getUserTokenViaGithubRequest(githubId);
      saveTokenOnLocalStorage(token);
      setAuthorizationHeadersOnAxios(token);

      await getMyData();

      setAutoLoginLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const autoLogin = useCallback(async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      setAutoLoginLoading(false);
      return;
    }

    setAuthorizationHeadersOnAxios(token);

    checkForWinningPointsAfterAutoLoginRequest();
    try {
      await getMyData();
      setAutoLoginLoading(false);
    } catch (error) {
      removeTokenFromLocalStorage();
      setAutoLoginLoading(false);
      setTimeout(() => {
        window.location.href = '/';
      }, 0);
    }
    // eslint-disable-next-line
  }, []);

  const loginViaResetPassword = async (token) => {
    try {
      setAuthorizationHeadersOnAxios(token);
      saveTokenOnLocalStorage(token);
      await autoLogin();
    } catch (err) {
      throw err.response.data.message;
    }
  };

  useEffect(() => {
    autoLogin();
  }, [autoLogin]);

  const getMyProfile = async () => {
    try {
      const responseData = await getMyProfileRequest();

      const amIBanned = responseData.user.banned;
      if (amIBanned) {
        return logout();
      }

      setProfile({ ...responseData.user });
    } catch (error) {
      throw error;
    }
  };

  const getMyFollowings = async () => {
    try {
      const { data } = await getMyFollowingsRequest();
      setMyFollowings(data);

      const updatedMyFollowingsIds = {};
      data.forEach(({ following }) => {
        updatedMyFollowingsIds[following._id] = following;
      });
      setMyFollowingsIds(updatedMyFollowingsIds);
    } catch (error) {
      throw error;
    }
  };

  const getMyFollowers = async () => {
    try {
      const { data } = await getMyFollowersRequest();
      setMyFollowers(data);
    } catch (error) {
      throw error;
    }
  };

  const removeFollower = async (followerId) => {
    try {
      await removeFollowerRequest(followerId);
      setMyFollowers((prevFollowers) =>
        prevFollowers.filter(({ follower }) => follower._id !== followerId)
      );
    } catch (error) {
      throw error;
    }
  };

  const unfollowUser = async (followingId) => {
    try {
      await unfollowUserRequest(followingId);
      setMyFollowings((prevFollowings) =>
        prevFollowings.filter(({ following }) => following._id !== followingId)
      );

      const updatedMyFollowingsIds = { ...myFollowingsIds };
      delete updatedMyFollowingsIds[followingId];
      setMyFollowingsIds(updatedMyFollowingsIds);
    } catch (error) {
      throw error;
    }
  };

  const getMyNfts = async () => {
    try {
      const responseData = await getMyNftsRequest();
      setMyNftsAsAuthor(responseData.author);
      setMyNftsAsOwner(responseData.owner);
    } catch (error) {
      throw error;
    }
  };

  const getMyWatchlist = async () => {
    try {
      const { data } = await getMyWatchlistRequest();
      setMyWatchlist(data);
    } catch (error) {
      throw error;
    }
  };

  const getMyLikes = async () => {
    try {
      const { data } = await getMyLikesRequest();
      setMyLikes(data);
    } catch (error) {
      throw error;
    }
  };

  const getNumberOfUnreadNotifications = async () => {
    try {
      const { data } = await getNumberOfUnreadNotificationsRequest();
      setNumberOfUnreadNotifications(data);
    } catch (error) {
      throw error;
    }
  };

  const getMyWalletInformation = async () => {
    try {
      const { data } = await getWalletInformationRequest();
      setMyWalletInformation(data);
    } catch (error) {
      throw error;
    }
  };

  const getMyLockedGLMSBalance = async () => {
    try {
      const {
        myPendingOffersSum,
        myPendingNftRequestsFeesSum,
        data,
      } = await getMyLockedGLMSBalanceRequest();

      setMyLockedGLMSBalance({
        myPendingOffersSum,
        myPendingNftRequestsFeesSum,
        total: data,
      });
    } catch (error) {
      throw error;
    }
  };

  const getMyWyreWalletInformation = async () => {
    try {
      const { data } = await getWyreWalletInformationRequest();

      setMyWyreWalletInformation({
        availableBalances: data.availableBalances,
        balances: data.balances,
        depositAddresses: data.depositAddresses,
        srn: data.srn,
        id: data.id,
        totalBalances: data.totalBalances,
      });
    } catch (err) {
      throw err;
    }
  };

  const getMyWyreKycStatus = async () => {
    try {
      const { data } = await getWyreKycStatusRequest();

      setMyWyreKycStatus(data);
    } catch (err) {
      throw err;
    }
  };

  const getNumberOfUnreadMessagesForMyChats = async () => {
    try {
      const { data } = await getNumberOfUnreadMessagesForMyChatsRequest();
      setNumberOfUnreadMessagesForMyChats(data);
    } catch (error) {
      throw error;
    }
  };

  const getNumberOfUnreadGroupMessagesForMyGroups = async () => {
    try {
      const { data } = await getNumberOfUnreadGroupMessagesForMyGroupsRequest();
      setNumberOfUnreadGroupMessagesForMyGroups(data);
    } catch (error) {
      throw error;
    }
  };

  const doIHaveAccessToCreateNftCollectionOrCommunityHandler = async () => {
    try {
      const {
        data,
      } = await doIHaveAccessToCreateNftCollectionOrCommunityRequest();
      const { accessToCreateCommunity, accessToCreateNftCollection } = data;
      setMyNftCollectionAccessToCreate(accessToCreateNftCollection);
      setMyCommunityAccessToCreate(accessToCreateCommunity);
    } catch (error) {
      throw error;
    }
  };

  const getMyAccessibilityInformation = async () => {
    try {
      const { data } = await getMyAccessibilityInformationRequest();
      setMyAccessibilityInformation(data);
    } catch (error) {
      throw error;
    }
  };

  const signup = async (
    email,
    firstName,
    lastName,
    password,
    username,
    referredBy
  ) => {
    try {
      const requestBody = { email, firstName, lastName, password, username };
      return signupRequest(requestBody, referredBy);
    } catch (err) {
      throw err;
    }
  };

  const signupWithMetaMask = async ({
    metaMaskPersonalSignature,
    firstName,
    lastName,
    email,
    username,
    metaMaskWalletAddress,
    referredBy,
  }) => {
    try {
      const requestBody = {
        metaMaskPersonalSignature,
        firstName,
        lastName,
        email,
        username,
        metaMaskWalletAddress,
        referredBy,
      };

      const { token } = await signupWithMetaMaskRequest(
        requestBody,
        referredBy
      );
      saveTokenOnLocalStorage(token);
      setAuthorizationHeadersOnAxios(token);

      await getMyData();
    } catch (err) {
      throw err;
    }
  };

  const signupWithUnstoppable = async ({
    nonce,
    rawToken,
    domainName,
    unstoppableWalletAddress,
    unstoppableEmail,
  }) => {
    try {
      const requestBody = {
        nonce,
        rawToken,
        domainName,
        unstoppableWalletAddress,
        unstoppableEmail,
      };

      const { token } = await signUpWithUnstoppableDomainsRequest(requestBody);

      saveTokenOnLocalStorage(token);
      setAuthorizationHeadersOnAxios(token);

      await getMyData();
    } catch (err) {
      throw err;
    }
  };

  const login = async (email, password) => {
    try {
      const requestBody = { email, password };
      const responseData = await loginRequest(requestBody);
      const { Token } = responseData;

      setAuthorizationHeadersOnAxios(Token);
      saveTokenOnLocalStorage(Token);

      await getMyData();
    } catch (error) {
      throw error;
    }
  };

  const loginWithMetaMask = async (
    metaMaskPersonalSignature,
    metaMaskWalletAddress
  ) => {
    try {
      const requestBody = {
        metaMaskPersonalSignature,
        metaMaskWalletAddress,
      };
      const { token } = await loginWithMetaMaskRequest(requestBody);
      saveTokenOnLocalStorage(token);
      setAuthorizationHeadersOnAxios(token);

      await getMyData();
    } catch (err) {
      throw err;
    }
  };

  const loginWithUnstoppable = async ({ nonce, rawToken, domainName }) => {
    try {
      const requestBody = {
        nonce,
        rawToken,
        domainName,
      };
      const { token } = await loginWithUnstoppableRequest(requestBody);

      saveTokenOnLocalStorage(token);
      setAuthorizationHeadersOnAxios(token);

      await getMyData();
    } catch (err) {
      throw err;
    }
  };

  const logout = () => {
    removeTokenFromAxiosHeaders();
    removeTokenFromLocalStorage();
    setProfile(null);
  };

  const updateProfileInformation = (updatedProfileInformation) => {
    setProfile(updatedProfileInformation);
  };

  return (
    <AuthContext.Provider
      value={{
        signup,
        signupWithMetaMask,
        signupWithUnstoppable,
        login,
        loginWithMetaMask,
        loginWithUnstoppable,
        logout,
        profile,
        autoLoginLoading,
        getMyProfile,
        updateProfileInformation,
        loginViaResetPassword,
        myFollowings,
        myFollowingsIds,
        myFollowers,
        myNftsAsAuthor,
        myNftsAsOwner,
        myWatchlist,
        myLikes,
        myWalletInformation,
        myLockedGLMSBalance,
        myWyreWalletInformation,
        myWyreKycStatus,
        myNftCollectionAccessToCreate,
        myCommunityAccessToCreate,
        numberOfUnreadMessagesForMyChats,
        numberOfUnreadGroupMessagesForMyGroups,
        numberOfUnreadNotifications,
        myAccessibilityInformation,
        getNumberOfUnreadMessagesForMyChats,
        getNumberOfUnreadGroupMessagesForMyGroups,
        removeFollower,
        unfollowUser,
        getMyFollowings,
        getMyFollowers,
        getMyNfts,
        getMyWatchlist,
        getMyLikes,
        getMyWalletInformation,
        getMyLockedGLMSBalance,
        getNumberOfUnreadNotifications,
        getMyWyreWalletInformation,
        getMyWyreKycStatus,
        loginViaGithub,
        loginViaGoogle,
        loginViaFacebook,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
