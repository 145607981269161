import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import Button from '../../../../../../components/Button/Button';
import Input from '../../../../../../components/Input/Input';
import Modal from '../../../../../../components/Modal/Modal';
import { updateGroupChannelNameRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import classes from './EditChannelModal.module.css';

function EditChannelModal(props) {
  const queryClient = useQueryClient();
  const {
    setShowEditChannelModal,
    channelId,
    groupId,
    setSelectedChannelName,
  } = props;

  const [channelName, setChannelName] = useState(props.channelName || '');

  const closeModalHandler = () => {
    setShowEditChannelModal(false);
  };

  const channelNameChangeHandler = (e) => {
    setChannelName(e.target.value);
  };

  const updateChannelHandler = async () => {
    try {
      const requestBody = { name: channelName };
      await updateGroupChannelNameRequest(groupId, channelId, requestBody);

      await Promise.all([
        queryClient.invalidateQueries(['group-channels', groupId]),
        queryClient.refetchQueries(['group-channels', groupId]),
      ]);

      setSelectedChannelName();

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const channelNameInputStyle = {};
  if (!channelName) {
    channelNameInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Edit Channel"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            onClick={updateChannelHandler}
            disabled={!channelName}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
    >
      <div>
        <p className={classes['label']}>Channel Name</p>
        <Input
          style={{ width: '100%', ...channelNameInputStyle }}
          placeholder="Enter here"
          value={channelName}
          onChange={channelNameChangeHandler}
        />
      </div>
    </Modal>
  );
}

export default EditChannelModal;
