import React, { useEffect } from 'react';
import classes from './ReceivedOffers.module.css';
import { useInfiniteQuery } from 'react-query';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import { getMyReceivedNftOffersRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import ReceivedNftOffer from '../../../../components/ReceivedNftOffer/ReceivedNftOffer';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

const RESULTS_PER_PAGE = 6;

function ReceivedOffers(props) {
  const { searchValue, sortBy } = props;

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-received-nft-offers', searchValue, sortBy],
    ({ pageParam = 1 }) =>
      getMyReceivedNftOffersRequest(
        pageParam,
        RESULTS_PER_PAGE,
        searchValue,
        sortBy
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <>
      <div className={classes['nft-offers-list']}>
        {!isLoading && data && data?.pages[0].results === 0 && (
          <div
            style={{
              background: 'white',
              padding: '.8rem',
              borderRadius: 10,
              marginTop: 10,
              width: '95%',
              gridColumn: '1/-1',
            }}
          >
            <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
              No Offers Found!
            </p>
          </div>
        )}

        {!isLoading &&
          data?.pages &&
          data.pages.map((page) => {
            return page.data.map((nftOffer) => {
              return (
                <ReceivedNftOffer key={nftOffer._id} nftOffer={nftOffer} />
              );
            });
          })}
      </div>
      {(isLoading || isFetching) && <LoadingSpinner />}
    </>
  );
}

export default ReceivedOffers;
