import React, { useEffect, useState, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import ChatInfoIcon from '../../../../../../assets/chat-info.png';
import {
  deleteGroupChannelRequest,
  getAllMembersForASpecificPrivateGroupChannelRequest,
  inviteUsersToGroupChannelRequest,
} from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import classes from './PrivateChannelHeader.module.css';
import PrivateChannelMembersModal from '../PrivateChannelMembersModal/PrivateChannelMembersModal';
import MoreIcon from '../../../../../../assets/more.png';
import EditChannelModal from '../EditChannelModal/EditChannelModal';
import InviteUsersToPrivateChannelModal from '../InviteUsersToPrivateChannelModal/InviteUsersToPrivateChannelModal';
import RemovePrivateChannelMembersModal from '../RemovePrivateChannelMembersModal/RemovePrivateChannelMembersModal';
import ConfirmDeleteChannelModal from '../ConfirmDeleteChannelModal/ConfirmDeleteChannelModal';

function PrivateChannelHeader(props) {
  const {
    groupId,
    channelName,
    channelId,
    setSelectedChannelName,
    isMyRoleAdmin,
    setIsSelectedChannelPrivate,
    setSelectedChannelId,
  } = props;
  const moreIconRef = useRef();
  const queryClient = useQueryClient();
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showEditChannelModal, setShowEditChannelModal] = useState(false);
  const [
    showInviteUsersToPrivateChannelModal,
    setShowInviteUsersToPrivateChannelModal,
  ] = useState(false);

  const [
    showPrivateChannelMembersModal,
    setShowPrivateChannelMembersModal,
  ] = useState(false);
  const [
    showRemovePrivateChannelMembersModal,
    setShowRemovePrivateChannelMembersModal,
  ] = useState(false);

  const [
    showConfirmDeleteChannelModal,
    setShowConfirmDeleteChannelModal,
  ] = useState(false);

  const { data, error } = useQuery(
    ['private-channel-group-members', channelId, groupId, ''],
    () =>
      getAllMembersForASpecificPrivateGroupChannelRequest(
        groupId,
        channelId,
        1,
        5,
        ''
      )
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    const checkForClosingMoreOptionsHandler = (e) => {
      if (e.target !== moreIconRef.current) {
        setShowMoreOptions(false);
      }
    };

    window.addEventListener('click', checkForClosingMoreOptionsHandler);

    return () =>
      window.removeEventListener('click', checkForClosingMoreOptionsHandler);
  }, [moreIconRef]);

  const openPrivateChannelMembersModalHandler = () => {
    setShowPrivateChannelMembersModal(true);
  };

  const toggleMoreOptions = () => {
    setShowMoreOptions((prevState) => !prevState);
  };

  const openEditChannelModalHandler = () => {
    setShowEditChannelModal(true);
  };

  const deleteChannelHandler = async () => {
    try {
      await deleteGroupChannelRequest(groupId, channelId);

      await Promise.all([
        queryClient.invalidateQueries(['group-channels', groupId]),
        queryClient.refetchQueries(['group-channels', groupId]),
      ]);
      setSelectedChannelId();
      setSelectedChannelName(undefined);
      setIsSelectedChannelPrivate(false);
    } catch (err) {
      throw err;
    }
  };

  const openConfirmDeleteChannelModalHandler = () => {
    setShowConfirmDeleteChannelModal(true);
  };

  const openInviteUsersToPrivateChannelModalHandler = () => {
    setShowInviteUsersToPrivateChannelModal(true);
  };

  const openRemovePrivateChannelMembersModalHandler = () => {
    setShowRemovePrivateChannelMembersModal(true);
  };

  const inviteUsersToPrivateChannelHandler = async (
    invitedMembers,
    closeModalHandler
  ) => {
    try {
      const requestBody = {
        name: channelName,
        userIds: Object.keys(invitedMembers),
      };
      await inviteUsersToGroupChannelRequest(groupId, channelId, requestBody);

      closeModalHandler?.();
      notify('success', 'Invitation sent successfully!', 2000);
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  return (
    <>
      {showPrivateChannelMembersModal && (
        <PrivateChannelMembersModal
          groupId={groupId}
          channelId={channelId}
          setShowPrivateChannelMembersModal={setShowPrivateChannelMembersModal}
        />
      )}

      {showEditChannelModal && (
        <EditChannelModal
          setSelectedChannelName={setSelectedChannelName}
          groupId={groupId}
          channelId={channelId}
          channelName={channelName}
          setShowEditChannelModal={setShowEditChannelModal}
        />
      )}

      {showInviteUsersToPrivateChannelModal && (
        <InviteUsersToPrivateChannelModal
          inviteHandler={inviteUsersToPrivateChannelHandler}
          groupId={groupId}
          privateChannelId={channelId}
          setShowInviteUsersToPrivateChannelModal={
            setShowInviteUsersToPrivateChannelModal
          }
        />
      )}

      {showRemovePrivateChannelMembersModal && (
        <RemovePrivateChannelMembersModal
          groupId={groupId}
          privateChannelId={channelId}
          setShowRemovePrivateChannelMembersModal={
            setShowRemovePrivateChannelMembersModal
          }
        />
      )}

      {showConfirmDeleteChannelModal && (
        <ConfirmDeleteChannelModal
          setShowConfirmDeleteChannelModal={setShowConfirmDeleteChannelModal}
          deleteChannelHandler={deleteChannelHandler}
        />
      )}

      <div className={classes['header']}>
        <div className={classes['members-list']}>
          <h3 className={classes['channel-name']}>#{channelName}</h3>
          {data &&
            data.data &&
            data.data.map(({ user }) => {
              return (
                <img
                  key={user._id}
                  src={user.photo}
                  className={classes['user-image']}
                  alt="Member"
                />
              );
            })}
        </div>

        <div style={{ position: 'relative' }}>
          <img
            onClick={openPrivateChannelMembersModalHandler}
            alt="Info"
            width={18}
            height={18}
            src={ChatInfoIcon}
            className={classes['info-icon']}
          />
          {isMyRoleAdmin && (
            <img
              ref={moreIconRef}
              style={{ cursor: 'pointer', marginLeft: 20 }}
              alt="More"
              onClick={toggleMoreOptions}
              src={MoreIcon}
              height={20}
            />
          )}
          {showMoreOptions && (
            <div className={classes['more-options']}>
              <p
                className={classes['option']}
                onClick={openEditChannelModalHandler}
              >
                Edit Channel
              </p>

              <p
                className={classes['option']}
                onClick={openInviteUsersToPrivateChannelModalHandler}
              >
                Invite Users
              </p>

              <p
                onClick={openRemovePrivateChannelMembersModalHandler}
                className={classes['delete']}
              >
                Remove Members
              </p>

              <p
                onClick={openConfirmDeleteChannelModalHandler}
                className={classes['delete']}
              >
                Delete Channel
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PrivateChannelHeader;
