import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../../../../../../components/Modal/Modal';
import classes from './TransactionVerificationModal.module.css';
import { verifyAndConfirmTransferGlmsRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import Button from '../../../../../../components/Button/Button';

function TransactionVerificationModal(props) {
  const {
    glmsToTransferValue,
    glmsToTransferInUsd,
    walletAddress,
    setShowTransferModal,
  } = props;
  const [verificationCodeNumbers, setVerificationCodeNumbers] = useState([]);
  const [checkingVerificationCode, setCheckingVerificationCode] = useState(
    false
  );

  const closeModalHandler = () => setShowTransferModal(false);

  const sendVerificationCodeRequest = useCallback(
    async (verificationCode) => {
      setCheckingVerificationCode(true);

      try {
        const requestBody = {
          verificationCode,
          glmsAmount: glmsToTransferValue,
        };

        await verifyAndConfirmTransferGlmsRequest(walletAddress, requestBody);
        notify(
          'success',
          'Transfering started successfully. You will receive a notification when it is finished!',
          2000
        );
        setCheckingVerificationCode(false);
        setShowTransferModal(false);
      } catch (error) {
        setVerificationCodeNumbers([]);
        setCheckingVerificationCode(false);
        notify('error', error, 2000);
      }
    },
    [glmsToTransferValue, walletAddress, setShowTransferModal]
  );

  useEffect(() => {
    const pasteVerificationCodeHandler = ({ clipboardData }) => {
      const verificationCode = clipboardData.getData('Text');
      const splittedVerificationCode = verificationCode.split('');

      setVerificationCodeNumbers(splittedVerificationCode);

      sendVerificationCodeRequest(verificationCode);
    };

    document.addEventListener('paste', pasteVerificationCodeHandler);

    return () =>
      document.removeEventListener('paste', pasteVerificationCodeHandler);
  }, [sendVerificationCodeRequest]);

  useEffect(() => {
    const verificationCodeHandler = async (e) => {
      const key = e.key;

      if (key === 'Backspace' || key === 'Delete') {
        const updatedVerificationCodeNumbers = [...verificationCodeNumbers];
        if (updatedVerificationCodeNumbers.length === 0) return;
        updatedVerificationCodeNumbers.pop();
        return setVerificationCodeNumbers(updatedVerificationCodeNumbers);
      }

      if (isNaN(key)) return;

      const updatedVerificationCodeNumbers = [...verificationCodeNumbers, key];
      setVerificationCodeNumbers(updatedVerificationCodeNumbers);

      if (updatedVerificationCodeNumbers.length === 6) {
        sendVerificationCodeRequest(updatedVerificationCodeNumbers.join(''));
      }
    };

    window.addEventListener('keydown', verificationCodeHandler);

    return () => window.removeEventListener('keydown', verificationCodeHandler);
  }, [sendVerificationCodeRequest, verificationCodeNumbers]);

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            darkpink="true"
            disabled={
              verificationCodeNumbers.length < 6 || checkingVerificationCode
            }
          >
            {checkingVerificationCode ? 'Verifying...' : 'Verify and confirm'}
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Verify Transaction"
    >
      <div>
        <div className={classes['amount-container']}>
          <p className={classes['address-text']}>Address:</p>
          <p className={classes['wallet-address-text']}>{walletAddress}</p>
        </div>

        <div className={classes['amount-container']}>
          <p className={classes['amount-text']}>Amount:</p>
          <p className={classes['glimpse-balance']}>
            {glmsToTransferValue || 0} GLMS
            <span className={classes['glimpse-balance-usd']}>
              ${glmsToTransferInUsd}
            </span>
          </p>
        </div>

        <div>
          <div className={classes['verification-code-container']}>
            {new Array(6).fill(0).map((_, i) => {
              if (verificationCodeNumbers[i])
                return (
                  <div key={i} className={classes['box-number']}>
                    {verificationCodeNumbers[i]}
                  </div>
                );
              else
                return (
                  <div
                    style={{ color: 'rgba(0,0,0,.4)' }}
                    key={i}
                    className={classes['box-number']}
                  >
                    -
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TransactionVerificationModal;
