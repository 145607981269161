import React, { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getUserPaginatedFriendsRequest } from '../../../../httpRequests/httpRequests';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import classes from './Friends.module.css';
import notify from '../../../../utils/notify';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Friend from './components/Friend/Friend';
import { useParams } from 'react-router-dom';

const RESULTS_PER_PAGE = 10;

function Friends() {
  const { userId } = useParams();
  const [searchInputValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['user-paginated-friends', userId, searchInputValue],
    ({ pageParam = 1 }) =>
      getUserPaginatedFriendsRequest(
        userId,
        pageParam,
        RESULTS_PER_PAGE,
        searchInputValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['friends-page-container']}>
      {data && data.pages[0].results === 0 && (
        <div
          style={{
            background: 'white',
            padding: '.8rem',
            borderRadius: 10,
            marginTop: 20,
          }}
        >
          <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
            No Friends Found!
          </p>
        </div>
      )}

      <div className={classes['friends-container']}>
        {data &&
          data.pages.map((page) => {
            return page.data.map(({ follower }) => {
              return (
                <Friend
                  searchInputValue={searchInputValue}
                  friendId={follower._id}
                  follower={follower}
                  key={follower._id}
                />
              );
            });
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default Friends;
