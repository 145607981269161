import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Modal from '../../../../components/Modal/Modal';
import { getMyPaginatedFriendsRequest } from '../../../../httpRequests/httpRequests';
import classes from './CreateGroupChatModal.module.css';
import Button from '../../../../components/Button/Button';
import ConfirmCreateGroupChatModal from './components/ConfirmCreateGroupChatModal/ConfirmCreateGroupChatModal';
import notify from '../../../../utils/notify';
import { useHistory } from 'react-router-dom';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import GreenPlusIcon from '../../../../assets/green-plus.png';

const RESULTS_PER_PAGE = 10;

function CreateGroupChatModal(props) {
  const friendsContainerRef = useRef();
  const history = useHistory();

  const { setShowCreateGroupChatModal } = props;
  const [searchValue, setSearchValue] = useState('');
  const [
    selectedUsersToJoinTheGroup,
    setSelectedUsersToJoinTheGroup,
  ] = useState([]);
  const [
    showConfirmCreateGroupChatModal,
    setShowConfirmCreateGroupChatModal,
  ] = useState(false);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-paginated-friends', searchValue],
    ({ pageParam = 1 }) =>
      getMyPaginatedFriendsRequest(pageParam, RESULTS_PER_PAGE, searchValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    const friendsContainerElement = friendsContainerRef.current;

    if (!friendsContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    friendsContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      friendsContainerElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  const closeModalHandler = () => {
    setShowCreateGroupChatModal(false);
  };

  const searchValueChangeHandler = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const selectUserToJoinGroupHandler = (user) => {
    setSelectedUsersToJoinTheGroup((prevState) => [...prevState, user]);
    setSearchValue('');
  };

  const removeSelectedUserFromJoiningGroup = (userId) => {
    setSelectedUsersToJoinTheGroup((prevState) =>
      prevState.filter(({ _id }) => _id !== userId)
    );
  };

  const selectUserToChatHandler = (selectedUser) => {
    history.push(`/chats?user=${selectedUser._id}`);
  };

  const openConfirmCreateGroupChatModalHandler = () => {
    if (selectedUsersToJoinTheGroup.length === 1) {
      selectUserToChatHandler(selectedUsersToJoinTheGroup[0]);
      closeModalHandler();
    } else {
      setShowConfirmCreateGroupChatModal(true);
    }
  };

  const checkIfThereAreNoMoreUsersToJoinGroup = useCallback(() => {
    const allFriends = [];
    data.pages.forEach((page) => {
      page.data.forEach((user) => allFriends.push(user));
    });

    return allFriends.every(({ follower }) =>
      selectedUsersToJoinTheGroup.some(({ _id }) => _id === follower._id)
    );
  }, [data, selectedUsersToJoinTheGroup]);

  return (
    <>
      {showConfirmCreateGroupChatModal && (
        <ConfirmCreateGroupChatModal
          setShowConfirmCreateGroupChatModal={
            setShowConfirmCreateGroupChatModal
          }
          setShowCreateGroupChatModal={setShowCreateGroupChatModal}
          selectedUsersToJoinTheGroup={selectedUsersToJoinTheGroup}
        />
      )}
      {!showConfirmCreateGroupChatModal && (
        <Modal
          style={{ maxWidth: 700 }}
          footer={
            <div className={classes['footer']}>
              <Button
                style={{ width: 200 }}
                onClick={closeModalHandler}
                darkpinkcolor="true"
              >
                Cancel
              </Button>

              <Button
                style={{ width: 200 }}
                disabled={selectedUsersToJoinTheGroup.length < 1}
                onClick={openConfirmCreateGroupChatModalHandler}
                darkpink="true"
              >
                Confirm
              </Button>
            </div>
          }
          closeModal={closeModalHandler}
          title="Chat Information"
        >
          <>
            <div className={classes['input-selected-users-container']}>
              {/* <p className={classes['to-text']}>Members:</p> */}

              {/* <div
                className={classes['selected-users-to-join-group-container']}
              >
                {selectedUsersToJoinTheGroup.slice(0, 2).map((user) => (
                  <div
                    key={user._id}
                    className={classes['selected-user-container']}
                  >
                    <p className={classes['selected-user-text']}>
                      {limitString(user.fullName, 12)}
                    </p>
                    <img
                      alt="Remove"
                      className={classes['remove-icon']}
                      onClick={() =>
                        removeSelectedUserFromJoiningGroup(user._id)
                      }
                      src={PinkRemoveIcon}
                      width={8}
                      height={9}
                    />
                  </div>
                ))}
                {selectedUsersToJoinTheGroup.length > 2 && (
                  <div className={classes['selected-user-container']}>
                    <p className={classes['selected-user-text']}>
                      + {selectedUsersToJoinTheGroup.length - 2} others
                    </p>
                  </div>
                )}
              </div> */}

              <SearchInput
                placeholder="Search user"
                style={{ width: 450 }}
                value={searchValue}
                onChange={searchValueChangeHandler}
              />

              {searchValue && (
                <div
                  ref={friendsContainerRef}
                  className={classes['friends-container']}
                >
                  {data && data.pages && data.pages[0].results === 0 && (
                    <p className={classes['no-users-found']}>No Users Found</p>
                  )}
                  {data &&
                    data.pages &&
                    data.pages[0].results !== 0 &&
                    checkIfThereAreNoMoreUsersToJoinGroup() && (
                      <p className={classes['no-users-found']}>
                        There are no more users to join the group
                      </p>
                    )}
                  {data &&
                    data.pages &&
                    data.pages.map((page) => {
                      return page.data.map(({ follower }) => {
                        const isUserSelectedToJoinGroup = selectedUsersToJoinTheGroup.some(
                          (user) => user._id === follower._id
                        );
                        if (isUserSelectedToJoinGroup) return null;

                        return (
                          <div
                            onClick={() =>
                              selectUserToJoinGroupHandler(follower)
                            }
                            key={follower._id}
                            className={classes['friend-container']}
                          >
                            <img
                              alt={follower.fullName}
                              className={classes['user-image']}
                              src={follower.photo}
                              width={40}
                              height={40}
                            />
                            <p className={classes['follower-fullName']}>
                              {follower.fullName}
                            </p>
                          </div>
                        );
                      });
                    })}
                  {(isLoading || isFetching) && <LoadingSpinner />}
                </div>
              )}
            </div>

            <div className={classes['selected-members-container']}>
              {selectedUsersToJoinTheGroup.map((user) => (
                <div
                  className={classes['selected-member-container']}
                  key={user._id}
                >
                  <img
                    src={user.photo}
                    alt={user.fullName}
                    className={classes['user-photo']}
                  />
                  <div>
                    <p className={classes['user-fullName']}>{user.fullName}</p>
                    <p className={classes['user-username']}>{user.username}</p>
                  </div>

                  <div
                    onClick={() => removeSelectedUserFromJoiningGroup(user._id)}
                    style={{
                      border: '1px solid #00B432',
                    }}
                    className={classes['add-container']}
                  >
                    <img
                      alt="Plus"
                      src={GreenPlusIcon}
                      height={12}
                      className={classes['plus-icon']}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        </Modal>
      )}
    </>
  );
}

export default CreateGroupChatModal;
