import React, { useState, useEffect } from 'react';
import classes from './JoinCommunityModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import GrayCommunitiesIcon from '../../../../assets/my-communities-icon.png';
import { useQuery, useQueryClient } from 'react-query';
import {
  getGroupSuggestionsRequest,
  joinPublicGroupRequest,
  requestGroupMembershipRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { limitString } from '../../../../helpers/helpers';
import GroupPresentationImage from '../../../../assets/group-presentation-image.png';
import Button from '../../../../components/Button/Button';

function JoinCommunityModal(props) {
  const queryClient = useQueryClient();
  const [joiningCommunities, setJoininingCommunities] = useState({});
  const [requestingCommunities, setRequestingCommunities] = useState({});
  const [joinedFirstGroup, setJoinedFirstGroup] = useState(false);
  const { setShowJoinCommunityModal } = props;

  const {
    isLoading,
    data,
    error,
  } = useQuery('get-started-task-group-suggestions', () =>
    getGroupSuggestionsRequest(1, 10)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const closeModalHandler = async () => {
    await Promise.all([
      queryClient.invalidateQueries('get-started-tasks-completion-info'),
      queryClient.refetchQueries('get-started-tasks-completion-info'),
    ]);
    setShowJoinCommunityModal(false);
  };
  const requestGroupMembershipHandler = async (groupId) => {
    if (requestingCommunities[groupId]) return;

    setRequestingCommunities((prevState) => ({
      ...prevState,
      [groupId]: true,
    }));

    try {
      await requestGroupMembershipRequest(groupId);
      queryClient.invalidateQueries('get-started-task-group-suggestions');
      queryClient.refetchQueries('get-started-task-group-suggestions');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const joinPublicGroupHandler = async (groupId) => {
    if (joiningCommunities[groupId]) return;

    setJoininingCommunities((prevState) => ({ ...prevState, [groupId]: true }));

    try {
      await joinPublicGroupRequest(groupId);
      setJoinedFirstGroup(true);
      queryClient.invalidateQueries('get-started-task-group-suggestions');
      queryClient.refetchQueries('get-started-task-group-suggestions');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      style={{ height: 500, width: 400 }}
      closeIcon="true"
      closeModal={closeModalHandler}
      title="Join a community"
    >
      <div className={classes['join-community-modal-container']}>
        <p className={classes['different-type-text']}>
          There are 3 different types of communities on Glimpse
        </p>

        <div className={classes['public-groups']}>
          <div className={classes['community-icon-container']}>
            <img
              alt="Communities"
              src={GrayCommunitiesIcon}
              className={classes['community-icon']}
            />
          </div>

          <div className={classes['public-group-description-container']}>
            <h3 className={classes['group-type']}>Public Groups</h3>
            <p className={classes['group-description']}>
              Public communities are, well, public. This means anyone can join
              the group at anytime as there is no special access or approval
              needed. This is best for general discussion or interest groups.
            </p>
          </div>
        </div>

        <div className={classes['private-groups']}>
          <div className={classes['community-icon-container']}>
            <img
              alt="Communities"
              src={GrayCommunitiesIcon}
              className={classes['community-icon']}
            />
          </div>

          <div className={classes['private-group-description-container']}>
            <h3 className={classes['group-type']}>Private Groups</h3>
            <p className={classes['group-description']}>
              To join a private community, you will need to send a request by
              clicking on the request button of the community. An admin will be
              notified and either approve or deny your request to join. This is
              best to form an exclusive community of some kind.
            </p>
          </div>
        </div>

        <div className={classes['collectible-gated-groups']}>
          <div className={classes['community-icon-container']}>
            <img
              alt="Communities"
              src={GrayCommunitiesIcon}
              className={classes['community-icon']}
            />
          </div>

          <div>
            <h3 className={classes['group-type']}>Collectible Gated Groups</h3>
            <p className={classes['group-description']}>
              Think of a gated community like a lock and key. The community is
              the lock, and a digital collectible you can purchase or claim for
              free is the key that provides access to that community. Access is
              handled automatically, so if you sell a collectible that provides
              access to a community, you will also lose access to that
              community.
            </p>
          </div>
        </div>

        <div className={classes['group-list']}>
          {!isLoading &&
            data?.data &&
            data.data.map(({ group }) => {
              return (
                <div key={group._id} className={classes['suggested-group']}>
                  <img
                    src={group.coverPhoto || GroupPresentationImage}
                    alt="Community"
                    className={classes['group-cover-photo']}
                  />
                  <p className={classes['group-name']}>
                    {limitString(group.name, 22)}
                  </p>

                  <div className={classes['join-request-container']}>
                    {group.status === 'public' ? (
                      <div
                        onClick={() => joinPublicGroupHandler(group._id)}
                        className={classes['join-container']}
                      >
                        <p className={classes['join-text']}>JOIN</p>
                      </div>
                    ) : (
                      <div
                        onClick={() => requestGroupMembershipHandler(group._id)}
                        className={classes['request-container']}
                      >
                        <p className={classes['request-text']}>REQUEST</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>

        <Button
          onClick={closeModalHandler}
          disabled={!joinedFirstGroup}
          darkpink="true"
          style={{ width: '100%', margin: '10px 0' }}
        >
          Complete
        </Button>
      </div>
    </Modal>
  );
}

export default JoinCommunityModal;
