import React, { useEffect } from 'react';
import classes from './SuggestedGroups.module.css';
import MetaTags from 'react-meta-tags';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import {
  getGroupSuggestionsRequest,
  requestGroupMembershipRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { limitString } from '../../helpers/helpers';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import CommunitiesIcon from '../../assets/white-communities-icon.png';

const RESULTS_PER_PAGE = 21;

function SuggestedGroups() {
  const history = useHistory();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    'group-suggestions',
    ({ pageParam = 1 }) =>
      getGroupSuggestionsRequest(pageParam, RESULTS_PER_PAGE),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const requestGroupMembershipHandler = async (groupId) => {
    try {
      await requestGroupMembershipRequest(groupId);
      queryClient.invalidateQueries('group-suggestions');
      queryClient.refetchQueries('group-suggestions');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <>
      <MetaTags>
        <title>Suggested Groups | Glimpse</title>
        <meta property="og:title" content={`Suggested Groups | Glimpse`} />
      </MetaTags>

      <div className={classes['suggested-groups-page']}>
        <div>
          <div className={classes['groups-list']}>
            {data?.pages && data.pages[0].results === 0 && (
              <p>No Suggestions For You</p>
            )}

            {data &&
              data.pages &&
              data.pages.map((page) => {
                return page.data.map(({ numberOfMembers, group }) => {
                  return (
                    <div
                      key={group._id}
                      className={`${
                        group.status === 'private'
                          ? classes['private-suggested-group']
                          : classes['suggested-group']
                      }`}
                    >
                      <div className={classes['group-info-container']}>
                        <div className={classes['communities-icon-container']}>
                          <img
                            className={classes['communities-icon']}
                            src={CommunitiesIcon}
                            alt="Communities"
                          />
                        </div>

                        <div className={classes['group-name-status-container']}>
                          <h3 className={classes['group-name']}>
                            {limitString(group.name, 22)}
                          </h3>

                          {group.status === 'private' ? (
                            <p className={classes['status-text']}>PRIVATE</p>
                          ) : (
                            <p className={classes['status-text']}>PUBLIC </p>
                          )}
                        </div>
                      </div>

                      <div className={classes['members-container']}>
                        <h3 className={classes['members-text']}>MEMBERS</h3>
                        <p className={classes['members-value']}>
                          {numberOfMembers}
                        </p>
                      </div>

                      <div className={classes['join-request-container']}>
                        {group.status === 'public' ? (
                          <div
                            onClick={() => history.push(`/groups/${group._id}`)}
                            className={classes['join-container']}
                          >
                            <p className={classes['join-text']}>VISIT</p>
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              requestGroupMembershipHandler(group._id)
                            }
                            className={classes['request-container']}
                          >
                            <p className={classes['request-text']}>REQUEST</p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                });
              })}
            {(isLoading || isFetching) && <LoadingSpinner />}
          </div>
        </div>
      </div>
    </>
  );
}

export default SuggestedGroups;
