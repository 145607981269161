import React, { useState, useEffect, useRef } from 'react';
import classes from './PublicChannelHeader.module.css';
import MoreIcon from '../../../../../../assets/more.png';
import EditChannelModal from '../EditChannelModal/EditChannelModal';
import { useQuery, useQueryClient } from 'react-query';
import {
  deleteGroupChannelRequest,
  getGroupFavoriteStatusRequest,
  markGroupAsFavoriteRequest,
  removeGroupAsFavoriteRequest,
} from '../../../../../../httpRequests/httpRequests';
import UpdateWhoCanWriteModal from '../UpdateWhoCanWriteModal/UpdateWhoCanWriteModal';
import ConfirmDeleteChannelModal from '../ConfirmDeleteChannelModal/ConfirmDeleteChannelModal';
import notify from '../../../../../../utils/notify';

function PrivateChannelHeader(props) {
  const queryClient = useQueryClient();
  const {
    channelName,
    channelId,
    groupId,
    setSelectedChannelName,
    isMyRoleAdmin,
  } = props;
  const moreIconRef = useRef();
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showEditChannelModal, setShowEditChannelModal] = useState(false);

  const [showUpdateWhoCanWriteModal, setShowUpdateWhoCanWriteModal] = useState(
    false
  );

  const groupFavoriteStatusResponse = useQuery(
    ['group-favorite-status', groupId],
    () => getGroupFavoriteStatusRequest(groupId)
  );

  const [
    showConfirmDeleteChannelModal,
    setShowConfirmDeleteChannelModal,
  ] = useState(false);

  useEffect(() => {
    const checkForClosingMoreOptionsHandler = (e) => {
      if (e.target !== moreIconRef.current) {
        setShowMoreOptions(false);
      }
    };

    window.addEventListener('click', checkForClosingMoreOptionsHandler);

    return () =>
      window.removeEventListener('click', checkForClosingMoreOptionsHandler);
  }, [moreIconRef]);

  const toggleMoreOptions = () => {
    setShowMoreOptions((prevState) => !prevState);
  };

  const openEditChannelModalHandler = () => {
    if (channelName === 'General') return;

    setShowEditChannelModal(true);
  };

  const deleteChannelHandler = async () => {
    if (channelName === 'General') return;

    try {
      await deleteGroupChannelRequest(groupId, channelId);

      await Promise.all([
        queryClient.invalidateQueries(['group-channels', groupId]),
        queryClient.refetchQueries(['group-channels', groupId]),
      ]);
      setSelectedChannelName('General');
    } catch (err) {
      throw err;
    }
  };

  const openConfirmDeleteChannelModalHandler = () => {
    setShowConfirmDeleteChannelModal(true);
  };

  const openUpdateWhoCanWriteModalHandler = () => {
    setShowUpdateWhoCanWriteModal(true);
  };

  const invalidateFavoriteStatus = () => {
    Promise.all([
      queryClient.invalidateQueries(['group-favorite-status', groupId]),
      queryClient.refetchQueries(['group-favorite-status', groupId]),
      queryClient.invalidateQueries(['my-groups', '']),
      queryClient.refetchQueries(['my-groups', '']),
    ]);
  };

  const removeGroupAsFavoriteHandler = async () => {
    try {
      await removeGroupAsFavoriteRequest(groupId);

      invalidateFavoriteStatus();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const markGroupAsFavoriteHandler = async () => {
    try {
      await markGroupAsFavoriteRequest(groupId);

      invalidateFavoriteStatus();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderFavoriteStatus = () => {
    const isFavorite = groupFavoriteStatusResponse.data?.data;
    if (isFavorite) {
      return (
        <p className={classes['option']} onClick={removeGroupAsFavoriteHandler}>
          Remove as Favorite
        </p>
      );
    } else {
      return (
        <p className={classes['option']} onClick={markGroupAsFavoriteHandler}>
          Mark as Favorite
        </p>
      );
    }
  };

  let deleteChannelStyle = {};
  if (channelName === 'General') {
    deleteChannelStyle = {
      cursor: 'not-allowed',
      color: '#999999',
    };
  }

  const updateWhoCanWriteStyle = {};

  return (
    <>
      {showEditChannelModal && channelName !== 'General' && (
        <EditChannelModal
          setSelectedChannelName={setSelectedChannelName}
          groupId={groupId}
          channelId={channelId}
          channelName={channelName}
          setShowEditChannelModal={setShowEditChannelModal}
        />
      )}

      {showUpdateWhoCanWriteModal && (
        <UpdateWhoCanWriteModal
          groupId={groupId}
          channelId={channelId}
          setShowUpdateWhoCanWriteModal={setShowUpdateWhoCanWriteModal}
        />
      )}

      {showConfirmDeleteChannelModal && (
        <ConfirmDeleteChannelModal
          setShowConfirmDeleteChannelModal={setShowConfirmDeleteChannelModal}
          deleteChannelHandler={deleteChannelHandler}
        />
      )}

      <div className={classes['header']}>
        <h3 className={classes['channel-name']}>#{channelName}</h3>
        {isMyRoleAdmin ? (
          <div style={{ position: 'relative' }}>
            <img
              ref={moreIconRef}
              style={{ cursor: 'pointer' }}
              alt="More"
              onClick={toggleMoreOptions}
              src={MoreIcon}
              height={20}
            />
            {showMoreOptions && (
              <div className={classes['more-options']}>
                <p
                  style={deleteChannelStyle}
                  className={
                    channelName === 'General'
                      ? classes['general-option']
                      : classes['option']
                  }
                  onClick={openEditChannelModalHandler}
                >
                  Edit Channel
                </p>
                <p
                  style={updateWhoCanWriteStyle}
                  className={
                    channelName === 'General'
                      ? classes['general-option']
                      : classes['option']
                  }
                  onClick={openUpdateWhoCanWriteModalHandler}
                >
                  Update Who Can Write
                </p>
                <p
                  style={deleteChannelStyle}
                  onClick={openConfirmDeleteChannelModalHandler}
                  className={classes['delete']}
                >
                  Delete Channel
                </p>

                {!groupFavoriteStatusResponse?.isLoading &&
                  channelName === 'General' &&
                  renderFavoriteStatus()}
              </div>
            )}
          </div>
        ) : (
          channelName === 'General' && (
            <div style={{ position: 'relative' }}>
              <img
                ref={moreIconRef}
                style={{ cursor: 'pointer' }}
                alt="More"
                onClick={toggleMoreOptions}
                src={MoreIcon}
                height={20}
              />
              {showMoreOptions && (
                <div className={classes['more-options']}>
                  {!groupFavoriteStatusResponse?.isLoading &&
                    channelName === 'General' &&
                    renderFavoriteStatus()}
                </div>
              )}
            </div>
          )
        )}
      </div>
    </>
  );
}

export default PrivateChannelHeader;
