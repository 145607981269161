import React, { useEffect } from 'react';
import classes from './VerifiedInstitutions.module.css';
import { Col, Row } from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import {
  getVerifiedInstitutionsRequest,
  unVerifyUserAsInstitutionRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';

function VerifiedInstitutions() {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { isLoading, data, error } = useQuery(
    'verified-institutions',
    getVerifiedInstitutionsRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateVerifiedInstitutionsHandler = () =>
    Promise.all([
      queryClient.invalidateQueries('verified-institutions'),
      queryClient.refetchQueries('verified-institutions'),
    ]);

  const unVerifyUserAsInstitutionHandler = async (userId) => {
    try {
      await unVerifyUserAsInstitutionRequest(userId);

      invalidateVerifiedInstitutionsHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-users-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-users-heading']}>
            VERIFIED INSTITUTIONS = {data?.data.length}
          </h2>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={1} className={classes['pagination-col']}>
              Photo
            </Col>
            <Col md={2} className={classes['pagination-col']}>
              Full Name
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Email
            </Col>

            <Col md={2} className={classes['pagination-col']}>
              Unverify
            </Col>
          </Row>

          {!isLoading &&
            data?.data &&
            data.data.map((user) => {
              return (
                <div key={user._id}>
                  <hr />
                  <Row justify="space-between" style={{ padding: '1em 0' }}>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={1}
                    >
                      <img
                        width={25}
                        height={25}
                        style={{ borderRadius: 100, objectFit: 'cover' }}
                        alt="User"
                        src={user.photo}
                      />
                    </Col>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={2}
                    >
                      {user.firstName} {user.lastName}
                    </Col>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={3}
                    >
                      {user.email}
                    </Col>

                    <Col md={2}>
                      <button
                        className="btn-white-glimpse"
                        onClick={() =>
                          unVerifyUserAsInstitutionHandler(user._id)
                        }
                      >
                        Unverify
                      </button>
                    </Col>
                  </Row>
                </div>
              );
            })}

          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default VerifiedInstitutions;
