import React, { useContext } from 'react';
import classes from './BrandItem.module.css';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import VerifiedIcon from '../../assets/verified.png';
import { getFormattedNumber, limitString } from '../../helpers/helpers';

function BrandItem(props) {
  const { brandItem } = props;
  const { owner, price, title, type, previewUrl, _id } = brandItem;

  const { profile } = useContext(AuthContext);

  return (
    <div className={classes['brand-item-container']}>
      <Link to={`/brand-items/${_id}`}>
        {type === 'image' ? (
          <img
            alt="Brand"
            className={classes['brand-item-image']}
            src={previewUrl}
          />
        ) : (
          <ReactPlayer width={330} height={360} url={previewUrl} />
        )}
      </Link>

      <div className={classes['brand-item-information-container']}>
        <div className={classes['brand-item-owner-container']}>
          <Link
            to={
              owner._id === profile?._id
                ? '/my-space'
                : `/user-feed/${owner._id}`
            }
          >
            <img
              src={owner.photo}
              width={35}
              height={35}
              className={classes['owner-photo']}
              alt={owner.fullName}
            />
          </Link>

          <div className={classes['brand-item-owner-info']}>
            <p className={classes['brand-item-title']}>
              {limitString(title, 14)}
            </p>
            <p
              title={`${owner.fullName}`}
              className={classes['owner-fullName']}
            >
              {limitString(`${owner.fullName}`, 15)}
              {owner.verified && (
                <img
                  alt="Verified"
                  src={VerifiedIcon}
                  style={{ marginLeft: 6, height: 17 }}
                />
              )}
            </p>
          </div>

          <div className={classes['price-container']}>
            <p className={classes['usd-price']}>
              ${getFormattedNumber(price.toFixed(2))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandItem;
