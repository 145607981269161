import React, { useState, useEffect } from 'react';
import classes from './AllGroupsNotHiddenFromSuggestions.module.css';
import { useQuery, useQueryClient } from 'react-query';
import {
  getAllGroupsNotHiddenFromSuggestionsRequest,
  hideGroupFromSuggestionsRequest,
} from '../../../../httpRequests/httpRequests';
import { Col, Row } from 'antd';
import { limitString } from '../../../../helpers/helpers';
import notify from '../../../../utils/notify';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

function AllGroupsNotHiddenFromSuggestions() {
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState('');

  const { isLoading, data, error } = useQuery(
    ['all-groups-not-hidden-from-suggestions', searchValue],
    () => getAllGroupsNotHiddenFromSuggestionsRequest(searchValue)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateAllGroupsNotHiddenFromSuggestionsHandler = () =>
    Promise.all([
      queryClient.invalidateQueries([
        'all-groups-not-hidden-from-suggestions',
        searchValue,
      ]),
      queryClient.refetchQueries([
        'all-groups-not-hidden-from-suggestions',
        searchValue,
      ]),
    ]);

  const hideGroupFromSuggestionsHandler = async (groupId) => {
    try {
      await hideGroupFromSuggestionsRequest(groupId);

      invalidateAllGroupsNotHiddenFromSuggestionsHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-groups-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-groups-heading']}>
            ALL GROUPS = {data?.data.length}
          </h2>

          <div>
            <input
              placeholder="Search For Groups"
              style={{ width: 300 }}
              className="searchInput"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={5} className={classes['pagination-col']}>
              Name
            </Col>
            <Col md={4} className={classes['pagination-col']}>
              Organizer
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Hide
            </Col>
          </Row>

          {!isLoading &&
            data &&
            data.data.map((group) => {
              return (
                <div key={group._id}>
                  <hr />
                  <Row justify="space-between" style={{ padding: '1em 0' }}>
                    <Col style={{ display: 'flex', gap: 10 }} md={5}>
                      <img
                        style={{ objectFit: 'cover', borderRadius: '50%' }}
                        src={group.coverPhoto}
                        width={30}
                        height={30}
                        alt={group.name}
                      />
                      {limitString(group.name, 30)}
                    </Col>

                    <Col style={{ display: 'flex', gap: 10 }} md={4}>
                      <img
                        style={{ objectFit: 'cover', borderRadius: '50%' }}
                        src={group.organizer.photo}
                        alt="Organizer"
                        width={30}
                        height={30}
                      />
                      <p>{group.organizer.fullName}</p>
                    </Col>

                    <Col md={3}>
                      <button
                        onClick={() =>
                          hideGroupFromSuggestionsHandler(group._id)
                        }
                        className="cancel-button"
                      >
                        Hide
                      </button>
                    </Col>
                  </Row>
                </div>
              );
            })}

          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default AllGroupsNotHiddenFromSuggestions;
