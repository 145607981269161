import React, { useState } from 'react';
import AllUsers from './components/AllUsers/AllUsers';
import SidebarAdminPanel from './components/SidebarAdminPanel/SidebarAdminPanel';
import classes from './AdminPanel.module.css';
import CategoriesAndSubCategories from './components/CategoriesAndSubCategories.js/CategoriesAndSubCategories';
import Events from './components/Events/Events';
import UserNftsStatistics from './components/UserNftsStatistics/UserNftsStatistics';
import UsersTransactionsStatistics from './components/UsersTransactionsStatistics/UsersTransactionsStatistics';
import AllNfts from './components/AllNfts/AllNfts';
import Reports from './components/Reports/Reports';
import RequestBetaAccess from './components/RequestBetaAccess/RequestBetaAccess';
import CreatorApplies from './components/CreatorApplies/CreatorApplies';
import WyreUsersMappings from './components/WyreUsersMappings/WyreUsersMappings';
import WyrePayments from './components/WyrePayments/WyrePayments';
import WyreTransfers from './components/WyreTransfers/WyreTransfers';
import UserAdmins from './components/UserAdmins/UserAdmins';
import VerifiedUsers from './components/VerifiedUsers/VerifiedUsers';
import VerifiedInstitutions from './components/VerifiedInstitutions/VerifiedInstitutions';
import ManageAdmins from './components/ManageAdmins/ManageAdmins';
import PublishNftsOrCollectionContent from './components/PublishNftsOrCollectionContent/PublishNftsOrCollectionContent';
import AllGroups from './components/AllGroups/AllGroups';
import AllGroupsNotHiddenFromSuggestions from './components/AllGroupsNotHiddenFromSuggestions/AllGroupsNotHiddenFromSuggestions';
import StripeAccountAccess from './components/StripeAccountAccess/StripeAccountAccess';
import StripePayments from './components/StripePayments/StripePayments';
// import UnverifiedEmails from './components/UnverifiedEmails/UnverifiedEmails';
import Level2Verified from './components/Level2Verified/Level2Verified';
import UnderSuspicion from './components/UnderSuspicion/UnderSuspicion';
import TypesOfSignup from './components/TypesOfSignup/TypesOfSignup';
import GlmsStripeBuys from './components/GlmsStripeBuys/GlmsStripeBuys';
import PointsStripeBuys from './components/PointsStripeBuys/PointsStripeBuys';
import CreateUser from './components/CreateUser/CreateUser';

export const SIDEBAR_OPTIONS = {
  ALL_USERS: 'All Users',
  CATEGORIES_SUBCATEGORIES: 'Categories & SubCategories',
  EVENTS: 'Events',
  USER_NFTS_STATS: 'User-NFTs Statistics',
  TRANSACTIONS_STATISTICS: 'Transactions Statistics',
  ALL_NFTS: 'All NFTs',
  REPORTS: 'Reports',
  REQUEST_BETA_ACCESS: 'Request Beta Access',
  CREATOR_APPLIES: 'Creator Applies',
  WYRE_USERS_MAPPINGS: 'WYRE USERS MAPPINGS',
  WYRE_PAYMENTS: 'WYRE PAYMENTS',
  WYRE_TRANSFERS: 'WYRE TRANSFERS',
  USER_ADMINS: 'USER_ADMINS',
  VERIFIED_USERS: 'VERIFIED_USERS',
  VERIFIED_INSTITUTIONS: 'VERIFIED_INSTITUTIONS',
  MANAGE_ADMINS: 'MANAGE_ADMINS',
  PUBLISH_NFTS_OR_COLLECTION_CONTENT: 'PUBLISH_NFT_CONTENT',
  ALL_GROUPS: 'ALL_GROUPS',
  ALL_GROUPS_NOT_HIDDEN_FROM_SUGGESTIONS: 'ALL_SUGGESTED_GROUPS',
  STRIPE_ACCOUNT_ACCESS: 'STRIPE_ACCOUNT_ACCESS',
  STRIPE_PAYMENTS: 'STRIPE_PAYMENTS',
  // UNVERIFIED_EMAILS: 'UNVERIFIED_EMAILS',
  LEVEL2_VERIFIED: 'LEVEL2_VERIFIED',
  UNDER_SUSPICION: 'UNDER_SUSPICION',
  TYPES_OF_SIGNUP: 'TYPES_OF_SIGNUP',
  GLMS_STRIPE_BUYS: 'GLMS_STRIPE_BUYS',
  POINTS_STRIPE_BUYS: 'POINTS_STRIPE_BUYS',
  CREATE_USER: 'CREATE_USER',
};

function AdminPanel() {
  const [selectedOption, setSelectedOption] = useState(
    SIDEBAR_OPTIONS['ALL_USERS']
  );

  const renderComponentAccordingToSelectedOption = () => {
    if (selectedOption === SIDEBAR_OPTIONS['ALL_USERS']) return <AllUsers />;

    if (selectedOption === SIDEBAR_OPTIONS['CATEGORIES_SUBCATEGORIES'])
      return <CategoriesAndSubCategories />;

    if (selectedOption === SIDEBAR_OPTIONS['EVENTS']) return <Events />;

    if (selectedOption === SIDEBAR_OPTIONS['USER_NFTS_STATS'])
      return <UserNftsStatistics />;

    if (selectedOption === SIDEBAR_OPTIONS['TRANSACTIONS_STATISTICS'])
      return <UsersTransactionsStatistics />;

    if (selectedOption === SIDEBAR_OPTIONS['ALL_NFTS']) return <AllNfts />;

    if (selectedOption === SIDEBAR_OPTIONS['REPORTS']) return <Reports />;

    if (selectedOption === SIDEBAR_OPTIONS['REQUEST_BETA_ACCESS'])
      return <RequestBetaAccess />;

    if (selectedOption === SIDEBAR_OPTIONS['CREATOR_APPLIES'])
      return <CreatorApplies />;

    if (selectedOption === SIDEBAR_OPTIONS['WYRE_USERS_MAPPINGS'])
      return <WyreUsersMappings />;

    if (selectedOption === SIDEBAR_OPTIONS['WYRE_PAYMENTS'])
      return <WyrePayments />;

    if (selectedOption === SIDEBAR_OPTIONS['WYRE_TRANSFERS'])
      return <WyreTransfers />;

    if (selectedOption === SIDEBAR_OPTIONS['USER_ADMINS'])
      return <UserAdmins />;

    if (selectedOption === SIDEBAR_OPTIONS['VERIFIED_USERS'])
      return <VerifiedUsers />;

    if (selectedOption === SIDEBAR_OPTIONS['VERIFIED_INSTITUTIONS'])
      return <VerifiedInstitutions />;

    if (selectedOption === SIDEBAR_OPTIONS['MANAGE_ADMINS'])
      return <ManageAdmins />;

    if (
      selectedOption === SIDEBAR_OPTIONS['PUBLISH_NFTS_OR_COLLECTION_CONTENT']
    )
      return <PublishNftsOrCollectionContent />;

    if (selectedOption === SIDEBAR_OPTIONS['ALL_GROUPS']) return <AllGroups />;

    if (
      selectedOption ===
      SIDEBAR_OPTIONS['ALL_GROUPS_NOT_HIDDEN_FROM_SUGGESTIONS']
    )
      return <AllGroupsNotHiddenFromSuggestions />;

    if (selectedOption === SIDEBAR_OPTIONS['STRIPE_ACCOUNT_ACCESS'])
      return <StripeAccountAccess />;

    if (selectedOption === SIDEBAR_OPTIONS['STRIPE_PAYMENTS'])
      return <StripePayments />;

    // if (selectedOption === SIDEBAR_OPTIONS['UNVERIFIED_EMAILS'])
    //   return <UnverifiedEmails />;

    if (selectedOption === SIDEBAR_OPTIONS['LEVEL2_VERIFIED'])
      return <Level2Verified />;

    if (selectedOption === SIDEBAR_OPTIONS['UNDER_SUSPICION'])
      return <UnderSuspicion />;

    if (selectedOption === SIDEBAR_OPTIONS['TYPES_OF_SIGNUP'])
      return <TypesOfSignup />;

    if (selectedOption === SIDEBAR_OPTIONS['GLMS_STRIPE_BUYS'])
      return <GlmsStripeBuys />;

    if (selectedOption === SIDEBAR_OPTIONS['POINTS_STRIPE_BUYS'])
      return <PointsStripeBuys />;

    if (selectedOption === SIDEBAR_OPTIONS['CREATE_USER'])
      return <CreateUser />;
  };

  return (
    <div className="site-layout-content">
      <div className={classes['admin-panel-container']}>
        <SidebarAdminPanel
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
        />
        {renderComponentAccordingToSelectedOption()}
      </div>
    </div>
  );
}

export default AdminPanel;
