import React, { useContext, useEffect } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import GoBack from '../../components/GoBack/GoBack';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import { AuthContext } from '../../context/auth-context';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import {
  getMyNotificationsRequest,
  markAllNotificationsAsReadRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import classes from './Notifications.module.css';
import Notification from './components/Notification/Notification';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import MetaTags from 'react-meta-tags';
// import MessagesSidebar from '../../components/MessagesSidebar/MessagesSidebar';

const RESULTS_PER_PAGE = 20;

function Notifications() {
  const queryClient = useQueryClient();
  const { getNumberOfUnreadNotifications } = useContext(AuthContext);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    'my-notifications',
    ({ pageParam = 1 }) =>
      getMyNotificationsRequest(pageParam, RESULTS_PER_PAGE),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const markAllNotificationsAsReadHandler = async () => {
    try {
      await markAllNotificationsAsReadRequest();
      queryClient.invalidateQueries('my-notifications');
      queryClient.refetchQueries('my-notifications');
      getNumberOfUnreadNotifications();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  return (
    <>
      <MetaTags>
        <title>Notifications | Glimpse</title>
        <meta property="og:title" content={'Notifications | Glimpse'} />
      </MetaTags>

      <div className={classes['notifications-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['notifications-container']}>
          <div className={classes['go-back-mark-as-read-container']}>
            <div className={classes['go-back-container']}>
              <GoBack />
              <h3 className={classes['notification-text']}>Notifications</h3>
            </div>

            <p
              onClick={markAllNotificationsAsReadHandler}
              className={classes['mark-as-read-text']}
            >
              Mark all as read
            </p>
          </div>

          <div className={classes['horizontal-line']}></div>

          <div className={classes['notifications-list']}>
            {!isLoading && data && data.pages[0].results === 0 ? (
              <div
                style={{
                  background: 'white',
                  padding: '.8rem',
                  borderRadius: 10,
                }}
              >
                <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
                  You do not have any notifications yet!
                </p>
              </div>
            ) : (
              <>
                {!isLoading &&
                  data &&
                  data.pages.map((page) => {
                    return page.data.map((notification) => {
                      return (
                        <div key={notification._id}>
                          <Notification notification={notification} />
                          <div
                            className={classes['notification-horizontal-line']}
                          ></div>
                        </div>
                      );
                    });
                  })}
                {(isLoading || isFetching) && <LoadingSpinner />}
              </>
            )}
          </div>
        </div>

        {/* <div className={classes['messages-sidebar-container']}>
          <MessagesSidebar />
        </div> */}
      </div>
    </>
  );
}

export default Notifications;
