import React, { useEffect } from 'react';
import classes from './UserAdmins.module.css';
import { Col, Row } from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  getUserAdminsRequest,
  markAdminAsUserRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

function UserAdmins() {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { isLoading, data, error } = useQuery(
    'user-admins',
    getUserAdminsRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateAdminsHandler = () => {
    return Promise.all([
      queryClient.invalidateQueries('user-admins'),
      queryClient.refetchQueries('user-admins'),
    ]);
  };

  const markAdminAsUserHandler = async (adminId) => {
    try {
      await markAdminAsUserRequest(adminId);
      invalidateAdminsHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-users-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-users-heading']}>
            ALL ADMINS = {data?.data.length}
          </h2>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={1} className={classes['pagination-col']}>
              Photo
            </Col>
            <Col md={2} className={classes['pagination-col']}>
              Full Name
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Email
            </Col>

            <Col md={2} className={classes['pagination-col']}>
              Remove
            </Col>
          </Row>

          {!isLoading &&
            data?.data &&
            data.data.map((user) => {
              return (
                <div key={user._id}>
                  <hr />
                  <Row justify="space-between" style={{ padding: '1em 0' }}>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={1}
                    >
                      <img
                        width={25}
                        height={25}
                        style={{ borderRadius: 100, objectFit: 'cover' }}
                        alt="User"
                        src={user.photo}
                      />
                    </Col>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={2}
                    >
                      {user.firstName} {user.lastName}
                    </Col>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={3}
                    >
                      {user.email}
                    </Col>

                    <Col md={2}>
                      <button
                        className="btn-white-glimpse"
                        onClick={() => markAdminAsUserHandler(user._id)}
                      >
                        Remove
                      </button>
                    </Col>
                  </Row>
                </div>
              );
            })}

          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default UserAdmins;
