import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { IPFS_URL } from '../../../../../../../../constants/IPFS';
import { CoinMarketCapContext } from '../../../../../../../../context/coinmarketcap-context';
import { limitString } from '../../../../../../../../helpers/helpers';
import classes from './MostRecentNftCard.module.css';

function MostRecentNftCard(props) {
  const { nft } = props;
  const { glmsToUsd } = useContext(CoinMarketCapContext);

  const { owner, _id: nftId, ipfs, listedForBuyNow, buyNowPrice, type } = nft;

  return (
    <Link to={`/nfts/${nftId}`} className={classes['card-container']}>
      <div>
        {type === 'image' ? (
          <img
            alt="IPFS Hash"
            className={classes['nft-img']}
            src={nft.previewUrl || `${IPFS_URL}/${ipfs}`}
          />
        ) : (
          <ReactPlayer
            className={classes['nft-video']}
            height={200}
            width={'100%'}
            url={nft.previewUrl || `${IPFS_URL}/${ipfs}`}
          />
        )}
      </div>

      <div className={classes['owner-container']}>
        <img
          alt={owner.firstName}
          src={owner.photo}
          className={classes['owner-photo']}
        />
        <div>
          <p className={classes['owner-firstName']}>
            {limitString(owner.firstName, 10)}
          </p>
          <p className={classes['buy-price']}>
            {listedForBuyNow && `$ ${Math.ceil(buyNowPrice * glmsToUsd.price)}`}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default MostRecentNftCard;
