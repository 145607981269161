import React, { useState } from 'react';
import Modal from '../../../../../../../../components/Modal/Modal';
import { updateReportActionTakenDescriptionRequest } from '../../../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../../../utils/notify';

function ActionTakenDescriptionModal(props) {
  const {
    actionTakenDescriptionModalState,
    setActionTakenDescriptionModalState,
  } = props;
  const [
    actionTakenDescriptionValue,
    setActionTakenDescriptionValue,
  ] = useState('');
  const [
    updatingActionTakenDescription,
    setUpdatingActionTakenDescription,
  ] = useState(false);

  const closeModalHandler = () => {
    setActionTakenDescriptionModalState({
      opened: false,
      selectedReportId: undefined,
    });
  };

  const actionTakenDescriptionValueChangeHandler = (e) =>
    setActionTakenDescriptionValue(e.target.value);

  const confirmActionTakenDescriptionHandler = async () => {
    setUpdatingActionTakenDescription(true);

    try {
      const requestBody = {
        actionTakenDescription: actionTakenDescriptionValue,
      };

      await updateReportActionTakenDescriptionRequest(
        actionTakenDescriptionModalState.selectedReportId,
        requestBody
      );

      closeModalHandler();
    } catch (error) {
      notify('error', error, 2000);
    }

    setUpdatingActionTakenDescription(false);
  };

  const isConfirmButtonDisabled =
    actionTakenDescriptionValue.length < 4 || updatingActionTakenDescription;
  const textAreaStyle = {};
  if (actionTakenDescriptionValue.length < 4) {
    textAreaStyle['border'] = '1px solid red';
  }

  return (
    <>
      <Modal
        footer={
          <>
            <button onClick={closeModalHandler} className={'cancel-button'}>
              Close
            </button>
            <span style={{ paddingLeft: 10 }}></span>
            <button
              disabled={isConfirmButtonDisabled}
              onClick={confirmActionTakenDescriptionHandler}
              className={
                isConfirmButtonDisabled ? 'btn-disabled' : 'btn-white-glimpse'
              }
            >
              Confirm
            </button>
          </>
        }
        closeModal={closeModalHandler}
        title="Action Description"
      >
        <textarea
          className="input-glimpse input-background"
          style={{ minHeight: 100, width: '80%', ...textAreaStyle }}
          onChange={actionTakenDescriptionValueChangeHandler}
          placeholder="Action Description"
          value={actionTakenDescriptionValue}
        />
      </Modal>
    </>
  );
}

export default ActionTakenDescriptionModal;
