import React, { useContext, useEffect } from 'react';
import classes from './WyrePayments.module.css';
import { useQuery } from 'react-query';
import { getAllWyrePaymentsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { Col, Row } from 'antd';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../context/auth-context';
import moment from 'moment';

function WyrePayments() {
  const { profile } = useContext(AuthContext);
  const { isLoading, data, error } = useQuery(
    'wyre-payments',
    getAllWyrePaymentsRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['all-payments-container']}>
      <div className={classes['heading-container']}>
        <h2 className={classes['all-users-heading']}>
          WYRE PAYMENTS = {data?.data.length}
        </h2>
      </div>

      <div
        style={{ minHeight: '85vh' }}
        id="table-nft"
        className={classes['likes-container']}
      >
        <Row
          style={{ margin: 10, borderBottom: '1px solid white' }}
          justify="space-between"
        >
          <Col md={3} className={classes['pagination-col']}>
            User
          </Col>
          <Col md={2} className={classes['pagination-col']}>
            USD Amount
          </Col>

          <Col md={2} className={classes['pagination-col']}>
            Status
          </Col>

          <Col md={2} className={classes['pagination-col']}>
            Payment For
          </Col>

          <Col md={5} className={classes['pagination-col']}>
            Entity ID
          </Col>

          <Col md={5} className={classes['pagination-col']}>
            Wyre Transfers
          </Col>

          <Col md={4} className={classes['pagination-col']}>
            Updated At
          </Col>
        </Row>

        {data?.data &&
          data.data.map(
            ({
              user,
              usdAmount,
              paymentFor,
              status,
              entityId,
              wyreTransfers = [],
              updatedAt,
            }) => {
              return (
                <Row
                  style={{ margin: 10, borderBottom: '1px solid white' }}
                  justify="space-between"
                >
                  <Col md={3} className={classes['pagination-col']}>
                    <Link
                      to={
                        user._id === profile?._id
                          ? '/my-space'
                          : `/user-feed/${user._id}`
                      }
                    >
                      <img
                        width={25}
                        height={25}
                        style={{ borderRadius: 100, objectFit: 'cover' }}
                        src={user.photo}
                        alt={user.fullName}
                      />
                      <p className={classes['user-fullName']}>
                        {user.fullName}
                      </p>
                    </Link>
                  </Col>
                  <Col md={2} className={classes['pagination-col']}>
                    {usdAmount}
                  </Col>
                  <Col md={2} className={classes['pagination-col']}>
                    {status}
                  </Col>
                  <Col md={2} className={classes['pagination-col']}>
                    {paymentFor}
                  </Col>
                  <Col md={5} className={classes['pagination-col']}>
                    {entityId}
                  </Col>

                  <Col md={5} className={classes['pagination-col']}>
                    {wyreTransfers.join(' ')}
                  </Col>

                  <Col md={4} className={classes['pagination-col']}>
                    {moment(updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
                  </Col>
                </Row>
              );
            }
          )}

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default WyrePayments;
