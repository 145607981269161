import React, { useContext, useEffect, useMemo } from 'react';
import classes from './ReferralsNetwork.module.css';
import { useQuery } from 'react-query';
import { getMyReferralsNetworkRequest } from '../../../../httpRequests/httpRequests';
import Graph from 'react-graph-vis';
import notify from '../../../../utils/notify';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../context/auth-context';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

function ReferralsNetwork() {
  const { profile } = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, data, error } = useQuery(
    'my-referrals-network',
    getMyReferralsNetworkRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const nodes = useMemo(() => {
    if (!data?.data) return [];

    const nodes = [];
    const { nodesInformation } = data;
    for (const userId in data.data) {
      const node = {
        id: userId,
        shape: 'image',
        value: 2,
        image: nodesInformation[userId].photo,
        label: nodesInformation[userId].fullName,
        title: nodesInformation[userId].fullName,
        size: 20,
        font: {
          color: '#000000b3',
          size: 12,
          face: 'Poppins',
          background: 'none',
          strokeWidth: 0,
          strokeColor: '#ffffff',
          align: 'center',
          multi: false,
          vadjust: 0,
        },
        shadow: {
          enabled: true,
          color: 'rgba(0,0,0,0.5)',
          size: 10,
          x: 5,
          y: 5,
        },
        shapeProperties: {
          interpolation: false,
          useImageSize: false,
          useBorderWithImage: false,
          coordinateOrigin: 'center',
        },
        imagePadding: 4,
        color: {
          border: 'transparent',
          background: 'transparent',
          highlight: { border: 'transparent', background: 'transparent' },
          hover: { border: 'transparent', background: 'transparent' },
        },
      };
      nodes.push(node);
    }

    return nodes;
  }, [data]);

  const edges = useMemo(() => {
    if (!data?.data) return [];

    const edges = [];
    for (const userId in data.data) {
      const vertexes = data.data[userId];
      vertexes.forEach((el) => {
        edges.push({
          from: userId,
          to: el,
        });
      });
    }

    return edges;
  }, [data]);

  const graph = {
    nodes,
    edges,
  };

  const graphEvents = {
    select: (event) => {
      const { nodes } = event;

      const userId = nodes[0];

      if (userId) {
        history.push(
          userId === profile?._id ? '/my-space' : `/user-feed/${userId}`
        );
      }
    },
    hoverNode: (e) => {
      e.event.target.style.cursor = 'pointer';
    },
    blurNode: (e) => {
      e.event.target.style.cursor = 'default';
    },
  };

  const networkOptions = {
    layout: {
      randomSeed: undefined,
      improvedLayout: true,
    },
    edges: {
      color: '#F900B7',
      font: {
        color: '#F900B7',
        size: 14,
        face: 'Poppins',
        background: 'none',
        strokeWidth: 1,
        strokeColor: '#ffffff',
        align: 'horizontal',
        multi: false,
        vadjust: 0,
        bold: {
          color: '#F900B7',
          size: 14,
          face: 'Poppins',
          vadjust: 0,
          mod: 'bold',
        },
      },
      dashes: true,
      shadow: {
        enabled: true,
        color: 'rgba(0,0,0,0.5)',
        size: 10,
        x: 5,
        y: 5,
      },
      smooth: {
        enabled: true,
        type: 'dynamic',
        roundness: 0.3,
      },
      width: 1.4,
    },
    autoResize: true,
    height: '100%',
    width: '100%',
    locale: 'en',
    interaction: {
      dragNodes: true,
      dragView: true,
      hideEdgesOnDrag: false,
      hideEdgesOnZoom: false,
      hideNodesOnDrag: false,
      hover: true,
      hoverConnectedEdges: true,
      keyboard: {
        enabled: true,
        speed: { x: 10, y: 10, zoom: 0.02 },
        bindToWindow: true,
        autoFocus: true,
      },
      multiselect: true,
      navigationButtons: true,
      selectable: true,
      selectConnectedEdges: true,
      tooltipDelay: 300,
      zoomSpeed: 0.4,
      zoomView: true,
    },
  };

  return (
    <div className={classes['referrals-network-page-container']}>
      <div id="graph" className={classes['graph-container']}>
        {isLoading && <LoadingSpinner />}
        <Graph events={graphEvents} graph={graph} options={networkOptions} />
      </div>
    </div>
  );
}

export default ReferralsNetwork;
