import React, { useContext, useState } from 'react';
import Button from '../../../../components/Button/Button';
import { createNftRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './PaymentInfo.module.css';
import { CoinMarketCapContext } from '../../../../context/coinmarketcap-context';
import { getFormattedNumber } from '../../../../helpers/helpers';
import { AuthContext } from '../../../../context/auth-context';
import { useHistory } from 'react-router-dom';

function PaymentInfo(props) {
  const history = useHistory();
  const { getMyLockedGLMSBalance } = useContext(AuthContext);
  const { glmsToUsd } = useContext(CoinMarketCapContext);
  const {
    setStep,
    requestFor,
    nftFor,
    occasion,
    description,
    nftTitle,
    userProfile,
    isNftTypeVideo,
  } = props;
  const [requestingNft, setRequestingNft] = useState(false);

  const nftFee = userProfile.requestNftPrice
    ? userProfile.requestNftPrice
    : 1000;

  const goBackHandler = () => {
    setStep('Request NFT');
  };

  const bookNftRequestHandler = async () => {
    setRequestingNft(true);

    try {
      const requestBody = {
        nftFor,
        occasion,
        description,
        nftTitle,
        nftFee,
        nftType: isNftTypeVideo ? 'video' : 'image',
      };
      await createNftRequest(requestFor, requestBody);

      getMyLockedGLMSBalance();
      notify('success', 'Your Request was sent successfully!', 2000);

      setRequestingNft(false);

      history.push('/requested-nfts?tab=Sent');
    } catch (err) {
      setRequestingNft(false);

      notify('error', err, 2000);
    }
  };

  return (
    <div>
      <div className={classes['delivery-speed-container']}>
        <h3>1. Delivery Speed</h3>

        <div className={classes['delivery-option-container']}>
          <input checked type={'radio'} className={classes['checkbox']} />
          <div className={classes['delivery-option-text-container']}>
            <p className={classes['delivery-option']}>
              <span className={classes['standard-delivery-text']}>
                Standard delivery
              </span>
              <span className={classes['free-text']}> Free</span>
            </p>
            <p className={classes['may-take-text']}>May take up to 7 days</p>
          </div>
        </div>

        <h3 className={classes['summary-text']}>2. Summary</h3>

        <div className={classes['summary-description']}>
          <div className={classes['fee-container']}>
            <p className={classes['fee-description']}>Video Fee</p>
            <p className={classes['fee-price']}>
              $
              {getFormattedNumber(
                ((0.9 * nftFee).toFixed(2) * glmsToUsd.price).toFixed(2)
              )}
            </p>
            {/* <p className={classes['fee-price']}>
              {getFormattedNumber((0.9 * nftFee).toFixed(2))} GLMS ≈ $
              {getFormattedNumber(
                ((0.9 * nftFee).toFixed(2) * glmsToUsd.price).toFixed(2)
              )}
            </p> */}
          </div>

          <div className={classes['fee-container']}>
            <p className={classes['fee-description']}>Service Fee</p>
            <p className={classes['fee-price']}>
              $
              {getFormattedNumber(
                ((0.1 * nftFee).toFixed(2) * glmsToUsd.price).toFixed(2)
              )}
            </p>
            {/* <p className={classes['fee-price']}>
              {getFormattedNumber((0.1 * nftFee).toFixed(2))} GLMS ≈ $
              {getFormattedNumber(
                ((0.1 * nftFee).toFixed(2) * glmsToUsd.price).toFixed(2)
              )}
            </p> */}
          </div>
        </div>

        <div className={classes['horizontal-line']}></div>

        <div className={classes['total-container']}>
          <p className={classes['total-description']}>Total</p>
          <p className={classes['total-price']}>
            $
            {getFormattedNumber(
              (nftFee.toFixed(2) * glmsToUsd.price).toFixed(2)
            )}
          </p>
          {/* <p className={classes['total-price']}>
            {getFormattedNumber(nftFee.toFixed(2))} GLMS ≈ $
            {getFormattedNumber(
              (nftFee.toFixed(2) * glmsToUsd.price).toFixed(2)
            )}
          </p> */}
        </div>

        <div className={classes['actions-container']}>
          <Button
            disabled={requestingNft}
            onClick={goBackHandler}
            style={{ width: 200 }}
            darkpinkcolor="true"
          >
            Go Back
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={requestingNft}
            onClick={bookNftRequestHandler}
            darkpink="true"
          >
            Book Now
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PaymentInfo;
