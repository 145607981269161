import React, { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import LoadingSpinner from '../../../../../../../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import { AuthContext } from '../../../../../../../../../../../../../../context/auth-context';
import {
  likeUserPostCommentReplyRequest,
  removeUserPostCommentReplyLikeRequest,
  getMyPostCommentReplyLikeStatusRequest,
  getNumberOfLikesForSpecificUserPostCommentReplyRequest,
} from '../../../../../../../../../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../../../../../../../../../utils/notify';
import classes from './PostCommentReplyLikeText.module.css';

function PostCommentReplyLikeText(props) {
  const queryClient = useQueryClient();
  const { replyId } = props;
  const { profile } = useContext(AuthContext);

  const { isLoading, data } = useQuery(
    ['my-post-comment-reply-like-status', replyId],
    () => getMyPostCommentReplyLikeStatusRequest(replyId, profile?._id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: numberOfUserPostCommentReplyLikesData } = useQuery(
    ['number-of-user-post-comment-reply-likes', replyId],
    () => getNumberOfLikesForSpecificUserPostCommentReplyRequest(replyId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const invalidateNumberOfUserPostCommentReplyLikes = () => {
    queryClient.invalidateQueries([
      'number-of-user-post-comment-reply-likes',
      replyId,
    ]);
    queryClient.refetchQueries([
      'number-of-user-post-comment-reply-likes',
      replyId,
    ]);
  };

  const invalidateMyUserPostCommentReplyLikeStatus = () => {
    queryClient.invalidateQueries([
      'my-post-comment-reply-like-status',
      replyId,
    ]);
    queryClient.refetchQueries(['my-post-comment-reply-like-status', replyId]);
  };

  const likeUserPostCommentReplyHandler = async () => {
    try {
      await likeUserPostCommentReplyRequest(replyId);
      invalidateMyUserPostCommentReplyLikeStatus();
      invalidateNumberOfUserPostCommentReplyLikes();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const removeUserPostCommentReplyLikeHandler = async () => {
    try {
      await removeUserPostCommentReplyLikeRequest(replyId);
      invalidateMyUserPostCommentReplyLikeStatus();
      invalidateNumberOfUserPostCommentReplyLikes();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const renderLikeTextAccordingly = () => {
    if (data.data === null) {
      return (
        <p
          className={classes['like']}
          onClick={likeUserPostCommentReplyHandler}
        >
          Like ({numberOfUserPostCommentReplyLikesData.data})
        </p>
      );
    } else {
      return (
        <p
          className={classes['liked']}
          onClick={removeUserPostCommentReplyLikeHandler}
        >
          Liked ({numberOfUserPostCommentReplyLikesData.data})
        </p>
      );
    }
  };
  return !isLoading && data && numberOfUserPostCommentReplyLikesData ? (
    <div style={{ display: 'inline-block' }}>{renderLikeTextAccordingly()}</div>
  ) : (
    <LoadingSpinner width={20} />
  );
}

export default PostCommentReplyLikeText;
