import React, { useState } from 'react';
import classes from './UserImageAlbum.module.css';
import MoreIcon from '../../../../../../../../assets/gray-menu-options.png';
import notify from '../../../../../../../../utils/notify';
import { deleteAlbumRequest } from '../../../../../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import { limitString } from '../../../../../../../../helpers/helpers';

function UserImageAlbum(props) {
  const queryClient = useQueryClient();
  const {
    album,
    setSelectedAlbumId,
    setShowAlbumPhotos,
    setPhotosOptions,
    setSelectedPhotoOption,
  } = props;

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const selectAlbumHandler = () => {
    setPhotosOptions(['Albums', 'Album Photos']);
    setSelectedPhotoOption('Album Photos');
    setSelectedAlbumId(album._id);
    setShowAlbumPhotos(true);
  };

  const renderAlbumFirstImage = () => {
    if (album.images.length === 0) {
      return (
        <div
          className={`${classes['empty-image']} ${classes['user-image']}`}
        ></div>
      );
    } else {
      return (
        <img
          alt="ImageAlbum"
          src={album.images[album.images.length - 1]}
          className={classes['user-image']}
        />
      );
    }
  };

  const toggleMoreOptionsHandler = () => {
    setShowMoreOptions((prevState) => !prevState);
  };

  const deleteAlbumHandler = async () => {
    try {
      await deleteAlbumRequest(album._id);
      queryClient.invalidateQueries('my-user-image-albums');
      queryClient.refetchQueries('my-user-image-albums');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div className={classes['album-container']}>
      {renderAlbumFirstImage()}

      <div
        onClick={toggleMoreOptionsHandler}
        className={classes['more-container']}
      >
        <img alt="More" src={MoreIcon} className={classes['more-icon']} />

        <div
          style={{ visibility: !showMoreOptions ? 'hidden' : 'visible' }}
          className={classes['options']}
        >
          <p onClick={selectAlbumHandler} className={classes['view-album']}>
            View Album
          </p>
          <p onClick={deleteAlbumHandler} className={classes['delete-text']}>
            Delete
          </p>
        </div>
      </div>

      <div className={classes['album-name-container']}>
        <p title={album.name} className={classes['album-name']}>
          {limitString(album.name, 14)} ({album.images.length})
        </p>
      </div>
    </div>
  );
}

export default UserImageAlbum;
