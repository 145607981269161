import React, { useContext, useEffect } from 'react';
import classes from './GlmsTransactions.module.css';
import { useQuery } from 'react-query';
import { getMyGlmsTransactionsRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { AuthContext } from '../../../../context/auth-context';
import moment from 'moment';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useHistory } from 'react-router-dom';

function GlmsTransactions() {
  const history = useHistory();
  const { profile } = useContext(AuthContext);
  const { isLoading, data, error } = useQuery(
    'my-glms-transactions',
    getMyGlmsTransactionsRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const renderTransactionDate = (createdAt) => {
    return (
      <p className={classes['transaction-date']}>
        {moment(createdAt).format('MMMM Do, YYYY')}
      </p>
    );
  };

  const copyWalletAddress = async (walletAddress) => {
    try {
      await navigator.clipboard.writeText(walletAddress);

      notify('success', 'Copied successfully', 2000);
    } catch (err) {}
  };

  const renderSentTo = (transaction, isStripeTransaction) => {
    if (isStripeTransaction)
      return <p className={classes['sent-to']}>{profile?.fullName}</p>;

    const fromMe = transaction.from._id === profile?._id;

    if (!fromMe)
      return <p className={classes['sent-to']}>{profile?.fullName}</p>;

    return (
      <p
        onClick={() => copyWalletAddress(transaction.to)}
        title={transaction.to}
        className={classes['sent-to']}
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
      >{`${transaction.to.substring(0, 5)}...${transaction.to.substring(
        transaction.to.length - 5
      )}`}</p>
    );
  };

  const renderReceivedFrom = (transaction, isStripeTransaction) => {
    if (isStripeTransaction) return <p>GLIMPSE</p>;

    const fromMe = transaction.from._id === profile?._id;
    if (fromMe)
      return <p className={classes['received-from']}>{profile?.fullName}</p>;

    return (
      <p
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => history.push(`/user-feed/${transaction.from._id}`)}
        className={classes['received-from']}
      >
        {transaction.from.fullName}
      </p>
    );
  };

  const renderUsdAmount = (transaction, isStripeTransaction) => {
    if (isStripeTransaction)
      return (
        <p className={classes['green-price']}>+ {transaction.usdAmount} USD</p>
      );

    const fromMe = transaction.from._id === profile?._id;

    if (fromMe) {
      return (
        <p className={classes['red-price']}>
          - {(transaction.amount * 0.01).toFixed(2)} USD
        </p>
      );
    } else {
      return (
        <p className={classes['green-price']}>
          + {(transaction.amount * 0.01).toFixed(2)} USD
        </p>
      );
    }
  };

  const renderGlmsAmount = (transaction, isStripeTransaction) => {
    if (isStripeTransaction)
      return (
        <p className={classes['green-price']}>+ {transaction.glmsAmount}</p>
      );

    const fromMe = transaction.from._id === profile?._id;

    if (fromMe) {
      return (
        <p className={classes['red-price']}>- {transaction.amount} GLMS</p>
      );
    } else {
      return (
        <p className={classes['green-price']}>+ {transaction.amount} GLMS</p>
      );
    }
  };

  const getSortedTransactions = (transactions) => {
    const sortedTransactions = [...transactions];

    sortedTransactions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return sortedTransactions;
  };

  return (
    <div className={classes['transactions-container']}>
      <h3 className={classes['transactions-text']}>Transactions</h3>

      <div className={classes['table-horizontal-line']}></div>

      <div className={classes['transactions-table']}>
        <p>ACTION</p>
        <p>SENT TO</p>
        <p>RECEIVED FROM</p>
        <p>USD AMOUNT</p>
        <p>GLMS AMOUNT</p>
        <p>DATE</p>

        <div className={classes['table-horizontal-line']}></div>

        {!isLoading && data && data.results === 0 && (
          <p style={{ opacity: '.4', gridColumn: '1/-1' }}>
            No Transactions Found!
          </p>
        )}

        {!isLoading &&
          data &&
          data.glmsTransfers &&
          getSortedTransactions([
            ...data.glmsTransfers,
            ...data.stripeGlmsTransactions,
          ]).map((transaction) => {
            const isStripeTransaction = transaction.stripeTransaction;

            return (
              <div key={transaction._id} className={classes['transaction']}>
                <p>{isStripeTransaction ? 'Buy' : 'Transfer'}</p>
                {renderSentTo(transaction, isStripeTransaction)}
                {renderReceivedFrom(transaction, isStripeTransaction)}
                {renderUsdAmount(transaction, isStripeTransaction)}
                {renderGlmsAmount(transaction, isStripeTransaction)}
                {renderTransactionDate(transaction.createdAt)}
              </div>
            );
          })}

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default GlmsTransactions;
