import React from 'react';
import classes from './NftSections.module.css';

function NftSections(props) {
  const { selectedSection, setSelectedSection, isCollectionClaimable } = props;

  return (
    <div className={classes['items-activity-container']}>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => setSelectedSection('Listed Items')}
      >
        <p
          className={`${classes['items-text']} ${
            selectedSection === 'Listed Items' && classes['selected-section']
          }`}
        >
          Listed Items
        </p>
        {selectedSection === 'Listed Items' && (
          <div className={classes['selected-border']}>&nbsp;</div>
        )}
      </div>

      <div
        style={{ cursor: 'pointer' }}
        onClick={() => setSelectedSection('Not Listed Items')}
      >
        <p
          className={`${classes['items-text']} ${
            selectedSection === 'Not Listed Items' &&
            classes['selected-section']
          }`}
        >
          Not Listed Items
        </p>
        {selectedSection === 'Not Listed Items' && (
          <div className={classes['selected-border']}>&nbsp;</div>
        )}
      </div>

      {isCollectionClaimable && (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => setSelectedSection('Claimable Items')}
        >
          <p
            className={`${classes['items-text']} ${
              selectedSection === 'Claimable Items' &&
              classes['selected-section']
            }`}
          >
            Claimable Items
          </p>
          {selectedSection === 'Claimable Items' && (
            <div className={classes['selected-border']}>&nbsp;</div>
          )}
        </div>
      )}
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => setSelectedSection('Activity')}
      >
        <p
          className={`${classes['activity-text']} ${
            selectedSection === 'Activity' && classes['selected-section']
          }`}
        >
          Activity
        </p>

        {selectedSection === 'Activity' && (
          <div
            className={`${classes['selected-border']}
      ${selectedSection === 'Activity' && classes['selected-section']}
  `}
          >
            &nbsp;
          </div>
        )}
      </div>
    </div>
  );
}

export default NftSections;
