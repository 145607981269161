import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Modal from '../../../../components/Modal/Modal';
import classes from './ChatInfoModal.module.css';
import ArrowDownIcon from '../../../../assets/arrowDown.png';
import ArrowUpIcon from '../../../../assets/arrowUp.png';
import { AuthContext } from '../../../../context/auth-context';
import MoreIcon from '../../../../assets/more.png';
import OrganizerIcon from '../../../../assets/organizer.png';
import { useHistory } from 'react-router-dom';
import ReportUserModal from '../../../../components/ReportUserModal/ReportUserModal';
import PlusIcon from '../../../../assets/plusIcon.png';
import {
  updateChatMembersRequest,
  getMyPaginatedFriendsRequest,
  removeMemberFromChatRequest,
  editChatNameRequest,
} from '../../../../httpRequests/httpRequests';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import notify from '../../../../utils/notify';
import { limitString } from '../../../../helpers/helpers';
import PinkRemoveIcon from '../../../../assets/pink-remove.png';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import VerifiedIcon from '../../../../assets/verified.png';

function ChatInfoModal({
  selectedChat,
  setShowChatInfoModal,
  setSelectedChat,
}) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const moreIconRef = useRef();
  const { profile } = useContext(AuthContext);
  const [showGroupDetails, setShowGroupDetails] = useState(true);
  const [showGroupMembers, setShowGroupMembers] = useState(true);
  const [chatName, setChatName] = useState(selectedChat.name);
  const [optionsState, setOptionsState] = useState({
    opened: false,
    user: undefined,
  });
  const [reportUserModalState, setReportUserModalState] = useState({
    opened: false,
    user: undefined,
  });
  const [showAddNewMember, setShowAddNewMember] = useState(false);

  useEffect(() => {
    const checkForClosingMoreOptions = (e) => {
      if (!e.target?.id || !e.target?.id.startsWith('moreIcon-')) {
        setOptionsState({
          opened: false,
          user: undefined,
        });
      }
    };

    window.addEventListener('click', checkForClosingMoreOptions);

    return () =>
      window.removeEventListener('click', checkForClosingMoreOptions);
  }, []);

  const closeModalHandler = () => {
    setShowChatInfoModal(false);
  };

  const chatNameChangeHandler = (e) => {
    setChatName(e.target.value);
  };

  const showOptionsHandler = (user) => {
    if (optionsState.opened === false || optionsState.user._id !== user._id) {
      setOptionsState({
        opened: true,
        user,
      });
    } else {
      setOptionsState({
        opened: false,
        user: undefined,
      });
    }
  };

  const openReportUserModalHandler = (user) => {
    setReportUserModalState({
      opened: true,
      user,
    });
  };

  const showAddNewMemberHandler = () => {
    setShowAddNewMember(true);
  };

  const chatNameInputStyle = {};
  if (chatName.length === 0) {
    chatNameInputStyle['border'] = '1px solid red';
  }

  const RESULTS_PER_PAGE = 3;
  const friendsContainerRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const [
    selectedUsersToJoinTheGroup,
    setSelectedUsersToJoinTheGroup,
  ] = useState(selectedChat.users);

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-paginated-friends', searchValue],
    ({ pageParam = 1 }) =>
      getMyPaginatedFriendsRequest(pageParam, RESULTS_PER_PAGE, searchValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    const friendsContainerRefElement = friendsContainerRef.current;
    if (!friendsContainerRefElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    friendsContainerRefElement.addEventListener('scroll', scrollHandler);

    return () =>
      friendsContainerRefElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  const searchValueChangeHandler = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const selectUserToJoinGroupHandler = (user) => {
    setSelectedUsersToJoinTheGroup((prevState) => [...prevState, user]);
  };

  const removeSelectedUserFromJoiningGroup = (userId) => {
    setSelectedUsersToJoinTheGroup((prevState) =>
      prevState.filter(({ _id }) => _id !== userId)
    );
  };

  const checkIfThereAreNoMoreUsersToJoinGroup = useCallback(() => {
    const allFriends = [];
    data.pages.forEach((page) => {
      page.data.forEach((user) => allFriends.push(user));
    });

    return allFriends.every(({ follower }) =>
      selectedUsersToJoinTheGroup.some(({ _id }) => _id === follower._id)
    );
  }, [data, selectedUsersToJoinTheGroup]);

  const kickUserHandler = async (user) => {
    try {
      const requestBody = {
        userId: user._id,
      };
      const { data: updatedChat } = await removeMemberFromChatRequest(
        selectedChat._id,
        requestBody
      );
      setSelectedChat(updatedChat);
    } catch (err) {
      notify('error', error, 2000);
    }
  };

  const addNewMembersHandler = async () => {
    const isGroupChatNameChanged = selectedChat.name !== chatName;

    if (isGroupChatNameChanged) {
      const editChatNameRequestBody = {
        name: chatName,
      };
      await editChatNameRequest(selectedChat._id, editChatNameRequestBody);
    }

    try {
      const requestBody = {
        members: selectedUsersToJoinTheGroup.map((user) => user._id),
      };
      const { data: updatedChat } = await updateChatMembersRequest(
        selectedChat._id,
        requestBody
      );
      await Promise.all([
        queryClient.invalidateQueries('my-chats'),
        queryClient.refetchQueries('my-chats'),
      ]);
      setSelectedChat(updatedChat);

      closeModalHandler();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  return (
    <>
      {reportUserModalState.opened && (
        <ReportUserModal
          userId={reportUserModalState.user._id}
          user={reportUserModalState.user}
          setShowReportUserModal={() =>
            setReportUserModalState({ opened: false, user: undefined })
          }
        />
      )}

      {!reportUserModalState.opened && (
        <Modal
          style={{
            height: 700,
            width: 600,
          }}
          footer={
            <div className={classes['footer']}>
              <Button onClick={closeModalHandler} pinkcolor="true">
                Cancel
              </Button>

              <Button pink="true" onClick={addNewMembersHandler}>
                {showAddNewMember ? 'Add' : 'Save'}
              </Button>
            </div>
          }
          closeModal={closeModalHandler}
          title="Group Information"
        >
          <>
            <div className={classes['group-details-container']}>
              <div className={classes['group-details-arrow']}>
                <p className={classes['group-details-text']}>Group Details</p>
                <img
                  alt="Arrow"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowGroupDetails((prevState) => !prevState)}
                  src={showGroupDetails ? ArrowUpIcon : ArrowDownIcon}
                  width={10}
                />
              </div>
              {showGroupDetails && (
                <>
                  <div className={classes['group-details']}>
                    <div className={classes['images']}>
                      {selectedChat.users.length > 3 && (
                        <p className={classes['others-text']}>
                          + {selectedChat.users.length - 3}{' '}
                          {selectedChat.users.length - 3 === 1
                            ? 'other'
                            : 'others'}
                        </p>
                      )}

                      {selectedChat.users.slice(0, 3).map((user) => (
                        <img
                          key={user._id}
                          alt={user.fullName}
                          style={{ marginRight: -10 }}
                          src={user.photo}
                          width={50}
                          height={50}
                          className={classes['user-image']}
                        />
                      ))}
                    </div>
                  </div>
                  <p className={classes['group-name-text']}>Group Name</p>
                  <Input
                    placeholder="Enter here"
                    style={{ ...chatNameInputStyle }}
                    value={chatName}
                    disabled={selectedChat.organizer !== profile?._id}
                    onChange={chatNameChangeHandler}
                  />
                </>
              )}
            </div>

            <div className={classes['horizontal-line']}></div>

            <div className={classes['group-members-container']}>
              <div className={classes['group-members-arrow']}>
                <p>Group Members</p>
                <img
                  alt="Arrow"
                  src={showGroupMembers ? ArrowUpIcon : ArrowDownIcon}
                  width={10}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowGroupMembers((prevState) => !prevState)}
                />
              </div>

              {showGroupMembers && (
                <div className={classes['group-members']}>
                  {selectedChat.users.map((user) => {
                    return (
                      <div
                        key={user._id}
                        className={classes['member-container']}
                      >
                        <div className={classes['member']}>
                          <img
                            alt={user.fullName}
                            src={user.photo}
                            className={classes['user-image']}
                          />
                          <p className={classes['user-fullName']}>
                            {user.fullName}
                            {user.verified && (
                              <img
                                alt="Verified"
                                src={VerifiedIcon}
                                style={{
                                  height: 16,
                                  marginLeft: 6,
                                  marginBottom: 2,
                                }}
                              />
                            )}
                          </p>

                          {user._id === selectedChat.organizer && (
                            <img
                              alt="Organizer"
                              style={{ marginLeft: -5 }}
                              src={OrganizerIcon}
                              width={34}
                            />
                          )}
                        </div>
                        {profile?._id !== user._id && (
                          <div
                            className={classes['more-icon-options-container']}
                          >
                            <img
                              ref={moreIconRef}
                              id={`moreIcon-${user._id}`}
                              onClick={() => showOptionsHandler(user)}
                              style={{ cursor: 'pointer' }}
                              src={MoreIcon}
                              height={20}
                              alt="More"
                            />
                            {optionsState.opened &&
                              optionsState.user._id === user._id && (
                                <div className={classes['options-container']}>
                                  <p
                                    onClick={() =>
                                      openReportUserModalHandler(user)
                                    }
                                    className={classes['option']}
                                  >
                                    Report
                                  </p>
                                  <p
                                    onClick={() =>
                                      history.push(`/user-feed/${user._id}`)
                                    }
                                    className={classes['option']}
                                  >
                                    View Profile
                                  </p>
                                  <p
                                    onClick={() => kickUserHandler(user)}
                                    className={classes['kick']}
                                  >
                                    Kick
                                  </p>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {profile?._id === selectedChat.organizer && !showAddNewMember && (
                <div className={classes['add-new-member-container']}>
                  <div
                    onClick={showAddNewMemberHandler}
                    className={classes['pink-circle']}
                  >
                    <img
                      alt="Plus"
                      src={PlusIcon}
                      className={classes['plus-icon']}
                    />
                  </div>
                  <p className={classes['add-member']}>Add New Member</p>
                </div>
              )}

              {showAddNewMember && (
                <div>
                  <p className={classes['add-member-input-text']}>Add Member</p>
                  <div className={classes['input-selected-users-container']}>
                    <p className={classes['to-text']}>Members:</p>

                    <div
                      className={
                        classes['selected-users-to-join-group-container']
                      }
                    >
                      {selectedUsersToJoinTheGroup.slice(0, 1).map((user) => (
                        <div
                          key={user._id}
                          className={classes['selected-user-container']}
                        >
                          <p className={classes['selected-user-text']}>
                            {limitString(user.fullName, 12)}
                          </p>
                          {profile?._id !== user._id && (
                            <img
                              alt="Remove"
                              className={classes['remove-icon']}
                              onClick={() =>
                                removeSelectedUserFromJoiningGroup(user._id)
                              }
                              src={PinkRemoveIcon}
                              width={8}
                              height={9}
                            />
                          )}
                        </div>
                      ))}
                      {selectedUsersToJoinTheGroup.length > 1 && (
                        <div className={classes['selected-user-container']}>
                          <p className={classes['selected-user-text']}>
                            + {selectedUsersToJoinTheGroup.length - 1} others
                          </p>
                        </div>
                      )}
                    </div>

                    <Input
                      value={searchValue}
                      onChange={searchValueChangeHandler}
                    />

                    <div
                      ref={friendsContainerRef}
                      className={classes['friends-container']}
                    >
                      {data && data.pages && data.pages[0].results === 0 && (
                        <p className={classes['no-users-found']}>
                          No Users Found
                        </p>
                      )}
                      {data &&
                        data.pages &&
                        data.pages[0].results !== 0 &&
                        checkIfThereAreNoMoreUsersToJoinGroup() && (
                          <p className={classes['no-users-found']}>
                            There are no more users to join the group
                          </p>
                        )}
                      {data &&
                        data.pages &&
                        data.pages.map((page) => {
                          return page.data.map(({ follower }) => {
                            const isUserSelectedToJoinGroup = selectedUsersToJoinTheGroup.some(
                              (user) => user._id === follower._id
                            );
                            if (isUserSelectedToJoinGroup) return null;

                            return (
                              <div
                                onClick={() =>
                                  selectUserToJoinGroupHandler(follower)
                                }
                                key={follower._id}
                                className={classes['friend-container']}
                              >
                                <img
                                  className={classes['user-image']}
                                  src={follower.photo}
                                  width={40}
                                  height={40}
                                  alt={follower.fullName}
                                />
                                <p className={classes['follower-fullName']}>
                                  {follower.fullName}
                                </p>
                              </div>
                            );
                          });
                        })}
                      {(isLoading || isFetching) && <LoadingSpinner />}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </Modal>
      )}
    </>
  );
}

export default ChatInfoModal;
