import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  getGroupAdminsRequest,
  getNumberOfMembersForASpecificGroupRequest,
  getSpecificGroupRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './GroupAbout.module.css';
import PrivateIcon from '../../../../assets/private-1.png';
import GeneralIcon from '../../../../assets/general.png';
import PublicIcon from '../../../../assets/public.png';
import { getDateFrom } from '../../../../helpers/helpers';

function GroupAbout(props) {
  const { selectedGroup } = props;

  const { data, error } = useQuery(['groups', selectedGroup._id], () =>
    getSpecificGroupRequest(selectedGroup._id)
  );

  const numberOfMembersResponse = useQuery(
    ['number-of-members', selectedGroup._id],
    () => getNumberOfMembersForASpecificGroupRequest(selectedGroup._id)
  );

  const groupAdminsResponse = useQuery(
    ['group-admins', selectedGroup._id],
    () => getGroupAdminsRequest(selectedGroup._id)
  );

  const groupAdmins = useMemo(() => {
    if (!groupAdminsResponse.data?.data) return [];

    return groupAdminsResponse.data.data.map(({ user }) => user);
  }, [groupAdminsResponse]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['group-about-container']}>
      <div>
        <h3 className={classes['introduction-text']}>Introduction</h3>
        <p className={classes['introduction-content']}>{data?.data.about}</p>
      </div>

      <div className={classes['about-container']}>
        <h3 className={classes['about-text']}>About</h3>
        {data?.data && (
          <div className={classes['status-container']}>
            <img
              src={data.data.status === 'private' ? PrivateIcon : PublicIcon}
              alt="Private"
              className={classes['private-icon']}
            />

            <div>
              <p className={classes['status-text']}>
                {data.data.status.substring(0, 1).toUpperCase() +
                  data.data.status.substring(1)}{' '}
              </p>
              <p className={classes['only-members']}>
                {data.data.status === 'public'
                  ? ` Anyone can see who's in the group and what they post.`
                  : `Only members can see who’s in the group and what they post`}
              </p>
            </div>
          </div>
        )}

        {/* {data?.data && (
          <div className={classes['status-container']}>
            <img
              src={
                data.data.visibility === 'visible' ? VisibleIcon : HiddenIcon
              }
              alt="Private"
              className={classes['visibility-icon']}
            />

            <div>
              <p className={classes['visible-text']}>
                {data.data.visibility.substring(0, 1).toUpperCase() +
                  data.data.visibility.substring(1)}
              </p>
              <p className={classes['anyone-text']}>
                {data.data.visibility === 'visible'
                  ? `Anyone can find who’s in the group and what they post`
                  : 'No one can find this group'}
              </p>
            </div>
          </div>
        )} */}

        <div className={classes['general-container']}>
          <img
            alt="General"
            src={GeneralIcon}
            className={classes['general-icon']}
          />
          <p className={classes['general-text']}>General</p>
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <div className={classes['members-container']}>
          <h3 className={classes['members-text']}>Members</h3>
          <p className={classes['circle']}></p>
          <p className={classes['members-count-text']}>
            {numberOfMembersResponse.data?.data}
          </p>
        </div>

        <div className={classes['admins-list']}>
          {groupAdmins.slice(0, 5).map((admin, i) => (
            <img
              key={admin._id}
              style={{ zIndex: 11 - i }}
              src={admin.photo}
              alt="Admin"
              className={classes['user-image']}
            />
          ))}
        </div>
        <p className={classes['admin-numbers']}>
          {groupAdmins.length} {groupAdmins.length === 1 ? 'admin' : 'admins'}{' '}
          in this group
        </p>
      </div>

      <div>
        <h3>Activity</h3>

        <div className={classes['general-createdAt']}>
          <img
            alt="General"
            src={GeneralIcon}
            className={classes['general-icon']}
          />
          <p className={classes['createdAt-text']}>
            Created {data?.data && `${getDateFrom(data.data.createdAt)} ago`}
          </p>
        </div>

        {data?.data?.lastActivity && (
          <div className={classes['general-createdAt']}>
            <p className={classes['createdAt-text']}>
              Last Activity {`${getDateFrom(data.data.lastActivity)} ago`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default GroupAbout;
