import React, { useState, useEffect, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getPaginatedMutualFollowersRequest } from '../../httpRequests/httpRequests';
import classes from './MutualFollowersModal.module.css';
import User from '../User/User';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import Input from '../Input/Input';
import notify from '../../utils/notify';

const RESULTS_PER_PAGE = 10;

function MutualFollowersModal(props) {
  const mutualFollowersContainerRef = useRef();

  const { userId, setShowMutualFollowersModal } = props;

  const [searchInputValue, setSearchInputValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['paginated-mutual-followers', userId, searchInputValue],
    ({ pageParam = 1 }) =>
      getPaginatedMutualFollowersRequest(
        userId,
        pageParam,
        RESULTS_PER_PAGE,
        searchInputValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const mutualFollowersContainerElement = mutualFollowersContainerRef.current;

    if (!mutualFollowersContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    mutualFollowersContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      mutualFollowersContainerElement?.removeEventListener(
        'scroll',
        scrollHandler
      );
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const closeModalHandler = () => {
    setShowMutualFollowersModal(false);
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: '100%' }}
            onClick={closeModalHandler}
            pinkcolor="true"
          >
            Close
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Mutual Followers"
    >
      <>
        <Input
          style={{ width: '100%' }}
          placeholder="Search user"
          value={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
        />
        <div
          ref={mutualFollowersContainerRef}
          className={classes['mutual-followers-list']}
        >
          {data &&
            data.pages &&
            data.pages.map((page) => {
              return page.data.map(({ mutualFollower }) => {
                return <User user={mutualFollower} key={mutualFollower._id} />;
              });
            })}

          {data && data.pages && data.pages[0].results === 0 && (
            <p>No Results Found</p>
          )}

          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </>
    </Modal>
  );
}

export default MutualFollowersModal;
