import React from 'react';
import classes from './CreateGroupForm.module.css';
import Input from '../../../../components/Input/Input';
import TextArea from '../../../../components/TextArea/TextArea';
import notify from '../../../../utils/notify';
import SliderButton from '../../../../components/SliderButton/SliderButton';
import UploadIcon from '../../../../assets/upload.png';
import ImageUpload from '../../../../components/ImageUpload/ImageUpload';
import GreenOkIcon from '../../../../assets/green-ok.png';

function CreateGroupForm(props) {
  const {
    coverPhotoPreviewUrl,
    setCoverPhotoUrl,
    setCoverPhotoPreviewUrl,
    groupName,
    setGroupName,
    groupNameTouched,
    setGroupNameTouched,
    groupUsername,
    setGroupUsername,
    groupUsernameTouched,
    setGroupUsernameTouched,
    about,
    setAbout,
    aboutTouched,
    setAboutTouched,
    isSelectedPrivacyPrivate,
    setIsSelectedPrivacyPrivate,
  } = props;

  const groupNameChangeHandler = (e) => {
    setGroupName(e.target.value);
    setGroupNameTouched(true);
  };

  const groupUsernameChangeHandler = (e) => {
    setGroupUsername(e.target.value);
    setGroupUsernameTouched(true);
  };

  const aboutChangeHandler = (e) => {
    setAbout(e.target.value);
    setAboutTouched(true);
  };

  const coverPhotoInputHandler = (value) => {
    if (!value) return;

    if (value.size > 40000000)
      return notify(
        'error',
        'You can not upload more than 40MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setCoverPhotoUrl(value);
    try {
      fileReader.onload = () => {
        setCoverPhotoPreviewUrl(fileReader.result);
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const groupNameInputStyle = { width: '100%' };
  if (groupNameTouched && groupName.length === 0) {
    groupNameInputStyle['border'] = '1px solid red';
  }

  const groupUsernameInputStyle = { width: '100%' };
  if (groupUsernameTouched && groupUsername.length === 0) {
    groupUsernameInputStyle['border'] = '1px solid red';
  }

  const aboutStyle = { width: '100%', minHeight: 100 };
  if (aboutTouched && about.length === 0) {
    aboutStyle['border'] = '1px solid red';
  }

  return (
    <>
      <div className={classes['create-group-form-container']}>
        <p className={classes['create-group-text']}>Add Group Details</p>

        <div>
          <p className={classes['label']}>Group Name</p>
          <Input
            value={groupName}
            onChange={groupNameChangeHandler}
            style={groupNameInputStyle}
            placeholder={'Enter here'}
          />
        </div>

        <div className={classes['group-username-container']}>
          <p className={classes['label']}>Group Username</p>
          <Input
            value={groupUsername}
            onChange={groupUsernameChangeHandler}
            style={groupUsernameInputStyle}
            placeholder={'Enter here'}
          />
        </div>

        <div className={classes['about-container']}>
          <p className={classes['label']}>About Info</p>
          <TextArea
            style={aboutStyle}
            placeholder="Enter here"
            value={about}
            onChange={aboutChangeHandler}
          />
        </div>

        <div className={classes['privacy-container']}>
          <SliderButton
            leftValue={<p className={classes['privacy-text']}>Private</p>}
            rightValue={<p className={classes['privacy-text']}>Public</p>}
            isLeftContentSelected={isSelectedPrivacyPrivate}
            setIsLeftContentSelected={setIsSelectedPrivacyPrivate}
          />
        </div>

        <ImageUpload onInput={coverPhotoInputHandler}>
          <div className={classes['upload-cover-photo-container']}>
            <img
              className={classes['upload-icon']}
              src={coverPhotoPreviewUrl ? GreenOkIcon : UploadIcon}
              alt="Upload"
            />
            <p className={classes['click-to-upload-text']}>
              {coverPhotoPreviewUrl
                ? 'Successfully Uploaded'
                : 'Click To Upload Cover Photo'}
            </p>
          </div>
        </ImageUpload>
      </div>
    </>
  );
}

export default CreateGroupForm;
