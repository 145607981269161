import React, { useState } from 'react';
import classes from './Select.module.css';
import GrayArrowDownIcon from '../../assets/gray-arrow-down.png';

function Select(props) {
  const { selectedValue, options, onChange, style } = props;
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div
      style={style ? style : {}}
      onClick={() => setShowOptions((prevState) => !prevState)}
      className={classes['select-container']}
    >
      <div className={classes['selected-value-container']}>
        <p className={classes['selected-value']}>{selectedValue}</p>
        <img alt="Arrow Down" src={GrayArrowDownIcon} width={9} />
      </div>

      {showOptions && (
        <div className={classes['options-container']}>
          {options.map((option) => (
            <p
              className={classes['option']}
              onClick={() => onChange(option)}
              key={option}
            >
              {option}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

export default Select;
