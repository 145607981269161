import React, { useEffect, useRef, useState } from 'react';
import classes from './BrandItems.module.css';
import MetaTags from 'react-meta-tags';
import { useInfiniteQuery } from 'react-query';
import { getAllBrandItemsListedForSaleRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import BrandItem from '../../components/BrandItem/BrandItem';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { limitString } from '../../helpers/helpers';
import BrightDownIcon from '../../assets/bright-down-icon.png';

const RESULTS_PER_PAGE = 12;

const trendingTags = [
  'Nike',
  'Under',
  'Armour',
  'Lululemon',
  'Chick-fil-A',
  'Starbucks',
  'Apple',
  'Coca Cola',
  'McDonalds',
  'Disney',
  'Tiffany & Co.',
  'Gucci',
  'Rolex',
  'Adidas',
  'H&M',
  'RedBull',
  'Puma',
  'Jordan',
];

function BrandItems() {
  const tagOptionsContainerRef = useRef();

  const [searchValue, setSearchValue] = useState('');
  const [showTagOptions, setShowTagOptions] = useState(false);
  const [selectedTagName, setSelectedTagName] = useState('All');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['brand-items-listed-for-sale', searchValue, selectedTagName],
    ({ pageParam = 1 }) =>
      getAllBrandItemsListedForSaleRequest(
        pageParam,
        RESULTS_PER_PAGE,
        searchValue,
        selectedTagName
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const tagChangeHandler = (tag) => {
    setSelectedTagName(tag);
    setShowTagOptions(false);
  };

  const renderTags = () => {
    return (
      <div className={classes['tags-container']}>
        <div
          className={classes['selected-tag-option-container']}
          onClick={() => setShowTagOptions((prevState) => !prevState)}
        >
          <p className={classes['tag-value']}>
            Tag:{' '}
            <span
              title={selectedTagName}
              className={classes['selected-tag-value']}
            >
              {selectedTagName && limitString(selectedTagName, 16)}
            </span>
          </p>
          <img
            style={{ marginLeft: 'auto' }}
            width={13}
            alt="See"
            src={BrightDownIcon}
          />
        </div>

        {showTagOptions && (
          <div
            ref={tagOptionsContainerRef}
            className={classes['tag-options-container']}
          >
            {['All', ...trendingTags].map((tag) => {
              let style = {};
              if (tag === selectedTagName) {
                style = {
                  color: '#263238 ',
                  fontWeight: 500,
                };
              }

              return (
                <p
                  title={`#${tag}`}
                  onClick={() => tagChangeHandler(tag)}
                  style={style}
                  className={classes['tag-text']}
                  key={tag}
                >
                  {`#${limitString(tag, 16)}`}
                </p>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <MetaTags>
        <title>Brands | Glimpse</title>
        <meta property="og:title" content={`Brands | Glimpse`} />
      </MetaTags>

      <div className={classes['brand-items-page-container']}>
        <div className={classes['filter-container']}>
          <input
            value={searchValue}
            onChange={searchValueChangeHandler}
            placeholder="Search by title or creator"
            className={classes['search-input']}
          />

          {renderTags()}
        </div>

        {!isLoading && data && data.pages && data.pages[0].results === 0 && (
          <div
            style={{
              background: 'white',
              padding: '.8rem',
              borderRadius: 10,
              marginTop: 10,
              width: '95%',
              gridColumn: '1/-1',
            }}
          >
            <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
              No Brands Found!
            </p>
          </div>
        )}
        <div className={classes['brand-items-list']}>
          {!isLoading &&
            data &&
            data.pages &&
            data.pages.map((page) => {
              return page.data.map((brandItem) => (
                <div key={brandItem._id} className={classes['nft-collection']}>
                  <BrandItem brandItem={brandItem} />
                  <div className={classes['horizontal-line']}></div>
                </div>
              ));
            })}
        </div>
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </>
  );
}

export default BrandItems;
