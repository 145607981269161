import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../context/auth-context';
import { isAdmin } from '../helpers/accessPermission';

function ProtectedRoute(props) {
  const { profile } = useContext(AuthContext);
  const { Component, accessCondition } = props;

  if (accessCondition === 'is-admin') {
    if (isAdmin(profile)) {
      return <Component {...props} />;
    }
  }

  return <Redirect to="/" />;
}

export default ProtectedRoute;
