import React, { useEffect } from 'react';
import classes from './MetaMaskNfts.module.css';
import { useInfiniteQuery } from 'react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import MetaMaskNft from '../../../../components/MetaMaskNft/MetaMaskNft';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import { getMetaMaskNftsForASpecificUserRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import MetaMaskNftsLockedImage from '../../../../assets/no-metamask-nfts-image.png';
import NoMetaMaskNftsImage from '../../../../assets/no-results.png';

const RESULTS_PER_PAGE = 10;

function MetaMaskNfts(props) {
  const { userProfile } = props;

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['specific-user-metamask-nfts', userProfile._id],
    ({ pageParam = 1 }) =>
      getMetaMaskNftsForASpecificUserRequest(
        userProfile._id,
        pageParam,
        RESULTS_PER_PAGE
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  if (data?.pages !== undefined && data.pages[0].access !== 'public') {
    return (
      <div className={classes['locked-nfts-container']}>
        <img height={150} src={MetaMaskNftsLockedImage} alt="Access Private" />
        <h2 style={{ marginTop: 10 }}>Metamask NFT’s are Locked!</h2>
        <p className={classes['private-text']}>
          Metamask NFTs are set to private by the user and you cannot access the
          private NFT’s.
        </p>
      </div>
    );
  }

  return (
    <div className={classes['metamask-nfts-container']}>
      {data?.pages && data?.pages[0].results === 0 && (
        <div className={classes['no-nfts-container']}>
          <img height={200} src={NoMetaMaskNftsImage} alt="No NFTs Found" />
          <h3 style={{ margin: 10 }}>No Metamask NFT’s Found!</h3>
          <p className={classes['get-back-text']}>
            Get Back In a while there might be something.
          </p>
        </div>
      )}
      <div className={classes['nft-list']}>
        {data?.pages &&
          data?.pages.map((page) => {
            return page.data.map(
              (
                { imageUrl, chain, collectionAddress, collectionName, name },
                i
              ) => {
                if (imageUrl && imageUrl.startsWith('https://'))
                  return (
                    <MetaMaskNft
                      key={i}
                      imageUrl={imageUrl}
                      chain={chain}
                      collectionAddress={collectionAddress}
                      collectionName={collectionName}
                      name={name}
                    />
                  );

                return null;
              }
            );
          })}
      </div>
      {(isLoading || isFetching) && <LoadingSpinner />}
    </div>
  );
}

export default MetaMaskNfts;
