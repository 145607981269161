import React from 'react';
import BoughtNftsStatistics from './components/BoughtNftsStatistics/BoughtNftsStatistics';
import SoldNftsStatistics from './components/SoldNftsStatistics/SoldNftsStatistics';
import classes from './UsersTransactionsStatistics.module.css';

function UserTransactionsStatistics() {
  return (
    <div className={classes['users-transactions-statistics-cotainer']}>
      <SoldNftsStatistics />
      <BoughtNftsStatistics />
    </div>
  );
}

export default UserTransactionsStatistics;
