import React, { useContext, useEffect, useState } from 'react';
import classes from './TransferNftModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { IPFS_URL } from '../../../../constants/IPFS';
import ReactPlayer from 'react-player';
import { limitString } from '../../../../helpers/helpers';
import Input from '../../../../components/Input/Input';
import {
  createTransferOfNftRequest,
  searchUsersByUsernameRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import VerifyAndConfirmTransferOfNftModal from '../VerifyAndConfirmTransferOfNftModal/VerifyAndConfirmTransferOfNftModal';
import { AuthContext } from '../../../../context/auth-context';

function TransferNftModal(props) {
  const { profile } = useContext(AuthContext);
  const { nft, setShowTransferNftModal, invalidateNft } = props;
  const [transferToUsername, setTransferToUsername] = useState('');
  const [transferToOptionsLoading, setTransferToOptionsLoading] = useState(
    false
  );
  const [transferToOptions, setTransferToOptions] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState('');
  const [
    showVerifyAndConfirmTransferOfNftModal,
    setShowVerifyAndConfirmTransferOfNftModal,
  ] = useState(false);
  const [gettingVerificationCode, setGettingVerificationCode] = useState(false);

  const closeModalHandler = () => {
    setShowTransferNftModal(false);
  };

  const transferToChangeHandler = (e) => {
    setTransferToUsername(e.target.value);
    setSelectedUsername(undefined);
  };

  const selectTransferToOptionHandler = (user) => {
    setSelectedUsername(user.username);
    setTransferToUsername(user.username);
  };

  useEffect(() => {
    const updateTransferToOptions = async () => {
      try {
        setTransferToOptionsLoading(true);
        const { data } = await searchUsersByUsernameRequest(transferToUsername);
        setTransferToOptions(data);
      } catch (err) {
        notify('error', err, 2000);
      }
      setTransferToOptionsLoading(false);
    };

    updateTransferToOptions();
  }, [transferToUsername]);

  const createTransferOfNftHandler = async () => {
    setGettingVerificationCode(true);

    try {
      const requestBody = {
        toUsername: selectedUsername,
      };
      await createTransferOfNftRequest(nft._id, requestBody);
      setShowVerifyAndConfirmTransferOfNftModal(true);
    } catch (err) {
      notify('error', err, 2000);
    }

    setGettingVerificationCode(false);
  };

  return (
    <>
      {showVerifyAndConfirmTransferOfNftModal && (
        <VerifyAndConfirmTransferOfNftModal
          setShowTransferNftModal={setShowTransferNftModal}
          toUsername={selectedUsername}
          nft={nft}
          setShowVerifyAndConfirmTransferOfNftModal={
            setShowVerifyAndConfirmTransferOfNftModal
          }
          invalidateNft={invalidateNft}
        />
      )}

      {!showVerifyAndConfirmTransferOfNftModal && (
        <Modal
          style={{ height: 550 }}
          closeModal={closeModalHandler}
          title={`Transfer NFT (${limitString(nft.title, 25)})`}
          footer={
            <div className={classes['footer']}>
              <Button
                style={{ width: 200 }}
                darkpinkcolor="true"
                onClick={closeModalHandler}
              >
                Cancel
              </Button>
              <Button
                style={{ width: '100%' }}
                disabled={!selectedUsername || gettingVerificationCode}
                darkpink="true"
                onClick={createTransferOfNftHandler}
              >
                Get Verification Code
              </Button>
            </div>
          }
        >
          <div className={classes['transfer-container']}>
            {nft.type === 'image' ? (
              <img
                alt="IPFS Hash"
                className={classes['nft-image']}
                src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
              />
            ) : (
              <ReactPlayer
                style={{
                  objectFit: 'cover',
                  borderRadius: 16,
                }}
                width={280}
                height={280}
                url={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
              />
            )}
          </div>

          <div className={classes['transfer-to-container']}>
            <p className={classes['label']}>Transfer To:</p>
            <Input
              style={{ width: '100%' }}
              placeholder="Enter Username"
              onChange={transferToChangeHandler}
              value={transferToUsername}
            />

            {!selectedUsername && (
              <div
                style={{ padding: transferToUsername.length === 0 && 0 }}
                className={classes['users-container']}
              >
                {transferToUsername.length > 0 ? (
                  transferToOptions.map((user) => {
                    if (user._id === profile?._id) return null;

                    return (
                      <div
                        key={user._id}
                        onClick={() => selectTransferToOptionHandler(user)}
                        className={classes['user-container']}
                      >
                        <img
                          alt={user.fullName}
                          src={user.photo}
                          className={classes['user-image']}
                        />
                        <p className={classes['user-text']}>
                          {user.username} <span>@{user.username}</span>
                        </p>
                      </div>
                    );
                  })
                ) : transferToUsername.length > 0 &&
                  transferToOptionsLoading === false ? (
                  <p className={classes['no-users-found']}>No Users Found</p>
                ) : (
                  transferToUsername.length > 0 && (
                    <p className={classes['loading-users']}>Loading Users...</p>
                  )
                )}
              </div>
            )}

            {/* <p className={classes['fee-text']}>250 GLMS Fee</p> */}
            <p className={classes['fee-text']}>0.25 USD Fee</p>
          </div>
        </Modal>
      )}
    </>
  );
}

export default TransferNftModal;
