import React, { useState, useEffect } from 'react';
import classes from './Events.module.css';
import { useInfiniteQuery } from 'react-query';
import { getAllEventsRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import Event from './components/Event/Event';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

const RESULTS_PER_PAGE = 10;

function Events() {
  const [searchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['events', searchValue],
    ({ pageParam = 1 }) =>
      getAllEventsRequest(pageParam, RESULTS_PER_PAGE, searchValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner />}
      <div className={classes['events-container']}>
        {!isLoading &&
          data?.pages &&
          data.pages.map((page) => {
            return page.data.map((event) => (
              <Event searchValue={searchValue} key={event._id} event={event} />
            ));
          })}
      </div>
    </>
  );
}

export default Events;
