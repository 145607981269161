import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import Modal from '../../../../components/Modal/Modal';
import {
  // createNftCollectionMappingRequest,
  createNftCollectionRequest,
  // createNftPartOfFungibleAgnosticCollectionRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import FifthScreen from './components/FifthScreen/FifthScreen';
import FirstScreen from './components/FirstScreen/FirstScreen';
import FourthScreen from './components/FourthScreen/FourthScreen';
import SecondScreen from './components/SecondScreen/SecondScreen';
import ThirdScreen from './components/ThirdScreen/ThirdScreen';
import moment from 'moment';
import HolderBenefitScreen from './components/HolderBenefitsScreen/HolderBenefitScreen';
import SingleOrMultipleUploadModal from './components/SingleOrMultipleUploadModal/SingleOrMultipleUploadModal';
import { useHistory, useLocation } from 'react-router-dom';

const GLMS_TO_USD_PRICE = 0.01;

function CreateCollectionModal(props) {
  const {
    setShowCreateCollectionModal,
    setCreatedCollectionLinkQRCodeModalState,
  } = props;
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const claimableParam = queryParams.get('claimable');

  const queryClient = useQueryClient();

  const [screenNumber, setScreenNumber] = useState(0);
  const [collectionName, setCollectionName] = useState('');
  const [collectionNameTouched, setCollectionNameTouched] = useState(false);
  const [categories, setCategories] = useState([]);
  const [description, setDescription] = useState('');
  const [descriptionTouched, setDescriptionTouched] = useState(false);

  const [telegramUrl, setTelegramUrl] = useState('');
  const [includeTelegramUrl, setIncudeTelegramUrl] = useState(false);
  const [isTelegramUrlValid, setIsTelegramUrlValid] = useState(false);

  const [twitterUrl, setTwitterUrl] = useState('');
  const [includeTwitterUrl, setIncudeTwitterUrl] = useState(false);
  const [isTwitterUrlValid, setIsTwitterUrlValid] = useState(false);

  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [includeLinkedinUrl, setIncudeLinkedinUrl] = useState(false);
  const [isLinkedinUrlValid, setIsLinkedinUrlValid] = useState(false);

  const [instagramUrl, setInstagramUrl] = useState('');
  const [includeInstagramUrl, setIncludeInstagramUrl] = useState(false);
  const [isInstagramUrlValid, setIsInstagramUrlValid] = useState(false);

  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [includeYoutubeUrl, setIncludeYoutubeUrl] = useState(false);
  const [isYoutubeUrlValid, setIsYoutubeUrlValid] = useState(false);

  const [linktreeUrl, setLinktreeUrl] = useState('');
  const [includeLinktreeUrl, setIncludeLinktreeUrl] = useState(false);
  const [isLinktreeUrlValid, setIsLinktreeUrlValid] = useState(false);

  const [imdbUrl, setImdbUrl] = useState('');
  const [includeImdbUrl, setIncludeImdbUrl] = useState(false);
  const [isImdbUrlValid, setIsImdbUrlValid] = useState(false);

  const [shareInGroup, setShareInGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();

  const [capped, setCapped] = useState(true);
  const [associatedGroups, setAssociatedGroups] = useState([]);
  const [liveDate, setLiveDate] = useState();
  const [isLiveDateSelected, setIsLiveDateSelected] = useState(false);

  const [coverPhotoUrl, setCoverPhotoUrl] = useState();
  const [coverPhotoPreviewUrl, setCoverPhotoPreviewUrl] = useState();
  const [isCollectionStatusPublic, setIsCollectionStatusPublic] = useState(
    true
  );
  const [startingPrice, setStartingPrice] = useState('');
  const [startingPriceTouched, setStartingPriceTouched] = useState(false);
  const [royalteFee, setRoyalteFee] = useState('');
  const [royalteFeeTouched, setRoyalteFeeTouched] = useState('');
  const [profilePhotoPreviewUrl, setProfilePhotoPreviewUrl] = useState();
  const [profilePhotoUrl, setProfilePhotoUrl] = useState();

  const [scheduleDay, setScheduleDay] = useState('');
  const [scheduleDayTouched, setScheduleDayTouched] = useState(false);
  const [isScheduleDayValid, setIsScheduleDayValid] = useState(false);
  const [scheduleMonth, setScheduleMonth] = useState('');
  const [scheduleMonthTouched, setScheduleMonthTouched] = useState(false);
  const [isScheduleMonthValid, setIsScheduleMonthValid] = useState(false);
  const [scheduleYear, setScheduleYear] = useState('');
  const [scheduleYearTouched, setScheduleYearTouched] = useState(false);
  const [isScheduleYearValid, setIsScheduleYearValid] = useState(false);
  const [scheduleHour, setScheduleHour] = useState(0);
  const [scheduleHourTouched, setScheduleHourTouched] = useState(false);
  const [isScheduleHourValid, setIsScheduleHourValid] = useState(false);
  const [scheduleMinutes, setScheduleMinutes] = useState(0);
  const [scheduleMinutesTouched, setScheduleMinutesTouched] = useState(false);
  const [isScheduleMinutesValid, setIsScheduleMinutesValid] = useState(false);
  const [scheduleSeconds, setScheduleSeconds] = useState();
  const [scheduleSecondsTouched, setScheduleSecondsTouched] = useState(false);
  const [isScheduleSecondsValid, setIsScheduleSecondsValid] = useState(false);
  const [isAMSelected, setIsAMSelected] = useState(true);

  const [uploadedNfts, setUploadedNfts] = useState([]);
  const [loadingIPFS, setLoadingIPFS] = useState(false);
  const [creatingCollection, setCreatingCollection] = useState(false);

  const [holderBenefits, setHolderBenefits] = useState([
    // {
    //   value: '',
    //   isValid: false,
    //   touched: false,
    // },
  ]);

  const [editionSize, setEditionSize] = useState('');
  const [editionSizeTouched, setEditionSizeTouched] = useState(false);

  const [claimable, setClaimable] = useState(
    claimableParam === '1' ? true : false
  );

  const [isSingleUploadSelected, setIsSingleUploadSeleted] = useState(false);

  const [isNftGatedCommunity, setIsNftGatedCommunity] = useState(false);

  const closeModalHandler = () => {
    setShowCreateCollectionModal(false);
    if (claimableParam) {
      history.push('/explore?tab=Collections');
    }
  };

  const createCollectionHandler = async () => {
    setCreatingCollection(true);
    try {
      const formData = new FormData();
      formData.append('name', collectionName);
      formData.append('description', description);
      formData.append('categories', JSON.stringify(categories));
      formData.append('associatedGroups', JSON.stringify(associatedGroups));
      formData.append(
        'holderBenefits',
        JSON.stringify(holderBenefits.map(({ value }) => value))
      );

      formData.append('isNftGatedCommunity', associatedGroups.length > 0);

      if (includeTelegramUrl) formData.append('telegramUrl', telegramUrl);
      if (includeTwitterUrl) formData.append('twitterUrl', twitterUrl);
      if (includeLinkedinUrl) formData.append('linkedinUrl', linkedinUrl);
      if (includeImdbUrl) formData.append('imdbUrl', imdbUrl);
      if (includeInstagramUrl) formData.append('instagramUrl', instagramUrl);
      if (includeLinktreeUrl) formData.append('linktreeUrl', linktreeUrl);
      if (includeYoutubeUrl) formData.append('youtubeUrl', youtubeUrl);

      const collectionStatus = isCollectionStatusPublic ? 'Public' : 'Private';
      formData.append('status', collectionStatus);
      if (coverPhotoUrl) formData.append('coverPhoto', coverPhotoUrl);
      if (profilePhotoUrl) formData.append('profilePhoto', profilePhotoUrl);

      if (shareInGroup && selectedGroup)
        formData.append('linkedGroup', selectedGroup._id);

      formData.append('capped', capped);
      if (isLiveDateSelected) {
        formData.append(
          'liveDate',
          moment(
            `${scheduleMonth}-${scheduleDay}-${scheduleYear} ${scheduleHour}:${scheduleMinutes}:${scheduleSeconds} ${
              isAMSelected ? 'AM' : 'PM'
            }`,
            'MM-DD-YYYY hh:mm:ss A'
          )
        );
      }
      formData.append('claimable', claimable);

      const editionSizeValue = isNaN(editionSize) ? 1 : parseInt(editionSize);
      if (
        editionSizeTouched &&
        uploadedNfts.length === 1 &&
        editionSizeValue > 1
      ) {
        const { ipfs, isVideo, url } = uploadedNfts[0];
        formData.append('editionSize', editionSizeValue);
        formData.append('isFungibilityAgnostic', true);
        formData.append('ipfs', ipfs);
        formData.append('category', categories[0]);
        formData.append(
          'nftAdditionalCategories',
          JSON.stringify(categories.slice(1))
        );
        formData.append('adultContent', false);
        formData.append('royalteFee', parseInt(royalteFee));

        formData.append(
          'buyNowPrice',
          parseFloat(startingPrice / GLMS_TO_USD_PRICE)
        );
        formData.append('type', isVideo ? 'video' : 'image');
        formData.append('previewUrl', url);

        const { data: createdCollection } = await createNftCollectionRequest(
          formData
        );

        await Promise.all([
          queryClient.invalidateQueries(['nft-collections', '']),
          queryClient.refetchQueries(['nft-collections', '']),
        ]);
        closeModalHandler();

        setCreatedCollectionLinkQRCodeModalState({
          collectionId: createdCollection._id,
          opened: true,
          claimable,
          collectionName,
        });

        return;
      }

      const uniqueUploadedNfts = [];
      const seenIpfs = {};
      uploadedNfts.forEach((nft) => {
        if (seenIpfs[nft.ipfs]) return;

        seenIpfs[nft.ipfs] = true;
        uniqueUploadedNfts.push(nft);
      });

      formData.append(
        'uniqueUploadedNfts',
        JSON.stringify(
          uniqueUploadedNfts.map(({ ipfs, isVideo }) => ({
            ipfs,
            isVideo,
          }))
        )
      );

      formData.append('category', categories[0]);
      formData.append(
        'nftAdditionalCategories',
        JSON.stringify(categories.slice(1))
      );
      formData.append('adultContent', false);
      formData.append('royalteFee', parseInt(royalteFee));

      formData.append(
        'buyNowPrice',
        parseFloat(startingPrice / GLMS_TO_USD_PRICE)
      );

      for (let i = 0; i < uniqueUploadedNfts.length; i++) {
        formData.append('previewUrls', uniqueUploadedNfts[i].url);
      }

      const { data: createdCollection } = await createNftCollectionRequest(
        formData
      );

      await Promise.all([
        queryClient.invalidateQueries(['nft-collections', '']),
        queryClient.refetchQueries(['nft-collections', '']),
      ]);
      closeModalHandler();

      setCreatedCollectionLinkQRCodeModalState({
        collectionId: createdCollection._id,
        opened: true,
        claimable,
        collectionName,
      });
    } catch (err) {
      notify('error', err, 2000);
    }

    setCreatingCollection(false);
  };

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Create Collection"
      closeIcon="true"
      style={{ maxHeight: 650, overflowY: 'auto' }}
    >
      <>
        {screenNumber === 0 && (
          <SingleOrMultipleUploadModal
            closeCreateCollectionModal={closeModalHandler}
            setScreenNumber={setScreenNumber}
            isSingleUploadSelected={isSingleUploadSelected}
            setIsSingleUploadSeleted={setIsSingleUploadSeleted}
          />
        )}

        {screenNumber === 1 && (
          <FirstScreen
            collectionName={collectionName}
            setCollectionName={setCollectionName}
            categories={categories}
            setCategories={setCategories}
            description={description}
            setDescription={setDescription}
            setScreenNumber={setScreenNumber}
            collectionNameTouched={collectionNameTouched}
            setCollectionNameTouched={setCollectionNameTouched}
            descriptionTouched={descriptionTouched}
            setDescriptionTouched={setDescriptionTouched}
          >
            {/* <CompletedSteps screenNumber={screenNumber} /> */}
          </FirstScreen>
        )}

        {screenNumber === 2 && (
          <SecondScreen
            isTelegramUrlValid={isTelegramUrlValid}
            setIsTelegramUrlValid={setIsTelegramUrlValid}
            setScreenNumber={setScreenNumber}
            telegramUrl={telegramUrl}
            setTelegramUrl={setTelegramUrl}
            includeTelegramUrl={includeTelegramUrl}
            setIncudeTelegramUrl={setIncudeTelegramUrl}
            twitterUrl={twitterUrl}
            setTwitterUrl={setTwitterUrl}
            isTwitterUrlValid={isTwitterUrlValid}
            setIsTwitterUrlValid={setIsTwitterUrlValid}
            includeTwitterUrl={includeTwitterUrl}
            setIncudeTwitterUrl={setIncudeTwitterUrl}
            linkedinUrl={linkedinUrl}
            setLinkedinUrl={setLinkedinUrl}
            isLinkedinUrlValid={isLinkedinUrlValid}
            setIsLinkedinUrlValid={setIsLinkedinUrlValid}
            includeLinkedinUrl={includeLinkedinUrl}
            setIncudeLinkedinUrl={setIncudeLinkedinUrl}
            instagramUrl={instagramUrl}
            setInstagramUrl={setInstagramUrl}
            includeInstagramUrl={includeInstagramUrl}
            setIncludeInstagramUrl={setIncludeInstagramUrl}
            isInstagramUrlValid={isInstagramUrlValid}
            setIsInstagramUrlValid={setIsInstagramUrlValid}
            youtubeUrl={youtubeUrl}
            setYoutubeUrl={setYoutubeUrl}
            includeYoutubeUrl={includeYoutubeUrl}
            setIncludeYoutubeUrl={setIncludeYoutubeUrl}
            isYoutubeUrlValid={isYoutubeUrlValid}
            setIsYoutubeUrlValid={setIsYoutubeUrlValid}
            linktreeUrl={linktreeUrl}
            setLinktreeUrl={setLinktreeUrl}
            includeLinktreeUrl={includeLinktreeUrl}
            setIncludeLinktreeUrl={setIncludeLinktreeUrl}
            isLinktreeUrlValid={isLinktreeUrlValid}
            setIsLinktreeUrlValid={setIsLinktreeUrlValid}
            imdbUrl={imdbUrl}
            setImdbUrl={setImdbUrl}
            includeImdbUrl={includeImdbUrl}
            setIncludeImdbUrl={setIncludeImdbUrl}
            isImdbUrlValid={isImdbUrlValid}
            setIsImdbUrlValid={setIsImdbUrlValid}
          >
            {/* <CompletedSteps screenNumber={screenNumber} /> */}
          </SecondScreen>
        )}

        {screenNumber === 3 && (
          <ThirdScreen
            liveDate={liveDate}
            isLiveDateSelected={isLiveDateSelected}
            setIsLiveDateSelected={setIsLiveDateSelected}
            setLiveDate={setLiveDate}
            capped={capped}
            setCapped={setCapped}
            setScreenNumber={setScreenNumber}
            setShareInGroup={setShareInGroup}
            shareInGroup={shareInGroup}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            associatedGroups={associatedGroups}
            setAssociatedGroups={setAssociatedGroups}
            isNftGatedCommunity={isNftGatedCommunity}
            setIsNftGatedCommunity={setIsNftGatedCommunity}
          >
            {/* <CompletedSteps screenNumber={screenNumber} /> */}
          </ThirdScreen>
        )}

        {screenNumber === 6 && (
          <FourthScreen
            setCoverPhotoPreviewUrl={setCoverPhotoPreviewUrl}
            setCoverPhotoUrl={setCoverPhotoUrl}
            coverPhotoPreviewUrl={coverPhotoPreviewUrl}
            coverPhotoUrl={coverPhotoUrl}
            setScreenNumber={setScreenNumber}
            isCollectionStatusPublic={isCollectionStatusPublic}
            setIsCollectionStatusPublic={setIsCollectionStatusPublic}
            createCollectionHandler={createCollectionHandler}
            creatingCollection={creatingCollection}
            startingPrice={startingPrice}
            setStartingPrice={setStartingPrice}
            startingPriceTouched={startingPriceTouched}
            setStartingPriceTouched={setStartingPriceTouched}
            royalteFee={royalteFee}
            setRoyalteFee={setRoyalteFee}
            royalteFeeTouched={royalteFeeTouched}
            setRoyalteFeeTouched={setRoyalteFeeTouched}
            profilePhotoPreviewUrl={profilePhotoPreviewUrl}
            profilePhotoUrl={profilePhotoUrl}
            setProfilePhotoPreviewUrl={setProfilePhotoPreviewUrl}
            setProfilePhotoUrl={setProfilePhotoUrl}
            loadingIPFS={loadingIPFS}
            setLoadingIPFS={setLoadingIPFS}
            uploadedNfts={uploadedNfts}
            setUploadedNfts={setUploadedNfts}
            editionSize={editionSize}
            setEditionSize={setEditionSize}
            editionSizeTouched={editionSizeTouched}
            setEditionSizeTouched={setEditionSizeTouched}
            claimable={claimable}
            setClaimable={setClaimable}
            isSingleUploadSelected={isSingleUploadSelected}
            capped={capped}
            setCapped={setCapped}
          >
            {/* <CompletedSteps screenNumber={screenNumber} /> */}
          </FourthScreen>
        )}

        {screenNumber === 5 && (
          <HolderBenefitScreen
            setScreenNumber={setScreenNumber}
            benefits={holderBenefits}
            setBenefits={setHolderBenefits}
          />
        )}

        {screenNumber === 4 && (
          <FifthScreen
            scheduleDay={scheduleDay}
            scheduleMonth={scheduleMonth}
            scheduleYear={scheduleYear}
            setScheduleDay={setScheduleDay}
            setScheduleMonth={setScheduleMonth}
            setScheduleYear={setScheduleYear}
            isLiveDateSelected={isLiveDateSelected}
            setIsLiveDateSelected={setIsLiveDateSelected}
            setScreenNumber={setScreenNumber}
            scheduleHour={scheduleHour}
            scheduleMinutes={scheduleMinutes}
            scheduleSeconds={scheduleSeconds}
            setScheduleHour={setScheduleHour}
            setScheduleMinutes={setScheduleMinutes}
            setScheduleSeconds={setScheduleSeconds}
            scheduleDayTouched={scheduleDayTouched}
            setScheduleDayTouched={setScheduleDayTouched}
            scheduleMinutesTouched={scheduleMinutesTouched}
            setScheduleMinutesTouched={setScheduleMinutesTouched}
            scheduleMonthTouched={scheduleMonthTouched}
            setScheduleMonthTouched={setScheduleMonthTouched}
            setScheduleHourTouched={setScheduleHourTouched}
            scheduleYearTouched={scheduleYearTouched}
            setScheduleYearTouched={setScheduleYearTouched}
            isScheduleDayValid={isScheduleDayValid}
            setIsScheduleDayValid={setIsScheduleDayValid}
            isScheduleHourValid={isScheduleHourValid}
            setIsScheduleHourValid={setIsScheduleHourValid}
            isScheduleMinutesValid={isScheduleMinutesValid}
            setIsScheduleMinutesValid={setIsScheduleMinutesValid}
            isScheduleMonthValid={isScheduleMonthValid}
            setIsScheduleMonthValid={setIsScheduleMonthValid}
            scheduleHourTouched={scheduleHourTouched}
            isScheduleYearValid={isScheduleYearValid}
            setIsScheduleYearValid={setIsScheduleYearValid}
            scheduleSecondsTouched={scheduleSecondsTouched}
            setScheduleSecondsTouched={setScheduleSecondsTouched}
            isScheduleSecondsValid={isScheduleSecondsValid}
            setIsScheduleSecondsValid={setIsScheduleSecondsValid}
            isAMSelected={isAMSelected}
            setIsAMSelected={setIsAMSelected}
          >
            {/* <CompletedSteps screenNumber={screenNumber} /> */}
          </FifthScreen>
        )}
      </>
    </Modal>
  );
}

export default CreateCollectionModal;
