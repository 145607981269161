import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth-context';
import {
  addNftToWatchlistRequest,
  getNumberOfLikesForSpecificNftRequest,
  getNumberOfWatchlistsForSpecificNftRequest,
  likeNftRequest,
  removeNftFromWatchlistRequest,
  unlikeNftRequest,
  unlistBuyNowRequest,
  isNftAccessibleToBuyByMeRequest,
  createStripeCheckoutRequest,
  claimNftRequest,
  getMyClaimableAccessForASpecificNftRequest,
  getUnlockableContentStatusForSpecificNftRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import { useQuery, useQueryClient } from 'react-query';
import NftMessages from './components/NftMessages/NftMessages';
import heartIcon from '../../assets/liked-nft-icon.png';
import unfilledHeartIcon from '../../assets/like-nft-icon.png';
import { CoinMarketCapContext } from '../../context/coinmarketcap-context';
import BuyNowModal from './components/BuyNowModal/BuyNowModal';
import UpdateNftBuyNowPriceModal from './components/UpdateNftBuyNowPriceModal/UpdateNftBuyNowPriceModal';
import classes from './NftContent.module.css';
import ReactPlayer from 'react-player';
import { IPFS_URL } from '../../constants/IPFS';
import ShareIcon from '../../assets/share-icon.png';
import Button from '../../components/Button/Button';
import ListBuyNowModal from './components/ListBuyNowModal/ListBuyNowModal';
import NftDetails from './components/NftDetails/NftDetails';
import NftActivity from './components/NftActivity/NftActivity';
import { getFormattedNumber, limitString } from '../../helpers/helpers';
import { Link, useHistory } from 'react-router-dom';
import VerifiedIcon from '../../assets/verified.png';
import {
  UPDATING_NFT_PRICE,
  LISTING_NFT_FOR_BUY_NOW,
  UNLISTING_NFT,
  PROCESSING_BUY_NOW,
  NFT_UPDATE,
  PROCESSING_NFT_OFFER,
} from '../../constants/SOCKET_EVENTS';
import { SocketContext } from '../../context/socket-context';
import {
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { FacebookIcon, TwitterIcon } from 'react-share';
import MoreIcon from '../../assets/more.png';
import EditNftModal from './components/EditNftModal/EditNftModal';
import ImageModal from '../../components/ImageModal/ImageModal';
import ReportNftModal from './components/ReportNftModal/ReportNftModal';
import moment from 'moment';
import MetaTags from 'react-meta-tags';
import MakeOfferModal from './components/MakeOfferModal/MakeOfferModal';
import PendingOffers from './components/PendingOffers/PendingOffers';
import BuyingMethodModal from './components/BuyingMethodModal/BuyingMethodModal';
import TransferNftModal from './components/TransferNftModal/TransferNftModal';
import BuyWithWyreWalletModal from './components/BuyWithWyreWalletModal/BuyWithWyreWalletModal';

import BookmarkIcon from '../../assets/white-bookmark-icon.png';
import BookmarkedIcon from '../../assets/pink-bookmark-icon.png';
import BenefitsModal from './components/BenefitsModal/BenefitsModal';
import UnlockableContentModal from './components/UnlockableContentModal/UnlockableContentModal';

import StarIcon from '../../assets/custom-benefits-star-icon.png';
import PersonalizedVideoIcon from '../../assets/personalized-video-icon.png';
import PersonalizedImageIcon from '../../assets/personalized-image-icon.png';
import ChatBenefitIcon from '../../assets/chat-benefit-icon.png';
import VideoCallBenefitIcon from '../../assets/video-call-benefit-icon.png';
import ShareNftModal from './components/ShareNftModal/ShareNftModal';

const NFT_DETAILS_SECTIONS = [
  'Messages',
  'Details',
  'Activity',
  'Pending Offers',
];

const POPULAR_BENEFITS = [
  '1/1 Chat (15mins)',
  '1/1 Video Call (15mins)',
  'Personalized Image',
  'Personalized Video',
];

const NftContent = (props) => {
  const history = useHistory();
  const moreIconRef = useRef();
  const queryClient = useQueryClient();
  const { glmsToUsd } = useContext(CoinMarketCapContext);
  const { socket } = useContext(SocketContext);

  const { nft } = props;
  const nftId = nft?._id;
  const nftOwner = nft.owner;
  const {
    getMyWatchlist,
    myWatchlist,
    profile,
    myLikes,
    getMyLikes,
    myWalletInformation,
    myWyreWalletInformation,
  } = useContext(AuthContext);
  const isMyNft = profile?._id === nftOwner?._id;

  const [showBuyNowModal, setShowBuyNowModal] = useState(false);
  const [
    showUpdateNftBuyNowPriceModal,
    setShowUpdateNftBuyNowPriceModal,
  ] = useState(false);
  const [showListBuyNowModal, setShowListBuyNowModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState('Messages');
  const [showShareIcons, setShowShareIcons] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showEditNftModal, setShowEditNftModal] = useState(false);
  const [showNftImageModal, setShowNftImageModal] = useState(false);
  const [showReportNftModal, setShowReportNftModal] = useState(false);
  const [showMakeOfferModal, setShowMakeOfferModal] = useState(false);
  const [showBuyingMethodModal, setShowBuyingMethodModal] = useState(false);
  const [showTransferNftModal, setShowTransferNftModal] = useState(false);
  const [claimingNft, setClaimingNft] = useState(false);

  const [showBuyWithWyreWalletModal, setShowBuyWithWyreWalletModal] = useState(
    false
  );

  const [showBenefitsModal, setShowBenefitsModal] = useState(false);
  const [showUnlockableContentModal, setShowUnlockableContentModal] = useState(
    false
  );
  const [showShareNftModal, setShowShareNftModal] = useState(false);

  const numberOfLikesResponse = useQuery(
    ['nft-number-likes', nft._id],
    () => getNumberOfLikesForSpecificNftRequest(nft._id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const numberOfWatchlistsResponse = useQuery(
    ['nft-number-watchlists', nft._id],
    () => getNumberOfWatchlistsForSpecificNftRequest(nft._id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const myClaimableAccessForASpecificNftResponse = useQuery(
    ['nft-claimable-access', nft._id],
    () => getMyClaimableAccessForASpecificNftRequest(nft._id)
  );

  const unlockableContentStatusResponse = useQuery(
    ['nft-unlockableContent-status', nft._id],
    () => getUnlockableContentStatusForSpecificNftRequest(nft._id)
  );

  const invalidateNft = (nftId) => {
    queryClient.invalidateQueries(['nfts', nftId]);
    queryClient.refetchQueries(['nfts', nftId]);
  };

  // useEffect(() => {
  //   const checkForClosingMoreOptions = (e) => {
  //     if (e.target !== moreIconRef.current) {
  //       setShowMoreOptions(false);
  //     }
  //   };

  //   window.addEventListener('click', checkForClosingMoreOptions);

  //   return () =>
  //     window.removeEventListener('click', checkForClosingMoreOptions);
  // }, []);

  useEffect(() => {
    if (!socket) return;

    [
      NFT_UPDATE,
      UPDATING_NFT_PRICE,
      UNLISTING_NFT,
      LISTING_NFT_FOR_BUY_NOW,
      PROCESSING_BUY_NOW,
      PROCESSING_NFT_OFFER,
    ].forEach((event) => {
      socket.on(event, (nftId) => {
        if (nft._id === nftId) {
          invalidateNft(nftId);
        }
      });
    });

    // eslint-disable-next-line
  }, []);

  const addNftToWatchlistHandler = async () => {
    try {
      await addNftToWatchlistRequest(nftId);
      queryClient.invalidateQueries(['nft-number-watchlists', nft?._id]);
      queryClient.refetchQueries(['nft-number-watchlists', nft?._id]);
      getMyWatchlist();
    } catch (error) {
      // notify('error', error, 2000);
    }
  };

  const likeNftHandler = async () => {
    try {
      await likeNftRequest(nftId);
      await Promise.all([
        getMyLikes(),
        queryClient.invalidateQueries(['nft-number-likes', nft._id]),
        queryClient.refetchQueries(['nft-number-likes', nft._id]),
      ]);
    } catch (error) {
      // notify('error', error, 2000);
    }
  };

  const unlikeNftHandler = async () => {
    try {
      await unlikeNftRequest(nftId);

      await Promise.all([
        getMyLikes(),
        queryClient.invalidateQueries(['nft-number-likes', nft._id]),
        queryClient.refetchQueries(['nft-number-likes', nft._id]),
      ]);
    } catch (error) {
      // notify('error', error, 2000);
    }
  };

  const removeNftFromWatchlistHandler = async () => {
    try {
      await removeNftFromWatchlistRequest(nftId);
      queryClient.invalidateQueries(['nft-number-watchlists', nft?._id]);
      queryClient.refetchQueries(['nft-number-watchlists', nft?._id]);
      getMyWatchlist();
    } catch (error) {
      // notify('error', error, 2000);
    }
  };

  const renderWatchlistContainer = () => {
    if (!profile?._id)
      return (
        <div
          onClick={addNftToWatchlistHandler}
          className={classes['likes-container']}
        >
          <img
            className={classes['heart-icon']}
            alt="likes"
            src={BookmarkIcon}
            height={19}
          />
          <p className={classes['number-watchlist']}>
            {numberOfWatchlistsResponse.data?.data}
          </p>
        </div>
      );

    const isNftInMyWatchlist = myWatchlist.some(
      (el) => el.nft?._id === nft._id
    );

    if (isNftInMyWatchlist) {
      return (
        <div
          onClick={removeNftFromWatchlistHandler}
          className={classes['likes-container']}
        >
          <img
            className={classes['heart-icon']}
            alt="likes"
            src={BookmarkedIcon}
            height={19}
          />

          <p className={classes['number-watchlist']}>
            {numberOfWatchlistsResponse.data?.data}
          </p>
        </div>
      );
    } else {
      return (
        <div
          onClick={addNftToWatchlistHandler}
          className={classes['likes-container']}
        >
          <img
            className={classes['heart-icon']}
            alt="likes"
            src={BookmarkIcon}
            height={19}
          />
          <p className={classes['number-watchlist']}>
            {numberOfWatchlistsResponse.data?.data}
          </p>
        </div>
      );
    }
  };

  const renderLikeButton = () => {
    const isCurrentNftPartOfMyLikes = myLikes.some(
      ({ nft }) => nft?._id === nftId
    );

    if (!isCurrentNftPartOfMyLikes) {
      return (
        <div onClick={likeNftHandler} className={classes['likes-container']}>
          <img alt="Like" width={18} height={18} src={unfilledHeartIcon} />

          <p className={classes['number-likes']}>
            {numberOfLikesResponse?.data?.data}
          </p>
        </div>
      );
    } else {
      return (
        <div onClick={unlikeNftHandler} className={classes['likes-container']}>
          <img alt="Unlike" width={18} height={18} src={heartIcon} />
          <p className={classes['number-likes']}>
            {numberOfLikesResponse?.data?.data}
          </p>
        </div>
      );
    }
  };

  const openBuyNowModalHandler = async () => {
    try {
      const { data, reason } = await isNftAccessibleToBuyByMeRequest(nftId);
      if (data === false) {
        return notify('error', reason, 2000);
      }

      if (
        nft.nftCollection?.liveDate &&
        moment().diff(nft.nftCollection.liveDate) < 0
      ) {
        return notify('error', 'NFT Collection is not live yet!', 2000);
      }

      if (
        myWalletInformation &&
        myWalletInformation.glimpseBalance &&
        parseFloat(myWalletInformation.glimpseBalance) <
          parseFloat(nft.buyNowPrice)
      ) {
        notify(
          'error',
          'You do not have enough balance to buy this NFT!',
          2000
        );
      } else {
        setShowBuyNowModal(true);
      }
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const openUpdateNftBuyPriceModalHandler = () =>
    setShowUpdateNftBuyNowPriceModal(true);

  const openListBuyNowModal = () => setShowListBuyNowModal(true);

  const unlistBuyNowHandler = async () => {
    try {
      await unlistBuyNowRequest(nft._id);
      queryClient.invalidateQueries(['nfts', nft._id]);
      queryClient.refetchQueries(['nfts', nft._id]);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const openBuyingMethodModalHandler = async () => {
    try {
      const { data, reason } = await isNftAccessibleToBuyByMeRequest(nftId);
      if (data === false) {
        return notify('error', reason, 2000);
      }

      if (
        nft.nftCollection?.liveDate &&
        moment().diff(nft.nftCollection.liveDate) < 0
      ) {
        return notify('error', 'NFT Collection is not live yet!', 2000);
      }
      setShowBuyingMethodModal(true);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderBuyNowButtonAccordingly = () => {
    if (!isMyNft)
      return (
        <Button
          onClick={openBuyingMethodModalHandler}
          style={{
            width: '100%',
          }}
          darkpink="true"
          disabled={
            !nft.listedForBuyNow ||
            nft.processingBuyNowTransactionOnTheBlockchain ||
            nft.updatingBuyNowPriceOnBlockchain ||
            nft.listingForBuyNowOnBlockchain ||
            nft.unlistingBuyNowOnBlockchain ||
            nft.processingNftOfferOnTheBlockchain ||
            nft.transferringNftOnTheBlockchain ||
            isNftAccessibleToBeClaimedByMe
          }
        >
          Buy Now
        </Button>
      );

    if (nft.listedForBuyNow) {
      return (
        <Button
          onClick={openUpdateNftBuyPriceModalHandler}
          style={{
            width: '100%',
          }}
          darkpink="true"
          disabled={
            nft.processingBuyNowTransactionOnTheBlockchain ||
            nft.updatingBuyNowPriceOnBlockchain ||
            nft.listingForBuyNowOnBlockchain ||
            nft.unlistingBuyNowOnBlockchain ||
            nft.processingNftOfferOnTheBlockchain ||
            nft.transferringNftOnTheBlockchain
          }
        >
          Update Price
        </Button>
      );
    } else {
      return (
        <Button
          style={{
            width: '100%',
          }}
          darkpink="true"
          disabled={
            nft.processingBuyNowTransactionOnTheBlockchain ||
            nft.updatingBuyNowPriceOnBlockchain ||
            nft.listingForBuyNowOnBlockchain ||
            nft.unlistingBuyNowOnBlockchain ||
            nft.processingNftOfferOnTheBlockchain ||
            nft.transferringNftOnTheBlockchain
          }
          onClick={openListBuyNowModal}
        >
          List Buy Now
        </Button>
      );
    }
  };

  // const renderAddToWatchlistButtonAccordingly = () => {
  //   const isCurrentNftPartOfMyWatchlist = myWatchlist.some(
  //     ({ nft }) => nft?._id === nftId
  //   );

  //   if (isCurrentNftPartOfMyWatchlist) {
  //     return (
  //       <Button
  //         onClick={removeNftFromWatchlistHandler}
  //         style={{
  //           borderRadius: '8px',
  //           width: '100%',
  //           padding: '0.5rem 0.25rem',
  //         }}
  //         modernpink="true"
  //       >
  //         Added to Watchlist
  //       </Button>
  //     );
  //   } else {
  //     return (
  //       <Button
  //         onClick={addNftToWatchlistHandler}
  //         style={{
  //           borderRadius: '8px',
  //           width: '100%',
  //         }}
  //         modernpinkcolor="true"
  //       >
  //         Add to Watchlist
  //       </Button>
  //     );
  //   }
  // };

  const renderUnlistNftButtonAccordingly = () => {
    // if (!nft.tokenId) {
    //   return renderAddToWatchlistButtonAccordingly();
    // }

    if (
      nft.processingBuyNowTransactionOnTheBlockchain ||
      nft.updatingBuyNowPriceOnBlockchain ||
      nft.listingForBuyNowOnBlockchain ||
      nft.unlistingBuyNowOnBlockchain ||
      !nft.listedForBuyNow ||
      nft.transferringNftOnTheBlockchain
    ) {
      return (
        <Button
          style={{
            width: '100%',
          }}
          darkpink="true"
          disabled
        >
          Unlist
        </Button>
      );
    } else {
      if (nft.listedForBuyNow) {
        return (
          <Button
            onClick={unlistBuyNowHandler}
            style={{
              width: '100%',
            }}
            darkpinkcolor="true"
          >
            Unlist
          </Button>
        );
      }
    }
  };

  const renderNftPrice = () => {
    if (nft.processingNftOfferOnTheBlockchain) {
      return (
        <div className={classes['processingBuyNowTransactionOnTheBlockchain']}>
          <p className={classes['blockchain-text']}>Processing Offer...</p>
        </div>
      );
    }

    if (nft.processingBuyNowTransactionOnTheBlockchain) {
      return (
        <div className={classes['processingBuyNowTransactionOnTheBlockchain']}>
          <p className={classes['blockchain-text']}>Processing Buy Now...</p>
        </div>
      );
    }
    if (nft.updatingBuyNowPriceOnBlockchain) {
      return (
        <div className={classes['processingBuyNowTransactionOnTheBlockchain']}>
          <p className={classes['blockchain-text']}>
            Updating Price on the Blockchain...
          </p>
        </div>
      );
    }

    if (nft.listingForBuyNowOnBlockchain) {
      return (
        <div className={classes['processingBuyNowTransactionOnTheBlockchain']}>
          <p className={classes['blockchain-text']}>
            Listing for Buy Now on the Blockchain...
          </p>
        </div>
      );
    }

    if (nft.transferringNftOnTheBlockchain) {
      return (
        <div className={classes['processingBuyNowTransactionOnTheBlockchain']}>
          <p className={classes['blockchain-text']}>
            A transfer of this Collectible is being processed...
          </p>
        </div>
      );
    }

    if (nft.listedForBuyNow) {
      return (
        <div className={classes['nft-price-container']}>
          <p className={classes['price-text']}>PRICE</p>
          <p className={classes['nft-price']}>
            {getFormattedNumber((nft.buyNowPrice * glmsToUsd.price).toFixed(2))}{' '}
            USD
          </p>
          {/* <p className={classes['nft-price']}>
            {getFormattedNumber(nft.buyNowPrice)} GLMS{' '}
            <span className={classes['usd-price']}>
              $
              {getFormattedNumber(
                (nft.buyNowPrice * glmsToUsd.price).toFixed(2)
              )}
            </span>
          </p> */}
        </div>
      );
    }

    if (!nft.listedForBuyNow && nft.lastBuyNowPrice) {
      return (
        <div className={classes['nft-price-container']}>
          <p className={classes['price-text']}>LAST PRICE</p>
          <p className={classes['nft-price']}>
            {getFormattedNumber(
              (nft.lastBuyNowPrice * glmsToUsd.price).toFixed(2)
            )}{' '}
            USD
          </p>
          {/* <p className={classes['nft-price']}>
            {getFormattedNumber(nft.lastBuyNowPrice)} GLMS{' '}
            <span className={classes['usd-price']}>
              $
              {getFormattedNumber(
                (nft.lastBuyNowPrice * glmsToUsd.price).toFixed(2)
              )}
            </span>
          </p> */}
        </div>
      );
    }

    if (!nft.listedForBuyNow && !nft.lastBuyNowPrice) {
      return (
        <div className={classes['personal-collection-container']}>
          <p className={classes['price-text']}>
            This Collectible is owned by {nft.owner.fullName}!
          </p>
        </div>
      );
    } else return null;
  };

  const toggleShareIconsHandler = () => {
    setShowShareIcons((prevState) => !prevState);
  };

  const toggleMoreOptionsHandler = () => {
    setShowMoreOptions((prevState) => !prevState);
  };

  const openEditNftModalHandler = () => {
    setShowEditNftModal(true);
  };

  const openNftImageModalHandler = () => {
    setShowNftImageModal(true);
  };

  const openReportNftModalHandler = () => {
    setShowReportNftModal(true);
  };

  const openMakeOfferModalHandler = async () => {
    try {
      const { data, reason } = await isNftAccessibleToBuyByMeRequest(nftId);
      if (data === false) {
        return notify('error', reason, 2000);
      }

      if (
        nft.nftCollection?.liveDate &&
        moment().diff(nft.nftCollection.liveDate) < 0
      ) {
        return notify('error', 'NFT Collection is not live yet!', 2000);
      }
      setShowMakeOfferModal(true);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const openTransferNftModalHandler = () => {
    setShowTransferNftModal(true);
  };

  const openBenefitsModalHandler = () => {
    if (
      nft.benefits &&
      Array.isArray(nft.benefits) &&
      nft.benefits?.length > 0
    ) {
      setShowBenefitsModal(true);
    } else {
      notify('error', 'No benefits connected with this collectible!', 3000);
    }
  };

  const openUnlockableContentModalHandler = () => {
    if (nft.owner._id !== profile?._id && nft.author._id !== profile?._id) {
      return notify('error', 'Only the purchaser will receive access!', 3000);
    }

    setShowUnlockableContentModal(true);
  };

  const openShareNftModalHandler = () => {
    setShowShareNftModal(true);
    setShowMoreOptions(false);
  };

  const createCheckoutSessionHandler = async () => {
    try {
      const { data, reason } = await isNftAccessibleToBuyByMeRequest(nftId);
      if (data === false) {
        return notify('error', reason, 2000);
      }

      if (
        nft.nftCollection?.liveDate &&
        moment().diff(nft.nftCollection.liveDate) < 0
      ) {
        return notify('error', 'NFT Collection is not live yet!', 2000);
      }

      const requestBody = {
        nftId: nft._id,
      };
      const { sessionUrl } = await createStripeCheckoutRequest(requestBody);
      window.open(sessionUrl, '_blank');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const claimNftHandler = async () => {
    setClaimingNft(true);

    try {
      await claimNftRequest(nft._id);

      invalidateNft(nftId);
      await Promise.all([
        queryClient.invalidateQueries(['nft-claimable-access', nft._id]),
        queryClient.refetchQueries(['nft-claimable-access', nft._id]),
      ]);
    } catch (err) {
      notify('error', err, 2000);
    }

    setClaimingNft(false);
  };

  const buyWithWyreHandler = async () => {
    if (
      !myWyreWalletInformation ||
      !myWyreWalletInformation.availableBalances['USD'] ||
      parseFloat(myWyreWalletInformation.availableBalances['USD']) <
        parseFloat(nft.buyNowPrice * glmsToUsd.price)
    ) {
      notify(
        'error',
        'Make sure you have enough availabe USD to proceed!',
        2000
      );

      setTimeout(() => {
        history.push('/wallet');
      }, 2000);

      return;
    } else {
      setShowBuyWithWyreWalletModal(true);
    }
  };

  const customAndNonCustomBenefits = useMemo(() => {
    if (!nft.benefits || nft.benefits?.length === 0)
      return { customBenefits: [], nonCustomBenefits: [] };

    const customBenefits = [];
    const nonCustomBenefits = [];
    nft.benefits.forEach((benefit) => {
      if (POPULAR_BENEFITS.includes(benefit)) {
        nonCustomBenefits.push(benefit);
      } else {
        customBenefits.push(benefit);
      }
    });

    return {
      customBenefits,
      nonCustomBenefits,
    };
  }, [nft.benefits]);

  const { customBenefits, nonCustomBenefits } = customAndNonCustomBenefits;

  const renderBenefits = () => {
    const isChatIncluded = nonCustomBenefits.includes('1/1 Chat (15mins)');
    const isVideoCallIncluded = nonCustomBenefits.includes(
      '1/1 Video Call (15mins)'
    );
    const isPersonalizedImageIncluded = nonCustomBenefits.includes(
      'Personalized Image'
    );
    const isPersonalizedVideoIncluded = nonCustomBenefits.includes(
      'Personalized Video'
    );

    return (
      <div className={classes['custom-non-custom-benefits-container']}>
        {isChatIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={ChatBenefitIcon} alt="Chat Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                1/1 Chat (15mins)
              </p>
              <p className={classes['benefits-text']}>Benefits</p>
            </div>
          </div>
        )}

        {isVideoCallIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={VideoCallBenefitIcon} alt="Video Call Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                1/1 Video Call (15mins)
              </p>
              <p className={classes['benefits-text']}>Benefits</p>
            </div>
          </div>
        )}

        {isPersonalizedImageIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={PersonalizedImageIcon} alt="Personalized Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                Personalized Image
              </p>
              <p className={classes['benefits-text']}>Benefits</p>
            </div>
          </div>
        )}

        {isPersonalizedVideoIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={PersonalizedVideoIcon} alt="Personalized Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                Personalized Video
              </p>
              <p className={classes['benefits-text']}>Benefits</p>
            </div>
          </div>
        )}

        {customBenefits.length > 0 && (
          <div
            onClick={openBenefitsModalHandler}
            className={classes['custom-benefits-container']}
          >
            <img
              src={StarIcon}
              alt="Custom Benefits"
              className={classes['star-icon']}
            />
            <div className={classes['custom-text-container']}>
              <p className={classes['custom-benefits-text-bold']}>
                Custom Benefits
              </p>
              <p className={classes['custom-benefits-text']}>Benefits</p>
            </div>
          </div>
        )}

        {unlockableContentStatusResponse.data?.data === true && (
          <div
            onClick={openUnlockableContentModalHandler}
            className={classes['unlockable-content-container']}
          >
            <p>Unlockable Content</p>
            <span className={classes['only-access-text']}>
              Owner receives access after purchase
            </span>
          </div>
        )}
      </div>
    );
  };

  const isNftAccessibleToBeClaimedByMe =
    myClaimableAccessForASpecificNftResponse?.data !== undefined &&
    myClaimableAccessForASpecificNftResponse?.data
      ?.isNftAccessibleToBeClaimed === true;

  return (
    <>
      <MetaTags>
        <title>{nft.title} - NFT | Glimpse</title>
        <meta name="description" content={nft.description} />
        <meta property="og:title" content={nft.title} />
        <meta
          property="og:image"
          content={nft.previewUrl ? nft.previewUrl : `${IPFS_URL}/${nft.ipfs}`}
        />
      </MetaTags>

      {showMakeOfferModal && (
        <MakeOfferModal
          nftId={nft._id}
          setShowMakeOfferModal={setShowMakeOfferModal}
        />
      )}

      {showListBuyNowModal && (
        <ListBuyNowModal
          setShowListBuyNowModal={setShowListBuyNowModal}
          nft={nft}
        />
      )}

      {showUpdateNftBuyNowPriceModal && (
        <UpdateNftBuyNowPriceModal
          nft={nft}
          setShowUpdateNftBuyNowPriceModal={setShowUpdateNftBuyNowPriceModal}
        />
      )}

      {showBuyNowModal && (
        <BuyNowModal setShowBuyNowModal={setShowBuyNowModal} nft={nft} />
      )}

      {showBuyWithWyreWalletModal && (
        <BuyWithWyreWalletModal
          setShowBuyWithWyreWalletModal={setShowBuyWithWyreWalletModal}
          nft={nft}
        />
      )}

      {showEditNftModal && (
        <EditNftModal nft={nft} setShowEditNftModal={setShowEditNftModal} />
      )}

      {showTransferNftModal && (
        <TransferNftModal
          nft={nft}
          setShowTransferNftModal={setShowTransferNftModal}
          invalidateNft={invalidateNft}
        />
      )}

      {showShareNftModal && (
        <ShareNftModal nft={nft} setShowShareNftModal={setShowShareNftModal} />
      )}

      {showNftImageModal && (
        <ImageModal
          title={`${nft.title} NFT`}
          setShowImageModal={setShowNftImageModal}
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            alt="IPFS Hash"
            className={classes['full-nft-image']}
            src={nft.previewUrl ? nft.previewUrl : `${IPFS_URL}/${nft.ipfs}`}
          />
        </ImageModal>
      )}

      {showReportNftModal && (
        <ReportNftModal
          nft={nft}
          setShowReportNftModal={setShowReportNftModal}
        />
      )}

      {showBuyingMethodModal && (
        <BuyingMethodModal
          openBuyNowModalHandler={openBuyNowModalHandler}
          createCheckoutSessionHandler={createCheckoutSessionHandler}
          nftId={nftId}
          nft={nft}
          setShowBuyingMethodModal={setShowBuyingMethodModal}
          buyWithWyreHandler={buyWithWyreHandler}
        />
      )}

      {showBenefitsModal && (
        <BenefitsModal
          setShowBenefitsModal={setShowBenefitsModal}
          benefits={customBenefits}
        />
      )}

      {showUnlockableContentModal && (
        <UnlockableContentModal
          nftId={nft._id}
          setShowUnlockableContentModal={setShowUnlockableContentModal}
        />
      )}

      <div className={classes['nft-content-page-container']}>
        <div className={classes['nft-image-details-container']}>
          <div className={classes['nft-content-container']}>
            <div className={classes['nft-image-container']}>
              {nft.type === 'video' && (
                <ReactPlayer
                  width={500}
                  height={500}
                  url={nft.previewUrl || `${IPFS_URL}/${nft?.ipfs}`}
                  controls
                  playing={true}
                />
              )}
              {nft.type === 'image' && (
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={openNftImageModalHandler}
                  className={classes['nft-image']}
                  alt="IPFS Hash"
                  src={
                    nft.previewUrl ? nft.previewUrl : `${IPFS_URL}/${nft?.ipfs}`
                  }
                />
              )}

              <div className={classes['watchlist-likes']}>
                {renderLikeButton()}
                {renderWatchlistContainer()}

                <div
                  onClick={toggleShareIconsHandler}
                  className={classes['watchlist-container']}
                >
                  <img alt="Share" src={ShareIcon} width={18} height={18} />

                  {showShareIcons && (
                    <div className={classes['share-icons-container']}>
                      <FacebookShareButton
                        url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                        hashtag={`#NFT`}
                        description={nft.description}
                        title={nft.title}
                      >
                        <FacebookIcon size={28} round />
                      </FacebookShareButton>

                      <TwitterShareButton
                        url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                        hashtag={`#NFT`}
                        description={nft.description}
                        title={nft.title}
                      >
                        <TwitterIcon size={28} round />
                      </TwitterShareButton>

                      <WhatsappShareButton
                        url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                        hashtag={`#NFT`}
                        description={nft.description}
                        title={nft.title}
                      >
                        <WhatsappIcon size={28} round />
                      </WhatsappShareButton>

                      <TelegramShareButton
                        url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                        hashtag={`#NFT`}
                        description={nft.description}
                        title={nft.title}
                      >
                        <TelegramIcon size={28} round />
                      </TelegramShareButton>

                      <RedditShareButton
                        url={`https://www.glimpsenft.com/nfts/${nft._id}`}
                        hashtag={`#NFT`}
                        description={nft.description}
                        title={nft.title}
                      >
                        <RedditIcon size={28} round />
                      </RedditShareButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {nft.audioUrl && (
            <audio src={nft.audioUrl} controls>
              {/* <source src={nft.audioUrl} type="audio/ogg"></source> */}
            </audio>
          )}

          <div className={classes['nft-actions-container']}>
            <div className={classes['price-information-container']}>
              {renderNftPrice()}
              <div className={classes['royalte-fee-container']}>
                <p className={classes['royalte-fee-value']}>
                  {nft.royalteFee} %
                </p>{' '}
                <p className={classes['royalte-fee-text']}>Royalte Fee</p>
              </div>
            </div>

            <div className={classes['horizontal-line']}></div>

            <div className={classes['buy-add-to-watchlist-container']}>
              {myClaimableAccessForASpecificNftResponse?.data !== undefined &&
                isNftAccessibleToBeClaimedByMe && (
                  <Button
                    style={{
                      width: '100%',
                    }}
                    disabled={claimingNft}
                    onClick={claimNftHandler}
                    darkpinkcolor="true"
                  >
                    Claim
                  </Button>
                )}
              {renderBuyNowButtonAccordingly()}

              {!isMyNft && (
                <Button
                  style={{
                    width: '100%',
                  }}
                  darkpinkcolor="true"
                  onClick={openMakeOfferModalHandler}
                >
                  Make Offer
                </Button>
              )}
              {/* {!isMyNft && renderAddToWatchlistButtonAccordingly()} */}
              {isMyNft && renderUnlistNftButtonAccordingly()}
            </div>
          </div>
        </div>

        <div className={classes['nft-information-container']}>
          <div className={classes['nft-info-container']}>
            <div className={classes['title-tags-collection-container']}>
              <div className={classes['title-tags-container']}>
                <div className={classes['title-container']}>
                  <h3 title={nft.title} className={classes['title-text']}>
                    {limitString(nft.title, 40)}
                  </h3>

                  {nft.isPartOfCollection && nft.nftCollection?.name && (
                    <Link to={`/collections/${nft.nftCollection._id}`}>
                      <div className={classes['nft-collection']}>
                        <div
                          className={
                            classes[
                              'nft-collection-profile-photo-name-container'
                            ]
                          }
                        >
                          <p className={classes['nft-collection-text']}>
                            COLLECTION:
                          </p>
                          <img
                            className={classes['nft-collection-profile-photo']}
                            src={nft.nftCollection.profilePhoto}
                            alt={nft.nftCollection.name}
                          />
                          <p className={classes['nft-collection-name']}>
                            {nft.nftCollection.name}
                          </p>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>

                <div className={classes['description-container']}>
                  <p className={classes['description']}>{nft.description}</p>
                </div>

                <div className={classes['tags']}>
                  <p title={`#${nft.category.name}`} className={classes['tag']}>
                    #{limitString(nft.category.name, 18)}
                  </p>

                  {nft.additionalCategories &&
                    Array.isArray(nft.additionalCategories) &&
                    nft.additionalCategories.map((category, i) => (
                      <p
                        title={category}
                        className={classes['tag']}
                        key={category + i}
                      >
                        #{limitString(category, 18)}
                      </p>
                    ))}
                </div>

                <div className={classes['author-owner-container']}>
                  <Link
                    to={
                      nft.author._id === profile?._id
                        ? '/my-space'
                        : `/user-feed/${nft.author._id}`
                    }
                    className={classes['creator-container']}
                  >
                    <img
                      alt={nft.author.fullName}
                      className={classes['author-owner-image']}
                      src={nft.author.photo}
                    />
                    <div>
                      <p className={classes['creator-text']}>Creator</p>
                      <p className={classes['author-firstName']}>
                        {nft.author.firstName}
                        {nft.author.verified && (
                          <img
                            alt="Verified"
                            src={VerifiedIcon}
                            style={{
                              height: 14,
                              marginLeft: 2,
                              marginBottom: 2,
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </Link>
                  <Link
                    to={
                      nft.owner._id === profile?._id
                        ? '/my-space'
                        : `/user-feed/${nft.owner._id}`
                    }
                    className={classes['owner-container']}
                  >
                    <img
                      alt={nft.owner.fullName}
                      className={classes['author-owner-image']}
                      src={nft.owner.photo}
                    />
                    <div>
                      <p className={classes['owner-text']}>Owner</p>

                      <p className={classes['owner-firstName']}>
                        {nft.owner.firstName}
                        {nft.owner.verified && (
                          <img
                            alt="Verified"
                            src={VerifiedIcon}
                            style={{
                              height: 14,
                              marginLeft: 2,
                              marginBottom: 2,
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </Link>

                  {nft.claimedBy && nft.claimedBy.firstName && (
                    <Link
                      to={
                        nft.claimedBy._id === profile?._id
                          ? '/my-space'
                          : `/user-feed/${nft.claimedBy._id}`
                      }
                      className={classes['owner-container']}
                    >
                      <img
                        alt={nft.claimedBy.firstName}
                        className={classes['author-owner-image']}
                        src={nft.claimedBy.photo}
                      />
                      <div>
                        <p className={classes['owner-text']}>Claimed By</p>

                        <p className={classes['owner-firstName']}>
                          {nft.claimedBy.firstName}
                          {nft.claimedBy.verified && (
                            <img
                              alt="Verified"
                              src={VerifiedIcon}
                              style={{
                                height: 17,
                                marginLeft: 6,
                                marginBottom: 2,
                              }}
                            />
                          )}
                        </p>
                      </div>
                    </Link>
                  )}

                  <div className={classes['more-options-container']}>
                    {showMoreOptions && (
                      <div className={classes['more-options']}>
                        {nft.author._id === profile?._id && !nft.tokenId && (
                          <p
                            onClick={openEditNftModalHandler}
                            className={classes['option']}
                          >
                            Edit Collectible
                          </p>
                        )}

                        {nft.owner._id === profile?._id && (
                          <p
                            onClick={openTransferNftModalHandler}
                            className={classes['option']}
                          >
                            Transfer Collectible
                          </p>
                        )}

                        {nft.owner._id === profile?._id && (
                          <p
                            onClick={openShareNftModalHandler}
                            className={classes['option']}
                          >
                            Share Collectible
                          </p>
                        )}

                        {nft.owner._id !== profile?._id && (
                          <p
                            onClick={openMakeOfferModalHandler}
                            className={classes['option']}
                          >
                            Make Offer
                          </p>
                        )}

                        {nft.owner._id !== profile?._id && (
                          <p
                            onClick={openReportNftModalHandler}
                            className={classes['report-option']}
                          >
                            Report NFT
                          </p>
                        )}
                      </div>
                    )}

                    <div
                      onClick={toggleMoreOptionsHandler}
                      className={classes['more-icon-container']}
                    >
                      <img
                        alt="More"
                        className={classes['more-icon']}
                        src={MoreIcon}
                        ref={moreIconRef}
                        height={18}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!unlockableContentStatusResponse.isLoading && (
            <div className={classes['benefits-unlockable-content-container']}>
              {renderBenefits()}
            </div>
          )}

          <div className={classes['nft-messages-details-container']}>
            <div className={classes['nft-details-container']}>
              <div className={classes['nft-sections-container']}>
                {NFT_DETAILS_SECTIONS.map((section) => {
                  return (
                    <div
                      onClick={() => setSelectedSection(section)}
                      key={section}
                      className={classes['section-container']}
                    >
                      <div
                        className={`${classes['section-text']} ${
                          section === selectedSection
                            ? classes['selected-section']
                            : ''
                        }`}
                      >
                        {section}

                        {section === selectedSection && (
                          <div className={classes['selected-border']}>
                            &nbsp;
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className={classes['horizontal-line']}></div>

              <div className={classes['section-content']}></div>

              {selectedSection === 'Messages' && <NftMessages nft={nft} />}
              {selectedSection === 'Details' && <NftDetails nft={nft} />}
              {selectedSection === 'Activity' && (
                <NftActivity nftId={nft._id} />
              )}
              {selectedSection === 'Pending Offers' && (
                <PendingOffers nftId={nft._id} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(NftContent);
