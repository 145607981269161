import React, { useCallback, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../../context/auth-context';
import {
  getChatMessagesRequest,
  markChatMessagesAsReadRequest,
} from '../../../httpRequests/httpRequests';
import notify from '../../../utils/notify';
import classes from './Chat.module.css';
import { getDateFrom, limitString } from '../../../helpers/helpers';
import DotIcon from '../../../assets/circle.png';
import ReadIcon from '../../../assets/read-icon-pink.png';
import heart from '../../../assets/pin-icon.png';
import DeliveredIcon from '../../../assets/delivered-icon-pink.png';

function Chat(props) {
  const { profile, getNumberOfUnreadMessagesForMyChats } = useContext(
    AuthContext
  );

  const {
    chat,
    setSelectedChatGroup,
    selectedChatGroupId,
    setSelectedChatGroupId,
    isFavorite,
  } = props;

  const { data } = useQuery(
    ['chat-messages', chat._id],
    () => getChatMessagesRequest(chat._id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const messages = useMemo(() => (data ? data.data : []), [data]);

  const getMessagesUnReadByMe = useCallback(() => {
    const unReadMessages = [];
    messages.forEach((message) => {
      const { readBy, user: sender } = message;
      if (sender !== profile?._id) {
        if (!readBy.some((userId) => userId === profile?._id)) {
          unReadMessages.push(message);
        }
      }
    });

    return unReadMessages;
  }, [messages, profile?._id]);

  const renderUnReadMessagesIndicator = useCallback(() => {
    if (chat.lastMessage && chat.lastMessage.user === profile?._id) {
      if (chat.lastMessage.readBy?.length <= 1) {
        return (
          <div className={classes['read-icons-container']}>
            <img
              alt="Delivered"
              className={classes['half-read-icon']}
              src={DeliveredIcon}
            />
          </div>
        );
      }
    }

    const unReadMessages = getMessagesUnReadByMe();
    if (unReadMessages.length === 0)
      return (
        <div className={classes['read-icons-container']}>
          <img alt="Read" src={ReadIcon} />
        </div>
      );

    return (
      <div className={classes['unread-messages']}>
        <p className={classes['unread-messages-text']}>
          {unReadMessages.length > 99 ? '99+' : unReadMessages.length}
        </p>
      </div>
    );
  }, [chat.lastMessage, profile, getMessagesUnReadByMe]);

  const selectChatGroupHandler = useCallback(async () => {
    try {
      setSelectedChatGroup(chat);
      setSelectedChatGroupId(chat._id);
      await markChatMessagesAsReadRequest(chat._id);
      getNumberOfUnreadMessagesForMyChats();
    } catch (err) {
      notify('error', err, 3000);
    }
    // eslint-disable-next-line
  }, [chat, setSelectedChatGroup, setSelectedChatGroupId]);

  const renderLastMessage = useCallback(() => {
    if (!chat.lastMessage) return `No messages...`;

    if (chat.lastMessage.uploadedFiles?.length > 0) return `File`;

    if (chat.lastMessage.message !== '')
      return limitString(chat.lastMessage.message, 10);
  }, [chat]);

  const renderChat = useCallback(() => {
    const profileImages = [];
    for (let i = 0; i < chat.users.length; i++) {
      const user = chat.users[i];
      if (user._id === profile?._id) continue;

      if (profileImages.length < 4) profileImages.push(user.photo);
    }

    const styleForSelectedChatGroupId = {};
    if (selectedChatGroupId === chat._id) {
      styleForSelectedChatGroupId.background = '#F4F5F5';
      styleForSelectedChatGroupId.borderTopLeftRadius = '8px';
      styleForSelectedChatGroupId.borderBottomLeftRadius = '8px';
    }

    const otherUserIfSingleChat = chat.users.find(
      (user) => user._id !== profile?._id
    );

    return (
      <div
        onClick={selectChatGroupHandler}
        style={styleForSelectedChatGroupId}
        className={classes['chat-container']}
      >
        {!chat.isGroupChat ? (
          <div className={classes['user-container']}>
            <img
              className={classes['user-image']}
              src={otherUserIfSingleChat?.photo}
              alt={otherUserIfSingleChat?.fullName}
              width={42}
              height={42}
            />

            <div style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  gap: 5,
                  justifyContent: 'space-between',
                }}
              >
                <h3
                  title={otherUserIfSingleChat.fullName}
                  className={classes['other-user-fullName']}
                >
                  {limitString(otherUserIfSingleChat.fullName, 20)}
                </h3>

                {isFavorite && (
                  <img
                    height={15}
                    src={heart}
                    alt="Favorite"
                    className={classes['heart-icon']}
                  />
                )}
              </div>

              <div className={classes['last-message-container']}>
                <p className={classes['last-message']}>{renderLastMessage()}</p>
                {chat.lastMessage && (
                  <p className={classes['last-message-date']}>
                    <img
                      alt="Dot"
                      className={classes['dot-icon']}
                      src={DotIcon}
                      width={6}
                    />
                    {getDateFrom(chat.lastMessage.createdAt)}
                  </p>
                )}
                {chat.lastMessage && renderUnReadMessagesIndicator()}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={classes['user-container']}>
              <div className={classes['profile-images-container']}>
                {profileImages.slice(0, 4).map((image, i) => {
                  return (
                    <img
                      key={image + i}
                      className={classes['profile-image']}
                      src={image}
                      width={30}
                      height={30}
                      alt={'User'}
                    />
                  );
                })}
              </div>

              <div style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: 5,
                    justifyContent: 'space-between',
                  }}
                >
                  <h3
                    title={chat.name}
                    className={classes['other-user-fullName']}
                  >
                    {limitString(chat.name, 14)}
                  </h3>

                  {isFavorite && (
                    <img
                      height={15}
                      src={heart}
                      alt="Favorite"
                      className={classes['heart-icon']}
                    />
                  )}
                </div>

                <div className={classes['last-message-container']}>
                  <p className={classes['last-message']}>
                    {renderLastMessage()}
                  </p>
                  {chat.lastMessage && (
                    <p className={classes['last-message-date']}>
                      <img
                        alt="Dot"
                        className={classes['dot-icon']}
                        src={DotIcon}
                        width={6}
                      />
                      {getDateFrom(chat.lastMessage.createdAt)}
                    </p>
                  )}
                  {chat.lastMessage && renderUnReadMessagesIndicator()}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }, [
    chat,
    profile?._id,
    selectedChatGroupId,
    renderUnReadMessagesIndicator,
    selectChatGroupHandler,
    isFavorite,
    renderLastMessage,
  ]);

  return renderChat();
}

export default React.memo(Chat);
