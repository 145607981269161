import React, { useState } from 'react';
import classes from './ListBrandItemForSaleModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { listBrandItemForSaleRequest } from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import notify from '../../../../utils/notify';
import Input from '../../../../components/Input/Input';

function ListBrandItemForSaleModal(props) {
  const queryClient = useQueryClient();
  const { setShowListBrandItemForSaleModal, brandItemId } = props;

  const [price, setPrice] = useState('');
  const [priceTouched, setPriceTouched] = useState(false);
  const [listingBrandItem, setListingBrandItem] = useState(false);

  const closeModalHandler = () => {
    setShowListBrandItemForSaleModal(false);
  };

  const priceChangeHandler = (e) => {
    setPrice(e.target.value);
    setPriceTouched(true);
  };

  const invalidateBrandItemHandler = () =>
    Promise.all([
      queryClient.invalidateQueries(['brand-item', brandItemId]),
      queryClient.refetchQueries(['brand-item', brandItemId]),
    ]);

  const listBrandItemForSaleHandler = async () => {
    setListingBrandItem(true);

    try {
      const requestBody = {
        price: parseFloat(price),
      };
      await listBrandItemForSaleRequest(brandItemId, requestBody);
      await invalidateBrandItemHandler();

      setListingBrandItem(false);

      closeModalHandler();
    } catch (err) {
      setListingBrandItem(false);

      notify('error', err, 2000);
    }
  };

  const priceInputStyle = {};
  const isPriceInvalid = !price || isNaN(price) || parseFloat(price) < 5;
  if (priceTouched && isPriceInvalid) {
    priceInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      closeModal={closeModalHandler}
      title="List for sale"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>
          <Button
            onClick={listBrandItemForSaleHandler}
            disabled={isPriceInvalid || listingBrandItem}
            style={{ width: 200 }}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
    >
      <Input
        style={{ width: '100%', ...priceInputStyle }}
        value={price}
        onChange={priceChangeHandler}
        placeholder="Enter USD amount"
        type="number"
      />
    </Modal>
  );
}

export default ListBrandItemForSaleModal;
