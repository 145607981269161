import React from 'react';
import { limitString } from '../../../../helpers/helpers';
import classes from './Introduction.module.css';

function Introduction(props) {
  const { userProfile } = props;

  return (
    <div className={classes['introduction-page-container']}>
      <div className={classes['introduction-container']}>
        <h3 className={classes['introduction-text']}>Introduction</h3>
      </div>

      {userProfile.introduction ? (
        <p title={userProfile.introduction} className={classes['introduction']}>
          {limitString(userProfile.introduction, 120)}
        </p>
      ) : (
        <p className={classes['no-introduction']}>No Introduction Found</p>
      )}
    </div>
  );
}

export default Introduction;
