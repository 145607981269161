import React, { useEffect, useState } from 'react';
import classes from './CompleteRequestedService.module.css';
import { useHistory, useParams } from 'react-router-dom';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import MetaTags from 'react-meta-tags';
import GoBackIcon from '../../assets/go-back-icon-2.png';
import { useQuery } from 'react-query';
import {
  getSpecificRequestedServiceForMeRequest,
  uploadRequestedServiceFileRequest,
  uploadRequestedServiceTextRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import moment from 'moment';

import StarIcon from '../../assets/custom-benefits-star-icon.png';
import PersonalizedVideoIcon from '../../assets/personalized-video-icon.png';
import PersonalizedImageIcon from '../../assets/personalized-image-icon.png';
import ChatBenefitIcon from '../../assets/chat-benefit-icon.png';
import VideoCallBenefitIcon from '../../assets/video-call-benefit-icon.png';
import ImageVideoUpload from '../../components/ImageVideoUpload/ImageVideoUpload';

import UploadIcon from '../../assets/upload-icon.png';
import Button from '../../components/Button/Button';
import ReactPlayer from 'react-player';

function CompleteRequestedService() {
  const history = useHistory();
  const { requestedServiceId } = useParams();

  const [uploadTextValue, setUploadTextValue] = useState('');
  const [uploadedFile, setUploadedFile] = useState();
  const [uploadedFilePreviewUrl, setUploadedFilePreviewUrl] = useState();
  const [uploadedFileType, setUploadedFileType] = useState();
  const [complitingRequestedService, setComplitingRequestedService] = useState(
    false
  );

  const { isLoading, data, error } = useQuery(
    ['requested-service-for-me', requestedServiceId],
    () => getSpecificRequestedServiceForMeRequest(requestedServiceId)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const renderServiceIcon = (service) => {
    if (service === 'Personalized Video')
      return (
        <img
          className={classes['service-icon']}
          src={PersonalizedVideoIcon}
          alt="Personalized Video"
        />
      );

    if (service === 'Personalized Image')
      return (
        <img
          className={classes['service-icon']}
          src={PersonalizedImageIcon}
          alt="Personalized Benefit"
        />
      );

    if (service === '1/1 Video Call (15mins)')
      return (
        <img
          className={classes['service-icon']}
          src={VideoCallBenefitIcon}
          alt="Video Call Benefit"
        />
      );

    if (service === '1/1 Chat (15mins)')
      return (
        <img
          className={classes['service-icon']}
          src={ChatBenefitIcon}
          alt="Chat Benefit"
        />
      );

    return (
      <img
        className={classes['service-icon']}
        src={StarIcon}
        alt="Custom Services"
        style={{ width: 16, height: 16, marginBottom: 1.5 }}
      />
    );
  };

  const uploadTextValueChangeHandler = (e) => {
    setUploadTextValue(e.target.value);
  };

  const inputFileHandler = (value) => {
    if (!value) return;
    const fileReader = new FileReader();

    setUploadedFile(value);

    if (value.type === 'video/mp4') {
      setUploadedFileType('video');
    } else {
      setUploadedFileType('image');
    }
    try {
      fileReader.onload = () => {
        setUploadedFilePreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  if (isLoading || !data?.data) return null;

  const requestedService = data.data;
  const { from, service, createdAt, price } = requestedService;

  const completeRequestedServiceHandler = async () => {
    setComplitingRequestedService(true);

    try {
      if (uploadedFile && uploadedFileType) {
        const formData = new FormData();

        formData.append('uploadedFile', uploadedFile);
        formData.append('uploadedFileType', uploadedFileType);
        if (uploadTextValue) {
          formData.append('uploadedText', uploadTextValue);
        }

        await uploadRequestedServiceFileRequest(requestedService._id, formData);
      } else {
        if (!uploadTextValue)
          return notify('error', 'Text content is required', 2000);

        const requestBody = {
          uploadedText: uploadTextValue,
        };

        await uploadRequestedServiceTextRequest(
          requestedService._id,
          requestBody
        );
      }
      setComplitingRequestedService(false);

      history.push('/requested-services');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <>
      <MetaTags>
        <title>Complete Requested Service | Glimpse</title>
        <meta
          property="og:title"
          content={`Complete Requested Service | Glimpse`}
        />
      </MetaTags>

      <div className={classes['requested-service-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['requested-service-container']}>
          <header className={classes['requested-service-header']}>
            <div className={classes['go-back-container']}>
              <div onClick={() => history.goBack()}>
                <img
                  src={GoBackIcon}
                  alt="Go Back"
                  className={classes['go-back-icon']}
                />
              </div>
              <h3>
                Requested Service /
                <span className={classes['from-fullName-header']}>
                  {requestedService.from.fullName}
                </span>
              </h3>
            </div>
          </header>

          <div className={classes['buyer-details-container']}>
            <h3 className={classes['buyer-details-text']}>Buyer Details</h3>
            <div className={classes['horizontal-line']}></div>

            <div className={classes['buyer-info']}>
              <div className={classes['photo-fullName-container']}>
                <img
                  src={from.photo}
                  alt={from.fullName}
                  className={classes['from-photo']}
                />
                <div>
                  <p className={classes['from-fullName']}>{from.fullName}</p>
                  <p className={classes['created-at']}>
                    Received:{' '}
                    {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                  </p>
                </div>
              </div>

              <div className={classes['service-container']}>
                <div className={classes['service-icon-container']}>
                  {renderServiceIcon(service)}
                  <p className={classes['service-text']}>{service}</p>
                </div>
                <p className={classes['price']}>${price}</p>
              </div>
            </div>
            {requestedService.description && (
              <div className={classes['note-container']}>
                <p className={classes['note-text']}>Note:</p>
                <p className={classes['note-value']}>
                  {requestedService.description}
                </p>
              </div>
            )}
          </div>

          <div className={classes['delivery-order-container']}>
            <h3 className={classes['delivery-order-text']}>Delivery Order</h3>

            <div className={classes['horizontal-line']}></div>

            <div className={classes['upload-container']}>
              <div>
                <p className={classes['upload-text']}>Upload Text</p>
                <textarea
                  value={uploadTextValue}
                  onChange={uploadTextValueChangeHandler}
                  className={classes['textarea']}
                  placeholder="Enter here"
                  style={{ width: 400, height: 200 }}
                />
              </div>
              <div>
                <p className={classes['upload-text']}>Upload Image/Video</p>

                <ImageVideoUpload onInput={inputFileHandler}>
                  <div className={classes['upload-file-container']}>
                    {uploadedFilePreviewUrl ? (
                      uploadedFileType === 'image' ? (
                        <img
                          className={classes['uploaded-file']}
                          alt="File"
                          src={uploadedFilePreviewUrl}
                        />
                      ) : (
                        <ReactPlayer
                          style={{
                            objectFit: 'cover',
                            borderRadius: 10,
                          }}
                          controls
                          width={400}
                          height={200}
                          url={uploadedFilePreviewUrl}
                        />
                      )
                    ) : (
                      <img
                        alt="File"
                        src={UploadIcon}
                        className={classes['image-post-icon']}
                      />
                    )}
                  </div>
                </ImageVideoUpload>
              </div>
            </div>
          </div>

          <div className={classes['actions-container']}>
            <Button style={{ width: 200 }} darkpinkcolor="true">
              Cancel
            </Button>
            <Button
              onClick={completeRequestedServiceHandler}
              disabled={
                (!uploadedFile && !uploadTextValue) ||
                complitingRequestedService
              }
              style={{ width: 200 }}
              darkpink="true"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompleteRequestedService;
