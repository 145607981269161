import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { getUnlockableContentForSpecificNftRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './UnlockableContentModal.module.css';

function UnlockableContentModal(props) {
  const { setShowUnlockableContentModal, nftId } = props;

  const { isLoading, data, error } = useQuery(
    ['nft-unlockableContent', nftId],
    () => getUnlockableContentForSpecificNftRequest(nftId)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const closeModalHandler = () => {
    setShowUnlockableContentModal(false);
  };

  return (
    <Modal
      title={`Unlockable Content`}
      closeModal={closeModalHandler}
      footer={
        <Button
          style={{ width: 200 }}
          darkpinkcolor="true"
          onClick={closeModalHandler}
        >
          Close
        </Button>
      }
    >
      <div className={classes['unlockable-content-container']}>
        {!isLoading && !error && data?.data && (
          <p className={classes['unlockable-content-text']}>{data.data}</p>
        )}

        {!isLoading && !error && data && data.data === null && (
          <p className={classes['unlockable-content-text']}>
            No unlockable content connected with this collectible!
          </p>
        )}

        {!isLoading && error && (
          <p className={classes['unlockable-content-text']}>{error}</p>
        )}
      </div>
    </Modal>
  );
}

export default UnlockableContentModal;
