import React, { useState } from 'react';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import Input from '../../../../../../components/Input/Input';
import classes from './ChangePasswordNewsFeedModal.module.css';
import { changePasswordForUserCreatedByAdminRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';

function ChangePasswordNewsFeedModal({
  setShowChangePasswordModal,
  setShowSettingsModal,
}) {
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordTouched, setOldPasswordTouched] = useState(false);
  const [oldPasswordValid, setOldPasswordValid] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordTouched, setNewPasswordTouched] = useState(false);
  const [newPasswordValid, setNewPasswordValid] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState('');
  const [confirmPasswordValid, setConfirmPasswordValid] = useState('');
  const [changingPassword, setChangingPassword] = useState(false);

  const closeModalHandler = () => {
    setShowChangePasswordModal(false);
  };

  const oldPasswordChangeHandler = (e) => {
    setOldPassword(e.target.value);
    setOldPasswordTouched(true);
    setOldPasswordValid(e.target.value.length >= 6);
  };

  const newPasswordChangeHandler = (e) => {
    setNewPassword(e.target.value);
    setNewPasswordTouched(true);
    setNewPasswordValid(e.target.value.length >= 6);
  };

  const confirmPasswordChangeHandler = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordTouched(true);
    setConfirmPasswordValid(e.target.value.length >= 6);
  };

  const changePasswordHandler = async () => {
    setChangingPassword(true);

    try {
      const requestBody = {
        newPassword,
      };

      await changePasswordForUserCreatedByAdminRequest(requestBody);
      notify('success', 'Password changed successfully!', 1500);
      setShowSettingsModal(false);
    } catch (error) {
      notify('error', error, 2000);
    }

    setChangingPassword(false);
  };

  const oldPasswordInputStyle = { width: '100%' };
  const newPasswordInputStyle = { width: '100%' };
  const confirmPasswordInputStyle = { width: '100%' };
  if (oldPasswordTouched && oldPasswordValid === false) {
    oldPasswordInputStyle['border'] = '1px solid red';
  }
  if (newPasswordTouched && newPasswordValid === false) {
    newPasswordInputStyle['border'] = '1px solid red';
  }
  if (confirmPasswordTouched && confirmPasswordValid === false) {
    confirmPasswordInputStyle['border'] = '1px solid red';
  }

  const isSaveButtonEnabled =
    !changingPassword &&
    newPasswordValid &&
    confirmPasswordValid &&
    newPassword === confirmPassword;

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
            disabled={true}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            onClick={changePasswordHandler}
            darkpink="true"
            disabled={!isSaveButtonEnabled}
          >
            Save
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Change Password"
    >
      {/* <p className={classes['label']}>Old Password</p>
      <Input
        type="password"
        placeholder="Enter here"
        style={oldPasswordInputStyle}
        value={oldPassword}
        onChange={oldPasswordChangeHandler}
      /> */}

      <p className={classes['label']}>New Password</p>
      <Input
        type="password"
        placeholder="Enter here"
        style={newPasswordInputStyle}
        value={newPassword}
        onChange={newPasswordChangeHandler}
      />

      <p className={classes['label']}>Confirm Password</p>
      <Input
        type="password"
        placeholder="Enter here"
        style={confirmPasswordInputStyle}
        value={confirmPassword}
        onChange={confirmPasswordChangeHandler}
      />
    </Modal>
  );
}

export default ChangePasswordNewsFeedModal;
