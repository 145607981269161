import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import classes from './CreatedCollectionLinkQRCodeModal.module.css';
import CopyIcon from '../../../../../../assets/copy-1.png';
import QRCode from 'qrcode';
import TwitterIcon from '../../../../../../assets/twitter.png';
import LinkedinIcon from '../../../../../../assets/linkedin.png';
import FacebookIcon from '../../../../../../assets/facebook-icon.png';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import notify from '../../../../../../utils/notify';
import PinkOkIcon from '../../../../../../assets/pink-ok.svg';
import DownloadIcon from '../../../../../../assets/download.png';
import {
  notifyClaimNftLinkToMyFollowersRequest,
  notifyCollectionCreatedToMyFollowersRequest,
} from '../../../../../../httpRequests/httpRequests';

function CreatedCollectionLinkQRCodeModal(props) {
  const canvasRef = useRef();
  const {
    collectionId,
    setCreatedCollectionLinkQRCodeModalState,
    createdCollectionLinkQRCodeModalState,
  } = props;

  const { claimable } = createdCollectionLinkQRCodeModalState;

  const [claimNftLinkCopied, setClaimNftLinkCopied] = useState(false);
  const [notifyingMyFollowers, setNotifyingMyFollowers] = useState(false);

  const claimNftLink = claimable
    ? `${window.location.origin}/claim-nft/${collectionId}`
    : `${window.location.origin}/collections/${collectionId}`;

  useEffect(() => {
    QRCode.toCanvas(canvasRef.current, claimNftLink);
  }, [claimNftLink]);

  const closeModalHandler = () => {
    setCreatedCollectionLinkQRCodeModalState({
      collectionId: undefined,
      opened: false,
      claimable: undefined,
      collectionName: undefined,
    });
  };

  const copyClaimNftLinkHandler = async () => {
    try {
      await navigator.clipboard.writeText(claimNftLink);

      setClaimNftLinkCopied(true);

      setTimeout(() => {
        setClaimNftLinkCopied(false);
      }, 2000);
    } catch (err) {
      notify('error', 'Something went wrong!', 2000);
    }
  };

  const downloadQRCodeHandler = async () => {
    try {
      const link = document.createElement('a');
      link.download = claimable
        ? `claim-nft.png`
        : `${createdCollectionLinkQRCodeModalState.collectionName}.png`;
      link.href = canvasRef.current.toDataURL();
      link.click();
    } catch (err) {
      notify('error', 'Something went wrong!', 2000);
    }
  };

  const notifyFollowersHandler = async () => {
    setNotifyingMyFollowers(true);

    try {
      if (claimable) {
        await notifyClaimNftLinkToMyFollowersRequest(collectionId);
      } else {
        await notifyCollectionCreatedToMyFollowersRequest(collectionId);
      }

      notify('success', 'Link sent successfully to all your Followers!', 2000);

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setNotifyingMyFollowers(false);
  };

  return (
    <Modal
      style={{
        height: 580,
      }}
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            disabled={notifyingMyFollowers}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>

          <Button
            style={{ width: 200 }}
            disabled={notifyingMyFollowers}
            onClick={notifyFollowersHandler}
            darkpink="true"
          >
            Notify Followers
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Your Collection"
    >
      <div className={classes['created-collection-container']}>
        <h3 className={classes['congrats-text']}>Congrats!</h3>
        <p className={classes['you-created-collection-text']}>
          You’ve created a Collection!
        </p>

        <p className={classes['collection-links-text']}>
          Here is the link you can share with your followers!
        </p>

        <p className={classes['link']}>
          {claimNftLink}
          <img
            onClick={copyClaimNftLinkHandler}
            src={CopyIcon}
            alt="Copy"
            className={classes['copy-icon']}
          />
        </p>

        {claimNftLinkCopied && (
          <div className={classes['copied-container']}>
            <img
              alt="Copied"
              src={PinkOkIcon}
              className={classes['pinkOk-icon']}
            />
            <p className={classes['copied-text']}>Link Copied!</p>
          </div>
        )}

        <div className={classes['qr-code-container']}>
          <canvas ref={canvasRef}></canvas>

          <div
            onClick={downloadQRCodeHandler}
            className={classes['download-qr-code-container']}
          >
            <img
              src={DownloadIcon}
              alt="Download"
              className={classes['download-icon']}
            />
          </div>
        </div>
        <div className={classes['share-icons-container']}>
          <FacebookShareButton
            url={claimNftLink}
            hashtag={`#CLAIM NFT`}
            description={`CLAIM NFT`}
            title={'CLAIM NFT Link'}
          >
            <div className={classes['facebook-icon-container']}>
              <img
                src={FacebookIcon}
                className={classes['facebook-icon']}
                alt="Facebook"
              />
            </div>
          </FacebookShareButton>

          <TwitterShareButton
            url={claimNftLink}
            hashtag={`#CLAIM NFT`}
            description={`CLAIM NFT`}
            title={'CLAIM NFT Link'}
          >
            <div className={classes['twitter-icon-container']}>
              <img
                src={TwitterIcon}
                className={classes['twitter-icon']}
                alt="Twitter"
              />
            </div>
          </TwitterShareButton>

          <LinkedinShareButton
            url={claimNftLink}
            hashtag={`#CLAIM NFT`}
            description={`CLAIM NFT`}
            title={'CLAIM NFT Link'}
          >
            <div className={classes['linkedin-icon-container']}>
              <img
                src={LinkedinIcon}
                className={classes['linkedin-icon']}
                alt="Linkedin"
              />
            </div>
          </LinkedinShareButton>
        </div>
      </div>
    </Modal>
  );
}

export default CreatedCollectionLinkQRCodeModal;
