import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import notify from '../../utils/notify';
import { useHistory } from 'react-router-dom';
import {
  isInputValidationCompleted,
  isOverallFormValid,
} from '../../helpers/helpers';
import classes from './Register.module.css';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import MetaTags from 'react-meta-tags';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import FacebookIcon from '../../assets/facebook-icon.png';
import HiddenIcon from '../../assets/hidden.png';
import NotHiddenIcon from '../../assets/not-hidden.png';
import MetaMaskIcon from '../../assets/metamask-icon.png';
import GoBackIcon from '../../assets/goBack.png';
import { MetaMaskContext } from '../../context/metamask-context';
import SignupWithUnstoppable from './components/SignupWithUnstoppable/SignupWithUnstoppable';
import GlimpseLogo from '../../assets/glimpse-logo.png';
import AppStoreIcon from '../../assets/app-store-icon.png';
import PlayStoreIcon from '../../assets/play-store-icon.png';

const Register = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const [referredBy, setReferredBy] = useState(queryParams.get('ref') || '');
  // const [referredByTouched, setReferredByTouched] = useState(
  //   !!queryParams.get('ref')
  // );
  const {
    connectedWalletAddress,
    setConnectedWalletAddress,
    isMetaMaskInstalled,
  } = useContext(MetaMaskContext);

  const history = useHistory();
  const [formState, setFormState] = useState({
    firstName: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
    lastName: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
    username: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { required: true },
    },
    email: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { isEmail: true },
    },
    password: {
      value: '',
      touched: false,
      valid: false,
      validationRequirements: { minLength: 6 },
    },
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState();
  const [termsOfServiceChecked, setTermsOfServiceChecked] = useState(false);

  const [showPasswordAsText, setShowPasswordAsText] = useState(false);
  const [createAccountWithMetaMask, setCreateAccountWithMetaMask] = useState(
    false
  );

  const {
    signup,
    signupWithMetaMask,
    loginViaGoogle,
    loginViaFacebook,
  } = useContext(AuthContext);

  useEffect(() => {
    error && notify('error', error, 2000, () => setError());
  }, [error]);

  const inputChangeHandler = (formStateKey, value) => {
    const updatedFormState = { ...formState };
    updatedFormState[formStateKey].value = value;
    updatedFormState[formStateKey].touched = true;

    updatedFormState[formStateKey].valid = isInputValidationCompleted(
      value,
      updatedFormState[formStateKey].validationRequirements
    );

    if (formStateKey === 'username') {
      updatedFormState[formStateKey].valid =
        updatedFormState[formStateKey].valid &&
        !value.includes(' ') &&
        /^[\w-_]*$/.test(value);
    }

    setIsFormValid(isOverallFormValid(updatedFormState));

    setFormState(updatedFormState);
  };

  const referredByChangeHandler = (e) => {
    setReferredBy(e.target.value);
    // setReferredByTouched(true);
  };

  const firstNameStyle = { with: '100%' };
  const lastNameStyle = { width: '100%' };
  const emailStyle = {};
  const passwordStyle = {};
  const usernameStyle = {};
  const referredByStyle = { width: '100%', padding: '11px 10px' };

  if (formState.firstName.valid === false && formState.firstName.touched) {
    firstNameStyle.border = '1px solid red';
  }
  if (formState.lastName.valid === false && formState.lastName.touched) {
    lastNameStyle.border = '1px solid red';
  }

  if (
    formState.email.valid === false &&
    formState.email.touched &&
    !createAccountWithMetaMask
  ) {
    emailStyle.border = '1px solid red';
  }

  if (formState.password.valid === false && formState.password.touched) {
    passwordStyle.border = '1px solid red';
  }

  if (formState.username.valid === false && formState.username.touched) {
    usernameStyle.border = '1px solid red';
  }

  // const isReferredByValid =
  //   referredBy && !referredBy.includes(' ') && /^[\w-_]*$/.test(referredBy);
  // if (!isReferredByValid && referredByTouched) {
  //   referredByStyle.border = '1px solid red';
  // }

  let formButtonStyle = {};
  if (isFormValid === false || !termsOfServiceChecked) {
    formButtonStyle = {
      cursor: 'not-allowed',
      background: 'gray',
      borderRadius: 16,
    };
  } else {
    formButtonStyle = {
      borderRadius: 16,
    };
  }

  const termsOfServiceChangeHandler = (e) => {
    const termsOfServiceChecked = e.target.checked;

    setTermsOfServiceChecked(termsOfServiceChecked);
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();

    // if (!referredBy)
    //   return setError(
    //     '*You must have a referral ID / Link to join the web Beta'
    //   );

    try {
      await signup(
        formState.email.value.toLowerCase(),
        formState.firstName.value,
        formState.lastName.value,
        formState.password.value,
        formState.username.value.toLowerCase(),
        referredBy
      );
      notify(
        'success',
        'Registered successfully. An email was sent to this email to verify the account!',
        1950
      );
      setTimeout(() => {
        history.push('/login');
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  const loginWithGoogleFailureHandler = (err) => {
    const errorMessage = err.details
      ? `Login with Google Error: ${err.details}`
      : 'Something went wrong via Login with Google';
    notify('error', errorMessage, 2000);
  };

  const loginWithGoogleSuccessHandler = async (res) => {
    // if (!referredBy)
    //   return setError(
    //     '*You must have a referral ID / Link to join the web Beta'
    //   );

    try {
      const accessToken = res.tokenId;
      await loginViaGoogle(accessToken, referredBy);
    } catch (error) {
      notify(
        'error',
        error || 'Something went wrong via Login with Google!',
        2000
      );
    }
  };

  const loginWithFacebookHandler = async (res) => {
    try {
      const { accessToken } = res;
      await loginViaFacebook(accessToken, referredBy);
    } catch (err) {
      notify('error', 'Something went wrong via Login with Facebook!', 2000);
    }
  };

  const signupWithMetaMaskHandler = async () => {
    const { ethereum } = window;

    try {
      let metaMaskWalletAddress;
      if (connectedWalletAddress) {
        metaMaskWalletAddress = connectedWalletAddress;
      } else {
        await ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await ethereum.request({ method: 'eth_accounts' });

        metaMaskWalletAddress = accounts[0];
      }

      const msg = `0x${Buffer.from(
        'Use MetaMask to Connect to Glimpse',
        'utf8'
      ).toString('hex')}`;
      const metaMaskPersonalSignature = await ethereum.request({
        method: 'personal_sign',
        params: [msg, metaMaskWalletAddress, 'Example password'],
      });

      await signupWithMetaMask({
        metaMaskPersonalSignature,
        firstName: formState.firstName.value,
        lastName: formState.lastName.value,
        email: formState.email.value,
        username: formState.username.value,
        metaMaskWalletAddress,
        referredBy,
      });

      setConnectedWalletAddress(metaMaskWalletAddress);
    } catch (err) {
      if (typeof err === 'string') {
        notify('error', err, 2000);
      } else notify('error', err.message, 2000);
    }
  };

  const isSignupWithMetaMaskDisabled = () => {
    if (
      !formState.firstName.valid ||
      !formState.lastName.valid ||
      // !formState.email.valid ||
      !formState.username.valid ||
      !termsOfServiceChecked
    ) {
      return true;
    }

    return false;
  };

  let metaMaskButtonStyle = {};
  if (isSignupWithMetaMaskDisabled()) {
    metaMaskButtonStyle = {
      cursor: 'not-allowed',
      background: 'gray',
      borderRadius: 16,
    };
  } else {
    metaMaskButtonStyle = {};
  }

  return (
    <>
      <MetaTags>
        <title>Register | Glimpse</title>
        <meta property="og:title" content={'Register | Glimpse'} />
      </MetaTags>

      <div className={classes['register-container']}>
        <div className={classes['info']}>
          <header className={classes['info-header']}>
            <img
              src={GlimpseLogo}
              alt="Glimpse"
              className={classes['glimpse-logo']}
            />

            <Link to="/apply-as-creator">
              <div className={classes['apply-as-creator-container']}>
                <p className={classes['apply-as-creator-text']}>
                  Apply As Creator
                </p>
              </div>
            </Link>
          </header>

          <div className={classes['info-description']}>
            <h3>Get Inside Access to Your Favorite Creator</h3>
            <p>
              Get the inside scoop, the latest updates, and the behind the scene
              shots by joining your favorite creators private community - It’s
              like being a part of their private story!
            </p>
          </div>

          <div className={classes['app-store-play-store-container']}>
            <a
              href={'https://apps.apple.com/us/app/glimpse-nft/id1630409614'}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={AppStoreIcon}
                alt="App Store"
                className={classes['app-store-icon']}
              />
            </a>

            <a
              href={
                'https://play.google.com/store/apps/details?id=com.glimpse.glimpsenft&hl=en_US&gl=US'
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={PlayStoreIcon}
                alt="Play Store"
                className={classes['play-store-icon']}
              />
            </a>
          </div>
        </div>

        <div className={classes['register-form']}>
          <div className={classes['form-container']}>
            <div className={classes['create-account-container']}>
              {createAccountWithMetaMask && (
                <img height={25} src={MetaMaskIcon} alt="MetaMask" />
              )}
              {createAccountWithMetaMask ? (
                <div className={classes['go-back-container']}>
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={() => setCreateAccountWithMetaMask(false)}
                    width={20}
                    src={GoBackIcon}
                    alt="Go Back"
                  />
                  <h2>Create Account With MetaMask</h2>
                </div>
              ) : (
                <h2>Create Account</h2>
              )}
            </div>

            <div className={classes['form']}>
              <div className={classes['first-last']}>
                <Input
                  value={formState.firstName.value}
                  style={firstNameStyle}
                  onChange={(e) =>
                    inputChangeHandler('firstName', e.target.value)
                  }
                  placeholder="First Name"
                />
                <Input
                  value={formState.lastName.value}
                  style={lastNameStyle}
                  onChange={(e) =>
                    inputChangeHandler('lastName', e.target.value)
                  }
                  placeholder="Last Name"
                />
              </div>
              <div className={classes['email']}>
                <Input
                  value={formState.email.value}
                  style={{ width: '100%', padding: '11px 10px', ...emailStyle }}
                  placeholder="E-mail"
                  onChange={(e) => inputChangeHandler('email', e.target.value)}
                />
              </div>
              <div className={classes['username']}>
                <Input
                  value={formState.username.value}
                  type="text"
                  onChange={(e) =>
                    inputChangeHandler('username', e.target.value)
                  }
                  style={{
                    width: '100%',
                    padding: '11px 10px',
                    ...usernameStyle,
                  }}
                  placeholder="Username"
                />
              </div>

              {!createAccountWithMetaMask && (
                <div className={classes['password']}>
                  <Input
                    value={formState.password.value}
                    type={showPasswordAsText ? 'text' : 'password'}
                    onChange={(e) =>
                      inputChangeHandler('password', e.target.value)
                    }
                    style={{
                      width: '100%',
                      padding: '11px 10px',
                      ...passwordStyle,
                    }}
                    placeholder="Create Password"
                  />

                  <img
                    onClick={() =>
                      setShowPasswordAsText((prevState) => !prevState)
                    }
                    src={!showPasswordAsText ? HiddenIcon : NotHiddenIcon}
                    alt="Hide"
                    className={classes['hidden-icon']}
                  />
                </div>
              )}

              <div className={classes['referral-id-container']}>
                <Input
                  style={referredByStyle}
                  value={referredBy}
                  onChange={referredByChangeHandler}
                  placeholder={'Enter Referral ID (Optional)'}
                />
              </div>

              <div
                onClick={() =>
                  setTermsOfServiceChecked((prevState) => !prevState)
                }
                className={classes['terms-of-service-container']}
              >
                <input
                  checked={termsOfServiceChecked}
                  onChange={termsOfServiceChangeHandler}
                  type={'checkbox'}
                  className={classes['checkbox']}
                />

                <p className={`${classes['paragraph']} ${classes['agree']}`}>
                  I have read and agree to the{' '}
                  <Link
                    rel="noreferrer"
                    to={'/terms'}
                    target="_blank"
                    className={classes['terms']}
                  >
                    Terms of Service
                  </Link>
                </p>
              </div>

              <div style={{ marginBottom: 10 }}></div>

              {!createAccountWithMetaMask ? (
                <Button
                  onClick={submitFormHandler}
                  style={{
                    ...formButtonStyle,
                    width: '100%',
                    marginBottom: 10,
                  }}
                  disabled={
                    !isFormValid || !termsOfServiceChecked
                    // ||
                    // !isReferredByValid ||
                    // !referredBy
                  }
                  darkpink={'true'}
                >
                  Sign Up
                </Button>
              ) : isMetaMaskInstalled ? (
                <Button
                  onClick={signupWithMetaMaskHandler}
                  style={{
                    ...metaMaskButtonStyle,
                    width: '100%',
                    marginBottom: 10,
                  }}
                  disabled={isSignupWithMetaMaskDisabled()}
                  darkpink={'true'}
                >
                  Sign Up MetaMask
                </Button>
              ) : (
                <a
                  rel="noreferrer"
                  href={`https://metamask.io/download/`}
                  target="_blank"
                >
                  <div className={classes['signup-metamask-button']}>
                    <img
                      src={MetaMaskIcon}
                      alt="MetaMask"
                      className={classes['metamask-icon']}
                    />
                    <p className={classes['signup-metamask-text']}>
                      Install & Login With MetaMask
                    </p>
                  </div>
                </a>
              )}

              {!createAccountWithMetaMask && (
                <Link to="/">
                  <div className={classes['already-have-an-account']}>
                    <p>Already have an account? Sign In</p>
                  </div>
                </Link>
              )}
              {!createAccountWithMetaMask && (
                <div className={classes['facebook-google-container']}>
                  <GoogleLogin
                    clientId={
                      '937758613434-b2fbdud15euo7ugs79inld6bbflmcr73.apps.googleusercontent.com'
                    }
                    buttonText={
                      <p className={classes['login-google']}>
                        Sign Up With Google
                      </p>
                    }
                    // disabled={!referredBy}
                    className="login-with-google-btn"
                    onSuccess={loginWithGoogleSuccessHandler}
                    onFailure={loginWithGoogleFailureHandler}
                    cookiePolicy={'single_host_origin'}
                  />
                  <div className="facebook-login-container">
                    <FacebookLogin
                      // isDisabled={!referredBy}
                      appId="303589791961151"
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={loginWithFacebookHandler}
                      scope="public_profile,email,user_photos,user_videos,user_friends"
                      cssClass="my-facebook-button-class"
                      textButton="Sign Up With Facebook"
                      icon={
                        <img
                          alt="Facebook"
                          src={FacebookIcon}
                          className={classes['facebook-icon']}
                        />
                      }
                    />
                  </div>
                </div>
              )}

              {!createAccountWithMetaMask && (
                <div
                  onClick={() => setCreateAccountWithMetaMask(true)}
                  className={classes['signup-metamask-button']}
                >
                  <img
                    src={MetaMaskIcon}
                    alt="MetaMask"
                    className={classes['metamask-icon']}
                  />
                  <p className={classes['signup-metamask-text']}>
                    Sign Up With MetaMask
                  </p>
                </div>
              )}

              {!createAccountWithMetaMask && <SignupWithUnstoppable />}
            </div>
          </div>
        </div>

        {/* <div className={classes['info']}>
          <img
            alt="Signup Preview"
            src={SignupImagePreview}
            className={classes['signup-image-preview']}
          />

          <div className={classes['info-center']}>
            <img
              alt="Glimpse"
              className={classes['glimpse-vector']}
              src={GlimpseVector}
            />

            <h3>
              Social media built to reward ALL users. Whether you're a content
              creator,
              <span style={{ display: 'block' }}>
                supporter, or simply use social media to stay connected - we
                designed Glimpse with you in mind.
              </span>
            </h3>
            <p className={classes['through']}>
              With Glimpse, users can easily create NFT's (seriously, it's
              grandma friendly), connect with others who have verified shared
              interests, and receive rewards simply for being social!
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Register;
