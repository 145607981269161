import React, { useEffect } from 'react';
import classes from './RequestedServicesFromMe.module.css';
import { useQuery } from 'react-query';
import { getRequestedServicesFromMeRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import RequestedServiceFromMe from '../RequestedServiceFromMe/RequestedServiceFromMe';

function RequestedServicesFromMe() {
  const { isLoading, data, error } = useQuery(
    ['requested-services', 'Sent'],
    getRequestedServicesFromMeRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <>
      {!isLoading && data?.data && data.data.length === 0 && (
        <div className={classes['no-requested-services-container']}>
          <p className={classes['no-requested-services-text']}>
            No Requested Services Found!
          </p>
        </div>
      )}

      <div className={classes['requested-services-list']}>
        {!isLoading &&
          data?.data &&
          data.data.map((requestedService) => (
            <RequestedServiceFromMe
              key={requestedService._id}
              requestedService={requestedService}
            />
          ))}
      </div>
    </>
  );
}

export default RequestedServicesFromMe;
