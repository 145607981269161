import React, { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import classes from './EditGroupInfoModal.module.css';
import TextArea from '../../../../components/TextArea/TextArea';
import Input from '../../../../components/Input/Input';
import notify from '../../../../utils/notify';
import { updateMyGroupRequest } from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';

export default function EditGroupInfoModal(props) {
  const queryClient = useQueryClient();

  const { setShowEditGroupInfoModal, groupId } = props;

  const [about, setAbout] = useState(props.about);
  const [groupName, setGroupName] = useState(props.groupName);

  const closeModalHandler = () => {
    setShowEditGroupInfoModal(false);
  };

  const aboutChangeHandler = (e) => {
    setAbout(e.target.value);
  };

  const groupNameChangeHandler = (e) => {
    setGroupName(e.target.value);
  };

  const editGroupInfoHandler = async () => {
    try {
      const requestBody = {
        about,
        name: groupName,
      };

      await updateMyGroupRequest(groupId, requestBody);
      queryClient.invalidateQueries(['groups', groupId]);
      queryClient.invalidateQueries(['groups', groupId]);

      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const aboutInputStyle = {};
  if (!about) {
    aboutInputStyle['border'] = '1px solid red';
  }
  const groupNameInputStyle = {};
  if (!groupName) {
    groupNameInputStyle['border'] = '1px solid red';
  }

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Edit Group Information"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            onClick={editGroupInfoHandler}
            disabled={!groupName || !about}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
    >
      <div>
        <p className={classes['label']}>Name</p>
        <Input
          onChange={groupNameChangeHandler}
          style={{ width: '100%', ...groupNameInputStyle }}
          placeholder={'Enter here'}
          value={groupName}
        />

        <p className={classes['label']}>About</p>
        <TextArea
          onChange={aboutChangeHandler}
          style={{ width: '100%', minHeight: 100, ...aboutInputStyle }}
          placeholder={'Enter here'}
          value={about}
        />
      </div>
    </Modal>
  );
}
