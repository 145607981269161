import React from 'react';
import Admins from './components/Admins/Admins';
import MembersExceptAdmins from './components/MembersExceptAdmins/MembersExceptAdmins';
import classes from './Members.module.css';

function Members(props) {
  const { numberOfMembers, groupId, isMyRoleAdmin } = props;

  return (
    <div className={classes['members-container']}>
      <div className={classes['members-header']}>
        <h3 className={classes['members-text']}>Members</h3>
        <p className={classes['circle']}></p>
        <p className={classes['number-of-members']}>{numberOfMembers}</p>
      </div>

      <p className={classes['new-people-text']}>
        New people who join this group will appear here.
      </p>

      <div className={classes['horizontal-line']}></div>

      <Admins isMyRoleAdmin={isMyRoleAdmin} groupId={groupId} />

      <div
        style={{ marginTop: 10 }}
        className={classes['horizontal-line']}
      ></div>

      <MembersExceptAdmins isMyRoleAdmin={isMyRoleAdmin} groupId={groupId} />
    </div>
  );
}

export default Members;
