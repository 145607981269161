import React, { useEffect } from 'react';
import './nft.scss';
import NftContent from './NftContent';
import classes from './Nft.module.css';
import { getNftByIdRequest } from '../../httpRequests/httpRequests';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import notify from '../../utils/notify';
import UserSidebar from '../../components/UserSidebar/UserSidebar';

const Nft = () => {
  const { nftId } = useParams();
  const { isLoading, data, error } = useQuery(
    ['nfts', nftId],
    () => getNftByIdRequest(nftId),
    {
      refetchInterval: 20000,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['nft-page-container']}>
      <div className={classes['user-sidebar-container']}>
        <UserSidebar selectedLink="marketplace" />
      </div>
      {!isLoading && data && <NftContent nft={data.nft} />}
    </div>
  );
};

export default Nft;
