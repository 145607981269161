import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { Picker } from 'emoji-mart';
import { useInfiniteQuery, useQueryClient, useQuery } from 'react-query';
import Button from '../../../../../../components/Button/Button';
import {
  createGroupMessageRequest,
  getAllGroupMembersForASpecificGroupRequest,
  getAllMembersForASpecificPrivateGroupChannelRequest,
  getGroupMessagesForSpecificChannelRequest,
  getSpecificGroupChannelRequest,
  markAllGroupChannelMessagesAsReadRequest,
} from '../../../../../../httpRequests/httpRequests';
import EmojiIcon from '../../../../../../assets/emoji.png';
import classes from './Messages.module.css';
import notify from '../../../../../../utils/notify';
import {
  convertToMB,
  downloadFile,
  isToday,
  isValidUrl,
} from '../../../../../../helpers/helpers';
import moment from 'moment';
import { AuthContext } from '../../../../../../context/auth-context';
import { Link, useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { LinkedList } from '../../../../../../data-structures/LinkedList';
import PrivateChannelHeader from '../PrivateChannelHeader/PrivateChannelHeader';
import PublicChannelHeader from '../PublicChannelHeader/PublicChannelHeader';
import { SocketContext } from '../../../../../../context/socket-context';
import { GROUP_MESSAGE_CREATED } from '../../../../../../constants/SOCKET_EVENTS';
import { Document, Page, pdfjs } from 'react-pdf';
import FileModal from '../../../../../../components/ImageModal/ImageModal';
import ImagePostIcon from '../../../../../../assets/image-post-1.png';
import ReactPlayer from 'react-player';
import MultipleFileUpload from '../../../../../../components/MultipleFileUpload/MultipleFileUpload';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RESULTS_PER_PAGE = 30;
let fetchedPages = 1;

let GROUP_ID;
let CHANNEL_ID;

function Messages(props) {
  const {
    groupId,
    channelId,
    isSelectedChannelPrivate,
    selectedChannelName,
    setSelectedChannelName,
    isMyRoleAdmin,
    setIsSelectedChannelPrivate,
    setSelectedChannelId,
  } = props;

  const groupChannelResponse = useQuery(
    ['group-channel', channelId, groupId],
    () => getSpecificGroupChannelRequest(groupId, channelId)
  );

  const queryClient = useQueryClient();
  const messagesRef = useRef();
  const history = useHistory();
  const { profile, getNumberOfUnreadGroupMessagesForMyGroups } = useContext(
    AuthContext
  );
  const { socket } = useContext(SocketContext);
  const messageInputRef = useRef();
  const [message, setMessage] = useState('');
  const [showEmojiEditor, setShowEmojiEditor] = useState(false);
  const [
    scrolledToBottomOnInitialLoad,
    setScrolledToBottomOnInitialLoad,
  ] = useState(false);

  const [splittedMessage, setSplittedMessage] = useState([]);
  const [mentionedUsernames, setMentionedUsernames] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [fileModalState, setFileModalState] = useState({
    show: false,
    fileUrl: undefined,
    fileType: undefined,
    fileName: undefined,
  });

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['group-messages', groupId, channelId],
    ({ pageParam = 1 }) =>
      getGroupMessagesForSpecificChannelRequest(
        groupId,
        channelId,
        pageParam,
        RESULTS_PER_PAGE
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    markAllGroupChannelMessagesAsReadRequest(groupId, channelId).then(() => {
      getNumberOfUnreadGroupMessagesForMyGroups();
    });
    // eslint-disable-next-line
  }, [groupId, channelId]);

  const getMentionValue = () => {
    if (message === '') return '';

    if (splittedMessage[splittedMessage.length - 1]?.includes('\n@')) {
      let mentionValue = '';
      let i = splittedMessage[splittedMessage.length - 1].length - 1;
      while (splittedMessage[splittedMessage.length - 1][i] !== '@') {
        mentionValue += splittedMessage[splittedMessage.length - 1][i];
        i--;
      }
      return mentionValue.split('').reverse().join('');
    }

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (msg[0] === '@' && mentionedUsernames[msg]) continue;

      if (msg[0] === '@' && !mentionedUsernames[msg]) {
        const mentionValue = msg.replace('@', '');
        return mentionValue;
      }
    }
    return '';
  };

  const mentionValue = getMentionValue();

  const mentionKey = isSelectedChannelPrivate
    ? ['private-channel-group-members', channelId, groupId, mentionValue]
    : ['group-mentions', groupId, mentionValue];

  const mentionFn = () =>
    isSelectedChannelPrivate
      ? getAllMembersForASpecificPrivateGroupChannelRequest(
          groupId,
          channelId,
          1,
          50,
          mentionValue
        )
      : getAllGroupMembersForASpecificGroupRequest(
          1,
          50,
          groupId,
          mentionValue
        );

  const { data: mentions } = useQuery(mentionKey, mentionFn);

  const formatMessages = () => {
    const allMessages = new LinkedList();
    data.pages.forEach((page) => {
      page.data.forEach((message) => {
        allMessages.insert(message);
      });
    });

    return allMessages.toArray();
  };

  const messages = useMemo(() => {
    if (!data?.pages) return [];

    return formatMessages();
    // eslint-disable-next-line
  }, [data]);

  const scrollToBottom = useCallback(() => {
    const scrollingElement = messagesRef.current;
    if (scrollingElement)
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
  }, [messagesRef]);

  const scrollWhenFetchingData = useCallback(() => {
    const scrollingElement = messagesRef.current;
    if (scrollingElement)
      scrollingElement.scrollTop = scrollingElement.scrollHeight / 2;
  }, [messagesRef]);

  useEffect(() => {
    setScrolledToBottomOnInitialLoad(false);
    setMessage('');
    setSplittedMessage([]);
  }, [groupId, channelId]);

  useEffect(() => {
    if (messages.length > 0 && scrolledToBottomOnInitialLoad === false) {
      setTimeout(scrollToBottom);
      setScrolledToBottomOnInitialLoad(true);
    }
  }, [messages, scrolledToBottomOnInitialLoad, scrollToBottom]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    if (!messagesRef.current) return;

    const messagesRefElement = messagesRef.current;
    let fetching = false;
    const scrollHandler = async (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;

      if (
        !fetching &&
        scrollHeight - scrollTop - clientHeight > 100 * fetchedPages
      ) {
        fetching = true;

        if (hasNextPage) {
          fetchedPages += 3;
          await fetchNextPage();
          setTimeout(() => scrollWhenFetchingData());
          fetching = false;
        }
      }
    };

    messagesRefElement.addEventListener('scroll', scrollHandler);
    return () => {
      messagesRefElement.removeEventListener('scroll', scrollHandler);
    };
  }, [
    hasNextPage,
    fetchNextPage,
    queryClient,
    messagesRef,
    groupId,
    channelId,
    scrollWhenFetchingData,
  ]);

  useEffect(() => {
    GROUP_ID = groupId;
    CHANNEL_ID = channelId;
  }, [groupId, channelId]);

  useEffect(() => {
    if (!socket) return;

    socket.on(GROUP_MESSAGE_CREATED, async (data) => {
      if (data.groupId === GROUP_ID && data.channelId === CHANNEL_ID) {
        await Promise.all([
          queryClient.invalidateQueries([
            'group-messages',
            data.groupId,
            data.channelId,
          ]),
          queryClient.refetchQueries([
            'group-messages',
            data.groupId,
            data.channelId,
          ]),
        ]);

        setTimeout(scrollToBottom);
      }
    });
    // eslint-disable-next-line
  }, []);

  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
    const splittedMessage = e.target.value.split(' ');
    setSplittedMessage(splittedMessage);

    const updatedMentionedUsernames = {};
    for (const usernameKey in mentionedUsernames) {
      if (splittedMessage.includes(usernameKey)) {
        updatedMentionedUsernames[usernameKey] =
          mentionedUsernames[usernameKey];
      }
    }

    setMentionedUsernames(updatedMentionedUsernames);
  };

  const shouldNotShowMentionsTable = () => {
    return splittedMessage.every((msg) => {
      return (
        msg === '' ||
        msg === ' ' ||
        msg[0] !== '@' ||
        (msg[0] === '@' && typeof mentionedUsernames[msg] === 'object')
      );
    });
  };

  const shouldShowMentionsTableInNewLine = () => {
    return splittedMessage[splittedMessage.length - 1]?.includes('\n@');
  };

  const selectUserToMentionHandler = (user) => {
    const updatedMentionedUsernames = { ...mentionedUsernames };

    for (let i = 0; i < splittedMessage.length; i++) {
      const msg = splittedMessage[i];

      if (
        msg[0] === '@' &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[i] = `@${username}`;
      } else if (
        splittedMessage[splittedMessage.length - 1]?.includes('\n@') &&
        typeof updatedMentionedUsernames[msg] !== 'object'
      ) {
        const username =
          user.username && user.username.length > 1
            ? user.username.trim()
            : user.firstName.trim();
        updatedMentionedUsernames[`@${username}`] = user;
        splittedMessage[splittedMessage.length - 1] = `\n@${username}`;
      }
    }

    splittedMessage.push('');
    setMentionedUsernames(updatedMentionedUsernames);
    setMessage(splittedMessage.join(' '));
    setSplittedMessage(splittedMessage);
    messageInputRef.current.focus();
  };

  const toggleEmojiEditorHandler = () => {
    setShowEmojiEditor((prevState) => !prevState);
  };

  const removeUploadedFileHandler = (index) => {
    setUploadedFiles((prevState) => prevState.filter((_, i) => i !== index));
  };

  const inputFileHandler = (value) => {
    if (!value) return;

    let files = [...value];
    let totalFilesSize = 0;
    files.forEach((el) => {
      totalFilesSize += el.size;
    });
    if (totalFilesSize > 10000000)
      return notify(
        'error',
        'You can not upload more than 10MB Content!',
        2000
      );

    files.forEach((value) => {
      const fileReader = new FileReader();

      try {
        fileReader.onload = () => {
          setUploadedFiles((prevState) => [
            ...prevState,
            { type: value.type, url: value, previewUrl: fileReader.result },
          ]);
        };

        fileReader.readAsDataURL(value);
      } catch (err) {
        notify('error', err, 2000);
      }
    });
  };

  const getMessagesGroupedByDate = useCallback(() => {
    const messagesGroupedByDate = {};
    messages.forEach((message) => {
      const { createdAt } = message;

      const groupedByDateKey = isToday(createdAt)
        ? 'Today'
        : `${moment(createdAt).format('MMMM Do, YYYY')}`;

      if (messagesGroupedByDate[groupedByDateKey]) {
        messagesGroupedByDate[groupedByDateKey].push(message);
      } else {
        messagesGroupedByDate[groupedByDateKey] = [message];
      }
    });

    const groupedMessages = [];
    for (const key in messagesGroupedByDate) {
      groupedMessages.push({
        date: key,
        messages: messagesGroupedByDate[key],
      });
    }

    return groupedMessages;
  }, [messages]);

  const messagesGroupedByDate = useMemo(() => getMessagesGroupedByDate(), [
    getMessagesGroupedByDate,
  ]);

  const renderMessages = useCallback(() => {
    if (messagesGroupedByDate.length === 0 && !isLoading && !isFetching) {
      return <p className={classes['no-messages']}>No messages yet</p>;
    }

    return messagesGroupedByDate.map(({ date, messages }) => {
      return (
        <div key={date}>
          <p className={classes['date-text']}>{date}</p>
          {messages.map(
            ({ message, _id, sender, createdAt, mentions, uploadedFiles }) => {
              const isMyMessage = profile?._id === sender._id;

              if (isMyMessage) {
                return (
                  <>
                    {message && (
                      <div
                        key={_id}
                        className={classes['my-message-container']}
                      >
                        <div className={classes['my-message-content']}>
                          {message.split('\n').map((newLineMessage, i) => {
                            const splittedNewLineMessage = newLineMessage.split(
                              ' '
                            );
                            return (
                              <p
                                className={classes['my-message-text']}
                                key={
                                  newLineMessage + i + Math.random().toString()
                                }
                              >
                                {splittedNewLineMessage.map((msg) => {
                                  if (msg === '\r')
                                    return (
                                      <p
                                        key={msg + i + Math.random().toString()}
                                        className={classes['empty-space']}
                                      >
                                        &nbsp;
                                      </p>
                                    );

                                  if (msg === '' || msg === ' ')
                                    return (
                                      <span
                                        key={msg + i + Math.random().toString()}
                                      >
                                        {' '}
                                      </span>
                                    );
                                  const mentionIndex = mentions.findIndex(
                                    ({ mentionUsername }) =>
                                      mentionUsername === msg
                                  );

                                  const isMention =
                                    msg[0] === '@' && mentionIndex !== -1;

                                  if (isMention) {
                                    return (
                                      <span
                                        key={msg + i + Math.random().toString()}
                                      >
                                        <span> </span>
                                        <span
                                          className={classes['my-mention']}
                                          onClick={() =>
                                            history.push(
                                              mentions[mentionIndex]?.data
                                                .id === profile?._id
                                                ? '/my-space'
                                                : `/user-feed/${mentions[mentionIndex]?.data.id}`
                                            )
                                          }
                                        >
                                          {msg}
                                        </span>
                                      </span>
                                    );
                                  }

                                  if (isValidUrl(msg)) {
                                    return (
                                      <span
                                        key={msg + i + Math.random().toString()}
                                      >
                                        <span> </span>
                                        <a
                                          rel="noreferrer"
                                          className={classes['other-user-link']}
                                          target={'_blank'}
                                          href={
                                            msg.startsWith('https://')
                                              ? msg
                                              : `https://${msg}`
                                          }
                                        >
                                          {msg}
                                        </a>
                                      </span>
                                    );
                                  }

                                  return (
                                    <span
                                      key={msg + i + Math.random().toString()}
                                    >
                                      <span> </span>
                                      <span>{msg}</span>
                                    </span>
                                  );
                                })}
                              </p>
                            );
                          })}

                          <div
                            className={classes['date-delivered-icon-container']}
                          >
                            <p className={classes['my-created-at']}>
                              {moment(createdAt).format('HH:mm A')}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={classes['my-uploaded-files']}>
                      {uploadedFiles.map(
                        ({ fileType, fileUrl, fileName, fileSize }, i) => {
                          if (fileType === 'application/pdf') {
                            return (
                              <div
                                onClick={() =>
                                  downloadFile({
                                    fileUrl,
                                    fileName,
                                    contentType: fileType,
                                  })
                                }
                                key={fileUrl + i}
                                className={classes['my-pdf-message-content']}
                              >
                                <div
                                  className={classes['file-fileName-container']}
                                >
                                  <Document file={fileUrl}>
                                    <Page height={25} pageNumber={1} />
                                  </Document>

                                  <div
                                    className={
                                      classes['fileName-fileSize-container']
                                    }
                                  >
                                    <p className={classes['my-fileName']}>
                                      {fileName}
                                    </p>
                                    <p className={classes['my-file-size']}>
                                      {convertToMB(fileSize)} MB
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={
                                    classes['date-delivered-icon-container']
                                  }
                                >
                                  <p className={classes['my-created-at']}>
                                    {moment(createdAt).format('HH:mm A')}
                                  </p>
                                </div>
                              </div>
                            );
                          } else if (fileType === 'video/mp4') {
                            return (
                              <div
                                onClick={() =>
                                  setFileModalState({
                                    show: true,
                                    fileUrl,
                                    fileType,
                                    fileName,
                                  })
                                }
                                key={fileUrl + i}
                                className={classes['my-pdf-message-content']}
                              >
                                <div
                                  className={classes['file-fileName-container']}
                                >
                                  <video
                                    height={30}
                                    src={fileUrl}
                                    alt="FileImage"
                                  />
                                  <div
                                    className={
                                      classes['fileName-fileSize-container']
                                    }
                                  >
                                    <p className={classes['my-fileName']}>
                                      {fileName}
                                    </p>
                                    <p className={classes['my-file-size']}>
                                      {convertToMB(fileSize)} MB
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={
                                    classes['date-delivered-icon-container']
                                  }
                                >
                                  <p className={classes['my-created-at']}>
                                    {moment(createdAt).format('HH:mm A')}
                                  </p>
                                </div>
                              </div>
                            );
                          } else if (fileType.startsWith('image')) {
                            return (
                              <div
                                onClick={() =>
                                  setFileModalState({
                                    show: true,
                                    fileUrl,
                                    fileType,
                                    fileName,
                                  })
                                }
                                key={fileUrl + i}
                                className={classes['my-pdf-message-content']}
                              >
                                <div
                                  className={classes['file-fileName-container']}
                                >
                                  <img
                                    height={30}
                                    src={fileUrl}
                                    alt="FileImage"
                                  />
                                  <div
                                    className={
                                      classes['fileName-fileSize-container']
                                    }
                                  >
                                    <p className={classes['my-fileName']}>
                                      {fileName}
                                    </p>
                                    <p className={classes['my-file-size']}>
                                      {convertToMB(fileSize)} MB
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={
                                    classes['date-delivered-icon-container']
                                  }
                                >
                                  <p className={classes['my-created-at']}>
                                    {moment(createdAt).format('HH:mm A')}
                                  </p>
                                </div>
                              </div>
                            );
                          }

                          return null;
                        }
                      )}
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    {message && (
                      <div
                        key={_id}
                        className={classes['other-user-message-container']}
                      >
                        <Link to={`/user-feed/${sender._id}`}>
                          <img
                            alt={sender.fullName}
                            width={40}
                            height={40}
                            className={classes['sender-photo']}
                            src={sender.photo}
                          />
                        </Link>
                        <div className={classes['message-content']}>
                          {message &&
                            message.split('\n').map((newLineMessage, i) => {
                              const splittedNewLineMessage = newLineMessage.split(
                                ' '
                              );
                              return (
                                <p
                                  className={classes['message-text']}
                                  key={
                                    newLineMessage +
                                    i +
                                    Math.random().toString()
                                  }
                                >
                                  {splittedNewLineMessage.map((msg) => {
                                    if (msg === '\r')
                                      return (
                                        <p
                                          key={
                                            msg + i + Math.random().toString()
                                          }
                                          className={classes['empty-space']}
                                        >
                                          &nbsp;
                                        </p>
                                      );

                                    if (msg === '' || msg === ' ')
                                      return (
                                        <span
                                          key={
                                            msg + i + Math.random().toString()
                                          }
                                        >
                                          {' '}
                                        </span>
                                      );
                                    const mentionIndex = mentions.findIndex(
                                      ({ mentionUsername }) =>
                                        mentionUsername === msg
                                    );

                                    const isMention =
                                      msg[0] === '@' && mentionIndex !== -1;

                                    if (isMention) {
                                      return (
                                        <span
                                          className={classes['mention']}
                                          key={
                                            msg + i + Math.random().toString()
                                          }
                                        >
                                          <span> </span>
                                          <span
                                            onClick={() =>
                                              history.push(
                                                mentions[mentionIndex]?.data
                                                  .id === profile?._id
                                                  ? '/my-space'
                                                  : `/user-feed/${mentions[mentionIndex]?.data.id}`
                                              )
                                            }
                                          >
                                            {msg}
                                          </span>
                                        </span>
                                      );
                                    }

                                    if (isValidUrl(msg)) {
                                      return (
                                        <span
                                          key={
                                            msg + i + Math.random().toString()
                                          }
                                        >
                                          <span> </span>
                                          <a
                                            rel="noreferrer"
                                            className={
                                              classes['other-user-link']
                                            }
                                            target={'_blank'}
                                            href={
                                              msg.startsWith('https://')
                                                ? msg
                                                : `https://${msg}`
                                            }
                                          >
                                            {msg}
                                          </a>
                                        </span>
                                      );
                                    }

                                    return (
                                      <span
                                        key={msg + i + Math.random().toString()}
                                      >
                                        <span> </span>
                                        <span>{msg}</span>
                                      </span>
                                    );
                                  })}
                                </p>
                              );
                            })}

                          <p className={classes['created-at']}>
                            {moment(createdAt).format('HH:mm A')}
                          </p>
                        </div>
                      </div>
                    )}
                    {uploadedFiles?.length > 0 && (
                      <div className={classes['other-user-message-container']}>
                        <Link to={`/user-feed/${sender._id}`}>
                          <img
                            alt={sender.fullName}
                            width={40}
                            height={40}
                            className={classes['sender-photo']}
                            src={sender.photo}
                          />
                        </Link>
                        <div className={classes['uploaded-files-container']}>
                          {uploadedFiles?.map(
                            ({ fileType, fileUrl, fileName, fileSize }, i) => {
                              if (fileType === 'application/pdf') {
                                return (
                                  <div
                                    onClick={() =>
                                      downloadFile({
                                        fileUrl,
                                        fileName,
                                        contentType: fileType,
                                      })
                                    }
                                    key={fileUrl + i}
                                  >
                                    <div
                                      className={classes['pdf-message-content']}
                                    >
                                      <div
                                        className={
                                          classes['file-fileName-container']
                                        }
                                      >
                                        <Document file={fileUrl}>
                                          <Page height={25} pageNumber={1} />
                                        </Document>

                                        <div
                                          className={
                                            classes[
                                              'fileName-fileSize-container'
                                            ]
                                          }
                                        >
                                          <p className={classes['fileName']}>
                                            {fileName}
                                          </p>
                                          <p className={classes['file-size']}>
                                            {convertToMB(fileSize)} MB
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          classes[
                                            'date-delivered-icon-container'
                                          ]
                                        }
                                      >
                                        <p className={classes['created-at']}>
                                          {moment(createdAt).format('HH:mm A')}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else if (fileType === 'video/mp4') {
                                return (
                                  <div
                                    onClick={() =>
                                      setFileModalState({
                                        show: true,
                                        fileUrl,
                                        fileType,
                                        fileName,
                                      })
                                    }
                                    key={fileUrl + i}
                                    className={classes['pdf-message-content']}
                                  >
                                    <div
                                      className={
                                        classes['file-fileName-container']
                                      }
                                    >
                                      <video
                                        height={30}
                                        src={fileUrl}
                                        alt="FileVideo"
                                      />
                                      <div
                                        className={
                                          classes['fileName-fileSize-container']
                                        }
                                      >
                                        <p className={classes['fileName']}>
                                          {fileName}
                                        </p>
                                        <p className={classes['file-size']}>
                                          {convertToMB(fileSize)} MB
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        classes['date-delivered-icon-container']
                                      }
                                    >
                                      <p className={classes['created-at']}>
                                        {moment(createdAt).format('HH:mm A')}
                                      </p>
                                    </div>
                                  </div>
                                );
                              } else if (fileType.startsWith('image')) {
                                return (
                                  <div
                                    onClick={() =>
                                      setFileModalState({
                                        show: true,
                                        fileUrl,
                                        fileType,
                                        fileName,
                                      })
                                    }
                                    key={fileUrl + i}
                                    className={classes['pdf-message-content']}
                                  >
                                    <div
                                      className={
                                        classes['file-fileName-container']
                                      }
                                    >
                                      <img
                                        height={30}
                                        src={fileUrl}
                                        alt="FileImage"
                                      />
                                      <div
                                        className={
                                          classes['fileName-fileSize-container']
                                        }
                                      >
                                        <p className={classes['fileName']}>
                                          {fileName}
                                        </p>
                                        <p className={classes['file-size']}>
                                          {convertToMB(fileSize)} MB
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        classes['date-delivered-icon-container']
                                      }
                                    >
                                      <p className={classes['created-at']}>
                                        {moment(createdAt).format('HH:mm A')}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }

                              return null;
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </>
                );
              }
            }
          )}
        </div>
      );
    });
  }, [messagesGroupedByDate, profile?._id, history, isLoading, isFetching]);

  const sendMessageHandler = async () => {
    setSendingMessage(true);

    try {
      const mentions = [];
      Object.entries(mentionedUsernames).forEach(([mentionUsername, data]) => {
        mentions.push({ mentionUsername, data: { ...data, id: data._id } });
      });

      const formData = new FormData();
      formData.append('message', message);
      formData.append('mentions', JSON.stringify(mentions));

      if (uploadedFiles.length > 0) {
        for (let i = 0; i < uploadedFiles.length; i++) {
          formData.append('uploadedFiles', uploadedFiles[i].url);
        }
      }

      await createGroupMessageRequest(groupId, channelId, formData);
      setMessage('');
      setUploadedFiles([]);

      await Promise.all([
        queryClient.invalidateQueries(['group-messages', groupId, channelId]),
        queryClient.refetchQueries(['group-messages', groupId, channelId]),
      ]);

      setTimeout(scrollToBottom);
    } catch (error) {
      notify('error', error, 2000);
    }

    setSendingMessage(false);
  };

  const isSendButtonDisabled = () => {
    if (sendingMessage || (!message && uploadedFiles.length === 0)) return true;

    return false;
  };

  const resizeTextAreaHeightOnKeyupHandler = (e) => {
    const element = e.target;

    if (
      element.style?.height?.replace('px', '') &&
      parseInt(element.style.height.replace('px', '')) > 80
    )
      return;

    element.style.height = '1px';
    element.style.height = 5 + element.scrollHeight + 'px';
  };

  const renderInputSendContainer = () => {
    if (!groupChannelResponse?.data?.data) return null;

    if (
      groupChannelResponse.data.data.whoCanWrite === 'anyone' ||
      isMyRoleAdmin
    ) {
      return (
        <div className={classes['input-send-container']}>
          <div className={classes['mentions-send-message-container']}>
            <div className={classes['send-message-container']}>
              <textarea
                onKeyUp={resizeTextAreaHeightOnKeyupHandler}
                className={classes['textarea']}
                ref={messageInputRef}
                value={message}
                onChange={messageChangeHandler}
                placeholder="Type message"
              />
              {showEmojiEditor && (
                <div className={classes['emoji-container']}>
                  <Picker
                    title="Pick your emoji"
                    emoji="point_up"
                    onSelect={({ native }) =>
                      setMessage((prevMessage) => prevMessage + native)
                    }
                  />
                </div>
              )}
              <img
                alt="Emoji"
                onClick={toggleEmojiEditorHandler}
                src={EmojiIcon}
                className={classes['emoji-icon']}
              />

              <MultipleFileUpload
                accept=".mp4,.jpg,.png,.jpeg,.pdf"
                onInput={inputFileHandler}
              >
                <img
                  src={ImagePostIcon}
                  alt="File"
                  className={classes['file-icon']}
                />
              </MultipleFileUpload>

              <Button
                style={{
                  padding: '12px 1.5rem',
                  marginRight: '.5rem',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                disabled={isSendButtonDisabled()}
                onClick={sendMessageHandler}
                darkpink="true"
              >
                Send
              </Button>
            </div>

            {uploadedFiles.length > 0 && (
              <div className={classes['uploaded-files-preview']}>
                {uploadedFiles.map(({ type, previewUrl }, i) => {
                  if (type.startsWith('image')) {
                    return (
                      <div className={classes['file-remove-container']}>
                        <img
                          className={classes['file-preview']}
                          src={previewUrl}
                          alt="Preview"
                        />
                        <Button
                          onClick={() => removeUploadedFileHandler(i)}
                          pinkcolor="true"
                          style={{ minWidth: 110 }}
                        >
                          Remove
                        </Button>
                      </div>
                    );
                  } else if (type.startsWith('video')) {
                    return (
                      <div className={classes['file-remove-container']}>
                        <ReactPlayer
                          controls
                          url={previewUrl}
                          className={classes['file-preview']}
                          alt="Preview"
                        />
                        <Button
                          onClick={() => removeUploadedFileHandler(i)}
                          pinkcolor="true"
                          style={{ minWidth: 110 }}
                        >
                          Remove
                        </Button>
                      </div>
                    );
                  } else if (type === 'application/pdf') {
                    return (
                      <div className={classes['file-remove-container']}>
                        <div className={classes['file-fileName-container']}>
                          <Document file={previewUrl}>
                            <Page height={150} pageNumber={1} />
                          </Document>
                        </div>
                        <Button
                          onClick={() => removeUploadedFileHandler(i)}
                          pinkcolor="true"
                          style={{ minWidth: 110, marginTop: 125 }}
                        >
                          Remove
                        </Button>
                      </div>
                    );
                  }

                  return null;
                })}
              </div>
            )}

            {(!shouldNotShowMentionsTable() ||
              shouldShowMentionsTableInNewLine()) && (
              <div className={classes['mention-container']}>
                {mentions && mentions.data && mentions.data.length === 0 && (
                  <p className={classes['no-users-found']}>No Users Found</p>
                )}

                {mentions &&
                  mentions.data &&
                  mentions.data.map(({ user }) => {
                    const { fullName } = user;
                    return (
                      <div
                        onClick={() => selectUserToMentionHandler(user)}
                        className={classes['user-container']}
                        key={user._id}
                      >
                        <img
                          alt="userPhoto"
                          className={classes['user-image']}
                          src={user.photo}
                        />
                        <p className={classes['user-text']}>
                          {user.username} ({fullName})
                          {user._id === profile?._id ? ' (You)' : ''}
                        </p>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className={classes['only-admins-container']}>
        <p>Only admins can write in this channel</p>
      </div>
    );
  };

  useEffect(() => {
    if (message.length === 0 && messageInputRef.current) {
      messageInputRef.current.style.height = '1px';
      messageInputRef.current.style.height =
        5 + messageInputRef.current.scrollHeight + 'px';
    }
  }, [message, messageInputRef]);

  return (
    <>
      {fileModalState.show && (
        <FileModal
          title={fileModalState.fileName}
          setShowImageModal={() =>
            setFileModalState({
              show: false,
              fileUrl: undefined,
              fileType: undefined,
              fileName: undefined,
            })
          }
        >
          {fileModalState.fileType.startsWith('image') && (
            <img
              style={{ maxHeight: 600 }}
              src={fileModalState.fileUrl}
              alt="File"
            />
          )}
          {fileModalState.fileType.startsWith('video') && (
            <ReactPlayer
              style={{
                objectFit: 'cover',
                borderRadius: 10,
              }}
              url={fileModalState.fileUrl}
              alt="File"
              controls
            />
          )}
        </FileModal>
      )}
      <div style={{ flex: 1 }}>
        {isSelectedChannelPrivate ? (
          <PrivateChannelHeader
            setSelectedChannelId={setSelectedChannelId}
            setIsSelectedChannelPrivate={setIsSelectedChannelPrivate}
            groupId={groupId}
            channelId={channelId}
            channelName={selectedChannelName}
            setSelectedChannelName={setSelectedChannelName}
            isMyRoleAdmin={isMyRoleAdmin}
          />
        ) : (
          <PublicChannelHeader
            setSelectedChannelId={setSelectedChannelId}
            isMyRoleAdmin={isMyRoleAdmin}
            groupId={groupId}
            channelId={channelId}
            channelName={selectedChannelName}
            setSelectedChannelName={setSelectedChannelName}
          />
        )}

        <div ref={messagesRef} className={classes['messages']}>
          {(isLoading || isFetching) && <LoadingSpinner />}
          {renderMessages()}
        </div>

        {renderInputSendContainer()}
      </div>
    </>
  );
}

export default Messages;
