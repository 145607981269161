import React, { useState } from 'react';
import classes from './RequestNftUploadAsCollaboratorModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import notify from '../../../../utils/notify';
import ipfs from '../../../../ipfs';
import { useDropzone } from 'react-dropzone';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import UploadImageIcon from '../../../../assets/upload-img-icon.png';
import RemoveIcon from '../../../../assets/gray-remove.png';
import TextArea from '../../../../components/TextArea/TextArea';
import { requestCreatorToUploadMyNftsAsCollaboratorRequest } from '../../../../httpRequests/httpRequests';

function RequestNftUploadAsCollaboratorModal(props) {
  const { nftCollectionId, setShowRequestNftUploadAsCollaboratorModal } = props;

  const [uploadedNfts, setUploadedNfts] = useState([]);
  const [loadingIPFS, setLoadingIPFS] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const [comment, setComment] = useState('');

  const closeModalHandler = () => {
    setShowRequestNftUploadAsCollaboratorModal(false);
  };

  const onDrop = (files) => {
    setLoadingIPFS(true);
    if (files.length === 1) {
      if (files[0].size > 100000000) {
        notify('error', 'File size is bigger than 100MB', '', 2000);
        setLoadingIPFS(false);
        return;
      }
      let ext = files[0].name.split('.');
      ext = ext[ext.length - 1];
      ext = ext.toLowerCase();

      if (
        ext !== 'jpg' &&
        ext !== 'JPG' &&
        ext !== 'jpeg' &&
        ext !== 'JPEG' &&
        ext !== 'png' &&
        ext !== 'PNG' &&
        ext !== 'mp4' &&
        ext !== 'MP4'
      ) {
        notify('error', 'Invalid file type', '', 2000);
        setLoadingIPFS(false);
        return;
      }

      const previewUrlSize = files[0].size;
      const nftUrl = files[0];
      let previewUrl;

      let isVideo = false;
      if (ext === 'mp4' || ext === 'MP4') {
        isVideo = true;
      } else {
        isVideo = false;
      }

      const fileReader = new FileReader();
      fileReader.onload = () => {
        previewUrl = fileReader.result;
      };
      fileReader.readAsDataURL(files[0]);

      let reader = new FileReader();
      reader.readAsArrayBuffer(files[0]);

      reader.onloadend = async () => {
        const content = Buffer(reader.result);
        const added = await ipfs.add(content);
        const hash = added.cid.toString();

        setLoadingIPFS(false);
        const nftData = {
          isVideo,
          previewUrl,
          url: nftUrl,
          previewUrlSize,
          ipfs: hash,
        };
        setUploadedNfts((prevState) => [nftData, ...prevState]);
      };
    } else {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 100000000) {
          notify('error', 'File size is bigger than 100MB', '', 2000);
          setLoadingIPFS(false);
          continue;
        }

        let ext = files[i].name.split('.');
        ext = ext[ext.length - 1];
        ext = ext.toLowerCase();

        if (
          ext !== 'jpg' &&
          ext !== 'JPG' &&
          ext !== 'jpeg' &&
          ext !== 'JPEG' &&
          ext !== 'png' &&
          ext !== 'PNG' &&
          ext !== 'mp4' &&
          ext !== 'MP4'
        ) {
          notify('error', 'Invalid file type', '', 2000);
          setLoadingIPFS(false);
          continue;
        }

        const previewUrlSize = files[i].size;
        const nftUrl = files[i];
        let previewUrl;

        let isVideo = false;
        if (ext === 'mp4' || ext === 'MP4') {
          isVideo = true;
        } else {
          isVideo = false;
        }

        const fileReader = new FileReader();
        fileReader.onload = () => {
          previewUrl = fileReader.result;
        };
        fileReader.readAsDataURL(files[i]);

        let reader = new FileReader();
        reader.readAsArrayBuffer(files[i]);

        reader.onloadend = async () => {
          const content = Buffer(reader.result);
          const added = await ipfs.add(content);
          const hash = added.cid.toString();

          setLoadingIPFS(false);
          const nftData = {
            isVideo,
            previewUrl,
            url: nftUrl,
            previewUrlSize,
            ipfs: hash,
          };
          setUploadedNfts((prevState) => [nftData, ...prevState]);
        };
      }
    }
  };

  const removeUploadedNft = (index) => {
    setUploadedNfts((prevState) => prevState.filter((_, i) => i !== index));
  };

  const commentChangeHandler = (e) => {
    setComment(e.target.value);
  };

  const requestNftUploadHandler = async () => {
    setRequesting(true);

    try {
      const formData = new FormData();
      formData.append(
        'uploadedNfts',
        JSON.stringify(
          uploadedNfts.map(({ ipfs, isVideo }) => ({
            ipfs,
            isVideo,
          }))
        )
      );

      formData.append('comment', comment);

      for (let i = 0; i < uploadedNfts.length; i++) {
        formData.append('previewUrls', uploadedNfts[i].url);
      }

      await requestCreatorToUploadMyNftsAsCollaboratorRequest(
        nftCollectionId,
        formData
      );
      setRequesting(false);

      closeModalHandler();
    } catch (err) {
      setRequesting(false);
      notify('error', err, 2000);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Request NFT Upload"
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={requesting}
            modernpinkcolor="true"
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <Button
            disabled={requesting}
            modernpink="true"
            onClick={requestNftUploadHandler}
          >
            Request
          </Button>
        </div>
      }
    >
      <div className={classes['uploaded-nfts-container']}>
        <p style={{ margin: '10px 0 3px 0' }}>Upload NFT’s</p>
        <div className={classes['upload-file-and-nfts-container']}>
          <div className={classes['upload-img-container']}>
            <input {...getInputProps()} />
            <div>
              {loadingIPFS && <LoadingSpinner />}
              {!loadingIPFS && (
                <div
                  {...getRootProps()}
                  className={classes['upload-container']}
                >
                  <img
                    className={classes['upload-img']}
                    src={UploadImageIcon}
                    alt="Upload"
                    width={35}
                    height={40}
                  />
                  <p className={classes['drag-drop-text']}>Upload Files</p>
                </div>
              )}
            </div>
          </div>

          {uploadedNfts.slice(0, 3).map((nft, i) => {
            if (nft.isVideo) {
              return (
                <div
                  key={i}
                  style={{ marginTop: 5 }}
                  className={classes['nft-container']}
                >
                  <video className={classes['nft-video']} controls>
                    <source src={nft.previewUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div
                    onClick={() => removeUploadedNft(i)}
                    className={classes['remove-icon-container']}
                  >
                    {' '}
                    <img
                      alt="Remove"
                      src={RemoveIcon}
                      className={classes['remove-icon']}
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div key={i} className={classes['nft-container']}>
                  <img
                    className={classes['nft-image']}
                    alt="IPFS-hash"
                    src={nft.previewUrl}
                  />
                  <div
                    onClick={() => removeUploadedNft(i)}
                    className={classes['remove-icon-container']}
                  >
                    {' '}
                    <img
                      alt="Remove"
                      src={RemoveIcon}
                      className={classes['remove-icon']}
                    />
                  </div>
                </div>
              );
            }
          })}

          {uploadedNfts.length > 3 && (
            <div className={classes['more-nfts-container']}>
              <div className={classes['more-text-container']}>
                <h3 className={classes['more-text']}>
                  +{uploadedNfts.length - 3}
                </h3>{' '}
              </div>
            </div>
          )}
        </div>

        <p className={classes['label']}>Comment</p>
        <TextArea
          placeholder="Enter here"
          value={comment}
          onChange={commentChangeHandler}
          style={{
            width: '100%',
            minHeight: 100,
          }}
        />
      </div>
    </Modal>
  );
}

export default RequestNftUploadAsCollaboratorModal;
