import React from 'react';
import classes from './UserNftsStatistics.module.css';
import { Row, Col } from 'antd';

function UserNftsStatistics(props) {
  const {
    totalNftsInformation,
    soldNftsInformation,
    boughtNftsInformation,
  } = props;

  const totalNfts = totalNftsInformation.length;
  const totalNftsForSale = totalNftsInformation.filter(
    ({ data }) => data.forSale
  ).length;
  const totalNftsNotForSale = totalNfts - totalNftsForSale;

  const totalNftsBought =
    boughtNftsInformation.length === 0
      ? 0
      : boughtNftsInformation[0].nftsBought;
  const totalNftsBoughtPrice =
    boughtNftsInformation.length === 0
      ? 0
      : boughtNftsInformation[0].totalBought;

  const totalNftsSold =
    soldNftsInformation.length === 0 ? 0 : soldNftsInformation[0].nftsSold;
  const totalNftsSoldPrice =
    soldNftsInformation.length === 0 ? 0 : soldNftsInformation[0].totalSold;

  return (
    <div className={classes['user-nfts-stats-container']}>
      <div
        className={classes['nfts-stats-header']}
        style={{ textAlign: 'center' }}
      >
        <h2 className="bold-text">NFTs Statistics</h2>
      </div>

      <div>
        <div className={classes['user-stats-container']}>
          <div id="table-nft" className={classes['likes-container']}>
            <Row justify="space-between">
              <Col md={2} className={classes['pagination-col']}>
                Total NFTs
              </Col>
              <Col md={2} className={classes['pagination-col']}>
                Collection
              </Col>

              <Col md={2} className={classes['pagination-col']}>
                Listings
              </Col>

              <Col md={2} className={classes['pagination-col']}>
                No. NFTs Bought
              </Col>
              <Col md={2} className={classes['pagination-col']}>
                Total Bought Price
              </Col>
              <Col md={2} className={classes['pagination-col']}>
                Avg Bought Price
              </Col>

              <Col md={2} className={classes['pagination-col']}>
                No. NFTs Sold
              </Col>

              <Col md={2} className={classes['pagination-col']}>
                Total Sold Price
              </Col>

              <Col md={2} className={classes['pagination-col']}>
                Avg Sold Price
              </Col>
            </Row>

            <div>
              <hr />
              <Row justify="space-between" style={{ padding: '1em 0' }}>
                <Col md={2}>{totalNfts}</Col>
                <Col md={2}>{totalNftsNotForSale}</Col>
                <Col md={2}>{totalNftsForSale}</Col>
                <Col md={2}>{totalNftsBought}</Col>
                <Col md={2}>{totalNftsBoughtPrice} GLMS</Col>
                <Col md={2}>
                  {totalNftsBoughtPrice === 0
                    ? 0
                    : (totalNftsBoughtPrice / totalNftsBought).toFixed(2)}{' '}
                  GLMS
                </Col>
                <Col md={2}>{totalNftsSold}</Col>
                <Col md={2}>{totalNftsSoldPrice} GLMS</Col>
                <Col md={2}>
                  {totalNftsSoldPrice === 0
                    ? 0
                    : (totalNftsSoldPrice / totalNftsSold).toFixed(2)}{' '}
                  GLMS
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserNftsStatistics;
