import React, { useContext, useEffect, useState, useCallback } from 'react';
import classes from './NftCollectionInformation.module.css';
import {
  addNftCollectionToWatchlistRequest,
  deleteMyNftCollectionRequest,
  getMyWatchlistStatusForSpecificNftCollectionRequest,
  getNftCollectionStatisticsRequest,
  getSpecificNftCollectionRequest,
  removeNftCollectionFromWatchlistRequest,
} from '../../../../httpRequests/httpRequests';
import { useQuery, useQueryClient } from 'react-query';
import notify from '../../../../utils/notify';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { limitString } from '../../../../helpers/helpers';
import VerifiedIcon from '../../../../assets/verified.png';
import { Link, useHistory } from 'react-router-dom';
import TelegramIcon from '../../../../assets/telegram.png';
import TwitterIcon from '../../../../assets/twitter.png';
import InstagramIcon from '../../../../assets/instagram.png';
import LinktreeIcon from '../../../../assets/linktree.png';
import YoutubeIcon from '../../../../assets/youtube.png';
import ImdbIcon from '../../../../assets/imdb.png';
import LinkedinIcon from '../../../../assets/linkedin-1.png';
import MoreIcon from '../../../../assets/pink-more-icon.png';
import AddNftsToCollectionModal from '../AddNftsToCollectionModal/AddNftsToCollectionModal';
import { AuthContext } from '../../../../context/auth-context';
import EditCollectionModal from '../EditCollectionModal/EditCollectionModal';
import Button from '../../../../components/Button/Button';
import InviteCollaboratorsModal from '../InviteCollaboratorsModal/InviteCollaboratorsModal';
import SeeCollaboratorsModal from '../SeeCollaboratorsModal/SeeCollaboratorsModal';
import UploadNewNftsModal from '../UploadNewNftsModal/UploadNewNftsModal';
import AssociatedGroupsModal from '../AssociatedGroupsModal/AssociatedGroupsModal';
import ImageModal from '../../../../components/ImageModal/ImageModal';
import moment from 'moment';
import CollectionGroupIcon from '../../../../assets/collection-group-icon.png';
import MetaTags from 'react-meta-tags';
import UniqueOwnersModal from '../UniqueOwnersModal/UniqueOwnersModal';
import BenefitsForHoldersModal from '../BenefitsForHoldersModal/BenefitsForHoldersModal';
import PrivateKey from '../../../../assets/private-pink.png';
import RequestNftAsCollaboratorModal from '../RequestNftAsCollaboratorModal/RequestNftAsCollaboratorModal';
import RequestNftUploadAsCollaboratorModal from '../RequestNftUploadAsCollaboratorModal/RequestNftUploadAsCollaboratorModal';
import DeleteNftCollectionModal from '../DeleteNftCollectionModal/DeleteNftCollectionModal';

const GLMS_TO_USD_PRICE = 0.01;

function NftCollectionInformation(props) {
  const { collectionId, setIsCollectionClaimable } = props;
  const history = useHistory();
  const queryClient = useQueryClient();
  const { profile } = useContext(AuthContext);

  const { isLoading, data, error } = useQuery(
    ['nft-collection-information', collectionId],
    () => getSpecificNftCollectionRequest(collectionId)
  );

  const nftCollectionStatisticsResponse = useQuery(
    ['nft-collection-stats', collectionId],
    () => getNftCollectionStatisticsRequest(collectionId)
  );

  const watchlistNftCollectionStatus = useQuery(
    ['watchlist-nft-collection-status', collectionId],
    () =>
      getMyWatchlistStatusForSpecificNftCollectionRequest(
        collectionId,
        profile?._id
      )
  );

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [
    showAddNftsToCollectionModal,
    setShowAddNftsToCollectionModal,
  ] = useState(false);
  const [showEditCollectionModal, setShowEditCollectionModal] = useState(false);
  const [
    showInviteCollaboratosModal,
    setShowInviteCollaboratorsModal,
  ] = useState(false);
  const [showSeeCollaboratorsModal, setShowSeeCollaboratorsModal] = useState(
    false
  );
  const [showUploadNewNftsModal, setShowUploadNewNftsModal] = useState(false);
  const [processingWatchlisting, setProcessingWatchlisting] = useState(false);
  const [showAssociatedGroupsModal, setShowAssociatedGroupsModal] = useState(
    false
  );
  const [
    showNftCollectionCoverPhotoImageModal,
    setShowNftColectionCoverPhotoImageModal,
  ] = useState(false);
  const [
    showNftCollectionProfilePhotoModal,
    setShowNftCollectionProfilePhotoModal,
  ] = useState(false);
  const [showUniqueOwnersModal, setShowUniqueOwnersModal] = useState(false);
  const [
    remainingDaysUntilLiveDate,
    setRemainingDaysUntilLiveDate,
  ] = useState();
  const [
    remainingHoursUntilLiveDate,
    setRemainingHoursUntilLiveDate,
  ] = useState();
  const [
    remainingMinutesUntilLiveDate,
    setRemainingMinutesUntilLiveDate,
  ] = useState();
  const [
    remainingSecondsUntilLiveDate,
    setRemainingSecondsUntilLiveDate,
  ] = useState();
  const [
    showBenefitsForHoldersModal,
    setShowBenefitsForHoldersModal,
  ] = useState(false);

  const [
    showRequestNftAsCollaboratorModal,
    setShowRequestNftAsCollaboratorModal,
  ] = useState(false);

  const [
    showRequestNftUploadAsCollaboratorModal,
    setShowRequestNftUploadAsCollaboratorModal,
  ] = useState(false);

  const [
    showDeleteNftCollectionModal,
    setShowDeleteNftCollectionModal,
  ] = useState(false);

  useEffect(() => {
    error && notify('error', error, 2000);
    nftCollectionStatisticsResponse.error &&
      notify('error', nftCollectionStatisticsResponse.error, 2000);
  }, [error, nftCollectionStatisticsResponse.error]);

  const updateRemainingHoursAndMinutes = useCallback(() => {
    if (!data?.data) return;

    const duration = moment.duration(moment(data.data.liveDate).diff());
    if (duration < 0) return;

    const remainingDays = parseInt(duration.asDays());
    const remainingHours = (duration.asDays() - remainingDays) * 24;
    const remainingMinutes = (remainingHours - parseInt(remainingHours)) * 60;

    const realSeconds = duration.asSeconds();
    setRemainingDaysUntilLiveDate(remainingDays);
    setRemainingHoursUntilLiveDate(parseInt(remainingHours));
    setRemainingMinutesUntilLiveDate(parseInt(remainingMinutes));
    setRemainingSecondsUntilLiveDate(
      parseInt(
        (realSeconds - (duration.asMinutes() - remainingMinutes) * 60) % 60
      )
    );
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateRemainingHoursAndMinutes();
    }, 1000);

    return () => clearInterval(interval);
  }, [updateRemainingHoursAndMinutes]);

  useEffect(() => {
    if (!data?.data) return;

    setIsCollectionClaimable(data.data.claimable);
  }, [data, setIsCollectionClaimable]);

  const toggleMoreOptionsHandler = () => {
    setShowMoreOptions((prevState) => !prevState);
  };

  const openAddNftsToCollectionModalHandler = () => {
    setShowAddNftsToCollectionModal(true);
  };

  const openEditCollectionModalHandler = () => {
    setShowEditCollectionModal(true);
  };

  const openInviteCollaboratorsModalHandler = () => {
    setShowInviteCollaboratorsModal(true);
  };

  const invalidateWatchlistNftCollection = async () => {
    await Promise.all(
      [
        ['nft-collection-stats', collectionId],
        ['watchlist-nft-collection-status', collectionId],
      ].map((queryKey) => {
        return Promise.all([
          queryClient.invalidateQueries(queryKey),
          queryClient.refetchQueries(queryKey),
        ]);
      })
    );
  };

  const addNftCollectionToWatchlistHandler = async () => {
    setProcessingWatchlisting(true);
    try {
      await addNftCollectionToWatchlistRequest(nftCollection._id);
      await invalidateWatchlistNftCollection();
    } catch (err) {
      notify('error', err, 2000);
    }
    setProcessingWatchlisting(false);
  };

  const removeNftCollectionFromWatchlistHandler = async () => {
    setProcessingWatchlisting(true);

    try {
      await removeNftCollectionFromWatchlistRequest(nftCollection._id);
      await invalidateWatchlistNftCollection();
    } catch (err) {
      notify('error', err, 2000);
    }
    setProcessingWatchlisting(false);
  };

  const openUploadNewNftsModalHandler = () => {
    setShowUploadNewNftsModal(true);
  };

  const openAssociatedGroupsModalHandler = () => {
    setShowAssociatedGroupsModal(true);
  };

  const openBenefitsForHoldersModalHandler = () => {
    setShowBenefitsForHoldersModal(true);
  };

  const openRequestNftAsCollaboratorModalHandler = () => {
    setShowRequestNftAsCollaboratorModal(true);
  };

  const openRequestNftUploadAsCollaboratorModalHandler = () => {
    setShowRequestNftUploadAsCollaboratorModal(true);
  };

  const openDeleteNftCollectionModalHandler = () => {
    setShowDeleteNftCollectionModal(true);
  };

  const deleteNftCollectionHandler = async () => {
    try {
      await deleteMyNftCollectionRequest(nftCollection._id);
      history.push('/explore?tab=Collections');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const renderMoreOptions = () => {
    if (nftCollection.creator._id === profile?._id) {
      return (
        <div
          className={classes['more-options-container']}
          onClick={toggleMoreOptionsHandler}
        >
          {showMoreOptions && (
            <div className={classes['more-options']}>
              {!nftCollection.capped && !nftCollection.claimable && (
                <p
                  onClick={openAddNftsToCollectionModalHandler}
                  className={classes['option']}
                >
                  Add NFTs
                </p>
              )}

              {!nftCollection.capped && (
                <p
                  onClick={openUploadNewNftsModalHandler}
                  className={classes['option']}
                >
                  Upload New NFTs
                </p>
              )}

              <p
                onClick={openEditCollectionModalHandler}
                className={classes['option']}
              >
                Edit Collection
              </p>

              {!nftCollection.capped && (
                <p
                  onClick={openInviteCollaboratorsModalHandler}
                  className={classes['option']}
                >
                  Invite Collaborators
                </p>
              )}

              <p
                onClick={openAssociatedGroupsModalHandler}
                className={classes['option']}
              >
                Associated Groups
              </p>

              <p
                onClick={openBenefitsForHoldersModalHandler}
                className={classes['option']}
              >
                Benefits For Holders
              </p>

              <p
                onClick={openDeleteNftCollectionModalHandler}
                // onClick={deleteNftCollectionHandler}
                className={classes['option']}
              >
                Delete Collection
              </p>
            </div>
          )}
          <img
            src={MoreIcon}
            alt="More"
            height={18}
            className={classes['more-icon']}
          />
        </div>
      );
    }

    const amICollaborator = nftCollection.collaborators.find(
      (collaborator) => collaborator._id === profile?._id
    );
    if (amICollaborator) {
      return (
        <div
          className={classes['more-options-container']}
          onClick={toggleMoreOptionsHandler}
        >
          {showMoreOptions && (
            <div className={classes['more-options']}>
              {/* <p
                onClick={openAddNftsToCollectionModalHandler}
                className={classes['option']}
              >
                Add NFTs
              </p> */}

              <p
                className={classes['option']}
                onClick={openRequestNftAsCollaboratorModalHandler}
              >
                Add NFTs
              </p>

              <p
                className={classes['option']}
                onClick={openRequestNftUploadAsCollaboratorModalHandler}
              >
                Upload NFTs
              </p>

              {/* <p
                onClick={openUploadNewNftsModalHandler}
                className={classes['option']}
              >
                Upload New NFTs
              </p> */}
            </div>
          )}

          <img
            src={MoreIcon}
            alt="More"
            height={18}
            className={classes['more-icon']}
          />
        </div>
      );
    }
  };

  const openSeeCollaboratorsModalHandler = () => {
    setShowSeeCollaboratorsModal(true);
  };

  const renderNftCollectionLiveDate = () => {
    if (moment(nftCollection.liveDate).diff() > 0) {
      return (
        <div className={classes['new-collection-container']}>
          <p className={classes['new-collection-text']}>
            <span className={classes['days']}>
              {remainingDaysUntilLiveDate}{' '}
              {remainingDaysUntilLiveDate !== 1 ? 'Days' : 'Day'}
            </span>{' '}
            :{' '}
            <span className={classes['hours']}>
              {remainingHoursUntilLiveDate}{' '}
              {remainingHoursUntilLiveDate !== 1 ? 'Hours' : 'Hour'}
            </span>{' '}
            :{' '}
            <span className={classes['mins']}>
              {remainingMinutesUntilLiveDate}{' '}
              {remainingMinutesUntilLiveDate !== 1 ? 'Mins' : 'Min'}
            </span>{' '}
            :{' '}
            <span className={classes['secs']}>
              {remainingSecondsUntilLiveDate}{' '}
              {remainingSecondsUntilLiveDate !== 1 ? 'Secs' : 'Sec'}
            </span>
          </p>
        </div>
      );
    }
  };

  const openUniqueOwnersModalHandler = () => {
    setShowUniqueOwnersModal(true);
  };

  if (
    (isLoading && !data?.data) ||
    nftCollectionStatisticsResponse.data?.status !== 'success'
  )
    return <LoadingSpinner />;

  const nftCollection = data.data;
  const nftCollectionStatistics = nftCollectionStatisticsResponse.data;

  const hasNotSocials =
    !nftCollection.twitterUrl &&
    !nftCollection.telegramUrl &&
    !nftCollection.linkedinUrl &&
    !nftCollection.youtubeUrl &&
    !nftCollection.linktreeUrl &&
    !nftCollection.instagramUrl &&
    !nftCollection.imdbUrl;

  return (
    <>
      <MetaTags>
        <title>{nftCollection.name} - Collection | Glimpse</title>
        <meta name="description" content={nftCollection.description} />
        <meta property="og:title" content={nftCollection.name} />
        <meta property="og:image" content={nftCollection.profilePhoto} />
      </MetaTags>

      {showAddNftsToCollectionModal && (
        <AddNftsToCollectionModal
          setShowAddNftsToCollectionModal={setShowAddNftsToCollectionModal}
          collectionId={nftCollection._id}
        />
      )}

      {showEditCollectionModal && (
        <EditCollectionModal
          nftCollection={nftCollection}
          setShowEditCollectionModal={setShowEditCollectionModal}
        />
      )}

      {showInviteCollaboratosModal && (
        <InviteCollaboratorsModal
          nftCollection={nftCollection}
          setShowInviteCollaboratorsModal={setShowInviteCollaboratorsModal}
        />
      )}

      {showSeeCollaboratorsModal && (
        <SeeCollaboratorsModal
          nftCollectionId={nftCollection._id}
          nftCollectionCreator={nftCollection.creator}
          collaborators={nftCollection.collaborators}
          setShowSeeCollaboratorsModal={setShowSeeCollaboratorsModal}
        />
      )}

      {showUniqueOwnersModal && (
        <UniqueOwnersModal
          nftCollectionId={nftCollection._id}
          setShowUniqueOwnersModal={setShowUniqueOwnersModal}
        />
      )}

      {showUploadNewNftsModal && (
        <UploadNewNftsModal
          nftCollection={nftCollection}
          setShowUploadNewNftsModal={setShowUploadNewNftsModal}
        />
      )}

      {showAssociatedGroupsModal && (
        <AssociatedGroupsModal
          nftCollection={nftCollection}
          setShowAssociatedGroupsModal={setShowAssociatedGroupsModal}
        />
      )}

      {showBenefitsForHoldersModal && (
        <BenefitsForHoldersModal
          nftCollection={nftCollection}
          setShowBenefitsForHoldersModal={setShowBenefitsForHoldersModal}
        />
      )}

      {showNftCollectionCoverPhotoImageModal && (
        <ImageModal
          title="Cover Photo"
          setShowImageModal={setShowNftColectionCoverPhotoImageModal}
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={nftCollection.coverPhoto}
            alt="Cover"
          />
        </ImageModal>
      )}

      {showNftCollectionProfilePhotoModal && (
        <ImageModal
          title="Profile Photo"
          setShowImageModal={setShowNftCollectionProfilePhotoModal}
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={nftCollection.profilePhoto}
            alt="ProfilePhoto"
          />
        </ImageModal>
      )}

      {showRequestNftAsCollaboratorModal && (
        <RequestNftAsCollaboratorModal
          setShowRequestNftAsCollaboratorModal={
            setShowRequestNftAsCollaboratorModal
          }
          nftCollectionId={nftCollection._id}
        />
      )}

      {showRequestNftUploadAsCollaboratorModal && (
        <RequestNftUploadAsCollaboratorModal
          setShowRequestNftUploadAsCollaboratorModal={
            setShowRequestNftUploadAsCollaboratorModal
          }
          nftCollectionId={nftCollection._id}
        />
      )}

      {showDeleteNftCollectionModal && (
        <DeleteNftCollectionModal
          setShowDeleteNftCollectionModal={setShowDeleteNftCollectionModal}
          deleteNftCollectionHandler={deleteNftCollectionHandler}
        />
      )}

      <div className={classes['nft-collection-info-container']}>
        <img
          src={nftCollection.coverPhoto}
          alt="Cover"
          className={classes['cover-photo']}
          onClick={() => setShowNftColectionCoverPhotoImageModal(true)}
        />

        <div className={classes['collection-container']}>
          <div className={classes['header-container']}>
            <div className={classes['profile-photo-creator-container']}>
              <div className={classes['profile-photo-container']}>
                <img
                  src={nftCollection.profilePhoto}
                  className={classes['profile-photo']}
                  alt={nftCollection.name}
                  onClick={() => setShowNftCollectionProfilePhotoModal(true)}
                />

                {nftCollection.status === 'Private' && (
                  <div className={classes['private-status-container']}>
                    <img
                      className={classes['private-key-icon']}
                      src={PrivateKey}
                      alt="Private"
                    />
                  </div>
                )}
              </div>
              <div>
                <h2 className={classes['collection-name']}>
                  {nftCollection.name}
                </h2>
                <p>
                  <span className={classes['created-by']}>Created by</span>
                  <Link to={`/user-feed/${nftCollection.creator._id}`}>
                    <span className={classes['username']}>
                      @{limitString(nftCollection.creator.username, 18)}
                      {nftCollection.creator.verified && (
                        <img
                          alt="Verified"
                          src={VerifiedIcon}
                          className={classes['verified-icon']}
                        />
                      )}
                    </span>
                  </Link>
                </p>
              </div>
            </div>

            <div className={classes['live-date-capped-info-container']}>
              {nftCollection.liveDate && renderNftCollectionLiveDate()}
              {nftCollection.capped ? (
                <div className={classes['limited-collection-container']}>
                  <p className={classes['limited-text']}>Limited</p>
                </div>
              ) : (
                <div className={classes['limited-collection-container']}>
                  <p className={classes['unlimited-text']}>Unlimited</p>
                </div>
              )}

              {nftCollection.associatedGroups?.length > 0 ? (
                <Link
                  to={`/groups/${nftCollection.associatedGroups[0]._id}`}
                  className={classes['glimpse-collection-link-container']}
                >
                  <img
                    className={classes['link-image']}
                    src={CollectionGroupIcon}
                    alt="Link"
                    height={14}
                  />
                  <p
                    title={nftCollection.associatedGroups[0].name}
                    className={classes['associated-group-text']}
                  >
                    {limitString(nftCollection.associatedGroups[0].name, 12)}
                  </p>
                </Link>
              ) : (
                <div className={classes['glimpse-collection-link-container']}>
                  <img
                    className={classes['link-image']}
                    src={CollectionGroupIcon}
                    alt="Link"
                    height={14}
                  />
                  <p className={classes['associated-group-text']}>Public</p>
                </div>
              )}
            </div>
          </div>

          <p className={classes['description']}>{nftCollection.description}</p>

          <div className={classes['statistics-container']}>
            <div className={classes['items-container']}>
              <p className={classes['stats-text']}>
                {nftCollectionStatistics.numberOfUniqueNfts}
              </p>
              <p className={classes['stats-description']}>Items</p>
            </div>

            <div className={classes['vertical-line']}>&nbsp;</div>

            <div>
              <p className={classes['stats-text']}>
                {nftCollectionStatistics.numberOfWatchlists}
              </p>
              <p className={classes['stats-description']}>In Watchlist</p>
            </div>

            <div className={classes['vertical-line']}>&nbsp;</div>

            <div
              className={classes['unique-owners-container']}
              onClick={openUniqueOwnersModalHandler}
            >
              <p className={classes['stats-text']}>
                {nftCollectionStatistics.numberOfUniqueOwners}
              </p>
              <p className={classes['stats-description']}>Unique Owners</p>
            </div>

            <div className={classes['vertical-line']}>&nbsp;</div>

            <div className={classes['floor-price-container']}>
              {/* <p className={classes['stats-text']}>
                {nftCollectionStatistics.floorPrice} GLMS
              </p> */}
              <p className={classes['stats-text']}>
                {parseFloat(
                  nftCollectionStatistics.floorPrice * GLMS_TO_USD_PRICE
                ).toFixed(2)}{' '}
                USD
              </p>
              <p className={classes['stats-description']}>Floor Price</p>
            </div>
          </div>

          <div className={classes['add-to-watchlist-socials-container']}>
            <div
              className={
                classes['add-to-watchlist-see-collaborators-container']
              }
            >
              {nftCollection.collaborators?.length > 0 && (
                <Button
                  style={{ marginRight: 10 }}
                  modernpinkcolor="true"
                  onClick={openSeeCollaboratorsModalHandler}
                >
                  See Collaborators
                </Button>
              )}

              {watchlistNftCollectionStatus.data?.status === 'success' &&
              watchlistNftCollectionStatus.data?.data === null ? (
                <Button
                  darkpinkcolor="true"
                  onClick={addNftCollectionToWatchlistHandler}
                  disabled={processingWatchlisting}
                >
                  Add To Watchlist
                </Button>
              ) : (
                <Button
                  disabled={processingWatchlisting}
                  darkpink="true"
                  onClick={removeNftCollectionFromWatchlistHandler}
                >
                  Remove from Watchlist
                </Button>
              )}
            </div>

            <div className={classes['socials-container']}>
              {hasNotSocials && (
                <p className={classes['no-socials-found']}>No Socials Found</p>
              )}

              {nftCollection.twitterUrl && (
                <div className={classes['social-container']}>
                  <a
                    rel="noreferrer"
                    target={'_blank'}
                    href={
                      nftCollection.twitterUrl.startsWith('https://')
                        ? nftCollection.twitterUrl
                        : `https://${nftCollection.twitterUrl}`
                    }
                  >
                    <img
                      className={classes['social-link']}
                      alt="Twitter"
                      src={TwitterIcon}
                      width={25}
                    />
                  </a>
                </div>
              )}
              {nftCollection.telegramUrl && (
                <div className={classes['social-container']}>
                  <a
                    rel="noreferrer"
                    target={'_blank'}
                    href={
                      nftCollection.telegramUrl.startsWith('https://')
                        ? nftCollection.telegramUrl
                        : `https://${nftCollection.telegramUrl}`
                    }
                  >
                    <img
                      className={classes['social-link']}
                      alt="Telegram"
                      src={TelegramIcon}
                      width={25}
                    />
                  </a>
                </div>
              )}
              {nftCollection.linkedinUrl && (
                <div className={classes['social-container']}>
                  <a
                    rel="noreferrer"
                    target={'_blank'}
                    href={
                      nftCollection.linkedinUrl.startsWith('https://')
                        ? nftCollection.linkedinUrl
                        : `https://${nftCollection.linkedinUrl}`
                    }
                  >
                    <img
                      className={classes['social-link']}
                      alt="Linkedin"
                      src={LinkedinIcon}
                      width={25}
                    />
                  </a>
                </div>
              )}

              {nftCollection.youtubeUrl && (
                <div className={classes['social-container']}>
                  <a
                    rel="noreferrer"
                    target={'_blank'}
                    href={
                      nftCollection.youtubeUrl.startsWith('https://')
                        ? nftCollection.youtubeUrl
                        : `https://${nftCollection.youtubeUrl}`
                    }
                  >
                    <img
                      className={classes['social-link']}
                      alt="Youtube"
                      src={YoutubeIcon}
                      width={25}
                    />
                  </a>
                </div>
              )}

              {nftCollection.linktreeUrl && (
                <div className={classes['social-container']}>
                  <a
                    rel="noreferrer"
                    target={'_blank'}
                    href={
                      nftCollection.linktreeUrl.startsWith('https://')
                        ? nftCollection.linktreeUrl
                        : `https://${nftCollection.linktreeUrl}`
                    }
                  >
                    <img
                      className={classes['social-link']}
                      alt="Linktree"
                      src={LinktreeIcon}
                      width={25}
                    />
                  </a>
                </div>
              )}

              {nftCollection.instagramUrl && (
                <div className={classes['social-container']}>
                  <a
                    rel="noreferrer"
                    target={'_blank'}
                    href={
                      nftCollection.instagramUrl.startsWith('https://')
                        ? nftCollection.instagramUrl
                        : `https://${nftCollection.instagramUrl}`
                    }
                  >
                    <img
                      className={classes['social-link']}
                      alt="Instagram"
                      src={InstagramIcon}
                      width={25}
                    />
                  </a>
                </div>
              )}

              {nftCollection.imdbUrl && (
                <div className={classes['social-container']}>
                  <a
                    rel="noreferrer"
                    target={'_blank'}
                    href={
                      nftCollection.imdbUrl.startsWith('https://')
                        ? nftCollection.imdbUrl
                        : `https://${nftCollection.imdbUrl}`
                    }
                  >
                    <img
                      className={classes['social-link']}
                      alt="Imdb"
                      src={ImdbIcon}
                      width={25}
                    />
                  </a>
                </div>
              )}
              {!hasNotSocials && (
                <div className={classes['social-vertical-line']}>&nbsp;</div>
              )}
              {renderMoreOptions()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NftCollectionInformation;
