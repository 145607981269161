import React, { useState } from 'react';
import { Row, Col } from 'antd';
import classes from './UserInformation.module.css';
import ReportInformationModal from './components/ReportInformationModal/ReportInformationModal';

function UserInformation(props) {
  const {
    userInformation,
    nrOfFollowers,
    nrOfFollowings,
    nrOfLikedNfts,
    numberOfNftsInWatchlist,
  } = props;
  const [showReportInformationModal, setShowReportInformationModal] = useState(
    false
  );

  const openReportInformationModal = () => setShowReportInformationModal(true);

  return (
    <>
      {showReportInformationModal && (
        <ReportInformationModal
          userId={userInformation._id}
          setShowReportInformationModal={setShowReportInformationModal}
        />
      )}
      <div className={classes['user-info-container']}>
        <div>
          <img
            alt={`${userInformation.fullName}`}
            className={classes['user-photo']}
            width={40}
            height={40}
            src={userInformation.photo}
          />
        </div>
        <div>
          <h2 className="bold-text">
            {userInformation.firstName} {userInformation.lastName}
          </h2>
        </div>

        <div style={{ flex: 1, alignSelf: 'stretch' }}>
          <div className={classes['user-stats-container']}>
            <div id="table-nft" className={classes['likes-container']}>
              <Row justify="space-between">
                <Col md={2} className={classes['pagination-col']}>
                  Followers
                </Col>
                <Col md={2} className={classes['pagination-col']}>
                  Following
                </Col>

                <Col md={2} className={classes['pagination-col']}>
                  NFTs in Watchlist
                </Col>

                <Col md={2} className={classes['pagination-col']}>
                  Liked Nfts
                </Col>
                <Col
                  onClick={openReportInformationModal}
                  style={{ cursor: 'pointer' }}
                  md={2}
                  className={classes['pagination-col']}
                >
                  No. Reports
                </Col>
                <Col md={2} className={classes['pagination-col']}>
                  No. Warnings
                </Col>
              </Row>

              <div>
                <hr />
                <Row justify="space-between" style={{ padding: '1em 0' }}>
                  <Col md={2}>{nrOfFollowers}</Col>
                  <Col md={2}>{nrOfFollowings}</Col>
                  <Col md={2}>{numberOfNftsInWatchlist}</Col>
                  <Col md={2}>{nrOfLikedNfts}</Col>
                  <Col
                    onClick={openReportInformationModal}
                    style={{ cursor: 'pointer' }}
                    md={2}
                  >
                    {userInformation.reports.length}
                  </Col>
                  <Col md={2}>{userInformation.warnings.length}</Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserInformation;
