import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import classes from './Friend.module.css';
import MoreIcon from '../../../../../../assets/gray-menu-options.png';
import { AuthContext } from '../../../../../../context/auth-context';
import ReportUserModal from '../../../../../../components/ReportUserModal/ReportUserModal';
import VerifiedIcon from '../../../../../../assets/verified.png';

function Friend(props) {
  const history = useHistory();
  const moreIconRef = useRef(null);
  const { follower } = props;
  const { profile } = useContext(AuthContext);
  const [reportUserModalState, setReportUserModalState] = useState({
    opened: false,
    user: undefined,
  });

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  useEffect(() => {
    const checkForClosingMoreOptions = (e) => {
      if (e.target !== moreIconRef.current) {
        setShowMoreOptions(false);
      }
    };

    window.addEventListener('click', checkForClosingMoreOptions);

    return () =>
      window.removeEventListener('click', checkForClosingMoreOptions);
  }, []);

  const openReportUserModalHandler = (user) => {
    if (profile) {
      setReportUserModalState({
        opened: true,
        user,
      });
    } else if (!profile) {
      history.push('/create');
    }
  };

  return (
    <>
      {reportUserModalState.opened && (
        <ReportUserModal
          userId={reportUserModalState.user._id}
          user={reportUserModalState.user}
          setShowReportUserModal={() =>
            setReportUserModalState({ opened: false, user: undefined })
          }
        />
      )}

      <div className={classes['friend-container']}>
        {follower.coverPhoto && (
          <img
            alt="Friend"
            src={follower.coverPhoto}
            className={classes['follower-cover-photo']}
          />
        )}

        <div className={classes['more-icon-container']}>
          <img
            alt="More"
            ref={moreIconRef}
            onClick={() => setShowMoreOptions((prevState) => !prevState)}
            src={MoreIcon}
            height={20}
            className={classes['more-icon']}
          />
        </div>
        {showMoreOptions && (
          <div className={classes['more-options-container']}>
            {follower._id !== profile._id && (
              <p
                onClick={() => openReportUserModalHandler(follower)}
                className={classes['option']}
              >
                Report
              </p>
            )}
            <p
              onClick={() =>
                history.push(
                  follower._id === profile?._id
                    ? '/my-space'
                    : `/user-feed/${follower._id}`
                )
              }
              className={classes['option']}
            >
              View Profile
            </p>
          </div>
        )}
        <div className={classes['photo-fullName-container']}>
          <img
            alt={follower.fullName}
            src={follower.photo}
            className={classes['friend-photo']}
          />
          <p className={classes['friend-fullName']}>
            {follower.fullName}
            {follower.verified && (
              <img
                alt="Verified"
                src={VerifiedIcon}
                style={{ height: 17, marginLeft: 6, marginBottom: 2 }}
              />
            )}
            <p className={classes['username']}>@{follower.username}</p>
          </p>
        </div>
      </div>
    </>
  );
}

export default Friend;
