import React, { useEffect } from 'react';
import classes from './UsersWhoHaveAccessToCreateStripeAccount.module.css';
import { Col, Row } from 'antd';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { limitString } from '../../../../../../helpers/helpers';
import { getAllUsersWhoHaveAccessToCreateStripeAccountRequest } from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';

function UsersWhoHaveAccessToCreateStripeAccount() {
  const history = useHistory();
  const { isLoading, data, error } = useQuery(
    'users-who-have-access-to-create-stripe-account',
    getAllUsersWhoHaveAccessToCreateStripeAccountRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-users-container']}>
        <div className={classes['heading-container']}>
          <h2 className={classes['all-users-heading']}>
            ALL USERS = {data?.data.length}
          </h2>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={3} className={classes['pagination-col']}>
              Photo
            </Col>
            <Col md={3} className={classes['pagination-col']}>
              Full Name
            </Col>

            <Col md={5} className={classes['pagination-col']}>
              Email
            </Col>
          </Row>

          {data?.data &&
            data.data.map((user) => {
              return (
                <div key={user._id}>
                  <hr />
                  <Row justify="space-between" style={{ padding: '1em 0' }}>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={3}
                    >
                      <img
                        width={25}
                        height={25}
                        style={{ borderRadius: 100, objectFit: 'cover' }}
                        alt="User"
                        src={user.photo}
                      />
                    </Col>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={3}
                    >
                      {user.firstName} {user.lastName}
                    </Col>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                      md={5}
                    >
                      {limitString(user.email, 30)}
                    </Col>
                  </Row>
                </div>
              );
            })}

          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default UsersWhoHaveAccessToCreateStripeAccount;
