import React, { useEffect } from 'react';
import classes from './ClaimableNfts.module.css';
import { useInfiniteQuery } from 'react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Nft from '../../../../components/Nft/Nft';
import { getClaimableNftsForASpecificCollectionRequest } from '../../../../httpRequests/httpRequests';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import notify from '../../../../utils/notify';

const RESULTS_PER_PAGE = 10;

function ClaimableNfts(props) {
  const { collectionId } = props;

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['claimable-nfts-for-specific-collection', collectionId],
    ({ pageParam = 1 }) =>
      getClaimableNftsForASpecificCollectionRequest(
        collectionId,
        pageParam,
        RESULTS_PER_PAGE
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <div>
      {!isLoading && data?.pages && data.pages[0].results === 0 && (
        <p style={{ fontWeight: 500, padding: '.75rem 1rem' }}>
          No NFTs Found!
        </p>
      )}
      <div className={classes['nft-list']}>
        {!isLoading &&
          data?.pages &&
          data.pages.map((page) => {
            return page.data.map(({ nft }) => <Nft key={nft._id} nft={nft} />);
          })}
      </div>
      {(isLoading || isFetching) && <LoadingSpinner />}
    </div>
  );
}

export default ClaimableNfts;
