import React, { useState } from 'react';
import { createUserWarningRequest } from '../../../../../httpRequests/httpRequests';
import Modal from '../../../../../components/Modal/Modal';
import notify from '../../../../../utils/notify';
import { useQueryClient } from 'react-query';

function WarnUserModal(props) {
  const queryClient = useQueryClient();
  const {
    setShowWarnUserModal,
    selectedUser,
    setSelectedUserForWarn,
    searchUserInputValue,
  } = props;
  const [warningUser, setWarningUser] = useState(false);

  const closeModalHandler = () => {
    setShowWarnUserModal(false);
    setSelectedUserForWarn(undefined);
  };

  const invalidateUser = (newWarning) => {
    queryClient.setQueryData(['all-users', searchUserInputValue], (data) => {
      return {
        ...data,
        pages: data.pages.map((page) => {
          const updatedData = page.data.map((user) =>
            user._id === selectedUser._id
              ? { ...user, warnings: [...user.warnings, newWarning] }
              : user
          );
          return { ...page, data: updatedData };
        }),
      };
    });
  };

  const warnUserHandler = async () => {
    setWarningUser(true);

    try {
      const { data } = await createUserWarningRequest(selectedUser._id);

      notify('success', 'Warning email sent successfully!', 2500);
      invalidateUser(data);
      setShowWarnUserModal(false);
      setSelectedUserForWarn(undefined);
    } catch (error) {
      notify('error', error, 3000);
    }

    setWarningUser(false);
  };

  return (
    <div>
      <Modal
        footer={
          <>
            <button onClick={closeModalHandler} className={'cancel-button'}>
              Cancel
            </button>
            <span style={{ marginLeft: 10 }}></span>
            <button
              disabled={warningUser}
              onClick={warnUserHandler}
              className={warningUser ? 'btn-disabled' : 'warning-button'}
            >
              {warningUser ? 'Warning...' : 'Warn'}
            </button>
          </>
        }
        closeModal={closeModalHandler}
        title="Warn User"
      >
        <h2 style={{ textAlign: 'center' }} className="bold-text">
          Are you sure you want to warn user:
        </h2>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <img
            width={30}
            height={30}
            style={{
              borderRadius: 100,
              objectFit: 'cover',
              marginBottom: 10,
            }}
            alt="User"
            src={selectedUser.photo}
          />
          <h2
            style={{
              display: 'inline-block',
              marginLeft: 20,
              marginBottom: 20,
            }}
            className="bold-text"
          >
            {selectedUser.firstName} {selectedUser.lastName} ?
          </h2>
        </div>
      </Modal>
    </div>
  );
}

export default WarnUserModal;
