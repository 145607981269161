import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import GoBack from '../../components/GoBack/GoBack';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { getUserStatisticsRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import UserInformation from './components/UserInformation/UserInformation';
import UserNftsAsOwnerAndCreator from './components/UserNftsAsOwnerAndCreator/UserNftsAsOwnerAndCreator';
import UserNftsStatistics from './components/UserNftsStatistics/UserNftsStatistics';

function UserStatistics(props) {
  const { userId } = props.computedMatch.params;
  const { isLoading, data, error } = useQuery(['user-stats', userId], () =>
    getUserStatisticsRequest(userId)
  );
  const userStatistics = data ? data.data : undefined;

  useEffect(() => {
    error && notify('error', error, 2000);
  });

  return (
    <div className="site-layout-content">
      <GoBack />
      <div style={{ textAlign: 'center' }}>
        <h2 className="bold-text">User Statistics</h2>
      </div>
      {isLoading && (
        <div style={{ textAlign: 'center' }}>
          {' '}
          <LoadingSpinner />
        </div>
      )}
      {userStatistics && (
        <UserInformation
          nrOfFollowers={userStatistics.nrOfFollowers}
          nrOfFollowings={userStatistics.nrOfFollowings}
          userInformation={userStatistics.userInformation}
          nrOfLikedNfts={userStatistics.nrOfLikedNfts}
          numberOfNftsInWatchlist={userStatistics.numberOfNftsInWatchlist}
        />
      )}

      {userStatistics && (
        <UserNftsStatistics
          totalNftsInformation={userStatistics.totalNftsInformation}
          soldNftsInformation={userStatistics.soldNftsInformation}
          boughtNftsInformation={userStatistics.boughtNftsInformation}
        />
      )}

      {userStatistics && (
        <UserNftsAsOwnerAndCreator
          userId={userStatistics.userInformation._id}
        />
      )}
    </div>
  );
}

export default UserStatistics;
