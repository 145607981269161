import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import Modal from '../../../../components/Modal/Modal';
import { updateNftBuyNowPriceRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './UpdateNftBuyNowPriceModal.module.css';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';

const GLMS_TO_USD_PRICE = 0.01;

function UpdateNftBuyPriceModal(props) {
  const queryClient = useQueryClient();
  const { nft, setShowUpdateNftBuyNowPriceModal } = props;

  const [buyNowPriceValue, setBuyNowPriceValue] = useState(
    parseFloat(nft.buyNowPrice * GLMS_TO_USD_PRICE).toFixed(2)
  );
  const [updatingBuyNowPrice, setUpdatingBuyNowPrice] = useState(false);

  const closeModalHandler = () => setShowUpdateNftBuyNowPriceModal(false);

  const buyNowPriceValueChangeHandler = (e) => {
    setBuyNowPriceValue(e.target.value);
  };

  const invalidateNft = async () => {
    await Promise.all([
      queryClient.invalidateQueries(['nfts', nft._id]),
      queryClient.refetchQueries(['nfts', nft._id]),
    ]);
  };

  const updateBuyNowPriceHandler = async () => {
    setUpdatingBuyNowPrice(true);

    try {
      const requestBody = {
        buyNowPrice: parseFloat(buyNowPriceValue / GLMS_TO_USD_PRICE),
      };
      await updateNftBuyNowPriceRequest(nft._id, requestBody);
      await invalidateNft();

      setUpdatingBuyNowPrice(false);
      closeModalHandler();
    } catch (error) {
      setUpdatingBuyNowPrice(false);
      notify('error', error, 2000);
    }
  };

  const buyNowPriceInputStyle = { width: '100%' };
  const isBuyNowInvalid =
    !buyNowPriceValue ||
    isNaN(buyNowPriceValue) ||
    parseFloat(buyNowPriceValue) < 5;
  if (isBuyNowInvalid) {
    buyNowPriceInputStyle['border'] = '1px solid red';
  }

  return (
    <>
      <Modal
        title={`Update Buy Price`}
        closeModal={closeModalHandler}
        footer={
          <div className={classes['footer']}>
            <Button
              style={{ width: 200 }}
              darkpinkcolor="true"
              onClick={closeModalHandler}
            >
              Cancel
            </Button>

            <Button
              style={{ width: 200 }}
              disabled={isBuyNowInvalid || updatingBuyNowPrice}
              darkpink="true"
              onClick={updateBuyNowPriceHandler}
            >
              {updatingBuyNowPrice ? 'Updating...' : 'Update'}
            </Button>
          </div>
        }
      >
        <div>
          <p className={classes['label']}>Price (Min 5 USD)</p>
          <Input
            style={buyNowPriceInputStyle}
            type={'number'}
            placeholder="Enter USD amount"
            value={buyNowPriceValue}
            onChange={buyNowPriceValueChangeHandler}
          />
        </div>
      </Modal>
    </>
  );
}

export default UpdateNftBuyPriceModal;
