import React, { useContext, useEffect } from 'react';
import classes from './EventViewContent.module.css';
import CalendarIcon from '../../assets/calendar-icon.png';
import LocationIcon from '../../assets/location-icon.png';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteEventRequest,
  getEventRequest,
  uploadEventPhotosRequest,
} from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import { limitString } from '../../helpers/helpers';
import { AuthContext } from '../../context/auth-context';
import moment from 'moment';
import MultipleImageUpload from '../../components/MultipleImageUpload/MultipleImageUpload';
import Button from '../../components/Button/Button';

function EventViewContent() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { eventId } = useParams();
  const { profile } = useContext(AuthContext);

  const { data, error } = useQuery(['specific-event', eventId], () =>
    getEventRequest(eventId)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const addPhotosHandler = async (files) => {
    try {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append('photos', file);
      });

      await uploadEventPhotosRequest(event._id, formData);

      queryClient.invalidateQueries(['specific-event', event._id]);
      queryClient.refetchQueries(['specific-event', event._id]);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const deleteEventHandler = async () => {
    try {
      await deleteEventRequest(event._id);
      history.goBack();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const event = data?.data || undefined;

  return event ? (
    <>
      <div className={classes['event-container']}>
        <img
          src={event.photo}
          alt={event.name}
          className={classes['event-photo']}
        />

        <div className={classes['event-info-container']}>
          <h3 className={classes['event-name']}>{event.name}</h3>
          <div className={classes['location-date-container']}>
            <div className={classes['location-container']}>
              <img
                className={classes['location-icon']}
                src={LocationIcon}
                alt="Location"
              />
              <p className={classes['location']}>
                <span className={classes['location-text']}>Location: </span>
                <span
                  title={event.location}
                  className={classes['location-value']}
                >
                  {limitString(event.location, 10)}
                </span>
              </p>
            </div>

            <div className={classes['date-container']}>
              <img
                className={classes['calendar-icon']}
                src={CalendarIcon}
                alt="Calendar"
              />

              <p className={classes['date']}>
                <span className={classes['date-text']}>Date: </span>
                <span className={classes['date-value']}>
                  {moment(event.date).format('MMMM Do, YYYY')}
                </span>
              </p>
            </div>
          </div>

          <p className={classes['description']}>{event.description}</p>
          {event.creator?.photo && (
            <div className={classes['creator-container']}>
              <img
                src={event.creator.photo}
                alt="Creator"
                className={classes['creator-photo']}
              />

              <div className={classes['creator-fullName-container']}>
                <p className={classes['creator-text']}>Creator</p>
                <p className={classes['creator-fullName']}>
                  {event.creator.fullName}
                </p>
              </div>
            </div>
          )}

          <div className={classes['horizontal-line']}>&nbsp;</div>

          {event.creator?._id === profile?._id && (
            <Button
              style={{ width: '100%', margin: '10px 0' }}
              modernpinkcolor="true"
              onClick={deleteEventHandler}
            >
              Delete Event
            </Button>
          )}
          {event.creator?._id === profile?._id && (
            <MultipleImageUpload onInput={addPhotosHandler}>
              <Button
                style={{ width: '100%', margin: '10px 0' }}
                modernpink="true"
              >
                Add Photos
              </Button>
            </MultipleImageUpload>
          )}
        </div>
      </div>
      <div className={classes['event-photos-container']}>
        {event.photos?.length > 0 &&
          event.photos.map((photo) => (
            <img
              key={photo}
              src={photo}
              className={classes['event-photo']}
              alt="EventPhoto"
            />
          ))}
      </div>
    </>
  ) : null;
}

export default EventViewContent;
