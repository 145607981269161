import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/auth-context';
import { createReportRequest } from '../../httpRequests/httpRequests';
import notify from '../../utils/notify';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import Select from '../Select/Select';
import TextArea from '../TextArea/TextArea';
import classes from './ReportUserModal.module.css';

const REPORT_USER_REASONS = [
  `It's content that shouldn't be on Glimpse`,
  `It's pretending to be someone else`,
  'Pornography',
  'Profanity',
  'Violence',
  'Other (Please specify...)',
];

function ReportUserModal(props) {
  const { user, userId, setShowReportUserModal } = props;
  const { profile } = useContext(AuthContext);
  const [selectedReportReason, setSelectedReportReason] = useState(
    REPORT_USER_REASONS[0]
  );
  const [reportDescription, setReportDescription] = useState('');
  const [reportDescriptionTouched, setReportDescriptionTouched] = useState(
    false
  );
  const [reporting, setReporting] = useState(false);

  const closeModalHandler = () => setShowReportUserModal(false);

  const reportUserHandler = async () => {
    setReporting(true);

    try {
      const requestBody = {
        from: profile?._id,
        to: userId,
        reason: selectedReportReason,
        description: reportDescription,
        entityType: 'User',
        entityId: userId,
      };
      await createReportRequest(requestBody);
      closeModalHandler();
      notify('success', 'Report sent successfully!', 2000);
    } catch (error) {
      notify('error', error, 2000);
    }

    setReporting(false);
  };

  const reportDescriptionInputHandler = (e) => {
    setReportDescription(e.target.value);
    setReportDescriptionTouched(true);
  };

  const isReportButtonDisabled = reportDescription.length < 10 || reporting;

  const textAreaStyle = {};
  if (reportDescription.length < 10 && reportDescriptionTouched) {
    textAreaStyle['border'] = '1px solid red';
  }

  return (
    <>
      <Modal
        closeModal={closeModalHandler}
        title="Report User"
        footer={
          <div className={classes['footer']}>
            <Button
              style={{ width: 200 }}
              darkpinkcolor="true"
              onClick={closeModalHandler}
            >
              Cancel
            </Button>
            <Button
              style={{ width: 200 }}
              darkpink="true"
              disabled={isReportButtonDisabled}
              onClick={reportUserHandler}
            >
              Send Report
            </Button>
          </div>
        }
      >
        <p className={classes['label']}>Username</p>
        <Input
          style={{ width: '100%' }}
          value={`@${user?.username}`}
          disabled
        />

        <p className={classes['label']}>Reason</p>
        <Select
          style={{ width: '100%' }}
          selectedValue={selectedReportReason}
          options={REPORT_USER_REASONS}
          onChange={(value) => setSelectedReportReason(value)}
        />

        <p className={classes['label']}>Description</p>
        <TextArea
          style={{ minHeight: 100, width: '100%' }}
          onChange={reportDescriptionInputHandler}
          value={reportDescription}
          placeholder="Enter here (Minimum 10 characters)"
        />
      </Modal>
    </>
  );
}

export default ReportUserModal;
