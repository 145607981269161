import React, { useState } from 'react';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import classes from './Watchlist.module.css';
import NftWatchlist from './components/NftWatchlist/NftWatchlist';
import NftCollectionWatchlist from './components/NftCollectionWatchlist/NftCollectionWatchlist';
import MetaTags from 'react-meta-tags';
import GoBackIcon from '../../assets/go-back-icon-2.png';
import { useHistory } from 'react-router-dom';

const WATCHLIST_OPTIONS = {
  NFTs: '1/1’s',
  Collections: 'Collections',
};

function Watchlist() {
  const history = useHistory();
  const [selectedWatchlistOption, setSelectedWatchlistOption] = useState(
    `1/1’s`
  );

  return (
    <>
      <MetaTags>
        <title>Watchlist | Glimpse</title>
        <meta property="og:title" content={'Watchlist | Glimpse'} />
      </MetaTags>

      <div className={classes['watchlist-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar selectedLink="watchlist" />
        </div>

        <div className={classes['watchlist-container']}>
          <header className={classes['watchlist-header']}>
            <div className={classes['go-back-container']}>
              <div onClick={() => history.goBack()}>
                <img
                  src={GoBackIcon}
                  alt="Go Back"
                  className={classes['go-back-icon']}
                />
              </div>
              <h3>Watchlist</h3>
            </div>

            <div className={classes['watchlist-options-container']}>
              {Object.values(WATCHLIST_OPTIONS).map((option) => (
                <div
                  key={option}
                  onClick={() => setSelectedWatchlistOption(option)}
                  className={classes['watchlist-option-container']}
                >
                  <p
                    className={`${classes['watchlist-option-text']} ${
                      option === selectedWatchlistOption &&
                      classes['watchlist-option-text-selected']
                    }`}
                  >
                    {option}
                  </p>
                </div>
              ))}
            </div>
          </header>

          {selectedWatchlistOption === WATCHLIST_OPTIONS.NFTs && (
            <NftWatchlist />
          )}
          {selectedWatchlistOption === WATCHLIST_OPTIONS.Collections && (
            <NftCollectionWatchlist />
          )}
        </div>
      </div>
    </>
  );
}

export default Watchlist;
