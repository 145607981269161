import React from 'react';
import classes from './PrivatePurchases.module.css';
import PrivatePurchasesImage from '../../../../assets/no-metamask-nfts-image.png';

function PrivatePurchases() {
  return (
    <div className={classes['locked-nfts-container']}>
      <img height={150} src={PrivatePurchasesImage} alt="Access Private" />
      <h2 style={{ marginTop: 10 }}>Not Listed NFTs are Locked!</h2>
      <p className={classes['private-text']}>
        Not Listed NFTs are set to private by the user and you cannot access the
        private NFTs.
      </p>
    </div>
  );
}

export default PrivatePurchases;
