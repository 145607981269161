import React, { useContext, useRef, useEffect } from 'react';
import classes from './Request.module.css';

import Input from '../../../../components/Input/Input';
import GraduationIcon from '../../../../assets/graduation-1.png';
import PinkGraduationIcon from '../../../../assets/pink-graduation.png';
import PepTalkIcon from '../../../../assets/pep-talk-1.png';
import PinkPepTalkIcon from '../../../../assets/pink-pep-talk.png';
import AdviceIcon from '../../../../assets/advice-1.png';
import PinkAdviceIcon from '../../../../assets/pink-advice.png';
import OtherIcon from '../../../../assets/others.png';
import PinkOtherIcon from '../../../../assets/pink-others.png';
import BirthdayIcon from '../../../../assets/birthday.png';
import PinkBirthdayIcon from '../../../../assets/pink-birthday.png';
import MySelfIcon from '../../../../assets/myself.png';
import PinkMyselfIcon from '../../../../assets/pink-myself.png';
import TextArea from '../../../../components/TextArea/TextArea';
import Button from '../../../../components/Button/Button';
import { useQuery } from 'react-query';
import { searchUsersByUsernameRequest } from '../../../../httpRequests/httpRequests';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { AuthContext } from '../../../../context/auth-context';
import SliderButton from '../../../../components/SliderButton/SliderButton';

function Request(props) {
  const { profile } = useContext(AuthContext);
  const nftForRef = useRef();
  const {
    nftFor,
    setNftFor,
    selectedOccasion,
    setSelectedOccasion,
    about,
    setAbout,
    showNftForOptions,
    setShowNftForOptions,
    setSelectedNftForId,
    isNftForMyself,
    setIsNftForMyself,
    setStep,
    userProfile,
    selectedNftForId,
    nftTitle,
    setNftTitle,
    nftTitleTouched,
    setNftTitleTouched,
    isNftTypeVideo,
    setIsNftTypeVideo,
  } = props;

  useEffect(() => {
    const checkForClosingNftForOptions = (e) => {
      if (e.target !== nftForRef.current) {
        setShowNftForOptions(false);
      }
    };

    window.addEventListener('click', checkForClosingNftForOptions);
  }, [setShowNftForOptions]);

  const nftForOptionsResponse = useQuery(['mentions', nftFor], () =>
    searchUsersByUsernameRequest(nftFor)
  );

  const nftForChangeHandler = (e) => {
    setNftFor(e.target.value);
    setShowNftForOptions(true);
  };

  const selectOccassionHandler = (occasion) => {
    setSelectedOccasion(occasion);
  };

  const aboutChangeHandler = (e) => {
    setAbout(e.target.value);
  };

  const selectNftForHandler = (user) => {
    setNftFor(user.fullName);
    setSelectedNftForId(user._id);
    setShowNftForOptions(false);
  };

  const nftTitleChangeHandler = (e) => {
    setNftTitle(e.target.value);
    setNftTitleTouched(true);
  };

  const nftForInputStyle = { width: '100%' };
  const aboutInputStyle = { width: '100%', minHeight: 120 };
  const nftTitleInputStyle = { width: '100%' };

  if (!nftTitle && nftTitleTouched) {
    nftTitleInputStyle['border'] = '1px solid red';
  }

  const isNextButtonDisabled =
    !selectedNftForId || !nftFor || !selectedOccasion || !about || !nftTitle;

  return (
    <div className={classes['request-container']}>
      <div className={classes['new-request-for-container']}>
        <img
          className={classes['user-image']}
          src={userProfile.photo}
          alt={userProfile.fullName}
        />
        <p className={classes['new-request-for-text']}>
          New Request for {userProfile.fullName}
        </p>
      </div>

      <div className={classes['who-is-for-container']}>
        <h3>1. Who is this for?</h3>

        <div className={classes['someone-else-myself-container']}>
          <div
            onClick={() => {
              setIsNftForMyself(false);
              setNftFor('');
              setSelectedNftForId();
            }}
            className={`${classes['someone-else-container']} ${
              !isNftForMyself && classes['selected-container']
            }`}
          >
            <img
              src={isNftForMyself ? BirthdayIcon : PinkBirthdayIcon}
              alt="Someone else"
              height={40}
            />
            <p className={classes['someone-else-text']}>Someone else</p>
          </div>
          <div
            onClick={() => {
              setIsNftForMyself(true);
              setNftFor(profile?.fullName);
              setSelectedNftForId(profile?._id);
            }}
            className={`${classes['myself-container']} ${
              isNftForMyself && classes['selected-container']
            }`}
          >
            <img
              src={!isNftForMyself ? MySelfIcon : PinkMyselfIcon}
              alt="Myself"
              height={40}
            />
            <p className={classes['myself-text']}>Myself</p>
          </div>
        </div>

        <div style={{ position: 'relative' }}>
          <p className={classes['to-text']}>To (Full Name)</p>
          <Input
            ref={nftForRef}
            disabled={isNftForMyself}
            style={nftForInputStyle}
            value={nftFor}
            onChange={nftForChangeHandler}
            placeholder="Enter here"
          />
          {showNftForOptions && (
            <div className={classes['nft-for-options']}>
              {nftForOptionsResponse.data?.data &&
                nftForOptionsResponse.data.data.map((user) => {
                  return (
                    <div
                      onClick={() => selectNftForHandler(user)}
                      className={classes['user-option-container']}
                    >
                      <img
                        src={user.photo}
                        alt={`${user.fullName}`}
                        className={classes['user-image']}
                      />
                      <p className={classes['user-fullName']}>
                        {user.fullName} ({user.username})
                      </p>
                    </div>
                  );
                })}

              {nftForOptionsResponse.isLoading && <LoadingSpinner />}
            </div>
          )}
        </div>
      </div>

      <div className={classes['title-section-container']}>
        <h3>2. What do you want the title of the collectible to be?</h3>

        <div className={classes['title-input-container']}>
          <p className={classes['label']}>Title</p>
          <Input
            style={nftTitleInputStyle}
            placeholder="Enter here"
            value={nftTitle}
            onChange={nftTitleChangeHandler}
          />
        </div>
      </div>

      <div className={classes['what-is-occasion-for-container']}>
        <h3>3. What is the occasion?</h3>

        <div className={classes['occasions-container']}>
          <div
            onClick={() => selectOccassionHandler('Birthday')}
            className={`${classes['occasion-container']} ${
              selectedOccasion === 'Birthday' && classes['selected-occasion']
            } `}
          >
            <img
              src={
                selectedOccasion === 'Birthday'
                  ? PinkBirthdayIcon
                  : BirthdayIcon
              }
              alt="Birthday"
              height={40}
            />
            <p className={classes['someone-else-text']}>Birthday</p>
          </div>

          <div
            onClick={() => selectOccassionHandler('Graduation')}
            className={`${classes['occasion-container']} ${
              selectedOccasion === 'Graduation' && classes['selected-occasion']
            } `}
          >
            <img
              src={
                selectedOccasion === 'Graduation'
                  ? PinkGraduationIcon
                  : GraduationIcon
              }
              alt="Graduation"
              height={40}
            />
            <p className={classes['someone-else-text']}>Graduation</p>
          </div>
          <div
            onClick={() => selectOccassionHandler('Pep Talk')}
            className={`${classes['occasion-container']} ${
              selectedOccasion === 'Pep Talk' && classes['selected-occasion']
            }`}
          >
            <img
              src={
                selectedOccasion === 'Pep Talk' ? PinkPepTalkIcon : PepTalkIcon
              }
              alt="Pep Talk"
              height={40}
            />
            <p className={classes['myself-text']}>Pep Talk</p>
          </div>
          <div
            onClick={() => selectOccassionHandler('Advice')}
            className={`${classes['occasion-container']} ${
              selectedOccasion === 'Advice' && classes['selected-occasion']
            }`}
          >
            <img
              src={selectedOccasion === 'Advice' ? PinkAdviceIcon : AdviceIcon}
              alt="Advice"
              height={40}
            />
            <p className={classes['myself-text']}>Advice</p>
          </div>

          <div
            onClick={() => selectOccassionHandler('Other')}
            className={`${classes['occasion-container']} ${
              selectedOccasion === 'Other' && classes['selected-occasion']
            }`}
          >
            <img
              src={selectedOccasion === 'Other' ? PinkOtherIcon : OtherIcon}
              alt="Other"
              height={40}
            />
            <p className={classes['myself-text']}>Other</p>
          </div>
        </div>
      </div>

      <div className={classes['what-is-occasion-for-container']}>
        <h3>
          4. What would you like {userProfile.fullName} to cover in the video /
          photo ? Share specifics here!
        </h3>
        <p className={classes['label']}>About</p>
        <TextArea
          style={aboutInputStyle}
          value={about}
          onChange={aboutChangeHandler}
          placeholder="Enter here"
        />
      </div>

      <div className={classes['what-nft-type-container']}>
        <h3 style={{ marginBottom: 20 }}>
          5. Would you like the collectible to be a photo or video ?
        </h3>

        <SliderButton
          leftValue={<p className={classes['nft-type-text']}>Image</p>}
          rightValue={<p className={classes['nft-type-text']}>Video</p>}
          isLeftContentSelected={!isNftTypeVideo}
          setIsLeftContentSelected={setIsNftTypeVideo}
        />
      </div>

      <div className={classes['actions-container']}>
        <Button darkpinkcolor="true">Cancel</Button>
        <Button
          style={{ width: '100%' }}
          disabled={isNextButtonDisabled}
          onClick={() => setStep('Payment Info')}
          darkpink="true"
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default Request;
