import React, { useContext, useRef, useState } from 'react';
import classes from './PayablePost.module.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import { getDateFrom } from '../../helpers/helpers';
import VerifiedIcon from '../../assets/verified.png';
import ReactPlayer from 'react-player';
import PinkDiamondIcon from '../../assets/pink-diamond-icon.png';
import notify from '../../utils/notify';
import CollectorsOnlyIcon from '../../assets/collectors-only-icon.png';
import CommunityOnlyIcon from '../../assets/community-only-icon.png';
import FollowersOnlyIcon from '../../assets/followers-only-icon.png';
import { createPostPaymentRequest } from '../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import BuyPointsModal from '../BuyPointsModal/BuyPointsModal';

const LOCKED_URL = `https://bucket-locally.s3.us-east-2.amazonaws.com/eb16fdebb7dc7d971bd720c163ac2f2b98685fcd`;

function PayablePost(props) {
  const queryClient = useQueryClient();
  const { profile, getMyProfile } = useContext(AuthContext);
  const postContainerRef = useRef();

  const { payablePost } = props;
  const {
    _id,
    user,
    postStatus,
    postContent,
    uploadedFileType,
    // mentions,
    price,
    createdAt,
    uploadedFile,
  } = payablePost;

  const [creatingPostPayment, setCreatingPostPayment] = useState(false);
  const [showBuyPointsModal, setShowBuyPointsModal] = useState(false);

  const renderPostType = () => {
    if (postStatus === 'My Followers' || postStatus === 'Specific Followers')
      return (
        <div className={classes['followers-post-type-container']}>
          <img
            src={FollowersOnlyIcon}
            alt="Exclusive Followers"
            className={classes['exclusive-icon']}
          />
          <p className={classes['exclusive-followers-text']}>
            {postStatus} only
          </p>
        </div>
      );

    if (postStatus === 'Collectible Holders')
      return (
        <div className={classes['collectors-post-type-container']}>
          <img
            src={CollectorsOnlyIcon}
            alt="Exclusive Collectors"
            className={classes['exclusive-icon']}
          />
          <p className={classes['exclusive-collectors-text']}>
            Collectors only
          </p>
        </div>
      );

    if (postStatus === 'My Communities')
      return (
        <div className={classes['public-post-type-container']}>
          <img
            src={CommunityOnlyIcon}
            alt="Exclusive Communities"
            className={classes['exclusive-icon']}
          />
          <p className={classes['exclusive-public-text']}>Community only</p>
        </div>
      );
  };

  const invalidatePayablePosts = () =>
    Promise.all([
      queryClient.invalidateQueries('payable-posts'),
      queryClient.refetchQueries('payable-posts'),
    ]);

  const unlockPayablePostHandler = async () => {
    if (profile?.winningPoints < parseFloat(price)) {
      setShowBuyPointsModal(true);
      return;
    }

    setCreatingPostPayment(true);
    try {
      await createPostPaymentRequest(_id);

      await invalidatePayablePosts();

      getMyProfile();
    } catch (err) {
      if (!err.startsWith('E11000')) {
        notify('error', err, 2000);
      }
    }
    setCreatingPostPayment(false);
  };

  return (
    <>
      {showBuyPointsModal && (
        <BuyPointsModal setShowBuyPointsModal={setShowBuyPointsModal} />
      )}

      <div ref={postContainerRef} className={classes['post-container']}>
        <div className={classes['header']}>
          <div className={classes['user']}>
            <Link
              to={
                user._id === profile?._id
                  ? '/my-space'
                  : `/user-feed/${user._id}`
              }
            >
              <img
                alt={user.fullName}
                src={user.photo}
                className={classes['user-photo']}
              />
            </Link>
            <div className={classes['user-info']}>
              <div className={classes['post-author-tags']}>
                <Link
                  to={
                    user._id === profile?._id
                      ? '/my-space'
                      : `/user-feed/${user._id}`
                  }
                >
                  <div className={classes['fullName']}>
                    {user.fullName}
                    {user.verified && (
                      <img
                        alt="Verified"
                        src={VerifiedIcon}
                        style={{ height: 16, marginLeft: 6, marginBottom: 2 }}
                      />
                    )}
                    {renderPostType()}
                  </div>
                </Link>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <p className={classes['created-at']}>
                  {getDateFrom(createdAt)}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes['post-content-container']}>
          <p className={classes['post-content-text']}>{postContent}</p>
        </div>

        <div className={classes['post-image-container']}>
          <div className={classes['image-container']}>
            {!uploadedFile ? (
              <img
                alt="LOCKED"
                className={classes['post-image']}
                src={LOCKED_URL}
              />
            ) : uploadedFileType === 'image' ? (
              <img
                alt="LOCKED FILE"
                className={classes['post-image']}
                src={uploadedFile}
              />
            ) : (
              <ReactPlayer
                controls
                width={'100%'}
                height={360}
                url={uploadedFile}
              />
            )}
          </div>
        </div>

        {!uploadedFile && (
          <div
            style={{ cursor: creatingPostPayment ? 'not-allowed' : 'pointer' }}
            onClick={unlockPayablePostHandler}
            className={classes['unlock-post-container']}
          >
            <div className={classes['unlock-button']}>
              <p className={classes['unlock-post-text']}>
                UNLOCK POST FOR{' '}
                <span className={classes['price-text']}>
                  {price}
                  <img
                    alt="Diamonds"
                    className={classes['diamond-icon']}
                    src={PinkDiamondIcon}
                  />
                  DIAMONDS
                </span>{' '}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PayablePost;
