import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import Button from '../../../../../../components/Button/Button';
import Input from '../../../../../../components/Input/Input';
import Modal from '../../../../../../components/Modal/Modal';
import {
  createGroupChannelRequest,
  createPrivateGroupChannelRequest,
} from '../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../utils/notify';
import classes from './CreateGroupChannelModal.module.css';
import SliderButton from '../../../../../../components/SliderButton/SliderButton';
import InviteGroupMembersModal from '../../../../../../components/InviteGroupMembersModal/InviteGroupMembersModal';

function CreateGroupChannelModal(props) {
  const queryClient = useQueryClient();
  const { setShowCreateGroupChannelModal, groupId } = props;

  const [channelName, setChannelName] = useState('');
  const [channelNameTouched, setChannelNameTouched] = useState(false);
  const [creatingGroupChannel, setCreatingGroupChannel] = useState(false);
  const [isPublicStatusSelected, setIsPublicStatusSelected] = useState(true);
  const [
    showInviteGroupMembersModal,
    setShowInviteGroupMembersModal,
  ] = useState(false);

  const closeModalHandler = () => {
    setShowCreateGroupChannelModal(false);
  };

  const channelNameChangeHandler = (e) => {
    setChannelNameTouched(true);
    setChannelName(e.target.value);
  };

  const invalidateGroupChannels = async () => {
    await Promise.all([
      queryClient.invalidateQueries(['group-channels', groupId]),
      queryClient.refetchQueries(['group-channels', groupId]),
    ]);
  };

  const createGroupChannelHandler = async () => {
    setCreatingGroupChannel(true);

    try {
      const requestBody = {
        name: channelName,
        status: isPublicStatusSelected ? 'Public' : 'Private',
      };

      if (isPublicStatusSelected) {
        await createGroupChannelRequest(groupId, requestBody);
        await invalidateGroupChannels();

        closeModalHandler();
      } else {
        setShowInviteGroupMembersModal(true);
      }
    } catch (error) {
      notify('error', error, 2000);
    }
    setCreatingGroupChannel(false);
  };

  const inviteGroupMembersHandler = async (invitedMembers) => {
    try {
      const requestBody = {
        name: channelName,
        invitedUserIds: Object.keys(invitedMembers),
      };
      await createPrivateGroupChannelRequest(groupId, requestBody);
      await invalidateGroupChannels();
      notify('success', 'Invitation sent successfully!', 2000);
      closeModalHandler();
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const channelNameInputStyle = {};
  if (channelNameTouched && channelName.length === 0)
    channelNameInputStyle['border'] = '1px solid red';

  return (
    <>
      {showInviteGroupMembersModal && (
        <InviteGroupMembersModal
          groupId={groupId}
          setShowInviteGroupMembersModal={setShowInviteGroupMembersModal}
          inviteHandler={inviteGroupMembersHandler}
        />
      )}

      {!showInviteGroupMembersModal && (
        <Modal
          closeModal={closeModalHandler}
          title="Create Group Channel"
          footer={
            <div className={classes['footer']}>
              <Button
                style={{ width: 200 }}
                darkpinkcolor="true"
                onClick={closeModalHandler}
              >
                Cancel
              </Button>
              <Button
                style={{ width: 200 }}
                disabled={channelName.length === 0 || creatingGroupChannel}
                darkpink="true"
                onClick={createGroupChannelHandler}
              >
                {isPublicStatusSelected ? 'Create' : 'Invite Members'}
              </Button>
            </div>
          }
        >
          <>
            <p className={classes['label']}>Channel Name</p>
            <Input
              onChange={channelNameChangeHandler}
              placeholder="Enter here"
              style={{ width: '100%', ...channelNameInputStyle }}
              value={channelName}
            />

            <div className={classes['slider-container']}>
              <SliderButton
                leftValue={<p className={classes['public-text']}>Public</p>}
                rightValue={<p className={classes['private-text']}>Private</p>}
                isLeftContentSelected={isPublicStatusSelected}
                setIsLeftContentSelected={setIsPublicStatusSelected}
              />
            </div>
          </>
        </Modal>
      )}
    </>
  );
}

export default CreateGroupChannelModal;
