import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/auth-context';
import classes from './MySpace.module.css';
import MyPosts from './components/MyPosts/MyPosts';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import notify from '../../utils/notify';
import {
  getUsersNumberOfFollowersAndFollowingsRequest,
  // uploadCoverPhotoRequest,
} from '../../httpRequests/httpRequests';
import { useQuery } from 'react-query';
import MyFriends from './components/MyFriends/MyFriends';
import Photos from './components/Photos/Photos';
import MyPurchases from './components/MyPurchases/MyPurchases';
import MyListings from './components/MyListings/MyListings';
import MyFollowersModal from './components/MyFollowersModal/MyFollowersModal';
import MyFollowingsModal from './components/MyFollowingsModal/MyFollowingsModal';
import CameraIcon from '../../assets/camera-1.png';
import UploadImageModal from './components/UploadImageModal/UploadImageModal';
import UploadCoverPhotoModal from './components/UploadCoverPhotoModal/UploadCoverPhotoModal';
import VerifiedIcon from '../../assets/verified.png';
import AlbumPhotos from './components/AlbumPhotos/Photos';
import ImageModal from '../../components/ImageModal/ImageModal';
import MetaTags from 'react-meta-tags';
import MetaMaskNfts from './components/MetaMaskNfts/MetaMaskNfts';
import { MetaMaskContext } from '../../context/metamask-context';
import { formatWalletAddress, limitString } from '../../helpers/helpers';
import MetaMaskIcon from '../../assets/metamask-icon.png';
import MyNftCollections from './components/MyNftCollections/MyNftCollections';
import MyGroups from './components/MyGroups/MyGroups';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import MessagesSidebar from '../../components/MessagesSidebar/MessagesSidebar';
import BrightDownIcon from '../../assets/bright-down-icon.png';
import DarkDownIcon from '../../assets/dark-down-icon.png';

function MySpace() {
  const { profile } = useContext(AuthContext);
  const {
    connectedWalletAddress,
    isMetaMaskInstalled,
    connectMetaMaskHandler,
  } = useContext(MetaMaskContext);

  const [showMyFollowersModal, setShowMyFollowersModal] = useState(false);
  const [showMyFollowingsModal, setShowMyFollowingsModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState('Posts');
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [uploadImagePreviewUrl, setUploadImagePreviewUrl] = useState();
  const [uploadImageUrl, setUploadImageUrl] = useState();

  const myNumberOfFollowersAndFollowingsResponse = useQuery(
    ['my-number-of-followers-and-followings'],
    () => getUsersNumberOfFollowersAndFollowingsRequest(profile?._id)
  );

  const myNumberOfFollowers = myNumberOfFollowersAndFollowingsResponse.data
    ? myNumberOfFollowersAndFollowingsResponse.data.numberOfFollowers
    : 0;
  const myNumberOfFollowings = myNumberOfFollowersAndFollowingsResponse.data
    ? myNumberOfFollowersAndFollowingsResponse.data.numberOfFollowings
    : 0;

  const [coverPhotoUrl, setCoverPhotoUrl] = useState();
  const [coverPhotoPreviewUrl, setCoverPhotoPreviewUrl] = useState(
    profile?.coverPhoto || undefined
  );
  const [showUploadCoverPhotoModal, setShowUploadCoverPhotoModal] = useState(
    false
  );
  const [showCoverPhotoImageModal, setShowCoverPhotoImageModal] = useState(
    false
  );
  const [showProfilePhotoImageModal, setShowProfilePhotoImageModal] = useState(
    false
  );

  const [
    showListingsPurchasesAndCollectionsOptions,
    setShowListingsPurchasesAndCollectionsOptions,
  ] = useState(false);

  // useEffect(() => {
  //   const updateMyCoverPhotoHandler = () => {
  //     if (!coverPhotoUrl) return;

  //     const formData = new FormData();
  //     formData.append('coverPhoto', coverPhotoUrl);
  //     uploadCoverPhotoRequest(formData);
  //   };

  //   updateMyCoverPhotoHandler();
  // }, [coverPhotoUrl]);

  const coverPhotoInputHandler = (value) => {
    if (!value) return;

    if (value.size > 40000000)
      return notify(
        'error',
        'You can not upload more than 40MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setCoverPhotoUrl(value);
    try {
      fileReader.onload = () => {
        setCoverPhotoPreviewUrl(fileReader.result);
        setShowUploadCoverPhotoModal(true);
      };

      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const openMyFollowersModalHander = () => {
    setShowMyFollowersModal(true);
  };

  const openMyFollowingsModalHander = () => {
    setShowMyFollowingsModal(true);
  };

  const inputImageHandler = (value) => {
    if (!value) return;

    if (value.size > 40000000)
      return notify(
        'error',
        'You can not upload more than 40MB Content!',
        2000
      );

    const fileReader = new FileReader();

    setUploadImageUrl(value);
    try {
      fileReader.onload = () => {
        setUploadImagePreviewUrl(fileReader.result);
        setShowUploadImageModal(true);
      };
      fileReader.readAsDataURL(value);
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const connectMetaMask = async () => {
    try {
      await connectMetaMaskHandler();
    } catch (err) {
      notify('error', err.message, 2000);
    }
  };

  const renderSignatureSignedStatus = () => {
    if (profile?.metaMaskPersonalSignatureSigned) {
      return (
        <div>
          <p className={classes['signature-signed-text']}>Signed Address</p>
          <p>{formatWalletAddress(profile?.signedMetaMaskWalletAddress)}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p className={classes['not-signed-text']}>Not Signed Address</p>
        </div>
      );
    }
  };

  const renderMetaMaskContent = () => {
    return connectedWalletAddress ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignSelf: 'flex-start',
          justifyContent: 'center',
          cursor: 'auto',
          width: '100%',
        }}
      >
        <p className={classes['address-text']}>Connected Address</p>
        <p title={connectedWalletAddress}>
          {formatWalletAddress(connectedWalletAddress)}
        </p>

        {renderSignatureSignedStatus()}
      </div>
    ) : isMetaMaskInstalled ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          width: '100%',
        }}
        onClick={connectMetaMask}
      >
        <p className={classes['connect-metamask-text']}>Connect</p>
      </div>
    ) : (
      <a
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        href={'https://metamask.io/download/'}
        target="_blank"
        rel="noreferrer"
      >
        <p className={classes['install-metamask-text']}>Install</p>
      </a>
    );
  };

  const renderListingsPurchasesAndCollectionsHandler = () => {
    const isContainerSelected = [
      'Listings',
      'Not Listed',
      'Collections',
    ].includes(selectedSection);

    return (
      <div className={classes['listings-purchases-collections-container']}>
        <div
          onClick={() =>
            setShowListingsPurchasesAndCollectionsOptions(
              (prevState) => !prevState
            )
          }
          className={classes['selected-section-down-icon-container']}
        >
          <p
            className={
              isContainerSelected
                ? classes['selected-container-text']
                : undefined
            }
          >
            {isContainerSelected ? selectedSection : 'Collectibles'}
          </p>
          <img
            alt="See"
            src={isContainerSelected ? DarkDownIcon : BrightDownIcon}
          />
        </div>

        {showListingsPurchasesAndCollectionsOptions && (
          <div
            className={
              classes['listings-purchases-collections-options-container']
            }
          >
            {['Listings', 'Not Listed', 'Collections']
              .filter((el) => el !== selectedSection)
              .map((el) => (
                <p
                  style={{ margin: 0, padding: '5px' }}
                  onClick={() => {
                    setSelectedSection(el);
                    setShowListingsPurchasesAndCollectionsOptions(
                      (prevState) => !prevState
                    );
                  }}
                >
                  {el}
                </p>
              ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <MetaTags>
        <title>{profile?.fullName} | Glimpse</title>
        <meta property="og:title" content={`${profile?.fullName} | Glimpse`} />
        <meta property="og:image" content={profile?.photo} />
      </MetaTags>

      {showUploadCoverPhotoModal && (
        <UploadCoverPhotoModal
          setShowUploadCoverPhotoModal={setShowUploadCoverPhotoModal}
          coverPhotoPreviewUrl={coverPhotoPreviewUrl}
          setCoverPhotoPreviewUrl={setCoverPhotoPreviewUrl}
          coverPhotoImageUrl={coverPhotoUrl}
          setCoverPhotoImageUrl={setCoverPhotoUrl}
        />
      )}

      {showUploadImageModal && (
        <UploadImageModal
          setUploadImagePreviewUrl={setUploadImagePreviewUrl}
          setUploadImageUrl={setUploadImageUrl}
          uploadImageUrl={uploadImageUrl}
          uploadImagePreviewUrl={uploadImagePreviewUrl}
          setShowUploadImageModal={setShowUploadImageModal}
        />
      )}

      {showMyFollowersModal && (
        <MyFollowersModal setShowMyFollowersModal={setShowMyFollowersModal} />
      )}
      {showMyFollowingsModal && (
        <MyFollowingsModal
          setShowMyFollowingsModal={setShowMyFollowingsModal}
        />
      )}

      {showCoverPhotoImageModal && (
        <ImageModal
          setShowImageModal={setShowCoverPhotoImageModal}
          title="Cover Photo"
        >
          <img
            alt="Cover"
            style={{ width: '100%', objectFit: 'cover' }}
            src={profile?.coverPhoto}
          />
        </ImageModal>
      )}

      {showProfilePhotoImageModal && (
        <ImageModal
          title="Profile Photo"
          setShowImageModal={setShowProfilePhotoImageModal}
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={profile?.photo}
            alt="ProfilePhoto"
          />
        </ImageModal>
      )}

      <div className={classes['my-space-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['user-feed-content-container']}>
          {profile?.coverPhoto ? (
            <div className={classes['cover-photo-container']}>
              <img
                onClick={() => setShowCoverPhotoImageModal(true)}
                alt={profile?.fullName}
                className={classes['cover-photo']}
                src={profile?.coverPhoto}
              />
              <div className={classes['cover-photo-camera-icon-container']}>
                <ImageUpload onInput={coverPhotoInputHandler}>
                  <img
                    alt="Camera"
                    src={CameraIcon}
                    className={classes['cover-photo-camera-icon']}
                  />
                </ImageUpload>
              </div>
            </div>
          ) : (
            <div className={classes['cover-photo-container']}>
              <div className={classes['no-cover-photo']}></div>
              <div className={classes['cover-photo-camera-icon-container']}>
                <ImageUpload onInput={coverPhotoInputHandler}>
                  <img
                    alt="Camera"
                    src={CameraIcon}
                    className={classes['cover-photo-camera-icon']}
                  />
                </ImageUpload>
              </div>
            </div>
          )}

          <div className={classes['user-information']}>
            <div className={classes['user-info-cover-photo-container']}>
              <div className={classes['image-camera-container']}>
                <img
                  alt={profile?.fullName}
                  src={profile?.photo}
                  className={classes['user-image']}
                  onClick={() => setShowProfilePhotoImageModal(true)}
                />

                <div className={classes['camera-container']}>
                  <ImageUpload onInput={inputImageHandler}>
                    <img
                      alt="Camera"
                      className={classes['camera-icon']}
                      src={CameraIcon}
                      height={16}
                    />
                  </ImageUpload>
                </div>
              </div>

              <div>
                <h3 className={classes['user-fullName']}>
                  {profile?.fullName && limitString(profile?.fullName, 30)}
                  {profile.verified && (
                    <img
                      alt="Verified"
                      src={VerifiedIcon}
                      style={{ marginLeft: 8, height: 16 }}
                    />
                  )}
                </h3>
                <p className={classes['username']}>@{profile?.username}</p>
              </div>
            </div>
          </div>

          <div className={classes['user-sections-container']}>
            {[
              'Posts',
              'Photos',
              // 'Albums',
              // 'Collections',
              'Communities',
              // 'Not Listed',
              'Friends',
              // 'MetaMask NFTs',
              // 'Listings',
            ].map((section) => {
              return (
                <div
                  onClick={() => {
                    setSelectedSection(section);
                    setShowListingsPurchasesAndCollectionsOptions(false);
                  }}
                  key={section}
                  className={classes['section-container']}
                >
                  <div
                    className={`${classes['section-text']} ${
                      section === selectedSection
                        ? classes['selected-section']
                        : ''
                    }`}
                  >
                    {section}

                    {section === selectedSection && (
                      <div className={classes['selected-border']}>&nbsp;</div>
                    )}
                  </div>
                </div>
              );
            })}

            {renderListingsPurchasesAndCollectionsHandler()}
          </div>
          <div className={classes['horizontal-line']}></div>

          {selectedSection === 'Posts' && (
            <MyPosts
              openMyFollowersModalHander={openMyFollowersModalHander}
              openMyFollowingsModalHander={openMyFollowingsModalHander}
              myNumberOfFollowers={myNumberOfFollowers}
              myNumberOfFollowings={myNumberOfFollowings}
              showListingsSectionsHandler={() => setSelectedSection('Listings')}
              seeAllPhotosHandler={() => setSelectedSection('Photos')}
            />
          )}
          {selectedSection === 'Photos' && <Photos />}
          {selectedSection === 'Not Listed' && <MyPurchases />}
          {selectedSection === 'Collections' && <MyNftCollections />}
          {selectedSection === 'Communities' && <MyGroups />}
          {selectedSection === 'Listings' && <MyListings />}
          {selectedSection === 'Friends' && <MyFriends />}
          {selectedSection === 'Albums' && <AlbumPhotos />}
          {selectedSection === 'MetaMask NFTs' ? (
            connectedWalletAddress ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    background: 'white',
                  }}
                  className={classes['metamask-box-container']}
                >
                  <div style={{ height: 25 }}>&nbsp;</div>
                  <img height={30} src={MetaMaskIcon} alt="MetaMask" />

                  {renderMetaMaskContent()}
                </div>
                <MetaMaskNfts />
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  background: 'white',
                }}
                className={classes['metamask-box-container']}
              >
                <div style={{ height: 25 }}>&nbsp;</div>
                <img height={30} src={MetaMaskIcon} alt="MetaMask" />

                {renderMetaMaskContent()}
              </div>
            )
          ) : null}
        </div>

        <div className={classes['messages-sidebar-container']}>
          <MessagesSidebar />
        </div>
      </div>
    </>
  );
}

export default MySpace;
