import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../../context/auth-context';
import { updateUsernameRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import Button from '../../../Button/Button';
import Input from '../../../Input/Input';
import Modal from '../../../Modal/Modal';
import classes from './ChangeUsernameModal.module.css';

function ChangeUsernameModal(props) {
  const { setShowChangeUsernameModal, setShowSettingsModal } = props;
  const { profile, updateProfileInformation } = useContext(AuthContext);
  const [username, setUsername] = useState(profile?.username);
  const [error, setError] = useState();

  const closeModalHandler = () => setShowChangeUsernameModal(false);

  const updateUsernameHandler = async () => {
    try {
      const requestBody = { username };
      await updateUsernameRequest(requestBody);
      updateProfileInformation({ ...profile, username });
      notify('success', 'Username updated successfully!', 1500);
      setShowSettingsModal(false);
    } catch (err) {
      setError(err);
      setTimeout(() => {
        setError();
      }, 2000);
    }
  };

  const isUsernameValid =
    username.length > 1 &&
    !username.includes(' ') &&
    /^[\w-_]*$/.test(username);

  const usernameInputStyle = { width: '100%' };
  if (!isUsernameValid) {
    usernameInputStyle['border'] = '1px solid red';
  }

  const isSaveButtonEnabled = isUsernameValid;

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            onClick={updateUsernameHandler}
            darkpink="true"
            disabled={!isSaveButtonEnabled}
          >
            Save
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Change Username"
    >
      <p className={classes['label']}>Username</p>
      <Input
        style={usernameInputStyle}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      {error && <p className={classes['error']}>{error}</p>}
    </Modal>
  );
}

export default ChangeUsernameModal;
