import React, { useState } from 'react';
import classes from './UpdateWhoCanPostModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import notify from '../../../../utils/notify';
import { updateWhoCanPostInASpecificGroupRequest } from '../../../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';

function UpdateWhoCanPostModal(props) {
  const { setShowUpdateWhoCanPostModal, group } = props;
  const queryClient = useQueryClient();

  const [whoCanPost, setWhoCanPost] = useState(group.whoCanPost);
  const [updatingWhoCanPost, setUpdatingWhoCanPost] = useState(false);

  const closeModalHandler = () => {
    setShowUpdateWhoCanPostModal(false);
  };

  const updateWhoCanPostHandler = (who) => {
    setWhoCanPost(who);
  };

  const invalidateGroupInformation = () =>
    Promise.all([
      queryClient.invalidateQueries(['groups', group._id]),
      queryClient.refetchQueries(['groups', group._id]),
    ]);

  const updateWhoCanPostInASpecificGroupHandler = async () => {
    setUpdatingWhoCanPost(true);

    try {
      const requestBody = {
        whoCanPost,
      };
      await updateWhoCanPostInASpecificGroupRequest(group._id, requestBody);

      await invalidateGroupInformation();
      setUpdatingWhoCanPost(false);

      closeModalHandler();
    } catch (err) {
      setUpdatingWhoCanPost(false);
      notify('error', err, 2000);
    }
  };

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Update Who Can Post"
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={updatingWhoCanPost}
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Close
          </Button>

          <Button
            disabled={updatingWhoCanPost}
            onClick={updateWhoCanPostInASpecificGroupHandler}
            style={{ width: 200 }}
            darkpink="true"
          >
            Save
          </Button>
        </div>
      }
    >
      <div>
        <div className={classes['anyone-container']}>
          <input
            onChange={() => updateWhoCanPostHandler('anyone')}
            checked={whoCanPost === 'anyone'}
            type={'radio'}
            className={classes['checkbox']}
          />
          <p>Anyone</p>
        </div>

        <div className={classes['only-admins-container']}>
          <input
            onChange={() => updateWhoCanPostHandler('admins')}
            checked={whoCanPost === 'admins'}
            type={'radio'}
            className={classes['checkbox']}
          />
          <p>Only Admins</p>
        </div>
      </div>
    </Modal>
  );
}

export default UpdateWhoCanPostModal;
