import React, { useContext } from 'react';
import classes from './Event.module.css';
import CalendarIcon from '../../../../assets/calendar-icon.png';
import LocationIcon from '../../../../assets/location-icon.png';
import moment from 'moment';
import Button from '../../../../components/Button/Button';
import notify from '../../../../utils/notify';
import { uploadEventPhotosRequest } from '../../../../httpRequests/httpRequests';
import MultipleImageUpload from '../../../../components/MultipleImageUpload/MultipleImageUpload';
import { useQueryClient } from 'react-query';
import { limitString } from '../../../../helpers/helpers';
import { AuthContext } from '../../../../context/auth-context';
import { Link } from 'react-router-dom';

function Event(props) {
  const queryClient = useQueryClient();
  const { profile } = useContext(AuthContext);
  const { event, searchValue } = props;

  const addPhotosHandler = async (files) => {
    try {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append('photos', file);
      });

      const updatedEvent = await uploadEventPhotosRequest(event._id, formData);

      queryClient.setQueryData(['events', searchValue], (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => {
            const updatedData = page.data.map((el) => {
              if (el._id === event._id) {
                return { ...el, photos: updatedEvent.data.photos };
              } else {
                return { ...el };
              }
            });
            return {
              ...page,
              data: updatedData,
            };
          }),
        };
      });
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div className={classes['event-container']}>
      <img
        src={event.photo}
        alt={event.name}
        className={classes['event-photo']}
      />

      <div className={classes['event-info-container']}>
        <h3 className={classes['event-name']}>{event.name}</h3>
        <div className={classes['location-date-container']}>
          <div className={classes['location-container']}>
            <img
              className={classes['location-icon']}
              src={LocationIcon}
              alt="Location"
            />
            <p className={classes['location']}>
              <span className={classes['location-text']}>Location: </span>
              <span
                title={event.location}
                className={classes['location-value']}
              >
                {limitString(event.location, 10)}
              </span>
            </p>
          </div>

          <div className={classes['date-container']}>
            <img
              className={classes['calendar-icon']}
              src={CalendarIcon}
              alt="Calendar"
            />

            <p className={classes['date']}>
              <span className={classes['date-text']}>Date: </span>
              <span className={classes['date-value']}>
                {moment(event.date).format('MMMM Do, YYYY')}
              </span>
            </p>
          </div>
        </div>

        <p className={classes['description']}>{event.description}</p>
        {event.creator?.photo && (
          <div className={classes['creator-container']}>
            <img
              src={event.creator.photo}
              alt="Creator"
              className={classes['creator-photo']}
            />

            <div className={classes['creator-fullName-container']}>
              <p className={classes['creator-text']}>Creator</p>
              <p className={classes['creator-fullName']}>
                {event.creator.fullName}
              </p>
            </div>
          </div>
        )}

        <div className={classes['horizontal-line']}>&nbsp;</div>

        <Link to={`/events/${event._id}`}>
          <Button
            style={{ width: '100%', margin: '5px 0' }}
            modernpinkcolor="true"
          >
            Visit
          </Button>
        </Link>

        {event.creator?._id === profile?._id && (
          <MultipleImageUpload onInput={addPhotosHandler}>
            <Button
              style={{ width: '100%', margin: '10px 0' }}
              modernpink="true"
            >
              Add Photos
            </Button>
          </MultipleImageUpload>
        )}
      </div>

      <div className={classes['event-photos-container']}>
        {event.photos?.length > 0 &&
          event.photos.map((photo) => (
            <img
              key={photo}
              src={photo}
              style={{ width: 50, height: 50, objectFit: 'cover' }}
              alt="EventPhoto"
            />
          ))}
      </div>
    </div>
  );
}

export default Event;
