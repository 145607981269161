import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../../../context/auth-context';
import CreateDedicatedPostModal from './components/CreateDedicatedPostModal/CreateDedicatedPostModal';
import classes from './CreateDedicatedPost.module.css';
import PhotoOrVideoIcon from '../../../../../../assets/photo-or-video.png';
import FeelingIcon from '../../../../../../assets/feeling.png';

function CreateDedicatedPost({ userProfile }) {
  const { profile } = useContext(AuthContext);
  const [
    showCreateDedicatedPostModal,
    setShowCreateDedicatedPostModal,
  ] = useState(false);

  const openCreateDedicatedPostModalHandler = () => {
    setShowCreateDedicatedPostModal(true);
  };

  return (
    <>
      {showCreateDedicatedPostModal && (
        <CreateDedicatedPostModal
          userProfile={userProfile}
          setShowCreateDedicatedPostModal={setShowCreateDedicatedPostModal}
        />
      )}

      <div className={classes['what-are-you-thinking-container']}>
        <div className={classes['user-image-what-are-you-thinking']}>
          <img
            className={classes['user-image']}
            src={profile?.photo}
            alt={`${profile?.fullName}`}
          />
          <p
            onClick={openCreateDedicatedPostModalHandler}
            className={classes['what-are-you-thinking']}
          >
            Write something to {userProfile?.firstName}{' '}
          </p>
        </div>

        <div className={classes['horizontal-line']}>&nbsp;</div>

        <div className={classes['photo-video-feeling-container']}>
          <div
            onClick={openCreateDedicatedPostModalHandler}
            className={classes['photo-or-video-container']}
          >
            <img
              className={classes['photo-or-video-image']}
              src={PhotoOrVideoIcon}
              alt="PhotoOrVideo"
            />
            <p className={classes['photo-or-video-text']}>Photo / Video</p>
          </div>

          <div
            onClick={openCreateDedicatedPostModalHandler}
            className={classes['feeling-container']}
          >
            <img
              className={classes['feeling-image']}
              src={FeelingIcon}
              alt="Feeling / Activity"
            />
            <p className={classes['feeling-text']}>Feeling / Activity</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateDedicatedPost;
