import React, { useContext, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import classes from './ForgotPasswordModal.module.css';
import {
  isInputValidationCompleted,
  isOverallFormValid,
} from '../../../helpers/helpers';
import { resetPasswordRequest } from '../../../httpRequests/httpRequests';
import notify from '../../../utils/notify';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../context/auth-context';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';

function ForgotPasswordModal(props) {
  const { loginViaResetPassword } = useContext(AuthContext);
  const history = useHistory();
  const [formState, setFormState] = useState({
    verificationCode: {
      placeholder: 'Verification Code',
      value: '',
      isValid: false,
      touched: false,
      validationRequirements: { required: true },
    },
    newPassword: {
      placeholder: 'New password',
      value: '',
      isValid: false,
      touched: false,
      validationRequirements: { minLength: 6 },
    },
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const inputChangeHandler = (formStateKey, value) => {
    const updatedFormState = { ...formState };
    updatedFormState[formStateKey].value = value;
    updatedFormState[formStateKey].touched = true;
    updatedFormState[formStateKey].valid = isInputValidationCompleted(
      value,
      updatedFormState[formStateKey].validationRequirements
    );

    setIsFormValid(isOverallFormValid(updatedFormState));

    setFormState(updatedFormState);
  };

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    try {
      const requestBody = {
        password: formState.newPassword.value,
      };
      const responseData = await resetPasswordRequest(
        requestBody,
        formState.verificationCode.value
      );
      await loginViaResetPassword(responseData.token);
      history.push('/my-space');
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const closeModalHandler = () => {
    props.setShowForgotPasswordModal(false);
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            darkpink="true"
            disabled={!isFormValid}
            onClick={resetPasswordHandler}
          >
            Reset
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Reset Password"
    >
      <div>
        {Object.entries(formState).map((el) => {
          let inputStyle = { width: '100%' };
          if (el[1].touched && el[1].valid === false) {
            inputStyle = { ...inputStyle, border: '1px solid red' };
          }
          const inputType = el[0] === 'newPassword' ? 'password' : 'text';
          return (
            <div key={el[0]}>
              <p className={classes['label']}>{el[1].placeholder}</p>
              <Input
                key={el[0]}
                style={inputStyle}
                type={inputType}
                placeholder={'Enter here'}
                value={el[1].value}
                onChange={(e) => inputChangeHandler(el[0], e.target.value)}
              />
            </div>
          );
        })}
      </div>
    </Modal>
  );
}

export default ForgotPasswordModal;
