import React, { useContext, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import classes from './EditIntroductionModal.module.css';
import TextArea from '../../../../components/TextArea/TextArea';
import { AuthContext } from '../../../../context/auth-context';
import { editIntroductionRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';

function EditIntroductionModal({ setShowEditIntroductionModal }) {
  const { profile, updateProfileInformation } = useContext(AuthContext);
  const [introduction, setIntroduction] = useState(profile?.introduction || '');

  const [editingIntroduction, setEditingIntroduction] = useState(false);

  const closeModalHandler = () => {
    setShowEditIntroductionModal(false);
  };

  const editIntroductionHandler = async () => {
    setEditingIntroduction(true);

    try {
      const requestBody = {
        introduction,
      };
      await editIntroductionRequest(requestBody);
      const updatedProfileInformation = {
        ...profile,
        introduction,
      };
      updateProfileInformation(updatedProfileInformation);
      setEditingIntroduction(false);

      closeModalHandler();
    } catch (err) {
      setEditingIntroduction(false);

      notify('error', err, 2000);
    }
  };

  const introductionChangeHandler = (e) => {
    const { value } = e.target;

    if (value.length <= 200) {
      setIntroduction(value);
    }
  };

  return (
    <Modal
      title="Edit Introduction"
      closeModal={closeModalHandler}
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Close
          </Button>
          <Button
            disabled={editingIntroduction}
            style={{ width: 200 }}
            darkpink="true"
            onClick={editIntroductionHandler}
          >
            Save
          </Button>
        </div>
      }
    >
      <p className={classes['introduction-text']}>Introduction</p>
      <TextArea
        placeholder="Enter here"
        style={{ minHeight: 120, width: '100%' }}
        value={introduction}
        onChange={introductionChangeHandler}
      />
    </Modal>
  );
}

export default EditIntroductionModal;
