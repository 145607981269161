import React, { useEffect, useState } from 'react';
import classes from './InviteCollaboratorsModal.module.css';
import { useQuery } from 'react-query';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Modal from '../../../../components/Modal/Modal';
import {
  getAvailableUsersToInviteAsNftCollectionCollaboratorRequest,
  inviteNftCollectionCollaboratorRequest,
} from '../../../../httpRequests/httpRequests';
import { limitString } from '../../../../helpers/helpers';
import PinkRemoveIcon from '../../../../assets/pink-remove.png';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import notify from '../../../../utils/notify';

function InviteCollaboratorsModal(props) {
  const { setShowInviteCollaboratorsModal, nftCollection } = props;

  const [searchValue, setSearchValue] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState({});

  const [
    selectedUsersToInviteAsCollaborator,
    setSelectedUsersToInviteAsCollaborator,
  ] = useState([]);
  const [
    showAvailableCollaboratorsToInvite,
    setShowAvailableCollaboratorsToInvite,
  ] = useState(false);
  const [invitingCollaborators, setInvitingCollaborators] = useState(false);

  const { isLoading, data, error } = useQuery(
    [
      'available-users-to-invite-as-nft-collection-collaborator',
      nftCollection._id,
      searchValue,
    ],
    () =>
      getAvailableUsersToInviteAsNftCollectionCollaboratorRequest(
        nftCollection._id,
        1,
        100,
        searchValue
      )
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const closeModalHandler = () => {
    setShowInviteCollaboratorsModal(false);
  };

  const selectUserToInviteAsCollaboratorHandler = (user) => {
    setSelectedUsersToInviteAsCollaborator((prevState) => [...prevState, user]);
    setSelectedUserIds((prevState) => ({ ...prevState, [user._id]: user }));
  };

  const removeSelectedUserFromInvitationAsCollaboratorHandler = (userId) => {
    setSelectedUsersToInviteAsCollaborator((prevState) =>
      prevState.filter((user) => user._id !== userId)
    );

    setSelectedUserIds((prevState) => {
      const updatedSelectedUserIds = { ...prevState };
      delete updatedSelectedUserIds[userId];
      return updatedSelectedUserIds;
    });
  };

  const searchValueChangeHandler = (e) => {
    setSearchValue(e.target.value);
    setShowAvailableCollaboratorsToInvite(true);
  };

  const inviteCollaboratorsHandler = async () => {
    setInvitingCollaborators(true);

    try {
      await Promise.allSettled(
        selectedUsersToInviteAsCollaborator.map(async (user) =>
          inviteNftCollectionCollaboratorRequest(nftCollection._id, user._id)
        )
      );

      notify(
        'success',
        'Invitation sent successfully to all selected users!',
        2000
      );
      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setInvitingCollaborators(false);
  };

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Invite Collaborators"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            disabled={
              invitingCollaborators ||
              selectedUsersToInviteAsCollaborator.length === 0
            }
            onClick={inviteCollaboratorsHandler}
            darkpink="true"
          >
            Invite
          </Button>
        </div>
      }
    >
      <>
        <p style={{ margin: '10px 0 0 0' }} className={classes['label']}>
          Invite Collaborators
        </p>
        <div className={classes['input-selected-users-container']}>
          <div
            className={
              classes['selected-users-to-invite-as-collaborator-container']
            }
          >
            {selectedUsersToInviteAsCollaborator.slice(0, 2).map((user) => (
              <div
                key={user._id}
                className={classes['selected-user-container']}
              >
                <p className={classes['selected-user-text']}>
                  {limitString(user.fullName, 12)}
                </p>
                <img
                  alt="Remove"
                  className={classes['remove-icon']}
                  onClick={() =>
                    removeSelectedUserFromInvitationAsCollaboratorHandler(
                      user._id
                    )
                  }
                  src={PinkRemoveIcon}
                  width={8}
                  height={9}
                />
              </div>
            ))}
            {selectedUsersToInviteAsCollaborator.length > 2 && (
              <div className={classes['selected-user-container']}>
                <p className={classes['selected-user-text']}>
                  + {selectedUsersToInviteAsCollaborator.length - 2} others
                </p>
              </div>
            )}
          </div>

          <Input
            placeholder="Search user"
            value={searchValue}
            onChange={searchValueChangeHandler}
          />

          {showAvailableCollaboratorsToInvite && (
            <div className={classes['users-container']}>
              {!isLoading && data && data.data && data.results === 0 && (
                <p className={classes['no-users-found']}>No Users Found</p>
              )}
              {data &&
                data.data &&
                data.data.map((user) =>
                  user._id in selectedUserIds ? null : (
                    <div
                      onClick={() =>
                        selectUserToInviteAsCollaboratorHandler(user)
                      }
                      key={user._id}
                      className={classes['user-container']}
                    >
                      <img
                        alt={user.fullName}
                        className={classes['user-image']}
                        src={user.photo}
                        width={40}
                        height={40}
                      />
                      <p className={classes['user-fullName']}>
                        {user.fullName}
                      </p>
                    </div>
                  )
                )}

              {isLoading && <LoadingSpinner />}
            </div>
          )}
        </div>
      </>
    </Modal>
  );
}

export default InviteCollaboratorsModal;
