import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getNftOffersForSpecificNftRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import moment from 'moment';
import classes from './PendingOffers.module.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../context/auth-context';
import VerifiedIcon from '../../../../assets/verified.png';
import { CoinMarketCapContext } from '../../../../context/coinmarketcap-context';

function PendingOffers(props) {
  const { nftId } = props;
  const { profile } = useContext(AuthContext);
  const { glmsToUsd } = useContext(CoinMarketCapContext);

  const { isLoading, data, error } = useQuery(
    ['pending-nft-offers', nftId],
    () => getNftOffersForSpecificNftRequest(nftId)
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['pending-offers-container']}>
      {!isLoading && data && data.data.length === 0 && (
        <p style={{ padding: 0, margin: 0 }}>No Pending Offers Found!</p>
      )}

      {!isLoading &&
        data &&
        data.data.map(({ buyer, price, createdAt }, i) => {
          const to = buyer;
          return (
            <div key={i} className={classes['pending-offers-info']}>
              <Link
                to={
                  to._id === profile?._id ? '/my-space' : `/user-feed/${to._id}`
                }
              >
                <img
                  alt={to.fullName}
                  src={to.photo}
                  width={35}
                  height={35}
                  className={classes['user-image']}
                />
              </Link>

              <div>
                <p className={classes['price-info']}>
                  <span className={classes['price-text']}>
                    {parseFloat(price * glmsToUsd.price).toFixed(2)} USD
                  </span>
                  {/* <span className={classes['price-text']}>{price} GLMS</span> */}
                  <span className={classes['by']}>By</span>
                  <Link
                    to={
                      to._id === profile?._id
                        ? '/my-space'
                        : `/user-feed/${to._id}`
                    }
                  >
                    <span className={classes['to-fullName']}>
                      {to.fullName}

                      {to.verified && (
                        <img
                          alt="Verified"
                          src={VerifiedIcon}
                          style={{ height: 17, marginLeft: 6, marginBottom: 2 }}
                        />
                      )}
                    </span>
                  </Link>
                </p>

                <p className={classes['hour-info']}>
                  <span>at</span>
                  <span>{moment(createdAt).format('DD/MM/YYYY')},</span>
                  <span>{moment(createdAt).format('hh:mm A')}</span>
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PendingOffers;
