import React, { useContext, useState } from 'react';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import classes from './ReportNftModal.module.css';
import Select from '../../../../components/Select/Select';
import TextArea from '../../../../components/TextArea/TextArea';
import { AuthContext } from '../../../../context/auth-context';
import notify from '../../../../utils/notify';
import { createReportRequest } from '../../../../httpRequests/httpRequests';

const REPORT_REASONS = ['NSFW', 'Pornography', 'Copyright Infringement'];

function ReportNftModal(props) {
  const { profile } = useContext(AuthContext);
  const { setShowReportNftModal, nft } = props;

  const [selectedReportReason, setSelectedReportReason] = useState(
    REPORT_REASONS[0]
  );
  const [reportDescription, setReportDescription] = useState('');
  const [reporting, setReporting] = useState(false);

  const closeModalHandler = () => {
    setShowReportNftModal(false);
  };

  const reportDescriptionInputHandler = (e) =>
    setReportDescription(e.target.value);

  const reportNftHandler = async () => {
    setReporting(true);

    try {
      const requestBody = {
        from: profile?._id,
        to: nft.owner._id,
        reason: selectedReportReason,
        description: reportDescription,
        entityType: 'NFT',
        entityId: nft._id,
      };
      await createReportRequest(requestBody);
      closeModalHandler();
      notify(
        'success',
        'Report sent successfully. Waiting for approvement!',
        2000
      );
    } catch (error) {
      notify('error', error, 2000);
    }

    setReporting(false);
  };

  const isReportButtonDisabled = reporting || reportDescription.length < 10;

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Report User"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            darkpink="true"
            disabled={isReportButtonDisabled}
            onClick={reportNftHandler}
          >
            Send Report
          </Button>
        </div>
      }
    >
      <p className={classes['label']}>Reason</p>
      <Select
        style={{ width: '100%' }}
        selectedValue={selectedReportReason}
        options={REPORT_REASONS}
        onChange={(value) => setSelectedReportReason(value)}
      />

      <p className={classes['label']}>Description</p>
      <TextArea
        style={{ minHeight: 100, width: '100%' }}
        onChange={reportDescriptionInputHandler}
        value={reportDescription}
        placeholder="Enter here (Minimum 10 characters)"
      />
    </Modal>
  );
}

export default ReportNftModal;
