import React, { useState, useContext } from 'react';
import classes from './SetRequestNftPriceModal.module.css';
import { AuthContext } from '../../../../context/auth-context';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import Input from '../../../Input/Input';
import notify from '../../../../utils/notify';
import { updateMyRequestNftPriceRequest } from '../../../../httpRequests/httpRequests';

const GLMS_TO_USD_PRICE = 0.01;

function SetRequestNftPriceModal(props) {
  const { profile, updateProfileInformation } = useContext(AuthContext);
  const { setShowSetRequestNftPriceModal } = props;

  const [requestNftPrice, setRequestNftPrice] = useState(
    profile.requestNftPrice
      ? parseFloat(profile.requestNftPrice * GLMS_TO_USD_PRICE).toFixed(2)
      : ''
  );
  const [requestNftPriceTouched, setRequestNftPriceTouched] = useState(
    !!profile.requestNftPrice
  );
  const [requestNftPriceValid, setRequestNftPriceValid] = useState(
    !!profile.requestNftPrice
  );

  const closeModalHandler = function () {
    setShowSetRequestNftPriceModal(false);
  };

  const requestNftPriceChangeHandler = (e) => {
    const { value } = e.target;
    setRequestNftPriceTouched(true);
    setRequestNftPrice(value);

    if (
      !value ||
      isNaN(value) ||
      parseFloat(value) < 10 ||
      parseFloat(value) > 10000
    ) {
      setRequestNftPriceValid(false);
    } else {
      setRequestNftPriceValid(true);
    }
  };

  const updateRequestNftPriceHandler = async () => {
    try {
      const requestBody = {
        requestNftPrice: parseFloat(requestNftPrice / GLMS_TO_USD_PRICE),
      };
      const { data } = await updateMyRequestNftPriceRequest(requestBody);
      updateProfileInformation({
        ...profile,
        requestNftPrice: data.requestNftPrice,
      });
      closeModalHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  const requestNftPriceInputStyle = { width: '100%' };
  if (requestNftPriceTouched && !requestNftPriceValid) {
    requestNftPriceInputStyle['border'] = '1px solid red';
  }

  const isSaveButtonEnabled = requestNftPriceValid;

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Cancel
          </Button>
          <Button
            style={{ width: 200 }}
            onClick={updateRequestNftPriceHandler}
            darkpink="true"
            disabled={!isSaveButtonEnabled}
          >
            Save
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Request Collectible Price"
    >
      <p className={classes['label']}>
        {/* Request Collectible Price (1,000 - 1,000,000 GLMS) */}
        Request Collectible Price (10 - 10,000 USD)
      </p>
      <Input
        type="number"
        placeholder="Enter USD amount"
        style={requestNftPriceInputStyle}
        value={requestNftPrice}
        onChange={requestNftPriceChangeHandler}
      />
    </Modal>
  );
}

export default SetRequestNftPriceModal;
