import React, { useEffect } from 'react';
import classes from './RequestBetaAccess.module.css';
import { Col, Row } from 'antd';
import { useQuery } from 'react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { getAllBetaAccessRequests } from '../../../../httpRequests/httpRequests';
import { useHistory } from 'react-router-dom';
import notify from '../../../../utils/notify';

function RequestBetaAccess() {
  const history = useHistory();
  const { isLoading, data, error } = useQuery(
    'beta-access-requests',
    getAllBetaAccessRequests
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['all-events-container']}>
      <div className={classes['heading-container']}>
        <h2 className={classes['all-events-heading']}>
          REQUEST BETA ACCESS = {data?.results}
        </h2>
      </div>
      <div
        style={{ minHeight: '85vh' }}
        id="table-nft"
        className={classes['likes-container']}
      >
        <Row justify="space-between">
          <Col
            style={{ paddingLeft: '4%' }}
            md={4}
            className={classes['pagination-col']}
          >
            Email
          </Col>

          <Col md={4} className={classes['pagination-col']}>
            User
          </Col>

          <Col md={4} className={classes['pagination-col']}>
            Access For
          </Col>
        </Row>

        {data?.data &&
          data.data.map(({ email, user, accessFor }) => {
            return (
              <div key={email}>
                <hr />
                <Row justify="space-between" style={{ padding: '1em 0' }}>
                  <Col md={4}>{email}</Col>
                  {user ? (
                    <Col
                      md={4}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        cursor: 'pointer',
                      }}
                      onClick={() => history.push(`/user-stats/${user._id}`)}
                    >
                      <img
                        width={25}
                        height={25}
                        style={{ borderRadius: 100, objectFit: 'cover' }}
                        alt="User"
                        src={user.photo}
                      />
                      <p>{user.fullName}</p>
                    </Col>
                  ) : (
                    <Col md={4}>-</Col>
                  )}

                  <Col md={4}>{accessFor}</Col>
                </Row>
              </div>
            );
          })}

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default RequestBetaAccess;
