import React, { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import Nft from '../../../../components/Nft/Nft';
import { getNftsNotForSaleRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useParams } from 'react-router-dom';
import classes from './Purchases.module.css';
import Input from '../../../../components/Input/Input';

const RESULTS_PER_PAGE = 6;

function Purchases() {
  const { userId } = useParams();
  const [searchValue, setSearchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['user-nfts-not-for-sale', userId, searchValue],
    ({ pageParam = 1 }) =>
      getNftsNotForSaleRequest(
        userId,
        pageParam,
        RESULTS_PER_PAGE,
        searchValue
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 3000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
    scrollCoefficient: 2.1,
  });

  return (
    <div className={classes['purchases-page-container']}>
      <div className={classes['input-container']}>
        <Input
          placeholder="Search for NFTs"
          style={{ width: '100%', background: 'white' }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className={classes['collection-container']}>
        {!isLoading &&
          data &&
          data.pages.map((page) => {
            return page.data.map((nft) => {
              return <Nft key={nft._id} nft={nft} owner={nft.owner} />;
            });
          })}
      </div>
      {(isLoading || isFetching) && <LoadingSpinner />}
      {!isLoading && data && data.pages[0].results === 0 && (
        <div
          style={{
            background: 'white',
            padding: '.8rem',
            borderRadius: 10,
            marginBottom: 10,
          }}
        >
          <p style={{ opacity: '.7', padding: 0, margin: 0 }}>
            No NFT’s Found!
          </p>
        </div>
      )}
    </div>
  );
}

export default Purchases;
