import React from 'react';
import classes from './SeeCollaboratorsModal.module.css';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import NftCollectionCollaborator from '../../../../components/NftCollectionCollaborator/NftCollectionCollaborator';

function SeeCollaboratorsModal(props) {
  const {
    setShowSeeCollaboratorsModal,
    collaborators,
    nftCollectionCreator,
    nftCollectionId,
  } = props;

  const closeModalHandler = () => {
    setShowSeeCollaboratorsModal(false);
  };

  return (
    <Modal
      closeModal={closeModalHandler}
      title="Collaborators"
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            darkpinkcolor="true"
            onClick={closeModalHandler}
          >
            Close
          </Button>
        </div>
      }
    >
      {collaborators.length === 0 && <p>No Collaborators Found!</p>}
      {collaborators.map((collaborator) => (
        <NftCollectionCollaborator
          nftCollectionId={nftCollectionId}
          nftCollectionCreator={nftCollectionCreator}
          user={collaborator}
          key={collaborator._id}
        />
      ))}
    </Modal>
  );
}

export default SeeCollaboratorsModal;
