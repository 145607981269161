import React, { useEffect } from 'react';
import { getGroupPostTopicsSortedByNumberOfPostsRequest } from '../../../../httpRequests/httpRequests';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useInfiniteQuery } from 'react-query';
import classes from './Topics.module.css';
import notify from '../../../../utils/notify';
import Topic from './components/Topic/Topic';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';

const RESULTS_PER_PAGE = 4;

function Topics(props) {
  const {
    groupId,
    selectedTopic,
    setSelectedTopic,
    setSelectedGroupSection,
    limit,
  } = props;

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['group-post-topics', groupId],
    ({ pageParam = 1 }) =>
      getGroupPostTopicsSortedByNumberOfPostsRequest(
        groupId,
        pageParam,
        RESULTS_PER_PAGE
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <div className={classes['topics-page-container']}>
      <h3 className={classes['group-topics-text']}>Trending Topics</h3>

      <div className={classes['topics-list']}>
        {data && data.pages && data.pages[0].results === 0 && (
          <p>No Topics Found</p>
        )}
        {data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map((topic, i) => {
              if (limit && i > limit) return null;

              return (
                <Topic
                  setSelectedGroupSection={setSelectedGroupSection}
                  setSelectedTopic={setSelectedTopic}
                  isSelected={selectedTopic === topic._id}
                  key={topic._id}
                  topic={topic._id}
                  numberOfPosts={topic.numberOfPosts}
                />
              );
            });
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default Topics;
