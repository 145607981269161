import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../context/auth-context';
import CreateGroupPostModal from '../CreateGroupPostModal/CreateGroupPostModal';
import GroupPosts from '../GroupPosts/GroupPosts';
import classes from './Discussion.module.css';
import PhotoOrVideoIcon from '../../../../assets/photo-or-video.png';
import FeelingIcon from '../../../../assets/feeling.png';
import GroupAbout from '../GroupAbout/GroupAbout';
import Topics from '../Topics/Topics';
import CreatorServices from '../CreatorServices/CreatorServices';

function Discussion(props) {
  const { profile } = useContext(AuthContext);
  const {
    groupId,
    selectedTopic,
    selectedGroup,
    setSelectedTopic,
    setSelectedGroupSection,

    isMyRoleAdmin,
  } = props;

  const [showCreateGroupPostModal, setShowCreateGroupPostModal] = useState(
    false
  );

  const openCreateGroupPostModalHandler = () => {
    setShowCreateGroupPostModal(true);
  };

  const renderWhatAreYouThinkingContainerAccordingly = () => {
    if (
      selectedGroup.whoCanPost !== 'admins' ||
      (isMyRoleAdmin && selectedGroup.whoCanPost === 'admins')
    ) {
      return (
        <div className={classes['what-are-you-thinking-container']}>
          <div className={classes['user-image-what-are-you-thinking']}>
            <img
              className={classes['user-image']}
              src={profile?.photo}
              alt={`${profile?.fullName}`}
            />
            <p
              onClick={openCreateGroupPostModalHandler}
              className={classes['what-are-you-thinking']}
            >
              What are you thinking, {profile?.firstName} ?
            </p>
          </div>

          <div className={classes['horizontal-line']}>&nbsp;</div>

          <div className={classes['photo-video-feeling-container']}>
            <div
              onClick={openCreateGroupPostModalHandler}
              className={classes['photo-or-video-container']}
            >
              <img
                className={classes['photo-or-video-image']}
                src={PhotoOrVideoIcon}
                alt="PhotoOrVideo"
              />
              <p className={classes['photo-or-video-text']}>Photo / Video</p>
            </div>

            <div
              onClick={openCreateGroupPostModalHandler}
              className={classes['feeling-container']}
            >
              <img
                className={classes['feeling-image']}
                src={FeelingIcon}
                alt="Feeling / Activity"
              />
              <p className={classes['feeling-text']}>Feeling / Activity</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes['what-are-you-thinking-container']}>
          <p className={classes['only-admins-text']}>
            Only Admins are allowed to post in this group!
          </p>
        </div>
      );
    }
  };

  return (
    <>
      {showCreateGroupPostModal && (
        <CreateGroupPostModal
          groupId={groupId}
          setShowCreateGroupPostModal={setShowCreateGroupPostModal}
        />
      )}

      <div className={classes['discussions-container']}>
        <div style={{ flex: 1 }}>
          {renderWhatAreYouThinkingContainerAccordingly()}

          <GroupPosts selectedTopic={selectedTopic} groupId={groupId} />
        </div>

        <div style={{ position: 'relative' }}>
          <div className={classes['about-container']}>
            <GroupAbout selectedGroup={selectedGroup} />

            <CreatorServices selectedGroup={selectedGroup} />

            <Topics
              limit={4}
              groupId={groupId}
              selectedTopic={selectedTopic}
              setSelectedTopic={setSelectedTopic}
              setSelectedGroupSection={setSelectedGroupSection}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Discussion;
