import React, { useEffect, useState, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { getMyPaginatedFollowersRequest } from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import Modal from '../../../../components/Modal/Modal';
import User from '../../../../components/User/User';
import classes from './MyFollowersModal.module.css';

const RESULTS_PER_PAGE = 10;

function MyFollowers({ setShowMyFollowersModal }) {
  const followersContainerRef = useRef();
  const [searchValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-paginated-followers', searchValue],
    ({ pageParam = 1 }) =>
      getMyPaginatedFollowersRequest(pageParam, RESULTS_PER_PAGE, searchValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const followingsContainerRefElement = followersContainerRef.current;

    if (!followingsContainerRefElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    followingsContainerRefElement.addEventListener('scroll', scrollHandler);

    return () =>
      followingsContainerRefElement?.removeEventListener(
        'scroll',
        scrollHandler
      );
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  return (
    <Modal
      style={{ maxWidth: 600, height: 600 }}
      closeIcon="true"
      closeModal={() => setShowMyFollowersModal(false)}
      title="My Followers"
    >
      <div
        ref={followersContainerRef}
        className={classes['followers-container']}
      >
        {data && data.pages && data.pages[0].results === 0 && (
          <p className={classes['no-followers']}>No Followers Found!</p>
        )}
        {data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map(({ follower }) => {
              return (
                <User
                  key={follower._id}
                  user={follower}
                  closeModal={() => setShowMyFollowersModal(false)}
                />
              );
            });
          })}
        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </Modal>
  );
}

export default MyFollowers;
