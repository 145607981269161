import React, { useState, useEffect } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import {
  getAllReportsRequest,
  updateReportStatusRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './Reports.module.css';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import ReactPlayer from 'react-player';
import { IPFS_URL } from '../../../../constants/IPFS';
import DescriptionModal from './components/DescriptionModal/DescriptionModal';
import ActionTakenDescriptionModal from './components/ActionTakenDescriptionModal/ActionTakenDescriptionModal';
import ActionTakenResultModal from './components/ActionTakenResultModal/ActionTakenResultModal';

const RESULTS_PER_PAGE = 10;
const REPORT_STATUSES = ['In Review', 'Approved', 'Denied'];

function Reports() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [showReportDescriptionModal, setShowReportDescriptionModal] = useState(
    false
  );
  const [selectedReportDescription, setSelectedReportDescription] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [
    actionTakenDescriptionModalState,
    setActionTakenDescriptionModalState,
  ] = useState({
    opened: false,
    selectedReportId: undefined,
  });
  const [actionTakenResultState, setActionTakenResultState] = useState({
    opened: false,
    actionTakenDescription: '',
  });

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['reports', searchValue],
    ({ pageParam = 1 }) =>
      getAllReportsRequest(pageParam, RESULTS_PER_PAGE, searchValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  const invalidateReportQuery = (reportId, newData) => {
    queryClient.setQueryData(['reports', searchValue], (data) => {
      return {
        ...data,
        pages: data.pages.map((page) => {
          const updatedData = page.data.map((report) =>
            report._id === reportId ? { ...report, ...newData } : report
          );
          return { ...page, data: updatedData };
        }),
      };
    });
  };
  const reportStatusChangeHandler = async (reportId, reportStatus) => {
    try {
      const requestBody = {
        status: reportStatus,
      };
      await updateReportStatusRequest(reportId, requestBody);

      invalidateReportQuery(reportId, { status: reportStatus });

      setActionTakenDescriptionModalState({
        opened: true,
        selectedReportId: reportId,
      });
    } catch (error) {
      notify('error', error, 2000);
    }
  };

  const renderEntityInformation = (entities, index, entityType) => {
    if (entityType === 'NFT') {
      const nft = entities[index];
      if (nft.type === 'image') {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/nfts/${nft._id}`)}
          >
            <img
              width={30}
              height={30}
              style={{ borderRadius: 100, objectFit: 'cover' }}
              alt="User"
              src={`${IPFS_URL}/${nft.ipfs}`}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/nfts/${nft._id}`)}
          >
            <ReactPlayer
              width={30}
              height={30}
              url={`${IPFS_URL}/${nft.ipfs}`}
            />
          </div>
        );
      }
    } else if (entityType === 'User') {
      const user = entities[index];
      return (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/user-stats/${user._id}`)}
        >
          <img
            width={30}
            height={30}
            style={{ borderRadius: 100, objectFit: 'cover' }}
            alt="User"
            src={user.photo}
          />
        </div>
      );
    }
  };

  const seeDescriptionHandler = (description) => {
    setShowReportDescriptionModal(true);
    setSelectedReportDescription(description);
  };

  const showActionTakenResultModalHandler = (actionTakenDescription) => {
    setActionTakenResultState({
      opened: true,
      actionTakenDescription,
    });
  };

  return (
    <>
      {showReportDescriptionModal && (
        <DescriptionModal
          setShowReportDescriptionModal={setShowReportDescriptionModal}
          description={selectedReportDescription}
        />
      )}

      {actionTakenDescriptionModalState.opened && (
        <ActionTakenDescriptionModal
          invalidateReportQuery={invalidateReportQuery}
          actionTakenDescriptionModalState={actionTakenDescriptionModalState}
          setActionTakenDescriptionModalState={
            setActionTakenDescriptionModalState
          }
        />
      )}

      {actionTakenResultState.opened && (
        <ActionTakenResultModal
          setActionTakenResultState={setActionTakenResultState}
          actionTakenDescription={actionTakenResultState.actionTakenDescription}
        />
      )}

      <div style={{ flex: 1 }}>
        <div className={classes['all-events-container']}>
          <div className={classes['heading-container']}>
            <h2 className={classes['all-events-heading']}>
              REPORTS = {data?.pages[0].results}
            </h2>
            <div>
              <input
                placeholder="Search For Users"
                style={{ width: 300 }}
                className="searchInput"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            style={{ minHeight: '85vh' }}
            id="table-nft"
            className={classes['likes-container']}
          >
            <Row justify="space-between">
              <Col
                style={{ paddingLeft: '4%' }}
                md={3}
                className={classes['pagination-col']}
              >
                From
              </Col>

              <Col md={2} className={classes['pagination-col']}>
                Entity Type
              </Col>

              <Col md={2} className={classes['pagination-col']}>
                Entity
              </Col>

              <Col md={2} className={classes['pagination-col']}>
                Reason
              </Col>

              <Col
                md={4}
                style={{ paddingLeft: '2%' }}
                className={classes['pagination-col']}
              >
                Description
              </Col>

              <Col md={4} className={classes['pagination-col']}>
                Created At
              </Col>
              <Col
                style={{ paddingLeft: 20 }}
                md={3}
                className={classes['pagination-col']}
              >
                Status
              </Col>
              <Col
                style={{ paddingLeft: 40 }}
                md={3}
                className={classes['pagination-col']}
              >
                Action
              </Col>
            </Row>
            {data && data.pages[0].results === 0 && (
              <h2 className="bold-text">No results found!</h2>
            )}
            {data &&
              data.pages.map((page) => {
                return page.data.map(
                  (
                    {
                      _id,
                      from,
                      reason,
                      description,
                      entityType,
                      createdAt,
                      status,
                      actionTakenDescription,
                    },
                    index
                  ) => {
                    return (
                      <div key={_id}>
                        <hr />
                        <Row
                          justify="space-between"
                          style={{ padding: '1em 0' }}
                        >
                          <Col
                            md={3}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              history.push(`/user-stats/${from._id}`)
                            }
                          >
                            <img
                              width={25}
                              height={25}
                              style={{ borderRadius: 100, objectFit: 'cover' }}
                              alt="User"
                              src={from.photo}
                            />
                            <p>{from.fullName}</p>
                          </Col>
                          <Col md={2}>{entityType}</Col>
                          <Col md={2}>
                            {renderEntityInformation(
                              page.entities,
                              index,
                              entityType
                            )}
                          </Col>
                          <Col md={2}>{reason}</Col>
                          <Col md={4}>
                            <button
                              onClick={() => seeDescriptionHandler(description)}
                              className="btn-white-glimpse"
                            >
                              Description
                            </button>
                          </Col>
                          <Col md={4}>
                            {moment(createdAt).format('MMMM Do, YYYY')}
                          </Col>
                          <Col md={3}>
                            <select
                              className="select-input"
                              onChange={(e) =>
                                reportStatusChangeHandler(
                                  _id,
                                  e.target.value,
                                  actionTakenDescription
                                )
                              }
                              value={status}
                            >
                              {REPORT_STATUSES.map((reportStatus) => {
                                return (
                                  <option value={reportStatus}>
                                    {reportStatus}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>
                          <Col
                            style={{ paddingLeft: 20 }}
                            md={3}
                            className={classes['pagination-col']}
                          >
                            <button
                              className="btn-white-glimpse"
                              onClick={() =>
                                showActionTakenResultModalHandler(
                                  actionTakenDescription
                                )
                              }
                            >
                              Result
                            </button>
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                );
              })}

            {(isLoading || isFetching) && <LoadingSpinner />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
