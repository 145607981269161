import React, { useState } from 'react';
import moreIcon from '../../../../../../../../assets/gray-menu-options.png';
import { deleteSpecificImageInAlbumRequest } from '../../../../../../../../httpRequests/httpRequests';
import notify from '../../../../../../../../utils/notify';
import classes from './AlbumPhoto.module.css';
import { useQueryClient } from 'react-query';
import ImageModal from '../../../../../../../../components/ImageModal/ImageModal';

function AlbumPhoto(props) {
  const { image, selectedAlbum } = props;
  const queryClient = useQueryClient();
  const [showOptions, setShowOptions] = useState(false);
  const [showAlbumPhotoImageModal, setShowAlbumPhotoImageModal] = useState(
    false
  );

  const deleteAlbumImageHander = async (image) => {
    try {
      const requestBody = {
        image,
      };
      await deleteSpecificImageInAlbumRequest(selectedAlbum._id, requestBody);
      queryClient.invalidateQueries('my-user-image-albums');
      queryClient.refetchQueries('my-user-image-albums');
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <>
      {showAlbumPhotoImageModal && (
        <ImageModal
          setShowImageModal={setShowAlbumPhotoImageModal}
          title="Album Photo"
        >
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={image}
            alt="AlbumPhoto"
          />
        </ImageModal>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '1rem',
        }}
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          <img
            onClick={() => setShowAlbumPhotoImageModal(true)}
            alt="AlbumPhoto"
            className={classes['image']}
            src={image}
            style={{ cursor: 'pointer' }}
          />
          <div
            onClick={() => setShowOptions((prevState) => !prevState)}
            className={classes['more-icon-container']}
          >
            <img
              src={moreIcon}
              alt="More Icon"
              className={classes['more-icon']}
            />

            <div
              style={{ visibility: showOptions ? 'visible' : 'hidden' }}
              className={classes['options']}
            >
              <p
                onClick={() => deleteAlbumImageHander(image)}
                className={classes['delete-text']}
              >
                Delete
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AlbumPhoto;
