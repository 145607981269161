import React, { useContext, useEffect, useState } from 'react';
import classes from './VerifyPhoneNumberModal.module.css';
import Button from '../../../Button/Button';
import Modal from '../../../Modal/Modal';
import Input from '../../../Input/Input';
import moment from 'moment';
import {
  requestPhoneNumberVerificationCodeRequest,
  verifyPhoneNumberRequest,
} from '../../../../httpRequests/httpRequests';
import { AuthContext } from '../../../../context/auth-context';
import { countries } from '../../../../data/countries.json';
import ArrowDownIcon from '../../../../assets/arrowDown.png';
import ArrowUpIcon from '../../../../assets/arrowUp.png';

function VerifyPhoneNumberModal(props) {
  const { profile, getMyProfile } = useContext(AuthContext);
  const { setShowVerifyPhoneNumberModal } = props;
  const [phoneNumber, setPhoneNumber] = useState(
    profile.phoneNumberVerified ? profile.phoneNumber : ''
  );
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(
    false
  );
  const [verificationCode, setVerificationCode] = useState('');
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [verificationCodeExpiresIn, setVerificationCodeExpiresIn] = useState(
    '--'
  );
  const [
    phoneNumberVerificationExpiresIn,
    setPhoneNumberVerificationExpiresIn,
  ] = useState();
  const [selectedPrefix, setSelectedPrefix] = useState('+1');
  const [prefixes, setPrefixes] = useState(countries);
  const [open, setOpen] = useState(false);
  const [prefixValue, setPrefixValue] = useState('');
  const [verificationLoading, setVerificationLoading] = useState(false);

  const closeModalHandler = () => {
    setShowVerifyPhoneNumberModal(false);
  };

  const phoneNumberChangeHandler = (e) => {
    setPhoneNumber(e.target.value);
  };

  const verificationCodeChangeHandler = (e) => {
    setVerificationCode(e.target.value);
  };

  useEffect(() => {
    if (!showVerificationCodeInput) return;

    const timer = setInterval(() => {
      if (verificationCodeExpiresIn <= 0) return;

      const duration = moment.duration(
        moment(phoneNumberVerificationExpiresIn).diff()
      );
      const remainingSeconds = duration.asSeconds();

      if (remainingSeconds <= 0) {
        setVerificationCodeExpiresIn(0);
      } else {
        setVerificationCodeExpiresIn(parseInt(remainingSeconds));
      }
    }, 1000);

    if (verificationCodeExpiresIn <= 0) clearInterval(timer);

    return () => clearInterval(timer);
  }, [
    showVerificationCodeInput,
    verificationCodeExpiresIn,
    phoneNumberVerificationExpiresIn,
  ]);

  const requestPhoneNumberVerificationCodeHandler = async () => {
    setVerificationLoading(true);

    try {
      const completedPhoneNumber = `${selectedPrefix}${phoneNumber}`.replace(
        /\s/g,
        ''
      );

      const requestBody = {
        phoneNumber: completedPhoneNumber,
      };
      const {
        phoneNumberVerificationExpiresIn,
      } = await requestPhoneNumberVerificationCodeRequest(requestBody);

      setPhoneNumberVerificationExpiresIn(phoneNumberVerificationExpiresIn);
      setShowVerificationCodeInput(true);
      setVerificationCodeExpiresIn('--');
    } catch (err) {
      setErrorMessage(err);
      setTimeout(() => {
        setErrorMessage();
      }, 2000);
    }

    setVerificationLoading(false);
  };

  const verifyPhoneNumberHandler = async () => {
    setVerificationLoading(true);

    try {
      await verifyPhoneNumberRequest(verificationCode);

      setSuccessMessage('Your Phone Number successfully verified!');
      setTimeout(() => {
        setVerificationLoading(false);
        setSuccessMessage();
        closeModalHandler();
      }, 2000);
      await getMyProfile();
    } catch (err) {
      setErrorMessage(err);
      setTimeout(() => {
        setErrorMessage();
        setVerificationLoading(false);
      }, 2000);
    }
  };

  const prefixValueSearchHandler = (e) => {
    const { value } = e.target;
    setPrefixValue(e.target.value);
    const updatedPrefixes = [...countries].filter(({ phone, name }) => {
      return (
        phone.startsWith(value) ||
        name.toLowerCase().startsWith(value.toLowerCase())
      );
    });

    setPrefixes(updatedPrefixes);
  };

  const selectPrefixHandler = (phoneNumber) => {
    setOpen(false);
    setSelectedPrefix(phoneNumber);
  };

  const isVerificationButtonDisabled = () => {
    if (!showVerificationCodeInput) {
      return !selectedPrefix || !phoneNumber;
    } else {
      return !verificationCode;
    }
  };

  const phoneNumberInputStyle = { width: '100%' };
  const verificationCodeInputStyle = { width: '100%' };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            style={{ width: 200 }}
            disabled={verificationLoading}
            onClick={closeModalHandler}
            darkpinkcolor="true"
          >
            Cancel
          </Button>
          <Button
            style={{ width: '100%' }}
            disabled={isVerificationButtonDisabled() || verificationLoading}
            onClick={
              !showVerificationCodeInput
                ? requestPhoneNumberVerificationCodeHandler
                : verifyPhoneNumberHandler
            }
            darkpink="true"
          >
            {!showVerificationCodeInput
              ? 'Get Verification Code'
              : 'Verify Now'}
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="Verify Phone Number"
    >
      <p className={classes['label']}>Phone Number</p>

      <div className={classes['phone-input-container']}>
        <div
          onClick={() => setOpen(!open)}
          className={classes['phone-select-container']}
        >
          <p className={classes['selected-prefix']}>{selectedPrefix}</p>
          <img
            alt="Arrow"
            src={open ? ArrowUpIcon : ArrowDownIcon}
            width={10}
            className={classes['arrow-down-icon']}
          />
        </div>

        {open && (
          <div className={classes['prefixes-container']}>
            <Input
              value={prefixValue}
              placeholder="Search"
              style={{ marginBottom: 10 }}
              onChange={prefixValueSearchHandler}
            />
            {prefixes.map((item) => {
              const { name: countryName, phone } = item;

              return (
                <div
                  onClick={() => selectPrefixHandler(phone)}
                  className={classes['phone-country-container']}
                  key={phone}
                >
                  <p className={classes['country-name']}>{countryName}</p>
                  <p className={classes['phone-text']}>{phone}</p>
                </div>
              );
            })}
          </div>
        )}

        <input
          type="number"
          placeholder="Enter here"
          style={phoneNumberInputStyle}
          value={phoneNumber}
          onChange={phoneNumberChangeHandler}
          className={classes['input']}
        />
      </div>

      {showVerificationCodeInput && (
        <>
          <p className={classes['label']}>Verification Code</p>

          <Input
            type="number"
            placeholder="Enter here"
            style={verificationCodeInputStyle}
            value={verificationCode}
            onChange={verificationCodeChangeHandler}
          />
          {!successMessage && (
            <p
              style={{ cursor: verificationCodeExpiresIn === 0 && 'pointer' }}
              onClick={
                verificationCodeExpiresIn === 0
                  ? requestPhoneNumberVerificationCodeHandler
                  : undefined
              }
              className={classes['expires-in']}
            >
              {verificationCodeExpiresIn === 0
                ? 'Resend'
                : `Expires in: ${verificationCodeExpiresIn} seconds`}
            </p>
          )}
        </>
      )}

      {successMessage && (
        <p className={classes['success-message']}>{successMessage}</p>
      )}

      {errorMessage && (
        <p className={classes['error-message']}>{errorMessage}</p>
      )}
    </Modal>
  );
}

export default VerifyPhoneNumberModal;
