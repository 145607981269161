import React, { useContext, useEffect } from 'react';
import classes from './PointsTransactions.module.css';
import { useQuery } from 'react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { AuthContext } from '../../../../context/auth-context';
import { getMyPointsTransactionsRequest } from '../../../../httpRequests/httpRequests';
import moment from 'moment';
import notify from '../../../../utils/notify';
import { useHistory } from 'react-router-dom';

function PointsTransactions() {
  const history = useHistory();
  const { profile } = useContext(AuthContext);
  const { isLoading, data, error } = useQuery(
    'my-points-transactions',
    getMyPointsTransactionsRequest
  );

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const renderTransactionDate = (createdAt) => {
    return (
      <p className={classes['transaction-date']}>
        {moment(createdAt).format('MMMM Do, YYYY')}
      </p>
    );
  };

  const renderSentTo = (transaction, isStripeTransaction, isNftPostTip) => {
    if (isStripeTransaction)
      return <p className={classes['sent-to']}>{profile?.fullName}</p>;

    const fromMe = transaction.from._id === profile?._id;

    if (!fromMe)
      return <p className={classes['sent-to']}>{profile?.fullName}</p>;

    return (
      <p
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() =>
          history.push(
            `/user-feed/${
              isNftPostTip
                ? transaction.nftPostCreator._id
                : transaction.postCreator._id
            }`
          )
        }
        className={classes['sent-to']}
      >
        {isNftPostTip
          ? transaction.nftPostCreator.fullName
          : transaction.postCreator.fullName}
      </p>
    );
  };

  const renderReceivedFrom = (transaction, isStripeTransaction) => {
    if (isStripeTransaction) return <p>GLIMPSE</p>;

    const fromMe = transaction.from._id === profile?._id;
    if (fromMe)
      return <p className={classes['received-from']}>{profile?.fullName}</p>;

    return (
      <p
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => history.push(`/user-feed/${transaction.from._id}`)}
        className={classes['received-from']}
      >
        {transaction.from.fullName}
      </p>
    );
  };

  const renderUsdAmount = (transaction, isStripeTransaction) => {
    if (isStripeTransaction)
      return (
        <p className={classes['green-price']}>+ {transaction.usdAmount} USD</p>
      );

    const fromMe = transaction.from._id === profile?._id;

    if (fromMe) {
      return (
        <p className={classes['red-price']}>
          - {transaction.tipAmount * 0.01} USD
        </p>
      );
    } else {
      return (
        <p className={classes['green-price']}>
          + {transaction.tipAmount * 0.01} USD
        </p>
      );
    }
  };

  const renderPointsAmount = (transaction, isStripeTransaction) => {
    if (isStripeTransaction)
      return (
        <p className={classes['green-price']}>+ {transaction.pointsAmount}</p>
      );

    const fromMe = transaction.from._id === profile?._id;

    if (fromMe) {
      return <p className={classes['red-price']}>- {transaction.tipAmount}</p>;
    } else {
      return (
        <p className={classes['green-price']}>+ {transaction.tipAmount}</p>
      );
    }
  };

  const getSortedTransactions = (transactions) => {
    const sortedTransactions = [...transactions];

    sortedTransactions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return sortedTransactions;
  };

  return (
    <div className={classes['transactions-container']}>
      <h3 className={classes['transactions-text']}>Transactions</h3>

      <div className={classes['table-horizontal-line']}></div>

      <div className={classes['transactions-table']}>
        <p>ACTION</p>
        <p>SENT TO</p>
        <p>RECEIVED FROM</p>
        <p>USD AMOUNT</p>
        <p>DIAMONDS AMOUNT</p>
        <p>DATE</p>

        <div className={classes['table-horizontal-line']}></div>

        {!isLoading && data && data.results === 0 && (
          <p style={{ opacity: '.4', gridColumn: '1/-1' }}>
            No Transactions Found!
          </p>
        )}

        {!isLoading &&
          data &&
          data.postTips &&
          getSortedTransactions([
            ...data.postTips,
            ...data.stripePointsTransactions,
            ...data.nftPostTips,
          ]).map((transaction) => {
            const isStripeTransaction = transaction.stripeTransaction;

            const isNftPostTip = !!transaction.nftPost;

            return (
              <div key={transaction._id} className={classes['transaction']}>
                <p>
                  {isStripeTransaction
                    ? 'Buy'
                    : isNftPostTip
                    ? 'NFT Post Tip'
                    : 'Post Tip'}
                </p>
                {renderSentTo(transaction, isStripeTransaction, isNftPostTip)}
                {renderReceivedFrom(transaction, isStripeTransaction)}
                {renderUsdAmount(transaction, isStripeTransaction)}
                {renderPointsAmount(transaction, isStripeTransaction)}
                {renderTransactionDate(transaction.createdAt)}
              </div>
            );
          })}

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default PointsTransactions;
