import React, { useState, useMemo } from 'react';
import classes from './CreatorServices.module.css';

import StarIcon from '../../../../assets/custom-benefits-star-icon.png';
import PersonalizedVideoIcon from '../../../../assets/personalized-video-icon.png';
import PersonalizedImageIcon from '../../../../assets/personalized-image-icon.png';
import ChatBenefitIcon from '../../../../assets/chat-benefit-icon.png';
import VideoCallBenefitIcon from '../../../../assets/video-call-benefit-icon.png';

import NoSuggestionsIcon from '../../../../assets/no-suggestions.png';
import { limitString } from '../../../../helpers/helpers';
import RequestUserServiceModal from '../../../../components/RequestUserServiceModal/RequestUserServiceModal';

const POPULAR_SERVICES = [
  '1/1 Chat (15mins)',
  '1/1 Video Call (15mins)',
  'Personalized Image',
  'Personalized Video',
];

function CreatorServices(props) {
  const { userProfile } = props;
  const { creatorServices } = userProfile;

  const [
    requestUserServiceModalState,
    setRequestUserServiceModalState,
  ] = useState({
    open: false,
    selectedService: undefined,
  });

  const userExistingServices = useMemo(() => {
    if (!creatorServices || creatorServices?.length === 0)
      return {
        custom: [],
        nonCustom: [],
      };

    const existingCustomServices = [];
    const existingNonCustomServices = [];
    creatorServices.forEach((el) => {
      if (POPULAR_SERVICES.includes(el)) {
        existingNonCustomServices.push(el);
      } else {
        existingCustomServices.push(el);
      }
    });

    return {
      custom: existingCustomServices,
      nonCustom: existingNonCustomServices,
    };
  }, [creatorServices]);

  const customServices = userExistingServices.custom;
  const nonCustomServices = userExistingServices.nonCustom;

  const openRequestUserServiceModalHandler = async (service) => {
    setRequestUserServiceModalState({
      open: true,
      selectedService: service,
    });
  };

  const renderServices = () => {
    const isChatIncluded = nonCustomServices.includes('1/1 Chat (15mins)');
    const isVideoCallIncluded = nonCustomServices.includes(
      '1/1 Video Call (15mins)'
    );
    const isPersonalizedImageIncluded = nonCustomServices.includes(
      'Personalized Image'
    );
    const isPersonalizedVideoIncluded = nonCustomServices.includes(
      'Personalized Video'
    );

    if (nonCustomServices.length + customServices.length === 0) {
      return (
        <div>
          <img
            src={NoSuggestionsIcon}
            alt="No Services"
            className={classes['no-services-icon']}
          />
          <p className={classes['no-services-text']}>No Services Found!</p>
        </div>
      );
    }

    return (
      <div className={classes['custom-non-custom-benefits-container']}>
        {isChatIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={ChatBenefitIcon} alt="Chat Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                1/1 Chat (15mins)
              </p>
              <div
                onClick={() =>
                  openRequestUserServiceModalHandler('1/1 Chat (15mins)')
                }
                className={classes['request-button']}
              >
                <p>Request</p>
              </div>
            </div>
          </div>
        )}

        {isVideoCallIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={VideoCallBenefitIcon} alt="Video Call Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                1/1 Video Call (15mins)
              </p>
              <div
                onClick={() =>
                  openRequestUserServiceModalHandler('1/1 Video Call (15mins)')
                }
                className={classes['request-button']}
              >
                <p>Request</p>
              </div>
            </div>
          </div>
        )}

        {isPersonalizedImageIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={PersonalizedImageIcon} alt="Personalized Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                Personalized Image
              </p>
              <div
                onClick={() =>
                  openRequestUserServiceModalHandler('Personalized Image')
                }
                className={classes['request-button']}
              >
                <p>Request</p>
              </div>
            </div>
          </div>
        )}

        {isPersonalizedVideoIncluded && (
          <div className={classes['non-custom-benefits-container']}>
            <img src={PersonalizedVideoIcon} alt="Personalized Benefit" />
            <div className={classes['non-custom-text-container']}>
              <p className={classes['non-custom-benefit-text-bold']}>
                Personalized Video
              </p>
              <div
                onClick={() =>
                  openRequestUserServiceModalHandler('Personalized Video')
                }
                className={classes['request-button']}
              >
                <p>Request</p>
              </div>
            </div>
          </div>
        )}

        {customServices.map((customService) => {
          return (
            <div
              key={customService}
              className={classes['custom-benefits-container']}
            >
              <img
                src={StarIcon}
                alt="Custom Services"
                className={classes['star-icon']}
              />
              <div className={classes['custom-text-container']}>
                <p
                  style={{
                    overflowWrap: 'anywhere',
                    whiteSpace: 'pre-wrap',
                  }}
                  title={customService}
                  className={classes['custom-benefits-text-bold']}
                >
                  {limitString(customService, 15)}
                </p>
                <div
                  onClick={() =>
                    openRequestUserServiceModalHandler(customService)
                  }
                  className={classes['request-button']}
                >
                  <p>Request</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {requestUserServiceModalState.open && (
        <RequestUserServiceModal
          userProfile={userProfile}
          service={requestUserServiceModalState.selectedService}
          closeModal={() =>
            setRequestUserServiceModalState({
              open: false,
              selectedService: undefined,
            })
          }
        />
      )}

      <div className={classes['creator-services-container']}>
        <div className={classes['creator-services-edit-container']}>
          <p className={classes['creator-services-text']}>Creator Services</p>
        </div>

        <div className={classes['services-container']}>{renderServices()}</div>
      </div>
    </>
  );
}

export default CreatorServices;
