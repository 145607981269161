import React, { useEffect, useState } from 'react';
import classes from './TypesOfSignup.module.css';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import {
  deleteUserAccountRequest,
  getAllUsersRegisteredViaSpecificSignupTypeRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import { Col, Row } from 'antd';
import { limitString } from '../../../../helpers/helpers';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

const SIGNUP_TYPES = {
  EMAIL: 'EMAIL',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  METAMASK: 'METAMASK',
  APPLE: 'APPLE',
  UNSTOPPABLE: 'UNSTOPPABLE',
};

const RESULTS_PER_PAGE = 20;

function TypesOfSignup() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [selectedSignupType, setSelectedSignupType] = useState(
    SIGNUP_TYPES.EMAIL
  );
  const [searchQuery, setSearchQuery] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['types-of-signups', searchQuery, selectedSignupType],
    ({ pageParam = 1 }) =>
      getAllUsersRegisteredViaSpecificSignupTypeRequest(
        pageParam,
        RESULTS_PER_PAGE,
        searchQuery,
        selectedSignupType
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  const invalidateTypesOfSignupsHandler = () =>
    Promise.all([
      queryClient.invalidateQueries([
        'types-of-signups',
        searchQuery,
        selectedSignupType,
      ]),
      queryClient.refetchQueries([
        'types-of-signups',
        searchQuery,
        selectedSignupType,
      ]),
    ]);

  const deleteUserAccountHandler = async (userId) => {
    try {
      await deleteUserAccountRequest(userId);
      await invalidateTypesOfSignupsHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <div className={classes['all-users-container']}>
        <div className={classes['types-of-signups-container']}>
          {Object.values(SIGNUP_TYPES).map((signupType) => (
            <p
              style={{
                color: selectedSignupType === signupType && '#f92baf',
              }}
              onClick={() => setSelectedSignupType(signupType)}
            >
              {signupType}
            </p>
          ))}
        </div>

        <div className={classes['heading-container']}>
          <h2 className={classes['all-users-heading']}>
            ALL USERS = {data?.pages[0].results}
          </h2>

          <div>
            <input
              placeholder="Search For Users (Full Name / Email)"
              style={{ width: 300 }}
              className="searchInput"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={{ minHeight: '85vh' }}
          id="table-nft"
          className={classes['likes-container']}
        >
          <Row justify="space-between">
            <Col md={1} className={classes['pagination-col']}>
              Photo
            </Col>
            <Col md={2} className={classes['pagination-col']}>
              Full Name
            </Col>

            <Col md={3} className={classes['pagination-col']}>
              Email
            </Col>

            <Col md={1} className={classes['pagination-col']}>
              Email Verified
            </Col>

            <Col md={2} className={classes['pagination-col']}>
              Delete
            </Col>
          </Row>

          {!isLoading &&
            data &&
            data.pages.map((page) => {
              return page.data.map((user) => {
                return (
                  <div key={user._id}>
                    <hr />
                    <Row justify="space-between" style={{ padding: '1em 0' }}>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/user-stats/${user._id}`)}
                        md={1}
                      >
                        <img
                          width={25}
                          height={25}
                          style={{ borderRadius: 100, objectFit: 'cover' }}
                          alt="User"
                          src={user.photo}
                        />
                      </Col>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/user-stats/${user._id}`)}
                        md={2}
                      >
                        {user.firstName} {user.lastName}
                      </Col>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/user-stats/${user._id}`)}
                        md={3}
                      >
                        {limitString(user.email, 30)}
                      </Col>
                      <Col md={1}>{user.emailVerified ? 'YES' : 'NO'}</Col>

                      {selectedSignupType === SIGNUP_TYPES.EMAIL &&
                      !user.emailVerified ? (
                        <Col md={2}>
                          <button
                            onClick={() => deleteUserAccountHandler(user._id)}
                            className="cancel-button"
                          >
                            Delete
                          </button>
                        </Col>
                      ) : (
                        <Col md={2}></Col>
                      )}
                    </Row>
                  </div>
                );
              });
            })}

          {(isLoading || isFetching) && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default TypesOfSignup;
