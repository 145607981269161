import React, { useState } from 'react';
import Modal from '../../../../../components/Modal/Modal';
import { changeUserPasswordRequest } from '../../../../../httpRequests/httpRequests';
import notify from '../../../../../utils/notify';

function ResetPasswordModal(props) {
  const {
    setShowResetPasswordModal,
    selectedUser,
    setSelectedUserForResetPassword,
  } = props;
  const [resetPasswordInputValue, setResetPasswordInputValue] = useState('');
  const [
    resetPasswordInputValidation,
    setResetPasswordInputValidation,
  ] = useState({
    touched: false,
    valid: false,
  });
  const [confirmPasswordInputValue, setconfirmPasswordInputValue] = useState(
    ''
  );
  const [
    confirmPasswordInputValidation,
    setConfirmPasswordInputValidation,
  ] = useState({
    touched: false,
    valid: false,
  });
  const [resetingPassword, setResetingPassword] = useState(false);

  const closeModalHandler = () => {
    setShowResetPasswordModal(false);
    setSelectedUserForResetPassword(undefined);
  };

  const resetPasswordHandler = async () => {
    setResetingPassword(true);
    try {
      const requestBody = {
        password: resetPasswordInputValue,
        confirmPassword: confirmPasswordInputValue,
      };
      await changeUserPasswordRequest(selectedUser._id, requestBody);
      notify('success', 'Password changed successfully!', 2000);
      setShowResetPasswordModal(false);
      setSelectedUserForResetPassword(undefined);
    } catch (error) {
      notify('error', error, 3000);
    }

    setResetingPassword(false);
  };

  const resetPasswordInputChangeHandler = (e) => {
    const inputValue = e.target.value;
    setResetPasswordInputValue(inputValue);
    setResetPasswordInputValidation({
      touched: true,
      valid: inputValue.length >= 6,
    });
  };

  const confirmPasswordInputChangeHandler = (e) => {
    const inputValue = e.target.value;
    setconfirmPasswordInputValue(inputValue);
    setConfirmPasswordInputValidation({
      touched: true,
      valid: inputValue.length >= 6,
    });
  };

  const resetPasswordInputStyle = {};
  if (
    resetPasswordInputValidation.touched &&
    !resetPasswordInputValidation.valid
  )
    resetPasswordInputStyle['border'] = '1px solid red';

  const confirmPasswordInputStyle = {};
  if (
    confirmPasswordInputValidation.touched &&
    !confirmPasswordInputValidation.valid
  )
    confirmPasswordInputStyle['border'] = '1px solid red';

  const isResetPasswordButtonDisabled =
    !resetPasswordInputValidation.valid ||
    !confirmPasswordInputValidation.valid;

  return (
    <div>
      <Modal
        footer={
          <>
            <button onClick={closeModalHandler} className={'cancel-button'}>
              Cancel
            </button>
            <span style={{ marginLeft: 10 }}></span>
            <button
              disabled={resetingPassword || isResetPasswordButtonDisabled}
              onClick={resetPasswordHandler}
              className={
                resetingPassword || isResetPasswordButtonDisabled
                  ? 'btn-disabled'
                  : 'btn-white-glimpse'
              }
            >
              {resetingPassword ? 'Reseting...' : 'Reset'}
            </button>
          </>
        }
        closeModal={closeModalHandler}
        title="Reset Password"
      >
        <form autoComplete="off">
          <h2 className="bold-text">
            Are you sure you want to reset password for user:{' '}
          </h2>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <img
              width={30}
              height={30}
              style={{
                borderRadius: 100,
                objectFit: 'cover',
                marginBottom: 10,
              }}
              alt="User"
              src={selectedUser.photo}
            />
            <h2
              style={{
                display: 'inline-block',
                marginLeft: 20,
                marginBottom: 20,
              }}
              className="bold-text"
            >
              {selectedUser.firstName} {selectedUser.lastName} ?
            </h2>
          </div>
          <input
            style={resetPasswordInputStyle}
            onChange={resetPasswordInputChangeHandler}
            placeholder="New Password"
            className="searchInput"
            type={'password'}
            autoComplete="off"
            value={resetPasswordInputValue}
          />
          <div></div>
          <input
            style={confirmPasswordInputStyle}
            onChange={confirmPasswordInputChangeHandler}
            placeholder="Confirm Password"
            className="searchInput"
            type={'password'}
            autoComplete="off"
            value={confirmPasswordInputValue}
          />
        </form>
      </Modal>
    </div>
  );
}

export default ResetPasswordModal;
