import React, { useState, useContext } from 'react';
import classes from './Referrals.module.css';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import MetaTags from 'react-meta-tags';
import ReferralsNetwork from './components/ReferralsNetwork/ReferralsNetwork';
import ReferralsProgress from './components/ReferralsProgress/ReferralsProgress';
import { AuthContext } from '../../context/auth-context';
import CopyIcon from '../../assets/copy-1.png';
import {
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramIcon,
  RedditIcon,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import notify from '../../utils/notify';
import { useHistory } from 'react-router-dom';
import GoBackIcon from '../../assets/go-back-icon-2.png';

const REFERRALS_SECTIONS = {
  REFERRALS_NETWORK: 'Network',
  PROGRESS: 'Progress',
};

function Referrals() {
  const history = useHistory();
  const { profile } = useContext(AuthContext);
  const [selectedSection, setSelectedSection] = useState(
    REFERRALS_SECTIONS.REFERRALS_NETWORK
  );

  const referralLink = `${window.location.origin}/register?ref=${profile.username}`;

  const copyReferralLinkHandler = async () => {
    await navigator.clipboard.writeText(referralLink);

    notify('success', 'Copied!', 1000);
  };

  const copyReferralIdHandler = async () => {
    await navigator.clipboard.writeText(profile.username);

    notify('success', 'Copied!', 1000);
  };

  return (
    <>
      <MetaTags>
        <title>Referrals | Glimpse</title>
        <meta property="og:title" content={'Referrals | Glimpse'} />
      </MetaTags>

      <div className={classes['referrals-page-container']}>
        <div className={classes['user-sidebar-container']}>
          <UserSidebar />
        </div>

        <div className={classes['referrals-container']}>
          <header className={classes['referrals-header']}>
            <div className={classes['go-back-container']}>
              <div onClick={() => history.goBack()}>
                <img
                  src={GoBackIcon}
                  alt="Go Back"
                  className={classes['go-back-icon']}
                />
              </div>
              <h3 className={classes['referrals-text']}>Referrals</h3>
            </div>

            <div className={classes['referrals-sections']}>
              {Object.values(REFERRALS_SECTIONS).map((section) => (
                <div
                  onClick={() => setSelectedSection(section)}
                  key={section}
                  className={classes['section-container']}
                >
                  <div
                    className={`${classes['section-text']} ${
                      section === selectedSection
                        ? classes['selected-section']
                        : ''
                    }`}
                  >
                    {section}
                  </div>
                </div>
              ))}

              <div className={classes['referral-link-container']}>
                <p className={classes['referral-link-text']}>Referral Link: </p>{' '}
                <div className={classes['referral-link-copy-icon-container']}>
                  <p className={classes['referral-link']}>{referralLink}</p>
                  <img
                    className={classes['copy-icon']}
                    src={CopyIcon}
                    alt="Copy"
                    onClick={copyReferralLinkHandler}
                  />
                </div>
              </div>

              <div className={classes['referral-id-container']}>
                <p className={classes['referral-id-text']}>Referral ID:</p>{' '}
                <div className={classes['referral-id-copy-icon-container']}>
                  <p className={classes['referral-id']}>{profile.username}</p>
                  <img
                    className={classes['copy-icon']}
                    src={CopyIcon}
                    alt="Copy"
                    onClick={copyReferralIdHandler}
                  />
                </div>
              </div>

              <div className={classes['share-icons-container']}>
                <FacebookShareButton
                  url={referralLink}
                  hashtag={`#Referral`}
                  description={`Referral Link`}
                  title={'Referral Link'}
                >
                  <FacebookIcon size={28} round />
                </FacebookShareButton>

                <TwitterShareButton
                  url={referralLink}
                  hashtag={`#Referral`}
                  description={`Referral Link`}
                  title={'Referral Link'}
                >
                  <TwitterIcon size={28} round />
                </TwitterShareButton>

                <WhatsappShareButton
                  url={referralLink}
                  hashtag={`#Referral`}
                  description={`Referral Link`}
                  title={'Referral Link'}
                >
                  <WhatsappIcon size={28} round />
                </WhatsappShareButton>

                <TelegramShareButton
                  url={referralLink}
                  hashtag={`#Referral`}
                  description={`Referral Link`}
                  title={'Referral Link'}
                >
                  <TelegramIcon size={28} round />
                </TelegramShareButton>

                <RedditShareButton
                  url={referralLink}
                  hashtag={`#Referral`}
                  description={`Referral Link`}
                  title={'Referral Link'}
                >
                  <RedditIcon size={28} round />
                </RedditShareButton>
              </div>
            </div>
          </header>

          {selectedSection === REFERRALS_SECTIONS.REFERRALS_NETWORK && (
            <ReferralsNetwork />
          )}
          {selectedSection === REFERRALS_SECTIONS.PROGRESS && (
            <ReferralsProgress />
          )}
        </div>
      </div>
    </>
  );
}

export default Referrals;
