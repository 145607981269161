import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import {
  getAllGroupChannelsForSpecificGroupRequest,
  getMyGroupsRequest,
  getNumberOfUnreadMessagesForSpecificGroupChannelRequest,
  getNumberOfUnreadGroupMessagesForSpecificGroupRequest,
} from '../../../../httpRequests/httpRequests';
import notify from '../../../../utils/notify';
import classes from './CommunitiesChatList.module.css';
import GroupPresentationImage from '../../../../assets/group-presentation-image.png';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { limitString } from '../../../../helpers/helpers';
import ArrowUpIcon from '../../../../assets/gray-arrow-up.png';
import ArrowDownIcon from '../../../../assets/arrowDown.png';
import PrivateIcon from '../../../../assets/private-1.png';
import heart from '../../../../assets/pin-icon.png';
import { Link } from 'react-router-dom';

const RESULTS_PER_PAGE = 11;

function CommunitiesChatList(props) {
  const {
    searchChatValue,
    selectedCommunity,
    setSelectedCommunity,
    selectedCommunityChannel,
    setSelectedCommunityChannel,
    messagesSidebar,
  } = props;
  const [channelsForEachGroup, setChannelForEachGroup] = useState({});
  const [expandedCommunities, setExpandedCommunities] = useState({});
  const [
    numberOfUnreadMessagesForEachGroupChannel,
    setNumberOfUnreadMessagesForEachGroupChannel,
  ] = useState({});
  const [
    numberOfUnreadMessagesForEachGroup,
    setNumberOfUnreadMessagesForEachGroup,
  ] = useState({});

  const groupListRef = useRef();

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['my-groups', searchChatValue],
    ({ pageParam = 1 }) =>
      getMyGroupsRequest(pageParam, RESULTS_PER_PAGE, searchChatValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const groupListContainerElement = groupListRef.current;

    if (!groupListContainerElement) return;

    let fetching = false;
    const scrollHandler = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;

        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    groupListContainerElement.addEventListener('scroll', scrollHandler);

    return () =>
      groupListContainerElement?.removeEventListener('scroll', scrollHandler);
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    error && notify('error', error, 2000);
  }, [error]);

  useEffect(() => {
    if (!selectedCommunityChannel) return;

    setNumberOfUnreadMessagesForEachGroupChannel((prevState) => ({
      ...prevState,
      [selectedCommunityChannel.channelId]: 0,
    }));

    if (
      typeof numberOfUnreadMessagesForEachGroupChannel[
        selectedCommunityChannel.channelId
      ] === 'number'
    ) {
      setNumberOfUnreadMessagesForEachGroup((prevState) => {
        return {
          ...prevState,
          [selectedCommunity]:
            parseInt(prevState[selectedCommunity]) -
            parseInt(
              numberOfUnreadMessagesForEachGroupChannel[
                selectedCommunityChannel.channelId
              ]
            ),
        };
      });
    }

    // eslint-disable-next-line
  }, [selectedCommunity, selectedCommunityChannel]);

  useEffect(() => {
    if (!data?.pages) return;

    const populateGroupChannels = async () => {
      const groupIds = [];
      data.pages.forEach((page) => {
        page.data.forEach(({ group }) => {
          const { _id: groupId } = group;
          groupIds.push(groupId);
        });
      });

      const publicAndPrivateChannelsForEachGroup = await Promise.all(
        groupIds.map(async (groupId) => {
          return {
            groupId,
            publicAndPrivateChannels: await getAllGroupChannelsForSpecificGroupRequest(
              groupId
            ),
            numberOfUnreadMessages: await getNumberOfUnreadGroupMessagesForSpecificGroupRequest(
              groupId
            ),
          };
        })
      );

      const updatedChannelsForEachGroup = {};
      const myPublicChannels = [];
      const myPrivateChannels = [];
      const numberOfUnreadMessagesForSpecificGroup = {};
      publicAndPrivateChannelsForEachGroup.forEach((el) => {
        const {
          groupId,
          publicAndPrivateChannels,
          numberOfUnreadMessages,
        } = el;
        if (!publicAndPrivateChannels) return;

        const { data, myPrivateGroupChannels } = publicAndPrivateChannels;

        myPublicChannels.push(...data);
        myPrivateChannels.push(...myPrivateGroupChannels);

        updatedChannelsForEachGroup[groupId] = {
          publicChannels: data,
          privateChannels: myPrivateGroupChannels,
        };

        numberOfUnreadMessagesForSpecificGroup[groupId] =
          numberOfUnreadMessages.data;
      });
      setNumberOfUnreadMessagesForEachGroup(
        numberOfUnreadMessagesForSpecificGroup
      );
      setChannelForEachGroup(updatedChannelsForEachGroup);

      const numberOfUnreadMessagesForEachGroupChannel = {};
      await Promise.all([
        ...myPublicChannels.map(async ({ _id: channelId, group: groupId }) => {
          const {
            data,
          } = await getNumberOfUnreadMessagesForSpecificGroupChannelRequest(
            groupId,
            channelId
          );

          numberOfUnreadMessagesForEachGroupChannel[channelId] = data;
        }),
        ...myPrivateChannels.map(async (el) => {
          if (el.groupChannel?._id) {
            const {
              data,
            } = await getNumberOfUnreadMessagesForSpecificGroupChannelRequest(
              el.group,
              el.groupChannel._id
            );

            numberOfUnreadMessagesForEachGroupChannel[
              el.groupChannel._id
            ] = data;
          }
        }),
      ]);

      setNumberOfUnreadMessagesForEachGroupChannel(
        numberOfUnreadMessagesForEachGroupChannel
      );
    };

    populateGroupChannels();
  }, [data]);

  const toggleCommunityExpandHandler = (groupId) => {
    if (!channelsForEachGroup[groupId]) return;

    const { publicChannels } = channelsForEachGroup[groupId];
    const generalChannel = publicChannels.find((el) => el.name === 'General');

    if (generalChannel && !expandedCommunities[groupId]) {
      setSelectedCommunity(groupId);
      setSelectedCommunityChannel({
        channelId: generalChannel._id,
        channelName: generalChannel.name,
        privateChannel: false,
      });
    }

    const updatedExpandedCommunities = { ...expandedCommunities };
    updatedExpandedCommunities[groupId] = updatedExpandedCommunities[groupId]
      ? false
      : true;
    setExpandedCommunities(updatedExpandedCommunities);
  };

  const selectCommunityChannelHandler = ({
    channelId,
    groupId,
    channelName,
    privateChannel,
  }) => {
    setSelectedCommunityChannel({ channelId, channelName, privateChannel });
    setSelectedCommunity(groupId);
  };

  // const invalidateFavoriteStatus = (groupId, isFavorite) => {
  //   queryClient.setQueryData(['my-groups', searchChatValue], (data) => {
  //     return {
  //       ...data,
  //       pages: data.pages.map((page) => {
  //         const updatedData = page.data.map((el) => {
  //           if (el.group._id === groupId) {
  //             return { ...el, isFavorite };
  //           } else {
  //             return { ...el };
  //           }
  //         });
  //         return {
  //           ...page,
  //           data: updatedData,
  //         };
  //       }),
  //     };
  //   });
  // };

  // const markGroupAsFavoriteHandler = async (groupId) => {
  //   try {
  //     await markGroupAsFavoriteRequest(groupId);

  //     invalidateFavoriteStatus(groupId, true);
  //   } catch (err) {
  //     notify('error', err, 2000);
  //   }
  // };

  // const removeGroupAsFavoriteHandler = async (groupId) => {
  //   try {
  //     await removeGroupAsFavoriteRequest(groupId);

  //     invalidateFavoriteStatus(groupId, false);
  //   } catch (err) {
  //     notify('error', err, 2000);
  //   }
  // };

  const selectGeneralChannelHandler = (groupId) => {
    if (!channelsForEachGroup[groupId]) return;
    const { publicChannels } = channelsForEachGroup[groupId];
    if (!publicChannels?.length) return;
    const firstPublicChannel = publicChannels[0];
    setSelectedCommunityChannel({
      channelId: firstPublicChannel._id,
      channelName: firstPublicChannel.name,
      privateChannel: false,
    });
    setSelectedCommunity(groupId);
  };

  return (
    <>
      <div ref={groupListRef} className={classes['chat-list']}>
        {data?.pages && data.pages[0].results === 0 && (
          <p className={classes['no-results']}>No Results Found!</p>
        )}

        {data?.pages &&
          data.pages.map((page) => {
            return page.data.map(({ group, isFavorite }) => {
              const { name, coverPhoto, _id } = group;

              return (
                <div
                  className={
                    expandedCommunities[_id] && classes['expanded-community']
                  }
                  key={_id}
                >
                  <div
                    onClick={
                      messagesSidebar
                        ? () => selectGeneralChannelHandler(_id)
                        : undefined
                    }
                    className={`${classes['group-container']} ${
                      messagesSidebar && classes['group-container-hover']
                    }`}
                  >
                    <div className={classes['cover-photo-name-container']}>
                      <Link to={`/groups/${group._id}`}>
                        <img
                          style={{
                            marginTop: expandedCommunities[_id] && '3px',
                          }}
                          className={classes['group-cover-photo']}
                          alt="Group"
                          src={coverPhoto || GroupPresentationImage}
                        />
                      </Link>
                      <p
                        onClick={() => toggleCommunityExpandHandler(_id)}
                        className={classes['group-name']}
                      >
                        {limitString(name, 20)}{' '}
                      </p>
                    </div>
                    {numberOfUnreadMessagesForEachGroup[_id] !== undefined &&
                      numberOfUnreadMessagesForEachGroup[_id] !== 0 && (
                        <span className={classes['unread-messages']}>
                          <span className={classes['unread-messages-text']}>
                            {numberOfUnreadMessagesForEachGroup[_id]}
                          </span>
                        </span>
                      )}

                    <div
                      style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                    >
                      {isFavorite && (
                        <img
                          src={heart}
                          height={15}
                          alt="Favorite"
                          className={classes['heart-icon']}
                        />
                      )}

                      {!messagesSidebar && (
                        <div
                          onClick={() => toggleCommunityExpandHandler(_id)}
                          className={classes['circle']}
                        >
                          {!expandedCommunities[_id] ? (
                            <img
                              alt="Arrow Down"
                              src={ArrowDownIcon}
                              className={classes['arrowDown-icon']}
                            />
                          ) : (
                            <img
                              alt="Arrow Up"
                              src={ArrowUpIcon}
                              className={classes['arrowUp-icon']}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {expandedCommunities[_id] && channelsForEachGroup[_id] && (
                    <div className={classes['channels-container']}>
                      {channelsForEachGroup[_id]['publicChannels'].map(
                        ({ name, _id: channelId }) => {
                          return (
                            <div
                              key={channelId}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                              }}
                            >
                              {/* {selectedCommunity === _id &&
                                selectedCommunityChannel.channelId ===
                                  channelId && (
                                  <div
                                    className={
                                      classes['selected-channel-border']
                                    }
                                  >
                                    &nbsp;
                                  </div>
                                )} */}
                              <p
                                title={'#' + name}
                                onClick={() =>
                                  selectCommunityChannelHandler({
                                    channelId,
                                    groupId: _id,
                                    channelName: name,
                                    privateChannel: false,
                                  })
                                }
                                key={channelId}
                                className={`${classes['channel-name']} ${
                                  selectedCommunity === _id &&
                                  selectedCommunityChannel.channelId ===
                                    channelId &&
                                  classes['selected-channel']
                                }`}
                              >
                                #{limitString(name, 10)}{' '}
                              </p>
                              {numberOfUnreadMessagesForEachGroupChannel[
                                channelId
                              ] !== undefined &&
                                numberOfUnreadMessagesForEachGroupChannel[
                                  channelId
                                ] !== 0 && (
                                  <span
                                    style={{ marginRight: 1 }}
                                    className={classes['unread-messages']}
                                  >
                                    <span
                                      className={
                                        classes['unread-messages-text']
                                      }
                                    >
                                      {
                                        numberOfUnreadMessagesForEachGroupChannel[
                                          channelId
                                        ]
                                      }
                                    </span>
                                  </span>
                                )}

                              <div style={{ width: 14 }}>&nbsp;</div>
                            </div>
                          );
                        }
                      )}

                      {channelsForEachGroup[_id]['privateChannels'].map(
                        (el) => {
                          if (el.groupChannel?.name) {
                            const { name } = el.groupChannel;
                            return (
                              <div
                                key={el.groupChannel._id}
                                onClick={() =>
                                  selectCommunityChannelHandler({
                                    channelId: el.groupChannel._id,
                                    groupId: _id,
                                    channelName: name,
                                    privateChannel: true,
                                  })
                                }
                                className={classes['private-channel-container']}
                              >
                                {selectedCommunity === _id &&
                                  selectedCommunityChannel.channelId ===
                                    el.groupChannel._id && (
                                    <div
                                      className={
                                        classes['selected-channel-border']
                                      }
                                    >
                                      &nbsp;
                                    </div>
                                  )}
                                <p
                                  key={name}
                                  className={`${classes['channel-name']} ${
                                    selectedCommunity === _id &&
                                    selectedCommunityChannel.channelId ===
                                      el.groupChannel._id &&
                                    classes['selected-channel']
                                  }`}
                                >
                                  #{limitString(name, 18)}{' '}
                                </p>

                                {numberOfUnreadMessagesForEachGroupChannel[
                                  el.groupChannel._id
                                ] !== undefined &&
                                  numberOfUnreadMessagesForEachGroupChannel[
                                    el.groupChannel._id
                                  ] !== 0 && (
                                    <span
                                      style={{ marginRight: 1 }}
                                      className={classes['unread-messages']}
                                    >
                                      <span
                                        className={
                                          classes['unread-messages-text']
                                        }
                                      >
                                        {
                                          numberOfUnreadMessagesForEachGroupChannel[
                                            el.groupChannel._id
                                          ]
                                        }
                                      </span>
                                    </span>
                                  )}

                                <img
                                  alt="Private Channel"
                                  src={PrivateIcon}
                                  className={classes['private-icon']}
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        }
                      )}
                    </div>
                  )}
                </div>
              );
            });
          })}

        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </>
  );
}

export default CommunitiesChatList;
