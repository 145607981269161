import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import classes from './ReceivedCollaboratorNftRequest.module.css';
import ReactPlayer from 'react-player';
import { IPFS_URL } from '../../constants/IPFS';
import VerifiedIcon from '../../assets/verified.png';
import Button from '../Button/Button';
import notify from '../../utils/notify';
import {
  acceptRequestedNftFromCollaboratorRequest,
  rejectRequestedNftFromCollaboratorRequest,
} from '../../httpRequests/httpRequests';
import { useQueryClient } from 'react-query';
import { limitString } from '../../helpers/helpers';

function ReceivedCollaboratorNftRequest(props) {
  const queryClient = useQueryClient();
  const [acceptingOrRejecting, setAcceptingOrRejecting] = useState(false);

  const {
    nft,
    nftCollection,
    from,
    updatedAt,
    collaboratorNftRequestId,
  } = props;

  const invalidateMyRequestedNftsFromCollaboratorsHandler = () => {
    return Promise.all([
      queryClient.invalidateQueries('my-requested-nfts-from-collaborators'),
      queryClient.refetchQueries('my-requested-nfts-from-collaborators'),
    ]);
  };

  const rejectRequestedNftFromCollaboratorHandler = async () => {
    setAcceptingOrRejecting(true);

    try {
      await rejectRequestedNftFromCollaboratorRequest(collaboratorNftRequestId);

      await invalidateMyRequestedNftsFromCollaboratorsHandler();
    } catch (err) {
      notify('error', err, 2000);
    }
    setAcceptingOrRejecting(false);
  };

  const acceptRequestedNftFromCollaboratorHandler = async () => {
    setAcceptingOrRejecting(true);

    try {
      await acceptRequestedNftFromCollaboratorRequest(collaboratorNftRequestId);

      await invalidateMyRequestedNftsFromCollaboratorsHandler();
    } catch (err) {
      notify('error', err, 2000);
    }

    setAcceptingOrRejecting(false);
  };

  return (
    <div className={classes['received-collaborator-nft-request-container']}>
      {nft.type === 'image' ? (
        <Link to={`/nfts/${nft._id}`}>
          <img
            className={classes['nft-image']}
            src={nft.previewUrl || `${IPFS_URL}/${nft.ipfs}`}
            alt="IPFS Hash"
          />
        </Link>
      ) : (
        <Link to={`/nfts/${nft._id}`}>
          <ReactPlayer
            style={{
              objectFit: 'cover',
              borderRadius: 10,
            }}
            width={330}
            height={300}
            url={`${IPFS_URL}/${nft.ipfs}`}
          />
        </Link>
      )}

      <div
        className={classes['received-collaborator-nft-request-info-container']}
      >
        <div className={classes['from-info-container']}>
          <p className={classes['from-text']}>From: </p>
          <img className={classes['from-photo']} src={from.photo} alt="From" />
          <p className={classes['from-fullName']}>{from.fullName}</p>
          {from.verified && (
            <img width={14} src={VerifiedIcon} alt="Verified" />
          )}
        </div>

        <p className={classes['received-on']}>
          Received on: {moment(updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
        </p>

        <div className={classes['collection-container']}>
          <p className={classes['collection-text']}>Collection:</p>
          <img
            src={nftCollection.coverPhoto}
            alt="Collection"
            className={classes['collection-cover-photo']}
          />
          <p className={classes['collection-title']}>
            {limitString(nftCollection.name, 16)}
          </p>
        </div>

        <div className={classes['button-actions-container']}>
          <Button
            disabled={acceptingOrRejecting}
            onClick={acceptRequestedNftFromCollaboratorHandler}
            darkpink="true"
          >
            Accept
          </Button>
          <Button
            disabled={acceptingOrRejecting}
            onClick={rejectRequestedNftFromCollaboratorHandler}
            darkpinkcolor="true"
          >
            Reject
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReceivedCollaboratorNftRequest;
